import React, { useState, useEffect, useContext } from 'react';
import AppContext from 'context/Context';
import { Button, Modal, Row, Col, Form } from 'react-bootstrap';
import { sleep } from 'utils/functions';
import { documentAPI } from 'utils/api/fulcrum-api';
import Table from 'react-bootstrap/Table';
import { toast } from 'react-toastify';
import moment from 'moment';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import PDFViewerModal from 'components/app/pdfjs-viewer/PDFViewerModal';

const columns = [
	{
		accessor: 'title',
		Header: 'Title',
		headerProps: { className: 'pe-1' },
		cellProps: {
			className: 'py-2'
		}
	},
	{
		accessor: 'document_type',
		Header: 'Type',
		headerProps: { className: 'pe-1' },
		cellProps: {
			className: 'py-2'
		}
	},
	{
		accessor: 'created_at',
		Header: 'SSN',
		headerProps: { className: 'pe-1' },
		cellProps: {
			className: 'py-2'
		}
	},
	{
		accessor: 'action',
		Header: 'Action',
		headerProps: { className: 'pe-1' },
		cellProps: {
			className: 'py-2'
		}
	}
];

function BulAction({ selectedRowIds, handleMultiDocumentSelection = () => { } }) {
	return (
		<Row className="flex-between-center mb-3">
			<Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
				<h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">
					{
						Object.keys(selectedRowIds).length > 0 ?
							'You have selected ' + Object.keys(selectedRowIds).length + ' rows'
							:
							'Select a claimant'
					}
				</h5>
			</Col>
			<Col xs={8} sm="auto" className="ms-auto text-end ps-0">
				{Object.keys(selectedRowIds).length > 0 ? (
					<div className="d-flex">
						<Button
							type="button"
							variant="falcon-default"
							size="sm"
							className="ms-2"
							onClick={() => {
								console.log('selectedRowIds', selectedRowIds);
								handleMultiDocumentSelection(selectedRowIds);
							}}
						>
							Select All
						</Button>
					</div>
				) : (
					<div id="orders-actions">
					</div>
				)}
			</Col>
		</Row>
	);
};

const ClaimantDocumentQuickFind = ({ buttonLabel = "Claimant Documents", buttonVariant = "primary", handleDocumentSelection = () => { }, defaultSearchParams = {}, requiredSearchParams = {}, skipFirstQuery = true, allowMultipleSelect = true, claimantId, claimant = {} }) => {

	const {
		config: { isDark }
	} = useContext(AppContext);

	const [quickFindModalState, setQuickFindModalState] = useState(false);
	const [foundDocuments, setFoundDocuments] = useState([]);
	const [documentCount, setDocumentCount] = useState(0);
	const [searchParams, setSearchParams] = useState({ ...defaultSearchParams, ...requiredSearchParams, });
	const [lastQuery, setLastQuery] = useState({});
	const [skipFirstSearch, setSkipFirstSearch] = useState(skipFirstQuery);
	const [searching, setSearching] = useState(false);
	const [pdfViewerModalState, setPdfViewerModalState] = useState(false);
	const [selectedFileId, setSelectedFileId] = useState(null);

	const formatData = (data = []) => {
		let adjustedData = data.map(doc => {
			return {
				...doc,
				document_type: doc?.document_type?.type || 'Unknown',
				created_at: doc?.createdAt ? moment(doc.createdAt).format('MM-DD-YYYY hh:mm A') : '',
				action: (
					<>
						<Button variant="link" onClick={async () => { setSelectedFileId(doc.file_id); await sleep(1000); setPdfViewerModalState(true); }} className="">View</Button>

						<Button
							variant="falcon-default"
							size="sm"
							onClick={() => handleDocumentSelect([doc])}
						>
							Select
						</Button>
					</>
				)
			}
		});
		setFoundDocuments(adjustedData);

		setSearching(false);
	};

	// Search Claimant Documents
	const handleSearch = async (params) => {
		try {

			let response = await documentAPI.getClaimantDocuments(claimantId);
			if (!response?.data) {
				throw new Error('No claimant data found');
			}

			setDocumentCount(response.data.length);
			formatData(response.data);
			setLastQuery(params);
		} catch (error) {
			console.log(error);
		}
	};

	// Apply search filters
	const newSearch = async (params) => {
		setSearching(true);
		let adjustedSearchParams = {
			...params,
			...requiredSearchParams,
			claimant_id: claimantId
		};
		setSkipFirstSearch(false);
		handleSearch(adjustedSearchParams);
	};


	// Handle modal close
	const handleClose = () => {
		setDocumentCount(0);
		setFoundDocuments([]);
		setSearchParams({});
		setQuickFindModalState(false);
	};

	// Handle multiple document selection
	const handleMultiDocumentSelection = (selectedRows = {}) => {
		// loop through and grab claimant data by row
		let documents = [];
		Object.keys(selectedRows).forEach(row => {
			documents.push(foundDocuments[row]);
		});
		handleDocumentSelect(documents);
	}


	// Select a document
	const handleDocumentSelect = (documents = []) => {
		handleDocumentSelection(documents);
		handleClose();
	};

	// Handle modal open
	const handleShow = () => {
		newSearch();
	};


	return (
		<>
			<Button variant={buttonVariant} onClick={() => setQuickFindModalState(true)}>
				{buttonLabel}
			</Button>

			<Modal
				show={quickFindModalState}
				onHide={handleClose}
				onShow={handleShow}
				size="lg"
				backdrop="static"
				contentClassName="border"
			>
				<Modal.Header
					closeButton
					closeVariant={isDark ? 'white' : undefined}
					className="bg-light px-card border-bottom-0"
				>
					<Modal.Title as="h5">Claimant Documents</Modal.Title>
				</Modal.Header>
				<Modal.Body className="p-card">
					{claimant?.first_name && (
						<>
							<span className="fw-bold">Viewing documents for {claimant.first_name} {claimant.last_name},</span>
						</>
					)}
					<hr />
					<div className="d-flex justify-content-between align-items-center mt-3">
						<h5 className="mb-0">{documentCount} Found Claimants</h5>
						{/* <span className="text-muted">{documentCount} found</span> */}
					</div>
					<div className="mt-3">
						{
							searching ?
								<p>Searching....</p>
								:
								<AdvanceTableWrapper
									columns={columns}
									data={foundDocuments}
									sortable
									searchable
									pagination
									perPage={5}
									selection={allowMultipleSelect}
								>

									<BulAction table handleMultiDocumentSelection={handleMultiDocumentSelection} />
									<Row className="flex-end-center mb-3">
										<Col xs="auto" sm={6} lg={4}>
											<AdvanceTableSearchBox table />
										</Col>
									</Row>
									<AdvanceTable
										table
										headerClassName="bg-200 text-900 text-nowrap align-middle"
										rowClassName="align-middle white-space-nowrap"
										tableProps={{
											bordered: true,
											striped: true,
											className: 'fs--1 mb-0 overflow-hidden'
										}}
									/>
									<div className="mt-3">
										<AdvanceTableFooter
											rowCount={documentCount}
											table
											navButtons
										/>
									</div>
								</AdvanceTableWrapper>

						}
					</div>

					<PDFViewerModal
						modalState={pdfViewerModalState}
						setModalState={setPdfViewerModalState}
						selectedFileId={selectedFileId}
						setSelectedFileId={setSelectedFileId}
					/>
				</Modal.Body>
			</Modal>
		</>
	);
};

export default ClaimantDocumentQuickFind;