import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Modal, Row, Col, Alert, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import AppContext from 'context/Context';
import { assureAPI, claimantAPI } from 'utils/api/fulcrum-api';
import { toast } from 'react-toastify';
import { removeEmptyFromObj } from 'utils/functions';
import moment from 'moment';
import auth from 'utils/auth';
import ClaimantQuickFind from 'components/claimants/find-a-claimant/ClaimantQuickFind';

const CreateCaseFileRequest = ({
	modalState,
	setModalState = () => { },
	account_id = auth.getProfile()?.data?.account_id || null,
	caseFileRequestData,
	setCaseFileRequestData,
	claimant = {},
	// attorneyList = [],
	refreshParent = (() => { })
}) => {
	const {
		config: { isDark }
	} = useContext(AppContext);
	const [claimantInfo, setClaimantInfo] = useState({});
	const navigate = useNavigate();
	const [loading, setLoading] = useState(true);
	const [submitting, setSubmitting] = useState(false);
	const [attorneyList, setAttorneyList] = useState([])
	const [errors, setErrors] = useState([]);
	const defaultForm = {
		office_with_jurisdiction: '',
		case_id: '',
		type: 2,
		test_mode: false
	};

	const [formData, setFormData] = useState(defaultForm);

	// Handle Change
	const handleChange = ({ target }) => {
		let { name, value } = target;
		let additionalValues = {};

		setFormData({ ...formData, ...additionalValues, [name]: value });
	};

	// Get Attorney List
	// const getAttorneyList = async () => {
	// 	try {
	// 		let result = await assureAPI.listEreCredentials(account_id || null);
	// 		if (!result?.data?.data) {
	// 			throw new Error("Unable to retrieve a list of attorney credentials.");
	// 		}
	// 		setAttorneyList(Array.isArray(result.data.data) ? result.data.data : [])
	// 	} catch (error) {
	// 		toast.error("Unbale to retrieve a list of attorney credentials.", { theme: 'colored' });
	// 	}
	// };

	// Get claimant active case data 
	const getClaimantActiveCaseData = async (claimantId) => {
		try {
			let res = await claimantAPI.getClaimant(claimantId || null, false);
			if (!res?.data) {
				throw new Error('No data returned');
			}
			setClaimantInfo(res.data);
			let { office_with_jurisdiction } = res.data?.claimant_active_case || {};
			if (office_with_jurisdiction === "Social Security Field Office") {
				office_with_jurisdiction = "State Agency";
			}
			setFormData({ ...formData, office_with_jurisdiction, case_id: res.data?.active_case_id || null });
		} catch (err) {
			toast.error("Unable to pull claimant case information needed to submit the case file request.", { theme: 'colored' });
			handleClose();
		}
		setLoading(false);
	}

	// Submit
	const handleSubmit = async e => {
		e.preventDefault();
		setErrors([]);
		setSubmitting(true);

		let newErrors = [];

		if (!claimantInfo?.id) {
			newErrors.push('Please select a claimant.');
		}

		if (!formData.type) {
			newErrors.push('Type is required.');
		}

		if (newErrors.length) {
			setErrors(newErrors);
			setSubmitting(false);
			return;
		}

		try {
			// claimantId, caseId,
			let newClaimantData = await assureAPI.createCaseFileRequest(claimantInfo?.id || null, claimantInfo?.active_case_id || null, {
				claimant_id: claimantInfo?.id || null,
				claimantInfo_case_id: claimantInfo?.active_case_id || null,
				type: formData.type,
				office_with_jurisdiction: formData.office_with_jurisdiction,
				test_mode: formData.test_mode
			});

			if (newClaimantData?.message !== "Atlasware Casefile Request has been submitted.") {
				throw new Error('Unable to submit the case file request.');
			}
			refreshParent(); // If a callback function was passed, invoke it
			toast.success('Case file request submitted!', { theme: 'colored' });
			handleClose();
		} catch (error) {
			toast.error(error, {
				theme: 'colored'
			});
		}

		setSubmitting(false);
	};

	// Handle Claimant Selection
	const handleClaimantSelection = (claimant = []) => {
		if (claimant[0]?.id) {
			getClaimantActiveCaseData(claimant[0].id);
		} else {
			toast.error('Unable to find claimant.', { theme: 'colored' });
			setClaimantInfo({});
		}
	};

	// handle show
	const handleShow = () => {
		setSubmitting(false);

		// getAttorneyList();
		if (claimant?.id) {
			setLoading(true);
			getClaimantActiveCaseData(claimant?.id);
		} else {

			setLoading(false);
		}
	};

	// Handle Close
	const handleClose = () => {
		setSubmitting(false);
		setFormData(defaultForm);
		setClaimantInfo({});
		setModalState(false);
	};

	return (
		<>
			<Modal
				show={modalState}
				onHide={handleClose}
				onShow={handleShow}
				contentClassName="border"
				size='lg'
			>
				<Form onSubmit={handleSubmit}>
					<Modal.Header
						closeButton
						closeVariant={isDark ? 'white' : undefined}
						className="bg-light px-card border-bottom-0"
					>
						<Modal.Title as="h5">Create Case File Request</Modal.Title>
					</Modal.Header>
					<Modal.Body className="p-card">
						{
							loading ?
								<>
									<div>
										<Spinner animation="border" role="status">
											<span className="sr-only">Loading...</span>
										</Spinner>
									</div>
								</>
								:
								<>
									<Alert variant={'primary'}>
										To create a new case file request, please fill out the form below.
									</Alert>

									{
										!claimantInfo?.id
											?
											<div>
												{
													!submitting &&
													<>
														<p>Find a claimant to submit the case file request for.</p>
														<ClaimantQuickFind
															handleClaimantSelection={handleClaimantSelection}
															allowMultipleSelect={false}
														/>
													</>
												}
											</div>
											:
											<>
												<h5 className="mb-3">Submit a case file request for {claimantInfo?.first_name || ''} {claimantInfo?.last_name || ''}</h5>

												<span className="">Claimant: <span className="fw-bold">
													{claimantInfo?.first_name || ''} {' '}
													{claimantInfo?.last_name || ''}
												</span></span>
												<br />
												<span className="">Primary Rep: <span className="fw-bold">
													{claimantInfo?.claimant_active_case?.primary_representative?.first_name || ''} {' '}
													{claimantInfo?.claimant_active_case?.primary_representative?.last_name || ''}
												</span></span>
												<br />
												Case ID: {formData?.case_id || ''}
												<br />

												ERE Credential ID: {claimantInfo?.claimant_active_case?.assure_credential_id || ''}
												<br /><br />

												{
													(!claimantInfo?.claimant_active_case?.primary_representative?.assure_ere_credentials_id || !formData?.case_id) ?
														(
															<Alert variant={'danger'}>
																The selected claimant must have an active case with an assigned set of ERE credentials.
															</Alert>
														)
														:
														(
															<>
																{
																	errors.length > 0 &&
																	(
																		<Alert variant={'danger'}>
																			<ul>
																				{
																					errors.map((error, index) => (
																						<li key={index}>{error}</li>
																					))
																				}
																			</ul>
																		</Alert>
																	)
																}

																{/* <Form.Group className="my-3">
																	<Form.Label>Attorney</Form.Label>
																	<Form.Control
																		as="select"
																		name="credential_id"
																		value={formData.credential_id}
																		onChange={handleChange}
																		required
																		disabled={submitting}
																	>
																		<option value="">Select an attorney</option>
																		{
																			Array.isArray(attorneyList) && attorneyList?.map(attorney => (
																				<option key={`attorney-cred-${attorney.id}`} value={attorney.id}>{attorney.attorney_name}</option>
																			))
																		}
																	</Form.Control>
																</Form.Group> */}

																<Row>
																	<Col>
																		<Form.Group as={Col} className="mb-3" controlId="office_with_jurisdiction">
																			<Form.Label className="fs-0">Office with Jurisdiction</Form.Label>
																			<Form.Select
																				name="office_with_jurisdiction"
																				defaultValue={''}
																				value={formData.office_with_jurisdiction || null}
																				onChange={handleChange}
																				required
																				disabled={submitting}
																			>
																				<option value={""}>--Select One--</option>
																				{/* <option value={"Social Security Field Office"}>Social Security Field Office</option> */}
																				<option value={"State Agency"}>State Agency</option>
																				<option value={"Office of Hearing Operations"}>Office of Hearing Operations</option>
																				<option value={"Appeals Council"}>Appeals Council</option>
																				<option value={"Federal Court"}>Federal Court</option>
																			</Form.Select>
																		</Form.Group>

																		<Form.Group className="my-3">
																			<Form.Label>Test mode?</Form.Label> <br />
																			<Form.Check
																				type="radio"
																				inline
																				label="Yes"
																				name="test_mode"
																				value={true}
																				id="test_mode_yes"
																				checked={formData.test_mode === true}
																				onChange={e => {
																					setFormData({
																						...formData,
																						test_mode: e.target.value === 'true' ? true : false
																					})
																				}}
																				disabled={submitting}
																			/>
																			<Form.Check
																				type="radio"
																				inline
																				label="No"
																				name="test_mode"
																				value={false}
																				id="test_mode_no"
																				checked={formData.test_mode === false}
																				onChange={e => {
																					setFormData({
																						...formData,
																						test_mode: e.target.value === 'true' ? true : false
																					})
																				}}
																				disabled={submitting}
																			/>
																		</Form.Group>

																		<hr />

																		<div>
																			{
																				(formData.office_with_jurisdiction === 'State Agency' || formData.office_with_jurisdiction === "Social Security Field Office") &&
																				<>
																					<Alert variant={"warning"}>
																						This case is currently pending at the state agency level. Check to see if a state agency case file is available?
																					</Alert>

																					<div className="py-3 mt-3">
																						<Button
																							variant="outline-danger"
																							onClick={e => handleClose(e)}
																							className="px-4"
																						>
																							Cancel
																						</Button>
																						{' '}
																						<Button
																							variant="primary"
																							type="submit"
																							className="px-4 mx-0"
																							disabled={submitting}
																						>
																							{submitting ? 'Submitting...' : 'Submit'}
																						</Button>
																					</div>
																				</>
																			}

																			{
																				["Office of Hearing Operations", "Appeals Council"].includes(formData.office_with_jurisdiction) &&
																				<>
																					<p>It looks like this case might have an ERE case file. Which kind of file would you like?</p>

																					<Form.Select
																						value={formData.type}
																						name="type"
																						onChange={handleChange}
																						aria-label="Office with Jurisdiction Select"
																						disabled={submitting}
																						required
																					>
																						<option value={2}>All</option>
																						<option value={1}>Exhibited Case File</option>
																						{/* <option value="Exhibits">Exhibited Case File</option>
																		<option value="Unexhibits">Unexhibited Case File</option>
																		<option value="All">Both Exhibited and Unexhibited Case Files</option> */}
																					</Form.Select>



																					<div className="py-3 mt-3">
																						<Button
																							variant="outline-danger"
																							onClick={e => handleClose(e)}
																							className="px-4"
																						>
																							Cancel
																						</Button>
																						{' '}
																						{
																							formData.type !== '' &&
																							<Button
																								variant="primary"
																								type="submit"
																								className="px-4 mx-0"
																								disabled={submitting}
																							>
																								{submitting ? 'Submitting...' : 'Submit'}
																								Submit
																							</Button>
																						}

																					</div>
																				</>
																			}

																			{
																				["Federal Court"].includes(formData.office_with_jurisdiction) &&
																				<>
																					<Alert variant={"warning"}>
																						It looks like this case might be pending in Federal Court. You can access <a href="https://www.uscourts.gov/court-records" target="_blank">federal court cases here</a>.
																					</Alert>

																					<Button
																						variant="outline-danger"
																						onClick={e => handleClose(e)}
																						className="px-4"
																					>
																						Cancel
																					</Button>
																				</>
																			}
																		</div>
																	</Col>
																</Row>
															</>
														)
												}
											</>
									}

								</>
						}
					</Modal.Body>
					<Modal.Footer className="bg-light px-card border-top-0">

					</Modal.Footer>
				</Form>
			</Modal>


		</>
	);
};

export default CreateCaseFileRequest;