import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';
import Table from 'react-bootstrap/Table';
import AppContext, { AuthContext } from 'context/Context';
import { accountServiceAPI, serviceAPI } from 'utils/api/fulcrum-api';
import { Form, ListGroup } from 'react-bootstrap';
import SignServiceAgreementModal from './SignServiceAgreementModal';
import Service from './Service';
import { toast } from 'react-toastify';

const Services = ({ account }) => {

  // Get services
  const [services, setServices] = useState([]);
  const [accountServices, setAccountServices] = useState([]);
  const [error, setError] = useState(false);


  const getServices = async () => {
    try {
      let result = await serviceAPI.searchMyService({});
      console.log(result);
      if (!result?.data?.rows) {
        throw new Error('No services found');
      }

      setServices(result.data.rows);

    } catch (error) {
      console.log(error);
      setError(true);
    }
  };

  // Get Account Services
  const getAccountServices = async () => {
    try {
      let result = await accountServiceAPI.searchMyAccountService({ account_id: account.id });
      if (!result?.data?.rows) {
        throw new Error('No account services found');
      }
      setAccountServices(result.data.rows.map(accountService => {
        return {
          ...accountService,
          service: services.find(service => service.id === accountService.service_id),
          service_agreements: JSON.parse(accountService.service_agreements || '[]')
        }
      }));
    } catch (error) {
      console.log(error);
      setError(true);
    }
  };

  // Refresh Account Services
  const refreshAccountServices = async () => {
    getServices();
    getAccountServices();
  };


  useEffect(() => {
    getServices();
    getAccountServices();
  }, []);

  return (
    <>
      {
        error
          ?
          <p>Unable to load services.</p>
          :
          services.map((service, index) => (
            <Service
              key={index}
              account={account}
              service={service}
              refreshAccountServices={refreshAccountServices}
              accountService={accountServices.find(accountService => accountService.service_id === service.id)}
            />
          ))
      }
    </>
  );
}


export default Services;