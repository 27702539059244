import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Modal, Row, Col, Alert, ListGroup, Spinner } from 'react-bootstrap';
import { useNavigate, Link } from 'react-router-dom';
import AppContext from 'context/Context';
import { assureAPI, userAPI, accountAPI } from 'utils/api/fulcrum-api';
import GettingStarted from './GettingStarted';
import ScheduleADemoModal from 'components/app/hubspot/ScheduleADemoModal';
import CredentialSetup from './CredentialSetup';
import Finished from './Finished';
import LinkAtlaswareAccount from './LinkAtlaswareAccount';
import TFASetup from './TFASetup';
import { toast } from 'react-toastify';

const AtlaswareOnboardingWizard = ({
	setModalState = () => { },
	user = {},
	refreshParent = (() => { }),
	handleClose = (() => { }),
	callback = (() => { }),
	setScheduleADemoModalState = (() => { }),
	scheduleADemoModalState = false,
	scheduleSessionCallback = (() => { }),
}) => {
	const [credentialInfo, setCredentialInfo] = useState({});
	const [slide, setSlide] = useState(1);
	/*
		SLIDES:
		1: Getting started
		2: 
	*/

	// Check if Assure account has been linked
	const checkForLinkedAssureAccount = async () => {
		try {


		} catch (err) {
			console.error(err);
			set
			toast.error('There was an error checking for linked Assure account.');
		}
	}

	const [formState, setFormState] = useState({
		attorney_name: '',
		ere_username: '',
		ere_password: '',
		user_firm_id: '',
		forward_phone_number: '',
		forward_email: '',
		forward_numbers: [],
		forward_emails: [],
	});

	// Render current slide
	const renderSlide = () => {
		switch (slide) {
			case 1:
				return (
					<LinkAtlaswareAccount
						slide={slide}
						setSlide={setSlide}
						user={user}
						setModalState={setModalState}
						setScheduleADemoModalState={setScheduleADemoModalState}
						formState={formState}
						setFormState={setFormState}
						refreshParent={refreshParent}
						callback={callback}
						handleClose={handleClose}
						credentialInfo={credentialInfo}
						setCredentialInfo={setCredentialInfo}
					/>
				);
			case 2:
				return (
					<GettingStarted
						slide={slide}
						setSlide={setSlide}
						user={user}
						setModalState={setModalState}
						setScheduleADemoModalState={setScheduleADemoModalState}
						formState={formState}
						setFormState={setFormState}
						refreshParent={refreshParent}
						handleClose={handleClose}
						credentialInfo={credentialInfo}
						setCredentialInfo={setCredentialInfo}
					/>
				);
			case 3:
				return (
					<CredentialSetup
						slide={slide}
						setSlide={setSlide}
						user={user}
						setModalState={setModalState}
						setScheduleADemoModalState={setScheduleADemoModalState}
						formState={formState}
						setFormState={setFormState}
						refreshParent={refreshParent}
						handleClose={handleClose}
						credentialInfo={credentialInfo}
						setCredentialInfo={setCredentialInfo}
					/>
				);
			case 4:
				return (
					<TFASetup
						slide={slide}
						setSlide={setSlide}
						user={user}
						setModalState={setModalState}
						setScheduleADemoModalState={setScheduleADemoModalState}
						formState={formState}
						setFormState={setFormState}
						refreshParent={refreshParent}
						handleClose={handleClose}
						credentialInfo={credentialInfo}
						setCredentialInfo={setCredentialInfo}
					/>
				);
			case 5:
				return (
					<Finished
						slide={slide}
						setSlide={setSlide}
						user={user}
						setModalState={setModalState}
						setScheduleADemoModalState={setScheduleADemoModalState}
						formState={formState}
						setFormState={setFormState}
						refreshParent={refreshParent}
						handleClose={handleClose}
						credentialInfo={credentialInfo}
						setCredentialInfo={setCredentialInfo}
					/>
				);
			case 6:
				return (
					<div>
						<h5>Missing Assure Account</h5>
						<hr />
						<p>
							You do not have an Assure account linked to your Fulcrum account. Please link your Assure account before continuing.
						</p>

					</div>
				);
			default:
				toast.error("Something went wrong. Please try again.");
				handleClose();
				break;
		}
	}



	return (
		<div>
			<div>
				{
					slide > 1 &&
					<Button
						variant="link"
						onClick={() => setSlide(slide - 1)}
					>
						Back
					</Button>
				}
				{
					slide < 5 &&
					<Button
						variant="link"
						onClick={() => setSlide(slide + 1)}
					>
						Skip
					</Button>
				}
				{slide}
			</div>
			{renderSlide()}

			<ScheduleADemoModal
				modalState={scheduleADemoModalState}
				setModalState={setScheduleADemoModalState}
				title={"Schedule an Atlasware Onboarding Session"}
				message={"Schedule a session with Assure to assist you in setting you up with your Atlasware onboarding process."}
				callBack={scheduleSessionCallback}
			/>
		</div>
	);
};

export default AtlaswareOnboardingWizard;