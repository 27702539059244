import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ListGroup from 'react-bootstrap/ListGroup';
import IconButton from 'components/common/IconButton';
import { toast } from 'react-toastify';
import { claimantHospitalVisitAPI } from 'utils/api/fulcrum-api';
import HospitalRow from './HospitalRow';

const Hospitals = ({ claimant, setClaimant }) => {
  const [loading, setLoading] = useState(true);
  const [hospitals, setHospitals] = useState([]);

  // Get the hospitals
  const getClaimantHospitalVisits = async () => {
    return;// @todo - remove this line
    try {
      let result = await claimantHospitalVisitAPI.getAllClaimantHospitalVisits(claimant.id);
      if (!result?.data) {
        throw new Error('No claimant hospitals data returned');
      }
      setLoading(false);
      setHospitals(result.data);
    } catch (error) {
      console.error(error);
    }
  };

  // Add a new hospital
  const addHospitals = async () => {
    try {
      let result = await claimantHospitalVisitAPI.createClaimantHospitalVisit(claimant.id, {
        hospital: '',
        phone: '',
        address: '',
        date_of_admission: null,
        duration_of_admission: '',
      });
      if (!result?.data) {
        throw new Error('No data returned from server');
      }
      setHospitals([...hospitals, result.data]);
      toast.success('Hospital added', { theme: 'colored' });
    } catch (error) {
      console.log(error);
      toast.error('Error adding hospital', { theme: 'colored' });
    };
  };

  useEffect(() => {
    getClaimantHospitalVisits();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div id="claimant-current-employer-container" className="my-3">
      <h5 className="d-inline">Hospitals</h5>

      <IconButton
        className="me-2 mb-1 float-end"
        variant="falcon-default"
        size="sm"
        icon="plus"
        transform="shrink-3"
        onClick={addHospitals}
      >
        Add
      </IconButton>
      <hr />
      <ListGroup>
        {hospitals.map((hospital, index) => (
          <ListGroup.Item key={hospital.id}>
            <HospitalRow
              hospital={hospital}
              index={index}
              claimant={claimant}
              setClaimant={setClaimant}
              getClaimantHospitalVisits={getClaimantHospitalVisits}
            />
          </ListGroup.Item>
        ))}
      </ListGroup>
    </div>
  );
};

Hospitals.propTypes = {
  claimant: PropTypes.object,
  setClaimant: PropTypes.func
}

export default Hospitals;