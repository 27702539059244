import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { userAPI } from 'utils/api/fulcrum-api';
import { removeEmptyFromObj, formatPhone } from 'utils/functions';

import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import CustomAdvanceTable from 'components/common/advance-table/CustomAdvanceTable';
import CustomAdvanceTablePagination from 'components/common/advance-table/CustomAdvanceTablePagination';
import UserTableHeader from './UserTableHeader';
import UserFilters from './UserFilters';
import CreateUserForm from './CreateUserForm';

const defaultColumns = [
	{
		accessor: 'name',
		Header: 'Name',
		headerProps: { className: 'pe-1' },
		cellProps: {
			className: 'py-2'
		},
	},
	// {
	// 	accessor: 'first_name',
	// 	Header: 'First Name',
	// 	headerProps: { className: 'pe-1' },
	// 	cellProps: {
	// 		className: 'py-2'
	// 	}
	// },
	// {
	// 	accessor: 'last_name',
	// 	Header: 'Last Name',
	// 	headerProps: { className: 'pe-1' },
	// 	cellProps: {
	// 		className: 'py-2'
	// 	}
	// },
	{
		accessor: 'user_type',
		Header: 'User Type',
		headerProps: { className: 'pe-1' },
		cellProps: {
			className: 'py-2'
		}
	},
	{
		accessor: 'user_status',
		Header: 'Status',
		headerProps: { className: 'pe-1' },
		cellProps: {
			className: 'py-2'
		}
	},
	{
		accessor: 'admin',
		Header: 'Admin',
		headerProps: { className: 'pe-1' },
		cellProps: {
			className: 'py-2'
		}
	},
	{
		accessor: 'email',
		Header: 'Email',
		headerProps: { className: 'pe-1' },
		cellProps: {
			className: 'py-2'
		}
	},
	{
		accessor: 'phone',
		Header: 'Phone',
		Cell: rowData => {
			const { phone } = rowData.row.original;
			return <a href={`tel:${formatPhone(phone)}`}>{formatPhone(phone)}</a>;
		}
	},
	{
		accessor: 'created_at',
		Header: 'Created',
		headerProps: { className: 'pe-1' },
		cellProps: {
			className: 'py-2'
		}
	},
	{
		accessor: 'last_login_timestamp',
		Header: 'Last Login',
		headerProps: { className: 'pe-1' },
		cellProps: {
			className: 'py-2'
		}
	},
	// {
	// 	accessor: 'address',
	// 	Header: 'Billing Address',
	// 	headerProps: { style: { minWidth: '200px' }, className: 'ps-5' },
	// 	cellProps: { className: 'ps-5' }
	// },
	// {
	// 	accessor: 'joined',
	// 	Header: 'Joined'
	// },
];

const UserTable = ({ allowedColumns = [], tableHeaderLabel = "Users", columns = defaultColumns, defaultSearchParams = {}, requiredSearchParams = {}, account = {}, siteAdminArea = false }) => {
	const navigate = useNavigate();
	const [createUserModal, setCreateUserModal] = useState(false);
	const [showFindAClaimantModal, setShowFindAClaimantModal] = useState(false);
	const [userData, setUserData] = useState([]);
	const [Pages, setPages] = useState([1]);
	const [activePage, setActivePage] = useState(1);
	const [perPage, setPerPage] = useState(10);
	const [userDataCount, setUserDataCount] = useState(0);
	const [searching, setSearching] = useState(true);
	const [lastQuery, setLastQuery] = useState({});
	const [searchParams, setSearchParams] = useState({ ...defaultSearchParams, ...requiredSearchParams });
	let defaultSort = ["created_at", "ASC"];
	const [appliedSortOrders, setAppliedSortOrders] = useState([defaultSort]);

	const handleBulkActions = (actionObj = {}, selectedRows = [], selectedIds = []) => {
		let userItem = {};
		if (Array.isArray(selectedRows) && selectedRows.length === 1) {
			userItem = selectedRows[0];
		}
		let { bulk_action } = actionObj || {};
		switch (bulk_action) {
			case 'clear filters':
				clearSearchParams();
				break;
			case 'new user':
				setCreateUserModal(true);
				break;
			default:
				console.log("@todo handleBulkActions", actionObj, "selectedRows", selectedRows, "selectedIds", selectedIds, "userItem", userItem);
				// refreshLastQuery();
				break;
		}
	};

	const formatData = (data = []) => {
		let adjustedData = data.map(u => {
			return {
				...u,
				user_status: `${u?.user_status?.status || 'Unknown'}`,
				user_type: `${u.assigned_user_types.filter(t => t.user_type_id === "REPRESENTATIVE").length > 0 ? 'REPRESENTATIVE' : 'STAFF'}`,
				admin: `${u.assigned_user_types.filter(t => t.user_type_id === "ADMIN").length > 0 ? 'Admin' : ''}`,
				name: <span className="text-primary cursor-pointer" onClick={() => { handleRowClick(u.id) }}>{u?.first_name || ''} {u?.last_name || ''}</span>,
				created_at: moment(u.createdAt).format('MM/DD/YYYY'),
				last_login_timestamp: u?.last_login_timestamp ? moment(u.last_login_timestamp).format('MM/DD/YYYY hh:mm A') : '',
				business_name: u?.account?.business_name || '',
			}
		});
		setUserData(adjustedData);
	};

	// Navigate to user detail page
	const handleRowClick = (id) => {
		if (siteAdminArea) {
			navigate(`/admin/user-details/${id}`);
		} else {
			navigate(`/user/profile/${id}`);
		}
	};

	const headerClickFn = (id, previousSortBy) => {
		if (!id) {
			return;
		}
		let foundIndex = appliedSortOrders.findIndex(s => s[0] === id);
		let sortBy = !previousSortBy ? "ASC" : previousSortBy === "asc" ? "DESC" : '';

		if (!sortBy) { // Not Sorted so remove clicked sort item 
			if (foundIndex > -1) {
				setAppliedSortOrders((prev) => {
					let newSortOrders = [...prev];
					newSortOrders.splice(foundIndex, 1); // remove clicked sort item
					return newSortOrders;
				});
			}
		} else { // Sort by Ascending or Descending 
			let newSort = [id, sortBy];
			if (foundIndex > -1) { // replaces existing 
				setAppliedSortOrders((prev) => {
					let newSortOrders = [...prev];
					newSortOrders[foundIndex] = newSort;
					return newSortOrders;
				});
			} else { // inserts 
				setAppliedSortOrders((prev) => [...prev, newSort]);
			}
		}
	};

	const userSearchHandler = async (params = {}, saveLastQuery = true) => {
		setSearching(true);
		try {
			params = removeEmptyFromObj(params);
			params.page = activePage;
			params.limit = perPage;
			params.navSize = 4; // How many buttons to show for navigation
			params.order = appliedSortOrders; // Example [['last_last', 'asc'],['first_name','asc'], ['id','asc']]
			let { error, response, data } = await userAPI.searchUsers(params);
			if (error) {
				if (typeof response?.error === 'string') {
					throw new Error(`${error} - ${response.error}`);
				} else if (typeof response === 'string') {
					throw new Error(`${error} - ${response}`);
				}
				throw new Error(`${error} - There was an error searching users.`);
			}

			let { count = 0, rows = [], pages = [] } = data || {}; // data = { count = 0, rows = [], limit, pageCount, page, pages = [1,2,3,10] } 
			if (!rows || (Array.isArray(rows) && rows.length === 0)) {
				formatData([]);
				setActivePage(1); // Make sure we don't get stuck loading nothing
				// throw new Error('No user data found');
			} else {
				formatData(rows);
				setPages(pages);
			}

			formatData(rows);
			setUserDataCount(count);
			setPages(pages);
			if (saveLastQuery) {
				setLastQuery({ params, ...requiredSearchParams });
			}
		} catch (error) {
			console.log("Error searching user data", error);
			setUserData([]);
			setUserDataCount(0);
			setActivePage(1);
		}
		setSearching(false);
	};

	// Refresh last query
	const refreshLastQuery = async () => {
		await userSearchHandler(lastQuery, false);
	};

	// Clear search params and reset to default
	const clearSearchParams = () => {
		if (JSON.stringify(searchParams) !== JSON.stringify({ ...defaultSearchParams, ...requiredSearchParams })) {
			setSearchParams({ ...defaultSearchParams, ...requiredSearchParams });
		}
		if (JSON.stringify(appliedSortOrders) !== JSON.stringify([defaultSort])) {
			setAppliedSortOrders([defaultSort]);
		}
		if (activePage !== 1) {
			setActivePage(1);
		}
		if (perPage !== 10) {
			setPerPage(10);
		}
	};

	useEffect(() => {
		userSearchHandler(searchParams);
	}, [searchParams, activePage, perPage, appliedSortOrders]);

	return (
		<div>
			<AdvanceTableWrapper
				columns={columns}
				data={userData}
				// selection
				sortable
				pagination
				perPage={perPage}
				setPerPage={setPerPage}
			>
				<Card className="mb-3 shadow-none border">
					<Card.Header>
						<UserTableHeader
							table
							label={tableHeaderLabel}
							handleBulkActions={handleBulkActions}
							setCreateUserModal={setCreateUserModal}
							searchParams={searchParams}
							setSearchParams={setSearchParams}
							showClearFilters={JSON.stringify(searchParams) !== JSON.stringify({ ...defaultSearchParams, ...requiredSearchParams })}
						/>
						<UserFilters
							searchParams={searchParams}
							setSearchParams={setSearchParams}
							fields={[
								"account_business_name",
								"first_name",
								"last_name",
								"email",
								"user_status_id"
							]}
							clearSearchParams={clearSearchParams}
						/>
					</Card.Header>
					<Card.Body className="p-0">
						{
							searching
								?
								<p>Searching users....</p>
								:
								<>
									<CustomAdvanceTable
										table
										headerClickFn={headerClickFn}
										appliedSortOrders={appliedSortOrders}
										headerClassName="bg-200 text-900 text-nowrap align-middle"
										rowClassName="align-middle white-space-nowrap"
										tableProps={{
											size: 'sm',
											striped: true,
											className: 'fs--1 mb-0'
										}}
									/>
								</>
						}
					</Card.Body>
					<Card.Footer>
						<CustomAdvanceTablePagination
							table
							Pages={Pages}
							activePage={activePage}
							setActivePage={setActivePage}
							totalRecords={userDataCount}
						/>
					</Card.Footer>
				</Card>
			</AdvanceTableWrapper>

			<CreateUserForm
				createUserModal={createUserModal}
				setCreateUserModal={setCreateUserModal}
				userData={userData}
				setUserData={setUserData}
				refreshParent={refreshLastQuery}
				account_id={account?.id || null}
				siteAdminArea={siteAdminArea}
			/>
		</div>
	);
};

UserTable.propTypes = {
	allowedColumns: PropTypes.array,
	tableHeaderLabel: PropTypes.string,
	columns: PropTypes.array,
	defaultSearchParams: PropTypes.object,
	requiredSearchParams: PropTypes.object,
	account: PropTypes.object,
	siteAdminArea: PropTypes.bool
};

export default UserTable;