import React, { useContext, useState, useRef, useCallback } from 'react';
import { Button, Form, Modal, Table, Alert, Spinner } from 'react-bootstrap';
// import { v4 as uuid } from 'uuid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import AppContext from 'context/Context';
import { documentAPI, accountAPI } from 'utils/api/fulcrum-api';
import ClaimantQuickFind from 'components/claimants/find-a-claimant/ClaimantQuickFind';
import { useDropzone } from 'react-dropzone';
import Flex from 'components/common/Flex';
import cloudUpload from 'assets/img/icons/cloud-upload.svg';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import auth from 'utils/auth';

const UploadDocumentForm = ({
  modalState,
  setModalState,
  account_id = auth.getProfile()?.data?.account_id || null,
  refreshParent = () => { },
  defaultClaimants = [],
  siteAdminArea = false
}) => {
  const {
    config: { isDark }
  } = useContext(AppContext);
  const defaultFormData = {
    document_type_id: null,
    document_status_id: null,
    title: '',
    description: '',
    due_date: null,
    file: null,
    account_id: account_id || auth.getProfile().data.account_id
  };
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [accountData, setAccountData] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [documentTypes, setDocumentTypes] = useState([]);
  const [doucmentStatuses, setDocumentStatuses] = useState([]);
  const [formData, setFormData] = useState(defaultFormData);
  const [claimants, setClaimants] = useState(defaultClaimants);
  const [errorMessages, setErrorMessages] = useState([]);

  const formRef = useRef(null);

  const onDrop = useCallback(acceptedFiles => {
    setSelectedFiles([...selectedFiles, ...acceptedFiles])
  }, [selectedFiles])

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    onDrop,
  })

  const files = selectedFiles.map(file => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));


  const removeAll = () => {
    setSelectedFiles([])
  }

  const handleChange = ({ target }) => {
    let { name, value } = target;
    let additionalValues = {};

    if (['document_type_id'].includes(name)) {
      additionalValues = {
        title: documentTypes.find((docType) => docType.id === value)?.type || ''
      }
    }

    setFormData({ ...formData, ...additionalValues, [name]: value });
  };


  const handleSubmit = async e => {
    e.preventDefault();

    let errors = [];
    if (!formData?.document_type_id) {
      toast.error('Please select a document type.', { theme: 'colored' });
      errors.push('Please select a document type.');
    }

    if (!formData?.title) {
      toast.error('Please enter title.', { theme: 'colored' });
      errors.push('Please enter title.');
    }

    if (!acceptedFiles.length) {
      toast.error('Please select a file.', { theme: 'colored' });
      errors.push('Please select a file.');
    }

    if (!formData?.account_id) {
      toast.error('Please select an account.', { theme: 'colored' });
      errors.push('Please select an account.');
    }

    if (errors.length) {
      setErrorMessages(errors);
      return;
    }

    setIsUploading(true);

    let formDataObject = new FormData(formRef.current);

    try {
      for (let i = 0; i < acceptedFiles.length; i++) {
        let file = acceptedFiles[i];
        file.originalFilename = file.path;
        formDataObject.append("files", file);
      }


      // Append claimants
      formDataObject.append('claimants', JSON.stringify(claimants));

      const response = await documentAPI.uploadDocument(formData.account_id, formDataObject);

      Swal.fire({
        icon: 'success',
        text: 'Success.',
        showConfirmButton: true,
        background: false,
        backdrop: false
      });
    } catch (error) {
      Swal.fire({
        icon: 'error',
        text: error,
        showConfirmButton: true,
        background: false,
        backdrop: false
      });
      return false;
    }
    setIsUploading(false);
    refreshParent();
    handleClose();
  };

  // Handle Claimant Selection// Handle Claimant Selection
  const handleClaimantSelection = (selectedClaimants = []) => {
    // Check if the claimant is already in the list
    console.log(selectedClaimants);
    let approvedClaimants = [];


    for (let i = 0; i < selectedClaimants.length; i++) {
      let allowClaimant = true;
      for (let j = 0; j < claimants.length; j++) {
        if (selectedClaimants[i].id === claimants[j].id) {
          allowClaimant = false
        }
      }
      if (allowClaimant) {
        approvedClaimants.push(selectedClaimants[i]);
      }
    }

    setClaimants([...claimants, ...approvedClaimants]);
  };

  // Get related data @TODO: Move this to a context
  const getRelatedData = async () => {
    try {
      let docTypeResults = await documentAPI.searchDocumentTypes();
      if (!docTypeResults?.data?.rows) {
        throw new Error('Unable to get documents types.');
      }

      let docStatusResults = await documentAPI.searchDocumentStatuses();
      if (!docStatusResults?.data?.rows) {
        throw new Error('Unable to get document statuses.');
      }

      setDocumentTypes(docTypeResults.data.rows);
      setDocumentStatuses(docStatusResults.data.rows);
    } catch (error) {
      alert(error);
    }
  };

  // Get Account Data
  const getAccountData = async () => {
    try {
      let result = await accountAPI.searchAllAccounts({});
      if (!result?.data?.rows) {
        throw new Error('Unable to get account data.');
      }

      setAccountData(result.data.rows);
    } catch (error) {
      console.log(error)
      toast.error('Unable to load account data.', { theme: 'colored' });
      handleClose();
    }
  }

  // Handle Show
  const handleShow = () => {
    removeAll();
    setClaimants(defaultClaimants);
    setFormData(defaultFormData);
    getRelatedData();

    if (siteAdminArea) {
      getAccountData();
    }
  };

  // Handle Close
  const handleClose = () => {
    removeAll();
    setFormData(defaultFormData);
    setClaimants([]);
    setModalState(false);
  };


  return (
    <Modal
      show={modalState}
      onHide={handleClose}
      onShow={handleShow}
      contentClassName="border"
      backdrop='static'
    >
      <Form ref={formRef} onSubmit={handleSubmit}>
        <Modal.Header
          closeButton
          closeVariant={isDark ? 'white' : undefined}
          className="bg-light px-card border-bottom-0"
        >
          <Modal.Title as="h5">Upload A Document</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-card">
          <Alert variant="danger" show={errorMessages.length}>
            <ul className="mb-0">
              {errorMessages.map((error, index) => (
                <li key={index}>{error}</li>
              ))}
            </ul>
          </Alert>
          {
            siteAdminArea && setAccountData.length &&
            <Form.Group className="mb-3" controlId="account_id">
              <Form.Label className="fs-0">Account</Form.Label>
              <Form.Select
                disabled={isUploading}
                name="account_id"
                size="sm"
                defaultValue={""}
                value={formData.account_id || null}
                onChange={handleChange}
                required
              >
                <option value="" disabled={true}>--Select One--</option>
                {
                  accountData.map((account, index) => {
                    return <option key={index} value={account?.id || null}>{account?.business_name || ''}</option>
                  })
                }
              </Form.Select>
            </Form.Group>
          }

          <Form.Group className="mb-3" controlId="document_type_id">
            <Form.Label className="fs-0">Document Type</Form.Label>
            <Form.Select
              name="document_type_id"
              value={formData.document_type_id}
              onChange={handleChange}
              required
              disabled={isUploading}
            >
              <option value={""}>--Select One--</option>
              {Array.isArray(documentTypes) && documentTypes.map((docType, index) => (
                <option key={index} value={docType.id}>{docType.type}</option>
              ))}
            </Form.Select>
          </Form.Group>

          <Form.Group className="mb-3" controlId="title">
            <Form.Label className="fs-0">Title</Form.Label>
            <Form.Control
              type="text"
              name="title"
              value={formData.title}
              required
              onChange={handleChange}
              disabled={isUploading}
            />
          </Form.Group>

          <div className="py-3 border-bottom border-top my-3">

            <p className="fw-bold">Assign Claimants(s)
              {
                !isUploading && (
                  <ClaimantQuickFind
                    buttonLabel={'Search Claimants'}
                    buttonVariant={'link'}
                    handleClaimantSelection={handleClaimantSelection}
                    requiredSearchParams={{ account_id: formData.account_id }}
                    defaultSearchParams={{}}
                  />
                )
              }
            </p>


            {
              (claimants.length) > 0 ? (
                <>
                  {
                    claimants.length > 25 ? (
                      <>
                        <p>More than 25 claimants have been selected.

                          <Button
                            variant="link"
                            className="p-0 float-end text-danger"
                            disabled={isUploading}
                            onClick={() => {
                              setClaimants([]);
                            }}
                          >
                            <FontAwesomeIcon className="text-danger" icon={faTrash} /> Clear
                          </Button>
                        </p>
                      </>
                    ) :
                      <Table striped bordered size="sm">
                        <thead>
                          <tr>
                            <th>Claimant</th>
                          </tr>
                        </thead>
                        <tbody>
                          {claimants.map((claimant, index) => (
                            <tr key={index}>
                              <td>
                                {claimant.first_name} {claimant.last_name}
                                <Button
                                  variant="link"
                                  className="p-0 float-end"
                                  onClick={() => {
                                    setClaimants(claimants.filter((c) => c.id !== claimant.id));
                                  }}
                                >
                                  <FontAwesomeIcon className="text-danger" icon={faTrash} />
                                </Button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                  }

                </>
              ) : <p className="text-danger">No claimants selected.</p>
            }
          </div>

          {
            !isUploading && (
              <div {...getRootProps({ className: `dropzone-area py-6 ${isUploading && 'd-none'}` })}>
                <input {...getInputProps({ multiple: false })} />
                <Flex justifyContent="center">
                  <img src={cloudUpload} alt="" width={25} className="me-2" />
                  <p className="fs-0 mb-0 text-700">Drop your file here</p>
                </Flex>
              </div>
            )
          }
          <div className="mt-3">
            {selectedFiles.length > 0 && (
              <>
                <h6>File</h6>
                <ul>{files}</ul>
              </>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer className="bg-light px-card border-top-0">
          {
            isUploading ? (
              <Button
                variant="primary"
                className="px-4 mx-0"
                type="button"
                disabled
              >
                <Spinner animation="border" size="sm" className="me-2" />
                Uploading...
              </Button>
            ) : (
              <Button
                variant="primary"
                className="px-4 mx-0"
                type="submit"
              >
                Upload Document
              </Button>
            )
          }
        </Modal.Footer>
      </Form>
    </Modal>
  );
};


export default UploadDocumentForm;