import React, { useState, useContext } from 'react';
import Swal from 'sweetalert2';
import { Button, Form, Modal, Table } from 'react-bootstrap';
import { userAPI } from 'utils/api/fulcrum-api';
import { toast } from 'react-toastify';

const PrimaryRepSearch = ({ account_id, modalState, setModalState = () => { }, primaryRepSelectionCallBack = () => { }, displayType = "Modal" }) => {
	const [foundRepresentatives, setFoundRepresentatives] = useState([]);
	const [loading, setLoading] = useState(true);

	// Search Represenatives
	const searchRepresentatives = async () => {
		try {
			let result = await userAPI.searchUsersByUserType('REPRESENTATIVE', { account_id });
			if (!result?.data) {
				throw 'Error searching for representatives';
			}
			setFoundRepresentatives(result?.data);
		} catch (error) {
			console.log(error);
			toast.error(error, { theme: 'colored' });
			handleClose();
		}
		setLoading(false);
	};

	// Handle Modal Show
	const handleShow = () => {
		setLoading(true);
		searchRepresentatives();
	};

	// Handle Modal Close
	const handleClose = () => {
		setModalState(false);
		setLoading(true);
		setFoundRepresentatives([]);
	};


	// Handle Primary Rep Select
	const handlePrimaryRepSearchSelect = async (rep) => {
		handleClose();
		primaryRepSelectionCallBack(rep);
	};

	return (
		<>
			{
				displayType !== "Modal"
					?
					<>
						<Form.Select
							aria-label="Primary Representative"
							onChange={(e) => {
								handlePrimaryRepSearchSelect(e.target.value);
							}}
						>
							<option value="">Select Primary Representative</option>
							{
								foundRepresentatives.map((rep, index) => {
									return (
										<option key={index} value={rep._id}>{rep.firstName} {rep.lastName}</option>
									);
								})
							}
						</Form.Select>
					</>
					:
					<Modal
						show={modalState}
						onShow={handleShow}
						onHide={handleClose}
						backdrop="static"
						size="lg"
					>
						<Modal.Header closeButton>
							<Modal.Title>Primary Representative Search</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							{
								loading
									?
									<div>
										<h5>Loading...</h5>
									</div>
									:
									foundRepresentatives.length > 0
										?
										<div>
											<h5>Found Representatives</h5>
											<Table striped bordered hover>
												<thead>
													<tr>
														<th>Name</th>
														<th>Rep ID</th>
														<th></th>
													</tr>
												</thead>
												<tbody>
													{
														foundRepresentatives.map((rep, index) => {
															return (
																<tr key={index}>
																	<td>{rep.first_name} {rep.last_name}</td>
																	<td>{rep.representative_id}</td>
																	<td>
																		<Button variant="link" onClick={(e) => { handlePrimaryRepSearchSelect(rep) }} value={rep.id}>Select</Button>
																	</td>
																</tr>
															)
														})
													}
												</tbody>
											</Table>
										</div>
										:
										<div>
											<h5>No Representatives Found</h5>
										</div>
							}
						</Modal.Body>
						<Modal.Footer>
							<Button variant="secondary" onClick={handleClose}>
								Close
							</Button>
						</Modal.Footer>
					</Modal>
			}

		</>
	);
};

export default PrimaryRepSearch;