import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  Button,
  Card,
  Col,
  OverlayTrigger,
  Row,
  Tooltip,
  Form
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import IconButton from 'components/common/IconButton';

import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import moment from 'moment';

import Swal from 'sweetalert2';

// import events from 'data/calendar/events';
const events = [];

import AddScheduleModal from './AddScheduleModal';
import CalendarEventModal from './CalendarEventModal';
import DropdownFilter from 'components/common/DropdownFilter';
import AppContext from 'context/Context';
import { v4 as uuid } from 'uuid';

import { reservationAPI } from 'utils/api/fulcrum-api';
import { toast } from 'react-toastify';

const Calendar = () => {

  const {
    config: { isRTL }
  } = useContext(AppContext);

  const calendarRef = useRef();
  const [title, setTitle] = useState('');
  const [calendarApi, setCalendarApi] = useState({});
  const [currentFilter, setCurrentFilter] = useState('Month View');
  const [isOpenScheduleModal, setIsOpenScheduleModal] = useState(false);
  const [isOpenEventModal, setIsOpenEventModal] = useState(false);

  const [modalEventContent, setModalEventContent] = useState(
    {
      id: '',
      title: '',
      start: new Date(),
      end: new Date(),
      description: '',
      location: '',
      organizer: '',
      className: '',
      allDay: false,
      schedules: []
    }
  );

  const [scheduleStartDate, setScheduleStartDate] = useState();
  const [scheduleEndDate, setScheduleEndDate] = useState();
  const [invokeSearch, setInvokeSearch] = useState(Date.now());

  const [formData, setFormData] = useState({ selectedCalendar: 1 });

  const eventList = events.reduce(
    (acc, event) =>
      event.schedules
        ? acc.concat(event.schedules.concat(event))
        : acc.concat(event),
    []
  );


  const handleInputChange = (event) => {
    let { name, value } = event.target;
    setInvokeSearch(Date.now());
    setFormData({ ...formData, [name]: value });
  };

  const reservationApiSearch = async (e) => {
    // console.log("reservationApiSearch");

    setInitialEvents([]);

    /*

      https://stackoverflow.com/questions/6525538/convert-utc-date-time-to-local-date-time

      var utcDate = '2011-06-29T16:52:48.000Z';  // ISO-8601 formatted date returned from server
      var localDate = new Date(utcDate);
      The localDate will be in the right local time which in my case would be two hours later (DK time).

      You really don't have to do all this parsing which just complicates stuff, as long as you are consistent with what format to expect from the server.

      var date = new Date('6/29/2011 4:52:48 PM UTC');
      // or test      date = new Date('2011-06-29T16:52:48.000Z');
      date.toString() // "Wed Jun 29 2011 09:52:48 GMT-0700 (PDT)"  ((Pacific Daylight Time))  (based on the timezone of the browser) automatically converted to local time

      var date = new Date('6/29/2011 4:52:48 PM UTC');
      // or test      date = new Date('2011-06-29T16:52:48.000Z');
      date.toString() // "Wed Jun 29 2011 10:52:48 GMT-0600 (MST)"  ((Mountain Standard Time))  (based on the timezone of the browser) automatically converted to local time

 */

    e?.preventDefault();

    let searchPayload = {
      // "id": "0f71d1ba-c2f1-4f95-8259-d8325e0f01a2",
      // "account_id": 1, // important that the backend sets this through session login data
      // "calendar_id": formData?.selectedCalendar || 1,
      // "user_id": 2, // important that the backend sets this through session login data
      // "title": "title",
      // "description": "description",
      // "organizer": "organizer",
      // "location": "location",
      // "label": "label",
      // "email": "s@p.com",
      // "from_date_time": "2022-10-30T19:05:15.659Z",
      // "to_date_time": "2022-10-30T19:05:15.659Z",
      // "all_day": 1,
      // "schedules": [],
      "limit": 1000,
      "offset": 0,
      "search_type": "and"
    }

    searchPayload.from_date_time = moment(scheduleStartDate || new Date()).subtract(1, 'month').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
    searchPayload.to_date_time = moment(scheduleEndDate || new Date()).add(1, 'month').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');

    // console.log("reservationApiSearch payload:", searchPayload);

    let searchResult;

    try {
      searchResult = await reservationAPI.searchAllReservation(searchPayload);
      // console.log("reservationApiSearch result", searchResult);
    } catch (err) {
      // console.log("reservationApiSearch error", err);
      setInitialEvents([]);
      return;
    }

    let addedArray = [];

    addedArray = (searchResult?.data?.rows || []).reduce((acc, cur) => {
      acc.push({
        id: cur.id,
        title: cur.title,
        ...(cur?.from_date_time && { start: new Date(cur.from_date_time) }),
        ...(cur?.to_date_time && { end: new Date(cur.to_date_time) }),
        allDay: cur.all_day,
        description: cur.description,
        organizer: cur.organizer,
        location: cur.location,
        className: cur.label,
        email: cur.email,
        schedules: cur.schedules
      });
      return acc;
    }, []);

    // setInitialEvents([...initialEvents, ...addedArray]);

    setInitialEvents([...addedArray]);

    // console.log("reservationApiSearch addedArray", addedArray.length);
    // console.log("reservationApiSearch initialEvents", initialEvents.length);

    return searchResult;
  }



  const eventTimeFormat = { // note: possible relevant link https://fullcalendar.io/docs/eventTimeFormat
    hour: 'numeric',
    minute: '2-digit',
    omitZeroMinute: true,
    meridiem: true
  };

  const handleEventClick = async info => {

    // console.log("Calendar Selected Event event:");
    // console.log(JSON.stringify(info?.event));

    // console.log("Calendar Selected Event extendedProps:");
    // console.log(JSON.stringify(info?.event?.extendedProps));

    setModalEventContent(info || {});
    setScheduleStartDate(info?.start || new Date());
    setScheduleEndDate(info?.end || new Date());
    setIsOpenEventModal(true);

    // if (info?.event?.url) {
    //   window.open(info.event.url);
    //   info.jsEvent.preventDefault();
    // } else {
    //   setModalEventContent(info || {});
    //   setScheduleStartDate(info?.start || new Date());
    //   setScheduleEndDate(info?.end || new Date());
    //   if (info?.event?.id) {
    //     // setIsOpenScheduleModal(true); // detail edit
    //     setIsOpenEventModal(true); // detail view
    //   }
    // }

  };

  const [initialEvents, setInitialEvents] = useState([...eventList]);

  const viewName = [
    'Month View',
    'Week View',
    'Day View',
    'List View',
    'Year View'
  ];

  const handleFilter = filter => {
    setCurrentFilter(filter);
    switch (filter) {
      case 'Month View':
        calendarApi.changeView('dayGridMonth');
        setTitle(calendarApi.getCurrentData().viewTitle);
        break;
      case 'Week View':
        calendarApi.changeView('timeGridWeek');
        setTitle(calendarApi.getCurrentData().viewTitle);
        break;
      case 'Day View':
        calendarApi.changeView('timeGridDay');
        setTitle(calendarApi.getCurrentData().viewTitle);
        break;
      case 'List View':
        calendarApi.changeView('listWeek');
        setTitle(calendarApi.getCurrentData().viewTitle);
        break;
      default:
        calendarApi.changeView('listYear');
        setTitle(calendarApi.getCurrentData().viewTitle);
    }
  };

  useEffect(() => {
    setCalendarApi(calendarRef.current.getApi());
  }, []);

  useEffect(() => {
    if (calendarApi) {
      reservationApiSearch();
    }
  }, [invokeSearch]);

  return (
    <>
      <Card>

        <Form.Select
          size="sm"
          as="select"
          hidden
          disabled
          value={formData?.selectedCalendar || 1}
          aria-label="Default select example"
          name='selectedCalendar'
          onChange={handleInputChange}
        >
          <option key={'1'} value={1}>Default Calendar</option>
          {/* <option key={'2'} value={2}>Shared Calendar</option> */}
          {/* <option key={'3'} value={3}>Public Calendar</option> */}
        </Form.Select>

        <Card.Header>
          <Row className="align-items-center gx-0">
            <Col xs="auto" className="d-flex justify-content-end order-md-1">
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip id="nextTooltip">Previous</Tooltip>}
              >
                <Button
                  variant="link"
                  className="icon-item icon-item-sm icon-item-hover shadow-none p-0 me-1 ms-md-2"
                  onClick={() => {
                    calendarApi.prev();
                    setTitle(calendarApi.getCurrentData().viewTitle);
                    // console.log("calendarApi", calendarApi.getCurrentData());
                    // console.log("calendarApi", calendarApi.getCurrentData().currentDate);
                    setScheduleStartDate(calendarApi.getCurrentData().currentDate || new Date());
                    setScheduleEndDate(calendarApi.getCurrentData().currentDate || new Date());
                    setInvokeSearch(Date.now());
                  }}
                >
                  <FontAwesomeIcon icon="arrow-left" />
                </Button>
              </OverlayTrigger>
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip id="previousTooltip">Next</Tooltip>}
              >
                <Button
                  variant="link"
                  className="icon-item icon-item-sm icon-item-hover shadow-none p-0 me-lg-2"
                  onClick={() => {
                    calendarApi.next();
                    setTitle(calendarApi.getCurrentData().viewTitle);
                    // console.log("calendarApi", calendarApi.getCurrentData());
                    // console.log("calendarApi", calendarApi.getCurrentData().currentDate);
                    setScheduleStartDate(calendarApi.getCurrentData().currentDate || new Date());
                    setScheduleEndDate(calendarApi.getCurrentData().currentDate || new Date());
                    setInvokeSearch(Date.now());
                  }}
                >
                  <FontAwesomeIcon icon="arrow-right" />
                </Button>
              </OverlayTrigger>
            </Col>
            <Col xs="auto" className="d-flex justify-content-end order-md-2">
              <h4 className="mb-0 fs-0 fs-sm-1 fs-lg-2">
                {title || `${calendarApi.currentDataManager?.data?.viewTitle}`}
              </h4>
            </Col>
            <Col xs md="auto" className="d-flex justify-content-end order-md-3">
              <Button
                size="sm"
                variant="falcon-primary"
                onClick={() => {
                  calendarApi.today();
                  setTitle(calendarApi.getCurrentData().viewTitle);
                  // console.log("calendarApi", calendarApi.getCurrentData().currentDate);
                  setScheduleStartDate(calendarApi.getCurrentData().currentDate || new Date());
                  setScheduleEndDate(calendarApi.getCurrentData().currentDate || new Date());
                  setInvokeSearch(Date.now());
                }}
              >
                Today
              </Button>
            </Col>
            <Col md="auto" className="d-md-none">
              <hr />
            </Col>
            <Col xs="auto" className="d-flex order-md-0">
              <IconButton
                variant="primary"
                iconClassName="me-2"
                icon="plus"
                // transform="shrink-3"
                size="sm"
                onClick={() => {
                  setModalEventContent({
                    id: '',
                    title: '',
                    start: new Date(),
                    end: new Date(),
                    description: '',
                    location: '',
                    organizer: '',
                    className: '',
                    allDay: false,
                    schedules: []
                  });
                  setIsOpenScheduleModal(!isOpenScheduleModal);
                }}
              >
                Add Schedule
              </IconButton>
            </Col>
            <Col className="d-flex justify-content-end order-md-2">
              <DropdownFilter
                className="me-2"
                filters={viewName}
                currentFilter={currentFilter}
                handleFilter={handleFilter}
                icon="sort"
                right
              />
            </Col>
          </Row>
        </Card.Header>
        <Card.Body className="p-0">
          <FullCalendar
            ref={calendarRef}
            headerToolbar={false}
            plugins={[
              dayGridPlugin,
              timeGridPlugin,
              interactionPlugin,
              listPlugin
            ]}
            initialView="dayGridMonth"
            themeSystem="bootstrap"
            dayMaxEvents={6}
            direction={isRTL ? 'rtl' : 'ltr'}
            height={800}
            stickyHeaderDates={false}
            editable
            selectable
            selectMirror
            select={info => {
              setModalEventContent({
                id: '',
                title: '',
                start: new Date(),
                end: new Date(),
                description: '',
                location: '',
                organizer: '',
                className: '',
                allDay: false,
                schedules: []
              });
              setScheduleStartDate(info?.start || new Date());
              setScheduleEndDate(info?.end || new Date());
              setIsOpenScheduleModal(true);
            }}
            eventTimeFormat={eventTimeFormat}
            eventClick={handleEventClick}
            events={initialEvents || []}
          />
        </Card.Body>
      </Card>

      <AddScheduleModal
        selectedCalendar={formData.selectedCalendar}
        isOpenScheduleModal={isOpenScheduleModal}
        setIsOpenScheduleModal={setIsOpenScheduleModal}
        initialEvents={initialEvents}
        setInitialEvents={setInitialEvents}
        scheduleStartDate={scheduleStartDate}
        scheduleEndDate={scheduleEndDate}
        setScheduleStartDate={setScheduleStartDate}
        setScheduleEndDate={setScheduleEndDate}
        modalEventContent={modalEventContent}
        setModalEventContent={setModalEventContent}
      />

      <CalendarEventModal
        isOpenEventModal={isOpenEventModal}
        setIsOpenEventModal={setIsOpenEventModal}
        modalEventContent={modalEventContent}
        setModalEventContent={setModalEventContent}
        setIsOpenScheduleModal={setIsOpenScheduleModal}
      />
    </>
  );
};

export default Calendar;
