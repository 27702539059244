import React from 'react';
import processList from 'data/feature/processList';
import Section from 'components/common/Section';
import Process from './Process';
import SectionHeader from './SectionHeader';
import { isIterableArray } from 'helpers/utils';

const Processes = () => (
  <Section className={'w-100 '}>
    <SectionHeader
      title="Helping firms better advocate for their clients"
      subtitle="With automated and professional service, Fulcrum can help increase productivity and increase client capacity, allowing firms to spend time doing what really matters, advocating for their clients."
    />
    {isIterableArray(processList) &&
      processList.map((process, index) => (
        <Process key={process.color} isFirst={index === 0} {...process} />
      ))}
  </Section>
);

export default Processes;