import React, { useRef, useState, useEffect } from 'react';
import { Col, Form, Button } from 'react-bootstrap';
import DocumentTable from 'components/documents/DocumentTable';
import { Link } from 'react-router-dom';
import { formatPhone } from 'utils/functions';

const DocumentManagement = ({ } = {}) => {


	return (
		<div>
			<DocumentTable
				siteAdminArea={true}
				columns={[
					{
						accessor: 'business_name',
						Header: 'Account',
						headerProps: { className: 'pe-1' },
						cellProps: {
							className: 'py-2'
						},
						Cell: rowData => {
							const { account = {} } = rowData.row.original;
							return <Link to={`/admin/account-details/${account?.id || null}`}>{account?.business_name || ''}</Link>;
						},
					},
					{
						accessor: 'document_type',
						Header: 'Document Title',
						headerProps: { className: 'pe-1' },
						cellProps: {
							className: 'py-2'
						}
					},
					{
						accessor: 'title',
						Header: 'Title',
						headerProps: { className: 'pe-1' },
						cellProps: {
							className: 'py-2'
						}
					},
					{
						accessor: 'packet_title',
						Header: 'Packet',
						headerProps: { className: 'pe-1' },
						cellProps: {
							className: 'py-2'
						}
					},
					{
						accessor: 'claimant_name',
						Header: 'Claimant',
						headerProps: { className: 'pe-1' },
						cellProps: {
							className: 'py-2'
						}
					},
					{
						accessor: 'menu',
						Header: '',
						headerProps: { className: 'pe-1' },
						cellProps: {
							className: 'py-2'
						}
					}
				]}
			/>
		</div>
	);
};





export default DocumentManagement;