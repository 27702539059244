import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Col, Form } from 'react-bootstrap';
import { formatDate } from 'utils/functions';
import IconButton from 'components/common/IconButton';
import ExportReportButton from 'components/ExportReportButton';

const UserTableHeader = ({ label = "Users", selectedRowIds, page = [], handleBulkActions, setCreateUserModal = () => { }, searchParams = {}, showClearFilters = false, setSearchParams = () => { } } = {}) => {
	const formRef = useRef();
	const onClick = async (e) => {
		if (e) {
			e.preventDefault();
			e.stopPropagation();
		}
		let bulk_action = e.target?.innerText ? `${e.target.innerText}`.toLowerCase() : '';
		const formData = new FormData(formRef.current);
		let formDataObj = { bulk_action };
		for (let [key, value] of formData) {
			formDataObj[key] = value;
		}
		if (typeof handleBulkActions === 'function' && bulk_action) {
			let selectedRows = Array.isArray(page) ? page.reduce((acc, { isSelected = false, original } = {}) => (
				[...acc, ...(isSelected ? [original] : [])]
			), []) : [];
			let selectedIds = selectedRows.length > 0 ? selectedRows.map(({ id } = {}) => id) : [];
			await handleBulkActions(formDataObj, selectedRows, selectedIds);
		}
	};

	const onSubmit = async (e) => {
		if (e) {
			e.preventDefault();
			e.stopPropagation();
		}
		const formData = new FormData(formRef.current);
		let formDataObj = {};
		for (let [key, value] of formData) {
			formDataObj[key] = value;
		}
		if (typeof handleBulkActions === 'function' && formDataObj.bulk_action) {
			let selectedRows = Array.isArray(page) ? page.reduce((acc, { isSelected = false, original } = {}) => (
				[...acc, ...(isSelected ? [original] : [])]
			), []) : [];
			let selectedIds = selectedRows.length > 0 ? selectedRows.map(({ id } = {}) => id) : [];
			await handleBulkActions(formDataObj, selectedRows, selectedIds);
		}
	};

	const selectedIds = Array.isArray(page)
		? (page.reduce((acc, { isSelected = false, original } = {}) => (
			[...acc, ...(isSelected ? [original] : [])]
		), [])).map(({ id } = {}) => id)
		: [];

	return (
		<Form noValidate ref={formRef} onSubmit={onSubmit} className="row flex-between-center">
			<Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
				<h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">{label}</h5>
			</Col>
			<Col xs={8} sm="auto" className="ms-auto text-end ps-0">
				{Object.keys(selectedRowIds).length > 0 ? (
					<div className="d-flex">

					</div>
				) : (
					<div id="orders-actions">
						<IconButton
							size="sm"
							variant="falcon-default"
							className="me-2"
							type="button"
							onClick={() => {
								setCreateUserModal(true)
							}}
						>
							<span className="d-none d-sm-inline-block">Add User</span>
						</IconButton>
						<ExportReportButton
							controllerName="User"
							fnName="searchAllUserFn"
							saveAs={`${label.replace("Report", "").trim()}_${formatDate(new Date(), "YYYY-MM-DD")}.xlsx`}
							params={{
								...searchParams,
								offset: 0,
								limit: null,
								selectedRowIds: selectedIds,
							}}
							additionalParamsArray={[false]} // countOnly argument for searchAllTaskFn
							flattenData // whenever the return data rows has dataValues or any objects inside it, set this to true to flatten the data in the rows exported to excel
							appendDuplicates={false} // if true, flattened data with duplicates will be appended to each other 
							overwriteDuplicates={false} // if true, flattened data with duplicates will be overwritten with the last value. false will keep the first value and any more duplicates will be ignored 
						/>
					</div>
				)}
			</Col>
		</Form>
	);
};

UserTableHeader.propTypes = {
	label: PropTypes.string,
	selectedRowIds: PropTypes.object,
	page: PropTypes.array,
	handleBulkActions: PropTypes.func,
	setCreateUserModal: PropTypes.func,
	searchParams: PropTypes.object,
	showClearFilters: PropTypes.bool,
	setSearchParams: PropTypes.func

};

export default UserTableHeader;