import React, { useContext, useEffect, useState, useRef } from 'react';
import { myFormsAPI } from 'utils/api/fulcrum-api';
import AppContext from 'context/Context';
import { Row, Col, Button, Card, Form, Offcanvas, Spinner, Alert } from 'react-bootstrap';
import { toast } from 'react-toastify';
import SignatureModal from 'components/app/SignatureModal';
import InnerHTML from 'dangerously-set-html-content';
import { sleep } from 'utils/functions';
import { getClientDigitalInformation } from 'utils/ipify';


// const MyFormViewer = ({ uuid, accessVerification, meta_data }) => {
const MyFormViewer = ({ uuid, inboxOutboxId, myFormResponseId, selectedItem, setSelectedItem = () => { }, assignedTasks = [], setAssignedTasks = () => { } }) => {
	const {
		config: { isDark }
	} = useContext(AppContext);
	const [loading, setLoading] = useState(true);
	const [slide, setSlide] = useState(1); // 1 = form, 2 = Completed, 3 = Error, 4 = Invalid or Expired Form
	const [formResponse, setFormResponse] = useState({});
	const [showSignatureModal, setShowSignatureModal] = useState(false);
	const [selectedSignatureElement, setSelectedSignatureElement] = useState(null);
	const [renderedContent, setRenderedContent] = useState('<div>Loading...</div>');
	const [warningMessages, setWarningMessages] = useState([]);
	const [digitalConsent, setDigitalConsent] = useState(false);
	const [myFormData, setMyFormData] = useState({});
	const [submitting, setSubmitting] = useState(false);

	const fieldRef = useRef({});

	const executeScroll = (id) => { fieldRef.current[id].scrollIntoView() }

	// Get My Form Response Data
	const getMyFormResponseDataByUUID = async () => {
		try {
			let result;
			let myFormResponseData;
			if (inboxOutboxId && myFormResponseId) {
				result = await myFormsAPI.getCorrespondenceMyFormResponseByUUID(uuid, inboxOutboxId, myFormResponseId);
				if (!result?.data?.myFormResponse) {
					throw new Error('No data found');
				}
				myFormResponseData = result.data.myFormResponse;
			} else {
				result = await myFormsAPI.getMyFormResponseByUUID(uuid);
				if (!result?.data || !result.data?.status) { throw new Error('No data found'); }
				myFormResponseData = result.data;
			}

			if (!['SENT', 'LOOKUP_COMPLETE'].includes(myFormResponseData.status)) {
				setSlide(2);
				setLoading(false);
				return;
			}

			setFormResponse({
				...myFormResponseData,
				response_data: JSON.parse(myFormResponseData?.response_data || '{}'),
			});

			setRenderedContent(myFormResponseData?.adjustedContent || '')
			setLoading(false);
		} catch (error) {
			setSlide(3);
		}
	};


	const handleSubmit = async (e) => {
		setSubmitting(true);
		e.preventDefault();
		let warnings = [];
		let formData = document.getElementById("my-form-response-form").elements;
		let form_data = document.getElementById("my-form-response-container").innerHTML;

		// Make sure all signature fields are filled out
		let signatureFields = document.querySelectorAll('.signature');
		let signatureFieldsFilled = true;

		signatureFields.forEach((field) => {
			if (!field?.src || field.src.includes('/signature-placeholder.png')) {
				executeScroll(field.id)
				signatureFieldsFilled = false;
			}
		});

		if (!signatureFieldsFilled) {
			toast.error("Please sign all signature fields", { theme: 'colored' });
			warnings.push('Please sign all signature fields');
		}

		// loop through the form data and create a new object
		let data = {};
		for (let i = 0; i < formData.length; i++) {
			if (formData[i].name) {
				data[formData[i].name] = {
					id: formData[i].id,
					name: formData[i].name,
					value: formData[i].value,
					type: formData[i].type,
					checked: formData[i].checked,
					model: formData[i].dataset?.model || null,
					key: formData[i].dataset?.key || null,
					reference_id: formData[i].dataset?.reference_id || null,
				}
			}
		}

		let clientDeviceInformation = await getClientDigitalInformation();

		data = { ...data, response_data: formResponse?.response_data || {}, clientDeviceInformation, form_data };

		setWarningMessages(warnings);
		if (warnings.length) {
			setSubmitting(false);
			return;
		}


		try {
			let result = await myFormsAPI.submitMyFormResponse(uuid, formResponse.id, data);
			if (!result?.data) { throw new Error('Error submitting form'); }
			setSlide(2);

			setSelectedItem({ ...selectedItem, task_status: 'Completed' })
			setAssignedTasks(assignedTasks.map((task) => {
				if (task.id === selectedItem.id) {
					return { ...task, task_status: 'Completed' }
				}
				return task;
			}));
			toast.success("Form submitted!", { theme: 'colored' });
		} catch (error) {
			toast.error(error, { theme: 'colored' });
			setSlide(3);
		}
		setSubmitting(false);
	};

	// Render Slide
	const renderSlide = (slide) => {
		switch (slide) {
			case 1:
				return (
					<Card className="vh-100 bg-200" style={{ overflow: 'scroll' }}>
						<Form id="my-form-response-form" className="pb-5 " onSubmit={handleSubmit}>
							<div className="shadow" style={{
								position: 'sticky',
								top: '0',
								zIndex: '1000',
							}}>
								<div className="px-3 pt-3 pb-2" style={{ backgroundColor: "#025cb9", color: "#FFFFFF" }}>
									<div className="pb-3">
										<h4 className="d-inline" style={{ color: '#FFFFFF' }}>Review and Submit</h4>
										<Button variant='success' type="submit" className='float-end'>Reviewed & Submit</Button>
									</div>
								</div>
								<div className="px-3 pt-3 pb-2  mb-5" style={{ backgroundColor: '#FFFFFF' }}>
									<Form.Check
										onChange={e => { setDigitalConsent(e.target.checked); }}
										type='checkbox'
										disabled={loading || submitting}
										required
										id='electronic_signature_consent'
										label='I consent to signing this document and conducting business electronically.'
										className={`fs-2 fw-bold align-middle ${myFormData?.electronic_signature_consent ? 'text-success' : 'text-danger'}`}
									/>
									{
										warningMessages.length > 0 &&
										<Alert variant={"warning"}>
											<ul>

												{
													warningMessages.length > 0 && warningMessages.map((message, index) => (
														<li key={index}>{message}</li>
													))
												}
											</ul>
										</Alert>
									}
								</div>
							</div>

							<Card className={`w-75 mx-auto h-100 mb-5 ${isDark && 'bg-black'}`}>
								<Card.Body>
									<Row>
										<Col>
											<div id="my-form-response-container">
												<InnerHTML html={renderedContent} />
											</div>
										</Col>
									</Row>
								</Card.Body>
							</Card>


							<br /><br />
							<br /><br />
							<SignatureModal
								modalState={showSignatureModal}
								setModalState={setShowSignatureModal}
								callBack={applySignature}
							/>
						</Form>
					</Card >
				);
				break;
			case 2:
				return (
					<div className=' d-flex my-5'>
						<Card className="mx-auto text-center py-5 px-3" style={{ width: "50%" }}>
							<Card.Header className="border-bottom">
								<h2 className="text-success">Form Submitted!</h2>
							</Card.Header>
							<Card.Body>
								<p>Thank you for your form submission! Your firm will review your document and reach out if any further action is needed.</p>
							</Card.Body>
						</Card>
					</div>
				)
				break;
			case 3:
				return (
					<div className='d-flex  my-5'>
						<Card className="mx-auto text-center justify-content-center py-5 px-3" style={{ width: "50%" }}>
							<Card.Header className="border-bottom">
								<h2 className="text-danger">Something went wrong.</h2>
							</Card.Header>
							<Card.Body>
								<p>Something went wrong attempting to submit the form. Please reach out to your firm for assistance.</p>
							</Card.Body>
						</Card>
					</div>
				)
				break;
			case 4:
			default:
				return (
					<div className='d-flex my-5 '>
						<Card className="mx-auto text-center justify-content-center py-5 px-3" style={{ width: "50%" }}>
							<Card.Header className="border-bottom">
								<h2 className="text-danger">Invalid or Expired Form!</h2>
							</Card.Header>
							<Card.Body>
								<p>The form you're trying to access has either expired or is invalid. Please reach out to your firm for assistance.</p>
							</Card.Body>
						</Card>
					</div>
				)
		}
	};



	// Apply Signature
	const applySignature = (sigData) => {
		selectedSignatureElement.setAttribute('src', sigData.signature);

		// const { name, value, id, dataset } = selectedSignatureElement;
		const { id, dataset } = selectedSignatureElement;
		const model = dataset?.model || null;

		setFormResponse({
			...formResponse,
			response_data: {
				...formResponse.response_data,
				[id]: {
					...formResponse.response_data[id],
					defaultValue: sigData.signature
				}
			}
		});
		setShowSignatureModal(false);
	};


	useEffect(() => { getMyFormResponseDataByUUID(); }, []);

	useEffect(() => {
		if (renderedContent) {
			fieldRef.current = {};
			const signatures = document.querySelectorAll('.signature');
			signatures.forEach((signature) => {
				signature.classList.add('border');

				fieldRef.current[signature.id] = signature;

				signature.addEventListener('click', (e) => {
					setSelectedSignatureElement(signature);
					const { id, name, value } = e.target;
					fieldRef.current[id].value = value;
					fieldRef.current[id].setAttribute('value', value);

					setShowSignatureModal(true);
				});
			});

			const inputs = document.querySelectorAll('#my-form-response-container input');
			inputs.forEach((element) => {
				fieldRef.current[element.id] = element;

				element.addEventListener('change', (e) => {
					let { value, id, dataset, type, checked, name } = e.target;
					let { tableset, fieldName, parentId, recordId, model } = dataset;


					if (type === 'checkbox') {
						value = checked ? "on" : "off";
						e.target.setAttribute('checked', checked);

					} else if (type === 'radio') {
						let radioGroup = document.querySelectorAll(`input[name="${name}"]`);
						radioGroup.forEach((radio) => {
							radio.removeAttribute('checked');
						});
						fieldRef.current[id].setAttribute('checked', value);
						e.target.setAttribute('checked', checked);
						return;
					}

					setFormResponse({
						...formResponse,
						response_data: {
							...formResponse.response_data,
							[id]: {
								...formResponse.response_data[id],
								defaultValue: value
							}
						}
					});
					fieldRef.current[id].setAttribute('value', value);
				}, false);
			});
		}
	}, [renderedContent, slide]);

	return (
		<div className="vh-100">
			{
				loading &&
				<div style={{
					"position": "absolute",
					"height": "100%",
					"width": "100%",
					zIndex: '9999',
					backgroundColor: "#FFFFFF",
					"opacity": "0.7",
				}}>
					<div className={'text-center opacity-75 d-flex'}>
						<div className="justify-content-center m-auto">
							<Spinner animation="border" role="status"> <span className="visually-hidden">Loading...</span> </Spinner>
						</div>
					</div>
				</div>
			}

			{renderSlide(slide)}
		</div>
	);
};


export default MyFormViewer;