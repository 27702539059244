import React from 'react';
import UploadRequestTable from './UploadRequestTable';


const AtlaswareUploadRequest = ({ claimant = {}, defaultSearchParams = {}, requiredSearchParams = {}, columns, refreshParent = () => { } }) => {
	return (
		<>
			<UploadRequestTable
				tableHeaderLabel="Upload Requests"
				claimant={claimant}
				columns={(columns && Array.isArray(columns)) ? columns : [
					{
						accessor: 'id',
						Header: 'ID',
						headerProps: { className: 'pe-1' },
						cellProps: {
							className: 'py-2'
						},
					},
					{
						accessor: 'claimant_name',
						Header: 'Claimant Name',
						headerProps: { className: 'pe-1' },
						cellProps: {
							className: 'py-2'
						},
					},
					{
						accessor: 'document',
						Header: 'Document',
						headerProps: { className: 'pe-1' },
						cellProps: {
							className: 'py-2'
						},
					},
					{
						accessor: 'document_type',
						Header: 'Type',
						headerProps: { className: 'pe-1' },
						cellProps: {
							className: 'py-2'
						},
					},
					{
						accessor: 'status',
						Header: 'Status',
						headerProps: { className: 'pe-1' },
						cellProps: {
							className: 'py-2'
						},
					},
					{
						accessor: 'primary_rep',
						Header: 'Primary Rep',
						headerProps: { className: 'pe-1' },
						cellProps: {
							className: 'py-2'
						},
					},
					{
						accessor: 'created_at',
						Header: 'Submitted',
						headerProps: { className: 'pe-1' },
						cellProps: {
							className: 'py-2'
						},
					},
					{
						accessor: 'menu',
						Header: '',
						headerProps: { className: 'pe-1' },
						cellProps: {
							className: 'py-2'
						},
					}
					// Cell: rowData => {
					// 	const { name, id } = rowData.row.original;
					// 	return <Link to={`/claimant/profile/${id}`}>{name}</Link>;
					// }
				]}
				defaultSearchParams={{
					claimant_id: claimant?.id || null,
				}}
				requiredSearchParams={{
					claimant_id: claimant?.id || null,
				}}
				refreshParent={refreshParent}
			/>
		</>
	);
};

export default AtlaswareUploadRequest;