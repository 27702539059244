import React, { useContext, useState, useEffect } from 'react';
import { Button, Form, Modal, Row, Col, Alert, Spinner } from 'react-bootstrap';
import AppContext from 'context/Context';
import { userAPI, accountAPI } from 'utils/api/fulcrum-api';
import { toast } from 'react-toastify';
import auth from 'utils/auth';
import { validateEmail, removeEmptyFromObj } from 'utils/functions';

const CreateUserForm = ({
	setCreateUserModal,
	createUserModal,
	userData,
	setUserData,
	account_id,
	siteAdminArea = false,
	refreshParent = () => { }
}) => {
	const {
		config: { isDark }
	} = useContext(AppContext);
	const [submitting, setSubmitting] = useState(false);
	const defaultForm = {
		first_name: '',
		last_name: '',
		email: '',
		user_type_id: '',
	};
	defaultForm.account_id = account_id || auth.getProfile().data.account_id;

	const [errors, setErrors] = useState([]);
	const [accountData, setAccountData] = useState([]);
	const [sendInviteLink, setSendInviteLink] = useState(true);
	const [formData, setFormData] = useState(defaultForm);
	const [emailUnique, setEmailUnique] = useState(false);
	const [validEmail, setValidEmail] = useState(false);


	// Verify email is unique
	const verifyEmail = async (primary_email, withAvailabilityCheck = true) => {
		try {
			if (!validateEmail(primary_email)) {
				setEmailUnique(false);
				return false;
			}
			if (!withAvailabilityCheck) return true;

			let isUniqueResult = await userAPI.verifyEmailAvailability(primary_email);
			if (!isUniqueResult?.data || isUniqueResult.data == false) {
				setEmailUnique(false);
				throw new Error('Email is not unique');
			}
			setEmailUnique(true);
			return true;
		} catch (error) {
			errors.primary_email = { message: 'Email is not unique' };
			setEmailUnique(false);
			return false;
		}
	};

	const handleClose = () => {
		setFormData(defaultForm);
		setSubmitting(false);
		setCreateUserModal(!createUserModal);
	};

	const handleChange = ({ target }) => {
		let { name, value } = target;
		let additionalValues = {};

		if (name == 'email') {
			verifyEmail(value);
		}

		setFormData({ ...formData, ...additionalValues, [name]: value });
	};
	const handleSubmit = async e => {
		e.preventDefault();
		setSubmitting(true);
		setErrors([]);
		let newErrors = [];

		if (!formData.user_type_id) {
			newErrors.push('Please select a user type.');
		}

		if (!formData.email) {
			newErrors.push('Please enter an email.');
		}

		// Validate required fields
		let uniqueEmail = await verifyEmail(formData.email);
		if (!uniqueEmail) {
			newErrors.push({ message: 'Email is not unique' });
		}

		if (newErrors.length > 0) {
			setErrors(newErrors);
			setSubmitting(false);
			return;
		}

		let user_type_list = [formData.user_type_id];

		try {
			let newUserResult = await userAPI.createUser({
				...formData,
				user_type_list
			});
			if (!newUserResult?.data?.id) {
				throw new Error('Unable to create the new user.');
			}

			// Send Invite Link
			if (sendInviteLink) {
				let sendInviteResult = await userAPI.sendUserInviteEmail(newUserResult.data.id, {});
				if (!sendInviteResult?.data?.message) {
					throw new Error('Unable to send the invite email.');
				}
			}

			setUserData([...userData, newUserResult.data]);
			setCreateUserModal(false);
			refreshParent();
		} catch (error) {
			console.log(error)
		}
		setSubmitting(false);
	};

	// Get Account Data
	const getAccountData = async () => {
		try {
			let result = await accountAPI.searchAllAccounts({});
			if (!result?.data?.rows) {
				throw new Error('Unable to get account data.');
			}

			setAccountData(result.data.rows);
		} catch (error) {
			console.log(error)
			toast.error('Unable to load account data.', { theme: 'colored' });
			handleClose();
		}
	}

	// Handle Show
	const handleShow = () => {
		if (siteAdminArea) {
			getAccountData();
		}
	}

	return (
		<Modal
			show={createUserModal}
			onShow={handleShow}
			onHide={handleClose}
			contentClassName="border"
		>
			<Form onSubmit={handleSubmit}>
				<Modal.Header
					closeButton
					closeVariant={isDark ? 'white' : undefined}
					className="bg-light px-card border-bottom-0"
				>
					<Modal.Title as="h5">Invite New User</Modal.Title>
				</Modal.Header>
				<Modal.Body className="p-card">
					{
						sendInviteLink &&
						<Alert variant={"info"}>
							<span className="fw-semi-bold">Invite a new user to use Fulcrum.</span>
							<br />
							An activiation email will be sent to the provided email for the user to fully activiate their account.
						</Alert>
					}

					{
						errors.length > 0 && (
							<Alert variant={'danger'}>
								{errors.map((error, index) => (
									<div key={index}>{error}</div>
								))}
							</Alert>
						)
					}

					{
						siteAdminArea && setAccountData.length &&
						<Form.Group className="mb-3" controlId="account_id">
							<Form.Label className="fs-0">Account</Form.Label>
							<Form.Select
								disabled={submitting}
								name="account_id"
								size="sm"
								defaultValue={""}
								value={formData.account_id || null}
								onChange={handleChange}
								required
							>
								<option value="" disabled={true}>--Select One--</option>
								{
									accountData.map((account, index) => {
										return <option key={index} value={account?.id || null}>{account?.business_name || ''}</option>
									})
								}
							</Form.Select>
						</Form.Group>
					}

					<Form.Group className="mb-3" controlId="user_type_id">
						<Form.Label className="fs-0">User Type</Form.Label>
						<Form.Select
							name="user_type_id"
							size="sm"
							defaultValue={""}
							value={formData.user_type_id || null}
							onChange={handleChange}
							required
							disabled={submitting}
						>
							<option value="" disabled={true}>--Select One--</option>
							<option value="REPRESENTATIVE">Representative</option>
							<option value="STAFF">Staff Member</option>
						</Form.Select>
					</Form.Group>

					<Row>
						<Form.Group as={Col} className="mb-3" controlId="first_name">
							<Form.Label className="fs-0">First Name</Form.Label>
							<Form.Control
								type="text"
								size="sm"
								name="first_name"
								value={formData.first_name}
								required
								onChange={handleChange}
								disabled={submitting}
							/>
						</Form.Group>
						<Form.Group as={Col} className="mb-3" controlId="last_name">
							<Form.Label className="fs-0">Last Name</Form.Label>
							<Form.Control
								type="text"
								size="sm"
								name="last_name"
								value={formData.last_name}
								required
								onChange={handleChange}
								disabled={submitting}
							/>
						</Form.Group>
					</Row>

					<Form.Group className="mb-3" controlId="email">
						<Form.Label className="fs-0">Email* (Must be unique) <br />
							{
								emailUnique ?
									<span className="text-success">Email is unique and valid</span>
									:
									<span className="text-danger">Email is not valid and/or unique</span>
							}</Form.Label>
						<Form.Control
							type="email"
							size="sm"
							name="email"
							value={formData.email}
							required
							onChange={async e => {
								handleChange(e);
							}}
							onBlur={e => verifyEmail(e.target.value)}
							disabled={submitting}
						/>
					</Form.Group>

					<Form.Group className="mb-3" controlId="sendInviteLink">
						<Form.Check
							type="checkbox"
							name="sendInviteLink"
							label="Send the user an invite link to activate their account?"
							checked={sendInviteLink}
							disabled={submitting}
							onChange={e => {
								setSendInviteLink(e.target.checked)
							}}
						/>
					</Form.Group>


				</Modal.Body>
				<Modal.Footer className="bg-light px-card border-top-0">
					<Button
						variant="outline-danger"
						onClick={e => handleClose(e)}
						className="px-4 mx-0"
					>
						Cancel
					</Button>
					<Button
						variant="primary"
						type="submit"
						className="px-4 mx-0"
						disabled={submitting}
					>
						{

							submitting ?
								<>
									<Spinner
										as="span"
										animation="border"
										size="sm"
										role="status"
										aria-hidden="true"
										className="mr-2"
									/> Creating user...
								</>
								:
								<>
									{sendInviteLink ? "Send Invite" : "Create User"}
								</>
						}
					</Button>
				</Modal.Footer>
			</Form>
		</Modal>
	);
};



export default CreateUserForm;