import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Modal } from 'react-bootstrap';
import AppContext from 'context/Context';
import { MedicalRecordRequestAPI } from 'utils/api/fulcrum-api';
import ClaimantQuickFind from 'components/claimants/find-a-claimant/ClaimantQuickFind';
import { toast } from 'react-toastify';


const MedicalRecordRequestForm = ({
	setModalState = () => { },
	modalState,
	refreshParent = () => { },
	defaultClaimants = [],
}) => {
	const {
		config: { isDark }
	} = useContext(AppContext);
	const defaultFormData = {

	};
	const [formData, setFormData] = useState(defaultFormData);
	const [claimants, setClaimants] = useState(defaultClaimants);

	const handleClose = () => {
		setFormData(defaultFormData);
		setClaimants([]);
		setModalState(false);
	};

	// const handleChange = ({ target }) => {
	// 	let { name, value } = target;
	// 	let additionalValues = {};

	// 	setFormData({ ...formData, ...additionalValues, [name]: value });
	// };


	const handleSubmit = async e => {
		e.preventDefault();

		try {
			let medicalRecordRequestResult = await MedicalRecordRequestAPI.postMedicareRequest({
				...formData
			});
			if (!medicalRecordRequestResult?.data?.id) {
				throw new Error('Unable to post medical record request');
			}

			handleClose();
			refreshParent();
			toast.success('Medical record request submitted!', { theme: 'colored' });
		} catch (error) {
			toast.error(error.message, { theme: 'colored' });
		}
	};

	// Handle Claimant Selection
	const handleClaimantSelection = (claimant) => {
		for (let i = 0; i < claimants.length; i++) {
			if (claimants[i].id === claimant.id) {
				return;
			}
		}

		setClaimants([...claimants, claimant]);
	};

	// Handle Show
	const handleShow = () => {
		setClaimants(defaultClaimants);
		setFormData(defaultFormData);
	};


	return (
		<Modal
			show={modalState}
			onHide={handleClose}
			onShow={handleShow}
			contentClassName="border"
		>
			<Form onSubmit={handleSubmit}>
				<Modal.Header
					closeButton
					closeVariant={isDark ? 'white' : undefined}
					className="bg-light px-card border-bottom-0"
				>
					<Modal.Title as="h5">Request Medical Records</Modal.Title>
				</Modal.Header>
				<Modal.Body className="p-card">
					<h6>Select a Claiamnt</h6>
					<ClaimantQuickFind
						buttonLabel='Find-A-Claimant'
						buttonVariant='primary'
						handleClaimantSelection={handleClaimantSelection}
						defaultSearchParams={{}}
						requiredSearchParams={{}}
						skipFirstQuery={true}
					/>

					<hr />

					<h6>Select a Provider</h6>
					<p>Select a provider that you </p>
					{/* <Form.Group className="mb-3" controlId="task_type_id">
						<Form.Label className="fs-0">Task Type</Form.Label>
						<Form.Select name="task_type_id" defaultValue={''} value={formData.task_type_id || null} onChange={handleChange} required>
							<option value={""}>--Select One--</option>
							{Array.isArray(taskTypes) && taskTypes.map((taskType, index) => (
								<option key={index} value={taskType.id}>{taskType.type}</option>
							))}
						</Form.Select>
					</Form.Group>
									<Form.Group className="mb-3" controlId="title">
										<Form.Label className="fs-0">Title</Form.Label>
										<Form.Control name="title" value={formData.title || ''} onChange={handleChange} required />
									</Form.Group> */}
				</Modal.Body>
				<Modal.Footer className="bg-light px-card border-top-0">
				</Modal.Footer>
			</Form>
		</Modal>
	);
};

MedicalRecordRequestForm.propTypes = {
	setModalState: PropTypes.func,
	modalState: PropTypes.bool,
	refreshParent: PropTypes.func,
	defaultClaimants: PropTypes.array
}

export default MedicalRecordRequestForm;