// import { AuthWizardContext } from 'context/Context';
import Lottie from 'lottie-react';
import PropTypes from 'prop-types';
import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import celebration from '../lottie/celebration.json';

const Complete = ({ handleSubmitForm }) => {
  // const { setStep, setUser } = useContext(AuthWizardContext);

  return (
    <>
      <Row>
        <Col className="text-center">
          <div className="wizard-lottie-wrapper">
            <div className="wizard-lottie mx-auto">
              <Lottie animationData={celebration} loop={true} />
            </div>
          </div>
          <h4 className="mb-1">You're All Done!</h4>
          <p className="fs-0">Login using the email that received the invite link and the password you just set.</p>
          <Button color="primary" className="px-5 my-3" onClick={handleSubmitForm}>
            Go to Login
          </Button>
        </Col>
      </Row>
    </>
  );
};

Complete.propTypes = {
  reset: PropTypes.func.isRequired,
  handleSubmitForm: PropTypes.func
};

export default Complete;