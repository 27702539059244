import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, Alert, Form, Table, ButtonGroup, ListGroup } from 'react-bootstrap';
import { caseAPI, myFormsAPI, documentAPI } from 'utils/api/fulcrum-api';
import { toast } from 'react-toastify';
import EditorModal from 'components/pages/documents/my-forms/document-generator/EditorModal';
import PDFViewerModal from 'components/app/pdfjs-viewer/PDFViewerModal';
import Swal from 'sweetalert2';
import PDFViewerPreviewModal from 'components/app/pdfjs-viewer/PDFViewerPreviewModal';
import MyFormFinderModal from 'components/pages/documents/my-forms/MyFormFinderModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ClaimantDocumentQuickFind from 'components/claimants/claimant-document-quick-find';
import moment from 'moment/moment';

const FileAnAppeal = ({ modalState, setModalState = () => { }, claimant = {}, selectedCase = {} } = {}) => {
	const [claimantDocuments, setClaimantDocuments] = useState([]);
	const [documentTypes, setDocumentTypes] = useState([]);
	const [myFormFinderModal, setMyFormFinderModal] = useState(false);
	const [formEditorModal, setFormEditorModal] = useState(false);
	const [selectedForm, setSelectedForm] = useState(null);
	const [slide, setSlide] = useState(1);
	const [submitting, setSubmitting] = useState(false);
	const [forms, setForms] = useState([]);
	const [pdfViewerModalState, setPdfViewerModalState] = useState(false);
	const [selectedFileId, setSelectedFileId] = useState(null);
	const [errors, setErrors] = useState([]);

	let defaultFormData = {
		appeal_type: '', // Request for Reconsideration, Request for Hearing, Request for Appeals Council Review
		mode_of_transmission: '',
		forms: [],
		claimant_id: claimant.id,
		documents: [],
		recipient: ''
	}

	const [formData, setFormData] = useState(defaultFormData);

	// Handle Submit
	const handleSubmit = async (e) => {
		e.preventDefault();
		setErrors([]);
		let newErrors = [];

		if (!formData.appeal_type) {
			newErrors.push('Please select an appeal type.');
		}

		// if (!formData.mode_of_transmission) {
		// 	newErrors.push('Please select a mode of transmission.');
		// }

		if (!formData.recipient) {
			newErrors.push('Please select a recipient.');
		}

		if (newErrors.length > 0) {
			setErrors(newErrors);
			return;
		}
		setSubmitting(true);
		try {
			let result = await caseAPI.fileAnAppeal(claimant.id, selectedCase.id, formData);
			if (result?.message !== "Appeal has been queued for processing.") {
				throw new Error('Unable to file an appeal.');
			}
			toast.success('Appeal has been queued for processing.', { theme: 'colored' });
			handleClose();
		} catch (error) {
			toast.error('Unable to file an appeal.', { theme: 'colored' });
		}
		setSubmitting(false);
	};

	// Get related data @TODO: Move this to a context
	const getRelatedData = async () => {
		try {
			let docTypeResults = await documentAPI.searchDocumentTypes();
			if (!docTypeResults?.data?.rows) {
				throw new Error('Unable to get documents types.');
			}

			setDocumentTypes(docTypeResults.data.rows);
		} catch (error) {
			alert(error);
		}
	};

	// Handle Editor Save
	const handleEditorSave = (content) => {
		let newForms = forms.map((form, index) => {
			if (form.id === selectedForm.id) {
				return {
					...form,
					content
				}
			}
			return form;
		});

		setForms(newForms);
	};

	// Get default form by id and add to forms
	const getDefaultForm = async (id) => {
		try {
			let result = await myFormsAPI.getMyForm(id);
			if (!result?.data) {
				throw new Error('Unable to get default form');
			}

			return result.data;
		} catch (error) {
			toast.error('Unable to attach the default form.', { theme: 'colored' });
			return null;
		}
	};

	const selectDefaultFormTemplates = async (type) => {
		let formIdList = [];
		switch (type) {
			case 'Request for Reconsideration':
				formIdList = [
					{ id: 43 }, // ssa-561-u2
					{ id: 41 }, // ssa-3441
				];
				break;
			case 'Request for Hearing':
				formIdList = [
					{ id: 30 }, // ha-501
					{ id: 41 }, // SSA-3441
				];
				break;
			case 'Request for Appeals Council Review':
				formIdList = [
					{ id: 31 }, // ha-501
				];
				break;
			default:
				formIdList = [];
				break
		}

		let newForms = [];
		for (let i = 0; i < formIdList.length; i++) {
			let form = await getDefaultForm(formIdList[i].id);
			if (form?.id) {
				newForms.push(form);
			}
		}
		setForms(newForms);
	};

	// Get claimant documents
	const getClaimantDocuments = async (claimant_id) => {
		if (claimant_id) {
			try {
				let result = await documentAPI.getClaimantDocuments(claimant_id);
				if (!result?.data) {
					throw new Error('Unable to get claimant documents.');
				}
				setClaimantDocuments(result.data.map((document) => {
					return {
						...document,
						value: document.id,
						label: `${document?.document_type?.type || ''} - ${document.title} - ${document?.createdAt ? moment(document.createdAt).format('MM-DD-YYYY hh:mm A') : ''}`,
					}
				}));
			} catch (error) {
				setClaimantDocuments([]);
				setFormData({ ...formData, documents: null });
				toast.error('Unable to get claimant documents.', { theme: 'colored' });
			}
		}
	};

	// Handle document selection
	const handleDocumentSelection = (selectedClaimantDocuments = []) => {
		let approvedDocuments = [];

		let currentDocuments = formData.documents || [];

		for (let i = 0; i < selectedClaimantDocuments.length; i++) {
			let allowDocument = true;
			for (let j = 0; j < currentDocuments.length; j++) {
				if (selectedClaimantDocuments[i].id === currentDocuments[j].id) {
					allowDocument = false
				}
			}
			if (allowDocument) {
				approvedDocuments.push(selectedClaimantDocuments[i]);
			}
		}
		setFormData({ ...formData, documents: [...currentDocuments, ...approvedDocuments] });
	};

	//  Handle Input Change
	const handleInputChange = (e) => {
		const { name, value } = e.target;

		if (name === 'appeal_type') {
			selectDefaultFormTemplates(value);
		}
		setFormData({
			...formData,
			[name]: value
		});
	};

	// Handle close
	const handleClose = () => {
		setSlide(1);
		setModalState(false);
	};

	// Get claimant's field office fax number
	const getClaimantOfficeFaxNumber = async () => {
		let faxNumber = formData?.claimant?.claimant_active_case?.field_office_fax || null;
		faxNumber = faxNumber?.replace(/\D/g, '');// Remove all but digits

		setFormData({ ...formData, recipient: faxNumber });
		// getFaxCoversheetTemplate(formData.fax_coversheet_my_form_id); // getFaxCoversheetTemplate is not defined in this scope - this is a bug
	};

	// Handle show
	const handleShow = () => {
		getRelatedData();
		getClaimantDocuments(claimant.id);
		setModalState(true);
	};

	// Handle form selection
	const handleFormSelection = (form, file_type = "My Form") => {
		let newForms = [...forms, { id: Date.now(), ...form, order: forms.length + 1, file_type }];
		setForms(newForms);
		setFormData({
			...formData,

			forms: [newForms],
			title: !formData?.title ? (form?.title ? form.title : "") : formData.title,
			document_type_id: !formData?.document_type_id ? (form?.form_type_id ? form.form_type_id : "OTHER") : formData.document_type_id,
		});
	};

	// Handle edit selected 
	const handleEditSelected = (form) => {
		setSelectedForm(form);
		switch (form.type) {
			case "My Form":
			case "RTF":
				setSelectedForm(form);

				/*
				// setAllowNewFormSave, setEditorModalOptions, and setEditorConfig is not defined in this scope so I commented this out to avoid errors - this is a bug
				setAllowNewFormSave(false); 
				setEditorModalOptions({     
					...editorModalOptions,
					allowNewFormSave: false,
					allowFormSave: true
				})
				setEditorConfig({
					readonly: false,
				});
				*/

				setFormEditorModal(true);

				break;
			case "PDF":
				setPdfViewerModalState(true);
				break;
			default:
				break;
		}
	};

	// Create task reminder
	const createTaskReminder = async () => {
		try {
			let result = await caseAPI.createOnlineAppealCheckTask(claimant.id, selectedCase.id);
			if (!result?.data) {
				throw new Error('Unable to create task reminder.');
			}

			Swal.fire({
				icon: 'success',
				text: 'A new task reminder to check the status of an online appeal has been set for this claimant.',
				showConfirmButton: true,
				background: false,
				backdrop: true,
			});
		} catch (error) {
			toast.error('Unable to create task reminder.', { theme: 'colored' });
		}
		handleClose();
	};

	// Handle Option Click
	const handleOptionClick = (option) => {
		switch (option) {
			case "file_appeal":
				setSlide(2);
				break;
			case "task_reminder":
				createTaskReminder();
				break;
			default:
				setSlide(1);
				break;
		}
	}

	return (
		<div>
			{/* <Button variant="falcon-primary" onClick={() => { setModalState(true) }}>File an Appeal</Button> */}

			<Modal
				show={modalState}
				size="xl"
				backdrop="static"
				onShow={handleShow}
				onHide={handleClose}
			>
				<Modal.Header
					closeButton
				>
					<Modal.Title>File an Appeal</Modal.Title>
				</Modal.Header>
				<Modal.Body className="bg-100">
					<Alert variant="info">
						You can file an online appeal <a href="https://www.ssa.gov/benefits/disability/appeal.html" target="_blank" rel="noopener, noreferrer">here</a>.  Do you want to file a paper appeal?
					</Alert>

					{
						slide === 1
							?
							<>

								<ListGroup>
									<ListGroup.Item className="my-3 py-3 px-3 shadow-sm" action onClick={() => handleOptionClick("file_appeal")}>
										<h5 className="fw-bold">Yep! Let's file a hard copy.</h5>
										<span>Follow the steps to file an appeal.</span>
									</ListGroup.Item>
									<ListGroup.Item className="my-3 py-3 px-3 shadow-sm" action onClick={() => handleOptionClick("task_reminder")}>
										<h5 className="fw-bold">No, but set a task reminding me to check on my online appeal.</h5>
										<span>Set a task reminder to check the appeal online.</span>
									</ListGroup.Item>
								</ListGroup>
							</>
							:
							<>
								<div>
									<Form onSubmit={handleSubmit}>

										{
											errors.length > 0 &&
											<Alert variant="danger">
												<ul>
													{
														errors.map((error, index) => {
															return <li key={index}>{error}</li>
														})
													}
												</ul>
											</Alert>
										}
										<Form.Group className="mb-3" controlId="formPlaintextEmail">
											<Form.Label className="fw-bold">
												Appeal Type
											</Form.Label>
											<Form.Select
												size="sm"
												as="select"
												aria-label="Appeal Type"
												value={formData.appeal_type}
												name='appeal_type'
												onChange={handleInputChange}
												className={` form-select`}
											>
												<option value="">--Select an Appeal Type--</option>
												<option value="Request for Reconsideration">Request for Reconsideration</option>
												<option value="Request for Hearing">Request for Hearing</option>
												<option value="Request for Appeals Council Review">Request for Appeals Council Review</option>
											</Form.Select>
										</Form.Group>

										<Form.Group className="mb-3" controlId="recipient">
											<Form.Label className="fw-bold">
												Fax Recipient
											</Form.Label>
											<Form.Control
												type="text"
												placeholder="Recipient"
												value={formData.recipient}
												name='recipient'
												onChange={handleInputChange}
												className={`form-control`}
											/>
										</Form.Group>

										<hr />

										<span className="fw-bold">Selected templates</span>
										<Button
											variant="link"
											onClick={() => setMyFormFinderModal(true)}
										>
											Select Form Templates
										</Button>
										{
											forms.length > 0 ?
												<div>
													<Table bordered size="md" className="bg-white mt-3">
														<thead className="bg-200">
															<tr>
																<th>Type</th>
																<th style={{ width: "50%" }}>Title</th>
																<th>Form Type</th>
																<th>Order</th>
																<th></th>
															</tr>
														</thead>
														<tbody>
															{
																forms.map((form, index) => (
																	<tr key={index}>
																		<td className="fw-bold">{form.type}</td>
																		<td>
																			<Form.Control
																				type="text"
																				name="title"
																				value={form.title}
																				disabled={submitting}
																				onChange={e => {
																					const newForms = [...forms];
																					newForms[index].title = e.target.value;
																					setForms(newForms);
																				}}
																				required
																			/>
																		</td>
																		<td>
																			<Form.Control
																				as="select"
																				name="form_type_id"
																				value={form.form_type_id}
																				disabled={submitting}
																				onChange={e => {
																					const newForms = [...forms];
																					newForms[index].form_type_id = e.target.value;
																					setForms(newForms);
																				}}
																				required
																			>
																				<option value={""}>--Select One--</option>
																				{Array.isArray(documentTypes) && documentTypes.map((docType, index) => (
																					<option key={index} value={docType.id}>{docType.type}</option>
																				))}
																			</Form.Control>
																		</td>
																		<td>
																			{/* <span className="fw-bold px-0">{index + 1}</span> */}

																			<ButtonGroup size="lg" className="me-2 mb-1">
																				{
																					index > 0 &&
																					<Button variant="link" disabled={submitting} onClick={e => {
																						const newForms = [...forms];
																						const temp = newForms[index];
																						newForms[index] = newForms[index - 1];
																						newForms[index - 1] = temp;
																						setForms(newForms);
																					}} className="text-primary px-0 mx-1">
																						<FontAwesomeIcon icon="arrow-up" />
																					</Button>
																				}

																				{
																					index < forms.length - 1 &&
																					<Button variant="link" disabled={submitting} onClick={e => {
																						const newForms = [...forms];
																						const temp = newForms[index];
																						newForms[index] = newForms[index + 1];
																						newForms[index + 1] = temp;
																						setForms(newForms);
																					}} className="text-primary px-0  mx-1">
																						<FontAwesomeIcon icon="arrow-down" />
																					</Button>
																				}
																			</ButtonGroup>

																		</td>
																		<td>
																			<ButtonGroup size="lg" className="me-2 mb-1">

																				{
																					form?.type === 'PDF' ?
																						<Button variant="link" className="px-0 mx-1" disabled={submitting} onClick={e => { handleEditSelected(form) }}>
																							<FontAwesomeIcon icon="eye" />
																						</Button>
																						:
																						<Button variant="link" className="px-0 mx-1" disabled={submitting} onClick={e => { handleEditSelected(form) }}>
																							<FontAwesomeIcon icon="edit" />
																						</Button>
																				}
																				<Button variant="link" className="text-danger px-0 mx-1" disabled={submitting} onClick={e => {
																					Swal.fire({
																						title: 'Remove template?',
																						text: "Are you sure you would like to remove this template?",
																						icon: 'warning',
																						showCancelButton: true,
																						confirmButtonColor: '#d33',
																						cancelButtonColor: '#3085d6',
																						confirmButtonText: 'Remove it!'
																					}).then((result) => {
																						if (result.isConfirmed) {
																							setForms(forms.filter(
																								(f, i) => i !== index
																							))
																						}
																					})
																				}}>
																					<FontAwesomeIcon icon="trash" />
																				</Button>
																			</ButtonGroup>
																		</td>
																	</tr>
																))
															}
														</tbody>
													</Table>
												</div>
												: <p className="my-3">No form templates selected</p>

										}
										<MyFormFinderModal
											modalState={myFormFinderModal}
											setModalState={setMyFormFinderModal}
											handleFormSelection={handleFormSelection}
										/>
										<PDFViewerPreviewModal
											modalState={pdfViewerModalState}
											setModalState={setPdfViewerModalState}
											selectedClaimants={[claimant] || []}
											pdf_template={selectedForm?.content || ""}
										/>

										<EditorModal
											modalState={formEditorModal}
											setModalState={setFormEditorModal}
											content={selectedForm?.content || ""}
											handleEditorSave={handleEditorSave}
											allowNewFormSave={false}
											allowPreview={true}
											setAllowNewFormSave={() => { }}
											defaultEditorConfig={{
												readonly: false,
											}}
											options={{
												allowNewFormSave: false,
												allowFormSave: true,
											}}
											clientDataArray={[claimant] || []}
										/>

										<hr />

										{
											['Request for Hearing', 'Request for Reconsideration'].includes(formData.appeal_type) &&
											(
												<Alert variant="warning">
													<Alert.Heading>Notice</Alert.Heading>
													<p>
														Filing request for reconsideration or request for hearing may require an executed SSA-827 Form. You can find this form <a href="https://www.ssa.gov/forms/ssa-827.pdf">here.</a>
													</p>
												</Alert>
											)
										}


										<p className="fw-bold">Attach Claimant Documents:</p>
										<ClaimantDocumentQuickFind
											buttonLabel={'Browse Claimant Documents'}
											buttonVariant={'falcon-primary'}
											handleDocumentSelection={handleDocumentSelection}
											requiredSearchParams={{}}
											defaultSearchParams={{}}
											allowMultipleSelect={true}
											claimantId={claimant.id}
											claimant={claimant}
										/>



										<PDFViewerModal
											modalState={pdfViewerModalState}
											setModalState={setPdfViewerModalState}
											selectedFileId={selectedFileId}
											setSelectedFileId={setSelectedFileId}
										/>
										<Table striped bordered size="md" className="bg-white mt-3">
											<thead>
												<tr>
													<th>Document Type</th>
													<th>Title</th>
													<th>Created At</th>
													<th>Order</th>
													<th>Actions</th>
												</tr>
											</thead>
											<tbody>
												{
													Array.isArray(formData.documents) && formData.documents
														?
														formData.documents.map((document, index) => (
															<tr key={`selected_doc_${document?.id}`}>
																<td>{document?.document_type_id || ''}</td>
																<td>{document?.title || ''}</td>
																<td>{document?.createdAt ? moment(document.createdAt).format('MM-DD-YYYY hh:mm A') : ''}</td>
																<td>{index + 1}
																</td>
																<td>
																	<Button variant="link" onClick={async () => {
																		setSelectedFileId(document.file_id);
																		setPdfViewerModalState(true);
																	}} >View</Button>
																	<Button
																		variant="link"
																		className='text-danger'
																		onClick={() => {
																			const newDocuments = formData.documents.filter((_, i) => i !== index);
																			setFormData({ ...formData, documents: newDocuments });
																		}}
																	>
																		<FontAwesomeIcon icon="trash" />
																	</Button>
																</td>
															</tr>
														))
														:
														(
															<tr>
																<td colSpan="4" className='text-danger'>No documents selected</td>
															</tr>
														)

												}
											</tbody>
										</Table>

										<Button
											variant="falcon-primary"
											className="mt-3"
											type="submit"
											disabled={submitting}
										>
											{submitting ? 'Submitting...' : 'Submit Appeal'}
										</Button>

										<Button
											variant="outline-secondary"
											className="mt-3 ml-3"
											onClick={handleClose}
										>
											Cancel
										</Button>
									</Form>
								</div>
							</>
					}

				</Modal.Body>
				<Modal.Footer>

				</Modal.Footer>
			</Modal>
		</div >
	);
};

FileAnAppeal.propTypes = {
	modalState: PropTypes.bool,
	setModalState: PropTypes.func,
	claimant: PropTypes.object,
	selectedCase: PropTypes.object
};

export default FileAnAppeal;