import React, { useState } from 'react';
import HearingCreationTool from './HearingCreationTool';
import { Card, Col, Row, Button, Tabs, Tab } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import HearingTable from './HearingTable';

const Hearings = () => {
	const [hearingCreationToolModal, setHearingCreationToolModal] = useState(false);

	return (
		<div>
			<Card className="bg-100  border mb-3 bg-line-chart-gradient text-white">
				<Card.Body>
					<Row className=" justify-content-between pr-0">
						<Col sm="auto" className="pr-0 align-middle">
							<Flex alignItems="center">
								<h4 className="align-middle fw-bold mb-0 h-inline">
									<span className=" text-white fw-medium">Hearings</span>
								</h4>

								{/* <Button
									variant="light"
									className="ml-3 float-end"
									onClick={() => setHearingCreationToolModal(true)}
								>
									<i className="fas fa-plus mr-2"></i>
									<span className="fw-medium">Create Hearing</span>
								</Button> */}
							</Flex>
						</Col>
					</Row>
				</Card.Body>
			</Card>


			<Tabs variant='pills' defaultActiveKey="my-hearings" id="uncontrolled-tab-example" className='mb-3'>
				<Tab eventKey="my-hearings" title="My Hearings" className='border p-3'>
					<h3>My Hearings</h3>
					<HearingTable

					/>
				</Tab>
				<Tab eventKey="open-hearings" title="Open Hearings" className='border p-3'>
					<h3>Open Hearings</h3>

				</Tab>
			</Tabs>
		</div>
	);
};

export default Hearings;