import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import AppContext from 'context/Context';
import { toast } from 'react-toastify';
import { Button, Modal } from 'react-bootstrap';
import IconButton from 'components/common/IconButton';
import { removeEmptyFromObj } from 'utils/functions';
import { claimantAPI } from 'utils/api/fulcrum-api.js';
const { exportDataToExcel } = claimantAPI || {};

/**
 * ExportReportButton 
 * @param {object} params - { 
 * cb - (function) callback function to run after api call, 
 * controllerName - (string) controller where function to call is located,
 * fnName - (string) function to call on contextController,
 * saveAs - (string) name of file to save as, 
 * params - (object) params to pass to function, 
 * additionalParamsArray - (array) array of additional params to pass to function
 * flattenData - (boolean) whether to flatten data or not,
 * appendDuplicates - (boolean) whether to append duplicates or not,
 * overwriteDuplicates - (boolean) whether to overwrite duplicates or not
 * }
 * @returns handleConfirm - function to call api endpoint with provided params that will export data as excel and finally run args.cb if provided
 */
const ExportReportButton = ({ cb, controllerName, fnName, saveAs, params = {}, additionalParamsArray = [], flattenData = false, appendDuplicates = true, overwriteDuplicates = false, ...props } = {}) => {
  if (!Array.isArray(additionalParamsArray)) {
    if (additionalParamsArray != null && typeof additionalParamsArray === 'object') {
      additionalParamsArray = [additionalParamsArray]; // Convert to array
    } else {
      additionalParamsArray = []; // Set to empty array
    }
  }
  if (params == null || typeof params !== 'object') {
    params = {};
  }

  const { config: { isDark } } = useContext(AppContext);
  const hasRowsSelected = (params?.selectedIds || []).length > 0 || (params?.selectedRowIds || []).length > 0;
  const [modalIsShown, setModalState] = useState(false);
  const handleOpen = () => (setModalState(true));
  const handleClose = () => (setModalState(false));

  if (typeof cb !== 'function') {
    cb = ({ status, statusText, data } = {}) => {
      let { ok, statusMessage, error } = data || {};
      if (ok === undefined) {
        ok = Number(status) === 200;
      }
      if (statusMessage === undefined) {
        statusMessage = statusText || ok ? "Success!" : "Failed!";
      }
      if (error) {
        statusMessage = `${error}`;
        ok = false;
      }

      const message = ok ? statusMessage : `[${status}] ${statusMessage}`;
      const toastParams = { position: "top-right", autoClose: 10000, pauseOnHover: true, theme: isDark ? "dark" : "light" };

      if (ok) {
        console.log(message);
        toast.success(message, toastParams)
      } else {
        console.error(message);
        toast.warning(message, toastParams);
      }

      return { status, statusText, data };
    };
  }

  const handleConfirm = async (opt = {}) => {
    const { removeEmptyOrNull = true } = opt || {};
    if (removeEmptyOrNull) { // Maybe we want to search where a field is null or empty string so we may not always want to remove empty params
      params = removeEmptyFromObj(params || {});
    }

    let payload = {
      controllerName,
      fnName,
      saveAs,
      flattenData,
      appendDuplicates,
      overwriteDuplicates,
      argArray: [params, ...additionalParamsArray]
    };

    try {
      await exportDataToExcel(payload, cb);
    } catch (e) {
      let { status, statusText, headers = {}, data } = e?.response || {};
      let type = headers['content-type'] || 'application/pdf'; // Extract type from header 

      if (["text/html", "text/plain", "application/json"].some((t) => (type.includes(t)))) {
        let tmpData = await data.text();
        if (type.includes('application/json')) {
          data = JSON.parse(tmpData); // { error }
        } else {
          data = {
            ok: status === 200,
            statusCode: status,
            statusMessage: tmpData,
          };
        }
      }
      cb({ status, statusText, data });
    }
    return handleClose();
  };

  return (<>
    <IconButton variant="falcon-default" size="sm" icon="external-link-alt" transform="shrink-3" onClick={handleOpen}>
      <span className="d-none d-sm-inline-block ms-1">Export Found</span>
    </IconButton>
    <Modal show={modalIsShown} onHide={handleClose}  {...props} >
      <Modal.Header closeButton>
        <Modal.Title as="h5">Confirm Export</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Button variant={hasRowsSelected ? 'secondary' : 'primary'} className="w-100" onClick={handleConfirm} >
          Export {hasRowsSelected ? "Just Selected" : "All of Search"}
        </Button>
      </Modal.Body>
    </Modal>
  </>);
};

ExportReportButton.propTypes = {
  cb: PropTypes.func,
  controllerName: PropTypes.string,
  fnName: PropTypes.string,
  saveAs: PropTypes.string,
  params: PropTypes.object,
  additionalParamsArray: PropTypes.array,
  flattenData: PropTypes.bool,
  appendDuplicates: PropTypes.bool,
  overwriteDuplicates: PropTypes.bool,
};

export default ExportReportButton;