import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';
import AppContext, { AuthContext } from 'context/Context';
import { accountServiceAPI, serviceAPI, documentAPI } from 'utils/api/fulcrum-api';
import { sleep } from 'utils/functions';
import { Form, ListGroup, Table, Button, Card } from 'react-bootstrap';
import ExecuteServiceAgreementDocument from './ExecuteServiceAgreementDocument';
import UploadServiceAgreementDocument from './UploadServiceAgreementDocument';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';

const Service = ({ account, service = {}, accountService = {}, refreshAccountServices = () => { } }) => {
  const [accountServiceAgreements, setAccountServiceAgreements] = useState([]);
  const [serviceAgreements, setServiceAgreements] = useState([]);
  const [executeServiceAgreementModalState, setExecuteServiceAgreementModalState] = useState(false);
  const [selectedServiceAgreement, setSelectedServiceAgreement] = useState({});
  const [showUploadServiceAgreementDocument, setShowUploadServiceAgreementDocument] = useState(false);

  // Terminate service agreement
  const terminateServiceAgreement = async (serviceAgreement) => {
    Swal.fire({
      title: 'Terminate service agreement?',
      text: 'This will terminate the service agreement and will disable acces to the service. Would you like to continue?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, terminate it!'
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          let result = await accountServiceAPI.terminateMyAccountServiceAgreement(account.id, service.id);
          if (!result?.data) {
            throw new Error('No account service found');
          }
          refreshAccountServices();
        } catch (error) {
          console.log(error);
        }
      }
    });
  };

  // Download Service Agreement Document
  const downloadServiceAgreementDocument = async (serviceAgreement, file_id) => {
    if (!file_id) {
      return alert('No file found to download. Please reach out for assistance.');
    }
    try {
      let result = await documentAPI.downloadDocuments(account?.id || null, [file_id], (response) => {
        if (response?.status !== 200) {
          throw new Error('No account service found');
        }
      });
    } catch (error) {
      toast.error('Unable to download service agreement document', { theme: 'colored' });
      console.log(error);
    }
  };

  useEffect(() => {
    if (service?.service_agreements) {
      setServiceAgreements(JSON.parse(service.service_agreements));
    }
  }, []);

  return (
    <div className=" my-3 px-3">
      <div className={`px-3 py-3 border bg-300`}>
        <h5>{service?.label || ''}</h5>
        <span>{service?.description || ''}</span>
      </div>
      <div className="p-3 border">
        <span>
          Status: {('active' in accountService && accountService.active) ? <span className="text-success">Active</span> : <span className="text-danger">Not Active</span>}
          {
            ("active" in accountService && accountService.active)
              ?
              <Button variant="falcon-danger float-end" onClick={() => terminateServiceAgreement(accountService)}>Terminate Service</Button>
              :
              <></>
          }
        </span>
        <Table>
          <thead>
            <tr>
              <th>Service Agreement</th>
              <th>Status</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {
              serviceAgreements.map((serviceAgreement, index) => (
                <tr key={`${index}_service_agreement_${serviceAgreement?.my_form_id || null}`}>
                  <td>{serviceAgreement?.title || ''}</td>
                  <td>
                    {
                      accountService?.service_agreements
                        // accountService?.service_agreements?.current
                        ?
                        accountService.service_agreements.find(sa => sa.title === serviceAgreement.title && sa.status === "SIGNED")?.status ||
                        accountService.service_agreements.find(sa => sa.title === serviceAgreement.title && sa.status === "VOID")?.status ||
                        "PENDING"
                        // accountService.service_agreements.current.find(sa => sa.title === serviceAgreement.title)?.status || 'PENDING'
                        :
                        'PENDING'
                    }
                  </td>
                  <td>
                    {
                      ((accountService?.service_agreements && accountService.service_agreements.find(sa => sa.title === serviceAgreement.title && sa.status === "SIGNED")?.status === 'SIGNED')) ?
                        // ((accountService?.service_agreements?.current && accountService.service_agreements.current.find(sa => sa.title === serviceAgreement.title)?.status === 'COMPLETED')) ?
                        <>
                          <Button variant="link" size="sm" onClick={() => {
                            let file_id = accountService.service_agreements.find(sa => sa.title === serviceAgreement.title && sa.status === "SIGNED")?.file_id;
                            downloadServiceAgreementDocument(serviceAgreement, file_id)
                          }
                          }>Download Signed Agreement</Button>
                        </>
                        :
                        <>
                          <Button variant="link" size="sm" onClick={async () => { setSelectedServiceAgreement(serviceAgreement); await sleep(1000); setExecuteServiceAgreementModalState(true) }}>Sign</Button>
                          <Button variant="link" size="sm" onClick={() => { setSelectedServiceAgreement(serviceAgreement); setShowUploadServiceAgreementDocument(true) }}>Upload</Button>
                        </>
                    }
                  </td>
                </tr>
              ))
            }
          </tbody>
        </Table>

        <ExecuteServiceAgreementDocument
          account={account}
          service={service}
          refreshAccountServices={refreshAccountServices}
          selectedServiceAgreement={selectedServiceAgreement}
          setSelectedServiceAgreement={setSelectedServiceAgreement}
          modalState={executeServiceAgreementModalState}
          setModalState={setExecuteServiceAgreementModalState}
        />

        <UploadServiceAgreementDocument
          account={account}
          service={service}
          selectedServiceAgreement={selectedServiceAgreement}
          modalState={showUploadServiceAgreementDocument}
          setModalState={setShowUploadServiceAgreementDocument}
          refreshAccountServices={refreshAccountServices} />
      </div>
    </div>
  );
}


export default Service;