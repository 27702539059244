import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Modal, Row, Col, Alert, ListGroup, Spinner } from 'react-bootstrap';
import { useNavigate, Link } from 'react-router-dom';
import AppContext from 'context/Context';
import { assureAPI, userAPI, accountAPI } from 'utils/api/fulcrum-api';

const GettingStarted = ({
	setModalState = () => { },
	user = {},
	slide,
	setScheduleADemoModalState = (() => { }),
	setSlide = (() => { })
}) => {


	return (
		<div>
			<h5>Getting Started</h5>
			<br />
			<p>
				Enrollment in Atlasware is easy and only takes a few minutes. This onboarding wizard will assist you through the process every step of the way. Atlasware adds a lot of value to your business by automating tedious tasks and providing you with the tools to make better business decisions.
			</p>

			<p>
				To get started, you'll need to have the following information ready:
			</p>
			<ul>
				<li>Your credentials to the Electronic Records Express (“ERE”) platform,</li>
				<li>access to the email address or phone number that receives two-factor authentication codes from the ERE, and</li>
				<li>a phone number and/or email address you would like to receive two-factor authentication codes in the future.</li>
			</ul>

			<p>
				Before finalizing the onboarding process, you will be required to log into your ERE account to complete enrollment in Atlasware.
				Be sure to have your ERE credentials ready before proceeding.
			</p>

			<br />
			<div className="text-center">
				<Button
					variant="primary"
					onClick={() => setSlide(slide + 1)}
					size="lg"
				>
					Let's get started!
				</Button>
			</div>
		</div>
	);
};

export default GettingStarted;