export const mainRoutes = {
  label: '',
  viewPermission: null,
  labelDisable: true,
  children: [
    {
      name: 'Dashboard',
      icon: 'home',
      to: '/dashboard',
      active: true
    },
    {
      name: 'Inbound Leads',
      icon: 'user',
      to: '/inbound-leads',
      active: true
    },
    {
      name: 'Claimant Folders',
      icon: 'user',
      to: '/claimant-folders',
      active: true
    },
    {
      name: 'Task Manager',
      icon: 'calendar-day',
      to: '/task-manager',
      active: true
    },
    {
      name: 'Documents',
      icon: 'file',
      active: true,
      children: [
        // {
        //   name: 'Form Generator',
        //   to: '/form-generator',
        //   active: true
        // },
        {
          name: 'My Forms',
          to: '/my-forms',
          active: true
        },
        // {
        //   name: 'Document Generator',
        //   to: '/document-generator',
        //   active: true
        // },
        // {
        //   name: 'Upload Document Tool',
        //   to: '/upload-document-tool',
        //   active: true
        // },
        {
          name: 'Documents',
          to: '/documents',
          active: true
        }
      ]
    },
    {
      name: 'Inbox/Outbox',
      icon: 'envelope-open',
      to: '/inbox-outbox',
      active: true
    },
    // {
    //   name: 'Inbox/Outbox',
    //   icon: 'envelope-open',
    //   active: true,
    //   children: [
    //     {
    //       name: 'Inbox',
    //       to: '/inbox',
    //       active: true
    //     },
    //     {
    //       name: 'Outbox',
    //       to: '/outbox',
    //       active: true
    //     },
    //     {
    //       name: 'Create Correspondence',
    //       to: '/temp/email/compose',
    //       active: true
    //     }
    //   ]
    // },
    {
      name: 'Atlasware',
      icon: 'file',
      to: '/atlasware',
      active: true
    },
    // {
    //   name: 'Atlasware',
    //   icon: 'file',
    //   active: true,
    //   children: [
    //     {
    //       name: 'Case Files',
    //       to: '/atlasware/case-files',
    //       active: true
    //     },
    //     {
    //       name: 'Uploader',
    //       to: '/atlasware/uploader',
    //       active: true
    //     },
    //     {
    //       name: 'Status Reports',
    //       to: '/atlasware/status-reports',
    //       active: true
    //     }
    //   ]
    // },
    {
      name: 'Hearings',
      icon: 'calendar-alt',
      to: '/hearings',
      active: true
    },
    // {
    //   name: 'Hearings',
    //   icon: 'calendar-alt',
    //   active: true,
    //   children: [
    //     {
    //       name: 'Hearing Entries',
    //       to: '/hearings',
    //       active: true
    //     },
    //     {
    //       name: 'Hearing Entry Creator',
    //       to: '/hearings/creator',
    //       active: true
    //     },
    //     {
    //       name: 'Hearing Metrics',
    //       to: '/hearings/metrics',
    //       active: true
    //     }
    //   ]
    // },
    {
      name: 'Briefs',
      icon: 'file',
      to: '/briefs',
      active: true
    },
    {
      name: 'Medical Records',
      icon: 'file',
      to: '/medical-records',
      active: true
    },
    {
      name: 'Reports',
      icon: 'chart-line',
      to: '/reports',
      active: true
    }

  ]
};
export const adminRoutes = {
  label: 'Admin',
  userTypeAccess: ['SUPER_ADMIN', 'ADMIN', 'Super Admin', 'Admin'],
  requiredPermission: 'ADMIN_AREA_ACCESS',
  children: [
    {
      name: 'Account',
      icon: 'lock',
      to: '/account-management',
      active: true
    },
    {
      name: 'Custom Fields',
      icon: 'file',
      to: '/custom-fields',
      active: true
    },
    {
      name: 'Lead Vendors',
      icon: 'file',
      to: '/lead-vendors',
      active: true
    },
    {
      name: 'Users',
      icon: 'users',
      to: '/user-management',
      active: true
    }
  ]
};

export const superAdminRoutes = {
  label: 'Site Admin',
  userTypeAccess: ['SUPER_ADMIN'],
  requiredPermission: 'SUPER_ADMIN_AREA_ACCESS',
  children: [
    {
      name: 'Account Management',
      icon: 'lock',
      to: '/admin/account-management',
    },
    {
      name: 'User Management',
      icon: 'users',
      to: '/admin/user-management',
    },
    {
      name: 'Claimant Management',
      icon: 'user',
      to: '/admin/claimant-management',
    },
    {
      name: 'Task Management',
      icon: 'calendar-day',
      to: '/admin/task-management',
    },
    {
      name: 'Document Management',
      icon: 'file',
      to: '/admin/document-management',
    }
  ]
};

export const documentationRoutes = {
  label: 'Documentation',
  children: [
    {
      name: 'Privacy Policy',
      icon: 'file',
      to: '/documentation/privacy-policy',
      active: true
    }
  ]
};

export default [
  mainRoutes,
  adminRoutes,
  superAdminRoutes,
  documentationRoutes
];
