import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import SignatureModal from 'components/app/SignatureModal';
import { getClientDigitalInformation } from 'utils/ipify';

// NOTE: @todo This component seems completely pointless. Why not just use SignatureModal directly instead of this middleman pointless component?
const GenericFormView = ({ content } = {}) => {
	const [loading, setLoading] = useState(true);
	const [showSignatureModal, setShowSignatureModal] = useState(false);
	const [selectedSignatureElement, setSelectedSignatureElement] = useState(null);
	const [theContent, setTheContent] = useState(content || '');

	const genericFormViewRef = useRef({});

	// Apply Signature
	const applySignature = (sigData) => {
		// alert(sigData.signature);
		selectedSignatureElement.setAttribute('src', sigData.signature);

		// const { name, value, id, dataset } = selectedSignatureElement;
		const { id, dataset } = selectedSignatureElement;
		const model = dataset?.model || null;

		setShowSignatureModal(false);
	};

	useEffect(() => {
		if (theContent) {
			document.querySelector('#my-form-response-container').innerHTML = theContent || '';

			// const componentDivFound = document.getElementById('#testcomponent');
			// React.render(React.createElement(MyTestReactView), document.getElementById('placeholderForReactView'));

			// if (componentDivFound) {
			// 	// componentDivFound.innerHTML = <testComponent />;
			// 	// React.render(<testComponent />, document.getElementById('#testcomponent'));
			// }

			const signatures = document.querySelectorAll('.signature');
			signatures.forEach((signature) => {
				// signature.classList.add('border');
				genericFormViewRef.current[signature.name] = signature;
				signature.addEventListener('click', (e) => {
					setSelectedSignatureElement(signature);
					// const { name, value, id, dataset } = e.target;
					const { name, value } = e.target;
					// const model = dataset.model;
					genericFormViewRef.current[name].value = value;
					genericFormViewRef.current[name].setAttribute('value', value);

					setShowSignatureModal(true);
				});
			});

			const testButton = document.querySelectorAll('.test_button');
			testButton.forEach((button) => {
				genericFormViewRef.current[button.name] = button;
				button.addEventListener('click', (e) => {
					e.stopPropagation();
					e.preventDefault();
					setSelectedSignatureElement(button);
					// const { name, value, id, dataset } = e.target;
					const { name, value } = e.target;
					// const model = dataset.model;
					genericFormViewRef.current[name].value = value;
					genericFormViewRef.current[name].setAttribute('value', value);

					setShowSignatureModal(true);
				});
			});

			const inputs = document.querySelectorAll('#my-form-response-container input');
			inputs.forEach((element) => {
				genericFormViewRef.current[element.name] = element;
				element.addEventListener('change', (e) => {
					const { value, id, dataset } = e.target;
					const model = dataset.model;
					if (id.startsWith('radio_no_manipulation')) {
						return;
					}
					genericFormViewRef.current[id].value = value;
					genericFormViewRef.current[id].setAttribute('value', value);
				}, false);
			});
			setLoading(false);
		}
	}, [theContent]);

	return (
		<div>
			<Form id="my-form-response-form" className="pb-5">
				<div id="my-form-response-container">
					{/* <InnerHTML html={content} /> */}
				</div>
			</Form>

			<SignatureModal
				modalState={showSignatureModal}
				setModalState={setShowSignatureModal}
				callBack={applySignature}
			/>
		</div>
	);
};

GenericFormView.propTypes = {
	content: PropTypes.string,
};

export default GenericFormView;