import React, { useState, useEffect } from 'react';
import { Button, Modal, Dropdown, Form, Alert } from 'react-bootstrap';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import 'leaflet/dist/leaflet.css';
import { hearingOfficeAPI } from 'utils/api/fulcrum-api';

const OFFICE_REGIONS = {
    'R1': 'Boston',
    'R2': 'New York',
    'R3': 'Philadelphia',
    'R4': 'Atlanta',
    'R5': 'Chicago',
    'R6': 'Dallas',
    'R7': 'Kansas City',
    'R8': 'Denver',
    'R9': 'San Francisco',
    'R10': 'Seattle'
};

const HearingOfficeMapModal = ({ modalState, setModalState = () => { }, claimant, updateCaseInformation = () => { } }) => {
    const [offices, setOffices] = useState([]);
    const [filteredOffices, setFilteredOffices] = useState([]);
    const [selectedRegion, setSelectedRegion] = useState(null);
    const [errors, setErrors] = useState([]);
    const [submitting, setSubmitting] = useState(false);
    let defaultFormData = {
        selectedRegion: null,
        selectedOffice: null,
        selectedOfficeDetails: null
    };
    const [formData, setFormData] = useState(defaultFormData);

    const getRelatedData = async () => {
        try {
            const { data } = await hearingOfficeAPI.searchHearingOffice({});
            if (!data) return;
            // console.log(data);
            setOffices(data.rows);
        } catch (error) {
            console.log("Couldn't fetch Hearing Office data:", error);
        }
    }

    // Get selected office details
    const getSelectedOfficeDetails = (officeId) => {
        let selectedOffice = offices.find((officeObj) => officeObj.id === officeId);
        console.log({
            selectedOffice,
            officeId,
            offices
        })
        setFormData({ ...formData, selectedOffice: officeId, selectedOfficeDetails: selectedOffice });
    }

    // Get filtered offices
    const getFilteredOffices = (region) => {
        let newFilteredOffices = offices.filter((officeObj) => officeObj.id.split('_')[0] === region);

        setFilteredOffices(newFilteredOffices);
    }

    const handleSelect = (targetId) => {
        let sfo = formData?.selectedOfficeDetails;
        setErrors([]);
        let newErrors = [];
        if (!sfo) {
            newErrors.push('Please select a hearing office');
        }

        if (newErrors.length > 0) {
            setErrors(newErrors);
            return;
        }
        setSubmitting(true);
        updateCaseInformation({
            office_with_jurisdiction_mailing_address: sfo.address_line_2 ? `${sfo.address_line_2}, ${sfo.address_line_1}` : sfo.address_line_1,
            office_with_jurisdiction_mailing_city: sfo.city,
            office_with_jurisdiction_mailing_state: sfo.state,
            office_with_jurisdiction_mailing_zip: sfo.zip,
            office_with_jurisdiction_phone: sfo.phone,
            office_with_jurisdiction_fax: sfo.fax
        });
        handleClose();
        setSubmitting(false);
    }

    // handle modal show
    const handleShow = () => {
        setErrors([]);
        setModalState(true);
    };

    // Handle modal close
    const handleClose = () => {
        setErrors([]);
        setFormData(defaultFormData);
        setModalState(false);
    };


    useEffect(() => {
        getRelatedData();
    }, []);

    return (
        <Modal show={modalState} onHide={handleClose} onShow={handleShow} size="lg">
            <Modal.Header closeButton>
                <Modal.Title as="h5">Find a Hearing Office</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Alert variant="info">
                    <p>
                        You can use the form below or reference the hearing offices at <strong><a href="https://www.ssa.gov/appeals/ho_locator.html">https://www.ssa.gov/appeals/ho_locator.html</a></strong>
                    </p>
                </Alert>

                {
                    errors.length > 0 &&
                    <Alert variant="danger">
                        <ul>
                            {errors.map((error, index) => (
                                <li key={index}>{error}</li>
                            ))}
                        </ul>
                    </Alert>
                }
                {/* <MapContainer center={[40, -100]} zoom={4} scrollWheelZoom={true} style={{ height: '500px' }}>
                    <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    {offices?.length > 0 && <MarkerClusterGroup chunkedLoading={true} spiderfyOnMaxZoom={false}>
                        {offices.map((officeData) => (
                            <Marker
                                key={officeData.id}
                                position={[officeData.r_latitude ? officeData.r_latitude : officeData.latitude, officeData.r_longitude ? officeData.r_longitude : officeData.longitude]}
                            >
                                <Popup>
                                    {`${officeData.title} ${officeData.site_code}`} - <Button onClick={() => handleSelect(officeData.id)}>Select</Button>
                                </Popup>
                            </Marker>
                        ))}
                    </MarkerClusterGroup>}
                </MapContainer> */}

                <Form>
                    <Form.Group controlId="region">
                        <Form.Label>Region</Form.Label>
                        <Form.Select
                            value={formData.selectedRegion}
                            name="selectedRegion"
                            disabled={submitting}
                            onChange={(e) => {
                                getFilteredOffices(e.target.value);
                                setFormData({
                                    ...formData,
                                    selectedRegion: e.target.value
                                });
                            }}
                        >
                            <option value="">Select a Region</option>
                            {Object.keys(OFFICE_REGIONS).map((rIndex) => (
                                <option key={rIndex} value={rIndex}>{OFFICE_REGIONS[rIndex]}</option>
                            ))}
                        </Form.Select>
                    </Form.Group>
                    {
                        formData.selectedRegion && (
                            <div>
                                <Form.Group controlId="office">
                                    <Form.Label>Office</Form.Label>
                                    <Form.Select
                                        name="selectedOffice"
                                        disabled={submitting}
                                        value={formData.selectedOffice}
                                        onChange={(e) => {
                                            getSelectedOfficeDetails(e.target.value);
                                        }}
                                    >
                                        <option value="">Select an Office</option>
                                        {
                                            filteredOffices.map(o => (
                                                <option key={`${o.id}_office`} value={o.id}>{`${o.title} ${o.site_code} ${o.office_type}`}</option>
                                            ))
                                        }
                                    </Form.Select>
                                </Form.Group>

                                {
                                    formData.selectedOffice && (
                                        <div className="px-2 py-3 my-3 border">
                                            <h5>Selected Office Details:</h5>
                                            <hr />
                                            <div className="py-3 px-3">
                                                <span className="fw-bold">{formData.selectedOfficeDetails?.office_name || ""}</span>
                                                <br />
                                                <span>{formData.selectedOfficeDetails?.address_line_1 || ""}</span>
                                                <br />
                                                <span>{formData.selectedOfficeDetails?.address_line_2 || ""}</span>
                                                <br />
                                                <span>{formData.selectedOfficeDetails?.city || ""}</span>, <span>{formData.selectedOfficeDetails?.state || ""}</span> <span>{formData.selectedOfficeDetails?.zip || ""}</span>

                                                <br />
                                                <br />
                                                <span className="fw-bold">Phone:</span> <span>{formData.selectedOfficeDetails?.phone || ""}</span>
                                                <br />
                                                <span className="fw-bold">Fax:</span> <span>{formData.selectedOfficeDetails?.fax || ""}</span>
                                                <br />
                                                <span className="fw-bold">eFile Fax:</span> <span>{formData.selectedOfficeDetails?.efile_fax || ""}</span>

                                                <br />
                                                <br />
                                                <span className="fw-bold">Office Hours:</span> <span>{formData.selectedOfficeDetails?.office_hours || ""}</span>

                                                <br />
                                                <br />
                                                <span className="fw-bold">Directions:</span>
                                                <br />
                                                <span>{formData.selectedOfficeDetails?.field_offices || ""}</span>
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                        )
                    }



                </Form>
            </Modal.Body>
            <Modal.Footer>
                {
                    formData.selectedOffice && (
                        <Button disabled={submitting} variant="success" onClick={() => handleSelect()}>{submitting ? "Selecting location..." : "Select Location"}</Button>
                    )
                }
                <Button
                    variant="secondary"
                    onClick={() => handleClose()}
                    disabled={submitting}
                >
                    Cancel
                </Button>
            </Modal.Footer>
        </Modal>
    );
}


export default HearingOfficeMapModal;