import React, { useContext, useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import NavbarTop from 'components/navbar/top/NavbarTop';
import NavbarVertical from 'components/navbar/vertical/NavbarVertical';
import AppContext, { AuthContext } from 'context/Context';
import Footer from 'components/footer/Footer';
import ProductProvider from 'components/app/e-commerce/ProductProvider';
import classNames from 'classnames';
import LookupTableLoader from 'components/LookupTableLoader';
import auth from 'utils/auth';

const MainLayout = () => {
  const { hash, pathname } = useLocation();
  const isKanban = pathname.includes('kanban');
  // const isChat = pathname.includes('chat');
  const [authState, setAuthState] = useState({});

  const [checkingAuth, setCheckingAuth] = useState(true);

  const {
    permissionsDispatch
  } = useContext(AuthContext);

  const {
    config: { FULCRUM_isFluid, navbarPosition }
  } = useContext(AppContext);

  // Check is logged in
  const checkIsLoggedIn = async () => {
    try {
      let loggedIn = await auth.checkSession(permissionsDispatch);
      if (loggedIn) {
        setAuthState({ ...authState, isLoggedIn: true });
      } else {
        setAuthState({ ...authState, isLoggedIn: false });
      }
    } catch (error) {
      console.log(error);
    }
    setCheckingAuth(false);
  };

  useEffect(() => {
    setCheckingAuth(true);
    checkIsLoggedIn();

    setTimeout(() => {
      if (hash) {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ block: 'start', behavior: 'smooth' });
        }
      }
    }, 0);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    checkIsLoggedIn();
  }, [pathname]);

  if (checkingAuth) {
    return <></>;
  }

  return (
    <div className={FULCRUM_isFluid ? 'container-fluid' : 'container'}>
      {(navbarPosition === 'vertical' || navbarPosition === 'combo') && (
        <NavbarVertical />
      )}
      <LookupTableLoader />
      <ProductProvider>
        <div className={classNames('content', { 'pb-0': isKanban })}>
          <NavbarTop />
          {/*------ Main Routes ------*/}
          <Outlet />
          {!isKanban && <Footer />}
        </div>
      </ProductProvider>
    </div>
  );
};

export default MainLayout;
