import React, { useContext, useState, useRef, useCallback } from 'react';
import { Button, Form, Modal, Table, Alert, Spinner } from 'react-bootstrap';
import AppContext from 'context/Context';
import { documentAPI, inboxOutboxAPI } from 'utils/api/fulcrum-api';
import ClaimantQuickFind from 'components/claimants/find-a-claimant/ClaimantQuickFind';
import { useDropzone } from 'react-dropzone';
import Flex from 'components/common/Flex';
import cloudUpload from 'assets/img/icons/cloud-upload.svg';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import moment from 'moment';
import auth from 'utils/auth';

const UploadCorrespondence = ({
	account_id = auth.getProfile()?.data?.account_id,
	modalState,
	setModalState,
	refreshParent = () => { },
}) => {
	const {
		config: { isDark }
	} = useContext(AppContext);
	const defaultFormData = {
		document_type_id: "CORRESPONDENCE_RECEIVED",
		title: `Upload, ${moment().format('MM-DD-YYYY hh:mm A')}`,
		description: '',
		sender: '',
	};

	const [isUploading, setIsUploading] = useState(false);
	const [documentTypes, setDocumentTypes] = useState([]);
	const [formData, setFormData] = useState(defaultFormData);
	const [errorMessages, setErrorMessages] = useState([]);
	const [claimant, setClaimant] = useState(null);
	const formRef = useRef(null);

	const [selectedFiles, setSelectedFiles] = useState([]);

	const onDrop = useCallback(acceptedFiles => {
		setSelectedFiles([...selectedFiles, ...acceptedFiles])
	}, [selectedFiles])

	const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
		onDrop,
	})

	const files = selectedFiles.map(file => (
		<li key={file.path}>
			{file.path} - {file.size} bytes
		</li>
	));


	const removeAll = () => {
		setSelectedFiles([])
	}

	const handleChange = ({ target }) => {
		let { name, value } = target;
		let additionalValues = {};

		setFormData({ ...formData, ...additionalValues, [name]: value });
	};


	const handleSubmit = async e => {
		e.preventDefault();

		let errors = [];
		if (!formData?.sender) {
			toast.error('Please enter sender.', { theme: 'colored' });
			errors.push('Please enter sender.');
		}

		if (!formData?.document_type_id) {
			toast.error('Please select a document type.', { theme: 'colored' });
			errors.push('Please select a document type.');
		}

		if (!formData?.title) {
			toast.error('Please enter title.', { theme: 'colored' });
			errors.push('Please enter title.');
		}

		if (!acceptedFiles.length) {
			toast.error('Please select a file.', { theme: 'colored' });
			errors.push('Please select a file.');
		}

		if (errors.length) {
			setErrorMessages(errors);
			return;
		}

		setIsUploading(true);

		let formDataObject = new FormData(formRef.current);

		try {
			for (let i = 0; i < acceptedFiles.length; i++) {
				let file = acceptedFiles[i];
				file.originalFilename = file.path;
				formDataObject.append("files", file);
			}


			// Append claimant
			formDataObject.append('claimant', JSON.stringify(claimant));

			const response = await inboxOutboxAPI.uploadCorrespondence(account_id, formDataObject);

			Swal.fire({
				icon: 'success',
				text: 'Success.',
				showConfirmButton: true,
				background: false,
				backdrop: false
			});
		} catch (error) {
			Swal.fire({
				icon: 'error',
				text: error,
				showConfirmButton: true,
				background: false,
				backdrop: false
			});
			return false;
		}
		setIsUploading(false);
		refreshParent();
		handleClose();
	};

	// Handle Claimant Selection// Handle Claimant Selection
	const handleClaimantSelection = (selectedclaimant = []) => {
		setClaimant(selectedclaimant[0]?.id ? {
			id: selectedclaimant[0].id,
			acitve_case_id: selectedclaimant[0].active_case_id,
			first_name: selectedclaimant[0].first_name,
			last_name: selectedclaimant[0].last_name,
		} : {});
	};

	// Get related data @TODO: Move this to a context
	const getRelatedData = async () => {
		try {
			let docTypeResults = await documentAPI.searchDocumentTypes();
			if (!docTypeResults?.data?.rows) {
				throw new Error('Unable to get documents types.');
			}

			setDocumentTypes(docTypeResults.data.rows);
		} catch (error) {
			alert(error);
		}
	};

	// Handle Show
	const handleShow = () => {
		setFormData({
			document_type_id: "CORRESPONDENCE_RECEIVED",
			title: `Upload, ${moment().format('MM-DD-YYYY hh:mm A')}`,
			description: '',
			sender: '',
		});
		removeAll();
		getRelatedData();
	};

	// Handle Close
	const handleClose = () => {
		setFormData(defaultFormData);
		setClaimant([]);
		removeAll();
		setModalState(false);
	};


	return (
		<Modal
			show={modalState}
			onHide={handleClose}
			onShow={handleShow}
			contentClassName="border"
		>
			<Form ref={formRef} onSubmit={handleSubmit}>
				<Modal.Header
					closeButton
					closeVariant={isDark ? 'white' : undefined}
					className="bg-light px-card border-bottom-0"
				>
					<Modal.Title as="h5">Upload A Correspondence</Modal.Title>
				</Modal.Header>
				<Modal.Body className="p-card">
					<Alert variant="danger" show={errorMessages.length}>
						<ul className="mb-0">
							{errorMessages.map((error, index) => (
								<li key={index}>{error}</li>
							))}
						</ul>
					</Alert>
					<Form.Group className="mb-3" controlId="sender">
						<Form.Label className="fs-0">Sender</Form.Label>
						<Form.Control
							name="sender"
							value={formData.sender}
							onChange={handleChange}
							required
							disabled={isUploading}
						/>
					</Form.Group>

					<Form.Group className="mb-3" controlId="document_type_id">
						<Form.Label className="fs-0">Document Type</Form.Label>
						<Form.Select
							name="document_type_id"
							value={formData.document_type_id}
							onChange={handleChange}
							required
							disabled={isUploading}
						>
							<option value={""}>--Select One--</option>
							{Array.isArray(documentTypes) && documentTypes.map((docType, index) => (
								<option key={index} value={docType.id}>{docType.type}</option>
							))}
						</Form.Select>
					</Form.Group>

					<Form.Group className="mb-3" controlId="title">
						<Form.Label className="fs-0">Title</Form.Label>
						<Form.Control
							type="text"
							name="title"
							value={formData.title}
							required
							onChange={handleChange}
							disabled={isUploading}
						/>
					</Form.Group>

					<div className="py-3 border-bottom border-top my-3">


						{
							claimant?.id ?
								(
									<>
										<span>Selected Claimant: </span><span className="fw-bold">{claimant?.first_name} {claimant?.last_name} </span>
										{
											!isUploading && (
												<ClaimantQuickFind
													buttonLabel={'Change Claimant'}
													buttonVariant={'link'}
													handleClaimantSelection={handleClaimantSelection}
													requiredSearchParams={{}}
													defaultSearchParams={{}}
													allowMultipleSelect={false}
												/>
											)
										}
									</>
								) : (
									<>
										<span className="">Assign a Claimant:</span>
										{
											!isUploading && (
												<ClaimantQuickFind
													buttonLabel={'Search Claimants'}
													buttonVariant={'link'}
													handleClaimantSelection={handleClaimantSelection}
													requiredSearchParams={{}}
													defaultSearchParams={{}}
													allowMultipleSelect={false}
												/>
											)
										}
									</>
								)
						}
					</div>

					<div {...getRootProps({ className: `dropzone-area py-6 ${isUploading && 'd-none'}` })}>
						<input {...getInputProps({ multiple: false })} />
						<Flex justifyContent="center">
							<img src={cloudUpload} alt="" width={25} className="me-2" />
							<p className="fs-0 mb-0 text-700">Drop your file here</p>
						</Flex>
					</div>
					<div className="mt-3">
						{selectedFiles.length > 0 && (
							<>
								<h6>File</h6>
								<ul>{files}</ul>
							</>
						)}
					</div>

				</Modal.Body>
				<Modal.Footer className="bg-light px-card border-top-0">
					{
						isUploading ? (
							<Button
								variant="primary"
								className="px-4 mx-0"
								type="button"
								disabled
							>
								<Spinner animation="border" size="sm" className="me-2" />
								Uploading...
							</Button>
						) : (
							<Button
								variant="primary"
								className="px-4 mx-0"
								type="submit"
							>
								Upload Correspondence
							</Button>
						)
					}
				</Modal.Footer>
			</Form>
		</Modal>
	);
};


export default UploadCorrespondence;