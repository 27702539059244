import React, { useState } from 'react';
import { claimantAPI } from 'utils/api/fulcrum-api';
import { toast } from 'react-toastify';
import { Modal, Button, Form, Spinner, Alert } from 'react-bootstrap';

const SendScreeningDocumentsButton = ({ modalState, setModalState = () => { }, claimant }) => {
	const [submitting, setSubmitting] = useState(false);
	const defaultFormData = {
		mode_of_transmission: null
	};
	const [formData, setFormData] = useState(defaultFormData);

	// Handle form change
	const handleChange = (e) => {
		let { name, value } = e.target;
		setFormData({ ...formData, [name]: value });
	};

	// Handle submit
	const handleSubmit = async () => {
		setSubmitting(true);
		try {

			if (formData.mode_of_transmission === "sms" && !claimant?.primary_phone?.phone) {
				toast.error('This claimant does not have a primary phone selected.', { theme: 'colored' });
				setSubmitting(false);
				return;
			}

			if (formData.mode_of_transmission === "email" && !claimant?.primary_email?.email) {
				toast.error('This claimant does not have a primary email selected.', { theme: 'colored' });
				setSubmitting(false);
				return;
			}

			let result = await claimantAPI.sendClaimantScreeningDocuments(claimant.id, formData);
			if (!result?.message) {
				if (result?.response?.error) {
					throw new Error(result.response.error);
				} else {
					throw new Error('Unable to send screening documents.');
				}
			}

			toast.success('Screening documents sent', { theme: 'colored' });
			handleClose();
		} catch (error) {
			toast.error(error?.message || 'Unable to send screending documents.', { theme: 'colored' });
			console.log(error);
		}
		setSubmitting(false);
	};

	// Handle modal close
	const handleClose = () => {
		setFormData(defaultFormData);
		setModalState(false);
	};

	// Handle modal open
	const handleShow = () => {
		setModalState(true);
	};

	return (
		<>

			<Modal
				show={modalState}
				onShow={handleShow}
				onHide={handleClose}
				size="lg"
				aria-labelledby="contained-modal-title-vcenter"
			>
				<Modal.Header closeButton>
					<Modal.Title id="contained-modal-title-vcenter">
						Send Screening Documents
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<p>Send the claimant screening documents. Please select one of the following:</p>
					<Form>
						<Form.Group controlId="formBasicCheckbox">
							<Form.Check
								type="radio"
								label="Send as an email"
								checked={formData.mode_of_transmission === 'email'}
								name="mode_of_transmission"
								id="email"
								disabled={submitting}
								onChange={(e) => {
									setFormData({ ...formData, [e.target.name]: e.target.id });
								}}
							/>
							<Form.Check
								type="radio"
								label="Send as a SMS text message"
								checked={formData.mode_of_transmission === 'sms'}
								name="mode_of_transmission"
								id="sms"
								disabled={submitting}
								onChange={(e) => {
									setFormData({ ...formData, [e.target.name]: e.target.id });
								}}
							/>
							<Form.Check
								type="radio"
								label="Send by regular mail"
								checked={formData.mode_of_transmission === 'mail'}
								name="mode_of_transmission"
								id="mail"
								disabled={submitting}
								onChange={(e) => {
									setFormData({ ...formData, [e.target.name]: e.target.id });
								}}
							/>
						</Form.Group>
					</Form>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="outline-secondary" onClick={handleClose}>Close</Button>
					{' '}
					{
						formData.mode_of_transmission !== null &&
							submitting ?
							<Button variant="primary" onClick={handleSubmit} disabled={submitting}>
								<Spinner animation="border" role="status">
									<span className="sr-only">Sending...</span>
								</Spinner>
							</Button>
							:
							<Button variant="primary" onClick={handleSubmit} disabled={submitting}>Send</Button>
					}

				</Modal.Footer>


			</Modal>
		</>
	);
};


export default SendScreeningDocumentsButton;