import React, { useState, useContext } from 'react';
import Swal from 'sweetalert2';
import { Button, Form, Modal, Table } from 'react-bootstrap';
import { userAPI } from 'utils/api/fulcrum-api';
import { toast } from 'react-toastify';
import auth from 'utils/auth';

const FindAUserByType = ({ account_id = auth.getProfile()?.data?.account_id || null, modalState, setModalState = () => { }, userType, label, handleUserSelection = () => { } }) => {
	const [foundUsers, setFoundUsers] = useState([]);

	// Search Users
	const searchUsers = async () => {
		try {
			let result = await userAPI.searchUsersByUserType(userType, { account_id });
			if (!result?.data) {
				throw 'Error searching for representatives';
			}
			setFoundUsers(result?.data);
		} catch (error) {
			console.log(error);
			toast.error(error, { theme: 'colored' });
		}
	};

	// Handle Modal Show
	const handleShow = () => {
		setFoundUsers([]);
		searchUsers();
	};

	// Handle Modal Close
	const handleClose = () => {
		setFoundUsers([]);
		setModalState(false);
	};


	// Handle selected user
	const handleSelectedUser = async (user) => {
		try {
			handleUserSelection(user);
			handleClose();
		} catch (error) {
			console.log(error);
			toast.error(error, { theme: 'colored' });
		}
	};

	return (
		<div>
			<Modal
				show={modalState}
				onShow={handleShow}
				onHide={handleClose}
				backdrop="static"
				size="lg"
			>
				<Modal.Header closeButton>
					<Modal.Title>{label}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{
						foundUsers.length > 0
							?
							<div>
								<h5>Found Users</h5>
								<Table striped bordered hover>
									<thead>
										<tr>
											<th>Name</th>
											<th>Email</th>
											<th>Phone</th>
											<th></th>
										</tr>
									</thead>
									<tbody>
										{
											foundUsers.map((user, index) => {
												return (
													<tr key={index}>
														<td>{user.first_name} {user.last_name}</td>
														<td>{user.email}</td>
														<td>{user.phone}</td>
														<td>
															<Button variant="link" onClick={(e) => { handleSelectedUser(user) }} value={user.id}>Select</Button>
														</td>
													</tr>
												)
											})
										}
									</tbody>
								</Table>
							</div>
							:
							<div>
								<h5>No Users Found</h5>
							</div>
					}
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleClose}>
						Close
					</Button>
				</Modal.Footer>
			</Modal>
		</div>
	);
};

export default FindAUserByType;