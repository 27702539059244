import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import SignatureCanvas from 'react-signature-canvas';
import { toast } from 'react-toastify';
import { myFormsAPI } from 'utils/api/fulcrum-api';


const SignServiceAgreementModal = ({ agreementHtml, handleClose, show, signAgreement, title, selectedService }) => {
    const canvas = useRef(null);
    const [sigData, setSigData] = useState();
    const [modalWidthPx, setModalWidthPx] = useState(500);

    // Get rendered agreement html
    const getAgreementHtml = async () => {
        try {
            console.log("test", selectedService);
            let myFormId = selectedService?.my_form_id || null;
            if (!myFormId) { throw new Error('No myFormId'); };

            let myFormResponse = await myFormsAPI.getMyForm(myFormId);
            console.log(myFormResponse);



        } catch (error) {
            toast.error('Error getting agreement html', { theme: 'colored' });
            console.log(error);
            handleClose();
        }
    };

    // Handle Show
    const handleShow = () => {
        getAgreementHtml();
    };

    useEffect(() => {
        const ele = document.querySelector('.agreement .modal-content');
        if (ele) setModalWidthPx(ele.offsetWidth);
    }, [show]);

    useEffect(() => {
        const ele = document.querySelector('.agreement .modal-body');
        if (ele) ele.innerHTML = agreementHtml;
    }, [agreementHtml, show]);

    return (
        <Modal
            show={show}
            size="lg"
            onHide={handleClose}
            onShow={handleShow}
            className='agreement'
        >
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body />
            <Modal.Footer>
                <div style={{ border: '2px solid black' }}>
                    <SignatureCanvas
                        penColor='blue'
                        ref={canvas}
                        onEnd={() => setSigData(canvas.current.toData())}
                        canvasProps={{ width: modalWidthPx - 40, height: 120 }}
                    />
                </div>
                <Button variant='secondary' onClick={handleClose}>Cancel</Button>
                <Button
                    variant='primary'
                    onClick={() => { signAgreement(sigData); handleClose(); }}
                    disabled={!canvas.current || canvas.current.isEmpty()}
                >Accept Agreement</Button>
            </Modal.Footer>
        </Modal>
    );
}

SignServiceAgreementModal.propTypes = {
    agreementHtml: PropTypes.string,
    handleClose: PropTypes.func,
    show: PropTypes.bool,
    signAgreement: PropTypes.func,
    title: PropTypes.string
}

export default SignServiceAgreementModal;