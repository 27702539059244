import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ListGroup, Dropdown, Button, Form } from 'react-bootstrap';
import moment from 'moment';
import Auth from 'utils/auth';
import { claimantAPI, entityTagAPI } from 'utils/api/fulcrum-api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SoftBadge from 'components/common/SoftBadge';
import { toast } from 'react-toastify';

const COLOR_OPTIONS = [
    { label: "Default", color: "primary" },
    { label: "Secondary", color: "secondary" },
    { label: "Blue", color: "info" },
    { label: "Highlight", color: "warning" },
    { label: "Green", color: "success" },
    { label: "Red", color: "danger" },
    { label: "Light", color: "light" },
    { label: "Dark", color: "dark" },
];

const TagList = ({ entity, setEntity, entity_type, entity_id, tags }) => {
    const [showAddTag, setShowAddTag] = useState(false);
    const [newTag, setNewTag] = useState('');
    const [tagList, setTagList] = useState([]);

    const addTagRef = useRef(null);

    // Get entity tags
    const getEntityTags = async () => {
        try {
            let result = await entityTagAPI.getEntityTags(entity_id, {});
            if (!result?.data?.rows) {
                throw new Error('No tag data returned');
            }

            setTagList(result.data.rows || []);
        } catch (error) {
            console.error(error);
        }
    }

    const createNewTag = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        try {
            let result = await entityTagAPI.createEntityTag(entity_id, {
                tag: newTag,
            });
            if (!result?.data) {
                throw new Error('No tag data returned');
            }

            if (result?.data?.message !== 'Tag already exists for this entity') {
                setTagList([...tagList, result.data]);
                setNewTag('');
            } else {
                toast.warning("Tag already exists for this entity.", { theme: 'colored' });
            }
        } catch (error) {
            toast.error("Unable to create new tag.", { theme: 'colored' });
        }
    };

    // Update tag
    const updateTag = async (tag_id, data) => {
        try {
            let result = await entityTagAPI.updateEntityTag(entity_id, tag_id, data);
            if (!result?.data) {
                throw new Error('No tag data returned');
            }
            setTagList(tagList.map(tag => tag.id === tag_id ? result.data : tag));
        } catch (error) {
            toast.error("Unable to update tag.", { theme: 'colored' });
        }
    };

    // Delete tag
    const deleteTag = async (tag_id) => {
        try {
            let result = await entityTagAPI.deleteEntityTag(entity_id, tag_id);
            if (!result?.message) {
                throw new Error('No tag data returned');
            }
            setTagList(tagList.filter(tag => tag.id !== tag_id));

        } catch (error) {
            toast.error("Unable to delete tag.", { theme: 'colored' });
        }
    };


    useEffect(() => { if (showAddTag) { addTagRef.current.focus() } }, [showAddTag]);
    useEffect(() => {
        getEntityTags();
    }, []);

    return (
        <div>
            <ListGroup horizontal className='flex-wrap'>
                {
                    Array.isArray(tagList) && tagList.map((tag, i) => (
                        <ListGroup.Item key={`tag_i_${tag.id}`} className="border border-0 p-1" style={{ backgroundColor: "transparent" }}>
                            <SoftBadge bg={tag?.color || 'primary'} className='py-0'>
                                <span
                                    className="py-0 word-wrap d-inline-block text-truncate align-middle cursor-pointer"
                                    style={{ maxWidth: "200px" }}
                                    onClick={() => { }}>{tag.tag}</span>
                                <Dropdown className="d-inline-block link-info">
                                    <Dropdown.Toggle
                                        as={Button}
                                        variant="link-info"
                                        size="sm"
                                        className="dropdown-caret-none"
                                    >
                                        <FontAwesomeIcon icon="ellipsis-v" inverse />
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        {
                                            COLOR_OPTIONS.map(option => (
                                                <Dropdown.Item
                                                    key={option.color}
                                                    disabled={tag.color === option.color}
                                                    onClick={() => {
                                                        updateTag(tag.id, {
                                                            color: option.color
                                                        });
                                                    }}>{option.label}</Dropdown.Item>
                                            ))
                                        }
                                        <Dropdown.Divider></Dropdown.Divider>
                                        <Dropdown.Item className="text-danger" onClick={() => deleteTag(tag.id)}>
                                            Delete Tag
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </SoftBadge>

                        </ListGroup.Item>
                    ))
                }
                {/* {tags && JSON.parse(tags).map((tag, i) => (
                    <ListGroup.Item key={i} className="border border-0 p-1" style={{ backgroundColor: "transparent" }}>
                        <SoftBadge bg={tag?.color || 'primary'} className='py-0'>
                            <span
                                className="py-0 word-wrap d-inline-block text-truncate align-middle cursor-pointer"
                                style={{ maxWidth: "200px" }}
                                onClick={() => { }}>{tag.tag_name}</span>
                            <Dropdown className="d-inline-block link-info">
                                <Dropdown.Toggle
                                    as={Button}
                                    variant="link-info"
                                    size="sm"
                                    className="dropdown-caret-none"
                                >
                                    <FontAwesomeIcon icon="ellipsis-v" inverse />
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    {
                                        COLOR_OPTIONS.map(option => (
                                            <Dropdown.Item
                                                key={option.color}
                                                disabled={tag.color === option.color}
                                                onClick={() => {
                                                    updateTag(i, {
                                                        ...tag,
                                                        color: option.color
                                                    });
                                                }}>{option.label}</Dropdown.Item>
                                        ))
                                    }
                                    <Dropdown.Divider></Dropdown.Divider>
                                    <Dropdown.Item className="text-danger" onClick={() => deleteTag(i)}>
                                        Delete Tag
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </SoftBadge>

                    </ListGroup.Item>
                ))} */}

                <ListGroup.Item className="border border-0 p-1" style={{ backgroundColor: "transparent" }}>
                    {
                        showAddTag ?
                            <SoftBadge bg='primary' className='py-0 px-0'>
                                <Form onSubmit={createNewTag}>
                                    <Form.Control
                                        size="sm"
                                        placeholder="Enter new tag..."
                                        aria-label="Enter Tag"
                                        name="newTag"
                                        value={newTag}
                                        className="border-0"
                                        aria-describedby="basic-addon2"
                                        style={{ width: '150px', padding: '0px', paddingLeft: '5px', paddingRight: '5px', opacity: '0.6', margin: '0px' }}
                                        type="text"
                                        required={true}
                                        onChange={(e) => setNewTag(e.target.value)}
                                        onBlur={() => { setNewTag(''); setShowAddTag(false); }}
                                        ref={addTagRef}
                                    />
                                </Form>
                            </SoftBadge>
                            :
                            <Button variant="link" size="sm" onClick={() => { setShowAddTag(true); }}>+ Add Tag</Button>
                    }
                </ListGroup.Item>
            </ListGroup>
        </div>
    );
}

TagList.propTypes = {
    entity: PropTypes.object,
    setEntity: PropTypes.func,
    entity_type: PropTypes.string,
    entity_id: PropTypes.string,
    tags: PropTypes.array
}

export default TagList;