// import { AuthWizardContext } from 'context/Context';
import PropTypes from 'prop-types';
import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';

const Success = ({ handleSubmitForm }) => {
	// const { setStep, setUser } = useContext(AuthWizardContext);

	return (
		<>
			<Row>
				<Col className="text-center">
					<h4 className="mb-1">Review and Send</h4>
					<p className="fs-0">Review your message and send once ready.</p>
					<Button color="primary" className="px-5 my-3" onClick={handleSubmitForm}>
						Send Correspondence
					</Button>
				</Col>
			</Row>
		</>
	);
};

Success.propTypes = {
	reset: PropTypes.func.isRequired,
	handleSubmitForm: PropTypes.func
};

export default Success;
