import React, { useState, useRef, useEffect } from 'react';
import { Row, Col, Card, Button, Form, Accordion } from 'react-bootstrap';
// import { useAccordionButton } from 'react-bootstrap/AccordionButton';
// import MyFormContentEditor from '../MyFormContentEditor';
import JoditEditor from "jodit-pro-react";


const DocumentGeneratorFormTemplateRow = ({ content, setContent = () => { }, setFormData = () => { } }) => {
	const editor = useRef(null);


	const editorConfig = {
		// license: 
		readonly: false, // all options from https://xdsoft.net/jodit/doc/
		// toolbarAdaptive: false,
		height: "600",
		buttons: [
			"bold",
			"strikethrough",
			"underline",
			"italic",
			'ul',
			'ol',
			'outdent',
			'indent',
			'font',
			'fontsize',
			'brush',
			'paragraph',
			'image',
			'table',
			'link',
			'align',
			'undo',
			'redo',
			'hr',
			'eraser',
			'symbol',
			'copyformat',
			// 'fullsize',
			'print'
		],
		extraButtons: [
			{
				name: 'Embed Fields',
				tooltip: 'Embed fields',
				popup: (editor, current, self, close) => {

					// const form = editor.create.fromHTML(htmlPieces.join('\n'));
					const html = `
					<form style="min-width: 500px; min-height: 500px; padding: 15px;">

					<div class="form-group" id="field-group-embed-type">
						<label for="exampleFormControlSelect1">Embed Type</label>
						<select class="form-control" defaultValue="t" id="field-embed-type" required>
							<option value="t">Text</option>
							<option value="i">Input</option>
						</select>
					</div>

					<div class="form-group d-none">
						<label for="exampleFormControlSelect1">Field select</label>
						<select class="form-control" id="field-select">
						${fieldOptions}
						</select>
					</div>

					<div class="form-group d-none" id="input-field-group-select">
						<label for="exampleFormControlSelect1">Input Field select</label>
						<select class="form-control" id="input-field-select">
						${inputFieldOptions}
						</select>
					</div>

					<div class="form-group"  id="text-field-group-select">
						<label for="exampleFormControlSelect1">Text Field select</label>
						<select class="form-control" id="text-field-select">
						${textFieldOptions}
						</select>
					</div>

					

					<div class="form-check d-none" id="field-group-required">
						<input type="checkbox" class="field-required" id="field-required">
						<label class="field-required" for="field-required">Require Field?</label>
					</div>

					<div class="form-check d-none" id="field-group-disabled">
					  	<input type="checkbox" class="field-disabled" id="field-disabled">
					 	<label class="field-disabled" for="field-disabled">Disable Field?</label>
					</div>

					<div class="form-check d-none" id="field-group-placeholder">
					  <input type="checkbox" class="field-placeholder" id="field-placeholder" checked>
					  <label class="field-placeholder" for="field-placeholder">Show Field Placeholder?</label>
					</div>

					<div class="form-check d-none" id="field-group-placeholder">
					  <input type="checkbox" class="field-placeholder" id="field-placeholder" checked>
					  <label class="field-placeholder" for="field-placeholder">Show Field Placeholder?</label>
					</div>

					<div class="form-check field-showDefaultValue d-none" id="field-group-showDefaultValue">
						<input type="checkbox" class="field-showDefaultValue" id="field-showDefaultValue" checked>
						<label class="field-showDefaultValue" for="field-showDefaultValue">Show Default Value?</label>
					</div>

					<div class="form-check field-hidden d-none" id="field-group-hidden">
						<input type="checkbox" class="field-hidden" id="field-hidden">
						<label class="field-hidden" for="field-hidden">Hide Field?</label>
					</div>

					<br />

					<button type="submit" class="btn btn-primary" id="field-group-submit-button">Primary</button>
				</form>
				`;

					const form = editor.create.fromHTML(html);

					// on Change handler for #field-select
					form.querySelector('#text-field-group-select').addEventListener('change', (e) => {
						document.getElementById("field-group-submit-button").classList.remove("d-none");
					});
					form.querySelector('#input-field-group-select').addEventListener('change', (e) => {
						document.getElementById("field-group-submit-button").classList.remove("d-none");
					});

					form.querySelector('#field-embed-type').addEventListener('change', (e) => {

						if (e.target.value === 'i') {
							document.getElementById("text-field-group-select").classList.add("d-none");
							document.getElementById("input-field-group-select").classList.remove("d-none");
							document.getElementById("field-group-placeholder").classList.remove("d-none");
							document.getElementById("field-group-showDefaultValue").classList.remove("d-none");
							document.getElementById("field-group-disabled").classList.remove("d-none");
							document.getElementById("field-group-required").classList.remove("d-none");
						} else if (e.target.value === 't') {
							document.getElementById("input-field-group-select").classList.add("d-none");
							document.getElementById("text-field-group-select").classList.remove("d-none");
							document.getElementById("field-group-placeholder").classList.add("d-none");
							document.getElementById("field-group-showDefaultValue").classList.add("d-none");
							document.getElementById("field-group-disabled").classList.add("d-none");
							document.getElementById("field-group-required").classList.add("d-none");
						} else {
							document.getElementById("input-field-group-select").classList.add("d-none");
							document.getElementById("text-field-group-select").classList.add("d-none");
							document.getElementById("field-group-placeholder").classList.add("d-none");
							document.getElementById("field-group-showDefaultValue").classList.add("d-none");
							document.getElementById("field-group-disabled").classList.add("d-none");
							document.getElementById("field-group-required").classList.add("d-none");
						}
					});
					// editor.events.on(form, 'change', (e) => {

					editor.e.on(form, 'submit', (e) => {
						e.preventDefault();
						// Get the input field values
						const embedType = form?.querySelector('#field-embed-type').value || null;

						const inputId = `${ABBREVIATIONS[embedType]}${countInputs.current}`;

						// Complile the string to be inserted into the editor
						let embedString = '';
						let selected;
						let optionObj = {};
						switch (embedType) {
							case 't':
								selected = form?.querySelector("#text-field-select").value || null;
								embedString = `{{${selected}}}`;
								optionObj = {
									...availableFields[selected],
									selected,
									embedType
								};
								break;
							case 'i':
								selected = form?.querySelector("#input-field-select").value || null;
								const showPlaceholder = form?.querySelector("#field-placeholder").checked || null;
								const useDefaultValue = form?.querySelector("#field-showDefaultValue").checked || null;
								const disabled = form?.querySelector("#field-disabled").checked || null;
								const required = form?.querySelector("#field-required").checked || null;
								let attributes = [];
								required && attributes.push('r');
								disabled && attributes.push('d');
								!showPlaceholder && attributes.push('ph');
								!useDefaultValue && attributes.push('dv');
								embedString = `{{input|${selected}|[${attributes.join(',')}]}}`;
								optionObj = {
									...availableFields[selected],
									selected,
									embedType,
									required,
									disabled,
								};
								break;

							default:
								alert('Please select a field type');
								return;
						};

						if (!selected) { return alert('You must have a field name selected'); }

						editor.s.insertHTML(`<span class='' id="embeded_object_${selected}_${inputId}">${embedString}</span>`);

						metadata.current = {
							...metadata.current,
							[inputId]: optionObj
						};

						countInputs.current++;
						close();
					});

					return form;
				}
			}
		],
		// ...defaultEditorConfig
	}

	return (
		<div>
			<JoditEditor
				ref={editor}
				value={content}
				rows={10}
				config={editorConfig}
				onChange={newContent => setContent(newContent)}
				tabIndex={1} // tabIndex of textarea
			/>
		</div>
	);
};

export default DocumentGeneratorFormTemplateRow;