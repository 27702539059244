import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ListGroup from 'react-bootstrap/ListGroup';
import IconButton from 'components/common/IconButton';
import { toast } from 'react-toastify';
import { claimantDoctorAPI } from 'utils/api/fulcrum-api';
import DoctorRow from './DoctorRow';

const Doctors = ({ claimant, setClaimant }) => {
	const [loading, setLoading] = useState(true);
	const [doctors, setDoctors] = useState((claimant?.doctors) ? JSON.parse(claimant.doctors) : []);

	// Get the doctors
	const getClaimantDoctors = async () => {
		try {
			let result = await claimantDoctorAPI.getAllClaimantDoctors(claimant.id);
			if (!result?.data) {
				throw new Error('No claimant doctors data returned');
			}
			setLoading(false);
			setDoctors(result.data);
		} catch (error) {
			console.error(error);
		}
	};

	// Add a new doctor
	const addDoctors = async () => {
		try {
			let result = await claimantDoctorAPI.createClaimantDoctor(claimant.id, {
				doctor: '',
				phone: '',
				address: '',
				start_date: null,
				end_date: null,
				frequency_of_visits: ''
			});
			if (!result?.data) {
				throw new Error('No data returned from server');
			}
			// setDoctors([...doctors, result.data]);
			getClaimantDoctors();
			// toast.success('Doctor added', { theme: 'colored' });
		} catch (error) {
			console.log(error);
			toast.error('Error adding doctor', { theme: 'colored' });
		};
	};

	useEffect(() => {
		getClaimantDoctors();
	}, []);

	if (loading) {
		return <div>Loading...</div>;
	}

	return (
		<div id="claimant-current-employer-container" className="my-3">
			<h5 className="d-inline">Doctors</h5>

			<IconButton
				className="me-2 mb-1 float-end"
				variant="falcon-default"
				size="sm"
				icon="plus"
				transform="shrink-3"
				onClick={addDoctors}
			>
				Add
			</IconButton>
			<hr />
			<ListGroup>
				{doctors.map((doctor, index) => (
					<ListGroup.Item key={index}>
						<DoctorRow
							doctor={doctor}
							index={index}
							claimant={claimant}
							setClaimant={setClaimant}
							getClaimantDoctors={getClaimantDoctors}
						/>
					</ListGroup.Item>
				))}
			</ListGroup>
		</div>
	);
};

Doctors.propTypes = {
	claimant: PropTypes.object,
	setClaimant: PropTypes.func
}

export default Doctors;