import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const ClaimantSpouse = ({ claimant, saveOnChange }) => {
	let defaultState = {
		has_spouse: claimant.has_spouse,
		spouse_name: claimant.spouse_name,
		spouse_job: claimant.spouse_job,
		spouse_disability: claimant.spouse_disability
	};

	const [formData, setFormData] = useState(defaultState);
	// const [previousFormData, setPreviousFormData] = useState(defaultState);

	// Handle form changes
	const handleInputChange = (event) => {
		let { name, value } = event.target;

		if (['has_spouse', 'spouse_disability'].includes(name)) {
			value === 'true' ? (value = true) : (value = false);
		}

		setFormData({ ...formData, [name]: value });
	};

	useEffect(() => {

	}, []);

	return (
		<div id="claimant-spouse-container" className="my-3">
			<h5 className="d-inline">Spouse Info</h5>
			<hr />
			<Form>
				<Row>
					<Col sm={6}>
						<Form.Group controlId="hasSpouse">
							<Form.Label>
								Has Spouse
							</Form.Label>
							<Form.Control
								as="select"
								name="has_spouse"
								value={formData.has_spouse}
								onChange={e => { handleInputChange(e); saveOnChange(e) }}
							>
								<option value="true">Yes</option>
								<option value="false">No</option>
							</Form.Control>
						</Form.Group>
					</Col>
				</Row>

				{
					formData.has_spouse &&
					<>
						<Form.Group controlId="spouseName">
							<Form.Label>
								Spouse Name
							</Form.Label>
							<Form.Control
								type="text"
								name="spouse_name"
								value={formData.spouse_name}
								onChange={handleInputChange}
								onBlur={saveOnChange}
							/>
						</Form.Group>
						<Form.Group controlId="spouseJob">
							<Form.Label>
								Spouse Job
							</Form.Label>
							<Form.Control
								type="text"
								name="spouse_job"
								value={formData.spouse_job}
								onChange={handleInputChange}
								onBlur={saveOnChange}
							/>
						</Form.Group>
						<Form.Group controlId="spouseDisability">
							<Form.Label>
								Spouse Disability
							</Form.Label>
							<Form.Control
								as="select"
								name="spouse_disability"
								value={formData.spouse_disability}
								onChange={e => { handleInputChange(e); saveOnChange(e) }}
							>
								<option value="true">Yes</option>
								<option value="false">No</option>
							</Form.Control>
						</Form.Group>
					</>
				}
			</Form>
		</div>
	);
};

ClaimantSpouse.propTypes = {
	claimant: PropTypes.object,
	saveOnChange: PropTypes.func
}

export default ClaimantSpouse;