import React, { useState } from 'react';
import { Table, Form, Button } from 'react-bootstrap';
import IconButton from 'components/common/IconButton';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import { representativeAdmittedAPI } from 'utils/api/fulcrum-api';
import AdmittedRow from './AdmittedRow';
import { useEffect } from 'react';

const AdmittedLog = ({ user }) => {
	const [admittedLog, setAdmittedLog] = useState([]);
	const [loading, setLoading] = useState(true);

	// Get Representative Admitted Log
	const getRepresentativeAdmittedLog = async () => {
		try {
			let result = await representativeAdmittedAPI.getAllRepresentativeAdmittedLogsByUserId(user.id);
			if (!result?.data) {
				throw new Error('Error getting admitted log');
			}

			setAdmittedLog(result.data);
		} catch (error) {
			console.error(error);
			toast.error('Error getting admitted log', { theme: 'colored' });
		}
		setLoading(false);
	};

	// Add a new admitted log item
	const addAdmittedLogItem = async () => {
		try {
			let result = await representativeAdmittedAPI.createRepresentativeAdmitted(user.id, {
				bar: '',
				year: '',
				bar_number: ''
			});
			if (!result?.data) {
				throw new Error('Error adding admitted log item');
			}
			getRepresentativeAdmittedLog();
		} catch (error) {
			console.error(error);
			toast.error('Error adding admitted log item', { theme: 'colored' });
		}
	};

	useEffect(() => {
		getRepresentativeAdmittedLog();
	}, []);

	// useEffect(() => {
	// 	getRepresentativeAdmittedLog();
	// }, [user.id]);

	return (
		<div id="user-admittedLog-container" className="my-3">
			<Table responsive bordered striped >
				<thead>
					<th>Court or Bar</th>
					<th>Year Admitted</th>
					<th>Court or Bar License Number</th>
					<th>
						<Button
							variant="falcon-primary"
							size="sm"
							onClick={() => addAdmittedLogItem()}
						>
							+ Add
						</Button>

					</th>
				</thead>
				<tbody>
					{
						loading ? (
							<tr>
								<td colSpan="4" className="text-center">
									Loading...
								</td>
							</tr>
						) :
							admittedLog.map((admittedLogItem) => (
								<tr key={`${admittedLogItem.id}_admitted_log`}>
									<AdmittedRow
										log={admittedLogItem}
										admittedLog={admittedLog}
										setAdmittedLog={setAdmittedLog}
										user={user}
										getRepresentativeAdmittedLog={getRepresentativeAdmittedLog}
									/>
								</tr>
							))
					}

				</tbody>
			</Table>
		</div>
	);
};


export default AdmittedLog;