import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import PasswordResetWizardLayout from './PasswordResetWizardLayout';
import AuthWizardProvider from '../AuthWizardProvider';
import { accessVerificationAPI } from 'utils/api/fulcrum-api';

const PasswordResetWizard = ({ variant, validation, progressBar }) => {
	let { uuid } = useParams();
	const [accessVerificationData, setAccessVerificationData] = useState({});
	const [verifyingUUID, setVerifyingUUID] = useState(true);
	const [expiredLink, setExpiredLink] = useState(false);

	// Verify UUID
	const verifyUUID = async () => {
		try {
			let result = await accessVerificationAPI.getAccessVerification(uuid);
			if (!result?.data) { throw new Error('Invalid UUID'); }
			setVerifyingUUID(false);
			setAccessVerificationData(result.data);
		} catch (error) {
			setExpiredLink(true);
		}
	};

	useEffect(() => {
		verifyUUID();
	}, []);

	if (expiredLink) {
		return (<div>Expired Link</div>);
	}

	if (verifyingUUID) {
		return <div>Verifying UUID...</div>;
	};
	return (
		<AuthWizardProvider>
			<PasswordResetWizardLayout
				uuid={uuid}
				accessVerificationData={accessVerificationData}
				variant={variant}
				validation={validation}
				progressBar={progressBar}
			/>
		</AuthWizardProvider>
	);
};

PasswordResetWizard.propTypes = {
	variant: PropTypes.oneOf(['pills']),
	validation: PropTypes.bool,
	progressBar: PropTypes.bool
};

export default PasswordResetWizard;