/* eslint-disable no-case-declarations */
import React, { useContext, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Modal, Tabs, Tab, Dropdown, Button, Row, Col, Form } from 'react-bootstrap';
import { useDropzone } from 'react-dropzone';
import Flex from 'components/common/Flex';
import cloudUpload from 'assets/img/icons/cloud-upload.svg';
import CardDropdown from 'components/common/CardDropdown';
import { getSize } from 'helpers/utils';
import AppContext from 'context/Context';
import SignatureCanvas from 'react-signature-canvas'

const SignatureModal = ({ modalState, setModalState = () => { }, callBack = () => { } } = {}) => {
  const { config: { isDark } } = useContext(AppContext);
  const [typedSignatureCanvas, setTypedSignatureCanvas] = useState({ fontSize: 48 });
  const [key, setKey] = useState('draw');
  const colors = ["black", "blue"];
  const [penColor, setPenColor] = useState("black");
  let defaultFormData = {
    imageURL: '',
    signature: '',
    typed_signature: '',
    type: 'draw',
  };
  const [formData, setFormData] = useState(defaultFormData);
  const [files, setFiles] = useState([]);

  const sigCanvas = useRef();

  // Convert image to base64
  const convertToBase64 = async (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  // Handle Signature Submit
  const handleSignatureSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    let returnData = {
      type: key,
      signature: null
    };
    let URL;
    switch (key) {
      case 'draw':
        URL = sigCanvas.current.getCanvas().toDataURL("image/png"); // @note - see below, this version doesn't trim the image
        // URL = sigCanvas.current.getTrimmedCanvas().toDataURL("image/png");
        returnData.signature = URL;
        break;
      case 'upload':
        // Convert file to base64
        console.log(files[0])
        URL = await convertToBase64(files[0]);
        returnData.signature = URL;
        console.log({
          returnData
        })
        break;
      case 'typed':
        let canvas = document.getElementById("type-signature-canvas");
        let url = canvas.toDataURL();
        returnData.signature = url;
        break;
      default:
        return alert('Please select a signature type');
    }
    setFormData({ ...formData, imageURL: URL });
    callBack(returnData);
  };

  // Handle Close
  const handleClose = () => {
    setModalState(false);
    setFormData(defaultFormData);
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    onDrop: acceptedFiles => {
      setFiles(
        acceptedFiles.map(file =>
          Object.assign(file, {
            preview: URL.createObjectURL(file)
          })
        )
      );
    }
  });

  const handleRemove = path => {
    setFiles(files.filter(file => file.path !== path));
  };

  // Draw text to canvas
  const drawTextToCanvas = async (text, color) => {
    color = color || penColor;
    let canvas = document.getElementById("type-signature-canvas");
    let ctx = canvas.getContext("2d");
    let width;
    let fontSize = 48;

    do {
      ctx.font = `${fontSize}px Brush Script MT`;
      width = ctx.measureText(text).width;

      if (width > (canvas.width - 40)) {
        fontSize--;
      }

    } while (width > (canvas.width - 20));

    ctx.textAlign = "center";
    ctx.textBaseline = "middle";
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.fillStyle = color;
    ctx.fillText(text, 200, 50);

    setTypedSignatureCanvas({ ...typedSignatureCanvas, fontSize });
  };

  return (<Modal
    show={modalState}
    onHide={handleClose}
    onShow={() => setModalState(true)}
    contentClassName="border"
    size="lg"
    backdrop="static"
  >
    <Modal.Header
      closeButton
      closeVariant={isDark ? 'white' : undefined}
      className="bg-light px-card border-bottom-0"
    >
      <Modal.Title as="h5">Create New Signature</Modal.Title>
    </Modal.Header>
    <Modal.Body className="p-card">
      <Tabs
        activeKey={key}
        onSelect={(k) => setKey(k)}
        id="uncontrolled-tab-example"
      >
        <Tab eventKey="draw" title="Draw" className='border-bottom border-x p-3 '>
          <Row>
            <Col md={12}>

              <div className="text-center">
                <h5>Draw Siganture</h5>
                <SignatureCanvas
                  ref={sigCanvas}
                  penColor={penColor}
                  canvasProps={{ width: 400, height: 100, className: 'sigCanvas border bg-light' }}
                />
              </div>

            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <div className="mx-auto py-3" style={{ width: '500px' }}>

                <span className="fw-bold">Pen Color: </span> &nbsp;
                {colors.map((color) => (
                  <span
                    key={color}
                    style={{
                      backgroundColor: color,
                      border: `${color === penColor ? `4px solid ${color === "white" ? "#d1d1d1" : color}` : " 1px solid #d1d1d1"}`,
                      padding: "0px 10px",
                      width: "50px",
                      height: "50px",
                      borderRadius: "50%!important",
                      marginRight: "5px",
                      cursor: "pointer",
                    }}
                    onClick={() => { setPenColor(color); }}
                  ></span>
                ))}
                <Button
                  variant="link"
                  className="text-danger float-end"
                  onClick={() => sigCanvas.current.clear()}
                >Clear</Button>
              </div>
            </Col>
          </Row>
          {/* <Row>
							<Col>
								{
									formData.imageURL && (
										<>
											<img src={formData.imageURL} alt="signature" className="signature" />
										</>
									)
								}
							</Col>
						</Row> */}
        </Tab>
        <Tab eventKey="typed" title="Type" className='border-bottom border-x p-3 pb-5'>

          <div className="text-center mx-auto" style={{ maxWidth: "410px" }}>
            <h5>Type Siganture</h5>
            <canvas id="type-signature-canvas" width="400" height="100" className="border">
              Your browser does not support the canvas element.
            </canvas>

            <Form.Control
              size="lg"
              type="text"
              name="typed_signature"
              value={formData.typed_signature}
              onChange={e => { setFormData({ ...formData, typed_signature: e.target.value }); drawTextToCanvas(e.target.value) }}
              placeholder="Type signature..."
            />
            <div className="pt-3 text-left">
              <span className="fw-bold">Pen Color: </span> &nbsp;
              {colors.map((color) => (
                <span
                  key={color}
                  style={{
                    backgroundColor: color,
                    border: `${color === penColor ? `4px solid ${color === "white" ? "#d1d1d1" : color}` : " 1px solid #d1d1d1"}`,
                    padding: "0px 10px",
                    width: "50px",
                    height: "50px",
                    borderRadius: "50%!important",
                    marginRight: "5px",
                    cursor: "pointer",
                  }}
                  onClick={() => { setPenColor(color); drawTextToCanvas(formData.typed_signature, color); }}
                ></span>
              ))}
              <Button variant="link" className="text-danger float-end" onClick={() => {
                setFormData({ ...formData, typed_signature: '' });

                let canvas = document.getElementById("type-signature-canvas");
                let ctx = canvas.getContext("2d");

                ctx.clearRect(0, 0, canvas.width, canvas.height);
              }}>Clear</Button>
            </div>

          </div>
        </Tab>
        <Tab eventKey="upload" title="Upload" className='border-bottom border-x p-3'>
          <div {...getRootProps({ className: 'dropzone-area py-6' })}>
            <input {...getInputProps()} />
            <Flex justifyContent="center">
              <img src={cloudUpload} alt="" width={25} className="me-2" />
              <p className="fs-0 mb-0 text-700">Drop your images here</p>
            </Flex>
          </div>

          <div className="mt-3">
            {files.map(file => (
              <Flex
                alignItems="center"
                className="py-3 border-bottom btn-reveal-trigger"
                key={file.path}
              >
                {/* <Image rounded width={40} height={40} src={file.preview} alt={file.path} /> */}
                <Flex justifyContent="between" alignItems="center" className="ms-3 flex-1">
                  <div>
                    <h6>{file.path}</h6>
                    <Flex className="position-relative" alignItems="center">
                      <p className="mb-0 fs--1 text-400 line-height-1">
                        <strong>
                          {getSize(file.size)}
                        </strong>
                      </p>
                    </Flex>
                  </div>
                </Flex>
                <CardDropdown>
                  <div className="py-2">
                    <Dropdown.Item className="text-danger" onClick={() => handleRemove(file.path)}>
                      Remove
                    </Dropdown.Item>
                  </div>
                </CardDropdown>
              </Flex>
            ))}
          </div>
        </Tab>
      </Tabs>
    </Modal.Body>
    <Modal.Footer>
      <Button variant="falcon-success" className="" onClick={e => handleSignatureSubmit(e)}>Create Signature</Button>
    </Modal.Footer>
  </Modal>);
};

SignatureModal.propTypes = {
  modalState: PropTypes.bool,
  setModalState: PropTypes.func,
  callBack: PropTypes.func
};

export default SignatureModal;