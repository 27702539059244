import React from 'react';
import { Link } from 'react-router-dom';
import { Nav } from 'react-bootstrap';

const LandingRightSideNavItem = () => {

  return (
    <Nav navbar className="ms-auto">
      <Nav.Item>
        <Nav.Link
          as={Link}
          to="/login"
        >
          Login
        </Nav.Link>
      </Nav.Item>

      <Nav.Item>
        <Nav.Link
          as={Link}
          to="/register"
        >
          Register
        </Nav.Link>
      </Nav.Item>
    </Nav>
  );
};

export default LandingRightSideNavItem;
