import React, { useContext, useState, useRef } from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
import AppContext from 'context/Context';
import OtherRecipientCorrespondence from './OtherRecipientCorrespondence';
import CustomMessageEditor from './CustomMessageEditor';
import auth from 'utils/auth';

const OtherRecipientCorrespondenceWrapper = ({
	account_id = auth.getProfile()?.data?.account_id || null,
	defaultFormData = {},
	handleClose = () => { },
	defaultClaimants = [],
	defaultSelectedDocuments = [],
	defaultModeOfTransmission = null,
	allowMultipleClaimants = false,
	setSlide = () => { },
	refreshParent = () => { },
}) => {
	const submitRef = useRef();

	const {
		config: { isDark }
	} = useContext(AppContext);

	const [customMessage, setCustomMessage] = useState(false);

	return (
		<div>

			<div className="mx-3 px-3 py-3">
				<OtherRecipientCorrespondence
					defaultFormData={defaultFormData}
					refreshParent={refreshParent}
					handleClose={handleClose}
					defaultClaimants={defaultClaimants}
					setCustomMessage={setCustomMessage}
					submitRef={submitRef}
					account_id={account_id}
					allowMultipleClaimants={allowMultipleClaimants}
				/>
			</div>

			{
				customMessage &&
				<div className="mx-3 px-3 py-3">
					<CustomMessageEditor
						defaultFormData={defaultFormData}
					/>
				</div>
			}

			<Button variant={'primary'} className="float-end" onClick={(e) => submitRef.current.click(e)}>Submit Correspondence</Button>
			<Form submitRef={submitRef} onSubmit={e => { e.preventDefault() }} />
		</div>
	);
};


export default OtherRecipientCorrespondenceWrapper;