import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { Card, Dropdown, ListGroup } from 'react-bootstrap';
// import {
//   rawEarlierNotifications,
//   rawNewNotifications
// } from 'data/notification/notification';
import { isIterableArray } from 'helpers/utils';
// import useFakeFetch from 'hooks/useFakeFetch';
import Notification from 'components/notification/Notification';
import Auth from 'utils/auth';
import { notificationAPI } from 'utils/api/fulcrum-api';
import ProcessNotificationModal from 'components/notification/ProcessNotificationModal';
import { sortByCreatedAt } from 'utils/functions';


const NotificationDropdown = () => {
  // State
  const [show, setShow] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isAllRead, setIsAllRead] = useState(true);
  const [currentNotification, setCurrentNotification] = useState({});

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const checkNotifications = async () => {
    const { account_id, user_id } = Auth.getProfile().data;
    try {
      const { data: userMatchData } = await notificationAPI.searchNotifications({
        entity_id: `${account_id}_User_${user_id}`
      });
      if (isIterableArray(userMatchData.rows)) {
        setNotifications([...userMatchData.rows]);
      }
    } catch (error) {
      console.log('Unable to fetch notifications:', error);
    }
  }

  // Handler
  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    checkNotifications();
    const unsubscribeTo = setInterval(() => {
      checkNotifications();
    }, 10000);
    window.addEventListener('scroll', () => {
      window.innerWidth < 1200 && setIsOpen(false);
    });
    return (() => clearInterval(unsubscribeTo));
  }, []);

  useEffect(() => {
    if (notifications) {
      setIsAllRead(notifications.filter((notification) => !notification.dismissed_timestamp).length < 1);
    }
  }, [notifications]);

  // const markAsRead = e => {
  //   e.preventDefault();

  //   const updatedNewNotifications = newNotifications.map(notification =>
  //     Object.prototype.hasOwnProperty.call(notification, 'unread')
  //       ? { ...notification, unread: false }
  //       : notification
  //   );
  //   const updatedEarlierNotifications = earlierNotifications.map(notification =>
  //     Object.prototype.hasOwnProperty.call(notification, 'unread')
  //       ? { ...notification, unread: false }
  //       : notification
  //   );

  //   setIsAllRead(true);
  //   setNewNotifications(updatedNewNotifications);
  //   setEarlierNotifications(updatedEarlierNotifications);
  // };

  return (
    <Dropdown navbar={true} as="li" show={isOpen} onToggle={handleToggle}>
      <Dropdown.Toggle
        bsPrefix="toggle"
        as={Link}
        to="#!"
        className={classNames('px-0 nav-link', {
          'notification-indicator notification-indicator-primary': !isAllRead
        })}
      >
        <FontAwesomeIcon icon="bell" transform="shrink-6" className="fs-4" />
      </Dropdown.Toggle>

      <Dropdown.Menu className="dropdown-menu-card dropdown-menu-end dropdown-caret dropdown-caret-bg">
        <Card
          className="dropdown-menu-notification dropdown-menu-end shadow-none"
          style={{ maxWidth: '20rem' }}
        >
          {/* <FalconCardHeader
            className="card-header"
            title="Notifications"
            titleTag="h6"
            light={false}
            endEl={
              <Link
                className="card-link fw-normal"
                to="#!"
              // onClick={markAsRead}
              >
                Mark all as read
              </Link>
            }
          /> */}
          <ListGroup
            variant="flush"
            className="fw-normal fs--1 scrollbar"
            style={{ maxHeight: '19rem' }}
          >
            <div className="list-group-title">Unread</div>{' '}
            {isIterableArray(notifications) &&
              notifications.filter((notification) => !notification.dismissed_timestamp)
                .sort(sortByCreatedAt)
                .map(notification => (
                  <ListGroup.Item key={notification.id} onClick={handleToggle}>
                    <Notification notification={notification} flush selectNotification={setCurrentNotification} showModal={handleShow} />
                  </ListGroup.Item>
                ))}
            <div className="list-group-title">Recently Dismissed</div>
            {isIterableArray(notifications) &&
              notifications
                .filter((notification) => !!notification.dismissed_timestamp)
                .sort(sortByCreatedAt)
                .map(notification => (
                  <ListGroup.Item key={notification.id} onClick={handleToggle}>
                    <Notification notification={notification} flush selectNotification={setCurrentNotification} showModal={handleShow} />
                  </ListGroup.Item>
                ))}
          </ListGroup>
          <div
            className="card-footer text-center border-top"
            onClick={handleToggle}
          >
            {/* <Link className="card-link d-block" to="#!">
              View all
            </Link> */}
          </div>
        </Card>
      </Dropdown.Menu>
      <ProcessNotificationModal
        show={show}
        handleClose={handleClose}
        notification={currentNotification}
        setNotification={setCurrentNotification}
        notifications={notifications}
        setNotifications={setNotifications}
      />
    </Dropdown>
  );
};

export default NotificationDropdown;
