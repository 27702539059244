import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import Dropdown from 'react-bootstrap/Dropdown';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import CardDropdown from 'components/common/CardDropdown';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import { incomeSourceAPI } from 'utils/api/fulcrum-api';

const CurrentIncomeSourceRow = ({ incomeSource, claimant, getClaimantIncomeSources = () => { } }) => {
	let defaultFormData = {
		amount: incomeSource.amount,
		source: incomeSource.source
	};
	const [formData, setFormData] = useState(defaultFormData);
	const [previousFormData, setPreviousFormData] = useState(defaultFormData);

	// Remove a income source
	const removeChild = () => {
		Swal.fire({
			title: 'Permanently delete this income source record?',
			text: "You won't be able to revert this!",
			icon: 'warning',
			showCansetFormDatalButton: true,
			confirmButtonColor: '#3085d6',
			cansetFormDatalButtonColor: '#d33',
			confirmButtonText: 'Yes, delete it!',
			cancelButtonColor: '#d33',
			showCancelButton: true,
		}).then(async (result) => {
			if (result.isConfirmed) {
				try {
					let result = await incomeSourceAPI.deleteClaimantIncomeSource(claimant.id, incomeSource.id);
					if (!result?.message) {
						throw new Error('No data returned from server');
					}
					getClaimantIncomeSources();
					toast.warning('Income source removed', { theme: 'colored' });
				} catch (error) {
					console.error(error);
					toast.error('Error deleting income source', { theme: 'colored' });
				}
			}
		});
	};

	// Update a income source
	const updateCurrentIncomeSource = async (incomeSourceId, data) => {
		try {
			let result = await incomeSourceAPI.updateClaimantIncomeSource(claimant.id, incomeSourceId, data);
			console.log(result);
			if (!result) {
				throw new Error('Unable to update the income source.');
			}
			setPreviousFormData({ ...formData, ...data });

		} catch (error) {
			console.error(error);
			toast.error('Error updating income source.', {
				theme: 'colored'
			});
		}
	};

	// Save on change
	const saveOnChange = async (e, saveChange) => {
		let { name, value } = e.target;

		if (saveChange && previousFormData[name] !== value) {
			await updateCurrentIncomeSource(incomeSource.id, { [name]: value });
		}

		setFormData({ ...formData, [name]: value });
	};

	useEffect(() => {
		let incomeSourceData = {
			amount: incomeSource.amount,
			source: incomeSource.source
		};
		setFormData(incomeSourceData);
		setPreviousFormData(incomeSourceData);
	}, [incomeSource])

	return (
		<>
			<td className="px-0">
				<Form.Control
					type="number"
					size="sm"
					name="amount"
					value={formData.amount}
					onChange={e => saveOnChange(e, false)}
					onBlur={e => saveOnChange(e, true)}
				/>
			</td>
			<td className="px-0">
				<Form.Control
					type="text"
					size="sm"
					name="source"
					value={formData.source}
					onChange={e => saveOnChange(e, false)}
					onBlur={e => saveOnChange(e, true)}
				/>
			</td>
			<td>
				<div className="d-flex justify-content-between float-end">
					<CardDropdown>
						<div className="py-2">
							<Dropdown.Item onClick={() => { removeChild(incomeSource.id) }} className="text-danger">Delete</Dropdown.Item>
						</div>
					</CardDropdown>
				</div>
			</td>
		</>
	);
};

export default CurrentIncomeSourceRow;