import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import LeadVendorTable from './LeadVendorTable';
import auth from 'utils/auth';

const LeadVendors = ({ account_id = auth.getProfile().data.account_id, withHeader = true }) => {

	return (
		<div>
			{
				withHeader && (
					<Card className="bg-100  border mb-3 bg-line-chart-gradient text-white">
						<Card.Body>
							<Row className=" justify-content-between pr-0">
								<Col sm="auto" className="pr-0 align-middle">
									<Flex alignItems="center">
										<h4 className="align-middle fw-bold mb-0 h-inline">
											<span className=" text-white fw-medium">Lead Vendors</span>
										</h4>
									</Flex>
								</Col>
							</Row>
						</Card.Body>
					</Card>
				)
			}

			<LeadVendorTable
				defaultSearchParams={{}}
				requiredSearchParams={{ account_id }}
				account_id={account_id}
			/>
		</div>
	);
};

export default LeadVendors;