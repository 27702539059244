import React, { useState, useEffect } from 'react';
import IconButton from 'components/common/IconButton';
import { toast } from 'react-toastify';
import { incomeSourceAPI } from 'utils/api/fulcrum-api';
import CurrentIncomeSourceRow from './CurrentIncomeSourceRow';
import { Table } from 'react-bootstrap';

const CurrentIncomeSourceList = ({ claimant, setClaimant }) => {
	const [loading, setLoading] = useState(true);
	const [incomeSources, setIncomeSources] = useState([]);

	// Get the incomeSources
	const getClaimantIncomeSources = async () => {
		try {
			let result = await incomeSourceAPI.getAllClaimantIncomeSources(claimant.id);
			if (!result?.data) {
				throw new Error('No claimant income source data returned');
			}
			setLoading(false);
			setIncomeSources(result.data);
		} catch (error) {
			console.error(error);
		}
	};

	// Add a new income source
	const AddIncomeSource = async () => {
		try {
			let result = await incomeSourceAPI.createClaimantIncomeSource(claimant.id, {
				name: '',
				relationship: '',
			});
			if (!result?.data) {
				throw new Error('No data returned from server');
			}
			// getClaimantIncomeSources();
			setIncomeSources([...incomeSources, result.data]);
		} catch (error) {
			console.log(error);
			toast.error('Error adding income source.', { theme: 'colored' });
		};
	};

	useEffect(() => {
		getClaimantIncomeSources();
	}, []);

	if (loading) {
		return <div>Loading...</div>;
	}

	return (
		<div id="claimant-current-income-sourcesr" className="my-3">
			<h5 className="d-inline">Current Income Sources</h5>
			<IconButton
				className="me-2 mb-1 float-end"
				variant="falcon-default"
				size="sm"
				icon="plus"
				transform="shrink-3"
				onClick={AddIncomeSource}
			>
				Add Income Source
			</IconButton>
			<p>Please list your current income sources.</p>
			<Table>
				<thead>
					<tr>
						<th>Amount</th>
						<th style={{ width: "65%" }}>Source</th>
						<th>

						</th>
					</tr>
				</thead>
				<tbody>
					{
						(incomeSources.length > 0)
							?
							incomeSources.map((incomeSource, index) => (
								<tr key={index}>
									<CurrentIncomeSourceRow
										incomeSource={incomeSource}
										claimant={claimant}
										setClaimant={setClaimant}
										getClaimantIncomeSources={getClaimantIncomeSources}
									/>
								</tr>
							))
							:
							<tr>
								<td colSpan="3">No income sources.</td>
							</tr>
					}

				</tbody>
			</Table>
		</div>
	);
};


export default CurrentIncomeSourceList;