import React, { useState } from 'react';
import { Modal, Button, Row, Col } from 'react-bootstrap';
import { assureAPI } from 'utils/api/fulcrum-api';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';

const columns = [
	{
		accessor: 'id',
		Header: 'ID',
		headerProps: { className: 'pe-1' },
		cellProps: {
			className: 'py-2'
		}
	},
	{
		accessor: 'first_name',
		Header: 'First Name',
		headerProps: { className: 'pe-1' },
		cellProps: {
			className: 'py-2'
		}
	},
	{
		accessor: 'last_name',
		Header: 'Last Name',
		headerProps: { className: 'pe-1' },
		cellProps: {
			className: 'py-2'
		}
	},
	{
		accessor: 'region',
		Header: 'Region',
		headerProps: { className: 'pe-1' },
		cellProps: {
			className: 'py-2'
		}
	},
	{
		accessor: 'office',
		Header: 'Office',
		headerProps: { className: 'pe-1' },
		cellProps: {
			className: 'py-2'
		}
	},
	{
		accessor: 'menu',
		Header: '',
		headerProps: { className: 'pe-1' },
		cellProps: {
			className: 'py-2'
		}
	}
];

const ViewJudges = ({ modalState, setModalState = () => { }, judgeSelectCallBack = () => { }, alreadySelectedJudges = [] }) => {
	const [foundJudges, setFoundJudges] = useState([]);
	const [foundJudgeCount, seetFoundJudgeCount] = useState(0);
	const [loading, setLoading] = useState(false);

	const formatData = (data = []) => {
		let adjustedData = data.map(j => {
			return {
				...j,
				menu:
					alreadySelectedJudges.includes(j)
						?
						<span className="text-success">Selected</span>
						:
						<Button variant="falcon-primary" onClick={() => handleJudgeSelect(j)}>Select</Button>
			}
		});
		setFoundJudges(adjustedData);
	};

	// Get assure judge list
	const getJudgeList = async () => {
		setLoading(true);
		try {
			let result = await assureAPI.getJudgeList();
			formatData(result?.data?.data || []);
			seetFoundJudgeCount(result?.data?.data?.length || 0);
		} catch (error) {
			console.log(error);
		}
		setLoading(false);
	};

	// Handle judge select
	const handleJudgeSelect = (judge) => {
		judgeSelectCallBack(judge);
		setModalState(false);
	};

	// Handle open
	const handleOpen = () => {
		getJudgeList();
	};

	// Handle close
	const handleClose = () => {
		setModalState(false);
	};


	return (
		<div>
			<Modal
				show={modalState}
				onShow={handleOpen}
				onHide={handleClose}
				backdrop="static"
				size="xl"
			>
				<Modal.Header closeButton>
					<Modal.Title>Judges</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{
						loading ?
							<div className="text-center">
								<div className="spinner-border" role="status">
									<span className="sr-only">Loading Judges...</span>
								</div>
							</div>
							:
							<AdvanceTableWrapper
								columns={columns}
								data={foundJudges}
								sortable
								searchable
								pagination
								perPage={10}
							>
								<Row className="flex-end-center mb-3">
									<Col xs="auto" sm={6} lg={4}>
										<AdvanceTableSearchBox table />
									</Col>
								</Row>
								<AdvanceTable
									table
									headerClassName="bg-200 text-900 text-nowrap align-middle"
									rowClassName="align-middle white-space-nowrap"
									tableProps={{
										bordered: true,
										striped: true,
										className: 'fs--1 mb-0 overflow-hidden'
									}}
								/>
								<div className="mt-3">
									<AdvanceTableFooter
										rowCount={foundJudgeCount}
										table
										navButtons
									/>
								</div>
							</AdvanceTableWrapper>
					}
				</Modal.Body>
			</Modal>
		</div>
	);
};

export default ViewJudges;