import React, { useEffect, useState } from 'react';
import { Card, Row, Col, Button, Form } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import { Link } from 'react-router-dom';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import RevisionLogTableHeader from './RevisionLogTableHeader';
import CustomAdvanceTable from 'components/common/advance-table/CustomAdvanceTable';
import CustomAdvanceTablePagination from 'components/common/advance-table/CustomAdvanceTablePagination';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import { revisionLogAPI } from 'utils/api/fulcrum-api';
import { removeEmptyFromObj } from 'utils/functions';
import moment from 'moment';
import Auth from 'utils/auth';
import DatePicker from 'react-datepicker';
import RevisionLogFilters from './RevisionLogFilters';
import { toast } from 'react-toastify';
import RevisionLogDetails from './RevisionLogDetails';

const columns = [
	// {
	// 	accessor: 'table',
	// 	Header: 'Table',
	// 	headerProps: { className: 'pe-1' },
	// 	cellProps: {
	// 		className: 'py-2'
	// 	}
	// },
	{
		accessor: 'field',
		Header: 'Field',
		headerProps: { className: 'pe-1' },
		cellProps: {
			className: 'py-2'
		}
	},
	{
		accessor: 'previous_value',
		Header: 'Previous Value',
		headerProps: { className: 'pe-1' },
		cellProps: {
			className: 'py-2'
		}
	},
	{
		accessor: 'new_value',
		Header: 'New Value',
		headerProps: { className: 'pe-1' },
		cellProps: {
			className: 'py-2'
		}
	},
	{
		accessor: 'created_by',
		Header: 'Created By',
		headerProps: { className: 'pe-1' },
		cellProps: {
			className: 'py-2'
		}
	},
	{
		accessor: 'created_at',
		Header: 'Modified',
		headerProps: { className: 'pe-1' },
		cellProps: {
			className: 'py-2'
		}
	},
	{
		accessor: 'view',
		Header: '',
		headerProps: { className: 'pe-1' },
		cellProps: {
			className: 'py-2'
		}
	},
];

const RevisionLogTable = ({ tableHeaderLabel = "Revision Logs", defaultSearchParams = {}, requiredSearchParams = {}, defaultClaimants = [], }) => {
	const [skipFirstQuery, setSkipFirstQuery] = useState(true);
	const [revisionData, setRevisionData] = useState([]);
	const [scheduleStartDate, setScheduleStartDate] = useState();
	const [scheduleEndDate, setScheduleEndDate] = useState();
	const [revisionLogCount, setRevisionLogCount] = useState(0);
	const [Pages, setPages] = useState([1]);
	const [activePage, setActivePage] = useState(1);
	const [perPage, setPerPage] = useState(10);
	const [revisionLogDetailsModal, setRevisionLogDetailsModal] = useState(false);
	const [selectedRevisionLog, setSelectedRevisionLog] = useState({});
	const [searching, setSearching] = useState(true);
	const [lastQuery, setLastQuery] = useState({});
	const [searchParams, setSearchParams] = useState({ ...defaultSearchParams, ...requiredSearchParams });
	let defaultSort = ["created_at", "ASC"];
	const [appliedSortOrders, setAppliedSortOrders] = useState([defaultSort]);


	const headerClickFn = (id, previousSortBy) => {
		if (!id) {
			return;
		}
		let foundIndex = appliedSortOrders.findIndex(s => s[0] === id);
		let sortBy = !previousSortBy ? "ASC" : previousSortBy === "asc" ? "DESC" : '';

		if (!sortBy) { // Not Sorted so remove clicked sort item 
			if (foundIndex > -1) {
				setAppliedSortOrders((prev) => {
					let newSortOrders = [...prev];
					newSortOrders.splice(foundIndex, 1); // remove clicked sort item
					return newSortOrders;
				});
			}
		} else { // Sort by Ascending or Descending 
			let newSort = [id, sortBy];
			if (foundIndex > -1) { // replaces existing 
				setAppliedSortOrders((prev) => {
					let newSortOrders = [...prev];
					newSortOrders[foundIndex] = newSort;
					return newSortOrders;
				});
			} else { // inserts 
				setAppliedSortOrders((prev) => [...prev, newSort]);
			}
		}
	};

	const formatData = (data = []) => {
		let adjustedData = data.map(c => {
			return {
				...c,
				created_by: c?.user ? c.user.first_name + ' ' + c.user.last_name : '',
				created_at: moment(c.createdAt).format('MM/DD/YYYY hh:mm A'),
				view: <span className={`link-primary cursor-pointer`} onClick={() => handleRevisionLogSelection(c)}>View</span>
			}
		});

		setRevisionData(adjustedData);
	};

	// Handle Revision Log Selection
	const handleRevisionLogSelection = (revisionLog) => {
		setSelectedRevisionLog(revisionLog);
		setRevisionLogDetailsModal(true);
	};

	const revisionLogSearchHandler = async (params = {}, saveLastQuery = false) => {
		try {
			params = removeEmptyFromObj(params);
			params.page = activePage;
			params.limit = perPage;
			params.navSize = 4; // How many buttons to show for navigation
			params.order = appliedSortOrders; // Example [['last_last', 'asc'],['first_name','asc'], ['id','asc']]
			let { data } = await revisionLogAPI.searchRevisionLogs(params);
			if (!data) {
				throw new Error('No revision log data found');
			}
			let { rows = [], pages = [], count = 0, page = 1, pageCount = 0 } = data || {}; // pages = [1,2,3,10]
			if (!rows) {
				throw new Error('No revision log data found');
			}
			if ((Array.isArray(rows) && rows.length === 0)) {
				setActivePage(1); // Make sure we don't get stuck loading nothing
			}

			if (pageCount < activePage) {
				setActivePage(1);
			} else {
				setActivePage(page);
			}
			setPages(pages);
			formatData(rows);
			setRevisionLogCount(count);

			if (saveLastQuery) {
				setLastQuery(params);
			}
		} catch (error) {
			console.log(error);
			toast.error('Error searching revision logs', { theme: 'colored' });
		}
		setSearching(false);
		return;
	};

	// Clear search params and reset to default
	const clearSearchParams = () => {
		setSearchParams({ ...defaultSearchParams, ...requiredSearchParams });
	};

	// New Search
	const newSearch = async (params) => {
		setSearching(true);
		let adjustedSearchParams = {
			...params,
			...requiredSearchParams
		};

		revisionLogSearchHandler(adjustedSearchParams, true);
	};


	// Refresh last query
	const refreshLastQuery = async () => {
		revisionLogSearchHandler({ ...lastQuery, ...requiredSearchParams }, false);
	};

	useEffect(async () => {
		if (!skipFirstQuery) {
			newSearch({ ...defaultSearchParams });
		}
		setSkipFirstQuery(false);
	}, []);

	// Apply search filters
	useEffect(() => { newSearch(searchParams) }, [searchParams, activePage, perPage, appliedSortOrders]);

	return (
		<div>
			<AdvanceTableWrapper
				columns={columns}
				data={revisionData}
				// selection
				sortable
				pagination
				perPage={perPage}
				setPerPage={setPerPage}
			>
				<Card className="mb-3 shadow-none">
					<Card.Header>
						<RevisionLogTableHeader
							table
							label={tableHeaderLabel}
							defaultSearchParams={defaultSearchParams}
							searchParams={searchParams}
							setSearchParams={setSearchParams}
							refreshLastQuery={refreshLastQuery}
							requiredSearchParams={requiredSearchParams}
							revisionData={revisionData}
							clearSearchParams={clearSearchParams}
						/>
						<RevisionLogFilters
							searchParams={searchParams}
							setSearchParams={setSearchParams}
							clearSearchParams={clearSearchParams}
							fields={[
								"table",
								"field",
								"previous_value",
								"new_value",
								"created_by_id",
							]}
						/>
					</Card.Header>
					<Card.Body className="p-0">
						{
							searching
								?
								<p>Searching revision logs....</p>
								:
								revisionData.length === 0
									?
									<p>No revision logs found</p>
									:
									<CustomAdvanceTable
										table
										headerClickFn={headerClickFn}
										appliedSortOrders={appliedSortOrders}
										headerClassName="bg-200 text-900 text-nowrap align-middle"
										rowClassName="align-middle white-space-nowrap"
										tableProps={{
											size: 'sm',
											striped: true,
											className: 'fs--1 mb-0 overflow-hidden'
										}}
									/>
						}
					</Card.Body>
					<Card.Footer>
						<CustomAdvanceTablePagination
							table
							Pages={Pages}
							activePage={activePage}
							setActivePage={setActivePage}
						/>
					</Card.Footer>
				</Card>
			</AdvanceTableWrapper>
			<RevisionLogDetails
				modalState={revisionLogDetailsModal}
				setModalState={setRevisionLogDetailsModal}
				selectedRevisionLog={selectedRevisionLog}
				setSelectedRevisionLog={setSelectedRevisionLog}
			/>
		</div>
	);
};


export default RevisionLogTable;