import React, { useEffect, useState } from 'react';
import { Form, Dropdown, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { isIterableArray } from 'helpers/utils';
import Flex from 'components/common/Flex';
import FalconCloseButton from 'components/common/FalconCloseButton';
import { claimantAPI } from 'utils/api/fulcrum-api';

const SearchBox = ({ autoCompleteItem }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [searchInputValue, setSearchInputValue] = useState('');
  const [resultItem, setResultItem] = useState(autoCompleteItem);
  const [foundClaimants, setFoundClaimants] = useState([]);
  const [searching, setSearching] = useState(false);


  // Search Claimants
  const searchClaimants = async () => {
    setDropdownOpen(true);
    try {
      let result = await claimantAPI.searchClaimants({ searchTerm: searchInputValue, limit: 10 });
      if (!result?.data || !result?.data?.rows) { throw new Error('No data found'); }
      setFoundClaimants(result.data.rows || []);
    } catch (error) {
      console.log(error);
    }

    setSearching(false);
  };

  const toggle = () => {
    if (!dropdownOpen && !searchInputValue) {
      setFoundClaimants([]);
    }
    setDropdownOpen(!dropdownOpen);

  };

  useEffect(() => {
    if (searchInputValue.length > 2) {
      const timeOutId = setTimeout(() => {
        // setSearching(true);
        console.log('searchInputValue', searchInputValue)
        searchClaimants(searchInputValue);
        setDropdownOpen(true);
      }, 1000);
      return () => clearTimeout(timeOutId);
    }
  }, [searchInputValue]);

  return (
    <Dropdown onToggle={toggle} className="search-box">
      <Dropdown.Toggle
        as="div"
        data-toggle="dropdown"
        aria-expanded={dropdownOpen}
        bsPrefix="toggle"
      >
        <Form onSubmit={e => { e.preventDefault(); searchClaimants(); }} className="position-relative">
          <Form.Control
            type="search"
            placeholder="Search..."
            aria-label="Search"
            className="rounded-pill search-input"
            value={searchInputValue}
            onChange={({ target }) => setSearchInputValue(target.value)}
            onClick={() => setDropdownOpen(false)}
          />
          <FontAwesomeIcon
            icon="search"
            className="position-absolute text-400 search-box-icon"
          />
          {searchInputValue && (
            <div
              className="search-box-close-btn-container"
            >
              <FalconCloseButton
                size="sm"
                noOutline
                onClick={() => { setSearchInputValue(''); setFoundClaimants([]); }}
              />
            </div>
          )}
        </Form>
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {searching ?
          <Dropdown.Header as="h6" className="px-card pt-0 pb-2 fw-medium">
            <Flex alignItems="center">
              <FontAwesomeIcon

                icon="circle"
                className="me-2 text-300 fs--2"
              />
              <div className="fw-normal">
                Searching...
              </div>
            </Flex>
          </Dropdown.Header>
          :
          <div className="scrollbar py-3" style={{ maxHeight: '24rem' }}>
            {isIterableArray(foundClaimants) && (
              <>
                <Dropdown.Header as="h6" className="px-card pt-0 pb-2 fw-medium">
                  Found Claimants
                </Dropdown.Header>

                {foundClaimants.map(item => (
                  <Dropdown.Item
                    as={Link}
                    to={`/claimant/profile/${item.id}`}
                    className="fs--1 px-card py-1 hover-primary "
                    key={item.id}
                  >
                    <Flex alignItems="center">
                      <FontAwesomeIcon
                        icon="circle"
                        className="me-2 text-300 fs--2"
                      />
                      <div className="fw-normal">
                        {item?.first_name || ''} {item?.last_name || ''}
                      </div>
                    </Flex>
                  </Dropdown.Item>
                ))}
              </>
            )}

            {resultItem.length === 0 && (
              <p className="fs-1 fw-bold text-center mb-0">No Result Found.</p>
            )}
          </div>
        }
      </Dropdown.Menu>
    </Dropdown>
  );
};


export default SearchBox;
