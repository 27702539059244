import React from 'react';
import PropTypes from 'prop-types';
import CreateCorrespondenceWizardLayout from './CreateCorrespondenceWizardLayout';
import AuthWizardProvider from '../AuthWizardProvider';

const CreateCorrespondenceWizard = ({ variant, validation, progressBar }) => {

	// useEffect(() => {
	// }, []);


	return (
		<AuthWizardProvider>
			<CreateCorrespondenceWizardLayout
				variant={variant}
				validation={validation}
				progressBar={progressBar}
			/>
		</AuthWizardProvider>
	);
};

CreateCorrespondenceWizard.propTypes = {
	variant: PropTypes.oneOf(['pills']),
	validation: PropTypes.bool,
	progressBar: PropTypes.bool
};

export default CreateCorrespondenceWizard;