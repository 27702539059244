import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import ChangePassword from './ChangePassword';

const PasswordChangeModal = ({ userId }) => {

	const [modalShow, setModalShow] = useState(false);

	return (
		<div className="py-2" >
			<h5 className="">Password Reset</h5>
			<p>
				Change password or send user a reset link <span className="cursor-pointer text-primary" onClick={() => setModalShow(true)}>here.</span>
			</p>
			{/* <Button variant="link" onClick={() => setModalShow(true)}>
				Change Password
			</Button> */}


			<Modal
				show={modalShow}
				onHide={() => setModalShow(false)}
				size="lg"
				aria-labelledby="contained-modal-title-vcenter"
				centered
			>
				<Modal.Header closeButton>
					<Modal.Title id="contained-modal-title-vcenter">Change Password</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<ChangePassword userId={userId} setModalShow={setModalShow} />
				</Modal.Body>
			</Modal>
		</div>
	);
};

PasswordChangeModal.propTypes = {
	userId: PropTypes.string
}

export default PasswordChangeModal;