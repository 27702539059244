import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Modal, Table, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import AppContext from 'context/Context';
import DatePicker from 'react-datepicker';
import { taskAPI } from 'utils/api/fulcrum-api';
import auth from 'utils/auth';
import ClaimantQuickFind from 'components/claimants/find-a-claimant/ClaimantQuickFind';
import UserQuickFind from 'components/users/find-a-user/NewUserQuickFind';
import moment from 'moment';
import { toast } from 'react-toastify';


const CreateTaskForm = ({
	setIsOpenScheduleModal,
	isOpenScheduleModal,
	scheduleStartDate,
	setScheduleStartDate,
	scheduleEndDate,
	refreshParent = () => { },
	defaultClaimants = [],
	account_id = auth.getProfile()?.data?.account_id || null,
	siteAdminArea = false
}) => {
	const {
		config: { isDark }
	} = useContext(AppContext);
	const defaultFormData = {
		task_type_id: null,
		task_status_id: null,
		title: '',
		description: '',
		due_date: null,
		all_claimants: false,
	};
	account_id = account_id || auth.getProfile().account_id;
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [taskTypes, setTaskTypes] = useState([]);
	const [taskStatuses, setTaskStatuses] = useState([]);
	const [formData, setFormData] = useState(defaultFormData);
	const [users, setUsers] = useState([]);
	const [claimants, setClaimants] = useState(defaultClaimants);

	const handleClose = () => {
		setFormData(defaultFormData);
		setUsers([]);
		setClaimants([]);
		setIsOpenScheduleModal(false);
	};

	const handleChange = ({ target }) => {
		let { name, value } = target;
		let additionalValues = {};

		if (['task_type_id'].includes(name)) {
			additionalValues = {
				title: taskTypes.find((taskType) => taskType.id === value)?.name || '',
				description: ''
			}
		}

		setFormData({ ...formData, ...additionalValues, [name]: value });
	};


	const handleSubmit = async e => {
		e.preventDefault();
		setIsSubmitting(true);
		let allowPost = true;
		if (!formData.task_type_id) {
			alert('Please select a task type');
			allowPost = false;
		}

		if (!formData.due_date) {
			alert('Please select a due date');
			allowPost = false;
		}



		// Bulk create tasks
		if (!claimants && !formData.all_claimants) {
			alert('Please select a claimant');
			allowPost = false;
		}

		// Package up the user data
		let assignedUsers = [];
		if (users.length > 0) {
			assignedUsers = users.map((user) => {
				return {
					user_id: user.id,
					first_name: user.first_name,
					last_name: user.last_name,
				}
			});
		}

		if (allowPost) {
			try {
				let result = await taskAPI.bulkCreateTasks(account_id, {
					account_id,
					assignedUsers,
					all_claimants: formData.all_claimants,
					claimantIdList: claimants.map((claimant) => claimant.id),
					task_type_id: formData.task_type_id,
					task_status_id: formData.task_status_id,
					title: formData.title,
					description: formData.description,
					due_date: moment(formData.due_date).format('YYYY-MM-DD hh:mm A') || null,
				});
				if (!result?.data) {
					throw new Error('Error creating task');
				}

				toast.success('Task(s) created', { theme: 'colored' });
				refreshParent();
				handleClose();
			} catch (error) {
				console.log(error);
				toast.error('Error creating task(s)', { theme: 'colored' });
			}
		}

		setIsSubmitting(false);

		// // Determine if we are creating multiple tasks for multiple claimants
		// if (claimants.length > 1) {
		// 	let tasksArray = claimants.map(claimant => {
		// 		return {
		// 			...formData,
		// 			claimant_id: claimant.id,
		// 			assigned_to: formData.assigned_to || null,
		// 			due_date: moment(formData.due_date).format('YYYY-MM-DD hh:mm A') || null,
		// 			creation_user_id: auth.getProfile().data.user_id
		// 		};
		// 	});

		// 	try {
		// 		let result = await taskAPI.bulkCreateTasks({ tasksArray, assignedUsers });
		// 		if (!result?.data) {
		// 			throw new Error('Error creating task');
		// 		}

		// 		handleClose();
		// 	} catch (error) {
		// 		console.log(error);
		// 	}
		// } else if (claimants.length < 2) {
		// 	// Create single task
		// 	formData.claimant_id = claimants.length === 1 ? claimants[0].id : null;

		// 	try {
		// 		let newTaskResult = await taskAPI.createTask({
		// 			...formData,
		// 			assignedUsers
		// 		});
		// 		if (!newTaskResult?.data?.id) {
		// 			throw new Error('Unable to create new task.');
		// 		}

		// 		handleClose();
		// 		refreshParent();
		// 	} catch (error) {
		// 		console.log(error);
		// 	}
		// }

		// setIsSubmitting(false);
	};

	// Handle Claimant Selection
	const handleClaimantSelection = (selectedClaimants = []) => {
		// Check if the claimant is already in the list
		let approvedClaimants = [];

		for (let i = 0; i < selectedClaimants.length; i++) {
			let allowClaimant = true;
			for (let j = 0; j < claimants.length; j++) {
				if (selectedClaimants[i].id === claimants[j].id) {
					allowClaimant = false
				}
			}
			if (allowClaimant) {
				approvedClaimants.push(selectedClaimants[i]);
			}
		}

		setClaimants([...claimants, ...approvedClaimants]);
	};

	// Handle User Selection
	const handleUserSelection = (selectedUsers = []) => {
		let approvedUsers = [];

		for (let i = 0; i < selectedUsers.length; i++) {
			let allowClaimant = true;
			for (let j = 0; j < users.length; j++) {
				if (selectedUsers[i].id === users[j].id) {
					allowClaimant = false
				}
			}
			if (allowClaimant) {
				approvedUsers.push(selectedUsers[i]);
			}
		}

		setUsers([...users, ...approvedUsers]);
	};

	// Get related data @TODO: Move this to a context
	const getRelatedData = async () => {
		try {
			let taskTypeResult = await taskAPI.getTaskTypes();
			if (!taskTypeResult?.data?.rows) {
				throw new Error('Unable to get task types.');
			}

			let taskStatusResult = await taskAPI.getTaskStatuses();
			if (!taskStatusResult?.data?.rows) {
				throw new Error('Unable to get task statuses.');
			}

			setTaskTypes(taskTypeResult.data.rows);
			setTaskStatuses(taskStatusResult.data.rows);
		} catch (error) {
			console.log(error);
		}
	};

	// Handle Show
	const handleShow = () => {
		setClaimants(defaultClaimants);
		setFormData(defaultFormData);
		getRelatedData();
	};

	useEffect(() => {
		if (isOpenScheduleModal) {
			setFormData({
				...formData,
				due_date: scheduleStartDate
			});
		} else {
			setScheduleStartDate(null);
		}
	}, [isOpenScheduleModal, scheduleStartDate, scheduleEndDate]);

	return (
		<Modal
			show={isOpenScheduleModal}
			onHide={handleClose}
			onShow={handleShow}
			contentClassName="border"
		>
			<Form onSubmit={handleSubmit}>
				<Modal.Header
					closeButton
					closeVariant={isDark ? 'white' : undefined}
					className="bg-light px-card border-bottom-0"
				>
					<Modal.Title as="h5"> Create Task</Modal.Title>
				</Modal.Header>
				<Modal.Body className="p-card">
					<Form.Group className="mb-3" controlId="task_type_id">
						<Form.Label className="fs-0">Task Type</Form.Label>
						<Form.Select
							name="task_type_id"
							defaultValue={''}
							value={formData.task_type_id || null}
							onChange={handleChange}
							disabled={isSubmitting}
							required>
							<option value={""}>--Select One--</option>
							{Array.isArray(taskTypes) && taskTypes.map((taskType, index) => (
								<option key={index} value={taskType.id}>{taskType.type}</option>
							))}
						</Form.Select>
					</Form.Group>
					{
						formData.task_type_id &&
						<>

							{
								(formData.task_type_id === 1) && (
									<Form.Group className="mb-3" controlId="title">
										<Form.Label className="fs-0">Title</Form.Label>
										<Form.Control
											type="text"
											name="title"
											value={formData.title}
											required
											disabled={isSubmitting}
											onChange={handleChange}
										/>
									</Form.Group>
								)
							}

							{
								formData.task_type_id === '1' && (
									<Form.Group className="mb-3" controlId="title">
										<Form.Label className="fs-0">Title</Form.Label>
										<Form.Control
											name="title"
											value={formData.title || ''}
											onChange={handleChange}
											disabled={isSubmitting}
											required />
									</Form.Group>
								)
							}

							<div className="py-3 border-bottom border-top my-3">

								<p className="fw-bold">Assign Claimants(s)
									{
										(!formData.all_claimants && !isSubmitting) && (
											<ClaimantQuickFind
												buttonLabel={'Search Claimants'}
												buttonVariant={'link'}
												handleClaimantSelection={handleClaimantSelection}
												requiredSearchParams={{ account_id }}
												defaultSearchParams={{}}
											/>
										)
									}
								</p>

								{/* All Claimants Checkbox */}
								{/* <Form.Check
									type="checkbox"
									id="all_claimants"
									name="all_claimants"
									label="All Claimants"
									checked={formData.all_claimants}
									onChange={e => {
										setFormData({
											...formData,
											all_claimants: e.target.checked,
											claimants: []
										});
									}}
								/> */}

								{
									!formData.all_claimants ?
										(
											(claimants.length) > 0 ? (
												claimants.length > 10 ? (
													<>
														<p>More than 10 claimants have been selected.

															<Button
																variant="link"
																className="p-0 float-end text-danger"
																disabled={isSubmitting}
																onClick={() => {
																	setClaimants([]);
																}}
															>
																<FontAwesomeIcon className="text-danger" icon={faTrash} /> Clear
															</Button>
														</p>
													</>
												) :
													<>

														<Table striped bordered size="sm">
															<thead>
																<tr>
																	<th>Claimant</th>
																</tr>
															</thead>
															<tbody>
																{claimants.map((claimant, index) => (
																	<tr key={index}>
																		<td>
																			{claimant.first_name} {claimant.last_name}
																			<Button
																				variant="link"
																				className="p-0 float-end"
																				disabled={isSubmitting}
																				onClick={() => {
																					setClaimants(claimants.filter((c) => c.id !== claimant.id));
																				}}
																			>
																				<FontAwesomeIcon className="text-danger" icon={faTrash} />
																			</Button>
																		</td>
																	</tr>
																))}
															</tbody>
														</Table>
													</>
											) : <p className="text-danger">No claimants selected. You must select at least one claimant.</p>
										) : <p className="text-warning">All claimants will get assigned this task.</p>
								}
							</div>



							<Form.Group className="mb-3" controlId="due_date">
								<Form.Label className="fs-0">Due Date</Form.Label>
								<DatePicker
									selected={scheduleStartDate}
									minDate={new Date()}
									onChange={date => {
										setScheduleStartDate(date);
										setFormData({ ...formData, due_date: date });
									}}
									className="form-control"
									placeholderText="MM-DD-YYYY H:M"
									dateFormat="MM-dd-yyyy h:mm aa"
									showTimeSelect
									required
								/>
							</Form.Group>

							<div className="py-3 border-bottom border-top my-3">
								<p className="fw-bold">Assign User(s)
									<UserQuickFind
										buttonLabel={'Search Users'}
										buttonVariant={'link'}
										handleUserSelection={handleUserSelection}
										requiredSearchParams={{ account_id }}
										defaultSearchParams={{}}
									/>
								</p>

								{
									users.length > 0 && (
										<>
											<Table striped bordered size="sm">
												<thead>
													<tr>
														<th>Name</th>
													</tr>
												</thead>
												<tbody>
													{users.map((user, index) => (
														<tr key={index}>
															<td>
																{user.first_name} {user.last_name}
																<Button
																	variant="link"
																	className="p-0 float-end"
																	disabled={isSubmitting}
																	onClick={() => {
																		setUsers(users.filter((c) => c.id !== user.id));
																	}}
																>
																	<FontAwesomeIcon className="text-danger" icon={faTrash} />
																</Button>
															</td>
														</tr>
													))}
												</tbody>
											</Table>
										</>
									)
								}
							</div>



							<Form.Group className="mb-3" controlId="description">
								<Form.Label className="fs-0">Description</Form.Label>
								<Form.Control
									as="textarea"
									rows={3}
									value={formData.description}
									name="description"
									onChange={handleChange}
									disabled={isSubmitting}
								/>
							</Form.Group>

						</>
					}
				</Modal.Body>
				<Modal.Footer className="bg-light px-card border-top-0">
					{
						isSubmitting ? (
							<Button variant="primary" className="px-4 mx-0" type="submit" disabled={isSubmitting}>
								<Spinner animation="border" size="sm" />
							</Button>
						)
							:
							<>

								<Button variant="outline-secondary" className="px-4 mr-3" onClick={() => setIsOpenScheduleModal(false)}>
									Cancel
								</Button>
								{' '}
								<Button
									variant={
										!formData.task_type_id ||
											(claimants.length === 0 && !formData.all_claimants) ||
											!formData.due_date ? 'secondary' : 'primary'
									}
									className="px-4 mx-0"
									type="submit"
									disabled={
										!formData.task_type_id ||
										(claimants.length === 0 && !formData.all_claimants) ||
										!formData.due_date
									}
								>
									Create Task
								</Button>
							</>
					}

				</Modal.Footer>
			</Form>
		</Modal>
	);
};

CreateTaskForm.propTypes = {
	setIsOpenScheduleModal: PropTypes.func.isRequired,
	isOpenScheduleModal: PropTypes.bool.isRequired,
	setTasks: PropTypes.func,
	tasks: PropTypes.array,
	scheduleStartDate: PropTypes.instanceOf(Date),
	setScheduleStartDate: PropTypes.func.isRequired,
	scheduleEndDate: PropTypes.instanceOf(Date),
	setScheduleEndDate: PropTypes.func.isRequired,
	refreshParent: PropTypes.func,
	defaultClaimants: PropTypes.array
};

export default CreateTaskForm;