import React, { useRef } from 'react';
import IconButton from 'components/common/IconButton';
import PropTypes from 'prop-types';
import { Col, Form } from 'react-bootstrap';

const PaymentTableHeader = ({ label = "Payment Methods", handleBulkActions, selectedRowIds, page } = {}) => {
    const formRef = useRef();
    const onSubmit = async (e) => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }

        let formDataObj = { bulk_action: "add" }; // Set default action to add new payment method
        const formData = new FormData(formRef.current);
        for (let [key, value] of formData) {
            formDataObj[key] = value;
        }
        if (typeof handleBulkActions === 'function') {
            let selectedRows = Array.isArray(page) ? page.reduce((acc, { isSelected = false, original } = {}) => (
                [...acc, ...(isSelected ? [original] : [])]
            ), []) : [];
            let selectedIds = selectedRows.length > 0 ? selectedRows.map(({ id } = {}) => id) : [];
            await handleBulkActions(formDataObj, selectedRows, selectedIds);
        }
    };

    return (
        <Form noValidate ref={formRef} onSubmit={onSubmit} className="row flex-between-center">
            <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
                <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">{label}</h5>
            </Col>
            <Col xs={8} sm="auto" className="ms-auto text-end ps-0">
                {Object.keys(selectedRowIds).length === 1 ? (
                    <Form.Group className="d-flex">
                        <Form.Select size="sm" aria-label="Bulk actions" name="bulk_action" className="flex-grow-1 w-auto">
                            <option value="set_primary">Set Primary</option>
                            <option value="update">Update</option>
                            <option value="delete">Delete</option>
                        </Form.Select>
                        <IconButton variant="primary" size="sm" icon="check" className="ms-2" type="submit" iconAlign="left">
                            <span className="d-sm-inline-block ms-1">Apply</span>
                        </IconButton>
                    </Form.Group>
                ) : (
                    <div className="d-flex">
                        <IconButton variant="falcon-default" size="sm" icon="plus" transform="shrink-3" type="submit">
                            <span className="d-none d-sm-inline-block ms-1">Add Payment Method</span>
                        </IconButton>
                    </div>
                )}
            </Col>
        </Form>
    );
};

PaymentTableHeader.propTypes = {
    label: PropTypes.string,
    handleBulkActions: PropTypes.func,
    selectedRowIds: PropTypes.object,
    page: PropTypes.array,
};

export default PaymentTableHeader;
