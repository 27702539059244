import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form, Row, Col } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { userAPI } from 'utils/api/fulcrum-api';
import UserAdminToggle from '../profile/UserAdminToggle';

const UserServicePermissions = ({ user, setUser }) => {
	const [loading, setLoading] = useState(true);
	const [permissions, setPermissions] = useState({
		CASE_FILE_REQUESTS: {
			allowed: false,
			label: 'Case File Requests',
			description: 'Allows the user to request case files from the Fulcrum system.'
		},
		HEARING_COVERAGE_REQUESTS: {
			allowed: false,
			label: 'Hearing Coverage Requests',
			description: 'Allows the user to request hearing coverage from the Fulcrum system.'
		},
		HEARING_ATTENDANCE_REQUESTS: {
			allowed: false,
			label: 'Hearing Attendance Requests',
			description: 'Allows the user to request hearing attendance from the Fulcrum system.'
		},
		BRIEF_REQUESTS: {
			allowed: false,
			label: 'Brief Requests',
			description: 'Allows the user to request briefs from the Fulcrum system.'
		},
		BRIEF_TOOL: {
			allowed: false,
			label: 'Brief Tool',
			description: 'Allows the user to use the brief tool.'
		},
		MEDICAL_RECORD_REQUESTS: {
			allowed: false,
			label: 'Medical Record Requests',
			description: 'Allows the user to request medical records from the Fulcrum system.'
		},
		CLAIMANT_LOCATOR_REQUESTS: {
			allowed: false,
			label: 'Claimant Locator Requests',
			description: 'Allows the user to request claimant locator information from the Fulcrum system.'
		},
	});

	// Get user permissions
	const getUserPermissions = async () => {
		try {
			let result = await userAPI.getUserPermissions(user.id);
			if (!result?.data) { return; }
			let userPermissions = permissions;
			// Loop through permissions
			for (let permission of result.data) {
				if (userPermissions[permission.permission_id]) {
					userPermissions[permission.permission_id].allowed = true;
				}
			}

			setPermissions(userPermissions);
		} catch (error) {
			console.error(error);
			toast.error('Error getting user permissions', { theme: 'colored' });
		}
		setLoading(false);
	};

	// Set user permission
	const setUserPermission = async (e, permissionId) => {
		// let { name, value, checked } = e.target;
		let { checked } = e.target;

		if (!permissionId) { alert('No permission ID'); return; }

		try {
			if (checked) {
				// Add permission
				let result = await userAPI.createUserPermission(user.id, { permissionId, allowed: true });
				if (!result?.data) { throw new Error('Error setting user permission'); }

			} else {
				// Delete Permission
				let result = await userAPI.deleteUserPermission(user.id, permissionId);
				if (!result?.data) { throw new Error('Error setting user permission'); }
			}

			toast.success('User permission updated', { theme: 'colored' });
			setPermissions({
				...permissions,
				[permissionId]: {
					...permissions[permissionId],
					allowed: checked
				}
			});
		} catch (error) {
			console.error(error);
			toast.error('Error setting user permission', { theme: 'colored' });
		}
	};

	useEffect(() => { getUserPermissions(); }, []);

	return (
		<div id="user-service-permissions">
			<h5>User Permissions</h5>
			<p>These permissions are for each individual user and do not consider their assigned user roles.</p>
			<hr />

			<UserAdminToggle user={user} setUser={setUser} />

			{loading ?
				<p>Loading permsissions...</p>
				:
				Object.keys(permissions).map((permission) => (
					<Row key={permission} className="pt-2 border-bottom">
						<Col sm={1}>
							<Form.Check
								type="switch"
								id={permission}
								className="d-inline"
								checked={permissions[permission].allowed}
								onChange={(e) => setUserPermission(e, permission)}
							/>
						</Col>
						<Col>
							<span className="fw-bold">{permissions[permission].label}: </span>
							<p>{permissions[permission].description}</p>
						</Col>
					</Row>
				))}
		</div>
	);
};

UserServicePermissions.propTypes = {
	user: PropTypes.object,
	setUser: PropTypes.func
};

export default UserServicePermissions;