import React, { useState } from 'react';
import { Table, Modal, Button } from 'react-bootstrap';
import { assureAPI } from 'utils/api/fulcrum-api';

const ViewAttorneys = ({ modalState, setModalState = () => { }, attorneySelectCallBack = () => { } }) => {
    const [foundAttorneys, setFoundAttorneys] = useState([]);
    const [loading, setLoading] = useState(false);

    // Get assure attorney list
    const getAttorneyList = async () => {
        setLoading(true);
        try {
            let result = await assureAPI.getAttorneyList();
            setFoundAttorneys(result?.data?.data || []);
        } catch (error) {
            console.log(error);
        }
        setLoading(false);
    };

    // Handle attorney select
    const handleAttorneySelect = (attorney) => {
        attorneySelectCallBack(attorney);
        setModalState(false);
    };

    // Handle open
    const handleOpen = () => {
        getAttorneyList();
    };

    // Handle close
    const handleClose = () => {
        setModalState(false);
    };


    return (
        <div>
            <Modal
                show={modalState}
                onShow={handleOpen}
                onHide={handleClose}
                size="lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Attorneys</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        loading ?
                            <div className="text-center">
                                <div className="spinner-border" role="status">
                                    <span className="sr-only">Loading Assure attorneys...</span>
                                </div>
                            </div>
                            :
                            <Table size='sm'>
                                <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>Attorney</th>
                                        <th>Is Active</th>
                                        <th>Is Assignable</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        Array.isArray(foundAttorneys) && foundAttorneys.map((attorney, index) => (
                                            <tr key={index} onClick={() => { handleAttorneySelect(attorney) }}>
                                                <td>{attorney.id}</td>
                                                <td>{attorney.first_name} {attorney.last_name}</td>
                                                <td>{attorney.is_active ? "Yes" : 'No'}</td>
                                                <td>{attorney.is_assignable ? 'Yes' : 'No'}</td>
                                                <td>
                                                    <Button
                                                        variant="link"
                                                        className=""
                                                        onClick={() => { handleAttorneySelect(attorney) }}
                                                    >Select</Button>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </Table>
                    }
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default ViewAttorneys;