import React, { useContext } from 'react';
import Dash from '../../dashboard-components';
import { AuthContext } from 'context/Context';

const Dashboard = ({ accountSettings }) => {
  const { permissionsState } = useContext(AuthContext);

  console.log("permissions:", permissionsState.permissions);
  console.log("settings: ", permissionsState.settings);

  return (
    <>
      <Dash />
    </>
  );
};

export default Dashboard;