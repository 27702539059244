import React, { useEffect, useState } from 'react';
import { Card, Dropdown, Button } from 'react-bootstrap';
import moment from 'moment';
import { removeEmptyFromObj } from 'utils/functions';
import { assureAPI, statusReportAPI } from 'utils/api/fulcrum-api';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import CustomAdvanceTablePagination from 'components/common/advance-table/CustomAdvanceTablePagination';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import CardDropdown from 'components/common/CardDropdown';
import StatusReportItemTableHeader from './StatusReportItemTableHeader';
import ReactInterval from 'react-interval';
const defaultColumns = [
	{
		accessor: 'id',
		Header: 'ID',
		headerProps: { className: 'pe-1' },
		cellProps: {
			className: 'py-2'
		},
		visible: true,
	},
	{
		accessor: 'status_report_request_id',
		Header: 'Status Report Request ID',
		headerProps: { className: 'pe-1' },
		cellProps: {
			className: 'py-2'
		},
		visible: true,
	},
	{
		accessor: 'ac_report',
		Header: 'Report Type',
		headerProps: { className: 'pe-1' },
		cellProps: {
			className: 'py-2'
		},
		visible: true,
	},
	{
		accessor: 'first_name',
		Header: 'First Name',
		headerProps: { className: 'pe-1' },
		cellProps: {
			className: 'py-2'
		},
		visible: true,
	},
	{
		accessor: 'last_name',
		Header: 'Last Name',
		headerProps: { className: 'pe-1' },
		cellProps: {
			className: 'py-2'
		},
		visible: true,
	},
	{
		accessor: 'middle_name',
		Header: 'Middle Name',
		headerProps: { className: 'pe-1' },
		cellProps: {
			className: 'py-2'
		},
		visible: true,
	},
	{
		accessor: 'last_4_ssn',
		Header: 'Last 4 SSN',
		headerProps: { className: 'pe-1' },
		cellProps: {
			className: 'py-2'
		},
		visible: true,
	},
	{
		accessor: 'appeals_office_with_jurisdiction',
		Header: 'Appeals Office with Jurisdiction',
		headerProps: { className: 'pe-1' },
		cellProps: {
			className: 'py-2'
		},
		visible: true,
	},
	{
		accessor: 'transfer_information_to',
		Header: 'Transfer Information To',
		headerProps: { className: 'pe-1' },
		cellProps: {
			className: 'py-2'
		},
		visible: true,
	},
	{
		accessor: 'transfer_information_from',
		Header: 'Transfer Information From',
		headerProps: { className: 'pe-1' },
		cellProps: {
			className: 'py-2'
		},
		visible: true,
	},
	{
		accessor: 'request_date',
		Header: 'Request Date',
		headerProps: { className: 'pe-1' },
		cellProps: {
			className: 'py-2'
		},
		visible: true,
	},
	{
		accessor: 'hearing_office_with_jurisdiction',
		Header: 'Hearing Office with Jurisdiction',
		headerProps: { className: 'pe-1' },
		cellProps: {
			className: 'py-2'
		},
		visible: true,
	},
	{
		accessor: 'status_of_case',
		Header: 'Status of Case',
		headerProps: { className: 'pe-1' },
		cellProps: {
			className: 'py-2'
		},
		visible: true,
	},
	{
		accessor: 'status_date',
		Header: 'Status Date',
		headerProps: { className: 'pe-1' },
		cellProps: {
			className: 'py-2'
		},
		visible: true,
	},
	{
		accessor: 'claim_type',
		Header: 'Claim Type',
		headerProps: { className: 'pe-1' },
		cellProps: {
			className: 'py-2'
		},
		visible: true,
	},
	{
		accessor: 'electronic_folder',
		Header: 'Electronic Folder',
		headerProps: { className: 'pe-1' },
		cellProps: {
			className: 'py-2'
		},
		visible: true,
	},
	{
		accessor: 'hearing_requested_date',
		Header: 'Hearing Requested Date',
		headerProps: { className: 'pe-1' },
		cellProps: {
			className: 'py-2'
		},
		visible: true,
	},
	{
		accessor: 'expedited_case',
		Header: 'Expedited Case',
		headerProps: { className: 'pe-1' },
		cellProps: {
			className: 'py-2'
		},
		visible: true,
	},
	{
		accessor: 'hearing_Scheduled_date',
		Header: 'Hearing Scheduled Date',
		headerProps: { className: 'pe-1' },
		cellProps: {
			className: 'py-2'
		},
		visible: true,
	},
	{
		accessor: 'hearing_time',
		Header: 'Hearing Time',
		headerProps: { className: 'pe-1' },
		cellProps: {
			className: 'py-2'
		},
		visible: true,
	},
	{
		accessor: 'claimant_location',
		Header: 'Claimant Location',
		headerProps: { className: 'pe-1' },
		cellProps: {
			className: 'py-2'
		},
		visible: true,
	},
	{
		accessor: 'representative_location',
		Header: 'Representative Location',
		headerProps: { className: 'pe-1' },
		cellProps: {
			className: 'py-2'
		},
		visible: true,
	},
	{
		accessor: 'alj_location',
		Header: 'ALJ Location',
		headerProps: { className: 'pe-1' },
		cellProps: {
			className: 'py-2'
		},
		visible: true,
	},
	{
		accessor: 'alj_last_name',
		Header: 'ALJ Last Name',
		headerProps: { className: 'pe-1' },
		cellProps: {
			className: 'py-2'
		},
		visible: true,
	},
	{
		accessor: 'alj_first_name',
		Header: 'ALJ First Name',
		headerProps: { className: 'pe-1' },
		cellProps: {
			className: 'py-2'
		},
		visible: true,
	},
	{
		accessor: 'medical_expert',
		Header: 'Medical Expert',
		headerProps: { className: 'pe-1' },
		cellProps: {
			className: 'py-2'
		},
		visible: true,
	},
	{
		accessor: 'vocational_expert',
		Header: 'Vocational Expert',
		headerProps: { className: 'pe-1' },
		cellProps: {
			className: 'py-2'
		},
		visible: true,
	},
	{
		accessor: 'hearing_held_date',
		Header: 'Hearing Held Date',
		headerProps: { className: 'pe-1' },
		cellProps: {
			className: 'py-2'
		},
		visible: true,
	},
	{
		accessor: 't2_decision',
		Header: 'T2 Decision',
		headerProps: { className: 'pe-1' },
		cellProps: {
			className: 'py-2'
		},
		visible: true,
	},
	{
		accessor: 't16_decision',
		Header: 'T16 Decision',
		headerProps: { className: 'pe-1' },
		cellProps: {
			className: 'py-2'
		},
		visible: true,
	},
	{
		accessor: 'fee_agreement',
		Header: 'Fee Agreement',
		headerProps: { className: 'pe-1' },
		cellProps: {
			className: 'py-2'
		},
		visible: true,
	},
	{
		accessor: 'fee_petition',
		Header: 'Fee Petition',
		headerProps: { className: 'pe-1' },
		cellProps: {
			className: 'py-2'
		},
		visible: true,
	},
	{
		accessor: 'created_at',
		Header: 'Created At',
		headerProps: { className: 'pe-1' },
		cellProps: {
			className: 'py-2'
		},
		visible: true,
	},
	{
		accessor: 'updated_at',
		Header: 'Updated At',
		headerProps: { className: 'pe-1' },
		cellProps: {
			className: 'py-2'
		},
		visible: true,
	}
];

const StatusReportItemTable = ({ claimant = {}, tableHeaderLabel = "Status Report Items", defaultVisibleColumns = defaultColumns, defaultSearchParams = {}, requiredSearchParams = {} }) => {
	const [createStatusReportItemModal, setCreateStatusReportItemModal] = useState(false);
	const [statusReportItemDetailsModal, setStatusReportItemDetailsModal] = useState(false);
	const [statusReportItemData, setStatusReportItemData] = useState([]);
	const [selectedStatusReportItem, setSelectedStatusReportItem] = useState({});
	const [statusReportItemCount, setStatusReportItemCount] = useState(0);
	const [columns, setColumns] = useState(defaultVisibleColumns);
	const [Pages, setPages] = useState([1]);
	const [activePage, setActivePage] = useState(1);
	const [perPage, setPerPage] = useState(10);
	const [searching, setSearching] = useState(true);
	const [lastQuery, setLastQuery] = useState({});
	const [searchParams, setSearchParams] = useState({ ...defaultSearchParams, ...requiredSearchParams });
	const [appliedSortOrders, setAppliedSortOrders] = useState([]);

	const headerClickFn = (id, previousSortBy) => {
		if (!id) {
			return;
		}
		let foundIndex = appliedSortOrders.findIndex(s => s[0] === id);
		let sortBy = !previousSortBy ? "ASC" : previousSortBy === "asc" ? "DESC" : '';

		if (!sortBy) { // Not Sorted so remove clicked sort item 
			if (foundIndex > -1) {
				setAppliedSortOrders((prev) => {
					let newSortOrders = [...prev];
					newSortOrders.splice(foundIndex, 1); // remove clicked sort item
					return newSortOrders;
				});
			}
		} else { // Sort by Ascending or Descending 
			let newSort = [id, sortBy];
			if (foundIndex > -1) { // replaces existing 
				setAppliedSortOrders((prev) => {
					let newSortOrders = [...prev];
					newSortOrders[foundIndex] = newSort;
					return newSortOrders;
				});
			} else { // inserts 
				setAppliedSortOrders((prev) => [...prev, newSort]);
			}
		}
	};

	const formatData = (data = []) => {
		let adjustedData = data.map(record => {
			return {
				...record,
				ac_report: !record.ac_report ? "Hearing Status Report" : "Appeals Status Report",
				created_at: record?.createdAt ? moment(record.createdAt).format('MM/DD/YYYY hh:mm A') : null,
				menu: (<div className="d-flex justify-content-between float-end">
					<CardDropdown>
						<div className="py-2">
							<Dropdown.Item onClick={async () => { setSelectedStatusReportItem(record); setStatusReportItemDetailsModal(true) }} className="">View</Dropdown.Item>
						</div>
					</CardDropdown>
				</div>)
			}
		});
		setStatusReportItemData(adjustedData);
	};

	const statusReportItemSearch = async (params = {}, saveLastQuery = false, options = {}) => {
		try {
			params = removeEmptyFromObj(params);
			params.page = activePage;
			params.limit = perPage;
			params.navSize = 5; // How many buttons to show for navigation 
			params.order = appliedSortOrders; // Example [[id, 'ASC']]
			let { error, response, data } = await statusReportAPI.searchAllStatusReportItems(params, options);
			if (error) {
				if (typeof response?.error === 'string') {
					throw new Error(`${error} - ${response.error}`);
				} else if (typeof response === 'string') {
					throw new Error(`${error} - ${response}`);
				}
				throw new Error(`${error} - There was an error searching status report requests.`);
			}

			let { count = 0, rows = [], pages = [], page = 1, pageCount = 0 } = data || {}; // data = { count = 0, rows = [], limit, pageCount, page, pages = [1,2,3,10] } 
			if (!rows || (Array.isArray(rows) && rows.length === 0)) {
				throw new Error('No status report data found');
			}

			if (pageCount < activePage) {
				setActivePage(1);
			} else {
				setActivePage(page);
			}
			setPages(pages);
			formatData(rows);
			setStatusReportItemCount(count);
			setSearching(false);

			if (saveLastQuery) {
				setLastQuery(params);
			}
		} catch (error) {
			console.log(error);
		}
	};

	const newSearch = async (params) => {
		setSearching(true);
		let adjustedSearchParams = {
			...params,
			...requiredSearchParams
		};

		statusReportItemSearch(adjustedSearchParams);
	};

	// Refresh last query
	const refreshLastQuery = async () => {
		statusReportItemSearch({ ...lastQuery, ...requiredSearchParams }, false);
	};

	useEffect(() => {
		newSearch({ ...defaultSearchParams });
	}, []);

	// Apply search filters
	useEffect(() => { newSearch(searchParams) }, [searchParams, activePage, perPage, appliedSortOrders]);

	return (<>
		<AdvanceTableWrapper
			columns={columns}
			data={statusReportItemData}
			// selection
			sortable
			pagination
			perPage={perPage}
			setPerPage={setPerPage}
		>
			<Card className="shadow-none">
				<Card.Header>
					<StatusReportItemTableHeader
						table
						label={tableHeaderLabel}
						setCreateStatusReportItemModal={setCreateStatusReportItemModal}
						defaultSearchParams={defaultSearchParams}
						searchParams={searchParams}
						setSearchParams={setSearchParams}
						refreshLastQuery={refreshLastQuery}
						requiredSearchParams={requiredSearchParams}
						statusReportItemData={statusReportItemData}
						claimant={claimant}
					/>
				</Card.Header>
				<Card.Body className="p-0">
					{
						searching
							?
							<p>Searching Status Reports....</p>
							:
							<AdvanceTable
								table
								headerClickFn={headerClickFn}
								appliedSortOrders={appliedSortOrders}
								headerClassName="bg-200 text-900 text-nowrap align-middle"
								rowClassName="align-middle white-space-nowrap"
								tableProps={{
									size: 'sm',
									striped: true,
									className: 'fs--1 mb-0 overflow-hidden'
								}}
							/>
					}
				</Card.Body>
				<Card.Footer>
					<CustomAdvanceTablePagination
						table
						Pages={Pages}
						activePage={activePage}
						setActivePage={setActivePage}
					/>
				</Card.Footer>
			</Card>
		</AdvanceTableWrapper>

		<ReactInterval timeout={15000} enabled={true} callback={() => { refreshLastQuery({ ignoreTouch: true }) }} />

	</>);
};


export default StatusReportItemTable;