import React from 'react';
import { Row, Col, Tabs, Tab, Card } from 'react-bootstrap';
import TaskTable from 'components/task/TaskTable';
import DocumentTable from 'components/documents/DocumentTable';
import ClaimantBio from './bio/ClaimantBio';
import ClaimantInboxOutboxView from 'components/pages/inbox-outbox/ClaimantInboxOutboxView';
import CaseInformation from './case/CaseInformation';
import NoteList from 'components/app/notes/NoteList';
import Hearing from './hearing/Hearing';
import AssureCase from './case/AssureCase';
import Atlasware from 'components/pages/assure/atlasware';
import RevisionLogTable from 'components/app/revision-logs/RevisionLogTable';


const ClaimantProfileTabs = ({ attorneyList, setAttorneyList = () => { }, caseStatuses, claimant, setClaimant, selectedCase = {}, componentState, setComponentState = () => { }, refreshParent = () => { }, setSelectedCase = () => { }, getClaimantDetails = () => { } }) => {
	return (
		<Card>
			<Card.Body>
				<Row>
					<Col sm={8}>
						<Tabs defaultActiveKey={(claimant.active_case && claimant.active_case_id) ? 'claim' : 'bio'} id="uncontrolled-tab-example" unmountOnExit={true} mountOnEnter={true}>
							{
								(claimant.active_case && claimant.active_case_id) &&
								<Tab eventKey="claim" title="Claim" className='border-bottom border-x p-3'>
									<CaseInformation
										refreshParent={refreshParent}
										claimant={claimant}
										setClaimant={setClaimant}
										selectedCase={selectedCase}
										setSelectedCase={setSelectedCase}
										componentState={componentState}
										setComponentState={setComponentState}
										caseStatuses={caseStatuses}
										attorneyList={attorneyList}
									/>
								</Tab>
							}
							<Tab eventKey="bio" title="Bio" className='border-bottom border-x p-3'>
								<ClaimantBio claimant={claimant} setClaimant={setClaimant} />
							</Tab>


							{
								(claimant?.claimant_cases && claimant.claimant_cases.length > 0) &&
								<Tab eventKey="atlasware" title="Atlasware" className='border-bottom border-x p-3 overflow-auto'>
									<AssureCase
										claimant={claimant}
										setClaimant={setClaimant}
										selectedCase={selectedCase}
										setSelectedCase={setSelectedCase}
										refreshParent={refreshParent}
										displayAtlaswareContent={true}
									/>
								</Tab>
							}
							{
								(claimant.active_case && claimant.active_case_id) &&
								<Tab eventKey="hearing" title="Hearing" className='border-bottom border-x p-3 overflow-auto'>
									<Hearing refreshParent={refreshParent} claimant={claimant} setClaimant={setClaimant} selectedCase={selectedCase} setSelectedCase={setSelectedCase} />
								</Tab>
							}
							<Tab eventKey="tasks" title="Tasks" className='border-bottom border-x p-3 overflow-auto'>
								<TaskTable
									showMetrics={false}
									defaultSearchParams={{}}
									requiredSearchParams={{ claimant_id: claimant.id, account_id: claimant.account_id }}
									defaultClaimants={[claimant]}
									refreshParent={refreshParent}
									account_id={claimant.account_id}
								/>
							</Tab>
							<Tab eventKey="documents" title="Documents" className='overflow-auto border-bottom border-x p-3'>
								<DocumentTable
									defaultSearchParams={{}}
									requiredSearchParams={{ claimant_id: claimant.id }}
									defaultClaimants={[claimant]}
									refreshParent={refreshParent}
								/>
							</Tab>
							<Tab eventKey="correspondence" title="Correspondence" className='overflow-auto border-bottom p-3'>
								<ClaimantInboxOutboxView
									requiredSearchParams={{ claimant: claimant }}
									defaultSearchParams={{}}
									refreshParent={refreshParent}
								/>
							</Tab>
							<Tab eventKey="other" title="Other" className='border-bottom border-x p-3'>
								<Tabs defaultActiveKey="revision-logs" id="other-tabs">

									<Tab eventKey="revision-logs" title="Revision Logs" className='border-bottom border-x p-3 overflow-auto'>
										<RevisionLogTable
											defaultSearchParams={{}}
											requiredSearchParams={{ table: 'claimant', parent_id: claimant.id, account_id: claimant.account_id }}
										/>
									</Tab>
								</Tabs>
							</Tab>
						</Tabs>
					</Col>
					<Col className="m-0 p-0">
						<div>
							<NoteList claimant={claimant} refreshParent={refreshParent} claimant_id={claimant.id} />
						</div>
					</Col>
				</Row>
			</Card.Body>
		</Card>
	);
};


export default ClaimantProfileTabs;