import React, { useState, useContext, useEffect } from 'react';
import Swal from 'sweetalert2';
import { Button, Dropdown, Card, Spinner, Row, Col, Alert, Tabs, Tab } from 'react-bootstrap';
import { assureAPI, claimantAPI } from 'utils/api/fulcrum-api';
import { toast } from 'react-toastify';
import CardDropdown from 'components/common/CardDropdown';
import CaseFiles from './CaseFiles';
import EREFileList from 'components/pages/assure/atlasware/ere-file-list';
import moment from 'moment';
import Uploader from 'components/pages/assure/atlasware/uploader';
// import StatusReports from './status-reports';

const AssureCase = ({ claimant, setClaimant, selectedCase, setSelectedCase = () => { }, displayAtlaswareContent = false, refreshParent = () => { } }) => {
	const [syncingCaseInfo, setSyncingCaseInfo] = useState(false);

	// Unlink Assure Case
	const unlinkAssureCase = async () => {
		Swal.fire({
			title: 'Unlink Assure Case?',
			text: "This action will unlink the Assure case from this claimant's case file.",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Yes, unlink it!'
		}).then(async (result) => {
			if (result.isConfirmed) {
				try {
					let result = await assureAPI.unlinkAssureCase(claimant.id, selectedCase.id);
					if (!result?.data) {
						throw 'There was an error unlinking the case.';
					}
					setSelectedCase({ ...selectedCase, assure_case_id: null });
					toast.success('Case unlinked successfully.', { theme: 'colored' });
				} catch (err) {
					toast.error('There was an error unlinking the case.', { theme: 'colored' });
				}
			}
		});
	}

	// Create Assure Case and Link
	const createAssureCase = async () => {
		Swal.fire({
			title: 'Create and Link Assure Case?',
			text: "This action will create a case at Assure and link it to this claimant's case file.",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Yes, create it!'
		}).then(async (result) => {
			if (result.isConfirmed) {
				try {
					let result = await assureAPI.createAnLinkAssureCase(claimant.id, selectedCase.id);
					if (!result?.data) {
						let errorMessage = result?.response || result?.error || 'Unknown error'
						throw `Error creating and linking Assure case: ${JSON.stringify(errorMessage)}`;
					}
					setSelectedCase({
						...selectedCase,
						...result.data
					})
					console.log("HERERERERER", {
						...selectedCase,
						...result.data
					})
					toast.success('Assure case created and linked successfully.', { theme: 'colored' });
				} catch (error) {
					console.log(error);
					toast.error(error, { theme: 'colored' });
				}
			}
		});
	};

	// Sync Assure Case Information
	const syncAssureCaseInformation = async () => {
		setSyncingCaseInfo(true);
		try {
			let result = await assureAPI.syncCaseInformationWithAssure(claimant.id, selectedCase.id, {});
			if (!result?.data) {
				let errorMessage = result?.response || result?.error || 'Unknown error'
				throw `Error syncing Assure case information: ${JSON.stringify(errorMessage)}`;
			}
			refreshParent(selectedCase.id);
			toast.success('Assure case information synced successfully.', { theme: 'colored' });
		} catch (error) {
			toast.error(error, { theme: 'colored' });
		}
		setSyncingCaseInfo(false);
	};

	useEffect(() => {
		setSyncingCaseInfo(true);
		setSyncingCaseInfo(false);
	}, [selectedCase?.assure_case_id, selectedCase?.assure_case_file_id, selectedCase?.assure_case_file_status, selectedCase?.assure_exhibited_file_id])

	return (
		<div className="my-4">
			<h5 className="d-inline">ERE Case Information</h5>

			<div className="d-flex justify-content-between float-end  border rounded">
				<CardDropdown>
					<div className="py-2">
						{
							selectedCase?.assure_case_id
								?
								(
									<>
										<Dropdown.Item onClick={async () => { alert('coming') }} className="">View</Dropdown.Item>
										<Dropdown.Item onClick={async () => { unlinkAssureCase() }} className="text-danger">Unlink Assure Account</Dropdown.Item>
									</>
								)
								:
								(
									<>
									</>
								)
						}
					</div>
				</CardDropdown>
			</div>
			<Button
				variant="link"
				className="float-end"
				onClick={() => { syncAssureCaseInformation() }}
				disabled={syncingCaseInfo}
			>
				{
					syncingCaseInfo

						?
						<>
							<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
							<span className="ms-2">Syncing...</span>
						</>
						:
						<>
							{
								selectedCase?.assure_case_id && (
									<>
										<span className="fas fa-sync-alt me-2"></span>
										<span>Sync Assure Case Information</span>
									</>
								)
							}
						</>

				}
			</Button>
			<hr />
			{
				selectedCase?.assure_case_id
					?
					(
						<>
							<p className="border-bottom pb-2 mb-2"><span className="fw-bold">Assure Case ID:</span> <span className="float-end">{selectedCase?.assure_case_id}</span></p>
							<p className="border-bottom pb-2 mb-2"><span className="fw-bold">Claimant:</span> <span className="float-end">{claimant?.first_name} {claimant?.last_name}</span></p>
							<p className="border-bottom pb-2 mb-2"><span className="fw-bold">Case Status:</span> <span className="float-end">{selectedCase?.assure_case_status} ({selectedCase?.assure_case_status_id})</span></p>
							<p className="border-bottom pb-2 mb-2"><span className="fw-bold">Status Date:</span> <span className="float-end">{selectedCase?.assure_case_status_date ? moment(selectedCase.assure_case_status_date).format('MM-DD-YYYY hh:mm A') : ''}</span></p>
							<p className="border-bottom pb-2 mb-2"><span className="fw-bold">External ID:</span> <span className="float-end">{selectedCase?.assure_case_external_id}</span></p>
							{
								false && (
									<>
										<p className="border-bottom pb-2 mb-2"><span className="fw-bold">All Case File ID:</span> <span className="float-end">{selectedCase.assure_case_file_id}</span></p>
										<p className="border-bottom pb-2 mb-2"><span className="fw-bold">Exhibited Case File ID:</span> <span className="float-end">{selectedCase.assure_exhibited_file_id}</span></p>
									</>
								)
							}
							{
								displayAtlaswareContent && (
									selectedCase?.primary_representative?.assure_ere_credentials_id
										?
										(
											<Tabs variant='pills' defaultActiveKey="case-files" id="uncontrolled-tab-example" className="mt-5">
												<Tab className="border-top border-5  p-2 my-3" eventKey="case-files" title="Case Files">
													<Row>
														<Col>
															<CaseFiles
																key={`case-files-${selectedCase?.assure_case_id}`}
																claimant={claimant}
																setClaimant={setClaimant}
																selectedCase={selectedCase}
																setSelectedCase={setSelectedCase}
																refreshParent={refreshParent}
																type={2}
																syncingCaseInfo={syncingCaseInfo}
															/>
														</Col>
														{
															selectedCase?.assure_case_status_id == 23
																?
																(
																	<>

																	</>
																)
																:
																(

																	<Col>
																		<CaseFiles
																			key={`exhibited-case-files-${selectedCase?.assure_case_id}`}
																			claimant={claimant}
																			setClaimant={setClaimant}
																			selectedCase={selectedCase}
																			setSelectedCase={setSelectedCase}
																			refreshParent={refreshParent}
																			type={1}
																			syncingCaseInfo={syncingCaseInfo}
																		/>
																	</Col>
																)
														}
													</Row>
												</Tab>
												<Tab className="border-top border-5 p-2 my-3" eventKey="ere-file-list" title="ERE File List">
													<EREFileList
														claimant={claimant}
														setClaimant={setClaimant}
														selectedCase={selectedCase}
														setSelectedCase={setSelectedCase}
														refreshParent={refreshParent}
													/>
												</Tab>
												<Tab className="border-top border-5 p-2 my-3" eventKey="uploader" title="ERE Uploader">
													<Uploader
														claimant={claimant}
														refreshParent={refreshParent}
														defaultSearchParams={{ claimant_id: claimant.id }}
														requiredSearchParams={{ claimant_id: claimant.id }}
														columns={[
															// {
															// 	accessor: 'id',
															// 	Header: 'ID',
															// 	headerProps: { className: 'pe-1' },
															// 	cellProps: {
															// 		className: 'py-2'
															// 	},
															// },
															{
																accessor: 'document',
																Header: 'Document',
																headerProps: { className: 'pe-1' },
																cellProps: {
																	className: 'py-2'
																},
															},
															{
																accessor: 'document_type',
																Header: 'Type',
																headerProps: { className: 'pe-1' },
																cellProps: {
																	className: 'py-2'
																},
															},
															{
																accessor: 'status',
																Header: 'Status',
																headerProps: { className: 'pe-1' },
																cellProps: {
																	className: 'py-2'
																},
															},
															// {
															// 	accessor: 'primary_rep',
															// 	Header: 'Primary Rep',
															// 	headerProps: { className: 'pe-1' },
															// 	cellProps: {
															// 		className: 'py-2'
															// 	},
															// },
															{
																accessor: 'created_at',
																Header: 'Submitted',
																headerProps: { className: 'pe-1' },
																cellProps: {
																	className: 'py-2'
																},
															},
															{
																accessor: 'menu',
																Header: '',
																headerProps: { className: 'pe-1' },
																cellProps: {
																	className: 'py-2'
																},
															}
														]}
													/>
												</Tab>
												<Tab className="border border-top border-5 p-2 my-3" eventKey="status-report" title="Status Report">
													<h5>Status Report</h5>
												</Tab>
											</Tabs>
										)
										:
										(
											<div className="my-4 bg-200 text-center py-4 px-2 text-warning">
												<p>
													The primary representative you have selected has no linked ERE credentials.
													You must link ERE credentials to the primary representative in order to perform Atlasware case file requests.
												</p>
											</div>
										)
								)

							}
						</>
					)
					:
					(
						<>
							<div className="my-4  text-center px-2 text-warning">
								<Alert variant="info">
									<p>No Assure case has been linked to this case. In order to utilize Assure services, please link an Assure case.</p>
									<Button variant="warning" onClick={() => { createAssureCase() }}>Link Assure Case</Button>
								</Alert>
							</div>
						</>
					)
			}
		</div >
	);
};

export default AssureCase;