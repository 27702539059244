import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
let { emailAPI } = require('utils/api/fulcrum-api');
import Dropdown from 'react-bootstrap/Dropdown';
import { Form } from 'react-bootstrap';
import CardDropdown from 'components/common/CardDropdown';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faLaptopHouse } from '@fortawesome/free-solid-svg-icons'

// const EmailRow = ({ email, getEmailList, emails, setEmails, emailTypes }) => {
const EmailRow = ({ refreshParent = () => { }, email, emails, setEmails = () => { }, getEmailList = () => { } }) => {
	const [loading, setLoading] = useState(false);
	const defaultFormData = {
		email_type_id: email.email_type_id,
		email: email.email,
		extension: email.extension,
		note: email.note,
		is_primary: email.is_primary
	}
	const [formData, setFormData] = useState(defaultFormData);
	const [previousFormData, setPreviousFormData] = useState(defaultFormData);

	const handleInputChange = (event) => {
		let { name, value } = event.target;
		let additonalFormData = {};

		if (name === 'is_primary') {
			value = !formData[name];
		}

		setFormData({ ...formData, ...additonalFormData, [name]: value });
	};

	// Toggle primary email
	const togglePrimaryEmail = async (newPrimaryId) => {
		try {
			let result = await emailAPI.togglePrimaryEmail(newPrimaryId, email.entity_id);
			if (!result?.message) {
				throw new Error('Unable to update primary email.');
			} else if (result?.message === 'You must have at least one primary contact email.') {
				throw new Error(result.message);
			}
		} catch (error) {
			console.error(error);
			toast.error(`${error}`, {
				theme: 'colored'
			});
		}

		getEmailList();
	};

	// Update email
	const updateEmail = async (data) => {
		try {
			let result = await emailAPI.updateContactEmail(email.id, data);
			if (!result?.data) {
				throw new Error('Unable to update the email record.');
			}
			setPreviousFormData({
				...formData,
				...data
			});

			getEmailList();
		} catch (error) {
			toast.error(error.message, {
				theme: 'colored'
			});
		}
	};

	// Save on change
	const saveOnChange = async (event) => {
		let { name, value } = event.target;

		if (['is_primary'].includes(name)) {
			value = !formData[name];
			let newPrimaryId = value ? email.id : null;
			return await togglePrimaryEmail(newPrimaryId);
		}

		setFormData({ ...formData, [name]: value });

		if (previousFormData[name] !== value) {
			await updateEmail({ [name]: value });
		}
	};

	// Delete email
	const deleteEmail = async (emailId) => {
		Swal.fire({
			title: 'Permanently delete email?',
			text: "You won't be able to revert this!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Yes, delete it!'
		}).then(async (result) => {
			if (result.isConfirmed) {
				try {
					let result = await emailAPI.deleteContactEmail(emailId);
					if (!result?.message) {
						if (result?.response?.error) {
							throw new Error(result.response.error);
						}
						throw new Error('Unable to delete the email record.');
					}
					let newEmails = [...emails.filter(p => p.id !== emailId)];
					setEmails(newEmails);
					refreshParent(newEmails);
					toast.warning(`Email deleted successfully.`, {
						theme: 'colored'
					});
				} catch (error) {
					console.error(error);
					toast.error(`${error}`, {
						theme: 'colored'
					});
				}
			}
		});
	};

	// Set primary email
	// const setPrimaryEmail = async () => {
	// 	try {
	// 		let result = await emailAPI.setPrimaryEmail(email.id);
	// 		if (!result?.data) {
	// 			throw new Error('Unable to set the primary email record.');
	// 		}
	// 		setFormData(defaultFormData);
	// 	} catch (error) {
	// 		console.error(error);
	// 	}
	// };

	// Reset form
	// const resetForm = () => {
	// 	setFormData(previousFormData);
	// };


	useEffect(() => {
		setLoading(true);
		let emailData = {
			email_type_id: email.email_type_id,
			email: email.email,
			extension: email.extension,
			note: email.note,
			is_primary: email.is_primary
		};
		setFormData(emailData);
		setPreviousFormData(emailData);

		setLoading(false);
	}, [email, email?.is_primary]);

	if (loading) {
		return (<p>Loading...</p>)
	}

	return (

		<tr key={email.id} className="mb-1">
			<td className="px-1 py-1 text-center">

				{/* <FontAwesomeIcon icon={faEnvelope} className="text-twitter cursor-pointer" onClick={() => { alert('Comming soon') }} /> */}
				{/* {" "}&nbsp; */}
				<div className="d-inline">
					<Form.Check
						size="sm"
						type="checkbox"
						name='is_primary'
						checked={formData.is_primary}
						onChange={e => { handleInputChange(e); saveOnChange(e); }}
						className={`text-center ${formData.is_primary !== previousFormData.is_primary && ''} d-inline`}
					/>
				</div>

			</td>

			<td className="px-0 py-1">
				<Form.Control
					size="sm"
					type="text"
					// placeholder="Enter email number" 
					value={formData.email}
					name='email'
					onChange={handleInputChange}
					onBlur={e => saveOnChange(e)}
					className={` ${formData.email !== previousFormData.email && ''}`}
				/>
			</td>

			{/* <td className="px-0 py-1">
				<Form.Control
					size="sm"
					type="text"
					// placeholder="Enter email notes" 
					value={formData.note}
					name='note'
					onChange={handleInputChange}
					onBlur={e => saveOnChange(e)}
					className={` ${formData.note !== previousFormData.note && ''}`}
				/>
			</td> */}

			<td className="px-0 py-1">
				<CardDropdown>
					<div className="py-2">
						<Dropdown.Item onClick={() => { deleteEmail(email.id) }} className="text-danger">Delete</Dropdown.Item>
					</div>
				</CardDropdown>
			</td>
		</tr>
	);
};

EmailRow.propTypes = {
	email: PropTypes.string,
	emails: PropTypes.array,
	setEmails: PropTypes.func
}

export default EmailRow;