import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import moment from 'moment';
import { toast } from 'react-toastify';
import ExecuteMOU from './ExecuteMOU';
import UploadMOU from './UploadMOU';
import { documentAPI } from 'utils/api/fulcrum-api';

const RepresentativeMOU = ({ user, setUser, refreshParent = () => { } } = {}) => {
	const [executeMOUModalState, setExecuteMOUModalState] = useState(false);
	const [uploadRepresentativeMOUModalState, setUploadRepresentativeMOUModalState] = useState(false);

	// Refresh 
	const handleRefresh = () => {
		refreshParent();
	};

	// Download MOU
	const downloadMOUDocument = async (file_id) => {
		if (!file_id) {
			return alert('No file found to download. Please reach out for assistance.');
		}
		try {
			let result = await documentAPI.downloadDocuments(user?.account_id || null, [file_id], (response) => {
				if (response?.status !== 200) {
					throw new Error('No account service found');
				}
			});
		} catch (error) {
			toast.error('Unable to download MOU.', { theme: 'colored' });
			console.log(error);
		}
	};

	return (<div>
		<span className="fw-bold">Memorandum of Understanding</span>
		<hr />
		<span className="fw-bold">Status: &nbsp;
			{user?.rep_executed_mou_timestamp ?
				<>
					<span className="text-success fw-bold"> Completed {moment().format('MM-DD-YYYY hh:mm A')}</span>
					<Button variant="link" onClick={() => downloadMOUDocument(user?.rep_executed_mou_file_id || null)}>Download</Button>
				</>
				:
				<>
					<span className="text-danger fw-bold">Not Executed</span> &nbsp;

					<Button variant="link" onClick={() => setExecuteMOUModalState(true)}>
						Execute MOU
					</Button>
					{/* &nbsp; OR &nbsp;
							<Button variant="link" onClick={() => setUploadRepresentativeMOUModalState(true)}>
								Upload
							</Button> */}
				</>}
		</span>

		<ExecuteMOU
			modalState={executeMOUModalState}
			setModalState={setExecuteMOUModalState}
			user={user}
			setUser={setUser}
			refreshParent={handleRefresh}
		/>

		{/* <UploadMOU
				modalState={uploadRepresentativeMOUModalState}
				setModalState={setUploadRepresentativeMOUModalState}
				user={user}
				setUser={setUser}
				refreshParent={handleRefresh}
			/> */}
	</div>);
};

RepresentativeMOU.propTypes = {
	user: PropTypes.object,
	setUser: PropTypes.func,
	refreshParent: PropTypes.func
};

export default RepresentativeMOU;