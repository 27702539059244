import React, { forwardRef } from 'react';
import { Card } from 'react-bootstrap';

const ThirdParties = forwardRef((props, ref) => {
	return (
		<Card className="mb-3" id="third-parties" ref={ref}>
			<Card.Header className="bg-light">
				<h5 className="mb-0">Disclosing Information to Third Parties</h5>
			</Card.Header>
			<Card.Body>
				<p className="mb-0 ps-3">
					Assure Disability, Inc. may disclose information to a third party for the business purposes described above.  Assure Disability, Inc. will enter into a contract that describes both the purpose of the disclosure prior to the sharing of the information and require the recipient to both keep that information confidential and to refrain from using such information for any purpose except performing the purpose of the disclosure.  Assure Disability, Inc.  does not sell or share information with anyone other than our own internal personnel, the unaffiliated sponsoring and non-sponsoring lawyers and law firms, and certain authorized third parties.  As part of this process, those third parties agree to keep any disclosed information confidential and agree not to disclose information with any third party without your permission.
				</p>
				<p className="mb-0 ps-3">
					Assure Disability, Inc. may provide aggregate statistical information to third parties to describe services to prospective partners, advertisers, and other third parties, and for other lawful purposes.  Assure Disability, Inc. will not, however, disclose any personally identifiable information to these third parties.
				</p>
				<p className="mb-0 ps-3">
					Assure Disability, Inc. does not disclose information about clients outside the bounds of this Privacy Policy; however, Assure Disability Inc. may disclose information when we have a good-faith belief that such action is necessary to comply with applicable laws, valid legal process, a court order, a current judicial proceeding, or to protect rights or property.
				</p>
			</Card.Body>
		</Card>
	);
});

export default ThirdParties;
