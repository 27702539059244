import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Card, Dropdown, Button } from 'react-bootstrap';
import moment from 'moment';
import { removeEmptyFromObj } from 'utils/functions';
import { assureAPI } from 'utils/api/fulcrum-api';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import CustomAdvanceTablePagination from 'components/common/advance-table/CustomAdvanceTablePagination';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import CardDropdown from 'components/common/CardDropdown';
import StatusReportTableHeader from './StatusReportTableHeader';
import ReactInterval from 'react-interval';
import auth from 'utils/auth';

const defaultColumns = [
	{
		accessor: 'assure_id',
		Header: 'Assure ID',
		headerProps: { className: 'pe-1' },
		cellProps: {
			className: 'py-2'
		}
	},
];

const StatusReportTable = ({ account_id = auth.getProfile()?.data?.account_id || null, claimant = {}, tableHeaderLabel = "Status Report s", columns = defaultColumns, defaultSearchParams = {}, requiredSearchParams = {} }) => {
	const [createStatusReportModal, setCreateStatusReportModal] = useState(false);
	const [statusReportDetailsModal, setStatusReportDetailsModal] = useState(false);
	const [statusReportData, setStatusReportData] = useState([]);
	const [selectedStatusReport, setSelectedStatusReport] = useState({});
	const [statusReportCount, setStatusReportCount] = useState(0);
	const [Pages, setPages] = useState([1]);
	const [activePage, setActivePage] = useState(1);
	const [perPage, setPerPage] = useState(10);
	const [searching, setSearching] = useState(true);
	const [lastQuery, setLastQuery] = useState({});
	const [searchParams, setSearchParams] = useState({ ...defaultSearchParams, ...requiredSearchParams });
	const [appliedSortOrders, setAppliedSortOrders] = useState([]);

	const headerClickFn = (id, previousSortBy) => {
		if (!id) {
			return;
		}
		let foundIndex = appliedSortOrders.findIndex(s => s[0] === id);
		let sortBy = !previousSortBy ? "ASC" : previousSortBy === "asc" ? "DESC" : '';

		if (!sortBy) { // Not Sorted so remove clicked sort item 
			if (foundIndex > -1) {
				setAppliedSortOrders((prev) => {
					let newSortOrders = [...prev];
					newSortOrders.splice(foundIndex, 1); // remove clicked sort item
					return newSortOrders;
				});
			}
		} else { // Sort by Ascending or Descending 
			let newSort = [id, sortBy];
			if (foundIndex > -1) { // replaces existing 
				setAppliedSortOrders((prev) => {
					let newSortOrders = [...prev];
					newSortOrders[foundIndex] = newSort;
					return newSortOrders;
				});
			} else { // inserts 
				setAppliedSortOrders((prev) => [...prev, newSort]);
			}
		}
	};

	const formatData = (data = []) => {
		let adjustedData = data.map(record => {
			return {
				...record,
				ac_report: !record.ac_report ? "Hearing Status Report" : "Appeals Status Report",
				created_at: record?.createdAt ? moment(record.createdAt).format('MM/DD/YYYY hh:mm A') : null,
			}
		});
		setStatusReportData(adjustedData);
	};

	const statusReportSearch = async (params = {}, saveLastQuery = false, options = {}) => {
		try {
			params = removeEmptyFromObj(params);
			params.page = activePage;
			params.paginate = perPage;

			let result = await assureAPI.searchAssureStatusReportCases(account_id, params, options);
			console.log(result?.data?.data)
			if (!result?.data?.data || (Array.isArray(result.data.data) && result.data.data.length === 0)) {
				throw new Error('No status report data found');
			}
			// setPages(pages);
			formatData(result.data.data || []);
			setSearching(false);

			if (saveLastQuery) {
				setLastQuery(params);
			}
		} catch (error) {
			console.log(error);
		}
	};

	const newSearch = async (params) => {
		setSearching(true);
		let adjustedSearchParams = {
			...params,
			...requiredSearchParams
		};

		statusReportSearch(adjustedSearchParams);
	};

	// Refresh last query
	const refreshLastQuery = async () => {
		statusReportSearch({ ...lastQuery, ...requiredSearchParams }, false);
	};

	useEffect(() => {
		newSearch({ ...defaultSearchParams });
	}, []);

	// Apply search filters
	useEffect(() => { newSearch(searchParams) }, [searchParams, activePage, perPage, appliedSortOrders]);

	return (<>
		<AdvanceTableWrapper
			columns={columns}
			data={statusReportData}
			// selection
			sortable
			pagination
			perPage={perPage}
			setPerPage={setPerPage}
		>
			<Card className="shadow-none">
				<Card.Header>
					<StatusReportTableHeader
						table
						label={tableHeaderLabel}
						setCreateStatusReportModal={setCreateStatusReportModal}
						defaultSearchParams={defaultSearchParams}
						searchParams={searchParams}
						setSearchParams={setSearchParams}
						refreshLastQuery={refreshLastQuery}
						requiredSearchParams={requiredSearchParams}
						statusReportData={statusReportData}
						claimant={claimant}
					/>
					<Button
						variant="link"
						className="text-900 text-nowrap align-middle"
						onClick={() => refreshLastQuery()}
					>
						Refresh
					</Button>
				</Card.Header>
				<Card.Body className="p-0">
					{
						searching
							?
							<p>Searching Status Reports....</p>
							:
							<AdvanceTable
								table
								headerClickFn={headerClickFn}
								appliedSortOrders={appliedSortOrders}
								headerClassName="bg-200 text-900 text-nowrap align-middle"
								rowClassName="align-middle white-space-nowrap"
								tableProps={{
									size: 'sm',
									striped: true,
									className: 'fs--1 mb-0 overflow-hidden'
								}}
							/>
					}
				</Card.Body>
				<Card.Footer>
					<CustomAdvanceTablePagination
						table
						Pages={Pages}
						activePage={activePage}
						setActivePage={setActivePage}
					/>
				</Card.Footer>
			</Card>
		</AdvanceTableWrapper>
		{/* <ReactInterval timeout={15000} enabled={true} callback={() => { refreshLastQuery({ ignoreTouch: true }) }} /> */}

	</>);
};


export default StatusReportTable;