import React from 'react';
import StatusReportRequestTable from './status-report-request/StatusReportRequestTable';
import StatusReportItemTable from './status-report-items/StatusReportItemTable';
import StatusReportTable from './status-report/StatusReportTable';
import auth from 'utils/auth';
import { Tabs, Tab, Card, Row, Col } from 'react-bootstrap';

const AtlaswareStatusReports = ({ claimant, account_id = auth.getProfile()?.data?.account_id || null }) => {
	return (
		<Card>
			<Card.Header>
				<Row className=" justify-content-between pr-0">
					<Col sm="auto" className="pr-0 align-middle">
						<h4 className="align-middle fw-bold mb-0 h-inline">
							<span className="fw-medium">Status Reports</span>
						</h4>
					</Col>
				</Row>
			</Card.Header>
			<Card.Body>
				<Tabs defaultActiveKey="status-report-requests" id="uncontrolled-tab-example">
					<Tab eventKey="status-report-requests" title="Status Report Requests">
						<StatusReportRequestTable claimant={claimant} account_id={account_id} />
					</Tab>
					<Tab eventKey="status-report-items" title="Status Report">
						<StatusReportItemTable claimant={claimant} account_id={account_id} />
					</Tab>
					<Tab eventKey="status-report-assure" title="Assure Status Report">
						<StatusReportTable claimant={claimant} account_id={account_id} />
					</Tab>
				</Tabs>

			</Card.Body>
		</Card>
	);
};

export default AtlaswareStatusReports;