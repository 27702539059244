// import { AuthWizardContext } from 'context/Context';
import Lottie from 'lottie-react';
import PropTypes from 'prop-types';
import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import celebration from '../lottie/celebration.json';

const Success = ({ handleSubmitForm }) => {
	// const { setStep, setUser } = useContext(AuthWizardContext);

	return (
		<>
			<Row>
				<Col className="text-center">
					<div className="wizard-lottie-wrapper">
						<div className="wizard-lottie mx-auto">
							<Lottie animationData={celebration} loop={true} />
						</div>
					</div>
					<h4 className="mb-1">Your password has been reset!</h4>
					<p className="fs-0">Using your email and new password, navigate back to the login page to access your account.</p>
					<Button color="primary" className="px-5 my-3" onClick={handleSubmitForm}>
						Go to Login
					</Button>
				</Col>
			</Row>
		</>
	);
};

Success.propTypes = {
	reset: PropTypes.func.isRequired,
	handleSubmitForm: PropTypes.func
};

export default Success;
