import React, { useState, useContext } from 'react';
import { Button, Modal, Alert, Form, Row, Col } from 'react-bootstrap';
import { hearingAPI } from 'utils/api/fulcrum-api';
import AppContext from 'context/Context';
import { toast } from 'react-toastify';
import ClaimantQuickFind from 'components/claimants/find-a-claimant/ClaimantQuickFind';
import UserQuickFind from 'components/users/find-a-user/UserQuickFind';
import ViewLocations from '../assure/locations/ViewLocations';
import ViewJudges from '../assure/judges/ViewJudges';
import DatePicker from 'react-datepicker';
import FindAUserByType from '../../users/find-a-user/FindAUserByType';


const HearingCreationTool = ({ account_id, modalState, setModalState = () => { }, refreshParent = () => { }, defaultClaimant = {} }) => {
	const {
		config: { isDark }
	} = useContext(AppContext);
	const [time, setTime] = useState(null);
	const [date, setDate] = useState(null);
	const [locationModalState, setLocationModalState] = useState(false);
	const [judgeModalState, setJudgeModalState] = useState(false);
	const [errors, setErrors] = useState([]);
	const [hearingRepModalState, setHearingRepModalState] = useState(false);

	const defaultState = {
		selected_claimant: defaultClaimant || null,
		claimant_id: defaultClaimant?.id || '',
		case_id: '',
		status: '',
		hearing_date: '',
		hearing_time: '',
		city: '',
		state: '',
		hearing_type: '',
		attendance_type: '',
		assure_location_id: '',
		selected_location: null,
		selected_judge: null,
		scheduling_notes: '',
		vocational_expert: false,
		medical_expert: false,
		hearing_rep_id: '',
		hearing_representation_type: '',
		hearing_representative: '',
		suggested_hearing_representative: '',
		hearing_alj: '',
		assure_judge_id: '',
		ere_alj_first_name: '',
		ere_alj_last_name: '',
		hearing_coverage_request: false,
		hearing_representative_type: null
	};

	const [formData, setFormData] = useState(defaultState);

	// Handle Form Change
	const handleChange = (e) => {
		let { name, value } = e.target;

		setFormData({ ...formData, [name]: value });
	};

	// Handle Form Submit
	const handleSubmit = async (e) => {
		e.preventDefault();

		if (!formData.claimant_id) {
			toast.error('Please select a claimant', { theme: 'colored' });
			return;
		}

		try {
			let result = await hearingAPI.createHearing(formData.claimant_id, {
				...formData,
			});
			if (!result?.data?.message) {
				throw new Error('Error creating hearing');
			}

			refreshParent();
			toast.success('Hearing created successfully', { theme: 'colored' });
			handleClose();
		} catch (error) {
			console.log(error);
			toast.error('Error creating hearing', { theme: 'colored' });
		}
	};

	// Handle Claimant Selection
	const handleClaimantSelect = (selectedClaimants) => {
		let claimant = {};
		if (!Array.isArray(selectedClaimants) || selectedClaimants.length === 0 || !selectedClaimants[0]?.id) {
			setErrors([...errors, 'Unable to use selected claimant.'])
		} else {
			claimant = selectedClaimants[0];
			setErrors(errors.filter(error => error !== 'Unable to use selected claimant.'));
		}
		setFormData({ ...formData, claimant_id: claimant?.id || null, selected_claimant: claimant });
	};

	// Handle Hearing Rep Selection
	const handleHearingRepSelect = (user) => {
		let { id, first_name, last_name } = user;
		setFormData({ ...formData, hearing_rep_id: id, hearing_representative: `${first_name} ${last_name}` });
	};


	// Handle Location Selection
	const handleLocationSelect = (location) => {
		let { address_street_1, address_street_2, address_city, address_state, address_zip, id } = location;
		let fullAddress = `${address_street_1} ${address_street_2}, ${address_city}, ${address_state} ${address_zip}`;
		console.log(location);
		setFormData({ ...formData, city: address_city, state: address_state, location_id: id, location_full_address: fullAddress, selected_location: location });
	};

	// Handle Judge Selection
	const handleJudgeSelect = (judge) => {
		setFormData({
			...formData,
			assure_judge_id: judge.id,
			selected_judge: judge,
			ere_alj_first_name: judge?.first_name || null,
			ere_alj_last_name: judge?.last_name || null,
			assure_judge_id: judge?.id || null,
			hearing_alj: `${judge?.first_name || ''} ${judge?.last_name || null}`,
		});
	};

	// Handle Modal Close
	const handleClose = () => {
		setFormData(defaultState);
		setModalState(false);
	};

	// Handle Modal Show
	const handleShow = () => {
		setFormData(defaultState);
		setModalState(true);
	};

	return (
		<Modal
			show={modalState}
			onHide={handleClose}
			onShow={handleShow}
			backdrop="static"
			size="xl"
		>

			<Form onSubmit={handleSubmit}>
				<Modal.Header closeButton>
					<Modal.Title>Hearing Creation Tool</Modal.Title>
				</Modal.Header>
				<Modal.Body>

					<Alert variant="danger" show={errors.length > 0}>
						{errors.map((error, index) => (
							<p key={index}>{error}</p>
						))}
					</Alert>
					<Row>
						<Col>
							<h4>Claimant</h4>
							{
								defaultClaimant?.id ?
									<span className="fw-bold">Selected Claimant: {formData?.selected_claimant?.first_name} {formData?.selected_claimant?.last_name}</span>
									:
									<span className="fw-bold">Selected Claimant: {formData?.selected_claimant?.id
										?
										<span>
											{formData?.selected_claimant?.first_name} {formData?.selected_claimant?.last_name}
											<ClaimantQuickFind
												buttonLabel="Change Claimant"
												buttonVariant="link"
												handleClaimantSelection={handleClaimantSelect}
												allowMultipleSelect={false}
											/>
										</span>
										:
										<ClaimantQuickFind
											buttonLabel="Select Claimant"
											buttonVariant="link"
											handleClaimantSelection={handleClaimantSelect}
											allowMultipleSelect={false}
										/>
									}</span>
							}

						</Col>

					</Row>
					<hr />
					{
						(formData?.selected_claimant?.id && !formData?.selected_claimant?.active_case_id) &&
						<Alert variant="warning">
							<p>Selected Claimant does not have an active case. Please create a case for the claimant before creating a hearing entry.</p>
						</Alert>
					}
					{
						formData?.selected_claimant?.id && formData?.selected_claimant?.active_case_id &&
						<div>
							<h4>Hearing Information</h4>

							<div>
								<Row>
									<Col>
										<Form.Group controlId="formBasicHearingDate">
											<Form.Label>Hearing Date</Form.Label>
											<DatePicker
												selected={date}
												onChange={(date) => {
													setDate(date);
													setFormData({ ...formData, hearing_date: date });
												}}
												formatWeekDay={day => day.slice(0, 3)}
												className='form-control'
												placeholderText="Select Date"
												required
											/>
										</Form.Group>
									</Col>
									<Col>
										<Form.Group controlId="formBasicHearingTime">
											<Form.Label>Hearing Time</Form.Label>
											<DatePicker
												selected={time}
												onChange={(time) => {
													setTime(time);
													setFormData({ ...formData, hearing_time: time });
												}}
												className='form-control'
												placeholderText="Select Time"
												timeIntervals={15}
												dateFormat="h:mm aa"
												showTimeSelect
												showTimeSelectOnly
												required
											/>
										</Form.Group>
									</Col>
								</Row>
								<hr />

								<Row>
									<Col>
										<span className="fw-bold">Hearing Location: </span>
										<br />
										{
											formData?.selected_location?.id
												?
												<>
													<Button
														variant="link"
														onClick={() => setLocationModalState(true)}
													>
														Change Location
													</Button>
												</>
												:
												<Button
													variant="link"
													onClick={() => setLocationModalState(true)}
												>
													Select Location
												</Button>
										}
									</Col>
								</Row>
								<Row>
									<Col sm={8}>
										<Form.Group controlId="formBasicHearingCity">
											<Form.Label>Hearing City</Form.Label>
											<Form.Control
												type="text"
												placeholder="Enter City"
												value={formData.city}
												onChange={(e) => setFormData({ ...formData, city: e.target.value })}
												required
												disabled
											/>
										</Form.Group>
									</Col>
									<Col sm={4}>
										<Form.Group controlId="formBasicHearingState">
											<Form.Label>Hearing State</Form.Label>
											<Form.Control
												type="text"
												placeholder="Enter State"
												value={formData.state}
												onChange={(e) => setFormData({ ...formData, state: e.target.value })}
												required
												disabled
											/>
										</Form.Group>
									</Col>
								</Row>

								<ViewLocations
									modalState={locationModalState}
									setModalState={setLocationModalState}
									locationSelectCallBack={handleLocationSelect}
									alreadySelectedLocations={[formData.selected_location]}
								/>
								<hr />

								<Row>
									<Col>
										<span className="fw-bold">Hearing Judge: </span>
										<br />
										{
											formData?.selected_judge?.id
												?
												<>
													<Button
														variant="link"
														onClick={() => setJudgeModalState(true)}
													>
														Change Judge
													</Button>
												</>
												:
												<Button
													variant="link"
													onClick={() => setJudgeModalState(true)}
												>
													Select Judge
												</Button>
										}

										<Form.Group controlId="formBasicHearingJudge">
											<Form.Label>Hearing Judge</Form.Label>
											<Form.Control
												type="text"
												placeholder="Enter Judge"
												value={formData.hearing_alj}
												onChange={(e) => setFormData({ ...formData, hearing_alj: e.target.value })}
												disabled
											/>
										</Form.Group>
									</Col>
								</Row>

								<ViewJudges
									modalState={judgeModalState}
									setModalState={setJudgeModalState}
									judgeSelectCallBack={handleJudgeSelect}
									alreadySelectedJudges={[formData.selected_judge]}
								/>

								<hr />

								<Row>
									<Col>
										<Form.Group controlId="formBasicHearingType">
											<Form.Label>Hearing Type</Form.Label>
											<Form.Control
												as="select"
												name="hearing_type"
												value={formData.hearing_type}
												onChange={handleChange}
											>
												<option value="" disabled>Select</option>
												<option value="1">Initial Hearing</option>
												<option value="2">Supplemental</option>
												<option value="3">Appeals Council Remand</option>
												<option value="4">CDR</option>
											</Form.Control>
										</Form.Group>
									</Col>
								</Row>

								<Row>
									<Col>
										<Form.Group controlId="formBasicHearingType">
											<Form.Label>Attendance Type</Form.Label>
											<Form.Control
												as="select"
												name="attendance_type"
												value={formData.attendance_type}
												onChange={handleChange}
											>
												<option value="" disabled>Select</option>
												<option value="0">Not Set</option>
												<option value="1">In-Person</option>
												<option value="2">Telephonic</option>
												<option value="3">Video/Microsoft Teams</option>
											</Form.Control>
										</Form.Group>
									</Col>
								</Row>

								<Row>
									<Col>
										<Form.Group>
											<Form.Label>Notes</Form.Label>
											<Form.Control
												as="textarea"
												rows={3}
												name="notes"
												value={formData.notes}
												onChange={handleChange}
											/>
										</Form.Group>
									</Col>
								</Row>


								<Row>
									<Col>
										<Form.Group>
											<Form.Label>Vocational Expert</Form.Label>
											<Form.Control
												as="select"
												name="vocational_expert"
												value={formData.vocational_expert}
												onChange={handleChange}
											>
												<option value="" disabled>Select</option>
												<option value="true">Yes</option>
												<option value="false">No</option>
											</Form.Control>
										</Form.Group>
									</Col>
									<Col>
										<Form.Group>
											<Form.Label>Medical Expert</Form.Label>
											<Form.Control
												as="select"
												name="medical_expert"
												value={formData.medical_expert}
												onChange={handleChange}
											>
												<option value="" disabled>Select</option>
												<option value="true">Yes</option>
												<option value="false">No</option>
											</Form.Control>
										</Form.Group>
									</Col>
								</Row>
							</div>

							<hr />

							<div>
								<Row>
									<Col>


										<span className="fw-bold">Hearing Representative</span>
										<Form.Group>
											<Form.Label>Hearing Representation Type</Form.Label>
											<Form.Control
												as="select"
												name="hearing_representation_type"
												value={formData.hearing_representation_type}
												onChange={handleChange}
											>
												<option value="" disabled>Select</option>
												<option value="1">Select Hearing Representative</option>
												<option value="2">Submit a Hearing Coverage Request</option>
											</Form.Control>
										</Form.Group>

										{
											formData.hearing_representation_type === "1" &&
											<div>
												<div className='text-center'>

													{
														formData.hearing_representative ?

															<Button
																variant="link"
																onClick={() => setHearingRepModalState(true)}
															>
																Change Hearing Representative
															</Button>
															:

															<Button
																variant="link"
																onClick={() => setHearingRepModalState(true)}
															>
																Select Hearing Representative
															</Button>
													}
													<FindAUserByType
														userType='REPRESENTATIVE'
														label={"Hearing Representative"}
														handleUserSelection={handleHearingRepSelect}
														modalState={hearingRepModalState}
														setModalState={setHearingRepModalState}
														account_id={account_id}
													/>

													<Form.Group controlId="formBasicHearingRepresentative">
														<Form.Label>Hearing Representative</Form.Label>
														<Form.Control
															type="text"
															name="hearing_representative"
															placeholder="Hearing Representative"
															value={formData.hearing_representative}
															onChange={(e) => setFormData({ ...formData, hearing_representative: e.target.value })}
															disabled
														/>
													</Form.Group>
												</div>



											</div>
										}

										{
											formData.hearing_representation_type === "2" &&
											<div>
												<Button
													variant="primary"
													size="sm"
													onClick={() => setShowAddHearingRepModal(true)}
												>
													Submit a Hearing Coverage Request
												</Button>
											</div>
										}
									</Col>
								</Row>
							</div>

							{
								formData.hearing_coverage_request &&
								<div>
									<Row>
										<Col>
											<Form.Group>
												<Form.Label>Suggested Hearing Representative</Form.Label>
												<Form.Control
													as="text"
													name="suggested_hearing_representative"
													value={formData.suggested_hearing_representative}
													onChange={handleChange}
												/>
											</Form.Group>
										</Col>
									</Row>
								</div>
							}

						</div>
					}


				</Modal.Body>
				<Modal.Footer>
					{
						true &&
						<Button
							variant="primary"
							type="submit"
						>Submit</Button>
					}

					<Button variant="secondary" onClick={handleClose}>
						Close
					</Button>
				</Modal.Footer>
			</Form>
		</Modal>


	);
};

export default HearingCreationTool;