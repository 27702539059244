import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import IconButton from 'components/common/IconButton';

const DocumentTableHeader = ({
	selectedRowIds,
	page,
	label = "Documents",
	documentData = [],
	searchParams = {},
	defaultSearchParams = {},
	requiredSearchParams = {},
	setSearchParams = () => { },
	setDocumentCreateModal = () => { },
	clearSearchParams = () => { },
	bulkActionOverride = [],
	attachSelectedCB = () => { },
}) => {

	const [bulkActions, setBulkActions] = useState(
		bulkActionOverride.length > 0 ? bulkActionOverride :
			[
				// {
				// 	label: 'Delete',
				// 	action: "DELETE",
				// 	visible: true,
				// 	actionHanlder: () => {
				// 		Swal.fire({
				// 			title: 'Are you sure?',
				// 			text: 'You will not be able to recover this document(s)!',
				// 			icon: 'warning',
				// 			showCancelButton: true,
				// 			confirmButtonColor: '#3085d6',
				// 			cancelButtonColor: '#d33',
				// 			confirmButtonText: 'Yes, delete it!'
				// 		}).then(async (result) => {
				// 			if (result.isConfirmed) {
				// 				try {
				// 					let result = await documentAPI.deleteDocuments(selectedRowIds);
				// 					if (!result?.message) { throw new Error(result.error); }
				// 					toast.warning('Document(s) deleted', { theme: 'colored' });
				// 					refreshLastQuery();
				// 				} catch (error) {
				// 					toast.error("Unable to delete document(s)", { theme: 'colored' });
				// 					console.log(error);
				// 				}
				// 			}
				// 		});
				// 	}
				// },
				{
					label: 'Attach Selected',
					action: "ATTACH_SELECTED",
					visible: true,
					actionHanlder: () => {
						alert('here')
						attachSelectedCB(selectedRowIds);
					}
				}
			]
	);

	const documentIdsSelected = Array.isArray(page)
		? (page.reduce((acc, { isSelected = false, original } = {}) => (
			[...acc, ...(isSelected ? [original] : [])]
		), [])).map(({ id } = {}) => id)
		: [];

	return (
		<Row className="flex-between-center">
			<Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
				<h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">{label}</h5>
			</Col>
			<Col xs={8} sm="auto" className="ms-auto text-end ps-0">
				{Object.keys(selectedRowIds).length > 0 ? (
					<div className="d-flex">
						{/* <Form.Select size="sm" aria-label="Bulk actions">
							<option >Bulk Actions</option>
							<option value="send-notification">Send Notification</option>
							<option value="disable">Disable</option>
						</Form.Select> */}
						{/* <Button
							type="button"
							variant="falcon-default"
							size="sm"
							className="ms-2"
						>
							Apply
						</Button> */}
					</div>
				) : (
					<div id="orders-actions">
						{
							JSON.stringify(searchParams) !== JSON.stringify({ ...defaultSearchParams, ...requiredSearchParams }) && (
								<IconButton
									size="sm"
									variant="outline-danger"
									className="me-2"
									icon="x"
									onClick={() => {
										clearSearchParams();
									}}
								>Clear Filters</IconButton>
							)
						}
						{/* <IconButton
							variant="falcon-default"
							size="sm"
							icon="filter"
							transform="shrink-3"
							className="mx-2"
							onClick={() => { setShowFindAClaimantModal(true) }}
						>
							<span className="d-none d-sm-inline-block ms-1">Find-A-Claimant</span>
						</IconButton> */}
						<IconButton
							variant="falcon-default"
							size="sm"
							icon="plus"
							transform="shrink-3"
							onClick={() => { setDocumentCreateModal(true) }}
						>
							<span className="d-none d-sm-inline-block ms-1">Upload</span>
						</IconButton>
					</div>
				)}
			</Col>

		</Row>
	);
};

DocumentTableHeader.propTypes = {
	selectedRowIds: PropTypes.object,
	page: PropTypes.array,
	label: PropTypes.string,
	documentData: PropTypes.array,
	searchParams: PropTypes.object,
	defaultSearchParams: PropTypes.object,
	requiredSearchParams: PropTypes.object,
	setSearchParams: PropTypes.func,
	setDocumentCreateModal: PropTypes.func,
	clearSearchParams: PropTypes.func,
	bulkActionOverride: PropTypes.array,
	attachSelectedCB: PropTypes.func,
};

export default DocumentTableHeader;