import axios from "axios";
import Auth from 'utils/auth';

// Auth Monitor
const statusCodeMonitor = (statusCode) => {
  if (statusCode === 401) {
    // Auth.logout(); // @todo - uncomment this ASAP AFTER TESTING.
  }
  return statusCode;
};

// Axios call function
export const axiosCall = async (config) => {
  // @todo - consider logging here to track API calls and see component and or page refreshes, etc.
  if (config.timeout === undefined) {
    config.timeout = 1000 * 60 * 1; // 1 minute timeout because if we don't give enough time then post will cancel and page will refresh 
  }
  return await axios(config)
    .then(({ status, statusText, data } = {}) => {
      if (!data && status !== 204) { // 204 = No Content status
        throw new Error(`${status} ${statusText} - No data returned from server`);
      }
      return data;
    }).catch(error => {
      if (axios.isCancel(error)) {
        console.log('axiosCall Cancelled', error);
      } else {
        console.log("axiosCall Error", error);
      }
      return error?.response ? { error: statusCodeMonitor(error.response.status), response: error?.response?.data || error } : error;
    });
};



