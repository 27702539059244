import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import IconButton from 'components/common/IconButton';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';

const CurrentIncome = ({ claimant, saveClaimant }) => {
	const [currentIncomeSources, setCurrentIncomeSources] = useState((claimant?.current_income) ? JSON.parse(claimant.current_income) : []);

	// Prepare the currentIncome array to save
	const prepareCurrentIncomeData = (currentIncomeArray) => {
		let adjustedCurrentIncom = JSON.stringify(currentIncomeArray);

		if (claimant.currentIncome !== adjustedCurrentIncom) {
			saveClaimant({ current_income: adjustedCurrentIncom });
		}
	};

	// Update a current income source
	const updateCurrentIncomeSource = (index, field, value) => {
		let newCurrentIncomeSource = [...currentIncomeSources];
		newCurrentIncomeSource[index][field] = value;
		setCurrentIncomeSources(newCurrentIncomeSource);
	};

	// Add a new current income source
	const addCurrentIncomeSource = () => {
		let newCurrentIncomeSourceMember = {
			amount: '',
			source: ''
		};
		let newCurrentIncomeSource = [...currentIncomeSources, newCurrentIncomeSourceMember];
		setCurrentIncomeSources(newCurrentIncomeSource);
		prepareCurrentIncomeData(newCurrentIncomeSource);
	};

	// Remove a current income source
	const removeCurrentIncomeSource = (index) => {
		Swal.fire({
			title: 'Permanently delete this current income record?',
			text: "You won't be able to revert this!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Yes, delete it!'
		}).then(async (result) => {
			if (result.isConfirmed) {
				let newCurrentIncomeSource = [...currentIncomeSources];
				newCurrentIncomeSource.splice(index, 1);
				setCurrentIncomeSources(newCurrentIncomeSource);
				prepareCurrentIncomeData(newCurrentIncomeSource);
				toast.warning(result.message, {
					theme: 'colored'
				});
			}
		});
	};

	return (
		<div id="claimant-currentIncome-container" className="my-3">
			<h5 className="d-inline">Current Income</h5>

			<IconButton
				className="me-2 mb-1 float-end"
				variant="falcon-default"
				size="sm"
				icon="plus"
				transform="shrink-3"
				onClick={addCurrentIncomeSource}
			>
				Add
			</IconButton>
			<hr />
			<Table responsive bordered striped >
				<thead>
					<th scope="col">Amount</th>
					<th scope="col">Source</th>
					<th scope="col"></th>
				</thead>
				<tbody>
					{currentIncomeSources.map((cis, index) => (
						<tr key={index}>
							<td>
								<Form.Control
									type="number"
									size="sm"
									value={cis.amount}
									onChange={e => { updateCurrentIncomeSource(index, 'amount', e.target.value) }}
									onBlur={() => prepareCurrentIncomeData(currentIncomeSources)}
								/>
							</td>
							<td>
								<Form.Control
									type="text"
									size="sm"
									value={cis.source}
									onChange={e => { updateCurrentIncomeSource(index, 'source', e.target.value) }}
									onBlur={() => prepareCurrentIncomeData(currentIncomeSources)}
								/>
							</td>
							<td>
								<IconButton
									className="me-2 mb-1 float-end"
									variant="falcon-danger"
									size="sm"
									icon="trash"
									transform="shrink-3"
									onClick={() => removeCurrentIncomeSource(index)}
								>
									Delete
								</IconButton>
							</td>
						</tr>
					))}
				</tbody>
			</Table>
		</div>
	);
};

CurrentIncome.propTypes = {
	claimant: PropTypes.object,
	saveClaimant: PropTypes.func
}

export default CurrentIncome;