import React, { useEffect, useState } from 'react';
import { Card, Row, Col, Button, Form } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import Flex from 'components/common/Flex';
import moment from 'moment';
import { toast } from 'react-toastify';
import AccountTableHeader from './AccountTableHeader';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import CustomAdvanceTable from 'components/common/advance-table/CustomAdvanceTable';
import CustomAdvanceTablePagination from 'components/common/advance-table/CustomAdvanceTablePagination';
import { accountAPI } from 'utils/api/fulcrum-api';
import { removeEmptyFromObj } from 'utils/functions';
import AccountFilters from './AccountFilters';
import CreateAccount from './CreateAccount';

const defaultColumns = [
	{
		accessor: 'business_name',
		Header: 'Name',
		headerProps: { className: 'pe-1' },
		cellProps: {
			className: 'py-2'
		},
		Cell: rowData => {
			const { business_name, id } = rowData.row.original;
			return <Link to={`/admin/account-details/${id}`}>{business_name}</Link>;
		}
	},
	{
		accessor: 'account_status',
		Header: 'Status',
		headerProps: { className: 'pe-1' },
		cellProps: {
			className: 'py-2'
		},
	},
	{
		accessor: 'user_count',
		Header: 'Users',
		headerProps: { className: 'pe-1' },
		cellProps: {
			className: 'py-2'
		},
	},
	{
		accessor: 'created_at',
		Header: 'Created',
		headerProps: { className: 'pe-1' },
		cellProps: {
			className: 'py-2'
		},
	}
];

const AccountTable = ({ allowedColumns = [], tableHeaderLabel = "Accounts", columns = defaultColumns, defaultSearchParams = {}, requiredSearchParams = {} }) => {
	const navigate = useNavigate();
	const [createAccountModal, setCreateAccountModal] = useState(false);
	const [accountData, setAccountData] = useState([]);
	const [Pages, setPages] = useState([1]);
	const [activePage, setActivePage] = useState(1);
	const [perPage, setPerPage] = useState(10);
	const [accountDataCount, setAccountDataCount] = useState(0);
	const [searching, setSearching] = useState(true);
	const [lastQuery, setLastQuery] = useState({});
	const [searchParams, setSearchParams] = useState({ ...defaultSearchParams });
	let defaultSort = ["created_at", "ASC"];
	const [appliedSortOrders, setAppliedSortOrders] = useState([defaultSort]);


	const handleBulkActions = (actionObj = {}, selectedRows = [], selectedIds = []) => {
		let claimantItem = {};
		if (Array.isArray(selectedRows) && selectedRows.length === 1) {
			claimantItem = selectedRows[0];
		}
		let { bulk_action } = actionObj || {};
		switch (bulk_action) {
			case 'clear filters':
				clearSearchParams();
				break;
			case 'new claimant':
				setCreateAccountModal(true);
				break;
			default:
				console.log("@todo handleBulkActions", actionObj, "selectedRows", selectedRows, "selectedIds", selectedIds, "claimantItem", claimantItem);
				// refreshLastQuery();
				break;
		}
	};

	const formatData = (data = []) => {
		let adjustedData = data.map(account => {
			return {
				...account,
				created_at: moment(account.createdAt).format('MM-DD-YYYY'),
			}
		});
		setAccountData(adjustedData);
	};

	const headerClickFn = (id, previousSortBy) => {
		if (!id) {
			return;
		}
		let foundIndex = appliedSortOrders.findIndex(s => s[0] === id);
		let sortBy = !previousSortBy ? "ASC" : previousSortBy === "asc" ? "DESC" : '';

		if (!sortBy) { // Not Sorted so remove clicked sort item 
			if (foundIndex > -1) {
				setAppliedSortOrders((prev) => {
					let newSortOrders = [...prev];
					newSortOrders.splice(foundIndex, 1); // remove clicked sort item
					return newSortOrders;
				});
			}
		} else { // Sort by Ascending or Descending 
			let newSort = [id, sortBy];
			if (foundIndex > -1) { // replaces existing 
				setAppliedSortOrders((prev) => {
					let newSortOrders = [...prev];
					newSortOrders[foundIndex] = newSort;
					return newSortOrders;
				});
			} else { // inserts 
				setAppliedSortOrders((prev) => [...prev, newSort]);
			}
		}
	};

	const accountSearchHandler = async (params = {}, saveLastQuery = false) => {
		try {
			params = removeEmptyFromObj(params);
			params.page = activePage;
			params.limit = perPage;
			params.navSize = 4; // How many buttons to show for navigation
			params.order = appliedSortOrders; // Example [['last_last', 'asc'],['first_name','asc'], ['id','asc']]
			let { error, response, data } = await accountAPI.searchAllAccounts({ ...params, ...requiredSearchParams });
			if (error) {
				if (typeof response?.error === 'string') {
					throw new Error(`${error} - ${response.error}`);
				} else if (typeof response === 'string') {
					throw new Error(`${error} - ${response}`);
				}
				throw new Error(`${error} - There was an error searching accounts.`);
			}

			let { count = 0, rows = [], pages = [] } = data || {}; // data = { count = 0, rows = [], limit, pageCount, page, pages = [1,2,3,10] } 
			if (!rows || (Array.isArray(rows) && rows.length === 0)) {
				formatData([]);
				setActivePage(1); // Make sure we don't get stuck loading nothing
			} else {
				formatData(rows);
				setPages(pages);
				setSearching(false);
			}

			formatData(rows);
			setAccountDataCount(count);
			setPages(pages);
			if (saveLastQuery) {
				setLastQuery({ params });
			}
		} catch (error) {
			console.log("Error searching account data", error);
			setAccountData([]);
			setAccountDataCount(0);
			setActivePage(1);
		}
		setSearching(false);
	};


	const newSearch = async (params) => {
		setSearching(true);
		let adjustedSearchParams = {
			...params
		};
		return await accountSearchHandler(adjustedSearchParams);
	};

	// Refresh last query
	const refreshLastQuery = async () => {
		accountSearchHandler({ lastQuery, ...requiredSearchParams }, false);
	};

	// Clear search params and reset to default
	const clearSearchParams = () => {
		if (JSON.stringify(searchParams) !== JSON.stringify({ ...defaultSearchParams })) {
			setSearchParams({});
		}
		if (JSON.stringify(appliedSortOrders) !== JSON.stringify([defaultSort])) {
			setAppliedSortOrders([defaultSort]);
		}
		if (activePage !== 1) {
			setActivePage(1);
		}
		if (perPage !== 10) {
			setPerPage(10);
		}
	};

	// Apply search filters
	useEffect(() => { newSearch(searchParams) }, [searchParams, activePage, perPage, appliedSortOrders]);

	return (
		<div>
			<AdvanceTableWrapper
				columns={columns}
				data={accountData}
				// selection
				sortable
				pagination
				perPage={perPage}
				setPerPage={setPerPage}
			>
				<Card className="mb-3">
					<Card.Header>
						<AccountTableHeader
							table
							label={tableHeaderLabel}
							setCreateAccountModal={setCreateAccountModal}
							handleBulkActions={handleBulkActions}
							searchParams={searchParams}
							setSearchParams={setSearchParams}
							showClearFilters={JSON.stringify(searchParams) !== JSON.stringify({ ...defaultSearchParams, ...requiredSearchParams })}
						/>
						<AccountFilters
							searchParams={searchParams}
							setSearchParams={setSearchParams}
							clearSearchParams={clearSearchParams}
						/>
					</Card.Header>
					<Card.Body className="p-0">
						{
							searching
								?
								<p>Searching accounts....</p>
								:
								<>
									<CustomAdvanceTable
										table
										headerClickFn={headerClickFn}
										appliedSortOrders={appliedSortOrders}
										headerClassName="bg-200 text-900 text-nowrap align-middle"
										rowClassName="align-middle white-space-nowrap"
										tableProps={{
											size: 'sm',
											striped: true,
											className: 'fs--1 mb-0'
										}}
									/>
								</>
						}
					</Card.Body>
					<Card.Footer>
						<CustomAdvanceTablePagination
							table
							Pages={Pages}
							activePage={activePage}
							setActivePage={setActivePage}
							totalRecords={accountDataCount}
						/>
					</Card.Footer>
				</Card>
			</AdvanceTableWrapper>

			<CreateAccount
				modalState={createAccountModal}
				setModalState={setCreateAccountModal}
				refreshParent={refreshLastQuery}
			/>
		</div>
	);
};

export default AccountTable;