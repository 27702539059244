import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import bg2 from 'assets/img/generic/bg-2.jpg';
// import dashboardDark from 'assets/img/generic/dashboard-alt-dark.png';
// import dashboard from 'assets/img/generic/dashboard-alt.png';
import dashboardDark from 'assets/img/generic/dark-dash.png';
import dashboard from 'assets/img/generic/light-dash.png';
import Section from 'components/common/Section';
import AppContext from 'context/Context';
import React, { useContext, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import TypeAnimation from 'react-type-animation';
import ScheduleADemoModal from 'components/app/hubspot/ScheduleADemoModal';

const Hero = () => {
  const [showModal, setShowModal] = useState(false);
  const {
    config: { isDark }
  } = useContext(AppContext);

  return (
    <Section
      className="py-0 overflow-hidden light"
      image={bg2}
      position="center bottom"
      overlay
    >
      <Row className="justify-content-center align-items-center pt-8 pt-lg-10 pb-lg-9 pb-xl-0">
        <Col
          md={11}
          lg={8}
          xl={4}
          className="pb-7 pb-xl-9 text-center text-xl-start"
        >
          <h1 className="text-white fw-light">
            Fulcrum is <br />
            <TypeAnimation
              cursor={true}
              sequence={[
                ' Streamlined.',
                1200,
                ' Powerful.',
                1200,
                ' Secure.',
                1200
              ]}
              repeat={Infinity}
              wrapper="span"
              className="fw-bold"
            />
          </h1>
          {/* <h1 className="text-white fw-light">
            Helping firms better advocate
            <br />
            for their clients
          </h1> */}
          <p className="lead text-white opacity-75">
            With automated and professional service, Fulcrum can help increase productivity and increase client capacity, allowing firms to spend time doing what really matters, advocating for their clients.
          </p>
          <Button
            as={Link}
            variant="outline-light"
            size="lg"
            className="border-2 rounded-pill mt-4 fs-0 py-2"
            onClick={() => setShowModal(true)}
          >
            Request a demo
            <FontAwesomeIcon icon="play" transform="shrink-6 down-1 right-5" />
          </Button>
        </Col>
        <Col
          xl={{ span: 7, offset: 1 }}
          className="align-self-end mt-4 mt-xl-0"
        >
          <Link to="/" className="img-landing-banner">
            <img
              className="img-fluid"
              src={isDark ? dashboardDark : dashboard}
              alt=""
            />
          </Link>
        </Col>
      </Row>

      <ScheduleADemoModal
        modalState={showModal}
        setModalState={setShowModal}
        title="Request a Demo"
        message="We would love to give you a full tour of all the great things Fulcrum has to offer. All you need to do is fill out this form and we will reach out to you so we can schedule a demo. We can't wait to introduce you to your new way of working."
      />
    </Section>
  );
};

export default Hero;