import React, { useState } from 'react';
import { Modal, Button, Alert } from 'react-bootstrap';


const ManageAtlaswareCredentials = ({ modalState, setModalState = () => { }, user, setUser = () => { }, assureAttorneyInfo, setAssureAttorneyInfo = () => { } }) => {

	const [formData, setFormData] = useState({
		username: '',
		password: ''
	});

	const handleShow = () => setShow(true);
	const handleClose = () => {
		setModalState(false);
	};


	return (
		<>
			<div className="mt-3">
				<h5>Manage Log In Credentials</h5>
				<hr />
				<Alert variant={'info'}>
					<Alert.Heading>Warning!</Alert.Heading>
					<p>
						Login crednetials are not recoverable. If you would like to change your password, please <Alert.Link onClick={handleShow}>click here</Alert.Link>.
					</p>
				</Alert>
			</div>
			{/* <Button variant="link" onClick={handleShow}>Edit Credentials</Button> */}
			<Modal show={modalState} onHide={handleClose}>
				<Modal.Header closeButton>
					<Modal.Title>Manage Atlasware Credentials</Modal.Title>
				</Modal.Header>
				<Modal.Body>

				</Modal.Body>
				<Modal.Footer>
				</Modal.Footer>
			</Modal>
		</>
	);
}

export default ManageAtlaswareCredentials;