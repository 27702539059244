import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Auth from 'utils/auth';
import { userAPI } from 'utils/api/fulcrum-api';
import { Row, Col, Form } from 'react-bootstrap';
import { toast } from 'react-toastify';

const UserAdminToggle = ({ user, setUser = () => { } }) => {
    const [isAdmin, setIsAdmin] = useState(false);

    const adminFlag = (user) => {
        if (!user?.assigned_user_types) return false;
        let result = user.assigned_user_types.filter(t => t.user_type_id === 'ADMIN').length > 0 ? true : false;
        setIsAdmin(result);
        return result;
    }

    const handleToggle = async (ev) => {
        ev.preventDefault();
        // let { name, value, checked } = ev.target;
        let { checked } = ev.target;

        if (!checked) {
            // Remove admin flag
            try {
                const { data } = await userAPI.deleteAssignedUserType(user.id, 'ADMIN');
                if (!data) throw new Error('Error removing admin flag');
                setUser({
                    ...user,
                    assigned_user_types: user.assigned_user_types.filter(t => t.user_type_id !== 'ADMIN')
                });
                setIsAdmin(false);
                toast.success('Admin flag removed', { theme: 'colored' });
            } catch (error) {
                console.log('Unable to disable ADMIN status:', error);
                toast.error('Unable to disable ADMIN status', { theme: 'colored' });
            }
        } else {
            // Add admin flag
            try {
                const result = await userAPI.createAssignedUserType(user.id, {
                    userTypeId: 'ADMIN',
                    active: true
                });
                if (!result?.data) throw new Error('Error adding admin flag');

                setUser({
                    ...user,
                    assigned_user_types: [
                        ...user.assigned_user_types,
                        result.data
                    ]
                });
                toast.success('Admin flag removed', { theme: 'colored' });
                setIsAdmin(true);
            } catch (error) {
                console.log('Unable to enable ADMIN status:', error);
                toast.error('Unable to enable ADMIN status', { theme: 'colored' });
            }
        }
    }

    useEffect(() => {
        adminFlag(user);
        // setActiveUser(Auth.getProfile().data);
    }, []);

    return (
        <div>
            <Row className="pt-2 border-bottom">
                <Col sm={1}>
                    <Form.Check
                        type="switch"
                        id={"admin-toggle"}
                        className="d-inline"
                        checked={isAdmin}
                        onChange={(ev) => handleToggle(ev)}
                        disabled={user.id === Auth.getProfile().data.user_id}
                    />
                </Col>
                <Col>
                    <span className="fw-bold">Admin </span>
                    <p>Admins are granted access to allow feature available within Fulcrum under the client account.</p>
                </Col>
            </Row>
            {/* <p>
                {adminFlag(user) ? "ADMIN" : "NOT ADMIN"}
                {!loading && adminFlag(activeUser) && activeUser.user_id !== user.id && <span>
                    {` - `}<button onClick={(ev) => handleToggle(ev)} style={{ cursor: 'pointer' }}>(Toggle)</button>
                </span>}
            </p> */}
        </div>
    );
}

UserAdminToggle.propTypes = {
    user: PropTypes.object,
    setUser: PropTypes.func
}

export default UserAdminToggle;