import React, { useState, useEffect } from 'react';
let { emailAPI } = require('utils/api/fulcrum-api');
import EmailRow from './EmailRow';
import IconButton from 'components/common/IconButton';
import Table from 'react-bootstrap/Table';
import { toast } from 'react-toastify';
import { Alert } from 'react-bootstrap';

const EmailList = ({ entity_id, refreshParent = () => { } }) => {
	const [hasPrimaryEmail, setHasPrimaryEmail] = useState(false);
	const [loading, setLoading] = useState(true);
	const [emails, setEmails] = useState([]);



	// Add email
	const addEmail = async () => {
		try {
			let result = await emailAPI.createContactEmail({
				email_type_id: 1,
				entity_id
			});
			if (!result?.data) {
				throw new Error('No email data returned');
			}
			getEmailList();
			toast.success(`Email successfully added.`, {
				theme: 'colored'
			});
		} catch (error) {
			console.error(error);
			toast.error(`${error}`, {
				theme: 'colored'
			});
		}
	};

	// Get email list by entity_id
	const getEmailList = async () => {
		try {
			let result = await emailAPI.getAllEmailsByEntityId(entity_id);
			if (!result?.data) {
				throw new Error('No email data returned');
			}

			// Check if any emails are primary
			let hasPrimary = false;
			result.data.forEach(email => {
				if (email.is_primary) {
					hasPrimary = true;
				}
			});
			setHasPrimaryEmail(hasPrimary);
			setEmails(result.data);
			refreshParent(result.data);
			setLoading(false);
		} catch (error) {
			console.error(error);
			toast.error(`${error}`, {
				theme: 'colored'
			});
		}
	};

	useEffect(() => {
		getEmailList(entity_id);
		// getRelatedData();
	}, []);

	if (!entity_id) {
		return (<p>No entity provided</p>);
	}

	return (
		<div>
			<div>

				{loading ? <p>Loading emails...</p> : (
					<>
						<span className="mb-2 fw-bold">Emails</span>
						<Table responsive>
							<thead>

								{/* <tr>
								<th colSpan={3}>
									<span className="d-inline fw-bold">Claimant Emails</span>
									<IconButton
										className="me-2 mb-1 float-end"
										variant="falcon-default"
										size="sm"
										icon="plus"
										transform="shrink-3"
										onClick={addEmail}
									>
										Add Email
									</IconButton>
								</th>
							</tr> */}

								<tr>
									<th scope="col">Prim.</th>
									<th scope="col">Email</th>
									{/* <th scope="col">Note</th> */}
									<th scope="col">
									</th>
								</tr>
							</thead>
							<tbody>
								{
									!hasPrimaryEmail && (
										<tr>
											<td colSpan={3}>
												<Alert variant='danger' className="">No primary email selected.</Alert>
											</td>
										</tr>
									)
								}
								{emails.map((email) => (
									<EmailRow
										email={email}
										getEmailList={getEmailList}
										emails={emails}
										setEmails={setEmails}
										key={email.id}
										refreshParent={refreshParent}
									// emailTypes={emailTypes}
									/>
								))}
								<tr>
									<td colSpan={3}>
										<IconButton
											className=""
											variant="falcon-default"
											size="sm"
											icon="plus"
											transform="shrink-3"
											onClick={addEmail}
										>
											Add Email
										</IconButton>
									</td>
								</tr>
							</tbody>
						</Table>
					</>
				)}
			</div>
		</div>
	);
};

export default EmailList;