import { claimantAPI } from "../utils/api/fulcrum-api";
import { toast } from 'react-toastify';

const handleNotification = async (notification, callback = () => { }) => {
    const data = JSON.parse(notification?.data || {});

    switch (notification.notification_type_id) {
        case 'CASE_CLOSED_BY_SOCIAL_SECURITY':
        case 'RECIEVED_AWARD_LETTER':
            break;
        case "RECEIVED_RETAINER_PACKET":
            // Build case file
            try {
                const result = await claimantAPI.createClaimantActiveCase(data?.claimant_id || null);
                if (result?.response?.error === "Claimant already has an active case.") {
                    toast.error("Claimant already has an active case.", {
                        theme: 'colored',
                    });
                    break;
                }
                if (!result?.data || result?.error) {
                    throw new Error('Unable to create new case');
                }
                toast.success('New active case created', { theme: 'colored' });
            } catch (error) {
                toast.error('Unable to create new case', {
                    theme: 'colored',
                });
            }
            break;
        default:
            if (data?.actionPath && Array.isArray(data.actionPath)) {
                data.actionPath.forEach(async (actionObj) => {
                    switch (actionObj.type) {
                        case "update_claimant":
                            try {
                                await claimantAPI.updateClaimant(actionObj.claimant_id, { ...actionObj.payload });
                            } catch (error) {
                                console.log('Error updating claimant information:', error);
                            }
                            break;
                        case "log":
                            console.log(actionObj.contents);
                            break;
                        default:
                            console.log('Data-action Type not found.');
                    }
                });
            }

    }

    callback();
};

export default handleNotification;