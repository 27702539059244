import React, { useState, useContext } from 'react';
import { Button, Modal, Alert, Form } from 'react-bootstrap';
import { claimantAPI, caseAPI } from 'utils/api/fulcrum-api';
import { toast } from 'react-toastify';
import LeadProgressTool from '../LeadProgressTool';
import Swal from 'sweetalert2';
import AppContext from 'context/Context';
import { Tabs, Tab } from 'react-bootstrap';
import EREInformation from './EREInformation';
import HearingTable from 'components/pages/hearings/HearingTable';


const Hearing = ({ claimant, setClaimant, selectedCase, setSelectedCase = () => { }, getClaimantCase = () => { } }) => {
	const {
		config: { isDark }
	} = useContext(AppContext);


	return (
		<div>
			<h4>Hearing Information</h4>
			<Tabs defaultActiveKey="ere-information" id="uncontrolled-tab-example">
				<Tab eventKey="ere-information" title="ERE Information" className='border-bottom border-x p-3'>
					<EREInformation claimant={claimant} setClaimant={setClaimant} selectedCase={selectedCase} setSelectedCase={setSelectedCase} getClaimantCase={getClaimantCase} />
				</Tab>
				<Tab eventKey="hearing-entries" title="Hearing Entries" className='border-bottom border-x p-3'>
					<HearingTable
						tableHeaderLabel={'Hearings'}
						defaultSearchParams={{}}
						requiredSearchParams={{ claimant_id: claimant.id }}
						defaultClaimant={claimant}
					/>
				</Tab>
			</Tabs>
		</div>
	);
};

export default Hearing;