import React, { useState, useContext, useEffect } from 'react';
import Swal from 'sweetalert2';
import { Alert, Button, Row, Col, Card, Dropdown, Spinner } from 'react-bootstrap';
import { assureAPI } from 'utils/api/fulcrum-api';
import { toast } from 'react-toastify';
import moment from 'moment';
import CardDropdown from 'components/common/CardDropdown';
import { sleep } from 'utils/functions';
import PDFViewerModal from 'components/app/pdfjs-viewer/PDFViewerModal';
// import CreateCaseFileRequest from 'components/pages/assure/case-files/CreateCaseFileRequestNew';

const CaseFiles = ({ claimant, setClaimant, selectedCase, setSelectedCase = () => { }, type, syncingCaseInfo, refreshParent = () => { } }) => {
	const [loading, setLoading] = useState(true);
	const [downloadingFile, setDownloadingFile] = useState(false);
	const [refreshingFile, setRefreshingFile] = useState(false);
	const [caseFileInformation, setCaseFileInformation] = useState({});
	const [caseFileInformationLoading, setCaseFileInformationLoading] = useState(true);
	const [pdfViewerModalState, setPdfViewerModalState] = useState(false);
	const [errors, setErrors] = useState([]);

	// Handle error
	const handleError = (errorMessage) => {
		setErrors([errorMessage]);
		toast.error(errorMessage, { theme: 'colored' });
	}

	// Set case file information by type
	const setCaseFileInformationByType = async () => {
		setCaseFileInformationLoading(true);
		let newCaseFileInformation = {};
		switch (type) {
			case 1:
				// Exhibited Case File
				newCaseFileInformation = {
					...caseFileInformation,
					id: selectedCase?.assure_exhibited_file_id || null,
					file_status: selectedCase?.exhibited_file_status ? selectedCase.exhibited_file_status : '',
					file_requested: selectedCase?.exhibited_file_requested ? moment(selectedCase.exhibited_file_requested).format('MM-DD-YYYY hh:mm A') : 'Not requested.',
					file_last_checked: selectedCase?.exhibited_file_last_checked ? moment(selectedCase.exhibited_file_last_checked).format('MM-DD-YYYY hh:mm A') : '',
					file_last_updated: selectedCase?.exhibited_file_last_updated ? moment(selectedCase.exhibited_file_last_updated).format('MM-DD-YYYY hh:mm A') : '',
					file_last_changed: selectedCase?.exhibited_file_last_changed ? moment(selectedCase.exhibited_file_last_changed).format('MM-DD-YYYY hh:mm A') : '',
					file_count: selectedCase?.exhitibed_file_count ? selectedCase.exhitibed_file_count : null,
					file_page_count: selectedCase?.exhibited_file_page_count ? selectedCase.exhibited_file_page_count : null,
					file_file_id: selectedCase?.assure_exhibited_file_file_id || null,
					file_stored_file_id: selectedCase?.assure_exhibited_file_stored_file_id || null,
					file_id: selectedCase?.exhibited_file_file_id || null,
				}
				break;
			case 2:
				// All Case Files
				newCaseFileInformation = {
					...caseFileInformation,
					id: selectedCase?.assure_case_file_id || null,
					file_status: selectedCase?.case_file_status ? selectedCase.case_file_status : '',
					file_requested: selectedCase?.case_file_requested ? moment(selectedCase.case_file_requested).format('MM-DD-YYYY hh:mm A') : 'Not requested.',
					file_last_checked: selectedCase?.case_file_last_checked ? moment(selectedCase.case_file_last_checked).format('MM-DD-YYYY hh:mm A') : '',
					file_last_updated: selectedCase?.case_file_last_updated ? moment(selectedCase.case_file_last_updated).format('MM-DD-YYYY hh:mm A') : '',
					file_last_changed: selectedCase?.case_file_last_changed ? moment(selectedCase.case_file_last_changed).format('MM-DD-YYYY hh:mm A') : '',
					file_count: selectedCase?.case_file_count ? selectedCase.case_file_count : null,
					file_page_count: selectedCase?.case_file_page_count ? selectedCase.case_file_page_count : null,
					file_file_id: selectedCase?.assure_case_file_file_id || null,
					file_stored_file_id: selectedCase?.assure_case_file_stored_file_id || null,
					file_id: selectedCase?.case_file_file_id || null,
				}
				break;
			default:
				break;
		}
		setCaseFileInformation(newCaseFileInformation);
		setCaseFileInformationLoading(false);
		await sleep(1000);
		setLoading(false);
	}

	// Perform download check
	const performDownloadCheck = async () => {
		setErrors([]);
		setCaseFileInformation({ ...caseFileInformation, file_status: 1001 });
		try {
			let result = await assureAPI.performCaseFileDownloadCheck(selectedCase?.claimant_id || null, selectedCase?.id || null, type);
			if (!result?.data) {
				if (result?.response?.message) {
					throw result.response.message;
				}
				throw 'Unable to perform the update check. Please try again later.'
			}
		} catch (error) {
			handleError(error || 'Unable to perform the download check. Please try again later.');
		}
	};

	// Perform update check 
	const performUpdateCheck = async () => {
		setErrors([]);
		setCaseFileInformation({ ...caseFileInformation, file_status: 1002 });
		try {
			let result = await assureAPI.performCaseFileUpdateCheck(selectedCase?.claimant_id || null, selectedCase?.id || null, type);
			if (!result?.data) {
				if (result?.response?.message) {
					throw result.response.message;
				}
				throw 'Unable to perform the update check. Please try again later.'
			}
			setSelectedCase(result.data);
		} catch (error) {
			handleError(error || 'Unable to perform the update check. Please try again later.');
		}
	};

	// Download case file
	const downloadCaseFile = async () => {
		setDownloadingFile(true);
		try {
			let result = await assureAPI.downloadCaseFileByType(selectedCase?.claimant_id || null, selectedCase?.id || null, type);
			refreshParent(selectedCase?.id);
		} catch (error) {
			handleError(error || 'Unable to download the case file. Please try again later.');
		}
		setDownloadingFile(false);
	};

	// Refresh case file
	const refreshCaseFile = async () => {
		setErrors([]);
		setCaseFileInformation({ ...caseFileInformation, file_status: 1003 });
		setRefreshingFile(true);
		try {
			let result = await assureAPI.syncCaseInformationWithAssure(selectedCase?.claimant_id || null, selectedCase?.id || null, {});
			refreshParent(selectedCase?.id);
			setCaseFileInformationByType();
			await quickRefresh();
		} catch (error) {
			toast.error('Unable to refresh the case file. Please try again later.', { theme: 'colored' });
		}
		setRefreshingFile(false);
	};


	// Quick Refresh
	const quickRefresh = async () => {
		setErrors([]);
		setCaseFileInformation({ ...caseFileInformation, file_status: 1003 });
		setRefreshingFile(true);
		try {
			let result = await assureAPI.viewAndUpdateCaseFileByType(selectedCase?.claimant_id || null, selectedCase?.id || null, type, {});
			refreshParent(selectedCase?.id);
			setCaseFileInformationByType();
		} catch (error) {
			toast.error('Unable to refresh the case file. Please try again later.', { theme: 'colored' });
		}
		setRefreshingFile(false);
	};

	// Resolve case file status
	const resloveCaseFileStatus = (status) => {
		status = parseInt(status);
		let statusJSX;

		switch (status) {
			case 0:
				statusJSX = (<span>Not Requested</span>);
				break;
			case 1:
				statusJSX = (<span className="text-info"><Spinner animation="border" size="sm" /> In Queue...</span>);
				break;
			case 2:
				statusJSX = (<span className="text-info"><Spinner animation="border" size="sm" /> Authenticating...</span>);
				break;
			case 3:
				statusJSX = (<span className="text-info"><Spinner animation="border" size="sm" /> Downloading...</span>);
				break;
			case 4:
				statusJSX = (<span className="text-info"><Spinner animation="border" size="sm" /> Importing...</span>);
				break;
			case 5:
				statusJSX = (<span className="text-success">Completed</span>);
				break;
			case 6:
				statusJSX = (<span className="text-danger">Failed</span>);
				break;
			case 7:
				statusJSX = (<span className="text-info"><Spinner animation="border" size="sm" /> Updating...</span>);
				break;
			case 8:
				statusJSX = (<span className="text-danger">Corrupted</span>);
				break;
			case 9:
				statusJSX = (<span className="text-danger">No SSN Match</span>);
				break;
			case 10:
				statusJSX = (<span className="text-info"><Spinner animation="border" size="sm" /> In ERE Queue...</span>);
				break;
			case 11:
				statusJSX = (<span className="text-info"><Spinner animation="border" size="sm" /> Downloading...</span>);
				break;
			case 12:
				statusJSX = (<span className="text-warning">No Exhibited</span>);
				break;
			case 13:
				statusJSX = (<span className="text-danger">Error</span>);
				break;
			case 1001:
				statusJSX = (<span className="text-info"><Spinner animation="border" size="sm" /> Performing Download Check...</span>);
				break;
			case 1002:
				statusJSX = (<span className="text-info"><Spinner animation="border" size="sm" /> Checking for Update...</span>);
				break;
			case 1003:
				statusJSX = (<span className="text-info"><Spinner animation="border" size="sm" /> Refreshing...</span>);
				break;
			default:
				statusJSX = (<span className="text-warning">Unknown</span>);
				break;
		}

		return statusJSX;
	};

	useEffect(() => {
		setCaseFileInformationByType();
	}, []);

	useEffect(() => {
		// All Case Files
		if (type === 2) {
			quickRefresh();
		}
	}, [
		selectedCase?.assure_case_id,
		selectedCase?.assure_case_file_id,
		selectedCase?.case_file_status,
		selectedCase?.case_file_update_requested,
	])

	useEffect(() => {
		// Exhibited
		if (type === 1) {
			quickRefresh();
		}
	}, [
		selectedCase?.assure_case_id,
		selectedCase?.assure_exhibited_file_id,
		selectedCase?.exhibited_file_status,
		selectedCase?.exhibited_file_update_requested,
	])

	return (
		<div className="my-2">
			<h5 className="d-inline">{type === 1 ? "Exhbited Files" : "All Case Files"}</h5>
			<div className="d-flex justify-content-between float-end border rounded">
				<CardDropdown>
					<div className="py-2">
						{
							caseFileInformation?.id &&
							<Dropdown.Item onClick={async () => { refreshCaseFile(2) }} className="">Refresh</Dropdown.Item>
						}
						{
							caseFileInformation?.file_status === 5 &&
							<Dropdown.Item onClick={async () => { performUpdateCheck(2) }} className="">Update Check</Dropdown.Item>
						}
						{
							(caseFileInformation?.file_file_id || caseFileInformation?.file_stored_file_id) &&
							<Dropdown.Item onClick={async () => { downloadCaseFile(2) }} className="" disabled={downloadingFile}>
								{
									downloadingFile ? "Downloading..." : "Download File"
								}
							</Dropdown.Item>
						}

						{
							!caseFileInformation?.id && (
								<Dropdown.Item onClick={async () => { performDownloadCheck() }} className="">Download Check</Dropdown.Item>
							)
						}
					</div>
				</CardDropdown>
			</div>
			<hr />
			{
				(loading || syncingCaseInfo) ?
					(
						<div className="d-flex justify-content-center">
							<Spinner animation="border" /> Loading sync...
						</div>
					)
					:
					(
						<>
							{
								Array.isArray(errors) && errors.length > 0 && (
									<Alert variant="danger" onClose={() => { setErrors([]) }} dismissible>
										{
											errors.map((error, index) => {
												return (
													<p key={index}>{error}</p>
												)
											})
										}
									</Alert>
								)
							}
							<div>
								{
									!caseFileInformation?.id ?
										(
											<div className="text-center bg-200 my-3 mx-2 py-3 px-3">
												<p className="fw-bold">It looks like there is no attached case file. Perform a download check to see if the claimant has a case file on the ERE.</p>

												<Button
													variant="outline-primary"
													onClick={() => {
														performDownloadCheck(2);
													}}
												>
													Download Check
												</Button>
											</div>
										)
										:
										(
											<>
												{
													false ?
														(
															<div className="text-center bg-200 my-3 mx-2 py-3 px-3">
																<Spinner animation="border" role="status" size="sm">
																	<span className="visually-hidden">Loading...</span>
																</Spinner>
																<p className="fw-bold">Refreshing case file...</p>
															</div>
														)
														:
														(
															<>
																<p className="border-bottom pb-2 mb-2"><span className="fw-bold">ID:</span> <span className="float-end">{caseFileInformation?.id ? caseFileInformation?.id : ''}</span></p>
																<p className="border-bottom pb-2 mb-2"><span className="fw-bold">Status:</span> <span className="float-end fw-bold">{caseFileInformation?.file_status ? <span>{resloveCaseFileStatus(caseFileInformation.file_status)}</span> : ''}</span></p>
																<p className="border-bottom pb-2 mb-2"><span className="fw-bold">Last Update Requested:</span> <span className="float-end">{caseFileInformation?.file_requested ? moment(caseFileInformation.file_requested).format('MM-DD-YYYY hh:mm A') : 'Not requested.'}</span></p>
																<p className="border-bottom pb-2 mb-2"><span className="fw-bold">Last Checked:</span> <span className="float-end">{caseFileInformation?.file_last_checked ? moment(caseFileInformation.file_last_checked).format('MM-DD-YYYY hh:mm A') : ''}</span></p>
																<p className="border-bottom pb-2 mb-2"><span className="fw-bold">Last Update:</span> <span className="float-end">{caseFileInformation?.file_last_updated ? moment(caseFileInformation.file_last_updated).format('MM-DD-YYYY hh:mm A') : ''}</span></p>
																<p className="border-bottom pb-2 mb-2"><span className="fw-bold">Last Changed:</span> <span className="float-end">{caseFileInformation?.file_last_changed ? moment(caseFileInformation.file_last_changed).format('MM-DD-YYYY hh:mm A') : ''}</span></p>
																<p className="border-bottom pb-2 mb-2"><span className="fw-bold">File Count:</span> <span className="float-end">{caseFileInformation?.file_count ? caseFileInformation.file_count : 'Unknown'}</span></p>
																<p className="border-bottom pb-2 mb-2"><span className="fw-bold">Page Count:</span> <span className="float-end">{caseFileInformation?.file_page_count ? caseFileInformation.file_page_count : 'Unknown'}</span></p>
																<p className="border-bottom pb-2 mb-2"><span className="fw-bold">Assure File ID:</span> <span className="float-end">{caseFileInformation?.file_file_id ? caseFileInformation.file_file_id : 'Unknown'}</span></p>
																<p className="border-bottom pb-2 mb-2"><span className="fw-bold">Stored File ID:</span> <span className="float-end">{caseFileInformation?.file_stored_file_id ? caseFileInformation.file_stored_file_id : 'Unknown'}</span></p>
																{
																	(caseFileInformation?.file_file_id || caseFileInformation?.file_stored_file_id) &&
																	<>
																		<p className="border-bottom pb-2 mb-2"><span className="fw-bold">File:</span> <span className="float-end">
																			<Button variant="link" onClick={() => downloadCaseFile()} disabled={downloadingFile}>
																				{
																					downloadingFile ?
																						<>
																							<Spinner animation="border" role="status" size="sm" /> Downloading...
																						</>
																						:
																						<>
																							{
																								caseFileInformation?.file_status == '5' ?
																									(
																										<span>
																											Download File
																										</span>
																									)
																									:
																									(
																										<span>
																											Download Last Successful File
																										</span>
																									)
																							}
																						</>
																				}
																			</Button>
																			{
																				caseFileInformation?.file_id && (
																					<>
																						<Button
																							onClick={() => setPdfViewerModalState(true)}
																							variant="link"
																							disabled={downloadingFile}
																						>
																							View File
																						</Button>
																						<PDFViewerModal
																							modalState={pdfViewerModalState}
																							setModalState={setPdfViewerModalState}
																							selectedFileId={caseFileInformation.file_id}
																							setSelectedFileId={() => { }}
																						/>
																					</>
																				)
																			}
																		</span></p>




																	</>
																}
															</>
														)
												}
											</>
										)
								}
							</div>
						</>
					)
			}

		</div >
	);
};

export default CaseFiles;