import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Card, Dropdown } from 'react-bootstrap';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import LeadVendorTableHeader from './LeadVendorTableHeader';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import { leadVendorAPI } from 'utils/api/fulcrum-api';
import { useEffect } from 'react';
import { removeEmptyFromObj } from 'utils/functions';
import moment from 'moment';
import LeadVendorDetails from './LeadVendorDetails';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import CardDropdown from 'components/common/CardDropdown';
import auth from 'utils/auth';

const columns = [
	{
		accessor: 'lead_vendor_name',
		Header: 'Lead Vendor',
		headerProps: { className: 'pe-1' },
		cellProps: {
			className: 'py-2'
		}
	},
	{
		accessor: 'email',
		Header: 'Email',
		headerProps: { className: 'pe-1' },
		cellProps: {
			className: 'py-2'
		}
	},
	{
		accessor: 'is_active',
		Header: 'Status',
		headerProps: { className: 'pe-1' },
		cellProps: {
			className: 'py-2'
		}
	},
	{
		accessor: 'phone',
		Header: 'Phone',
		headerProps: { className: 'pe-1' },
		cellProps: {
			className: 'py-2'
		}
	},
	{
		accessor: 'created_at',
		Header: 'Created',
		headerProps: { className: 'pe-1' },
		cellProps: {
			className: 'py-2'
		}
	},
	{
		accessor: 'menu',
		Header: '',
		headerProps: { className: 'pe-1' },
		cellProps: {
			className: 'py-2'
		},
	}
];

const LeadVendorTable = ({ account_id = auth.getProfile().data.account_id, defaultSearchParams = {}, requiredSearchParams = {} }) => {
	const [leadVendorData, setLeadVendorData] = useState([]);
	const [, setLeadVendorCount] = useState(0);
	const [searching, setSearching] = useState(false);
	const [lastQuery, setLastQuery] = useState({});
	const [searchParams] = useState({ ...defaultSearchParams, ...requiredSearchParams });
	const [selectedLeadVendor, setSelectedLeadVendor] = useState({});
	const [leadVendorModalState, setLeadVendorModalState] = useState(false);

	// Format data for table
	const formatData = (data = []) => {
		let adjustedData = data.map(lv => {
			return {
				...lv,
				created_at: lv?.createdAt ? moment(lv.createdAt).format('MM/DD/YYYY hh:mm A') : '',
				is_active: lv.is_active ? <span className="text-success">Active</span> : <span className="text-danger">Inactive</span>,
				menu:
					<div className="d-flex justify-content-between float-end">
						<CardDropdown>
							<div className="py-2">
								<Dropdown.Item onClick={() => { handleCustomButtonSelection(lv) }} className="">Edit</Dropdown.Item>
								<Dropdown.Divider />
								<Dropdown.Item onClick={() => { deleteLeadVendor(lv.id) }} className="text-danger">Delete</Dropdown.Item>
							</div>
						</CardDropdown>
					</div>
			}
		});
		setLeadVendorData(adjustedData);
	};

	// Delete lead vendor
	const deleteLeadVendor = async (id) => {
		Swal.fire({
			title: 'Are you sure?',
			text: "You won't be able to revert this!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Yes, delete it!'
		}).then(async (result) => {
			if (result.isConfirmed) {
				try {
					let result = await leadVendorAPI.deleteLeadVendor(id);
					if (!result?.message) { throw new Error('No data returned from server'); }
					refreshLastQuery();
					toast.success('Lead vendor has been deleted.', {
						theme: 'colored',
					});
				} catch (err) {
					toast.error('There was an error deleting the lead vendor.', {
						theme: 'colored',
					});
					console.log(err);
				}
			}
		})
	};


	// Lead Vendor Search Handler
	const leadVendorSearchHandler = async (params = {}, saveLastQuery = false) => {
		try {
			params = removeEmptyFromObj(params);
			let leadVendorResult = await leadVendorAPI.searchLeadVendors({ ...params, ...requiredSearchParams });
			if (!leadVendorResult?.data?.rows) {
				throw new Error('No custom field data found');
			}

			formatData(leadVendorResult.data.rows);
			setLeadVendorCount(leadVendorResult.data.count);

			if (saveLastQuery) {
				setLastQuery(params);
			}
		} catch (error) {
			console.log(error);
		}
		setSearching(false);
	};

	// New Search
	const newSearch = async (params) => {
		setSearching(true);
		let adjustedSearchParams = {
			...params,
			...requiredSearchParams
		};
		leadVendorSearchHandler(adjustedSearchParams);
	};

	// Refresh last query
	const refreshLastQuery = async () => {
		leadVendorSearchHandler(lastQuery);
	};


	// Handle leadVendor selection
	const handleCustomButtonSelection = (leadVendor) => {
		setSelectedLeadVendor(leadVendor);
		setLeadVendorModalState(true);
	};

	useEffect(() => {
		newSearch({ ...defaultSearchParams });
	}, []);

	// Apply search filters
	useEffect(() => { newSearch(searchParams) }, [searchParams]);

	return (
		<>
			<AdvanceTableWrapper
				columns={columns}
				data={leadVendorData}
				// selection
				sortable
				pagination
				perPage={10}
			>
				<Card className="mb-3 shadow-none">
					<Card.Header>
						<LeadVendorTableHeader
							table
							setLeadVendorModalState={setLeadVendorModalState}
							refreshLastQuery={refreshLastQuery}
						/>
					</Card.Header>
					<Card.Body className="p-0">
						{
							searching ?
								<p>Loading lead vendors...</p>
								:
								<AdvanceTable
									table
									headerClassName="bg-200 text-900 text-nowrap align-middle"
									rowClassName="align-middle white-space-nowrap"
									tableProps={{
										size: 'sm',
										striped: true,
										className: 'fs--1 mb-0 overflow-hidden'
									}}
								/>
						}

						<LeadVendorDetails
							modalState={leadVendorModalState}
							setModalState={setLeadVendorModalState}
							selectedLeadVendor={selectedLeadVendor}
							setSelectedLeadVendor={setSelectedLeadVendor}
							refreshParent={refreshLastQuery}
							account_id={account_id}
						/>
					</Card.Body>
					<Card.Footer>
						<AdvanceTablePagination table />
					</Card.Footer>
				</Card>
			</AdvanceTableWrapper>

		</>
	);
};

LeadVendorTable.propTypes = {
	defaultSearchParams: PropTypes.func,
	requiredSearchParams: PropTypes.func
}

export default LeadVendorTable;