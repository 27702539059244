import React, { useRef, useState, useEffect } from 'react';
import { Col, Form, Button, Tabs, Tab } from 'react-bootstrap';
import UserTable from 'components/pages/users/user-list/UserTable';
import { Link } from 'react-router-dom';
import { formatPhone } from 'utils/functions';
import ClaimantFolderTable from 'components/claimants/ClaimantFolderTable';
import InboundLeadTable from 'components/claimants/InboundLeadTable';

const ClaimantManagement = ({ } = {}) => {


	return (
		<div>

			<Tabs defaultActiveKey="inbound-leads" id="uncontrolled-tab-example">
				<Tab eventKey="inbound-leads" title="Inbound Leads" className='border-bottom border-x p-3' mountOnEnter={true}>
					<div>
						<InboundLeadTable
							requiredSearchParams={{}}
							siteAdminArea={true}
							showMetrics={false}
							defaultColumns={[
								{
									accessor: 'business_name',
									Header: 'Account',
									headerProps: { className: 'pe-1' },
									cellProps: {
										className: 'py-2'
									},
									Cell: rowData => {
										const { account = {} } = rowData.row.original;
										return <Link to={`/admin/account-details/${account?.id || null}`}>{account?.business_name || ''}</Link>;
									},
								},
								{
									accessor: 'first_name',
									Header: 'First Name',
									headerProps: { className: 'pe-1' },
									cellProps: {
										className: 'py-2'
									},
									Cell: rowData => {
										const { first_name, id } = rowData.row.original;
										return <Link to={`/admin/claimant-details/${id}`}>{first_name}</Link>;
									},
								},
								{
									accessor: 'last_name',
									Header: 'Last Name',
									headerProps: { className: 'pe-1' },
									cellProps: {
										className: 'py-2'
									},
									Cell: rowData => {
										const { last_name, id } = rowData.row.original;
										return <Link to={`/admin/claimant-details/${id}`}>{last_name}</Link>;
									}
								},
								{
									accessor: 'created_at',
									Header: 'Created',
									headerProps: { className: 'pe-1' },
									cellProps: {
										className: 'py-2'
									}
								},
								{
									accessor: 'last_called',
									Header: 'Last Called',
									headerProps: { className: 'pe-1' },
									cellProps: {
										className: 'py-2'
									},
									Cell: rowData => {
										const { last_called } = rowData.row.original;
										return last_called ? last_called : <span className="text-danger">Haven't called</span>;
									}
								},
								{
									accessor: 'established_contact',
									Header: 'Established Contact',
									headerProps: { className: 'pe-1' },
									cellProps: {
										className: 'py-2'
									},
									Cell: rowData => {
										const { established_contact } = rowData.row.original;
										return established_contact ? <span className='text-success'>Yes</span> : <span className="text-danger">No</span>;
									}
								},
								{
									accessor: 'screened',
									Header: 'Screened',
									headerProps: { className: 'pe-1' },
									cellProps: {
										className: 'py-2'
									},
									Cell: rowData => {
										const { screened } = rowData.row.original;
										return screened ? <span className='text-success'>Yes</span> : <span className="text-danger">No</span>;
									}
								},
								{
									accessor: 'reviewed',
									Header: 'Reviewed',
									headerProps: { className: 'pe-1' },
									cellProps: {
										className: 'py-2'
									},
									Cell: rowData => {
										const { reviewed } = rowData.row.original;
										return reviewed ? <span className='text-success'>Yes</span> : <span className="text-danger">No</span>;
									}
								},
							]}
						/>
					</div>
				</Tab>
				<Tab eventKey="claimant-folders" title="Claimant Folders" className='border-bottom border-x p-3' mountOnEnter={true}>
					<div>
						<ClaimantFolderTable
							requiredSearchParams={{}}
							siteAdminArea={true}
							defaultColumns={[
								{
									accessor: 'business_name',
									Header: 'Account',
									headerProps: { className: 'pe-1' },
									cellProps: {
										className: 'py-2'
									},
									Cell: rowData => {
										const { account = {} } = rowData.row.original;
										return <Link to={`/admin/account-details/${account?.id || null}`}>{account?.business_name || ''}</Link>;
									},
								},
								{
									accessor: 'first_name',
									Header: 'First Name',
									headerProps: { className: 'pe-1' },
									cellProps: {
										className: 'py-2'
									},
									Cell: rowData => {
										const { first_name, id } = rowData.row.original;
										return <Link to={`/admin/claimant-details/${id}`}>{first_name}</Link>;
									}
								},
								{
									accessor: 'last_name',
									Header: 'Last Name',
									headerProps: { className: 'pe-1' },
									cellProps: {
										className: 'py-2'
									},
									Cell: rowData => {
										const { last_name, id } = rowData.row.original;
										return <Link to={`/admin/claimant-details/${id}`}>{last_name}</Link>;
									}
								},
								{
									accessor: 'ssn',
									Header: 'SSN',
									headerProps: { className: 'pe-1' },
									cellProps: {
										className: 'py-2'
									}
								},
								{
									accessor: 'created_at',
									Header: 'Created',
									headerProps: { className: 'pe-1' },
									cellProps: {
										className: 'py-2'
									}
								},
								{
									accessor: 'primary_representative',
									Header: 'Primary Rep',
									headerProps: { className: 'pe-1' },
									cellProps: {
										className: 'py-2'
									},
								},
								{
									accessor: 'office_with_jurisdiction',
									Header: 'Office with Jurisdiction',
									headerProps: { className: 'pe-1' },
									cellProps: {
										className: 'py-2'
									},
								},
							]}
						/>
					</div>
				</Tab>
			</Tabs>
		</div>
	);
};





export default ClaimantManagement;