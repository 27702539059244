export const fulcrumFaq = [
  {
    id: 1,
    title: 'How do I access your services?',
    description: 'Assure is a web-based platform and can be accessed from any browser from anywhere in the world where you have internet connectivity.'
  },
  {
    id: 2,
    title: 'What are the benefits for my firm?',
    description: 'The benefits of our products vary based on the products utilized, but many firms who use Atlasware may find their firm spending as much as half the number of hours previously spent to download and upload files to the SSA electronic records exchange (ERE). Hearing coverage can also allow firms to expand their disability practice nationwide. Firms no longer have to be bound only representing claimants in one geographical location. Now firms can partner with us to represent claimants, regardless of where they live or move.'
  },
  {
    id: 3,
    title: 'Is there a long-term contract?',
    description: 'Firms will have to review and accept our Masters Service Agreement (MSA) to utilize the products we offer, but there is not a long-term commitment or contract.'
  },
  {
    id: 4,
    title: "How do I know my firm's data is secure on the cloud?",
    description: 'Our firm is SOC2 certified and HIPAA compliant. We are audited each year to verify our security and privacy policies meet industry standards. If your firm wants specific information regarding our security and privacy policies, we would be glad to provide additional information upon request.'
  },
  {
    id: 5,
    title: "What type of ongoing support does your business need?",
    description: 'With a team of highly trained professionals, we are here to help you anytime you need help. Access is available via email support 24/7. Phone support is available Monday- Friday 9-4 CST.'
  },
  {
    id: 6,
    title: "Can I use just one service or do I have to subscribe to all of them?",
    description: 'Our products are completely flexible. All products are sold a la carte and can be used as much or as infrequently as your firm needs. If you decide to use additional products after your firm has been onboarded, just contact our sales team.'
  },
  {
    id: 7,
    title: "Is there a monthly service fee?",
    description: 'We currently do not have a monthly service fee to use our products. Each product is offered for a one time cost, without a monthly service fee, maintenance fee, or training expense.'
  },
  {
    id: 8,
    title: "How much do your services cost?",
    description: 'Assure provides a variety of services. The price of our products varies based on the services in which your firm subscribes. We offer a quick 20 minute demo of our system so your firm can determine what services you would like to use.'
  },
  {
    id: 10,
    title: "How do I get started?",
    description: 'Contact our sales team by clicking the Request Demo option on our site. The good news is the products offered by Assure Disability can be accessed through a website through any major browser. Our system is platform neutral and is compatible with both Microsoft and macOS.'
  },
  {
    id: 11,
    title: "Can multiple people use your services at the same time?",
    description: 'Yes, multiple unique logins can be created for each user in the firm. All employees can log on to the system and simultaneously be uploading and downloading medical records, ordering or working on briefs, or ordering medical records.'
  }
]