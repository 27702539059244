import React, { useEffect, useState } from 'react';
import { Button, Col, Row, Form, Alert, Table, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { assureAPI, userAPI } from 'utils/api/fulcrum-api.js';
import Swal from 'sweetalert2';
// import AtlaswareOnboardingModal from 'components/wizard/atlasware-onboarding-wizard/AtlaswareOnboardingModal';
import AtlaswareOnboardingModal from 'components/pages/assure/atlasware/crednetials/atlasware-onboarding-wizard/AtlaswareOnboardingModal';
import ManageAtlaswareCredentials from './ManageAtlaswareCredentials';
import LinkAssureAttorney from './LinkAssureAttorney';
import { formatPhone } from 'utils/functions';
import moment from 'moment';

const UserAtlasware = ({ user, setUser = () => { }, refreshParent = () => { } }) => {
	const [enrolledInAtlasware, setEnrolledInAtlasware] = useState(false);
	const [loadingTextMessages, setLoadingTextMessages] = useState(false);
	const [assureAttorneyInfo, setAssureAttorneyInfo] = useState({});
	const [loading, setLoading] = useState(true);
	const [errors, setErrors] = useState([]);
	const [atlaswareOnboardingModalState, setAtlaswareOnboardingModalState] = useState(false);
	const [credentialInfo, setCredentialInfo] = useState({});
	const [textMessages, setTextMessages] = useState([]);
	const [editableForm, setEditableForm] = useState(false);

	const [formData, setFormData] = useState({});
	const [previousFormData, setPreviousFormData] = useState({});

	// Handle form change
	const handleFormChange = async (e) => {
		let { name, value } = e.target;
		setFormData({ ...formData, [name]: value });
	};

	// Link an ERE Credential call back
	const linkERECredentialCallback = (cred) => {
		getERECredentialInfo(cred?.id || null);
	};

	// Get ERE Credentials Text Messages
	const getERECredentialTextMessages = async (id) => {
		setLoadingTextMessages(true);
		try {
			let result = await assureAPI.getEreCredentialTextMessages(user?.account_id || null, id);
			if (!result?.data?.data?.messages) {
				throw new Error('No ERE Credential data returned');
			}
			// order messages with the most recent first
			let orderedMessages = result.data.data.messages ? result.data.data.messages.sort((a, b) => {
				let aDate = a?.dateTime?.date ? moment(a.dateTime.date) : null;
				let bDate = b?.dateTime?.date ? moment(b.dateTime.date) : null;
				if (aDate && bDate) {
					return bDate - aDate;
				}
			}) : [];
			setTextMessages(orderedMessages || []);
		} catch (error) {
			toast.error('Something went wrong while getting the ERE Credential Text Messages.', { theme: 'colored' });
			setErrors([...errors, "Unable to retrieve ERE Credential Text Messages from Assure."]);
		}
		setLoadingTextMessages(false);
	};

	// Get ERE Credential Info
	const getERECredentialInfo = async (id, withLoading = true) => {
		if (withLoading) {
			setLoading(true);
		}

		try {
			let result = await assureAPI.getEreCredentials(user?.account_id || null, id);
			if (!result?.data?.data) {
				throw new Error('No ERE Credential data returned');
			}

			console.log(result.data.data)
			setCredentialInfo(result.data.data || {});

			let newFormState = {
				attorney_name: result.data.data?.attorney_name || '',
				username: null,
				password: null,
			};
			setPreviousFormData(newFormState);
			setFormData(newFormState);

			if (result.data.data?.ere_phone_number?.number) {
				getERECredentialTextMessages(id);
			}
		} catch (error) {
			toast.error('Something went wrong while getting the ERE Credential Info.', { theme: 'colored' });
			setErrors([...errors, "Unable to retrieve ERE Credential Info from Assure."]);
		}
		setLoading(false);
	};

	// Unlink ERE Credential
	const unlinkERECredential = async () => {
		Swal.fire({
			title: 'Are you sure?',
			text: "You are about to unlink this ERE Credential from Assure. This will not delete the ERE Credential from Atlasware.",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Yes, unlink it!'
		}).then(async (result) => {
			if (result.isConfirmed) {
				try {
					let result = await userAPI.updateUser(user.id, { assure_ere_credentials_id: null, assure_ere_attorney_name: null });
					if (!result?.data) {
						throw new Error('No data returned');
					}
					refreshParent();
					toast.warning('The ERE Credential has been unlinked from Assure.', { theme: 'colored' });
				} catch (error) {
					toast.error('Something went wrong while unlinking the ERE Credential.', { theme: 'colored' });
					setErrors([...errors, "Unable to unlink ERE Credential from Assure."]);
				}
			}
		});
	};

	// Cancel Edit
	const cancelEdit = () => {
		setEditableForm(false);
		setFormData({
			attorney_name: credentialInfo?.attorney_name || '',
		})
	};

	// Update ERE Credential
	const updateERECredential = async () => {
		try {
			let payload = {
				attorney_name: formData?.attorney_name || null,
			}

			if (formData?.username) {
				payload.username = formData?.username || null;
			}

			if (formData?.password) {
				payload.password = formData?.password || null;
			}

			let result = await assureAPI.updateEreCredentials(user?.account_id || null, credentialInfo?.id || null, payload);
			if (!result?.data?.data || result?.data?.code !== 200) {
				throw new Error('No ERE Credential data returned');
			}
			console.log(result);
			setCredentialInfo(result.data.data || {});
			let newFormState = {
				attorney_name: result.data.data?.attorney_name || '',
				username: null,
				password: null,
			};

			setPreviousFormData(newFormState);
			setFormData(newFormState);

			toast.success('The ERE Credential has been updated.', { theme: 'colored' });
			setEditableForm(false);
		} catch (error) {
			toast.error('Something went wrong while updating the ERE Credential.', { theme: 'colored' });
			setFormData({
				attorney_name: credentialInfo?.ere_attorney_name || '',
			})
		}
	};

	useEffect(() => {
		if (user.assure_ere_credentials_id) {
			getERECredentialInfo(user.assure_ere_credentials_id, true);
		}
	}, []);


	// useEffect to detect if assureAttorneyInfo changes
	useEffect(() => {
		if (assureAttorneyInfo?.id) {
			setFormState({
				...formData,
				attorney_id: assureAttorneyInfo.id,
				attorney_first_name: assureAttorneyInfo.first_name,
				attorney_last_name: assureAttorneyInfo.last_name,
				is_active: assureAttorneyInfo.is_active,
				is_assignable: assureAttorneyInfo.is_assignable,
				external_id: assureAttorneyInfo.external_id
			})
			setEnrolledInAtlasware(assureAttorneyInfo.is_active);
		}
	}, [assureAttorneyInfo]);

	return (
		<div id="user-atlasware-tab" className="my-3">

			<h4>Atlasware</h4>
			{/* Enrolled in Service: {user?.assure_ere_credentials_id ? <span className="text-success">Yes</span> : <span className="text-danger">No</span>} */}
			<hr />
			{
				!user.assure_ere_credentials_id
					?
					<>
						<Button variant={'primary'} onClick={() => setAtlaswareOnboardingModalState(true)} >
							Atlasware Onboarding Wizard
						</Button>
						<AtlaswareOnboardingModal
							user={user}
							modalState={atlaswareOnboardingModalState}
							setModalState={setAtlaswareOnboardingModalState}
							refreshParent={refreshParent}
							callback={linkERECredentialCallback}
						/>
					</>
					:
					<>
						{
							loading
								?
								<>
									<Spinner animation="border" role="status" size="sm" /> Loading ERE information...
								</>
								:
								<>
									<div>
										<span className="fw-bold">ERE Credential Information</span>
										<Button
											variant="link"
											className="float-end"
											onClick={() => {
												getERECredentialInfo(user.assure_ere_credentials_id);
											}}
										>
											Refresh
										</Button>

										<div className="">
											<p>Not your Atlasware account? <span className="text-primary cursor-pointer" onClick={() => { unlinkERECredential() }}>Unlink account here.</span></p>
										</div>
										<Table bordered responsive size='sm'>
											<thead>
												<tr>
													<th style={{ width: '30%' }}></th>
													<th>
														<Button
															variant="link"
															className="float-end"
															onClick={() => {
																if (editableForm) {
																	cancelEdit();
																} else {
																	setEditableForm(true);
																}
															}}
														>
															{editableForm ? 'Cancel' : 'Edit'}
														</Button>
													</th>
												</tr>
											</thead>
											<tbody>
												<tr>
													<td>Attorney Name:</td>
													<td>
														<Form.Group>
															<Form.Control
																type="text"
																name="attorney_name"
																value={formData.attorney_name}
																onChange={handleFormChange}
																disabled={!editableForm}
															/>
														</Form.Group>
														{
															formData.attorney_name !== credentialInfo.attorney_name &&
															<Button
																variant="link"
																className="text-primary"
																onClick={() => {
																	updateERECredential();
																}}
															>
																Update
															</Button>
														}
													</td>
												</tr>
												<tr>
													<td>ID:</td>
													<td>{credentialInfo.id}</td>
												</tr>
												<tr>
													<td>Status:</td>
													<td>{("outdated" in credentialInfo && credentialInfo.outdated)
														?
														<span className="text-danger">Outdated</span>
														:
														<span>Connected</span>
													}</td>
												</tr>
												<tr>
													<td>ERE TFA Phone:</td>
													<td>{credentialInfo?.ere_phone_number?.number ? formatPhone(credentialInfo.ere_phone_number?.number) : (<span className="text-danger">No phone found.</span>)}</td>
												</tr>
												<tr>
													<td>ERE ID:</td>
													<td>
														{
															!editableForm ?
																<Button variant="link" onClick={() => { setEditableForm(true) }}>Edit</Button>
																:
																<Form.Group>
																	<Form.Control
																		type="text"
																		name="username"
																		value={formData.username}
																		onChange={handleFormChange}
																		disabled={!editableForm}
																	/>
																</Form.Group>
														}
													</td>
												</tr>
												<tr>
													<td>ERE Password:</td>
													<td>
														{
															!editableForm ?
																<Button variant="link" onClick={() => { setEditableForm(true) }}>Edit</Button>
																:
																<Form.Group>
																	<Form.Control
																		type="text"
																		name="password"
																		value={formData.password}
																		onChange={handleFormChange}
																		disabled={!editableForm}
																	/>
																</Form.Group>
														}
													</td>
												</tr>
											</tbody>
										</Table>
									</div>

									{/* <ManageAtlaswareCredentials
										user={user}
										credentialInfo={credentialInfo}
										modalState={atlaswareOnboardingModalState}
										setModalState={setAtlaswareOnboardingModalState}
										refreshParent={refreshParent}
									/> */}



									<div className="my-4">
										<span className="fw-bold">Forward Phones</span>
										<p>
											Phone numbers that you would like to have receive forwarded TFA authentication codes.
										</p>

										<Table bordered responsive striped size='sm'>
											<thead>
												<tr>
													<th style={{ width: '70%' }}>Phone</th>
													<th>Actions</th>
												</tr>
											</thead>
											<tbody>
											</tbody>
										</Table>
									</div>

									<div className="my-4">
										<span className="fw-bold">Forward Emails</span>
										<p>
											Email addresses that you would like to have receive forwarded TFA authentication codes.
										</p>

										<Table bordered responsive striped size='sm'>
											<thead>
												<tr>
													<th style={{ width: '70%' }}>Email</th>
													<th>Actions</th>
												</tr>
											</thead>
											<tbody>
											</tbody>
										</Table>
									</div>


									<div>
										<span className="fw-bold">Recent Text Messages</span>
										{
											credentialInfo?.ere_phone_number &&
											<Button
												variant="link"
												className="float-end"
												onClick={() => {
													getERECredentialTextMessages();
												}}
											>
												Refresh Messages
											</Button>
										}
										{
											!credentialInfo?.ere_phone_number?.number ?
												<p className="text-warning">You must link an ERE phone number for TFA authentication codes to see recent text messages.</p>
												:
												<>
													<p>
														Recent text messages that have been sent to your ERE phone number.
													</p>

													{
														Array.isArray(textMessages) && textMessages.length > 0
															?
															textMessages.map((message, index) => {
																return (
																	<div key={index}>
																		<p className="text-muted">{message?.date}</p>
																		<p>{message.body}</p>
																	</div>
																)
															})
															:
															<p className="text-muted">No text messages found.</p>
													}
												</>
										}
									</div>
								</>
						}
					</>
			}
		</div >
	);
};


export default UserAtlasware;