import PropTypes from 'prop-types';
import React, { useState, useContext, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { userAPI } from '../../utils/api/fulcrum-api';
import auth from '../../utils/auth';
import { AuthContext } from 'context/Context';
import { useEffect } from 'react';


const LoginForm = ({ hasLabel, setSlide = () => { }, setUUID = () => { } }) => {

  const ref = useRef(null);

  const navigate = useNavigate();
  // State
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    remember: false
  });


  const {
    permissionsDispatch
  } = useContext(AuthContext);

  // Handler
  const handleSubmit = async e => {
    e.preventDefault();

    try {
      let result = await userAPI.login(formData.email, formData.password, {});
      if (!result?.session) {
        throw new Error('Incorrect credentials');
      }

      // Store sessions settings returned by front end login
      Object.keys(result?.session?.settings).forEach(key => {
        // localStorage.setItem(key, JSON.stringify(result?.session?.settings[key]));
        // localStorage.setItem(key + "_value", result?.session?.settings[key]?.value);
        console.log("After Login, (LoginForm.js):");
        console.log("key: " + key);
        console.log(JSON.stringify(result?.session?.settings[key]));
      });

      // Check if TFA is needed
      if (result?.session?.status === 'Pending TFA') {
        setUUID(result?.session?.tfa_uuid);
        setSlide("tfa");
        toast.warning('TFA required.', { theme: 'colored' });
      } else {
        toast.success(`Logged in as ${formData.email}`, {
          theme: 'colored'
        });
        auth.login({ data: { ...result.session } }, permissionsDispatch, navigate);
      }
    } catch (error) {
      toast.error(error.message, {
        theme: 'colored'
      });
    }
  };

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  useEffect(() => {
    ref.current.focus();
  }, [ref]);

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Email address</Form.Label>}
        <Form.Control
          ref={ref}
          placeholder={!hasLabel ? 'Email address' : ''}
          value={formData.email}
          name="email"
          onChange={handleFieldChange}
          required
          type="email"
        />
      </Form.Group>

      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Password</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'Password' : ''}
          value={formData.password}
          name="password"
          onChange={handleFieldChange}
          type="password"
        />
      </Form.Group>

      <Row className="justify-content-between align-items-center">
        <Col xs="auto">
        </Col>

        <Col xs="auto">
          <Link
            className="fs--1 mb-0"
            to={`/forgot-password`}
          >
            Forgot Password?
          </Link>
        </Col>
      </Row>

      <Form.Group>
        <Button
          type="submit"
          color="primary"
          className="mt-3 w-100"
          disabled={!formData.email || !formData.password}
        >
          Log in
        </Button>
      </Form.Group>
    </Form>
  );
};

LoginForm.propTypes = {
  layout: PropTypes.string,
  hasLabel: PropTypes.bool,
  setSlide: PropTypes.func,
  setUUID: PropTypes.func
};

LoginForm.defaultProps = {
  layout: 'simple',
  hasLabel: false
};

export default LoginForm;
