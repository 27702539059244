import React, { useState } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import bg2 from 'assets/img/generic/bg-2.jpg';
import Section from 'components/common/Section';
import ScheduleADemoModal from 'components/app/hubspot/ScheduleADemoModal';

const Cta = () => {
  const [showModal, setShowModal] = useState(false);


  return (
    <Section overlay image={bg2} position="center top" className="light">
      <Row className="justify-content-center text-center">
        <Col lg={8}>
          <p className="fs-3 fs-sm-4 text-white">
            Join our community today and manage your business with ease.
          </p>
          <Button
            variant="outline-light"
            size="lg"
            className="border-2 rounded-pill mt-4 fs-0 py-2"
            onClick={() => setShowModal(true)}
          >
            Request a Demo
          </Button>

          <ScheduleADemoModal
            modalState={showModal}
            setModalState={setShowModal}
            title="Request a Demo"
            message="We would love to give you a full tour of all the great things Fulcrum has to offer. All you need to do is fill out this form and we will reach out to you so we can schedule a demo. We can't wait to introduce you to your new way of working."
          />
        </Col>
      </Row>
    </Section>
  )
};

export default Cta;
