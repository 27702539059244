import React, { useState, useEffect, useRef } from 'react';
import { accountServiceAPI } from 'utils/api/fulcrum-api';
import { Alert } from 'react-bootstrap';
import auth from 'utils/auth';

const ServiceAccess = ({ user, service_id, resultCallBack = () => { } }) => {
	let account_id = auth.getProfile()?.data?.account_id;
	const [accountHasServiceAccess, setAccountHasServiceAccess] = useState(false);

	let service;

	switch (service_id) {
		case 1:
			service = 'Hearing Coverage';
			break;
		case 2:
			service = 'Briefing';
			break;
		case 3:
			service = 'Claimant Locator';
			break;
		case 4:
			service = 'Outbound Lead Generation';
			break;
		default:
			break;
	}

	// Check if account has service access
	const checkAccountServiceAccess = async () => {
		let hasAccess = false;
		try {
			let result = await accountServiceAPI.checkAccountServiceStatus(account_id, service_id, `${account_id}_${service_id}`);
			if (!result?.data) { throw new Error(`Error checking service access for ${service}.`); }

			if ("isActive" in result.data && result.data.isActive) {
				setAccountHasServiceAccess(true);
				hasAccess = false;
			} else {
				setAccountHasServiceAccess(false);
				hasAccess = false;
			}
		} catch (error) {
			setAccountHasServiceAccess(false);
			hasAccess = false;
		}

		resultCallBack(accountHasServiceAccess);
	};

	useEffect(() => {
		checkAccountServiceAccess();
	}, [])


	return (
		<div>
			{
				accountHasServiceAccess ?
					<div>
						<Alert variant="success">
							Account has {service} Access!
						</Alert>
					</div>
					:
					<div>
						<Alert variant="danger">
							Account does not have {service} Access. You must enable this on the Account details page under the Services tab.
						</Alert>
					</div>
			}
		</div>
	);
}

export default ServiceAccess;