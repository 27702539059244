import FalconComponentCard from 'components/common/FalconComponentCard';
import { chartJsDefaultTooltip } from 'helpers/chartjs-utils';
import { getColor, rgbaColor } from 'helpers/utils';
import { useNavigate } from 'react-router-dom';
import React, { useState, useEffect, useRef } from 'react';
import { Bar } from 'react-chartjs-2';
import { Card } from 'react-bootstrap';
import { claimantAPI } from 'utils/api/fulcrum-api';

function ChartOptions(data, options, height = 1000, width = 1618) {
  const navigate = useNavigate();
  const chartRef = useRef();
  if (!data?.data?.datasets) {
    return <></>;
  }

  return (
    <Bar
      ref={chartRef}
      data={data.data}
      options={{
        plugins: {
          tooltip: chartJsDefaultTooltip(),
          legend: {
            position: "bottom",
            onHover: function (e, chartElement) {
              e.native.target.style.cursor = 'pointer';
            },
            onClick: (evt, legendItem, legend) => {
              const index = legendItem.datasetIndex;
              const ci = legend.chart;
              let link = data.data.datasets[index].link;
              if (link) {
                navigate(link);
              }
            }
          }
        },
        scales: {
          x: {
            grid: {
              color: rgbaColor(getColor('black'), 0.1),
            },
          },
          y: {
            grid: {
              color: rgbaColor(getColor('black'), 0.1),
              drawBorder: true
            }
          }
        },
        onHover: function (e, chartElement) {
          if (chartElement.length > 0) {
            let ind = chartElement[0].datasetIndex;
            let link = data.data.datasets[ind].link;
            if (link) {
              chartRef.current.canvas.style.cursor = 'pointer';
            }
          } else {
            chartRef.current.canvas.style.cursor = 'default';
          }
        },
        onClick: function (evt, element) {
          if (element.length > 0) {
            let ind = element[0].datasetIndex;
            let link = data.data.datasets[ind].link;
            if (link) {
              navigate(link);
            }
          }
        },
      }
      }
      height={height}
      width={width}
    />
  );
}


const ChartBar = () => {
  const [loading, setLoading] = useState(true);
  const [options, setOptions] = useState({
    plugins: {
      tooltip: chartJsDefaultTooltip()
    },
    scales: {
      x: {
        grid: {
          color: rgbaColor(getColor('black'), 0.1)
        }
      },
      y: {
        grid: {
          color: rgbaColor(getColor('black'), 0.1),
          drawBorder: true
        }
      }
    }
  });

  const [someData, setSomeData] = useState();

  const [data, setData] = useState(null);

  // Categorize inbound lead data
  const categorizeInboundLeads = async (inboundLeads, activeLeads) => {
    let totalInboundLeads = inboundLeads;
    let totalActiveLeads = activeLeads;

    let newData = {
      labels: [''],
      datasets: [
        {
          label: `Active Inbound Leads: ${totalInboundLeads}`,
          data: [totalInboundLeads],
          backgroundColor: [
            rgbaColor(getColor('primary'), 0.2),
          ],
          borderColor: [
            getColor('primary'),
          ],
          borderWidth: 1,
          link: '/inbound-leads'
        },
        {
          label: `Active Cases: ${totalActiveLeads}`,
          data: [totalActiveLeads],
          backgroundColor: [
            rgbaColor(getColor('success'), 0.2),
          ],
          borderColor: [
            getColor('success'),
          ],
          borderWidth: 1,
          link: '/claimant-folders'
        },
      ]
    }
    setData(newData)
  };


  // Get inbound and active lead data from API
  const getLeadData = async () => {
    setLoading(true);
    try {
      let inboundLeadResult = await claimantAPI.searchClaimantCount({
        active_claimant: true,
        active_case: false
      });

      let activeLeadResult = await claimantAPI.searchClaimantCount({
        active_claimant: true,
        active_case: true
      });

      let totalRecords = inboundLeadResult.data + activeLeadResult.data;
      let inboundLeads = inboundLeadResult?.data || 0;
      let activeLeads = activeLeadResult?.data || 0;

      categorizeInboundLeads(inboundLeads, activeLeads, totalRecords);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getLeadData();
  }, [])

  useEffect(() => {
    if (data?.datasets && data?.datasets.length > 0) {
      setLoading(false);
    }
  }, [data])


  return (
    <Card>
      {/* <Card.Header>
        <Card.Title tag="h5" className="mb-0">
          Active Claimant Metrics
        </Card.Title>
      </Card.Header> */}
      <Card.Body>
        {
          loading ?
            <p>Loading...</p> :
            data?.datasets && data?.datasets.length > 0 &&
            <ChartOptions data={data} options={options} />
        }
      </Card.Body>
    </Card>
  );
};

export default ChartBar;
