import React, { useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
let { accountAPI } = require('utils/api/fulcrum-api');
import { Col, Row, Card } from 'react-bootstrap';
import AccountProfileCard from 'components/pages/account/AccountProfileCard';
import AccountProfileTabs from 'components/pages/account/AccountProfileTabs';
import { toast } from 'react-toastify';

const UserProfile = () => {
	let { accountId } = useParams();
	const [loading, setLoading] = useState(true);
	const [account, setAccount] = useState({});
	const navigate = useNavigate();

	// Get account details
	const getAccountDetails = async () => {
		try {
			let result = await accountAPI.getAccount(accountId);
			if (!result?.data) {
				throw new Error('No user data returned');
			}
			setAccount(result.data);
			setLoading(false);
		} catch (error) {
			console.error(error);
			navigate('/admin/account-management');
			toast.error('Account not found', { theme: 'colored' });
		}
	};

	useEffect(() => {
		getAccountDetails(accountId);
	}, []);

	if (loading) {
		return (<p>Loading account details...</p>);
	};

	return (
		<div>
			<Row>
				<Col className="mb-3">
					<h5>Account Details</h5>
					<Link to="/admin/account-management" className="float-right">Back to Account Management</Link>

				</Col>
			</Row>
			<Row>
				<Col md={5} xxl={5}>
					<div className="mb-3">
						<AccountProfileCard account={account} setAccount={setAccount} getAccountDetails={getAccountDetails} siteAdminArea={true} />
					</div>

				</Col>
				<Col md={7} xxl={7}>
					<AccountProfileTabs account={account} setAccount={setAccount} siteAdminArea={true} />
				</Col>

			</Row>
		</div>
	);
};

export default UserProfile;