import React from 'react';
import CaseFileRequestTable from './NewCaseFileRequestTable';


const AtlaswareCaseFiles = ({ claimant = {} }) => {
	return (
		<>
			<CaseFileRequestTable
				tableHeaderLabel="Case File Requests"
				claimant={claimant}
				columns={[
					{
						accessor: 'id',
						Header: 'ID',
						headerProps: { className: 'pe-1' },
						cellProps: {
							className: 'py-2'
						},
					},
					{
						accessor: 'claimant_name',
						Header: 'Claimant Name',
						headerProps: { className: 'pe-1' },
						cellProps: {
							className: 'py-2'
						},
					},
					{
						accessor: 'type',
						Header: 'Type',
						headerProps: { className: 'pe-1' },
						cellProps: {
							className: 'py-2'
						},
					},
					{
						accessor: 'status',
						Header: 'Status',
						headerProps: { className: 'pe-1' },
						cellProps: {
							className: 'py-2'
						},
					},
					{
						accessor: 'office_with_jurisdiction',
						Header: 'Office with Jurisdiction',
						headerProps: { className: 'pe-1' },
						cellProps: {
							className: 'py-2'
						},
					},
					{
						accessor: 'primary_rep',
						Header: 'Primary Rep',
						headerProps: { className: 'pe-1' },
						cellProps: {
							className: 'py-2'
						},
					},
					{
						accessor: 'assure_last_checked_at',
						Header: 'Assure Last Checked At',
						headerProps: { className: 'pe-1' },
						cellProps: {
							className: 'py-2'
						},
					},
					{
						accessor: 'assure_last_updated_at',
						Header: 'Last Updated',
						headerProps: { className: 'pe-1' },
						cellProps: {
							className: 'py-2'
						},
					},
					{
						accessor: 'created_at',
						Header: 'Requested',
						headerProps: { className: 'pe-1' },
						cellProps: {
							className: 'py-2'
						},
					},
					{
						accessor: 'menu',
						Header: '',
						headerProps: { className: 'pe-1' },
						cellProps: {
							className: 'py-2'
						},
					}
					// Cell: rowData => {
					// 	const { name, id } = rowData.row.original;
					// 	return <Link to={`/claimant/profile/${id}`}>{name}</Link>;
					// }
				]}
				defaultSearchParams={{
					claimant_id: claimant?.id || null,
				}}
				requiredSearchParams={{
					claimant_id: claimant?.id || null,
				}}
			/>
		</>
	);
};

export default AtlaswareCaseFiles;