import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Card, Dropdown, Row, Col } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import CardDropdown from 'components/common/CardDropdown';
import Flex from 'components/common/Flex';
// import Avatar from 'components/common/Avatar';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import MyFormRepositoryTableHeader from './MyFormRepositoryTableHeader';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import { myFormsAPI } from 'utils/api/fulcrum-api';
import { useEffect } from 'react';
import { removeEmptyFromObj } from 'utils/functions';
import moment from 'moment';
import MyFormEditorModal from './MyFormEditorModal';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import PreviewFormModal from './MyFormViewer/PreviewFormModal';


const columns = [
	{
		accessor: 'title',
		Header: 'Title',
		headerProps: { className: 'pe-1' },
		cellProps: {
			className: 'py-2'
		}
	},
	{
		accessor: 'standard_template',
		Header: 'Standard Template',
		headerProps: { className: 'pe-1' },
		cellProps: {
			className: 'py-2'
		}
	},
	{
		accessor: 'form_type',
		Header: 'Form Type',
		headerProps: { className: 'pe-1' },
		cellProps: {
			className: 'py-2'
		}
	},
	{
		accessor: 'created_at',
		Header: 'Created',
		headerProps: { className: 'pe-1' },
		cellProps: {
			className: 'py-2'
		}
	},
	// {
	// 	accessor: 'updated_at',
	// 	Header: 'Last Updated',
	// 	headerProps: { className: 'pe-1' },
	// 	cellProps: {
	// 		className: 'py-2'
	// 	}
	// },
	{
		accessor: 'actions',
		Header: 'Actions',
		headerProps: { className: 'pe-1' },
		cellProps: {
			className: 'py-2'
		}
	}

];

const MyFormRepositoryTable = ({ defaultSearchParams = {}, requiredSearchParams = {} }) => {
	const navigate = useNavigate();
	const [myFormData, setMyFormData] = useState([]);
	const [defaultNewFormValues, setDefaultNewFormValues] = useState({});
	const [, setFormCount] = useState(0);
	const [searching, setSearching] = useState(false);
	const [lastQuery, setLastQuery] = useState({});
	const [searchParams] = useState({ ...defaultSearchParams, ...requiredSearchParams });
	const [selectedForm, setSelectedForm] = useState({});
	const [formModalState, setFormModalState] = useState(false);
	const [previewModalState, setPreviewModalState] = useState(false);
	const [previewContent, setPreviewContent] = useState('');

	// Delete My Form
	const deleteMyForm = async (id) => {
		Swal.fire({
			title: 'Permanently delete this form?',
			text: "You won't be able to revert this!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Yes, delete it!'
		}).then(async (result) => {
			if (result.isConfirmed) {
				try {
					let result = await myFormsAPI.deleteMyForm(id);
					if (!result?.message) {
						throw new Error('Error deleting form');
					}
					// setMyFormData(myFormData.filter((form) => form.id !== id));
					refreshLastQuery();
					toast.warning(result.message, {
						theme: 'colored'
					});
				} catch (error) {
					console.log(error);
					toast.error(error.message, {
						theme: 'colored'
					});
				}
			}
		});
	};


	const formatData = (data = []) => {
		let adjustedData = data.map(form => {
			return {
				...form,
				title: form?.title ? (form.title.length > 75 ? form.title.slice(0, 75) + '...' : form.title) : '',
				// description: form?.description ? form.description.slice(0, 10)+'...' : '',
				created_at: moment(form.createdAt).format('MM/DD/YYYY hh:mm A'),
				// updated_at: moment(form.updatedAt).format('MM/DD/YYYY hh:mm A'),
				form_type: form?.document_type?.type,
				standard_template: !form?.account_id ? 'Yes' : '',
				actions: (
					<Flex className="justify-content-end">
						<CardDropdown>
							<div className="py-2">
								<Dropdown.Item onClick={() => { editMyForm(form) }}>
									{/* <FontAwesomeIcon icon={'file'} className="me-2" /> */}
									<span>Edit</span>
								</Dropdown.Item>
								<Dropdown.Item onClick={() => { setPreviewContent(form?.content || ''); setPreviewModalState(form) }}>
									<span>Preview</span>
								</Dropdown.Item>
								<Dropdown.Divider />
								{
									form?.account_id &&
									<Dropdown.Item
										onClick={() => deleteMyForm(form.id)}
									>
										<span>Delete</span>
									</Dropdown.Item>
								}
							</div>
						</CardDropdown>
					</Flex>
				)
			}
		});
		setMyFormData(adjustedData);
	};

	const myFormSearchHandler = async (params = {}, saveLastQuery = false) => {
		try {
			params = removeEmptyFromObj(params);
			let myFormSearchResult = await myFormsAPI.searchMyForms({ ...params, ...requiredSearchParams });
			if (!myFormSearchResult?.data?.rows) {
				throw new Error('No form data found');
			}

			formatData(myFormSearchResult.data.rows);
			setFormCount(myFormSearchResult.data.count);
			setSearching(false);

			if (saveLastQuery) {
				setLastQuery(params);
			}
		} catch (error) {
			console.log(error);
		}
	};

	// Edit My Form
	const editMyForm = async (formInfo) => {
		if (!formInfo?.account_id && false) {
			Swal.fire({
				title: 'Editing a standard form template.',
				text: 'Duplicate system template to edit it.',
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Duplicate'
			}).then(async (result) => {
				if (result.isConfirmed) {
					setDefaultNewFormValues({
						title: '',
						description: formInfo?.title || `Duplicated Form - ${Date.now()}}`,
						form_type_id: formInfo?.form_type_id || "OTHER",
						content: formInfo?.content || '',
					});
					setFormModalState(true);
				}
			});

		} else {
			navigate(formInfo?.id ? `/my-forms/${formInfo.id}` : '/my-forms');
		}
	};


	const newSearch = async (params) => {
		setSearching(true);
		let adjustedSearchParams = {
			...params,
			...requiredSearchParams
		};
		myFormSearchHandler(adjustedSearchParams);
	};

	// Refresh last query
	const refreshLastQuery = async () => {
		myFormSearchHandler(lastQuery);
	};


	// Handle form selection
	const handleFormSelection = (form) => {
		setSelectedForm(form);
		setFormModalState(true);
	};

	useEffect(() => {
		newSearch({ ...defaultSearchParams });
	}, []);

	// Apply search filters
	useEffect(() => { newSearch(searchParams) }, [searchParams]);


	return (
		<>
			<AdvanceTableWrapper
				columns={columns}
				data={myFormData}
				selection
				sortable
				pagination
				perPage={10}
			>
				<Card className="mb-3">
					<Card.Header>
						<MyFormRepositoryTableHeader
							table
							setFormModalState={setFormModalState}
							refreshLastQuery={refreshLastQuery}
						/>
						<Row className="flex-end-center my-3">
							<Col xs="auto" sm={6} lg={4}>
								<AdvanceTableSearchBox table />
							</Col>
						</Row>
					</Card.Header>
					<Card.Body className="p-0">
						{
							searching ?
								<p>Searching forms...</p>
								:
								<AdvanceTable
									table
									headerClassName="bg-200 text-900 text-nowrap align-middle"
									rowClassName="align-middle white-space-nowrap"
									tableProps={{
										size: 'sm',
										striped: true,
										className: 'fs--1 mb-0 overflow-auto'
									}}
								/>
						}

					</Card.Body>
					<Card.Footer>
						<AdvanceTablePagination table />
					</Card.Footer>
				</Card>
			</AdvanceTableWrapper>


			<MyFormEditorModal
				modalState={formModalState}
				setModalState={setFormModalState}
				myFormData={myFormData}
				selectedForm={selectedForm}
				setSelectedForm={setSelectedForm}
				setMyFormData={setMyFormData}
				refreshParent={refreshLastQuery}
				defaultNewFormValues={defaultNewFormValues}
				setDefaultNewFormValues={setDefaultNewFormValues}
			/>

			<PreviewFormModal
				content={previewContent}
				setContent={setPreviewContent}
				modalState={previewModalState}
				setModalState={setPreviewModalState}
				withRender={false}
			/>
		</>
	);
};

MyFormRepositoryTable.propTypes = {
	defaultSearchParams: PropTypes.object,
	requiredSearchParams: PropTypes.object
}

export default MyFormRepositoryTable;