import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Button, Modal, Alert } from 'react-bootstrap';
import { toast } from 'react-toastify';
import GenericFormView from 'components/pages/documents/my-forms/MyFormViewer/GenericFormView';
import { myFormsAPI, userAPI } from 'utils/api/fulcrum-api';
import { getClientDigitalInformation } from 'utils/ipify';

const ExecuteMOU = ({ user, setUser = () => { }, modalState, setModalState = () => { }, refreshParent = () => { } } = {}) => {
	const [renderedMOU, setRenderedMOU] = useState('');
	const [loading, setLoading] = useState(false);
	const [submitting, setSubmitting] = useState(false);
	const [errors, setErrors] = useState([]);
	const [electronicSignatureConsent, setElectronicSignatureConsent] = useState(false);

	// Get rendered agreement html
	const getRenderedHtml = async () => {
		setLoading(true);
		try {
			let myFormResponse = await myFormsAPI.getMyForm(52);
			if (!myFormResponse?.data?.content) {
				throw new Error('No agreement html');
			}

			let mergedContentResult = await myFormsAPI.getRenderedMyFormContent(myFormResponse?.data?.content, { user_id: user.id, account_id: user?.account_id || null });
			if (!mergedContentResult?.data?.adjustedContent) {
				throw new Error('No merged agreement html');
			}

			setRenderedMOU(mergedContentResult?.data?.adjustedContent || '');
		} catch (error) {
			toast.error('Error getting Memorandum of Understanding html', { theme: 'colored' });
			console.log(error);
			handleClose();
		}
		setLoading(false);
	};

	const handleUpload = async (event) => {
		if (event) { event.preventDefault(); }
		return;
	}

	// Handle Submit
	const handleSubmit = async () => {
		setSubmitting(true);
		setErrors([]);
		let newErrors = [];

		try {
			// Get rendered agreement html
			let adjustedContent = document.querySelector('#my-form-response-container').innerHTML;

			if (!adjustedContent) {
				throw new Error('No agreement found');
			}

			if (!electronicSignatureConsent) {
				newErrors.push('Please consent to the electronic signature');
			}

			// Make sure all signature fields are filled out
			let signatureFields = document.querySelectorAll('.signature');
			let signatureFieldsFilled = true;

			signatureFields.forEach((field) => {
				if (!field?.src || field.src.includes('/signature-placeholder.png')) {
					signatureFieldsFilled = false;
				}
			});

			if (!signatureFieldsFilled) {
				newErrors.push('Please sign all signature fields');
			}

			if (newErrors.length > 0) {
				setErrors(newErrors);
				setSubmitting(false);
				return;
			}

			let clientDeviceInformation = await getClientDigitalInformation();

			let data = {
				clientDeviceInformation,
				electronicSignatureConsent: electronicSignatureConsent,
				content: adjustedContent,
			}

			// Submit MOU 
			let myFormResponseResult = await userAPI.executeMOU(user.id, data);
			if (!myFormResponseResult?.data?.message) {
				throw new Error('No myFormResponseResult');
			}
			toast.success('Agreement executed', { theme: 'colored' });
			console.log("My Form Response", myFormResponseResult);

			refreshParent();
			handleClose();
		} catch (error) {
			console.log(error);
			toast.error('Error executing Memorandum of Understanding', { theme: 'colored' });
		}
		setSubmitting(false);
	};

	// Handle Close
	const handleClose = () => {
		setModalState(false);
		setRenderedMOU('');
		setSubmitting(false);
	};

	// Handle Show
	const handleShow = () => {
		getRenderedHtml();
	};

	return (<Modal
		show={modalState}
		size="lg"
		onHide={handleClose}
		onShow={handleShow}
		className='agreement'
	>
		<Modal.Header closeButton>
			<Modal.Title>Execute Memorandum of Understanding</Modal.Title>
		</Modal.Header>
		<Modal.Body>
			<Form onSubmit={handleSubmit}>
				<p>Please review and sign the Memorandum of Understanding below.</p>
				{errors.length > 0 && <Alert variant="danger">
					<ul>
						{
							errors.map((error, index) => {
								return <li key={index}>{error}</li>
							})
						}
					</ul>
				</Alert>}

				<Form.Check
					type="checkbox"
					className="text-warning"
					id="electronicSignatureConsent"
					label="I agree to the terms of the Memorandum of Understanding and consent to the electronic execution of this agreement."
					checked={electronicSignatureConsent}
					onChange={(e) => setElectronicSignatureConsent(e.target.checked)}
					disabled={submitting}
					required
				/>

				{loading ?
					<div className="text-center">
						<div className="spinner-border text-primary" role="status">
							<span className="sr-only">Loading...</span>
						</div>
					</div>
					:
					<div className="border p-2" style={{ overflowY: "auto", backgroundColor: '#FFFFFF', color: '#000000', height: "500px" }}>
						{submitting ?
							<div className="text-center">
								<div className="spinner-border text-primary" role="status">
									<span className="sr-only">Submitting Memorandum of Understanding...</span>
								</div>
							</div>
							:
							<GenericFormView
								content={renderedMOU}
							/>}
					</div>}
			</Form>
		</Modal.Body>
		<Modal.Footer>
			{!submitting && <Button variant="outline-secondary" onClick={handleClose}>
				Close
			</Button>} &nbsp;
			<Button variant="primary" onClick={handleSubmit} disabled={submitting}>
				{submitting ? 'Submitting...' : 'Submit'}
			</Button>
		</Modal.Footer>
	</Modal>);
};

ExecuteMOU.propTypes = {
	user: PropTypes.object,
	setUser: PropTypes.func,
	modalState: PropTypes.bool,
	setModalState: PropTypes.func,
	refreshParent: PropTypes.func,
};

export default ExecuteMOU;