import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal, Form, Button, Row, Col, Alert, InputGroup } from 'react-bootstrap';
import AppContext from 'context/Context';
import { leadVendorAPI } from 'utils/api/fulcrum-api';
import { toast } from 'react-toastify';
import IconAlert from 'components/common/IconAlert';
import Swal from 'sweetalert2';
import auth from 'utils/auth';

const LeadVendorDetails = ({ account_id = auth.getProfile().data.account_id, selectedLeadVendor, setSelectedLeadVendor = () => { }, modalState, setModalState = () => { }, refreshParent = () => { } }) => {
	const { config: { isDark } } = useContext(AppContext);
	const [sendingWelcomeEmail, setSendingWelcomeEmail] = useState(false);
	let defaultFormData = {
		account_id,
		entity_id: null,
		is_active: true,
		lead_vendor_name: '',
		email: '',
		phone: '',
		fax: '',
		primary_contact_name: '',
		address: '',
		api_key: '',
		send_welcome_email: false,
		permissions: {
			GET_CLAIMANTS: false,
			CREATE_CLAIMANTS: true,
			can_delete_leads: false,
			can_update_leads: false,
			calls_per_minute: 60,
		}
	};
	const [formData, setFormData] = useState(defaultFormData);
	const [previousFormData, setPreviousFormData] = useState(defaultFormData);
	const [showApiKey, setShowApiKey] = useState(false);
	const [submitting, setSubmitting] = useState(false);


	// Handle form change
	const handleChange = (e, saveChange = false) => {
		let { name, value, type, checked } = e.target;
		let additionalData = {};

		if (['GET_CLAIMANTS', 'CREATE_CLAIMANTS', 'can_delete_leads', 'can_update_leads', 'calls_per_minute'].includes(name)) {
			value = { ...formData.permissions, [name]: type === 'checkbox' ? checked : value };
			name = 'permissions';
		}

		setFormData((prev) => ({
			...prev,
			...additionalData,
			[name]: value
		}));

		if (saveChange) {
			saveOnChange(e);
		}
	};

	// Send welcome email
	const sendWelcomeEmail = async () => {
		await Swal.fire({
			title: 'Send Welcome Email?',
			text: "Are you sure you want to send a welcome email to this lead vendor?",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Yes, send it!'
		}).then(async (result) => {
			if (result.isConfirmed) {
				setSendingWelcomeEmail(true);
				try {
					let sentEmailResult = await leadVendorAPI.sendWelcomeEmail(account_id, selectedLeadVendor.id, {});
					if (!sentEmailResult?.data) {
						throw new Error(sentEmailResult?.error || 'Error sending welcome email');
					}
					toast.success('Welcome email sent successfully', { theme: 'colored' });
				} catch (error) {
					console.error(error);
					toast.error('Error sending welcome email', { theme: 'colored' });
				}
				setSendingWelcomeEmail(false);
			}
		});
	};

	// Search Lead Vendor
	const searchLeadVendors = async (leadVendorId) => {
		try {
			let result = await leadVendorAPI.getLeadVendor(account_id, leadVendorId);
			if (!result?.data) {
				throw new Error('No lead vendor data found');
			}


			let defaultFormData = {
				account_id,
				entity_id: result.data?.entity_id || null,
				is_active: "is_active" in result.data ? result.data.is_active : true,
				lead_vendor_name: result.data?.lead_vendor_name || '',
				email: result.data?.email || '',
				phone: result.data?.phone || '',
				fax: result.data?.fax || '',
				primary_contact_name: result.data?.primary_contact_name || '',
				address: result.data?.address || '',
				api_key: result.data?.api_key || '',
				permissions: JSON.parse(result.data?.permissions || '{}')
			};
			setFormData(defaultFormData);
			setPreviousFormData(defaultFormData);
		} catch (error) {
			console.log(error);
			toast.error(error, {
				theme: 'colored'
			});
			handleClose();
		}
	};

	// Update Lead Vendor
	const handleUpdate = async (leadVendorId, data) => {
		setSubmitting(true);
		try {
			let result = await leadVendorAPI.updateLeadVendor(account_id, leadVendorId, data);
			if (!result?.data) {
				throw new Error('No lead vendor data found');
			}
			toast.success("Lead Vendor updated!", {
				theme: 'colored'
			});

			setFormData({ ...formData, ...data, permissions: JSON.parse(result?.data?.permissions || '{}') });
			setPreviousFormData({ ...formData, ...data, permissions: JSON.parse(result.data?.permissions || '{}') });
			refreshParent();
		} catch (error) {
			console.log(error);
			toast.error(error, {
				theme: 'colored'
			});
		}
		setSubmitting(false);
	};

	// Create Lead Vendor
	const handleCreate = async (data) => {
		setSubmitting(true);
		console.log(JSON.stringify(data));
		try {
			let result = await leadVendorAPI.createLeadVendor(account_id, {
				...data
			});
			if (!result?.data) {
				throw new Error('No lead vendor data found');
			}
			toast.success("Custom field created!", {
				theme: 'colored'
			});

			setFormData(result.data);
			refreshParent();
			handleClose();
		} catch (error) {
			console.log(error);
			toast.error(error, {
				theme: 'colored'
			});
		}
		setSubmitting(false);
	};

	// Generate new API Key
	const generateNewAPIKey = async () => {
		Swal.fire({
			title: 'Are you sure?',
			text: "By changing this API key, any previous API key the lead vendor was using will no longer work. Would you like to continue?",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Yes, change it!'
		}).then(async (result) => {
			if (result.isConfirmed) {
				try {
					let result = await leadVendorAPI.generateApiKey(account_id, selectedLeadVendor.id);
					if (!result?.data) {
						throw new Error('No lead vendor data found');
					}
					toast.success("API Key generated!", {
						theme: 'colored'
					});

					setFormData((prev) => ({
						...prev,
						api_key: result.data.api_key
					}));
				} catch (error) {
					console.log(error);
					toast.error(error, {
						theme: 'colored'
					});
				}
			}
		})
	};

	// Save on change
	const saveOnChange = (e) => {
		let { name, value, type, checked } = e.target;
		let additionalData = {};

		if (['GET_CLAIMANTS', 'CREATE_CLAIMANTS', 'can_delete_leads', 'can_update_leads', 'calls_per_minute'].includes(name)) {
			value = {
				...formData.permissions,
				[name]: type === 'checkbox' ? checked : value
			};
			name = 'permissions';
		} else if (name === 'is_active') {
			value = checked;
		}

		console.log(name, value);


		// Update database record
		if (value !== previousFormData[name] && selectedLeadVendor?.id) {
			handleUpdate(selectedLeadVendor.id, { [name]: name === 'permissions' ? JSON.stringify(value) : value });
		}

		setFormData((prev) => ({
			...prev,
			...additionalData,
			[name]: value
		}));

	};

	// Handle close
	const handleClose = () => {
		setSelectedLeadVendor({});
		setModalState(false);
	};

	// Handle Show
	const handleShow = () => {
		let permissions = selectedLeadVendor?.permissions ? JSON.parse(selectedLeadVendor.permissions) : {
			GET_CLAIMANTS: false,
			CREATE_CLAIMANTS: true,
			// can_delete_leads: false,
			UPDATE_CLAIMANTS: false,
		};
		let defaultFormData = {
			account_id,
			entity_id: selectedLeadVendor?.entity_id || null,
			is_active: "is_active" in selectedLeadVendor ? selectedLeadVendor.is_active : true,
			lead_vendor_name: selectedLeadVendor?.lead_vendor_name || '',
			email: selectedLeadVendor?.email || '',
			phone: selectedLeadVendor?.phone || '',
			fax: selectedLeadVendor?.fax || '',
			primary_contact_name: selectedLeadVendor?.primary_contact_name || '',
			address: selectedLeadVendor?.address || '',
			api_key: selectedLeadVendor?.api_key || '',
			// permissions: selectedLeadVendor?.permissions || '',
			settings: selectedLeadVendor?.settings || '',
			send_welcome_email: false,
			permissions
		};
		setFormData(defaultFormData);
		setPreviousFormData(defaultFormData);
	};

	useEffect(() => {
		if (selectedLeadVendor?.id) {
			searchLeadVendors(selectedLeadVendor.id);
		}
	}, [selectedLeadVendor]);


	return (
		<Modal
			show={modalState}
			onHide={handleClose}
			onShow={handleShow}
			contentClassName="border"
			size="lg"
			backdrop="static"
		>
			<Form onSubmit={e => {
				e.preventDefault();
				if (selectedLeadVendor?.id) {
					handleUpdate(selectedLeadVendor.id, formData);
				} else {
					handleCreate(formData);
				}
			}}>
				<Modal.Header
					closeButton
					closeVariant={isDark ? 'white' : undefined}
					className="bg-light px-card border-bottom-0"
				>
					<Modal.Title as="h5">Lead Vendor</Modal.Title>
				</Modal.Header>
				<Modal.Body className="p-card">
					{
						!selectedLeadVendor?.id && (
							<IconAlert variant={'info'}>
								<p>Invite a new lead vendor to send leads to your Fulcrum account. This will send the newly create lead vendor an email with instructions to integrate with the API.</p>
							</IconAlert>
						)
					}

					<Row>
						<Col sm={8}>
							<Form.Group className="mb-3" controlId={`lead_vendor_name_group`}>
								<Form.Label className="fs-0">Lead Vendor</Form.Label>
								<Form.Control
									size='sm'
									name={'lead_vendor_name'}
									type="text"
									value={formData.lead_vendor_name}
									onChange={handleChange}
									onBlur={saveOnChange}
									required
								/>
							</Form.Group>
						</Col>
						<Col sm={4}>
							<Form.Group controlId="is_active_group">
								<Form.Label>Active</Form.Label>
								<Form.Check
									type="switch"
									id="is_active"
									name="is_active"
									label="Active"
									checked={formData.is_active}
									onChange={e => handleChange(e, true)}
								/>
							</Form.Group>
						</Col>
					</Row>



					<Form.Group className="mb-3" controlId={`email_group`}>
						<Form.Label className="fs-0">Email</Form.Label>
						<Form.Control
							size='sm'
							name={'email'}
							type="email"
							value={formData.email}
							onChange={handleChange}
							onBlur={saveOnChange}
							required
						/>
					</Form.Group>

					<Form.Group className="mb-3" controlId={`primary_contact_name_group`}>
						<Form.Label className="fs-0">Primary Contact</Form.Label>
						<Form.Control
							size='sm'
							name={'primary_contact_name'}
							type="text"
							value={formData.primary_contact_name}
							onChange={handleChange}
							onBlur={saveOnChange}
						/>
					</Form.Group>

					<Row>
						<Col>
							<Form.Group className="mb-3" controlId={`phone_group`}>
								<Form.Label className="fs-0">Phone</Form.Label>
								<Form.Control
									size='sm'
									name={'phone'}
									type="text"
									value={formData.phone}
									onChange={handleChange}
									onBlur={saveOnChange}
								/>
							</Form.Group>
						</Col>
						<Col>
							<Form.Group className="mb-3" controlId={`fax_group`}>
								<Form.Label className="fs-0">Fax</Form.Label>
								<Form.Control
									size='sm'
									name={'fax'}
									type="text"
									value={formData.fax}
									onChange={handleChange}
									onBlur={saveOnChange}
								/>
							</Form.Group>
						</Col>
					</Row>


					<Form.Group className="mb-3" controlId={`address_group`}>
						<Form.Label className="fs-0">Address</Form.Label>
						<Form.Control
							size='sm'
							name={'address'}
							type="text"
							value={formData.address}
							onChange={handleChange}
							onBlur={saveOnChange}
						/>
					</Form.Group>

					{
						selectedLeadVendor?.id
							? (
								<>
									<Form.Group className="mb-3" controlId={`api_key_group`}>
										<Form.Label className="fs-0">API Key</Form.Label>
										<Button variant="link" onClick={() => {
											setShowApiKey(!showApiKey);
										}}>{!showApiKey ? 'Show' : 'Hide'} API Key</Button>
										<Button variant="link" onClick={() => {
											generateNewAPIKey();
										}}>Generate New API Key</Button>
									</Form.Group>
									{showApiKey && (
										<Alert variant="info" className="mt-2">
											<p>Only share this API key with the intended lead vendor.</p>
											<Form.Control
												size='sm' name={'api_key'} type="text" value={formData.api_key} disabled />
										</Alert>
									)}
								</>
							)
							:
							<IconAlert variant={'warning'}>
								<p>An API key will be generated after you create the lead vendor.</p>
							</IconAlert>
					}

					<Form.Group className="mb-3" controlId={`permissions_group`}>
						<Form.Label className="fs-0">Permissions</Form.Label>
						<hr />
						{/* <Form.Check
							type="switch"
							id="GET_CLAIMANTS"
							name="GET_CLAIMANTS"
							label="Can View Leads"
							checked={formData.permissions.GET_CLAIMANTS}
							onChange={e => handleChange(e, true)}
						/> */}
						<Form.Check
							type="switch"
							id="CREATE_CLAIMANTS"
							name="CREATE_CLAIMANTS"
							label="Can Post Leads"
							checked={formData.permissions.CREATE_CLAIMANTS}
							onChange={e => handleChange(e, true)}
						/>
						{/* <Form.Check
							type="switch"
							id="can_update_leads"
							name="can_update_leads"
							label="Can Update Leads"
							checked={formData.permissions.can_update_leads}
							onChange={e => handleChange(e, true)}
						/>
						<Form.Check
							type="switch"
							id="can_delete_leads"
							name="can_delete_leads"
							label="Can Delete Leads"
							checked={formData.permissions.can_delete_leads}
							onChange={e => handleChange(e, true)}
						/> */}
						{/* <InputGroup className="mb-3">
							<InputGroup.Text id="basic-addon1">Calls per minute</InputGroup.Text>
							<Form.Control
								placeholder="calls_per_minute"
								aria-label="calls_per_minute"
								aria-describedby="basic-addon1"
								name="calls_per_minute"
								value={formData.permissions.calls_per_minute}
								onChange={handleChange}
								onBlur={e => saveOnChange(e)}
							/>
						</InputGroup> */}
					</Form.Group>

					{
						!selectedLeadVendor?.id ?
							<Alert variant="info">
								<Form.Group className="mb-3" controlId={`permissions_group`}>
									<Form.Label className="fs-0">Send welcome email?</Form.Label>
									<p className="">Send a welcome email to the lead vendor containing information on how to integrate via API?</p>
									<Form.Check
										type="switch"
										id="send_welcome_email"
										name="send_welcome_email"
										label="Send welcome email?"
										checked={formData.permissions.send_welcome_email}
										onChange={e => handleChange(e, true)}
									/>
								</Form.Group>
							</Alert>
							:
							<Alert variant="info">
								<p>Send lead vendor an informational email on how to use the API.</p>
								{
									!formData.is_active ?
										<IconAlert variant={'danger'}>
											<p className="text-danger">Cannot send informational email to an inactive lead vendor</p>
										</IconAlert>
										:
										<Button variant="primary" onClick={() => {
											sendWelcomeEmail();
										}} disabled={sendingWelcomeEmail}>Send Welcome Email</Button>
								}
							</Alert>
					}

				</Modal.Body>
				<Modal.Footer className="bg-light px-card border-top-0">
					<Button variant="secondary" onClick={handleClose}>
						Close
					</Button>

					{
						selectedLeadVendor?.id ?
							<>
								{/* {
									JSON.stringify(previousFormData) !== JSON.stringify(formData) &&
									<Button variant="success" type="submit" disabled={submitting}>
										{submitting ? (
											<>
												{
													selectedLeadVendor?.id ? "Updating..." : "Creating..."
												}
											</>
										) :
											<>
												{
													selectedLeadVendor?.id ? (
														"Update Lead Vendor"
													) : "Create Lead Vendor"
												}
											</>}
									</Button>
								} */}
							</>
							:
							<>
								<Button variant="success" type="submit" disabled={submitting}>
									{submitting ? (
										<>
											{
												selectedLeadVendor?.id ? "Updating..." : "Creating..."
											}
										</>
									) :
										<>
											{
												selectedLeadVendor?.id ? (
													"Update Lead Vendor"
												) : "Create Lead Vendor"
											}
										</>}
								</Button>
							</>
					}


				</Modal.Footer>
			</Form>
		</Modal>
	);
};

LeadVendorDetails.propTypes = {
	selectedLeadVendor: PropTypes.object,
	setSelectedLeadVendor: PropTypes.func,
	modalState: PropTypes.bool,
	setModalState: PropTypes.func,
	refreshParent: PropTypes.func
}

export default LeadVendorDetails;