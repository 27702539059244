import React, { useContext, useState, useRef } from 'react';
import { Button, Form, Modal, Table, Alert, Spinner, ListGroup, Dropdown, Tabs, Tab } from 'react-bootstrap';
// import { v4 as uuid } from 'uuid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faSync } from '@fortawesome/free-solid-svg-icons'
import AppContext from 'context/Context';
import { documentAPI, inboxOutboxAPI } from 'utils/api/fulcrum-api';
import { sleep } from 'utils/functions'
import ClaimantQuickFind from 'components/claimants/find-a-claimant/ClaimantQuickFind';
import { useDropzone } from 'react-dropzone';
import Flex from 'components/common/Flex';
import cloudUpload from 'assets/img/icons/cloud-upload.svg';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import moment from 'moment';
import UnknownMessageTool from './inbox/UnknownMessageTool';
import { useEffect } from 'react';
import CardDropdown from 'components/common/CardDropdown';
import PDFViewerModal from 'components/app/pdfjs-viewer/PDFViewerModal';


const InboxOutboxDetails = ({
	selectedCorrespondence,
	setSelectedCorrespondence = () => { },
	modalState,
	setModalState,
	refreshParent = () => { },
}) => {
	const {
		config: { isDark }
	} = useContext(AppContext);

	let defaultFormData = {};
	const [loading, setLoading] = useState(true);
	const [formData, setFormData] = useState(defaultFormData);
	const [submitting, setSubmitting] = useState(false);
	const [errors, setErrors] = useState([]);
	const [inboxOutboxItem, setInboxOutboxItem] = useState({});
	const [uknownMessageToolModalState, setUknownMessageToolModalState] = useState(false);
	const [pdfViewerModalState, setPdfViewerModalState] = useState(false);
	const [selectedFileId, setSelectedFileId] = useState(null);

	const handleChange = ({ target }) => {
		let { name, value } = target;
		let additionalValues = {};

		setFormData({ ...formData, ...additionalValues, [name]: value });
	};


	// Get inbox outbox item
	const getInboxOutboxItem = async () => {
		try {
			let result = await inboxOutboxAPI.getInboxOutbox(selectedCorrespondence?.account_id || null, selectedCorrespondence.id);
			if (!result?.data) {
				throw new Error('No data returned.');
			}
			setInboxOutboxItem(result.data);

		} catch (error) {
			console.error(error);
			handleClose();
			toast.error('Error getting correspondence.', { theme: 'colored' });
		}
		setLoading(false);
	};

	// Handle claimant unnasociation
	const handleUnassociateClaimant = async (inboxItem = {}) => {
		let { id, claimant_id, account_id: accountId } = inboxItem || {};
		if (!id || !claimant_id) {
			alert("Missing inbox item or claimant id.");
			return;
		}
		Swal.fire({
			title: 'Unassociate Claimant?',
			text: "Are you sure you would like to unassociate this claimant from this correspondence?",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#d33',
			cancelButtonColor: '#3085d6',
			confirmButtonText: "Yes, unassociate it!"
		}).then(async (result) => {
			if (result.isConfirmed) {
				try {
					let result = await inboxOutboxAPI.updateInboxOutbox(accountId, id, { claimant_id: null });
					if (!result?.data) {
						throw new Error("Unable to unassociate claimant from correspondence.");
					}

					refreshDetails();
					toast.success("Successfully unassociated claimant from correspondence.", { theme: 'colored' });
				} catch (err) {
					toast.error("Unable to unnasociate the claimant from the inbox item.", { theme: 'colored' });
				}
			}
		});
	}

	// Download Document
	const downloadDocument = async (document) => {
		try {
			await documentAPI.downloadDocuments(selectedCorrespondence?.account_id || null, [document.file_id], (result) => {
				if (result?.error) {
					throw new Error(result.error);
				}
			});
		} catch (error) {
			toast.error("Unable to download document", { theme: 'colored' });
			console.log(error);
		}
	};

	// Handle Show
	const handleShow = () => {
		setLoading(true);
		setSubmitting(false);
		setErrors([]);
		if (!selectedCorrespondence?.id) {
			toast.error('Please select a correspondence.', { theme: 'colored' });
			handleClose();
		} else {
			getInboxOutboxItem();
		}
		setFormData(defaultFormData);
	};

	// Handle Close
	const handleClose = () => {
		setLoading(true);
		setSubmitting(false);
		setErrors([]);
		setSelectedCorrespondence(null);
		setFormData(defaultFormData);
		setModalState(false);
	};


	// Refresh
	const refreshDetails = (withParentRefresh = true) => {
		getInboxOutboxItem();
		if (withParentRefresh) {
			refreshParent();
		}
	};

	useEffect(() => {

	}, []);


	return (
		<Modal
			show={modalState}
			onHide={handleClose}
			onShow={handleShow}
			backdrop="static"
			size="lg"
			contentClassName="border"
		>
			<Modal.Header
				closeButton
				closeVariant={isDark ? 'white' : undefined}
				className="bg-light px-card border-bottom-0"
			>
				<Modal.Title as="h5">Correspondence Message</Modal.Title>
			</Modal.Header>
			<Modal.Body className="p-card">
				{
					loading
						?
						<Spinner animation="border" role="status">
							<span className="sr-only">Loading...</span>
						</Spinner>
						:
						<div>
							<h5 className="d-inline">{inboxOutboxItem?.direction || 'Unknowm'} {inboxOutboxItem?.mode_of_transmission || ""} Correspondence. ({inboxOutboxItem?.id})</h5>
							{/* Refresh Button */}
							<Button
								variant="link"
								className="p-0 float-end"
								onClick={() => refreshDetails(false)}
							>
								<FontAwesomeIcon icon={faSync} />
							</Button>
							<hr />
							<ListGroup variant="flush">
								<ListGroup.Item>Sender: {inboxOutboxItem?.sender || 'Unknowm'}</ListGroup.Item>
								<ListGroup.Item>Recipient: {inboxOutboxItem?.recipient || (inboxOutboxItem?.direction === "Inbound" && inboxOutboxItem?.account?.business_name) || 'Unknown'}</ListGroup.Item>
								<ListGroup.Item>Claimant: {
									inboxOutboxItem?.claimant_id
										?
										(
											<>
												{inboxOutboxItem?.claimant?.first_name} {inboxOutboxItem?.claimant?.last_name} <Button variant="link" className="text-danger" onClick={() => handleUnassociateClaimant(inboxOutboxItem)}>Unassociate Claimant</Button>
											</>
										)
										:
										(
											<>
												<Button
													variant="link"
													onClick={() => {
														setUknownMessageToolModalState(true);
													}}
												>
													Associate a Claimant
												</Button>

												<UnknownMessageTool
													modalState={uknownMessageToolModalState}
													setModalState={setUknownMessageToolModalState}
													refreshParent={refreshDetails}
													selectedCorrespondence={selectedCorrespondence}
													setSelectedCorrespondence={setSelectedCorrespondence}
													clearSelectedCorrespondence={false}
												/>
											</>
										)
								}</ListGroup.Item>
								<ListGroup.Item>Status: {inboxOutboxItem?.status ?
									['PENDING_CONTENT_RENDER', 'SENDING'].includes(inboxOutboxItem?.status) ?
										<span>

											<span className=""><Spinner animation="border" size={'sm'} role="status" />Preparing to Send</span>
										</span>
										:
										inboxOutboxItem?.status
									: ''}</ListGroup.Item>
								{
									inboxOutboxItem?.status_reason &&
									(
										<ListGroup.Item>Status Reason: {inboxOutboxItem?.status_reason || ''}</ListGroup.Item>
									)
								}
								<ListGroup.Item>{inboxOutboxItem?.direction === "Inbound" ? "Received" : "Sent"}: {inboxOutboxItem?.createdAt ? moment(inboxOutboxItem.createdAt).format('MM-DD-YYYY hh:mm A') : ''}</ListGroup.Item>
								{
									inboxOutboxItem?.mode_of_transmission === "EMAIL" &&
									(
										<ListGroup.Item>Subject: {inboxOutboxItem?.subject || ''}</ListGroup.Item>
									)
								}
							</ListGroup>

							<Tabs defaultActiveKey="message" id="uncontrolled-tab-example">
								<Tab eventKey="message" title="Message" className='border-bottom border-x p-3'>
									{
										inboxOutboxItem?.content
											?
											<div className="border p-2" style={{ overflowY: "auto", backgroundColor: '#FFFFFF', color: '#000000', height: "500px" }}>
												<div
													dangerouslySetInnerHTML={{ __html: inboxOutboxItem.content }}
												/>
											</div>
											:
											<></>
									}
								</Tab>
								{
									inboxOutboxItem?.my_form_responses && inboxOutboxItem.my_form_responses.length > 0 &&
									<Tab eventKey="forms" title={`${inboxOutboxItem?.my_form_responses ? inboxOutboxItem.my_form_responses.length : '0'} Form(s)`} className='border-bottom border-x p-3'>
										<Tabs defaultActiveKey="form-responses" id="uncontrolled-tab-example">
											<Tab eventKey="form-responses" title="Forms" className='border-bottom border-x p-3'>
												{
													inboxOutboxItem?.my_form_responses
														?
														(
															<>
																<Table>
																	<thead>
																		<tr>
																			<th>Form</th>
																			<th>Type</th>
																			<th>Status</th>
																			<th></th>
																		</tr>
																	</thead>
																	<tbody>
																		{
																			inboxOutboxItem.my_form_responses.map((form, index) => {
																				return (
																					<tr key={`form_${index}`}>
																						<td>{form?.title}</td>
																						<td>{form?.document_type_id}</td>
																						<td>{form?.status}</td>
																						<td>
																							{/* <Button variant="primary" onClick={() => {}}>View</Button> */}
																						</td>
																					</tr>
																				)
																			})
																		}
																	</tbody>
																</Table>
															</>
														)
														:
														<>
														</>
												}
											</Tab>
											<Tab eventKey="Access Links" title="Access Links" className='border-bottom border-x p-3'>
												{
													inboxOutboxItem?.access_verifications &&
													<Table>
														<thead>
															<tr>
																<th>UUID</th>
																<th>Status</th>
																<th>Expiration</th>
																<th></th>
															</tr>
														</thead>
														<tbody>
															{
																inboxOutboxItem.access_verifications.map((access, index) => {
																	return (
																		<tr key={`access_${index}`}>
																			<td>{access?.uuid}</td>
																			<td>{access?.status}</td>
																			<td>{access?.expiration ? <span className={""}>{moment(access?.expiration).format('MM-DD-YYYY hh:mm A')}</span> : ''}</td>
																			<td>
																				<div className="d-flex justify-content-between float-end">
																					<CardDropdown
																						children={(
																							<div className="py-2">
																								{
																									(access?.attempts < 5 || access?.status === 'SENT' || moment().isAfter(access.expiration)) ?
																										<Dropdown.Item onClick={() => { }} className="text-danger">Expire Link</Dropdown.Item>
																										:
																										<Dropdown.Item onClick={() => { }} className="text-danger">Re-Active Link</Dropdown.Item>
																								}
																							</div>
																						)}
																					/>
																				</div>
																			</td>
																		</tr>
																	)
																})
															}
														</tbody>
													</Table>
												}

											</Tab>
										</Tabs>

									</Tab>
								}
								<Tab eventKey="attachments" title={`${inboxOutboxItem?.inbox_outbox_attachments ? inboxOutboxItem.inbox_outbox_attachments.length : '0'} Attachment(s)`} className='border-bottom border-x p-3'>
									{
										inboxOutboxItem?.inbox_outbox_attachments
											?
											(
												<Table>
													<thead>
														<tr>
															<th>Document</th>
															<th>Packet</th>
															<th>Type</th>
															<th></th>
														</tr>
													</thead>
													<tbody>
														{
															inboxOutboxItem.inbox_outbox_attachments.map((attachment, index) => {
																return (
																	<tr key={`attachment_${index}`}>
																		<td>{attachment?.document?.title}</td>
																		<td>{attachment?.document?.document_packet?.title}</td>
																		<td>{attachment?.document?.document_type_id}</td>
																		<td>
																			<div className="d-flex justify-content-between float-end">
																				<CardDropdown
																					children={(
																						<div className="py-2">
																							<Dropdown.Item onClick={() => { downloadDocument(attachment?.document) }} className="">Download</Dropdown.Item>
																							<Dropdown.Item onClick={async () => { setSelectedFileId(attachment?.document?.file_id); await sleep(1000); setPdfViewerModalState(true); }} className="">View</Dropdown.Item>

																						</div>
																					)}
																				/>
																			</div>
																		</td>
																	</tr>
																)
															})
														}
													</tbody>
												</Table>
											)
											:
											<></>
									}
								</Tab>
							</Tabs>

						</div>
				}


				<PDFViewerModal
					modalState={pdfViewerModalState}
					setModalState={setPdfViewerModalState}
					selectedFileId={selectedFileId}
					setSelectedFileId={setSelectedFileId}
				/>
			</Modal.Body>
			<Modal.Footer className="bg-light px-card border-top-0">
			</Modal.Footer>
		</Modal>
	);
};


export default InboxOutboxDetails;