import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ListGroup from 'react-bootstrap/ListGroup';
import IconButton from 'components/common/IconButton';
import { toast } from 'react-toastify';
import { claimantChildrenAPI } from 'utils/api/fulcrum-api';
import ClaimantChildRow from './ClaimantChildRow';
import { Table } from 'react-bootstrap';

const Children = ({ claimant, setClaimant }) => {
	const [loading, setLoading] = useState(true);
	const [children, setChildren] = useState([]);

	// Get the children
	const getClaimantChildren = async () => {
		try {
			let result = await claimantChildrenAPI.getAllClaimantChildren(claimant.id);
			if (!result?.data) {
				throw new Error('No claimant children data returned');
			}
			setLoading(false);
			setChildren(result.data);
		} catch (error) {
			console.error(error);
		}
	};

	// Add a new child
	const addChildren = async () => {
		try {
			let result = await claimantChildrenAPI.createClaimantChild(claimant.id, {
				child: '',
				phone: '',
				address: '',
				start_date: null,
				end_date: null,
				frequency_of_visits: ''
			});
			if (!result?.data) {
				throw new Error('No data returned from server');
			}
			// setChildren([...children, result.data]);
			getClaimantChildren();
			// toast.success('Child added', { theme: 'colored' });
		} catch (error) {
			console.log(error);
			toast.error('Error adding child', { theme: 'colored' });
		};
	};

	useEffect(() => {
		getClaimantChildren();
	}, []);

	if (loading) {
		return <div>Loading...</div>;
	}

	return (
		<div id="claimant-current-employer-container" className="my-3">
			<h5 className="d-inline">Children</h5>
			<IconButton
				className="me-2 mb-1 float-end"
				variant="falcon-default"
				size="sm"
				icon="plus"
				transform="shrink-3"
				onClick={addChildren}
			>
				Add Child
			</IconButton>
			<p>Please list your children and their ages.</p>
			<Table>
				<thead>
					<tr>
						<th style={{ width: "65%" }}>Child</th>
						<th>Age</th>
						<th>

						</th>
					</tr>
				</thead>
				<tbody>
					{
						(children.length > 0)
							?
							children.map((child, index) => (
								<tr key={index}>
									<ClaimantChildRow
										child={child}
										claimant={claimant}
										setClaimant={setClaimant}
										getClaimantChildren={getClaimantChildren}
									/>
								</tr>
							))
							:
							<tr>
								<td colSpan="3">No children</td>
							</tr>
					}

				</tbody>
			</Table>
		</div>
	);
};

Children.propTypes = {
	claimant: PropTypes.object,
	setClaimant: PropTypes.func
}

export default Children;