import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';

const FindAClaimantForm = ({ setParentModalState = () => { }, searchParams, setSearchParams, requiredSearchParams = {} }) => {
	let defaultState = {
		first_name: searchParams.first_name || '',
		last_name: searchParams.last_name || '',
		dob: searchParams.dob || '',
		ssn: searchParams.ssn || '',
		tags: searchParams.tags || '',
		searchTerm: searchParams.searchTerm || '',
		phone: searchParams.phone || '',
		email: searchParams.email || '',
	};
	const [formData, setFormData] = useState(defaultState);

	const handleInputChange = (event) => {
		if (event) {
			event.stopPropagation();
		}
		let { name, value, type, checked = false } = event?.target || {}
		if (type === "checkbox") {
			value = checked;
		} else if (event) {
			event.preventDefault();
		}
		setFormData((prevState) => ({ ...prevState, [name]: value }));
	};

	// Apply Search Params
	const handleApply = (e) => {
		if (e) {
			e.stopPropagation();
			e.preventDefault();
		}
		setSearchParams(formData);
		setParentModalState(false);
	};

	// Clear Search Params
	const handleClear = () => {
		setSearchParams(requiredSearchParams);
		setParentModalState(false);
	};

	// const handleEdit = () => {
	// 	setIsEditing(true);
	// 	setPreviousFormData(formData);
	// };

	// const handleCancel = () => {
	// 	setIsEditing(false);
	// 	setFormData(previousFormData);
	// };

	// const handleSave = () => {
	// 	setIsEditing(false);
	// 	setPreviousFormData(formData);
	// };

	return (
		<Form onSubmit={handleApply} >
			{/* <Row>
				<Col>
					<Form.Group controlId="searchTerm">
						<Form.Label>Search Term</Form.Label>
						<Form.Control
							type="text"
							size="sm"
							name="searchTerm"
							value={formData.searchTerm || ''}
							onChange={handleInputChange}
							placeholder="Search..."
						/>
					</Form.Group>
				</Col>
			</Row> */}

			<Row>
				<Form.Group as={Col} controlId="formGridCity">
					<Form.Label>First Name</Form.Label>
					<Form.Control
						size="sm"
						name="first_name"
						value={formData.first_name}
						onChange={handleInputChange}
						className={""}
					/>
				</Form.Group>

				<Form.Group as={Col} controlId="formGridState">
					<Form.Label>Last Name</Form.Label>
					<Form.Control size="sm" name="last_name" value={formData.last_name} onChange={handleInputChange} className={""} />
				</Form.Group>
			</Row>

			<Row>
				<Col>
					<Form.Group controlId="exampleForm.ControlInput1">
						<Form.Label>SSN</Form.Label>
						<Form.Control size="sm" type="text" name="ssn" value={formData.ssn} onChange={handleInputChange} />
					</Form.Group>
				</Col>
				<Col>
					<Form.Group controlId="exampleForm.ControlInput2">
						<Form.Label>Tag</Form.Label>
						<Form.Control size="sm" type="text" name="tags" value={formData.tags} onChange={handleInputChange} />
					</Form.Group>
				</Col>
			</Row>


			<Row>
				<Col>
					<Form.Group as={Col} controlId="formGridPhone">
						<Form.Label>Phone</Form.Label>
						<Form.Control
							size="sm"
							name="phone"
							value={formData.phone}
							onChange={handleInputChange}
							className={""}
						/>
					</Form.Group>
				</Col>
				<Col>
					<Form.Group as={Col} controlId="formGridEmail">
						<Form.Label>Email</Form.Label>
						<Form.Control
							size="sm"
							name="email"
							value={formData.email}
							onChange={handleInputChange}
							className={""}
						/>
					</Form.Group>
				</Col>
			</Row>

			<div className="mt-3">
				<Button variant="primary" type="submit" size="sm" onClick={handleApply} >
					Apply Search
				</Button>

				{/* <span className="mx-2" />
				<Button variant="outline-danger" size="sm" onClick={handleClear}>
					Clear
				</Button> */}
			</div>
		</Form>
	);
};

FindAClaimantForm.propTypes = {
	setParentModalState: PropTypes.func,
	searchParams: PropTypes.object,
	setSearchParams: PropTypes.func,
	requiredSearchParams: PropTypes.object
};

export default FindAClaimantForm;