import React, { useContext, useState, useRef } from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
import AppContext from 'context/Context';
import CreateCorrespondenceWizard from 'components/wizard/create-correspondence-wizard/CreateCorrespondenceWizard';
import CreateCorrespondenceForm from './CreateCorrespondenceForm';
import CustomMessageEditor from './CustomMessageEditor';
import auth from 'utils/auth';

const CreateCorrespondenceModal = ({
	account_id = auth.getProfile()?.data?.account_id || null,
	defaultFormData = {},
	modalState,
	setModalState,
	defaultClaimants = [],
	refreshParent = () => { },
}) => {
	const submitRef = useRef();

	const {
		config: { isDark }
	} = useContext(AppContext);

	const [customMessage, setCustomMessage] = useState(false);

	// Submit Form
	const handleSubmit = (data) => {
	};

	// Handle Show
	const handleShow = () => {
	};

	const handleClose = () => {
		setModalState(false);
	};

	return (
		<Modal
			show={modalState}
			onHide={handleClose}
			onShow={handleShow}
			contentClassName="border"
			size="xl"
			enforceFocus={false}
			backdrop="static"
		>
			<Modal.Header
				closeButton
				closeVariant={isDark ? 'white' : undefined}
				className="border-bottom bg-200"
			>
				<Modal.Title as="h5">Create Correspondence</Modal.Title>
			</Modal.Header>
			<Modal.Body className="p-3 bg-100">
				<div className="mx-3 px-3 py-3">
					<CreateCorrespondenceForm
						defaultFormData={defaultFormData}
						refreshParent={refreshParent}
						handleClose={handleClose}
						defaultClaimants={defaultClaimants}
						setCustomMessage={setCustomMessage}
						submitRef={submitRef}
						account_id={account_id}
					/>
				</div>

				{
					customMessage &&
					<div className="bg-white">
						<CustomMessageEditor
							defaultFormData={defaultFormData}
						/>
					</div>
				}


			</Modal.Body>
			<Modal.Footer className="border-top bg-200">
				<Button variant={'primary'} className="float-end" onClick={(e) => submitRef.current.click(e)}>Submit Correspondence</Button>
				<Form submitRef={submitRef} onSubmit={e => { e.preventDefault() }} />
			</Modal.Footer>
		</Modal>
	);
};


export default CreateCorrespondenceModal;