import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Modal, Row, Col, Alert, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import AppContext from 'context/Context';
import { assureAPI, claimantAPI } from 'utils/api/fulcrum-api';
import { toast } from 'react-toastify';
import { removeEmptyFromObj } from 'utils/functions';
import moment from 'moment';
import auth from 'utils/auth';

const CreateCaseFileRequest = ({
	account_id = auth.getProfile()?.data?.account_id || null,
	modalState,
	setModalState = () => { },
	claimant = {},
	attorneyList = [],
	refreshParent = (() => { })
}) => {
	const {
		config: { isDark }
	} = useContext(AppContext);
	const navigate = useNavigate();
	const [loading, setLoading] = useState(true);
	const [submitting, setSubmitting] = useState(false);
	const [errors, setErrors] = useState([]);
	const defaultForm = {
		ere_credentials_id: null,
		ac_report: false,
		test_mode: false
	};

	const [formData, setFormData] = useState(defaultForm);

	// Handle Change
	const handleChange = ({ target }) => {
		let { name, value } = target;
		let additionalValues = {};
		setFormData({ ...formData, ...additionalValues, [name]: value });
	};


	// Submit
	const handleSubmit = async e => {
		e.preventDefault();
		setErrors([]);
		setSubmitting(true);

		let newErrors = [];


		if (newErrors.length) {
			setErrors(newErrors);
			setSubmitting(false);
			return;
		}

		try {
			// claimantId, caseId,
			let newClaimantData = await assureAPI.submitStatusReportRequest(account_id || null, {
				...formData
			});

			if (newClaimantData?.message !== "Atlasware status report request has been submitted." && newClaimantData?.message !== "Status report requested queued for submission.") {
				throw new Error('Unable to submit the status report request.');
			}
			refreshParent(); // If a callback function was passed, invoke it
			toast.success('Status report request submitted!', { theme: 'colored' });
			handleClose();
		} catch (error) {
			toast.error("Unable to submit the status report request.", {
				theme: 'colored'
			});
		}

		setSubmitting(false);
	};

	// handle show
	const handleShow = () => {
		setSubmitting(false);
		setLoading(false);
	};

	// Handle Close
	const handleClose = () => {
		setSubmitting(false);
		setFormData(defaultForm);
		setModalState(false);
	};

	return (
		<>
			<Modal
				show={modalState}
				onHide={handleClose}
				onShow={handleShow}
				contentClassName="border"
				size='lg'
			>
				<Form onSubmit={handleSubmit}>
					<Modal.Header
						closeButton
						closeVariant={isDark ? 'white' : undefined}
						className="bg-light px-card border-bottom-0"
					>
						<Modal.Title as="h5">Submit Status Report Request</Modal.Title>
					</Modal.Header>
					<Modal.Body className="p-card">
						{
							loading ?
								<>
									<div>
										<Spinner animation="border" role="status">
											<span className="sr-only">Loading...</span>
										</Spinner>
									</div>
								</>
								:
								<>
									<Alert variant={'primary'}>
										To submit a status report request, please complete the following form.
									</Alert>

									<Form.Group className="my-3">
										<Form.Label>Attorney</Form.Label>
										<Form.Control
											as="select"
											name="ere_credentials_id"
											value={formData.ere_credentials_id}
											onChange={handleChange}
											required
											disabled={submitting}
										>
											<option value="">Select an attorney</option>
											{
												Array.isArray(attorneyList) && attorneyList?.map(attorney => (
													<option key={`attorney-cred-${attorney.id}`} value={attorney.id}>{attorney.attorney_name}</option>
												))
											}
										</Form.Control>
									</Form.Group>

									<Form.Group className="my-3">
										<Form.Label>Type of report?</Form.Label> <br />
										<Form.Check
											type="radio"
											inline
											label="Hearing Status Report"
											name="ac_report"
											value={false}
											id="ac_report_hearing"
											checked={formData.ac_report === false}
											onChange={e => {
												setFormData({
													...formData,
													ac_report: e.target.value === 'true' ? true : false
												})
											}}
											disabled={submitting}
										/>
										<Form.Check
											type="radio"
											inline
											label="Appeals Council Report"
											name="ac_report"
											value={true}
											id="ac_report_appeals"
											checked={formData.ac_report === true}
											onChange={e => {
												setFormData({
													...formData,
													ac_report: e.target.value === 'true' ? true : false
												})
											}}
											disabled={submitting}
										/>
									</Form.Group>

									<Form.Group className="my-3">
										<Form.Label>Test mode?</Form.Label> <br />
										<Form.Check
											type="radio"
											inline
											label="Yes"
											name="test_mode"
											value={true}
											id="test_mode_yes"
											checked={formData.test_mode === true}
											onChange={e => {
												setFormData({
													...formData,
													test_mode: e.target.value === 'true' ? true : false
												})
											}}
											disabled={submitting}
										/>
										<Form.Check
											type="radio"
											inline
											label="No"
											name="test_mode"
											value={false}
											id="test_mode_no"
											checked={formData.test_mode === false}
											onChange={e => {
												setFormData({
													...formData,
													test_mode: e.target.value === 'true' ? true : false
												})
											}}
											disabled={submitting}
										/>
									</Form.Group>
								</>
						}
					</Modal.Body>
					<Modal.Footer className="bg-light px-card border-top-0">
						<Button variant="secondary" onClick={handleClose} disabled={submitting}>
							Close
						</Button>
						<Button variant="primary" type="submit" disabled={submitting}>
							{submitting ? 'Submitting...' : 'Submit'}
						</Button>
					</Modal.Footer>
				</Form>
			</Modal>
		</>
	);
};

export default CreateCaseFileRequest;