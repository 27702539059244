import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import WizardInput from './WizardInput';
import termsOfService from 'data/termsOfService.js';
import { Button } from 'react-bootstrap';

const TermsOfService = ({ register, errors }) => {
  const [terms, setTerms] = useState('');

  const onButtonClick = () => {
    // using Java Script method to get PDF file
    fetch(termsOfService.downloadUrl).then(response => {
      response.blob().then(blob => {
        // Creating new object of PDF file
        const fileURL = window.URL.createObjectURL(blob);
        // Setting various property values
        let alink = document.createElement('a');
        alink.href = fileURL;
        alink.download = termsOfService.downloadUrl;
        alink.click();
      })
    })
  }

  const applySubstitutions = (text, substitutions = []) => {
    if (Array.isArray(substitutions)) {
      for (let i = 0; i < substitutions.length; i++) {
        text = text.replaceAll(substitutions[i].replace, substitutions[i].with);
      }
    }
    return text;
  };

  useEffect(() => {
    setTerms(applySubstitutions(termsOfService.terms, termsOfService.substitutions));
  }, []);

  return (
    <>
      <p className="bold">Terms of Service</p>
      <div
        className="border p-3"
        style={{
          overflow: "auto",
          height: "400px"
        }}>
        <div
          dangerouslySetInnerHTML={{ __html: terms }}
        />
      </div>

      <br />
      <Button variant="link" onClick={onButtonClick}>Download Terms of Service</Button>
      <WizardInput
        type="checkbox"
        errors={errors}
        label={
          <>
            I accept the terms of service
          </>
        }
        name="agreedToTerms"
        formControlProps={{
          ...register('agreedToTerms', {
            required: 'You need to agree the terms of service.'
          })
        }}
      />
    </>
  );
};

TermsOfService.propTypes = {
  register: PropTypes.func.isRequired,
  errors: PropTypes.object,
  watch: PropTypes.func
};

export default TermsOfService;
