/* eslint-disable react/prop-types */
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { Button, Dropdown } from 'react-bootstrap';
import Flex from '../Flex';

export const CustomAdvanceTablePagination = ({
  // canPreviousPage,
  // canNextPage,
  // previousPage,
  // nextPage,
  // pageCount,
  // pageIndex,
  // gotoPage,
  // pageSize,
  Pages,
  activePage,
  setActivePage,
  setPerPage,
  perPage,
  totalRecords,
}) => {
  // if (Pages.length < 2) {
  //   return totalRecords == undefined ? null : <Flex alignItems="left" justifyContent="left">
  //     <span>Total Records: {totalRecords}</span>
  //   </Flex>;
  // }

  return (
    <Flex alignItems="center" justifyContent="center">
      <Button
        size="sm"
        variant="falcon-default"
        onClick={(e) => {
          e.preventDefault();
          setActivePage(activePage - 1);
        }}
        className={classNames({ disabled: activePage === 1 })}
      >
        <FontAwesomeIcon icon="chevron-left" />
      </Button>
      <ul className="pagination mb-0 mx-1">
        {Pages.map((pageNumber, index, arr) => (
          <li key={pageNumber} className={classNames({ active: pageNumber === activePage })}>
            <Button
              size="sm"
              variant="falcon-default"
              className={classNames('page', {
                'me-1': arr.length - 1 !== index
              })}
              onClick={(e) => {
                e.preventDefault();
                setActivePage(pageNumber);
              }}
            >
              {pageNumber}
            </Button>
          </li>
        ))}
      </ul>
      <Button
        size="sm"
        variant="falcon-default"
        onClick={(e) => {
          e.preventDefault();
          setActivePage(activePage + 1);
        }}
        className={classNames({ disabled: activePage === Pages[Pages.length - 1] })}
      >
        <FontAwesomeIcon icon="chevron-right" />
      </Button>
    </Flex>
  );
};

export default CustomAdvanceTablePagination;
