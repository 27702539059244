import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';

const ClaimantTableFilters = ({ searchParams, setSearchParams, requiredSearchParams = {}, exludedFilters = [] }) => {
	let defaultState = {
		business_name: searchParams.business_name || '',
		account_id: searchParams.account_id || '',
		first_name: searchParams.first_name || '',
		last_name: searchParams.last_name || '',
		dob: searchParams.dob || '',
		ssn: searchParams.ssn || '',
		tags: searchParams.tags || '',
		searchTerm: searchParams.searchTerm || '',
		phone: searchParams.phone || '',
		email: searchParams.email || '',
		established_contact: "established_contact" in searchParams ? searchParams.established_contact : '',
		screened: "screened" in searchParams ? searchParams.screened : '',
		reviewed: "reviewed" in searchParams ? searchParams.reviewed : '',
	};
	const [formData, setFormData] = useState(defaultState);

	const handleInputChange = (event) => {
		if (event) {
			event.stopPropagation();
			event.preventDefault();
		}
		let { name, value, type, checked = false } = event?.target || {}

		if (type === "checkbox") {
			value = checked;
		}

		if (['established_contact', 'screened', 'reviewed'].includes(name)) {
			value = value === 'true' ? true : false;
		}

		setFormData((prevState) => ({ ...prevState, [name]: value }));
	};

	// Apply Search Params
	const handleApply = (e) => {
		if (e) {
			e.stopPropagation();
			e.preventDefault();
		}
		setSearchParams(formData);
	};

	// Clear Search Params
	const handleClear = () => {
		setSearchParams({});
	};

	useEffect(() => {
		setFormData({
			business_name: searchParams.business_name || '',
			account_id: searchParams.account_id || '',
			first_name: searchParams.first_name || '',
			last_name: searchParams.last_name || '',
			dob: searchParams.dob || '',
			ssn: searchParams.ssn || '',
			tags: searchParams.tags || '',
			searchTerm: searchParams.searchTerm || '',
			phone: searchParams.phone || '',
			email: searchParams.email || '',
			established_contact: "established_contact" in searchParams ? searchParams.established_contact : '',
			screened: "screened" in searchParams ? searchParams.screened : '',
			reviewed: "reviewed" in searchParams ? searchParams.reviewed : '',
		});
	}, [searchParams]);

	return (
		<Form noValidate onSubmit={handleApply} className="row flex-between-center px-2 py-3 my-3 border bg-100 mx-2">
			<Row>
				<Col>
					<h5>Filters</h5>
				</Col>
			</Row>
			{!exludedFilters.includes('business_name') &&
				<Row>
					<Col>
						<Form.Group as={Col} controlId="business_name">
							<Form.Label>Account Business Name</Form.Label>
							<Form.Control
								size="sm"
								type="text"
								name="business_name"
								value={formData.business_name}
								onChange={handleInputChange}
								className={""}
							/>
						</Form.Group>
					</Col>
				</Row>
			}
			<Row>
				<Col>
					<Form.Group as={Col} controlId="first_name">
						<Form.Label>First Name</Form.Label>
						<Form.Control
							size="sm"
							name="first_name"
							value={formData.first_name}
							onChange={handleInputChange}
							className={""}
						/>
					</Form.Group>
				</Col>
				<Col>
					<Form.Group as={Col} controlId="last_name">
						<Form.Label>Last Name</Form.Label>
						<Form.Control
							size="sm"
							name="last_name"
							value={formData.last_name}
							onChange={handleInputChange}
							className={""}
						/>
					</Form.Group>
				</Col>
				<Col>
					<Form.Group as={Col} controlId="dob">
						<Form.Label>Date of Birth</Form.Label>
						<Form.Control
							size="sm"
							name="dob"
							type="date"
							value={formData.dob}
							onChange={handleInputChange}
							className={""}
						/>
					</Form.Group>
				</Col>
				<Col>
					<Form.Group as={Col} controlId="ssn">
						<Form.Label>SSN</Form.Label>
						<Form.Control
							size="sm"
							name="ssn"
							value={formData.ssn}
							onChange={handleInputChange}
							className={""}
						/>
					</Form.Group>
				</Col>
			</Row>
			<Row>
				<Col>
					<Form.Group as={Col} controlId="formGridPhone">
						<Form.Label>Phone</Form.Label>
						<Form.Control
							size="sm"
							name="phone"
							value={formData.phone}
							onChange={handleInputChange}
							className={""}
						/>
					</Form.Group>
				</Col>
				<Col>
					<Form.Group as={Col} controlId="formGridEmail">
						<Form.Label>Email</Form.Label>
						<Form.Control
							size="sm"
							name="email"
							value={formData.email}
							onChange={handleInputChange}
							className={""}
						/>
					</Form.Group>
				</Col>
				<Col>
					<Form.Group as={Col} controlId="tags">
						<Form.Label>Tags</Form.Label>
						<Form.Control
							size="sm"
							name="tags"
							value={formData.tags}
							onChange={handleInputChange}
							className={""}
						/>
					</Form.Group>
				</Col>
			</Row>
			<Row>
				{!exludedFilters.includes('established_contact') &&
					<Col>
						<Form.Group as={Col} controlId="established_contact">
							<Form.Label>Established Contact</Form.Label>
							<Form.Select
								size="sm"
								name="established_contact"
								value={formData.established_contact}
								onChange={handleInputChange}
								className={""}
							>
								<option value="">All</option>
								<option value={true}>Yes</option>
								<option value={false}>No</option>
							</Form.Select>
						</Form.Group>
					</Col>
				}

				{!exludedFilters.includes('screened') &&
					<Col>
						<Form.Group as={Col} controlId="screened">
							<Form.Label>Screened</Form.Label>
							<Form.Select

								size="sm"
								name="screened"
								value={formData.screened}
								onChange={handleInputChange}
								className={""}
							>
								<option value="">All</option>
								<option value={true}>Yes</option>
								<option value={false}>No</option>
							</Form.Select>
						</Form.Group>
					</Col>
				}

				{!exludedFilters.includes('screened') &&
					<Col>
						<Form.Group as={Col} controlId="reviewed">
							<Form.Label>Reviewed</Form.Label>
							<Form.Select
								size="sm"
								name="reviewed"
								value={formData.reviewed}
								onChange={handleInputChange}
								className={""}
							>
								<option value="">All</option>
								<option value={true}>Yes</option>
								<option value={false}>No</option>
							</Form.Select>
						</Form.Group>
					</Col>
				}

			</Row>


			<div className="mt-3">
				<Button variant="primary" type="submit" size="sm" onClick={handleApply} >
					Apply Search
				</Button>
				&nbsp;
				<Button variant="outline-danger" size="sm" onClick={handleClear}>
					Clear
				</Button>
			</div>
		</Form>
	);
};

export default ClaimantTableFilters;