import { useEffect, useContext } from 'react';
import AppContext from 'context/Context';
import { addressAPI, notificationTypeAPI, phoneAPI, serviceAPI } from 'utils/api/fulcrum-api';

const LookupTableLoader = () => {
    const {
        lookupsDispatch
    } = useContext(AppContext);

    const dbLoad = async () => {
        try {
            const { data } = await addressAPI.getAddressTypes();
            // console.log(data.rows);
            lookupsDispatch({ type: 'SET_LOOKUP', payload: { key: 'addressTypes', value: data.rows } });
        } catch (error) {
            console.log(error);
        }

        try {
            const { data } = await phoneAPI.getPhoneTypes();
            // console.log(data.rows);
            lookupsDispatch({ type: 'SET_LOOKUP', payload: { key: 'phoneTypes', value: data.rows } });
        } catch (error) {
            console.log(error);
        }

        try {
            const { data } = await serviceAPI.searchMyService();
            // const response = await serviceAPI.searchMyService();
            // console.log(response);
            lookupsDispatch({ type: 'SET_LOOKUP', payload: { key: 'services', value: data.rows } });
        } catch (error) {
            console.log(error);
        }

        try {
            const { data } = await notificationTypeAPI.searchMyNotificationType();
            lookupsDispatch({ type: 'SET_LOOKUP', payload: { key: 'notificationTypes', value: data.rows } });
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        dbLoad();
    }, []);

    return (null);
}

export default LookupTableLoader;