import React, { useContext, useState, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Modal, Table } from 'react-bootstrap';
// import { v4 as uuid } from 'uuid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import AppContext from 'context/Context';
import { documentAPI, serviceAPI } from 'utils/api/fulcrum-api';
import { useDropzone } from 'react-dropzone';
import Flex from 'components/common/Flex';
import cloudUpload from 'assets/img/icons/cloud-upload.svg';
import { toast } from 'react-toastify';
import Auth from 'utils/auth';

import Swal from 'sweetalert2';

const UploadServiceAgreementDocument = ({
  modalState,
  setModalState,
  selectedServiceAgreement,
  account,
  service,
  refreshAccountServices = () => { },
}) => {
  const {
    config: { isDark }
  } = useContext(AppContext);
  const defaultFormData = {
    title: selectedServiceAgreement?.title,
    file: null
  };

  const [isUploading, setIsUploading] = useState(false);
  const [documentTypes, setDocumentTypes] = useState([]);
  const [documentStatuses, setDocumentStatuses] = useState([]);
  const [formData, setFormData] = useState(defaultFormData);
  const [selectedFiles, setSelectedFiles] = useState([]);

  const onDrop = useCallback(acceptedFiles => {
    setSelectedFiles([...selectedFiles, ...acceptedFiles])
  }, [selectedFiles])

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    onDrop,
  })

  const formRef = useRef(null);

  const files = selectedFiles.map(file => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  const removeAll = () => {
    setSelectedFiles([])
  }


  const handleChange = ({ target }) => {
    let { name, value } = target;
    let additionalValues = {};
    setFormData({ ...formData, ...additionalValues, [name]: value });
  };

  const handleSubmit = async e => {
    e.preventDefault();

    if (!account?.id) {
      toast.error('Error getting account id');
      return;
    }

    if (!selectedServiceAgreement?.title) {
      toast.error('Please select a service agreement');
      return;
    }

    if (acceptedFiles.length !== 1) {
      toast.error('You must select one and only one file to upload.', { theme: 'colored' });
      return;
    }

    setIsUploading(true);
    let formDataObject = new FormData(formRef.current);

    try {

      for (let i = 0; i < acceptedFiles.length; i++) {
        let file = acceptedFiles[i];
        file.originalFilename = file.path;
        formDataObject.append("files", file);
        console.log("upload service agreement document handleSubmit");
        console.log(file?.name);
      }

      formDataObject.append("title", selectedServiceAgreement?.title || null);


      const response = await serviceAPI.uploadServiceAgreementDocument(account.id, service.id, formDataObject);

      console.log("upload service agreement document handleSubmit response", response);

      toast.success('Document uploaded successfully.', { theme: 'colored' });
      setIsUploading(false);
      refreshAccountServices();
      handleClose();

    } catch (error) {
      console.log("UploadServiceAgreementDocument handleSubmit error:", error);
      Swal.fire({
        icon: 'error',
        text: "Failed to upload document.",
        showConfirmButton: true,
        background: false,
        backdrop: false
      });

      setIsUploading(false);

    }

  };

  // Handle Show
  const handleShow = () => {
    setFormData(defaultFormData);
  };

  // Handle Close
  const handleClose = () => {
    removeAll();
    setFormData(defaultFormData);
    setModalState(false);
  };

  return (
    <Modal
      show={modalState}
      onHide={handleClose}
      onShow={handleShow}
      size="lg"
      contentClassName="border"
    >
      <Form ref={formRef} onSubmit={handleSubmit}>
        <Modal.Header
          closeButton
          closeVariant={isDark ? 'white' : undefined}
          className="bg-light px-card border-bottom-0"
        >
          <Modal.Title as="h5">Upload A Service Agreement Document</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-card">
          <span className="text-bold">{selectedServiceAgreement?.title || "No title"}</span>

          {
            !isUploading &&
            <div {...getRootProps({ className: 'dropzone-area py-6' })}>
              <input {...getInputProps({ multiple: false })} />
              <Flex justifyContent="center">
                <img src={cloudUpload} alt="" width={25} className="me-2" />
                <p className="fs-0 mb-0 text-700">Drop your file here</p>
              </Flex>
            </div>
          }

          <div className="mt-3">
            {selectedFiles.length > 0 && (
              <>
                <h6>File</h6>
                <ul>{files}</ul>
              </>
            )}
          </div>

        </Modal.Body>
        <Modal.Footer className="bg-light px-card border-top-0">

          <Button
            variant="secondary"
            className="mr-3"
            type="button"
            onClick={handleClose}
          >
            Cancel
          </Button>

          <Button
            variant="primary"
            className="px-4 mx-0"
            type="submit"
            disabled={isUploading}
            onClick={handleSubmit}
          >
            {isUploading ? "Uploading..." : "Upload"}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

UploadServiceAgreementDocument.propTypes = {
  modalState: PropTypes.bool,
  setModalState: PropTypes.func,
  refreshParent: PropTypes.func,
}

export default UploadServiceAgreementDocument;