import React from 'react';
import { Col, Form, Row, Button } from 'react-bootstrap';
import { formatDate } from 'utils/functions';
import IconButton from 'components/common/IconButton';
import ExportReportButton from 'components/ExportReportButton';

const StatusReportTableHeader = ({
	selectedRowIds,
	page = [],
	label = "Status Report",
	requiredSearchParams = {},
	searchParams = {},
	setSearchParams = () => { },
	claimant = {},
	caseFileData }) => {

	const statusReportIdList = Array.isArray(page)
		? (page.reduce((acc, { isSelected = false, original } = {}) => (
			[...acc, ...(isSelected ? [original] : [])]
		), [])).map(({ id } = {}) => id)
		: [];

	return (
		<Row className="flex-between-center">
			<Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
				<h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">{label}</h5>
			</Col>
			<Col xs={8} sm="auto" className="ms-auto text-end ps-0">
				{Object.keys(selectedRowIds).length > 0 ? (
					<div className="d-flex">
						{/* <Form.Select size="sm" aria-label="Bulk actions">
							<option >Bulk Actions</option> */}
						{/*
							<option value="send-notification">Send Notification</option>
							<option value="disable">Disable</option> 
							*/}
						{/* </Form.Select> */}
						{/* <Button
							type="button"
							variant="falcon-default"
							size="sm"
							className="ms-2"
						>
							Apply
						</Button> */}
					</div>
				) : (
					<div id="orders-actions">
						{/* <IconButton
							variant="falcon-default"
							size="sm"
							icon="plus"
							transform="shrink-3"
							className="mx-2"
							onClick={() => { setCreateStatusReportModal(true) }}
						>
							<span className="d-none d-sm-inline-block ms-1">New status report request</span>
						</IconButton> */}
						<ExportReportButton
							controllerName="AtlaswareCasefile"
							fnName="searchAllAtlaswareCasefileFn"
							saveAs={`${label.replace(" ", "_").trim()}_${formatDate(new Date(), "YYYY-MM-DD")}.xlsx`}
							params={{
								...searchParams,
								offset: 0,
								limit: null,
								selectedRowIds: statusReportIdList,
							}}
							flattenData // whenever the return data rows has dataValues or any objects inside it, set this to true to flatten the data in the rows exported to excel
							appendDuplicates={false} // if true, flattened data with duplicates will be appended to each other 
							overwriteDuplicates={false} // if true, flattened data with duplicates will be overwritten with the last value. false will keep the first value and any more duplicates will be ignored 
						/>
					</div>
				)}
			</Col>
		</Row>
	);
};


export default StatusReportTableHeader;