import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import JoditEditor from "jodit-pro-react";
import { myFormsAPI, documentAPI } from 'utils/api/fulcrum-api';
import { toast } from 'react-toastify';
import Button from 'react-bootstrap/Button';
import RenderMyFormContent from './RenderMyFormContent';
import { availableFields } from 'components/pages/documents/my-forms/availableFields';
import 'css/JoditDocumentBuilder.css';

const ABBREVIATIONS = {
	text: 'i',
	number: 'n',
	email: 'e',
	date: 'd',
	textarea: 't',
	image: 'p',
	pagebreak: 'b'
};

// const MyFormEditor = ({ setModalState = () => { }, selectedForm, setSelectedForm, myFormData, setMyFormData, refreshParent = () => { } }) => {
const CreateMyForm = ({ refreshParent = () => { }, defaultNewFormValues = {}, setDefaultNewFormValues = () => { }, handleClose = () => { }, withNavigateToFormPage = true }) => {
	const [loading, setLoading] = useState(true);
	const navigate = useNavigate();
	const [viewFormModalState, setViewFormModalState] = useState(false);
	let defaultFormState = {
		title: '',
		description: '',
		type: 'RTF',
		form_type_id: 'OTHER',
		content: '',
		...defaultNewFormValues
	};

	const [documentTypes, setDocumentTypes] = useState([]);
	const [formData, setFormData] = useState(defaultFormState);
	const [previousFormData, setPreviousFormData] = useState(defaultFormState);

	// Get related data @TODO: Move this to a context
	const getRelatedData = async () => {
		try {
			let docTypeResults = await documentAPI.searchDocumentTypes();
			if (!docTypeResults?.data?.rows) {
				throw new Error('Unable to get documents types.');
			}

			setDocumentTypes(docTypeResults.data.rows);
		} catch (error) {
			alert(error);
		}
	};


	// Handle Input Change
	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setFormData({ ...formData, [name]: value });
	}

	// Create new form
	const createNewForm = async () => {
		try {
			let result = await myFormsAPI.createForm({
				...formData
			});
			if (!result?.data) {
				throw new Error('Error creating form');
			}
			if (withNavigateToFormPage) {
				navigate(`/my-forms/${result.data.id}`);
			} else {
				handleClose();
			}

			toast.success("New form template created!", {
				theme: 'colored'
			});
			refreshParent();
		} catch (error) {
			console.log(error);

			toast.error(error, {
				theme: 'colored'
			});
		}
	};

	// Cancel Edit
	const cancelEdit = () => {
		setFormData(previousFormData);
	};


	useEffect(() => {
		getRelatedData();
		setLoading(false);

	}, []);


	if (loading) {
		return <p>Loading...</p>;
	}

	return (
		<>
			<Form>
				<Form.Group controlId="formBasicEmail">
					<Form.Label>Form Name</Form.Label>
					<Form.Control
						type="text"
						name="title"
						value={formData.title}
						placeholder="Enter form name"
						onChange={handleInputChange}
						required
					/>
				</Form.Group>
				<Form.Group controlId="formBasicPassword">
					<Form.Label>Form Description</Form.Label>
					<Form.Control
						type="text"
						name="description"
						value={formData.description}
						placeholder="Enter form description"
						onChange={handleInputChange}
					/>
				</Form.Group>


				<Form.Group className="mb-3" controlId="form_type_id">
					<Form.Label className="fs-0">Form Type</Form.Label>
					<Form.Text> Document type that the response will generate.</Form.Text>
					<Form.Select name="form_type_id" value={formData.form_type_id} onChange={handleInputChange} required>
						<option value={""}>--Select One--</option>
						{Array.isArray(documentTypes) && documentTypes.map((docType, index) => (
							<option key={index} value={docType.id}>{docType.type}</option>
						))}
					</Form.Select>
				</Form.Group>

				<div className="mt-3">
					<Button className="btn btn-primary" onClick={createNewForm}>Create Form Template</Button>
				</div>
			</Form>
		</>
	);
};

CreateMyForm.propTypes = {
	refreshParent: PropTypes.func
}

export default CreateMyForm;