import React, { forwardRef } from 'react';
import { Card } from 'react-bootstrap';

const Updates = forwardRef((props, ref) => {
	return (
		<Card className="mb-3" id="updates" ref={ref}>
			<Card.Header className="bg-light">
				<h5 className="mb-0">Updates to Privacy Policy</h5>
			</Card.Header>
			<Card.Body>
				<p className="mb-0 ps-3">
					Assure Disability, Inc. reserves the right to make changes and updates to this Privacy Policy without notice.
				</p>
			</Card.Body>
		</Card>
	);
});

export default Updates;
