import React, { useState } from 'react';
import { Form, Button, Row, Col } from 'react-bootstrap';

// Dropdowns


const AccountFilters = ({ searchParams, setSearchParams = () => { }, clearSearchParams = () => { } }) => {

	const [formData, setFormData] = useState({
		business_name: searchParams?.business_name || '',
		account_status: searchParams?.account_status || '',
		system_text_number: searchParams?.system_text_number || '',
		system_fax_email: searchParams?.system_fax_email || '',
		system_email: searchParams?.system_email || '',
		searchTerm: searchParams?.searchTerm || ''
	});

	// Handle form change
	const handleFormChange = e => {
		const { name, value } = e.target;
		setFormData({ ...formData, [name]: value });
	};

	// Handle clear filters
	const handleClearFilters = (e) => {
		e && e.preventDefault();
		clearSearchParams();
	};

	// Apply filters
	const handleApplyFilters = (e) => {
		e && e.preventDefault();
		setSearchParams({ ...searchParams, ...formData });
	}

	return (
		<Form onSubmit={handleApplyFilters} className="row flex-between-center px-2 py-3 my-3 border bg-100 mx-2">

			<Row>
				<Col>
					<h5>Filters</h5>
				</Col>
			</Row>

			<Row>
				<Col>
					<Row>
						<Col>
							<Form.Group>
								<Form.Label>Business Name</Form.Label>
								<Form.Control
									type="text"
									name="business_name"
									value={formData.business_name || ''}
									onChange={handleFormChange}
								/>
							</Form.Group>
						</Col>
						<Col>
							<Form.Group>
								<Form.Label>Account Status</Form.Label>
								<Form.Control
									as="select"
									name="account_status"
									value={formData.account_status || ''}
									onChange={handleFormChange}
								>
									<option value="">All</option>
									<option value="active">Active</option>
									<option value="inactive">Inactive</option>
								</Form.Control>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Col>
							<Form.Group>
								<Form.Label>System Text Number</Form.Label>
								<Form.Control
									type="text"
									name="system_text_number"
									value={formData.system_text_number || ''}
									onChange={handleFormChange}
								/>
							</Form.Group>
						</Col>
						<Col>
							<Form.Group>
								<Form.Label>System Fax Email</Form.Label>
								<Form.Control
									type="text"
									name="system_fax_email"
									value={formData.system_fax_email || ''}
									onChange={handleFormChange}
								/>
							</Form.Group>
						</Col>
						<Col>
							<Form.Group>
								<Form.Label>System Email</Form.Label>
								<Form.Control
									type="text"
									name="system_email"
									value={formData.system_email || ''}
									onChange={handleFormChange}
								/>
							</Form.Group>
						</Col>
					</Row>

					<Row className="mt-3">
						<Col>
							<div className="float-end">
								<Button variant="primary" type="submit" >Apply Filters</Button> &nbsp;
								<Button variant="outline-danger" onClick={handleClearFilters}>Clear Filters</Button>
							</div>
						</Col>
					</Row>
				</Col>
			</Row>
		</Form>
	);
};

export default AccountFilters;