import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { accessVerificationAPI } from 'utils/api/fulcrum-api';
import MyFormViewer from 'components/pages/documents/my-forms/MyFormViewer';

const AccessVerificationLayout = () => {
	// const { hash, pathname } = useLocation();
	const { uuid } = useParams();

	const [accessVerification] = useState({});
	const [expiredLink, setExpiredLink] = useState(false);
	const [loading, setLoading] = useState(true);

	// Lookup Aceess Verification by UUID
	const getAccessVerification = async () => {
		try {
			let response = await accessVerificationAPI.getAccessVerification(uuid);
			if (!response?.data) {
				throw new Error('Unable to find the Access Verification using the provided uuid.');
			}

		} catch (error) {
			setExpiredLink(true);
			console.log(error);
		}
		setLoading(false);
	};

	useEffect(() => {
		getAccessVerification();
	}, []);

	return (
		<div className="m-5">
			{
				loading
					?
					<div>Loading...</div>
					:
					(
						expiredLink
							?
							<div>
								<h1>Expired Link</h1>
							</div>
							:
							<div>
								<MyFormViewer
									uuid={uuid}
									accessVerification={accessVerification}
									meta_data={accessVerification?.meta_data ? JSON.parse(accessVerification.meta_data) : {}}
								/>
							</div>
					)
			}
		</div>
	);
};

export default AccessVerificationLayout;
