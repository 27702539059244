import React, { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import PermissionDenied from '../components/errors/PermissionDenied';
import { serviceAPI } from 'utils/api/fulcrum-api';

const ServicesLayout = () => {
	const { pathname } = useLocation();
	const [verifying, setVerifying] = useState(false);
	const [verified, setVerified] = useState(true);

	// Verify access to the service
	const verifyServiceAccess = async () => {
		// Get service ID based on the pathname
		let serviceId = null;
		switch (pathname) {
			case '/briefs':
				serviceId = 2;
				break;
			case '/hearing-coverage':
				serviceId = 1;
				break;
			case '/claimant-locator':
				serviceId = 3;
				break;
			case '/outbound-lead-generation':
				serviceId = 4;
				break;
			case '/medical-records':
				serviceId = 5;
				break;
			case '/atlasware':
				serviceId = 6;
				break;
			default:
				break;
		}

		try {
			let result = await serviceAPI.verifyAssureServiceAccess(serviceId);
			if (result?.data?.hasAccess) {
				setVerified(true);
			} else {
				setVerified(false);
			}
		} catch (error) {
			console.log(error);
		}
		setVerifying(false);
	};

	useEffect(() => {
		// setVerified(false);
		// setVerifying(true);
		// verifyServiceAccess();
	}, [pathname]);

	if (verifying) {
		return (<p>Verifying service...</p>);
	}

	return (
		<div className="container-fluid">
			{
				verified
					?
					<Outlet />
					:
					<PermissionDenied
						title="Service not available"
						message="You do not have access to this service. Please contact your system administrator to get enrolled in this service."
					/>
			}
		</div>
	);
};

export default ServicesLayout;
