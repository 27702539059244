import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { userAPI } from 'utils/api/fulcrum-api';
import { AuthContext } from 'context/Context';
import Button from 'react-bootstrap/Button';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';

const SendUserRegistrationEmail = ({ user }) => {
	const { permissionsState } = useContext(AuthContext);

	// Send user registration email
	const sendUserRegistrationEmail = async () => {
		Swal.fire({
			title: 'Send user new registration invite email?',
			text: "This will change the users status to 'Pending Activation' and send them a new registration invite email.",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Send Invite Link'
		}).then(async (result) => {
			if (result.isConfirmed) {
				try {
					const { data } = await userAPI.sendUserInviteEmail(user.id);
					if (data) toast.success(`A registration email has been sent to ${user.email}.`, {
						theme: 'colored'
					});
				} catch (error) {
					toast.error(error, {
						theme: 'colored'
					});
				}
			}
		});
	};

	return (
		<>
			{
				(![1].includes(user.user_status_id) && permissionsState.permissions.includes('SEND_USER_INVITE_LINK')) &&
				<Button variant="primary" onClick={sendUserRegistrationEmail}>Resend Invite Email</Button>
			}
		</>
	);
}

SendUserRegistrationEmail.propTypes = {
	user: PropTypes.object
}

export default SendUserRegistrationEmail;