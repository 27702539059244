import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
let { addressAPI, claimantDetailsAPI } = require('utils/api/fulcrum-api');
import ListGroup from 'react-bootstrap/ListGroup';
import AddressRow from './AddressRow';
import IconButton from 'components/common/IconButton';
import { toast } from 'react-toastify';

const AddressList = ({ entity_id, uuid }) => {
	const [loading, setLoading] = useState(true);
	const [addresses, setAddresses] = useState([]);
	// const [addressTypes, setAddressTypes] = useState([]);

	// Add address
	const addAddress = async () => {
		try {
			let result;
			if (uuid) {
				result = await claimantDetailsAPI.createAddress(uuid, entity_id.split('_')[2], {
					address_type_id: 4,
					entity_id
				});
			} else {
				result = await addressAPI.createAddress({
					address_type_id: 4,
					entity_id
				});
			}

			if (!result?.data) {
				throw new Error('No address data returned');
			}
			getAddressList();
			toast.success(`Address successfully added.`, {
				theme: 'colored'
			});
		} catch (error) {
			console.error(error);
			toast.error(`${error}`, {
				theme: 'colored'
			});
		}
	};

	// Get address list by entity_id
	const getAddressList = async () => {
		try {

			let result;
			if (uuid) {
				result = await claimantDetailsAPI.getAddressListByEntityId(uuid, entity_id.split('_')[2], entity_id);
			} else {
				result = await addressAPI.getAddressListByEntityId(entity_id);
			}

			if (!result?.data) {
				throw new Error('No address data returned');
			}
			setAddresses(result.data);
			setLoading(false);
		} catch (error) {
			console.error(error);
		}
	};

	// Get related data
	// const getRelatedData = async () => {
	// 	try {
	// 		let result = await addressAPI.getAddressTypes();
	// 		if (!result?.data?.rows) {
	// 			throw new Error('Unable to find any address types');
	// 		}
	// 		setAddressTypes(result.data.rows);
	// 		setLoading(false);
	// 	} catch (error) {
	// 		console.error(error);
	// 	}
	// };

	useEffect(() => {
		getAddressList(entity_id);
		// getRelatedData();
	}, []);

	if (!entity_id) {
		return (<p>No entity provided</p>);
	}

	return (
		<div>
			<div>
				<h5 className="d-inline">Addresses</h5>

				<IconButton
					className="me-2 mb-1 float-end"
					variant="falcon-default"
					size="sm"
					icon="plus"
					transform="shrink-3"
					onClick={addAddress}
				>
					Add
				</IconButton>
				<hr />

				{loading ? <p>Loading addresses...</p> : (
					<ListGroup as="ul" variant="flush">
						{addresses.map((address) => (
							<AddressRow
								entity_id={entity_id}
								uuid={uuid}
								key={address.id}
								address={address}
								setAddresses={setAddresses}
								addresses={addresses}
								getAddressList={getAddressList}
							// addressTypes={addressTypes}
							/>
						))}
					</ListGroup>
				)}
			</div>
		</div>
	);
};

AddressList.propTypes = {
	entity_id: PropTypes.string
}

export default AddressList;