import React from 'react';
import { Row, Col } from 'react-bootstrap';
import className from 'classnames';
import serviceList from 'data/feature/serviceList';
import Section from 'components/common/Section';
import CardService from './CardService';
import SectionHeader from './SectionHeader';

const Services = () => (
  <Section bg="light" className="text-center">
    <SectionHeader
      title="Here's what's in it for you"
      subtitle="With automated and professional services, Assure Disability can help increase productivity and increase client capacity. Allowing firms to spend time doing what really matters advocating for their clients. Tired of wasting thousands of dollars a month flying your attorneys to different hearing locations? Assure Disability offers a consolidated approach to disability hearing scheduling. No more hearings scheduled days apart at the same hearing office. By scheduling multiple hearings each day at one hearing office, law firms can substantially lower their legal costs."
    />
    <Row className="mt-6">
      {serviceList.map((service, index) => (
        <Col
          lg={4}
          className={className({ 'mt-6 mt-lg-0': index > 0 })}
          key={index}
        >
          <CardService {...service} />
        </Col>
      ))}
    </Row>
  </Section>
);

export default Services;
