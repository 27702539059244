import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { userAPI } from 'utils/api/fulcrum-api';
import { toast } from 'react-toastify';


const PaymentSettings = ({ user, setUser }) => {
	let defaultFormData = {
		banking_type: user?.banking_type || '',
		banking_account_number: user?.banking_account_number || '',
		banking_routing_number: user?.banking_routing_number || '',
	};
	const [formData, setFormData] = useState(defaultFormData);
	const [previousFormData, setPreviousFormData] = useState(defaultFormData);
	const [isEditing, setIsEditing] = useState(false);

	const handleInputChange = (event) => {
		const { name, value } = event.target;

		setFormData({ ...formData, [name]: value });
	};

	// Update User
	const updateUser = async () => {
		try {
			const result = await userAPI.updateUser(user.id, formData);
			if (!result?.data) throw new Error('Error updating user');
			setUser(result.data);
			setPreviousFormData(formData);
			setIsEditing(false);
			toast.success('User updated', { theme: 'colored' });
		} catch (error) {
			console.log('Unable to update user:', error);
			toast.error('Unable to update user', { theme: 'colored' });
		}
		setLoading(false);
	};

	// Save on change
	const saveOnChange = (ev) => {
		ev.preventDefault();
		let { name, value } = ev.target;

		if (value !== previousFormData[name]) {
			updateUser({ [name]: value });
		}

		setFormData({ ...formData, [name]: value });
	};


	return (
		<div>
			<h5>Banking Information</h5>
			<Form>
				<Form.Group controlId="banking_type">
					<Form.Label>Banking Type</Form.Label>
					<Form.Control
						as="select"
						name="banking_type"
						value={formData.banking_type}
						onChange={handleInputChange}
					>
						<option value="">Select</option>
						<option value="checking">Checking</option>
						<option value="savings">Savings</option>
					</Form.Control>
				</Form.Group>

				<Form.Group controlId="banking_account_number">
					<Form.Label>Banking Account Number</Form.Label>
					<Form.Control
						type="text"
						name="banking_account_number"
						value={formData.banking_account_number}
						onChange={handleInputChange}
						onBlur={saveOnChange}
					/>
				</Form.Group>

				<Form.Group controlId="banking_routing_number">
					<Form.Label>Banking Routing Number</Form.Label>
					<Form.Control
						type="text"
						name="banking_routing_number"
						value={formData.banking_routing_number}
						onChange={handleInputChange}
						onBlur={saveOnChange}
					/>
				</Form.Group>
			</Form>
		</div>
	);
};

export default PaymentSettings;
