import React from 'react';
import PropTypes from 'prop-types';
import SimpleBarReact from 'simplebar-react';
import { Table } from 'react-bootstrap';

const AdvanceTable = ({
  getTableProps,
  headers,
  page,
  prepareRow,
  headerClassName,
  rowClassName,
  tableProps,
  headerClickFn,
  appliedSortOrders,
}) => {
  return (
    <SimpleBarReact>
      <Table {...getTableProps(tableProps)}>
        <thead className={headerClassName}>
          <tr>
            {headers.map((column, index) => {
              const foundIndex = appliedSortOrders && appliedSortOrders.findIndex(s => s[0] === column.id);
              const sortBy = foundIndex > -1 ? `${appliedSortOrders[foundIndex][1]}`.toLowerCase()
                : column.canSort
                  ? column.isSorted
                    ? column.isSortedDesc ? 'desc' : 'asc'
                    : ''
                  : '';

              return (
                <th
                  key={index}
                  {...column.getHeaderProps(
                    column.getSortByToggleProps(column.headerProps)
                  )}
                  onClick={(e) => {
                    if (column.canSort) { // trigger the react-table header onClick manually
                      column.getHeaderProps(column.getSortByToggleProps()).onClick(e);
                      if (typeof headerClickFn === 'function') { // onClick header functionality
                        headerClickFn(column.id, sortBy);
                      }
                    } else {
                      column.getHeaderProps(column.getSortByToggleProps());
                    }
                  }}
                >
                  {column.render('Header')}
                  {column.canSort ? (<span className={`sort ${sortBy}`} />) : ''}
                </th>);
            })}
          </tr>
        </thead>
        <tbody>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr key={i} className={rowClassName} {...row.getRowProps()}>
                {row.cells.map((cell, index) => {
                  return (
                    <td
                      key={index}
                      {...cell.getCellProps(cell.column.cellProps)}
                    >
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </Table>
    </SimpleBarReact >
  );
};
AdvanceTable.propTypes = {
  getTableProps: PropTypes.func,
  headers: PropTypes.array,
  page: PropTypes.array,
  prepareRow: PropTypes.func,
  headerClassName: PropTypes.string,
  rowClassName: PropTypes.string,
  tableProps: PropTypes.object,
  headerClickFn: PropTypes.func,
  appliedSortOrders: PropTypes.array
};

export default AdvanceTable;
