import React, { useState, useContext } from 'react';
import { Button, Modal, Alert, Form, Spinner } from 'react-bootstrap';
import { claimantAPI } from 'utils/api/fulcrum-api';
import { toast } from 'react-toastify';
import AppContext from 'context/Context';

const CloseFile = ({ modalState, setModalState = () => { }, claimant, setClaimant = () => { } }) => {
	const [sending, setSending] = useState(false);
	let defaultFormState = {
		email: false,
		sms: false,
		fax: false,
		mail: false
	};
	const [formData, setFormData] = useState(defaultFormState);
	const {
		config: { isDark }
	} = useContext(AppContext);

	// Handle close file submit
	const handleSubmit = async () => {
		setSending(true);
		try {
			let result = await claimantAPI.closeFile(claimant.id, formData);
			if (!result?.data) {
				throw new Error('Something went wrong.');
			}
			setClaimant(result?.data?.updatedClaimantData || claimant);
			toast.success("The file has been closed!", {
				theme: 'colored'
			});
		} catch (error) {
			toast.error("Unable to close the file.", {
				theme: 'colored'
			});
		}

		handleClose();
		setSending(false);
	};

	// handle modal close
	const handleClose = async () => {
		setModalState(false);
	};


	return (
		<Modal show={modalState} size="lg" onHide={handleClose}>
			<Modal.Header closeButton>
				<Modal.Title>Time to close the file on this one?</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Alert variant="warning">
					Warning! By closing the file, the claimant will be set to inactive and any active case will be closed.
				</Alert>

				<h6>Optionally you can send the claimant a message informing them that their case is being closed. Please select one or more of the following options.</h6>
				<Form>
					<Form.Group controlId="formBasicCheckbox">
						<Form.Check
							type="checkbox"
							label="Send as an email"
							checked={formData.email}
							name="email"
							disabled={sending}
							onChange={(e) => {
								setFormData({ ...formData, [e.target.name]: e.target.checked });
							}}
						/>
					</Form.Group>
					<Form.Group controlId="formBasicCheckbox">
						<Form.Check
							type="checkbox"
							label="Send as a SMS text message"
							checked={formData.sms}
							name="sms"
							disabled={sending}
							onChange={(e) => {
								setFormData({ ...formData, [e.target.name]: e.target.checked });
							}}
						/>
					</Form.Group>
					<Form.Group controlId="formBasicCheckbox">
						<Form.Check
							type="checkbox"
							label="Send by regular mail"
							checked={formData.mail}
							name="mail"
							disabled={sending}
							onChange={(e) => {
								setFormData({ ...formData, [e.target.name]: e.target.checked });
							}}
						/>
					</Form.Group>
				</Form>
			</Modal.Body>
			<Modal.Footer>
				<Button variant="falcon-secondary" onClick={handleClose}>Cancel</Button>
				{
					sending
						?
						<Button variant="falcon-primary" onClick={handleSubmit} disabled={sending}>
							<Spinner animation="border" role="status">
								<span className="sr-only">Closing file...</span>
							</Spinner>
						</Button>
						:
						<Button variant="falcon-primary" onClick={handleSubmit}>Yep! Close the file.</Button>
				}
			</Modal.Footer>
		</Modal>
	);
};

export default CloseFile;