import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import UserProfile from 'components/pages/users/user/profile';
import { Link } from 'react-router-dom';

const UserDetails = ({ }) => {
	return (
		<div>
			<Row>
				<Col className="mb-3">
					<h5>User Details</h5>
					<Link to="/admin/user-management" className="float-right">Back User Management</Link>
				</Col>
			</Row>

			<UserProfile siteAdminArea={true} />
		</div>
	);
};

export default UserDetails;