import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Table, Row, Col, Alert, ListGroup, Spinner } from 'react-bootstrap';
import { useNavigate, Link } from 'react-router-dom';
import AppContext from 'context/Context';
import { assureAPI, userAPI } from 'utils/api/fulcrum-api';
import { formatPhone, isValidPhone } from 'utils/functions';
import { toast } from 'react-toastify';

const LinkAtlaswareAccount = ({
	setModalState = () => { },
	user = {},
	slide,
	handleClose = (() => { }),
	setScheduleADemoModalState = (() => { }),
	setSlide = (() => { }),
	refreshParent = () => { },
	callback = () => { },
	formState,
	setFormState,
}) => {
	const [retrievingCredentials, setRetrievingCredentials] = useState(true);
	const [credentialList, setCredentialList] = useState([]);

	// Get list of account ERE credentials so the user has the oportunity to link their account before proceeding
	const getEreCredentialsList = async () => {
		setRetrievingCredentials(true);
		try {
			let result = await assureAPI.listEreCredentials();
			if (!result?.data?.data) {
				throw new Error('Unable to get a list of ERE credentials.');
			}

			setCredentialList(result.data.data);
		} catch (error) {
			setSlide(slide + 1);
		}
		setRetrievingCredentials(false);
	};

	// Link existing Atlasware account to current user
	const linkExistingAccount = async (selectedAtlaswareAccount) => {
		try {
			// update the user with the new ERE credential ID and Attorney Name
			let result = await userAPI.updateUser(user.id, {
				assure_ere_credentials_id: selectedAtlaswareAccount?.id || null,
				assure_ere_attorney_name: selectedAtlaswareAccount?.attorney_name || null,
			});

			if (!result?.data) {
				throw new Error('Unable to update user.');
			}

			toast.success('Your existing Atlasware account has been linked to your Fulcrum user.', { theme: 'colored' });
			refreshParent();
			callback();
		} catch (error) {
			toast.error('There was an error linking your existing Atlasware account. Please reach out to support for assitance.', { theme: 'colored' });
		}
		handleClose();
	};

	useEffect(() => {
		getEreCredentialsList();
	}, []);

	return (
		<div>
			{
				retrievingCredentials ?
					<>
						<Spinner animation="border" role="status">
							<span className="sr-only">Check Assure for existing Atlasware accounts...</span>
						</Spinner>
					</>
					:
					<>
						<h5>Link an Existing Atlasware Account</h5>
						<br />
						<p>Before proceeding, lets check to see if you have already setup your Atlasware account with Assure.</p>

						{
							Array.isArray(credentialList) && credentialList.length > 0 ?
								<>
									<p>We found existing Atlasware accounts. If one of these are yours, select "Link Account" to complete Atlasware setup.</p>
									<Table>
										<thead>
											<tr>
												<th>Attorney Name</th>
												<th></th>
											</tr>
										</thead>
										<tbody>
											{
												credentialList.map((credential, index) => {
													return (
														<tr key={index}>
															<td>{credential.attorney_name}</td>
															<td className="text-right">
																<span
																	className="float-end cursor-pointer text-primary"
																	onClick={() => linkExistingAccount(credential)}
																>
																	Link Account
																</span>
															</td>
														</tr>
													);
												})
											}
										</tbody>
									</Table>
									<br />

									<div className="text-center">
										<Button
											variant="primary"
											onClick={() => setSlide(slide + 1)}
										>
											None of these are mine.
										</Button>
									</div>
								</>
								:
								<>
									<p>It looks like you have not setup your Atlasware account with Assure. Please click the button below to setup your account.</p>
									<div className="text-center">
										<Button
											variant="primary"
											size="lg"
											onClick={() => setSlide(slide + 1)}
										>
											Setup Account
										</Button>
									</div>
								</>
						}
					</>
			}
		</div>
	);
};

export default LinkAtlaswareAccount;