import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Button, Table, Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { assureAPI } from 'utils/api/fulcrum-api';


const EREFileList = ({ selectedCase, setSelectedCase = () => { }, claimant, setClaimant = () => { }, refreshParent = () => { } }) => {
	const [loading, setLoading] = useState(false);
	const [requested, setRequested] = useState(false);
	const [allFiles, setAllFiles] = useState(selectedCase?.ere_case_file_list ? JSON.parse(selectedCase.ere_case_file_list) : []);
	const [ereFileList, setEREFileList] = useState({
		'Section A. Payment Documents/Decisions': {
			files: []
		},
		'Section B. Jurisdictional Documents/Notices': {
			files: []
		},
		'Section D. Non-Disability Development': {
			files: []
		},
		'Section E. Disability Related Development': {
			files: []
		},
		'Section F. Medical Records': {
			files: []
		}
	});
	const [params, setParams] = useState({
		viewType: 'All',
	});
	const [errors, setErrors] = useState([]);

	// Format ERE File List
	const formatEREFileList = async (list = [], filterType) => {
		setLoading(true);
		let newList = [];
		switch (filterType) {
			case 'Exhibited':
				newList = list.filter(r => r?.exhibit_number);
				break;
			case 'Unexhibited':
				newList = list.filter(r => !r?.exhibit_number);
				break;
			case 'All':
			default:
				newList = list;
		}
		setEREFileList({
			'Section A. Payment Documents/Decisions': {
				files: newList.filter(r => r?.section_name === "Section A. Payment Documents/Decisions") || [],
				file_count: newList.filter(r => r?.section_name === "Section A. Payment Documents/Decisions").length || 0
			},
			'Section B. Jurisdictional Documents/Notices': {
				files: newList.filter(r => r?.section_name === "Section B. Jurisdictional Documents/Notices") || [],
				file_count: newList.filter(r => r?.section_name === "Section B. Jurisdictional Documents/Notices").length || 0
			},
			'Section D. Non-Disability Development': {
				files: newList.filter(r => r?.section_name === "Section D. Non-Disability Development") || [],
				file_count: newList.filter(r => r?.section_name === "Section D. Non-Disability Development").length || 0
			},
			'Section E. Disability Related Development': {
				files: newList.filter(r => r?.section_name === "Section E. Disability Related Development") || [],
				file_count: newList.filter(r => r?.section_name === "Section E. Disability Related Development").length || 0
			},
			'Section F. Medical Records': {
				files: newList.filter(r => r?.section_name === "Section F. Medical Records") || [],
				file_count: newList.filter(r => r?.section_name === "Section F. Medical Records").length || 0
			}
		});
		setLoading(false);
	};

	// Get ERE File List
	const getEREFileList = async () => {
		setLoading(true);
		try {
			let result = await assureAPI.getEREFileList(selectedCase?.claimant_id || null, selectedCase?.id || null, params);
			if (!result?.data?.data || !Array.isArray(result?.data?.data)) {
				throw 'There was an error getting the ERE file list.';
			}

			setAllFiles([]);
			setAllFiles(result?.data?.data || []);
			toast.success('Successfully retrieved the ERE file list.', { theme: 'colored' });
		} catch (error) {
			toast.error('There was an error getting the ERE file list.', { theme: 'colored' });
			setErrors(['Unable to load the ERE file list at this time. Please try again later.']);
		}
		setLoading(false);
	};


	useEffect(() => {
		formatEREFileList(allFiles, params?.viewType);
	}, [allFiles]);

	return (
		<div>
			{
				selectedCase?.assure_case_id
					?
					(
						<div className="my-5 mx-2">
							{
								(
									<div>

										{
											(!selectedCase?.ere_case_file_list_last_stored)
												?
												(
													<div className="py-5 px-3 bg-200 border-rounded text-center">

														<h4>Case Files</h4>
														<hr />
														<h5 className=" fw-bold">No ERE files found.</h5>
														<p>Either there is no case file or you have not attempted to pull the ERE files before. Please try pulling the latest list of ERE files.</p>
														<Button
															variant="primary"
															onClick={() => {
																getEREFileList(selectedCase.assure_case_id);
															}}
															disabled={loading}
														>
															{
																loading
																	?
																	(
																		<>
																			<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
																			<span className="">Attempting to pull ERE File List...</span>
																		</>
																	)
																	:
																	<>
																		<span>Attempt to Pull ERE File List</span>
																	</>
															}
														</Button>
													</div>
												)
												:
												(
													<div>

														<h4 className="d-inline">Case Files</h4>
														<Button
															variant="link"
															className="float-end"
															onClick={() => {
																getEREFileList(selectedCase.assure_case_id);
															}}
															disabled={loading}
														>
															{
																loading
																	?
																	(
																		<>
																			<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
																			<span className="">Refreshing...</span>
																		</>
																	)
																	:
																	<>
																		<span>Refresh</span>
																	</>
															}
														</Button>
														<hr />
														<span className="fw-bold">Last Updated:</span> {moment(selectedCase?.ere_case_file_list_last_stored).format('MM/DD/YYYY hh:mm A')}
														<Form.Group className="my-3">
															<Form.Label>Filter by type:</Form.Label>
															<Form.Control
																as="select"
																value={params.viewType}
																name="viewType"
																onChange={(e) => {
																	setParams({
																		...params,
																		viewType: e.target.value
																	});

																	formatEREFileList(allFiles, e.target.value);
																}}
															>
																<option value="All">All Case Files</option>
																<option value="Exhibited">Exhibited Files Only</option>
																<option value="Unexhibited">Unexhibited Files</option>
															</Form.Control>
														</Form.Group>

														<div className="my-5">
															<h5>Section A. Payment Documents/Decisions</h5>
															<Table striped>
																<thead className="bg-300">
																	<tr>
																		<th>
																			Document Name (ID)
																		</th>
																		<th>
																			Decision/Disposition Date
																		</th>
																		<th>
																			Exhibit
																		</th>
																		<th>
																			EF Received
																		</th>
																		<th>
																			Pg
																		</th>
																	</tr>
																</thead>
																<tbody>
																	{
																		ereFileList?.['Section A. Payment Documents/Decisions']?.files.length
																			?
																			ereFileList?.['Section A. Payment Documents/Decisions']?.files.map(r => (
																				<tr key={`${r?.case_id}_${r?.id}_section_a`}>
																					<td>
																						{r?.file_title || ''}
																					</td>
																					<td>
																						{r?.date_type === "Dec. Dt." ? (r?.from_date ? moment(r?.from_date).format('MM/DD/YYYY') : "") : ''}
																					</td>
																					<td>
																						{r?.exhibit_number || ''}
																					</td>
																					<td>
																						{r?.ef_receive_date ? moment(r.ef_receive_date).format('MM/DD/YYYY') : ''}
																					</td>
																					<td>
																						{r?.pages || ''}
																					</td>
																				</tr>
																			))
																			:
																			<tr>
																				<td colSpan="5">
																					No Files Found
																				</td>
																			</tr>
																	}
																</tbody>
															</Table>
														</div>
														<div className="my-5">
															<h5>Section B. Jurisdictional Documents/Notices</h5>
															<Table striped>
																<thead className="bg-300">
																	<tr>
																		<th>
																			Document Name (ID)
																		</th>
																		<th>
																			Document Date
																		</th>
																		<th>
																			Exhibit
																		</th>
																		<th>
																			EF Received
																		</th>
																		<th>
																			Pg
																		</th>
																	</tr>
																</thead>
																<tbody>
																	{
																		ereFileList?.['Section B. Jurisdictional Documents/Notices']?.files.length
																			?
																			ereFileList?.['Section B. Jurisdictional Documents/Notices']?.files.map(r => (
																				<tr key={`${r?.case_id}_${r?.id}_section_b`}>
																					<td>
																						{r?.file_title || ''}
																					</td>
																					<td>
																						{r?.date_type === "Doc. Dt." ? (r?.from_date ? moment(r?.from_date).format('MM/DD/YYYY') : "") : ''}
																					</td>
																					<td>
																						{r?.exhibit_number || ''}
																					</td>
																					<td>
																						{r?.ef_receive_date ? moment(r.ef_receive_date).format('MM/DD/YYYY') : ''}
																					</td>
																					<td>
																						{r?.pages || ''}
																					</td>
																				</tr>
																			))
																			:
																			<tr>
																				<td colSpan="5">
																					No Files Found
																				</td>
																			</tr>
																	}
																</tbody>
															</Table>

														</div>
														<div className="my-5">
															<h5>Section D. Non-Disability Development</h5>
															<Table striped>
																<thead className="bg-300">
																	<tr>
																		<th>
																			Document Name (ID)
																		</th>
																		<th>
																			Document Date
																		</th>
																		<th>
																			Exhibit
																		</th>
																		<th>
																			EF Received
																		</th>
																		<th>
																			Pg
																		</th>
																	</tr>
																</thead>
																<tbody>
																	{
																		ereFileList?.['Section D. Non-Disability Development']?.files.length
																			?
																			ereFileList?.['Section D. Non-Disability Development']?.files.map(r => (
																				<tr key={`${r?.case_id}_${r?.id}_section_d`}>
																					<td>
																						{r?.file_title || ''}
																					</td>
																					<td>
																						{r?.date_type === "Doc. Dt." ? (r?.from_date ? moment(r?.from_date).format('MM/DD/YYYY') : "") : ''}
																					</td>
																					<td>
																						{r?.exhibit_number || ''}
																					</td>
																					<td>
																						{r?.ef_receive_date ? moment(r.ef_receive_date).format('MM/DD/YYYY') : ''}
																					</td>
																					<td>
																						{r?.pages || ''}
																					</td>
																				</tr>
																			))
																			:
																			<tr>
																				<td colSpan="5">
																					No Files Found
																				</td>
																			</tr>
																	}
																</tbody>
															</Table>

														</div>
														<div className="my-5">
															<h5>Section E. Disability Related Development</h5>
															<Table striped>
																<thead className="bg-300">
																	<tr>
																		<th>
																			Document Name (ID)
																		</th>
																		<th>
																			Source
																		</th>
																		<th>
																			Date From
																		</th>
																		<th>
																			Date To
																		</th>
																		<th>
																			Exhibit
																		</th>
																		<th>
																			EF Received
																		</th>
																		<th>
																			Pg
																		</th>
																	</tr>
																</thead>
																<tbody>
																	{
																		ereFileList?.['Section E. Disability Related Development']?.files.length
																			?
																			ereFileList?.['Section E. Disability Related Development']?.files.map(r => (
																				<tr key={`${r?.case_id}_${r?.id}_section_d`}>
																					<td>
																						{r?.file_title || ''}
																					</td>
																					<td>
																						{r?.source || ''}
																					</td>
																					<td>
																						{r?.date_from ? moment(r.date_from).format('MM/DD/YYYY') : ''}
																					</td>
																					<td>
																						{r?.date_to ? moment(r.date_to).format('MM/DD/YYYY') : ''}
																					</td>
																					<td>
																						{r?.exhibit_number || ''}
																					</td>
																					<td>
																						{r?.ef_receive_date ? moment(r.ef_receive_date).format('MM/DD/YYYY') : ''}
																					</td>
																					<td>
																						{r?.pages || ''}
																					</td>
																				</tr>
																			))
																			:
																			<tr>
																				<td colSpan="5">
																					No Files Found
																				</td>
																			</tr>
																	}
																</tbody>
															</Table>

														</div>
														<div className="my-5">
															<h5>Section F. Medical Records</h5>
															<Table striped>
																<thead className="bg-300">
																	<tr>
																		<th>
																			Document Name (ID)
																		</th>
																		<th>
																			Source
																		</th>
																		<th>
																			Date From
																		</th>
																		<th>
																			Date To
																		</th>
																		<th>
																			Exhibit
																		</th>
																		<th>
																			EF Received
																		</th>
																		<th>
																			Pg
																		</th>
																	</tr>
																</thead>
																<tbody>
																	{
																		ereFileList?.['Section F. Medical Records']?.files.length
																			?
																			ereFileList?.['Section F. Medical Records']?.files.map(r => (
																				<tr key={`${r?.case_id}_${r?.id}_section_f`}>
																					<td>
																						{r?.file_title || ''}
																					</td>
																					<td>
																						{r?.source || ''}
																					</td>
																					<td>
																						{r?.date_from ? moment(r.date_from).format('MM/DD/YYYY') : ''}
																					</td>
																					<td>
																						{r?.date_to ? moment(r.date_to).format('MM/DD/YYYY') : ''}
																					</td>
																					<td>
																						{r?.exhibit_number || ''}
																					</td>
																					<td>
																						{r?.ef_receive_date ? moment(r.ef_receive_date).format('MM/DD/YYYY') : ''}
																					</td>
																					<td>
																						{r?.pages || ''}
																					</td>
																				</tr>
																			))
																			:
																			<tr>
																				<td colSpan="5">
																					No Files Found
																				</td>
																			</tr>
																	}
																</tbody>
															</Table>
														</div>
													</div>
												)
										}



									</div>
								)
							}
						</div>
					) :
					(
						<></>
					)
			}
		</div>
	);
};

export default EREFileList;