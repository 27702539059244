import React, { useContext, useState } from 'react';
import { Modal, Form, Button, Row, Col, Alert, Table } from 'react-bootstrap';
import AppContext from 'context/Context';
import { customFieldAPI } from 'utils/api/fulcrum-api';
import { arrayEqualsCheck, sleep } from 'utils/functions';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import auth from 'utils/auth';

const CustomFieldDetails = ({ account_id = auth.getProfile()?.data?.account_id, selectedCustomField, setSelectedCustomField = () => { }, modalState, setModalState = () => { }, refreshParent = () => { } }) => {
	const {
		config: { isDark }
	} = useContext(AppContext);
	const [loading, setLoading] = useState(true);
	const [saving, setSaving] = useState(false);
	const [viewsOptions, setViewsOptions] = useState([]);
	const [dropdownOptions, setDropdownOptions] = useState([]);
	let defaultFormData = {
		label: '',
		model: '',
		field_type: 'text',
		description: '',
		searchable: false,
		visible: true,
		editable: true,
		enabled: true,
		required: false,
		encrypt: false,
		options: [],
		views: '',
		stringified_options: JSON.stringify("")
	};
	const [formData, setFormData] = useState(defaultFormData);
	const [previousFormData, setPreviousFormData] = useState(defaultFormData);

	// Get model view options
	const getModelViewOptions = async (model, currentViews = []) => {
		let additionalData = {};
		switch (model) {
			case 'CLAIMANT':
				setViewsOptions([
					{
						label: 'Claimant Details',
						value: 'CLAIMANT_DETAILS',
						checked: currentViews.includes('CLAIMANT_DETAILS') || false
					},
					{
						label: 'Claimant Bio',
						value: 'CLAIMANT_BIO',
						checked: currentViews.includes('CLAIMANT_BIO') || false
					}
				]);
				additionalData = { views: 'CLAIMANT_DETAILS' };
				break;
			case 'USER':
				setViewsOptions([
					{
						label: 'User Details',
						value: 'USER_DETAILS',
						checked: currentViews.includes('USER_DETAILS') || false
					}
				]);
				additionalData = { views: 'USER_DETAILS' };
				break;
			case 'CASE':
				setViewsOptions([
					{
						label: 'Case Details',
						value: 'CASE_DETAILS',
						checked: currentViews.includes('CASE_DETAILS') || false
					}
				]);
				additionalData = { views: 'CASE_DETAILS' };
				break;
			case 'HEARING':
				setViewsOptions([
					{
						label: 'Hearing Details',
						value: 'HEARING_DETAILS',
						checked: currentViews.includes('HEARING_DETAILS') || false
					}
				]);
				additionalData = { views: 'HEARING_DETAILS' };
				break;
			case 'ACCOUNT':
				setViewsOptions([
					{
						label: 'Account Details',
						value: 'ACCOUNT_DETAILS',
						checked: currentViews.includes('ACCOUNT_DETAILS') || false
					}
				]);
				additionalData = { views: 'ACCOUNT_DETAILS' };
				break;
			default:
				setViewsOptions([]);
				additionalData = { views: '' };
				break;
		}

		return additionalData;
	}

	// Handle form change
	const handleChange = async (e) => {
		const { name, value, type, checked } = e.target;
		let additionalData = {};

		if (name === 'model') {
			let result = await getModelViewOptions(value);
			additionalData = {
				...additionalData,
				...result
			};
		} else if (name.includes("views")) {
			let editedViewCheck = name.split("::")[1];

			if (checked) {
				additionalData = {
					views: formData.views + `${formData?.views ? "," : ''}` + editedViewCheck
				};
			} else {
				let newViews = formData.views.split(",").filter(view => view !== editedViewCheck);
				additionalData = {
					views: newViews.join(",")
				};
			}

			setViewsOptions([
				...viewsOptions.map((view) => {
					if (view.value === editedViewCheck) {
						return {
							...view,
							checked: checked
						};
					} else {
						return view;
					}
				})
			]);
		}

		setFormData((prev) => ({
			...prev,
			...additionalData,
			[name]: type === 'checkbox' ? checked : value
		}));
	};

	// Handle cancel
	const handleCancel = () => {
		setFormData(previousFormData);
	};

	// Update Custom Field
	const handleUpdate = async () => {
		try {
			setSaving(true);
			let result = await customFieldAPI.updateCustomField(selectedCustomField?.account_id || null, selectedCustomField?.id || null, {
				...formData,
				options: JSON.stringify(formData.options)
			});
			if (!result?.data) {
				throw new Error('No custom field data found');
			}
			toast.success("Custom field updated!", {
				theme: 'colored'
			});

			setPreviousFormData(formData);
			refreshParent();
		} catch (error) {
			console.log(error);
			toast.error(error, {
				theme: 'colored'
			});
		}
		setSaving(false);
	};

	// Create Custom Field
	const handleCreate = async () => {
		try {
			setSaving(true);
			let result = await customFieldAPI.createCustomField(account_id, { ...formData, options: JSON.stringify(formData.options) });
			if (!result?.data) {
				throw new Error('No custom field data found');
			}
			toast.success("Custom field created!", {
				theme: 'colored'
			});

			refreshParent();
			handleClose();
			setSelectedCustomField(result?.data);
			handleShow();
		} catch (error) {
			console.log(error);
			toast.error(error, {
				theme: 'colored'
			});
		}
		setSaving(false);
	};

	// Handle close
	const handleClose = () => {
		setSaving(false);
		setSelectedCustomField({});
		setLoading(true);
		setFormData(defaultFormData);
		setPreviousFormData(defaultFormData);
		setModalState(false);
	};

	// Handle Show
	const handleShow = async () => {
		if (selectedCustomField?.id) {
			await getModelViewOptions(selectedCustomField?.model, selectedCustomField?.views ? selectedCustomField.views.split(',') : []);
		}
		let newFormData = {
			label: selectedCustomField?.label || '',
			model: selectedCustomField?.model || '',
			field_type: selectedCustomField?.field_type || 'text',
			description: selectedCustomField?.description || '',
			searchable: selectedCustomField?.searchable || false,
			visible: selectedCustomField?.visible || false,
			editable: selectedCustomField?.editable || false,
			enabled: selectedCustomField?.enabled || false,
			required: selectedCustomField?.required || false,
			encrypt: selectedCustomField?.encrypt || false,
			views: selectedCustomField?.views || '',
			options: selectedCustomField?.options ? JSON.parse(selectedCustomField.options) : [],
			stringified_options: JSON.stringify(selectedCustomField?.options ? JSON.parse(selectedCustomField.options) : [])
		};
		setFormData(newFormData);
		setPreviousFormData(newFormData);
		setModalState(true);
		setLoading(false);
	};


	return (
		<Modal
			show={modalState}
			onHide={handleClose}
			onShow={handleShow}
			contentClassName="border"
			backdrop="static"
			size="lg"
		>
			<Form>
				<Modal.Header
					closeButton
					closeVariant={isDark ? 'white' : undefined}
					className="bg-light px-card border-bottom-0 bg-300"
				>
					<Modal.Title as="h5">Custom Field</Modal.Title>
				</Modal.Header>
				<Modal.Body className="p-card bg-100">
					{
						loading
							?
							<div>Loading...</div>
							:
							<div>
								{
									selectedCustomField?.id
										?
										<>
											{
												(
													JSON.stringify(formData.options) !== JSON.stringify(previousFormData.options) ||
													JSON.stringify(previousFormData) !== JSON.stringify(formData)
												) &&

												<Alert variant="warning">
													<Alert.Heading>Unsaved Changes</Alert.Heading>
													<p>
														You have unsaved changes. Click "Save Changes" below if you would like to apply them or click "Cancel" to revert back.
													</p>
												</Alert>
											}
										</>
										:
										<>
											<Alert variant="info">
												<Alert.Heading>Custom Field</Alert.Heading>
												<p>
													To create a custom field, select a context you would like the custom field to belong to. Custom fields can be used to store additional information about a model that isn't there by default.
												</p>
											</Alert>
										</>
								}
								<Form.Group className="mb-3" controlId="model">
									<Form.Label className="fs-0 fw-bold">Context*</Form.Label>
									<Form.Select
										name="model"
										defaultValue={''}
										value={formData.model || null}
										onChange={handleChange}
										disabled={saving || selectedCustomField?.id}
										required
									>
										<option value={""} disabled>--Select One--</option>
										<option value={"CLAIMANT"}>Claimant</option>
										<option value={"CASE"}>Case</option>
										<option value={"USER"}>User</option>
										<option value={"ACCOUNT"}>Account</option>
									</Form.Select>
								</Form.Group>

								{
									!formData.model
										?
										<div className="text-center">
											<p className="my-5">Please select a context</p>
										</div>
										:
										<>


											<Form.Group className="mb-3" controlId="label">
												<Form.Label className="fs-0 fw-bold">Label*</Form.Label>
												<Form.Control
													name="label"
													type="text"
													value={formData.label || ''}
													onChange={handleChange}
													required
													disabled={saving}
												/>
											</Form.Group>

											<Form.Group className="mb-3" controlId="description">
												<Form.Label className="fs-0 fw-bold">Description</Form.Label>
												<Form.Control
													name="description"
													as="textarea"
													rows={3}
													value={formData.description || ''}
													onChange={handleChange}
													disabled={saving}
												/>
											</Form.Group>

											<Form.Group className="mb-3" controlId="field_type">
												<Form.Label className="fs-0 fw-bold">Type*</Form.Label>
												<Form.Select
													name="field_type"
													defaultValue={''}
													value={formData.field_type || null}
													onChange={handleChange}
													disabled={saving}
													required
												>
													<option value={""}>--Select One--</option>
													<option value={"text"}>Text</option>
													<option value={"textarea"}>Textarea</option>
													<option value={"number"}>Number</option>
													<option value={"decimal"}>Decimal</option>
													<option value={"date"}>Date</option>
													<option value={"checkbox"}>Checkbox</option>
													<option value={"dropdown"}>Dropdown</option>
													<option value={"radio"}>Radio</option>
												</Form.Select>
											</Form.Group>

											{
												['dropdown', 'radio', 'checkbox'].includes(formData.field_type)
													?
													<div className="mt-4">
														<Form.Label className="fs-0 fw-bold">Options</Form.Label>
														{/* <br /> */}
														<Button variant="falcon-primary" className="float-end" onClick={() => { setFormData({ ...formData, options: [...formData.options, { name: '', value: '', order: formData.options.length + 1, enabled: true }] }) }}>+ Add Option</Button>
														<Table striped bordered hover size="sm">
															<thead>
																<tr>
																	<th>Enabled</th>
																	<th>Name</th>
																	<th>Value</th>
																	<th>Order</th>
																	<th></th>
																</tr>
															</thead>
															<tbody>
																{
																	Array.isArray(formData.options) && formData?.options.map((option, index) => (
																		<tr key={index}>
																			<td className={
																				`text-center align-middle ${saving ? 'd-none' : ''}`
																			}>
																				<Form.Check
																					name={`dropdownOptions::${index}::enabled`}
																					type='checkbox'
																					id={`dropdownOptions::${index}::enabled`}
																					label=""
																					checked={option.enabled}
																					className=""
																					style={{
																						// Increase size
																						fontSize: '1.5em',
																					}}
																					onChange={e => {
																						const newOptions = [...formData.options];
																						newOptions[index].enabled = e.target.checked;

																						setFormData({ ...formData, options: newOptions, stringified_options: JSON.stringify(newOptions) })
																					}}
																					disabled={saving}
																				/>
																			</td>
																			<td colSpan={2}>
																				<Form.Group className="mb-3" controlId={`dropdownOptions::${index}::name`}>
																					<Form.Label className="fs-0">Name</Form.Label>
																					<Form.Control
																						name={`dropdownOptions::${index}::name`}
																						type="text" value={option.name || ''}
																						onChange={e => {
																							const newOptions = [...formData.options];
																							newOptions[index].name = e.target.value;
																							setFormData({ ...formData, options: newOptions, stringified_options: JSON.stringify(newOptions) })
																						}}
																						required
																						disabled={saving}
																					/>
																				</Form.Group>
																			</td>
																			<td colSpan={2}>
																				<Form.Group className="mb-3" controlId={`dropdownOptions::${index}::value`}>
																					<Form.Label className="fs-0">Value</Form.Label>
																					<Form.Control
																						name={`dropdownOptions::${index}::value`}
																						type="text" value={option.value || ''}
																						onChange={e => {
																							const newOptions = [...formData.options];
																							newOptions[index].value = e.target.value;
																							setFormData({ ...formData, options: newOptions, stringified_options: JSON.stringify(newOptions) })
																						}}
																						required
																						disabled={saving}
																					/>
																				</Form.Group>
																			</td>
																			<td>
																				<Form.Group className="mb-3" controlId={`dropdownOptions::${index}::order`}>
																					<Form.Label className="fs-0">Order</Form.Label>
																					<Form.Control
																						name={`dropdownOptions::${index}::order`}
																						type="number" value={option.order || ''}
																						onChange={async function (e) {
																							const newOptions = [...formData.options];
																							newOptions[index].order = e.target.value;
																							// re-order the options
																							newOptions.sort((a, b) => a.order - b.order);
																							setFormData({ ...formData, options: newOptions, stringified_options: JSON.stringify(newOptions) });

																							// leave focus
																							await sleep(200);
																							e.target.blur();
																						}}
																						required
																						disabled={saving}
																					/>
																				</Form.Group>
																			</td>
																			<td className={
																				`text-center align-middle ${saving ? 'd-none' : ''}`
																			}>
																				<Button
																					variant="outline-danger"
																					onClick={() => {
																						const newOptions = [...formData.options];
																						newOptions.splice(index, 1);
																						setFormData({ ...formData, options: newOptions, stringified_options: JSON.stringify(newOptions) })
																					}}
																					disabled={saving}
																				>
																					<FontAwesomeIcon icon={'trash'} />
																				</Button>
																			</td>

																			<hr />
																		</tr>
																	))
																}
															</tbody>
														</Table>
													</div>
													:
													null
											}

											<hr />

											<Form.Group>
												<Form.Label className="fs-0 fw-bold">Settings</Form.Label>
												<Form.Check
													name="enabled"
													type='checkbox'
													id="enabled"
													label="Enabled"
													checked={formData.enabled}
													onChange={handleChange}
													disabled={saving}
												/>
												<Form.Check
													name="required"
													type='checkbox'
													id="required"
													label="Required"
													checked={formData.required}
													onChange={handleChange}
													disabled={saving}
												/>
												<Form.Check
													name="searchable"
													type='checkbox'
													id="searchable"
													label="Searchable"
													checked={formData.searchable}
													onChange={handleChange}
													disabled={saving}
												/>

											</Form.Group>
											<hr />
											<Form.Group>
												<Form.Label className="fs-0 fw-bold">Views</Form.Label>
												<br />
												<span>Select where you would like to see the custom field on the layout.</span>
												<br />
												<br />
												{

													Array.isArray(viewsOptions) && viewsOptions.map(vo => (
														<Form.Check
															name={`views::${vo.value}`}
															type='checkbox'
															id={`views::${vo.value}`}
															label={vo.label}
															checked={vo.checked}
															onChange={handleChange}
															key={vo.value}
															disabled={saving}
														/>
													))
												}
											</Form.Group>


										</>
								}
							</div>
					}
				</Modal.Body>
				<Modal.Footer className="bg-light px-card border-top-0 bg-300">
					<Button variant="outline-secondary" onClick={handleClose}>
						Close
					</Button>

					{
						!loading && (
							selectedCustomField?.id
								?
								<>
									{
										(
											JSON.stringify(formData.options) !== JSON.stringify(previousFormData.options) ||
											JSON.stringify(previousFormData) !== JSON.stringify(formData) ||
											!arrayEqualsCheck(formData.views, previousFormData.views)
										) && (
											<>
												<Button variant="outline-danger" onClick={handleCancel}>
													Cancel
												</Button>
												<Button variant="primary" onClick={handleUpdate}>
													Save Changes
												</Button>
											</>
										)
									}
								</>
								:
								<>
									{
										formData?.model && formData?.field_type && formData?.label && (
											<Button variant="primary" onClick={() => handleCreate()}>
												Create
											</Button>
										)
									}
								</>
						)
					}
				</Modal.Footer>
			</Form>
		</Modal>
	);
};


export default CustomFieldDetails;