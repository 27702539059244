import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import AppContext from 'context/Context';
import { Modal, Form, Row, Col, Alert } from 'react-bootstrap';
import IconButton from 'components/common/IconButton';
import { useForm } from 'react-hook-form';
import StripePaymentForm from '../stripe/StripePaymentForm';
import WizardInput from '../wizard/WizardInput';
import { stateData } from '../../data/stateData';
import Auth from 'utils/auth';
import { removeEmptyFromObj, renameKeys, diffObjects, formatDate } from 'utils/functions';

let defaultFormData = {
	id: null,                    // id in PaymentMethod table
	user_id: null,               // id in user table that can be used to create new stripe customer if needed 
	email: null,                 // email in user table that can be used to create new stripe customer if needed
	stripe_customer_id: null,    // id in StripeCustomer table
	card_id: null,               // card_id on Stripe 
	payment_method_id: null,     // Looks the same as card_id but get's created on StripePaymentForm when onCapture is called 
	token_id: null,              // Is created on StripePaymentForm when onCapture is called
	brand: null,                 // brand for card on Stripe
	customer_id: null,           // customer_id on Stripe 
	is_primary: null,            // Boolean value in PaymentMethod table
	name: '',                    // name for card on Stripe
	exp_month: '',               // exp_month for card on Stripe
	exp_year: '',                // exp_year for card on Stripe
	billing_address_address: '', // address_line1
	billing_address_city: '',    // address_city
	billing_address_state: '',   // address_state
	billing_address_zip: '',     // address_zip
};
const renamedKeyMap = { // old_key: new_key 
	billing_address_address: 'address_line1',
	billing_address_city: 'address_city',
	billing_address_state: 'address_state',
	billing_address_zip: 'address_zip'
};

const PaymentDetails = ({ page, selectedRowIds, modalState, setModalState = () => { }, refreshParent = () => { }, handleUpdate = () => { }, handleCreate = () => { } } = {}) => {
	const { config: { isDark } } = useContext(AppContext);
	const [cardInformationValid, setCardInformationValid] = useState(false);
	const [previousFormData, setPreviousFormData] = useState({});
	const [modalAlert, setModalAlert] = useState({ message: "", alert_type: "info" });
	const [showAlert, setShowAlert] = useState(false);
	const [modalTitle, setModalTitle] = useState("Add Payment Method");
	const {
		reset,
		register,
		handleSubmit,
		formState: { errors },
		watch,
		useWatch,
		setValue,
		getValues,
		setError,
		clearErrors
	} = useForm();

	const validateFormData = (data) => {
		let alertObj = { message: "", alert_type: "info" };
		let isValid = Object.entries(data).every(([key, value]) => {
			if (value && value.length > 0) {
				if (key === 'exp_month') {
					if (value.length === 2) {
						return true;
					}
					alertObj.message += "Expiration month must be 2 digits. ";
					return false;
				} else if (key === 'exp_year') {
					if (value.length === 4) {
						return true;
					}
					alertObj.message += "Expiration year must be 4 digits. ";
					return false;
				} else if (key === 'name') {
					if (value.length > 1) {
						return true;
					}
					alertObj.message += "Name must be at least 2 characters. ";
					return false;
				} else if (key === 'billing_address_address') {
					if (value.length > 4) {
						return true;
					}
					alertObj.message += "Address must be at least 5 characters. ";
					return false;
				} else if (key === 'billing_address_city') {
					if (value.length > 2) {
						return true;
					}
					alertObj.message += "City must be at least 3 characters. ";
					return false;
				} else if (key === 'billing_address_state') {
					if (value.length === 2) {
						return true;
					}
					alertObj.message += "State must be 2 characters. ";
					return false;
				} else if (key === 'billing_address_zip') {
					if (value.length > 4) {
						return true;
					}
					alertObj.message += "Zip must be at least 5 characters. ";
					return false;
				}
			}
			return true;
		});

		if (isValid) {
			clearErrors();
			alertObj.alert_type = "success";
			alertObj.message = "All fields are valid!";
		} else {
			alertObj.alert_type = "danger";
			alertObj.message = alertObj.message.trim();
		}

		setShowAlert(alertObj.message);
		setModalAlert(prev => ({ ...prev, ...alertObj }));
		setCardInformationValid(isValid);
		return isValid;
	};

	const formatValues = (data) => {
		let NumberFields = ['id', 'stripe_customer_id', 'is_primary', 'exp_month', 'exp_year'];
		let BooleanFields = [];
		let dateFields = [];

		return Object.entries(data).reduce((acc, [key, value]) => {
			if (NumberFields.includes(key)) {
				acc[key] = Number(value);
			} else if (BooleanFields.includes(key)) {
				if (typeof value === 'string') {
					acc[key] = Boolean(value.toLowerCase() === 'true');
				} else {
					acc[key] = Boolean(value);
				}
			} else if (dateFields.includes(key)) {
				let parsedDate = typeof value === "string" ? new Date(value) : Number(value);
				if (isNaN(parsedDate)) {
					parsedDate = Date.parse(value);
				}
				if (isNaN(parsedDate)) {
					parsedDate = value;
				}
				acc[key] = formatDate(parsedDate, "YYYY-MM-DD HH:mm:ss");
			} else {
				acc[key] = value;
			}
			return acc;
		}, {});
	};

	const onSubmitData = async (data = {}) => {
		// console.log("onSubmitData", data);
		if (!validateFormData(data) || !data || data && Object.keys(data).length === 0) {
			return
		}

		// Get all required values 
		let { id, user_id, email, stripe_customer_id, customer_id, card_id } = data || {};
		let requiredValues = { user_id, email, stripe_customer_id, customer_id, card_id };

		let changedValues = diffObjects(previousFormData, data);
		changedValues = renameKeys(renamedKeyMap, changedValues);
		changedValues = removeEmptyFromObj({ ...requiredValues, ...changedValues });

		if (id && !data?.token_id) {
			changedValues = formatValues(changedValues);
			handleUpdate(id, changedValues);
		} else { // Will use token_id to add new card to stripe customer
			handleCreate(changedValues);
		}
	};

	const onError = async (data) => {
		let invalidFieldList = Object.keys(data) || [];
		let message = `Please fix the errors in the form [${invalidFieldList.join(", ")}]`;
		setModalAlert((prev) => ({ ...prev, alert_type: "danger", message }));
		setShowAlert(true);
	};

	const onCaptureStripeCard = async (token_id, orderData, payloadOptions) => {
		if (token_id) {
			setModalAlert((prev) => ({ ...prev, alert_type: "info", message: "Card information is appears to be valid." }));
		} else {
			setModalAlert((prev) => ({ ...prev, alert_type: "warning", message: "Card information might be invalid. Please double check the provided info!" }));
			console.log("onCaptureStripeCard", token_id, orderData, payloadOptions);
		}
		setShowAlert(true);
	};

	const openModal = () => (setModalState(true));
	const closeModal = () => {
		reset(defaultFormData);
		refreshParent();
		setModalState(false);
	};

	const initializePaymentForm = (initialState = defaultFormData) => {
		initialState = removeEmptyFromObj(initialState);
		if (Object.keys(initialState).length > 0) {
			setPreviousFormData(initialState);
			Object.entries(initialState).forEach(([key, value]) => {
				setValue(key, value);
			});
		}
	};

	useEffect(() => {
		reset(defaultFormData);
		let { user_id, email } = Auth.getProfile().data || {};
		let initFormState = {
			...(user_id && { user_id }),
			...(email && { email })
		};

		let card_last_four = null;
		if (Object.keys(selectedRowIds).length === 1) {
			let selectedRows = Array.isArray(page) ? page.reduce((acc, { isSelected = false, original } = {}) => (
				[...acc, ...(isSelected ? [original] : [])]
			), []) : [];

			// Destructuring values from selected row to populate form fields
			let { id, stripe_customer_id, card_id, customer_id, is_primary, name, last4: _last4,
				card: { exp_month, exp_year, brand, last4 } = {},
				billing_details: {
					address: {
						city: billing_address_city,
						line1: billing_address_address,
						postal_code: billing_address_zip,
						state: billing_address_state
					} = {}
				} = {},
			} = selectedRows[0] || {};

			// Update Selected Payment Method Id 
			let newState = {
				id, stripe_customer_id, card_id, customer_id, is_primary, name, exp_month, exp_year, brand,
				billing_address_city, billing_address_address, billing_address_state, billing_address_zip,
			};
			initFormState = { ...initFormState, ...newState };
			card_last_four = last4 || _last4 || null;
		} else if (Array.isArray(page) && page.length > 0 && page[0]?.original) { // Get customer id from first row if available 
			let { customer_id, stripe_customer_id } = page[0].original || {};
			initFormState = {
				...initFormState,
				...(customer_id && { customer_id }),
				...(stripe_customer_id && { stripe_customer_id }),
			};
		}

		if (card_last_four) {
			setModalTitle(`Update Payment Method [**** **** **** ${card_last_four}]`);
		} else {
			setModalTitle(`Add Payment Method`);
		}
		initializePaymentForm(initFormState);
	}, [selectedRowIds]);

	return <Modal show={modalState} onHide={closeModal} onShow={openModal} contentClassName="border" size="lg" backdrop="static" >
		<Modal.Header closeButton closeVariant={isDark ? 'white' : undefined} className="bg-light px-card border-bottom-0" >
			<Modal.Title as="h5">{modalTitle}</Modal.Title>
		</Modal.Header>
		<Modal.Body
			as={Form}
			noValidate
			onSubmit={handleSubmit(onSubmitData, onError)}
		>
			<Row>
				<Col>
					<Alert
						dismissible
						className="mb-3"
						onClose={() => setShowAlert(false)}
						show={showAlert}
						variant={modalAlert.alert_type}
					>{modalAlert.message}</Alert>
				</Col>
			</Row>
			{Object.keys(selectedRowIds).length === 1 ? <>
				<Row className="g-2 mb-3">
					<WizardInput
						label="Is Primary"
						name="is_primary"
						type="checkbox"
						errors={errors}
						formGroupProps={{ className: 'mb-3' }}
						formControlProps={{ ...register('is_primary') }}
					/>
				</Row>
				<Row className="g-2 mb-3">
					<WizardInput
						label="Name"
						name="name"
						errors={errors}
						formGroupProps={{ as: Col, sm: 12, className: 'mb-3' }}
						formControlProps={{ ...register('name') }}
					/>
				</Row>
				<Row className="g-2 mb-3">
					<WizardInput
						label="Exp Month"
						name="exp_month"
						type="number"
						errors={errors}
						formGroupProps={{ as: Col, sm: 6, className: 'mb-3' }}
						formControlProps={{ ...register('exp_month') }}
					/>
					<WizardInput
						label="Exp Year"
						name="exp_year"
						type="number"
						errors={errors}
						formGroupProps={{ as: Col, sm: 6, className: 'mb-3' }}
						formControlProps={{ ...register('exp_year') }}
					/>
				</Row>
				<Row className="g-2 mb-3">
					<WizardInput
						label="Billing Address"
						name="billing_address_address"
						errors={errors}
						formGroupProps={{ as: Col, sm: 12, className: 'mb-3' }}
						formControlProps={{
							...register('billing_address_address')
						}}
					/>
				</Row>
				<Row className="g-2 mb-3">
					<WizardInput
						label="City"
						name="billing_address_city"
						errors={errors}
						formGroupProps={{ as: Col, sm: 6, className: 'mb-3' }}
						formControlProps={{
							...register('billing_address_city')
						}}
					/>
					<WizardInput
						type='select'
						options={stateData.map(s => s.value)}
						label="State"
						name="billing_address_state"
						errors={errors}
						formGroupProps={{ as: Col, sm: 3, className: 'mb-3' }}
						formControlProps={{
							...register('billing_address_state')
						}}
					/>
					<WizardInput
						type="number"
						name="billing_address_zip"
						label="Zip Code"
						placeholder="1234"
						errors={errors}
						formGroupProps={{ as: Col, sm: 3 }}
						formControlProps={{
							className: 'input-spin-none',
							...register('billing_address_zip')
						}}
					/>
				</Row>
			</> : <StripePaymentForm
				cardInformationValid={cardInformationValid}
				setCardInformationValid={setCardInformationValid}
				useSetupIntent={false}
				useCardElement={true}
				hidePostalCode={true}
				register={register}
				errors={errors}
				watch={watch}
				useWatch={useWatch}
				setValue={setValue}
				getValues={getValues}
				setError={setError}
				clearErrors={clearErrors}
				onCapture={onCaptureStripeCard}
			/>}
			<Row className="g-2 mb-3 d-flex">
				<IconButton variant="primary" size="sm" icon="check" className="col" type="submit" iconAlign="left">
					<span className="d-sm-inline-block ms-1">Submit</span>
				</IconButton>
			</Row>
		</Modal.Body>
	</Modal>
};

PaymentDetails.propTypes = {
	page: PropTypes.array,
	selectedRowIds: PropTypes.object,
	modalState: PropTypes.bool,
	setModalState: PropTypes.func,
	refreshParent: PropTypes.func,
	handleUpdate: PropTypes.func,
	handleCreate: PropTypes.func,
};

export default PaymentDetails;