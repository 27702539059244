import React from 'react';
import MedicalRecordRequestForm from './MedicalRecordRequestForm';
import { Button } from 'react-bootstrap';


const MedicalRecords = () => {
	const [medicalRecordRequestModalState, setMedicalRecordRequestModalState] = React.useState(false);
	return (
		<>
			Medical Records
			<hr />
			<Button onClick={() => setMedicalRecordRequestModalState(true)}>Request Medical Records</Button>
			<MedicalRecordRequestForm
				modalState={medicalRecordRequestModalState}
				setModalState={setMedicalRecordRequestModalState}
			/>

		</>
	);
};

export default MedicalRecords;