import decode from 'jwt-decode';
import CryptoJS from 'crypto-js';
import { userAPI, sessionAPI } from './api/fulcrum-api';
import moment from 'moment';
const assureCallback = process.env.REACT_APP_ASSURE_CALLBACK || "http://localhost:3001/api/public/assure/callback";
const assureSkipLinkCheck = process.env.REACT_APP_ASSURE_SKIP_LINK_CHECK === "true"; // Normally false or undefined 

class AuthService {
  // Get profile info from token
  getProfile() {
    return this.getSessionObj();
  }

  // Check if logged in
  async isLoggedIn() {
    let loggedIn = true;
    let result = await sessionAPI.getSession();
    let currentTimestamp = moment().utc();
    let cookieExpireTimestamp = moment(result?.cookie?.expires);

    if (!cookieExpireTimestamp || !currentTimestamp.isBefore(cookieExpireTimestamp)) {
      loggedIn = false
      this.logout();
    }
    return {
      isLoggedIn: loggedIn,
      email: result?.email,
      remainingTime: cookieExpireTimestamp.diff(currentTimestamp, "minutes")
    };
  }

  // Check logged in status
  async loggedIn(source) {
    let { data: { data: result } } = await sessionAPI.getSession();

    if (!result?.isLoggedIn) { return false; }

    let currentTimestamp = moment().utc();
    let cookieExpireTimestamp = moment(result.cookie.expires);

    // Send to login page if it's not the login page as the source
    if (source !== 'LoginPage') {
      if (!currentTimestamp.isBefore(cookieExpireTimestamp)) { return this.logout(); }
    }

    return cookieExpireTimestamp.diff(currentTimestamp, "minutes");
  }

  // Check if token is valid
  isTokenExpired(token) {
    try {
      const decoded = decode(token);

      if (decoded.exp < Date.now() / 1000) {
        return true;
      } else {
        return false;
      }
    } catch (err) {
      return false;
    }
  }

  // Grab token from sessionStorage (localStorage??) @todo
  getToken() {
    var bytes = CryptoJS.AES.decrypt(localStorage.getItem('ful_id_token'), process.env.REACT_APP_SESSION_STORAGE_SECRET);
    const decryptedData = JSON.parse(JSON.parse(bytes.toString(CryptoJS.enc.Utf8)));
    return decryptedData.data.auth.token;
  }

  // Decrypt ful_id_token
  getSessionObj() {
    try {
      var bytes = CryptoJS.AES.decrypt(localStorage.getItem('ful_id_token'), process.env.REACT_APP_SESSION_STORAGE_SECRET);
      return JSON.parse(JSON.parse(bytes.toString(CryptoJS.enc.Utf8)));
    } catch (error) {
      this.logout({ type: 'NEW_PERMISSIONS_LIST', payload: [] });
    }
  }

  async checkSession(permissionsDispatch) {
    let isLoggedIn = false;

    try {
      let sessionResult = await userAPI.getUserSession();
      isLoggedIn = sessionResult?.session?.isLoggedIn ? sessionResult.session.isLoggedIn : false;
      permissionsDispatch({ type: 'NEW_PERMISSIONS_LIST', payload: isLoggedIn ? (sessionResult?.session?.permissions || []) : [] });
      permissionsDispatch({ type: 'NEW_SETTINGS_LIST', payload: isLoggedIn ? (sessionResult?.session?.settings || {}) : {} });
    } catch (error) {
      isLoggedIn = false;
    }

    if (isLoggedIn) {
      return true;
    } else {
      this.logout(permissionsDispatch);
    }
  }

  // Save token to sessionStorage
  async login(sessionDetails, permissionsDispatch, navHook) {
    const { assureAccountIsLinked = false } = sessionDetails?.data || {};

    let sessionString = JSON.stringify(sessionDetails);
    let encryptedSession = CryptoJS.AES.encrypt(JSON.stringify(sessionString), process.env.REACT_APP_SESSION_STORAGE_SECRET).toString();
    localStorage.setItem('ful_id_token', encryptedSession);
    permissionsDispatch({ type: 'NEW_PERMISSIONS_LIST', payload: sessionDetails.data.permissions });
    permissionsDispatch({ type: 'NEW_SETTINGS_LIST', payload: sessionDetails.data.settings });

    if (assureAccountIsLinked || assureSkipLinkCheck) { // Direct user to their dashboard if they are already linked to an assure account
      navHook('/dashboard');
    } else { // Direct user to the assure account linking page which will redirect them to their dashboard 
      return window.open(assureCallback, "_self", "noopener,noreferrer");
    }
  }

  // Remove token from sessionStorage
  async logout(permissionsDispatch) {
    // Clear settings list
    try {
      permissionsDispatch({ type: 'NEW_SETTINGS_LIST', payload: {} });
    } catch (error) {
      console.log(error);
    }

    // Clear permissions list
    try {
      await userAPI.logout();
      localStorage.removeItem('ful_id_token');
      permissionsDispatch({ type: 'NEW_PERMISSIONS_LIST', payload: [] });
    } catch (error) {
      console.log(error);
    }
    window.location.assign('/logout');
  }
}

export default new AuthService();