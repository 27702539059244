import React, { useRef, useState, useEffect } from 'react';
import { Col, Form, Button, Modal, Alert, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { superAdminAPI, userAPI } from 'utils/api/fulcrum-api';
import { validateEmail, removeEmptyFromObj } from 'utils/functions';
import { stateData } from 'data/stateData';
import Spacing from 'components/utilities/Spacing';

const CreateAccount = ({ modalState, setModalState = () => { }, refreshParent = () => { } } = {}) => {
	const navigate = useNavigate();
	const [errors, setErrors] = useState([]);
	const [submitting, setSubmitting] = useState(false);
	const [emailUnique, setEmailUnique] = useState(false);
	const [validEmail, setValidEmail] = useState(false);

	// Form Data
	const [formData, setFormData] = useState({
		business_name: '',
		account_status: '',
		website_url: '',
		fax: '',
		first_name: '',
		last_name: '',
		primary_phone: '',
		secondary_phone: '',
		primary_email: '',
		secondary_email: '',
		system_text_number: '',
		system_fax_email: '',
		system_email: '',
		business_mailing_address: '',
		business_mailing_city: '',
		business_mailing_state: '',
		business_mailing_zip: '',
		system_fax_email: '',
		system_text_number: '',
	});

	// Form Data Change
	const handleInputChange = (e) => {
		let { name, value } = e.target;
		setFormData({ ...formData, [name]: value });
	};

	// Verify email is unique
	const verifyEmail = async (primary_email) => {
		try {
			if (!validateEmail(primary_email)) {
				return setEmailUnique(false);
			}
			let isUniqueResult = await userAPI.verifyEmailAvailability(primary_email);
			if (!isUniqueResult?.data || isUniqueResult.data == false) {
				setEmailUnique(false);
				throw new Error('Email is not unique');
			}
			setEmailUnique(true);
			return true;
		} catch (error) {
			errors.primary_email = { message: 'Email is not unique' };
			setEmailUnique(false);
			return false;
		}
	};

	// Create Account
	const createAccount = async (e) => {
		e.preventDefault();
		setSubmitting(true);
		try {
			setErrors([]);
			let newErrors = [];
			let payload = removeEmptyFromObj(formData);
			// Validate required fields
			let uniqueEmail = await verifyEmail(payload?.primary_email);
			if (!uniqueEmail) {
				newErrors.push({ message: 'Email is not unique' });
			}

			if (errors.length > 0) {
				setErrors(newErrors);
				return;
			}
			const result = await superAdminAPI.createAccount(payload);
			if (!result?.data) {
				throw new Error('Error creating account');
			}

			toast.success('Account created successfully', { theme: 'colored' });
			handleClose();
		} catch (error) {
			toast.error('Unable to create the account.', { theme: 'colored' });
		}
		setSubmitting(false);
	};

	// Handle Show
	const handleShow = () => {
		setModalState(true);
	};

	// Handle Close
	const handleClose = () => {
		setSubmitting(false);
		setModalState(false);
	};

	return (
		<Modal
			show={modalState}
			onHide={handleClose}
			onShow={handleShow}
			backdrop="static"
			size="lg"
		>
			<Form onSubmit={createAccount}>
				<Modal.Header closeButton>
					<Modal.Title>Create Account</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Alert variant="info">
						<p>
							<strong>Create an Account:</strong> This form will allow you to create a new account to join Fulcrum. Please complete all of the required fields below to create an account.
						</p>
					</Alert>
					{
						errors.length > 0 && (
							<Alert variant="danger">
								<ul>
									{errors.map((error, index) => (
										<li key={index}>{error}</li>
									))}
								</ul>
							</Alert>
						)
					}

					<div className="my-3">
						<span className="fw-bold mb-3">Business Information</span>
						<br />
						<Row>
							<Col>
								<Form.Group controlId="business_name">
									<Form.Label>Business Name*</Form.Label>
									<Form.Control
										type="text"
										name="business_name"
										value={formData.business_name}
										onChange={handleInputChange}
										placeholder="Enter Business Name"
										disabled={submitting}
										required
									/>
								</Form.Group>
								<Form.Group controlId="website_url">
									<Form.Label>Business Website</Form.Label>
									<Form.Control
										type="text"
										name="website_url"
										value={formData.website_url}
										onChange={handleInputChange}
										placeholder="Website"
										disabled={submitting}
									/>
								</Form.Group>
							</Col>
						</Row>
					</div>




					<div className="my-3">
						<span className="fw-bold mb-3">Business Mailing Address</span>
						<Row>
							<Col>
								<Form.Group controlId="business_mailing_address">
									<Form.Label>Address*</Form.Label>
									<Form.Control
										type="text"
										name="business_mailing_address"
										value={formData.business_mailing_address}
										onChange={handleInputChange}
										placeholder="Address"
										disabled={submitting}
										required
									/>
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Col sm={5}>
								<Form.Group controlId="business_mailing_city">
									<Form.Label>City*</Form.Label>
									<Form.Control
										type="text"
										name="business_mailing_city"
										value={formData.business_mailing_city}
										onChange={handleInputChange}
										placeholder="City"
										disabled={submitting}
										required
									/>
								</Form.Group>
							</Col>
							<Col>
								<Form.Group controlId="business_mailing_state">
									<Form.Label>State*</Form.Label>
									<Form.Control
										as="select"
										name="business_mailing_state"
										value={formData.business_mailing_state}
										onChange={handleInputChange}
										disabled={submitting}
										required
									>
										<option value="">Select State</option>
										{
											Array.isArray(stateData) && stateData.map((state, index) => {
												return (
													<option key={index} value={state.abbreviation}>{state.abbreviation}</option>
												);
											})
										}
									</Form.Control>
								</Form.Group>
							</Col>
							<Col>
								<Form.Group controlId="business_mailing_zip">
									<Form.Label>Zip*</Form.Label>
									<Form.Control
										type="text"
										name="business_mailing_zip"
										value={formData.business_mailing_zip}
										onChange={handleInputChange}
										placeholder="Zip"
										disabled={submitting}
										required
									/>
								</Form.Group>
							</Col>
						</Row>
					</div>


					<div className="my-3">
						<span className="fw-bold mb-3">Primary Contact Information</span>
						<p>This contact will become a representative user for this account.</p>
						<Row>
							<Col>
								<Form.Group controlId="first_name">
									<Form.Label>First Name*</Form.Label>
									<Form.Control
										type="text"
										name="first_name"
										value={formData.first_name}
										onChange={handleInputChange}
										placeholder="First Name"
										disabled={submitting}
										required
									/>
								</Form.Group>
							</Col>
							<Col>
								<Form.Group controlId="last_name">
									<Form.Label>Last Name*</Form.Label>
									<Form.Control
										type="text"
										name="last_name"
										value={formData.last_name}
										onChange={handleInputChange}
										placeholder="Last Name"
										disabled={submitting}
										required
									/>
								</Form.Group>
							</Col>
						</Row>


						<Row>
							<Col sm={6}>
								<Form.Group controlId="primary_phone">
									<Form.Label>Primary Phone*</Form.Label>
									<Form.Control
										type="text"
										name="primary_phone"
										value={formData.primary_phone}
										onChange={handleInputChange}
										placeholder="Primary Phone"
										disabled={submitting}
										required
									/>
								</Form.Group>
							</Col>
							<Col>
								<Form.Group controlId="secondary_phone">
									<Form.Label>Secondary Phone</Form.Label>
									<Form.Control
										type="text"
										name="secondary_phone"
										value={formData.secondary_phone}
										onChange={handleInputChange}
										placeholder="Secondary Phone"
										disabled={submitting}
									/>
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Col>
								<Form.Group controlId="fax">
									<Form.Label>Fax Number</Form.Label>
									<Form.Control
										type="text"
										name="fax"
										value={formData.fax}
										onChange={handleInputChange}
										placeholder="Fax"
										disabled={submitting}
									/>
								</Form.Group>
							</Col>
						</Row>


						<Row>
							<Col>
								<Form.Group controlId="primary_email">
									<Form.Label>Primary Email* (Must be unique)
										<br />
										{
											emailUnique ?
												<span className="text-success">Email is unique and valid</span>
												:
												<span className="text-danger">Email is not valid and/or unique</span>
										}
									</Form.Label>
									<Form.Control
										type="text"
										name="primary_email"
										value={formData.primary_email}
										onChange={handleInputChange}
										onBlur={e => verifyEmail(e.target.value)}
										placeholder="Primary Email"
										disabled={submitting}
										required
									/>
								</Form.Group>
							</Col>
						</Row>


					</div>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleClose} disabled={submitting}>
						Close
					</Button>&nbsp;
					<Button variant="success" type="submit" disabled={submitting}>
						{submitting ? "Creating account..." : "Create Account"}
					</Button>
				</Modal.Footer>
			</Form>
		</Modal>
	);
};





export default CreateAccount;