import React, { useState, useEffect, useRef } from 'react';
import { accountServiceAPI } from 'utils/api/fulcrum-api';
import { Alert } from 'react-bootstrap';

const OfCounselAgreements = ({ user, serUser = () => { } }) => {


	return (
		<div>
			<h5>Of Counsel Agreements</h5>
		</div>
	);
}

export default OfCounselAgreements;