import React, { useState } from 'react';
import PropTypes from 'prop-types';
import WizardInput from '../WizardInput';
import { Col, Row } from 'react-bootstrap';
import IconAlert from '../../common/IconAlert';

// const UserForm = ({ register, errors, watch, accessVerificationData, updateUserError, setUpdateUserError }) => {
const UserForm = ({ register, errors, watch, updateUserError, setUpdateUserError }) => {
  // const [loadingUser, setLoadingUser] = useState(true);
  const [defaultValues] = useState({
    first_name: '',
    last_name: ''
  });

  // Get user details
  // const getUserDetails = async () => {
  // 	try {
  // 		let userId = accessVerificationData?.entity_id ? accessVerificationData.entity_id.split('_')[2] : null;

  // 		if (!userId) { throw new Error('No user ID.') }

  // 		let result = await userAPI.getUserDetails(userId);
  // 		if (!result?.data) {
  // 			throw new Error('Unable to get user details.');
  // 		}
  // 		setDefaultValues({
  // 			first_name: result.data.first_name,
  // 			last_name: result.data.last_name
  // 		});
  // 		setLoadingUser(false);
  // 	} catch (error) {
  // 	}
  // }

  // useEffect(() => {
  // 	getUserDetails();
  // }, []);

  // if (loadingUser) {
  // 	return <p>Loading user details...</p>;
  // }

  return (
    <>
      {updateUserError &&
        <IconAlert
          variant="danger"
          className="mb-3"
          dismissible={true}
          onClose={() => setUpdateUserError(false)}
        >
          <p>Unable to update the user. Please reach out to your system administrator for help.</p>
        </IconAlert>
      }

      <Row className="g-2">
        <WizardInput
          label="First Name*"
          name="first_name"
          errors={errors}
          formGroupProps={{ as: Col, sm: 6, className: 'mb-3' }}
          formControlProps={{
            defaultValue: defaultValues.first_name,
            ...register('first_name', {
              required: 'First name field is required'
            })
          }}
        />
        <WizardInput
          label="Last Name*"
          name="last_name"
          errors={errors}
          formGroupProps={{ as: Col, sm: 6, className: 'mb-3' }}
          formControlProps={{
            defaultValue: defaultValues.last_name,
            ...register('last_name', {
              required: 'Last name field is required'
            })
          }}
        />
      </Row>

      <WizardInput
        type="phone"
        errors={errors}
        label="Primary Phone*"
        name="primary_phone"
        formGroupProps={{ className: 'mb-3' }}
        formControlProps={{
          ...register('primary_phone', {
            placeholder: '###-###-####',
            pattern: {
              value: /^([+]?\d{1,2}[-\s]?|)[-(\s]?\d{3}[-)\s]?[\s]?\d{3}[-\s]?\d{4}$/,
              message: 'Primary phone must be valid'
            }
          })
        }}
      />

      <WizardInput
        type="text"
        errors={errors}
        label="Fax Number"
        name="fax_number"
        formGroupProps={{ className: 'mb-3' }}
        formControlProps={{
          ...register('fax_number', {
            placeholder: '###-###-####',
            pattern: {
              value: /^([+]?\d{1,2}[-\s]?|)[-(\s]?\d{3}[-)\s]?[\s]?\d{3}[-\s]?\d{4}$/,
              message: 'Fax must be valid'
            }
          })
        }}
      />

      <hr />
      <h5>Set Password</h5>
      <p>You must use your email and new password to log into your account.</p>

      <WizardInput
        type="password"
        errors={errors}
        label="Password*"
        name="password"
        formGroupProps={{}}
        formControlProps={{
          ...register('password', {
            required: 'You must specify a password',
            pattern: {
              value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
              message: 'Password must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, one number, and one special character'
            }
          })
        }}
      />
      <WizardInput
        type="password"
        errors={errors}
        label="Confirm Password*"
        name="confirmPassword"
        formGroupProps={{}}
        formControlProps={{
          ...register('confirmPassword', {
            required: 'Confirm Password field is required',
            validate: value =>
              value === watch('password') || 'The password does not match'
          })
        }}
      />
    </>
  );
};

UserForm.propTypes = {
  register: PropTypes.func.isRequired,
  errors: PropTypes.object,
  watch: PropTypes.func,
  updateUserError: PropTypes.bool,
  setUpdateUserError: PropTypes.func
};

export default UserForm;
