import React, { useState, useEffect } from 'react';
import { caseAPI, claimantAPI } from 'utils/api/fulcrum-api';
import { Nav, Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import classNames from 'classnames';
import Flex from 'components/common/Flex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Swal from 'sweetalert2';

const NavItemPill = ({ index, step, handleNavs, icon, label }) => {
	return (
		<Nav.Item>
			<Nav.Link
				className={classNames('fw-semi-bold', {
					done: step > index,
					active: step === index
				})}
				onClick={() => handleNavs(index)}
			>
				<Flex alignItems="center" justifyContent="center">
					<FontAwesomeIcon icon={icon} />
					<span className="d-none d-md-block mt-1 fs--1 ms-2">{label}</span>
				</Flex>
			</Nav.Link>
		</Nav.Item>
	);
};

const NavItem = ({ index, step, handleNavs, icon, label }) => {
	return (
		<Nav.Item>
			<Nav.Link
				className={classNames('fw-semi-bold', {
					done: index < 6 ? step > index : step > 5,
					active: step === index
				})}
				onClick={() => handleNavs(index)}
			>
				<span className="nav-item-circle-parent">
					<span className="nav-item-circle">
						<FontAwesomeIcon icon={icon} />
					</span>
				</span>
				<span className="d-none d-md-block mt-1 fs--1">{label}</span>
			</Nav.Link>
		</Nav.Item>
	);
};


const LeadProgressTool = ({ claimant, setClaimant }) => {
	const [step, setStep] = useState(3);
	let variant = null;
	const [navItems] = useState([
		{
			icon: 'circle',
			label: 'Established Contact'
		},
		{
			icon: 'circle',
			label: 'Screened'
		},
		{
			icon: 'circle',
			label: 'Reviewed'
		},
		{
			icon: 'circle',
			label: 'Received Retainer Packet'
		}
	]);

	// Update Claimant 
	const updateClaimant = async (params) => {
		try {
			let updatedClaimantResult = await claimantAPI.updateClaimant(claimant.id, params);
			if (!updatedClaimantResult?.data) {
				throw new Error('No case data returned');
			}

			setClaimant(updatedClaimantResult.data);

			toast.success(`Claimant updated!`, {
				theme: 'colored'
			});
		} catch (error) {
			console.log(error);
			toast.error(`${error}`, {
				theme: 'colored'
			});
		}
	};

	// updateClaimantCaseStatus 
	const updateClaimantCaseStatus = async () => {
		Swal.fire({
			title: 'Received Retainer Packet?',
			text: 'Are you sure you have received the retainer packet and would like to build the case file?',
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Yes, build it!'
		}).then(async (result) => {
			if (result.isConfirmed) {
				try {
					// Build the new case
					let newCaseResult = await caseAPI.createCase(claimant.id, {
						case_status_id: 1,
						is_active: true,
						claimant_id: claimant.id,
					});
					if (!newCaseResult?.data) {
						if (newCaseResult?.response?.message) {
							throw newCaseResult.response.message;
						} else {
							throw 'No case data returned';
						}
					}

					// Update the claimant with new case data
					// let result = await claimantAPI.updateClaimant(claimant.id, { active_case: true, active_case_id: newCaseId });
					// if (!result?.data) { throw new Error('Unable to update the claimant.') }
					setClaimant(newCaseResult.data);

					toast.success(`Claimant now has an active case!`, {
						theme: 'colored'
					});
				} catch (error) {
					console.log(error);
					toast.error(`${error}`, {
						theme: 'colored'
					});
				}
			}
		});
	};

	// Handle Nav Click
	const handleNavItemClick = (item) => {
		switch (item.label) {
			case 'Established Contact':
				if (!claimant.established_contact) {
					Swal.fire({
						title: 'Established Contact?',
						text: 'Are you sure you have established contact with the claimant?',
						icon: 'warning',
						showCancelButton: true,
						confirmButtonColor: '#3085d6',
						cancelButtonColor: '#d33',
						confirmButtonText: 'Yes, established!'
					}).then(async (result) => {
						if (result.isConfirmed) {
							updateClaimant({ established_contact: true });
						}
					});
				} else {
					Swal.fire({
						title: 'Remove Established Contact?',
						text: 'Are you sure you want to remove established contact with the claimant?',
						icon: 'warning',
						showCancelButton: true,
						confirmButtonColor: '#d33',
						cancelButtonColor: '#3085d6',
						confirmButtonText: 'Yes, remove!'
					}).then(async (result) => {
						if (result.isConfirmed) {
							updateClaimant({ established_contact: false });
						}
					});
				}
				break;
			case 'Screened':
				if (!claimant.screened) {
					Swal.fire({
						title: 'Screened?',
						text: 'Are you sure you have screened the claimant?',
						icon: 'warning',
						showCancelButton: true,
						confirmButtonColor: '#3085d6',
						cancelButtonColor: '#d33',
						confirmButtonText: 'Yes, screened!'
					}).then(async (result) => {
						if (result.isConfirmed) {
							updateClaimant({ screened: true });
						}
					});
				} else {
					Swal.fire({
						title: 'Remove Screened?',
						text: 'Are you sure you want to remove screened from the claimant?',
						icon: 'warning',
						showCancelButton: true,
						confirmButtonColor: '#d33',
						cancelButtonColor: '#3085d6',
						confirmButtonText: 'Yes, remove!'
					}).then(async (result) => {
						if (result.isConfirmed) {
							updateClaimant({ screened: false });
						}
					});
				}
				break;
			case 'Reviewed':
				if (!claimant.reviewed) {
					Swal.fire({
						title: 'Reviewed?',
						text: 'Are you sure you have reviewed the claimant?',
						icon: 'warning',
						showCancelButton: true,
						confirmButtonColor: '#3085d6',
						cancelButtonColor: '#d33',
						confirmButtonText: 'Yes, reviewed!'
					}).then(async (result) => {
						if (result.isConfirmed) {
							updateClaimant({ reviewed: true });
						}
					});
				} else {
					Swal.fire({
						title: 'Remove Reviewed?',
						text: 'Are you sure you want to remove reviewed from the claimant?',
						icon: 'warning',
						showCancelButton: true,
						confirmButtonColor: '#d33',
						cancelButtonColor: '#3085d6',
						confirmButtonText: 'Yes, remove!'
					}).then(async (result) => {
						if (result.isConfirmed) {
							updateClaimant({ reviewed: false });
						}
					});
				}
				break;
			case 'Received Retainer Packet':
				// alert('Received Retainer Packet');
				updateClaimantCaseStatus();
				break;
			default:
				break;
		}
	}

	// Update progress bar
	const updateProgressBar = async () => {
		let currentStep = 0;
		let adjustedNavItems = [...navItems];

		adjustedNavItems[0].icon = claimant.established_contact ? 'check' : 'circle';
		adjustedNavItems[1].icon = claimant.screened ? 'check' : 'circle';
		adjustedNavItems[2].icon = claimant.reviewed ? 'check' : 'circle';
		if (claimant.established_contact) {
			currentStep = 1;
		}
		if (claimant.screened) {
			currentStep = 2;
		}
		if (claimant.reviewed) {
			currentStep = 3;
		}
		setStep(currentStep + 1);
	};

	useEffect(() => { updateProgressBar(); }, [claimant.established_contact, claimant.screened, claimant.reviewed, claimant.active_case, claimant.active_case_id, step]);

	return (
		<div>

			<div className="text-center">
				{
					(claimant.established_contact && claimant.screened && claimant.reviewed)
						?
						<>
							<h5 className="fw-bold">Pending return of the retainer packet.</h5>
							<Button variant="link" onClick={() => updateClaimantCaseStatus()}>
								We got the retainer packet!  Time to build the case file!
							</Button>
						</>
						:
						<h5>Lead Progress Tool</h5>
				}
			</div>
			<div
				noValidate
				onSubmit={() => { }}
				className="theme-wizard"
			>
				<div>
					<Nav className="justify-content-center" variant={variant}>
						{variant === 'pills'
							? navItems.map((item, index) => (
								<NavItemPill
									key={item.label}
									index={index + 1}
									step={step}
									handleNavs={() => {
										handleNavItemClick(item);
									}}
									icon={item.icon}
									label={item.label}
								/>
							))
							: navItems.map((item, index) => (
								<NavItem
									key={item.label}
									index={index + 1}
									step={step}
									handleNavs={() => {
										handleNavItemClick(item);
									}}
									icon={item.icon}
									label={item.label}
								/>
							))}
					</Nav>
				</div>
			</div>
		</div>
	);
};

export default LeadProgressTool;