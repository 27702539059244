import React, { useState, useRef } from 'react';
import JoditEditor from "jodit-pro-react";

const FormGenerator = () => {
	const editor = useRef(null);
	const [formState, setFormState] = useState({
		body: ''
	})
	const editorConfig = {
		// licnese: 
		readonly: false // all options from https://xdsoft.net/jodit/doc/
	}

	return (
		<>
			Form Generator

			<JoditEditor
				tabIndex={1} // tabIndex of textarea
				ref={editor}
				value={formState.body}
				config={editorConfig}
				onBlur={newContent => { setFormState({ ...formState, body: newContent }) }}
			/>
		</>
	);
};

export default FormGenerator;