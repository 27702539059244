import React, { useEffect, useState } from 'react';
import PermissionDenied from '../components/errors/PermissionDenied';
import { superAdminAPI } from 'utils/api/fulcrum-api';
import { useNavigate, useLocation, Outlet } from 'react-router-dom';
import { Card, Col, Row, Nav, Navs } from 'react-bootstrap';
import Flex from 'components/common/Flex';

const SiteAdminLayout = () => {
	const { pathname } = useLocation();
	const [verifying, setVerifying] = useState(true);
	const [verified, setVerified] = useState(false);
	const navigate = useNavigate();

	// Verify access to the service
	const verifyServiceAccess = async () => {
		// Get service ID based on the pathname
		let hasAccess = false;
		try {
			let result = await superAdminAPI.checkSuperAdminStatus();
			if ("hasAccess" in result && result.hasAccess) {
				hasAccess = true;
			}
		} catch (error) {
			console.log(error);
		}

		if (!hasAccess) {
			navigate('/dashboard');
		}
		setVerified(hasAccess);
		setVerifying(false);
	};

	// Handle Site Admin Menu Click 
	const handleSiteAdminMenuClick = (menuItem) => {
		switch (menuItem) {
			case 'account-management':
				navigate('/admin/account-management');
				break;
			case 'user-management':
				navigate('/admin/user-management');
				break;
			case 'service-management':
				navigate('/admin/service-management');
				break;
			case 'claimant-management':
				navigate('/admin/claimant-management');
				break;
			case 'task-management':
				navigate('/admin/task-management');
				break;
			case 'document-management':
				navigate('/admin/document-management');
				break;
			default:
				navigate('/dashboard');
				break;
		}
	}

	useEffect(() => {
		setVerified(false);
		setVerifying(true);
		verifyServiceAccess();
	}, [pathname]);

	if (verifying) {
		return (<p>Verifying access...</p>);
	}

	return (
		<div className="container-fluid">
			{
				verified
					?
					<div className="">
						<div>
							<Card className="bg-100 shadow-none border mb-3">
								<Card.Body>
									<Row className=" justify-content-between pr-0">
										<Col sm="auto" className="pr-0 align-middle">
											<Flex alignItems="center">
												<h5 className="align-middle fw-bold mb-0 h-inline cursor-pointer" onClick={() => { navigate('/admin/account-management') }}>
													<span className="fw-medium">Site Admin Area</span>
												</h5>&nbsp;&nbsp;
												<Nav
													onSelect={(selectedKey) => { }}
													defaultActiveKey="account-management"
													className="justify-content-end"
												>
													<Nav.Item eventKey="account-management" onClick={() => { handleSiteAdminMenuClick('account-management') }}>
														<Nav.Link>Accounts</Nav.Link>
													</Nav.Item>
													<Nav.Item eventKey="user-management" onClick={() => { handleSiteAdminMenuClick('user-management') }}>
														<Nav.Link>Users</Nav.Link>
													</Nav.Item>
													<Nav.Item eventKey="claimant-management" onClick={() => { handleSiteAdminMenuClick('claimant-management') }}>
														<Nav.Link>Claimants</Nav.Link>
													</Nav.Item>
													<Nav.Item eventKey="task-management" onClick={() => { handleSiteAdminMenuClick('task-management') }}>
														<Nav.Link>Tasks</Nav.Link>
													</Nav.Item>
													<Nav.Item eventKey="task-management" onClick={() => { handleSiteAdminMenuClick('document-management') }}>
														<Nav.Link>Document</Nav.Link>
													</Nav.Item>
												</Nav>
											</Flex>
										</Col>
									</Row>
								</Card.Body>
							</Card>
						</div>
						<Outlet />
					</div>
					:
					<PermissionDenied
						title="Service not available"
						message="You do not have access to this service. Please contact your system administrator to get enrolled in this service."
					/>
			}
		</div>
	);
};

export default SiteAdminLayout;
