/* eslint-disable no-undef */
export const version = require("../package.json").version || "1.0.0";
export const navbarBreakPoint = 'xl'; // Vertical navbar breakpoint
export const topNavbarBreakpoint = 'lg';
export const settings = {
  fontSize: 'medium', // small, medium, large
  FULCRUM_isFluid: true,
  isRTL: false,
  isDark: false,
  navbarPosition: 'vertical', // vertical // top // combo
  showBurgerMenu: false, // controls showing vertical nav on mobile
  currency: '$',
  isNavbarVerticalCollapsed: false, // toggle vertical navbar collapse
  FULCRUM_navbarStyle: 'inverted', // transparent, // inverted // card // vibrant,
  accountSettings: {}
};

const type_scale = 1.2;
const font_size_base = 1;
export const fontSizeMap = {
  small: `${Math.round(((1 / Math.pow(type_scale, 2)) * font_size_base) * 100) / 100}rem`,     // 0.69rem - small 
  medium: `${font_size_base}rem`,                                                              // 1rem    - medium
  large: `${Math.round((Math.pow(type_scale, 2) * font_size_base) * 100) / 100}rem`,           // 1.44rem - large
  h1_small: `${Math.round((Math.pow(type_scale, 4) * font_size_base) * 100) / 100}rem`,        // 2.07em  - h1_small 
  h1_medium: `${Math.round((Math.pow(type_scale, 5) * font_size_base) * 100) / 100}rem`,       // 2.49rem - h1_medium
  h1_large: `${Math.round((Math.pow(type_scale, 6) * font_size_base) * 100) / 100}rem`,        // 2.99rem - h1_large
  h2_small: `${Math.round((Math.pow(type_scale, 3) * font_size_base) * 100) / 100}rem`,        // 1.73rem - h2_small 
  h2_medium: `${Math.round((Math.pow(type_scale, 4) * font_size_base) * 100) / 100}rem`,       // 2.07rem - h2_medium 
  h2_large: `${Math.round((Math.pow(type_scale, 5) * font_size_base) * 100) / 100}rem`,        // 2.49rem - h2_large 
  h3_small: `${Math.round((Math.pow(type_scale, 2) * font_size_base) * 100) / 100}rem`,        // 1.44rem - h3_small
  h3_medium: `${Math.round((Math.pow(type_scale, 3) * font_size_base) * 100) / 100}rem`,       // 1.73rem - h3_medium 
  h3_large: `${Math.round((Math.pow(type_scale, 4) * font_size_base) * 100) / 100}rem`,        // 2.07rem - h3_large 
  h4_small: `${Math.round((Math.pow(type_scale, 1) * font_size_base) * 100) / 100}rem`,        // 1.2rem  - h4_small 
  h4_medium: `${Math.round((Math.pow(type_scale, 2) * font_size_base) * 100) / 100}rem`,       // 1.44rem - h4_medium 
  h4_large: `${Math.round((Math.pow(type_scale, 3) * font_size_base) * 100) / 100}rem`,        // 1.73rem - h4_large 
  h5_small: `${font_size_base}rem`,                                                            // 1rem    - h5_small 
  h5_medium: `${Math.round((Math.pow(type_scale, 1) * font_size_base) * 100) / 100}rem`,       // 1.2rem  - h5_medium 
  h5_large: `${Math.round((Math.pow(type_scale, 2) * font_size_base) * 100) / 100}rem`,        // 1.44rem - h5_large 
  h6_small: `${Math.round(((1 / Math.pow(type_scale, 2)) * font_size_base) * 100) / 100}rem`,  // 0.69rem - h6_small
  h6_medium: `${Math.round(((1 / type_scale) * font_size_base) * 100) / 100}rem`,              // 0.83rem - h6_medium
  h6_large: `${font_size_base}rem`,                                                            // 1rem    - h6_large
  nav_link_small: "0.75rem",
  nav_link_medium: "0.875rem",
  nav_link_large: "1rem",
};
export const fontSizeKeys = ["--falcon-body-font-size", "--fulcrum-h1-font-size", "--fulcrum-h2-font-size", "--fulcrum-h3-font-size", "--fulcrum-h4-font-size", "--fulcrum-h5-font-size", "--fulcrum-h6-font-size", "--fulcrum-nav-link-font-size"];

export default { version, navbarBreakPoint, topNavbarBreakpoint, settings, fontSizeMap, fontSizeKeys };
