import React, { useState, useEffect, useContext } from 'react';
import {
  Elements,
  CardElement,
  ElementsConsumer,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  PaymentElement,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import PropTypes from 'prop-types';
import { Col, Row, Alert } from 'react-bootstrap';
import AppContext from 'context/Context';
import { stateData } from 'data/stateData';
import WizardInput from '../wizard/WizardInput';
import { paymentAPI } from "../../utils/api/fulcrum-api";
const STRIPE_PUBLIC_KEY = process.env.REACT_APP_STRIPE_PUBLIC_KEY;
const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);
const cardOptionsFn = (isDark, hideZip = false) => ({
  ...(typeof isDark === 'boolean' ? { // CardElement or CardNumberElement options 
    style: {
      base: {
        iconColor: "#c4f0ff",
        color: isDark ? "#fff" : "#000",
        fontWeight: "400",
        fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
        fontSize: "16px",
        fontSmoothing: "antialiased",
        ":-webkit-autofill": { color: "#fce883" },
        "::placeholder": { color: isDark ? "#fff" : "#000" }
      },
      invalid: {
        iconColor: "#ffc7ee",
        color: "ff0000"
      },
    },
    ...(hideZip && { hidePostalCode: true })
  } : { // PaymentElement options
    ...(hideZip && { fields: { billingDetails: { address: { postalCode: 'never' } } } })
  }),
});

export const CardDetailsForm = ({ cardInformationValid = false, setCardInformationValid = () => { }, useCardElement = true, useSetupIntent = false, setupIntentReturnUrl,
  card_options = {}, register, setValue, getValues, watch, errors, setError, clearErrors, init = () => { }, onCapture = async () => { }
} = {}) => {
  const [formAlert, setFormAlert] = useState({ message: "", alert_type: "info" });
  const [showAlert, setShowAlert] = useState(false);
  const watchPostalCode = watch("billing_address_zip", false); // watch for postal code changes from user form 
  const [isCardNumberElementValid, setIsCardNumberElementValid] = useState(false);
  const [isCardExpiryElementValid, setIsCardExpiryElementValid] = useState(false);
  const [isCardCvcElementValid, setIsCardCvcElementValid] = useState(false);

  const validateCardDetails = async (event, elements, stripe) => {
    let { elementType = '', error: element_error, empty, complete, brand, value } = event || {};
    let { postalCode } = value || {};

    if (postalCode) { // Update postal code in user form
      setValue('billing_address_zip', postalCode);
    } else if (watchPostalCode) {
      const payment_element = useSetupIntent ? elements.getElement(PaymentElement)
        : useCardElement ? elements.getElement(CardElement) : null;

      if (payment_element && !useSetupIntent) {
        // Update the postal code field with the value from the user
        payment_element.update({ value: { postalCode: watchPostalCode } });
      }
    }

    let alertObj = { message: "", alert_type: "info" };
    let cardIsValid = cardInformationValid;
    let isValid = complete;
    if (element_error) { // { code, message, type }
      isValid = false;
      let message = element_error.code ? `[${element_error.code}] ` : '';
      message += element_error.message || 'Invalid card details';

      console.log("validateCardDetails element_error", element_error, message);
      alertObj.message = message;
      alertObj.alert_type = "danger";
    }

    switch (elementType) {
      case "cardNumber":
        setIsCardNumberElementValid(isValid);
        cardIsValid = isValid && isCardExpiryElementValid && isCardCvcElementValid;
        break;

      case "cardExpiry":
        setIsCardExpiryElementValid(isValid);
        cardIsValid = isCardNumberElementValid && isValid && isCardCvcElementValid;
        break;

      case "cardCvc":
        setIsCardCvcElementValid(isValid);
        cardIsValid = isCardNumberElementValid && isCardExpiryElementValid && isValid;
        break;

      case "card":
      case "payment":
        setIsCardNumberElementValid(isValid);
        setIsCardExpiryElementValid(isValid);
        setIsCardCvcElementValid(isValid);
        cardIsValid = isValid;
        break;

      default:
        console.log(`Unknown elementType: ${elementType}`);
        console.log({ empty, complete, brand, value, isValid, isCardNumberElementValid, isCardExpiryElementValid, isCardCvcElementValid });
        break;
    }
    setCardInformationValid(cardIsValid);

    // Create Stripe Token if card is valid
    if (cardIsValid) {
      if (!stripe || !elements) {
        console.log("Stripe.js hasn't loaded yet.");
        return;
      }

      if (useSetupIntent) {
        // See Reference: https://stripe.com/docs/payments/save-and-reuse?html-or-react=react
        let { error } = await stripe.confirmSetup({
          elements,
          confirmParams: {
            // Return URL where the customer should be redirected after the PaymentIntent is confirmed.
            return_url: setupIntentReturnUrl || window.location.href
          },
        });
        if (error) {
          let { code, doc_url, message, param, request_log_url, type } = error || {};
          if (!message) {
            message = `Error creating payment method`;
          }
          if (code) {
            message = `[${code}] ${message}`;
          }
          if (type) {
            message += ` type: ${type}`;
          }
          if (param) {
            message += ` param: ${param}`;
          }
          if (request_log_url) {
            message += ` request_log_url: ${request_log_url}`;
          }
          if (doc_url) {
            message += ` See documentation at ${doc_url}`;
          }

          // Inform the customer that there was an error.
          console.log("stripe.confirmSetup Error", error, message);
          alertObj.message = message
          alertObj.alert_type = "danger";
        }
        setShowAlert(alertObj.message);
        if (alertObj.message) {
          alertObj.message = alertObj.message.trim();
          setFormAlert(prev => ({ ...prev, ...alertObj }));
        }
        return; // EXIT because we are using a SetupIntent and the rest of the billing details should be handled at return_url
      }

      const card_element = useCardElement ? elements.getElement(CardElement) : elements.getElement(CardNumberElement, CardExpiryElement, CardCvcElement);
      const { error: token_error, token } = await stripe.createToken(card_element);
      let { id: token_id, card } = token || {}; // token = { card, client_ip, created, id, livemode, object, type, used } 
      let { id: payment_method_id, brand } = card || {}; // card = { id, object, brand, address_zip_check, cvc_check, dynamic_last4, exp_month, exp_year, funding, last4, name, tokenization_method address_city, address_country, address_line1, address_line1_check, address_line2, address_state, address_zip, country }
      setValue('payment_method_id', payment_method_id);
      setValue('token_id', token_id);
      setValue('brand', brand);

      if (token_error) { // { code, message, type }
        let message = token_error.code ? `[${token_error.code}] ` : '';
        message += token_error.message || 'Invalid stripe token';
        if (token_error.type) {
          message += ` type: ${token_error.type}`;
        }

        console.log("validateCardDetails token_error", token_error, message);
        alertObj.message = message;
        alertObj.alert_type = "danger";
      }

      if (typeof onCapture === 'function') {
        let { // customer details
          first_name, last_name, business_name, business_website, business_mailing_address, business_mailing_city, business_mailing_state, business_mailing_zip, primary_phone, primary_email,
          billing_address_address, billing_address_city, billing_address_state, billing_address_zip, // billing details 
        } = (typeof getValues === 'function' ? getValues() : {}) || {};

        // Customer Details 
        const customer = {
          ...(first_name && { first_name }),
          ...(last_name && { last_name }),
          ...(business_name && { business_name }),
          ...(business_website && { business_website }),
          ...(business_mailing_address && { business_mailing_address }),
          ...(business_mailing_city && { business_mailing_city }),
          ...(business_mailing_state && { business_mailing_state }),
          ...(business_mailing_zip && { business_mailing_zip }),
          ...(primary_phone && { primary_phone }),
          ...(primary_email && { primary_email }),
        };

        // Billing and payment details 
        const payment = {
          gateway: 'stripe',
          stripe: {
            ...(payment_method_id && { payment_method_id }),
            ...(token_id && { token_id }),
          },
          billing_address: {
            ...(billing_address_address && { address: billing_address_address }),
            ...(billing_address_city && { city: billing_address_city }),
            ...(billing_address_state && { state: billing_address_state }),
            ...(billing_address_zip && { zip: billing_address_zip }),
          },
        };

        const orderData = {
          customer,
          payment,
        };
        const payloadOptions = { showLog: true, dryRun: true };
        await onCapture(token_id, orderData, payloadOptions);
      }
    } // END cardIsValid

    setShowAlert(alertObj.message);
    if (alertObj.message) {
      alertObj.message = alertObj.message.trim();
      setFormAlert(prev => ({ ...prev, ...alertObj }));
      setError('payment_details', { type: 'custom', message: alertObj.message }); // element_error?.payment_details.message 
    } else {
      clearErrors('payment_details');
    }
  }; // END validateCardDetails

  useEffect(() => {
    if (typeof init === 'function') {
      init();
    }
  }, []);

  return (
    <ElementsConsumer>{({ elements, stripe }) => (
      <>
        {showAlert &&
          <Row>
            <Col>
              <Alert
                dismissible
                className="mb-3"
                onClose={() => setShowAlert(false)}
                show={showAlert}
                variant={formAlert.alert_type}
              >{formAlert.message}</Alert>
            </Col>
          </Row>
        }
        <Row className="g-2 mb-3">
          <WizardInput
            label="Card Holder Name (required)"
            name="card_holder_name"
            errors={errors}
            formGroupProps={{ className: 'mb-3' }}
            formControlProps={{
              ...register('card_holder_name', {
                required: 'Card holder name is required'
              })
            }}
          />
        </Row>
        {useSetupIntent
          ? <Row className="g-2 mb-3">
            <PaymentElement id="payment-element" options={card_options} onChange={(e) => validateCardDetails(e, elements, stripe)} />
          </Row>
          : useCardElement
            ? <Row className="g-2 mb-3">
              <Col>
                <CardElement className="border p-3 rounded-2" options={card_options} onChange={(e) => validateCardDetails(e, elements, stripe)} />
              </Col>
            </Row>
            : <Row className="g-2 mb-3">
              <Col>
                <label htmlFor="card-number" className="bold-text">Card number</label>
                <CardNumberElement options={card_options} onChange={(e) => validateCardDetails(e, elements, stripe)} />
              </Col>
              <Col>
                <label htmlFor="card-expiry" className="bold-text">Expiration</label>
                <CardExpiryElement options={card_options} onChange={(e) => validateCardDetails(e, elements, stripe)} />
              </Col>
              <Col>
                <label htmlFor="card-cvc" className="bold-text">CSV</label>
                <CardCvcElement options={card_options} onChange={(e) => validateCardDetails(e, elements, stripe)} />
              </Col>
            </Row>}
        {!useSetupIntent && <>
          <Row className="g-2 mb-3">
            <WizardInput
              label="Billing Address*"
              name="billing_address_address"
              errors={errors}
              formGroupProps={{ className: 'mb-3' }}
              formControlProps={{
                ...register('billing_address_address', {
                  required: 'Billing address field is required'
                })
              }}
            />
          </Row>
          <Row className="g-2 mb-3">
            <WizardInput
              label="City*"
              name="billing_address_city"
              errors={errors}
              formGroupProps={{ as: Col, sm: 6, className: 'mb-3' }}
              formControlProps={{
                ...register('billing_address_city', {
                  required: 'Billing city field is required'
                })
              }}
            />
            <WizardInput
              type='select'
              options={stateData.map(s => s.value)}
              label="State*"
              name="billing_address_state"
              errors={errors}
              formGroupProps={{ as: Col, sm: 3, className: 'mb-3' }}
              formControlProps={{
                ...register('billing_address_state', {
                  required: 'Billing state field is required'
                })
              }}
            />
            <WizardInput
              type="number"
              name="billing_address_zip"
              label="Zip Code*"
              placeholder="1234"
              errors={errors}
              formGroupProps={{ as: Col, sm: 3 }}
              formControlProps={{
                className: 'input-spin-none',
                ...register('billing_address_zip', {
                  required: 'Zip code is required'
                })
              }}
            />
          </Row>
        </>}
      </>
    )}
    </ElementsConsumer>);
};

CardDetailsForm.propTypes = {
  cardInformationValid: PropTypes.bool,
  setCardInformationValid: PropTypes.func,
  useCardElement: PropTypes.bool,
  useSetupIntent: PropTypes.bool,
  setupIntentReturnUrl: PropTypes.string,
  card_options: PropTypes.object,
  register: PropTypes.func,
  setValue: PropTypes.func,
  getValues: PropTypes.func,
  watch: PropTypes.func,
  errors: PropTypes.object,
  setError: PropTypes.func,
  clearErrors: PropTypes.func,
  init: PropTypes.func,
  onCapture: PropTypes.func,
};

const StripePaymentForm = ({ cardInformationValid, setCardInformationValid = () => { }, useSetupIntent = true, useCardElement = true, hidePostalCode = true, ...props } = {}) => {
  const { config: { isDark = true } } = useContext(AppContext);
  const [clientSecret, setClientSecret] = useState("");

  useEffect(() => {
    if (useSetupIntent) { // Create PaymentIntent as soon as the page loads
      paymentAPI.createSetupIntent().then(({ client_secret } = {}) => { // returns { id, client_secret }
        setClientSecret(client_secret);
      }).catch((err) => {
        console.log("createSetupIntent", err);
      });
    }
  }, []);

  if (useSetupIntent) { // Renders differently with stripe themes and waits for createSetupIntent to give us the clientSecret
    const appearance = isDark ? { theme: 'night', labels: 'floating' } : { theme: 'stripe', labels: 'floating' };
    const options = { clientSecret, appearance };
    hidePostalCode = false; // Override hidePostalCode if using setupIntent because we want as much info as possible

    return clientSecret && <Elements options={options} stripe={stripePromise}>
      <CardDetailsForm
        setCardInformationValid={setCardInformationValid}
        cardInformationValid={cardInformationValid}
        useSetupIntent={true}
        useCardElement={false}
        card_options={cardOptionsFn(null, hidePostalCode)}
        {...props}
      />
    </Elements>
  }

  return (<Elements stripe={stripePromise}>
    <CardDetailsForm
      setCardInformationValid={setCardInformationValid}
      cardInformationValid={cardInformationValid}
      useSetupIntent={false}
      useCardElement={useCardElement}
      card_options={cardOptionsFn(isDark, hidePostalCode)}
      {...props}
    />
  </Elements>);
}

StripePaymentForm.propTypes = {
  cardInformationValid: PropTypes.bool,
  setCardInformationValid: PropTypes.func,
  useSetupIntent: PropTypes.bool,
  useCardElement: PropTypes.bool,
  hidePostalCode: PropTypes.bool,
  card_options: PropTypes.object,
  props: PropTypes.object,
};

export default StripePaymentForm;