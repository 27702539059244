import React, { useState, useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, Col, Row } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import { getColor } from 'helpers/utils';
import * as echarts from 'echarts/core';
import { PieChart } from 'echarts/charts';
import Spinner from 'react-bootstrap/Spinner';
import { claimantAPI } from 'utils/api/fulcrum-api';

import {
	GridComponent,
	TooltipComponent,
	TitleComponent
} from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';
import BasicECharts from 'components/common/BasicEChart';

echarts.use([
	TitleComponent,
	TooltipComponent,
	GridComponent,
	PieChart,
	CanvasRenderer
]);



const getOptions = (data, radius) => ({

	color: [
		getColor(data[0].color),
		getColor(data[1].color),
		getColor(data[2].color),
		getColor(data[3].color)
	],

	onEvents: {
		click: function () {
			// alert('test')
		}
	},
	tooltip: {
		padding: [7, 10],
		transitionDuration: 0,
		backgroundColor: getColor('100'),
		borderColor: getColor('300'),
		textStyle: { color: getColor('dark') },
		borderWidth: 1,
		formatter: params =>
			`<strong>${params.data.name}:</strong> ${params.data.value}`
	},
	series: [
		{
			name: `Total Inbound Leads 
			${data.reduce((acc, val) => val.value + acc, 0)}`,
			type: 'pie',
			radius,
			avoidLabelOverlap: false,
			emphasis: {
				scale: false
			},
			itemStyle: {
				borderWidth: 2,
				borderColor: getColor('card-bg')
			},
			label: {
				show: true,
				position: 'center',
				formatter: '{a}',
				fontSize: 10,
				color: getColor('dark')
			},
			data
		}
	]
});

// const InboundLeadMetricItem = ({ item, index, total, setSearchParams = () => { } }) => {
const InboundLeadMetricItem = ({ item, index, setSearchParams = () => { } }) => {
	const { name, color, value, searchParams } = item;
	// const navigate = useNavigate();

	// const percentage = ((value * 100) / total).toFixed(0);

	const handleItemClick = () => {
		setSearchParams(searchParams);
	};

	return (
		<Flex
			alignItems="center"
			justifyContent="between"
			className={`fw-semi-bold fs--2 ${index == 0 && 'mt-3'} cursor-pointer`}
			onClick={() => handleItemClick()}
		>
			<p className="mb-1">
				<FontAwesomeIcon
					icon="circle"
					className={`me-2 text-${color || 'text-info'}`}
				/>
				{value} {name}
			</p>
			{/* <div className="d-xxl-none">{value}</div> */}
		</Flex>
	);
};



const InboundLeadMetrics = ({ radius, setSearchParams = () => { }, siteAdminArea = false }) => {
	const [loading, setLoading] = useState(true);
	const [data, setData] = useState([]);

	// const [radius, setRadius] = useState(['50%', '50%', '0%', '0%']);

	// Categorize inbound lead data
	const categorizeInboundLeads = (inboundLeads) => {
		let uncontactedLeads = 0;
		let unreviewedLeads = 0;
		let unscreenedLeads = 0;
		let outstandingRetainerPackets = 0;

		inboundLeads.forEach((lead) => {
			if (!lead.established_contact) {
				uncontactedLeads++;
			} else if (lead.established_contact && !lead.screened) {
				unscreenedLeads++;
			} else if (lead.established_contact && lead.screened && !lead.reviewed) {
				unreviewedLeads++;
			} else if (lead.established_contact && lead.screened && lead.reviewed) {
				outstandingRetainerPackets++;
			}
		});

		let adjustedData = [
			{ id: 1, value: uncontactedLeads, name: 'Uncontaced Leads', color: 'danger', searchParams: { active_case: false, active_case_id: null, active_claimant: true, established_contact: false } },
			{ id: 2, value: unreviewedLeads, name: 'Unreviewed Leads', color: 'warning', searchParams: { active_case: false, active_case_id: null, active_claimant: true, established_contact: true, screened: false } },
			{ id: 3, value: unscreenedLeads, name: 'Unscreened Leads', color: 'primary', searchParams: { active_case: false, active_case_id: null, active_claimant: true, established_contact: true, screened: true, reviewed: false } },
			{ id: 4, value: outstandingRetainerPackets, name: 'Leads with Outstanding Retainer Packets', color: 'secondary', searchParams: { active_case: false, active_case_id: null, active_claimant: true, established_contact: true, screened: true, reviewed: true } },
		];
		setData(adjustedData);
		setLoading(false);
	};

	// Get inbound lead data
	const getInboundLeadData = async () => {
		try {
			let result = await claimantAPI.searchClaimants({
				active_claimant: true,
				active_case: false,
				active_case_id: null
			});
			if (!result?.data?.rows) {
				throw new Error('Unable to get inbound lead data');
			}
			categorizeInboundLeads(result.data.rows, result.data.count);
		} catch (error) {
			console.log(error);
		}
	};

	// const navigate = useNavigate();

	const handleItemClick = (sp = {}) => {
		setSearchParams(sp);
	};

	const total = data.reduce((acc, val) => val.value + acc, 0);

	useEffect(() => {
		getInboundLeadData();
	}, []);

	useEffect(() => {
		if (data.length > 0) {
			setLoading(false);
		}
	}, [data, radius]);

	return (
		<Card className="h-md-100">
			<Card.Body>
				<Row className="justify-content-between g-0">
					<h6 className="mt-1">Inbound Lead Metrics</h6>


					{
						loading ? (

							<Spinner animation="border" role="status">
								<span className="visually-hidden">Loading...</span>
							</Spinner>
						) :
							<>
								<Col xs={5} sm={6} xxl className="pe-2">
									{data.map((item, index) => (
										<InboundLeadMetricItem
											item={item}
											index={index}
											key={index}
											total={total}
											setSearchParams={setSearchParams}
										/>
									))}
								</Col>
								<Col xs="auto">
									<div className="ps-0">
										<BasicECharts
											echarts={echarts}
											options={getOptions(data, radius)}
											onEvents={{
												click: (event => { handleItemClick(event.data.searchParams) })
											}}
											style={{ width: '7.625rem', height: '7.625rem' }}
										/>
									</div>
								</Col>
							</>
					}

				</Row>
			</Card.Body>
		</Card>
	);
};

InboundLeadMetricItem.propTypes = {
	item: PropTypes.shape({
		name: PropTypes.string.isRequired,
		color: PropTypes.string.isRequired,
		value: PropTypes.number.isRequired,
		searchParams: PropTypes.object.isRequired
	}),
	index: PropTypes.number.isRequired,
	total: PropTypes.number.isRequired,
	setSearchParams: PropTypes.func
};

InboundLeadMetrics.propTypes = {
	radius: PropTypes.number,
	setSearchParams: PropTypes.func
};

export default InboundLeadMetrics;
