import React, { useState } from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import IconButton from 'components/common/IconButton';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import { representativeDisciplinaryReasonAPI } from 'utils/api/fulcrum-api';

const DiscRow = ({ user, saveUser, log, discLog, setDiscLog = () => { }, getRepresentativeDisciplinaryReasons = () => { } }) => {
	let defaultFormData = {
		agency_name: log.agency_name,
		bar_number: log.bar_number,
		year: log.year,
		beginning_date: log.beginning_date,
		ending_date: log.ending_date,
		description: log.description,
	};
	const [formData, setFormData] = useState(defaultFormData);
	const [previousFormData, setPreviousFormData] = useState(defaultFormData);

	// Handle input change
	const handleInputChange = (e) => {
		let { name, value } = e.target;
		setFormData({ ...formData, [name]: value });
	};

	// Update a disc log item
	const updateDiscItem = async (data) => {
		try {
			let result = await representativeDisciplinaryReasonAPI.updateRepresentativeDisciplinaryReason(user.id, log.id, data);
			if (!result?.data) {
				throw new Error('Error updating admitted log item');
			}
			// getRepresentativeDisciplinaryReasons();
			setDiscLog(discLog.map((item) => {
				if (item.id === log.id) {
					return result.data;
				}
				return item;
			}));
			setPreviousFormData(formData);
		} catch (error) {
			console.error(error);
			toast.error('Error updating admitted log item', { theme: 'colored' });
		}
	};

	// Remove a disc log item
	const removeDiscLogItem = async () => {
		Swal.fire({
			title: 'Permanently delete disciplinary log item?',
			text: "You won't be able to revert this!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Yes, delete it!'
		}).then(async (result) => {
			if (result.isConfirmed) {
				try {

					let result = await representativeDisciplinaryReasonAPI.deleteRepresentativeDisciplinaryReason(user.id, log.id);
					if (!result?.message) {
						throw new Error('Error deleting admitted log item');
					}
					// getRepresentativeDisciplinaryReasons();
					setDiscLog(discLog.filter((item) => item.id !== log.id));
					toast.warning('Admitted log item deleted', { theme: 'colored' });
				} catch (error) {
					console.error(error);
					toast.error('Error deleting admitted log item', { theme: 'colored' });
				}
			}
		});
	};

	// Save on blur
	const saveOnBlur = (e) => {
		let { name, value } = e.target;

		if (previousFormData[name] !== value) {
			updateDiscItem({ [name]: value });
			setPreviousFormData({ ...previousFormData, [name]: value });
		}
		setFormData({ ...formData, [name]: value });
	}

	return (
		<>
			<Row>
				<Col sm={12} className={'bg-300 py-3 px-3'}>
					<span className="fw-bold">Disciplinary Details</span>
					<IconButton
						className="me-2 mb-1 float-end"
						variant="falcon-danger"
						size="sm"
						icon="trash"
						transform="shrink-3"
						onClick={() => removeDiscLogItem()}
					>

					</IconButton>
				</Col>
			</Row>
			<Row>
				<Col sm={6} className={'px-0'}>
					<Form.Group controlId="agency_name">
						<FloatingLabel
							controlId="agency_name"
							label="Agency Name"
							className=""
							size="sm"
						>
							<Form.Control
								type="text"
								size="sm"
								name="agency_name"
								value={formData.agency_name}
								onChange={handleInputChange}
								onBlur={saveOnBlur}
							/>
						</FloatingLabel>
					</Form.Group>
				</Col>
				<Col sm={6} className={'px-0'}>
					<Form.Group controlId="bar_number">
						<FloatingLabel
							controlId="bar_number"
							label="Bar Number"
							className=""
							size="sm"
						>
							<Form.Control
								type="text"
								size="sm"
								name="bar_number"
								value={formData.bar_number}
								onChange={handleInputChange}
								onBlur={saveOnBlur}
							/>
						</FloatingLabel>
					</Form.Group>
				</Col>
			</Row>

			<Row>
				<Col sm={4} className={'px-0'}>
					<Form.Group controlId="year">
						<FloatingLabel
							controlId="year"
							label="Year Admitted"
							className=""
							size="sm"
						>
							<Form.Control
								type="text"
								size="sm"
								name="year"
								value={formData.year}
								onChange={handleInputChange}
								onBlur={saveOnBlur}
							/>
						</FloatingLabel>
					</Form.Group>
				</Col>
				<Col sm={4} className={'px-0'}>
					<Form.Group controlId="beginning_date">
						<FloatingLabel
							controlId="beginning_date"
							label="Beginning Date"
							className=""
							size="sm"
						>
							<Form.Control
								type="date"
								size="sm"
								name="beginning_date"
								value={formData.beginning_date}
								onChange={handleInputChange}
								onBlur={saveOnBlur}
							/>
						</FloatingLabel>
					</Form.Group>
				</Col>
				<Col sm={4} className={'px-0'}>
					<Form.Group controlId="ending_date">
						<FloatingLabel
							controlId="ending_date"
							label="Ending Date"
							className=""
							size="sm"
						>
							<Form.Control
								type="date"
								size="sm"
								name="ending_date"
								value={formData.ending_date}
								onChange={handleInputChange}
								onBlur={saveOnBlur}
							/>
						</FloatingLabel>
					</Form.Group>
				</Col>
			</Row>

			<Row>
				<Col sm={12} className={'px-0'}>
					<Form.Group controlId="description">
						<FloatingLabel
							controlId="description"
							label="Description"
							className=""
							size="sm"
						>
							<Form.Control
								type="text"
								size="sm"
								name="description"
								value={formData.description}
								onChange={handleInputChange}
								onBlur={saveOnBlur}
							/>
						</FloatingLabel>
					</Form.Group>
				</Col>
			</Row>

		</>
	);
};
export default DiscRow;