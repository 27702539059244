import React, { useRef, useState, useEffect } from 'react';
import { Col, Form, Button } from 'react-bootstrap';
import AccountTable from './AccountTable';

const AccountTableHeader = ({ } = {}) => {


	return (
		<div>
			<AccountTable

			/>
		</div>
	);
};





export default AccountTableHeader;