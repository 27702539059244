import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Card, Form, Row, Col } from 'react-bootstrap';
import { toast } from 'react-toastify';
import moment from 'moment';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import LogoUploader from './LogoUploader';
import IconButton from 'components/common/IconButton';
import AddressList from 'components/app/address/AddressList';
import PhoneList from 'components/app/phones/PhoneList';
import EmailList from 'components/app/email/emails/EmailList';
import CustomFieldList from 'components/custom-fields/custom-field-list/CustomFieldList';
import { accountAPI } from 'utils/api/fulcrum-api';
import { formatPhone, getParams } from 'utils/functions';
import { stateData } from 'data/stateData';
const assureAuthorizationURL = process.env.REACT_APP_ASSURE_AUTHORIZATION_URL || "https://aws-dev.myassureservices.com/oauth/authorize";
const assureCallback = process.env.REACT_APP_ASSURE_CALLBACK || "http://localhost:3001/api/public/assure/callback";
const assureAdminClientId = Number(process.env.REACT_APP_ASSURE_ADMIN_CLIENT_ID || 9953);
const assureAuthState = process.env?.REACT_APP_ASSURE_AUTHORIZE_STATE || null;

const LogoDisplay = ({ logo } = {}) => {
  return (
    <div style={{ margin: "0 auto", width: "250px" }}>
      <img src={logo} className='img-fluid text-center mb-3' style={{ maxWidth: "100%", maxHeight: "100%", verticalAlign: "middle" }} />
    </div>
  );
};

LogoDisplay.propTypes = {
  logo: PropTypes.string,
};

const AccountProfileCard = ({ account = {}, setAccount = () => { }, siteAdminArea = false }) => {
  const [formData, setFormData] = useState({});
  const [previousFormData, setPreviousFormData] = useState({});

  // Handle form input changes
  const handleInputChange = (event) => {
    let { name, value } = event.target;

    if (['primary_phone', 'secondary_phone', 'fax', 'primary_email', 'secondary_email'].includes(name)) {
      value = formatPhone(value);
    }

    setFormData({ ...formData, [name]: value });
  };

  // Update Account
  const updateAccount = async (updatedData = {}) => {
    if (['assure_client_secret', 'assure_client_id'].some((key) => key in updatedData)) { // Never allow these values to be changed by the user 
      ['assure_client_secret', 'assure_client_id'].forEach((key) => delete updatedData[key]);
    }
    if (Object.keys(updatedData).filter(Boolean).length === 0) {
      return;
    }

    try {
      let result = await accountAPI.updateAccount(account.id, updatedData);
      if (!result?.data) {
        throw new Error('Error updating account');
      }
      setAccount(result.data);
    } catch (error) {
      console.error(error);
      toast.error('Error updating account', { theme: 'colored' });
    }
  };

  // Save on change
  const saveOnChange = (event) => {
    let { name, value } = event.target;
    let additionalChanges = {};

    if (previousFormData[name] !== value && !['assure_client_secret', 'assure_client_id'].includes(name)) {
      // Never allow these values to be changed by the user ['assure_client_secret', 'assure_client_id']
      updateAccount({ [name]: value, ...additionalChanges });
    }
  };

  const handleAssureLink = (e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    if (!formData.assure_client_secret) {
      const authParams = {
        response_type: "code",
        client_id: assureAdminClientId,
        redirect_uri: assureCallback,
        scope: "openid",
        ...(assureAuthState && { state: assureAuthState })
      };
      const authorizeURL = `${assureAuthorizationURL}${getParams(authParams)}`;
      return window.open(authorizeURL, "_self", "noopener,noreferrer");
    };

    const buttonTextEl = e?.target?.parentElement?.querySelector('span');
    const ClientSecretEl = document?.getElementById("assure_client_secret");
    if (!ClientSecretEl?.type || !buttonTextEl?.innerText) {
      return;
    }

    const showClientSecret = ClientSecretEl.type === "password";
    ClientSecretEl.type = showClientSecret ? "text" : "password";
    buttonTextEl.innerText = showClientSecret ? "Hide Assure Client Secret" : "Show Assure Client Secret";
    return;
  };

  // Use effect
  useEffect(() => {
    let newFormData = {
      business_name: account.business_name,
      website_url: account.website_url,
      system_text_number: formatPhone(account.system_text_number),
      system_fax_email: account.system_fax_email,
      system_email: account.system_email,
      mailing_address: account.mailing_address,
      mailing_city: account.mailing_city,
      mailing_state: account.mailing_state,
      mailing_zip: account.mailing_zip,
      primary_email: account.primary_email,
      secondary_email: account.secondary_email,
      fax: account.fax,
      primary_phone: account.primary_phone,
      secondary_phone: account.secondary_phone,
      assure_client_id: account.assure_client_id,
      assure_client_secret: account.assure_client_secret,
    };
    setFormData(newFormData);
    setPreviousFormData(newFormData);
  }, []);

  return (
    <Card className="" >
      <Card.Body>
        <Row>
          <Col className="text-center">
            <LogoDisplay account={account} logo={account?.logo || 'business_logo_default.png'} alt={'Business logo'} />
          </Col>
        </Row>
        <Row>
          <Col className="text-center">
            <LogoUploader account={account} setAccount={setAccount} />
          </Col>
        </Row>
        <hr />
        <Form>
          <Form.Group controlId="business_name">
            <Form.Label>Business</Form.Label>
            <Form.Control
              size="sm"
              type="text"
              name="business_name"
              value={formData.business_name}
              onChange={handleInputChange}
              onBlur={saveOnChange}
            />
          </Form.Group>

          <Form.Group controlId="website_url">
            <Form.Label>Website</Form.Label>
            <Form.Control
              size="sm"
              type="text"
              name="website_url"
              value={formData.website_url}
              onChange={handleInputChange}
              onBlur={saveOnChange}
            />
          </Form.Group>

          <Form.Group controlId="primary_email">
            <Form.Label>Primary Email</Form.Label>
            <Form.Control
              size="sm"
              type="text"
              name="primary_email"
              value={formData.primary_email}
              onChange={handleInputChange}
              onBlur={saveOnChange}
            />
          </Form.Group>

          <Form.Group controlId="secondary_email">
            <Form.Label>Secondary Email</Form.Label>
            <Form.Control
              size="sm"
              type="text"
              name="secondary_email"
              value={formData.secondary_email}
              onChange={handleInputChange}
              onBlur={saveOnChange}
            />
          </Form.Group>

          <Form.Group controlId="primary_phone">
            <Form.Label>Primary Phone</Form.Label>
            <Form.Control
              size="sm"
              type="text"
              name="primary_phone"
              value={formData.primary_phone}
              onChange={handleInputChange}
              onBlur={saveOnChange}
            />
          </Form.Group>

          <Form.Group controlId="secondary_phone">
            <Form.Label>Secondary Phone</Form.Label>
            <Form.Control
              size="sm"
              type="text"
              name="secondary_phone"
              value={formData.secondary_phone}
              onChange={handleInputChange}
              onBlur={saveOnChange}
            />
          </Form.Group>

          <Form.Group controlId="fax">
            <Form.Label>Fax</Form.Label>
            <Form.Control
              size="sm"
              type="text"
              name="fax"
              value={formData.fax}
              onChange={handleInputChange}
              onBlur={saveOnChange}
            />
          </Form.Group>



          <Row className="mt-3 mx-2">
            <Col sm={12} className={'px-0'}>
              <span className="mb-2 fw-bold">Mailing Address</span>
              <Form.Group controlId="mailing_address">
                <FloatingLabel
                  controlId="mailing_address"
                  label="Mailing Address"
                  className=""
                  size="sm"
                >
                  <Form.Control
                    size="sm"
                    type="text"
                    name="mailing_address"
                    value={formData.mailing_address}
                    onChange={handleInputChange}
                    onBlur={saveOnChange}
                  />
                </FloatingLabel>
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-3  mx-2">
            <Col sm={6} className={'px-0'}>
              <Form.Group controlId="mailing_city">
                <FloatingLabel
                  controlId="mailing_city"
                  label="City"
                  className=""
                  size="sm"
                >
                  <Form.Control
                    size="sm"
                    type="text"
                    name="mailing_city"
                    value={formData.mailing_city}
                    onChange={handleInputChange}
                    onBlur={saveOnChange}
                  />
                </FloatingLabel>
              </Form.Group>
            </Col>
            <Col sm={3} className={'px-0'}>
              <Form.Group controlId="mailing_state">
                <FloatingLabel
                  controlId="mailing_state"
                  label="State"
                  className=""
                  size="sm"
                >
                  <Form.Select
                    size="sm"
                    as="select"
                    name="mailing_state"
                    value={formData.mailing_state}
                    onChange={handleInputChange}
                    onBlur={saveOnChange}
                  >
                    <option value=""></option>
                    {
                      Array.isArray(stateData) && stateData.map((state, index) => {
                        return (
                          <option key={index} value={state.abbreviation}>{state.abbreviation}</option>
                        );
                      })
                    }
                  </Form.Select>
                </FloatingLabel>
              </Form.Group>
            </Col>
            <Col sm={3} className={'px-0'}>
              <Form.Group>
                <FloatingLabel
                  controlId="mailing_zip"
                  label="Zip"
                  className=""
                  size="sm"
                >
                  <Form.Control
                    size="sm"
                    type="text"
                    name="mailing_zip"
                    value={formData.mailing_zip}
                    onChange={handleInputChange}
                    onBlur={saveOnChange}
                  />
                </FloatingLabel>
              </Form.Group>
            </Col>
          </Row>


          <hr />
          <Form.Group controlId="system_text_number">
            <Form.Label>System Text Number</Form.Label>
            <Form.Control
              size="sm"
              type="text"
              name="system_text_number"
              value={formData.system_text_number}
              onChange={handleInputChange}
              onBlur={saveOnChange}
              disabled={!siteAdminArea}
            />
          </Form.Group>

          <Form.Group controlId="system_fax_email">
            <Form.Label>System Fax Number</Form.Label>
            <Form.Control
              size="sm"
              type="text"
              name="system_fax_email"
              value={formData.system_fax_email}
              onChange={handleInputChange}
              onBlur={saveOnChange}
              disabled={!siteAdminArea}
            />
          </Form.Group>

          <Form.Group controlId="system_email">
            <Form.Label>System Email</Form.Label>
            <Form.Control
              size="sm"
              type="text"
              name="system_email"
              value={formData.system_email}
              onChange={handleInputChange}
              onBlur={saveOnChange}
              disabled={!siteAdminArea}
            />
          </Form.Group>

          {formData.assure_client_id && <Form.Group controlId="assure_client_id">
            <Form.Label>Assure Client ID</Form.Label>
            <Form.Control
              size="sm"
              type="text"
              name="assure_client_id"
              value={formData.assure_client_id}
              disabled
            />
          </Form.Group>}
          {formData.assure_client_secret && <Form.Group controlId="assure_client_secret">
            <Form.Label>Assure Client Secret</Form.Label>
            <Form.Control
              size="sm"
              type="password"
              name="assure_client_secret"
              value={formData.assure_client_secret}
              disabled
            />
          </Form.Group>}
          <IconButton variant="primary" size="sm" icon={formData.assure_client_secret ? "eye" : "spinner"} className="mt-2 w-100" type="button" iconAlign="left" onClick={handleAssureLink}>
            <span className="d-sm-inline-block ms-1">{formData.assure_client_secret ? "Show Assure Client Secret" : "Link Assure Account"}</span>
          </IconButton>
        </Form>

        <br />
        <span>Created: {account?.createdAt ? moment(account.createdAt).format('MM/DD/YYYY hh:mm A') : 'N/A'}</span>
        <br />
        <span>Account Owner: {account?.account_owner_id || 'N/A'}</span>
        <br />
        {/* <div className='my-3'>
          <AddressList entity_id={account.entity_id} />
        </div>
        <div className='mb-3'>
          <PhoneList entity_id={account.entity_id} />
        </div>
        <div className='mb-3'>
          <EmailList entity_id={account.entity_id} />
        </div> */}

        <CustomFieldList
          account_id={account.id}
          model={"ACCOUNT"}
          entity_id={account.entity_id}
          view={'ACCOUNT_DETAILS'}
          options={{}}
        />
      </Card.Body>
    </Card >
  );
};

AccountProfileCard.propTypes = {
  account: PropTypes.object,
  setAccount: PropTypes.func,
  siteAdminArea: PropTypes.bool,
};

export default AccountProfileCard;