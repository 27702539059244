export const permissionsReducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case 'NEW_PERMISSIONS_LIST':
      return {
        ...state,
        permissions: payload
      };
    case 'NEW_SETTINGS_LIST':
      return {
        ...state,
        settings: payload
      };
    default:
      return state;
  }
};
