import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import AppContext from 'context/Context';
import auth from 'utils/auth';
import AtlaswareOnboardingWizard from './AtlaswareOnboardingWizard';
import ScheduleADemoModal from 'components/app/hubspot/ScheduleADemoModal';

const AtlaswareOnboardingModal = ({
	account_id = auth.getProfile()?.data?.account_id || null,
	modalState,
	setModalState = () => { },
	user = {},
	callback = () => { },
	refreshParent = (() => { })
}) => {
	const {
		config: { isDark }
	} = useContext(AppContext);
	const navigate = useNavigate();
	const [scheduleADemoModalState, setScheduleADemoModalState] = useState(false);
	// handle show
	const handleShow = () => {
	};



	// Schedule an onboarding session with Assure callback
	const scheduleSessionCallback = () => {
		setScheduleADemoModalState(false);
		setModalState(false);
	}

	// Handle Close
	const handleClose = () => {
		setModalState(false);
	};

	return (
		<>
			<Modal
				show={modalState}
				onHide={handleClose}
				onShow={handleShow}
				contentClassName="border"
				size='xl'
			>
				<Modal.Header
					closeButton
					closeVariant={isDark ? 'white' : undefined}
					className="bg-light px-card border-bottom-0 bg-300"
				>
					<Modal.Title as="h5">Atlasware Onboarding Wizard</Modal.Title>
				</Modal.Header>
				<Modal.Body className="p-card bg-200">
					<AtlaswareOnboardingWizard
						setModalState={setModalState}
						user={user}
						refreshParent={refreshParent}
						handleClose={handleClose}
						callback={callback}
						scheduleADemoModalState={scheduleADemoModalState}
						setScheduleADemoModalState={setScheduleADemoModalState}
						scheduleSessionCallback={scheduleSessionCallback}
					/>
				</Modal.Body>
				<Modal.Footer className="bg-light px-card border-top-0 bg-200">



					<br />
					{/* <br />
				- or - */}
					<br />
					<br />
					<span className="fw">Need help?</span>
					<br />
					<Button
						variant="link"
						onClick={() => setScheduleADemoModalState(true)}
					>
						Schedule an Onboarding Session with Assure
					</Button>
					<ScheduleADemoModal
						modalState={scheduleADemoModalState}
						setModalState={setScheduleADemoModalState}
						title={"Schedule an Atlasware Onboarding Session"}
						message={"Schedule a session with Assure to assist you in setting you up with your Atlasware onboarding process."}
						callBack={scheduleSessionCallback}
					/>
				</Modal.Footer>
			</Modal>
		</>
	);
};

export default AtlaswareOnboardingModal;