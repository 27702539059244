import React from 'react';
import BriefRequestTable from './BriefRequestTable';

const Briefs = () => {
	return (
		<div>
			<BriefRequestTable
				columns={
					[
						{
							accessor: 'id',
							Header: 'ID',
							headerProps: { className: 'pe-1' },
							cellProps: {
								className: 'py-2'
							},
							// Cell: rowData => {
							// 	const { name, id } = rowData.row.original;
							// 	return <Link to={`/claimant/profile/${id}`}>{name}</Link>;
							// }
						},
						{
							accessor: 'claimant_name',
							Header: 'Claimant',
							headerProps: { className: 'pe-1' },
							cellProps: {
								className: 'py-2'
							}
						},
						{
							accessor: 'status',
							Header: 'Status',
							headerProps: { className: 'pe-1' },
							cellProps: {
								className: 'py-2'
							},
						},
						{
							accessor: 'due_at',
							Header: 'Due',
							headerProps: { className: 'pe-1' },
							cellProps: {
								className: 'py-2'
							},
						},
						{
							accessor: 'created_at',
							Header: 'Requested',
							headerProps: { className: 'pe-1' },
							cellProps: {
								className: 'py-2'
							},
						},
						{
							accessor: 'brief_type',
							Header: 'Type',
							headerProps: { className: 'pe-1' },
							cellProps: {
								className: 'py-2'
							},
							Cell: rowData => {
								const { name, id } = rowData.row.original;
								let briefType = 'Unknown';
								switch (rowData.value) {
									case 1:
										briefType = 'Pre-Hearing';
										break;
									case 2:
										briefType = 'On-The-Record';
										break;
									case 3:
										briefType = 'Post-Hearing';
										break;
									case 4:
										briefType = 'Appellate';
										break;
									case 5:
										briefType = 'Other';
										break;
									default:
										briefType = 'Unknown';
										break;
								}
								return briefType;
							}
						},
						{
							accessor: 'menu',
							Header: 'Menu',
							headerProps: { className: 'pe-1' },
							cellProps: {
								className: 'py-2'
							},
						}
					]
				}
			/>
		</div>
	);
};

export default Briefs;