import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Dropdown from 'react-bootstrap/Dropdown';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import CardDropdown from 'components/common/CardDropdown';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import { claimantHospitalVisitAPI } from 'utils/api/fulcrum-api';


const HospitalRow = ({ hospital, claimant, getClaimantHospitalVisits = () => { } }) => {
	let defaultFormData = {
		hospital: hospital.hospital,
		phone: hospital.phone,
		address: hospital.address,
		date_of_admission: hospital?.date_of_admission || '',
		duration_of_admission: hospital.duration_of_admission
	};
	const [formData, setFormData] = useState(defaultFormData);
	const [previousFormData, setPreviousFormData] = useState(defaultFormData);

	// Remove a hospital visit
	const removeHospitalVisit = () => {
		Swal.fire({
			title: 'Permanently delete this hospital visit record?',
			text: "You won't be able to revert this!",
			icon: 'warning',
			showCansetFormDatalButton: true,
			confirmButtonColor: '#3085d6',
			cansetFormDatalButtonColor: '#d33',
			confirmButtonText: 'Yes, delete it!',
			cancelButtonColor: '#d33',
			showCancelButton: true,
		}).then(async (result) => {
			if (result.isConfirmed) {
				try {
					let result = await claimantHospitalVisitAPI.deleteClaimantHospitalVisit(claimant.id, hospital.id);
					if (!result?.message) {
						throw new Error('No data returned from server');
					}
					getClaimantHospitalVisits();
					toast.warning('Hospital visit removed', { theme: 'colored' });
				} catch (error) {
					console.error(error);
					toast.error('Error deleting hospital visit', { theme: 'colored' });
				}
			}
		});
	};

	// Update a hospital visit
	const updateCurrentHospitalVisit = async (hospitalVisitId, data) => {
		try {
			let result = await claimantHospitalVisitAPI.updateClaimantHospitalVisit(claimant.id, hospitalVisitId, data);
			console.log(result);
			if (!result) {
				throw new Error('Unable to update the hospital visit');
			}
			toast.success('Hospital visit updated', { theme: 'colored' });
			setPreviousFormData({ ...formData, ...data });

		} catch (error) {
			console.error(error);
			toast.error('Error updating the hospital visit', {
				theme: 'colored'
			});
		}
	};

	// Save on change
	const saveOnChange = async (e, saveChange) => {
		let { name, value } = e.target;

		if (saveChange && previousFormData[name] !== value) {
			await updateCurrentHospitalVisit(hospital.id, { [name]: value });
		}

		setFormData({ ...formData, [name]: value });
	};

	useEffect(() => {
		let hospitalVisitData = {
			hospital: hospital.hospital,
			phone: hospital.phone,
			address: hospital.address,
			date_of_admission: hospital.date_of_admission,
			duration_of_admission: hospital.duration_of_admission
		};
		setFormData(hospitalVisitData);
		setPreviousFormData(hospitalVisitData);
	}, [hospital])

	return (
		<Form>
			<Row>
				<Col>
					<div className="d-flex justify-content-between float-end">
						<CardDropdown>
							<div className="py-2">
								<Dropdown.Item onClick={() => { removeHospitalVisit(hospital.id) }} className="text-danger">Delete</Dropdown.Item>
							</div>
						</CardDropdown>
					</div>
				</Col>

			</Row>
			<Row>
				<Col sm={12} className={'px-0'}>
					<Form.Group controlId="hospital">
						<FloatingLabel
							controlId="hospital"
							label="Name"
							className=""
							size="sm"
						>
							<Form.Control
								size="sm"
								type="text"
								name="hospital"
								value={formData.hospital}
								onChange={e => saveOnChange(e, false)}
								onBlur={e => saveOnChange(e, true)}
							/>
						</FloatingLabel>
					</Form.Group>
				</Col>
			</Row>
			<Row>
				<Col sm={12} className={'px-0'}>
					<Form.Group controlId="address">
						<FloatingLabel
							controlId="address"
							label="Address"
							className=""
							size="sm"
						>
							<Form.Control
								size="sm"
								type="text"
								name="address"
								value={formData.address}
								onChange={e => saveOnChange(e, false)}
								onBlur={e => saveOnChange(e, true)}
							/>
						</FloatingLabel>
					</Form.Group>
				</Col>
			</Row>
			<Row>
				<Col sm={4} className={'px-0'}>
					<Form.Group controlId="phone">
						<FloatingLabel
							controlId="phone"
							label="Phone Number"
							className=""
							size="sm"
						>
							<Form.Control
								size="sm"
								type="text"
								name="phone"
								value={formData.phone}
								onChange={e => saveOnChange(e, false)}
								onBlur={e => saveOnChange(e, true)}
							/>
						</FloatingLabel>
					</Form.Group>
				</Col>
				<Col sm={4} className={'px-0'}>
					<Form.Group controlId="date_of_admission">
						<FloatingLabel
							controlId="date_of_admission"
							label="Date of Admission"
							className=""
							size="sm"
						>
							<Form.Control
								size="sm"
								type="date"
								name="date_of_admission"
								value={formData.date_of_admission}
								onChange={e => saveOnChange(e, false)}
								onBlur={e => saveOnChange(e, true)}
							/>
						</FloatingLabel>
					</Form.Group>
				</Col>
				<Col sm={4} className={'px-0'}>
					<Form.Group controlId="duration_of_admission">
						<FloatingLabel
							controlId="duration_of_admission"
							label="Admission Duration"
							className=""
							size="sm"
						>
							<Form.Control
								size="sm"
								type="text"
								name="duration_of_admission"
								value={formData.duration_of_admission}
								onChange={e => saveOnChange(e, false)}
								onBlur={e => saveOnChange(e, true)}
							/>
						</FloatingLabel>
					</Form.Group>
				</Col>
			</Row>
		</Form>
	);
};

HospitalRow.propTypes = {
	hospital: PropTypes.object,
	claimant: PropTypes.object,
	getClaimantHospitalVisits: PropTypes.func
}

export default HospitalRow;