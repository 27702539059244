import React from 'react';
import InboundLeadTable from 'components/claimants/InboundLeadTable';
import auth from 'utils/auth';

const InboundLeads = () => {
	return (
		<>
			<InboundLeadTable
				requiredSearchParams={{
					account_id: auth.getProfile().data.account_id
				}}
			/>
		</>
	);
};

export default InboundLeads;
