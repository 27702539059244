import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, Alert, Form } from 'react-bootstrap';
import { caseAPI, myFormsAPI } from 'utils/api/fulcrum-api';
import { toast } from 'react-toastify';
import JoditEditor from "jodit-pro-react";
import Swal from 'sweetalert2';
let DEFAULT_FORM_ID = 23;

const editorConfig = {
	// licnese: 
	readonly: false, // all options from https://xdsoft.net/jodit/doc/
	height: "600"
}

// const RequestCaseStatusUpdate = ({ claimant, setClaimant, selectedCase, setSelectedCase = () => { } }) => {
const RequestCaseStatusUpdate = ({ modalState, setModalState = () => { }, claimant, selectedCase }) => {
	const [loading, setLoading] = useState(false);
	const [errors, setErrors] = useState([]);
	const editor = useRef(null);
	const [preview, setPreview] = useState(false);
	let defaultState = {
		content: '',
		recipient: claimant?.claimant_active_case?.office_with_jurisdiction_fax,
		mode_of_transmission: 'FAX',
	};
	const [formData, setFormData] = useState(defaultState);

	// Handle input change
	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setFormData({ ...formData, [name]: value });
	};

	// Get rendered content
	const getRenderedContent = async (id) => {
		setLoading(true);
		try {
			let result = null
			if (id) {
				result = await myFormsAPI.getRenderedMyFormContentById(DEFAULT_FORM_ID, {
					claimant_id: claimant.id,
					case_id: selectedCase.id
				});
			} else {
				result = await myFormsAPI.getRenderedMyFormContent(formData.content, {
					claimant_id: claimant.id,
					case_id: selectedCase.id
				});
			}

			if (!result?.data || !result?.data?.adjustedContent) {
				throw new Error('Error getting rendered content');
			}

			setFormData({ ...formData, content: result.data.adjustedContent });
		} catch (error) {
			toast.error('Unable to get rendered content preview', { theme: 'colored' });
			setPreview(false);
		}
		setLoading(false);
	};

	// Handle Confirm SSA Application Button Click
	const handleRequestCaseStatusUpdateClick = async () => {
		Swal.fire({
			title: 'Request updated case status from SSA?',
			text: "This will send a fax to SSA requesting an update of the case status.",
			icon: 'question',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Request Update'
		}).then(async (result) => {
			if (result.isConfirmed) {
				try {
					let result = await caseAPI.requestUpdatedCaseStatus(selectedCase.claimant_id, selectedCase.id, formData);
					if (result?.message !== "Request for case status update has been sent.") {
						throw new Error('Unable to confirm the SSA application.');
					}
					toast.success("SSA applicaiton confirmation completed!", {
						theme: 'colored'
					});
					handleClose();
				} catch (error) {
					toast.error("Unable to confirm the appliation", {
						theme: 'colored'
					});
				}
			}
		});
	};

	// Handle modal close
	const handleClose = () => {
		setFormData(defaultState);
		setLoading(false);
		setPreview(false);
		setModalState(false);
	};

	// Handle modal show
	const handleShow = () => {
		getRenderedContent(DEFAULT_FORM_ID);
	};

	return (
		<div>
			{/* <Button variant="falcon-primary" onClick={() => { setModalState(true) }}>Request Update From SSA</Button> */}

			<Modal show={modalState} size="lg" onHide={handleClose} onShow={handleShow}>
				<Modal.Header closeButton>
					<Modal.Title>Request Case Status Update From SSA</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Alert variant={'primary'}>
						Submit a case status update request from SSA. This will fax a message to SSA through the outbound correspondence tool.
					</Alert>

					{
						errors.length > 0 &&
						<Alert variant={'danger'}>
							<ul>
								{errors.map((error, index) => {
									return <li key={index}>{error}</li>
								})}
							</ul>
						</Alert>
					}

					{
						!claimant?.claimant_active_case?.field_office_fax && (
							<Alert variant="warning">
								Cannot find claimants office with jurisdiction fax number. Please provide a fax number or fix the claimant record and try again.
							</Alert>
						)
					}

					<Form.Group className="mb-3" controlId="recipient">
						<Form.Label>Recipient</Form.Label>
						<Form.Control type="text" name="recipient" value={formData.recipient} onChange={handleInputChange} />
					</Form.Group>


					{
						preview || true
							?
							(loading ? <div className="text-center"><i className="fal fa-spinner-third fa-spin fa-3x"></i></div>
								:
								<>
									<div className="mb-4 mt-4">
										<h5 className="d-inline">Preview and Modify Message</h5>

										<div className="float-end">
											<Button variant="falcon-warning" className="" onClick={() => {
												setPreview(true);
												getRenderedContent(DEFAULT_FORM_ID);
											}}>Reset</Button>
											{' '}&nbsp;
											<Button variant="falcon-primary" className="" onClick={() => {
												setPreview(true);
												getRenderedContent();
											}}>Refresh Preview</Button>
										</div>
									</div>
									{/* <hr /> */}
									<JoditEditor
										ref={editor}
										value={formData.content}
										config={editorConfig}
										tabIndex={1} // tabIndex of textarea
										onChange={newContent => { handleInputChange({ target: { name: "content", value: newContent } }) }}
									/>
								</>
							)
							:
							<div>
								<Button variant="falcon-primary" onClick={() => {
									setPreview(true);
									getRenderedContent(DEFAULT_FORM_ID);
								}}>Preview & Modify Message</Button>
							</div>
					}
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleClose}>
						Close
					</Button>
					<Button variant="primary" onClick={handleRequestCaseStatusUpdateClick}>
						Submit Request
					</Button>
				</Modal.Footer>
			</Modal>
		</div>
	);
};

RequestCaseStatusUpdate.propTypes = {
	claimant: PropTypes.object,
	selectedCase: PropTypes.object
}

export default RequestCaseStatusUpdate;