import React, { useState } from 'react';
import PageHeader from 'components/common/PageHeader';
import { Accordion, Card } from 'react-bootstrap';
import { fulcrumFaq } from 'data/faqs';
import FaqAccordionItem from 'components/pages/faq/faq-accordion/FaqAccordionItem';
import Section from 'components/common/Section';

const Faq = () => {
  const [faqs] = useState(fulcrumFaq);
  return (
    <Section bg="light" className="text-center">
      <PageHeader
        title="Frequently Asked Questions"
        description="Below you'll find answers to the questions we get asked the most about to join with Fulcrum."
        className="mb-3"
      />
      <Card>
        <Card.Body>
          <Accordion
            id="accordionFaq"
            className="border rounded overflow-hidden"
          >
            {faqs.map((faq, index) => (
              <FaqAccordionItem
                key={faq.id}
                faq={faq}
                isFirst={index === 0}
                isLast={index === faqs.length - 1}
              />
            ))}
          </Accordion>
        </Card.Body>
      </Card>
    </Section>
  );
};

export default Faq;