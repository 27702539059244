import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { formatDate } from 'utils/functions';
import IconButton from 'components/common/IconButton';
import ExportReportButton from 'components/ExportReportButton';

const TaskTableHeader = ({
	selectedRowIds,
	page,
	label = "Tasks",
	requiredSearchParams = {},
	searchParams = {},
	defaultSearchParams = {},
	setSearchParams = () => { },
	setTaskCreateModal = () => { },
	clearSearchParams = () => { },
	taskData = {} }) => {
	const taskIdsSelected = Array.isArray(page)
		? (page.reduce((acc, { isSelected = false, original } = {}) => (
			[...acc, ...(isSelected ? [original] : [])]
		), [])).map(({ id } = {}) => id)
		: [];

	return (
		<Row className="flex-between-center">
			<Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
				<h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">{label}</h5>
			</Col>
			<Col xs={8} sm="auto" className="ms-auto text-end ps-0">
				{Object.keys(selectedRowIds).length > 0 ? (
					<div className="d-flex">

					</div>
				) : (
					<div id="orders-actions">
						{
							JSON.stringify(searchParams) !== JSON.stringify({ ...defaultSearchParams, ...requiredSearchParams }) && (
								<IconButton
									size="sm"
									variant="outline-danger"
									className="me-2"
									icon="x"
									onClick={() => {
										clearSearchParams();
									}}
								>Clear Filters</IconButton>
							)
						}
						<IconButton
							variant="falcon-default"
							size="sm"
							icon="plus"
							transform="shrink-3"
							className="me-2"
							onClick={() => { setTaskCreateModal(true) }}
						>
							<span className="d-none d-sm-inline-block ms-1">New Task</span>
						</IconButton>
						<ExportReportButton
							controllerName="Task"
							fnName="searchAllTaskFn"
							saveAs={`${label.replace("Report", "").trim()}_${formatDate(new Date(), "YYYY-MM-DD")}.xlsx`}
							params={{
								...searchParams,
								offset: 0,
								limit: null,
								selectedRowIds: taskIdsSelected,
							}}
							additionalParamsArray={[false]} // countOnly argument for searchAllTaskFn
							flattenData // whenever the return data rows has dataValues or any objects inside it, set this to true to flatten the data in the rows exported to excel
							appendDuplicates={false} // if true, flattened data with duplicates will be appended to each other 
							overwriteDuplicates={false} // if true, flattened data with duplicates will be overwritten with the last value. false will keep the first value and any more duplicates will be ignored 
						/>
					</div>
				)}
			</Col>

		</Row>
	);
};

TaskTableHeader.propTypes = {
	selectedRowIds: PropTypes.object,
	page: PropTypes.array,
	label: PropTypes.string,
	requiredSearchParams: PropTypes.object,
	searchParams: PropTypes.object,
	defaultSearchParams: PropTypes.object,
	setSearchParams: PropTypes.func,
	setTaskCreateModal: PropTypes.func,
	clearSearchParams: PropTypes.func,
	taskData: PropTypes.object
};

export default TaskTableHeader;