import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { userAPI } from 'utils/api/fulcrum-api';
import ViewAttorneys from 'components/pages/assure/attorneys/ViewAttorneys';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';

const LinkAssureAttorney = ({ user, setUser = () => { }, assureAttorneyInfo, setAssureAttorneyInfo = () => { } }) => {
	const [attorneyModalState, setAttorneyModalState] = useState(false);
	// Check if attorney is already linked to a user
	const checkIfAttorneyIsLinked = async (assure_attorney_id) => {
		try {
			let response = await userAPI.searchUsers({});
			if (response?.data?.code !== 200 || !response?.data?.data) {
			}
		} catch (error) {
			console.error(error);
			toast.error('Something went wrong while checking if attorney is linked to a user.', { theme: 'colored' });
		}
	};

	// Update Assure attorney ID on user
	const updateAssureAttorneyId = async (params) => {
		try {
			let response = await userAPI.updateUser(user.id, { ...params });
			if (!response?.data) {
				throw new Error('No response data');
			}

			setUser(response.data);
			toast.success('Successfully linked attorney to this user.', { theme: 'colored' });
		} catch (error) {
			console.error(error);
			toast.error('Something went wrong while linking the user to the Assure attorney.', { theme: 'colored' });
		}
	};

	// Handle Attorney Select
	const handleAttorneySelect = (attorney) => {
		updateAssureAttorneyId({ assure_attorney_id: attorney.id });
		setAssureAttorneyInfo(attorney);
	};

	// Unlink Assure Attorney Attorney
	const unlinkAssureAttorney = () => {
		Swal.fire({
			title: 'Unlink user from Assure Attorney?',
			text: "This will unlink the user from the Assure Attorney. Are you sure you want to continue?",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Yes, unlink it.'
		}).then(async (result) => {
			if (result.isConfirmed) {
				updateAssureAttorneyId({ assure_attorney_id: null });
				setAssureAttorneyInfo({});
			}
		});
	};

	return (
		<div>
			{
				!user?.assure_attorney_id ?
					<div>

						<Button variamt="link" onClick={() => { setAttorneyModalState(true) }}>Link Existing Assure Attorney</Button>

						<ViewAttorneys
							modalState={attorneyModalState}
							setModalState={setAttorneyModalState}
							attorneySelectCallBack={handleAttorneySelect}
						/>
					</div>
					:
					<div>
						<Button variant="link" onClick={() => { unlinkAssureAttorney() }}>Unlink Attorney</Button>
					</div>
			}
		</div>
	);
}

export default LinkAssureAttorney;