import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';
import { notificationAPI } from 'utils/api/fulcrum-api';
import moment from 'moment';
import Auth from 'utils/auth';
import { toast } from 'react-toastify';
import handleNotification from 'helpers/handleNotification';

const ProcessNotificationModal = ({ show, handleClose, notification, setNotification, notifications, setNotifications }) => {

    const updateNotification = async (data) => {
        try {
            const result = await notificationAPI.updateMyNotification(notification.id, data);
            if (!result?.data) {
                throw new Error('Unable to update the notification.');
            }

            setNotifications([
                ...notifications.filter((note) => note.id !== result.data.id),
                result.data
            ]);
            setNotification(result.data);
            toast.success('Updated notification.', { theme: 'colored' });

        } catch (error) {
            console.log(error);
            toast.error('Unable to update the notification.', { theme: 'colored' });
        }
    };

    const toggleDismissed = async () => {
        if (notification.dismissed_timestamp) {
            // Mark as unread
            updateNotification({
                dismissed_timestamp: null,
                dismissed_by_id: null
            });
        } else {
            // Dismiss
            updateNotification({
                dismissed_timestamp: moment.now(),
                dismissed_by_id: Auth.getProfile().data.user_id
            });
            handleClose();
        }
    }

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Notification</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>{notification?.notification_type?.message} {notification?.message}</p>
            </Modal.Body>
            <Modal.Footer>
                {
                    !notification.dismissed_timestamp
                        ?
                        <>
                            <Button variant='secondary' type="button" onClick={toggleDismissed}>
                                {notification?.notification_type?.cancel_button_text || 'Dismiss'}
                            </Button>
                            {notification?.notification_type?.submit_button_text &&
                                <Button
                                    variant='primary'
                                    type="button"
                                    onClick={() => { handleNotification(notification); toggleDismissed(); }}
                                >
                                    {notification?.notification_type?.submit_button_text}
                                </Button>}
                        </>
                        :
                        <>
                            <Button variant='secondary' type="button" onClick={toggleDismissed}>
                                Mark as Unread
                            </Button>
                        </>
                }
            </Modal.Footer>
        </Modal>
    );
}

ProcessNotificationModal.propTypes = {
    show: PropTypes.bool,
    handleClose: PropTypes.func,
    notification: PropTypes.object,
    setNotification: PropTypes.func,
    notifications: PropTypes.array,
    setNotifications: PropTypes.func
}

export default ProcessNotificationModal;