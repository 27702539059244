import PropTypes from 'prop-types';
import React, { useState, useContext, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { userAPI, accessVerificationAPI } from '../../utils/api/fulcrum-api';
import auth from '../../utils/auth';
import { AuthContext } from 'context/Context';
import IconAlert from 'components/common/IconAlert';


const TFAForm = ({ uuid }) => {
	const ref = useRef();
	const navigate = useNavigate();
	const [newAccessCodeSent, setNewAccessCodeSent] = useState(false);

	// State
	const [formData, setFormData] = useState({
		tfa_code: '',
	});

	const {
		permissionsDispatch
	} = useContext(AuthContext);

	// Handler
	const handleSubmit = async e => {
		e.preventDefault();

		try {
			let result = await userAPI.postTFACode(uuid, formData.tfa_code);
			if (!result?.session) {
				throw new Error('Incorrect One-Time Code');
			}
			toast.success(`Logged in as ${formData.email}`, {
				theme: 'colored'
			});

			auth.login({ data: { ...result.session } }, permissionsDispatch, navigate);
		} catch (error) {
			toast.error(error.message, {
				theme: 'colored'
			});
		}
	};

	// Resend TFA code
	const resendTFA = async e => {
		e.preventDefault();
		try {
			let result = await accessVerificationAPI.resendGenericAccessCodeRoute(uuid, {});
			if (!result?.data) {
				throw new Error('Unabled to send new access code.');
			}
			setNewAccessCodeSent(true);
			toast.success('New access code sent.', { theme: 'colored' });
		} catch (error) {
			toast.error('Unable to send new access code.', { theme: 'colored' });
		}
	};

	const handleFieldChange = e => {
		setFormData({
			...formData,
			[e.target.name]: e.target.value
		});
	};

	useEffect(() => {
		ref.current.focus();
	}, []);

	return (
		<Form onSubmit={handleSubmit}>

			{newAccessCodeSent &&
				<IconAlert
					variant="success"
					className="mb-3"
					dismissible={true}
					onClose={() => setNewAccessCodeSent(false)}
				>
					<p>New access code sent.</p>
				</IconAlert>
			}

			<p>A one-time passcode has been sent to your email. You will need to provide this code below to complete the login process</p>
			<Form.Group className="mb-3">
				<Form.Label>One-time code</Form.Label>
				<Form.Control
					placeholder={'Enter one-time code'}
					value={formData.tfa_code}
					name="tfa_code"
					onChange={handleFieldChange}
					type="text"
					ref={ref}
				/>
			</Form.Group>

			<Row className="justify-content-between align-items-center">
				<Col xs="auto">
					<Button
						className="fs--1 mb-0"
						variant="link"
						onClick={resendTFA}
					>
						Send a new code.
					</Button>
				</Col>
			</Row>

			<Form.Group>
				<Button
					type="submit"
					color="primary"
					className="mt-3 w-100"
					disabled={!formData.tfa_code}
				>
					Submit
				</Button>
			</Form.Group>
		</Form>
	);
};

TFAForm.propTypes = {
	layout: PropTypes.string,
	hasLabel: PropTypes.bool,
	uuid: PropTypes.string
};

TFAForm.defaultProps = {
	layout: 'simple',
	hasLabel: false
};

export default TFAForm;
