import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import DocumentGeneratorForm from './DocumentGeneratorForm';

const DocumentGenerator = (props) => {
	const navigate = useNavigate();
	return (<div>
		<div className="d-flex align-items-center mb-3">
			<Button type="button" variant="link" onClick={() => navigate('/my-forms')}>
				Back to My Forms
			</Button>
		</div>

		<h1 className="h3 mb-3">Document Generator</h1>

		<DocumentGeneratorForm {...props} />
	</div>);
};

export default DocumentGenerator;