import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Card, Dropdown, Button } from 'react-bootstrap';
import moment from 'moment';
import { removeEmptyFromObj } from 'utils/functions';
import { assureAPI } from 'utils/api/fulcrum-api';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import CustomAdvanceTablePagination from 'components/common/advance-table/CustomAdvanceTablePagination';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import CardDropdown from 'components/common/CardDropdown';
import StatusReportRequestTableHeader from './StatusReportRequestTableHeader';
import CreateStatusReportRequest from './CreateStatusReportRequest';
import StatusReportRequestDetails from './StatusReportRequestDetails';
import ReactInterval from 'react-interval';
import auth from 'utils/auth';

const defaultColumns = [
	{
		accessor: 'assure_id',
		Header: 'Assure ID',
		headerProps: { className: 'pe-1' },
		cellProps: {
			className: 'py-2'
		}
	},
	{
		accessor: 'id',
		Header: 'ID',
		headerProps: { className: 'pe-1' },
		cellProps: {
			className: 'py-2'
		},
	},
	{
		accessor: 'status',
		Header: 'Status',
		headerProps: { className: 'pe-1' },
		cellProps: {
			className: 'py-2'
		},
	},
	{
		accessor: 'status_reason',
		Header: 'Status Reason',
		headerProps: { className: 'pe-1' },
		cellProps: {
			className: 'py-2'
		},
	},
	{
		accessor: 'created_at',
		Header: 'Requested',
		headerProps: { className: 'pe-1' },
		cellProps: {
			className: 'py-2'
		},
	},
	{
		accessor: 'ac_report',
		Header: 'Report Type',
		headerProps: { className: 'pe-1' },
		cellProps: {
			className: 'py-2'
		},
	},
	{
		accessor: 'menu',
		Header: '',
		headerProps: { className: 'pe-1' },
		cellProps: {
			className: 'py-2'
		},
	}
];

const StatusReportRequestTable = ({ account_id = auth.getProfile()?.data?.account_id || null, claimant = {}, tableHeaderLabel = "Status Report Requests", columns = defaultColumns, defaultSearchParams = {}, requiredSearchParams = {} }) => {
	const [createStatusReportRequestModal, setCreateStatusReportRequestModal] = useState(false);
	const [statusReportRequestDetailsModal, setStatusReportRequestDetailsModal] = useState(false);
	const [statusReportRequestData, setStatusReportRequestData] = useState([]);
	const [selectedStatusReportRequest, setSelectedStatusReportRequest] = useState({});
	const [statusReportRequestCount, setStatusReportRequestCount] = useState(0);
	const [Pages, setPages] = useState([1]);
	const [activePage, setActivePage] = useState(1);
	const [perPage, setPerPage] = useState(10);
	const [searching, setSearching] = useState(true);
	const [lastQuery, setLastQuery] = useState({});
	const [searchParams, setSearchParams] = useState({ ...defaultSearchParams, ...requiredSearchParams });
	const [appliedSortOrders, setAppliedSortOrders] = useState([]);
	const [attorneyList, setAttorneyList] = useState([]);

	const headerClickFn = (id, previousSortBy) => {
		if (!id) {
			return;
		}
		let foundIndex = appliedSortOrders.findIndex(s => s[0] === id);
		let sortBy = !previousSortBy ? "ASC" : previousSortBy === "asc" ? "DESC" : '';

		if (!sortBy) { // Not Sorted so remove clicked sort item 
			if (foundIndex > -1) {
				setAppliedSortOrders((prev) => {
					let newSortOrders = [...prev];
					newSortOrders.splice(foundIndex, 1); // remove clicked sort item
					return newSortOrders;
				});
			}
		} else { // Sort by Ascending or Descending 
			let newSort = [id, sortBy];
			if (foundIndex > -1) { // replaces existing 
				setAppliedSortOrders((prev) => {
					let newSortOrders = [...prev];
					newSortOrders[foundIndex] = newSort;
					return newSortOrders;
				});
			} else { // inserts 
				setAppliedSortOrders((prev) => [...prev, newSort]);
			}
		}
	};



	// Get Attorney List
	const getAttorneyList = async () => {
		try {
			let result = await assureAPI.listEreCredentials(account_id || null);
			if (!result?.data?.data) {
				throw new Error("Unable to retrieve a list of attorney credentials.");
			}
			setAttorneyList(Array.isArray(result.data.data) ? result.data.data : [])
		} catch (error) {
			toast.error("Unbale to retrieve a list of attorney credentials.", { theme: 'colored' });
		}
	};

	const formatData = (data = []) => {
		let adjustedData = data.map(record => {
			return {
				...record,
				ac_report: !record.ac_report ? "Hearing Status Report" : "Appeals Status Report",
				created_at: record?.createdAt ? moment(record.createdAt).format('MM/DD/YYYY hh:mm A') : null,
				menu: (<div className="d-flex justify-content-between float-end">
					<Button
						variant="falcon-primary"
						onClick={async () => { setSelectedStatusReportRequest(record); setStatusReportRequestDetailsModal(true) }}
					>View</Button>
				</div>)
			}
		});
		setStatusReportRequestData(adjustedData);
	};

	const statusReportRequestSearch = async (params = {}, saveLastQuery = false, options = {}) => {
		try {
			params = removeEmptyFromObj(params);
			params.page = activePage;
			params.limit = perPage;
			params.navSize = 5; // How many buttons to show for navigation 
			params.order = appliedSortOrders; // Example [[id, 'ASC']]
			let { error, response, data } = await assureAPI.searchStatusReportRequests(params, options);
			if (error) {
				if (typeof response?.error === 'string') {
					throw new Error(`${error} - ${response.error}`);
				} else if (typeof response === 'string') {
					throw new Error(`${error} - ${response}`);
				}
				throw new Error(`${error} - There was an error searching status report requests.`);
			}

			let { count = 0, rows = [], pages = [], page = 1, pageCount = 0 } = data || {}; // data = { count = 0, rows = [], limit, pageCount, page, pages = [1,2,3,10] } 
			if (!rows || (Array.isArray(rows) && rows.length === 0)) {
				throw new Error('No status report data found');
			}

			if (pageCount < activePage) {
				setActivePage(1);
			} else {
				setActivePage(page);
			}
			setPages(pages);
			formatData(rows);
			setStatusReportRequestCount(count);
			setSearching(false);

			if (saveLastQuery) {
				setLastQuery(params);
			}
		} catch (error) {
			console.log(error);
		}
	};

	const newSearch = async (params) => {
		setSearching(true);
		let adjustedSearchParams = {
			...params,
			...requiredSearchParams
		};

		statusReportRequestSearch(adjustedSearchParams);
	};

	// Refresh last query
	const refreshLastQuery = async () => {
		statusReportRequestSearch({ ...lastQuery, ...requiredSearchParams }, false);
	};

	useEffect(() => {
		getAttorneyList();
		newSearch({ ...defaultSearchParams });
	}, []);

	// Apply search filters
	useEffect(() => { newSearch(searchParams) }, [searchParams, activePage, perPage, appliedSortOrders]);

	return (<>
		<AdvanceTableWrapper
			columns={columns}
			data={statusReportRequestData}
			// selection
			sortable
			pagination
			perPage={perPage}
			setPerPage={setPerPage}
		>
			<Card className="shadow-none">
				<Card.Header>
					<StatusReportRequestTableHeader
						table
						label={tableHeaderLabel}
						setCreateStatusReportRequestModal={setCreateStatusReportRequestModal}
						defaultSearchParams={defaultSearchParams}
						searchParams={searchParams}
						setSearchParams={setSearchParams}
						refreshLastQuery={refreshLastQuery}
						requiredSearchParams={requiredSearchParams}
						statusReportRequestData={statusReportRequestData}
						claimant={claimant}
					/>
				</Card.Header>
				<Card.Body className="p-0">
					{
						searching
							?
							<p>Searching Status Reports....</p>
							:
							<AdvanceTable
								table
								headerClickFn={headerClickFn}
								appliedSortOrders={appliedSortOrders}
								headerClassName="bg-200 text-900 text-nowrap align-middle"
								rowClassName="align-middle white-space-nowrap"
								tableProps={{
									size: 'sm',
									striped: true,
									className: 'fs--1 mb-0 overflow-hidden'
								}}
							/>
					}
				</Card.Body>
				<Card.Footer>
					<CustomAdvanceTablePagination
						table
						Pages={Pages}
						activePage={activePage}
						setActivePage={setActivePage}
					/>
				</Card.Footer>
			</Card>
		</AdvanceTableWrapper>

		<CreateStatusReportRequest
			modalState={createStatusReportRequestModal}
			setModalState={setCreateStatusReportRequestModal}
			statusReportRequestData={statusReportRequestData}
			setStatusReportRequestData={setStatusReportRequestData}
			refreshParent={refreshLastQuery}
			claimant={claimant}
			attorneyList={attorneyList}
		/>

		<StatusReportRequestDetails
			modalState={statusReportRequestDetailsModal}
			setModalState={setStatusReportRequestDetailsModal}
			selectedStatusReportRequest={selectedStatusReportRequest}
			setSelectedStatusReportRequest={setSelectedStatusReportRequest}
			refreshParent={refreshLastQuery}
			attorneyList={attorneyList}
		/>


		<ReactInterval timeout={15000} enabled={true} callback={() => { refreshLastQuery({ ignoreTouch: true }) }} />

	</>);
};


export default StatusReportRequestTable;