import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import { myFormsAPI, documentAPI } from 'utils/api/fulcrum-api';
import { toast } from 'react-toastify';
import Button from 'react-bootstrap/Button';
import RenderMyFormContent from './RenderMyFormContent';

const MyFormEditor = ({ selectedForm, setSelectedForm, refreshParent = () => { }, documentTypes = [] }) => {
	const navigate = useNavigate();
	const [viewFormModalState, setViewFormModalState] = useState(false);
	let defaultFormState = {
		title: selectedForm.title,
		description: selectedForm.description,
		form_type_id: selectedForm.form_type_id
	};

	const [formData, setFormData] = useState(defaultFormState);
	const [previousFormData, setPreviousFormData] = useState(defaultFormState);

	// Handle Input Change
	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setFormData({ ...formData, [name]: value });
	}

	// Update form
	const updateForm = async (data = {}) => {
		try {
			if (!selectedForm?.id) { throw new Error('No form selected to update'); }

			let result = await myFormsAPI.updateMyForm(selectedForm.id, {
				...data
			});

			if (!result?.data) {
				throw new Error('Error updating form');
			}
			let newFormData = { ...formData, ...data };
			setPreviousFormData(formData);
			setSelectedForm(result.data);

			toast.success("Form saved!", {
				theme: 'colored'
			});
			refreshParent();
		} catch (error) {
			console.log(error);
			toast.error(error, {
				theme: 'colored'
			});
		}
	};

	// Cancel Edit
	const cancelEdit = () => {
		setFormData(previousFormData);
	};


	return (
		<>
			<RenderMyFormContent
				modalState={viewFormModalState}
				setModalState={setViewFormModalState}
				defaultContent={selectedForm.content}
			/>
			<Form>
				<Form.Group controlId="formBasicEmail">
					<Form.Label>Form Name</Form.Label>
					<Form.Control
						type="text"
						name="title"
						value={formData.title}
						placeholder="Enter form name"
						onChange={handleInputChange}
						required
					// onBlur={saveOnChange}
					/>
				</Form.Group>
				<Form.Group controlId="formBasicPassword">
					<Form.Label>Form Description</Form.Label>
					<Form.Control
						type="text"
						name="description"
						value={formData.description}
						placeholder="Enter form description"
						onChange={handleInputChange}
					// onBlur={saveOnChange}
					/>
				</Form.Group>


				<Form.Group className="mb-3" controlId="form_type_id">
					<Form.Label className="fs-0">Form Type</Form.Label>
					<Form.Text> Document type that the response will generate.</Form.Text>
					<Form.Select name="form_type_id" value={formData.form_type_id} onChange={handleInputChange} required>
						<option value={""}>--Select One--</option>
						{Array.isArray(documentTypes) && documentTypes.map((docType, index) => (
							<option key={index} value={docType.id}>{docType.type}</option>
						))}
					</Form.Select>
				</Form.Group>

				<div className="mt-3">
					{
						JSON.stringify(formData) !== JSON.stringify(previousFormData) &&
						<>
							<Button variant={'success'} onClick={() => updateForm(formData)}>Update Form</Button>
							<Button variant={'secondary'} onClick={cancelEdit}>Cancel</Button>
						</>
					}
				</div>
			</Form>
		</>
	);
};

MyFormEditor.propTypes = {
	selectedForm: PropTypes.object,
	setSelectedForm: PropTypes.func,
	refreshParent: PropTypes.func
}

export default MyFormEditor;