import { Link } from 'react-router-dom';

export const allowedColumns = [
	{
		accessor: 'first_name',
		Header: 'First Name',
		model: 'Claimant',
		headerProps: { className: 'pe-1' },
		cellProps: {
			className: 'py-2'
		},
		Cell: rowData => {
			const { first_name, id } = rowData.row.original;
			return <Link to={`/claimant/profile/${id}`}>{first_name}</Link>;
		},
		type: 'text'
	},
	{
		accessor: 'middle_name',
		Header: 'M.I.',
		model: 'Claimant',
		headerProps: { className: 'pe-1' },
		cellProps: {
			className: 'py-2'
		},
		type: 'text'
	},
	{
		accessor: 'last_name',
		Header: 'Last Name',
		model: 'Claimant',
		headerProps: { className: 'pe-1' },
		cellProps: {
			className: 'py-2'
		},
		Cell: rowData => {
			const { last_name, id } = rowData.row.original;
			return <Link to={`/claimant/profile/${id}`}>{last_name}</Link>;
		},
		type: 'text'
	},
	{
		accessor: 'suffix',
		Header: 'Suffix',
		model: 'Claimant',
		headerProps: { className: 'pe-1' },
		cellProps: {
			className: 'py-2'
		},
		type: 'text'
	},
	{
		accessor: 'lead_vendor_name',
		Header: 'Lead Vendor',
		model: 'LeadVendor',
		headerProps: { className: 'pe-1' },
		cellProps: {
			className: 'py-2'
		},
		type: 'text',
	},
	// {
	// 	accessor: 'external_id',
	// 	Header: 'External ID',
	// 	model: 'Claimant',
	// 	headerProps: { className: 'pe-1' },
	// 	cellProps: {
	// 		className: 'py-2'
	// 	}
	// },
	// {
	// 	accessor: 'claimant_status',
	// 	Header: 'Claimant Status',
	// 	model: 'claimant_status',
	// 	fieldsToRetrieve: ['status'],
	// 	headerProps: { className: 'pe-1' },
	// 	cellProps: {
	// 		className: 'py-2'
	// 	}
	// },
	// {
	// 	accessor: 'assigned_user',
	// 	Header: 'Assigned To',
	// 	model: 'claimant',
	// 	headerProps: { className: 'pe-1' },
	// 	cellProps: {
	// 		className: 'py-2'
	// 	}
	// },
	{
		accessor: 'first_name-primary_rep_first_name',
		Header: 'Primary Rep First Name',
		as: 'primary_rep',
		model: 'User',
		type: 'text',
		headerProps: { className: 'pe-1' },
		cellProps: {
			className: 'py-2'
		}
	},
	{
		accessor: 'last_name-primary_rep_last_name',
		Header: 'Primary Rep last Name',
		as: 'primary_rep',
		model: 'User',
		type: 'text',
		headerProps: { className: 'pe-1' },
		cellProps: {
			className: 'py-2'
		}
	},
	{
		accessor: 'dob',
		Header: 'DOB',
		model: 'Claimant',
		type: 'date',
		headerProps: { className: 'pe-1' },
		cellProps: {
			className: 'py-2'
		}
	},
	{
		accessor: 'ssn',
		Header: 'SSN',
		model: 'Claimant',
		headerProps: { className: 'pe-1' },
		cellProps: {
			className: 'py-2'
		}
	},
	{
		accessor: 'field_office_mailing_city',
		model: 'ClaimantCase',
		as: 'claimant_active_case',
		Header: 'Claimant Case field office mailing city',
		type: 'text',
		headerProps: { className: 'pe-1' },
		cellProps: {
			className: 'py-2'
		}
	},
	{
		accessor: 'field_office_mailing_state',
		model: 'ClaimantCase',
		as: 'claimant_active_case',
		Header: 'Claimant Case field office mailing state',
		type: 'select-state',
		headerProps: { className: 'pe-1' },
		cellProps: {
			className: 'py-2'
		}
	},
	{
		accessor: 'active_case_id',
		model: 'Claimant',
		Header: 'Claimant Case Status',
		type: 'nullish',
		headerProps: { className: 'pe-1' },
		cellProps: {
			className: 'py-2'
		}
	},
	// {
	// 	accessor: 'status',
	// 	model: 'CaseStatus',
	// 	underModel: 'ClaimantCase',
	// 	underModelAs: 'claimant_active_case',
	// 	as: 'case_status',
	// 	Header: 'Claimant Case status',
	// 	type: 'text',
	// 	headerProps: { className: 'pe-1' },
	// 	cellProps: {
	// 		className: 'py-2'
	// 	}
	// },
	{
		accessor: 'phone',
		model: 'ContactPhone',
		underModel: 'Entity',
		Header: 'Phone',
		type: 'text',
		headerProps: { className: 'pe-1' },
		cellProps: {
			className: 'py-2'
		}
	},
	{
		accessor: 'active_claimant',
		Header: 'Active Claimant',
		model: 'Claimant',
		type: "boolean",
		headerProps: { className: 'pe-1' },
		cellProps: {
			className: 'py-2'
		}
	},
	{
		accessor: 'established_contact',
		Header: 'Established Contact',
		type: 'boolean',
		model: 'Claimant',
		headerProps: { className: 'pe-1' },
		cellProps: {
			className: 'py-2'
		}
	},
	// {
	// 	accessor: 'screened',
	// 	Header: 'Screened',
	// 	model: 'claimant',
	// 	headerProps: { className: 'pe-1' },
	// 	cellProps: {
	// 		className: 'py-2'
	// 	}
	// },
	// {
	// 	accessor: 'reviewed',
	// 	Header: 'Reviewed',
	// 	model: 'claimant',
	// 	headerProps: { className: 'pe-1' },
	// 	cellProps: {
	// 		className: 'py-2'
	// 	}
	// },
	// {
	// 	accessor: 'last_called',
	// 	Header: 'Last Called',
	// 	model: 'claimant',
	// 	headerProps: { className: 'pe-1' },
	// 	cellProps: {
	// 		className: 'py-2'
	// 	}
	// },
	// {
	// 	accessor: 'tags',
	// 	Header: 'Tags',
	// 	model: 'claimant',
	// 	headerProps: { className: 'pe-1' },
	// 	cellProps: {
	// 		className: 'py-2'
	// 	}
	// },
	{
		accessor: 'has_spouse',
		Header: 'Has Spouse',
		model: 'Claimant',
		type: 'boolean',
		headerProps: { className: 'pe-1' },
		cellProps: {
			className: 'py-2'
		}
	},
	// {
	// 	accessor: 'spouse_name',
	// 	Header: 'Spouse Name',
	// 	model: 'claimant',
	// 	headerProps: { className: 'pe-1' },
	// 	cellProps: {
	// 		className: 'py-2'
	// 	}
	// },
	// {
	// 	accessor: 'spouse_job',
	// 	Header: 'Spouse Job',
	// 	model: 'claimant',
	// 	headerProps: { className: 'pe-1' },
	// 	cellProps: {
	// 		className: 'py-2'
	// 	}
	// },
	// {
	// 	accessor: 'spouse_disability',
	// 	Header: 'Spouse Disability',
	// 	model: 'claimant',
	// 	headerProps: { className: 'pe-1' },
	// 	cellProps: {
	// 		className: 'py-2'
	// 	}
	// },
	// {
	// 	accessor: 'has_children',
	// 	Header: 'Has Children',
	// 	model: 'claimant',
	// 	headerProps: { className: 'pe-1' },
	// 	cellProps: {
	// 		className: 'py-2'
	// 	}
	// },
	// {
	// 	accessor: 'over_ssi_asset_threshold',
	// 	Header: 'Over SSI Asset Threshold',
	// 	model: 'claimant',
	// 	type: 'boolean',
	// 	headerProps: { className: 'pe-1' },
	// 	cellProps: {
	// 		className: 'py-2'
	// 	}
	// },
	// {
	// 	accessor: 'in_kind_support_and_maintance',
	// 	Header: 'In Kind Support and Maintance',
	// 	model: 'claimant',
	// 	type: 'boolean',
	// 	headerProps: { className: 'pe-1' },
	// 	cellProps: {
	// 		className: 'py-2'
	// 	}
	// },
	// {
	// 	accessor: 'workers_comp_claim',
	// 	Header: 'Workers Comp Claim',
	// 	model: 'claimant',
	// 	type: 'boolean',
	// 	headerProps: { className: 'pe-1' },
	// 	cellProps: {
	// 		className: 'py-2'
	// 	}
	// },
	{
		accessor: 'ltd',
		Header: 'Private Disability Claim',
		type: 'boolean',
		model: 'Claimant',
		headerProps: { className: 'pe-1' },
		cellProps: {
			className: 'py-2'
		}
	},
	// {
	// 	accessor: 'alt_contact_name',
	// 	Header: 'Alt Contact Name',
	// 	type: 'string',
	// 	model: 'claimant',
	// 	headerProps: { className: 'pe-1' },
	// 	cellProps: {
	// 		className: 'py-2'
	// 	}
	// },
	// {
	// 	accessor: 'alt_contact_phone',
	// 	Header: 'Alt Contact Phone',
	// 	type: 'string',
	// 	model: 'claimant',
	// 	headerProps: { className: 'pe-1' },
	// 	cellProps: {
	// 		className: 'py-2'
	// 	}
	// },
	// {
	// 	accessor: 'alt_contact_relationship',
	// 	Header: 'Alt Contact Relationship',
	// 	type: 'string',
	// 	model: 'claimant',
	// 	headerProps: { className: 'pe-1' },
	// 	cellProps: {
	// 		className: 'py-2'
	// 	}
	// },
	// {
	// 	accessor: 'education',
	// 	Header: 'Education',
	// 	type: 'string',
	// 	model: 'claimant',
	// 	headerProps: { className: 'pe-1' },
	// 	cellProps: {
	// 		className: 'py-2'
	// 	}
	// },
	// {
	// 	accessor: 'special_education',
	// 	Header: 'Special Education',
	// 	type: 'boolean',
	// 	model: 'claimant',
	// 	headerProps: { className: 'pe-1' },
	// 	cellProps: {
	// 		className: 'py-2'
	// 	}
	// },
	// {
	// 	accessor: 'literacy',
	// 	Header: 'Literacy',
	// 	type: 'boolean',
	// 	model: 'claimant',
	// 	headerProps: { className: 'pe-1' },
	// 	cellProps: {
	// 		className: 'py-2'
	// 	}
	// },
	// {
	// 	accessor: 'primary_language',
	// 	Header: 'Primary Language',
	// 	type: 'string',
	// 	headerProps: { className: 'pe-1' },
	// 	cellProps: {
	// 		className: 'py-2'
	// 	}
	// },
	{
		accessor: 'translator',
		Header: 'Translator',
		type: 'boolean',
		model: 'Claimant',
		headerProps: { className: 'pe-1' },
		cellProps: {
			className: 'py-2'
		}
	},
	{
		accessor: 'veteran',
		Header: 'Veteran',
		type: 'boolean',
		model: 'Claimant',
		headerProps: { className: 'pe-1' },
		cellProps: {
			className: 'py-2'
		}
	},
	// {
	// 	accessor: 'scdr',
	// 	Header: 'VA Disability Benefits',
	// 	type: 'boolean',
	// 	model: 'claimant',
	// 	headerProps: { className: 'pe-1' },
	// 	cellProps: {
	// 		className: 'py-2'
	// 	}
	// },
	// {
	// 	accessor: 'scdr_percentage',
	// 	Header: 'Service-Connected Disability Rating',
	// 	type: 'number',
	// 	model: 'claimant',
	// 	headerProps: { className: 'pe-1' },
	// 	cellProps: {
	// 		className: 'py-2'
	// 	}
	// },
	// {
	// 	accessor: 'scdr_impairment',
	// 	Header: 'Service-Connected Impairment(s)',
	// 	type: 'string',
	// 	model: 'claimant',
	// 	headerProps: { className: 'pe-1' },
	// 	cellProps: {
	// 		className: 'py-2'
	// 	}
	// },
	// {
	// 	accessor: 'scdriu',
	// 	Header: 'Qualified for Individual Unemployability',
	// 	type: 'boolean',
	// 	model: 'claimant',
	// 	headerProps: { className: 'pe-1' },
	// 	cellProps: {
	// 		className: 'py-2'
	// 	}
	// },
	// {
	// 	accessor: 'legal',
	// 	Header: 'History of arrest/incarceration',
	// 	type: 'boolean',
	// 	headerProps: { className: 'pe-1' },
	// 	cellProps: {
	// 		className: 'py-2'
	// 	}
	// },
	// {
	// 	accessor: 'legal_date',
	// 	Header: 'Date of arrest/incarceration',
	// 	type: 'date',
	// 	model: 'claimant',
	// 	headerProps: { className: 'pe-1' },
	// 	cellProps: {
	// 		className: 'py-2'
	// 	}
	// },
	// {
	// 	accessor: 'legal_reason',
	// 	Header: 'Reason for arrest/incarceration',
	// 	type: 'string',
	// 	model: 'claimant',
	// 	headerProps: { className: 'pe-1' },
	// 	cellProps: {
	// 		className: 'py-2'
	// 	}
	// },
	{
		accessor: 'working',
		Header: 'Currently working',
		type: 'boolean',
		model: 'Claimant',
		headerProps: { className: 'pe-1' },
		cellProps: {
			className: 'py-2'
		}
	},
	// {
	// 	accessor: 'working_full_time',
	// 	Header: 'Working Full Time',
	// 	type: 'boolean',
	// 	model: 'claimant',
	// 	headerProps: { className: 'pe-1' },
	// 	cellProps: {
	// 		className: 'py-2'
	// 	}
	// },
	// {
	// 	accessor: 'last_work_date',
	// 	Header: 'Last Work Date',
	// 	type: 'date',
	// 	model: 'claimant',
	// 	headerProps: { className: 'pe-1' },
	// 	cellProps: {
	// 		className: 'py-2'
	// 	},
	// },
	// {
	// 	accessor: 'ssdi_insured',
	// 	Header: 'SSDI Insured',
	// 	type: 'boolean',
	// 	model: 'claimant',
	// 	headerProps: { className: 'pe-1' },
	// 	cellProps: {
	// 		className: 'py-2'
	// 	}
	// },
	// {
	// 	accessor: 'alleged_impairments',
	// 	Header: 'Last Work Date',
	// 	type: 'string',
	// 	model: 'claimant',
	// 	headerProps: { className: 'pe-1' },
	// 	cellProps: {
	// 		className: 'py-2'
	// 	}
	// },
	// {
	// 	accessor: 'health_insurance',
	// 	Header: 'Has Health Insurance',
	// 	type: 'boolean',
	// 	model: 'claimant',
	// 	headerProps: { className: 'pe-1' },
	// 	cellProps: {
	// 		className: 'py-2'
	// 	}
	// },
	// {
	// 	accessor: 'ever_treating',
	// 	Header: 'History of treating',
	// 	type: 'boolean',
	// 	model: 'claimant',
	// 	headerProps: { className: 'pe-1' },
	// 	cellProps: {
	// 		className: 'py-2'
	// 	}
	// },
	// {
	// 	accessor: 'currently_treating',
	// 	Header: 'Currently treating',
	// 	type: 'boolean',
	// 	model: 'claimant',
	// 	headerProps: { className: 'pe-1' },
	// 	cellProps: {
	// 		className: 'py-2'
	// 	}
	// },
	// {
	// 	accessor: 'voc_rehab',
	// 	Header: 'History of Vocational Rehabilitation',
	// 	type: 'boolean',
	// 	model: 'claimant',
	// 	headerProps: { className: 'pe-1' },
	// 	cellProps: {
	// 		className: 'py-2'
	// 	}
	// },
	// {
	// 	accessor: 'daa',
	// 	Header: 'History of Substance Abuse Treatment',
	// 	type: 'boolean',
	// 	model: 'claimant',
	// 	headerProps: { className: 'pe-1' },
	// 	cellProps: {
	// 		className: 'py-2'
	// 	}
	// },
	// {
	// 	accessor: 'pending_app',
	// 	Header: 'Pending SSD Application',
	// 	type: 'boolean',
	// 	headerProps: { className: 'pe-1' },
	// 	cellProps: {
	// 		className: 'py-2'
	// 	}
	// },
	// {
	// 	accessor: 'pending_app_filing',
	// 	Header: 'Pending App Filing',
	// 	type: 'date',
	// 	headerProps: { className: 'pe-1' },
	// 	cellProps: {
	// 		className: 'py-2'
	// 	}
	// },
	// {
	// 	accessor: 'pending_app_decision',
	// 	Header: 'Pending App Decision',
	// 	type: 'boolean',
	// 	model: 'claimant',
	// 	headerProps: { className: 'pe-1' },
	// 	cellProps: {
	// 		className: 'py-2'
	// 	}
	// },
	// {
	// 	accessor: 'pending_app_decision_date',
	// 	Header: 'Pending App Decision Date',
	// 	type: 'date',
	// 	model: 'claimant',
	// 	headerProps: { className: 'pe-1' },
	// 	cellProps: {
	// 		className: 'py-2'
	// 	}
	// },
	// {
	// 	accessor: 'pending_app_hearing',
	// 	Header: 'Pending App Hearing',
	// 	type: 'boolean',
	// 	model: 'claimant',
	// 	headerProps: { className: 'pe-1' },
	// 	cellProps: {
	// 		className: 'py-2'
	// 	}
	// },
	// {
	// 	accessor: 'pending_app_hearing_date',
	// 	Header: 'Pending App Hearing Date',
	// 	type: 'date',
	// 	model: 'claimant',
	// 	headerProps: { className: 'pe-1' },
	// 	cellProps: {
	// 		className: 'py-2'
	// 	}
	// },
	// {
	// 	accessor: 'prior_app',
	// 	Header: 'Prior App',
	// 	type: 'boolean',
	// 	model: 'claimant',
	// 	headerProps: { className: 'pe-1' },
	// 	cellProps: {
	// 		className: 'py-2'
	// 	}
	// },
	// {
	// 	accessor: 'prior_app_number',
	// 	Header: 'Prior App Number',
	// 	type: 'boolean',
	// 	model: 'claimant',
	// 	headerProps: { className: 'pe-1' },
	// 	cellProps: {
	// 		className: 'py-2'
	// 	}
	// },
	// {
	// 	accessor: 'prior_app_filing',
	// 	Header: 'Prior App Filing',
	// 	type: 'date',
	// 	model: 'claimant',
	// 	headerProps: { className: 'pe-1' },
	// 	cellProps: {
	// 		className: 'py-2'
	// 	}
	// },
	// {
	// 	accessor: 'prior_app_decision_date',
	// 	Header: 'Prior App Decision Date',
	// 	type: 'date',
	// 	model: 'claimant',
	// 	headerProps: { className: 'pe-1' },
	// 	cellProps: {
	// 		className: 'py-2'
	// 	}
	// },
	// {
	// 	accessor: 'prior_app_hearing',
	// 	Header: 'Prior App Hearing',
	// 	type: 'boolean',
	// 	model: 'claimant',
	// 	headerProps: { className: 'pe-1' },
	// 	cellProps: {
	// 		className: 'py-2'
	// 	}
	// },
	// {
	// 	accessor: 'prior_benefits',
	// 	Header: 'Prior Benefits',
	// 	type: 'boolean',
	// 	model: 'claimant',
	// 	headerProps: { className: 'pe-1' },
	// 	cellProps: {
	// 		className: 'py-2'
	// 	}
	// },
	// {
	// 	accessor: 'current_benefits',
	// 	Header: 'Current Benefits',
	// 	type: 'boolean',
	// 	model: 'claimant',
	// 	headerProps: { className: 'pe-1' },
	// 	cellProps: {
	// 		className: 'py-2'
	// 	}
	// },
	{
		accessor: 'mailing_address',
		Header: 'Mailing Address',
		type: 'text',
		model: 'Claimant',
		headerProps: { className: 'pe-1' },
		cellProps: {
			className: 'py-2'
		}
	},
	{
		accessor: 'mailing_city',
		Header: 'Mailing City',
		type: 'text',
		model: 'Claimant',
		headerProps: { className: 'pe-1' },
		cellProps: {
			className: 'py-2'
		}
	},
	{
		accessor: 'mailing_state',
		Header: 'Mailing State',
		type: 'select-state',
		model: 'Claimant',
		headerProps: { className: 'pe-1' },
		cellProps: {
			className: 'py-2'
		}
	},
	{
		accessor: 'mailing_zip',
		Header: 'Mailing Zip',
		type: 'text',
		model: 'Claimant',
		headerProps: { className: 'pe-1' },
		cellProps: {
			className: 'py-2'
		}
	},
	// {
	// 	accessor: 'physical_address',
	// 	Header: 'Physical Address',
	// 	type: 'string',
	// 	model: 'claimant',
	// 	headerProps: { className: 'pe-1' },
	// 	cellProps: {
	// 		className: 'py-2'
	// 	}
	// },
	// {
	// 	accessor: 'physical_address_2',
	// 	Header: 'Physical Address 2',
	// 	type: 'string',
	// 	model: 'claimant',
	// 	headerProps: { className: 'pe-1' },
	// 	cellProps: {
	// 		className: 'py-2'
	// 	}
	// },
	// {
	// 	accessor: 'physical_city',
	// 	Header: 'Physical City',
	// 	type: 'string',
	// 	model: 'claimant',
	// 	headerProps: { className: 'pe-1' },
	// 	cellProps: {
	// 		className: 'py-2'
	// 	}
	// },
	// {
	// 	accessor: 'physical_state',
	// 	Header: 'Physical State',
	// 	type: 'string',
	// 	model: 'claimant',
	// 	headerProps: { className: 'pe-1' },
	// 	cellProps: {
	// 		className: 'py-2'
	// 	}
	// },
	// {
	// 	accessor: 'physical_zip',
	// 	Header: 'Physical Zip',
	// 	type: 'string',
	// 	model: 'claimant',
	// 	headerProps: { className: 'pe-1' },
	// 	cellProps: {
	// 		className: 'py-2'
	// 	}
	// },
	// {
	// 	accessor: 'physical_country',
	// 	Header: 'Physical Country',
	// 	type: 'string',
	// 	model: 'claimant',
	// 	headerProps: { className: 'pe-1' },
	// 	cellProps: {
	// 		className: 'py-2'
	// 	}
	// },
	// {
	// 	accessor: 'case_status',
	// 	Header: 'Case Status',
	// 	type: 'string',
	// 	model: 'claimant',
	// 	headerProps: { className: 'pe-1' },
	// 	cellProps: {
	// 		className: 'py-2'
	// 	}
	// },
	// {
	// 	accessor: 'primary_representative',
	// 	Header: 'Primary Representative',
	// 	type: 'string',
	// 	model: 'claimant',
	// 	headerProps: { className: 'pe-1' },
	// 	cellProps: {
	// 		className: 'py-2'
	// 	}
	// },
	// {
	// 	accessor: 'assure_case_id',
	// 	Header: 'Assure Case ID',
	// 	type: 'string',
	// 	model: 'claimant',
	// 	headerProps: { className: 'pe-1' },
	// 	cellProps: {
	// 		className: 'py-2'
	// 	}
	// },
	// {
	// 	accessor: 'office_with_jurisdiction',
	// 	Header: 'Office with Jurisdiction',
	// 	type: 'string',
	// 	headerProps: { className: 'pe-1' },
	// 	cellProps: {
	// 		className: 'py-2'
	// 	}
	// },
	// {
	// 	accessor: 'office_with_jurisdiction_mailing_address',
	// 	Header: 'Office with Jurisdiction Mailing Address',
	// 	type: 'string',
	// 	headerProps: { className: 'pe-1' },
	// 	cellProps: {
	// 		className: 'py-2'
	// 	}
	// },
	// {
	// 	accessor: 'office_with_jurisdiction_mailing_city',
	// 	Header: 'Office with Jurisdiction Mailing City',
	// 	type: 'string',
	// 	headerProps: { className: 'pe-1' },
	// 	cellProps: {
	// 		className: 'py-2'
	// 	}
	// },
	// {
	// 	accessor: 'office_with_jurisdiction_mailing_state',
	// 	Header: 'Office with Jurisdiction Mailing State',
	// 	type: 'string',
	// 	headerProps: { className: 'pe-1' },
	// 	cellProps: {
	// 		className: 'py-2'
	// 	}
	// },
	// {
	// 	accessor: 'office_with_jurisdiction_mailing_zip',
	// 	Header: 'Office with Jurisdiction Mailing Zip',
	// 	type: 'string',
	// 	headerProps: { className: 'pe-1' },
	// 	cellProps: {
	// 		className: 'py-2'
	// 	}
	// },
	// {
	// 	accessor: 'office_with_jurisdiction_phone',
	// 	Header: 'Office with Jurisdiction Phone',
	// 	type: 'string',
	// 	headerProps: { className: 'pe-1' },
	// 	cellProps: {
	// 		className: 'py-2'
	// 	}
	// },
	// {
	// 	accessor: 'office_with_jurisdiction_fax',
	// 	Header: 'Office with Jurisdiction Fax',
	// 	type: 'string',
	// 	headerProps: { className: 'pe-1' },
	// 	cellProps: {
	// 		className: 'py-2'
	// 	}
	// },
	// {
	// 	accessor: 'field_office_mailing_address',
	// 	Header: 'Field Office Mailing Address',
	// 	type: 'string',
	// 	headerProps: { className: 'pe-1' },
	// 	cellProps: {
	// 		className: 'py-2'
	// 	}
	// },
	// {
	// 	accessor: 'field_office_mailing_city',
	// 	Header: 'Field Office Mailing City',
	// 	type: 'string',
	// 	headerProps: { className: 'pe-1' },
	// 	cellProps: {
	// 		className: 'py-2'
	// 	}
	// },
	// {
	// 	accessor: 'field_office_mailing_state',
	// 	Header: 'Field Office Mailing State',
	// 	type: 'string',
	// 	headerProps: { className: 'pe-1' },
	// 	cellProps: {
	// 		className: 'py-2'
	// 	}
	// },
	// {
	// 	accessor: 'field_office_mailing_zip',
	// 	Header: 'Field Office Mailing Zip',
	// 	type: 'string',
	// 	headerProps: { className: 'pe-1' },
	// 	cellProps: {
	// 		className: 'py-2'
	// 	}
	// },
	// {
	// 	accessor: 'field_office_phone',
	// 	Header: 'Field Office Phone',
	// 	type: 'string',
	// 	headerProps: { className: 'pe-1' },
	// 	cellProps: {
	// 		className: 'py-2'
	// 	}
	// },
	// {
	// 	accessor: 'field_office_fax',
	// 	Header: 'Field Office Fax',
	// 	type: 'string',
	// 	headerProps: { className: 'pe-1' },
	// 	cellProps: {
	// 		className: 'py-2'
	// 	}
	// },
	// {
	// 	accessor: 'ere_claim_type',
	// 	Header: 'ERE Claim Type',
	// 	type: 'string',
	// 	headerProps: { className: 'pe-1' },
	// 	cellProps: {
	// 		className: 'py-2'
	// 	}
	// },
	// {
	// 	accessor: 'ere_status_of_case',
	// 	Header: 'ERE Status of Case',
	// 	type: 'string',
	// 	headerProps: { className: 'pe-1' },
	// 	cellProps: {
	// 		className: 'py-2'
	// 	}
	// },
	// {
	// 	accessor: 'ere_status_date',
	// 	Header: 'ERE Status Date',
	// 	type: 'date',
	// 	headerProps: { className: 'pe-1' },
	// 	cellProps: {
	// 		className: 'py-2'
	// 	}
	// },
	// {
	// 	accessor: 'ere_electronic_folder',
	// 	Header: 'ERE Electronic Folder',
	// 	type: 'boolean',
	// 	headerProps: { className: 'pe-1' },
	// 	cellProps: {
	// 		className: 'py-2'
	// 	}
	// },
	// {
	// 	accessor: 'ere_fee_agreement',
	// 	Header: 'ERE Fee Agreement',
	// 	type: 'boolean',
	// 	headerProps: { className: 'pe-1' },
	// 	cellProps: {
	// 		className: 'py-2'
	// 	}
	// },
	// {
	// 	accessor: 'ere_fee_petition',
	// 	Header: 'ERE Fee Petition',
	// 	type: 'boolean',
	// 	headerProps: { className: 'pe-1' },
	// 	cellProps: {
	// 		className: 'py-2'
	// 	}
	// },
	{
		accessor: 'ere_t2_decision',
		Header: 'ERE T2 Decision',
		type: 'text',
		model: 'ClaimantCase',
		headerProps: { className: 'pe-1' },
		cellProps: {
			className: 'py-2'
		}
	},
	// {
	// 	accessor: 'ere_t16_decision',
	// 	Header: 'ERE T16 Decision',
	// 	type: 'string',
	// 	headerProps: { className: 'pe-1' },
	// 	cellProps: {
	// 		className: 'py-2'
	// 	}
	// },
	// {
	// 	accessor: 'confirmed_ssa_application',
	// 	Header: 'Confirmed SSA Application',
	// 	type: 'boolean',
	// 	headerProps: { className: 'pe-1' },
	// 	cellProps: {
	// 		className: 'py-2'
	// 	}
	// },
	// {
	// 	accessor: 'confirmed_appointment_as_representative',
	// 	Header: 'Confirmed Appointment as Representative',
	// 	type: 'boolean',
	// 	headerProps: { className: 'pe-1' },
	// 	cellProps: {
	// 		className: 'py-2'
	// 	}
	// },

]

