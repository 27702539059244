import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import ClaimantProfile from 'components/pages/claimant-folders/claimant-profile';
import { Link } from 'react-router-dom';

const UserDetails = ({ }) => {
	return (
		<div>
			<Row>
				<Col className="mb-3">
					<h5>Claimant Details</h5>
					<Link to="/admin/claimant-management" className="float-right">Back Claimant Management</Link>
				</Col>
			</Row>

			<ClaimantProfile siteAdminArea={true} />
		</div>
	);
};

export default UserDetails;