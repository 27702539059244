import React from 'react';
import { Col, Row, Card } from 'react-bootstrap';
import Greetings from './greetings/Greetings';
// import ActiveClaimantMetrics from 'components/metrics/claimants/ActiveClaimantMetrics';
import ActiveClaimantMetrics from 'components/metrics/claimants/ChartBar';
import TaskTable from 'components/task/TaskTable';
import InboxTable from 'components/app/inbox-outbox/inbox/InboxTable';

const Crm = () => {
  return (
    <>
      <Greetings />
      <Row className="g-3 mb-3">
        <Col xxl={12}>
          <Row className="g-3 mb-3">
            <Col md={4} xxl={3}>
              <ActiveClaimantMetrics radius={['100%', '87%']} />
            </Col>
          </Row>

          <Row className="g-3 mb-3">
            <Col md={12} xxl={12}>
              <Card>
                <Card.Body>
                  <TaskTable
                    defaultSearchParams={{}}
                    requiredSearchParams={{}}
                  />
                </Card.Body>
              </Card>
            </Col>
          </Row>

          <Row className="g-3 mb-3">
            <Col md={12} xxl={12}>
              <InboxTable />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default Crm;
