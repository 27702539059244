import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import IconButton from 'components/common/IconButton';

const RevisionLogTableHeader = ({
	selectedRowIds,
	page = [],
	label = "Revision Logs",
	requiredSearchParams = {},
	searchParams = {},
	defaultSearchParams = {},
	setSearchParams = () => { },
	refreshLastQuery = () => { },
	clearSearchParams = () => { },
	revisionData }) => {

	const revisionLogIdList = Array.isArray(page)
		? (page.reduce((acc, { isSelected = false, original } = {}) => (
			[...acc, ...(isSelected ? [original] : [])]
		), [])).map(({ id } = {}) => id)
		: [];

	return (
		<Row className="flex-between-center">
			<Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
				<h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">{label}</h5>
			</Col>
			<Col xs={8} sm="auto" className="ms-auto text-end ps-0">
				{Object.keys(selectedRowIds).length > 0 ? (
					<div className="d-flex">
					</div>
				) : (
					<div id="orders-actions">
						<IconButton
							size="sm"
							variant="outline-primary"
							className="me-2"
							icon=""
							onClick={() => {
								refreshLastQuery();
							}}
						>Refresh</IconButton>
						{
							JSON.stringify(searchParams) !== JSON.stringify({ ...defaultSearchParams, ...requiredSearchParams }) && (
								<IconButton
									size="sm"
									variant="outline-danger"
									className="me-2"
									icon="x"
									onClick={() => {
										clearSearchParams();
									}}
								>Clear Filters</IconButton>
							)
						}
					</div>
				)}
			</Col>
		</Row>
	);
};

RevisionLogTableHeader.propTypes = {
	selectedRowIds: PropTypes.object,
	page: PropTypes.array,
	label: PropTypes.string,
	requiredSearchParams: PropTypes.object,
	searchParams: PropTypes.object,
	defaultSearchParams: PropTypes.object,
	setSearchParams: PropTypes.func,
	refreshLastQuery: PropTypes.func,
	clearSearchParams: PropTypes.func,
	revisionData: PropTypes.object,
};

export default RevisionLogTableHeader;