import React, { useState, useContext } from 'react';
import { Button, Modal, Alert, Form } from 'react-bootstrap';
import { claimantAPI, caseAPI } from 'utils/api/fulcrum-api';
import { toast } from 'react-toastify';
import LeadProgressTool from '../LeadProgressTool';
import Swal from 'sweetalert2';
import AppContext from 'context/Context';

const EREInformation = ({ claimant, setClaimant, selectedCase, setSelectedCase = () => { }, getClaimantCase = () => { } }) => {
	const {
		config: { isDark }
	} = useContext(AppContext);

	const [slide, setSlide] = useState(1); // 1 = No ERE Hearing Scheduled, 2 = ERE Hearing Scheduled 


	const renderSlide = () => {
		switch (slide) {
			case 1:
				return (
					<div>
						<h5>No ERE Hearing Currently Scheduled</h5>
					</div>
				)
				break;
			case 2:
				break;
			default:
				break;
		}
	};

	return (
		<div>
			ERE Hearing Information

			{renderSlide()}
		</div>
	);
};

export default EREInformation;