import React, { forwardRef } from 'react';
import { Card } from 'react-bootstrap';

const Children = forwardRef((props, ref) => {
	return (
		<Card className="mb-3" id="children" ref={ref}>
			<Card.Header className="bg-light">
				<h5 className="mb-0">Children Under the Age of 16</h5>
			</Card.Header>
			<Card.Body>
				<p className="mb-0 ps-3">
					Assure Disability, Inc. does not solicit or collect information from children under the age of 16.  Assure Disability, Inc. does not advertise to or solicit services from children under the age of 16.   No one under the age of 16 may provide any personal information to Assure Disability, Inc., its employees, or its agents.  If you are under 16 years of age, do not provide any information to Assure Disability, Inc., its employees, or its agents.  If Assure Disability, Inc. collects or receives personal information concerning a child under the age of 16, such collection and receipt is deemed to have been provided with the consent of a parent or legal guardian.  All personal information collected or received by Assure Disability, Inc. concerning a child under the age of 16 without the consent of a parent or legal guardian will be deleted by Assure Disability, Inc.  If you believe Assure Disability, Inc. might have collected or received information from or about a child under the age of 16 without the consent of a parent or legal guardian, please communicate your concerns in writing to the address noted below.
				</p>
			</Card.Body>
		</Card>
	);
});

export default Children;