import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Row, Col, Form, Card, Button } from 'react-bootstrap';
import { claimantDetailsAPI, accessVerificationAPI, inboxOutboxAPI } from 'utils/api/fulcrum-api.js';
import Swal from 'sweetalert2';
import ExternalTaskPortalLayout from './ExternalTaskPortalLayout';
import IconAlert from 'components/common/IconAlert';

const ExternalTaskPortal = () => {
  const [slide, setSlide] = useState(0); // 0 = loading, 1 = verification, 2 = details, 3 = Expired/Denied, 4 = completed 
  let { uuid } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [accessKey, setAccessKey] = useState('');
  const [error, setError] = useState('');
  const [inboxOutboxId, setInboxOutboxId] = useState(null);
  const [claimant, setClaimant] = useState({});
  const [allowAccess, setAllowAccess] = useState(false);
  const [claimantId, setClaimantId] = useState(null);
  const [verifying, setVerifying] = useState(false);
  const [verified, setVerified] = useState(false);
  const [correspondenceRecord, setCorrespondenceRecord] = useState({});
  const [newAccessCodeSent, setNewAccessCodeSent] = useState(false);
  const [formData, setFormData] = useState({
    dob: '',
    ssn: '',
    access_code: '',
  });

  const verifyAccessVerificationForExternalTaskPortal = async (e) => {
    if (e) {
      e.preventDefault();
    }

    setVerifying(true);

    try {
      let result = await inboxOutboxAPI.verifyInboxOutboxAccessVerification(uuid, inboxOutboxId, {
        ...formData,
      });
      if (!result?.data) {
        if (result?.error === "Access denied. Link already used.") {
          setVerifying(false);
          return setSlide(4);
        } else {
          throw new Error('Could not verify access verification for claimant details.');
        }
      }

      setCorrespondenceRecord(result?.data?.correspondence);
      setAllowAccess(true);
      setSlide(2);
      setVerified(true);
      setLoading(false);
    } catch (error) {
      setSlide(6);
      setAllowAccess(false);
      console.log(error);
    }
    setVerifying(false);
  };


  const init = async () => {
    try {
      // access verification
      let getAccessVerificationResult = await accessVerificationAPI.getAccessVerification(uuid);
      if (getAccessVerificationResult?.error) {
        setError(getAccessVerificationResult.error);
      }

      let { inbox_outbox_id, required_access_type, entity_id } = getAccessVerificationResult?.data || {};

      if (!entity_id) {
        // Set allow access to false
        setAllowAccess(false);
        setSlide(3);
        setLoading(false);
      } else {
        setInboxOutboxId(inbox_outbox_id);
        // derive claimant id from entity_id
        let claimant_id = entity_id.split('_').pop();
        // get claimant details
        setClaimantId(claimant_id);

        switch (required_access_type) {
          case 'CLAIMANT_INFORMATION':
            setSlide(1); // Verification Needed
            break;
          case 'ACCESS_CODE':
          case 'ACCESS_KEY':
            setSlide(5); // Verification Needed
            break;
          default:
            verifyAccessVerificationForExternalTaskPortal();
        }
      }
    } catch (error) {
      setSlide(3); // Access Denied
      setAllowAccess(false);
      setLoading(false);
    }
  }

  // Resend Access Verification Code
  const sendNewAccessCodeLink = async () => {
    try {
      let result = await accessVerificationAPI.resendGenericAccessCodeRoute(uuid, {});
      if (!result?.data) {
        throw new Error('Unabled to send new access code.');
      }
      setNewAccessCodeSent(true);
    } catch (error) {
      alert('Unable to send new access code.');
    }
  };

  // Render content by slide
  const renderContent = () => {
    switch (slide) {
      case 0:
        return (
          <div className="m-5">
            <div>Loading...</div>
          </div>
        );
      case 1:
        return (
          <div className="m-5">
            <Card style={{ maxWidth: '500px' }} className="mx-auto">
              <Card.Header>
                <Card.Title>Access Verification Required</Card.Title>
              </Card.Header>
              <Card.Body>
                <p>Enter the information below to grant access to this claimant profile.</p>

                <Form onSubmit={(e) => verifyAccessVerificationForExternalTaskPortal(e)}>
                  <Form.Group controlId="dob">
                    <Form.Label className="fw-bold">Date of Birth</Form.Label>
                    <Form.Control type="date" name="dob" value={formData.dob} onChange={e => { setFormData({ ...formData, dob: e.target.value }) }} disabled={verifying} />
                  </Form.Group>
                  <br />
                  <Form.Group controlId="ssn">
                    <Form.Label className="fw-bold">SSN</Form.Label>
                    <br />
                    <span>Enter full or last four of your ssn</span>
                    <Form.Control type="text" name="ssn" value={formData.ssn} onChange={e => { setFormData({ ...formData, ssn: e.target.value }) }} disabled={verifying} />
                  </Form.Group>

                  <div className="my-3">
                    {
                      verifying ?
                        <Button variant="primary" type="submit" disabled>
                          Verifying...
                        </Button>
                        :
                        <Button variant="primary" type="submit">
                          Verify
                        </Button>
                    }
                  </div>
                </Form>
              </Card.Body>
            </Card>
          </div>
        );
      case 2:
        return (
          <div className="">
            {
              loading
                ?
                <div>Loading...</div>
                :
                (
                  !allowAccess
                    ?
                    <div className="m-5">
                      <div>Access denied.</div>
                    </div>
                    :
                    <div>
                      <ExternalTaskPortalLayout
                        slide={slide}
                        uuid={uuid}
                        setSlide={setSlide}
                        correspondenceRecord={correspondenceRecord}
                      />
                    </div>
                )
            }
          </div>
        );
      case 3:
        return (
          <div className="m-5">
            <Card style={{ maxWidth: '500px' }} className="mx-auto">
              <Card.Header>
                <Card.Title>Invalid or Expired Link</Card.Title>
              </Card.Header>
              <Card.Body>
                <p>You do not have access to this claimant's profile. Either your link has expired or you do not have access to this claimant's profile.</p>
              </Card.Body>
            </Card>
          </div>
        );
      case 4:
        return (
          <div className="m-5">
            <Card style={{ maxWidth: '500px' }} className="mx-auto">
              <Card.Header>
                <Card.Title>Completed</Card.Title>
              </Card.Header>
              <Card.Body>
                <p>You have successfully completed reviewing profile. If you need more access, please reach out to your firm for assistance.</p>
              </Card.Body>
            </Card>
          </div>
        );
        break;
      case 5:
        return (
          <div className="m-5">
            <Card style={{ maxWidth: '500px' }} className="mx-auto">
              <Card.Header>
                <Card.Title>Access Verification Required</Card.Title>
              </Card.Header>
              <Card.Body>
                <p>Enter the access code below to grant access to this claimant profile.</p>
                {newAccessCodeSent &&
                  <IconAlert
                    variant="success"
                    className="mb-3"
                    dismissible={true}
                    onClose={() => setNewAccessCodeSent(false)}
                  >
                    <p>New access code sent.</p>
                  </IconAlert>
                }

                <Form onSubmit={(e) => verifyAccessVerificationForExternalTaskPortal(e)}>
                  <Form.Group controlId="access_code">
                    <Form.Label className="fw-bold">Access Code</Form.Label>
                    <Form.Control type="text" name="access_code" value={formData.access_code} onChange={e => { setFormData({ ...formData, access_code: e.target.value }) }} disabled={verifying} />
                  </Form.Group>
                  <Button variant='link' className="pl-0" onClick={() => { sendNewAccessCodeLink() }}>Send new access link.</Button>

                  <div className="my-3">
                    {
                      verifying ?
                        <Button variant="primary" type="submit" disabled>
                          Verifying...
                        </Button>
                        :
                        <Button variant="primary" type="submit">
                          Verify
                        </Button>
                    }
                  </div>
                </Form>
              </Card.Body>
            </Card>
          </div>
        )
        break;
      default:
        return (
          <div className="m-5">
            <Card style={{ maxWidth: '500px' }} className="mx-auto">
              <Card.Header>
                <Card.Title>Access Denied</Card.Title>
              </Card.Header>
              <Card.Body>
                <p>You do not have access to this claimant's profile. Either your link has expired or you do not have access to this claimant's profile. Please reach out to your firm for assisance.</p>
              </Card.Body>
            </Card>
          </div>
        );
    }
  }

  useEffect(() => { }, [slide]);

  useEffect(() => {
    init();
  }, [uuid]);

  return (
    <div className="">
      {renderContent()}
    </div>
  );
};

export default ExternalTaskPortal;