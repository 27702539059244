import React, { useContext, useState, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import AppContext from 'context/Context';
import { Button, Form, Modal } from 'react-bootstrap';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import { useDropzone } from 'react-dropzone';
import Flex from 'components/common/Flex';
import Auth from 'utils/auth';
import { userAPI } from 'utils/api/fulcrum-api';
import cloudUpload from 'assets/img/icons/cloud-upload.svg';

const UploadMOU = ({ modalState, setModalState = () => { }, user, setUser = () => { }, refreshParent = () => { } } = {}) => {
	const {
		config: { isDark }
	} = useContext(AppContext);
	const defaultFormData = {
		title: 'Memorandum of Understanding',
		file: null
	};
	const [selectedFiles, setSelectedFiles] = useState([]);
	const onDrop = useCallback(acceptedFiles => {
		setSelectedFiles([...selectedFiles, ...acceptedFiles])
	}, [selectedFiles])

	const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
		onDrop,
	});

	const [isUploading, setIsUploading] = useState(false);
	const [documentTypes, setDocumentTypes] = useState([]);
	const [documentStatuses, setDocumentStatuses] = useState([]);
	const [formData, setFormData] = useState(defaultFormData);

	const formRef = useRef(null);

	const files = selectedFiles.map(file => (<li key={file.path}>{file.path} - {file.size} bytes</li>));

	const removeAll = () => {
		setSelectedFiles([])
	}

	const handleChange = ({ target }) => {
		let { name, value } = target;
		let additionalValues = {};
		setFormData({ ...formData, ...additionalValues, [name]: value });
	};

	const handleSubmit = async e => {
		e.preventDefault();

		if (acceptedFiles.length !== 1) {
			toast.error('You must select one and only one file to upload.', { theme: 'colored' });
			return;
		}

		setIsUploading(true);

		let account_id = await Auth.getProfile().data.account_id;

		let formDataObject = new FormData(formRef.current);

		try {

			for (let i = 0; i < acceptedFiles.length; i++) {
				let file = acceptedFiles[i];
				file.originalFilename = file.path;
				formDataObject.append("files", file);
			}

			const response = await userAPI.uploadMOU(user.id, formDataObject);
			if (response?.data?.message !== "Memorandum of Understanding") {
				throw new Error("Failed to upload document.");
			}
			toast.success('Successfully uploaded the memorandum of understanding.', { theme: 'colored' });

			setIsUploading(false);
			refreshParent();
			handleClose();

		} catch (error) {
			Swal.fire({
				icon: 'error',
				text: "Failed to upload document.",
				showConfirmButton: true,
				background: false,
				backdrop: false
			});

			setIsUploading(false);
		}
	};

	// Handle Show
	const handleShow = () => {
		setFormData(defaultFormData);
	};

	// Handle Close
	const handleClose = () => {
		removeAll();
		setFormData(defaultFormData);
		setModalState(false);
	};

	return (
		<Modal
			show={modalState}
			onHide={handleClose}
			onShow={handleShow}
			size="lg"
			contentClassName="border"
		>
			<Form ref={formRef} onSubmit={handleSubmit}>
				<Modal.Header
					closeButton
					closeVariant={isDark ? 'white' : undefined}
					className="bg-light px-card border-bottom-0"
				>
					<Modal.Title as="h5">Upload a completed Memorandum of Understanding</Modal.Title>
				</Modal.Header>
				<Modal.Body className="p-card">
					{
						!isUploading && (
							<div {...getRootProps({ className: 'dropzone-area py-6' })}>
								<input {...getInputProps({ multiple: false })} />
								<Flex justifyContent="center">
									<img src={cloudUpload} alt="" width={25} className="me-2" />
									<p className="fs-0 mb-0 text-700">Drop your file here</p>
								</Flex>
							</div>
						)
					}

					<div className="mt-3">
						{selectedFiles.length > 0 && (
							<>
								<h6>File</h6>
								<ul>{files}</ul>
							</>
						)}
					</div>

				</Modal.Body>
				<Modal.Footer className="bg-light px-card border-top-0">

					<Button
						variant="secondary"
						className="mr-3"
						type="button"
						onClick={handleClose}
					>
						Cancel
					</Button>

					<Button
						variant="primary"
						className="px-4 mx-0"
						type="submit"
						disabled={isUploading}
						onClick={handleSubmit}
					>
						{isUploading ? "Uploading..." : "Upload"}
					</Button>
				</Modal.Footer>
			</Form>
		</Modal>
	);
};

UploadMOU.propTypes = {
	modalState: PropTypes.bool,
	setModalState: PropTypes.func,
	user: PropTypes.object,
	setUser: PropTypes.func,
	refreshParent: PropTypes.func
};

export default UploadMOU;