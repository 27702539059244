import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import Dropdown from 'react-bootstrap/Dropdown';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import CardDropdown from 'components/common/CardDropdown';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import { claimantMedicationAPI } from 'utils/api/fulcrum-api';

const MedicationRow = ({ medication, claimant, getMedications = () => { } }) => {
	let defaultFormData = {
		medication: medication.medication,
		dosage: medication.dosage,
		prescribing_doctor: medication.prescribing_doctor,
		medical_condition: medication.medical_condition,
	};
	const [formData, setFormData] = useState(defaultFormData);
	const [previousFormData, setPreviousFormData] = useState(defaultFormData);

	// Remove a medication
	const removeMedication = () => {
		Swal.fire({
			title: 'Permanently delete this medication record?',
			text: "You won't be able to revert this!",
			icon: 'warning',
			showCansetFormDatalButton: true,
			confirmButtonColor: '#3085d6',
			cansetFormDatalButtonColor: '#d33',
			confirmButtonText: 'Yes, delete it!',
			cancelButtonColor: '#d33',
			showCancelButton: true,
		}).then(async (result) => {
			if (result.isConfirmed) {
				try {
					let result = await claimantMedicationAPI.deleteClaimantMedication(claimant.id, medication.id);
					if (!result?.message) {
						throw new Error('No data returned from server');
					}
					getMedications();
					toast.warning('Medication removed', { theme: 'colored' });
				} catch (error) {
					console.error(error);
					toast.error('Error deleting medication', { theme: 'colored' });
				}
			}
		});
	};

	// Update a medication
	const updateMedication = async (medicationId, data) => {
		try {
			let result = await claimantMedicationAPI.updateClaimantMedication(claimant.id, medicationId, data);
			console.log(result);
			if (!result?.data) {
				throw new Error('Unable to update medication');
			}
			setPreviousFormData({ ...formData, ...data });

		} catch (error) {
			console.error(error);
			toast.error('Error updating medication', {
				theme: 'colored'
			});
		}
	};

	// Save on change
	const saveOnChange = async (e, saveChange) => {
		let { name, value } = e.target;

		if (saveChange && previousFormData[name] !== value) {
			await updateMedication(medication.id, { [name]: value });
		}

		setFormData({ ...formData, [name]: value });
	};

	useEffect(() => {
		let medicationData = {
			medication: medication.medication,
			dosage: medication.dosage,
			prescribing_doctor: medication.prescribing_doctor,
			medical_condition: medication.medical_condition,
		};
		setFormData(medicationData);
		setPreviousFormData(medicationData);
	}, [medication])

	return (
		<>
			<td className="px-0">
				<Form.Control
					className="px-2"
					type="text"
					size="sm"
					name="medication"
					value={formData.medication}
					onChange={e => saveOnChange(e, false)}
					onBlur={e => saveOnChange(e, true)}
				/>
			</td>
			<td className="px-0">
				<Form.Control
					className="px-2"
					type="text"
					size="sm"
					name="dosage"
					value={formData.dosage}
					onChange={e => saveOnChange(e, false)}
					onBlur={e => saveOnChange(e, true)}
				/>
			</td>
			<td className="px-0">
				<Form.Control
					className="px-2"
					type="text"
					size="sm"
					name="prescribing_doctor"
					value={formData.prescribing_doctor}
					onChange={e => saveOnChange(e, false)}
					onBlur={e => saveOnChange(e, true)}
				/>
			</td>
			<td className="px-0">
				<Form.Control
					className="px-2"
					type="text"
					size="sm"
					name="medical_condition"
					value={formData.medical_condition}
					onChange={e => saveOnChange(e, false)}
					onBlur={e => saveOnChange(e, true)}
				/>
			</td>
			<td>
				<div className="d-flex justify-content-between float-end">
					<CardDropdown>
						<div className="py-2">
							<Dropdown.Item onClick={() => { removeMedication(medication.id) }} className="text-danger">Delete</Dropdown.Item>
						</div>
					</CardDropdown>
				</div>
			</td>
		</>
	);
};

export default MedicationRow;