import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';

const PermissionDenied = ({
	title = 'Permission Denied',
	message = 'You do not have permission to access this page.',
}) => (
	<Card className="text-center h-100">
		<Card.Body className="p-5">
			<div className="display-1 text-300 fs-error">Halt!</div>

			<p className="lead mt-4 text-800 font-sans-serif fw-semi-bold">{title}</p>

			<hr />

			<p>{message}</p>
		</Card.Body>
	</Card>
);

PermissionDenied.propTypes = {
	title: PropTypes.string,
	message: PropTypes.string
}

export default PermissionDenied;
