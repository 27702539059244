import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Modal, Row, Col, Alert, ListGroup, Spinner, Table } from 'react-bootstrap';
import { useNavigate, Link } from 'react-router-dom';
import AppContext from 'context/Context';
import { assureAPI, userAPI, accountAPI } from 'utils/api/fulcrum-api';
import { sleep, formatPhone } from 'utils/functions';
import ReactInterval from 'react-interval';
import accountInfo from 'assets/img/bso/tfa/AccountInfo.png';
import secureTextEnabled from 'assets/img/bso/tfa/ChangeNumber-SecureTextEnabled.png';
import changeTextEnabledPhone from 'assets/img/bso/tfa/ChangeTextEnabledPhone.png';
import oldNumberCode from 'assets/img/bso/tfa/OldNumberCode.png';
import newNumber from 'assets/img/bso/tfa/NewNumber.png';
import login from 'assets/img/bso/tfa/Login.png';
import { toast } from 'react-toastify';

const TFASetup = ({
	setModalState = () => { },
	user = {},
	slide,
	setScheduleADemoModalState = (() => { }),
	setSlide = (() => { }),
	formState,
	setFormState,
	handleClose = (() => { }),
	credentialInfo,
	setCredentialInfo = (() => { }),
}) => {
	const [gettingERENumber, setGettingERENumber] = useState(false);
	const [status, setStatus] = useState('');
	const [textMessages, setTextMessages] = useState([]);
	const [tfaTextRefreshCount, setTfaTextRefreshCount] = useState(0);

	// Handle finish 
	const handleFinish = async () => {
		// Validate that we are ready to finish
		//

		// Link the ERE Credential to the user if applicable. 
		//
		//

		// Close the modal
		setSlide(slide + 1);
	};

	// Update ERE Crednetials with the ERE Number
	const updateERECredentials = async (numberId) => {
		try {
			if (!numberId) {

			}
			let result = await assureAPI.updateEreCredentials(credentialInfo?.id, {
				ere_phone_number_id: numberId,
			});
			if (!result?.data?.data || result?.data?.code !== 200) {
				throw 'Unable to update ERE credentials.'
			}

			setCredentialInfo(result?.data?.data || {});
		} catch (error) {
			toast.error('There was an error updating the ERE credentials.', { theme: 'colored' });
			handleClose();
		}
	};

	// Get ERE Number and assign to the ERE Credentials that were just created
	const getERENumber = async () => {
		setGettingERENumber(true);
		try {
			let result = await assureAPI.getEreNumber(user?.account_id || null);
			if (!result?.data?.data?.number || result?.data?.code !== 200) {
				throw 'Unable to get ERE number. Please reach out to Assure for assiance linking your ERE account.'
			}
			setCredentialInfo({
				...credentialInfo,
				ere_phone_number: result.data.data.number,
			});

			console.log({
				...credentialInfo,
				ere_phone_number: result.data.data.number,
			})

			// await updateERECredentials(result?.data?.data?.id || null);
		} catch (error) {
			toast.error('There was an error getting the ERE number.', { theme: 'colored' });
			handleClose();
		}
		setGettingERENumber(false);
	};

	// Get Text Messages
	const getTextMessages = async () => {
		try {
			let result = await assureAPI.getEreCredentialTextMessages(null, 785);
			// let result = await assureAPI.getEreCredentialTextMessages(null, credentialInfo?.id);
			if (!result?.data?.data?.messages || result?.data?.code !== 200) {
				throw 'Unable to get text messages.'
			}
			console.log(result)
			setTextMessages(result?.data?.data?.messages || []);
			setTfaTextRefreshCount(tfaTextRefreshCount + 1);
		} catch (error) {
			toast.error('There was an error getting the text messages.', { theme: 'colored' });
			console.log(error);
			handleClose();
		}
	};

	// Handle input change
	const handleInputChange = e => {
		const { name, value } = e.target;
		setFormState({ ...formState, [name]: value });
	};

	useEffect(() => {
		getERENumber();
	}, [])


	return (
		<div>
			<h5>ERE TFA Setup</h5>
			<br />
			{
				gettingERENumber ?
					(
						<div className="text-center my-5">
							<Spinner animation="border" role="status">
								<span className="sr-only">Loading...</span>
							</Spinner> Getting ERE Phone Number...
						</div>
					)
					:
					(
						<div className="mx-auto" style={{ maxWidth: "1000px" }}>
							<h5 className="text-center">Your new ERE TFA number is: <br /><br /><span className="fw-bold text-success">{credentialInfo?.ere_phone_number ? formatPhone(credentialInfo.ere_phone_number) : ''}</span></h5>
							<hr />
							<br />
							<p className="">
								To complete the setup of Atlasware, you will need to open a new tab and log into your ERE account and configure the text-enabled number for TFA.
							</p>

							<p className="">
								Follow the steps below to complete the setup of your ERE TFA number.
							</p>
							<div className="my-4">
								<h5>Step 1: Log into your ERE account</h5>
								<p className="">
									Open a new tab and log into your ERE account. <a href="https://secure.ssa.gov/acu/LoginWeb/loginHandler.do" target="_blank">https://secure.ssa.gov/acu/LoginWeb/loginHandler.do</a>
								</p>


								<Alert variant="warning">
									WARNING! Do not log out of your ERE account until your Atlasware enrollment is confirmed.
								</Alert>
								<img src={login} alt="Login" className="img-fluid" />
							</div>

							<div className="my-4">
								<h5>Step 2: Navigate to your account information page</h5>
								<p>Click on the "View / Edit Account Info" link under the "Manage Account" menu on the right.</p>
								<img src={accountInfo} alt="View / Edit Account Info" className="img-fluid" />
							</div>

							<div className="my-4">
								<h5>Step 3: Change your text-enabled phone number</h5>
								<p>Under the "Secure Text-enabled Cell Phone Number" section, you will see your current assigned text-enabled phone number.
									Make sure you have access to this number so that you can receive a TFA authentication code from it when asked.</p>
								<p>On the right of the same section, click the "Change Number" button to proceed.</p>
								<img src={secureTextEnabled} alt="Change Number" className="img-fluid" />
							</div>

							<div className="my-4">
								<h5>Step 4: Select "I have both my current text-enabled cell phone number and the new cell phone number I would like to change it to available." and continue</h5>
								<p>On the next screen, make sure the "I have both my current text-enabled cell phone number and the new cell phone number I would like to change it to available." option and click "Next"</p>
								<img src={changeTextEnabledPhone} alt="Change Number" className="img-fluid" />
							</div>

							<div className="my-4">
								<h5>Step 5: Enter your new text-enabled phone number and continue</h5>
								<p>On the next screen, you will be asked to supply a one-time TFA code sent to your old phone number. Enter the code and click "Next" to continue.</p>
								<img src={oldNumberCode} alt="Change Number" className="img-fluid" />
							</div>

							<div className="my-4">
								<h5>Step 6: Enter the TFA code sent to your new phone number</h5>
								<p>Next, you will need to enter the new phone number, <span className="fw-bold">{formatPhone(credentialInfo.ere_phone_number)}</span>, that was generated for your Atlasware account in the field labeld "Text-enabled Cell Phone Number".
									Click next to proceed.</p>
								<img src={newNumber} alt="Change Number" className="img-fluid" />
							</div>

							<div className="my-4">
								<h5>Step 7: Enter the TFA code sent to your new phone number</h5>
								<p>On the next screen, you will be asked to supply a one-time TFA code sent to your new phone number. Below you will find recent text messages that have been sent to <span className="fw-bold">{formatPhone(credentialInfo.ere_phone_number)}</span>.
									Enter the code and click "Next" to continue.</p>
								<div>
									<span className="fw-bold">Recent Text Messages</span>
									<Button variant="link" size="sm" onClick={() => getTextMessages()}>Refresh</Button>
									<br />
									{Array.isArray(textMessages) && textMessages.length > 0
										?
										<Table striped bordered hover size="sm">
											<thead>
												<tr>
													<th>Message</th>
													<th>Date</th>
												</tr>
											</thead>
											<tbody>
												{textMessages.map((message, index) => (
													<tr key={index}>
														<td>{message?.body}</td>
														<td>{message?.date}</td>
													</tr>
												))}
											</tbody>
										</Table>
										:
										<p className="text-muted">No recent text messages found.</p>
									}
								</div>

							</div>
							<div className="text-center">
								<Button
									variant="success"
									onClick={() => handleFinish()}
									size="lg"
								>
									Finished!
								</Button>
							</div>
						</div>
					)
			}

			<ReactInterval timeout={15000} enabled={true} callback={() => { getTextMessages() }} />

		</div>
	);
};

export default TFASetup;