import React, { useState, useContext } from 'react';
import { Button, Modal, Alert, Form } from 'react-bootstrap';
import { claimantAPI, caseAPI } from 'utils/api/fulcrum-api';
import { toast } from 'react-toastify';
import LeadProgressTool from '../LeadProgressTool';
import Swal from 'sweetalert2';
import AppContext from 'context/Context';

const CloseCase = ({ modalState, setModalState = () => { }, claimant, setClaimant, selectedCase, setSelectedCase = () => { }, refreshParent = () => { } }) => {
	const [sending, setSending] = useState(false);
	let defaultFormState = {
		email: false,
		sms: false,
		fax: false,
		mail: false
	};
	const [formData, setFormData] = useState(defaultFormState);
	const {
		config: { isDark }
	} = useContext(AppContext);

	// Handle close case submit
	const handleSubmit = async () => {
		try {
			let result = await caseAPI.closeClaimantCase(selectedCase.claimant_id, selectedCase.id, formData);
			if (!result?.data) {
				throw new Error('Something went wrong.');
			}
			setSelectedCase(result.data.updatedCaseData);
			refreshParent();
			setClaimant(result.data.updatedClaimantData);
			toast.success("The case has been closed!", {
				theme: 'colored'
			});
		} catch (error) {
			toast.error("Unable to close the case.", {
				theme: 'colored'
			});
		}

		handleClose();
	};

	// handle modal close
	const handleClose = async () => {
		setModalState(false);
	};


	return (
		<div>
			{/* <Button variant="falcon-danger" onClick={() => { setModalState(true) }}>Close the Case</Button> */}
			<Modal show={modalState} size="lg" onHide={handleClose}>
				<Modal.Header closeButton>
					<Modal.Title>Time to close the file on this one?</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Alert variant="warning">
						Warning! By closing the case, the claimant will be set to inactive and the case will be closed.
					</Alert>

					<h6>Optionally you can send the claimant a message informing them that their case is being closed. Please select one or more of the following options.</h6>
					<Form>
						<Form.Group controlId="formBasicCheckbox">
							<Form.Check
								type="checkbox"
								label="Send as an email"
								checked={formData.email}
								name="email"
								onChange={(e) => {
									setFormData({ ...formData, [e.target.name]: e.target.checked });
								}}
							/>
						</Form.Group>
						<Form.Group controlId="formBasicCheckbox">
							<Form.Check
								type="checkbox"
								label="Send as a SMS text message"
								checked={formData.sms}
								name="sms"
								onChange={(e) => {
									setFormData({ ...formData, [e.target.name]: e.target.checked });
								}}
							/>
						</Form.Group>
						<Form.Group controlId="formBasicCheckbox">
							<Form.Check
								type="checkbox"
								label="Send by regular mail"
								checked={formData.mail}
								name="mail"
								onChange={(e) => {
									setFormData({ ...formData, [e.target.name]: e.target.checked });
								}}
							/>
						</Form.Group>
					</Form>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="falcon-secondary" onClick={handleClose}>Cancel</Button>
					<Button variant="falcon-primary" onClick={handleSubmit}>Yep! Close the file.</Button>
				</Modal.Footer>
			</Modal>
		</div>
	);
};

export default CloseCase;