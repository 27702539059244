import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Modal, Row, Col, Alert } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import AppContext from 'context/Context';
import { assureAPI, briefRequestAPI } from 'utils/api/fulcrum-api';
import { toast } from 'react-toastify';
import { removeEmptyFromObj } from 'utils/functions';
import moment from 'moment';

const BriefRequestDetails = ({
	modalState,
	setModalState = () => { },
	refreshParent = () => { },
	selectedBriefRequest,
	setSelectedBriefRequest = () => { },
}) => {
	const {
		config: { isDark }
	} = useContext(AppContext);
	const [briefRequest, setBriefRequest] = useState({});

	// Get Brief Request
	const getBriefRequest = async (id) => {
		try {
			let result = await briefRequestAPI.getBriefRequest(selectedBriefRequest?.claimant_id, id);
			if (!result?.data) {
				throw new Error('No Brief Request Data');
			}

			setBriefRequest(result.data);
		} catch (error) {
			console.error(error);
			toast.error('Error getting brief request', { theme: 'colored' });
			handleClose();
		}
	};

	// Handle Close
	const handleClose = () => {
		setBriefRequest({});
		setModalState(!modalState);
		setSelectedBriefRequest({});
	};

	// handle show
	const handleShow = () => {
		setBriefRequest({});
		if (selectedBriefRequest?.id) {
			getBriefRequest(selectedBriefRequest.id);
		} else {
			handleClose();
			toast.error('No Brief Request ID', { theme: 'colored' });
		}
	};

	return (
		<>
			<Modal
				show={modalState}
				onHide={handleClose}
				onShow={handleShow}
				contentClassName="border"
				backdrop="static"
				size='lg'
			>
				<Modal.Header
					closeButton
					closeVariant={isDark ? 'white' : undefined}
					className="bg-light px-card border-bottom-0"
				>
					<Modal.Title as="h5">Brief Request Details</Modal.Title>
				</Modal.Header>
				<Modal.Body className="p-card">
					{JSON.stringify(briefRequest)}
				</Modal.Body>
			</Modal>
		</>
	);
};

export default BriefRequestDetails;