import React, { useEffect, useState } from 'react';
import { Card, Dropdown } from 'react-bootstrap';
import moment from 'moment';
import { assureAPI } from 'utils/api/fulcrum-api';
import { removeEmptyFromObj } from 'utils/functions';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import CustomAdvanceTablePagination from 'components/common/advance-table/CustomAdvanceTablePagination';
import CardDropdown from 'components/common/CardDropdown';
import UploadRequestTableHeader from './UploadRequestTableHeader';
import CreateUploadRequest from './CreateUploadRequest';

const defaultColumns = [
	{
		accessor: 'id',
		Header: 'ID',
		headerProps: { className: 'pe-1' },
		cellProps: {
			className: 'py-2'
		},
		// Cell: rowData => {
		// 	const { name, id } = rowData.row.original;
		// 	return <Link to={`/claimant/profile/${id}`}>{name}</Link>;
		// }
	}
];

const UploadRequestTable = ({ claimant = {}, tableHeaderLabel = "Upload Requests", columns = defaultColumns, defaultSearchParams = {}, requiredSearchParams = {}, refreshParent = () => { } } = {}) => {
	const [createUploadRequestModal, setCreateUploadRequestModal] = useState(false);

	const [uploadRequestData, setUploadRequestData] = useState([]);
	const [Pages, setPages] = useState([1]);
	const [activePage, setActivePage] = useState(1);
	const [perPage, setPerPage] = useState(10);

	const [searching, setSearching] = useState(true);
	const [lastQuery, setLastQuery] = useState({});
	const [searchParams, setSearchParams] = useState({ ...defaultSearchParams, ...requiredSearchParams });
	const [appliedSortOrders, setAppliedSortOrders] = useState([]);

	const headerClickFn = (id, previousSortBy) => {
		if (!id) {
			return;
		}
		let foundIndex = appliedSortOrders.findIndex(s => s[0] === id);
		let sortBy = !previousSortBy ? "ASC" : previousSortBy === "asc" ? "DESC" : '';

		if (!sortBy) { // Not Sorted so remove clicked sort item 
			if (foundIndex > -1) {
				setAppliedSortOrders((prev) => {
					let newSortOrders = [...prev];
					newSortOrders.splice(foundIndex, 1); // remove clicked sort item
					return newSortOrders;
				});
			}
		} else { // Sort by Ascending or Descending 
			let newSort = [id, sortBy];
			if (foundIndex > -1) { // replaces existing 
				setAppliedSortOrders((prev) => {
					let newSortOrders = [...prev];
					newSortOrders[foundIndex] = newSort;
					return newSortOrders;
				});
			} else { // inserts 
				setAppliedSortOrders((prev) => [...prev, newSort]);
			}
		}
	};

	const formatData = (data = []) => {
		let adjustedData = data.map(c => {
			return {
				...c,
				document: c?.document?.title ? c.document.title : '',
				claimant_name: `${c?.claimant?.first_name || null} ${c?.claimant?.last_name || null}`,
				assure_case_id: c?.assure_case_id || null,
				created_at: c?.createdAt ? moment(c.createdAt).format('MM/DD/YYYY hh:mm A') : null,
				assure_last_checked_at: c?.assure_last_checked_at ? moment(c.assure_last_checked_at).format('MM/DD/YYYY hh:mm A') : null,
				assure_last_updated_at: c?.assure_last_updated_at ? moment(c.assure_last_updated_at).format('MM/DD/YYYY hh:mm A') : null,
				menu: (<div className="d-flex justify-content-between float-end">
					<CardDropdown >
						<div className="py-2">
							<Dropdown.Item onClick={async () => { alert('coming') }} className="">View</Dropdown.Item>
						</div>
					</CardDropdown>
				</div>)
			}
		});
		setUploadRequestData(adjustedData);
	};

	const uploadRequestSearchHandler = async (params = {}, saveLastQuery = false) => {
		setSearching(true);
		try {
			params = removeEmptyFromObj(params);
			params.page = activePage;
			params.limit = perPage;
			params.navSize = 4; // How many buttons to show for navigation
			params.order = appliedSortOrders; // Example [['last_last', 'asc'],['first_name','asc'], ['id','asc']]
			let { data } = await assureAPI.searchAtlaswareEREUploadRequests(params);
			let { rows = [], pages = [] } = data || {}; // pages = [1,2,3,10]
			if (!data || !('rows' in data)) {
				throw new Error('No claimant data found');
			}
			if (!rows || (Array.isArray(rows) && rows.length === 0)) {
				setActivePage(1); // Make sure we don't get stuck loading nothing
			}

			formatData(rows);
			setPages(pages);
			if (saveLastQuery) {
				setLastQuery(params);
			}
		} catch (error) {
			console.log(error);
		}
		setSearching(false);
	};

	// Refresh last query
	const refreshLastQuery = async () => {
		await uploadRequestSearchHandler(lastQuery);
	};

	// Apply search filters
	useEffect(() => {
		uploadRequestSearchHandler(searchParams)
	}, [searchParams, activePage, perPage, appliedSortOrders]);

	return (
		<>
			<AdvanceTableWrapper
				columns={columns}
				data={uploadRequestData}
				// selection
				sortable
				pagination
				perPage={perPage}
				setPerPage={setPerPage}
			>
				<Card className="shadow-none">
					<Card.Header>
						<UploadRequestTableHeader
							table
							label={tableHeaderLabel}
							setCreateUploadRequestModal={setCreateUploadRequestModal}
							defaultSearchParams={defaultSearchParams}
							searchParams={searchParams}
							setSearchParams={setSearchParams}
							refreshLastQuery={refreshLastQuery}
							requiredSearchParams={requiredSearchParams}
							uploadRequestData={uploadRequestData}
							claimant={claimant}
						/>
					</Card.Header>
					<Card.Body className="p-0">
						{
							searching
								?
								<p>Searching Upload Requests....</p>
								:
								<AdvanceTable
									table
									headerClickFn={headerClickFn}
									appliedSortOrders={appliedSortOrders}
									headerClassName="bg-200 text-900 text-nowrap align-middle"
									rowClassName="align-middle white-space-nowrap"
									tableProps={{
										size: 'sm',
										striped: true,
										className: 'fs--1 mb-0 overflow-hidden'
									}}
								/>
						}
					</Card.Body>
					<Card.Footer>
						<CustomAdvanceTablePagination
							table
							Pages={Pages}
							activePage={activePage}
							setActivePage={setActivePage}
						/>
					</Card.Footer>
				</Card>
			</AdvanceTableWrapper>

			<CreateUploadRequest
				modalState={createUploadRequestModal}
				setModalState={setCreateUploadRequestModal}
				uploadRequestData={uploadRequestData}
				setUploadRequestData={setUploadRequestData}
				refreshParent={refreshLastQuery}
				claimant={claimant}
			/>
		</>
	);
};


export default UploadRequestTable;