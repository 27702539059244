import React, { useState, useEffect } from 'react';
import { noteAPI } from 'utils/api/fulcrum-api';
import auth from 'utils/auth';
import ListGroup from 'react-bootstrap/ListGroup';
import NoteRow from './NoteRow';
import IconButton from 'components/common/IconButton';
import { toast } from 'react-toastify';
import moment from 'moment';
import Form from 'react-bootstrap/Form';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';


const columns = [
	{
		accessor: 'note',
		Header: 'note'
	}
];

const NoteList = ({ claimant, setClaimant, claimant_id }) => {
	const [loading, setLoading] = useState(true);
	const [notes, setNotes] = useState([]);
	const [filteredNotes, setFilteredNotes] = useState([]);
	const [firstQuery, setFirstQuery] = useState(true);
	const [viewType, setViewType] = useState('ALL');

	// Filter Notes
	const filterNotes = (noteList) => {
		switch (viewType) {
			case 'ALL':
				return noteList;
			case 'SYSTEM_NOTES':
				return noteList.filter(note => note.created_by_id === null || note.created_by_id === undefined);
			case 'STAFF_COMMENTS':
				return noteList.filter(note => note?.created_by_id);
			case 'MY_COMMENTS':
				return noteList.filter(note => note?.created_by_id === auth.getProfile().data.user_id);
			default:
				return noteList;
		}
	};

	// Readable Notes
	const readableNotes = (noteList) => {
		return noteList.map(noteObj => {
			// check if noteObj is a string
			if (typeof noteObj.note != 'string') {
				return noteObj;
			}
			// find and replace "1" with Unacknowledged
			if (noteObj.note.includes('"1"')) {
				noteObj.note = noteObj.note.replace('1', 'Unacknowledged');
			}
			// find and replace "2" with Acknowledged
			if (noteObj.note.includes('"2"')) {
				noteObj.note = noteObj.note.replace('2', 'Acknowledged');
			}
			// find and replace "4" with Completed
			if (noteObj.note.includes('"3"')) {
				noteObj.note = noteObj.note.replace('3', 'Completed');
			}
			return noteObj;
		});
	};
	

	// Format notes
	const formatData = (data = []) => {
		let adjustedData = data.map(note => {
			return {
				...note,
				note: (
					<NoteRow
						note={note}
						setNotes={setNotes}
						notes={notes}
						getNoteList={getNoteList}
						sortNotes={sortNotes}
					/>
				)
			}
		});
		setNotes(adjustedData);
	};

	// Sort notes
	const sortNotes = async (noteList = []) => {
		noteList = await filterNotes(notes);
		const readableNoteList = readableNotes(noteList);
		let pinnedNotes = readableNoteList.filter(n => n.pinned);
		pinnedNotes = pinnedNotes.sort(function (x, y) {
			var a = moment(y.createdAt);
			var b = moment(x.createdAt);
			return a.diff(b, 'seconds', true);
		});

		let remainingNotes = readableNoteList.filter(n => !n.pinned);
		remainingNotes = remainingNotes.sort(function (x, y) {
			var a = moment(y.createdAt);
			var b = moment(x.createdAt);
			return a.diff(b, 'seconds', true);
		});
		let sortedNotes = pinnedNotes.concat(remainingNotes);

		setFilteredNotes([]);
		setFilteredNotes(sortedNotes);
		return sortedNotes;
	};

	// Add note
	const addNote = async () => {
		try {
			let result = await noteAPI.createNote(claimant_id, {
				created_by_id: auth.getProfile().data.user_id,
				note_type: 'NOTE',
			});
			if (!result?.data) {
				throw new Error('No note data returned');
			}
			getNoteList();
		} catch (error) {
			toast.error(`${error}`, {
				theme: 'colored'
			});
		}
	};

	// Get note list by claimant_id
	const getNoteList = async () => {
		try {
			let result = await noteAPI.getAllClaimantNotes(claimant_id);
			if (!result?.data) {
				throw new Error('No note data returned');
			}
			// formatData(result.data)
			setNotes(result.data);
			setFilteredNotes(result.data);
			setLoading(false);
			setFirstQuery(false);
		} catch (error) {
			console.error(error);
		}
	};

	useEffect(() => {
		getNoteList(claimant_id);
	}, []);

	useEffect(() => {
		sortNotes(filteredNotes);
	}, [viewType]);

	useEffect(() => {
		sortNotes(notes);
	}, [notes]);

	useEffect(() => {
		if (!firstQuery) {
			getNoteList(claimant_id);
		}
	}, [claimant.notes.length]);

	if (!claimant_id) {
		return (<p>No claimant ID provided</p>);
	}

	return (
		<div>
			<h5 className="d-inline">Notes</h5>

			<IconButton
				className="me-2 mb-1 float-end"
				variant="falcon-default"
				size="sm"
				icon="plus"
				transform="shrink-3"
				onClick={addNote}
			>
				Add
			</IconButton>

			<div>

				<Form.Group className="mb-3" controlId="viewType">
					<Form.Select name="viewType" size="sm" value={viewType} onChange={e => { setViewType(e.target.value); }} >
						<option value="ALL">All Notes</option>
						<option value="SYSTEM_NOTES">System Notes</option>
						<option value="STAFF_COMMENTS">Staff Comments</option>
						<option value="MY_COMMENTS">My Comments</option>
					</Form.Select>
				</Form.Group>

			</div>
			<span>Viewing {filteredNotes.length} of {notes.length} notes.</span>

			{/* <hr /> */}

			{loading ? <p>Loading notes...</p> : (
				// <AdvanceTableWrapper
				// 	columns={columns}
				// 	data={notes}
				// 	sortable
				// 	pagination
				// 	perPage={5}
				// >
				// 	<AdvanceTable
				// 		table
				// 		headerClassName="bg-200 text-900 text-nowrap align-middle"
				// 		rowClassName="align-middle white-space-nowrap"
				// 		tableProps={{
				// 			bordered: true,
				// 			striped: true,
				// 			className: 'fs--1 mb-0 overflow-hidden'
				// 		}}
				// 	/>
				// 	<div className="mt-3">
				// 		<AdvanceTablePagination
				// 			table
				// 		/>
				// 	</div>
				// </AdvanceTableWrapper>
				<div as="ul" variant="flush" className="p-2 border" style={{
					overflow: "hidden",
					overflowY: "scroll",
					maxHeight: '600px',
				}}>
					{filteredNotes.map((note) => (

						<div as="li" key={note.id}>
							<NoteRow
								note={note}
								setNotes={setNotes}
								notes={notes}
								getNoteList={getNoteList}
								sortNotes={sortNotes}
							/>
						</div>
					))}
				</div>
			)}
		</div>
	);
};


export default NoteList;