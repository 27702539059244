import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Modal, Row, Col, Alert } from 'react-bootstrap';
import AppContext from 'context/Context';
import { toast } from 'react-toastify';
import { isValidPhone, isValidSSN, formatPhone, formatSSN, removeEmptyFromObj } from 'utils/functions';
import { stateData } from 'data/stateData';
import moment from 'moment';
import auth from 'utils/auth';
import { accountAPI, claimantAPI } from 'utils/api/fulcrum-api';
import { useNavigate } from 'react-router-dom';


const CreateClaimantForm = ({ account_id, modalState, setModalState = () => { }, refreshParent = () => { }, siteAdminArea = false } = {}) => {
	const navigate = useNavigate();
	const { config: { isDark } } = useContext(AppContext);
	const [accountData, setAccountData] = useState([]);
	const defaultForm = {
		first_name: '',
		last_name: '',
		// claimant_status_id: 1,
		dob: '',
		ssn: '',
		phone: '',
		email: '',
		mailing_address: '',
		mailing_address_2: '',
		mailing_city: '',
		mailing_state: '',
		mailing_zip: '',
		account_id,
	};
	defaultForm.account_id = account_id || auth.getProfile().data.account_id;
	const [formData, setFormData] = useState(defaultForm);
	const [errors, setErrors] = useState([]);
	const [submitting, setSubmitting] = useState(false);

	const handleShow = () => {
		setModalState(true);
		if (siteAdminArea) {
			getAccountData();
		}
	};
	const handleClose = () => {
		setFormData(defaultForm);
		setModalState(false);
	};

	// Get Account Data
	const getAccountData = async () => {
		try {
			let result = await accountAPI.searchAllAccounts({});
			if (!result?.data?.rows) {
				throw new Error('Unable to get account data.');
			}

			setAccountData(result.data.rows);
		} catch (error) {
			console.log(error)
			toast.error('Unable to load account data.', { theme: 'colored' });
			handleClose();
		}
	};


	const handleChange = (e) => {
		if (e) {
			e.stopPropagation();
		}
		let { name, value, type, checked = false } = e?.target || {}
		if (name == "phone") {
			value = formatPhone(value);
		}
		if (name == "ssn") {
			value = formatSSN(value);
		}
		if (type === "checkbox") {
			value = checked;
		} else if (e) {
			e.preventDefault();
		}
		setFormData((prevState) => ({ ...prevState, [name]: value }));
	};

	const handleBlur = (e) => {
		let { name, value, type, checked = false } = e?.target || {}
		if (name == 'phone' && !isValidPhone(value)) {
			toast.warning('Please enter a valid phone number.', {
				theme: 'colored'
			});
		}

		if (name == 'ssn' && !isValidSSN(value)) {
			toast.warning('Please enter a valid social security number.', {
				theme: 'colored'
			});
		}
	}

	// Submit
	const handleSubmit = async (e) => {
		if (e) {
			e.preventDefault();
		}
		setSubmitting(true);
		setErrors([]);
		let newErrors = [];
		let isValid = Object.entries(formData).some(([key, value]) => {
			return value && value.length > 0;
		});

		if (!isValid) {
			newErrors.push('Please fill out at least one field.');
		}

		if (errors.length > 0) {
			setSubmitting(false);
			setErrors(newErrors);
			return;
		}

		// Package up addresses
		let addresses = [];
		if (formData.mailing_address || formData.mailing_address_2 || formData.mailing_city || formData.mailing_state || formData.mailing_zip) {
			addresses.push({
				address_type_id: 2,
				address: formData.mailing_address,
				address_2: formData.mailing_address_2,
				city: formData.mailing_city,
				state: formData.mailing_state,
				zip: formData.mailing_zip,
				is_primary: true,
			});
		}

		let data = removeEmptyFromObj(formData);
		let newValues = {
			...data,
			dob: data?.dob ? moment(data.dob).format('YYYY-MM-DD hh:mm A') : null,
			addresses,
			phones: data?.phone ? [
				{
					phone_type_id: "Home",
					phone: data.phone,
					is_primary: true
				}
			] : null,
			emails: data?.email ? [
				{
					email: data.email,
					is_primary: true
				}
			] : null,
		};

		try {
			let newClaimantData = await claimantAPI.createClaimant(newValues);
			if (newClaimantData?.response?.error === 'Claimant already exists for this account.') {
				toast.warning('Claimant already exists for this account.', {
					theme: 'colored'
				});
				navigate(siteAdminArea ? `/admin/claimant-details/${newClaimantData.data.id}` : `/claimant/profile/${newClaimantData.response.claimant.id}`);
				return;
			}
			if (!newClaimantData?.data?.id) {
				throw new Error('Unable to create the new claimant.');
			}
			handleClose();
			toast.success('New claimant created!', {
				theme: 'colored'
			});
			navigate(siteAdminArea ? `/admin/claimant-details/${newClaimantData.data.id}` : `/claimant/profile/${newClaimantData.data.id}`);
		} catch (error) {
			console.log(error);
			toast.error('Unable to create the claimant.', {
				theme: 'colored'
			});
		}
		setSubmitting(false);
	};

	return (
		<Modal
			show={modalState}
			onHide={handleClose}
			onShow={handleShow}
			contentClassName="border"
			size="lg"
			backdrop="static"
		>
			<Form onSubmit={handleSubmit}>
				<Modal.Header
					closeButton
					closeVariant={isDark ? 'white' : undefined}
					className="bg-light px-card border-bottom-0"
				>
					<Modal.Title as="h5">Create Claimant</Modal.Title>
				</Modal.Header>

				<Modal.Body className="p-card">
					<Alert variant={'primary'}>
						To create a new claimant, users must enter at least one of the following fields.
					</Alert>

					{
						errors.length > 0 && (
							<Alert variant={'danger'}>
								{errors.map((error, index) => (
									<div key={index}>{error}</div>
								))}
							</Alert>
						)
					}
					{
						siteAdminArea && setAccountData.length &&
						<Form.Group className="mb-3" controlId="account_id">
							<Form.Label className="fs-0">Account</Form.Label>
							<Form.Select
								disabled={submitting}
								name="account_id"
								size="sm"
								defaultValue={""}
								value={formData.account_id || null}
								onChange={handleChange}
								required
							>
								<option value="" disabled={true}>--Select One--</option>
								{
									accountData.map((account, index) => {
										return <option key={index} value={account?.id || null}>{account?.business_name || ''}</option>
									})
								}
							</Form.Select>
						</Form.Group>
					}

					<Row>
						<Form.Group as={Col} className="mb-3" controlId="first_name">
							<Form.Label className="fs-0">First Name*</Form.Label>
							<Form.Control
								type="text"
								size="sm"
								name="first_name"
								value={formData.first_name}
								onChange={handleChange}
								disabled={submitting}
								required
							/>
						</Form.Group>
						<Form.Group as={Col} className="mb-3" controlId="last_name">
							<Form.Label className="fs-0">Last Name</Form.Label>
							<Form.Control
								type="text"
								size="sm"
								name="last_name"
								value={formData.last_name}
								disabled={submitting}
								onChange={handleChange}
							/>
						</Form.Group>
					</Row>
					<Row>
						<Form.Group as={Col} className="mb-3" controlId="dob">
							<Form.Label className="fs-0">Date of Birth</Form.Label>
							<Form.Control
								type="date"
								size="sm"
								name="dob"
								value={formData.dob}
								disabled={submitting}
								onChange={handleChange}
							/>
						</Form.Group>
						<Form.Group as={Col} className="mb-3" controlId="ssn">
							<Form.Label className="fs-0">SSN</Form.Label>
							<Form.Control
								type="text"
								size="sm"
								name="ssn"
								value={formData.ssn}
								disabled={submitting}
								onChange={handleChange}
								onBlur={handleBlur}
							/>
						</Form.Group>
					</Row>
					<Row>
						<Form.Group as={Col} className="mb-3" controlId="email">
							<Form.Label className="fs-0">Email</Form.Label>
							<Form.Control
								type="text"
								size="sm"
								name="email"
								value={formData.email}
								disabled={submitting}
								onChange={handleChange}
							/>
						</Form.Group>
					</Row>
					<Row>
						<Form.Group as={Col} className="mb-3" controlId="phone">
							<Form.Label className="fs-0">Phone</Form.Label>
							<Form.Control
								type="text"
								size="sm"
								name="phone"
								value={formData.phone}
								disabled={submitting}
								onChange={handleChange}
								onBlur={handleBlur}
							/>
						</Form.Group>
					</Row>
					<Row>
						<Form.Group as={Col} sm="12" className="mb-3" controlId="mailing_address">
							<Form.Label className="fs-0">Mailing Address</Form.Label>
							<Form.Control
								type="text"
								size="sm"
								name="mailing_address"
								value={formData.mailing_address}
								disabled={submitting}
								onChange={handleChange}
							/>
						</Form.Group>
						{/* <Form.Group as={Col} sm="4" className="mb-3" controlId="mailing_address_2">
							<Form.Label className="fs-0">Address 2</Form.Label>
							<Form.Control
								type="text"
								size="sm"
								name="mailing_address_2"
								value={formData.mailing_address_2}
								onChange={handleChange}
							/>
						</Form.Group> */}
					</Row>
					<Row>
						<Form.Group as={Col} sm="6" className="mb-3" controlId="mailing_city">
							<Form.Label className="fs-0">City</Form.Label>
							<Form.Control
								type="text"
								size="sm"
								name="mailing_city"
								value={formData.mailing_city}
								disabled={submitting}
								onChange={handleChange}
							/>
						</Form.Group>

						<Form.Group as={Col} sm="3" className="mb-3" controlId="mailing_state">

							<Form.Label className="fs-0">State</Form.Label>
							<Form.Select
								size="sm"
								as="select"
								name="mailing_state"
								value={formData.mailing_state}
								disabled={submitting}
								onChange={handleChange}
							>
								<option value=""></option>
								{
									Array.isArray(stateData) && stateData.map((state, index) => {
										return (
											<option key={index} value={state.abbreviation}>{state.abbreviation}</option>
										);
									})
								}
							</Form.Select>
						</Form.Group>
						{/* <Form.Group as={Col} className="mb-3" controlId="mailing_state">
							<Form.Label className="fs-0">State</Form.Label>
							<Form.Control
								type="text"
								size="sm"
								name="mailing_state"
								value={formData.mailing_state}
								onChange={handleChange}
							/>
						</Form.Group> */}
						<Form.Group as={Col} sm="3" className="mb-3" controlId="mailing_zip">
							<Form.Label className="fs-0">Zip</Form.Label>
							<Form.Control
								type="text"
								size="sm"
								name="mailing_zip"
								value={formData.mailing_zip}
								disabled={submitting}
								onChange={handleChange}
							/>
						</Form.Group>
					</Row>
				</Modal.Body>

				<Modal.Footer className="bg-light px-card border-top-0">
					<div className="">
						<Button
							variant="outline-danger"
							className="px-4"
							type="reset"
							onClick={handleClose}
						>
							Cancel
						</Button>
					</div>
					<div className="">
						<Button
							variant="primary"
							type="submit"
							className="px-4 mx-0"
							disabled={submitting}
						>
							{submitting ? "Creating claimant..." : "Create Claimant"}
						</Button>
					</div>
				</Modal.Footer>
			</Form>
		</Modal>
	);
};

CreateClaimantForm.propTypes = {
	modalState: PropTypes.bool,
	setModalState: PropTypes.func,
	refreshParent: PropTypes.func,
	handleCreate: PropTypes.func,
};

export default CreateClaimantForm;