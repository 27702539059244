import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

import { toast } from 'react-toastify';
import { claimantDetailsAPI } from 'utils/api/fulcrum-api';
import { getAgeFromBirthdate, formatPhone, isValidPhone, formatSSN } from 'utils/functions';
import AddressList from 'components/app/address/AddressList';
import EmailList from 'components/app/email/emails/EmailList';
import PhoneList from 'components/app/phones/PhoneList';
import moment from 'moment';

// import ClaimantConfirmContactInfo from './ClaimantConfirmContactInfo';
// import LostContact from './lost-contact-with-claimant/LostContact';

import FloatingLabel from 'react-bootstrap/FloatingLabel';

const ClaimantProfileDetails = ({ uuid, claimant, setClaimant }) => {
  let defaultState = {
    first_name: claimant?.first_name,
    last_name: claimant?.last_name,
    email: claimant?.email,
    ssn: formatSSN(claimant?.ssn),
    dob: claimant?.dob,
    alt_contact_name: claimant?.alt_contact_name,
    alt_contact_phone: formatPhone(claimant?.alt_contact_phone),
    alt_contact_relationship: claimant?.alt_contact_relationship,
  };

  const [formData, setFormData] = useState(defaultState);
  const [previousFormData, setPreviousFormData] = useState(defaultState);
  const [isEditing] = useState(true);
  const [saveOnChangeForm] = useState(true);

  // const [showLostContact, setShowLostContact] = useState(false);

  const handleInputChange = (event) => {
    let { name, value } = event.target;
    if (name == "alt_contact_phone") {
      value = formatPhone(value)
    }
    setFormData({ ...formData, [name]: value });
  };

  const saveClaimant = async (data) => {
    console.log("saveClaimant", data);
    try {
      let result = await claimantDetailsAPI.updateClaimantDetails(uuid, claimant.id, data);
      if (!result?.data) {
        throw new Error('No claimant data returned');
      }
      setClaimant(result.data);
      let updatedFormData = { ...formData, ...data };
      setFormData(updatedFormData);
      setPreviousFormData(updatedFormData);
    } catch (error) {
      console.error(error);
      toast.error(`${error}`, {
        theme: 'colored'
      });
    }
    setPreviousFormData(formData);
  };

  // Save on change
  const saveOnChange = (event) => {
    const { name, value } = event.target;
    if (name == "alt_contact_phone" && !isValidPhone(value)) {
      toast.warning('Please enter a valid phone number.', {
        theme: 'colored'
      });
    }
    if (previousFormData[name] !== value) {
      saveClaimant({ [name]: value });
    }
  };

  return (
    <Card className="" >
      <Card.Header className="border-bottom">
        <Card.Title>
          ID: {claimant?.id} - {claimant?.first_name} {claimant?.last_name}
        </Card.Title>
        <span>Created: {moment(claimant?.createdAt).format('MM/DD/YYYY hh:mm A')}</span>
        <br />
      </Card.Header>
      <Card.Body>

        <Form className="px-3">
          <Row>
            <Col sm={6} className={'px-0'}>
              <Form.Group controlId="first_name">
                <FloatingLabel
                  controlId="first_name"
                  label="First Name"
                  className=""
                  size="sm"
                >

                  <Form.Control
                    size="sm"
                    type="text"
                    name="first_name"
                    value={formData.first_name}
                    onChange={handleInputChange}
                    onBlur={saveOnChangeForm ? saveOnChange : () => { }}
                    disabled={!isEditing}
                  />
                </FloatingLabel>
              </Form.Group>
            </Col>
            <Col sm={6} className={'px-0'}>
              <Form.Group controlId="last_name">
                <FloatingLabel
                  controlId="last_name"
                  label="Last Name"
                  className=""
                  size="sm"
                >
                  <Form.Control
                    size="sm"
                    type="text"
                    name="last_name"
                    value={formData.last_name}
                    onChange={handleInputChange}
                    onBlur={saveOnChangeForm ? saveOnChange : () => { }}
                    disabled={!isEditing}
                  />
                </FloatingLabel>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col sm={5} className={'px-0'}>
              <Form.Group controlId="ssn">
                <FloatingLabel
                  controlId="ssn"
                  label="SSN"
                  className=""
                  size="sm"
                >

                  <Form.Control
                    size="sm"
                    type="text"
                    name="ssn"
                    value={formData.ssn}
                    onChange={handleInputChange}
                    onBlur={saveOnChangeForm ? saveOnChange : () => { }}
                    disabled={!isEditing}
                  />
                </FloatingLabel>
              </Form.Group>
            </Col>
            <Col sm={5} className={'px-0'}>
              <Form.Group controlId="dob">
                <FloatingLabel
                  controlId="dob"
                  label="DOB"
                  className=""
                  size="sm"
                >
                  <Form.Control
                    size="sm"
                    type="date"
                    name="dob"
                    value={formData.dob}
                    onChange={handleInputChange}
                    onBlur={saveOnChangeForm ? saveOnChange : () => { }}
                    disabled={!isEditing}
                  />
                </FloatingLabel>
              </Form.Group>
            </Col>
            <Col className={'px-0'}>
              <Form.Group controlId="age">
                <FloatingLabel
                  controlId="age"
                  label="Age"
                  className=""
                  size="sm"
                >

                  <Form.Control
                    size="sm"
                    name="age"
                    value={getAgeFromBirthdate(formData.dob)}
                    onChange={handleInputChange}
                    className={""}
                    onBlur={saveOnChangeForm ? saveOnChange : () => { }}
                    disabled={true}
                  />
                </FloatingLabel>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col sm={12} className={'px-0'}>
              <Form.Group controlId="email">
                <FloatingLabel
                  controlId="email"
                  label="Email"
                  className=""
                  size="sm"
                >

                  <Form.Control
                    size="sm"
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    className={""}
                    onBlur={saveOnChangeForm ? saveOnChange : () => { }}
                    disabled={!isEditing}
                  />
                </FloatingLabel>
              </Form.Group>
            </Col>
          </Row>
          <br />

          {/* <span className="fw-bold">Alternate Contact Information</span> */}
          <Row>
            <Col sm={12} className={'px-0'}>
              <Form.Group controlId="alt_contact_name">
                <FloatingLabel
                  controlId="alt_contact_name"
                  label="Alternate Contact Name"
                  className=""
                  size="sm"
                >
                  <Form.Control
                    size="sm"
                    name="alt_contact_name"
                    value={formData.alt_contact_name}
                    onChange={handleInputChange}
                    className={""}
                    onBlur={saveOnChangeForm ? saveOnChange : () => { }}
                    disabled={!isEditing}
                  />
                </FloatingLabel>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={6} className={'px-0'}>
              <Form.Group controlId="alt_contact_phone">
                <FloatingLabel
                  controlId="alt_contact_phone"
                  label=" Phone"
                  className=""
                  size="sm"
                >
                  <Form.Control
                    size="sm"
                    name="alt_contact_phone"
                    value={formData.alt_contact_phone}
                    onChange={handleInputChange}
                    className={""}
                    onBlur={saveOnChangeForm ? saveOnChange : () => { }}
                    disabled={!isEditing}
                  />
                </FloatingLabel>
              </Form.Group>
            </Col>
            <Col sm={6} className={'px-0'}>
              <Form.Group controlId="alt_contact_relationship">
                <FloatingLabel
                  controlId="alt_contact_relationship"
                  label="Relationship"
                  className=""
                  size="sm"
                >
                  <Form.Control
                    size="sm"
                    name="alt_contact_relationship"
                    value={formData.alt_contact_relationship}
                    onChange={handleInputChange}
                    className={""}
                    onBlur={saveOnChangeForm ? saveOnChange : () => { }}
                    disabled={!isEditing}
                  />
                </FloatingLabel>
              </Form.Group>
            </Col>
          </Row>

        </Form>
        <div className="my-3">
          <AddressList uuid={uuid} entity_id={claimant?.entity_id} />
        </div>
        <div className="my-3">
          <PhoneList uuid={uuid} entity_id={claimant?.entity_id} />
        </div>
        <div className="my-3">
          <EmailList uuid={uuid} entity_id={claimant?.entity_id} />
        </div>
      </Card.Body>
    </Card>
  );
};

ClaimantProfileDetails.propTypes = {
  claimant: PropTypes.object,
  setClaimant: PropTypes.func,
  uuid: PropTypes.string
}

export default ClaimantProfileDetails;