import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, Form } from 'react-bootstrap';
import { claimantAPI } from 'utils/api/fulcrum-api';
import { toast } from 'react-toastify';
import AppContext from 'context/Context';

const SendUpdateInformationRequestButton = ({ modalState, setModalState = () => { }, claimant }) => {
	const {
		config: { isDark }
	} = useContext(AppContext);
	const [sending, setSending] = useState(false);
	let defaultFormState = {
		mode_of_transmission: null
	};
	const [formData, setFormData] = useState(defaultFormState);

	// Handle click
	const handleClick = async () => {
		setSending(true);
		try {
			let result = await claimantAPI.sendUpdateInformationRequest(claimant.id, formData);
			if (!result?.data) {
				throw new Error('No claimant data returned');
			}
			toast.success('Update information request sent!', { theme: 'colored' });
			handleClose();
		} catch (error) {
			toast.error(error, { theme: 'colored' });
		}
		setSending(false);
	};

	// Handle modal close
	const handleClose = () => {
		setModalState(false);
		setSending(false);
		setFormData(defaultFormState);
	};

	return (
		<>
			{/* {
				claimant.screened == true &&
				<Button variant="falcon-primary" onClick={() => { setModalState(true) }}>Request Updated Information</Button>
			} */}

			<Modal
				show={modalState}
				onHide={handleClose}
				contentClassName="border"
			>
				<Modal.Header
					closeButton
					closeVariant={isDark ? 'white' : undefined}
					className="bg-light px-card border-bottom-0"
				>
					<Modal.Title as="h5">Request Updated Information</Modal.Title>
				</Modal.Header>
				<Modal.Body className="p-card">
					<p>Send the claimant a request to update their information. Please select at least one option below.</p>
					<Form>
						<Form.Group controlId="formBasicCheckbox">
							<Form.Check
								type="radio"
								label="Send as an email"
								checked={formData.mode_of_transmission === 'email'}
								name="mode_of_transmission"
								id="email"
								onChange={(e) => {
									setFormData({ ...formData, [e.target.name]: e.target.id });
								}}
							/>
							<Form.Check
								type="radio"
								label="Send as a SMS text message"
								checked={formData.mode_of_transmission === 'sms'}
								name="mode_of_transmission"
								id="sms"
								onChange={(e) => {
									setFormData({ ...formData, [e.target.name]: e.target.id });
								}}
							/>
							<Form.Check
								type="radio"
								label="Send by regular mail"
								checked={formData.mode_of_transmission === 'mail'}
								name="mode_of_transmission"
								id="mail"
								onChange={(e) => {
									setFormData({ ...formData, [e.target.name]: e.target.id });
								}}
							/>
						</Form.Group>
					</Form>
				</Modal.Body>
				<Modal.Footer className="border-top-0">
					<Button variant="secondary" onClick={handleClose}>
						Cancel
					</Button>
					{
						(formData.mode_of_transmission !== null) &&
						<Button variant="primary" onClick={handleClick} disabled={sending}>
							{
								sending &&
								<>
									<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
								</>
							}
							Send
						</Button>
					}
				</Modal.Footer>
			</Modal>
		</>
	);
};

SendUpdateInformationRequestButton.propTypes = {
	modalState: PropTypes.bool,
	setModalState: PropTypes.func,
	claimant: PropTypes.object
};

export default SendUpdateInformationRequestButton;