import React, { useRef, useState, useEffect } from 'react';
import { Col, Form, Button } from 'react-bootstrap';
import UserTable from 'components/pages/users/user-list/UserTable';
import { Link } from 'react-router-dom';
import { formatPhone } from 'utils/functions';

const UserManagement = ({ } = {}) => {


	return (
		<div>
			<UserTable
				siteAdminArea={true}
				columns={[
					{
						accessor: 'business_name',
						Header: 'Account',
						headerProps: { className: 'pe-1' },
						cellProps: {
							className: 'py-2'
						},
						Cell: rowData => {
							const { account = {} } = rowData.row.original;
							return <Link to={`/admin/account-details/${account?.id || null}`}>{account?.business_name || ''}</Link>;
						},
					},
					{
						accessor: 'name',
						Header: 'Name',
						headerProps: { className: 'pe-1' },
						cellProps: {
							className: 'py-2'
						},
					},
					// {
					// 	accessor: 'first_name',
					// 	Header: 'First Name',
					// 	headerProps: { className: 'pe-1' },
					// 	cellProps: {
					// 		className: 'py-2'
					// 	}
					// },
					// {
					// 	accessor: 'last_name',
					// 	Header: 'Last Name',
					// 	headerProps: { className: 'pe-1' },
					// 	cellProps: {
					// 		className: 'py-2'
					// 	}
					// },
					{
						accessor: 'user_type',
						Header: 'User Type',
						headerProps: { className: 'pe-1' },
						cellProps: {
							className: 'py-2'
						}
					},
					{
						accessor: 'user_status',
						Header: 'Status',
						headerProps: { className: 'pe-1' },
						cellProps: {
							className: 'py-2'
						}
					},
					{
						accessor: 'admin',
						Header: 'Admin',
						headerProps: { className: 'pe-1' },
						cellProps: {
							className: 'py-2'
						}
					},
					{
						accessor: 'email',
						Header: 'Email',
						headerProps: { className: 'pe-1' },
						cellProps: {
							className: 'py-2'
						}
					},
					{
						accessor: 'phone',
						Header: 'Phone',
						Cell: rowData => {
							const { phone } = rowData.row.original;
							return <a href={`tel:${formatPhone(phone)}`}>{formatPhone(phone)}</a>;
						}
					},
					{
						accessor: 'created_at',
						Header: 'Created',
						headerProps: { className: 'pe-1' },
						cellProps: {
							className: 'py-2'
						}
					},
					{
						accessor: 'last_login_timestamp',
						Header: 'Last Login',
						headerProps: { className: 'pe-1' },
						cellProps: {
							className: 'py-2'
						}
					},
					// {
					// 	accessor: 'address',
					// 	Header: 'Billing Address',
					// 	headerProps: { style: { minWidth: '200px' }, className: 'ps-5' },
					// 	cellProps: { className: 'ps-5' }
					// },
					// {
					// 	accessor: 'joined',
					// 	Header: 'Joined'
					// },
				]}
			/>
		</div>
	);
};





export default UserManagement;