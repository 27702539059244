import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Modal, Row, Col, Alert, ListGroup, Spinner } from 'react-bootstrap';
import { useNavigate, Link } from 'react-router-dom';
import AppContext from 'context/Context';
import { assureAPI, userAPI, accountAPI } from 'utils/api/fulcrum-api';
import { formatPhone, isValidPhone } from 'utils/functions';
import { toast } from 'react-toastify';

const CredentialSetup = ({
	setModalState = () => { },
	user = {},
	slide,
	setScheduleADemoModalState = (() => { }),
	setSlide = (() => { }),
	formState,
	setFormState,
	credentialInfo,
	handleClose = (() => { }),
	setCredentialInfo = () => { }
}) => {
	const [creatingAccount, setCreatingAccount] = useState(false);
	const [errors, setErrors] = useState([]);
	// Handle input change
	const handleInputChange = e => {
		const { name, value } = e.target;
		setFormState({ ...formState, [name]: value });
	};

	// Create Assure ERE Credentials
	const createAssureERECredentials = async e => {
		if (e) e.preventDefault();
		setCreatingAccount(true);
		setErrors([]);
		let newErrors = [];
		try {
			const result = await assureAPI.createEreCredentials(user?.account_id || null, formState);

			if (!result?.data?.data) {
				if (result?.response?.error === "The provided credentials appear to be a duplicate of existing credentials. Please update existing credentials instead of creating duplicates.") {
					setErrors(['The provided credentials appear to be a duplicate of existing credentials. Please update existing credentials instead of creating duplicates.'])
					setCreatingAccount(false);
					return;
				}
				throw new Error('There was an error creating ERE credentials.');
			}
			toast.success('Successfully created ERE credentials.', { theme: 'colored' });
			setCredentialInfo(result.data.data);
			setSlide(slide + 1);
		} catch (err) {
			toast.error('There was an error creating ERE credentials.', { theme: 'colored' });
			handleClose();
		}
		setCreatingAccount(false);
	};

	// Add forward number
	const addForwardNumber = () => {
		setFormState({
			...formState,
			forward_numbers: [...formState.forward_numbers, {
				contact_number: '',
			}]
		});
	};

	// Add forward email
	const addForwardEmail = () => {
		setFormState({
			...formState,
			forward_emails: [...formState.forward_emails, {
				email: '',
			}]
		});
	};


	return (
		<div>
			<h5>ERE Account Information</h5>
			<hr />
			<p>
				To get started, please fill out the form below. We will use this information to attempt logging into your ERE account and link it to your Assure account.
			</p>

			{
				errors.length > 0 && (
					<Alert variant="danger">
						{
							errors.map((error, index) => (
								<p key={index}>{error}</p>
							))
						}
					</Alert>
				)
			}

			<Form onSubmit={createAssureERECredentials} style={{ maxWidth: "1000px" }} className="mx-auto">
				<div className="mx-auto" style={{ maxWidth: "500px" }}>
					<Form.Group controlId="attorneyName" className="my-3">
						<Form.Label className="mb-0 fw-bold">
							Attorney Name
						</Form.Label>
						<br />
						<span className="text-muted">Enter the name exactly the same as it appears on your ERE account.</span>
						<Form.Control
							type="text"
							autoComplete="off"
							placeholder="Enter attorney name"
							disabled={creatingAccount}
							value={formState.attorney_name}
							onChange={(e) => setFormState({ ...formState, attorney_name: e.target.value })}
						/>
					</Form.Group>

					<Form.Group controlId="ere_username" className="my-3">
						<Form.Label className="mb-0 fw-bold">
							ERE Username
						</Form.Label>
						<br />
						<span className="text-muted">User ID and Password must contain a combination of 8 letters 'A' through 'Z' and numbers '0' through '9'.</span>
						<Form.Control
							type="text"
							autoComplete="off"
							placeholder="Enter username"
							value={formState.ere_username}
							disabled={creatingAccount}
							onChange={(e) => {
								let value = e.target.value;
								// Make sure that the username is only 8 characters long
								if (value.length > 8) {
									value = value.substring(0, 8);
								}

								setFormState({ ...formState, ere_username: value })
							}}
						/>
					</Form.Group>

					<Form.Group controlId="ere_password" className="my-3">
						<Form.Label className="mb-0 fw-bold">
							ERE Password
						</Form.Label>
						<br />
						<span className="text-muted">User ID and Password must contain a combination of 8 letters 'A' through 'Z' and numbers '0' through '9'.</span>
						<Form.Control
							type="password"
							autoComplete="off"
							placeholder="Enter password"
							value={formState.ere_password}
							disabled={creatingAccount}
							onChange={(e) => {
								let value = e.target.value;
								// Make sure the password is only 8 characters long
								if (value.length > 8) {
									value = value.substring(0, 8);
								}

								setFormState({ ...formState, ere_password: value })
							}}
						/>
					</Form.Group>
				</div>

				<br />
				<h5>Forwarding Options</h5>
				<hr />
				<p>
					Atlasware will automatically complete the ERE two-factor authentication process for you and will forward two-factor
					authentication codes to you as directed.
				</p>
				<div className="mx-auto" style={{ maxWidth: "500px" }}>
					<Form.Group controlId="forward_phone_number" className=" my-3">
						<Form.Label className="fw-bold">
							Forward Phone Number
						</Form.Label>
						<Form.Control
							type="text"
							autoComplete="off"
							placeholder="Enter phone number"
							value={formState.forward_phone_number}
							disabled={creatingAccount}
							onChange={(e) => {
								let value = e.target.value;
								// Make sure the phone number is valid
								value = formatPhone(value);
								setFormState({ ...formState, forward_phone_number: value });
							}}
						/>
					</Form.Group>

					<Form.Group controlId="forward_email" className=" my-3">
						<Form.Label className="fw-bold">
							Forward Email
						</Form.Label>
						<Form.Control
							type="email"
							autoComplete="off"
							placeholder="Enter email"
							value={formState.forward_email}
							disabled={creatingAccount}
							onChange={(e) => {
								let value = e.target.value;

								setFormState({ ...formState, forward_email: value })
							}}
						/>
					</Form.Group>
				</div>

				<br />
				<p>
					Next, you'll need to log into your ERE account to finish setting up two-factor authentication. This will require you to
					change the the text-enabled cell phone number associated with your ERE account.
				</p>

				{/* <div className=""> */}
				<Button
					variant="primary"
					type="submit"
					className="float-end"
					size="lg"
				>
					Next
				</Button>
				{/* </div> */}

			</Form>
		</div>
	);
};

export default CredentialSetup;