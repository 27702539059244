import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Button, Form, Row, Col } from 'react-bootstrap';
import { userAPI } from 'utils/api/fulcrum-api';
import { toast } from 'react-toastify';

const ChangePassword = ({ userId, setModalShow }) => {
  const [formData, setFormData] = useState({
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
    containsEightCharacters: false,
    containsUpperCase: false,
    containsLowerCase: false,
    containsNumber: false,
    containsSpecialCharacter: false,
    passwordMatch: false
  });
  const [viewPasswordContent, setViewPasswordContent] = useState(false);

  const handleChange = e => {
    let additionalChanges = {};

    if (e.target.name === 'confirmPassword' || e.target.name === 'newPassword') {
      additionalChanges = {
        ...additionalChanges,
        passwordMatch: e.target.value === formData.newPassword
      }
    }

    // Check if password contains requirements
    if (e.target.name === 'newPassword') {
      additionalChanges = {
        ...additionalChanges,
        containsEightCharacters: e.target.value.length >= 8,
        containsUpperCase: /[A-Z]/.test(e.target.value),
        containsLowerCase: /[a-z]/.test(e.target.value),
        containsNumber: /[0-9]/.test(e.target.value),
        containsSpecialCharacter: /[!@#$%^&*(),.?":{}|<>]/.test(e.target.value)
      };
    }

    let newFormState = {
      ...formData,
      ...additionalChanges,
      [e.target.name]: e.target.value
    };

    if (newFormState.oldPassword &&
      newFormState.newPassword &&
      newFormState.confirmPassword &&
      newFormState.passwordMatch &&
      newFormState.containsEightCharacters &&
      newFormState.containsUpperCase &&
      newFormState.containsLowerCase &&
      newFormState.containsNumber &&
      newFormState.containsSpecialCharacter) {
      newFormState = {
        ...newFormState,
        formValid: true
      };
    } else {
      newFormState = {
        ...newFormState,
        formValid: false
      }
    }

    setFormData(newFormState);
  };

  const handleSubmit = async e => {
    e.preventDefault();

    if (!formData.oldPassword || !formData.newPassword || !formData.confirmPassword) {
      return alert('Please fill out all fields');
    }

    if (formData.newPassword !== formData.confirmPassword) {
      return alert('The new password and confirm password fields must match');
    }

    if (!formData.containsEightCharacters || !formData.containsUpperCase || !formData.containsLowerCase || !formData.containsNumber || !formData.containsSpecialCharacter) {
      return alert('The new password must contain at least 8 characters, 1 uppercase letter, 1 lowercase letter, 1 number, and 1 special character');
    }

    try {
      let result = await userAPI.changePassword(userId, formData.oldPassword, formData.newPassword);
      console.log(result);
      if (!result?.data) {
        throw new Error('Unable to change password');
      }

      toast.success(`Password successfully changed`, {
        theme: 'colored'
      });
      setModalShow(false);
    } catch (error) {
      toast.error(`${error}`, {
        theme: 'colored'
      });
      console.error(error);
    }

  };

  const sendPasswordResetEmail = async e => {
    e.preventDefault();
    try {
      let result = await userAPI.sendUserPasswordResetEmail(userId);
      if (!result?.data) {
        throw new Error('Unable to send password reset email');
      }

      toast.success(`Password reset email successfully sent`, {
        theme: 'colored'
      });
      setModalShow(false);
    } catch (error) {
      toast.error(`${error}`, {
        theme: 'colored'
      });
      console.error(error);
    }
  };

  return (
    <div>


      <Form onSubmit={handleSubmit}>
        <Row>
          <Col>
            <Button onClick={() => setViewPasswordContent(!viewPasswordContent)} className="mb-3" variant="outline-primary" size="sm">
              {viewPasswordContent === true ? 'Hide Passwords' : 'Show Passwords'}
            </Button>

            <Form.Group className="mb-3" controlId="oldPassword">
              <Form.Label>Old Password</Form.Label>
              <Form.Control
                type={viewPasswordContent === true ? 'text' : 'password'}
                value={formData.oldPassword}
                name="oldPassword"
                onChange={handleChange}
                required
              />
            </Form.Group>

            <hr />

            <Form.Group className="mb-3" controlId="newPassword">
              <Form.Label>New Password</Form.Label>
              <Form.Control
                type={viewPasswordContent === true ? 'text' : 'password'}
                value={formData.newPassword}
                name="newPassword"
                onChange={handleChange}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="confirmPassword">
              <Form.Label>Confirm Password</Form.Label>
              <Form.Control
                type={viewPasswordContent === true ? 'text' : 'password'}
                value={formData.confirmPassword}
                name="confirmPassword"
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>
          <Col>
            {
              formData.passwordMatch === false ?
                (
                  <p className="text-danger">Passwords do not match</p>
                )
                :
                (
                  <p className="text-success">Passwords match</p>
                )
            }
            <ul>
              <li className={formData.containsEightCharacters ? 'text-success' : 'text-danger'}>Must contain at least 8 characters</li>
              <li className={formData.containsUpperCase ? 'text-success' : 'text-danger'}>Must contain at least 1 uppercase letter</li>
              <li className={formData.containsLowerCase ? 'text-success' : 'text-danger'}>Must contain at least 1 lowercase letter</li>
              <li className={formData.containsNumber ? 'text-success' : 'text-danger'}>Must contain at least 1 number</li>
              <li className={formData.containsSpecialCharacter ? 'text-success' : 'text-danger'}>Must contain at least 1 special character</li>
            </ul>
          </Col>
        </Row>

        <div className="text-center">
          <Button className="" type="submit" disabled={!formData.formValid}>
            Update Password
          </Button>
          {/* <span className="d-block my-3">OR</span>
          <p>Send the user a <span className={'link-primary cursor-pointer'} onClick={e => { sendPasswordResetEmail(e) }}>password reset email</span>.</p> */}
        </div>
      </Form>

    </div>
  );
};

ChangePassword.propTypes = {
  userId: PropTypes.string,
  setModalShow: PropTypes.func
}

export default ChangePassword;
