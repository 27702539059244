import { AuthWizardContext } from 'context/Context';
import PropTypes from 'prop-types';
import React, { useState, useContext } from 'react';
import { useEffect } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';

const Success = ({ handleSubmitForm, ...props } = {}) => {
  const { user, setStep, setUser } = useContext(AuthWizardContext);

  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const submitHandleSubmitForm = async () => {
    setIsSubmitting(true);
    toast.info('Submitting...');
    await handleSubmitForm();
    setIsSubmitting(false);
  }

  useEffect(() => {
    console.log(user); // @todo - for debugging purposes
  }, [user]);

  return (
    <>
      <Row>
        <Col className="text-center">
          {/* <div className="wizard-lottie-wrapper">
            <div className="wizard-lottie mx-auto">
              <Lottie animationData={celebration} loop={true} />
            </div>
          </div> */}
          <h4 className="mb-1">Ready to create your account?</h4>
          <p className="fs-0">You're now ready to create your account and login using the email that received the access code and the password you set.</p>
          <Button
            color="primary"
            disabled={isSubmitting}
            className="px-5 my-3"
            onClick={submitHandleSubmitForm}>
            Create Account
          </Button>
        </Col>
      </Row>
    </>
  );
};

Success.propTypes = {
  handleSubmitForm: PropTypes.func.isRequired
};

export default Success;
