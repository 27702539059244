import React, { useState, useEffect, useContext } from 'react';
import { Button, Modal } from 'react-bootstrap';
import GenericFormView from './GenericFormView';
import { myFormsAPI } from 'utils/api/fulcrum-api';
import { toast } from 'react-toastify';
import { sleep } from 'utils/functions';


const PreviewFormModal = ({ modalState, setModalState, content, setContent = () => { }, withRender = true, clientDataArray = [] }) => {
	const [loading, setLoading] = useState(true);
	const [renderTheContent, setRenderTheContent] = useState(true);

	const [renderedContent, setRenderedContent] = useState(content);
	const [selectedClaimantIndex, setSelectedClaimantIndex] = useState(0);

	// Get preview form content
	const getPreviewFromContent = async () => {
		try {
			if (renderTheContent) {
				let result = await myFormsAPI.getRenderedMyFormContent(content, { claimant_id: clientDataArray[selectedClaimantIndex]?.id || null }, clientDataArray[selectedClaimantIndex]?.id ? false : true);
				if (!result?.data?.adjustedContent) { throw new Error(`Error getting My Form content.`); }
				setRenderedContent(result?.data?.adjustedContent);
			} else {
				setRenderedContent(content);
			}
		} catch (error) {
			toast.error('Error getting My Form content.', { theme: 'colored' });
			handleClose();
		}
		setLoading(false);
	};

	// Handle Close
	const handleClose = () => {
		setRenderedContent('');
		setModalState(false);
		setContent('');
	};

	// Handle Show
	const handleShow = async () => {
		setLoading(true);
		await sleep(1000);
		getPreviewFromContent();
		setModalState(true);
	};

	useEffect(async () => {
		if (modalState && !loading) {
			setLoading(true);
			await sleep(1000);
			getPreviewFromContent();
		}
	}, [renderTheContent, selectedClaimantIndex]);

	return (
		<Modal
			show={modalState}
			onHide={handleClose}
			onShow={handleShow}
			size="xl"
			backdrop="static"
			contentClassName="border"
		>
			<Modal.Header closeButton >
				<Modal.Title as="h5">Form Preview</Modal.Title>

			</Modal.Header>
			<Modal.Body>
				{/* <Button className="mb-3" onClick={() => setRenderTheContent(!renderTheContent)} variant="outline-primary" size="sm">{
					renderTheContent ?
						<>
							<span className="ml-1">View Template</span>
						</>
						:
						<>
							<span className="ml-1">View Rendered Preview</span>
						</>
				}</Button> */}
				<div className="pt-3">
					{/* Toggle between clientDataArray index records */}
					{
						clientDataArray.length > 1 ?
							<div className="d-flex justify-content-center align-items-center py-3 bg-300">
								<Button onClick={() => setSelectedClaimantIndex(selectedClaimantIndex - 1)} variant="outline-primary" size="sm" disabled={selectedClaimantIndex === 0}>Previous</Button>

								<span className="fw-bold px-3">{clientDataArray[selectedClaimantIndex]?.first_name} {clientDataArray[selectedClaimantIndex]?.last_name}</span>

								<Button onClick={() => setSelectedClaimantIndex(selectedClaimantIndex + 1)} variant="outline-primary" size="sm" className="ml-2" disabled={selectedClaimantIndex === clientDataArray.length - 1}>Next</Button>
							</div>
							:
							clientDataArray.length === 1 ?
								<div className="d-flex justify-content-center align-items-center  py-3 bg-300">
									<span className="fw-bold px-3">Preview for {clientDataArray[selectedClaimantIndex]?.first_name} {clientDataArray[selectedClaimantIndex]?.last_name}</span>
								</div>
								:
								null
					}

					{
						loading ?
							<div className="d-flex justify-content-center align-items-center">
								<div className="spinner-border text-primary" role="status">
									<span className="sr-only">Loading...</span>
								</div>
							</div>
							:
							<div className="border p-2" style={{ overflowY: "auto", backgroundColor: '#FFFFFF', color: '#000000', height: "500px" }}>
								<GenericFormView content={renderedContent} withRender />
							</div>
					}
				</div>
			</Modal.Body>
		</Modal>
	);
};

export default PreviewFormModal;