import React, { useState } from 'react';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import AddressList from 'components/app/address/AddressList';
import PhoneList from 'components/app/phones/PhoneList';
import ProfileImageUploader from './ProfileImageUploader';
import SendUserRegistrationEmail from './misc/SendUserRegistrationEmail';
// import genericAvatar from 'assets/img/team/avatar.png';
import { toast } from 'react-toastify';
import { userAPI } from 'utils/api/fulcrum-api';
// import avatar from 'assets/img/team/2.jpg';
import moment from 'moment';
import { formatPhone, isValidPhone } from 'utils/functions';
import Background from 'components/common/Background';
import Avatar from 'components/common/Avatar';
// import classNames from 'classnames';
import coverSrc from 'assets/img/generic/4.jpg';
import { Link } from 'react-router-dom';
import CustomFieldList from 'components/custom-fields/custom-field-list/CustomFieldList';
import auth from 'utils/auth';


const UserProfileCard = ({ user, setUser, refreshParent = () => { }, siteAdminArea = false }) => {
  const [, setLoading] = useState(false);
  let defaultState = {
    first_name: user.first_name,
    middle_name: user.middle_name,
    last_name: user.last_name,
    user_status_id: user.user_status_id,
    suffix: user.suffix,
    email: user.email,
    phone: formatPhone(user.phone),
    fax: formatPhone(user.fax),
  };
  const [formData, setFormData] = useState(defaultState);
  const [previousFormData, setPreviousFormData] = useState(defaultState);
  const [isEditing, setIsEditing] = useState(false);
  const [userStatusOptions, setUserStatusOptions] = useState([
    { value: 1, label: 'Active' },
    { value: 2, label: 'Pending Activation' },
    { value: 3, label: 'Inactive' },
    { value: 4, label: 'Pending Password Reset' }
  ]);

  const handleInputChange = (event) => {
    let { name, value } = event.target;
    if (name == "phone" || name == "fax") {
      value = formatPhone(value);
    }
    setFormData({ ...formData, [name]: value });
  };

  // Update User
  const updateUser = async (DataTransfer) => {
    try {
      const result = await userAPI.updateUser(user.id, DataTransfer); // @todo - OMG, what's going on here!?
      if (!result?.data) throw new Error('Error updating user');
      setUser(result.data);
      setPreviousFormData(formData);
      setIsEditing(false);
      // toast.success('User updated', { theme: 'colored' });
    } catch (error) {
      console.log('Unable to update user:', error);
      toast.error('Unable to update user', { theme: 'colored' });
    }
    setLoading(false);
  };

  // Save on change
  const saveOnChange = (ev) => {
    ev.preventDefault();
    let { name, value } = ev.target;
    if (name == "phone" && !isValidPhone(value)) {
      toast.warning('Please enter a valid phone number.', {
        theme: 'colored'
      });
    }
    if (value !== previousFormData[name]) {
      updateUser({ [name]: value });
    }

    setFormData({ ...formData, [name]: value });
  };

  // const handleEdit = () => {
  // 	setIsEditing(true);
  // 	setPreviousFormData(formData);
  // };

  // const handleCancel = () => {
  // 	setIsEditing(false);
  // 	setFormData(previousFormData);
  // };

  // const handleSave = () => {
  // 	setIsEditing(false);
  // 	setPreviousFormData(formData);
  // };



  return (
    <Card className="" >
      <Card.Header
        style={{ minHeight: '15vh!important' }}
        className="position-relative min-vh-25 mb-7"
      // className={classNames("className", 'position-relative min-vh-25 mb-7')}
      >
        <Background image={coverSrc} className="rounded-3 rounded-bottom-0" />
        <div
          style={{ left: '0', right: '0', marginLeft: 'auto', marginRight: 'auto' }}
        >

          <Avatar
            size="5xl"
            className="avatar-profile"
            src={`${user?.profile_picture || "/user_profile_image_default.png"}`}
            mediaClass="img-thumbnail shadow-sm"
            centerAlign={true}
          />

        </div>

      </Card.Header>
      <Card.Body>

        <div className="text-center">
          {/* <img
						src={user?.profile_picture ? `/images/logos/${user.profile_picture}` : genericAvatar}
						alt={'Profile Image'}
						className='border-white shadow text-center mb-3'
						width="175"
						height="175"
						style={{ borderRadius: '50%', objectFit: "cover" }}
					/>
					<br /> */}
          <h4 className="mb-1">{user?.first_name || ""} {user?.last_name || ""}</h4>
          <span>{!user?.assigned_user_types ? 'Unknown' : (user.assigned_user_types.filter(t => t.user_type_id === "REPRESENTATIVE").length > 0 ? 'Representative' : 'Staff')}
            {!user?.assigned_user_types ? 'Unknown' : (user.assigned_user_types.filter(t => t.user_type_id === "ADMIN").length > 0 ? <span className=""> (Admin)</span> : '')}</span>
          <br /><span className="">Account: {siteAdminArea ? <Link to={`/admin/account-details/${user?.account_id}`}>{user?.account?.business_name}</Link> : user?.account?.business_name}</span>

          <div className="">
            <ProfileImageUploader user={user} setUser={setUser} updateUser={updateUser} />
          </div>
        </div>

        <Row>
          <Col sm={12} className="m-0 p-0 text-center">
            <SendUserRegistrationEmail user={user} />
          </Col>
        </Row>


        <Form className="px-3 mt-3">



          {
            user?.id !== auth.getProfile()?.data?.user_id && (
              <Row>
                <Col sm={12} className="m-0 p-0">
                  <Form.Group className="mt-3" controlId="user_status_id">
                    <Form.Label className="fw-bold">Status</Form.Label>
                    <Form.Select
                      size="sm"
                      name="user_status_id"
                      value={formData.user_status_id}
                      onChange={handleInputChange}
                      onBlur={saveOnChange}
                    >
                      {userStatusOptions.map((status, index) => (
                        <option key={index} value={status.value}>{status.label}</option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>
            )
          }

          <Row>
            <Col sm={4} className="m-0 p-0">
              <Form.Group className="mt-3" controlId="formGridCity">
                <Form.Label className="fw-bold">First Name</Form.Label>
                <Form.Control size="sm" name="first_name" value={formData.first_name} onBlur={saveOnChange} onChange={handleInputChange} className={""} />
              </Form.Group>
            </Col>

            <Col sm={2} className="m-0 p-0">
              <Form.Group className="mt-3" controlId="formGridCity">
                <Form.Label className="fw-bold">M.I.</Form.Label>
                <Form.Control size="sm" name="middle_name" value={formData.middle_name} onBlur={saveOnChange} onChange={handleInputChange} className={""} />
              </Form.Group>
            </Col>

            <Col sm={4} className="m-0 p-0">
              <Form.Group className="mt-3" controlId="formGridState">
                <Form.Label className="fw-bold">Last Name</Form.Label>
                <Form.Control size="sm" name="last_name" value={formData.last_name} onBlur={saveOnChange} onChange={handleInputChange} className={""} />
              </Form.Group>
            </Col>
            <Col sm={2} className="m-0 p-0">
              <Form.Group className="mt-3" controlId="formGridZip">
                <Form.Label className="fw-bold">Suffix</Form.Label>
                <Form.Control size="sm" name="suffix" value={formData.suffix} onBlur={saveOnChange} onChange={handleInputChange} className={""} />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col sm={12} className="m-0 p-0">
              <Form.Group className="mt-3" controlId="exampleForm.ControlInput1">
                <Form.Label className="fw-bold">Phone</Form.Label>
                <Form.Control size="sm" type="text" name="phone" onBlur={saveOnChange} onChange={handleInputChange} value={formData.phone} />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col sm={12} className="m-0 p-0">
              <Form.Group className="mt-3" controlId="exampleForm.ControlInput1">
                <Form.Label className="fw-bold">Fax</Form.Label>
                <Form.Control size="sm" type="text" name="fax" onBlur={saveOnChange} onChange={handleInputChange} value={formData.fax} />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col sm={12} className="m-0 p-0">
              <Form.Group className="mt-3" controlId="exampleForm.ControlInput1">
                <Form.Label className="fw-bold">Email</Form.Label>
                <Form.Control size="sm" type="email" name="email" value={user?.email || ""} onBlur={saveOnChange} onChange={handleInputChange} disabled />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col sm={12} className="m-0 p-0">
              <Form.Group className="mt-3" controlId="exampleForm.ControlInput1">
                <Form.Label className="fw-bold">Business</Form.Label>
                <Form.Control size="sm" type="text" name="account_business_name" value={user?.account?.business_name || ""} onBlur={saveOnChange} onChange={handleInputChange} disabled />
              </Form.Group>
            </Col>
          </Row>


        </Form>



        {/* <div className="my-3">
          <AddressList entity_id={user.entity_id} />
        </div>
        <div className="mb-3">
          <PhoneList entity_id={user.entity_id} />
        </div> */}

        <CustomFieldList
          account_id={user.account_id}
          model={"USER"}
          entity_id={user.entity_id}
          view={'USER_DETAILS'}
          options={{}}
        />

        <br />
        <span>User added: {user?.createdAt ? moment(user.createdAt).format('MM/DD/YYYY hh:mm A') : 'N/A'}</span>
        <br />
        <span>Last Login: {user?.last_login_timestamp ? moment(user.last_login_timestamp).format('MM/DD/YYYY hh:mm A') : 'N/A'}</span>

      </Card.Body>
    </Card>
  );
};


export default UserProfileCard;