import React, { useContext, useState, useEffect } from 'react';
import { Card, Button, Col, Row, Form, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { customFieldAPI } from 'utils/api/fulcrum-api';
import { sleep } from 'utils/functions';
import auth from 'utils/auth';

const CustomField = ({ field, account_id = auth.getProfile()?.data?.account_id, model, view, entity_id, refreshParent = () => { } }) => {
	const [formData, setFormData] = useState(null);
	const [fieldType, setFieldType] = useState(field.field_type);
	const [fieldOptions, setFieldOptions] = useState(field.field_options);
	const [fieldInfo, setFieldInfo] = useState(field);
	// Create custom field value record
	const createCustomFieldValue = async (e, value) => {
		if (e) e.preventDefault();
		try {
			let result = await customFieldAPI.createCustomFieldValue(account_id, entity_id, field.id, {
				[`${fieldType}_value`]: value
			});
			if (!result?.data) {
				throw new Error('Error creating custom field value');
			}
		} catch (error) {
			console.log(error);
		}
	};

	// Update custom field value record
	const updateCustomFieldValue = async (e, value) => {
		if (e) e.preventDefault();
		try {
			let result = await customFieldAPI.updateCustomFieldValue(account_id, entity_id, field.id, field?.custom_field_values?.[0]?.id, {
				[`${fieldType}_value`]: formData
			});
			if (!result?.data) {
				throw new Error('Error creating custom field value');
			}
		} catch (error) {
			console.log(error);
		}
	};

	// Save on blur
	const saveOnBlur = async (e, value) => {
		e.preventDefault();

		// Determine if we have a field value already saved
		if (field?.custom_field_values?.length > 0) {
			await updateCustomFieldValue(e, value);
		} else {
			await createCustomFieldValue(e, value);
		}
		refreshParent();
	};

	// Render custom field
	const renderCustomField = (customField) => {
		let newValue;
		let newFieldType = customField.field_type;
		let newfieldOptions = field?.options ? JSON.parse(field?.options) : [];
		switch (customField.field_type) {
			case 'text':
			case 'string':
				newFieldType = 'text';
				newValue = customField.custom_field_values[0]?.text_value || '';
				break;
			case 'textarea':
				newFieldType = 'text';
				newValue = customField.custom_field_values[0]?.text_value || '';
				break;
			case 'number':
				newFieldType = 'number';
				newValue = customField.custom_field_values[0]?.number_value || '';
				break;
			case 'decimal':
				newFieldType = 'decimal';
				newValue = customField.custom_field_values[0]?.decimal_value || '';
				break;
			case 'date':
				newFieldType = 'date';
				newValue = customField.custom_field_values[0]?.date_value || '';
				break;
			case 'checkbox':
				newFieldType = 'text';
				newValue = customField.custom_field_values[0]?.text_value || null;
				break;
			case 'radio':
				newFieldType = 'text';
				newValue = customField.custom_field_values[0]?.text_value || null;
				break;
			case 'dropdown':
				newFieldType = 'text';
				newValue = customField.custom_field_values[0]?.text_value || null;
				break;
		};
		setFieldType(newFieldType)
		setFieldOptions(newfieldOptions);
		setFormData(newValue);
	};

	// Render
	const render = (customField) => {
		switch (customField.field_type) {
			case 'text':
				return (
					<Form.Group>
						<Form.Label className="fw-bold">{customField.label}</Form.Label>
						<Form.Control
							type="text"
							size="sm"
							name={customField.id}
							value={formData}
							onChange={e => {
								let { value } = e.target;
								let length = value.length;
								if (length < 200) {
									setFormData(value)
								}
							}}
							onBlur={e => saveOnBlur(e, formData)}
						/>
					</Form.Group>
				)
				break;
			case 'textarea':
				return (
					<Form.Group>
						<Form.Label className="fw-bold">{customField.label}</Form.Label>
						<Form.Control
							as="textarea"
							rows={3}
							size="sm"
							name={customField.id}
							value={formData}
							onChange={e => {
								let { value } = e.target;
								let length = value.length
								if (length < 25000) {
									setFormData(value)
								}
							}}
							onBlur={e => saveOnBlur(e, formData)}
						/>
					</Form.Group>
				)
			case 'number':
				return (
					<Form.Group>
						<Form.Label className="fw-bold">{customField.label}</Form.Label>
						<Form.Control
							type="number"
							size="sm"
							name={customField.id}
							value={formData}
							onChange={e => {
								let { value } = e.target;

								// Make sure number is within integer range
								if (value > 2147483647) {
									value = 2147483647;
								} else if (value < -2147483648) {
									value = -2147483648;
								}

								setFormData(value)
							}}
							onBlur={e => saveOnBlur(e, formData)}
						/>
					</Form.Group>
				);
			case 'decimal':
				return (
					<Form.Group>
						<Form.Label className="fw-bold">{customField.label}</Form.Label>
						<Form.Control
							type="number"
							name={customField.id}
							value={formData}
							size="sm"
							onChange={e => {
								let { value } = e.target;
								// Make sure number is within decimal range (10, 2)
								if (value > 99999999.99) {
									value = 99999999.99;
								} else if (value < -99999999.99) {
									value = -99999999.99;
								}

								setFormData(value)
							}}
							onBlur={e => saveOnBlur(e, formData)}
						/>
					</Form.Group>
				);
			case 'date':
				return (
					<Form.Group>
						<Form.Label className="fw-bold">{customField.label}</Form.Label>
						<Form.Control
							type="date"
							name={customField.id}
							value={formData}
							size="sm"
							onChange={e => setFormData(e.target.value)}
							onBlur={e => saveOnBlur(e, formData)}
						/>
					</Form.Group>
				);
			case 'radio':
				return (
					<Form.Group>
						<Form.Label className="fw-bold">{customField.label}</Form.Label> &nbsp;
						<br />
						{
							Array.isArray(fieldOptions) && fieldOptions.map((option, index) => {
								return (
									<Form.Check
										type="radio"
										inline
										size="sm"
										name={customField.id}
										value={option?.value || `option_${index}`}
										label={option?.name || ''}
										checked={formData === option?.value || false}
										id={`${customField.id}_${index}_index_radio`}
										onChange={async e => {
											setFormData(e.target.value);
											// leave focus
											await sleep(200);
											e.target.blur();
										}}
										onBlur={e => saveOnBlur(e, formData)}
									/>
								)
							})
						}
					</Form.Group>
				);
			case 'checkbox':
				return (
					<Form.Group>
						<Form.Label className="fw-bold">{customField.label}</Form.Label> &nbsp;
						<br />
						{
							Array.isArray(fieldOptions) && fieldOptions.map((option, index) => {
								return (
									<Form.Check
										type="checkbox"
										inline
										size="sm"
										name={customField.id}
										value={option?.value || `option_${index}`}
										label={option?.name || ''}
										checked={(option?.value && formData) ? formData.includes(option.value) : false}
										id={`${customField.id}_${index}_index_checkbox`}
										onChange={async e => {
											let valueList = formData ? formData.split('|~--~|') : [];
											let item = e.target.value;
											if (e.target.checked) {
												// Add item to formData and make sure it's unique
												if (!valueList.includes(item)) {
													valueList.push(item);
												}
											} else {
												// remove value from formData
												valueList = valueList.filter(value => value !== item);
											}

											// Update formData
											let newValue = valueList.join('|~--~|');
											setFormData(newValue);
											// leave focus
											await sleep(200);
											e.target.blur();
										}}
										onBlur={e => saveOnBlur(e)}
									/>
								)
							})
						}
					</Form.Group>
				);
			case 'dropdown':
				return (
					<Form.Group>
						<Form.Label className="fw-bold">{customField.label}</Form.Label>
						<Form.Select
							name={customField.id}
							size="sm"
							value={formData}
							onChange={e => setFormData(e.target.value)}
							onBlur={e => saveOnBlur(e, formData)}
						>
							<option value={null}></option>
							{
								Array.isArray(fieldOptions) && fieldOptions.filter(o => o.enabled).map((option, index) => {
									return (
										<option key={index} value={option?.value || null}>{option?.name || option?.value || 'Unknown'}</option>
									)
								})
							}
						</Form.Select>
					</Form.Group>
				);
		};
	};

	// On First Load
	useEffect(() => {
		renderCustomField(field);
	}, []);


	return (
		<div>
			{render(field)}
		</div>
	);
};


export default CustomField;