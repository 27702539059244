import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, Form } from 'react-bootstrap';
import { claimantAPI } from 'utils/api/fulcrum-api';
import { toast } from 'react-toastify';
import Select from 'react-select';
import AppContext from 'context/Context';

const SendSSAForms = ({ claimant }) => {
	const {
		config: { isDark }
	} = useContext(AppContext);
	const [modalState, setModalState] = useState(false);
	const [selectedDocuments, setSelectedDocuments] = useState(null);
	const [availableDocuments, setAvailableDocuments] = useState([
		{ value: "SSA-1696", label: 'Claimant’s Appointment of Representative (Form SSA-1696)' },
		{ value: "SSA 827", label: 'Authorization to Disclose Information to the Social Security Administration (SSA) (Form SSA 827)' },
		{ value: "SSA-3288", label: 'Consent for Release of Information (Form SSA-3288)' },
		{ value: "SSA-3368-BK", label: 'Disability Report – Adult (Form SSA-3368-BK)' },
		{ value: "SSA-3820-BK", label: 'Disability Report – Child (Form SSA-3820-BK)' },
		{ value: "HA-4631", label: 'Claimant’s Recent Medical Treatment (Form HA-4631)' },
		{ value: "HA-4632", label: 'Claimant’s Medications (Form HA-4632)' },
		{ value: "HA-4633", label: 'Claimant’s Work Background (Form HA-4633)' },
		{ value: "SSA-821", label: 'Work Activity Report – Employee (Form SSA-821)' },
		{ value: "SSA-820", label: 'Work Activity Report – Self-Employment (Form SSA-820)' },
		{ value: "SSA-3373-BK", label: 'Function Report – Adult (Form SSA-3373-BK)' },
		{ value: "SSA-3380-BK", label: 'Function Report – Adult – Third Part Form (Form SSA-3380-BK)' },
		{ value: "SSA-3375-BK", label: 'Function Report – Child Birth to 1st Birthday (Form SSA-3375-BK)' },
		{ value: "SSA-3376-BK", label: 'Function Report – Child Age 1 to 3rd Birthday (Form SSA-3376-BK)' },
		{ value: "SSA-3377-BK", label: 'Function Report – Child Age 3 to 6th Birthday (Form SSA-3377-BK)' },
		{ value: "SSA-3378-BK", label: 'Function Report – Child Age 6 to 12th Birthday (Form SSA-3378-BK)' },
		{ value: "SSA-3379-BK", label: 'Function Report – Child Age 12 to 18th Birthday (Form SSA-3379-BK)' },
		{ value: "SSA-3381-BK", label: 'Questionnaire for Children Claiming SSI Benefits (Form SSA-3381-BK)' },
		{ value: "SSA-5665-BK", label: 'Teacher Questionnaire (Form SSA-5665-BK)' },
		{ value: "HA-539", label: 'Notice Regarding Substitution of Party upon Death of Claimant (Form HA-539)' },
	]);
	const [sending, setSending] = useState(false);
	let defaultFormState = {
		mode_of_transmission: null
	};
	const [formData, setFormData] = useState(defaultFormState);

	// Handle click
	const handleClick = async () => {
		try {
			let result = await claimantAPI.sendClaimantSSADocuments(claimant.id, {
				documents: selectedDocuments.map(doc => doc.value),
				mode_of_transmission: formData.mode_of_transmission
			});
			console.log(result);
			handleClose();
		} catch (error) {
			console.log(error);
			toast.error('Error sending documents', { theme: 'colored' });
		}
	};

	// Handle modal close
	const handleClose = () => {
		setModalState(false);
		setSending(false);
		setFormData(defaultFormState);
		setSelectedDocuments(null);
	};

	return (
		<>
			<Button variant="falcon-primary" onClick={() => { setModalState(true) }}>Send SSA Forms</Button>


			<Modal
				show={modalState}
				onHide={handleClose}
				contentClassName="border"
			>
				<Modal.Header
					closeButton
					closeVariant={isDark ? 'white' : undefined}
					className="bg-light px-card border-bottom-0"
				>
					<Modal.Title as="h5">Send Retainer Packet</Modal.Title>
				</Modal.Header>
				<Modal.Body className="p-card">
					<span>Select from the list of documents:</span>
					<Select
						closeMenuOnSelect={false}
						options={availableDocuments}
						placeholder='Select documents...'
						isMulti
						classNamePrefix="react-select"
						value={selectedDocuments}
						onChange={value => setSelectedDocuments(value)}
					/>

					{
						selectedDocuments && selectedDocuments.length > 0 && (
							<>
								<hr />
								<p>Send the claimant a retainer packet. Please select one of the following:</p>

								<Form>
									<Form.Group controlId="formBasicCheckbox">
										<Form.Check
											type="radio"
											label="Send as an email"
											checked={formData.mode_of_transmission === 'email'}
											name="mode_of_transmission"
											id="email"
											onChange={(e) => {
												setFormData({ ...formData, [e.target.name]: e.target.id });
											}}
										/>
										<Form.Check
											type="radio"
											label="Send as a SMS text message"
											checked={formData.mode_of_transmission === 'sms'}
											name="mode_of_transmission"
											id="sms"
											onChange={(e) => {
												setFormData({ ...formData, [e.target.name]: e.target.id });
											}}
										/>
										<Form.Check
											type="radio"
											label="Send by regular mail"
											checked={formData.mode_of_transmission === 'mail'}
											name="mode_of_transmission"
											id="mail"
											onChange={(e) => {
												setFormData({ ...formData, [e.target.name]: e.target.id });
											}}
										/>
									</Form.Group>
								</Form>
							</>
						)
					}


				</Modal.Body>
				<Modal.Footer className="border-top-0">
					{
						(selectedDocuments && selectedDocuments.length > 0 && formData.mode_of_transmission) && (
							<Button
								variant="falcon-primary"
								onClick={handleClick}
								disabled={sending}
							>
								{sending ? 'Sending...' : 'Send'}
							</Button>
						)
					}
				</Modal.Footer>
			</Modal>
		</>
	);
};

export default SendSSAForms;