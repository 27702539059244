import React, { useEffect, useState, useContext } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import PermissionDenied from '../components/errors/PermissionDenied';
import Auth from 'utils/auth';
import { AuthContext } from 'context/Context';

const AdminLayout = () => {
	const { pathname } = useLocation();
	const [verifyingAdmin, setVerifyingAdmin] = useState(true);
	const [verified, setVerified] = useState(false);

	const { permissionsState } = useContext(AuthContext);

	const verifyAccountAdmin = async () => {
		// Check that user has admin access
		if (permissionsState?.permissions && permissionsState.permissions.includes('ADMIN_AREA_ACCESS')) {
			setVerified(true);
		} else {
			setVerified(false);
		}
		setVerifyingAdmin(false);
	};

	useEffect(() => {
		verifyAccountAdmin();
		setVerifyingAdmin(false);
	}, [pathname]);

	if (verifyingAdmin) {
		return (<p>Verifying admin...</p>);
	}

	return (
		<div className="container-fluid">
			{
				verified
					?
					<Outlet />
					:
					<PermissionDenied
						title="Permission Denied"
						subtitle="You are not authorized to access this page."
					/>
			}
		</div>
	);
};

export default AdminLayout;
