import React from 'react';
// import TaskMetrics from 'components/task/TaskMetrics';
import TaskTable from 'components/task/TaskTable';
import Flex from 'components/common/Flex';
import { Card, Row, Col, Button } from 'react-bootstrap';
import Auth from 'utils/auth';
import { Link } from 'react-router-dom';

const TaskManagement = () => {
	return (
		<div>
			<Card>
				<Card.Body className="shadow-none">
					<TaskTable
						requiredSearchParams={{}}
						defaultSearchParams={{}}
						siteAdminArea={true}
						columns={[
							{
								accessor: 'business_name',
								Header: 'Account',
								headerProps: { className: 'pe-1' },
								cellProps: {
									className: 'py-2'
								},
								Cell: rowData => {
									const { account = {} } = rowData.row.original;
									return <Link to={`/admin/account-details/${account?.id || null}`}>{account?.business_name || ''}</Link>;
								},
							},
							{
								accessor: 'task_type',
								Header: 'Type',
								headerProps: { className: 'pe-1' },
								cellProps: {
									className: 'py-2'
								},
							},
							{
								accessor: 'due_date_text_only',
								Header: 'Due Date',
								headerProps: { className: 'pe-1' },
								cellProps: {
									className: 'py-2'
								}
							},
							{
								accessor: 'claimant_name',
								Header: 'Claimant',
								headerProps: { className: 'pe-1' },
								cellProps: {
									className: 'py-2'
								}
							},
							{
								accessor: 'title',
								Header: 'Title',
								headerProps: { className: 'pe-1' },
								cellProps: {
									className: 'py-2'
								}
							},
							{
								accessor: 'task_status_text_only',
								Header: 'Status',
								headerProps: { className: 'pe-1' },
								cellProps: {
									className: 'py-2'
								}
							},
							{
								accessor: 'assigned_users',
								Header: 'Users',
								headerProps: { className: 'pe-1' },
								cellProps: {
									className: 'py-2'
								}
							},
							{
								accessor: 'created_at',
								Header: 'Task Set',
								headerProps: { className: 'pe-1' },
								cellProps: {
									className: 'py-2'
								}
							},
							{
								accessor: 'view',
								Header: '',
								headerProps: { className: 'pe-1' },
								cellProps: {
									className: 'py-2'
								}
							},
						]}
					/>
				</Card.Body>
			</Card>
		</div>
	);
};

export default TaskManagement;