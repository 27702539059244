import React, { useState } from 'react';
import { Tabs, Tab, Card, Col, Row, Button } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import CaseFiles from './case-files';
import UploadRequests from './uploader';
import StatusReports from './status-reports';
import ViewAttorneys from '../attorneys/ViewAttorneys';
import AssureCase from 'components/pages/claimant-folders/claimant-profile/case/AssureCase';
import auth from 'utils/auth';

const Atlasware = ({ account_id = auth.getProfile()?.data?.account_id || null, claimant = {}, setClaimant = () => { }, selectedCase, setSelectedCase = () => { }, showPageHeader = true, refreshParent = () => { } }) => {
	const [attorneyModalState, setAttorneyModalState] = useState(false);
	const [attorneyList, setAttorneyList] = useState([]);

	// Get Attorney List
	const getAttorneyList = async () => {
		try {
			let result = await assureAPI.listEreCredentials(account_id || null);
			if (!result?.data?.data) {
				throw new Error("Unable to retrieve a list of attorney credentials.");
			}
			setAttorneyList(Array.isArray(result.data.data) ? result.data.data : [])
		} catch (error) {
			toast.error("Unbale to retrieve a list of attorney credentials.", { theme: 'colored' });
		}
	};

	return (
		<>
			{
				showPageHeader &&
				<Card className="bg-100  border mb-3 bg-line-chart-gradient text-white">
					<Card.Body>
						<Row className=" justify-content-between pr-0">
							<Col sm="auto" className="pr-0 align-middle">
								<Flex alignItems="center">
									<h4 className="align-middle fw-bold mb-0 h-inline">
										<span className=" text-white fw-medium">Atlasware</span>
									</h4>
								</Flex>
							</Col>
						</Row>
					</Card.Body>
				</Card>
			}


			{/* <Button onClick={() => setAttorneyModalState(true)}>View Attorneys</Button> */}
			<Tabs defaultActiveKey="ere-case-files" id="uncontrolled-tab-example">
				<Tab eventKey="ere-case-files" title="Case File Requests" className='border-bottom border-x '>
					{
						claimant?.id ?
							<div className="my-4 px-2">
								<AssureCase
									claimant={claimant}
									setClaimant={setClaimant}
									selectedCase={selectedCase}
									setSelectedCase={setSelectedCase}
									displayAtlaswareContent={true}
									refreshParent={refreshParent}
								/>
							</div>
							:
							<CaseFiles account_id={account_id} claimant={claimant} attorneyList={attorneyList} />
					}
				</Tab>
				<Tab eventKey="ere-uploader" title="ERE Uploader" className='border-bottom border-x'>
					<UploadRequests account_id={account_id} claimant={claimant} />
				</Tab>
				<Tab eventKey="ere-status-report" title="ERE Status Report" className='border-bottom border-x p-3'>
					<StatusReports account_id={account_id} claimant={claimant} />
				</Tab>
			</Tabs>

			<ViewAttorneys account_id={account_id} modalState={attorneyModalState} setModalState={setAttorneyModalState} />
		</>
	);
};

export default Atlasware;