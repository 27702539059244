import React, { useState, useEffect } from 'react';
import { Col, Form, Button, Row } from 'react-bootstrap';

const CustomFieldFilters = ({ searchParams, setSearchParams = () => { }, fields = [], clearSearchParams = () => { } } = {}) => {
	let defaultFilters = {
		model: searchParams?.model || "",
		label: searchParams?.label || null,
		description: searchParams?.description || null,
		enabled: searchParams?.enabled || null,
		field_type: searchParams?.field_type || null,
		views: searchParams?.views || null,
	};
	const [formData, setFormData] = useState(defaultFilters);

	// Clear Filters
	const clearFilters = () => {
		setFormData({
			model: "",
			label: "",
			description: "",
			enabled: "",
			field_type: "",
			views: "",
		});
		clearSearchParams();
	};

	const onSubmit = async (e) => {
		e.preventDefault();
		setSearchParams(formData);
	};

	useEffect(() => {
		setFormData({
			model: searchParams?.model || "",
			label: searchParams?.label || null,
			description: searchParams?.description || null,
			enabled: searchParams?.enabled || null,
			field_type: searchParams?.field_type || null,
			views: searchParams?.views || null,
		});
	}, [searchParams]);

	return (
		<Form noValidate onSubmit={onSubmit} className="row flex-between-center px-2 py-3 my-3 border bg-100 mx-2">
			<Row>
				<Col>
					<h5>Filters</h5>
				</Col>
			</Row>

			<Row>
				{
					fields.includes('model') &&
					<Col>
						{/* <Form.Group controlId="model">
							<Form.Label>Context</Form.Label>
							<Form.Control
								className={`${searchParams?.model ? "bg-soft-info" : ""}`}
								size="sm"
								type="text"
								placeholder="Context"
								name="model"
								value={formData.model}
								onChange={(e) => setFormData({ ...formData, model: e.target.value })}
							/>
						</Form.Group> */}


						<Form.Group controlId="model">
							<Form.Label>Context</Form.Label>
							<Form.Select
								className={`${searchParams?.model ? "bg-soft-info" : ""}`}
								size="sm"
								aria-label="Context"
								name="model"
								value={formData.model}
								onChange={(e) => setFormData({ ...formData, model: e.target.value })}
							>
								<option value="">All</option>
								<option value="ACCOUNT">Account</option>
								<option value="CLAIMANT">Claimant</option>
								<option value="CASE">Case</option>
								<option value="USER">User</option>
							</Form.Select>
						</Form.Group>
					</Col>
				}
				{
					fields.includes("field_type") &&
					<Col>
						<Form.Group controlId="field_type">
							<Form.Label>Field Type</Form.Label>
							<Form.Select
								className={`${searchParams?.field_type ? "bg-soft-info" : ""}`}
								size="sm"
								aria-label="Field Type"
								name="field_type"
								value={formData.field_type}
								onChange={(e) =>
									setFormData({ ...formData, field_type: e.target.value })
								}
							>
								<option value="">All</option>
								<option value="text">Text</option>
								<option value="textarea">Textarea</option>
								<option value="number">Number</option>
								<option value="decimal">Decimal</option>
								<option value="date">Date</option>
								<option value="dropdown">Dropdown</option>
								<option value="radio">Radio</option>
								<option value="checkbox">Checkbox</option>
							</Form.Select>
						</Form.Group>
					</Col>
				}

				{
					fields.includes("enabled") &&
					<Col>
						<Form.Group controlId="enabled">
							<Form.Label>Enabled</Form.Label>
							<Form.Select
								className={`${searchParams?.enabled ? "bg-soft-info" : ""}`}
								size="sm"
								aria-label="Enabled"
								name="enabled"
								value={formData.enabled}
								onChange={(e) =>
									setFormData({ ...formData, enabled: e.target.value === "true" ? true : e.target.value === "false" ? false : null })
								}
							>
								<option value="">All</option>
								<option value={true}>Enabled</option>
								<option value={false}>Disabled</option>
							</Form.Select>
						</Form.Group>
					</Col>
				}
			</Row>

			<Row>
				{
					fields.includes("label") &&
					<Col>
						<Form.Group controlId="label">
							<Form.Label>Label</Form.Label>
							<Form.Control
								className={`${searchParams?.label ? "bg-soft-info" : ""}`}
								size="sm"
								type="text"
								placeholder="Label"
								name="label"
								value={formData.label}
								onChange={(e) => setFormData({ ...formData, label: e.target.value })}
							/>
						</Form.Group>
					</Col>
				}

				{
					fields.includes("description") &&
					<Col>
						<Form.Group controlId="description">
							<Form.Label>Description</Form.Label>
							<Form.Control
								className={`${searchParams?.description ? "bg-soft-info" : ""}`}
								size="sm"
								type="text"
								placeholder="Description"
								name="description"
								value={formData.description}
								onChange={(e) => setFormData({ ...formData, description: e.target.value })}
							/>
						</Form.Group>
					</Col>
				}

				{
					fields.includes("views") &&
					<Col>
						<Form.Group controlId="views">
							<Form.Label>Views</Form.Label>
							<Form.Control
								className={`${searchParams?.views ? "bg-soft-info" : ""}`}
								size="sm"
								type="text"
								placeholder="Views"
								name="views"
								value={formData.views}
								onChange={(e) => setFormData({ ...formData, views: e.target.value })}
							/>
						</Form.Group>
					</Col>
				}
			</Row>



			<Row className="mt-3">
				<Col>
					<Button
						variant="outline-primary"
						size="sm"
						type="submit"
						className="me-2"
					>
						Search
					</Button>
					<Button
						variant="outline-danger"
						size="sm"
						onClick={() => { clearFilters() }}
					>
						Clear
					</Button>
				</Col>
			</Row>
		</Form >
	);
};

export default CustomFieldFilters;
