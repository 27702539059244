import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { noteAPI } from 'utils/api/fulcrum-api';
import Auth from 'utils/auth';
import Dropdown from 'react-bootstrap/Dropdown';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import CardDropdown from 'components/common/CardDropdown';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faNote, faComments, faFile, faCalendar, faListCheck } from '@fortawesome/free-solid-svg-icons'

const noteTypes = {
	NOTE: { label: 'Note', icon: "comments" },
	ACTIVITY_LOG: { label: 'Activity Log', icon: "comments" },
	TASK: { label: 'Task', icon: "list-check" },
	DOCUMENT: { label: 'Document', icon: "note-sticky" },
	EVENT: { label: 'Event', icon: "calendar" }
};


const NoteRow = ({ note, notes, setNotes, sortNotes }) => {
	let defaultFormData = {
		note_type: note.note_type,
		pinned: note.pinned,
		note: note.note,
		edited_timestamp: note?.edited_timestamp ? moment(note.edited_timestamp).format('MM/DD/YYYY hh:mm A') : null,
	};
	const [formData, setFormData] = useState(defaultFormData);
	const [previousFormData, setPreviousFormData] = useState(defaultFormData);

	const handleInputChange = (event) => {
		let { name, value } = event.target;
		let additonalFormData = {};

		if (name === 'pinned') {
			value = !formData[name];
		}

		setFormData({ ...formData, ...additonalFormData, [name]: value });
	};

	// Update note
	const updateNote = async (data) => {
		try {
			let result = await noteAPI.updateNote(note.claimant_id, note.id, data);
			if (!result?.data) {
				throw new Error('Unable to update note.');
			}
			let adjustedNot = { ...formData, ...data };
			setFormData(adjustedNot);
			setPreviousFormData(adjustedNot);

			let newNoteList = notes.map((n) => {
				if (n.id === note.id) {
					return result.data;
				} else {
					return n;
				}
			});

			setNotes([]);
			setNotes(newNoteList);
			sortNotes(newNoteList);
		} catch (error) {
			toast.error(error.message, {
				theme: 'colored'
			});
		}
	};

	// Save on change
	const saveOnChange = async (event) => {
		let { name, value } = event.target;

		if (previousFormData[name] !== value) {
			updateNote({ [name]: value });
		}
	};

	// Delete Note
	const deleteNote = async (noteId) => {
		Swal.fire({
			title: 'Permanently delete note?',
			text: "You won't be able to revert this!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Yes, delete it!'
		}).then(async (result) => {
			if (result.isConfirmed) {
				try {
					let result = await noteAPI.deleteNote(note.claimant_id, note.id);
					if (!result?.message) {
						throw new Error('Unable to delete note.');
					}
					setNotes([...notes.filter(a => a.id !== noteId)]);
					toast.warning(result.message, {
						theme: 'colored'
					});
				} catch (error) {
					toast.error(error.message, {
						theme: 'colored'
					});
				}
			}
		});
	};

	// Toggle pinned note
	const togglePinnedNote = async () => {
		let data = {
			pinned: !formData.pinned
		};
		updateNote(data);
	};


	return (
		<Form className="py-3">
			{/* <Row>
				<Col>
					<div className="d-flex justify-content-between float-end">
						<CardDropdown>
							<div className="py-2">
								{
									!formData.pinned
										?
										<Dropdown.Item onClick={() => togglePinnedNote()}>Pin Note</Dropdown.Item>
										:
										<Dropdown.Item onClick={() => togglePinnedNote()}>Unpin Note</Dropdown.Item>
								}
								<Dropdown.Divider></Dropdown.Divider>
								<Dropdown.Item onClick={() => { deleteNote(note.id) }} className="text-danger">Delete</Dropdown.Item>
							</div>
						</CardDropdown>
					</div>
				</Col>
			</Row> */}
			<div className="">

				<div className={`${formData.pinned && 'text-warning'} d-flex justify-content-between mb-2`}>

					<div style={{ width: "100%" }}>
						<div className="d-inline">
							{
								formData.pinned &&
								<>
									<FontAwesomeIcon icon="thumbtack" className="text-warning" />
									{" "}
								</>
							}
							<span className='pr-3'>
								<FontAwesomeIcon icon={(note?.note_type && note.note_type in noteTypes) ? noteTypes[note.note_type].icon : "info"} />
							</span>
							{" "}

							{note?.created_by_id
								?
								<span>{note?.created_by?.first_name} {note?.created_by?.last_name}</span>
								:
								<span>System</span>
							}
							{" - "}
							{moment(note.createdAt).format('MM/DD/YYYY hh:mm A')}
						</div>

						<div className="d-flex justify-content-between float-end">
							<CardDropdown>
								<div className="py-2">
									{
										!formData.pinned
											?
											<Dropdown.Item onClick={() => togglePinnedNote()}>Pin Note</Dropdown.Item>
											:
											<Dropdown.Item onClick={() => togglePinnedNote()}>Unpin Note</Dropdown.Item>
									}
									{/* <Dropdown.Divider></Dropdown.Divider> */}
									{/* <Dropdown.Item onClick={() => { deleteNote(note.id) }} className="text-danger">Delete</Dropdown.Item> */}
								</div>
							</CardDropdown>
						</div>

						{/* {note?.edited_timestamp &&
							<span>Edited: {moment(note.edited_timestamp).format('MM/DD/YYYY hh:mm A')}</span>
						} */}


					</div>
				</div>


				<Form.Group controlId="note">
					<Form.Control
						as="textarea"
						rows={4}
						name="note"
						value={formData.note}
						onChange={e => { handleInputChange(e); }}
						onBlur={e => { saveOnChange(e); }}
						className={`mx-0 ${formData.note !== previousFormData.note && 'border border-primary'}`}
						disabled={Auth.getProfile().data.user_id !== note.created_by_id}
					/>
				</Form.Group>
			</div>
		</Form>
	);
};

NoteRow.propTypes = {
	note: PropTypes.object,
	notes: PropTypes.array,
	setNotes: PropTypes.func,
	sortNotes: PropTypes.func
}

export default NoteRow;