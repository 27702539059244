import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Dropdown from 'react-bootstrap/Dropdown';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import CardDropdown from 'components/common/CardDropdown';
import ListGroup from 'react-bootstrap/ListGroup';
import IconButton from 'components/common/IconButton';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';

const Employers = ({ claimant, saveClaimant }) => {
	const [employers, setEmployers] = useState((claimant?.employers) ? JSON.parse(claimant.employers) : []);

	// Prepare the employers array to save
	const prepareEmployers = (employersArray) => {
		let adjustedEmployers = JSON.stringify(employersArray);

		if (claimant.employers !== adjustedEmployers) {
			saveClaimant({ employers: adjustedEmployers });
		}
	};

	// Update a employer
	const updateCurrentEmployer = (index, field, value) => {
		let newEmployers = [...employers];
		newEmployers[index][field] = value;
		setEmployers(newEmployers);
	};

	// Add a new employer
	const addEmployers = () => {
		let newEmployersMember = {
			employer: '',
			job_title: '',
			start_date: null,
			stop_date: null,
			full_time: false,
			job_description: '',
			standing_time: null,
			sitting_time: null,
			lifting: null
		};
		let newEmployers = [...employers, newEmployersMember];
		setEmployers(newEmployers);
		prepareEmployers(newEmployers);
	};

	// Remove an employer
	const removeEmployers = (index) => {
		Swal.fire({
			title: 'Permanently delete this employer record?',
			text: "You won't be able to revert this!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Yes, delete it!'
		}).then(async (result) => {
			if (result.isConfirmed) {
				let newEmployers = [...employers];
				newEmployers.splice(index, 1);
				setEmployers(newEmployers);
				prepareEmployers(newEmployers);
				toast.warning(result.message, {
					theme: 'colored'
				});
			}
		});
	};

	return (
		<div id="claimant-current-employer-container" className="my-3">
			<h5 className="d-inline">Employers</h5>

			<IconButton
				className="me-2 mb-1 float-end"
				variant="falcon-default"
				size="sm"
				icon="plus"
				transform="shrink-3"
				onClick={addEmployers}
			>
				Add
			</IconButton>
			<hr />
			<ListGroup>
				{employers.map((ce, index) => (
					<ListGroup.Item key={index}>
						<Form>
							<Row>
								<Col>
									<div className="d-flex justify-content-between float-end">
										<CardDropdown>
											<div className="py-2">
												<Dropdown.Item onClick={() => { removeEmployers(index) }} className="text-danger">Delete</Dropdown.Item>
											</div>
										</CardDropdown>
									</div>
								</Col>

							</Row>
							<Row>

								<Col sm={6} className={'px-0'}>
									<Form.Group controlId="employer">
										<FloatingLabel
											controlId="employer"
											label="Employer"
											className=""
											size="sm"
										>
											<Form.Control
												size="sm"
												type="text"
												name="employer"
												value={ce.employer}
												onChange={e => { updateCurrentEmployer(index, 'employer', e.target.value) }}
												onBlur={() => prepareEmployers(employers)}
											/>
										</FloatingLabel>
									</Form.Group>
								</Col>
								<Col sm={6} className={'px-0'}>
									<Form.Group controlId="job_title">
										<FloatingLabel
											controlId="job_title"
											label="Job Title"
											className=""
											size="sm"
										>
											<Form.Control
												size="sm"
												type="text"
												name="job_title"
												value={ce.job_title}
												onChange={e => { updateCurrentEmployer(index, 'job_title', e.target.value) }}
												onBlur={() => prepareEmployers(employers)}
											/>
										</FloatingLabel>
									</Form.Group>
								</Col>
							</Row>
							<Row>
								<Col sm={4} className={'px-0'}>
									<Form.Group controlId="start_date">
										<FloatingLabel
											controlId="start_date"
											label="Start Date"
											className=""
											size="sm"
										>
											<Form.Control
												size="sm"
												type="date"
												name="start_date"
												value={ce.start_date}
												onChange={e => { updateCurrentEmployer(index, 'start_date', e.target.value) }}
												onBlur={() => prepareEmployers(employers)}
											/>
										</FloatingLabel>
									</Form.Group>
								</Col>
								<Col sm={4} className={'px-0'}>
									<Form.Group controlId="stop_date">
										<FloatingLabel
											controlId="stop_date"
											label="Stop Date"
											className=""
											size="sm"
										>
											<Form.Control
												size="sm"
												type="date"
												name="stop_date"
												value={ce.stop_date}
												onChange={e => { updateCurrentEmployer(index, 'stop_date', e.target.value) }}
												onBlur={() => prepareEmployers(employers)}
											/>
										</FloatingLabel>
									</Form.Group>
								</Col>
								<Col sm={4} className={'px-0'}>
									<Form.Group controlId="address_type_id">
										<FloatingLabel
											controlId="full_time"
											label="Full-Time?"
											className=""
											size="sm"
										>
											<Form.Select
												size="sm"
												as="select"
												name="full_time"
												value={ce.full_time}
												onChange={e => { updateCurrentEmployer(index, 'full_time', e.target.value) }}
												onBlur={() => prepareEmployers(employers)}
											>
												<option value='true'>Yes</option>
												<option value='false'>No</option>
											</Form.Select>
										</FloatingLabel>
									</Form.Group>
								</Col>
							</Row>
							<Row>
								<Col sm={12} className={'px-0'}>
									<Form.Group controlId="job_description">
										<FloatingLabel
											controlId="job_description"
											label="Job Description"
											className=""
											size="sm"
										>
											<Form.Control
												size="sm"
												type="text"
												name="job_description"
												value={ce.job_description}
												onChange={e => { updateCurrentEmployer(index, 'job_description', e.target.value) }}
												onBlur={() => prepareEmployers(employers)}
											/>
										</FloatingLabel>
									</Form.Group>
								</Col>
							</Row>
							<Row>

								<Col sm={4} className={'px-0'}>
									<Form.Group controlId="standing_time">
										<FloatingLabel
											controlId="standing_time"
											label="Standing Time"
											className=""
											size="sm"
										>
											<Form.Control
												size="sm"
												type="text"
												name="standing_time"
												value={ce.standing_time}
												onChange={e => { updateCurrentEmployer(index, 'standing_time', e.target.value) }}
												onBlur={() => prepareEmployers(employers)}
											/>
										</FloatingLabel>
									</Form.Group>
								</Col>

								<Col sm={4} className={'px-0'}>
									<Form.Group controlId="sitting_time">
										<FloatingLabel
											controlId="sitting_time"
											label="Standing Time"
											className=""
											size="sm"
										>
											<Form.Control
												size="sm"
												type="text"
												name="sitting_time"
												value={ce.sitting_time}
												onChange={e => { updateCurrentEmployer(index, 'sitting_time', e.target.value) }}
												onBlur={() => prepareEmployers(employers)}
											/>
										</FloatingLabel>
									</Form.Group>
								</Col>
								<Col sm={4} className={'px-0'}>
									<Form.Group controlId="lifting">
										<FloatingLabel
											controlId="lifting"
											label="Lifting"
											className=""
											size="sm"
										>
											<Form.Control
												size="sm"
												type="text"
												name="lifting"
												value={ce.lifting}
												onChange={e => { updateCurrentEmployer(index, 'lifting', e.target.value) }}
												onBlur={() => prepareEmployers(employers)}
											/>
										</FloatingLabel>
									</Form.Group>
								</Col>
							</Row>
						</Form>
					</ListGroup.Item>
				))}
			</ListGroup>
		</div>
	);
};

Employers.propTypes = {
	claimant: PropTypes.object,
	saveClaimant: PropTypes.func
}

export default Employers;