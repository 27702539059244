import React, { useState } from 'react';
import PropTypes from 'prop-types';
import WizardInput from '../WizardInput';
import { Col, Row, InputGroup } from 'react-bootstrap';
import { stateData } from 'data/stateData';
import { validateEmail } from 'utils/functions';
import { userAPI, accountAPI } from 'utils/api/fulcrum-api';
import Alert from 'react-bootstrap/Alert';

const AccountForm = ({ register, errors, user, setUser, setValue = () => { }, clearErrors = () => { }, setError = () => { } }) => {
  const [emailUnique, setEmailUnique] = useState(null);
  const [systemEmailUnique, setSystemEmailUnique] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [systemEmailAlias, setSystemEmailAlias] = useState(null);

  // Verify email is unique
  const verifyEmail = async (primary_email) => {
    try {
      if (!validateEmail(primary_email)) {
        return setEmailUnique(false);
      }
      let isUniqueResult = await userAPI.verifyEmailAvailability(primary_email);
      if (!isUniqueResult?.data || isUniqueResult.data == false) {
        setEmailUnique(false);
        throw new Error('Email is not unique');
      }
      setEmailUnique(true);
      return true;
    } catch (error) {
      setEmailUnique(false);
      return false;
    }
  };

  // Verify system email is unique
  const verifySystemEmail = async (system_email) => {
    try {
      if (!validateEmail(system_email)) {
        return setSystemEmailUnique(null);
      }
      let isUniqueResult = await accountAPI.verifyAccountSystemEmailAvail(system_email);
      if (!isUniqueResult?.data || isUniqueResult.data == false) {
        setSystemEmailUnique(false);
        throw new Error('Email is not unique');
      }
      setSystemEmailUnique(true);
      return true;
    } catch (error) {
      setSystemEmailUnique(false);

      setError('system_email', {
        type: 'custom',
        message: 'System Email is not unique (not available for use)'
      });
      return false;
    }
  };

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };


  return (
    <>
      <Row className="g-2">
        <WizardInput
          label="First Name*"
          name="first_name"
          errors={errors}
          formGroupProps={{ as: Col, sm: 6, className: 'mb-3' }}
          formControlProps={{
            ...register('first_name', {
              required: 'First name field is required'
            })
          }}
        />
        <WizardInput
          label="Last Name*"
          name="last_name"
          errors={errors}
          formGroupProps={{ as: Col, sm: 6, className: 'mb-3' }}
          formControlProps={{
            ...register('last_name', {
              required: 'Last name field is required'
            })
          }}
        />
      </Row>

      <hr />

      <WizardInput
        label="Business Name*"
        name="business_name"
        errors={errors}
        formGroupProps={{ className: 'mb-3' }}
        formControlProps={{
          ...register('business_name', {
            required: 'Business name field is required',
            onBlur: (e) => {
              e.preventDefault();
              if (!user.system_email) {
                let newEmail = `${e.target.value.replace(/[^a-z0-9]/gi, '')}`.toLowerCase();
                setValue('system_email', newEmail);
                if (verifySystemEmail(`${newEmail}@fulcrum.com`)) {
                  setSystemEmailAlias(newEmail);
                  clearErrors('system_email');
                } else {
                  setSystemEmailAlias(null);
                };
              }
            }
          })
        }}
      />

      <WizardInput
        label="Business Website"
        name="business_website"
        errors={errors}
        formGroupProps={{ className: 'mb-3' }}
        formControlProps={{
          ...register('business_website')
        }}
      />

      <WizardInput
        label="Business Mailing*"
        name="business_mailing_address"
        errors={errors}
        formGroupProps={{ className: 'mb-3' }}
        formControlProps={{
          ...register('business_mailing_address', {
            required: 'Business mailing address field is required'
          })
        }}
      />

      <Row className="g-2 mb-3">
        <WizardInput
          label="City*"
          name="business_mailing_city"
          errors={errors}
          formGroupProps={{ as: Col, sm: 6, className: 'mb-3' }}
          formControlProps={{
            ...register('business_mailing_city', {
              required: 'Business mailing city field is required'
            })
          }}
        />
        <WizardInput
          type='select'
          options={stateData.map(s => s.value)}
          label="State*"
          name="business_mailing_state"
          errors={errors}
          formGroupProps={{ as: Col, sm: 3, className: 'mb-3' }}
          formControlProps={{
            ...register('business_mailing_state', {
              required: 'Business mailing state field is required'
            })
          }}
        />
        <WizardInput
          label="Zip*"
          name="business_mailing_zip"
          errors={errors}
          formGroupProps={{ as: Col, sm: 3, className: 'mb-3' }}
          formControlProps={{
            ...register('business_mailing_zip', {
              required: 'Business mailing zip field is required',
              pattern: {
                value: /^\d{5}(?:[-\s]\d{4})?$/,
                message: 'Invalid zip code'
              }
            })
          }}
        />
      </Row>

      <hr />

      <WizardInput
        type="phone"
        errors={errors}
        label={(<span>Primary Phone*</span>)}
        name="primary_phone"
        formGroupProps={{ className: 'mb-3' }}
        formControlProps={{
          ...register('primary_phone', {
            required: 'Phone field is required',
            placeholder: '###-###-####',
            pattern: {
              value: /^([+]?\d{1,2}[-\s]?|)[-(\s]?\d{3}[-)\s]?[\s]?\d{3}[-\s]?\d{4}$/,
              message: 'Primary phone must be valid'
            }
          })
        }}
      />

      <WizardInput
        type="phone"
        errors={errors}
        label="Secondary Phone"
        name="secondary_phone"
        formGroupProps={{ className: 'mb-3' }}
        formControlProps={{
          ...register('secondary_phone', {
            placeholder: '###-###-####',
            pattern: {
              value: /^([+]?\d{1,2}[-\s]?|)[-(\s]?\d{3}[-)\s]?[\s]?\d{3}[-\s]?\d{4}$/,
              message: 'Secondary phone must be valid'
            }
          })
        }}
      />

      <WizardInput
        type="text"
        errors={errors}
        label="Fax Number"
        name="fax_number"
        formGroupProps={{ className: 'mb-3' }}
        formControlProps={{
          ...register('fax_number', {
            placeholder: '###-###-####',
            pattern: {
              value: /^([+]?\d{1,2}[-\s]?|)[-(\s]?\d{3}[-)\s]?[\s]?\d{3}[-\s]?\d{4}$/,
              message: 'Fax number must be valid'
            }
          })
        }}
      />

      <WizardInput
        type="email"
        errors={errors}
        label={(<span>Primary Email*  <br /><sup className="text-secondary">This email will be used for recieve account notifications</sup></span>)}
        name="primary_email"
        formGroupProps={{ className: 'mb-3' }}
        formControlProps={{
          ...register('primary_email', {
            required: 'Primary email field is required',
            onBlur: (e => { e.preventDefault(); verifyEmail(e.target.value); }),
            pattern: {
              value:
                /[A-Za-z0-9._%+-]{1,}@[a-zA-Z]{1,}([.]{1}[a-zA-Z]{2,}|[.]{1}[a-zA-Z]{2,}[.]{1}[a-zA-Z]{1,})/i,
              message: 'Email must be valid'
            }
          })
        }}
      />
      {
        emailUnique ?
          <Alert
            variant="success"
            className="mb-3"
            dismissible={true}
          >Email is available for use.</Alert>
          : (emailUnique === null ? <></> :
            <Alert
              variant="danger"
              className="mb-3"
              dismissible={true}
            >Email is not unique.</Alert>
          )
      }

      <WizardInput
        type="email"
        errors={errors}
        label="Secondary Email"
        name="secondary_email"
        formGroupProps={{ className: 'mb-3' }}
        formControlProps={{
          ...register('secondary_email', {
            pattern: {
              value:
                /[A-Za-z0-9._%+-]{1,}@[a-zA-Z]{1,}([.]{1}[a-zA-Z]{2,}|[.]{1}[a-zA-Z]{2,}[.]{1}[a-zA-Z]{1,})/i,
              message: 'Email must be valid'
            }
          })
        }}
      />

      <hr />
      <Row>
        <Col sm={8} className="pr-0">
          <WizardInput
            label={(<span>System Email* <br /><sup className="text-secondary">This is the system email your account will use for sending and receiving correspondence.</sup></span>)}
            name="system_email"
            errors={errors}
            formGroupProps={{ className: 'mr-0' }}
            formControlProps={{
              ...register('system_email', {
                required: 'System email field is required',
                "aria-describedby": "basic-addon2",
                onBlur: (e => {
                  e.preventDefault();
                  let newEmail = e.target.value;
                  newEmail = newEmail.replace(/[^a-z0-9]/gi, '').toLowerCase();

                  if (verifySystemEmail(`${newEmail}@fulcrum.com`)) {
                    setSystemEmailAlias(newEmail);
                    clearErrors('system_email');
                    setValue('system_email', newEmail);
                    clearErrors('system_email');
                  } else {
                    setError('system_email', {
                      type: 'custom',
                      message: 'System Email is not unique (not available for use)'
                    });
                    setSystemEmailAlias(null);
                    setValue('system_email', null);
                  }
                }),
              })
            }}
          />
        </Col>
        {/* <InputGroup.Text id="basic-addon2">@example.com</InputGroup.Text> */}

        <Col className="d-flex align-items-end pb-2 pull-">
          <span className="justify-content-center">@fulcrum.com</span>
        </Col>
      </Row>
      <p>

      </p>
      {
        systemEmailUnique ?
          <Alert
            variant="success"
            className="mb-3"
            dismissible={true}
          >System email is available for use.</Alert>
          : (systemEmailUnique === null ? <></> :
            <Alert
              variant="danger"
              className="mb-3"
              dismissible={true}
            >System email is not unique.</Alert>
          )
      }

      {/* <WizardInput
        type="file"
        label="Business Logo (PNG, JPG, JPEG)"
        name="business_logo"
        errors={errors}
        formGroupProps={{ className: 'mb-3' }}
        formControlProps={{
          ...register('business_logo', {
            accept: ".png,.jpg,.jpeg,.webp",
            onChange: async (e) => {
              if (e?.target?.files && e.target.files.length > 0) {
                if (!['image/png', 'image/jpeg', 'image/jpg', 'image/web'].includes(e.target.files[0].type)) {
                  errors.business_logo = { message: 'File must be a png or jpeg' };
                  e.target.value = null;
                  e.target.files = null;
                  setImagePreview(null);
                  alert('File must be a png or jpeg');
                } else {
                  delete errors.business_logo;
                  let base64Logo = await convertToBase64(e.target.files[0]);
                  setUser({ ...user, business_logo_base64: base64Logo, business_logo_name: e.target.files[0].name });
                  setImagePreview(URL.createObjectURL(e.target.files[0]));
                }
              }
            }
          })
        }}
      /> */}

      {
        imagePreview ?
          <>
            <h5>Logo Preview</h5>
            <img id="logo-frame" src={imagePreview} height="125px" />
          </>
          : <></>
      }
    </>
  );
};

AccountForm.propTypes = {
  register: PropTypes.func.isRequired,
  errors: PropTypes.object,
  watch: PropTypes.func,
  user: PropTypes.object,
  setUser: PropTypes.func
};

export default AccountForm;
