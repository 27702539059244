import React, { useState, useEffect, useContext, useRef } from 'react';
import { myFormsAPI, documentAPI, inboxOutboxAPI } from 'utils/api/fulcrum-api';
import { Button, Form, Modal, Table, Alert, Spinner, Row, Col } from 'react-bootstrap';
import { sleep } from 'utils/functions';
import ClaimantQuickFind from 'components/claimants/find-a-claimant/ClaimantQuickFind';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { toast } from 'react-toastify';
import EditorModal from 'components/pages/documents/my-forms/document-generator/EditorModal';
import auth from 'utils/auth';
import moment from 'moment';
import ClaimantDocumentQuickFind from 'components/claimants/claimant-document-quick-find';
import PDFViewerModal from 'components/app/pdfjs-viewer/PDFViewerModal';


const SSACorrespondenceForm = ({ account_id = auth.getProfile()?.data?.account_id || null, refreshParent = () => { }, defaultModeOfTransmission, customMessage, handleClose = () => { }, defaultClaimant = {}, defaultFaxCoversheetId = null, defaultFaxCoversheetContent = '', defaultSelectedDocuments = [] }) => {
	const [submitting, setSubmitting] = useState(false);
	const [claimantDocuments, setClaimantDocuments] = useState([]);
	const [FaxCoversheetModalState, setFaxCoversheetModalState] = useState(false);
	const [selectedClaimantDocument, setSelectedClaimantDocument] = useState(null);
	const [pdfViewerModalState, setPdfViewerModalState] = useState(false);
	const [selectedFileId, setSelectedFileId] = useState(null);
	const [errors, setErrors] = useState([]);
	const [formData, setFormData] = useState({
		mode_of_transmission: null,
		recipient: null,
		claimant_id: defaultClaimant?.id || null,
		claimant: defaultClaimant || {},
		fax_coversheet_my_form_id: defaultFaxCoversheetId || 10,
		fax_coversheet_content: defaultFaxCoversheetContent || '',
		documents: defaultSelectedDocuments || [],
		forms: []
	});

	// Handle input change
	const handleInputChange = (e) => {
		let { name, value } = e.target;

		if (name === 'recipient') {
			// remove all but digits
			value = value.replace(/\D/g, '');
		}

		setFormData({ ...formData, [name]: value });
	};

	// Handle Submit
	const handleSubmit = async (e) => {
		e.preventDefault();
		setSubmitting(true);
		setErrors([]);
		let newErrors = [];

		if (!formData.claimant_id || !formData.claimant?.id || formData.claimant_id !== formData.claimant?.id) {
			newErrors.push('Please select a claimant.');
		}

		switch (formData.mode_of_transmission) {
			case 'FAX':
				if (!formData.recipient) {
					newErrors.push('Please enter a fax number.');
				}

				if (!formData?.documents || !Array.isArray(formData?.documents) || !formData.documents.length) {
					newErrors.push('Please select at least one document.');
				}
				break;
			case 'MAIL':

				break;
			default:

		}

		if (newErrors.length) {
			setErrors(newErrors);
			setSubmitting(false);
			return;
		}

		try {
			let result = await inboxOutboxAPI.submitCorrespondence(account_id, {
				...formData,
				recipient_type: "SSA",
				claimants: [formData.claimant],
			});
			console.log(result);
			if (result?.message !== "Correspondence submitted successfully.") {
				throw new Error('Unable to submit correspondence.');
			}
			toast.success('Correspondence submitted successfully.', { theme: 'colored' });
			setFormData({
				mode_of_transmission: null,
				recipient: null,
				claimant_id: null,
				claimant: {},
				fax_coversheet_my_form_id: null,
				fax_coversheet_content: '',
				documents: [],
				forms: []
			});
			handleClose();
			refreshParent();
		} catch (error) {
			toast.error('Unable to submit correspondence.', { theme: 'colored' });
		}
		setSubmitting(false);
	};

	// Get fax coversheet template
	const getFaxCoversheetTemplate = async (fax_coversheet_my_form_id) => {

		let faxCoversheet = `
			<div class="p-3">
			  <h1><strong><span>Fax</span></strong></h1>\n
			  <table class="table table-bordered" style=\"width: 719px; height: 204px;\">\n 
			  <tbody>
			  <tr>
			  <td><p>To:</p></td>
			  <td><p>{{claimant_first_name}} {{claimant_last_name}}</p></td>
			  <td><p>From:</p></td>
			  <td><p><span>{{account_business_name}}</span></p></td>
			  </tr>
			  <tr>
			  <td><p>Fax:</p></td>
			  <td><p><span>${formData?.recipient}</span></p></td>
			  <td><p>Fax:</p></td>
			  <td><p><span>${formData?.sender}</span></p></td>
			  </tr>
			  <tr>
			  <td><p>Phone:</p></td>
			  <td><p><span>${''}</span></p></td>
			  <td><p>Phone:</p></td>
			  <td><p><span>{{account_primary_phone}}</span></p></td>
			  </tr>
			  <tr><td><p>Subject:</p></td>
			  <td><p><span>&nbsp;</span><br></p><br></td>
			  <td><p>Date:</p></td><td><p><span>{{today}}</span></p></td>
			  </tr>
			  </tbody>
			  </table>
			  </div>
			`;

		if (fax_coversheet_my_form_id) {
			try {
				let result = await myFormsAPI.getRenderedMyFormContentById(fax_coversheet_my_form_id, { claimant_id: formData.claimant_id, account_id: auth.getProfile()?.data?.account_id || null });
				if (!result?.data) {
					throw new Error('Unable to get fax coversheet template.');
				}
				faxCoversheet = result.data.adjustedContent;
			} catch (error) {
			}
		}

		setFormData({ ...formData, fax_coversheet_content: faxCoversheet });
	};

	// Handle document selection
	const handleDocumentSelection = (selectedClaimantDocuments = []) => {
		let approvedDocuments = [];

		let currentDocuments = formData.documents || [];

		for (let i = 0; i < selectedClaimantDocuments.length; i++) {
			let allowDocument = true;
			for (let j = 0; j < currentDocuments.length; j++) {
				if (selectedClaimantDocuments[i].id === currentDocuments[j].id) {
					allowDocument = false
				}
			}
			if (allowDocument) {
				approvedDocuments.push(selectedClaimantDocuments[i]);
			}
		}
		setFormData({ ...formData, documents: [...currentDocuments, ...approvedDocuments] });
	};

	// Get claimant documents
	const getClaimantDocuments = async (claimant_id) => {
		if (claimant_id) {
			try {
				let result = await documentAPI.getClaimantDocuments(claimant_id);
				if (!result?.data) {
					throw new Error('Unable to get claimant documents.');
				}
				setClaimantDocuments(result.data.map((document) => {
					return {
						...document,
						value: document.id,
						label: `${document?.document_type?.type || ''} - ${document.title} - ${document?.createdAt ? moment(document.createdAt).format('MM-DD-YYYY hh:mm A') : ''}`,
					}
				}));
			} catch (error) {
				setClaimantDocuments([]);
				setFormData({ ...formData, documents: null });
				toast.error('Unable to get claimant documents.', { theme: 'colored' });
			}
		}
	};



	// Handle Claimant Selection// Handle Claimant Selection
	const handleClaimantSelection = (selectedClaimants = []) => {
		// Check if the claimant is already in the list
		if (selectedClaimants.length === 1) {
			setFormData({
				...formData,
				claimant_id: selectedClaimants[0].id,
				claimant: selectedClaimants[0],
			});
		} else {
			toast.error('You can only select one claimant', { theme: 'colored' });
		}
	};

	// Handle form selection
	const handleFormSelection = (form) => {
		let newForms = [...formData.forms, { id: Date.now(), ...form, order: formData.forms.length + 1 }];
		setFormData({ ...formData, forms: newForms });
	};

	// Get claimant's office with jurisdiction fax number
	const getClaimantOfficeFaxNumber = async () => {
		let faxNumber = formData?.claimant?.claimant_active_case?.office_with_jurisdiction_fax || null;
		faxNumber = faxNumber?.replace(/\D/g, '');// Remove all but digits

		setFormData({ ...formData, recipient: faxNumber });
		getFaxCoversheetTemplate(formData.fax_coversheet_my_form_id);
	};


	// If the claimant changes, get the claimant's documents and office with jurisdiction fax number
	useEffect(() => {
		if (formData.claimant?.id) {
			console.log('claimant_id changed', formData);
			getClaimantOfficeFaxNumber();
			getClaimantDocuments(formData.claimant.id);
			if (formData.mode_of_transmission === 'FAX') {
				getFaxCoversheetTemplate(formData.fax_coversheet_my_form_id);
			}
		}
	}, [formData.claimant?.id, formData.mode_of_transmission]);


	return (
		<div>
			<Form onSubmit={e => { handleSubmit(e) }}>
				<Alert variant="info">
					<Alert.Heading>SSA Correspondence</Alert.Heading>
					<p>
						Please select the claimant and the documents you would like to send to the SSA. This will send the documents to the Office with Jurisdiction attached to the claimant's active case.
					</p>
				</Alert>
				{
					errors && errors.length > 0 &&
					<Alert variant="danger">
						<Alert.Heading>Errors</Alert.Heading>
						<ul>
							{
								errors.map((error, index) => {
									return <li key={index}>{error}</li>
								})
							}
						</ul>
					</Alert>
				}
				<Form.Group>
					<Form.Label className="fw-bold">Mode of Transmission</Form.Label>
					<br />
					<Form.Check
						type="radio"
						inline
						label="Fax"
						name="mode_of_transmission"
						id="mode_of_transmission_fax"
						value="FAX"
						checked={formData.mode_of_transmission === 'FAX'}
						onChange={e => setFormData({ ...formData, mode_of_transmission: e.target.value })}
						disabled={submitting}
					/>
					<Form.Check
						type="radio"
						inline
						label="Regular Mail"
						name="mode_of_transmission"
						id="mode_of_transmission_mail"
						value="MAIL"
						checked={formData.mode_of_transmission === 'MAIL'}
						onChange={e => setFormData({ ...formData, mode_of_transmission: e.target.value })}
						disabled={submitting}
					/>
				</Form.Group>

				<hr />

				{
					formData.mode_of_transmission && (
						<>
							<span className="fw-bold">Select a claimant: </span>
							<br />
							{
								formData.claimant?.first_name && formData.claimant?.last_name ? (
									<>
										<span className="">Selected Claimant: <span className="text-success fw-bold">{formData.claimant.first_name} {formData.claimant.last_name}</span></span>
										<ClaimantQuickFind
											buttonLabel={'Change'}
											buttonVariant={'link'}
											handleClaimantSelection={handleClaimantSelection}
											requiredSearchParams={{}}
											defaultSearchParams={{}}
											allowMultipleSelect={false}
										/>
									</>
								) : (
									<>
										<span className="text-danger">No claimant selected </span>
										<ClaimantQuickFind
											buttonLabel={'Find a Claimant'}
											buttonVariant={'link'}
											handleClaimantSelection={handleClaimantSelection}
											requiredSearchParams={{}}
											defaultSearchParams={{}}
											allowMultipleSelect={false}
										/>
									</>
								)
							}

							<hr />

							{
								formData.claimant?.id && (
									<>

										{
											formData.mode_of_transmission === 'FAX' && (
												<>
													<span className="fw-bold">Fax Information: </span>
													<br />
													<br />
													{
														!formData?.claimant?.claimant_active_case?.office_with_jurisdiction_fax && (
															<Alert variant="warning">
																Cannot find claimants Office with Jurisdiction's fax number. Please provide a fax number.
															</Alert>
														)
													}
													<Form.Group controlId="recipient">
														<Form.Label className="fw-bold">Fax Number</Form.Label>
														<Form.Control
															type="text"
															placeholder="Enter Fax Number"
															name="recipient"
															value={formData.recipient}
															onChange={handleInputChange}
															disabled={submitting}
														/>
													</Form.Group>

													<br />
													<Form.Group controlId="fax_coversheet_content">
														<Form.Label className="fw-bold">Fax Cover Sheet</Form.Label>
														{/* <Button
															variant="link"
															onClick={() => getFaxCoversheetTemplate(formData.fax_coversheet_my_form_id)}
														>
															Refresh Template
														</Button> */}
														<Button
															variant="link"
															onClick={() => setFaxCoversheetModalState(true)}
														>
															view / Edit
														</Button>
													</Form.Group>

													<EditorModal
														modalState={FaxCoversheetModalState}
														setModalState={setFaxCoversheetModalState}
														content={formData?.fax_coversheet_content || ""}
														handleEditorSave={content => {
															setFormData({
																...formData,
																fax_coversheet_content: content,
															})
														}}
														allowNewFormSave={false}
														allowPreview={false}
														setAllowNewFormSave={false}
														defaultEditorConfig={{
															readonly: false,
														}}
														options={{
															allowNewFormSave: false,
															allowFormSave: true,
														}}
														clientDataArray={[formData.claimant] || []}
													/>

													{/* <FaxCoversheetModal
														defaultFormData={formData.fax_coversheet_content}
														modalState={FaxCoversheetModalState}
														setModalState={setFaxCoversheetModalState}
														refreshParent={() => { }}
													/> */}
													<hr />
												</>
											)
										}


										<p className="fw-bold">Attach Claimant Documents:</p>
										<ClaimantDocumentQuickFind
											buttonLabel={'Browse Claimant Documents'}
											buttonVariant={'falcon-primary'}
											handleDocumentSelection={handleDocumentSelection}
											requiredSearchParams={{}}
											defaultSearchParams={{}}
											allowMultipleSelect={true}
											claimantId={formData.claimant.id}
											claimant={formData.claimant}
										/>



										<PDFViewerModal
											modalState={pdfViewerModalState}
											setModalState={setPdfViewerModalState}
											selectedFileId={selectedFileId}
											setSelectedFileId={setSelectedFileId}
										/>
										<Table striped bordered size="md" className="bg-white mt-3">
											<thead>
												<tr>
													<th>Document Type</th>
													<th>Title</th>
													<th>Created At</th>
													<th>Order</th>
													<th>Actions</th>
												</tr>
											</thead>
											<tbody>
												{
													Array.isArray(formData.documents) && formData.documents
														?
														formData.documents.map((document, index) => (
															<tr key={`selected_doc_${document?.id}`}>
																<td>{document?.document_type_id || ''}</td>
																<td>{document?.title || ''}</td>
																<td>{document?.createdAt ? moment(document.createdAt).format('MM-DD-YYYY hh:mm A') : ''}</td>
																<td>{index + 1}
																</td>
																<td>
																	<Button variant="link" onClick={async () => { setSelectedFileId(document.file_id); await sleep(1000); setPdfViewerModalState(true); }} className="">View</Button>

																	<Button
																		variant="link"
																		className='text-danger'
																		onClick={() => {
																			const newDocuments = formData.documents.filter((_, i) => i !== index);
																			setFormData({ ...formData, documents: newDocuments });
																		}}
																	>
																		<FontAwesomeIcon icon="trash" />
																	</Button>
																</td>
															</tr>
														))
														:
														(
															<tr>
																<td colSpan="4" className='text-danger'>No documents selected</td>
															</tr>
														)

												}
											</tbody>
										</Table>
									</>
								)
							}

						</>
					)
				}


				<Button variant="falcon-primary" type="submit" className="mt-3" disabled={submitting}>
					{submitting ? 'Submitting...' : 'Submit'}
				</Button>

			</Form>
		</div>
	);
};

export default SSACorrespondenceForm;