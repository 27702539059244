import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Card, Button, Form, Accordion, ListGroup, Spinner, Table, ButtonGroup } from 'react-bootstrap';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import ClaimantQuickFind from 'components/claimants/find-a-claimant/ClaimantQuickFind';
import MyFormFinderModal from '../MyFormFinderModal';
import MyFormContentEditor from '../MyFormContentEditorTest';
import { myFormsAPI, documentAPI } from 'utils/api/fulcrum-api';
import { useEffect } from 'react';
import DocumentGeneratorFormTemplateRow from './DocumentGeneratorFormTemplateRow';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom'
import JoditEditor from "jodit-pro-react";
import EditorModal from './EditorModal';
import PDFViewerModal from 'components/app/pdfjs-viewer/PDFViewerModal';
import Swal from 'sweetalert2';
import PDFViewerPreviewModal from 'components/app/pdfjs-viewer/PDFViewerPreviewModal';

const CustomToggle = ({ children, eventKey, forms = [], setCompiledContent = () => { } } = {}) => {
	const decoratedOnClick = useAccordionButton(eventKey)
	return (<Button
		variant='link'
		onClick={() => {
			if (eventKey === '2') {
				setCompiledContent(forms.map(f => f.content).join(''));
			}
			decoratedOnClick()
		}}
	>
		{children}
	</Button>);
}

CustomToggle.propTypes = {
	children: PropTypes.node,
	eventKey: PropTypes.string.isRequired,
	forms: PropTypes.array,
	setCompiledContent: PropTypes.func,
};

const DocumentGeneratorForm = (props) => {
	const editor = useRef(null);
	const navigate = useNavigate();
	const [myFormFinderModal, setMyFormFinderModal] = useState(false);
	const [formEditorModal, setFormEditorModal] = useState(false);
	const [selectedForm, setSelectedForm] = useState(null);
	const [compiledConetent, setCompiledContent] = useState('');
	const [allowNewFormSave, setAllowNewFormSave] = useState(false);
	const [submitting, setSubmitting] = useState(false);

	const [editorModalOptions, setEditorModalOptions] = useState({
		allowNewFormSave: false,
		allowFormSave: true,
	});
	const [pdfViewerModalState, setPdfViewerModalState] = useState(false);
	const [editorConfig, setEditorConfig] = useState({});
	const [formData, setFormData] = useState({
		claimants: [],
		myForms: [],
		content: '',
		combine_to_packet: false,
		packet_title: '',
		document_type_id: '',
		form_type_id: '',
		title: '',
		all_claimants: false
	});

	const [forms, setForms] = useState([]);
	const [documentTypes, setDocumentTypes] = useState([]);

	// Get related data @TODO: Move this to a context
	const getRelatedData = async () => {
		try {
			let docTypeResults = await documentAPI.searchDocumentTypes();
			if (!docTypeResults?.data?.rows) {
				throw new Error('Unable to get documents types.');
			}

			setDocumentTypes(docTypeResults.data.rows);
		} catch (error) {
			alert(error);
		}
	};

	// Handle input change
	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setFormData({
			...formData,
			[name]: value
		});
	};

	// Handle Submit 
	const handleSubmit = async (e) => {
		e.preventDefault();

		const data = {
			documentData: {
				// title: formData.title,
				// document_type_id: formData.document_type_id,
				combine_to_packet: formData.combine_to_packet,
				packet_title: formData.packet_title,
			},
			forms: forms,
			content: compiledConetent,
			claimantIdList: formData.claimants.map(c => c.id),
			options: {
				performDocumentFlatten: true,
				all_claimants: formData.all_claimants
			}
		}

		setSubmitting(true);
		try {
			let result = await myFormsAPI.createDocumentFromMyFormContent(data);
			if (!result?.data) {
				console.log(result)
				alert('ere')
				throw new Error('Error creating document');
			}

			navigate('/my-forms');
			toast.success("Document(s) queued to be genereated.", { theme: 'colored' });
		} catch (error) {
			toast.error("Unable to create document(s)", { theme: 'colored' });
		}
		setSubmitting(false);
	};


	// Handle claimant selection
	const handleClaimantSelection = (newClaimants) => {
		// Check if claimant already exists
		// Loop through newClaimants and make sure their not in the existing claimants

		let newClaimantList = [...formData.claimants];
		for (let i = 0; i < newClaimants.length; i++) {
			let includeNewClaimant = true;

			for (let j = 0; j < formData.claimants.length; j++) {
				if (newClaimants[i].id === formData.claimants[j].id) {
					includeNewClaimant = false;
				}
			}

			if (includeNewClaimant) {
				newClaimantList.push(newClaimants[i]);
			}
		}

		setFormData({
			...formData,
			claimants: newClaimantList
		});
	};

	// Handle edit selected 
	const handleEditSelected = (form) => {
		setSelectedForm(form);
		switch (form.type) {
			case "My Form":
			case "RTF":
				setSelectedForm(form);

				setAllowNewFormSave(false);
				setEditorModalOptions({
					...editorModalOptions,
					allowNewFormSave: false,
					allowFormSave: true
				})
				setEditorConfig({
					readonly: false,
				});
				setFormEditorModal(true);

				break;
			case "PDF":
				setPdfViewerModalState(true);
				break;
			default:
				break;
		}
	};


	// Handle form selection
	const handleFormSelection = (form, file_type = "My Form") => {
		let newForms = [...forms, { id: Date.now(), ...form, order: formData.myForms.length + 1, file_type }];
		setForms(newForms);
		setFormData({
			...formData,

			myForms: [newForms],
			title: !formData?.title ? (form?.title ? form.title : "") : formData.title,
			document_type_id: !formData?.document_type_id ? (form?.form_type_id ? form.form_type_id : "OTHER") : formData.document_type_id,
		});
	};

	// Handle Editor Save
	const handleEditorSave = (content) => {
		let newForms = forms.map((form, index) => {
			if (form.id === selectedForm.id) {
				return {
					...form,
					content
				}
			}
			return form;
		});

		setForms(newForms);
	};

	useEffect(() => {
		getRelatedData()
	}, [])


	return (
		<div {...props}>
			<Accordion defaultActiveKey="0">
				<Card className='mb-2'>
					<Card.Header className="bg-500">
						<CustomToggle eventKey="0" className="float-end"><h4 className="text-white">Step 1) Select at least one claimant</h4></CustomToggle>
					</Card.Header>
					<Accordion.Collapse eventKey="0">
						<Card.Body>
							{/* All active claimants checkbox */}

							<ClaimantQuickFind
								buttonLabel={"Search Claimants"}
								buttonVariant={"falcon-primary"}
								handleClaimantSelection={handleClaimantSelection}
								defaultSearchParams={{}}
								requiredSearchParams={{}}
								skipFirstQuery={true}
							/>

							&ensp;&ensp; {' '}  OR {' '}  &ensp;&ensp;

							<Form.Check
								inline
								type="checkbox"
								label="All active claimants"
								checked={formData.all_claimants}
								onChange={e => {
									setFormData({
										...formData,
										all_claimants: e.target.checked
									})
								}}
							/>


							{
								formData.all_claimants ?
									<p className="text-warning">All active claimants will be associated with this request.</p>
									:
									<>
										{
											formData.claimants.length > 0 ?
												<div className="my-3">
													<ul>
														{
															formData.claimants.map((claimant, index) => (
																<li key={index} className="border-bottom py-3">
																	{claimant?.first_name || ""} {claimant?.last_name || ""}
																	<Button variant="link" onClick={e => {
																		const newClaimants = formData.claimants.filter(c => c.id !== claimant.id);
																		setFormData({
																			...formData,
																			claimants: newClaimants
																		});
																	}} className="float-end text-danger">Remove</Button>
																</li>
															))
														}
													</ul>
												</div>
												: <p className="my-3">No selected claimants.</p>
										}
									</>

							}
						</Card.Body>
					</Accordion.Collapse>
				</Card>
				<Form onSubmit={handleSubmit}>
					<Card className="mb-2">
						<Card.Header className="bg-500">
							<CustomToggle eventKey="1" className="float-end"><h4 className="text-white">Step 2) Select Forms</h4></CustomToggle>
						</Card.Header>
						<Accordion.Collapse eventKey="1">
							<Card.Body>
								<div className="mb-3">
									<Button
										variant="link"
										onClick={() => setMyFormFinderModal(true)}
									>
										Select Form Templates
									</Button>

									{/* Merge forms checkbox */}
									{/* <Form.Check
									type="checkbox"
									label="Merge forms to single document"
									checked={formData.merge_forms}
									onChange={e => {
										setFormData({
											...formData,
											merge_forms: e.target.checked
										});
									}}
								/> */}


									<MyFormFinderModal
										modalState={myFormFinderModal}
										setModalState={setMyFormFinderModal}
										handleFormSelection={handleFormSelection}
									/>
								</div>
								{
									forms.length > 0 ?
										<div>
											<Table bordered size="md" className="bg-white mt-3">
												<thead className="bg-200">
													<tr>
														<th>Type</th>
														<th style={{ width: "50%" }}>Title</th>
														<th>Form Type</th>
														<th>Order</th>
														<th></th>
													</tr>
												</thead>
												<tbody>
													{
														forms.map((form, index) => (
															<tr key={index}>
																<td className="fw-bold">{form.type}</td>
																<td>
																	<Form.Control
																		type="text"
																		name="title"
																		value={form.title}
																		disabled={submitting}
																		onChange={e => {
																			const newForms = [...forms];
																			newForms[index].title = e.target.value;
																			setForms(newForms);
																		}}
																		required
																	/>
																</td>
																<td>
																	<Form.Control
																		as="select"
																		name="form_type_id"
																		value={form.form_type_id}
																		disabled={submitting}
																		onChange={e => {
																			const newForms = [...forms];
																			newForms[index].form_type_id = e.target.value;
																			setForms(newForms);
																		}}
																		required
																	>
																		<option value={""}>--Select One--</option>
																		{Array.isArray(documentTypes) && documentTypes.map((docType, index) => (
																			<option key={index} value={docType.id}>{docType.type}</option>
																		))}
																	</Form.Control>
																</td>
																<td>
																	{/* <span className="fw-bold px-0">{index + 1}</span> */}

																	<ButtonGroup size="lg" className="me-2 mb-1">
																		{
																			index > 0 &&
																			<Button variant="link" disabled={submitting} onClick={e => {
																				const newForms = [...forms];
																				const temp = newForms[index];
																				newForms[index] = newForms[index - 1];
																				newForms[index - 1] = temp;
																				setForms(newForms);
																			}} className="text-primary px-0 mx-1">
																				<FontAwesomeIcon icon="arrow-up" />
																			</Button>
																		}

																		{
																			index < forms.length - 1 &&
																			<Button variant="link" disabled={submitting} onClick={e => {
																				const newForms = [...forms];
																				const temp = newForms[index];
																				newForms[index] = newForms[index + 1];
																				newForms[index + 1] = temp;
																				setForms(newForms);
																			}} className="text-primary px-0  mx-1">
																				<FontAwesomeIcon icon="arrow-down" />
																			</Button>
																		}
																	</ButtonGroup>

																</td>
																<td>
																	<ButtonGroup size="lg" className="me-2 mb-1">

																		{
																			form?.type === 'PDF' ?
																				<Button variant="link" className="px-0 mx-1" disabled={submitting} onClick={e => { handleEditSelected(form) }}>
																					<FontAwesomeIcon icon="eye" />
																				</Button>
																				:
																				<Button variant="link" className="px-0 mx-1" disabled={submitting} onClick={e => { handleEditSelected(form) }}>
																					<FontAwesomeIcon icon="edit" />
																				</Button>
																		}
																		<Button variant="link" className="text-danger px-0 mx-1" disabled={submitting} onClick={e => {
																			Swal.fire({
																				title: 'Remove template?',
																				text: "Are you sure you would like to remove this template?",
																				icon: 'warning',
																				showCancelButton: true,
																				confirmButtonColor: '#d33',
																				cancelButtonColor: '#3085d6',
																				confirmButtonText: 'Remove it!'
																			}).then((result) => {
																				if (result.isConfirmed) {
																					setForms(forms.filter(
																						(f, i) => i !== index
																					))
																				}
																			})
																		}}>
																			<FontAwesomeIcon icon="trash" />
																		</Button>
																	</ButtonGroup>
																</td>
															</tr>
														))
													}
												</tbody>
											</Table>
										</div>
										: <p className="my-3">No form templates selected</p>
								}



							</Card.Body>
						</Accordion.Collapse>
					</Card>

					<Card className='mb-2'>
						<Card.Header className="bg-500">
							<CustomToggle eventKey="2" className="float-end" forms={forms} setCompiledContent={setCompiledContent}><h4 className="text-white">Step 3) Review and Submit</h4></CustomToggle>
						</Card.Header>
						<Accordion.Collapse eventKey="2">
							<Card.Body>
								{/* <Button variant="primary" onClick={e => {
									setSelectedForm({
										title: "",
										content: compiledConetent

									});
									setEditorModalOptions({
										...editorModalOptions,
										allowNewFormSave: true,
										allowFormSave: false
									})
									setEditorConfig({
										readonly: true,
										buttons: [],
										extraButtons: []
									});
									setAllowNewFormSave(true);
									setFormEditorModal(true)
								}
								}>Review</Button> */}

								{/* Combine all documents in a packet checkbox */}
								<Form.Group className="mb-3" controlId="combine_to_packet">
									<Form.Check
										type="checkbox"
										label="Combine all documents in a packet"
										name="combine_to_packet"
										checked={formData.combine_to_packet}
										onChange={e => {
											setFormData({
												...formData,
												combine_to_packet: e.target.checked
											})
										}} />
								</Form.Group>

								{
									formData.combine_to_packet &&
									<div className="">
										<span className="fw-bold">Provide Packet Details</span>
										<Form.Group className="mb-3" controlId="packet_title">
											<Form.Label className="fs-0">Packet Title</Form.Label>
											<Form.Control
												type="text"
												name="packet_title"
												placeholder="Enter Packet Title"
												value={formData.packet_title}
												onChange={handleInputChange}
												required
											/>
										</Form.Group>
									</div>
								}

								{/* <Form.Group className="mb-3" controlId="document_type_id">
									<Form.Label className="fs-0">Document Type</Form.Label>
									<Form.Select name="document_type_id" value={formData.document_type_id} onChange={handleInputChange} required>
										<option value={""}>--Select One--</option>
										{Array.isArray(documentTypes) && documentTypes.map((docType, index) => (
											<option key={index} value={docType.id}>{docType.type}</option>
										))}
									</Form.Select>
								</Form.Group>

								<Form.Group controlId="formBasicEmail">
									<Form.Label>Title</Form.Label>
									<Form.Control
										type="text"
										name="title"
										value={formData.title}
										placeholder="Enter a title"
										onChange={handleInputChange}
										required
									/>
								</Form.Group> */}

								{
									submitting
										?
										<Button variant="primary" type='submit' disabled={submitting}>
											<Spinner
												as="span"
												animation="border"
												size="sm"
												role="status"
												aria-hidden="true"
											/>
											Submitting...
										</Button>
										:
										<Button variant="primary" type='submit' >Submit</Button>
								}
							</Card.Body>
						</Accordion.Collapse>
					</Card>
				</Form>
			</Accordion>

			<PDFViewerModal
				modalState={pdfViewerModalState}
				setModalState={setPdfViewerModalState}
				selectedFileId={1}
				setSelectedFileId={() => { }}
			/>

			<PDFViewerPreviewModal
				modalState={pdfViewerModalState}
				setModalState={setPdfViewerModalState}
				selectedClaimants={formData.claimants || []}
				pdf_template={selectedForm?.content || ""}
			/>

			<EditorModal
				modalState={formEditorModal}
				setModalState={setFormEditorModal}
				content={selectedForm?.content || ""}
				handleEditorSave={handleEditorSave}
				allowNewFormSave={allowNewFormSave}
				allowPreview={true}
				setAllowNewFormSave={setAllowNewFormSave}
				defaultEditorConfig={editorConfig}
				options={editorModalOptions}
				clientDataArray={formData.claimants || []}
			/>
		</div >
	);
};

export default DocumentGeneratorForm;