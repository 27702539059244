import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import AppContext from 'context/Context';
import { Button, Modal, Row, Col, Form } from 'react-bootstrap';
import FindAClaimantForm from './FindAClaimantForm';
import { removeEmptyFromObj } from 'utils/functions';
import { claimantAPI } from 'utils/api/fulcrum-api';
import Table from 'react-bootstrap/Table';
import { toast } from 'react-toastify';
import moment from 'moment';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';

const columns = [
	{
		accessor: 'id',
		Header: 'ID',
		headerProps: { className: 'pe-1' },
		cellProps: {
			className: 'py-2'
		}
	},
	{
		accessor: 'first_name',
		Header: 'First Name',
		headerProps: { className: 'pe-1' },
		cellProps: {
			className: 'py-2'
		}
	},
	{
		accessor: 'last_name',
		Header: 'Last Name',
		headerProps: { className: 'pe-1' },
		cellProps: {
			className: 'py-2'
		}
	},
	{
		accessor: 'ssn',
		Header: 'SSN',
		headerProps: { className: 'pe-1' },
		cellProps: {
			className: 'py-2'
		}
	},
	{
		accessor: 'dob',
		Header: 'DOB',
		headerProps: { className: 'pe-1' },
		cellProps: {
			className: 'py-2'
		}
	},
	{
		accessor: 'action',
		Header: 'Action',
		headerProps: { className: 'pe-1' },
		cellProps: {
			className: 'py-2'
		}
	}
];

function BulAction({ selectedRowIds, handleMultiClaimantSelection = () => { } }) {
	return (
		<Row className="flex-between-center mb-3">
			<Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
				<h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">
					{
						Object.keys(selectedRowIds).length > 0 ?
							'You have selected ' + Object.keys(selectedRowIds).length + ' rows'
							:
							'Select a claimant'
					}
				</h5>
			</Col>
			<Col xs={8} sm="auto" className="ms-auto text-end ps-0">
				{Object.keys(selectedRowIds).length > 0 ? (
					<div className="d-flex">
						<Button
							type="button"
							variant="falcon-primary"
							size="sm"
							className="ms-2"
							onClick={() => {
								console.log('selectedRowIds', selectedRowIds);
								handleMultiClaimantSelection(selectedRowIds);
							}}
						>
							Choose Selected
						</Button>
					</div>
				) : (
					<div id="orders-actions">
						{/* <IconButton
							variant="falcon-default"
							size="sm"
							icon="plus"
							transform="shrink-3"
							className='me-2'
						>
							<span className="d-none d-sm-inline-block ms-1">New</span>
						</IconButton>
						<IconButton
							variant="falcon-default"
							size="sm"
							icon="external-link-alt"
							transform="shrink-3"
						>
							<span className="d-none d-sm-inline-block ms-1">Export</span>
						</IconButton> */}
					</div>
				)}
			</Col>
		</Row>
	);
};

const ClaimantQuickFind = ({ buttonLabel = "Find-A-Cliamant", buttonVariant = "primary", handleClaimantSelection = () => { }, defaultSearchParams = {}, requiredSearchParams = {}, skipFirstQuery = true, allowMultipleSelect = true }) => {

	const {
		config: { isDark }
	} = useContext(AppContext);

	const [quickFindModalState, setQuickFindModalState] = useState(false);
	const [foundClaimants, setFoundClaimants] = useState([]);
	const [claimantCount, setClaimantCount] = useState(0);
	const [searchParams, setSearchParams] = useState({ ...defaultSearchParams, ...requiredSearchParams, });
	const [, setLastQuery] = useState({});
	const [skipFirstSearch, setSkipFirstSearch] = useState(skipFirstQuery);
	const [searching, setSearching] = useState(false);


	const formatData = (data = []) => {
		let adjustedData = data.map(c => {
			return {
				...c,
				action: (
					<Button
						variant="falcon-default"
						size="sm"
						onClick={() => handleClaimantSelect([c])}
					>
						Select
					</Button>
				)
			}
		});
		setFoundClaimants(adjustedData);

		setSearching(false);
	};

	// Search Clients
	const handleSearch = async (params) => {
		try {
			params = removeEmptyFromObj(params);

			let response = await claimantAPI.searchClaimants({ ...params });
			if (!response?.data?.rows) {
				throw new Error('No claimant data found');
			}
			setClaimantCount(response.data.count);
			formatData(response.data.rows);
			setLastQuery(params);
		} catch (error) {
			console.log(error);
		}
		setSearching(false);
	};

	// Apply search filters
	const newSearch = async (params) => {
		params = removeEmptyFromObj(params);
		if (Object.keys(params).length === 0) {
			toast.error("Please enter at least one search parameter.", { theme: 'colored' });
			return;
		}

		setSearching(true);
		let adjustedSearchParams = {
			...params,
			...requiredSearchParams
		};
		setSkipFirstSearch(false);
		handleSearch(adjustedSearchParams);
	};

	// Refresh last query
	// const refreshLastQuery = async () => {
	// 	handleSearch(lastQuery);
	// };

	// Handle modal close
	const handleClose = () => {
		setClaimantCount(0);
		setFoundClaimants([]);
		setSearchParams({});
		setQuickFindModalState(false);
	};

	// Handle multiple claimant selection
	const handleMultiClaimantSelection = (selectedRows = []) => {
		// loop through and grab claimant data by row
		let claimants = [];
		Object.keys(selectedRows).forEach(row => {
			claimants.push(foundClaimants[row]);
		});
		handleClaimantSelect(claimants);
	}


	// Select a claimant
	const handleClaimantSelect = (claimants = [], getClaimants = false) => {
		handleClaimantSelection(claimants);
		handleClose();
	};

	useEffect(() => {
		if (!skipFirstSearch) {
			if (Object.keys(searchParams).length > 0) {
				newSearch(searchParams);
			} else {
				// setSearchParams({});

				// setFoundClaimants([]);
				// setClaimantCount(0);
				// newSearch();
			}
		}
		setSkipFirstSearch(false);
	}, [searchParams]);


	return (
		<>
			<Button variant={buttonVariant} onClick={() => setQuickFindModalState(true)}>
				{buttonLabel}
			</Button>

			<Modal
				show={quickFindModalState}
				onHide={handleClose}
				size="lg"
				backdrop="static"
				contentClassName="border"
			>
				<Modal.Header
					closeButton
					closeVariant={isDark ? 'white' : undefined}
					className="bg-light px-card border-bottom-0"
				>
					<Modal.Title as="h5">Claimant Quick Find</Modal.Title>
				</Modal.Header>
				<Modal.Body className="p-card">
					<FindAClaimantForm
						searchParams={searchParams}
						setSearchParams={setSearchParams}
						requiredSearchParams={requiredSearchParams}
					/>

					<hr />

					<div className="d-flex justify-content-between align-items-center mt-3">
						<h5 className="mb-0">{claimantCount} Found Claimants</h5>
						{/* <span className="text-muted">{claimantCount} found</span> */}
					</div>
					<div className="mt-3">
						{
							searching ?
								<p>Searching....</p>
								:
								<AdvanceTableWrapper
									columns={columns}
									data={foundClaimants}
									sortable
									searchable
									pagination
									perPage={5}
									selection={allowMultipleSelect}
								>

									<BulAction table handleMultiClaimantSelection={handleMultiClaimantSelection} />
									{/* <Row className="flex-end-center mb-3">
										<Col xs="auto" sm={6} lg={4}>
											<AdvanceTableSearchBox table />
										</Col>
									</Row> */}
									<AdvanceTable
										table
										headerClassName="bg-200 text-900 text-nowrap align-middle"
										rowClassName="align-middle white-space-nowrap"
										tableProps={{
											bordered: true,
											striped: true,
											className: 'fs--1 mb-0 overflow-hidden'
										}}
									/>
									<div className="mt-3">
										<AdvanceTableFooter
											rowCount={claimantCount}
											table
											navButtons
										/>
									</div>
								</AdvanceTableWrapper>

						}
					</div>
				</Modal.Body>
			</Modal>
		</>
	);
};

ClaimantQuickFind.propTypes = {
	buttonLabel: PropTypes.string,
	buttonVariant: PropTypes.string,
	handleClaimantSelection: PropTypes.func,
	defaultSearchParams: PropTypes.object,
	requiredSearchParams: PropTypes.object,
	skipFirstQuery: PropTypes.bool
}

export default ClaimantQuickFind;