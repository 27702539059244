import React, { useReducer, useEffect } from 'react';
import PropTypes from 'prop-types';
import AppContext from 'context/Context';
import { settings, fontSizeMap, fontSizeKeys } from './config';
import { getColor, getItemFromStore } from 'helpers/utils';
import { configReducer } from './reducers/configReducer';
import useToggleStyle from './hooks/useToggleStyle';
import { lookupsReducer } from 'reducers/lookupsReducer';
import AuthContextProvider from 'components/authentication/AuthContextProvider';

const Main = props => {
  let fontSizes = Object.keys(fontSizeMap).filter((size) => /_+/.test(size) === false); // ['small', 'medium', 'large']
  const configState = {
    fontSize: getItemFromStore('fontSize', settings.fontSize),
    FULCRUM_isFluid: getItemFromStore('FULCRUM_isFluid', settings.FULCRUM_isFluid),
    isRTL: getItemFromStore('isRTL', settings.isRTL),
    isDark: getItemFromStore('isDark', settings.isDark),
    navbarPosition: getItemFromStore('navbarPosition', settings.navbarPosition),
    isNavbarVerticalCollapsed: getItemFromStore(
      'isNavbarVerticalCollapsed',
      settings.isNavbarVerticalCollapsed
    ),
    FULCRUM_navbarStyle: getItemFromStore('FULCRUM_navbarStyle', settings.FULCRUM_navbarStyle),
    currency: settings.currency,
    showBurgerMenu: settings.showBurgerMenu,
    showSettingPanel: false,
    navbarCollapsed: false
  };

  const [config, configDispatch] = useReducer(configReducer, configState);
  const [lookups, lookupsDispatch] = useReducer(lookupsReducer, {
    addressTypes: [],
    phoneTypes: [],
    services: []
  });

  const { isLoaded } = useToggleStyle(
    config.isRTL,
    config.isDark,
    configDispatch
  );

  const setConfig = (key, value) => {
    configDispatch({
      type: 'SET_CONFIG',
      payload: {
        key,
        value,
        setInStore: [
          'fontSize',
          'FULCRUM_isFluid',
          'isRTL',
          'isDark',
          'navbarPosition',
          'isNavbarVerticalCollapsed',
          'FULCRUM_navbarStyle'
        ].includes(key)
      }
    });
  };

  const updateRootStyle = (key, value) => {
    if (!key || !value) return;
    const prevState = getComputedStyle(document.documentElement).getPropertyValue(key);
    if (/\d+/.test(key)) { // Key has a number so must be a header style 
      value = `h${key.replace(/\D+/g, '')}_${value}`; // i.e h1_small 
    } else if (['--fulcrum-nav-link-font-size'].includes(key)) {
      value = `nav_link_${value}`; // i.e nav_small
    }
    let newValue = (fontSizeKeys.includes(key) && value in fontSizeMap) ? fontSizeMap[value] : fontSizeMap.medium;
    if (prevState && newValue && prevState !== newValue) {
      document.documentElement.style.setProperty(key, newValue);
    }
  };

  useEffect(() => {
    fontSizeKeys.forEach(key => updateRootStyle(key, config.fontSize));
  }, [isLoaded, config.fontSize])

  if (!isLoaded) {
    return (
      <div
        style={{
          position: 'fixed',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          backgroundColor: config.isDark ? getColor('dark') : getColor('light')
        }}
      />
    );
  }

  return (
    <AppContext.Provider value={{ config, setConfig, configDispatch, lookups, lookupsDispatch, fontSizes }}>
      <AuthContextProvider>
        {props.children}
      </AuthContextProvider>
    </AppContext.Provider>
  );
};

Main.propTypes = { children: PropTypes.node };

export default Main;
