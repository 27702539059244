import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
let { accountAPI } = require('utils/api/fulcrum-api');
import Auth from 'utils/auth';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import AccountProfileCard from './AccountProfileCard';
import AccountProfileTabs from './AccountProfileTabs';

const AccountProfile = () => {
	let { id: accountId } = useParams();
	accountId = accountId || Auth.getProfile().data.account_id;

	const [loading, setLoading] = useState(true);
	const [account, setAccount] = useState({});

	// Get account details
	const getAccountDetails = async () => {
		try {
			let result = await accountAPI.getAccount(accountId);
			if (!result?.data) {
				throw new Error('No user data returned');
			}
			setAccount(result.data);
			setLoading(false);
		} catch (error) {
			console.error(error);
		}
	};

	useEffect(() => {
		getAccountDetails(accountId);
	}, []);

	if (loading) {
		return (<p>Loading account details...</p>);
	};

	return (
		<div>
			<Row>
				<Col md={5} xxl={5}>
					<div className="mb-3">
						<AccountProfileCard account={account} setAccount={setAccount} getAccountDetails={getAccountDetails} />
					</div>

				</Col>
				<Col md={7} xxl={7}>
					<AccountProfileTabs account={account} setAccount={setAccount} />
				</Col>

			</Row>
		</div>
	);
};

export default AccountProfile;