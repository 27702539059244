import React, { useContext, useState, useEffect } from 'react';
import { Button, Form, Modal, Row, Col, Alert } from 'react-bootstrap';
import AppContext from 'context/Context';
import { documentAPI } from 'utils/api/fulcrum-api';
import DocumentTable from 'components/documents/DocumentTableOld';

const DocumentSearchTool = ({
	modalState,
	setModalState = () => { },
	defaultSearchParams = {},
	requiredSearchParams = {},
	handleDocumentSelection = (() => { }),
	immediateSearch = false
}) => {
	const {
		config: { isDark }
	} = useContext(AppContext);
	const [selectedDocuments, setSelectedDocuments] = useState([]);
	const [searchParams, setSearchParams] = useState(defaultSearchParams);
	const [requiredParams, setRequiredParams] = useState(requiredSearchParams);
	const [searchResults, setSearchResults] = useState([]);
	const [searchResultsCount, setSearchResultsCount] = useState(0);
	const [searching, setSearching] = useState(false);
	const [lastQuery, setLastQuery] = useState({});
	const [searchResultsError, setSearchResultsError] = useState(false);
	const [searchResultsErrorMessage, setSearchResultsErrorMessage] = useState('');


	// handle show
	const handleShow = () => {
		setSelectedDocuments([]);
		setSearchResults([]);
		setSearchResultsCount(0);
	};

	// Handle Close
	const handleClose = () => {
		setModalState(false);
		setSelectedDocuments([]);
		setSearchResults([]);
		setSearchResultsCount(0);
	};

	// Handle Document Selection
	const handleDocumentSelectionSubmit = () => {
		handleClose();
		handleDocumentSelection();
	};

	// Search Documents
	const searchDocuments = async (params = {}) => {
		try {
			let result = await documentAPI.searchDocuments({ ...params, ...requiredSearchParams });
			if (!documentSearchResult?.data?.rows) {
				throw new Error('No document data found');
			}

			formatData(documentSearchResult.data.rows);
			setDocumentCount(documentSearchResult.data.count);
			setSearching(false);

			if (saveLastQuery) {
				setLastQuery(params);
			}
		} catch (error) {
			console.log(error);
		}
	};

	// New Search
	const newSearch = (params) => {
		setSearching(true);
		let adjustedSearchParams = {
			...params,
			...requiredSearchParams
		};
		documentSearchHandler(adjustedSearchParams);
	};

	// Refresh Last Search
	const refreshLastSearch = () => {
		documentSearchHandler(lastQuery);
	};


	useEffect(() => {
		if (immediateSearch) {
			newSearch({ ...searchParams, ...requiredParams });
		}
	}, [])


	return (
		<>
			<Modal
				show={modalState}
				onHide={handleClose}
				onShow={handleShow}
				contentClassName="border"
				backdrop="static"
				size='lg'
			>
				<Modal.Header
					closeButton
					closeVariant={isDark ? 'white' : undefined}
					className="bg-light px-card border-bottom-0"
				>
					<Modal.Title as="h5">Document Search</Modal.Title>
				</Modal.Header>
				<Modal.Body className="p-card">
					<DocumentTable
						defaultSearchParams={defaultSearchParams}
						requiredSearchParams={requiredSearchParams}
						hideSearch={true}
						hideCreateButton={true}
						hideExport={true}
						immediateSearch={immediateSearch}
						bulkActionOverride={[
							{
								label: 'Attach Selected',
								action: "ATTACH_SELECTED",
								visible: true,
								actionHanlder: () => {
									alert('here')
									attachSelectedCB(selectedRowIds);
								}
							}
						]}
						defaultColumns={[
							{
								accessor: 'document_type',
								Header: 'Document Title',
								headerProps: { className: 'pe-1' },
								cellProps: {
									className: 'py-2'
								}
							},
							{
								accessor: 'created_at',
								Header: 'Document Set',
								headerProps: { className: 'pe-1' },
								cellProps: {
									className: 'py-2'
								}
							},
							{
								accessor: 'title',
								Header: 'Title',
								headerProps: { className: 'pe-1' },
								cellProps: {
									className: 'py-2'
								}
							},
						]}
					/>

				</Modal.Body>
				<Modal.Footer className="bg-light px-card border-top-0">
					<Button variant="secondary" onClick={handleClose}>
						Close
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
};

export default DocumentSearchTool;