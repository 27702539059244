import moment from 'moment';
import React, { useState } from 'react';
import { Card, Col, Row, Button, Modal, Form, Spinner, Alert } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { toast } from 'react-toastify';
import { preHearingChecklistAPI } from 'utils/api/fulcrum-api';

const PreHearingChecklist = ({ modalState, setModalState, selectedHearing, setSelectedHearing = () => { }, refreshParent = () => { } }) => {
	const defaultFormData = {
		contact: null, // Boolean (required) radio button
		questions: null, // Boolean (required) (if contact = true) radio button
		procedure: null, // Boolean (required) (if contact = true) radio button
		treatment_history: null, // Boolean (required) (if contact = true) radio button
		additional_sources: null, // Boolean (required) (if contact = true && treament_history = true) radio button
		additional_sources_description: null, // String (required) (if additional_sources = true && treament_history = true) textarea
		attendance: null, // Boolean (required) (if contact = true) radio button
		contact_date: null, // Date  (required) (if contact = false) datepicker
		contact_event: null, // String (required) (if contact = false) text input
		contact_notes: null, // String (required) (if contact = false) textarea
		issues: null, // Boolean (required) radio button
		issues_explanation: null, // String (required) (if issues = true) textarea
	};
	const [formData, setFormData] = useState(defaultFormData);
	const [previousFormData, setPreviousFormData] = useState(defaultFormData);
	const [loading, setLoading] = useState(true);
	const [submitting, setSubmitting] = useState(false);

	// Get Pre-Hearing Checklist
	const getPreHearingChecklist = async (hearingId, id) => {
		if (!id) return toast.error('Error getting Pre-Hearing Checklist');
		try {
			let result = await preHearingChecklistAPI.getPreHearingChecklist(hearingId, id);
			if (!result?.data) {
				toast.error('Error getting Pre-Hearing Checklist');
			}
			let newFormData = {
				contact: result.data.contact,
				questions: result.data.questions,
				procedure: result.data.procedure,
				treatment_history: result.data.treatment_history,
				additional_sources: result.data.additional_sources,
				additional_sources_description: result.data.additional_sources_description,
				attendance: result.data.attendance,
				contact_date: result.data.contact_date,
				contact_event: result.data.contact_event,
				contact_notes: result.data.contact_notes,
				issues: result.data.issues,
				issues_explanation: result.data.issues_explanation,
			};
			setFormData(newFormData);
			setPreviousFormData(newFormData);
		} catch (error) {
			toast.error('Error getting Pre-Hearing Checklist');
		}
		setLoading(false);
	};

	// Handle Submit
	const handleSubmit = async (e) => {
		setSubmitting(true);
		e.preventDefault();
		try {
			let result;
			if (selectedHearing?.pre_hearing_checklist_id) {
				result = await preHearingChecklistAPI.updatePreHearingChecklist(selectedHearing.id, selectedHearing.pre_hearing_checklist_id, formData);
			} else {
				result = await preHearingChecklistAPI.createPreHearingChecklist(selectedHearing.id, formData);
			}
			if (!result?.data) {
				throw new Error('Error saving Pre-Hearing Checklist');
			}
			// setFormData(result.data);
			setPreviousFormData(formData);
			toast.success('Pre-Hearing Checklist saved', { theme: 'colored' });
			refreshParent();
			handleClose(true);
		} catch (error) {
			toast.error('Error saving Pre-Hearing Checklist', { theme: 'colored' });
		}
		setSubmitting(false);
	};

	// Handle Close
	const handleClose = (bypassSaveCheck = false) => {
		if (!bypassSaveCheck && selectedHearing?.pre_hearing_checklist_id && JSON.stringify(formData) !== JSON.stringify(previousFormData)) {
			if (!window.confirm('You have unsaved changes. Are you sure you want to close?')) {
				return;
			}
		}
		setSelectedHearing(null);
		setFormData(defaultFormData);
		setModalState(false);
		setLoading(true);
	};

	// Handle Show
	const handleShow = () => {
		setFormData(defaultFormData);
		setModalState(true);
		setLoading(true);
		if (selectedHearing?.pre_hearing_checklist_id) {
			getPreHearingChecklist(selectedHearing.id, selectedHearing.pre_hearing_checklist_id);
		} else {
			setLoading(false);
		}
	};

	return (
		<Modal
			show={modalState}
			onShow={handleShow}
			onHide={handleClose}
			size="xl"
			backdrop="static"
			aria-labelledby="contained-modal-title-vcenter"
			centered
		>
			<Form onSubmit={handleSubmit}>
				<Modal.Header closeButton>
					<Modal.Title id="contained-modal-title-vcenter">
						Pre-Hearing Checklist
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Alert variant={'info'}>
						Please complete the pre-hearing checklist for {selectedHearing?.claimant?.frist_name || ""} {selectedHearing?.claimant?.last_name || ""} before the hearing.
					</Alert>
					{
						selectedHearing?.pre_hearing_checklist_id && (JSON.stringify(formData) !== JSON.stringify(previousFormData)) && (
							<Alert variant={'warning'}>
								You have unsaved changes. Please save or cancel before closing this window.
							</Alert>
						)
					}

					<Row>
						<Col>
							<Card className="shadow-none">
								<span className="fw-bold">Pre-Hearing checklist for {selectedHearing?.claimant?.frist_name || ""} {selectedHearing?.claimant?.last_name || ""}</span>
								<Card.Body>
									<Form.Group className="p-2 my-2">
										<Form.Label className="">Were you able to contact the claimant for a pre-hearing conference? </Form.Label>
										<br />
										<Form.Check
											type="radio"
											inline
											label="Yes"
											name="contact"
											value={true}
											checked={formData.contact === true}
											onChange={(e) => setFormData({ ...formData, contact: true })}
											required
										/>
										<Form.Check
											type="radio"
											inline
											label="No"
											name="contact"
											value={false}
											checked={formData.contact === false}
											onChange={(e) => setFormData({ ...formData, contact: false })}
											required
										/>
									</Form.Group>
									{formData.contact === true && (
										<>
											<Form.Group className="p-2 my-2">
												<Form.Label className="">Did you answer all of the claimant’s questions to the best of your ability?</Form.Label>
												<br />
												<Form.Check
													type="radio"
													inline
													label="Yes"
													required
													name="questions"
													value={true}
													checked={formData.questions === true}
													onChange={(e) => setFormData({ ...formData, questions: true })}
												/>
												<Form.Check
													type="radio"
													inline
													label="No"
													name="questions"
													required
													value={false}
													checked={formData.questions === false}
													onChange={(e) => setFormData({ ...formData, questions: false })}
												/>
											</Form.Group>
											<Form.Group className="p-2 my-2">
												<Form.Label className="">Did you generally explain the parties, procedures, and issues related to the claimant’s hearing?</Form.Label>
												<br />
												<Form.Check
													type="radio"
													inline
													label="Yes"
													name="procedure"
													value={true}
													required
													checked={formData.procedure === true}
													onChange={(e) => setFormData({ ...formData, procedure: true })}
												/>
												<Form.Check
													type="radio"
													inline
													label="No"
													name="procedure"
													value={false}
													checked={formData.procedure === false}
													onChange={(e) => setFormData({ ...formData, procedure: false })}
												/>
											</Form.Group>
											<Form.Group className="p-2 my-2">
												<Form.Label className="">Did you confirm the claimant’s treating history?</Form.Label>
												<br />
												<Form.Check
													type="radio"
													inline
													label="Yes"
													name="treatment_history"
													value={true}
													checked={formData.treatment_history === true}
													onChange={(e) => setFormData({ ...formData, treatment_history: true })}
												/>
												<Form.Check
													type="radio"
													inline
													label="No"
													name="treatment_history"
													value={false}
													checked={formData.treatment_history === false}
													onChange={(e) => setFormData({ ...formData, treatment_history: false })}
												/>
											</Form.Group>


											{
												formData.treatment_history === true && (
													<>
														<Form.Group className="p-2 my-2">
															<Form.Label className="">Were there additional treating sources?  If yes, please enter additional treating sources in the “Please Explain” box below.</Form.Label>
															<br />
															<Form.Check
																type="radio"
																inline
																label="Yes"
																name="additional_sources"
																value={true}
																checked={formData.additional_sources === true}
																onChange={(e) => {
																	setFormData({ ...formData, additional_sources: true, issues: true });
																}}
																required
															/>
															<Form.Check
																type="radio"
																inline
																label="No"
																name="additional_sources"
																value={false}
																checked={formData.additional_sources === false}
																onChange={(e) => setFormData({ ...formData, additional_sources: false })}
																required
															/>
														</Form.Group>
														{
															formData.additional_sources === true && (
																<Form.Group className="p-2 my-2">
																	<Form.Label className="">Please provied an explanation.</Form.Label>
																	<Form.Control
																		as="textarea"
																		rows={3}
																		name="additional_sources_description"
																		value={formData.additional_sources_description}
																		onChange={(e) => setFormData({ ...formData, additional_sources_description: e.target.value })}
																		required
																	/>
																</Form.Group>
															)
														}
													</>
												)
											}


											<Form.Group className="p-2 my-2">
												<Form.Label className="">Did you confirm that the client will attend the hearing at the noticed time?</Form.Label>
												<br />
												<Form.Check
													type="radio"
													inline
													label="Yes"
													name="attendance"
													value={true}
													checked={formData.attendance === true}
													onChange={(e) => setFormData({ ...formData, attendance: true })}
													required
												/>
												<Form.Check
													type="radio"
													inline
													label="No"
													name="attendance"
													value={false}
													checked={formData.attendance === false}
													onChange={(e) => setFormData({ ...formData, attendance: false })}
													required
												/>
											</Form.Group>

										</>
									)}

									{
										formData.contact === false && (
											<>
												<span className="">Please log your contact attempts.</span>
												<Row>
													<Col>
														<Form.Group controlId="contact_date">
															<Form.Label>
																Contact Date
															</Form.Label>
															<DatePicker
																selected={formData?.contact_date ? moment(formData.contact_date).toDate() : null}
																onChange={(date) => {
																	setFormData({ ...formData, contact_date: date });
																}}
																formatWeekDay={day => day.slice(0, 3)}
																className='form-control'
																placeholderText="Select Date"
																required
															/>
														</Form.Group>
													</Col>

													<Col>
														<Form.Group className="">
															<Form.Label className="">Contact Event</Form.Label>
															<Form.Control
																as="select"
																name="contact_event"
																value={formData.contact_event}
																onChange={(e) => setFormData({ ...formData, contact_event: e.target.value })}
																required
															>
																<option value="" disabled>Select an option</option>
																{/* Left Message, No Answer, Wrong Number/Disconnected, Busy, No Case File*/}
																<option value="Left Message">Left Message</option>
																<option value="No Answer">No Answer</option>
																<option value="Wrong Number/Disconnected">Wrong Number/Disconnected</option>
																<option value="Busy">Busy</option>
																<option value="No Case File">No Case File</option>
															</Form.Control>
														</Form.Group>
													</Col>
												</Row>
												<Form.Group className="p-2 my-2">
													<Form.Label className="">Contact Notes</Form.Label>
													<Form.Control
														as="textarea"
														rows={3}
														name="contact_notes"
														value={formData.contact_notes}
														onChange={(e) => setFormData({ ...formData, contact_notes: e.target.value })}
														required
													/>
												</Form.Group>
											</>
										)
									}

									<Form.Group className="p-2 my-2">
										<Form.Label className="">After speaking with the {selectedHearing?.claimant?.first_name || ''} {selectedHearing?.claimant?.last_name || ''}, are there any issues that the primary representative should know about before the hearing? </Form.Label>
										<br />
										<Form.Check
											type="radio"
											inline
											label="Yes"
											name="issues"
											value={true}
											checked={formData.issues === true}
											onChange={(e) => setFormData({ ...formData, issues: true })}
											required
										/>
										<Form.Check
											type="radio"
											inline
											label="No"
											name="issues"
											value={false}
											checked={formData.issues === false}
											onChange={(e) => setFormData({ ...formData, issues: false })}
											required
										/>
									</Form.Group>
									{
										formData.issues === true && (
											<Form.Group className="p-2 my-2">
												<Form.Label className="">Please explain</Form.Label>
												<Form.Control
													as="textarea"
													rows={3}
													name="issues_explanation"
													value={formData.issues_explanation}
													onChange={(e) => setFormData({ ...formData, issues_explanation: e.target.value })}
													required
												/>
											</Form.Group>
										)
									}
								</Card.Body>
							</Card>
						</Col>
					</Row>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="outline-secondary" onClick={handleClose}>Close</Button>

					{
						!loading &&
						(selectedHearing?.pre_hearing_checklist_id && (JSON.stringify(formData) !== JSON.stringify(previousFormData)) ? (
							<>
								{
									submitting ?
										<Button variant="primary" type="submit" disabled>
											<Spinner
												as="span"
												animation="border"
												size="sm"
												role="status"
												aria-hidden="true"
											/>
											Updating...
										</Button>
										:
										<Button variant="primary" type="submit">
											Save and Exit
										</Button>
								}
							</>
						) : (
							<>
								{
									submitting ?
										<Button variant="primary" type="submit" disabled>
											<Spinner
												as="span"
												animation="border"
												size="sm"
												role="status"
												aria-hidden="true"
											/>
											Saving...
										</Button>
										:
										<Button variant="primary" type="submit">
											Save
										</Button>
								}
							</>
						))
					}
				</Modal.Footer>
			</Form>
		</Modal>
	);
};

export default PreHearingChecklist;