import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import PaymentTableHeader from './PaymentTableHeader';
import CustomAdvanceTablePagination from 'components/common/advance-table/CustomAdvanceTablePagination'
import CustomAdvanceTable from 'components/common/advance-table/CustomAdvanceTable';
import { paymentAPI } from 'utils/api/fulcrum-api';
import { removeEmptyFromObj } from 'utils/functions';
import moment from 'moment';
import PaymentDetails from './PaymentDetails';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';

const defaultColumns = [
    {
        accessor: 'id',
        Header: 'ID',
        headerProps: { className: 'pe-1' },
        cellProps: {
            className: 'py-2'
        }
    },
    {
        accessor: 'payment_method_type',
        Header: 'Type',
        headerProps: { className: 'pe-1' },
        cellProps: {
            className: 'py-2'
        }
    },
    {
        accessor: 'name',
        Header: 'Name',
        headerProps: { className: 'pe-1' },
        cellProps: {
            className: 'py-2'
        }
    },
    {
        accessor: 'last4',
        Header: 'Last 4',
        headerProps: { className: 'pe-1' },
        cellProps: {
            className: 'py-2'
        }
    },
    {
        accessor: 'exp_date',
        Header: 'Exp Date',
        headerProps: { className: 'pe-1' },
        cellProps: {
            className: 'py-2'
        }
    },
    {
        accessor: 'service',
        Header: 'Services',
        headerProps: { className: 'pe-1' },
        cellProps: {
            className: 'py-2'
        }
    },
    {
        accessor: 'is_primary',
        Header: 'Primary?',
        headerProps: { className: 'pe-1' },
        cellProps: {
            className: 'py-2'
        }
    },
    {
        accessor: 'created_at',
        Header: 'Created',
        headerProps: { className: 'pe-1' },
        cellProps: {
            className: 'py-2'
        }
    },
];

const PaymentTable = ({ tableHeaderLabel = "Payment Methods", columns = defaultColumns, defaultSearchParams = {}, requiredSearchParams = {} } = {}) => {
    const [paymentData, setPaymentData] = useState([]);
    const [Pages, setPages] = useState([1]);
    const [activePage, setActivePage] = useState(1);
    const [perPage, setPerPage] = useState(20);
    const [totalRecords, setTotalRecords] = useState(0);

    const [searching, setSearching] = useState(false);
    const [lastQuery, setLastQuery] = useState({});
    const [appliedSortOrders, setAppliedSortOrders] = useState([]);
    const [paymentModalState, setPaymentModalState] = useState(false);

    const deletePaymentMethod = async (id) => {
        if (!id) {
            return;
        }
        let confirmed = await Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: `Yes, delete id ${id}!`
        }).then((result) => (result.isConfirmed));
        if (!confirmed) {
            return;
        }

        try {
            let { error, response, message } = await paymentAPI.deletePaymentMethod(id);
            if (error) {
                if (typeof response?.error === 'string') {
                    throw new Error(`${error} - ${response.error}`);
                } else if (typeof response === 'string') {
                    throw new Error(`${error} - ${response}`);
                }

                let { raw = {}, rawType = "", statusCode, type, param } = response?.error || response || {};
                let { message = '' } = raw || {};
                if (!message) {
                    message = `${error} - There was an error deleting payment method.`;
                }
                if (statusCode) {
                    message = `${statusCode} - ${message}`;
                } else {
                    message = `${error} - ${message}`;
                }
                if (type || rawType) {
                    message += `${type || rawType}`;
                }
                if (param) {
                    message += ` with ${param}`;
                }
                throw new Error(message);
            }

            refreshLastQuery();
            toast.success(message, {
                theme: 'colored',
            });
        } catch (err) {
            console.log(err);
            let error_message = err?.message || err?.data?.error?.message || err || "There was an error deleting the payment method. See console for error";
            console.log(error_message);
            toast.error(error_message, {
                theme: 'colored',
            });
        }
    };

    const updatePaymentMethod = async (id, updatedValues = {}) => {
        if (!id || !updatedValues || updatedValues && Object.keys(updatedValues).length === 0) {
            return;
        }

        try {
            let { error, response, data } = await paymentAPI.updatePaymentMethod(id, updatedValues);
            if (error) {
                if (typeof response?.error === 'string') {
                    throw new Error(`${error} - ${response.error}`);
                } else if (typeof response === 'string') {
                    throw new Error(`${error} - ${response}`);
                }

                let { raw = {}, rawType = "", statusCode, type, param } = response?.error || response || {};
                let { message = '' } = raw || {};
                if (!message) {
                    message = `${error} - There was an error updating payment method.`;
                }
                if (statusCode) {
                    message = `${statusCode} - ${message}`;
                } else {
                    message = `${error} - ${message}`;
                }
                if (type || rawType) {
                    message += `${type || rawType}`;
                }
                if (param) {
                    message += ` with ${param}`;
                }
                throw new Error(message);
            }

            refreshLastQuery();
            toast.success('Payment Method has been updated.', {
                theme: 'colored',
            });
            setPaymentModalState(false);
        } catch (err) {
            console.log(err);
            let error_message = err?.message || err?.data?.error?.message || err || "There was an error updating the payment method. See console for error";
            console.log(error_message);
            toast.error(error_message, {
                theme: 'colored',
            });
        }
    };

    const createPaymentMethod = async (newValues = {}) => {
        if (!newValues || newValues && Object.keys(newValues).length === 0) {
            return;
        }

        try {
            let { error, response, data } = await paymentAPI.createPaymentMethod(newValues);
            if (error) {
                if (typeof response?.error === 'string') {
                    throw new Error(`${error} - ${response.error}`);
                } else if (typeof response === 'string') {
                    throw new Error(`${error} - ${response}`);
                }

                let { raw = {}, rawType = "", statusCode, type, param } = response?.error || response || {};
                let { message = '' } = raw || {};
                if (!message) {
                    message = `${error} - There was an error creating payment method.`;
                }
                if (statusCode) {
                    message = `${statusCode} - ${message}`;
                } else {
                    message = `${error} - ${message}`;
                }
                if (type || rawType) {
                    message += `${type || rawType}`;
                }
                if (param) {
                    message += ` with ${param}`;
                }
                throw new Error(message);
            }

            refreshLastQuery();
            toast.success('Payment Method has been created.', {
                theme: 'colored',
            });
            setPaymentModalState(false);
        } catch (err) {
            console.log(err);
            let error_message = err?.message || err?.data?.error?.message || err || "There was an error creating the payment method. See console for error";
            console.log(error_message);
            toast.error(error_message, {
                theme: 'colored',
            });
        }
    };

    const handleBulkActions = (actionObj = {}, selectedRows = [], selectedIds = []) => {
        let { id, stripe_customer_id, payment_method_type, card_id, customer_id, service, is_primary, ...cardDetails } = selectedRows[0] || {};
        let { bulk_action } = actionObj || {};
        switch (bulk_action) {
            case 'set_primary':
                if (!is_primary) { // Only update if not already primary
                    updatePaymentMethod(id, { is_primary: true, default_source: card_id });
                }
                break;
            case 'update':
                setPaymentModalState(true);
                break;
            case 'delete':
                deletePaymentMethod(id);
                break;
            default: // add new payment method
                setPaymentModalState(true);
                break;
        }
    };

    // Format data for table
    const formatData = (data = []) => {
        let adjustedData = data.map(lv => {
            return {
                ...lv,
                created_at: lv?.createdAt ? moment(lv.createdAt).format('MM/DD/YYYY hh:mm A') : '',
            }
        });
        setPaymentData(adjustedData);
    };

    const headerClickFn = (id, previousSortBy) => {
        if (!id) {
            return;
        }
        let singleSortFields = ['name', 'last4', 'exp_date']; // These fields can't be stacked with any other sort fields
        let foundIndex = appliedSortOrders.findIndex(s => s[0] === id);
        let sortBy = !previousSortBy ? "ASC" : previousSortBy === "asc" ? "DESC" : '';

        if (!sortBy) { // Not Sorted so remove clicked sort item 
            if (foundIndex > -1) {
                setAppliedSortOrders((prev) => {
                    let newSortOrders = [...prev];
                    newSortOrders.splice(foundIndex, 1); // remove clicked sort item
                    return newSortOrders;
                });
            }
        } else { // Sort by Ascending or Descending 
            let newSort = [id, sortBy];
            if (singleSortFields.includes(id)) { // Overwrites all existing sort fields
                setAppliedSortOrders([newSort]);
            } else if (foundIndex > -1) { // replaces existing 
                setAppliedSortOrders((prev) => {
                    let newSortOrders = [...prev];
                    newSortOrders[foundIndex] = newSort;
                    return newSortOrders;
                });
            } else { // inserts 
                setAppliedSortOrders((prev) => [...prev, newSort]);
            }
        }
    };

    // Payment Method Search Handler
    const paymentSearchHandler = async (params = {}, saveLastQuery = false) => {
        try {
            params = removeEmptyFromObj(params);
            params.page = activePage;
            params.limit = perPage;
            params.navSize = 4; // How many buttons to show for navigation 
            params.order = appliedSortOrders; // Example [[id, 'ASC']]
            let { error, response, data } = await paymentAPI.searchPaymentMethods(params);
            if (error) {
                if (typeof response?.error === 'string') {
                    throw new Error(`${error} - ${response.error}`);
                } else if (typeof response === 'string') {
                    throw new Error(`${error} - ${response}`);
                }
                throw new Error(`${error} - There was an error searching for payment methods.`);
            }

            let { count = 0, rows = [], pages = [] } = data || {}; // returns { count = 0, rows = [], pageCount, page, pages = [1,2,3,10] } 
            if (!rows || (Array.isArray(rows) && rows.length === 0)) {
                throw new Error("No payment methods found.");
            }

            formatData(rows);
            setTotalRecords(count);
            setPages(pages);
            if (saveLastQuery) {
                setLastQuery(params);
            }
        } catch (error) {
            console.log(error);
            setPaymentData([]);
            setTotalRecords(0);
            setActivePage(1);
        }
        setSearching(false);
    };

    const refreshLastQuery = async () => (await paymentSearchHandler({ ...lastQuery, ...requiredSearchParams }));
    const newSearch = async (params) => {
        setSearching(true);
        let adjustedSearchParams = {
            ...params,
            ...requiredSearchParams
        };
        return await paymentSearchHandler(adjustedSearchParams);
    };

    // Apply search filters
    useEffect(() => { newSearch(defaultSearchParams); }, [activePage, perPage, appliedSortOrders]);
    return (
        <AdvanceTableWrapper
            columns={columns}
            data={paymentData}
            selection
            sortable
            pagination
            perPage={perPage}
            setPerPage={setPerPage}
        >
            <Card className="mb-3 shadow-none">
                <Card.Header>
                    <PaymentTableHeader
                        table
                        label={tableHeaderLabel}
                        handleBulkActions={handleBulkActions}
                    />
                </Card.Header>
                <Card.Body className="p-0">
                    {searching ?
                        <p>Loading payments...</p>
                        :
                        <CustomAdvanceTable
                            table
                            headerClickFn={headerClickFn}
                            appliedSortOrders={appliedSortOrders}
                            headerClassName="bg-200 text-900 text-nowrap align-middle"
                            rowClassName="align-middle white-space-nowrap"
                            tableProps={{
                                size: 'sm',
                                striped: true,
                                className: 'fs--1 mb-0'
                            }}
                        />}
                </Card.Body>
                <Card.Footer>
                    <CustomAdvanceTablePagination
                        table
                        Pages={Pages}
                        activePage={activePage}
                        setActivePage={setActivePage}
                        totalRecords={totalRecords}
                    />
                </Card.Footer>
            </Card>
            <PaymentDetails table
                modalState={paymentModalState}
                setModalState={setPaymentModalState}
                refreshParent={refreshLastQuery}
                handleUpdate={updatePaymentMethod}
                handleCreate={createPaymentMethod}
            />
        </AdvanceTableWrapper>
    );
};

PaymentTable.propTypes = {
    tableHeaderLabel: PropTypes.string,
    columns: PropTypes.array,
    defaultSearchParams: PropTypes.object,
    requiredSearchParams: PropTypes.object
};

export default PaymentTable;