import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Card, Row, Col, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import CustomAdvanceTable from 'components/common/advance-table/CustomAdvanceTable';
import CustomAdvanceTablePagination from 'components/common/advance-table/CustomAdvanceTablePagination';
import TaskTableHeader from './TaskTableHeader';
import CreateTaskForm from './CreateTaskForm';
import TaskSearchForm from './TaskSearchForm';
import TaskDetails from './TaskDetails/TaskDetails';
import Auth from 'utils/auth';
import { taskAPI } from 'utils/api/fulcrum-api';
import { removeEmptyFromObj } from 'utils/functions';

const defaultColumns = [
	{
		accessor: 'task_type',
		Header: 'Type',
		headerProps: { className: 'pe-1' },
		cellProps: {
			className: 'py-2'
		},
	},
	{
		accessor: 'due_date_text_only',
		Header: 'Due Date',
		headerProps: { className: 'pe-1' },
		cellProps: {
			className: 'py-2'
		}
	},
	{
		accessor: 'claimant_name',
		Header: 'Claimant',
		headerProps: { className: 'pe-1' },
		cellProps: {
			className: 'py-2'
		}
	},
	{
		accessor: 'title',
		Header: 'Title',
		headerProps: { className: 'pe-1' },
		cellProps: {
			className: 'py-2'
		}
	},
	{
		accessor: 'task_status_text_only',
		Header: 'Status',
		headerProps: { className: 'pe-1' },
		cellProps: {
			className: 'py-2'
		}
	},
	{
		accessor: 'assigned_users',
		Header: 'Users',
		headerProps: { className: 'pe-1' },
		cellProps: {
			className: 'py-2'
		}
	},
	{
		accessor: 'created_at',
		Header: 'Task Set',
		headerProps: { className: 'pe-1' },
		cellProps: {
			className: 'py-2'
		}
	},
	{
		accessor: 'view',
		Header: '',
		headerProps: { className: 'pe-1' },
		cellProps: {
			className: 'py-2'
		}
	},
];

const TaskTable = ({ columns = defaultColumns, account_id = Auth.getProfile()?.data?.account_id || null, siteAdminArea = false, showMetrics = true,
	tableHeaderLabel = "Tasks", defaultSearchParams = {}, requiredSearchParams = {}, defaultClaimants = [], refreshParent = () => { } } = {}) => {

	// const [createClaimantModal, setCreateClaimantModal] = useState(false);
	const [skipFirstQuery, setSkipFirstQuery] = useState(true);
	const [taskData, setTaskData] = useState([]);
	const [scheduleStartDate, setScheduleStartDate] = useState();
	const [scheduleEndDate, setScheduleEndDate] = useState();
	const [taskCount, setTaskCount] = useState(0);
	const [Pages, setPages] = useState([1]);
	const [activePage, setActivePage] = useState(1);
	const [perPage, setPerPage] = useState(10);
	const [taskDetailsModalState, setTaskDetailsModalState] = useState(false);
	const [selectedTask, setSelectedTask] = useState({});
	const [taskCreateModal, setTaskCreateModal] = useState(false);
	const [searching, setSearching] = useState(true);
	const [taskTypes, setTaskTypes] = useState([]);
	const [taskStatuses, setTaskStatuses] = useState([]);
	const [lastQuery, setLastQuery] = useState({});
	const [searchParams, setSearchParams] = useState({ ...defaultSearchParams, ...requiredSearchParams });
	let defaultSort = ["created_at", "ASC"];
	const [appliedSortOrders, setAppliedSortOrders] = useState([defaultSort]);
	const [metrics, setMetrics] = useState({
		'my_tasks': 0,
		'overdue': 0,
		'due_today': 0,
		'unassigned': 0,
	});

	const headerClickFn = (id, previousSortBy) => {
		if (!id) {
			return;
		}
		let foundIndex = appliedSortOrders.findIndex(s => s[0] === id);
		let sortBy = !previousSortBy ? "ASC" : previousSortBy === "asc" ? "DESC" : '';

		if (!sortBy) { // Not Sorted so remove clicked sort item 
			if (foundIndex > -1) {
				setAppliedSortOrders((prev) => {
					let newSortOrders = [...prev];
					newSortOrders.splice(foundIndex, 1); // remove clicked sort item
					return newSortOrders;
				});
			}
		} else { // Sort by Ascending or Descending 
			let newSort = [id, sortBy];
			if (foundIndex > -1) { // replaces existing 
				setAppliedSortOrders((prev) => {
					let newSortOrders = [...prev];
					newSortOrders[foundIndex] = newSort;
					return newSortOrders;
				});
			} else { // inserts 
				setAppliedSortOrders((prev) => [...prev, newSort]);
			}
		}
	};

	const formatData = (data = []) => {
		let adjustedData = data.map(c => {
			return {
				...c,
				// task_status: c.task_status ? c.task_status.status : '',
				task_status_text_only: c.task_status ? c.task_status.status : '',
				task_status: c?.task_status ? (
					<Form.Select
						id={`${c.id}_task_status`}
						size='sm'
						as="select"
						name="task_status_id"
						value={c.task_status_id}
						onChange={e => {
							updateTask(c.id, { task_status_id: e.target.value });
						}}
					>
						<option className="text-warning" value="1">Unacknowledged</option >
						<option className="text-primary" value="2">Acknowledged</option>
						<option className="text-success" value="3">Completed</option>
						<option className="text-danger" value="4">Closed (Not completed)</option>
					</Form.Select >
				) : 'Unknown',
				title: c?.title ? c.title : '',
				// title: (
				// 	<Form.Control
				// 		type="text"
				// 		name="title"
				// 		value={c.title}
				// 		onChange={e => {
				// 			}}
				// 		onBlur={e => {
				// 			if (c.title !== e.target.value) {
				// 				updateTask(c.id, { task_status_id: e.target.value });
				// 			}
				// 		}}
				// 	/>
				// ),
				task_type: <span className={`link-primary cursor-pointer`} onClick={() => handleTaskSelection(c)}>{c?.task_type.type === "Other" ? `${c.task_type.type} - ${c.title}` : c.task_type.type}</span>,
				created_at: moment(c.createdAt).format('MM/DD/YYYY hh:mm A'),
				due_date_text_only: c.due_date ? moment(c.due_date).format('MM/DD/YYYY hh:mm A') : '',
				due_date: (
					<DatePicker
						selected={c.due_date ? new Date(c.due_date) : null}
						minDate={new Date()}
						onChange={date => {
							updateTask(c.account_id, c.id, { due_date: date });
						}}
						className="form-control"
						placeholderText="MM-DD-YYYY H:M"
						dateFormat="MM-dd-yyyy h:mm aa"
						showTimeSelect
						required
					/>
				),
				claimant_name: c?.claimant ? <Link to={siteAdminArea ? `/admin/claimant-details/${c.claimant.id}` : `/claimant/profile/${c.claimant.id}`}>{`${c.claimant.first_name} ${c.claimant.last_name}`}</Link> : '',
				assigned_users: c?.assigned_users.length > 0 ? c?.assigned_users?.map(as => `${as.user.first_name} ${as.user.last_name}`).join(', ') : '',
				view: <span className={`link-primary cursor-pointer`} onClick={() => handleTaskSelection(c)}>View</span>
			}
		});

		setTaskData(adjustedData);
	};

	// Get task metrics
	const getTaskMetrics = async () => {
		try {
			let filters = {};
			if (searchParams.claimant_id != undefined) {
				filters = {
					claimant_id: searchParams.claimant_id
				}
			}
			let newMetricsResult = await taskAPI.getTaskMetrics(account_id, filters);
			setMetrics(newMetricsResult?.data || {
				'my_tasks': 0,
				'overdue': 0,
				'due_today': 0,
				'unassigned': 0,
			});
		} catch (error) {
			console.log(error);
		}
	}


	const taskSearchHandler = async (params = {}, saveLastQuery = false) => {
		try {
			if (showMetrics) {
				getTaskMetrics();
			}
			params = removeEmptyFromObj(params);
			params.page = activePage;
			params.limit = perPage;
			params.navSize = 4; // How many buttons to show for navigation
			params.order = appliedSortOrders; // Example [['last_last', 'asc'],['first_name','asc'], ['id','asc']]
			let { data } = await taskAPI.searchTasks(params);
			if (!data) {
				throw new Error('No task data found');
			}
			let { rows = [], pages = [], count = 0, page = 1, pageCount = 0 } = data || {}; // pages = [1,2,3,10]
			if (!rows) {
				throw new Error('No task data found');
			}
			if ((Array.isArray(rows) && rows.length === 0)) {
				setActivePage(1); // Make sure we don't get stuck loading nothing
			}

			if (pageCount < activePage) {
				setActivePage(1);
			} else {
				setActivePage(page);
			}
			setPages(pages);
			formatData(rows);
			console.log(count)
			setTaskCount(count);

			if (saveLastQuery) {
				setLastQuery(params);
			}
		} catch (error) {
			console.log(error);
			toast.error('Error searching tasks', { theme: 'colored' });
		}
		setSearching(false);
		return;
	};

	// Handle task metric click
	const handleTaskMetricClick = async (metric) => {
		try {
			switch (metric) {
				case 'my_tasks':
					setSearchParams({ assigned_to: Auth.getProfile().data.user_id, excludedTaskStatuses: [3, 4], ...requiredSearchParams });
					break;
				case 'overdue':
					taskSearchHandler({ overdueTasks: true, excludedTaskStatuses: [3, 4], ...requiredSearchParams });
					break;
				case 'due_today':
					taskSearchHandler({ todayTasks: true, ...requiredSearchParams });
					break;
				case 'unassigned':
					taskSearchHandler({ unassigned: true, ...requiredSearchParams });
					break;
				default:
					break;
			}
		} catch (error) {
			toast.error('Unable to get tasks.', { theme: 'colored' });
		}
	}

	// Clear search params and reset to default
	const clearSearchParams = () => {
		setSearchParams({ ...defaultSearchParams, ...requiredSearchParams });
	};

	// New Search
	const newSearch = async (params) => {
		setSearching(true);
		let adjustedSearchParams = {
			...params,
			...requiredSearchParams
		};

		taskSearchHandler(adjustedSearchParams, true);
	};

	// Get related data @TODO: Move this to a context
	const getRelatedData = async () => {
		try {
			let taskTypeResult = await taskAPI.getTaskTypes();
			if (!taskTypeResult?.data?.rows) {
				throw new Error('Unable to get task types.');
			}

			let taskStatusResult = await taskAPI.getTaskStatuses();
			if (!taskStatusResult?.data?.rows) {
				throw new Error('Unable to get task statuses.');
			}

			setTaskTypes(taskTypeResult.data.rows);
			setTaskStatuses(taskStatusResult.data.rows);
		} catch (error) {
			console.log(error);
		}
	};

	// Update task
	const updateTask = async (accountId, taskId, data) => {
		try {
			let result = await taskAPI.updateTask(accountId, taskId, data);
			if (!result?.data) {
				throw new Error('Error updating task');
			}
			refreshLastQuery();
		} catch (error) {
			console.log(error);
			toast.error('Error updating task', {
				theme: 'colored'
			});
		}
	};

	// Refresh last query
	const refreshLastQuery = async () => {
		taskSearchHandler({ ...lastQuery, ...requiredSearchParams }, false);
		refreshParent();
	};

	// Handle task selection
	const handleTaskSelection = (task) => {
		setSelectedTask(task);
		setTaskDetailsModalState(true);
	};

	useEffect(async () => {
		await getRelatedData();
		if (!skipFirstQuery) {
			newSearch({ ...defaultSearchParams });
		}
		setSkipFirstQuery(false);
	}, []);

	// Apply search filters
	useEffect(() => { newSearch(searchParams) }, [searchParams, activePage, perPage, appliedSortOrders]);

	return (
		<div>
			{
				showMetrics && (
					<div>
						<Row className="mb-3 border-bottom pb-3">
							<Col>
								<div className="text-center cursor-pointer" onClick={() => handleTaskMetricClick('my_tasks')}>
									<span className="fw-bold">{metrics.my_tasks}</span>
									<br />
									<span className="fw-bold">My Tasks</span>
								</div>
							</Col>
							<Col>
								<div className="text-center cursor-pointer" onClick={() => handleTaskMetricClick('overdue')}>
									<span className="fw-bold">{metrics.overdue}</span>
									<br />
									<span className="fw-bold">Overdue</span>
								</div>
							</Col>
							<Col>
								<div className="text-center cursor-pointer" onClick={() => handleTaskMetricClick('due_today')}>
									<span className="fw-bold">{metrics.due_today}</span>
									<br />
									<span className="fw-bold">Due Today</span>
								</div>
							</Col>
							<Col>
								<div className="text-center cursor-pointer" onClick={() => handleTaskMetricClick('unassigned')}>
									<span className="fw-bold">{metrics.unassigned}</span>
									<br />
									<span className="fw-bold">Unassigned</span>
								</div>
							</Col>
						</Row>
					</div>
				)
			}

			<TaskSearchForm
				searchParams={searchParams}
				setSearchParams={setSearchParams}
				taskTypes={taskTypes}
				taskStatuses={taskStatuses}
				clearSearchParams={clearSearchParams}
			/>

			<AdvanceTableWrapper
				columns={columns}
				data={taskData}
				// selection
				sortable
				pagination
				perPage={perPage}
				setPerPage={setPerPage}
			>
				<Card className="mb-3 shadow-none">
					<Card.Header>
						<TaskTableHeader
							table
							label={tableHeaderLabel}
							setTaskCreateModal={setTaskCreateModal}
							defaultSearchParams={defaultSearchParams}
							searchParams={searchParams}
							setSearchParams={setSearchParams}
							refreshLastQuery={refreshLastQuery}
							requiredSearchParams={requiredSearchParams}
							taskData={taskData}
							clearSearchParams={clearSearchParams}
						/>
					</Card.Header>
					<Card.Body className="p-0">
						{searching ? <p>Searching tasks....</p> : taskData.length === 0 ? <p className="ps-3">No tasks found</p> :
							<CustomAdvanceTable
								table
								headerClickFn={headerClickFn}
								appliedSortOrders={appliedSortOrders}
								headerClassName="bg-200 text-900 text-nowrap align-middle"
								rowClassName="align-middle white-space-nowrap"
								tableProps={{
									size: 'sm',
									striped: true,
									className: 'fs--1 mb-0 overflow-hidden'
								}}
							/>
						}
					</Card.Body>
					<Card.Footer>
						<CustomAdvanceTablePagination
							table
							Pages={Pages}
							activePage={activePage}
							setActivePage={setActivePage}
						/>
					</Card.Footer>
				</Card>
			</AdvanceTableWrapper>

			<TaskDetails
				parentState={taskDetailsModalState}
				setParentState={setTaskDetailsModalState}
				selectedTask={selectedTask}
				setSelectedTask={setSelectedTask}
				scheduleStartDate={scheduleStartDate}
				scheduleEndDate={scheduleEndDate}
				setScheduleStartDate={setScheduleStartDate}
				setScheduleEndDate={setScheduleEndDate}
				refreshParent={refreshLastQuery}
				account_id={account_id}
				siteAdminArea={siteAdminArea}
			/>

			<CreateTaskForm
				isOpenScheduleModal={taskCreateModal}
				setIsOpenScheduleModal={setTaskCreateModal}
				taskData={taskData}
				setTaskData={setTaskData}
				scheduleStartDate={scheduleStartDate}
				scheduleEndDate={scheduleEndDate}
				setScheduleStartDate={setScheduleStartDate}
				setScheduleEndDate={setScheduleEndDate}
				refreshParent={refreshLastQuery}
				defaultClaimants={defaultClaimants}
				account_id={account_id}
				siteAdminArea={siteAdminArea}
			/>
		</div>
	);
};

TaskTable.propTypes = {
	columns: PropTypes.array,
	account_id: PropTypes.number,
	siteAdminArea: PropTypes.bool,
	showMetrics: PropTypes.bool,
	tableHeaderLabel: PropTypes.string,
	defaultSearchParams: PropTypes.object,
	requiredSearchParams: PropTypes.array,
	defaultClaimants: PropTypes.array,
	refreshParent: PropTypes.func
};

export default TaskTable;