import React, { useContext, useState, useEffect } from 'react';
import { Card, Button, Col, Row } from 'react-bootstrap';
import { customFieldAPI } from 'utils/api/fulcrum-api';
import auth from 'utils/auth';
import CustomField from './CustomField';

const CustomFieldList = ({ account_id = auth.getProfile()?.data?.account_id, model, view, entity_id, refreshParent = () => { } }) => {
	const [customFields, setCustomFields] = useState([]);

	// Get custom fields and values
	const getCustomFieldsAndValues = async (e) => {
		if (e) e.preventDefault();
		try {
			let result = await customFieldAPI.getCustomFieldsAndValuesByEntityId(account_id, entity_id, { model });
			if (!result?.data) {
				return;
			}
			setCustomFields(result.data);
		} catch (error) {
			console.log(error);
		}
	};

	// 

	useEffect(() => {
		getCustomFieldsAndValues();
	}, []);

	return (
		<div>
			{
				customFields
					.filter(f => {
						let views = f?.views ? f.views.split(',') : [];
						if (f.enabled && !views) { return true; }
						if (f.enabled && views.includes(view)) { return true; }
					})
					.map((customField, index) => {
						console.log(customField)
						return (
							<div key={index} className="mt-3">
								<CustomField
									field={customField}
									entity_id={entity_id}
									account_id={account_id}
									refreshParent={refreshParent}
								/>
							</div>
						);
					})
			}
		</div>
	);
};


export default CustomFieldList;