import React, { useState } from 'react';
import { Card, Button, Row, Col } from 'react-bootstrap';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import CustomFieldTableHeader from './CustomFieldTableHeader';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import CustomFieldDetails from './CustomFieldDetails';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import { customFieldAPI } from 'utils/api/fulcrum-api';
import { useEffect } from 'react';
import { removeEmptyFromObj, sleep } from 'utils/functions';
import auth from 'utils/auth';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import CustomFieldFilters from './CustomFieldFilters';

const columns = [
	{
		accessor: 'model',
		Header: 'Context',
		headerProps: { className: 'pe-1' },
		cellProps: {
			className: 'py-2'
		}
	},
	{
		accessor: 'label',
		Header: 'Label',
		headerProps: { className: 'pe-1' },
		cellProps: {
			className: 'py-2'
		}
	},
	{
		accessor: 'enabled',
		Header: 'Enabled',
		headerProps: { className: 'pe-1' },
		cellProps: {
			className: 'py-2'
		},
		Cell: ({ value }) => {
			return (
				<div className="">
					{value ? <span className="text-success">Yes</span> : <span className="text-danger">No</span>}
				</div>
			);
		}
	},
	{
		accessor: 'field_type',
		Header: 'Type',
		headerProps: { className: 'pe-1' },
		cellProps: {
			className: 'py-2'
		}
	},
	{
		accessor: 'views',
		Header: 'Views',
		headerProps: { className: 'pe-1' },
		cellProps: {
			className: 'py-2'
		}
	},
	{
		accessor: 'description',
		Header: 'Description',
		headerProps: { className: 'pe-1' },
		cellProps: {
			className: 'py-2'
		}
	},
	{
		accessor: 'menu',
		Header: 'Menu',
		headerProps: { className: 'pe-1' },
		cellProps: {
			className: 'py-2'
		}
	}
];

const CustomFieldTable = ({ account_id = auth.getProfile()?.data?.account_id, defaultSearchParams = {}, requiredSearchParams = {} }) => {
	const [customFieldData, setCustomFieldData] = useState([]);
	const [, setCustomFieldCount] = useState(0);
	const [searching, setSearching] = useState(false);
	const [lastQuery, setLastQuery] = useState({});
	const [searchParams, setSearchParams] = useState({ ...defaultSearchParams });
	const [selectedCustomField, setSelectedCustomField] = useState({});
	const [customFieldDetailsModalState, setCustomFieldDetailsModalState] = useState(false);


	const formatData = (data = []) => {
		let adjustedData = data.map(cb => {
			return {
				...cb,
				menu: (
					<>
						<Button
							variant="falcon-primary"
							size="sm"
							className="me-1 float-end"
							onClick={() => handleCustomFieldSelection(cb)}
						>
							Edit
						</Button>
					</>
				)
			}
		});
		setCustomFieldData(adjustedData);
	};


	const customFieldSearchHandler = async (params = {}, saveLastQuery = false) => {
		try {
			params = removeEmptyFromObj(params);
			let customFieldResult = await customFieldAPI.searchCustomFields({ ...params, ...requiredSearchParams });
			if (!customFieldResult?.data?.rows) {
				throw new Error('No custom field data found');
			}

			formatData(customFieldResult.data.rows);
			setCustomFieldCount(customFieldResult.data.count);
			setSearching(false);

			if (saveLastQuery) {
				setLastQuery(params);
			}
		} catch (error) {
			console.log(error);
		}
	};


	const newSearch = async (params) => {
		setSearching(true);
		let adjustedSearchParams = {
			...params,
			...requiredSearchParams
		};
		customFieldSearchHandler(adjustedSearchParams, true);
	};

	// Refresh last query
	const refreshLastQuery = async () => {
		customFieldSearchHandler({ ...lastQuery, ...requiredSearchParams }, false);
	};


	// Handle customField selection
	const handleCustomFieldSelection = async (customField) => {
		setSelectedCustomField(customField);
		await sleep(500);
		setCustomFieldDetailsModalState(true);
	};

	useEffect(() => {
		newSearch({ ...defaultSearchParams });
	}, []);

	// Apply search filters
	useEffect(() => { newSearch(searchParams) }, [searchParams]);


	return (
		<>
			<AdvanceTableWrapper
				columns={columns}
				data={customFieldData}
				// selection
				sortable
				pagination
				perPage={10}
			>
				<Card className="mb-3 shadow-none">
					<Card.Header>
						<CustomFieldTableHeader
							table
							setCustomFieldDetailsModalState={setCustomFieldDetailsModalState}
							refreshLastQuery={refreshLastQuery}
						/>

						<CustomFieldFilters
							searchParams={searchParams}
							setSearchParams={setSearchParams}
							clearSearchParams={() => setSearchParams({})}
							fields={[
								"model",
								"label",
								"description",
								"enabled",
								"field_type",
								"views",
							]}
						/>
					</Card.Header>
					<Card.Body className="p-0 ">
						{
							searching ?
								<p>Loading custom fields...</p>
								:
								<>
									{/* <Row className="flex-end-center mb-3">
										<Col xs="auto" sm={6} lg={4}>
											<AdvanceTableSearchBox table />
										</Col>
									</Row> */}
									<AdvanceTable
										table
										headerClassName="bg-200 text-900 text-nowrap align-middle"
										rowClassName="align-middle white-space-nowrap"
										tableProps={{
											size: 'sm',
											striped: true,
											className: 'fs--1 mb-0 overflow-hidden'
										}}
									/>
								</>
						}

					</Card.Body>
					<Card.Footer>
						<AdvanceTablePagination table />
					</Card.Footer>
				</Card>
			</AdvanceTableWrapper>

			<CustomFieldDetails
				modalState={customFieldDetailsModalState}
				setModalState={setCustomFieldDetailsModalState}
				selectedCustomField={selectedCustomField}
				setSelectedCustomField={setSelectedCustomField}
				refreshParent={refreshLastQuery}
				account_id={account_id}
			/>

		</>
	);
};

export default CustomFieldTable;