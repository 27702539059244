import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form, ButtonGroup, Dropdown, DropdownButton } from 'react-bootstrap';
import { toast } from 'react-toastify';
import moment from 'moment';
import CustomFieldList from 'components/custom-fields/custom-field-list/CustomFieldList';
import ClaimantChildList from './children/ClaimantChildList';
import ClaimantOtherHouseholdList from './other-household/ClaimantOtherHouseholdList';
import CurrentIncomeSourceList from './current-income/CurrentIncomeSourceList';
import EmployerList from './employers/EmployerList';
import Doctors from './doctors/Doctors';
import Hospitals from './hospitals/Hospitals';
import MedicationList from './medications/MedicationList';
import ClaimantSpouse from './ClaimantSpouse';
import SendScreeningDocumentsButton from '../SendScreeningDocumentsButton';
import SendUpdateInformationRequestButton from '../SendUpdateInformationRequestButton';
import SendRetainerPacket from '../SendRetainerPacket';
import CloseFile from '../CloseFile';
import { claimantAPI } from 'utils/api/fulcrum-api';

const ClaimantBio = ({ claimant = {}, setClaimant = () => { }, refreshClaimant = () => { } } = {}) => {
  let defaultState = {
    has_spouse: claimant.has_spouse,
    spouse_name: claimant.spouse_name,
    spouse_job: claimant.spouse_job,
    spouse_disability: claimant.spouse_disability,
    has_children: claimant.has_children,
    children: claimant.children,
    others_in_household: claimant.others_in_household,
    in_kind_support_and_maintance: claimant.in_kind_support_and_maintance,
    over_ssi_asset_threshold: claimant.over_ssi_asset_threshold,
    workers_comp_claim: claimant.workers_comp_claim,
    ltd: claimant.ltd,
    education: claimant.education,
    special_education: claimant.special_education,
    literacy: claimant.literacy,
    primary_language: claimant.primary_language,
    translator: claimant.translator,
    veteran: claimant.veteran,
    scdr: claimant.scdr,
    scdr_percentage: claimant.scdr_percentage,
    scdr_impairment: claimant.scdr_impairment,
    scdriu: claimant.scdriu,
    legal: claimant.legal,
    legal_date: claimant.legal_date,
    legal_reason: claimant.legal_reason,
    working: claimant.working,
    working_full_time: claimant.working_full_time,
    last_work_date: claimant.last_work_date,
    ssdi_insured: claimant.ssdi_insured,
    employers: claimant.employers,
    alleged_impairments: claimant.alleged_impairments,
    health_insurance: claimant.health_insurance,
    ever_treating: claimant.ever_treating,
    currently_treating: claimant.currently_treating,
    voc_rehab: claimant.voc_rehab,
    daa: claimant.daa,
    doctors: claimant.doctors,
    hospitals: claimant.hospitals,
    medications: claimant.medications,
    pending_app: claimant.pending_app,
    pending_app_filing: claimant.pending_app_filing,
    prior_app_number: claimant.prior_app_number,
    pending_app_decision: claimant.pending_app_decision,
    pending_app_decision_date: claimant.pending_app_decision_date,
    pending_app_hearing: claimant.pending_app_hearing,
    pending_app_hearing_date: claimant.pending_app_hearing_date,
    prior_app: claimant.prior_app,
    prior_app_filing: claimant.prior_app_filing,
    prior_app_decision_date: claimant.prior_app_decision_date,
    prior_app_hearing: claimant.prior_app_hearing,
    prior_benefits: claimant.prior_benefits,
    current_benefits: claimant.current_benefits,
    last_bio_saved: claimant.last_bio_saved,
  };
  const [formData, setFormData] = useState(defaultState);
  const [previousFormData, setPreviousFormData] = useState(defaultState);
  const [isEditing, setIsEditing] = useState(false);
  const [retainerPacketModalState, setRetainerPacketModalState] = useState(false);
  const [sendUpdatedInfoRequestModal, setSendUpdatedInfoRequestModal] = useState(false);
  const [sendScreeningDocumentsModal, setSendScreeningDocumentsModal] = useState(false);
  const [closeFileModal, setCloseFileModal] = useState(false);

  const handleInputChange = (event) => {
    let { name, value, checked, type } = event.target;

    // if event.target is a checkbox, set value to checked
    // if (type === 'checkbox' || type === 'radio') {
    //   value = checked;
    // }

    if ([
      'has_spouse',
      'has_children',
      'spouse_disability',
      'literacy',
      'translator',
      'in_kind_support_and_maintance',
      'over_ssi_asset_threshold',
      'workers_comp_claim',
      'ltd',
      'current_benefits',
      'prior_benefits',
      'prior_app_hearing',
      'prior_app',
      'pending_app_hearing',
      'pending_app_decision',
      'pending_app',
      'daa',
      'voc_rehab',
      'translator',
      'veteran',
      'scdr',
      'scdriu',
      'legal',
      'working',
      'working_full_time',
      'ssdi_insured',
      'health_insurance',
      'special_education',
      'currently_treating'
    ].includes(name)) {
      let value = value === 'true' ? true : false;
    }
    setFormData({ ...formData, [name]: value });
  };

  // const handleEdit = () => {
  // 	setIsEditing(true);
  // 	setPreviousFormData(formData);
  // };

  // const handleCancel = () => {
  // 	setIsEditing(false);
  // 	setFormData(previousFormData);
  // };

  const saveClaimant = async (data) => {
    try { // @todo - need this to call alternative update route that uses uuid as well as claimant id
      let result = await claimantAPI.updateClaimant(claimant.id, { ...data, screened: true, last_bio_saved: moment().format('YYYY-MM-DD') });
      if (!result?.data) {
        throw new Error('No claimant data returned');
      }

      setClaimant(result.data);

      let updatedFormData = { ...formData, ...data };

      setFormData(updatedFormData);
      setPreviousFormData(updatedFormData);
      refreshClaimant();
    } catch (error) {
      console.error(error);
      toast.error(`${error}`, {
        theme: 'colored'
      });
      setFormData(previousFormData);
    }
    setIsEditing(false);
  };

  // Save on change
  const saveOnChange = (event) => {
    let { name, value, type, checked } = event.target;

    // if event.target is a checkbox, set value to checked
    // if (type === 'checkbox' || type === 'radio') {
    //   value = checked;
    // }

    if ([
      'has_spouse',
      'has_children',
      'spouse_disability',
      'literacy',
      'translator',
      'in_kind_support_and_maintance',
      'over_ssi_asset_threshold',
      'workers_comp_claim',
      'ltd',
      'current_benefits',
      'prior_benefits',
      'prior_app_hearing',
      'prior_app',
      'pending_app_hearing',
      'pending_app_decision',
      'pending_app',
      'daa',
      'voc_rehab',
      'translator',
      'veteran',
      'scdr',
      'scdriu',
      'legal',
      'working',
      'working_full_time',
      'ssdi_insured',
      'health_insurance',
      'special_education',
      'currently_treating'
    ].includes(name)) {
      value = value === 'true' ? true : false;
    }

    if (previousFormData[name] !== value) {
      saveClaimant({ [name]: value });
    }
  };



  return (
    <div id="claimant-bio-container">
      <div className="mb-3 py-3 px-2 bg-200">
        <h5 className="d-inline">Claimant Bio</h5>
        <DropdownButton
          as={ButtonGroup}
          id={'dropdown-variants-'}
          variant={'primary'}
          title={"Options"}
          className='me-2 mb-2 float-end'
        >
          {
            claimant.screened == false &&
            <Dropdown.Item onClick={() => { setSendScreeningDocumentsModal(true) }} eventKey="2">Send Screening Documents</Dropdown.Item>
          }

          {
            claimant.screened == true &&
            <>
              <Dropdown.Item onClick={() => { setSendUpdatedInfoRequestModal(true) }} eventKey="2">Request Updated Information</Dropdown.Item>
              <Dropdown.Item onClick={() => { setRetainerPacketModalState(true) }} eventKey="2">Send Retainer Packet</Dropdown.Item>
              <Dropdown.Divider />
              <Dropdown.Item className="text-danger" onClick={() => { setRetainerPacketModalState(true) }} eventKey="2">Close the File</Dropdown.Item>
            </>
          }
        </DropdownButton>
        <br />
        <span>Last Bio Saved: {formData?.last_bio_saved ? moment(formData.last_bio_saved).format('MM/DD/YYYY hh:mm A') : 'NA'}</span>
      </div>


      <SendUpdateInformationRequestButton modalState={sendUpdatedInfoRequestModal} setModalState={setSendUpdatedInfoRequestModal} claimant={claimant} setClaimant={setClaimant} />
      <SendRetainerPacket
        claimant={claimant}
        modalState={retainerPacketModalState}
        setModalState={setRetainerPacketModalState}
        defaultDocumentPacketTitle={"Retainer Packet"}
        defaultPacketDocumentTypeId={"RETAINER_PACKET"}
        defaultAttachedForms={[
          { id: 33 },
          { id: 20 },
          { id: 21 },
          { id: 47 },
          { id: 34 },
          { id: 22 },
        ]}
      />
      <SendScreeningDocumentsButton modalState={sendScreeningDocumentsModal} setModalState={setSendScreeningDocumentsModal} claimant={claimant} setClaimant={setClaimant} />
      <CloseFile modalState={closeFileModal} setModalState={setCloseFileModal} claimant={claimant} setClaimant={setClaimant} />
      <h5>Living Situation</h5>
      <hr />
      <ClaimantSpouse
        claimant={claimant}
        setClaimant={setClaimant}
        saveClaimant={saveClaimant}
        saveOnChange={saveOnChange}
      />

      <Form.Group controlId="has_children">
        <Form.Label>
          Has Children:
        </Form.Label> &nbsp;
        <Form.Check
          type="radio"
          inline
          name="has_children"
          value={"true"}
          label="Yes"
          checked={formData.has_children}
          id="hasChildYes"
          onChange={e => { handleInputChange(e); saveOnChange(e) }}
        />
        <Form.Check
          type="radio"
          inline
          name="has_children"
          value={"false"}
          label="No"
          checked={!formData.has_children}
          id="hasChildNo"
          onChange={e => { handleInputChange(e); saveOnChange(e) }}
        />
        {/* <Form.Control
          as="select"
          name="has_children"
          value={formData.has_children}
          onChange={e => { saveOnChange(e) }}
        >
          <option value={"true"}>Yes</option>
          <option value={"false"}>No</option>
        </Form.Control> */}
      </Form.Group>

      {
        formData.has_children &&
        <ClaimantChildList
          claimant={claimant}
          setClaimant={setClaimant}
        />
      }

      <ClaimantOtherHouseholdList
        claimant={claimant}
        setClaimant={setClaimant}
      />
      <CurrentIncomeSourceList
        claimant={claimant}
        setClaimant={setClaimant}
      />

      <Form>
        <Form.Group controlId="over_ssi_asset_threshold">
          <Form.Label>
            Over SSI Asset Threshold:
          </Form.Label> &nbsp;
          <Form.Check
            type="radio"
            inline
            label="Yes"
            name="over_ssi_asset_threshold"
            value="true"
            checked={formData.over_ssi_asset_threshold === true}
            id="overSsiAssetThresholdYes"
            onChange={e => { saveOnChange(e) }}
          />
          <Form.Check
            type="radio"
            inline
            label="No"
            name="over_ssi_asset_threshold"
            value={"false"}
            checked={formData.over_ssi_asset_threshold === false}
            id="overSsiAssetThresholdNo"
            onChange={e => { saveOnChange(e) }}
          />
          {/* <Form.Control
            as="select"
            name="over_ssi_asset_threshold"
            value={formData.over_ssi_asset_threshold}
            onChange={e => { handleInputChange(e); saveOnChange(e) }}
          >
            <option value={"true"}>Yes</option>
            <option value={"false"}>No</option>
          </Form.Control> */}
        </Form.Group>

        <Form.Group controlId="in_kind_support_and_maintance">
          <Form.Label>
            Receiving In-Kind Support and Maintenance:
          </Form.Label>&nbsp;
          <Form.Check
            type="radio"
            inline
            label="Yes"
            name="in_kind_support_and_maintance"
            value={"true"}
            checked={formData.in_kind_support_and_maintance === true}
            id="inKindSupportAndMaintanceYes"
            onChange={e => { saveOnChange(e) }}
          />
          <Form.Check
            type="radio"
            inline
            label="No"
            name="in_kind_support_and_maintance"
            value={"false"}
            checked={formData.in_kind_support_and_maintance === false}
            id="inKindSupportAndMaintanceNo"
            onChange={e => { saveOnChange(e) }}
          />

          {/* <Form.Control
            as="select"
            name="in_kind_support_and_maintance"
            value={formData.in_kind_support_and_maintance}
            onChange={e => { handleInputChange(e); saveOnChange(e) }}
          >
            <option value={"true"}>Yes</option>
            <option value={"false"}>No</option>
          </Form.Control> */}
        </Form.Group>

        <Form.Group controlId="workers_comp_claim">
          <Form.Label>
            Workers' Compensation Claim:
          </Form.Label>&nbsp;
          <Form.Check
            type="radio"
            inline
            label="Yes"
            name="workers_comp_claim"
            value={"true"}
            checked={formData.workers_comp_claim === true}
            id="workersCompClaimYes"
            onChange={e => { saveOnChange(e) }}
          />
          <Form.Check
            type="radio"
            inline
            label="No"
            name="workers_comp_claim"
            value={"false"}
            checked={formData.workers_comp_claim === false}
            id="workersCompClaimNo"
            onChange={e => { saveOnChange(e) }}
          />

          {/* <Form.Control
            as="select"
            name="workers_comp_claim"
            value={formData.workers_comp_claim}
            onChange={e => { handleInputChange(e); saveOnChange(e) }}
          >
            <option value={"true"}>Yes</option>
            <option value={"false"}>No</option>
          </Form.Control> */}
        </Form.Group>

        <Form.Group controlId="ltd">
          <Form.Label>
            Private Disability Claim:
          </Form.Label>&nbsp;
          <Form.Check
            type="radio"
            inline
            label="Yes"
            name="ltd"
            value={"true"}
            checked={formData.ltd === true}
            id="ltdYes"
            onChange={e => { saveOnChange(e) }}
          />
          <Form.Check
            type="radio"
            inline
            label="No"
            name="ltd"
            value={"false"}
            checked={formData.ltd === false}
            id="ltdNo"
            onChange={e => { saveOnChange(e) }}
          />

          {/* <Form.Control
            as="select"
            name="ltd"
            value={formData.ltd}
            onChange={e => { handleInputChange(e); saveOnChange(e) }}
          >
            <option value={"true"}>Yes</option>
            <option value={"false"}>No</option>
          </Form.Control> */}
        </Form.Group>

      </Form>



      <div id="claimant-spouse-container" className="my-3">
        <h5 className="d-inline">Vocational Profile</h5>
        <hr />
        <Form>

          <hr />
          <Form.Group controlId="education">
            <Form.Label>
              Education
            </Form.Label>
            <Form.Control
              type="text"
              name="education"
              value={formData.education}
              onChange={handleInputChange}
              onBlur={saveOnChange}
            />
          </Form.Group>
          <Form.Group controlId="special_education">
            <Form.Label>
              Special Education:
            </Form.Label>&nbsp;
            <Form.Check
              type="radio"
              inline
              label="Yes"
              name="special_education"
              value={"true"}
              checked={formData.special_education === true}
              id="specialEducationYes"
              onChange={e => { saveOnChange(e) }}
            />
            <Form.Check
              type="radio"
              inline
              label="No"
              name="special_education"
              value={"false"}
              checked={formData.special_education === false}
              id="specialEducationNo"
              onChange={e => { saveOnChange(e) }}
            />
            {/* <Form.Control
              as="select"
              name="special_education"
              value={formData.special_education}
              onChange={e => { handleInputChange(e); saveOnChange(e) }}
            >
              <option value='' disabled>--Select one--</option>
              <option value={"true"}>Yes</option>
              <option value={"false"}>No</option>
            </Form.Control> */}
          </Form.Group>
          <Form.Group controlId="literacy">
            <Form.Label>
              Able to read, write, understand English:
            </Form.Label>&nbsp;
            <Form.Check
              type="radio"
              inline
              label="Yes"
              name="literacy"
              value={"true"}
              checked={formData.literacy === true}
              id="literacyYes"
              onChange={e => { saveOnChange(e) }}
            />
            <Form.Check
              type="radio"
              inline
              label="No"
              name="literacy"
              value={"false"}
              checked={formData.literacy === false}
              id="literacyNo"
              onChange={e => { saveOnChange(e) }}
            />

            {/* <Form.Control
              as="select"
              name="literacy"
              value={formData.literacy}
              onChange={e => { handleInputChange(e); saveOnChange(e) }}
            >
              <option value='' disabled>--Select one--</option>
              <option value={"true"}>Yes</option>
              <option value={"false"}>No</option>
            </Form.Control> */}
          </Form.Group>
          {
            !formData.literacy &&
            <>
              <Form.Group controlId="primary_language">
                <Form.Label>
                  Primary Language
                </Form.Label>
                <Form.Control
                  type="text"
                  name="primary_language"
                  value={formData.primary_language}
                  onChange={handleInputChange}
                  onBlur={saveOnChange}
                />
              </Form.Group>
              <Form.Group controlId="translator">
                <Form.Label>
                  Requires Translator:
                </Form.Label>&nbsp;
                <Form.Check
                  type="radio"
                  inline
                  label="Yes"
                  name="translator"
                  value={"true"}
                  id="translatorYes"
                  checked={formData.translator === true}
                  onChange={e => { saveOnChange(e) }}
                />
                <Form.Check
                  type="radio"
                  inline
                  label="No"
                  name="translator"
                  value={"false"}
                  id="translatorNo"
                  checked={formData.translator === false}
                  onChange={e => { saveOnChange(e) }}
                />

                {/* <Form.Control
                  as="select"
                  name="translator"
                  value={formData.translator}
                  onChange={e => { handleInputChange(e); saveOnChange(e) }}
                >
                  <option value={"true"}>Yes</option>
                  <option value={"false"}>No</option>
                </Form.Control> */}
              </Form.Group>
            </>
          }

          <hr />
          <Form.Group controlId="veteran">
            <Form.Label>
              Veteran:
            </Form.Label>&nbsp;
            <Form.Check
              type="radio"
              inline
              label="Yes"
              name="veteran"
              value={"true"}
              checked={formData.veteran === true}
              id="veteranYes"
              onChange={e => { saveOnChange(e) }}
            />
            <Form.Check
              type="radio"
              inline
              label="No"
              name="veteran"
              value={"false"}
              checked={formData.veteran === false}
              id="veteranNo"
              onChange={e => { saveOnChange(e) }}
            />
            {/* <Form.Control
              as="select"
              name="veteran"
              value={formData.veteran}
              onChange={e => { handleInputChange(e); saveOnChange(e) }}
            >
              <option value='' disabled>--Select one--</option>
              <option value={"true"}>Yes</option>
              <option value={"false"}>No</option>
            </Form.Control> */}
          </Form.Group>

          {
            formData.veteran &&
            <>
              <Form.Group controlId="scdr">
                <Form.Label>
                  VA Disability Benefits:
                </Form.Label>&nbsp;
                <Form.Check
                  type="radio"
                  inline
                  label="Yes"
                  name="scdr"
                  value={"true"}
                  checked={formData.scdr === true}
                  id="scdrYes"
                  onChange={e => { saveOnChange(e) }}
                />
                <Form.Check
                  type="radio"
                  inline
                  label="No"
                  name="scdr"
                  value={"false"}
                  checked={formData.scdr === false}
                  id="scdrNo"
                  onChange={e => { saveOnChange(e) }}
                />
                {/* <Form.Control
                  as="select"
                  name="scdr"
                  value={formData.scdr}
                  onChange={e => { handleInputChange(e); saveOnChange(e) }}
                >
                  <option value={"true"}>Yes</option>
                  <option value={"false"}>No</option>
                </Form.Control> */}
              </Form.Group>
              <Form.Group controlId="scdr_percentage">
                <Form.Label>
                  Service-Connected Disability Rating
                </Form.Label>
                <Form.Control
                  type="number"
                  name="scdr_percentage"
                  value={formData.scdr_percentage}
                  onChange={handleInputChange}
                  onBlur={saveOnChange}
                />
              </Form.Group>

              <Form.Group controlId="scdr_impairment">
                <Form.Label>
                  Service-Connected Impairments
                </Form.Label>
                <Form.Control
                  type="text"
                  name="scdr_impairment"
                  value={formData.scdr_impairment}
                  onChange={handleInputChange}
                  onBlur={saveOnChange}
                />
              </Form.Group>

              <Form.Group controlId="scdriu">
                <Form.Label>
                  Qualified for Individual Unemployability:
                </Form.Label>&nbsp;
                <Form.Check
                  type="radio"
                  inline
                  name="scdriu"
                  value={"true"}
                  checked={formData.scdriu === true}
                  label="Yes"
                  id="scdriu_yes"
                  onChange={e => { saveOnChange(e) }}
                />
                <Form.Check
                  type="radio"
                  inline
                  name="scdriu"
                  value={"false"}
                  checked={formData.scdriu === false}
                  label="No"
                  id="scdriu_no"
                  onChange={e => { saveOnChange(e) }}
                />
                {/* <Form.Control
                  as="select"
                  name="scdriu"
                  value={formData.scdriu}
                  onChange={e => { handleInputChange(e); saveOnChange(e) }}
                >
                  <option value={"true"}>Yes</option>
                  <option value={"false"}>No</option>
                </Form.Control> */}
              </Form.Group>

            </>
          }


          <hr />
          <Form.Group controlId="legal">
            <Form.Label>
              History of arrest/incarceration:
            </Form.Label>&nbsp;
            <Form.Check
              type="radio"
              inline
              name="legal"
              value={"true"}
              checked={formData.legal === true}
              label="Yes"
              id="legal_yes"
              onChange={e => { saveOnChange(e) }}
            />
            <Form.Check
              type="radio"
              inline
              name="legal"
              value={"false"}
              checked={formData.legal === false}
              label="No"
              id="legal_no"
              onChange={e => { saveOnChange(e) }}
            />
            {/* <Form.Control
              as="select"
              name="legal"
              value={formData.legal}
              onChange={e => { handleInputChange(e); saveOnChange(e) }}
            >
              <option value='' disabled>--Select one--</option>
              <option value={"true"}>Yes</option>
              <option value={"false"}>No</option>
            </Form.Control> */}
          </Form.Group>

          {
            formData.legal &&
            <>
              <Form.Group controlId="legal_date">
                <Form.Label>
                  Date of arrest/incarceration:
                </Form.Label>
                <Form.Control
                  type="date"
                  name="legal_date"
                  value={formData.legal_date}
                  onChange={handleInputChange}
                  onBlur={saveOnChange}
                />
              </Form.Group>
              <Form.Group controlId="legal_reason">
                <Form.Label>
                  Reason for arrest/incarceration:
                </Form.Label>
                <Form.Control
                  type="text"
                  name="legal_reason"
                  value={formData.legal_reason}
                  onChange={handleInputChange}
                  onBlur={saveOnChange}
                />
              </Form.Group>
            </>
          }

          <hr />
          <Form.Group controlId="working">
            <Form.Label>
              Currently Working:
            </Form.Label>&nbsp;
            <Form.Check
              type="radio"
              inline
              name="working"
              label="Yes"
              value={"true"}
              checked={formData.working === true}
              onChange={e => { saveOnChange(e) }}
              id="working_yes"
            />
            <Form.Check
              type="radio"
              inline
              name="working"
              label="No"
              value={"false"}
              checked={formData.working === false}
              onChange={e => { saveOnChange(e) }}
              id="working_no"
            />
            {/* <Form.Control
              as="select"
              name="working"
              value={formData.working}
              onChange={e => { handleInputChange(e); saveOnChange(e) }}
            >
              <option value='' disabled>--Select one--</option>
              <option value={"true"}>Yes</option>
              <option value={"false"}>No</option>
            </Form.Control> */}
          </Form.Group>

          {
            formData.working ?
              <>
                <Form.Group controlId="working_full_time">
                  <Form.Label>
                    Full-Time:
                  </Form.Label>&nbsp;
                  <Form.Check
                    type="radio"
                    inline
                    name="working_full_time"
                    value={"true"}
                    checked={formData.working_full_time === true}
                    id="working_full_time_true"
                    onChange={e => { saveOnChange(e) }}
                  />
                  <Form.Check
                    type="radio"
                    inline
                    name="working_full_time"
                    value={"false"}
                    checked={formData.working_full_time === false}
                    id="working_full_time_false"
                    onChange={e => { saveOnChange(e) }}
                  />
                  {/* <Form.Control
                    as="select"
                    name="working_full_time"
                    value={formData.working_full_time}
                    onChange={e => { handleInputChange(e); saveOnChange(e) }}
                  >
                    <option value={"true"}>Yes</option>
                    <option value={"false"}>No</option>
                  </Form.Control> */}
                </Form.Group>
              </>
              :
              <Form.Group controlId="last_work_date">
                <Form.Label>
                  Last worked
                </Form.Label>
                <Form.Control
                  type="date"
                  name="last_work_date"
                  value={formData.last_work_date}
                  onChange={handleInputChange}
                  onBlur={saveOnChange}
                />
              </Form.Group>
          }

          <Form.Group controlId="ssdi_insured">
            <Form.Label>
              Worked Five of last Ten Years:
            </Form.Label>&nbsp;
            <Form.Check
              type="radio"
              name="ssdi_insured"
              inline
              label="Yes"
              value={"true"}
              checked={formData.ssdi_insured === true}
              onChange={e => { saveOnChange(e) }}
              id="ssdi_insured_yes"
            />
            <Form.Check
              type="radio"
              name="ssdi_insured"
              inline
              label="No"
              value={"false"}
              checked={formData.ssdi_insured === false}
              onChange={e => { saveOnChange(e) }}
              id="ssdi_insured_no"
            />
            {/* <Form.Control
              as="select"
              name="ssdi_insured"
              value={formData.ssdi_insured}
              onChange={e => { handleInputChange(e); saveOnChange(e) }}
            >
              <option value='' disabled>--Select one--</option>
              <option value={"true"}>Yes</option>
              <option value={"false"}>No</option>
            </Form.Control> */}
          </Form.Group>


          <EmployerList
            claimant={claimant}
            setClaimant={setClaimant}
          />

          <h5 className="d-inline">Medical Profile</h5>
          <hr />
          <Form.Group controlId="alleged_impairments">
            <Form.Label>
              Alleged Impairments
            </Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              name="alleged_impairments"
              value={formData.alleged_impairments}
              onChange={e => { handleInputChange(e) }}
              onBlur={e => { saveOnChange(e) }}
            />
          </Form.Group>
          <Form.Group controlId="health_insurance">
            <Form.Label>
              Health Insurance:
            </Form.Label>&nbsp;
            <Form.Check
              type="radio"
              inline
              label="Yes"
              name="health_insurance"
              value={"true"}
              checked={formData.health_insurance === true}
              id="health_insurance"
              onChange={e => { saveOnChange(e) }}
            />
            <Form.Check
              type="radio"
              inline
              label="No"
              name="health_insurance"
              value={"false"}
              checked={formData.health_insurance === false}
              id="health_insurance"
              onChange={e => { saveOnChange(e) }}
            />
            {/* <Form.Control
              as="select"
              name="health_insurance"
              value={formData.health_insurance}
              onChange={e => { handleInputChange(e); saveOnChange(e) }}
            >
              <option value='' disabled>--Select one--</option>
              <option value={"true"}>Yes</option>
              <option value={"false"}>No</option>
            </Form.Control> */}
          </Form.Group>

          <Form.Group controlId="ever_treating">
            <Form.Label>
              History of Treatment:
            </Form.Label>&nbsp;
            <Form.Check
              type="radio"
              inline
              label="Yes"
              name="ever_treating"
              value={"true"}
              checked={formData.ever_treating === true}
              id="ever_treating"
              onChange={e => { saveOnChange(e) }}
            />
            <Form.Check
              type="radio"
              inline
              label="No"
              name="ever_treating"
              value={"false"}
              checked={formData.ever_treating === false}
              id="ever_treating"
              onChange={e => { saveOnChange(e) }}
            />

            {/* <Form.Control
              as="select"
              name="ever_treating"
              value={formData.ever_treating}
              onChange={e => { handleInputChange(e); saveOnChange(e) }}
            >
              <option value='' disabled>--Select one--</option>
              <option value={"true"}>Yes</option>
              <option value={"false"}>No</option>
            </Form.Control> */}
          </Form.Group>


          <Form.Group controlId="currently_treating">
            <Form.Label>
              Currently Treating:
            </Form.Label>&nbsp;
            <Form.Check
              type="radio"
              inline
              label="Yes"
              name="currently_treating"
              value={"true"}
              checked={formData.currently_treating === true}
              id="currently_treating"
              onChange={e => { saveOnChange(e) }}
            />
            <Form.Check
              type="radio"
              inline
              label="No"
              name="currently_treating"
              value={"false"}
              checked={formData.currently_treating === false}
              id="currently_treating"
              onChange={e => { saveOnChange(e) }}
            />

            {/* <Form.Control
              as="select"
              name="currently_treating"
              value={formData.currently_treating}
              onChange={e => { handleInputChange(e); saveOnChange(e) }}
            >
              <option value='' disabled>--Select one--</option>
              <option value={"true"}>Yes</option>
              <option value={"false"}>No</option>
            </Form.Control> */}
          </Form.Group>

          <Form.Group controlId="voc_rehab">
            <Form.Label>
              History of Vocational Rehabilitation:
            </Form.Label>&nbsp;
            <Form.Check
              type="radio"
              inline
              label="Yes"
              name="voc_rehab"
              value={"true"}
              checked={formData.voc_rehab === true}
              id="voc_rehab-yes"
              onChange={e => { saveOnChange(e) }}
            />
            <Form.Check
              type="radio"
              inline
              label="No"
              name="voc_rehab"
              value={"false"}
              checked={formData.voc_rehab === false}
              id="voc_rehab-no"
              onChange={e => { saveOnChange(e) }}
            />
            {/* <Form.Control
              as="select"
              name="voc_rehab"
              value={formData.voc_rehab}
              onChange={e => { handleInputChange(e); saveOnChange(e) }}
            >
              <option value='' disabled>--Select one--</option>
              <option value={"true"}>Yes</option>
              <option value={"false"}>No</option>
            </Form.Control> */}
          </Form.Group>

          <Form.Group controlId="daa">
            <Form.Label>
              History of Substance Abuse Treatment:
            </Form.Label>&nbsp;
            <Form.Check
              type="radio"
              inline
              label="Yes"
              name="daa"
              value={"true"}
              checked={formData.daa === true}
              id="daa-yes"
              onChange={e => { saveOnChange(e) }}
            />
            <Form.Check
              type="radio"
              inline
              label="No"
              name="daa"
              value={"false"}
              checked={formData.daa === false}
              id="daa-no"
              onChange={e => { saveOnChange(e) }}
            />
            {/* <Form.Control
              as="select"
              name="daa"
              value={formData.daa}
              onChange={e => { handleInputChange(e); saveOnChange(e) }}
            >
              <option value='' disabled>--Select one--</option>
              <option value={"true"}>Yes</option>
              <option value={"false"}>No</option>
            </Form.Control> */}
          </Form.Group>

          <Doctors
            claimant={claimant}
            setClaimant={setClaimant}
            saveClaimant={saveClaimant}
            saveOnChange={saveOnChange}
          />

          <Hospitals
            claimant={claimant}
            setClaimant={setClaimant}
            saveClaimant={saveClaimant}
            saveOnChange={saveOnChange}
          />

          <MedicationList
            claimant={claimant}
            setClaimant={setClaimant}
            saveClaimant={saveClaimant}
            saveOnChange={saveOnChange}
          />

          <h5 className="d-inline">Social Security Summary</h5>
          <hr />
          <Form.Group controlId="pending_app">
            <Form.Label>
              Pending Social Security Disability Application:
            </Form.Label>&nbsp;
            <Form.Check
              type="radio"
              inline
              label="Yes"
              name="pending_app"
              value={"true"}
              checked={formData.pending_app === true}
              id="pending_app_yes"
              onChange={e => { saveOnChange(e) }}
            />
            <Form.Check
              type="radio"
              inline
              label="No"
              name="pending_app"
              value={"false"}
              checked={formData.pending_app === false}
              id="pending_app_no"
              onChange={e => { saveOnChange(e) }}
            />
            {/* <Form.Control
              as="select"
              name="pending_app"
              value={formData.pending_app}
              onChange={e => { handleInputChange(e); saveOnChange(e) }}
            >
              <option value='' disabled>--Select one--</option>
              <option value={"true"}>Yes</option>
              <option value={"false"}>No</option>
            </Form.Control> */}
          </Form.Group>

          {
            formData.pending_app &&
            <>
              <Form.Group controlId="pending_app_filing">
                <Form.Label>
                  Filed
                </Form.Label>
                <Form.Control
                  type="date"
                  name="pending_app_filing"
                  value={formData.pending_app_filing}
                  onChange={handleInputChange}
                  onBlur={saveOnChange}
                />
              </Form.Group>
              <Form.Group controlId="pending_app_decision">
                <Form.Label>
                  Received Decision:
                </Form.Label>&nbsp;
                <Form.Check
                  type="radio"
                  inline
                  name="pending_app_decision"
                  value={"true"}
                  id="pending_app_decision_true"
                  label="Yes"
                  checked={formData.pending_app_decision === true}
                  onChange={e => { saveOnChange(e) }}
                />
                <Form.Check
                  type="radio"
                  inline
                  name="pending_app_decision"
                  value={"false"}
                  id="pending_app_decision_false"
                  label="No"
                  checked={formData.pending_app_decision === false}
                  onChange={e => { saveOnChange(e) }}
                />

                {/* <Form.Control
                  as="select"
                  name="pending_app_decision"
                  value={formData.pending_app_decision}
                  onChange={e => { handleInputChange(e); saveOnChange(e) }}
                >
                  <option value={"true"}>Yes</option>
                  <option value={"false"}>No</option>
                </Form.Control> */}
              </Form.Group>

              {
                formData.pending_app_decision &&
                <Form.Group controlId="pending_app_decision_date">
                  <Form.Label>
                    Decision Date
                  </Form.Label>
                  <Form.Control
                    type="date"
                    name="pending_app_decision_date"
                    value={formData.pending_app_decision_date}
                    onChange={handleInputChange}
                    onBlur={saveOnChange}
                  />
                </Form.Group>
              }


              <Form.Group controlId="pending_app_hearing">
                <Form.Label>
                  Attended Hearing:
                </Form.Label>&nbsp;
                <Form.Check
                  type="radio"
                  inline
                  label="Yes"
                  name="pending_app_hearing"
                  value={"true"}
                  id="pending_app_hearing_yes"
                  checked={formData.pending_app_hearing === true}
                  onChange={e => { saveOnChange(e) }}
                />
                <Form.Check
                  type="radio"
                  inline
                  label="No"
                  name="pending_app_hearing"
                  value={"false"}
                  id="pending_app_hearing_no"
                  checked={formData.pending_app_hearing === false}
                  onChange={e => { saveOnChange(e) }}
                />
                {/* <Form.Control
                  as="select"
                  name="pending_app_hearing"
                  value={formData.pending_app_hearing}
                  onChange={e => { handleInputChange(e); saveOnChange(e) }}
                >

                  <option value='' disabled>--Select one--</option>
                  <option value={"true"}>Yes</option>
                  <option value={"false"}>No</option>
                </Form.Control> */}
              </Form.Group>

              {
                formData.pending_app_hearing &&
                <Form.Group controlId="pending_app_hearing_date">
                  <Form.Label>
                    Hearing Date
                  </Form.Label>
                  <Form.Control
                    type="date"
                    name="pending_app_hearing_date"
                    value={formData.pending_app_hearing_date}
                    onChange={handleInputChange}
                    onBlur={saveOnChange}
                  />
                </Form.Group>
              }


            </>
          }


          <Form.Group controlId="prior_app">
            <Form.Label>
              Prior Social Security Disability Application:
            </Form.Label> &nbsp;
            <Form.Check
              type="radio"
              inline
              label="Yes"
              name="prior_app"
              value={"true"}
              id="prior_app_yes"
              checked={formData.prior_app === true}
              onChange={e => { saveOnChange(e) }}
            />
            <Form.Check
              type="radio"
              inline
              label="No"
              name="prior_app"
              value={"false"}
              id="prior_app_no"
              checked={formData.prior_app === false}
              onChange={e => { saveOnChange(e) }}
            />
            {/* <Form.Control
              as="select"
              name="prior_app"
              value={formData.prior_app}
              onChange={e => { handleInputChange(e); saveOnChange(e) }}
            >
              <option value='' disabled>--Select one--</option>
              <option value={"true"}>Yes</option>
              <option value={"false"}>No</option>
            </Form.Control> */}
          </Form.Group>

          {
            formData.prior_app &&
            <>
              <Form.Group controlId="prior_app_number">
                <Form.Label>
                  Number of Prior Applications:
                </Form.Label>
                <Form.Control
                  type="number"
                  name="prior_app_number"
                  value={formData.prior_app_number}
                  onChange={handleInputChange}
                  onBlur={saveOnChange}
                />
              </Form.Group>
              <Form.Group controlId="prior_app_filing">
                <Form.Label>
                  Last Application:
                </Form.Label>
                <Form.Control
                  type="date"
                  name="prior_app_filing"
                  value={formData.prior_app_filing}
                  onChange={handleInputChange}
                  onBlur={saveOnChange}
                />
              </Form.Group>
              <Form.Group controlId="prior_app_hearing">
                <Form.Label>
                  Prior Hearing:
                </Form.Label>&nbsp;
                <Form.Check
                  type="radio"
                  inline
                  label="Yes"
                  name="prior_app_hearing"
                  value={"true"}
                  id="prior_app_hearing_yes"
                  checked={formData.prior_app_hearing === true}
                  onChange={e => { saveOnChange(e) }}
                />
                <Form.Check
                  type="radio"
                  inline
                  label="No"
                  name="prior_app_hearing"
                  value={"false"}
                  id="prior_app_hearing_no"
                  checked={formData.prior_app_hearing === false}
                  onChange={e => { saveOnChange(e) }}
                />


                {/* <Form.Control
                  as="select"
                  name="prior_app_hearing"
                  value={formData.prior_app_hearing}
                  onChange={e => { handleInputChange(e); saveOnChange(e) }}
                >

                  <option value='' disabled>--Select one--</option>
                  <option value={"true"}>Yes</option>
                  <option value={"false"}>No</option>
                </Form.Control> */}
              </Form.Group>

              {
                formData.prior_app_decision_date &&
                <Form.Group controlId="prior_app_decision_date">
                  <Form.Label>
                    Decision Date:
                  </Form.Label>
                  <Form.Control
                    as="date"
                    name="prior_app_decision_date"
                    value={formData.prior_app_decision_date}
                    onChange={handleInputChange}
                    onBlur={saveOnChange}
                  />
                </Form.Group>
              }
            </>
          }

          <Form.Group controlId="prior_benefits">
            <Form.Label>
              Previously Received Social Security Disability Benefits:
            </Form.Label> &nbsp;
            <Form.Check
              type="radio"
              inline
              label="Yes"
              name="prior_benefits"
              value={"true"}
              id="prior_benefits_yes"
              checked={formData.prior_benefits === true}
              onChange={e => { saveOnChange(e) }}
            />
            <Form.Check
              type="radio"
              inline
              label="No"
              name="prior_benefits"
              value={"false"}
              id="prior_benefits_no"
              checked={formData.prior_benefits === false}
              onChange={e => { saveOnChange(e) }}
            />
            {/* <Form.Control
              as="select"
              name="prior_benefits"
              value={formData.prior_benefits}
              onChange={e => { handleInputChange(e); saveOnChange(e) }}
            >

              <option value='' disabled>--Select one--</option>
              <option value={"true"}>Yes</option>
              <option value={"false"}>No</option>
            </Form.Control> */}
          </Form.Group>

          <Form.Group controlId="current_benefits">
            <Form.Label>
              Currently Receiving Social Security Disability Benefits:
            </Form.Label> &nbsp;
            <Form.Check
              type="radio"
              inline
              label="Yes"
              name="current_benefits"
              value={"true"}
              id="current_benefits_yes"
              checked={formData.current_benefits === true}
              onChange={e => { saveOnChange(e) }}
            />
            <Form.Check
              type="radio"
              inline
              label="No"
              name="current_benefits"
              value={"false"}
              id="current_benefits_no"
              checked={formData.current_benefits === false}
              onChange={e => { saveOnChange(e) }}
            />


            {/* <Form.Control
              as="select"
              name="current_benefits"
              value={formData.current_benefits}
              onChange={e => { handleInputChange(e); saveOnChange(e) }}
            >

              <option value='' disabled>--Select one--</option>
              <option value={"true"}>Yes</option>
              <option value={"false"}>No</option>
        </Form.Control>*/}
          </Form.Group>

          <CustomFieldList
            account_id={claimant.account_id}
            model={"CLAIMANT"}
            entity_id={claimant.entity_id}
            view={'CLAIMANT_BIO'}
            options={{}}
          />


        </Form>
      </div>
    </div >
  );
};

ClaimantBio.propTypes = {
  claimant: PropTypes.object,
  setClaimant: PropTypes.func,
  formData: PropTypes.object,
  refreshClaimant: PropTypes.func,
};

export default ClaimantBio;