import React, { useContext, useState } from 'react';
import { Button, Form, Modal, Table, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPlus } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom';
import AppContext from 'context/Context';
import { taskAPI } from 'utils/api/fulcrum-api';
import auth from 'utils/auth';
import ClaimantQuickFind from 'components/claimants/find-a-claimant/ClaimantQuickFind';
import UserQuickFind from 'components/users/find-a-user/UserQuickFind';
import moment from 'moment';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import DatePicker from 'react-datepicker';

const TaskDetails = ({
	parentState,
	setParentState,
	selectedTask,
	setSelectedTask,
	siteAdminArea = false,
	account_id = auth.getProfile()?.data?.account_id || null,
	refreshParent = () => { }
}) => {
	const {
		config: { isDark }
	} = useContext(AppContext);
	const defaultFormData = {
		task_type_id: null,
		task_status_id: null,
		title: '',
		description: '',
		due_date: "",
	};

	const [currentTask, setCurrentTask] = useState(defaultFormData);
	const [assignedUsers, setAssignedUsers] = useState([]);
	const [loading, setLoading] = useState(true);
	const [taskTypes, setTaskTypes] = useState([]);
	const [taskStatuses, setTaskStatuses] = useState([]);
	const [formData, setFormData] = useState(defaultFormData);
	const [prevoiusFormData, setPreviousFormData] = useState(defaultFormData);
	const [users] = useState([]);

	// Search task 
	const searchTask = async (taskId) => {
		try {
			const result = await taskAPI.getTask(selectedTask?.account_id || null, taskId);
			if (!result?.data) {
				throw new Error('No task data found');
			}

			setAssignedUsers(result.data?.assigned_users || []);

			let newTaskData = {
				task_type_id: result.data.task_type_id,
				task_status_id: result.data.task_status_id,
				title: result.data.title,
				description: result.data.description,
				due_date: result.data.due_date,
			};
			setFormData(newTaskData);
			setPreviousFormData(newTaskData);
			setCurrentTask(result?.data);
		} catch (error) {
			handleClose();
			toast.error('Error getting the task details', { theme: 'colored' })
			console.log(error);
		}
		setLoading(false);
	};

	const handleChange = ({ target }) => {
		let { name, value } = target;
		let additionalValues = {};

		if (['task_type_id'].includes(name)) {
			additionalValues = {
				title: taskTypes.find((taskType) => taskType.id === value)?.name || '',
				description: ''
			}
		}

		setFormData({ ...formData, ...additionalValues, [name]: value });
	};

	const updateTask = async (data) => {
		try {
			let result = await taskAPI.updateTask(selectedTask?.account_id || null, currentTask.id, data);
			if (!result?.data) {
				throw new Error('Error updating task');
			}
			setCurrentTask(result?.data);
			refreshParent();
			toast.success('Task updated successfully', {
				theme: 'colored'
			});
		} catch (error) {
			console.log(error);
			toast.error('Error updating task', {
				theme: 'colored'
			});
		}
	};

	// Save on change
	const saveOnChange = async (e) => {
		let { name, value } = e.target;
		let additionalValues = {};

		if (['task_type_id'].includes(name)) {
			additionalValues = {
				title: taskTypes.find((taskType) => taskType.id === value)?.name || ''
			}
		}

		if (prevoiusFormData[name] !== value) {
			updateTask({ [name]: value });
		}
		setFormData({ ...formData, ...additionalValues, [name]: value });
	};


	// Handle Claimant Selection
	const handleClaimantSelection = () => {

	};

	// Handle User Selection
	const handleUserSelection = async (user) => {
		for (let i = 0; i < users.length; i++) {
			if (users[i].id === user.id) {
				return;
			}
		}

		try {
			let result = await taskAPI.assignTaskUser(selectedTask?.account_id || null, selectedTask.id, [{ ...user }]);
			if (!result?.data) {
				throw new Error('Error assigning user to task');
			}
			searchTask(selectedTask.id);
			refreshParent();
			toast.success('User assigned to task successfully', { theme: 'colored' });
		} catch (error) {
			console.log(error);
			toast.error('Error assigning user to task', { theme: 'colored' });
		}

	};

	// Get related data @TODO: Move this to a context
	const getRelatedData = async () => {
		try {
			let taskTypeResult = await taskAPI.getTaskTypes();
			if (!taskTypeResult?.data?.rows) {
				throw new Error('Unable to get task types.');
			}

			let taskStatusResult = await taskAPI.getTaskStatuses();
			if (!taskStatusResult?.data?.rows) {
				throw new Error('Unable to get task statuses.');
			}

			setTaskTypes(taskTypeResult.data.rows);
			setTaskStatuses(taskStatusResult.data.rows);
		} catch (error) {
			console.log(error);
		}
	};

	// Complete Task
	const completeTask = async () => {
		Swal.fire({
			title: 'Are you sure?',
			text: 'You will not be able to recover this task!',
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Yes, complete it!'
		}).then(async (result) => {
			if (result.isConfirmed) {
				try {
					let result = await taskAPI.completeTask(selectedTask?.account_id || null, currentTask.id);
					if (!result?.data) {
						throw new Error('Error completing task');
					}
					setCurrentTask(result?.data);
					searchTask(selectedTask.id);

					refreshParent();
					toast.success('Task completed successfully', { theme: 'colored' });
				} catch (error) {
					console.log(error);
					toast.error('Error completing task', { theme: 'colored' });
				}
			}
		});
	};


	// Handle Anchnowldege Task
	const handleAcknowledgeTask = async () => {
		try {
			let result = await taskAPI.acknowledgeTask(selectedTask?.account_id || null, currentTask.id);
			if (!result?.data) {
				throw new Error('Error acknowledging task');
			}
			setCurrentTask(result?.data);
			searchTask(selectedTask.id);
			refreshParent();
		} catch (error) {
			console.log(error);
		}
	};

	// Handle remove user
	const handleRemoveUser = async (taskUserId) => {
		Swal.fire({
			title: 'Remove assigned user?',
			text: 'Are you sure you would like to remove this user from the task?',
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#d33',
			cancelButtonColor: '#3085d6',
			confirmButtonText: 'Remove'
		}).then(async (result) => {
			if (result.isConfirmed) {
				try {
					let result = await taskAPI.removeAssignedTaskUser(selectedTask?.account_id || null, selectedTask.id, taskUserId);
					if (!result?.message) {
						throw new Error('Error removing user');
					}
					let newAssignedUsers = assignedUsers.filter((user) => user.id !== taskUserId);
					setAssignedUsers(newAssignedUsers);
					refreshParent();
					toast.success('User removed successfully', { theme: 'colored' });
				} catch (error) {
					console.log(error);
					toast.error('Error removing user', { theme: 'colored' });
				}
			}
		});
	};

	// Handle close
	const handleClose = () => {
		setSelectedTask({});
		setParentState(false);
	};

	// Handle show
	const handleShow = () => {
		getRelatedData();
		setCurrentTask({});
		searchTask(selectedTask.id);
		setParentState(true);
	};

	return (
		<Modal
			show={parentState}
			onHide={handleClose}
			onShow={handleShow}
			contentClassName="border"
			size="xl"
			backdrop="static"
		>
			<Modal.Header
				closeButton
				closeVariant={isDark ? 'white' : undefined}
				className="bg-light px-card border-bottom-0"
			>
				<Modal.Title as="h5">Task Details</Modal.Title>
			</Modal.Header>
			<Modal.Body className="p-card">
				{
					loading
						?
						<>
							<p>Loading task details...</p>
						</>
						:
						<>
							<Form>
								<Row>
									<Col md={6} className="border-end border-end-2">
										<Row>
											<Col>
												<span>Claimant: </span>
												{
													selectedTask?.claimant_id ?
														<>
															<Link to={`/claimant/profile/${selectedTask?.claimant_id || null}`} >{selectedTask?.claimant?.first_name + ' ' + selectedTask?.claimant?.last_name}</Link>
														</> :
														<>
															<p>No claimant assigned</p>
															<ClaimantQuickFind
																buttonLabel={<><FontAwesomeIcon icon={faPlus} /> Assign User</>}
																buttonVariant='link'
																handleClaimantSelection={handleClaimantSelection}
																requiredSearchParams={{ account_id: selectedTask?.account_id || null }}
																defaultSearchParams={{}}
															/>
														</>
												}
											</Col>
										</Row>
										<hr />
										<Row className="my-2">
											<Col>
												<Form.Group controlId="task_type_id">
													<Form.Label>Task Type</Form.Label>
													<Form.Control
														as="select"
														name="task_type_id"
														value={formData.task_type_id}
														onChange={saveOnChange}
														disabled={true}

													// onChange={e=>handleChange(e, true)}
													>
														<option value="">Select a task type</option>
														{
															taskTypes.map((taskType) => (
																<option key={taskType.id} value={taskType.id}>{taskType.type}</option>
															))
														}
													</Form.Control>
												</Form.Group>
											</Col>
										</Row>
										<Row className="my-2">
											<Col>
												<Form.Group controlId="task_status_id">
													<Form.Label>Task Status</Form.Label>
													<Form.Control
														as="select"
														name="task_status_id"
														value={formData.task_status_id}
														onChange={saveOnChange}

													>
														<option value="" disabled>Select a task status</option>
														{
															taskStatuses.map((taskStatus) => (
																<option key={taskStatus.id} value={taskStatus.id}>{taskStatus.status}</option>
															))
														}
													</Form.Control>
												</Form.Group>
											</Col>
										</Row>
										<Row className="my-2">
											<Col md={12}>
												<Form.Group controlId="title">
													<Form.Label>Title</Form.Label>
													<Form.Control
														type="text"
														name="title"
														value={formData.title}
														onChange={handleChange}
														onBlur={saveOnChange}
														disabled={[3, 4].includes(formData.task_status_id)}
													/>
												</Form.Group>
											</Col>
										</Row>
										<Row className="my-2">

											<Col md={6}>
												<Form.Group controlId="due_date">
													<Form.Label>Due Date</Form.Label>
													<DatePicker
														selected={formData.due_date ? new Date(formData.due_date) : null}
														minDate={new Date()}
														onChange={date => {
															setFormData({ ...formData, due_date: date });
															saveOnChange({
																target: {
																	name: 'due_date',
																	value: date
																}
															});
														}}
														className="form-control"
														placeholderText="MM-DD-YYYY H:M"
														dateFormat="MM-dd-yyyy h:mm aa"
														showTimeSelect
														disabled={[3, 4].includes(formData.task_status_id)}
														required
													/>
													{/* <Form.Control
														type="date"
														name="due_date"
														value={formData.due_date}
														onChange={handleChange}
														onBlur={saveOnChange}
													/> */}
												</Form.Group>
											</Col>
										</Row>
										<Row className="my-2">
											<Col md={12}>
												<Form.Group controlId="description">
													<Form.Label>Description</Form.Label>
													<Form.Control
														as="textarea"
														rows={3}
														name="description"
														value={formData.description}
														onChange={handleChange}
														onBlur={saveOnChange}
														disabled={[3, 4].includes(formData.task_status_id)}
													/>
												</Form.Group>
											</Col>
										</Row>
									</Col>
									<Col md={6}>
										<Row>
											<Col md={12}>
												<h5 className="d-inline">Assigned Users</h5>
												<div className="float-end">
													{
														![3, 4].includes(formData.task_status_id) &&
														<UserQuickFind
															buttonLabel={<><FontAwesomeIcon icon={faPlus} /> Assign User</>}
															buttonVariant='link'
															handleUserSelection={handleUserSelection}
															requiredSearchParams={{ account_id: selectedTask?.account_id || null }}
															defaultSearchParams={{}}
														/>
													}
												</div>
												<hr />

												{
													assignedUsers.length > 0 ? (
														<Table striped bordered hover size="sm">
															<thead>
																<tr>
																	<th>Name</th>
																	<th>Acknowledged</th>
																	<th></th>
																</tr>
															</thead>
															<tbody>
																{
																	assignedUsers.map((assignedUser) => (
																		<tr key={assignedUser.id}>
																			<td>{assignedUser?.user?.first_name} {assignedUser?.user?.last_name}</td>
																			<td>
																				{assignedUser?.task_acknowledged_timestamp ?
																					<span className="text-success">{moment(assignedUser.task_acknowledged_timestamp).format('MM/DD/YYYY hh:mm A')}</span>
																					:
																					<>
																						{
																							auth.getProfile().data.user_id !== assignedUser.user_id ? (
																								<span className="text-danger">Not Acknowledged</span>
																							) : (
																								<>
																									{
																										![3, 4].includes(formData.task_status_id) &&
																										<Button variant="warning" onClick={() => handleAcknowledgeTask(assignedUser.user_id)}>Acknowledge Task</Button>
																									}
																								</>
																							)
																						}
																					</>
																				}
																			</td>
																			<td>
																				{
																					![3, 4].includes(formData.task_status_id) &&
																					<Button variant="link" className="p-0 text-danger float-end" onClick={() => handleRemoveUser(assignedUser.id)}>
																						<FontAwesomeIcon icon={faTrash} />
																					</Button>
																				}
																			</td>
																		</tr>
																	))
																}
															</tbody>
														</Table>
													) : (
														<p>No users assigned</p>
													)
												}
											</Col>
										</Row>
									</Col>
								</Row>
							</Form>
						</>
				}
			</Modal.Body>
			<Modal.Footer className="bg-light px-card border-top-0">
				{
					formData?.task_status_id !== 3 && (
						<Button variant="success" onClick={completeTask}>Mark Completed!</Button>
					)
				}
			</Modal.Footer>
		</Modal>
	);
};

export default TaskDetails;