import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import AppContext from 'context/Context';
import { Modal } from 'react-bootstrap';
import FindAClaimantForm from './FindAClaimantForm';

const FindAClaimantModal = ({ modalState, setModalState = () => { }, searchParams = {}, setSearchParams = () => { }, requiredSearchParams = {} }) => {
	const { config: { isDark } } = useContext(AppContext);
	const handleShow = () => (setModalState(true));
	const handleClose = () => (setModalState(false));

	return (
		<Modal
			show={modalState}
			onHide={handleClose}
			onShow={handleShow}
			contentClassName="border"
		>
			<Modal.Header
				closeButton
				closeVariant={isDark ? 'white' : undefined}
				className="bg-light px-card border-bottom-0"
			>
				<Modal.Title as="h5">Find-A-Lead</Modal.Title>
			</Modal.Header>
			<Modal.Body className="p-card">
				<FindAClaimantForm
					parentModalState={modalState}
					setParentModalState={setModalState}
					searchParams={searchParams}
					setSearchParams={setSearchParams}
					requiredSearchParams={requiredSearchParams}
				/>
			</Modal.Body>
		</Modal>
	);
};

FindAClaimantModal.propTypes = {
	modalState: PropTypes.bool,
	setModalState: PropTypes.func,
	searchParams: PropTypes.object,
	setSearchParams: PropTypes.func,
	requiredSearchParams: PropTypes.object
};

export default FindAClaimantModal;