import React from 'react';
import Section from 'components/common/Section';
import { Row, Col } from 'react-bootstrap';
import compliance from 'assets/img/generic/compliance.webp';

const Security = () => {
  return (
    <Section bg="light" className="py-3 shadow-sm">
      <Row className="flex-center">
        <Col md={7}>
          <h2>Compliance and Security</h2>
          <p>SOC2 certified and HIPAA compliant. Assure Disability is audited each year to verify our security and privacy policies meet industry standards. If your firm wants specific information regarding our security and privacy policies, we would be glad to provide additional information upon request.</p>
        </Col>
        <Col md={5} className="text-center">
          <img width={350} src={compliance} alt="Compliance and Security" />
        </Col>
      </Row>
    </Section>
  );
};

export default Security;
