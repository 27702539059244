import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Col, Form } from 'react-bootstrap';
import { formatDate } from 'utils/functions';
import IconButton from 'components/common/IconButton';
import ExportReportButton from 'components/ExportReportButton';

const ClaimantEntryTableHeader = ({ label = "Claimant Report", handleBulkActions, selectedRowIds, page, reportParams = {},
	claimantReports = [], selectedReport = {}, setSelectedReport = () => { }, showClearFilters = true } = {}) => {
	const formRef = useRef();
	const onClick = async (e) => {
		if (e) {
			e.preventDefault();
			e.stopPropagation();
		}
		let bulk_action = e.target?.innerText ? `${e.target.innerText}`.toLowerCase() : '';
		const formData = new FormData(formRef.current);
		let formDataObj = { bulk_action };
		for (let [key, value] of formData) {
			formDataObj[key] = value;
		}
		if (typeof handleBulkActions === 'function' && bulk_action) {
			let selectedRows = Array.isArray(page) ? page.reduce((acc, { isSelected = false, original } = {}) => (
				[...acc, ...(isSelected ? [original] : [])]
			), []) : [];
			let selectedIds = selectedRows.length > 0 ? selectedRows.map(({ id } = {}) => id) : [];
			await handleBulkActions(formDataObj, selectedRows, selectedIds);
		}
	};

	const onChange = async (e) => {
		let _selectedReport = claimantReports.find(({ id } = {}) => Number(id) === Number(e.target.value)) || claimantReports.find(({ id } = {}) => Number(id) === Number(claimantReports[0]?.id)) || {};
		setSelectedReport(_selectedReport);
	};

	const onSubmit = async (e) => {
		if (e) {
			e.preventDefault();
			e.stopPropagation();
		}
		const formData = new FormData(formRef.current);
		let formDataObj = {};
		for (let [key, value] of formData) {
			formDataObj[key] = value;
		}
		if (formDataObj.claimant_report_id) {
			formDataObj.bulk_action = 'load_report';
		}
		if (typeof handleBulkActions === 'function' && formDataObj.bulk_action) {
			let selectedRows = Array.isArray(page) ? page.reduce((acc, { isSelected = false, original } = {}) => (
				[...acc, ...(isSelected ? [original] : [])]
			), []) : [];
			let selectedIds = selectedRows.length > 0 ? selectedRows.map(({ id } = {}) => id) : [];
			await handleBulkActions(formDataObj, selectedRows, selectedIds);
		}
	};

	return (
		<Form noValidate ref={formRef} onSubmit={onSubmit} className="row flex-between-center">
			<Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
				<h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">{label}</h5>
			</Col>
			<Col xs={8} sm="auto" className="ms-auto text-end ps-0">
				{Object.keys(selectedRowIds).length > 0 ? (
					<div className="d-flex">

					</div>
				) : (
					<div className="d-flex">
						{claimantReports.length > 0 && (<Form.Group className="d-flex">
							<IconButton className="me-2" variant="danger" size="sm" icon="trash" transform="shrink-3" type="button" onClick={onClick}>
								<span className="d-none d-sm-inline-block ms-1">Delete Report</span>
							</IconButton>
							<Form.Select size="sm" aria-label="Claimant Report Ids" name="claimant_report_id" className="flex-grow-1 w-auto" value={selectedReport?.id} onChange={onChange}>
								{claimantReports.map(({ id, title } = {}, index) => (<option key={`${index}-${id}`} value={id}>{title}</option>))}
							</Form.Select>
							<IconButton className="mx-2" variant="primary" size="sm" icon="file" transform="shrink-3" type="submit">
								<span className="d-none d-sm-inline-block ms-1">Load Report</span>
							</IconButton>
						</Form.Group>)}
						{reportParams?.requestedFields?.length > 0 && (<IconButton className="me-2" variant="falcon-default" size="sm" icon="file" transform="shrink-3" type="button" onClick={onClick}>
							<span className="d-none d-sm-inline-block ms-1">Save Report</span>
						</IconButton>)}
						<IconButton className="me-2" variant="falcon-default" size="sm" icon="toggle" transform="shrink-3" type="button" onClick={onClick}>
							<span className="d-none d-sm-inline-block ms-1">Inactive Claimant</span>
						</IconButton>
						<IconButton className="me-2" variant="falcon-default" size="sm" icon="plus" transform="shrink-3" type="button" onClick={onClick}>
							<span className="d-none d-sm-inline-block ms-1">Filters</span>
						</IconButton>
						{showClearFilters && (<IconButton className="me-2" variant="danger" size="sm" icon="clear" transform="shrink-3" type="button" onClick={onClick}>
							<span className="d-none d-sm-inline-block ms-1">Clear Filters</span>
						</IconButton>)}
						{reportParams?.requestedFields?.length > 0 && <ExportReportButton
							controllerName="Claimant"
							fnName="getReportFn"
							saveAs={`${label.replace("Report", "").trim()}_${formatDate(new Date(), "YYYY-MM-DD")}.xlsx`}
							params={{
								...reportParams,
								offset: 0,
								limit: null,
								selectedIds: Array.isArray(page)
									? (page.reduce((acc, { isSelected = false, original } = {}) => (
										[...acc, ...(isSelected ? [original] : [])]
									), [])).map(({ id } = {}) => id)
									: []
							}}
						/>}
					</div>
				)}
			</Col>
		</Form>
	);
};

ClaimantEntryTableHeader.propTypes = {
	label: PropTypes.string,
	handleBulkActions: PropTypes.func,
	selectedRowIds: PropTypes.object,
	page: PropTypes.array,
	reportParams: PropTypes.object,
	claimantReports: PropTypes.array,
	selectedReport: PropTypes.object,
	setSelectedReport: PropTypes.func,
	showClearFilters: PropTypes.bool,
};

export default ClaimantEntryTableHeader;