import React, { useState, useEffect } from 'react';
import { Col, Form, Button, Row } from 'react-bootstrap';

const UserFilters = ({ searchParams, setSearchParams = () => { }, fields = [], clearSearchParams = () => { } } = {}) => {
	const statusOptions = [
		{
			id: 1,
			name: "Active",
		},
		{
			id: 2,
			name: "Pending Activation",
		},
		{
			id: 3,
			name: "Inactive",
		},
		{
			id: 4,
			name: "Pending Password Reset",
		},
	];

	let defaultFilters = {
		business_name: "",
		first_name: searchParams?.first_name || null,
		last_name: searchParams?.last_name || null,
		email: searchParams?.email || null,
		user_status_id: searchParams?.user_status_id || null,
	};
	const [formData, setFormData] = useState(defaultFilters);

	// Clear Filters
	const clearFilters = () => {
		setFormData({
			business_name: "",
			first_name: "",
			last_name: "",
			email: "",
			user_status_id: "",
		});
		clearSearchParams();
	};

	const onSubmit = async (e) => {
		e.preventDefault();
		setSearchParams(formData);
	};

	useEffect(() => {
		setFormData({
			business_name: "",
			first_name: searchParams?.first_name || null,
			last_name: searchParams?.last_name || null,
			email: searchParams?.email || null,
			user_status_id: searchParams?.user_status_id || null,
		});
	}, [searchParams]);

	return (
		<Form noValidate onSubmit={onSubmit} className="row flex-between-center px-2 py-3 my-3 border bg-100 mx-2">
			<Row>
				<Col>
					<h5>Filters</h5>
				</Col>
			</Row>

			<Row>
				{
					fields.includes('business_name') &&
					<Col>
						<Form.Group controlId="business_name">
							<Form.Label>Business Name</Form.Label>
							<Form.Control
								className={`${searchParams?.business_name ? "bg-soft-info" : ""}`}
								size="sm"
								type="text"
								placeholder="Business Name"
								name="business_name"
								value={formData.business_name}
								onChange={(e) => setFormData({ ...formData, business_name: e.target.value })}
							/>
						</Form.Group>
					</Col>
				}

			</Row>
			<Row>
				{
					fields.includes("user_status_id") &&
					<Col>
						<Form.Group controlId="user_status_id">
							<Form.Label>Status</Form.Label>
							<Form.Select
								className={`${searchParams?.user_status_id ? "bg-soft-info" : ""}`}
								size="sm"
								aria-label="Status"
								name="user_status_id"
								value={formData.user_status_id}
								onChange={(e) =>
									setFormData({ ...formData, user_status_id: e.target.value })
								}
							>
								<option value="">All</option>
								{statusOptions.map((option) => (
									<option key={`${option.id}_status`} value={option.id}>
										{option.name}
									</option>
								))}
							</Form.Select>
						</Form.Group>
					</Col>
				}

				{
					fields.includes('first_name') &&
					<Col>
						<Form.Group controlId="first_name">
							<Form.Label>First Name</Form.Label>
							<Form.Control
								className={`${searchParams?.first_name ? "bg-soft-info" : ""}`}
								size="sm"
								type="text"
								placeholder="First Name"
								name="first_name"
								value={formData.first_name}
								onChange={(e) => setFormData({ ...formData, first_name: e.target.value })}
							/>
						</Form.Group>
					</Col>
				}

				{
					fields.includes('last_name') &&
					<Col>
						<Form.Group controlId="last_name">
							<Form.Label>Last Name</Form.Label>
							<Form.Control
								className={`${searchParams?.last_name ? "bg-soft-info" : ""}`}
								size="sm"
								type="text"
								placeholder="Last Name"
								name="last_name"
								value={formData.last_name}
								onChange={(e) => setFormData({ ...formData, last_name: e.target.value })}
							/>
						</Form.Group>
					</Col>
				}
			</Row>
			<Row>
				{
					fields.includes('email') &&
					<Col>
						<Form.Group controlId="email">
							<Form.Label>Email</Form.Label>
							<Form.Control
								className={`${searchParams?.email ? "bg-soft-info" : ""}`}
								size="sm"
								type="text"
								placeholder="Email"
								name="email"
								value={formData.email}
								onChange={(e) =>
									setFormData({ ...formData, email: e.target.value })
								}
							/>
						</Form.Group>
					</Col>
				}
			</Row>

			<Row className="mt-3">
				<Col>
					<Button
						variant="outline-primary"
						size="sm"
						type="submit"
						className="me-2"
					>
						Search
					</Button>
					<Button
						variant="outline-secondary"
						size="sm"
						onClick={() => { clearFilters() }}
					>
						Clear
					</Button>
				</Col>
			</Row>
		</Form >
	);
};

export default UserFilters;
