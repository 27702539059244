import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Card, Dropdown } from 'react-bootstrap';
import CardDropdown from 'components/common/CardDropdown';
import Flex from 'components/common/Flex';
import moment from 'moment';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import CaseFileRequestTableHeader from './CaseFileRequestTableHeader';
import CreateCaseFileRequest from './CreateCaseFileRequest';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import CustomAdvanceTable from 'components/common/advance-table/CustomAdvanceTable';
import CaseFileRequestFilters from './CaseFileRequestFilters';
import CustomAdvanceTablePagination from 'components/common/advance-table/CustomAdvanceTablePagination';
import { assureAPI } from 'utils/api/fulcrum-api';
import { removeEmptyFromObj, sleep } from 'utils/functions';

const defualtCaseRequestColumns = [
	{
		accessor: 'id',
		Header: 'ID',
		headerProps: { className: 'pe-1' },
		cellProps: {
			className: 'py-2'
		}
	}
];

const CaseFileRequestTable = ({ claimant = {}, columns = defualtCaseRequestColumns, defaultSearchParams = {}, requiredSearchParams = {}, refreshParent = () => { } } = {}) => {
	const [caseFileData, setCaseFileData] = useState([]);
	const [Pages, setPages] = useState([1]);
	const [activePage, setActivePage] = useState(1);
	const [perPage, setPerPage] = useState(10);
	const [caseFileRequestCount, setCaseFileRequestCount] = useState(0);
	const [createCaseFileRequestModal, setCreateCaseFileRequestModal] = useState(false);
	const [searching, setSearching] = useState(false);
	const [lastQuery, setLastQuery] = useState({});
	const [searchParams, setSearchParams] = useState({ ...defaultSearchParams });
	let defaultSort = ["created_at", "DESC"];
	const [appliedSortOrders, setAppliedSortOrders] = useState([defaultSort]);
	const [selectedCaseFileRequest, setSelectedCaseFileRequest] = useState({});

	// Handle bulk actions
	const handleBulkActions = (actionObj = {}, selectedRows = [], selectedIds = []) => {
		let caseFileRequest = {};
		if (Array.isArray(selectedRows) && selectedRows.length === 1) {
			caseFileRequest = selectedRows[0];
		}
		let { bulk_action } = actionObj || {};
		switch (bulk_action) {
			default:
				alert('Bulk Action not handled', bulk_action)
				break;
		}
	};

	// Format found data
	const formatData = (data = []) => {

		let adjustedData = data.map(item => {
			return {
				...item,
				type: item?.type ? (item.type === 1 ? "Exhibited" : "All") : null,
				claimant_name: `${item?.claimant?.first_name || null} ${item?.claimant?.last_name || null}`,
				assure_case_id: item?.assure_case_id || null,
				created_at: item?.createdAt ? moment(item.createdAt).format('MM/DD/YYYY hh:mm A') : null,
				assure_last_checked_at: item?.assure_last_checked_at ? moment(item.assure_last_checked_at).format('MM/DD/YYYY hh:mm A') : null,
				assure_last_updated_at: item?.assure_last_updated_at ? moment(item.assure_last_updated_at).format('MM/DD/YYYY hh:mm A') : null,
				menu: (<>
					<div className="d-flex justify-content-between float-end">
						<CardDropdown
							children={(
								<div className="py-2">
									<Dropdown.Item onClick={async () => { alert('coming') }} className="">View</Dropdown.Item>
								</div>
							)}
						/>
					</div>
				</>)
			}
		});
		setCaseFileData(adjustedData);
	};

	// Clear search params and reset to default
	const clearSearchParams = () => {
		if (JSON.stringify(searchParams) !== JSON.stringify({ ...defaultSearchParams })) {
			setSearchParams({ ...defaultSearchParams });
		}
		if (JSON.stringify(appliedSortOrders) !== JSON.stringify([defaultSort])) {
			setAppliedSortOrders([defaultSort]);
		}
		if (activePage !== 1) {
			setActivePage(1);
		}
		if (perPage !== 10) {
			setPerPage(10);
		}
	};

	// View case file request	
	const viewCaseFileRequest = (caseFileRequest = {}) => {
		let { id } = caseFileRequest || {};
		if (!id) {
			setSelectedCaseFileRequest({});
		} else {
			setSelectedCaseFileRequest(caseFileRequest);
		}
	};

	// Handle header click
	const headerClickFn = (id, previousSortBy) => {
		if (!id) {
			return;
		}
		let foundIndex = appliedSortOrders.findIndex(s => s[0] === id);
		let sortBy = !previousSortBy ? "ASC" : previousSortBy === "asc" ? "DESC" : '';

		if (!sortBy) { // Not Sorted so remove clicked sort item 
			if (foundIndex > -1) {
				setAppliedSortOrders((prev) => {
					let newSortOrders = [...prev];
					newSortOrders.splice(foundIndex, 1); // remove clicked sort item
					return newSortOrders;
				});
			}
		} else { // Sort by Ascending or Descending 
			let newSort = [id, sortBy]

			if (id = "claimant") {
			}

			if (foundIndex > -1) { // replaces existing 
				setAppliedSortOrders((prev) => {
					let newSortOrders = [...prev];
					newSortOrders[foundIndex] = newSort;
					return newSortOrders;
				});
			} else { // inserts 
				setAppliedSortOrders((prev) => [...prev, newSort]);
			}
		}
	};

	// Get case file requests
	const getCaseFileRequests = async (params = {}, saveLastQuery = false) => {
		try {
			params = removeEmptyFromObj(params);
			params.page = activePage;
			params.limit = perPage;
			params.navSize = 5; // How many buttons to show for navigation 
			params.order = appliedSortOrders; // Example [[id, 'ASC']]
			let { error, response, data } = await assureAPI.searchCaseFileRequests(params);
			if (error) {
				if (typeof response?.error === 'string') {
					throw new Error(`${error} - ${response.error}`);
				} else if (typeof response === 'string') {
					throw new Error(`${error} - ${response}`);
				}
				throw new Error(`${error} - There was an error searching case file requests.`);
			}

			let { count = 0, rows = [], pages = [], page = 1, pageCount = 0 } = data || {}; // data = { count = 0, rows = [], limit, pageCount, page, pages = [1,2,3,10] } 
			if (!rows || (Array.isArray(rows) && rows.length === 0)) {
				throw new Error('No case file request data found');
			}

			if (pageCount < activePage) {
				setActivePage(1);
			} else {
				setActivePage(page);
			}
			setPages(pages);
			formatData(rows);
			setCaseFileRequestCount(count);

			if (saveLastQuery) {
				setLastQuery(params);
			}
		} catch (error) {
			console.log("Error getting case file request data", error);
			setCaseFileData([]);
			setCaseFileRequestCount(0);
			setActivePage(1);
		}
		setSearching(false);
	};

	// Refresh last query	
	const refreshLastQuery = async () => {
		await getCaseFileRequests({ ...lastQuery, ...requiredSearchParams }, false);
		refreshParent();
	};

	// Perform a new search
	const newSearch = async (params) => {
		setSearching(true);
		let adjustedSearchParams = {
			...params,
			...requiredSearchParams
		};
		return await getCaseFileRequests(adjustedSearchParams, true);
	};

	useEffect(() => {
		newSearch(searchParams);
	}, [searchParams, activePage, perPage, appliedSortOrders]);

	return (
		<AdvanceTableWrapper
			columns={columns}
			data={caseFileData}
			// selection
			sortable
			pagination
			perPage={perPage}
			setPerPage={setPerPage}
		>
			<Card className="mb-3 shadow-none">
				<Card.Header>
					<CaseFileRequestTableHeader
						table
						setCreateCaseFileRequestModal={setCreateCaseFileRequestModal}
						handleBulkActions={handleBulkActions}
					/>
					<CaseFileRequestFilters
						searchParams={searchParams}
						setSearchParams={setSearchParams}
						clearSearchParams={clearSearchParams}
						fields={[
							"claimant_name",
							"case_file_request_id",
							"office_with_jurisdiction",
							"primary_rep",
							"type",
							"status",
							"status_message"
						]}
					/>
				</Card.Header>
				<Card.Body className="p-0">
					{
						searching ?
							<p>Loading case file requestes...</p>
							:
							<CustomAdvanceTable
								table
								headerClickFn={headerClickFn}
								appliedSortOrders={appliedSortOrders}
								headerClassName="bg-200 text-900 text-nowrap align-middle"
								rowClassName="align-middle white-space-nowrap"
								tableProps={{
									bordered: true,
									striped: true,
									className: 'fs--1 mb-0'
								}}
							/>
					}

				</Card.Body>
				<Card.Footer>
					<CustomAdvanceTablePagination
						table
						Pages={Pages}
						activePage={activePage}
						setActivePage={setActivePage}
						totalRecords={caseFileRequestCount}
					/>
				</Card.Footer>
			</Card>

			<CreateCaseFileRequest
				modalState={createCaseFileRequestModal}
				setModalState={setCreateCaseFileRequestModal}
				caseFileData={caseFileData}
				setCaseFileData={setCaseFileData}
				refreshParent={refreshLastQuery}
				claimant={claimant}
			/>
		</AdvanceTableWrapper>
	)
};

export default CaseFileRequestTable;