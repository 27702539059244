import React, { useState, useEffect } from 'react';
import { claimantAPI, caseAPI, assureAPI } from 'utils/api/fulcrum-api';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import ClaimantProfileTabs from './ClaimantProfileTabs';
import LeadProgressTool from './LeadProgressTool';
import CloseCase from './case/CloseCase';
import { toast } from 'react-toastify';
import ReactInterval from 'react-interval';

const ClaimantCaseWrapper = ({ claimant, setClaimant, getClaimantDetails = () => { }, componentState, setComponentState = () => { }, refreshClaimant = () => { } }) => {
	const [selectedCase, setSelectedCase] = useState({});
	const [loading, setLoading] = useState(true);
	const [caseStatuses, setCaseStatuses] = useState([]);
	const [attorneyList, setAttorneyList] = useState([]);

	// get related data
	const getRelatedData = async () => {
		try {
			let caseStatusResult = await caseAPI.getCaseStatuses();
			if (caseStatusResult?.data?.rows) {
				setCaseStatuses(caseStatusResult.data.rows);
			}
		} catch (error) {
			console.error(error);
		}
	};

	// // Get Attorney List
	// const getAttorneyList = async () => {
	// 	try {
	// 		let result = await assureAPI.listEreCredentials(claimant?.account_id || null);
	// 		if (!result?.data?.data) {
	// 			throw new Error("Unable to retrieve a list of attorney credentials.");
	// 		}
	// 		setAttorneyList(Array.isArray(result.data.data) ? result.data.data : [])
	// 	} catch (error) {
	// 		toast.error("Unbale to retrieve a list of attorney credentials.", { theme: 'colored' });
	// 	}
	// };



	// Get claimant case
	const getClaimantCase = async (caseId, params = {}) => {
		try {
			if (caseId) {
				let result = await claimantAPI.getClaimantCase(claimant.id, caseId, params);
				if (!result?.data) {
					throw new Error('No claimant case data returned');
				}
				setSelectedCase(result?.data);
			}

		} catch (error) {
			console.error(error);
		}
		setLoading(false);
	};

	const refreshCaseWrapper = (id, params = {}) => {
		if (id) {
			getClaimantCase(id, params);
		}
		refreshClaimant();
	};

	useEffect(() => {
		getClaimantCase(selectedCase.id);
	}, [selectedCase.id]);

	useEffect(() => {
		if (!selectedCase.id) {
			getClaimantCase(claimant.active_case_id);
		}
	}, [claimant?.active_case_id]);

	useEffect(() => {
		getRelatedData();
	}, []);

	if (loading) {
		return (<p>Loading case details...</p>);
	};

	return (
		<div>

			{
				(selectedCase?.id && claimant?.claimant_cases && claimant?.claimant_cases.length > 0)
					?
					<>
					</>
					:
					<>
						<Row className="mb-2">
							<Col>
								<Card className="">
									<Card.Body className="">
										{
											!claimant.active_case && !selectedCase.id &&
											<div className="mb-2">
												<LeadProgressTool claimant={claimant} setClaimant={setClaimant} />
											</div>
										}

									</Card.Body>
								</Card>
							</Col>
						</Row>
					</>
			}
			<ClaimantProfileTabs
				componentState={componentState}
				setComponentState={setComponentState}
				refreshParent={refreshCaseWrapper}
				selectedCase={selectedCase}
				setSelectedCase={setSelectedCase}
				claimant={claimant}
				setClaimant={setClaimant}
				getClaimantDetails={getClaimantDetails}
				caseStatuses={caseStatuses}
				attorneyList={attorneyList}
				setAttorneyList={setAttorneyList}
			/>

			<CloseCase
				modalState={componentState.closeCaseModalState}
				setModalState={(state) => setComponentState({ ...componentState, closeCaseModalState: state })}
				claimant={claimant}
				setClaimant={setClaimant}
				selectedCase={selectedCase}
				setSelectedCase={setSelectedCase}
				caseStatuses={caseStatuses}
				refreshParent={refreshCaseWrapper}
			/>

			<ReactInterval timeout={15000} enabled={true} callback={() => { refreshCaseWrapper(selectedCase?.id || null, { ignoreTouch: true }) }} />

		</div >
	);
};

export default ClaimantCaseWrapper;