import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import Flex from 'components/common/Flex';
import cloudUpload from 'assets/img/icons/cloud-upload.svg';
import { Button } from 'react-bootstrap';
import { accountAPI } from 'utils/api/fulcrum-api';
import Auth from 'utils/auth';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import ImageUploader from './ImageUploader';

const LogoUploader = ({ account, setAccount = () => { } }) => {
  // const activeAccountId = Auth.getProfile().data.account_id;
  const [files, setFiles] = useState([]);
  const [show, setShow] = useState(false);

  const handleShow = () => setShow(true);
  const handleClose = () => { setShow(false); setFiles([]); };

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleSave = async (files) => {

    if (!files || !Array.isArray(files) || files.length != 1) {
      console.log('Error: One and only one file allowed.');
      return;
    }

    try {
      const base64 = await convertToBase64(files[0]);
      // @todo: should not allow any front end passing of this unless explicitly allowed
      const result = await accountAPI.updateAccount(account?.id, { // @todo: add backend check to catch mismatches of account_id and unless admin don't allow it
        logoBase64: base64
      });
      setAccount(result.data);
      if (!result) {
        throw new Error('Unable to update account logo.');
      }
      toast.success('Account logo updated successfully.', { theme: 'colored' });
    } catch (error) {
      console.log('Failed to save logo:', error);
      toast.error('Failed to save logo.', { theme: 'colored' });
    }
  }

  return (
    <>
      <Button variant='link' onClick={(e) => { e.preventDefault(); handleShow(); }} style={{ cursor: 'pointer' }}>(Change Logo)</Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Upload Logo</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ImageUploader setValue={handleSave} />
          {/* <img src={preview} style={{ "width": "90%" }} /> */}

          {/* <input type="file" name="file" id="file" className="inputfile"
            accept=".png,.jpg,.jpeg,.webp"
            onChange={onFileChange}
          /> */}
          {/* <div {...getRootProps({ className: 'dropzone-area py-6 border' })}
            style={{ border: files.length > 0 ? '3px solid blue' : '0' }}
          >
            <input {...getInputProps()} />
            <Flex justifyContent="center">
              <img src={cloudUpload} alt="" width={25} className="me-2" />
              <p className="fs-0 mb-0 text-700">Drop your org logo here</p>
            </Flex>
          </div> */}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>Cancel</Button>
          {/* <Button variant='primary' onClick={handleSave}>Save</Button> */}
        </Modal.Footer>
      </Modal>
    </>
  );
}

LogoUploader.propTypes = {
  setAccount: PropTypes.func
}

export default LogoUploader;