import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Modal, Row, Col } from 'react-bootstrap';
import AppContext from 'context/Context';
import DatePicker from 'react-datepicker';
import Swal from 'sweetalert2';

import { reservationAPI } from 'utils/api/fulcrum-api';

const AddScheduleModal = ({
  selectedCalendar,
  setIsOpenScheduleModal,
  isOpenScheduleModal,
  setInitialEvents,
  initialEvents,
  scheduleStartDate,
  setScheduleStartDate,
  scheduleEndDate,
  setScheduleEndDate,
  modalEventContent,
  setModalEventContent,

}) => {
  const {
    config: { isDark }
  } = useContext(AppContext);

  const [formData, setFormData] = useState({
    id: '',
    title: '',
    start: new Date(),
    end: new Date(),
    description: '',
    location: '',
    organizer: '',
    className: '',
    allDay: false,
    schedules: []
  });

  const handleClose = () => {
    setFormData({
      id: '',
      title: '',
      start: new Date(),
      end: new Date(),
      description: '',
      location: '',
      organizer: '',
      className: '',
      allDay: false,
      schedules: []
    });
    setModalEventContent({});
    setIsOpenScheduleModal(!isOpenScheduleModal);
  };

  const handleChange = ({ target }) => {
    let name = target.name;
    let value = name === 'allDay' ? target.checked : target.value;
    formData[name] = value;
    setFormData({ ...formData, [name]: value });
  };

  const reservationApiCreate = async (createPayload) => {
    delete createPayload.id;
    let createResult;
    try {
      createResult = await reservationAPI.createReservation(createPayload);
    } catch (err) {
    }
    return createResult;
  }

  const reservationApiUpdate = async (reservationId, updatePayload) => {
    let updateResult;
    try {
      updateResult = await reservationAPI.updateReservation(reservationId, updatePayload);
    } catch (err) {
    }
    return updateResult;
  }

  const reservationApiGet = async (reservationId) => {
    let getResult;
    try {
      getResult = await reservationAPI.getReservationById(reservationId);
    } catch (err) {
    }
    return getResult;
  }

  const reservationApiDelete = async (reservationId) => {
    // console.log("reservationApiDelete", reservationId);
    let deleteResult;
    try {
      deleteResult = await reservationAPI.deleteReservation(reservationId);
      // console.log("reservationApiDelete", deleteResult);
    } catch (err) {
      // console.log("reservationApiDelete", err);
    }
    return deleteResult;
  }

  const deleteEvent = async () => {

    if (formData?.id) {

      await Swal.fire({
        title: 'Are you sure?',
        text: 'You will not be able to recover this event!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'

      }).then(async (result) => {

        if (result.isConfirmed) {
          await reservationApiDelete(formData?.id);
          let originalEvent = initialEvents.find((event) => event.id === formData.id);
          let originalEventIndex = initialEvents.indexOf(originalEvent);
          initialEvents.splice(originalEventIndex, 1);
          setInitialEvents([...initialEvents]);
          setFormData({
            id: '',
            title: '',
            start: new Date(),
            end: new Date(),
            description: '',
            location: '',
            organizer: '',
            className: '',
            allDay: false,
            schedules: []
          });
          setModalEventContent({});
          setIsOpenScheduleModal(!isOpenScheduleModal);

          await Swal.fire(
            'Deleted!',
            'Your event has been deleted.',
            'success'
          );

        }

      })

    } else {

      await Swal.fire(
        'Delete Canceled!',
        'Your event has NOT been deleted.',
        'warning'
      );

      setFormData({
        id: '',
        title: '',
        start: new Date(),
        end: new Date(),
        description: '',
        location: '',
        organizer: '',
        className: '',
        allDay: false,
        schedules: []
      });
      setModalEventContent({});
      setIsOpenScheduleModal(!isOpenScheduleModal);
    }
  }

  const handleSubmit = async (e) => {

    e.preventDefault();
    e.stopPropagation();

    let submitPayload = {
      calendar_id: selectedCalendar || 1,
      title: formData?.title || '',
      from_date_time: formData?.start || null,
      to_date_time: formData?.end || null,
      description: formData?.description || '',
      location: formData?.location || '',
      organizer: formData?.organizer || '',
      label: formData?.className || '',
      all_day: formData?.allDay || false,
      schedules: formData?.schedules || []
    }

    let dbStorageResult;

    if (formData?.id) {
      dbStorageResult = await reservationApiUpdate(formData.id, { ...submitPayload });
      let originalEvent = initialEvents.find((event) => event.id === formData.id);
      let originalEventIndex = initialEvents.indexOf(originalEvent);
      initialEvents[originalEventIndex] = { ...formData, id: formData.id };
      setInitialEvents([...initialEvents]);
    } else {
      dbStorageResult = await reservationApiCreate({ ...submitPayload });
      setInitialEvents([...initialEvents, { ...formData, id: dbStorageResult.data.id }]);
    }

    setFormData({
      id: '',
      title: '',
      start: new Date(),
      end: new Date(),
      description: '',
      location: '',
      organizer: '',
      className: '',
      allDay: false,
      schedules: []
    });
    setModalEventContent({});
    setIsOpenScheduleModal(!isOpenScheduleModal);

  };

  useEffect(async () => {

    let className;

    let { id, title = '', end = scheduleEndDate, start = scheduleStartDate, allDay = false, classNames } = modalEventContent?.event || {};

    let { description = '', location = '', organizer = '', schedules = [] } = modalEventContent?.event?.extendedProps || {};

    if (classNames) {
      if (Array.isArray(classNames)) {
        className = classNames[0];
      }
    }

    let details = {
      id,
      title,
      start,
      end,
      description,
      location,
      organizer,
      className,
      schedules,
      allDay
    }

    if (isOpenScheduleModal) {
      setFormData((prev) => ({
        ...prev,
        ...details,
      }));
    }

  }, [isOpenScheduleModal]);

  return (
    <Modal
      size='lg'
      show={isOpenScheduleModal}
      onHide={handleClose}
      onShow={() => {
        setFormData({
          id: '',
          title: '',
          start: new Date(),
          end: new Date(),
          description: '',
          location: '',
          organizer: '',
          className: '',
          allDay: false,
          schedules: []
        });
      }}
      contentClassName="border"
    >
      <Form onSubmit={handleSubmit}>
        <Modal.Header
          closeButton
          closeVariant={isDark ? 'white' : undefined}
          className="bg-light px-card border-bottom-0"
        >
          <Modal.Title as="h5"> Create/Edit Schedule</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-card">
          <Form.Control
            disabled
            hidden
            type="text"
            name="id"
            required
            onChange={handleChange}
            value={formData.id}
          />
          <Form.Group className="mb-3" controlId="titleInput">
            <Form.Label className="fs-0">Title</Form.Label>
            <Form.Control
              type="text"
              name="title"
              required
              onChange={handleChange}
              value={formData.title}
            />
          </Form.Group>

          <Row>

            <Col>
              <Form.Group controlId="allDay">
                <Form.Check
                  type="checkbox"
                  id="allDay"
                  label="All Day"
                  name="allDay"
                  onChange={handleChange}
                  checked={formData.allDay}
                />
              </Form.Group>
            </Col>

            <Col>
              <Form.Group className="mb-3" controlId="startDate">
                <Form.Label className="fs-0">Start Date</Form.Label>
                <DatePicker
                  selected={formData.start}
                  onChange={date => {
                    setScheduleStartDate(date);
                    setFormData({ ...formData, start: date });
                  }}
                  className="form-control"
                  placeholderText="MM-DD-YYYY H:M"
                  dateFormat="MM-dd-yyyy h:mm aa"
                  showTimeSelect
                />
              </Form.Group>
            </Col>

            <Col>
              <Form.Group className="mb-3" controlId="endDate">
                <Form.Label className="fs-0">End Date</Form.Label>
                <DatePicker
                  selected={formData.end}
                  disabled={formData.allDay == true}
                  onChange={date => {
                    setScheduleEndDate(date);
                    setFormData({ ...formData, end: date });
                  }}
                  className="form-control"
                  placeholderText="MM-DD-YYYY H:M"
                  dateFormat="MM-dd-yyyy h:mm aa"
                  showTimeSelect
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>

            <Col>
              <Form.Group className="mb-3" controlId="description">
                <Form.Label className="fs-0">Description</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  name="description"
                  onChange={handleChange}
                  value={formData.description}
                />
              </Form.Group>
            </Col>


          </Row>

          <Row>
            <Col>
              <Form.Group className="mb-3" controlId="organizer">
                <Form.Label className="fs-0">Organizer</Form.Label>
                <Form.Control
                  type="text"
                  name="organizer"
                  onChange={handleChange}
                  value={formData.organizer}
                />
              </Form.Group>
            </Col>

            <Col>

              <Form.Group className="mb-3" controlId="location">
                <Form.Label className="fs-0">Location</Form.Label>
                <Form.Control
                  type="text"
                  name="location"
                  onChange={handleChange}
                  value={formData.location}
                />
              </Form.Group>
            </Col>
          </Row>

          <Form.Group className="mb-3" controlId="label">
            <Form.Label className="fs-0">Label</Form.Label>
            <Form.Select name="className" onChange={handleChange} value={formData?.className}>
              <option>None</option>
              <option value="bg-soft-info">Business</option>
              <option value="bg-soft-success">Important</option>
              <option value="bg-soft-warning">Personal</option>
              <option value="bg-soft-danger">Must Attend</option>
            </Form.Select>
          </Form.Group>
        </Modal.Body>

        <Modal.Footer className="bg-light px-card border-top-0">

          <Row>
            <Col>
              <Button
                disabled={!formData?.id}
                variant="falcon-danger"
                type="button"
                className="px-4 mx-0 me-2"
                onClick={deleteEvent}
              >
                Delete
              </Button>
            </Col>
            <Col>
              <Button
                variant="falcon-warning"
                type="button"
                className="px-4 mx-0 me-2"
                onClick={handleClose}
              >
                Cancel
              </Button>
            </Col>
            <Col>
              <Button
                variant="primary"
                type="submit"
                className="px-4 mx-0"
              >
                Save
              </Button>
            </Col>

          </Row>

        </Modal.Footer>

      </Form>
    </Modal>
  );
};

AddScheduleModal.propTypes = {
  setIsOpenScheduleModal: PropTypes.func.isRequired,
  isOpenScheduleModal: PropTypes.bool.isRequired,
  setInitialEvents: PropTypes.func,
  initialEvents: PropTypes.array,
  scheduleStartDate: PropTypes.instanceOf(Date),
  setScheduleStartDate: PropTypes.func.isRequired,
  scheduleEndDate: PropTypes.instanceOf(Date),
  setScheduleEndDate: PropTypes.func.isRequired
};

export default AddScheduleModal;
