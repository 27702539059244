import axios from "axios";
import { axiosCall } from "./";
import { getParams } from "../functions";

// Revision Log API
export const revisionLogAPI = {
  searchRevisionLogs: async (searchParams) => {
    return await axiosCall({
      method: "Post",
      url: `/api/v1/revision-log/search`,
      data: searchParams
    });
  },
  getRevisionLogById: async (accountId, id) => {
    return await axiosCall({
      method: "Get",
      url: `/api/v1/account/${accountId}/revision-log/${id}`
    });
  },
  getRevisionLogByEntityId: async (accountId, entityId) => {
    return await axiosCall({
      method: "Get",
      url: `/api/v1/account/${accountId}/revision-log/entity/${entityId}`
    });
  },
};

// Address API
export const addressAPI = {
  getAddressListByEntityId: async (entity_id) => {
    return await axiosCall({
      method: "Get",
      url: `/api/v1/contact-address/list/${entity_id}`
    });
  },
  createAddress: async (data) => {
    return await axiosCall({
      method: "Post",
      url: `/api/v1/contact-address/`,
      data: data
    });
  },
  updateAddress: async (addressId, data) => {
    return await axiosCall({
      method: "Put",
      url: `/api/v1/contact-address/${addressId}`,
      data
    });
  },
  deleteAddress: async (id) => {
    return await axiosCall({
      method: "Delete",
      url: `/api/v1/contact-address/${id}`
    });
  },
  getAddressById: async (id) => {
    return await axiosCall({
      method: "Get",
      url: `/api/v1/contact-address/${id}`
    });
  },
  togglePrimaryAddress: async (newPrimaryAddressId = null, entityId) => {
    return await axiosCall({
      method: "Put",
      url: `/api/v1/contact-address/toggle-primary-address/${entityId}`,
      data: {
        newPrimaryAddressId
      }
    });
  },
  getAddressTypes: async () => {
    return await axiosCall({
      method: "Get",
      url: `/api/v1/address-type/`
    });
  },
};

// Phone API
export const phoneAPI = {
  getPhoneListByEntityId: async (entity_id) => {
    return await axiosCall({
      method: "Get",
      url: `/api/v1/contact-phone/list/${entity_id}`
    });
  },
  createPhone: async (data) => {
    return await axiosCall({
      method: "Post",
      url: `/api/v1/contact-phone/`,
      data: data
    });
  },
  updatePhone: async (phoneId, data) => {
    return await axiosCall({
      method: "Put",
      url: `/api/v1/contact-phone/${phoneId}`,
      data
    });
  },
  deletePhone: async (id) => {
    return await axiosCall({
      method: "Delete",
      url: `/api/v1/contact-phone/${id}`
    });
  },
  getPhoneById: async (id) => {
    return await axiosCall({
      method: "Get",
      url: `/api/v1/contact-phone/${id}`
    });
  },
  togglePrimaryPhone: async (newPrimaryPhoneId = null, entityId) => {
    return await axiosCall({
      method: "Put",
      url: `/api/v1/contact-phone/toggle-primary-phone/${entityId}`,
      data: {
        newPrimaryPhoneId
      }
    });
  },
  getPhoneTypes: async () => {
    return await axiosCall({
      method: "Get",
      url: `/api/v1/phone-type/`
    });
  }
};

// User API
export const userAPI = {
  login: async (email, password, info = {}) => {
    return await axiosCall({
      method: "Post",
      url: '/api/public/login',
      data: {
        email,
        password,
        info
      }
    });
  },
  postTFACode: async (uuid, code) => {
    return await axiosCall({
      method: "Post",
      url: '/api/public/login/tfa',
      data: {
        uuid,
        code
      }
    });
  },
  logout: async () => {
    return await axiosCall({
      method: "Post",
      url: '/api/public/logout',
      data: {}
    });
  },
  getUserDetails: async (userId) => {
    return await axiosCall({
      method: "Get",
      url: `/api/v1/user/${userId}`
    });
  },
  createUser: async (data) => {
    return await axiosCall({
      method: "Post",
      url: '/api/v1/user',
      data
    });
  },
  updateUser: async (userId, data) => {
    console.log("updateUser:", userId);
    return await axiosCall({
      method: "Put",
      url: `/api/v1/user/${userId}`,
      data
    });
  },
  deleteUser: async (userId) => {
    return await axiosCall({
      method: "Delete",
      url: `/api/v1/user/${userId}`
    });
  },
  sendUserInviteEmail: async (userId, params = {}) => {
    return await axiosCall({
      method: "Post",
      url: `/api/v1/user/${userId}/invite`,
      params
    });
  },
  sendUserPasswordResetEmail: async (userId, params = {}) => {
    return await axiosCall({
      method: "Post",
      url: `/api/v1/user/${userId}/password-reset`,
      params
    });
  },
  verifyEmailAvailability: async (email) => {
    return await axiosCall({
      method: "Post",
      url: `/api/v1/user/verify-email-availability`,
      data: {
        email
      }
    });
  },
  searchUsers: async (data, params) => {
    return await axiosCall({
      method: "Post",
      url: `/api/v1/user/search`,
      data,
      params
    });
  },
  changePassword: async (userId, currentPassword, newPassword) => {
    return await axiosCall({
      method: "Put",
      url: `/api/v1/user/${userId}/change-password`,
      data: {
        currentPassword,
        newPassword
      }
    });
  },
  setPassword: async (userId, uuid, password) => {
    return await axiosCall({
      method: "Put",
      url: `/api/secure-access/v1/${uuid}/user/${userId}/set-password`,
      data: {
        password
      }
    });
  },
  getUserSession: async () => {
    return await axiosCall({
      method: "Get",
      url: `/api/v1/user/session/refresh`,
      params: { ignoreTouch: true }
    });
  },
  completeUserRegistration: async (userId, uuid, data) => {
    return await axiosCall({
      method: "Put",
      url: `/api/v1/user/${userId}/complete-registration/${uuid}`,
      data
    });
  },
  forgotPassword: async (email) => {
    return await axiosCall({
      method: "Post",
      url: `/api/public/forgot-password`,
      data: {
        email
      }
    });
  },
  getUserPermissions: async (userId) => {
    return await axiosCall({
      method: "Get",
      url: `/api/v1/user/${userId}/permissions`
    });
  },
  updateUserPermission: async (userId, permissionId, data) => {
    return await axiosCall({
      method: "Put",
      url: `/api/v1/user/${userId}/permissions/${permissionId}`,
      data
    });
  },
  createUserPermission: async (userId, data) => {
    return await axiosCall({
      method: "Post",
      url: `/api/v1/user/${userId}/permissions`,
      data
    });
  },
  deleteUserPermission: async (userId, permissionId) => {
    return await axiosCall({
      method: "Delete",
      url: `/api/v1/user/${userId}/permissions/${permissionId}`
    });
  },
  getAssignedUserTypes: async (userId) => {
    return await axiosCall({
      method: "Get",
      url: `/api/v1/user/${userId}/assigned-user-types/`
    });
  },
  createAssignedUserType: async (userId, data) => {
    return await axiosCall({
      method: "Post",
      url: `/api/v1/user/${userId}/assigned-user-types/`,
      data
    });
  },
  updateAssignedUserType: async (userId, userTypeId, data) => {
    return await axiosCall({
      method: "Put",
      url: `/api/v1/user/${userId}/assigned-user-type/${userTypeId}`,
      data
    });
  },
  deleteAssignedUserType: async (userId, userTypeId) => {
    return await axiosCall({
      method: "Delete",
      url: `/api/v1/user/${userId}/assigned-user-types/${userTypeId}`
    });
  },
  searchUsersByUserType: async (userTypeId, params = {}) => {
    return await axiosCall({
      method: "Get",
      url: `/api/v1/user/user-type/${userTypeId}/search`,
      params
    });
  },
  checkUserPermission: async (userId, permissionId) => {
    return await axiosCall({
      method: "Get",
      url: `/api/v1/user/${userId}/permissions/${permissionId}/check`
    });
  },
  executeMOU: async (userId, data) => {
    return await axiosCall({
      method: "Post",
      url: `/api/v1/user/${userId}/execute-mou`,
      data
    });
  },
  uploadMOU: async (userId, data) => {
    return await axiosCall({
      method: "Post",
      url: `/api/v1/user/${userId}/upload-mou`,
      data
    });
  },
};

// Representative Disciplinary Reason API
export const representativeDisciplinaryReasonAPI = {
  getRepresentativeDisciplinaryReason: async (userId, representativeDisciplinaryReasonId) => {
    return await axiosCall({
      method: "Get",
      url: `/api/v1/user/${userId}/representative-disciplinary-reason/${representativeDisciplinaryReasonId}`
    });
  },
  createRepresentativeDisciplinaryReason: async (userId, data) => {
    return await axiosCall({
      method: "Post",
      url: `/api/v1/user/${userId}/representative-disciplinary-reason`,
      data
    });
  },
  updateRepresentativeDisciplinaryReason: async (userId, representativeDisciplinaryReasonId, data) => {
    return await axiosCall({
      method: "Put",
      url: `/api/v1/user/${userId}/representative-disciplinary-reason/${representativeDisciplinaryReasonId}`,
      data
    });
  },
  deleteRepresentativeDisciplinaryReason: async (userId, representativeDisciplinaryReasonId) => {
    return await axiosCall({
      method: "Delete",
      url: `/api/v1/user/${userId}/representative-disciplinary-reason/${representativeDisciplinaryReasonId}`
    });
  },
  getAllRepresentativeDisciplinaryReasonByUserId: async (userId) => {
    return await axiosCall({
      method: "Get",
      url: `/api/v1/user/${userId}/representative-disciplinary-reason`
    });
  }
};

// Representative Admitted API
export const representativeAdmittedAPI = {
  getRepresentativeAdmitted: async (userId, representativeAdmittedId) => {
    return await axiosCall({
      method: "Get",
      url: `/api/v1/user/${userId}/representative-admitted/${representativeAdmittedId}`
    });
  },
  createRepresentativeAdmitted: async (userId, data) => {
    return await axiosCall({
      method: "Post",
      url: `/api/v1/user/${userId}/representative-admitted`,
      data
    });
  },
  updateRepresentativeAdmitted: async (userId, representativeAdmittedId, data) => {
    return await axiosCall({
      method: "Put",
      url: `/api/v1/user/${userId}/representative-admitted/${representativeAdmittedId}`,
      data
    });
  },
  deleteRepresentativeAdmitted: async (userId, representativeAdmittedId) => {
    return await axiosCall({
      method: "Delete",
      url: `/api/v1/user/${userId}/representative-admitted/${representativeAdmittedId}`
    });
  },
  getAllRepresentativeAdmittedLogsByUserId: async (userId) => {
    return await axiosCall({
      method: "Get",
      url: `/api/v1/user/${userId}/representative-admitted`
    });
  }
};


// Pre-hearing Checklist API
export const preHearingChecklistAPI = {
  getPreHearingChecklist: async (hearingId, preHearingChecklistId) => {
    return await axiosCall({
      method: "Get",
      url: `/api/v1/hearing/${hearingId}/pre-hearing-checklist/${preHearingChecklistId}`
    });
  },
  createPreHearingChecklist: async (hearingId, data) => {
    return await axiosCall({
      method: "Post",
      url: `/api/v1/hearing/${hearingId}/pre-hearing-checklist`,
      data
    });
  },
  updatePreHearingChecklist: async (hearingId, preHearingChecklistId, data) => {
    return await axiosCall({
      method: "Put",
      url: `/api/v1/hearing/${hearingId}/pre-hearing-checklist/${preHearingChecklistId}`,
      data
    });
  },
  deletePreHearingChecklist: async (hearingId, preHearingChecklistId) => {
    return await axiosCall({
      method: "Delete",
      url: `/api/v1/hearing/${hearingId}/pre-hearing-checklist/${preHearingChecklistId}`
    });
  }
};

// Post-hearing Summary API
export const postHearingSummaryAPI = {
  getPostHearingSummary: async (hearingId, postHearingSummaryId) => {
    return await axiosCall({
      method: "Get",
      url: `/api/v1/hearing/${hearingId}/post-hearing-summary/${postHearingSummaryId}`
    });
  },
  createPostHearingSummary: async (hearingId, data) => {
    return await axiosCall({
      method: "Post",
      url: `/api/v1/hearing/${hearingId}/post-hearing-summary`,
      data
    });
  },
  updatePostHearingSummary: async (hearingId, postHearingSummaryId, data) => {
    return await axiosCall({
      method: "Put",
      url: `/api/v1/hearing/${hearingId}/post-hearing-summary/${postHearingSummaryId}`,
      data
    });
  },
  deletePostHearingSummary: async (hearingId, postHearingSummaryId) => {
    return await axiosCall({
      method: "Delete",
      url: `/api/v1/hearing/${hearingId}/post-hearing-summary/${postHearingSummaryId}`
    });
  }
};


// Account API
export const accountAPI = {
  getAccount: async (accountId) => {
    return await axiosCall({
      method: "Get",
      url: `/api/v1/account/${accountId}?decryptEncryptedData=true`
    });
  },
  createAccount: async (data) => {
    return await axiosCall({
      method: "Post",
      url: '/api/v1/account',
      data
    });
  },
  updateAccount: async (accountId, data) => {
    console.log("Update account:", accountId, data);
    return await axiosCall({
      method: "Put",
      url: `/api/v1/account/${accountId}`,
      data
    });
  },
  deleteAccount: async (accountId) => {
    return await axiosCall({
      method: "Delete",
      url: `/api/v1/account/${accountId}`
    });
  },
  searchAllAccounts: async (data) => {
    return await axiosCall({
      method: "Post",
      url: '/api/v1/account/search',
      data
    });
  },
  registerAccount: async (data = {}) => {
    let timeout = 1000 * 60 * 2; // 2 minute timeout because if we don't give enough time then post will cancel and page will refresh 
    return await axiosCall({ method: "Post", url: '/api/v1/account/register', data, timeout });
  },
  verifyAccountSystemEmailAvail: async (email) => {
    return await axiosCall({
      method: "Post",
      url: `/api/public/verify-system-email-availability`,
      data: {
        email
      }
    });
  }
};

// AccessVerification API
export const accessVerificationAPI = {
  getAccessVerification: async (uuid) => {
    return await axiosCall({
      method: "Get",
      url: `/api/secure-access/v1/${uuid}/`
    });
  },
  requestAccessVerificationCode: async (data = {}) => {
    return await axiosCall({
      method: "Post",
      url: '/api/v1/access-verification/request-access-verification',
      data
    });
  },
  verifyAccessVerificationCode: async (uuid, data) => {
    return await axiosCall({
      method: "Put",
      url: `/api/secure-access/v1/${uuid}/verify-code`,
      data
    });
  },
  resendGenericAccessCodeRoute: async (uuid, data = {}) => {
    return await axiosCall({
      method: "Post",
      url: `/api/secure-access/v1/${uuid}/resend-code`,
      data
    });
  },
  sendRegistrationEmailVerification: async (data = {}) => {
    return await axiosCall({
      method: "Post",
      url: '/api/secure-access/v1/send-generic-access-verification-email',
      data: {
        ...data,
        type: 'REGISTRATION_EMAIL_VERIFICATION',
        subject: 'Fulcrum - Complete Registration',
        html: 'Use the following code to verify your email and complete the registration: <b>{{code}}</b>',
      }
    });
  }
};

export const sessionAPI = {
  extendSession: async () => {
    return await axiosCall({
      method: "Put",
      url: '/api/v1/user/session'
    });
  },
  getSession: async () => {
    return await axiosCall({ method: "Get", url: '/api/v1/user/session', params: { ignoreTouch: true } });
  },
};

export const taskAPI = {
  createTask: async (accountId, data) => {
    return await axiosCall({
      method: "Post",
      url: `/api/v1/account/${accountId}/task`,
      data
    });
  },
  getTask: async (accountId, taskId) => {
    return await axiosCall({
      method: "Get",
      url: `/api/v1/account/${accountId}/task/${accountId, taskId}`
    });
  },
  updateTask: async (accountId, taskId, data) => {
    return await axiosCall({
      method: "Put",
      url: `/api/v1/account/${accountId}/task/${taskId}`,
      data
    });
  },
  deleteTask: async (accountId, taskId) => {
    return await axiosCall({
      method: "Delete",
      url: `/api/v1/account/${accountId}/task/${taskId}`
    });
  },
  searchTasks: async (data) => {
    return await axiosCall({
      method: "Post",
      url: '/api/v1/task/search',
      data
    });
  },
  getTaskMetrics: async (accountId, data = {}) => {
    return await axiosCall({
      method: "Post",
      url: `/api/v1/account/${accountId}/task/metrics`,
      data
    });
  },
  getTaskTypes: async () => {
    return await axiosCall({
      method: "Get",
      url: '/api/v1/task-type'
    });
  },
  getTaskStatuses: async () => {
    return await axiosCall({
      method: "Get",
      url: '/api/v1/task-status'
    });
  },
  // bulkCreateTasks: async (data) => {
  //   return await axiosCall({
  //     method: "Post",
  //     url: '/api/v1/task/bulk',
  //     data
  //   });
  // },
  bulkCreateTasks: async (accountId, data) => {
    return await axiosCall({
      method: "Post",
      url: `/api/v1/account/${accountId}/task/bulk-create-tasks`,
      data
    });
  },
  getTaskAssignedUsers: async (accountId, taskId) => {
    return await axiosCall({
      method: "Get",
      url: `/api/v1/account/${accountId}/task/${taskId}/assigned-users`
    });
  },
  getAssignedTaskUser: async (accountId, taskId, taskUserId) => {
    return await axiosCall({
      method: "Get",
      url: `/api/v1/account/${accountId}/task/${taskId}/assigned-users/${taskUserId}`
    });
  },
  assignTaskUser: async (accountId, taskId, assigned_users = []) => {
    return await axiosCall({
      method: "Post",
      url: `/api/v1/account/${accountId}/task/${taskId}/assigned-users/`,
      data: {
        assigned_users
      }
    });
  },
  updateAssignedTaskUser: async (accountId, taskId, taskUserId, data) => {
    return await axiosCall({
      method: "Put",
      url: `/api/v1/account/${accountId}/task/${taskId}/assigned-users/${taskUserId}`,
      data
    });
  },
  removeAssignedTaskUser: async (accountId, taskId, taskUserId) => {
    return await axiosCall({
      method: "Delete",
      url: `/api/v1/account/${accountId}/task/${taskId}/assigned-users/${taskUserId}`
    });
  },
  acknowledgeTask: async (accountId, taskId) => {
    return await axiosCall({
      method: "Post",
      url: `/api/v1/account/${accountId}/task/${accountId, taskId}/action/acknowledge`
    });
  },
  completeTask: async (accountId, taskId) => {
    return await axiosCall({
      method: "Post",
      url: `/api/v1/account/${accountId}/task/${accountId, taskId}/action/complete`
    });
  }
};

/**
 * Provides UUID access based authorization to the routes as applicable
 */
export const claimantDetailsAPI = {
  getClaimantDetails: async (uuid, claimantId) => {
    return await axiosCall({
      method: "Get",
      url: `/api/secure-access/v1/${uuid}/claimant/${claimantId}/details/`
    });
  },
  updateClaimantDetails: async (uuid, claimantId, data) => {
    console.log("updateClaimantDetails " + JSON.stringify({ uuid, claimantId, data }));
    return await axiosCall({
      method: "Put",
      url: `/api/secure-access/v1/${uuid}/claimant/${claimantId}/details`,
      data
    });
  },
  getAllClaimantDoctorsDetails: async (uuid, claimantId) => {
    alert("getAllClaimantDoctorsDetails " + JSON.stringify({ uuid, claimantId }));
    return await axiosCall({
      method: "Get",
      url: `/api/secure-access/v1/${uuid}/claimant/${claimantId}/details/claimant-doctor`
    });
  },
  updateClaimantDoctorDetails: async (uuid, claimantId, claimantDoctorId, data) => {
    return await axiosCall({
      method: "Put",
      url: `/api/secure-access/v1/${uuid}/claimant/${claimantId}/details/claimant-doctor/${claimantDoctorId}`,
      data
    });
  },
  deleteClaimantDoctorDetails: async (uuid, claimantId, claimantDoctorId) => {
    return await axiosCall({
      method: "Delete",
      url: `/api/secure-access/v1/${uuid}/claimant/${claimantId}/details/claimant-doctor/${claimantDoctorId}`
    });
  },
  createClaimantHospitalVisitDetails: async (uuid, claimantId, data) => {
    return await axiosCall({
      method: "Post",
      url: `/api/secure-access/v1/${uuid}/claimant/${claimantId}/details/claimant-hospital-visits`,
      data
    });
  },
  getClaimantHospitalVisitDetails: async (uuid, claimantId, claimantHospitalVisitId) => {
    return await axiosCall({
      method: "Get",
      url: `/api/secure-access/v1/${uuid}/claimant/${claimantId}/details/claimant-hospital-visits/${claimantHospitalVisitId}`
    });
  },
  getAllClaimantHospitalVisitsDetails: async (uuid, claimantId) => {
    return await axiosCall({
      method: "Get",
      url: `/api/secure-access/v1/${uuid}/claimant/${claimantId}/details/claimant-hospital-visits`
    });
  },
  updateClaimantHospitalVisitDetails: async (uuid, claimantId, claimantHospitalVisitId, data) => {
    return await axiosCall({
      method: "Put",
      url: `/api/secure-access/v1/${uuid}/claimant/${claimantId}/details/claimant-hospital-visits/${claimantHospitalVisitId}`,
      data
    });
  },
  deleteClaimantHospitalVisitDetails: async (uuid, claimantId, claimantHospitalVisitId) => {
    return await axiosCall({
      method: "Delete",
      url: `/api/secure-access/v1/${uuid}/claimant/${claimantId}/details/claimant-hospital-visits/${claimantHospitalVisitId}`
    });
  },
  verifyAccessVerificationForClaimantDetails: async (uuid, claimantId, data = {}) => {
    return await axiosCall({
      method: "Post",
      url: `/api/secure-access/v1/${uuid}/claimant/${claimantId}/details/verify-access-verification`,
      data
    });
  },
  getAddressListByEntityId: async (uuid, claimantId, entity_id) => {
    return await axiosCall({
      method: "Get",
      url: `/api/secure-access/v1/${uuid}/claimant/${claimantId}/details/contact-address/list/${entity_id}`
    });
  },
  createAddress: async (uuid, claimantId, data) => {
    return await axiosCall({
      method: "Post",
      url: `/api/secure-access/v1/${uuid}/claimant/${claimantId}/details/contact-address/`,
      data: data
    });
  },
  updateAddress: async (uuid, claimantId, addressId, data) => {
    return await axiosCall({
      method: "Put",
      url: `/api/secure-access/v1/${uuid}/claimant/${claimantId}/details/contact-address/${addressId}`,
      data
    });
  },
  deleteAddress: async (uuid, claimantId, id) => {
    return await axiosCall({
      method: "Delete",
      url: `/api/secure-access/v1/${uuid}/claimant/${claimantId}/details/contact-address/${id}`
    });
  },
  getAllClaimantDoctors: async (uuid, claimantId) => {
    return await axiosCall({
      method: "Get",
      url: `/api/secure-access/v1/${uuid}/claimant/${claimantId}/details/claimant/${claimantId}/claimant-doctor`
    });
  },
};

export const entityTagAPI = {
  getEntityTags: async (entityId, data = {}) => {
    return await axiosCall({
      method: "Post",
      url: `/api/v1/entity-tag/entity/${entityId}/tag/search`,
      data
    });
  },
  createEntityTag: async (entityId, data) => {
    return await axiosCall({
      method: "Post",
      url: `/api/v1/entity-tag/entity/${entityId}/tag`,
      data
    });
  },
  deleteEntityTag: async (entityId, tagId) => {
    return await axiosCall({
      method: "Delete",
      url: `/api/v1/entity-tag/entity/${entityId}/tag/${tagId}`
    });
  },
  getEntityTag: async (entityId, tagId) => {
    return await axiosCall({
      method: "Get",
      url: `/api/v1/entity-tag/entity/${entityId}/tag/${tagId}`
    });
  },
  updateEntityTag: async (entityId, tagId, data) => {
    return await axiosCall({
      method: "Put",
      url: `/api/v1/entity-tag/entity/${entityId}/tag/${tagId}`,
      data
    });
  },
};

export const claimantAPI = {
  createClaimant: async (data) => {
    return await axiosCall({
      method: "Post",
      url: '/api/v1/claimant',
      data
    });
  },
  getClaimant: async (claimantId, saveRecentClaimant = false, params = {}) => {
    return await axiosCall({
      method: "Get",
      url: `/api/v1/claimant/${claimantId}`,
      params: {
        saveRecentClaimant,
        ...params
      }
    });
  },
  updateClaimant: async (claimantId, data) => {
    return await axiosCall({
      method: "Put",
      url: `/api/v1/claimant/${claimantId}`,
      data
    });
  },
  deleteClaimant: async (claimantId) => {
    return await axiosCall({
      method: "Delete",
      url: `/api/v1/claimant/${claimantId}`
    });
  },
  closeFile: async (claimantId, data) => {
    return await axiosCall({
      method: "Post",
      url: `/api/v1/claimant/${claimantId}/close-file`,
      data
    });
  },
  searchClaimants: async (data) => {
    return await axiosCall({
      method: "Post",
      url: '/api/v1/claimant/search',
      data
    });
  },
  getReport: async (data) => {
    return await axiosCall({
      method: "Post",
      url: '/api/v1/report/search',
      data
    });
  },
  createClaimantReport: async (accountId, data) => {
    return await axiosCall({
      method: "Post",
      url: `/api/v1/account/${accountId}/claimant-report`,
      data
    });
  },
  updateClaimantReport: async (accountId, claimantReportId, data) => {
    return await axiosCall({
      method: "Put",
      url: `/api/v1/account/${accountId}/claimant-report/${claimantReportId}`,
      data
    });
  },
  searchAllClaimantReport: async (queryParams) => {
    return await axiosCall({
      method: "Get",
      url: `/api/v1/claimant-report/search${getParams(queryParams)}`,
    });
  },
  getClaimantReport: async (accountId, claimantReportId) => {
    return await axiosCall({
      method: "Get",
      url: `/api/v1/account/${accountId}/claimant-report/${claimantReportId}`,
    });
  },
  deleteClaimantReport: async (accountId, claimantReportId) => {
    return await axiosCall({
      method: "Delete",
      url: `/api/v1/account/${accountId}/claimant-report/${claimantReportId}`,
    });
  },
  searchClaimantCount: async (data) => {
    return await axiosCall({
      method: "Post",
      url: '/api/v1/claimant/search/count',
      data
    });
  },
  getClaimantCase: async (claimantId, caseId, params = {}) => {
    return await axiosCall({
      method: "Get",
      url: `/api/v1/claimant/${claimantId}/case/${caseId}`,
      params
    });
  },
  createClaimantActiveCase: async (claimantId, data) => {
    return await axiosCall({
      method: "Post",
      url: `/api/v1/claimant/${claimantId}/case`,
      data
    });
  },
  exportDataToExcel: async (data, cb = () => { }) => { // Export data to excel should work with any controller function! 
    return await axios.post(`/api/v1/claimant-report/export`, data, {
      responseType: 'blob',
      timeout: 1000 * 60 * 1
    }).then(async (response) => {
      let { status, statusText, headers = {}, data } = response || {};
      let type = headers['content-type'] || 'application/json'; // Extract type from header 

      if (status === 204 || data.size === 0) {
        data = {
          ok: status === 200,
          statusCode: status,
          statusMessage: statusText || 'No Data Found',
        };
      } else if (["text/html", "text/plain", "application/json"].some((t) => (type.includes(t)))) {
        let tmpData = await data.text();
        if (tmpData && type.includes('application/json')) {
          data = JSON.parse(tmpData);
        } else {
          data = {
            ok: status === 200,
            statusCode: status,
            statusMessage: tmpData || statusText || 'No Data Found',
          };
        }
      } else { // Download the file 
        const blob = new Blob([data], { type });

        // Extract filename from header
        let disposition = headers['content-disposition']; // "attachment;filename=filename.xlsx" 
        const regex = /filename[^;\n=]*=((['"]).*?\2|[^;\n]*)/g;
        let m;
        let filename = '';
        if ((m = regex.exec(disposition)) !== null) {
          m.forEach((match, groupIndex) => {
            if (groupIndex === 1) {
              filename = match;
            }
          });
        }

        // Download the file 
        const link = document.createElement('a');
        link.download = filename;
        link.href = URL.createObjectURL(blob);
        link.addEventListener('click', () => {
          setTimeout(() => URL.revokeObjectURL(link.href), 30 * 1000);
        });
        link.click();
        data = {
          ok: status === 200,
          statusCode: status,
          statusMessage: `${filename} Successfully Downloaded!`
        };
      }

      if (typeof cb === 'function') {
        return cb({ status, statusText, data });
      }
      return { status, statusText, data };
    });
  },
  sendClaimantScreeningDocuments: async (claimantId, data) => {
    return await axiosCall({
      method: "Post",
      url: `/api/v1/claimant/${claimantId}/send-claimant-screening-documents`,
      data
    });
  },
  sendClaimantRetainerPacket: async (claimantId, data = {}, params = {}) => {
    return await axiosCall({
      method: "Post",
      url: `/api/v1/claimant/${claimantId}/send-claimant-retainer-packet`,
      data,
      params
    });
  },
  sendClaimantSSADocuments: async (claimantId, data) => {
    return await axiosCall({
      method: "Post",
      url: `/api/v1/claimant/${claimantId}/send-claimant-ssa-documents`,
      data
    });
  },
  sendUpdateInformationRequest: async (claimantId, data) => {
    return await axiosCall({
      method: "Post",
      url: `/api/v1/claimant/${claimantId}/send-claimant-update-information-request`,
      data
    });
  },
};

export const caseAPI = {
  createCase: async (claimantId, data) => {
    return await axiosCall({
      method: "Post",
      url: `/api/v1/claimant/${claimantId}/case`,
      data
    });
  },
  getCase: async (claimantId, caseId) => {
    return await axiosCall({
      method: "Get",
      url: `/api/v1/claimant/${claimantId}/case/${caseId}`
    });
  },
  updateCase: async (claimantId, caseId, data) => {
    return await axiosCall({
      method: "Put",
      url: `/api/v1/claimant/${claimantId}/case/${caseId}`,
      data
    });
  },
  deleteCase: async (claimantId, caseId) => {
    return await axiosCall({
      method: "Delete",
      url: `/api/v1/claimant/${claimantId}/case/${caseId}`
    });
  },
  searchCases: async (data) => {
    return await axiosCall({
      method: "Post",
      url: '/api/v1/case/search',
      data
    });
  },
  getCaseStatuses: async () => {
    return await axiosCall({
      method: "Get",
      url: '/api/v1/case-status'
    });
  },
  confirmSSAApplication: async (claimantId, caseId, data = {}) => {
    return await axiosCall({
      method: "Put",
      url: `/api/v1/claimant/${claimantId}/case/${caseId}/action/confirm-ssa-application`,
      data
    });
  },
  confirmApoointmentAsRepresentative: async (claimantId, caseId, data = {}) => {
    return await axiosCall({
      method: "Put",
      url: `/api/v1/claimant/${claimantId}/case/${caseId}/action/confirm-appointment-as-representative`,
      data
    });
  },
  submitRepresentiveDocuments: async (claimantId, caseId, data = {}) => {
    return await axiosCall({
      method: "Post",
      url: `/api/v1/claimant/${claimantId}/case/${caseId}/action/submit-representative-documents`,
      data
    });
  },
  lookupOfficeWithJurisdiction: async (claimantId, caseId) => {
    return await axiosCall({
      method: "Get",
      url: `/api/v1/claimant/${claimantId}/case/${caseId}/action/lookup-field-office`
    });
  },
  requestUpdatedCaseStatus: async (claimantId, caseId, data = {}) => {
    return await axiosCall({
      method: "Post",
      url: `/api/v1/claimant/${claimantId}/case/${caseId}/action/request-updated-case-status`,
      data
    });
  },
  closeClaimantCase: async (claimantId, caseId, data = {}) => {
    return await axiosCall({
      method: 'Post',
      url: `/api/v1/claimant/${claimantId}/case/${caseId}/action/close-case`,
      data
    })
  },
  // getCaseStatuses: async () => {
  //   return await axiosCall({
  //     method: "Get",
  //     url: '/api/v1/case-status'
  //   });
  // },
  createOnlineAppealCheckTask: async (claimantId, caseId, data = {}) => {
    return await axiosCall({
      method: 'Post',
      url: `/api/v1/claimant/${claimantId}/case/${caseId}/action/create-online-appeal-check-task`,
      data
    })
  },
  fileAnAppeal: async (claimantId, caseId, data = {}) => {
    return await axiosCall({
      method: 'Post',
      url: `/api/v1/claimant/${claimantId}/case/${caseId}/action/file-an-appeal`,
      data
    })
  }
};

export const noteAPI = {
  createNote: async (claimantId, data) => {
    return await axiosCall({
      method: "Post",
      url: `/api/v1/claimant/${claimantId}/note`,
      data
    });
  },
  getNote: async (claimantId, noteId) => {
    return await axiosCall({
      method: "Get",
      url: `/api/v1/claimant/${claimantId}/note/${noteId}`
    });
  },
  updateNote: async (claimantId, noteId, data) => {
    return await axiosCall({
      method: "Put",
      url: `/api/v1/claimant/${claimantId}/note/${noteId}`,
      data
    });
  },
  deleteNote: async (claimantId, noteId) => {
    return await axiosCall({
      method: "Delete",
      url: `/api/v1/claimant/${claimantId}/note/${noteId}`
    });
  },
  searchNotes: async (body = {}) => {
    return await axiosCall({
      method: "Post",
      url: '/api/v1/note/search',
      body
    });
  },
  getAllClaimantNotes: async (claimantId) => {
    return await axiosCall({
      method: "Get",
      url: `/api/v1/claimant/${claimantId}/note`
    });
  }
};

export const paymentAPI = {
  createSetupIntent: async (data = {}) => { // data = { customer_id, payment_method_types }
    return await axiosCall(({
      method: "Post",
      url: '/api/v1/stripe/setup-intent',
      data,
      headers: {
        "Content-Type": "application/json"
      }
    }));
  },
  searchPaymentMethods: async (data = {}) => {
    return await axiosCall({ method: "Post", url: "/api/v1/stripe-customer/payment-methods/search", data });
  },
  getPaymentMethod: async (paymentMethodId) => {
    return await axiosCall({ method: "Get", url: `/api/v1/stripe-customer/payment-methods/${paymentMethodId}` });
  },
  deletePaymentMethod: async (paymentMethodId) => {
    return await axiosCall({ method: "Delete", url: `/api/v1/stripe-customer/payment-methods/${paymentMethodId}` });
  },
  updatePaymentMethod: async (paymentMethodId, data = {}) => {
    return await axiosCall({ method: "Put", url: `/api/v1/stripe-customer/payment-methods/${paymentMethodId}`, data });
  },
  createPaymentMethod: async (data = {}) => {
    return await axiosCall({ method: "Post", url: "/api/v1/stripe-customer/payment-methods", data });
  },
}

export const documentAPI = {
  createDocument: async (accountId, data) => {
    return await axiosCall({
      method: "Post",
      url: `/api/v1/account/${accountId}/document`,
      data
    });
  },
  bulkCreateDocuments: async (accountId, data) => {
    return await axiosCall({
      method: "Post",
      url: `/api/v1/account/${accountId}/document/bulk`,
      data
    });
  },
  getDocument: async (accountId, documentId) => {
    return await axiosCall({
      method: "Get",
      url: `/api/v1/account/${accountId}/document/${documentId}`
    });
  },
  updateDocument: async (accountId, documentId, data) => {
    return await axiosCall({
      method: "Put",
      url: `/api/v1/account/${accountId}/document/${documentId}`,
      data
    });
  },
  deleteDocument: async (accountId, documentId) => {
    return await axiosCall({
      method: "Delete",
      url: `/api/v1/account/${accountId}/document/${documentId}`
    });
  },
  searchDocuments: async (data) => {
    return await axiosCall({
      method: "Post",
      url: '/api/v1/document/search',
      data
    });
  },
  createDocumentType: async (data) => {
    return await axiosCall({
      method: "Post",
      url: '/api/v1/document-type',
      data
    });
  },
  getDocumentType: async (documentTypeId) => {
    return await axiosCall({
      method: "Get",
      url: `/api/v1/document-type/${documentTypeId}`
    });
  },
  updateDocumentType: async (documentTypeId, data) => {
    return await axiosCall({
      method: "Put",
      url: `/api/v1/document-type/${documentTypeId}`,
      data
    });
  },
  deleteDocumentType: async (documentTypeId) => {
    return await axiosCall({
      method: "Delete",
      url: `/api/v1/document-type/${documentTypeId}`
    });
  },
  searchDocumentTypes: async (data) => {
    return await axiosCall({
      method: "Post",
      url: '/api/v1/document-type/search',
      data
    });
  },
  createDocumentStatus: async (data) => {
    return await axiosCall({
      method: "Post",
      url: '/api/v1/document-status',
      data
    });
  },
  getDocumentStatus: async (documentStatusId) => {
    return await axiosCall({
      method: "Get",
      url: `/api/v1/document-status/${documentStatusId}`
    });
  },
  updateDocumentStatus: async (documentStatusId, data) => {
    return await axiosCall({
      method: "Put",
      url: `/api/v1/document-status/${documentStatusId}`,
      data
    });
  },
  deleteDocumentStatus: async (documentStatusId) => {
    return await axiosCall({
      method: "Delete",
      url: `/api/v1/document-status/${documentStatusId}`
    });
  },
  searchDocumentStatuses: async (data) => {
    return await axiosCall({
      method: "Post",
      url: '/api/v1/document-status/search',
      data
    });
  },
  uploadDocument: async (accountId, data) => {
    return await axiosCall({
      method: "Post",
      url: `/api/v1/account/${accountId}/document/upload`,
      data
    });
  },
  savePDFBlob: async (blob) => {
    // return await axiosCall({ method: "Post", url: '/api/v1/file/save-pdf-blob', data });
    // Extract filename from header
    let headers = {};
    let type = "application/octet-stream";
    let disposition = headers['content-disposition']; // "attachment;filename=filename.xlsx" 
    const regex = /filename[^;\n=]*=((['"]).*?\2|[^;\n]*)/g;
    let m;
    let filename = 'sdafadsfasdf.pdf';
    if ((m = regex.exec(disposition)) !== null) {
      m.forEach((match, groupIndex) => {
        if (groupIndex === 1) {
          filename = match;
        }
      });
    }

    // Download the file 
    const link = document.createElement('a');
    link.download = filename;
    link.href = URL.createObjectURL(blob);
    link.addEventListener('click', () => {
      setTimeout(() => URL.revokeObjectURL(link.href), 30 * 1000);
    });
    link.click();
    // cb({ status, statusText, data: `Successfully Downloaded File` });
  },
  downloadDocuments: async (accountId, fileIdList, cb = () => { }) => {
    return await axios.post(`/api/v1/account/${accountId}/document/download`, { fileIdList }, {
      responseType: 'blob',
      timeout: 1000 * 60 * 1
    }).then(async (response) => {
      let { status, statusText, headers = {}, data } = response || {};
      let type = headers['content-type'] || 'application/octet-stream';

      if (status === 200) {
        const blob = new Blob([data], { type });

        // Extract filename from header
        let disposition = headers['content-disposition']; // "attachment;filename=filename.xlsx" 
        const regex = /filename[^;\n=]*=((['"]).*?\2|[^;\n]*)/g;
        let m;
        let filename = '';
        if ((m = regex.exec(disposition)) !== null) {
          m.forEach((match, groupIndex) => {
            if (groupIndex === 1) {
              filename = match;
            }
          });
        }

        // Download the file 
        const link = document.createElement('a');
        link.download = filename;
        link.href = URL.createObjectURL(blob);
        link.addEventListener('click', () => {
          setTimeout(() => URL.revokeObjectURL(link.href), 30 * 1000);
        });
        link.click();
        if (typeof cb === 'function') {
          return cb({ status, statusText, data: `Successfully Downloaded File` });
        }
        return { status, statusText, data: `Successfully Downloaded File` };
      } else {
        if (type.includes('text/html')) {
          data = await data.text();
        }
        if (typeof cb === 'function') {
          return cb({ status, statusText, data });
        }
        return { status, statusText, data };
      }
    });
  },
  streamFileFromS3: async (fileId, cb = () => { }) => {
    return await axios.get(`/api/v1/file/${fileId}/stream-file-from-s3`, {
      responseType: 'blob',
      timeout: 1000 * 60 * 1
    }).then(async (response) => {
      let {
        status,
        headers = {},
        data
      } = response || {};
      let type = headers['content-type'] || 'application/pdf'; // Extract type from header 

      if (status === 200) {
        let blob = new Blob([data], {
          type
        });
        return blob;
      } else {
        return {
          error: "Unable to retrieve file blob"
        }
      }
    });
  },
  getClaimantDocuments: async (claimantId) => {
    return await axiosCall({
      method: "Get",
      url: `/api/v1/claimant/${claimantId}/documents`
    });
  }
}

export const myFormsAPI = {
  createForm: async (data) => {
    return await axiosCall({
      method: "Post",
      url: '/api/v1/my-form',
      data
    });
  },
  getMyForm: async (myFormId) => {
    return await axiosCall({
      method: "Get",
      url: `/api/v1/my-form/${myFormId}`
    });
  },
  updateMyForm: async (myFormId, data) => {
    return await axiosCall({
      method: "Put",
      url: `/api/v1/my-form/${myFormId}`,
      data
    });
  },
  deleteMyForm: async (myFormId) => {
    return await axiosCall({
      method: "Delete",
      url: `/api/v1/my-form/${myFormId}`
    });
  },
  searchMyForms: async (data) => {
    return await axiosCall({
      method: "Post",
      url: '/api/v1/my-form/search',
      data
    });
  },
  renderMyFormContent: async (content, relatedData) => {
    return await axiosCall({
      method: "Post",
      url: `/api/v1/my-form/action/parse-form-content`,
      data: {
        content,
        relatedData
      }
    });
  },
  getCorrespondenceMyFormResponseByUUID: async (uuid, inboxOutboxId, myFormResponseId) => {
    return await axiosCall({
      method: "Get",
      url: `/api/secure-access/v1/${uuid}/correspondence/${inboxOutboxId}/my-form-response/${myFormResponseId}`
    });
  },
  getMyFormResponseByUUID: async (uuid, myFormResponseId) => {
    return await axiosCall({
      method: "Get",
      url: `/api/public/my-form/${myFormResponseId}/response/uuid/${uuid}`
    });
  },
  updateFormResponse: async (myFormResponseId, data = {}) => {
    return await axiosCall({
      method: "Put",
      url: `/api/public/my-form/response/${myFormResponseId}`,
      data
    });
  },
  submitMyFormResponse: async (uuid, myFormResponseId, data = {}) => {
    return await axiosCall({
      method: "Post",
      url: `/api/public/my-form/response/${myFormResponseId}/submit/${uuid}`,
      data
    });
  },
  getRenderedMyFormContentById: async (myFormId, relatedData = {}) => {
    return await axiosCall({
      method: "Post",
      url: `/api/v1/my-form/${myFormId}/action/render-form-content-by-form-id`,
      data: {
        relatedData
      }
    });
  },
  getRenderedMyFormContent: async (content = "", relatedData = {}, testMode = false) => {
    return await axiosCall({
      method: "Post",
      url: `/api/v1/my-form/action/render-form-content`,
      data: {
        content,
        relatedData,
        testMode
      }
    });
  },
  savePDFFormData: async (myFormResponseId, data = {}) => {
    return await axiosCall({
      method: "Put",
      url: `/api/public/pdf-form-response/${myFormResponseId}`,
      data
    });
  },
  streamPDFWithFormData: async (myFormResponseId) => {
    return await axios.get(`/api/v1/my-form/response/${myFormResponseId}/stream-pdf`, {
      responseType: 'blob',
      timeout: 1000 * 60 * 1
    }).then(async (response) => {
      let {
        status,
        headers = {},
        data
      } = response || {};
      let type = headers['content-type'] || 'application/pdf'; // Extract type from header 

      if (status === 200) {
        let blob = new Blob([data], {
          type
        });
        return blob;
      } else {
        return {
          error: "Unable to retrieve file blob"
        }
      }
    });
  },
  streamPDFWithFormDataPreview: async ({ claimant_id = 0, user_id = 0, myFormResponseId = 0, pdf_template } = {}) => {
    let streamURL = "/api/v1/my-form/response-preview/";
    if (myFormResponseId) {
      streamURL += `${myFormResponseId}`;
    } else if (claimant_id) {
      streamURL += `${claimant_id}/${pdf_template}`;
    } else if (user_id) {
      streamURL += `user/${user_id}/${pdf_template}`;
    } else {
      return {
        error: "Missing claimant_id or user_id"
      }
    }

    return await axios.get(streamURL, {
      responseType: 'blob',
      timeout: 1000 * 60 * 1
    }).then(async (response) => {
      let { status, headers = {}, data } = response || {};
      let type = headers['content-type'] || 'application/pdf'; // Extract type from header 

      if (status === 200) {
        return new Blob([data], { type });
      } else {
        return {
          error: "Unable to retrieve file blob"
        }
      }
    });
  },
  saveFilledPdf: async ({ claimant_id = 0, myFormResponseId = 0, pdf_template, ...data } = {}) => {
    let resp;
    if (myFormResponseId) {
      resp = await axios.patch(`/api/v1/my-form/response-preview/${myFormResponseId}`, { pdf_template, cleanup_files: false, ...data }, { responseType: 'blob', timeout: 1000 * 60 * 5 })
    } else {
      resp = await axios.post(`/api/v1/my-form/response-preview/${claimant_id}`, { pdf_template, ...data }, { responseType: 'blob', timeout: 1000 * 60 * 5 })
    }

    let { status, statusText, headers, data: response } = resp || {};
    let type = headers['content-type'] || 'application/pdf'; // Extract type from header
    let message = statusText;

    if (["text/html", "text/plain", "application/json"].some((t) => (type.includes(t)))) {
      let tmpData = await response.text();
      if (type.includes("application/json")) {
        response = JSON.parse(tmpData);
      } else {
        message = tmpData;
      }
    } else {
      let disposition = headers['content-disposition'];
      const filenameRegex = /filename[^;\n=]*=((['"]).*?\2|[^;\n]*)/g;
      let m;
      let filename = '';
      if ((m = filenameRegex.exec(disposition)) !== null) {
        m.forEach((match, groupIndex) => {
          if (groupIndex === 1) {
            filename = match;
          }
        });
      }
      if (!filename) {
        filename = `${pdf_template}.pdf`;
      }

      // Download the file 
      const link = document.createElement('a');
      link.download = filename;
      link.href = URL.createObjectURL(response);
      link.addEventListener('click', (e) => {
        setTimeout(() => URL.revokeObjectURL(link.href), 30 * 1000);
      });
      link.click();

      message = `Successfully Downloaded File ${filename}`;
      response = {
        statusCode: status,
        statusMessage: message,
      };
    }

    let error = status === 200 ? null : message;
    return { error, response, message };
  },
  getAvailableKeys: async (params) => {
    return await axiosCall({
      method: "Get",
      url: `/api/v1/my-form/available-keys`,
      params
    });
  },
  createDocumentFromMyFormContent: async (data) => {
    return await axiosCall({
      method: "Post",
      url: `/api/v1/document/action/create-document-from-my-form-content`,
      data // { content, claimantIdLIst, documentData }
    });
  }
}

export const inboxOutboxAPI = {
  createInboxOutbox: async (accountId, data) => {
    return await axiosCall({
      method: "Post",
      url: `/api/v1/account/${accountId}/inbox-outbox`,
      data
    });
  },
  submitCorrespondence: async (accountId, data) => {
    return await axiosCall({
      method: "Post",
      url: `/api/v1/account/${accountId}/inbox-outbox/action/submit-correspondence`,
      data
    });
  },
  getInboxOutbox: async (accountId, inboxOutboxId) => {
    return await axiosCall({
      method: "Get",
      url: `/api/v1/account/${accountId}/inbox-outbox/${inboxOutboxId}`
    });
  },
  updateInboxOutbox: async (accountId, inboxOutboxId, data) => {
    return await axiosCall({
      method: "Put",
      url: `/api/v1/account/${accountId}/inbox-outbox/${inboxOutboxId}`,
      data
    });
  },
  deleteInboxOutbox: async (accountId, inboxOutboxId) => {
    return await axiosCall({
      method: "Delete",
      url: `/api/v1/account/${accountId}/inbox-outbox/${inboxOutboxId}`
    });
  },
  searchInboxOutboxes: async (data, params = {}) => {
    return await axiosCall({
      method: "Post",
      url: '/api/v1/inbox-outbox/search',
      data,
      params
    });
  },
  createCorrespondence: async (accountId, data) => {
    return await axiosCall({
      method: "Post",
      url: `/api/v1/account/${accountId}/inbox-outbox/create-correspondence`,
      data
    });
  },
  verifyInboxOutboxAccessVerification: async (uuid, inboxOutboxId, data = {}) => {
    return await axiosCall({
      method: "Post",
      url: `/api/public/correspondence/${inboxOutboxId}/verify-access/${uuid}`,
      data
    });
  },
  uploadCorrespondence: async (accountId, data = {}) => {
    return await axiosCall({
      method: "Post",
      url: `/api/v1/account/${accountId}/inbox-outbox/action/upload-correspondence`,
      data
    });
  },
  associateClaimantToCorrespondence: async (accountId, inboxOutboxId, claimantId, data = {}) => {
    return await axiosCall({
      method: "Post",
      url: `/api/v1/account/${accountId}/inbox-outbox/${inboxOutboxId}/action/associate-claimant/${claimantId}`,
      data
    });
  }
};

export const accountServiceAPI = {
  createAccountService: async (data) => {
    return await axiosCall({
      method: "Post",
      url: '/api/v1/account-service',
      data
    });
  },
  getMyAccountService: async (myAccountServiceId) => {
    return await axiosCall({
      method: "Get",
      url: `/api/v1/account-service/${myAccountServiceId}`
    });
  },
  updateMyAccountService: async (myAccountServiceId, data) => {
    return await axiosCall({
      method: "Put",
      url: `/api/v1/account-service/${myAccountServiceId}`,
      data
    });
  },
  deleteMyAccountService: async (myAccountServiceId) => {
    return await axiosCall({
      method: "Delete",
      url: `/api/v1/account-service/${myAccountServiceId}`
    });
  },
  searchMyAccountService: async (data = {}) => {
    return await axiosCall({
      method: "Post",
      url: '/api/v1/account-service/search',
      data
    });
  },
  checkAccountServiceStatus: async (accountId, serviceId, accountServiceId) => {
    return await axiosCall({
      method: "Post",
      url: `/api/v1/account/${accountId}/service/${serviceId}/check-status/${accountServiceId}`,
      data: {}
    });
  },
  terminateMyAccountServiceAgreement: async (accountId, serviceId) => {
    return await axiosCall({
      method: "Delete",
      url: `/api/v1/account/${accountId}/service/${serviceId}/terminate-service-agreement`
    });
  },
  downloadMyAccountServiceAgreementDocument: async (accountId, serviceId, fileId) => {
    return await axiosCall({
      method: "Get",
      url: `/api/v1/account/${accountId}/service/${serviceId}/download-service-agreement-document/${fileId}`
    });
  },
}

export const SettingAPI = {
  createSetting: async (data) => {
    return await axiosCall({ method: "Post", url: `/api/v1/account/setting`, data });
  },
  getSetting: async (settingId) => {
    return await axiosCall({ method: "Get", url: `/api/v1/account/setting/${settingId}` });
  },
  updateSetting: async (settingId, data) => {
    return await axiosCall({ method: "Put", url: `/api/v1/account/setting/${settingId}`, data });
  },
  deleteSetting: async (settingId) => {
    return await axiosCall({ method: "Delete", url: `/api/v1/account/setting/${settingId}` });
  },
  searchAllSetting: async (data) => {
    return await axiosCall({ method: "Patch", url: `/api/v1/account/setting`, data });
  }
}

export const accountSettingAPI = {
  createAccountSetting: async (accountId, data) => {
    return await axiosCall({ method: "Post", url: `/api/v1/account/${accountId}/setting`, data });
  },
  getAppliedAccountSettings: async (accountId) => {
    return await axiosCall({ method: "Get", url: `/api/v1/account/${accountId}/setting/applied-account-settings` });
  },
  getAccountSetting: async (accountId, accountSettingId) => {
    return await axiosCall({ method: "Get", url: `/api/v1/account/${accountId}/setting/${accountSettingId}` });
  },
  updateAccountSetting: async (accountId, accountSettingId, data) => {
    return await axiosCall({ method: "Put", url: `/api/v1/account/${accountId}/setting/${accountSettingId}`, data });
  },
  deleteAccountSetting: async (accountId, accountSettingId) => {
    return await axiosCall({ method: "Delete", url: `/api/v1/account/${accountId}/setting/${accountSettingId}` });
  },
  searchAllAccountSetting: async (data) => {
    return await axiosCall({ method: "Patch", url: `/api/v1/account/setting/search`, data });
  }
}

export const superAdminAPI = {
  checkSuperAdminStatus: async () => {
    return await axiosCall({ method: "Get", url: `/api/v1/user/check-super-admin-status` });
  },
  createAccount: async (data) => {
    return await axiosCall({ method: "Post", url: `/api/v1/admin/account`, data });
  },
  searchAllAccounts: async (data) => {
    return await axiosCall({ method: "Patch", url: `/api/v1/admin/account/search`, data });
  }
}

export const serviceAPI = {
  createService: async (data) => {
    return await axiosCall({
      method: "Post",
      url: '/api/v1/service',
      data
    });
  },
  getMyService: async (myServiceId) => {
    return await axiosCall({
      method: "Get",
      url: `/api/v1/service/${myServiceId}`
    });
  },
  updateMyService: async (myServiceId, data) => {
    return await axiosCall({
      method: "Put",
      url: `/api/v1/service/${myServiceId}`,
      data
    });
  },
  deleteMyService: async (myServiceId) => {
    return await axiosCall({
      method: "Delete",
      url: `/api/v1/service/${myServiceId}`
    });
  },
  searchMyService: async (data = {}) => {
    return await axiosCall({
      method: "Post",
      url: '/api/v1/service/search',
      data
    });
  },
  executeServiceAgreement: async (accountId, myServiceId, data = {}) => {
    return await axiosCall({
      method: "Post",
      url: `/api/v1/account/${accountId}/service/${myServiceId}/execute`,
      data
    });
  },
  uploadServiceAgreementDocument: async (accountId, serviceId, data) => {
    return await axiosCall({
      method: "Post",
      url: `/api/v1/account/${accountId}/service/${serviceId}/upload`,
      data
    });
  },
  verifyAssureServiceAccess: async (serviceId) => {
    return await axiosCall({
      method: "Get",
      url: `/api/v1/service/${serviceId}/verify-assure-service-access`
    });
  }
}

export const claimantChildrenAPI = {
  createClaimantChild: async (claimantId, data) => {
    return await axiosCall({
      method: "Post",
      url: `/api/v1/claimant/${claimantId}/claimant-child`,
      data
    });
  },
  getClaimantChild: async (claimantId, claimantChildId) => {
    return await axiosCall({
      method: "Get",
      url: `/api/v1/claimant/${claimantId}/claimant-child/${claimantChildId}`
    });
  },
  getAllClaimantChildren: async (claimantId) => {
    return await axiosCall({
      method: "Get",
      url: `/api/v1/claimant/${claimantId}/claimant-child`
    });
  },
  updateClaimantChild: async (claimantId, claimantChildId, data) => {
    return await axiosCall({
      method: "Put",
      url: `/api/v1/claimant/${claimantId}/claimant-child/${claimantChildId}`,
      data
    });
  },
  deleteClaimantChild: async (claimantId, claimantChildId) => {
    return await axiosCall({
      method: "Delete",
      url: `/api/v1/claimant/${claimantId}/claimant-child/${claimantChildId}`
    });
  },
  searchClaimantChildren: async (data) => {
    return await axiosCall({
      method: "Post",
      url: '/api/v1/claimant-child/search',
      data
    });
  },
};

export const claimantOtherHouseholdMemberAPI = {
  createClaimantOtherHouseholdMember: async (claimantId, data) => {
    return await axiosCall({
      method: "Post",
      url: `/api/v1/claimant/${claimantId}/claimant-other-household-member`,
      data
    });
  },
  getClaimantOtherHouseholdMember: async (claimantId, claimantOtherHouseholdMemberId) => {
    return await axiosCall({
      method: "Get",
      url: `/api/v1/claimant/${claimantId}/claimant-other-household-member/${claimantOtherHouseholdMemberId}`
    });
  },
  getAllClaimantOtherHouseholdMembers: async (claimantId) => {
    return await axiosCall({
      method: "Get",
      url: `/api/v1/claimant/${claimantId}/claimant-other-household-member`
    });
  },
  updateClaimantOtherHouseholdMember: async (claimantId, claimantOtherHouseholdMemberId, data) => {
    return await axiosCall({
      method: "Put",
      url: `/api/v1/claimant/${claimantId}/claimant-other-household-member/${claimantOtherHouseholdMemberId}`,
      data
    });
  },
  deleteClaimantOtherHouseholdMember: async (claimantId, claimantOtherHouseholdMemberId) => {
    return await axiosCall({
      method: "Delete",
      url: `/api/v1/claimant/${claimantId}/claimant-other-household-member/${claimantOtherHouseholdMemberId}`
    });
  },
  searchClaimantOtherHouseholdMembers: async (data) => {
    return await axiosCall({
      method: "Post",
      url: '/api/v1/claimant-other-household-member/search',
      data
    });
  },
};

export const incomeSourceAPI = {
  createClaimantIncomeSource: async (claimantId, data) => {
    return await axiosCall({
      method: "Post",
      url: `/api/v1/claimant/${claimantId}/claimant-income-source`,
      data
    });
  },
  getClaimantIncomeSource: async (claimantId, incomeSourceId) => {
    return await axiosCall({
      method: "Get",
      url: `/api/v1/claimant/${claimantId}/claimant-income-source/${incomeSourceId}`
    });
  },
  getAllClaimantIncomeSources: async (claimantId) => {
    return await axiosCall({
      method: "Get",
      url: `/api/v1/claimant/${claimantId}/claimant-income-source`
    });
  },
  updateClaimantIncomeSource: async (claimantId, incomeSourceId, data) => {
    return await axiosCall({
      method: "Put",
      url: `/api/v1/claimant/${claimantId}/claimant-income-source/${incomeSourceId}`,
      data
    });
  },
  deleteClaimantIncomeSource: async (claimantId, incomeSourceId) => {
    return await axiosCall({
      method: "Delete",
      url: `/api/v1/claimant/${claimantId}/claimant-income-source/${incomeSourceId}`
    });
  },
  searchClaimantIncomeSources: async (data) => {
    return await axiosCall({
      method: "Post",
      url: '/api/v1/claimant-income-source/search',
      data
    });
  },
};

export const claimantMedicationAPI = {
  createClaimantMedication: async (claimantId, data) => {
    return await axiosCall({
      method: "Post",
      url: `/api/v1/claimant/${claimantId}/claimant-medication`,
      data
    });
  },
  getClaimantMedication: async (claimantId, claimantMedicationId) => {
    return await axiosCall({
      method: "Get",
      url: `/api/v1/claimant/${claimantId}/claimant-medication/${claimantMedicationId}`
    });
  },
  getAllClaimantMedications: async (claimantId) => {
    return await axiosCall({
      method: "Get",
      url: `/api/v1/claimant/${claimantId}/claimant-medication`
    });
  },
  updateClaimantMedication: async (claimantId, claimantMedicationId, data) => {
    return await axiosCall({
      method: "Put",
      url: `/api/v1/claimant/${claimantId}/claimant-medication/${claimantMedicationId}`,
      data
    });
  },
  deleteClaimantMedication: async (claimantId, claimantMedicationId) => {
    return await axiosCall({
      method: "Delete",
      url: `/api/v1/claimant/${claimantId}/claimant-medication/${claimantMedicationId}`
    });
  },
  searchClaimantMedications: async (data) => {
    return await axiosCall({
      method: "Post",
      url: '/api/v1/claimant-medication/search',
      data
    });
  },
};


export const claimantDoctorAPI = {
  createClaimantDoctor: async (claimantId, data) => {
    return await axiosCall({
      method: "Post",
      url: `/api/v1/claimant/${claimantId}/claimant-doctor`,
      data
    });
  },
  getClaimantDoctor: async (claimantId, claimantDoctorId) => {
    return await axiosCall({
      method: "Get",
      url: `/api/v1/claimant/${claimantId}/claimant-doctor/${claimantDoctorId}`
    });
  },
  getAllClaimantDoctors: async (claimantId) => {
    return await axiosCall({
      method: "Get",
      url: `/api/v1/claimant/${claimantId}/claimant-doctor`
    });
  },
  updateClaimantDoctor: async (claimantId, claimantDoctorId, data) => {
    return await axiosCall({
      method: "Put",
      url: `/api/v1/claimant/${claimantId}/claimant-doctor/${claimantDoctorId}`,
      data
    });
  },
  deleteClaimantDoctor: async (claimantId, claimantDoctorId) => {
    return await axiosCall({
      method: "Delete",
      url: `/api/v1/claimant/${claimantId}/claimant-doctor/${claimantDoctorId}`
    });
  },
  searchClaimantDoctors: async (data) => {
    return await axiosCall({
      method: "Post",
      url: '/api/v1/claimant-doctor/search',
      data
    });
  },
};

export const claimantEmploymentHistoryAPI = {
  createClaimantEmployer: async (claimantId, data) => {
    return await axiosCall({
      method: "Post",
      url: `/api/v1/claimant/${claimantId}/employment_history`,
      data
    });
  },
  getClaimantEmployer: async (claimantId, claimantEmployerId) => {
    return await axiosCall({
      method: "Get",
      url: `/api/v1/claimant/${claimantId}/employment_history/${claimantEmployerId}`
    });
  },
  getAllClaimantEmployers: async (claimantId) => {
    return await axiosCall({
      method: "Get",
      url: `/api/v1/claimant/${claimantId}/employment_history`
    });
  },
  updateClaimantEmployer: async (claimantId, claimantEmployerId, data) => {
    return await axiosCall({
      method: "Put",
      url: `/api/v1/claimant/${claimantId}/employment_history/${claimantEmployerId}`,
      data
    });
  },
  deleteClaimantEmployer: async (claimantId, claimantEmployerId) => {
    return await axiosCall({
      method: "Delete",
      url: `/api/v1/claimant/${claimantId}/employment_history/${claimantEmployerId}`
    });
  },
  searchClaimantEmployers: async (data) => {
    return await axiosCall({
      method: "Post",
      url: '/api/v1/claimant-doctor/search',
      data
    });
  },
};

export const claimantHospitalVisitAPI = {
  createClaimantHospitalVisit: async (claimantId, data) => {
    return await axiosCall({
      method: "Post",
      url: `/api/v1/claimant/${claimantId}/claimant-hospital-visits`,
      data
    });
  },
  getClaimantHospitalVisit: async (claimantId, claimantHospitalVisitId) => {
    return await axiosCall({
      method: "Get",
      url: `/api/v1/claimant/${claimantId}/claimant-hospital-visits/${claimantHospitalVisitId}`
    });
  },
  getAllClaimantHospitalVisits: async (claimantId) => {
    return await axiosCall({
      method: "Get",
      url: `/api/v1/claimant/${claimantId}/claimant-hospital-visits`
    });
  },
  updateClaimantHospitalVisit: async (claimantId, claimantHospitalVisitId, data) => {
    return await axiosCall({
      method: "Put",
      url: `/api/v1/claimant/${claimantId}/claimant-hospital-visits/${claimantHospitalVisitId}`,
      data
    });
  },
  deleteClaimantHospitalVisit: async (claimantId, claimantHospitalVisitId) => {
    return await axiosCall({
      method: "Delete",
      url: `/api/v1/claimant/${claimantId}/claimant-hospital-visits/${claimantHospitalVisitId}`
    });
  },
  searchClaimantHospitalVisits: async (data) => {
    return await axiosCall({
      method: "Post",
      url: '/api/v1/claimant-hospital-visits/search',
      data
    });
  },
};

// export const claimantHospitalVisitAPI = {  // OLD
//   createClaimantHospitalVisit: async (data) => {
//     return await axiosCall({ method: "Post", url: '/api/v1/claimant-hospital-visit', data });
//   },
//   getClaimantHospitalVisit: async (claimantHospitalVisitId) => {
//     return await axiosCall({ method: "Get", url: `/api/v1/claimant-hospital-visit/${claimantHospitalVisitId}` });
//   },
//   updateClaimantHospitalVisit: async (claimantHospitalVisitId, data) => {
//     return await axiosCall({ method: "Put", url: `/api/v1/claimant-hospital-visit/${claimantHospitalVisitId}`, data });
//   },
//   deleteClaimantHospitalVisit: async (claimantHospitalVisitId) => {
//     return await axiosCall({ method: "Delete", url: `/api/v1/claimant-hospital-visit/${claimantHospitalVisitId}` });
//   },
//   getAllClaimantHospitalVisits: async (claimantId) => {
//     return await axiosCall({ method: "Get", url: `/api/v1/claimant/${claimantId}/claimant-hospital-visit` });
//   },
//   searchClaimantHospitalVisits: async (data) => {
//     return await axiosCall({ method: "Post", url: '/api/v1/claimant-hospital-visit/search', data });
//   },
// };



export const assignedUserTypeAPI = {
  createAssignedUserType: async (data) => {
    return await axiosCall({
      method: "Post",
      url: '/api/v1/assigned-user-type',
      data
    });
  },
  searchAssignedUserTypes: async (data) => {
    return await axiosCall({
      method: "Post",
      url: '/api/v1/assigned-user-type/search',
      data
    });
  },
};

export const customFieldAPI = {
  createCustomField: async (accountId, data) => {
    return await axiosCall({
      method: "Post",
      url: `/api/v1/account/${accountId}/custom-field`,
      data
    });
  },
  getCustomField: async (accountId, customFieldId) => {
    return await axiosCall({
      method: "Get",
      url: `/api/v1/account/${accountId}/custom-field/${customFieldId}`
    });
  },
  updateCustomField: async (accountId, customFieldId, data) => {
    return await axiosCall({
      method: "Put",
      url: `/api/v1/account/${accountId}/custom-field/${customFieldId}`,
      data
    });
  },
  deleteCustomField: async (accountId, customFieldId) => {
    return await axiosCall({
      method: "Delete",
      url: `/api/v1/account/${accountId}/custom-field/${customFieldId}`
    });
  },
  searchCustomFields: async (data) => {
    return await axiosCall({
      method: "Post",
      url: '/api/v1/custom-field/search',
      data
    });
  },
  getCustomFieldsAndValuesByEntityId: async (accountId, entityId, data = {}) => {
    return await axiosCall({
      method: "Post",
      url: `/api/v1/account/${accountId}/custom-field/entity/${entityId}`,
      data
    });
  },
  createCustomFieldValue: async (accountId, entity_id, customFieldId, data) => {
    return await axiosCall({
      method: "Post",
      url: `/api/v1/account/${accountId}/custom-field/${customFieldId}/entity/${entity_id}/custom-field-value`,
      data
    });
  },
  getCustomFieldValue: async (accountId, entity_id, customFieldId, customFieldValueId) => {
    return await axiosCall({
      method: "Get",
      url: `/api/v1/account/${accountId}/custom-field/${customFieldId}/entity/${entity_id}/custom-field-value/${customFieldValueId}`
    });
  },
  updateCustomFieldValue: async (accountId, entity_id, customFieldId, customFieldValueId, data) => {
    return await axiosCall({
      method: "Put",
      url: `/api/v1/account/${accountId}/custom-field/${customFieldId}/entity/${entity_id}/custom-field-value/${customFieldValueId}`,
      data
    });
  },
  deleteCustomFieldValue: async (accountId, entity_id, customFieldId, customFieldValueId) => {
    return await axiosCall({
      method: "Delete",
      url: `/api/v1/account/${accountId}/custom-field/${customFieldId}/entity/${entity_id}/custom-field-value/${customFieldValueId}`
    });
  }
};

export const leadVendorAPI = {
  createLeadVendor: async (accountId, data) => {
    return await axiosCall({
      method: "Post",
      url: `/api/v1/account/${accountId}/lead-vendor`,
      data
    });
  },
  getLeadVendor: async (accountId, leadVendorId) => {
    return await axiosCall({
      method: "Get",
      url: `/api/v1/account/${accountId}/lead-vendor/${leadVendorId}`
    });
  },
  updateLeadVendor: async (accountId, leadVendorId, data) => {
    return await axiosCall({
      method: "Put",
      url: `/api/v1/account/${accountId}/lead-vendor/${leadVendorId}`,
      data
    });
  },
  deleteLeadVendor: async (accountId, leadVendorId) => {
    return await axiosCall({
      method: "Delete",
      url: `/api/v1/account/${accountId}/lead-vendor/${leadVendorId}`
    });
  },
  searchLeadVendors: async (data) => {
    return await axiosCall({
      method: "Post",
      url: '/api/v1/lead-vendor/search',
      data
    });
  },
  generateApiKey: async (accountId, leadVendorId) => {
    return await axiosCall({
      method: "Get",
      url: `/api/v1/account/${accountId}/lead-vendor/${leadVendorId}/generate-new-api-key`
    });
  },
  sendWelcomeEmail: async (accountId, leadVendorId, data) => {
    return await axiosCall({
      method: "Post",
      url: `/api/v1/account/${accountId}/lead-vendor/${leadVendorId}/send-welcome-email`,
      data
    });
  }
};

export const MedicalRecordRequestAPI = {
  createMedicalRecordRequest: async (data) => {
    return await axiosCall({
      method: "Post",
      url: '/api/v1/medical-record-request',
      data
    });
  },
  getMedicalRecordRequest: async (medicalRecordRequestId) => {
    return await axiosCall({
      method: "Get",
      url: `/api/v1/medical-record-request/${medicalRecordRequestId}`
    });
  },
  updateMedicalRecordRequest: async (medicalRecordRequestId, data) => {
    return await axiosCall({
      method: "Put",
      url: `/api/v1/medical-record-request/${medicalRecordRequestId}`,
      data
    });
  },
  deleteMedicalRecordRequest: async (medicalRecordRequestId) => {
    return await axiosCall({
      method: "Delete",
      url: `/api/v1/medical-record-request/${medicalRecordRequestId}`
    });
  },
  searchMedicalRecordRequests: async (data) => {
    return await axiosCall({
      method: "Post",
      url: '/api/v1/medical-record-request/search',
      data
    });
  },
  postMedicareRequest: async (data) => {
    return await axiosCall({
      method: "Post",
      url: '/api/v1/medical-record-request/post-request',
      data
    });
  }
};

// Notifications
export const notificationTypeAPI = {
  createNotificationType: async (data) => {
    return await axiosCall({
      method: "Post",
      url: '/api/v1/notification-type',
      data
    });
  },
  getMyNotificationType: async (myNotificationTypeId) => {
    return await axiosCall({
      method: "Get",
      url: `/api/v1/notification-type/${myNotificationTypeId}`
    });
  },
  updateMyNotificationType: async (myNotificationTypeId, data) => {
    return await axiosCall({
      method: "Put",
      url: `/api/v1/notification-type/${myNotificationTypeId}`,
      data
    });
  },
  deleteMyNotificationType: async (myNotificationTypeId) => {
    return await axiosCall({
      method: "Delete",
      url: `/api/v1/notification-type/${myNotificationTypeId}`
    });
  },
  searchMyNotificationType: async (data = {}) => {
    return await axiosCall({
      method: "Post",
      url: '/api/v1/notification-type/search',
      data
    });
  },
};
export const notificationAPI = {
  createNotification: async (data) => {
    return await axiosCall({
      method: "Post",
      url: '/api/v1/notification',
      data
    });
  },
  getMyNotification: async (myNotificationId) => {
    return await axiosCall({
      method: "Get",
      url: `/api/v1/notification/${myNotificationId}`
    });
  },
  updateMyNotification: async (myNotificationId, data) => {
    return await axiosCall({
      method: "Put",
      url: `/api/v1/notification/${myNotificationId}`,
      data
    });
  },
  deleteMyNotification: async (myNotificationId) => {
    return await axiosCall({
      method: "Delete",
      url: `/api/v1/notification/${myNotificationId}`
    });
  },
  searchNotifications: async (data = {}) => {
    return await axiosCall({
      method: "Post",
      url: '/api/v1/notification/search',
      data,
      params: {
        ignoreTouch: true
      }
    });
  },
};

export const emailAPI = {
  createContactEmail: async (data) => {
    return await axiosCall({
      method: "Post",
      url: '/api/v1/contact-email/',
      data
    });
  },
  getAllEmailsByEntityId: async (entityId) => {
    return await axiosCall({
      method: "Get",
      url: `/api/v1/contact-email/list/${entityId}`
    });
  },
  updateContactEmail: async (emailId, data) => {
    return await axiosCall({
      method: "Put",
      url: `/api/v1/contact-email/${emailId}`,
      data
    });
  },
  deleteContactEmail: async (emailId) => {
    return await axiosCall({
      method: "Delete",
      url: `/api/v1/contact-email/${emailId}`
    });
  },
  togglePrimaryEmail: async (newPrimaryEmailId = null, entityId) => {
    return await axiosCall({
      method: "Put",
      url: `/api/v1/contact-email/toggle-primary-email/${entityId}`,
      data: {
        newPrimaryEmailId
      }
    });
  },
};

export const ClaimantLocatorRequestAPI = {
  createClaimantLocatorRequest: async (data) => {
    return await axiosCall({
      method: "Post",
      url: '/api/v1/claimant-locator-request',
      data
    });
  },
  getClaimantLocatorRequest: async (claimantLocatorRequestId) => {
    return await axiosCall({
      method: "Get",
      url: `/api/v1/claimant-locator-request/${claimantLocatorRequestId}`
    });
  },
  updateClaimantLocatorRequest: async (claimantLocatorRequestId, data) => {
    return await axiosCall({
      method: "Put",
      url: `/api/v1/claimant-locator-request/${claimantLocatorRequestId}`,
      data
    });
  },
  deleteClaimantLocatorRequest: async (claimantLocatorRequestId) => {
    return await axiosCall({
      method: "Delete",
      url: `/api/v1/claimant-locator-request/${claimantLocatorRequestId}`
    });
  },
  searchClaimantLocatorRequests: async (data) => {
    return await axiosCall({
      method: "Post",
      url: '/api/v1/claimant-locator-request/search',
      data
    });
  },
  submitClaimantLocatorRequest: async (claimantId, data) => {
    return await axiosCall({
      method: "Post",
      url: `/api/v1/claimant/${claimantId}/claimant-locator-request`,
      data
    });
  }
};

export const hearingAPI = {
  createHearing: async (claimantId, data) => {
    return await axiosCall({
      method: "Post",
      url: `/api/v1/claimant/${claimantId}/hearing`,
      data
    });
  },
  searchHearings: async (data) => {
    return await axiosCall({
      method: "Post",
      url: '/api/v1/hearing/search',
      data
    });
  },
  getHearing: async (hearingId) => {
    return await axiosCall({
      method: "Get",
      url: `/api/v1/hearing/${hearingId}`
    });
  },
  updateHearing: async (hearingId, data) => {
    return await axiosCall({
      method: "Put",
      url: `/api/v1/hearing/${hearingId}`,
      data
    });
  },
  deleteHearing: async (hearingId) => {
    return await axiosCall({
      method: "Delete",
      url: `/api/v1/hearing/${hearingId}`
    });
  }
};

export const hearingOfficeAPI = {
  createHearingOffice: async (data) => {
    return await axiosCall({
      method: "Post",
      url: '/api/v1/hearing-office',
      data
    });
  },
  getHearingOffice: async (hearingOfficeId) => {
    return await axiosCall({
      method: "Get",
      url: `/api/v1/hearing-office/${hearingOfficeId}`
    });
  },
  updateHearingOffice: async (hearingOfficeId, data) => {
    return await axiosCall({
      method: "Put",
      url: `/api/v1/hearing-office/${hearingOfficeId}`,
      data
    });
  },
  deleteHearingOffice: async (hearingOfficeId) => {
    return await axiosCall({
      method: "Delete",
      url: `/api/v1/hearing-office/${hearingOfficeId}`
    });
  },
  searchHearingOffice: async (data) => {
    return await axiosCall({
      method: "Post",
      url: '/api/v1/hearing-office/search',
      data
    });
  },
};


/**
 * reservationAPI
 */
export const reservationAPI = {
  /**
   * createReservation
   * @param {*} data 
   * @returns 
   */
  createReservation: async (data) => {
    return await axiosCall({ method: "Post", url: `/api/v1/reservation/`, data });
  },
  /**
   * getReservationById
   * @param {*} reservationId 
   * @returns 
   */
  getReservationById: async (reservationId) => {
    return await axiosCall({ method: "Get", url: `/api/v1/reservation/${reservationId}` });
  },
  /**
   * updateReservation
   * @param {*} reservationId 
   * @param {*} data 
   * @returns 
   */
  updateReservation: async (reservationId, data) => {
    return await axiosCall({ method: "Put", url: `/api/v1/reservation/${reservationId}`, data });
  },
  /**
   * deleteReservation
   * @param {*} reservationId 
   * @returns 
   */
  deleteReservation: async (reservationId) => {
    return await axiosCall({ method: "Delete", url: `/api/v1/reservation/${reservationId}` });
  },
  /**
   * searchAllReservation
   * @param {*} data 
   * @param {*} params 
   * @returns 
   */
  searchAllReservation: async (data, params = {}) => {
    return await axiosCall({ method: "Post", url: `/api/v1/reservation/search`, data, params });
  }
}


export const statusReportAPI = {
  searchAllStatusReportItems: async (data) => {
    return await axiosCall({
      method: "Post",
      url: '/api/v1/status-report-item/search',
      data
    });
  }
}

export const assureAPI = {
  createCaseFileRequest: async (claimantId, caseId, data = {}) => {
    return await axiosCall({
      method: "Post",
      url: `/api/v1/claimant/${claimantId}/case/${caseId}/atlasware-casefile-request/submit`,
      data
    });
  },
  getCaseFileRequest: async (claimantId, caseId, caseFileRequestId) => {
    return await axiosCall({
      method: "Get",
      url: `/api/v1/claimant/${claimantId}/case/${caseId}/atlasware-casefile-request/${caseFileRequestId}`
    });
  },
  updateCaseFileRequest: async (claimantId, caseId, caseFileRequestId, data = {}) => {
    return await axiosCall({
      method: "Put",
      url: `/api/v1/claimant/${claimantId}/case/${caseId}/atlasware-casefile-request/${caseFileRequestId}`,
      data
    });
  },
  deleteCaseFileRequest: async (claimantId, caseId, caseFileRequestId) => {
    return await axiosCall({
      method: "Delete",
      url: `/api/v1/claimant/${claimantId}/case/${caseId}/atlasware-casefile-request/${caseFileRequestId}`
    });
  },
  searchCaseFileRequests: async (data = {}) => {
    return await axiosCall({
      method: "Post",
      url: `/api/v1/atlasware-casefile-request/search`,
      data
    });
  },
  getAttorneyList: async () => {
    return await axiosCall({
      method: "Get",
      url: `/api/v1/assure/attorney/list`
    });
  },
  getAttorneyDetails: async (attorneyId) => {
    return await axiosCall({
      method: "Get",
      url: `/api/v1/assure/attorney/${attorneyId}`
    });
  },
  submitAtlaswareEREUploadRequest: async (claimantId, caseId, documents = []) => {
    return await axiosCall({
      method: "Post",
      url: `/api/v1/claimant/${claimantId}/case/${caseId}/atlasware-upload-request`,
      data: { documents }
    });
  },
  searchAtlaswareEREUploadRequests: async (data = {}) => {
    return await axiosCall({
      method: "Post",
      url: `/api/v1/atlasware-upload-request/search`,
      data
    });
  },
  createAnLinkAssureCase: async (claimantId, caseId) => {
    return await axiosCall({
      method: "Post",
      url: `/api/v1/claimant/${claimantId}/case/${caseId}/action/link-assure-case`
    });
  },
  submitNewBriefRequest: async (claimantId, data = {}) => {
    return await axiosCall({
      method: 'Post',
      url: `/api/v1/claimant/${claimantId}/brief-request/submit-new-brief-request`,
      data
    });
  },
  searchBriefRequests: async (data = {}) => {
    return await axiosCall({
      method: 'Post',
      url: `/api/v1/brief-request/search`,
      data
    });
  },
  getLocationList: async () => {
    return await axiosCall({
      method: 'Get',
      url: `/api/v1/assure/location/list`
    });
  },
  getLocationById: async (locationId) => {
    return await axiosCall({
      method: 'Get',
      url: `/api/v1/assure/location/${locationId}`
    });
  },
  getJudgeList: async () => {
    return await axiosCall({
      method: 'Get',
      url: `/api/v1/assure/judge/list`
    });
  },
  getJudgeById: async (judgeId) => {
    return await axiosCall({
      method: 'Get',
      url: `/api/v1/assure/judge/${judgeId}`
    });
  },
  getStatusReportRequest: async (accountId, statusReportRequestId) => {
    return await axiosCall({
      method: 'Get',
      url: `/api/v1/account/${accountId}/status-report-request/${statusReportRequestId}`
    });
  },
  submitStatusReportRequest: async (accountId, data = {}, params = {}) => {
    return await axiosCall({
      method: 'Post',
      url: `/api/v1/account/${accountId}/status-report-request/submit`,
      data,
      params: {
        instantSubmit: false,
        ...params,
      }
    });
  },
  checkStatusReportRequest: async (accountId, statusReportRequestId) => {
    return await axiosCall({
      method: 'Put',
      url: `/api/v1/account/${accountId}/status-report-request/${statusReportRequestId}/check`
    });
  },
  searchStatusReportRequests: async (data = {}, params = {}) => {
    return await axiosCall({
      method: 'Post',
      url: `/api/v1/status-report-request/search`,
      data,
      params
    });
  },
  updateStatusReportRequest: async (accountId, statusReportRequestId, data = {}) => {
    return await axiosCall({
      method: 'Put',
      url: `/api/v1/account/${accountId}/status-report-request/${statusReportRequestId}`,
      data
    });
  },
  deleteStatusReportRequest: async (accountId, statusReportRequestId) => {
    return await axiosCall({
      method: 'Delete',
      url: `/api/v1/account/${accountId}/status-report-request/${statusReportRequestId}`
    });
  },
  getStatusReportItem: async (accountId, statusReportRequestId, statusReportItemId) => {
    return await axiosCall({
      method: 'Get',
      url: `/api/v1/account/${accountId}/status-report-request/${statusReportRequestId}/status-report-item/${statusReportItemId}`
    });
  },
  createStatusReportItem: async (accountId, statusReportRequestId, data = {}) => {
    return await axiosCall({
      method: 'Post',
      url: `/api/v1/account/${accountId}/status-report-request/${statusReportRequestId}/status-report-item`,
      data
    });
  },
  updateStatusReportItem: async (accountId, statusReportRequestId, statusReportItemId, data = {}) => {
    return await axiosCall({
      method: 'Put',
      url: `/api/v1/account/${accountId}/status-report-request/${statusReportRequestId}/status-report-item/${statusReportItemId}`,
      data
    });
  },
  deleteStatusReportItem: async (accountId, statusReportRequestId, statusReportItemId) => {
    return await axiosCall({
      method: 'Delete',
      url: `/api/v1/account/${accountId}/status-report-request/${statusReportRequestId}/status-report-item/${statusReportItemId}`
    });
  },
  searchStatusReportItems: async (data = {}) => {
    return await axiosCall({
      method: 'Post',
      url: `/api/v1/status-report-item/search`,
      data
    });
  },
  downloadStatusReport: async (accountId, statusReportRequestId, cb = () => { }) => {
    return await axios.get(`/api/v1/account/${accountId}/status-report-request/${statusReportRequestId}/download`, {
      responseType: 'blob',
      timeout: 1000 * 60 * 1
    }).then(async (response) => {
      let {
        status,
        statusText,
        headers = {},
        data
      } = response || {};
      let type = headers['content-type'] || 'application/octet-stream';

      if (status === 200) {
        const blob = new Blob([data], {
          type
        });

        // Extract filename from header
        let disposition = headers['content-disposition']; // "attachment;filename=filename.xlsx" 
        const regex = /filename[^;\n=]*=((['"]).*?\2|[^;\n]*)/g;
        let m;
        let filename = '';
        if ((m = regex.exec(disposition)) !== null) {
          m.forEach((match, groupIndex) => {
            if (groupIndex === 1) {
              filename = match;
            }
          });
        }

        // Download the file 
        const link = document.createElement('a');
        link.download = filename;
        link.href = URL.createObjectURL(blob);
        link.addEventListener('click', () => {
          setTimeout(() => URL.revokeObjectURL(link.href), 30 * 1000);
        });
        link.click();
        cb({
          status,
          statusText,
          data: `Successfully Downloaded File`
        });
      } else {
        if (type.includes('text/html')) {
          data = await data.text();
        }
        cb({
          status,
          statusText,
          data
        });
      }
    });
  },
  listEreCredentials: async (accountId) => {
    return await axiosCall({
      method: 'Get',
      url: `/api/v1/assure/ere-credentials/list`
    });
  },
  getEreCredentials: async (accountId, ereCredentialsId) => {
    return await axiosCall({
      method: 'Get',
      url: `/api/v1/assure/ere-credentials/${ereCredentialsId}`
    });
  },
  createEreCredentials: async (accountId, data = {}) => {
    return await axiosCall({
      method: 'Post',
      url: `/api/v1/assure/ere-credentials`,
      data
    });
  },
  updateEreCredentials: async (accountId, ereCredentialsId, data = {}) => {
    return await axiosCall({
      method: 'Put',
      url: `/api/v1/assure/ere-credentials/${ereCredentialsId}`,
      data
    });
  },
  getEreCredentialTextMessages: async (accountId, ereCredentialsId) => {
    return await axiosCall({
      method: 'Get',
      url: `/api/v1/assure/ere-credentials/${ereCredentialsId}/text-messages`
    });
  },
  getEreNumber: async (accountId, ereCredentialsId) => {
    return await axiosCall({
      method: 'Get',
      url: `/api/v1/assure/ere-credentials/ere-number`
    });
  },
  searchAssureStatusReportCases: async (account_id, data = {}, params = {}) => {
    return await axiosCall({
      method: 'Post',
      url: `/api/v1/account/${account_id}/status-report-request/status-report-item/assure`,
      data,
      params
    });
  },
  performCaseFileDownloadCheck: async (claimantId, caseId, type, data = {}) => {
    return await axiosCall({
      method: 'Post',
      url: `/api/v1/claimant/${claimantId}/case/${caseId}/action/case-file-download-check/${type}`,
      data
    });
  },
  performCaseFileUpdateCheck: async (claimantId, caseId, type, data = {}) => {
    return await axiosCall({
      method: 'Post',
      url: `/api/v1/claimant/${claimantId}/case/${caseId}/action/case-file-update-check/${type}`,
      data
    });
  },
  downloadCaseFileByType: async (claimantId, caseId, type, cb = () => { }) => {
    try {

      return await axios.get(`/api/v1/claimant/${claimantId}/case/${caseId}/action/case-file-download/${type}`, {
        responseType: 'blob',
        timeout: 1000 * 60 * 1
      }).then(async (response) => {
        let {
          status,
          statusText,
          headers = {},
          data
        } = response || {};
        let type = headers['content-type'] || 'application/octet-stream';

        if (status === 200) {
          const blob = new Blob([data], {
            type
          });

          // Extract filename from header
          let disposition = headers['content-disposition']; // "attachment;filename=filename.xlsx" 
          const regex = /filename[^;\n=]*=((['"]).*?\2|[^;\n]*)/g;
          let m;
          let filename = '';
          if ((m = regex.exec(disposition)) !== null) {
            m.forEach((match, groupIndex) => {
              if (groupIndex === 1) {
                filename = match;
              }
            });
          }

          // Download the file 
          const link = document.createElement('a');
          link.download = filename;
          link.href = URL.createObjectURL(blob);
          link.addEventListener('click', () => {
            setTimeout(() => URL.revokeObjectURL(link.href), 30 * 1000);
          });
          link.click();
          cb({
            status,
            statusText,
            data: `Successfully Downloaded File`
          });
        } else {
          if (type.includes('text/html')) {
            data = await data.text();
          }
          cb({
            status,
            statusText,
            data
          });
        }
      });
    } catch (error) {
      return error;
    }
  },
  viewAndUpdateCaseFileByType: async (claimantId, caseId, type, data = {}) => {
    return await axiosCall({
      method: 'Post',
      url: `/api/v1/claimant/${claimantId}/case/${caseId}/action/view-and-update-case-file/${type}`,
      data
    });
  },
  syncCaseInformationWithAssure: async (claimantId, caseId, data = {}) => {
    return await axiosCall({
      method: 'Post',
      url: `/api/v1/claimant/${claimantId}/case/${caseId}/action/sync-case-information-with-assure`,
      data
    });
  },
  unlinkAssureCase: async (claimantId, caseId, data = {}) => {
    return await axiosCall({
      method: 'Put',
      url: `/api/v1/claimant/${claimantId}/case/${caseId}/action/unlink-assure-case`,
      data
    });
  },
  getEREFileList: async (claimantId, caseId, params = {}) => {
    return await axiosCall({
      method: 'Get',
      url: `/api/v1/claimant/${claimantId}/case/${caseId}/action/ere-file-list`,
      params
    });
  },
};

// Brief Request
export const briefRequestAPI = {
  getBriefRequest: async (claimantId, briefRequestId) => {
    return await axiosCall({
      method: "Get",
      url: `/api/v1/claimant/${claimantId}/brief-request/${briefRequestId}`
    });
  }
};