import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import { SettingAPI, accountSettingAPI } from 'utils/api/fulcrum-api';
import { removeEmptyFromObj } from 'utils/functions';
import moment from 'moment';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import { Form, Row, Col, Check } from 'react-bootstrap';
import AppContext, { AuthContext } from 'context/Context';

const AccountPreferences = ({ user, setUser, account, setAccount = () => { }, siteAdminArea = false }) => {
  const { permissionsState, permissionsDispatch, getAccountSetting, setAccountSetting, setAccountSettings } = useContext(AuthContext);

  const [formData, setFormData] = useState({
    settings: []
  });

  const handleSettingChange = async (e, setting, updateRecord = false) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    let { name, value, type, checked = false } = e?.target || {}

    if (type === "checkbox") {
      value = checked;
    }

    let updateSettingsArray = formData.settings;
    updateSettingsArray.find(s => s.id === name).value = value;

    setFormData({
      ...formData, settings: [...updateSettingsArray]
    });

    if (updateRecord) {

      try {
        let createResponse;
        let updateResponse;

        if (setting?.account_setting_id) {
          let id = setting?.account_setting_id;
          let providedData = {
            value: setting.value,
            setting_id: setting.setting_id
          }
          updateResponse = await updateAccountSetting(id, providedData);
        } else {
          let providedData = {
            setting_id: setting.id,
            value: setting.value
          }
          createResponse = await createAccountSetting(providedData);
          setting.account_setting_id = createResponse.data[0].id; // Note: On create, need to set reference to underlying account setting id
          updateSettingsArray.find(s => s.id === name).account_setting_id = createResponse.data[0].id;;
        }

      } catch (error) {
        toast.error(error.message, { theme: 'colored' });
      }
      getAppliedAccountSettings();
    }
  };

  const createAccountSetting = async (providedData) => {
    let createAccountSettingResponse = null;
    try {
      createAccountSettingResponse = await accountSettingAPI.createAccountSetting(account.id, providedData);
    } catch (error) {
      console.log('error', error);
      toast.error('Error creating account setting.', {
        theme: 'colored',
      });
      createAccountSettingResponse = { statusCode: 500, error: true, message: error?.message || '' };
    }
    return createAccountSettingResponse;
  };

  const updateAccountSetting = async (id, providedData) => {
    let updateAccountSettingResponse = null;
    try {
      updateAccountSettingResponse = await accountSettingAPI.updateAccountSetting(account.id, id, providedData);
    } catch (error) {
      console.log('error', error);
      toast.error('Error updating account setting.', {
        theme: 'colored',
      });
      updateAccountSettingResponse = { statusCode: 500, error: true, message: error?.message || '' };
    }
    return updateAccountSettingResponse;
  };

  const getSettings = async () => {
    try {
      let allEditableSettings = [];
      let { data: arrayOfDefaultSettings } = await SettingAPI.searchAllSetting();
      let { data: arrayOfAccountSettings } = await accountSettingAPI.searchAllAccountSetting({ account_id: account.id });

      arrayOfDefaultSettings.rows.forEach((defaultSetting) => {
        allEditableSettings.push(defaultSetting);
      });
      arrayOfAccountSettings.rows.forEach((accountSetting) => {
        arrayOfDefaultSettings.rows.find((defaultSetting) => {
          if (defaultSetting.id === accountSetting.setting_id) {
            defaultSetting.account_setting_id = accountSetting.id;
            defaultSetting.value = accountSetting.value;
          }
        });
      });

      allEditableSettings = [...arrayOfDefaultSettings.rows];

      setFormData({
        ...formData, settings: [...allEditableSettings]
      });
    } catch (error) {
      console.error(error);
    }
  }

  const getAppliedAccountSettings = async () => {
    try {
      let { data } = await accountSettingAPI.getAppliedAccountSettings(account.id);
      let { appliedSettings } = data || {};

      permissionsDispatch({ type: 'NEW_SETTINGS_LIST', payload: { ...appliedSettings } });

    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    getAppliedAccountSettings();
    const init = async () => {
      await getSettings();
    }
    init();
  }, []);

  return (
    <>
      <div className="mb-3">
        <Card className="shadow-none">
          <Card.Body>
            <Form>
              {formData.settings && Array.isArray(formData.settings) && formData.settings.length > 0 && formData.settings.filter((r) => (['string', 'integer'].includes(r.value_type))).map((setting) => {
                return (
                  <Form.Group key={setting.id} as={Col} className="mb-3" controlId="setting">
                    <Form.Label className="fs-0">{`${(setting?.description || setting?.setting_id)}`}</Form.Label>
                    <Form.Control
                      type="text"
                      size="sm"
                      name={`${setting.id}`}
                      value={setting.value}
                      onChange={(e) => { handleSettingChange(e, setting); }}
                      onBlur={(e) => { handleSettingChange(e, setting, true); }}
                    />
                  </Form.Group>
                )
              })}
              {formData.settings && Array.isArray(formData.settings) && formData.settings.length > 0 && formData.settings.filter((r) => (['boolean'].includes(r.value_type))).map((setting) => {
                return (
                  <Form.Group key={setting.id} as={Col} className="mb-3" controlId="setting">
                    <Form.Label className="fs-0">{`${(setting?.description || setting?.setting_id)}`}</Form.Label>
                    <Form.Check
                      size="sm"
                      type="checkbox"
                      label={`${setting.description}`}
                      name={`${setting.id}`}
                      checked={(setting.value == true)}
                      onChange={(e) => { handleSettingChange(e, setting, true); }}
                    />
                  </Form.Group>
                )
              })}
            </Form>
          </Card.Body>
        </Card>
      </div>
    </>
  );
};

AccountPreferences.propTypes = {
  account: PropTypes.object,
  setAccount: PropTypes.func
};

export default AccountPreferences;