import React from 'react';
import PropTypes from 'prop-types';
import ClaimantTable from './ClaimantTable';
import { Link } from 'react-router-dom';

const InboundLeadTable = ({ requiredSearchParams = {}, defaultColumns, siteAdminArea = false, showMetrics = true } = {}) => {
	let columns = defaultColumns || [
		{
			accessor: 'first_name',
			Header: 'First Name',
			headerProps: { className: 'pe-1' },
			cellProps: {
				className: 'py-2'
			},
			Cell: rowData => {
				const { first_name, id } = rowData.row.original;
				return <Link to={`/claimant/profile/${id}`}>{first_name}</Link>;
			},
		},
		{
			accessor: 'last_name',
			Header: 'Last Name',
			headerProps: { className: 'pe-1' },
			cellProps: {
				className: 'py-2'
			},
			Cell: rowData => {
				const { last_name, id } = rowData.row.original;
				return <Link to={`/claimant/profile/${id}`}>{last_name}</Link>;
			}
		},
		{
			accessor: 'created_at',
			Header: 'Created',
			headerProps: { className: 'pe-1' },
			cellProps: {
				className: 'py-2'
			}
		},
		{
			accessor: 'last_called',
			Header: 'Last Called',
			headerProps: { className: 'pe-1' },
			cellProps: {
				className: 'py-2'
			},
			Cell: rowData => {
				const { last_called } = rowData.row.original;
				return last_called ? last_called : <span className="text-danger">Haven't called</span>;
			}
		},
		{
			accessor: 'established_contact',
			Header: 'Established Contact',
			headerProps: { className: 'pe-1' },
			cellProps: {
				className: 'py-2'
			},
			Cell: rowData => {
				const { established_contact } = rowData.row.original;
				return established_contact ? <span className='text-success'>Yes</span> : <span className="text-danger">No</span>;
			}
		},
		{
			accessor: 'screened',
			Header: 'Screened',
			headerProps: { className: 'pe-1' },
			cellProps: {
				className: 'py-2'
			},
			Cell: rowData => {
				const { screened } = rowData.row.original;
				return screened ? <span className='text-success'>Yes</span> : <span className="text-danger">No</span>;
			}
		},
		{
			accessor: 'reviewed',
			Header: 'Reviewed',
			headerProps: { className: 'pe-1' },
			cellProps: {
				className: 'py-2'
			},
			Cell: rowData => {
				const { reviewed } = rowData.row.original;
				return reviewed ? <span className='text-success'>Yes</span> : <span className="text-danger">No</span>;
			}
		},
	];

	return (<ClaimantTable
		tableHeaderLabel="Inbound Leads"
		siteAdminArea={siteAdminArea}
		columns={columns}
		showMetrics={showMetrics}
		defaultSearchParams={{}}
		requiredSearchParams={{
			active_claimant: true,
			active_case: false,
			active_case_id: null,
			...requiredSearchParams
		}}
	/>);
};

InboundLeadTable.propTypes = {
	requiredSearchParams: PropTypes.object,
	defaultColumns: PropTypes.array,
	siteAdminArea: PropTypes.bool,
	showMetrics: PropTypes.bool
};

export default InboundLeadTable;