import React, { useRef, useEffect, useState } from 'react';
import WebViewer from '@pdftron/pdfjs-express';
import { myFormsAPI, documentAPI } from 'utils/api/fulcrum-api';
import { toast } from 'react-toastify';
import { Button, Row, Col, Spinner } from 'react-bootstrap';
import PropTypes from 'prop-types';

const PDFJSWebviewer = ({ fileId, type = "file", showSubmitButton = false, disabledSubmitButton = false,
	xfdf, setXfdf = () => { }, viewerState, setViewerState = () => { },
	handleDocumentSave = () => { }, onDocumentLoadCallBack = () => { }, onFieldChangeCallBack = () => { }, onAnnotationChangeCallBack = () => { },
} = {}) => {
	const [formFields, setFormFields] = useState({});
	const viewer = useRef(null);
	const [loading, setLoading] = useState(true);

	// Stream Document
	const streamDocument = async (id) => {
		try {
			if (type === 'file') {
				return await documentAPI.streamFileFromS3(id);
			} else if (type === 'my-form') {
				return await myFormsAPI.streamPDFWithFormData(id);
			}
		} catch (error) {
			console.log(error);
		}
		return null;
	};

	const saveAnnotations = async () => {
		const { Core, Annotations } = viewerState;
		const { documentViewer, annotationManager } = Core;
		const fieldManager = annotationManager.getFieldManager();
		let adjustedFieldData = formFields;

		const xfdf = await annotationManager.exportAnnotations();
		const annots = annotationManager.getAnnotationsList();
		setXfdf(xfdf);
		handleDocumentSave(viewerState);

		return adjustedFieldData;
	};

	function generateAnnotationImage(annotation, paddingSize, instance) {
		paddingSize = paddingSize || 0;

		const canvas = document.createElement('canvas');
		canvas.width = annotation.Width + (2 * paddingSize);
		canvas.height = annotation.Height + (2 * paddingSize);

		const ctx = canvas.getContext('2d');
		ctx.translate(-annotation.X + paddingSize, -annotation.Y + paddingSize);

		const pageMatrix = instance.docViewer.getDocument().getPageMatrix(annotation.PageNumber);
		annotation.draw(ctx, pageMatrix);

		return canvas.toDataURL();
	};

	useEffect(() => {
		WebViewer(
			{
				path: '/webviewer/lib',
				licenseKey: 'wpdS767IzJPdSPuEAABI', // Plus License
				disabledElements: [
					'miscToolGroupButton'
				]
			},
			viewer.current,
		).then(async (instance) => {
			// instance.Core.Blob;
			const { Core, docViewer } = instance;
			const { documentViewer, annotationManager } = Core;

			let myBlob = await streamDocument(fileId);
			instance.UI.loadDocument(myBlob, { filename: 'myfile.pdf' });
			instance.UI.setHeaderItems(header => {
				const items = header.getItems().slice(9, -3);
				header.update(items);
			});

			instance.disableTools(['AnnotationEdit']);
			instance.setToolMode('Pan');
			docViewer.on('toolModeUpdated', (annot) => {
				if (annot.name === 'AnnotationEdit') {
					instance.setToolMode('Pan');
				}
			})

			instance.UI.setHeaderItems(function (header) {
				// get the tools overlay
				const toolsOverlay = header.getHeader('toolbarGroup-Annotate').get('toolsOverlay');
				header.getHeader('toolbarGroup-Annotate').delete('toolsOverlay');
				// add the line tool to the top header
				header.getHeader('default').push({
					type: 'toolGroupButton',
					toolGroup: 'lineTools',
					dataElement: 'lineToolGroupButton',
					title: 'annotation.line',
				});
				// add the tools overlay to the top header
				header.push(toolsOverlay);
			});
			// hide the ribbons and second header
			instance.UI.disableElements(['ribbons']);
			instance.UI.disableElements(['toolsHeader']);

			annotationManager.addEventListener('annotationChanged', (annots) => {
				console.log("annotationChanged", annots[0]);
				setViewerState(instance);
				onAnnotationChangeCallBack(annots[0]);
			});

			annotationManager.addEventListener('fieldChanged', async (field, value) => {
				console.log('Field changed: ' + field.name + ', ' + value);
				setViewerState(instance);
				onFieldChangeCallBack({ [field]: value });
			})

			// adding an event listener for when a document is loaded
			Core.documentViewer.addEventListener('documentLoaded', async () => {
				console.log('document loaded');
				documentViewer.getAnnotationsLoadedPromise().then(async () => {
					// iterate over fields
					await instance.Core.annotationManager.importAnnotations(xfdf);
				});

				const newButton = {
					type: 'actionButton',
					img: 'icon-header-print-line',
					title: 'action.print',
					onClick: () => {
						instance.UI.print()
					},
					dataElement: 'printButton',
				}
				instance.UI.setHeaderItems((header) => {
					const items = header.update([]); //erase all items
					header.push(newButton)
				});

				setViewerState(instance);
				onDocumentLoadCallBack();
				setLoading(false);
			});

			// adding an event listener for when the page number has changed
			Core.documentViewer.addEventListener('pageNumberUpdated', (pageNumber) => {
				console.log(`Page number is: ${pageNumber}`);
			});
		});
	}, []);

	return (
		<div className="PDFJSWebviewer h-100">
			<div className={`webviewer h-100`} ref={viewer}></div>
		</div>
	);
};

PDFJSWebviewer.propTypes = {
	fileId: PropTypes.string,
	type: PropTypes.string,
	showSubmitButton: PropTypes.bool,
	disabledSubmitButton: PropTypes.bool,
	xfdf: PropTypes.string,
	setXfdf: PropTypes.func,
	viewerState: PropTypes.object,
	setViewerState: PropTypes.func,
	handleDocumentSave: PropTypes.func,
	onDocumentLoadCallBack: PropTypes.func,
	onFieldChangeCallBack: PropTypes.func,
	onAnnotationChangeCallBack: PropTypes.func,
};

export default PDFJSWebviewer;