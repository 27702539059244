import React, { useState, useContext } from 'react';
import Swal from 'sweetalert2';
import { Button, Form, Modal, Table } from 'react-bootstrap';
import { userAPI } from 'utils/api/fulcrum-api';
import { toast } from 'react-toastify';

const PrimaryRep = ({ claimant, setClaimant, selectedCase, setSelectedCase = () => { }, getClaimantCase = () => { }, updateCaseInformation = () => { } }) => {
	const [show, setShow] = useState(false);
	const [foundRepresentatives, setFoundRepresentatives] = useState([]);

	// Search Represenatives
	const searchRepresentatives = async () => {
		try {
			let result = await userAPI.searchUsersByUserType('REPRESENTATIVE', {
				account_id: claimant?.account_id || null
			});
			if (!result?.data) {
				throw 'Error searching for representatives';
			}
			setFoundRepresentatives(result?.data);
		} catch (error) {
			console.log(error);
			toast.error(error, { theme: 'colored' });
		}
	};

	// Handle Modal Show
	const handleShow = () => {
		searchRepresentatives();
	};

	// Handle Modal Close
	const handleClose = () => setShow(false);


	// Handle Primary Rep Select
	const handlePrimaryRepSelect = async (rep) => {
		updateCaseInformation({ primary_rep_id: rep.id });
		handleClose();
	};

	return (
		<div>
			{
				selectedCase?.primary_representative?.id
					?
					<div>
						<span className="fw-bold">Primary Representative:</span> &nbsp;
						<span className="">{selectedCase.primary_representative.first_name} {selectedCase.primary_representative.last_name}</span>
						{' '}
						<span className="cursor-pointer text-primary" onClick={() => { setShow(true) }}>Change</span>
					</div>
					:
					<div>
						<span className="fw-bold">Primary Representative:</span> &nbsp;
						<span className="cursor-pointer text-primary" onClick={() => { setShow(true) }}>Assign Primary Rep</span>
					</div>
			}

			<Modal
				show={show}
				onShow={handleShow}
				onHide={handleClose}
				backdrop="static"
				size="lg"
			>
				<Modal.Header closeButton>
					<Modal.Title>Primary Representative Search</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{
						foundRepresentatives.length > 0
							?
							<div>
								<h5>Found Representatives</h5>
								<Table striped bordered hover>
									<thead>
										<tr>
											<th>Name</th>
											<th>Email</th>
											<th>Phone</th>
											<th></th>
										</tr>
									</thead>
									<tbody>
										{
											foundRepresentatives.map((rep, index) => {
												return (
													<tr key={index}>
														<td>{rep.first_name} {rep.last_name}</td>
														<td>{rep.email}</td>
														<td>{rep.phone}</td>
														<td>
															<Button variant="link" onClick={(e) => { handlePrimaryRepSelect(rep) }} value={rep.id}>Select</Button>
														</td>
													</tr>
												)
											})
										}
									</tbody>
								</Table>
							</div>
							:
							<div>
								<h5>No Representatives Found</h5>
							</div>
					}
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleClose}>
						Close
					</Button>
				</Modal.Footer>
			</Modal>
		</div>
	);
};

export default PrimaryRep;