import React, { useState, useEffect } from 'react';
import { Table, Form, Button, ListGroup } from 'react-bootstrap';
import IconButton from 'components/common/IconButton';
import { toast } from 'react-toastify';
import { representativeDisciplinaryReasonAPI } from 'utils/api/fulcrum-api';
import DiscRow from './DiscRow';

const DiscLog = ({ user }) => {
	const [discLog, setDiscLog] = useState([]);
	const [loading, setLoading] = useState(true);

	// Get Representative Disciplinary Reasons
	const getRepresentativeDisciplinaryReasons = async () => {
		try {
			let result = await representativeDisciplinaryReasonAPI.getAllRepresentativeDisciplinaryReasonByUserId(user.id);
			if (!result?.data) {
				throw new Error('Error getting disciplinary reason');
			}

			setDiscLog(result.data);
		} catch (error) {
			console.error(error);
			toast.error('Error getting disciplinary reason', { theme: 'colored' });
		}
		setLoading(false);
	};

	// Add disciplinary reason item
	const addDiscLogItem = async () => {
		try {
			let result = await representativeDisciplinaryReasonAPI.createRepresentativeDisciplinaryReason(user.id, {});
			if (!result?.data) {
				throw new Error('Error adding disciplinary reason item');
			}
			getRepresentativeDisciplinaryReasons();
		} catch (error) {
			console.error(error);
			toast.error('Error adding disciplinary reason item', { theme: 'colored' });
		}
	};


	useEffect(() => {
		getRepresentativeDisciplinaryReasons();
	}, []);


	return (
		<div id="user-discLog-container" className="my-3">
			<div>
				<h5 className="d-inline">Disciplinary Log</h5>

				<IconButton
					className="me-2 mb-1 float-end border"
					variant="falcon-default"
					size="sm"
					icon="plus"
					transform="shrink-3"
					onClick={addDiscLogItem}
				>
					Add
				</IconButton>
			</div>

			<hr />


			<ListGroup className="border-0">
				{
					loading
						?
						<tr>
							<td colSpan="7" className="text-center">
								Loading...
							</td>
						</tr>
						: discLog.map((discLogItem) => (
							<ListGroup.Item key={`${discLogItem.id}_disc_item`} className=" py-3 border-0">
								<div className="mx-3">
									<DiscRow
										user={user}
										log={discLogItem}
										discLog={discLog}
										setDiscLog={setDiscLog}
										getRepresentativeDisciplinaryReasons={getRepresentativeDisciplinaryReasons}
									/>
								</div>
							</ListGroup.Item>
						))
				}
			</ListGroup>
		</div>
	);
};


export default DiscLog;