import React, { useState, useEffect } from 'react';
import { Dropdown, Row, Col, Button, Card, Form, InputGroup, FormControl } from 'react-bootstrap';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import CardDropdown from 'components/common/CardDropdown';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import { claimantEmploymentHistoryAPI } from 'utils/api/fulcrum-api';

const EmployerRow = ({ employer, claimant, getClaimantEmployers = () => { } }) => {
  let defaultFormData = {
    employer: employer.employer,
    job_title: employer.job_title,
    start_date: employer.start_date,
    stop_date: employer.stop_date,
    full_time: employer.full_time,
    job_description: employer.job_description,
    standing_time: employer.standing_time,
    sitting_time: employer.sitting_time,
    lifting: employer.lifting,
  };
  const [formData, setFormData] = useState(defaultFormData);
  const [previousFormData, setPreviousFormData] = useState(defaultFormData);

  // Remove a employer
  const removeEmployer = () => {
    Swal.fire({
      title: 'Permanently delete this employer record?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCansetFormDatalButton: true,
      confirmButtonColor: '#3085d6',
      cansetFormDatalButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
      cancelButtonColor: '#d33',
      showCancelButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          let result = await claimantEmploymentHistoryAPI.deleteClaimantEmployer(claimant.id, employer.id);
          if (!result?.message) {
            throw new Error('No data returned from server');
          }
          getClaimantEmployers();
          toast.warning('Income source removed', { theme: 'colored' });
        } catch (error) {
          console.error(error);
          toast.error('Error deleting employer', { theme: 'colored' });
        }
      }
    });
  };

  // Update a employer
  const updateEmployer = async (id, data) => {
    try {
      let result = await claimantEmploymentHistoryAPI.updateClaimantEmployer(claimant.id, id, data);
      console.log(result);
      if (!result) {
        throw new Error('Unable to update the employer.');
      }
      setPreviousFormData({ ...formData, ...data });

    } catch (error) {
      console.error(error);
      toast.error('Error updating employer.', {
        theme: 'colored'
      });
    }
  };

  // Save on change
  const saveOnChange = async (e, saveChange) => {
    let { name, value } = e.target;

    if (name === 'full_time') {
      value = value === 'true' ? true : false;
    }

    if (saveChange && previousFormData[name] !== value) {
      await updateEmployer(employer.id, { [name]: value });
    }

    setFormData({ ...formData, [name]: value });
  };

  useEffect(() => {
    let employerData = {
      employer: employer.employer,
      job_title: employer.job_title,
      start_date: employer.start_date,
      stop_date: employer.stop_date,
      full_time: employer.full_time,
      job_description: employer.job_description,
      standing_time: employer.standing_time,
      sitting_time: employer.sitting_time,
      lifting: employer.lifting,
    };
    setFormData(employerData);
    setPreviousFormData(employerData);
  }, [employer])

  return (
    <>
      <Form>
        <Row>
          <Col>
            <div className="d-flex justify-content-between float-end">
              <CardDropdown>
                <div className="py-2">
                  <Dropdown.Item onClick={() => { removeEmployer() }} className="text-danger">Delete</Dropdown.Item>
                </div>
              </CardDropdown>
            </div>
          </Col>

        </Row>
        <Row>

          <Col sm={6} className={'px-0'}>
            <Form.Group controlId="employer">
              <FloatingLabel
                controlId="employer"
                label="Employer"
                className=""
                size="sm"
              >
                <Form.Control
                  size="sm"
                  type="text"
                  name="employer"
                  value={formData.employer}
                  onChange={e => saveOnChange(e, false)}
                  onBlur={e => saveOnChange(e, true)}
                />
              </FloatingLabel>
            </Form.Group>
          </Col>
          <Col sm={6} className={'px-0'}>
            <Form.Group controlId="job_title">
              <FloatingLabel
                controlId="job_title"
                label="Job Title"
                className=""
                size="sm"
              >
                <Form.Control
                  size="sm"
                  type="text"
                  name="job_title"
                  value={formData.job_title}
                  onChange={e => saveOnChange(e, false)}
                  onBlur={e => saveOnChange(e, true)}
                />
              </FloatingLabel>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col sm={4} className={'px-0'}>
            <Form.Group controlId="start_date">
              <FloatingLabel
                controlId="start_date"
                label="Start Date"
                className=""
                size="sm"
              >
                <Form.Control
                  size="sm"
                  type="date"
                  name="start_date"
                  value={formData.start_date}
                  onChange={e => saveOnChange(e, false)}
                  onBlur={e => saveOnChange(e, true)}
                />
              </FloatingLabel>
            </Form.Group>
          </Col>
          <Col sm={4} className={'px-0'}>
            <Form.Group controlId="stop_date">
              <FloatingLabel
                controlId="stop_date"
                label="Stop Date"
                className=""
                size="sm"
              >
                <Form.Control
                  size="sm"
                  type="date"
                  name="stop_date"
                  value={formData.stop_date}
                  onChange={e => saveOnChange(e, false)}
                  onBlur={e => saveOnChange(e, true)}
                />
              </FloatingLabel>
            </Form.Group>
          </Col>
          <Col sm={4} className={'px-0'}>
            <Form.Group controlId="address_type_id">
              <FloatingLabel
                controlId="full_time"
                label="Full-Time?"
                className=""
                size="sm"
              >
                <Form.Select
                  size="sm"
                  as="select"
                  name="full_time"
                  value={formData.full_time}
                  onChange={e => saveOnChange(e, false)}
                  onBlur={e => saveOnChange(e, true)}
                >
                  <option value='' disabled>--Select one--</option>
                  <option value='true'>Yes</option>
                  <option value='false'>No</option>
                </Form.Select>
              </FloatingLabel>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col sm={12} className={'px-0'}>
            <Form.Group controlId="job_description">
              <FloatingLabel
                controlId="job_description"
                label="Job Description"
                className=""
                size="sm"
              >
                <Form.Control
                  size="sm"
                  type="text"
                  name="job_description"
                  value={formData.job_description}
                  onChange={e => saveOnChange(e, false)}
                  onBlur={e => saveOnChange(e, true)}
                />
              </FloatingLabel>
            </Form.Group>
          </Col>
        </Row>
        <Row>

          <Col sm={4} className={'px-0'}>
            <Form.Group controlId="standing_time">
              <FloatingLabel
                controlId="standing_time"
                label="Standing Time"
                className=""
                size="sm"
              >
                <Form.Control
                  size="sm"
                  type="text"
                  name="standing_time"
                  value={formData.standing_time}
                  onChange={e => saveOnChange(e, false)}
                  onBlur={e => saveOnChange(e, true)}
                />
              </FloatingLabel>
            </Form.Group>
          </Col>

          <Col sm={4} className={'px-0'}>
            <Form.Group controlId="sitting_time">
              <FloatingLabel
                controlId="sitting_time"
                label="Sitting Time"
                className=""
                size="sm"
              >
                <Form.Control
                  size="sm"
                  type="text"
                  name="sitting_time"
                  value={formData.sitting_time}
                  onChange={e => saveOnChange(e, false)}
                  onBlur={e => saveOnChange(e, true)}
                />
              </FloatingLabel>
            </Form.Group>
          </Col>
          <Col sm={4} className={'px-0'}>
            <Form.Group controlId="lifting">
              <FloatingLabel
                controlId="lifting"
                label="Max Weight Lifted"
                className=""
                size="sm"
              >
                <Form.Control
                  size="sm"
                  type="text"
                  name="lifting"
                  value={formData.lifting}
                  onChange={e => saveOnChange(e, false)}
                  onBlur={e => saveOnChange(e, true)}
                />
              </FloatingLabel>
            </Form.Group>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default EmployerRow;