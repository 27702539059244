import React, { useContext, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Spinner } from 'react-bootstrap';
import AppContext from 'context/Context';
import { myFormsAPI, documentAPI } from 'utils/api/fulcrum-api';
import { useEffect } from 'react';
import { sleep } from 'utils/functions';
import JoditEditor from "jodit-pro-react";
import { availableFields } from 'components/pages/documents/my-forms/availableFields';

import PreviewFormModal from '../MyFormViewer/PreviewFormModal';
import MyFormEditorModal from '../MyFormEditorModal';

const ABBREVIATIONS = {
	i: 'i',
	t: 't',
};


const EditorModal = ({
	modalState,
	setModalState,
	handleEditorSave = () => { },
	content,
	defaultEditorConfig = {},
	allowNewFormSave = false,
	setAllowNewFormSave = () => { },
	options = {},
	clientDataArray = [],
	allowPreview = false,
}) => {
	const editor = useRef(null);
	const {
		config: { isDark }
	} = useContext(AppContext);
	const [loading, setLoading] = useState(false);
	const [editorContent, setEditorContent] = useState(content);
	const countInputs = useRef(1);
	const metadata = useRef({});
	const [viewFormModalState, setViewFormModalState] = useState(false);
	const [newFormModalState, setNewFormModalState] = useState(false);
	const [fieldOptions, setFieldOptions] = useState('');
	const [textFieldOptions, setTextFieldOptions] = useState('');
	const [inputFieldOptions, setInputFieldOptions] = useState('');
	let defaultEditorOptions = {
		allowNewFormSave: false,
		allowFormSave: false,
		...options
	};
	const [editorOptions, setEditorOptions] = useState(defaultEditorOptions);
	// Get Available Keys
	const getAvailableKeys = async () => {
		try {
			let result = await myFormsAPI.getAvailableKeys({});
			if (!result?.data) { throw new Error('Error getting available keys'); }

			getFieldOptions(result.data);
		} catch (error) {
			toast.error(error, {
				theme: 'colored'
			});
		}
	};

	const getFieldOptions = async (availableKeys = {}) => {
		let fieldOptionsSelect = [];
		let textFieldOptionSelect = [];
		let inputFieldOptionSelect = [];

		fieldOptionsSelect.push(`<option>-- Select One --</option>`);
		Object.keys(availableKeys).forEach((fieldName) => {
			const optionObj = availableKeys[fieldName];

			let option = `<option value="${fieldName}">${optionObj?.label || 'Unknown'}</options>`;

			// set input options
			if ("allowedInput" in optionObj && optionObj?.allowedInput) {
				inputFieldOptionSelect.push(option);
			}

			// set text options
			if ("allowedText" in optionObj && optionObj?.allowedText) {
				textFieldOptionSelect.push(option);
			}

			fieldOptionsSelect.push(option);
		});


		setFieldOptions(fieldOptionsSelect.join(''));
		setTextFieldOptions(textFieldOptionSelect.join(''));
		setInputFieldOptions(inputFieldOptionSelect.join(''));
		return fieldOptions;
	};


	const editorConfig = {
		// license: 
		readonly: false, // all options from https://xdsoft.net/jodit/doc/
		// toolbarAdaptive: false,
		height: "500px",
		buttons: [
			"source",
			"bold",
			"strikethrough",
			"underline",
			"italic",
			'ul',
			'ol',
			'outdent',
			'indent',
			'font',
			'fontsize',
			'brush',
			'paragraph',
			'image',
			'table',
			'link',
			'align',
			'undo',
			'redo',
			'hr',
			'eraser',
			'symbol',
			'copyformat',
			// 'fullsize',
			'print'
		],
		extraButtons: [
			{
				name: 'Embed Fields',
				tooltip: 'Embed fields',
				popup: (editor, current, self, close) => {

					// const form = editor.create.fromHTML(htmlPieces.join('\n'));
					const html = `
					<form style="min-width: 500px; min-height: tabIndex="0" 500px; padding: 15px;">

					<div class="form-group" id="field-group-embed-type">
						<label for="exampleFormControlSelect1">Embed Type</label>
						<select class="form-control" defaultValue="t" id="field-embed-type" required>
							<option value="t">Text</option>
							<option value="i">Input</option>
						</select>
					</div>

					<div class="form-group d-none">
						<label for="exampleFormControlSelect1">Field select</label>
						<select class="form-control" id="field-select">
						${fieldOptions}
						</select>
					</div>

					<div class="form-group d-none" id="input-field-group-select">
						<label for="exampleFormControlSelect1">Input Field select</label>
						<select class="form-control" id="input-field-select">
						${inputFieldOptions}
						</select>
					</div>

					<div class="form-group"  id="text-field-group-select">
						<label for="exampleFormControlSelect1">Text Field select</label>
						<select class="form-control" id="text-field-select">
						${textFieldOptions}
						</select>
					</div>

					

					<div class="form-check d-none" id="field-group-required">
						<input type="checkbox" class="field-required" id="field-required">
						<label class="field-required" for="field-required">Require Field?</label>
					</div>

					<div class="form-check d-none" id="field-group-disabled">
					  	<input type="checkbox" class="field-disabled" id="field-disabled">
					 	<label class="field-disabled" for="field-disabled">Disable Field?</label>
					</div>

					<div class="form-check d-none" id="field-group-placeholder">
					  <input type="checkbox" class="field-placeholder" id="field-placeholder" checked>
					  <label class="field-placeholder" for="field-placeholder">Show Field Placeholder?</label>
					</div>

					<div class="form-check d-none" id="field-group-placeholder">
					  <input type="checkbox" class="field-placeholder" id="field-placeholder" checked>
					  <label class="field-placeholder" for="field-placeholder">Show Field Placeholder?</label>
					</div>

					<div class="form-check field-showDefaultValue d-none" id="field-group-showDefaultValue">
						<input type="checkbox" class="field-showDefaultValue" id="field-showDefaultValue" checked>
						<label class="field-showDefaultValue" for="field-showDefaultValue">Show Default Value?</label>
					</div>

					<div class="form-check field-hidden d-none" id="field-group-hidden">
						<input type="checkbox" class="field-hidden" id="field-hidden">
						<label class="field-hidden" for="field-hidden">Hide Field?</label>
					</div>

					<br />

					<button type="submit" class="btn btn-primary" id="field-group-submit-button">Embed Field</button>
				</form>
				`;

					const form = editor.create.fromHTML(html);

					// on Change handler for #field-select
					form.querySelector('#text-field-group-select').addEventListener('change', (e) => {
						document.getElementById("field-group-submit-button").classList.remove("d-none");
					});
					form.querySelector('#input-field-group-select').addEventListener('change', (e) => {
						document.getElementById("field-group-submit-button").classList.remove("d-none");
					});

					form.querySelector('#field-embed-type').addEventListener('change', (e) => {

						if (e.target.value === 'i') {
							document.getElementById("text-field-group-select").classList.add("d-none");
							document.getElementById("input-field-group-select").classList.remove("d-none");
							document.getElementById("field-group-placeholder").classList.remove("d-none");
							document.getElementById("field-group-showDefaultValue").classList.remove("d-none");
							document.getElementById("field-group-disabled").classList.remove("d-none");
							document.getElementById("field-group-required").classList.remove("d-none");
						} else if (e.target.value === 't') {
							document.getElementById("input-field-group-select").classList.add("d-none");
							document.getElementById("text-field-group-select").classList.remove("d-none");
							document.getElementById("field-group-placeholder").classList.add("d-none");
							document.getElementById("field-group-showDefaultValue").classList.add("d-none");
							document.getElementById("field-group-disabled").classList.add("d-none");
							document.getElementById("field-group-required").classList.add("d-none");
						} else {
							document.getElementById("input-field-group-select").classList.add("d-none");
							document.getElementById("text-field-group-select").classList.add("d-none");
							document.getElementById("field-group-placeholder").classList.add("d-none");
							document.getElementById("field-group-showDefaultValue").classList.add("d-none");
							document.getElementById("field-group-disabled").classList.add("d-none");
							document.getElementById("field-group-required").classList.add("d-none");
						}
					});
					// editor.events.on(form, 'change', (e) => {

					editor.e.on(form, 'submit', (e) => {
						e.preventDefault();
						// Get the input field values
						const embedType = form?.querySelector('#field-embed-type').value || null;

						const inputId = `${ABBREVIATIONS[embedType]}${countInputs.current}`;

						// Complile the string to be inserted into the editor
						let embedString = '';
						let selected;
						let optionObj = {};
						switch (embedType) {
							case 't':
								selected = form?.querySelector("#text-field-select").value || null;
								embedString = `{{${selected}}}`;
								optionObj = {
									...fieldOptions[selected],
									selected,
									embedType
								};
								break;
							case 'i':
								selected = form?.querySelector("#input-field-select").value || null;
								const showPlaceholder = form?.querySelector("#field-placeholder").checked || null;
								const useDefaultValue = form?.querySelector("#field-showDefaultValue").checked || null;
								const disabled = form?.querySelector("#field-disabled").checked || null;
								const required = form?.querySelector("#field-required").checked || null;
								let attributes = [];
								required && attributes.push('r');
								disabled && attributes.push('d');
								!showPlaceholder && attributes.push('ph');
								!useDefaultValue && attributes.push('dv');
								embedString = `{{input|${selected}|[${attributes.join(',')}]}}`;
								optionObj = {
									...fieldOptions[selected],
									selected,
									embedType,
									required,
									disabled,
								};
								break;

							default:
								alert('Please select a field type');
								return;
						};

						if (!selected) { return alert('You must have a field name selected'); }

						editor.s.insertHTML(`<span class='' id="embeded_object_${selected}_${inputId}">${embedString}</span>`);

						metadata.current = {
							...metadata.current,
							[inputId]: optionObj
						};

						countInputs.current++;
						close();
					});

					return form;
				}
			}
		],
		...defaultEditorConfig
	}

	// Handle Save
	const handleSave = async (withModalClose = true) => {
		let textEditor = document.getElementById("testing-it").innerHTML;

		if (withModalClose) {
			handleClose();
		} else {

			setEditorContent(textEditor);
		}
		handleEditorSave(textEditor);
	};

	// Handle Show
	const handleShow = async () => {
		setLoading(true);
		setEditorOptions({ ...setEditorOptions, ...options })
		getAvailableKeys();
		await sleep(1000);
		// setEditorContent(content);
		setLoading(false);
	};

	// Handle Close
	const handleClose = () => {
		setLoading(true);
		setEditorOptions(defaultEditorOptions);
		setModalState(false);
	};



	return (
		<Modal
			show={modalState}
			onHide={handleClose}
			onShow={handleShow}
			contentClassName="border"
			enforceFocus={false}
			size="lg"
			backdrop="static"
		>
			<Modal.Header
				closeButton
				closeVariant={isDark ? 'white' : undefined}
				className="bg-light px-card border-bottom-0"
			>
				<Modal.Title as="h5">Form Editor</Modal.Title>
			</Modal.Header>
			<Modal.Body className="p-card">
				<div>
					<div id="testing-it" className="d-none"></div>
					{
						loading
							?
							<div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
								<Spinner animation="border" variant="primary" />
							</div>
							:
							<div className="mt-3">
								{
									allowPreview &&
									<div className="d-flex justify-content-end">
										<Button variant="outline-primary" onClick={() => { handleSave(false); setViewFormModalState(true) }}>Preview</Button>
									</div>
								}
								<JoditEditor
									ref={editor}
									id="editor-view"
									value={content}
									rows={25}
									config={editorConfig}
									onChange={newContent => {
										document.getElementById("testing-it").innerHTML = newContent;
									}}
									tabIndex={1} // tabIndex of textarea
								/>

								<PreviewFormModal
									modalState={viewFormModalState}
									setModalState={setViewFormModalState}
									content={editorContent}
									clientDataArray={clientDataArray}
								/>
							</div>
					}
				</div>
			</Modal.Body>
			<Modal.Footer className="bg-light px-card border-top-0">
				<div className="mb-3">
					{
						"allowFormSave" in editorOptions && editorOptions.allowFormSave &&
						<>
							<Button className="mr-2" onClick={handleSave}>Save and Exit</Button>
						</>
					}

					{
						"allowFormPreview" in editorOptions && editorOptions.allowFormPreview &&
						<>
							<Button className="mr-2" onClick={() => setViewFormModalState(true)}>Preview Form</Button>
						</>
					}

					{
						"allowNewFormSave" in editorOptions && editorOptions.allowNewFormSave &&
						<>
							<Button className="mr-2" onClick={() => setNewFormModalState(true)}>Save as new Form Template</Button>

							<MyFormEditorModal
								withNavigateToFormPage={false}
								modalState={newFormModalState}
								setModalState={setNewFormModalState}
								defaultNewFormValues={{
									title: 'My New Form Template',
									description: 'My New Form Template',
									form_type_id: 'OTHER',
									content
								}}
							/>
						</>
					}
					{/* <Button variant="falcon-primary" onClick={() => setViewFormModalState(true)}>Preview Form</Button> */}
					{" "}
					<Button variant="secondary-outlined" className="" onClick={handleClose}>
						Close
					</Button>
				</div>


			</Modal.Footer>
		</Modal>
	);
};


export default EditorModal;