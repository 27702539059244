import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Auth from 'utils/auth'
import { sessionAPI } from 'utils/api/fulcrum-api';
const REFRESH_USER_SECONDS = Number(process.env.REACT_APP_REFRESH_USER_SECONDS || "90"); // Zero value will disable the timer

const RefreshSession = ({ }) => {
	// const { signIn, checkUser: checkAzureUser } = useAppContext();
	const [modalState, setModalState] = useState(false);

	// Close modal
	const handleClose = () => {
		setModalState(false);
	};

	// Submit session refresh request
	const refreshSessionSubmit = async (event, loginType = "popup") => {
		if (event) {
			event.preventDefault();
		}
		try {
			await sessionAPI.extendSession();
		} catch (error) {
			console.error(error);
			return endSession();
		}
		setModalState(false);
	};

	// End session
	const endSession = async (event) => {
		if (event) {
			event.preventDefault();
		}

		handleClose();
		await Auth.logout();
	};

	// Check user session status
	const checkUserSession = async () => {
		// Check user session
		let { remainingTime } = await Auth.isLoggedIn();
		if (remainingTime <= 4) {
			setModalState(true);
		} else {
			setModalState(false);
		}
	}

	useEffect(() => {
		let mounted = true;
		const init = async () => {
			let { isLoggedIn = false } = await Auth.isLoggedIn();
			checkUserSession();
			if (isLoggedIn) {
				if (REFRESH_USER_SECONDS > 0) {
					setInterval(() => {
						checkUserSession();
					}, 1000 * REFRESH_USER_SECONDS);
				}
			}
		}
		if (mounted) { init(); }
		return () => { mounted = false };
	}, []);

	return (
		<Modal
			show={modalState}
			onHide={handleClose}
			size="lg"
			aria-labelledby="contained-modal-title-vcenter"
			centered
			backdrop='static'
		>
			<Modal.Header>
				<Modal.Title>Session Expiration</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className="search-input-container">
					<h5 className="mb-4">Your session is about to expire.  Would you like to continue? </h5>
					<Button onClick={refreshSessionSubmit} variant="falcon-primary" className="primary-button mr-3" >Extend Session</Button>
					{" "}
					<Button onClick={endSession} type="button" variant="falcon-danger" className="remove-button" >End Session</Button>
				</div>
			</Modal.Body>
		</Modal>
	);
}

export default RefreshSession;