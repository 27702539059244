import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
let { addressAPI, claimantDetailsAPI } = require('utils/api/fulcrum-api');
import Dropdown from 'react-bootstrap/Dropdown';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import CardDropdown from 'components/common/CardDropdown';
import ListGroup from 'react-bootstrap/ListGroup';
import { stateData } from 'data/stateData';
import AppContext from 'context/Context';

const AddressRow = ({ address, addresses, setAddresses, uuid, entity_id }) => {
	let defaultFormData = {
		address_type_id: address.address_type_id,
		address: address.address,
		address_2: address.address_2,
		city: address.city,
		state: address.state,
		zip: address.zip,
		country: address.country,
		description: address.description,
		is_primary: address.is_primary
	};
	const [formData, setFormData] = useState(defaultFormData);
	const [previousFormData, setPreviousFormData] = useState(defaultFormData);
	const {
		lookups
	} = useContext(AppContext);

	const handleInputChange = (event) => {
		let { name, value } = event.target;
		let additonalFormData = {};

		if (name === 'is_primary') {
			value = !formData[name];
		}

		setFormData({ ...formData, ...additonalFormData, [name]: value });
	};

	// Update address
	const updateAddress = async (data) => {
		try {
			let result;
			if (uuid) {
				result = await claimantDetailsAPI.updateAddress(uuid, entity_id.split('_')[2], address.id, data);
			} else {
				result = await addressAPI.updateAddress(address.id, data);
			}
			if (!result?.data) {
				throw new Error('Unable to update address.');
			}
			setPreviousFormData({
				...formData,
				...data
			});
		} catch (error) {
			console.error(error);
			setFormData(previousFormData);
			toast.error(error.message, {
				theme: 'colored'
			});
		}
	};

	// Save on change
	const saveOnChange = async (event) => {
		let { name, value } = event.target;

		if (['is_primary'].includes(name)) {
			value = !formData[name];
			if (value) { return await togglePrimaryAddress(address.id); }
		} else if (['zip'].includes(name)) {
			value = value.replace(/[^0-9]/g, '');
			if (!value.length) {
				value = null;
			}
		}

		if (previousFormData[name] !== value) {
			updateAddress({ [name]: value });
		}
	};

	// Toggle primary address
	const togglePrimaryAddress = async (newPrimaryId) => {
		try {
			let result = await addressAPI.togglePrimaryAddress(newPrimaryId, address.entity_id);
			if (!result?.message) {
				throw new Error('Unable to update primary address.');
			}

			// Update address list
			setAddresses(addresses.map(address => {
				if (address.id === newPrimaryId) {
					address.is_primary = true;
				} else {
					address.is_primary = false;
				}
				return address;
			}));
			toast.success(result.message, {
				theme: 'colored'
			});
		} catch (error) {
			toast.error(error.message, {
				theme: 'colored'
			});
		}
	};

	// Delete address
	const deleteAddress = async (addressId) => {
		Swal.fire({
			title: 'Permanently delete address?',
			text: "You won't be able to revert this!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Yes, delete it!'
		}).then(async (result) => {
			if (result.isConfirmed) {
				try {
					let result;
					if (uuid) {
						result = await claimantDetailsAPI.deleteAddress(uuid, entity_id.split('_')[2], addressId);
					} else {
						result = await addressAPI.deleteAddress(address.id);
					}

					if (!result?.message) {
						throw new Error('Unable to delete address.');
					}
					setAddresses([...addresses.filter(a => a.id !== addressId)]);
					toast.warning(result.message, {
						theme: 'colored'
					});
				} catch (error) {
					toast.error(error.message, {
						theme: 'colored'
					});
				}
			}
		});
	};

	useEffect(() => {
		let addressData = {
			address_type_id: address.address_type_id,
			address: address.address,
			address_2: address.address_2,
			city: address.city,
			state: address.state,
			zip: address.zip,
			country: address.country,
			description: address.description,
			is_primary: address.is_primary
		};
		setFormData(addressData);
		setPreviousFormData(addressData);
	}, [address.is_primary]);

	return (
		<ListGroup.Item as="li">
			<Form>
				<Row>
					<Col>
						<div className="">
							<Form.Check
								size="sm"
								type="checkbox"
								label={`Primary`}
								name='is_primary'
								checked={formData.is_primary}
								onChange={e => { handleInputChange(e); saveOnChange(e); }}
								className={`${formData.is_primary !== previousFormData.is_primary && ''}`}
							/>

						</div>
					</Col>
					<Col>
						<div className="d-flex justify-content-between float-end">
							<CardDropdown>
								<div className="py-2">
									<Dropdown.Item onClick={() => { deleteAddress(address.id) }} className="text-danger">Delete</Dropdown.Item>
								</div>
							</CardDropdown>
						</div>
					</Col>

				</Row>
				<Row>
					<Col sm={4} className={'px-0'}>
						<Form.Group controlId="address_type_id">
							<FloatingLabel
								controlId="address_type_id"
								label="Type"
								className=""
								size="sm"
							>
								<Form.Select
									size="sm"
									as="select"
									name="address_type_id"
									value={formData.address_type_id}
									onChange={handleInputChange}
									onBlur={saveOnChange}
								>
									<option value=''> </option>
									{
										Array.isArray(lookups.addressTypes) && lookups.addressTypes.map((type, index) => (
											<option key={index} value={type.id}>{type.type}</option>
										))
									}
								</Form.Select>
							</FloatingLabel>
						</Form.Group>
					</Col>
					<Col sm={8} className={'px-0'}>
						<Form.Group controlId="address">
							<FloatingLabel
								controlId="address"
								label="Address"
								className=""
								size="sm"
							>
								<Form.Control
									size="sm"
									type="text"
									name="address"
									value={formData.address}
									onChange={handleInputChange}
									onBlur={saveOnChange}
								/>
							</FloatingLabel>
						</Form.Group>
					</Col>
					{/* <Col sm={3} className={'px-0'}>
						<Form.Group controlId="address_2">
							<FloatingLabel
								controlId="address_2"
								label="Address 2"
								className=""
								size="sm"
							>
								<Form.Control
									size="sm"
									type="text"
									name="address_2"
									value={formData.address_2}
									onChange={handleInputChange}
									onBlur={saveOnChange}
								/>
							</FloatingLabel>
						</Form.Group>
					</Col> */}
				</Row>
				<Row>
					<Col sm={6} className={'px-0'}>
						<Form.Group controlId="city">
							<FloatingLabel
								controlId="city"
								label="City"
								className=""
								size="sm"
							>
								<Form.Control
									size="sm"
									type="text"
									name="city"
									value={formData.city}
									onChange={handleInputChange}
									onBlur={saveOnChange}
								/>
							</FloatingLabel>
						</Form.Group>
					</Col>
					<Col sm={3} className={'px-0'}>
						<Form.Group controlId="state">
							<FloatingLabel
								controlId="state"
								label="State"
								className=""
								size="sm"
							>
								<Form.Select
									size="sm"
									as="select"
									name="state"
									value={formData.state}
									onChange={handleInputChange}
									onBlur={saveOnChange}
								>
									<option value=""></option>
									{
										Array.isArray(stateData) && stateData.map((state, index) => {
											return (
												<option key={index} value={state.abbreviation}>{state.abbreviation}</option>
											);
										})
									}
								</Form.Select>
							</FloatingLabel>
						</Form.Group>
					</Col>
					<Col sm={3} className={'px-0'}>
						<Form.Group>
							<FloatingLabel
								controlId="zip"
								label="Zip"
								className=""
								size="sm"
							>
								<Form.Control
									size="sm"
									type="text"
									name="zip"
									value={formData.zip}
									onChange={handleInputChange}
									onBlur={saveOnChange}
								/>
							</FloatingLabel>
						</Form.Group>
					</Col>
				</Row>
			</Form>
		</ListGroup.Item>
	);
};

AddressRow.propTypes = {
	address: PropTypes.string,
	getAddressList: PropTypes.func,
	addresses: PropTypes.array,
	setAddresses: PropTypes.func
}

export default AddressRow;