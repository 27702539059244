import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import AppContext from 'context/Context';
import { Modal, Button, ListGroup, Form, Alert, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { ClaimantLocatorRequestAPI, accountServiceAPI, userAPI } from 'utils/api/fulcrum-api';
import { toast } from 'react-toastify';
import ServiceAccess from 'components/client-account/services/ServiceAccess';
import Auth from 'utils/auth';
import moment from 'moment';

const LostContact = ({ modalState, setModalState = () => { }, claimant }) => {
	const {
		config: { isDark }
	} = useContext(AppContext);
	const [submitting, setSubmitting] = useState(false);

	const [formData, setFormData] = useState({
		locatorRequestOption: ""
	});

	const defaultOptions = {
		assure_locator_request: {
			selected: false,
		},
		reestablish_contact_task: {
			selected: false,
		},
		request_claimant_contact: {
			selected: false,
			transmission_methods: {
				sms: false,
				email: false,
				mail: false,
			}
		},
		warn_claimant_file_close: {
			selected: false,
			transmission_methods: {
				sms: false,
				email: false,
				mail: false,
			}
		},
		close_file: {
			selected: false,
			transmission_methods: {
				sms: false,
				email: false,
				mail: false,
			}
		}
	};

	const [options, setOptions] = useState(defaultOptions);

	const [hasAssureLostContactAccess, setAssureLostContactAccess] = useState(true); // @TODO: Check if user has access to assure lost contact

	// Check if account has service access
	const checkAccountServiceAccess = async () => {
		let hasAccess = false;
		let account_id = Auth.getProfile()?.data?.account_id || null;
		let user_id = Auth.getProfile()?.data?.user_id || null;

		try {
			// Check if the user has access to the service
			let userAccessResult = await userAPI.checkUserPermission(user_id, `CLAIMANT_LOCATOR_REQUESTS`);

			// Check if account has access to the service
			let result = await accountServiceAPI.checkAccountServiceStatus(account_id, 3, `${account_id}_3`);
			if (!result?.data) { throw new Error(`Error checking service access for Claimant Locator Requests.`); }

			if (("isActive" in result.data && result.data.isActive) && ("hasPermission" in userAccessResult?.data && userAccessResult.data.hasPermission)) {
				hasAccess = true;
			} else {
				hasAccess = false;
			}
		} catch (error) {
			hasAccess = false;
		}

		setAssureLostContactAccess(hasAccess);
	};

	// Set a task to re-establish contact with the claimant
	const handleReEstablishContact = async () => {
		try {
			let result = await ClaimantLocatorRequestAPI.submitClaimantLocatorRequest(
				claimant.id,
				{
					...defaultOptions,
					reestablish_contact_task: {
						selected: true,
					}
				}
			);
			if (!result?.data) {
				throw new Error('Error submitting claimant locator request');
			}

			handleClose();

			toast.success('Re-establish contact task created.', { theme: 'colored' });
		} catch (error) {
			console.error(error);
			toast.error('Error creating the task to re-establish contact.', { theme: 'colored' });
		}

		setSubmitting(false);
	};

	// Handle Assure Lost Contact Request
	const handleAssureLostContact = async () => {
		try {

			// Check if there is already a pending request
			if (claimant?.claimant_locator_requests && Array.isArray(claimant.claimant_locator_requests) && claimant.claimant_locator_requests.filter(clr => clr.status === "SUBMITTED").length > 0) {
				toast.error('There is already a pending lost contact request for this claimant.', { theme: 'colored' });
				return;
			}

			let result = await ClaimantLocatorRequestAPI.submitClaimantLocatorRequest(
				claimant.id,
				{
					...defaultOptions,
					assure_locator_request: {
						selected: true,
					}
				}
			);
			if (!result?.data) {
				throw new Error('Error submitting claimant locator request');
			}

			handleClose();

			toast.success('Claimant locator request submitted to Assure.', { theme: 'colored' });
		} catch (error) {
			console.error(error);
			toast.error('Error: Unable to submit the claimant locator request to Assure.', { theme: 'colored' });
		}

		setSubmitting(false);
	};

	// Handle Request Claimant Contact
	const handleRequestClaimantContact = async () => {
		try {
			if (!options.request_claimant_contact.transmission_methods.sms
				&& !options.request_claimant_contact.transmission_methods.email
				&& !options.request_claimant_contact.transmission_methods.mail) {
				toast.error('Please select at least one transmission method.', { theme: 'colored' });
				return;
			}

			if (options.request_claimant_contact.transmission_methods.sms && !claimant?.primary_phone?.phone) {
				toast.error('This claimant does not have a primary phone selected.', { theme: 'colored' });
				return;
			}

			if (options.request_claimant_contact.transmission_methods.email && !claimant?.primary_email?.email) {
				toast.error('This claimant does not have a primary email selected.', { theme: 'colored' });
				return;
			}

			let result = await ClaimantLocatorRequestAPI.submitClaimantLocatorRequest(
				claimant.id,
				{
					...defaultOptions,
					request_claimant_contact: {
						selected: true,
						transmission_methods: {
							...options.request_claimant_contact.transmission_methods
						}
					},
				}
			);
			if (!result?.data) {
				throw new Error('Error submitting request');
			}

			handleClose();

			toast.success('Requested claimant contact.', { theme: 'colored' });
		} catch (error) {
			console.error(error);
			toast.error('Error: Unable to request claimant contact.', { theme: 'colored' });
		}

		setSubmitting(false);
	};

	// Handle Warn Claimant Their Case may be Closed
	const handleWarnClaimantCaseClosed = async () => {
		try {
			if (!options.warn_claimant_file_close.transmission_methods.sms
				&& !options.warn_claimant_file_close.transmission_methods.email
				&& !options.warn_claimant_file_close.transmission_methods.mail) {
				toast.error('Please select at least one transmission method.', { theme: 'colored' });
				return;
			}

			if (options.warn_claimant_file_close.transmission_methods.sms && !claimant?.primary_phone?.phone) {
				toast.error('This claimant does not have a primary phone selected.', { theme: 'colored' });
				return;
			}

			if (options.warn_claimant_file_close.transmission_methods.email && !claimant?.primary_email?.email) {
				toast.error('This claimant does not have a primary email selected.', { theme: 'colored' });
				return;
			}

			let result = await ClaimantLocatorRequestAPI.submitClaimantLocatorRequest(
				claimant.id,
				{
					...defaultOptions,
					warn_claimant_file_close: {
						selected: true,
						transmission_methods: {
							...options.warn_claimant_file_close.transmission_methods
						}
					},
				}
			);
			if (!result?.data) {
				throw new Error('Error submitting request');
			}

			handleClose();

			toast.success('Warned claimant their case may be closed.', { theme: 'colored' });
		} catch (error) {
			console.error(error);
			toast.error('Error: Unable to warn claimant their case may be closed.', { theme: 'colored' });
		}

		setSubmitting(false);
	};

	// Handle close file
	const handleCloseFile = async () => {
		try {
			if (!options.close_file.transmission_methods.sms
				&& !options.close_file.transmission_methods.email
				&& !options.close_file.transmission_methods.mail) {
				toast.error('Please select at least one transmission method.', { theme: 'colored' });
				return;
			}

			if (options.close_file.transmission_methods.sms && !claimant?.primary_phone?.phone) {
				toast.error('This claimant does not have a primary phone selected.', { theme: 'colored' });
				return;
			}

			if (options.close_file.transmission_methods.email && !claimant?.primary_email?.email) {
				toast.error('This claimant does not have a primary email selected.', { theme: 'colored' });
				return;
			}

			let result = await ClaimantLocatorRequestAPI.submitClaimantLocatorRequest(
				claimant.id,
				{
					...defaultOptions,
					close_file: {
						selected: true,
						transmission_methods: {
							...options.close_file.transmission_methods
						}
					},
				}
			);
			if (!result?.data) {
				throw new Error('Error submitting request');
			}

			handleClose();

			toast.success('Warned claimant their case may be closed.', { theme: 'colored' });
		} catch (error) {
			console.error(error);
			toast.error('Error: Unable to warn claimant their case may be closed.', { theme: 'colored' });
		}

		setSubmitting(false);
	};

	// Handle submit
	const handleSubmit = async (e) => {
		e.preventDefault();
		setSubmitting(true);
		switch (formData.locatorRequestOption) {
			case 'assure_locator_request':
				handleAssureLostContact();
				break;
			case 'reestablish_contact_task':
				handleReEstablishContact();
				break;
			case 'request_claimant_contact':
				handleRequestClaimantContact();
				break;
			case 'warn_claimant_file_close':
				handleWarnClaimantCaseClosed();
				break;
			case 'close_file':
				handleCloseFile();
				break;
			default:
				break;
		}
	}

	// Handle Modal Close
	const handleClose = () => {
		setFormData({
			locatorRequestOption: ""
		})
		setOptions(defaultOptions);
		setModalState(false);
	};

	// Handle Modal Show
	const handleShow = () => {
		setFormData({
			locatorRequestOption: ""
		});
		checkAccountServiceAccess();
		setOptions(defaultOptions);
		setModalState(true);
	};

	// Render lost contact option content
	const renderLostContactOptionContent = (option) => {
		let content;
		switch (option) {
			case 'assure_locator_request':
				content = (
					<>
						<span className="fw-bold">Have Assure Disability find this claimant.</span>
						<p className="mb-0">Assure Disability will search for this claimant and notify you if they are found.</p>

						{
							hasAssureLostContactAccess
								?
								<>
									{
										(claimant?.claimant_locator_requests &&
											Array.isArray(claimant.claimant_locator_requests) && claimant.claimant_locator_requests.length > 0)
											?
											<>
												{
													claimant?.claimant_locator_requests &&
													Array.isArray(claimant.claimant_locator_requests) &&
													claimant.claimant_locator_requests.filter(clr => clr.status === "SUBMITTED").map((r, index) => (
														<Alert variant="info" key={index}>
															<p>
																There is a pending lost contact request for this claimant with Assure Disability submitted {moment(r.createdAt).format('MM-DD-YYYY hh:mm A')}.
															</p>

														</Alert>
													))
												}
											</>
											: null
									}
								</>
								:
								<>
									<Alert variant={'warning'}>
										Either your user or account does not have access to execute lost contact requests with Assure Disability.
										An admin of your account can enroll in this service by navigating to the <Link to={'/account-management'}>account services tab.</Link>
									</Alert>
								</>
						}
					</>);
				break;
			case 'reestablish_contact_task':
				content = (
					<>
						<span className="fw-bold">Set a task to re-establish contact with {claimant?.first_name} {claimant?.last_name}.</span>

						<p className="mb-0">Selecting this option will generate a new task titled "Re-Establish Contact" due 3 days from now.</p>
					</>);
				break;
			case 'request_claimant_contact':
				content = (
					<>
						<span className="fw-bold">Send a message to {claimant?.first_name} {claimant?.last_name} requesting contact.</span>


						<p className="mb-0">Select at least one mode of transmission:</p>
						<Form.Check
							type='checkbox'
							id='defaultCheckbox'
							checked={options.request_claimant_contact.transmission_methods.email}
							onChange={(e) => setOptions({ ...options, request_claimant_contact: { ...options.request_claimant_contact, transmission_methods: { ...options.request_claimant_contact.transmission_methods, email: e.target.checked } } })}
							label='Send an email'
						/>
						<Form.Check
							type='checkbox'
							id='checkedCheckbox'
							checked={options.request_claimant_contact.transmission_methods.sms}
							onChange={(e) => setOptions({ ...options, request_claimant_contact: { ...options.request_claimant_contact, transmission_methods: { ...options.request_claimant_contact.transmission_methods, sms: e.target.checked } } })}
							label='Send as SMS message'
						/>
						<Form.Check
							type='checkbox'
							id='disabledCheckbox'
							checked={options.request_claimant_contact.transmission_methods.mail}
							onChange={(e) => setOptions({ ...options, request_claimant_contact: { ...options.request_claimant_contact, transmission_methods: { ...options.request_claimant_contact.transmission_methods, mail: e.target.checked } } })}
							label='Send by regular mail'
						/>

					</>);
				break;
			case 'warn_claimant_file_close':
				content = (
					<>
						<span className="fw-bold">Send a message to {claimant?.first_name} {claimant?.last_name} indicating their case may be closed.</span>

						<p className="mb-0">Select at least one mode of transmission:</p>
						<Form.Check
							type='checkbox'
							id='defaultCheckbox'
							checked={options.warn_claimant_file_close.transmission_methods.email}
							onChange={(e) => setOptions({ ...options, warn_claimant_file_close: { ...options.warn_claimant_file_close, transmission_methods: { ...options.warn_claimant_file_close.transmission_methods, email: e.target.checked } } })}
							label='Send an email'
						/>
						<Form.Check
							type='checkbox'
							id='checkedCheckbox'
							checked={options.warn_claimant_file_close.transmission_methods.sms}
							onChange={(e) => setOptions({ ...options, warn_claimant_file_close: { ...options.warn_claimant_file_close, transmission_methods: { ...options.warn_claimant_file_close.transmission_methods, sms: e.target.checked } } })}
							label='Send as SMS message'
						/>
						<Form.Check
							type='checkbox'
							id='disabledCheckbox'
							checked={options.warn_claimant_file_close.transmission_methods.mail}
							onChange={(e) => setOptions({ ...options, warn_claimant_file_close: { ...options.warn_claimant_file_close, transmission_methods: { ...options.warn_claimant_file_close.transmission_methods, mail: e.target.checked } } })}
							label='Send by regular mail'
						/>

					</>);
				break;
			case 'close_file':
				content = (
					<>
						<span className="fw-bold">Close the file</span>

						<p className="mb-0">Selecting this option will close out the file.</p>
						<p className="mb-0">Select at least one mode of transmission:</p>
						<Form.Check
							type='checkbox'
							id='defaultCheckbox'
							checked={options.close_file.transmission_methods.email}
							onChange={(e) => setOptions({ ...options, close_file: { ...options.close_file, transmission_methods: { ...options.close_file.transmission_methods, email: e.target.checked } } })}
							label='Send an email'
						/>
						<Form.Check
							type='checkbox'
							id='checkedCheckbox'
							checked={options.close_file.transmission_methods.sms}
							onChange={(e) => setOptions({ ...options, close_file: { ...options.close_file, transmission_methods: { ...options.close_file.transmission_methods, sms: e.target.checked } } })}
							label='Send as SMS message'
						/>
						<Form.Check
							type='checkbox'
							id='disabledCheckbox'
							checked={options.close_file.transmission_methods.mail}
							onChange={(e) => setOptions({ ...options, close_file: { ...options.close_file, transmission_methods: { ...options.close_file.transmission_methods, mail: e.target.checked } } })}
							label='Send by regular mail'
						/>
					</>);
				break;
			default:
				content = null;
		}

		return content;
	};

	return (
		<>
			<Modal
				show={modalState}
				onShow={handleShow}
				onHide={handleClose}
				contentClassName="border"
				backdrop="static"
				size="lg"
			>
				<Modal.Header
					closeButton
					closeVariant={isDark ? 'white' : undefined}
					className="bg-light px-card border-bottom-0"
				>
					<Modal.Title as="h5">Lost contact with {claimant?.first_name} {claimant?.last_name}?  What would you like to do?</Modal.Title>
				</Modal.Header>
				<Modal.Body className="p-card">
					<Form onSubmit={handleSubmit}>
						{/* Claimant Locator Request Option Dropdown */}
						<Form.Group controlId="locatorRequestOption">
							<Form.Label>Choose a Lost Contact Option</Form.Label>
							<Form.Control
								as="select"
								value={formData.locatorRequestOption}
								onChange={(e) => setFormData({ ...formData, locatorRequestOption: e.target.value })}
							>
								<option value="">Select an option</option>
								<option value="assure_locator_request">Have Assure Disability find this claimant.</option>
								<option value="reestablish_contact_task">Set a task to re-establish contact with {claimant?.first_name} {claimant?.last_name}.</option>
								<option value="request_claimant_contact">Send a message to {claimant?.first_name} {claimant?.last_name} requesting contact.</option>
								<option value="warn_claimant_file_close">Warn Claimant File Close</option>
								<option value="close_file">Close File</option>
							</Form.Control>
						</Form.Group>

						<div className="mx-3 my-4">
							{renderLostContactOptionContent(formData.locatorRequestOption)}
						</div>
					</Form>
					{/* <ListGroup>
						<ListGroup.Item>

							<Row>
								<Col sm={1}>
									<Form.Check
										type='checkbox'
										className="fs-2"
										checked={options.assure_locator_request.selected}
										onChange={(e) => setOptions({ ...options, assure_locator_request: { ...options.assure_locator_request, selected: e.target.checked } })}
										id='checkedCheckbox'
										label={''}
										disabled={!hasAssureLostContactAccess}
									/>
								</Col>
								<Col>
									<span className="fw-bold">Have Assure Disability find this claimant.</span>
									<p className="mb-0">Assure Disability will search for this claimant and notify you if they are found.</p>

									{
										hasAssureLostContactAccess
											?
											<>
												{
													(claimant?.claimant_locator_requests &&
														Array.isArray(claimant.claimant_locator_requests) && claimant.claimant_locator_requests.length > 0)
														?
														claimant.claimant_locator_requests.filter(clr => clr.status === "SUBMITTED").length > 0 &&
														<Alert variant="warning">
															<p>
																There is already a claimant locator request submitted for this claimant.
															</p>
														</Alert>
														: null
												}

											</>
											:
											<>
												<Alert variant={'warning'}>
													Your account does not have access to execute lost contact requests with Assure Disability.
													An admin of your account can enroll in this service by navigating to the <Link to={'/account-management'}> ccount services tab.</Link>
												</Alert>
											</>
									}
								</Col>
							</Row>
						</ListGroup.Item>



						<ListGroup.Item>
							<Row>
								<Col sm={1}>
									<Form.Check
										type='checkbox'
										className="fs-2"
										checked={options.reestablish_contact_task.selected}
										onChange={(e) => setOptions({ ...options, reestablish_contact_task: { ...options.reestablish_contact_task, selected: e.target.checked } })}
										id='checkedCheckbox'
										label={''}
									/>
								</Col>
								<Col>
									<span className="fw-bold">Set a task to re-establish contact with {claimant?.first_name} {claimant?.last_name}.</span>

									<p className="mb-0">Selecting this option will generate a new task titled "Re-Establish Contact" due 3 days from now.</p>
								</Col>
							</Row>
						</ListGroup.Item>

						<ListGroup.Item>
							<Row>
								<Col sm={1}>
									<Form.Check
										type='checkbox'
										className="fs-2"
										checked={options.request_claimant_contact.selected}
										onChange={(e) => setOptions({ ...options, request_claimant_contact: { ...options.request_claimant_contact, selected: e.target.checked } })}
										id='checkedCheckbox'
										label={''}
									/>
								</Col>
								<Col>
									<span className="fw-bold">Send a message to {claimant?.first_name} {claimant?.last_name} requesting contact.</span>

									{
										options.request_claimant_contact.selected &&
										<>
											<p className="mb-0">Select at least one mode of transmission:</p>
											<Form.Check
												type='checkbox'
												id='defaultCheckbox'
												checked={options.request_claimant_contact.transmission_methods.email}
												onChange={(e) => setOptions({ ...options, request_claimant_contact: { ...options.request_claimant_contact, transmission_methods: { ...options.request_claimant_contact.transmission_methods, email: e.target.checked } } })}
												label='Send an email'
											/>
											<Form.Check
												type='checkbox'
												id='checkedCheckbox'
												checked={options.request_claimant_contact.transmission_methods.sms}
												onChange={(e) => setOptions({ ...options, request_claimant_contact: { ...options.request_claimant_contact, transmission_methods: { ...options.request_claimant_contact.transmission_methods, sms: e.target.checked } } })}
												label='Send as SMS message'
											/>
											<Form.Check
												type='checkbox'
												id='disabledCheckbox'
												checked={options.request_claimant_contact.transmission_methods.mail}
												onChange={(e) => setOptions({ ...options, request_claimant_contact: { ...options.request_claimant_contact, transmission_methods: { ...options.request_claimant_contact.transmission_methods, mail: e.target.checked } } })}
												label='Send by regular mail'
											/>
										</>
									}
								</Col>
							</Row>
						</ListGroup.Item>

						<ListGroup.Item>
							<Row>
								<Col sm={1}>
									<Form.Check
										type='checkbox'
										className="fs-2"
										checked={options.warn_claimant_file_close.selected}
										onChange={(e) => setOptions({ ...options, warn_claimant_file_close: { ...options.warn_claimant_file_close, selected: e.target.checked } })}
										id='checkedCheckbox'
										label={''}
									/>
								</Col>
								<Col>
									<span className="fw-bold">Send a message to {claimant?.first_name} {claimant?.last_name} indicating their case may be closed.</span>

									{
										options.warn_claimant_file_close.selected &&
										<>
											<p className="mb-0">Select at least one mode of transmission:</p>
											<Form.Check
												type='checkbox'
												id='defaultCheckbox'
												checked={options.warn_claimant_file_close.transmission_methods.email}
												onChange={(e) => setOptions({ ...options, warn_claimant_file_close: { ...options.warn_claimant_file_close, transmission_methods: { ...options.warn_claimant_file_close.transmission_methods, email: e.target.checked } } })}
												label='Send an email'
											/>
											<Form.Check
												type='checkbox'
												id='checkedCheckbox'
												checked={options.warn_claimant_file_close.transmission_methods.sms}
												onChange={(e) => setOptions({ ...options, warn_claimant_file_close: { ...options.warn_claimant_file_close, transmission_methods: { ...options.warn_claimant_file_close.transmission_methods, sms: e.target.checked } } })}
												label='Send as SMS message'
											/>
											<Form.Check
												type='checkbox'
												id='disabledCheckbox'
												checked={options.warn_claimant_file_close.transmission_methods.mail}
												onChange={(e) => setOptions({ ...options, warn_claimant_file_close: { ...options.warn_claimant_file_close, transmission_methods: { ...options.warn_claimant_file_close.transmission_methods, mail: e.target.checked } } })}
												label='Send by regular mail'
											/>
										</>
									}
								</Col>
							</Row>
						</ListGroup.Item>

						<ListGroup.Item>
							<Row>
								<Col sm={1}>
									<Form.Check
										type='checkbox'
										className="fs-2"
										checked={options.close_file.selected}
										onChange={(e) => setOptions({ ...options, close_file: { ...options.close_file, selected: e.target.checked } })}
										id='checkedCheckbox'
										label={''}
									/>
								</Col>
								<Col>
									<span className="fw-bold">Close the file</span>

									<p className="mb-0">Selecting this option will close out the file.</p>
									{
										options.close_file.selected &&
										<>
											<p className="mb-0">Select at least one mode of transmission:</p>
											<Form.Check
												type='checkbox'
												id='defaultCheckbox'
												checked={options.close_file.transmission_methods.email}
												onChange={(e) => setOptions({ ...options, close_file: { ...options.close_file, transmission_methods: { ...options.close_file.transmission_methods, email: e.target.checked } } })}
												label='Send an email'
											/>
											<Form.Check
												type='checkbox'
												id='checkedCheckbox'
												checked={options.close_file.transmission_methods.sms}
												onChange={(e) => setOptions({ ...options, close_file: { ...options.close_file, transmission_methods: { ...options.close_file.transmission_methods, sms: e.target.checked } } })}
												label='Send as SMS message'
											/>
											<Form.Check
												type='checkbox'
												id='disabledCheckbox'
												checked={options.close_file.transmission_methods.mail}
												onChange={(e) => setOptions({ ...options, close_file: { ...options.close_file, transmission_methods: { ...options.close_file.transmission_methods, mail: e.target.checked } } })}
												label='Send by regular mail'
											/>
										</>
									}
								</Col>
							</Row>
						</ListGroup.Item>
					</ListGroup> */}
				</Modal.Body>
				<Modal.Footer>
					{
						(options.reestablish_contact_task || options.request_claimant_contact || options.warn_claimant_file_close || options.close_file) ?
							<Button variant="primary" onClick={handleSubmit}>
								Submit
							</Button>
							:
							<span className="text-warning">Select at least one option above</span>
					}
				</Modal.Footer>
			</Modal>
		</>
	);
};

LostContact.propTypes = {
	modalState: PropTypes.bool,
	setModalState: PropTypes.func,
	claimant: PropTypes.object
}

export default LostContact;