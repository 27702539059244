import React from 'react';
import PropTypes from 'prop-types';
import Flex from 'components/common/Flex';
import { Card, Col, Row } from 'react-bootstrap';
import corner4 from 'assets/img/illustrations/corner-4.png';

const PageHeader = ({ title, children }) => {
	return (
		<Card className="bg-100 shadow-none border mb-3">
			<Card.Body className="py-0">
				<Row className=" justify-content-between pr-0">
					<Col sm="auto" className="pr-0 align-middle">
						<Flex alignItems="center">
							<h4 className="text-primary align-middle fw-bold mb-0 h-inline">
								<span className="text-info fw-medium">{title}</span>
							</h4>
							<img
								src={corner4}
								width={140}
								alt="..."
								className="ms-n4 d-md-none d-lg-block float-end"
							/>
						</Flex>
					</Col>

					<Col sm="auto" className="pr-0">

						<Flex alignItems="center">
							<div className="float-end">
								{children}

							</div>
							<img
								src={corner4}
								width={140}
								alt="..."
								className="ms-n4 d-md-none d-lg-block float-end"
							/>
						</Flex>
					</Col>
				</Row>
			</Card.Body>
		</Card>
	);
};

PageHeader.propTypes = {
	title: PropTypes.string,
	children: PropTypes.node
}

export default PageHeader;
