import React from 'react';
import PropTypes from 'prop-types';
import RegistrationWizardLayout from './RegistrationWizardLayout';
import AuthWizardProvider from '../AuthWizardProvider';

const RegistrationWizard = ({ variant, validation, progressBar }) => {
	return (
		<AuthWizardProvider>
			<RegistrationWizardLayout
				variant={variant}
				validation={validation}
				progressBar={progressBar}
			/>
		</AuthWizardProvider>
	);
};

RegistrationWizard.propTypes = {
	variant: PropTypes.oneOf(['pills']),
	validation: PropTypes.bool,
	progressBar: PropTypes.bool
};

export default RegistrationWizard;