import React, { useState, useEffect, useContext, useRef } from 'react';
import { myFormsAPI, documentAPI, inboxOutboxAPI } from 'utils/api/fulcrum-api';
import { Button, Form, Modal, Table, Alert, Spinner, Row, Col } from 'react-bootstrap';
import ClaimantQuickFind from 'components/claimants/find-a-claimant/ClaimantQuickFind';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { toast } from 'react-toastify';
import JoditEditor from "jodit-pro-react";
import MyFormFinderModal from 'components/pages/documents/my-forms/MyFormFinderModal';
import EditorModal from 'components/pages/documents/my-forms/document-generator/EditorModal';
import PDFViewerModal from 'components/app/pdfjs-viewer/PDFViewerModal';
import CustomMessageEditor from './CustomMessageEditor';
import ReactSelect from 'react-select';
import moment from 'moment';
import { validateEmail } from 'utils/functions';
import ClaimantDocumentQuickFind from 'components/claimants/claimant-document-quick-find';
import Swal from 'sweetalert2';
import auth from 'utils/auth';

const receiptTypes = ["Claimant(s)", "Social Security Administration", "Another Recipient"];
const transmissionTypes = ["Email", "Fax", "SMS", "Mail"];
const messageTypes = ['Form Templates', 'Custom Message'];


const CreateCorrespondenceForm = ({ account_id = auth.getProfile()?.data?.account_id || null, claimant = {}, submitRef, handleClose = () => { }, setCustomMessage = () => { }, refreshParent = () => { }, defaultClaimants = [], defaultFormData = {} }) => {
	const [documentTypes, setDocumentTypes] = useState([]);
	const [myFormFinderModal, setMyFormFinderModal] = useState(false);
	const [formEditorModal, setFormEditorModal] = useState(false);
	const [selectedForm, setSelectedForm] = useState(null);
	const [selectedDocuments, setSelectedDocuments] = useState([]);
	const [claimantDocuments, setClaimantDocuments] = useState([]);
	const [editorModalOptions, setEditorModalOptions] = useState({
		allowNewFormSave: false,
		allowFormSave: true,
	});
	const [allowNewFormSave, setAllowNewFormSave] = useState(false);
	const [pdfViewerModalState, setPdfViewerModalState] = useState(false);
	const [submitting, setSubmitting] = useState(false);
	const [claimants, setClaimants] = useState(defaultClaimants);
	const [otherRecipients, setOtherRecipients] = useState([]);
	const [editorConfig, setEditorConfig] = useState({});
	const [attachments, setAttachments] = useState([]);
	const [forms, setForms] = useState([]);
	let defaultFormState = {
		mode_of_transmission: null,
		recipient_type: null,
		message_type: null,
		subject: '', // Email only
		all_claimants: false,
		merge_to_document_packet: false,
		document_packet_title: null
	};

	const [formData, setFormData] = useState(defaultFormState);

	// Get related data @TODO: Move this to a context
	const getRelatedData = async () => {
		try {
			let docTypeResults = await documentAPI.searchDocumentTypes();
			if (!docTypeResults?.data?.rows) {
				throw new Error('Unable to get documents types.');
			}

			setDocumentTypes(docTypeResults.data.rows);
		} catch (error) {
			alert(error);
		}
	};

	// Handle edit selected 
	const handleEditSelected = (form) => {
		setSelectedForm(form);
		switch (form.type) {
			case "My Form":
			case 'RTF':
				setSelectedForm(form);
				setAllowNewFormSave(false);
				setEditorModalOptions({
					...editorModalOptions,
					allowNewFormSave: false,
					allowFormSave: true
				})
				setEditorConfig({
					readonly: false,
				});
				setFormEditorModal(true);
				break;
			case "PDF":
				setPdfViewerModalState(true);
				break;
			default:
				break;
		}
	};


	// Handle input change
	const handleInputChange = (event) => {
		const { name, value } = event.target;
		let additionalChanges = {};
		if (name === 'recipient_type') {
			additionalChanges = {
				mode_of_transmission: null,
				message_type: null,
				subject: '',
				all_claimants: false,
				merge_to_document_packet: false,
				document_packet_title: null
			}
			setCustomMessage(false);
		} else if (name === 'message_type') {
			let showCustomMessage = value === 'Custom Message' ? true : false
			setCustomMessage(showCustomMessage);
		}
		setFormData({ ...formData, ...additionalChanges, [name]: value });
	};

	// Handle form submission
	const handleSubmit = async (event) => {
		event.preventDefault();
		setSubmitting(true);
		try {
			let formTemplates = [];
			if (formData.message_type === 'Custom Message') {
				let message = document.getElementById('custom-message-content').innerHTML;
				formTemplates.push({
					content: message,
					type: 'my-form-response',
					type: 'RTF',
					form_type_id: "OTHER",
					title: 'Custom Message',
					description: 'Custom Message',
					order: 1,
				});
			} else {
				formTemplates = forms;
			}

			let attachments = [];
			if (selectedDocuments && selectedDocuments.length > 0) {
				for (let i = 0; i < selectedDocuments.length; i++) {
					attachments.push({
						document_id: selectedDocuments[i].id,
						file_id: selectedDocuments[i].file_id,
					});
				}
			}

			let result = await inboxOutboxAPI.submitCorrespondence(account_id || null, {
				...formData,
				claimants: claimants,
				other_recipients: otherRecipients,
				forms: formTemplates,
				attachments
			});
			if (result?.message !== 'Correspondence submitted successfully.') {
				throw new Error('Unable to submit correspondence.');
			}
			toast.success('Correspondence submitted successfully.', { theme: 'colored' });
			refreshParent();
			// handleClose();
		} catch (error) {
			console.log(error);
			toast.error('Unable to submit correspondence.', { theme: 'colored' });
		}
		setSubmitting(false);
	};

	// Add other recipient
	const addOtherRecipient = () => {
		let newOtherRecipient = {
			recipient: '',
			claimant_id: null,
			claimant_name: null,
		};
		setOtherRecipients([...otherRecipients, newOtherRecipient]);
	};

	// Remove other recipient
	const removeOtherRecipient = (index) => {
		let newOtherRecipients = [...otherRecipients];
		newOtherRecipients.splice(index, 1);
		setOtherRecipients(newOtherRecipients);
	};

	// Remove other recipient claimant
	const removeOtherRecipientClaimant = (index) => {
		let newOtherRecipients = [...otherRecipients];
		newOtherRecipients[index].claimant_id = null;
		newOtherRecipients[index].claimant_name = null;
		setOtherRecipients(newOtherRecipients);
	};

	// Handle Claimant Selection// Handle Claimant Selection
	const handleClaimantSelection = (selectedClaimants = []) => {
		// Check if the claimant is already in the list
		console.log(selectedClaimants);
		let approvedClaimants = [];


		for (let i = 0; i < selectedClaimants.length; i++) {
			let allowClaimant = true;
			for (let j = 0; j < claimants.length; j++) {
				if (selectedClaimants[i].id === claimants[j].id) {
					allowClaimant = false
				}
			}
			if (allowClaimant) {
				approvedClaimants.push(selectedClaimants[i]);
			}
		}

		setClaimants([...claimants, ...approvedClaimants]);
	};

	// Handle form selection
	const handleFormSelection = (form) => {
		console.log(form)
		// let type = ['My Form', 'RTF', 'my-form-response'].includes(type) ? 'my-form-response' : 'document-response';
		let newForms = [...forms, { id: Date.now(), ...form, order: forms.length + 1 }];
		setForms(newForms);
	};

	// Handle other recipient claimant selection
	const handleOtherRecipientClaimantSelection = (index, selectedClaimant = []) => {
		console.log(selectedClaimant)
		if (selectedClaimant.length > 0) {
			let newOtherRecipients = [...otherRecipients];
			newOtherRecipients[index].claimant_id = selectedClaimant[0].id;
			newOtherRecipients[index].claimant_name = selectedClaimant[0].name;
			setOtherRecipients(newOtherRecipients);
		} else {
			alert('Unable to select the claimant');
		}
	}

	// Handle Editor Save
	const handleEditorSave = (content) => {
		let newForms = forms.map((form, index) => {
			if (form.id === selectedForm.id) {
				return {
					...form,
					content
				}
			}
			return form;
		});

		setForms(newForms);
	};

	// Get claimant documents
	const getClaimantDocuments = async (claimant_id) => {
		if (claimant_id) {
			try {
				let result = await documentAPI.getClaimantDocuments(claimant_id);
				if (!result?.data) {
					throw new Error('Unable to get claimant documents.');
				}
				setClaimantDocuments(result.data.map((document) => {
					return {
						...document,
						value: document.id,
						label: `${document?.document_type?.type || ''} - ${document.title} - ${document?.createdAt ? moment(document.createdAt).format('MM-DD-YYYY hh:mm A') : ''}`,
					}
				}));
			} catch (error) {
				setClaimantDocuments([]);
				setSelectedDocuments(null);
				toast.error('Unable to get claimant documents.', { theme: 'colored' });
			}
		}
	};

	// Check claimant email
	const checkClaimantEmail = (claimant) => {
		let pirmaryEmail = claimant.entity.contact_emails.find(e => e.is_primary)?.email || null;

		if (!pirmaryEmail) {
			return <span className="text-danger">No email found.</span>;
		} else {
			// validate email 
			let valid = validateEmail(pirmaryEmail);
			if (valid) {
				return <span>{pirmaryEmail}</span>;
			} else {
				return <span className="text-danger">{pirmaryEmail}</span>;
			}
		}

	};

	// Check claimant phone
	const checkClaimantPhone = (claimant, type) => {
		let phone;
		if (type === 'Primary') {
			phone = claimant.entity.contact_phones.find(e => e.is_primary)?.phone || null;
		} else if (type === 'Fax') {
			phone = claimant.entity.contact_phones.find(e => e.phone_type_id === "Fax")?.phone || null;
		}

		if (!phone) {
			return <span className="text-danger">No phone found.</span>;
		} else {
			// validate phone 
			return <span>{phone}</span>;
		}
	};

	// Handle document selection
	const handleDocumentSelection = (selectedClaimantDocuments = []) => {
		let approvedClaimants = [];

		for (let i = 0; i < selectedClaimantDocuments.length; i++) {
			let allowDocument = true;
			for (let j = 0; j < selectedDocuments.length; j++) {
				if (selectedClaimantDocuments[i].id === selectedDocuments[j].id) {
					allowDocument = false
				}
			}
			if (allowDocument) {
				approvedClaimants.push(selectedClaimantDocuments[i]);
			}
		}

		setSelectedDocuments([...selectedDocuments, ...approvedClaimants]);
	};


	useEffect(() => {
		setClaimantDocuments([]);
		setSelectedDocuments([]);
		if (formData.recipient_type === 'Social Security Administration' && claimants.length === 1) {
			getClaimantDocuments(claimants[0]?.id || null);
		}
	}, [claimants]);

	useEffect(() => {
		setClaimantDocuments([]);
		setSelectedDocuments([]);
		getRelatedData();
	}, []);

	return (
		<>
			<Form onSubmit={handleSubmit}>
				<Row>
					<Col sm={12} className={'px-0'}>
						<Alert variant="info" className="mb">
							<Alert.Heading>Instructions</Alert.Heading>
							<p>
								Please fill out the form below to create a correspondence. Once you have completed the form, click the "Submit" button to create and send the correspondence.
							</p>
						</Alert>

						<Form.Group controlId="recipient_type">
							<Form.Label className="fw-bold">Recipient Type: </Form.Label>
							<br />
							<Form.Check
								type="radio"
								inline
								required
								name="recipient_type"
								value={"Claimant(s)"}
								label="Claimant(s)"
								id="recipient_type_claimant"
								checked={formData.recipient_type === "Claimant(s)"}
								onChange={handleInputChange}
							/>
							<Form.Check
								type="radio"
								inline
								required
								name="recipient_type"
								value={"Social Security Administration"}
								label="Social Security Administration"
								id="recipient_type_ssa"
								checked={formData.recipient_type === "Social Security Administration"}
								onChange={handleInputChange}
							/>
							<Form.Check
								type="radio"
								inline
								required
								name="recipient_type"
								value={"Another Recipient"}
								label="Another Recipient"
								id="recipient_type_other"
								checked={formData.recipient_type === "Another Recipient"}
								onChange={handleInputChange}
							/>
						</Form.Group>


						{
							formData.recipient_type && (
								<>
									<Form.Group controlId="mode_of_transmission">
										<Form.Label className="fw-bold">Mode of Transmission: </Form.Label>
										<br />
										{
											!['Social Security Administration'].includes(formData.recipient_type) && (
												<Form.Check
													type="radio"
													inline
													required
													name="mode_of_transmission"
													value={"Email"}
													label="Email"
													id="mode_of_transmission_email"
													checked={formData.mode_of_transmission === "Email"}
													onChange={(e) => setFormData({ ...formData, mode_of_transmission: "Email" })}
												/>
											)
										}
										{
											!['Social Security Administration'].includes(formData.recipient_type) && (
												<Form.Check
													type="radio"
													inline
													required
													name="mode_of_transmission"
													value={"SMS"}
													label="SMS"
													id="mode_of_transmission_sms"
													checked={formData.mode_of_transmission === "SMS"}
													onChange={(e) => setFormData({ ...formData, mode_of_transmission: "SMS" })}
												/>
											)
										}
										<Form.Check
											type="radio"
											inline
											required
											name="mode_of_transmission"
											value={"Fax"}
											label="Fax"
											id="mode_of_transmission_fax"
											checked={formData.mode_of_transmission === "Fax"}
											onChange={(e) => setFormData({ ...formData, mode_of_transmission: "Fax" })}
										/>
										<Form.Check
											type="radio"
											inline
											required
											name="mode_of_transmission"
											value={"Mail"}
											label="Regular Mail"
											id="mode_of_transmission_mail"
											checked={formData.mode_of_transmission === "Mail"}
											onChange={(e) => setFormData({ ...formData, mode_of_transmission: "Mail" })}
										/>
									</Form.Group>
									<hr />
									{
										formData.mode_of_transmission &&
										<div>
											{
												formData.mode_of_transmission === "Email" &&
												(
													<>
														<Form.Group controlId="subject">
															<Form.Label className="fw-bold">Email Subject: </Form.Label>
															<Form.Control
																size="md"
																type="text"
																placeholder="Enter email subject"
																name="subject"
																value={formData.subject}
																onChange={handleInputChange}
																required
																disabled={submitting}
															/>
														</Form.Group>

														<hr />
													</>
												)
											}

											{
												['Claimant(s)'].includes(formData.recipient_type) && (
													<div>
														<div className="my-2">

															<Form.Label className="fw-bold">Attach Claimant(s) to Correspondence: </Form.Label>
															<br />
															<div className="pt-3">
																{
																	!submitting && (
																		<ClaimantQuickFind
																			buttonLabel={'Search Claimants'}
																			buttonVariant={'falcon-primary'}
																			handleClaimantSelection={handleClaimantSelection}
																			requiredSearchParams={{}}
																			defaultSearchParams={{}}
																			allowMultipleSelect={true}
																		/>
																	)
																}

																&ensp;&ensp; {' '}  OR {' '}  &ensp;&ensp;

																{/* All Claimants Checkbox */}
																<Form.Check
																	type="checkbox"
																	inline
																	id="all_claimants"
																	name="all_claimants"
																	label="All Active Claimants"
																	checked={formData.all_claimants}
																	onChange={e => {
																		setFormData({
																			...formData,
																			all_claimants: e.target.checked,
																			claimants: []
																		});
																	}}
																/>
															</div>



															{
																!formData.all_claimants ?
																	(claimants.length) > 0 ? (
																		<>
																			{
																				claimants.length > 25 ? (
																					<>
																						<p>More than 25 claimants have been selected.
																							<Button
																								variant="link"
																								className="p-0 float-end text-danger"
																								disabled={submitting}
																								onClick={() => {
																									setClaimants([]);
																								}}
																							>
																								<FontAwesomeIcon className="text-danger" icon={faTrash} /> Clear
																							</Button>
																						</p>
																					</>
																				) :
																					<Table bordered size="md" className="bg-white mt-3">
																						<thead className="bg-200">
																							<tr>
																								<th>Claimant</th>
																								<th>Email</th>
																								<th>Phone</th>
																								<th>Fax</th>
																								<th></th>
																							</tr>
																						</thead>
																						<tbody>
																							{claimants.map((claimant, index) => (
																								<tr key={index}>
																									<td>
																										{claimant.first_name} {claimant.last_name}
																									</td>
																									<td>{checkClaimantEmail(claimant)}</td>
																									<td>{checkClaimantPhone(claimant, 'Primary')}</td>
																									<td>{checkClaimantPhone(claimant, 'Fax')}</td>

																									<td>
																										<Button
																											variant="link"
																											className="p-0 float-end"
																											onClick={() => {
																												setClaimants(claimants.filter((c) => c.id !== claimant.id));
																											}}
																										>
																											<FontAwesomeIcon className="text-danger" icon={faTrash} />
																										</Button>
																									</td>
																								</tr>
																							))}
																						</tbody>
																					</Table>
																			}

																		</>
																	) : <p className="text-danger">Please select at least one claimant that will be linked to this correspondence.</p>
																	:
																	<p className="text-warning">All active claimants will recieve this correspondence. Please note that this will take some time to process.</p>
															}
														</div>

														<hr />
													</div>

												)
											}

											{
												['Social Security Administration'].includes(formData.recipient_type) && (
													<div>
														<p className="fw-bold">Assign a Claimant

															{
																!submitting && (
																	<ClaimantQuickFind
																		buttonLabel={'Search Claimants'}
																		buttonVariant={'link'}
																		handleClaimantSelection={(claimants = []) => {
																			setClaimants(claimants);
																		}}
																		requiredSearchParams={{}}
																		defaultSearchParams={{}}
																		allowMultipleSelect={false}
																	/>
																)
															}
														</p>


														<p>Please select a claimant to be linked to this Social Security Administration correspondence.</p>

														{
															(claimants.length) > 0 ? (
																<>
																	<Table striped bordered size="md">
																		<thead>
																			<tr>
																				<th>Claimant</th>
																			</tr>
																		</thead>
																		<tbody>
																			{claimants.map((claimant, index) => (
																				<tr key={index}>
																					<td>
																						{claimant.first_name} {claimant.last_name}
																						<Button
																							variant="link"
																							className="p-0 float-end"
																							onClick={() => {
																								setClaimants(claimants.filter((c) => c.id !== claimant.id));
																							}}
																						>
																							<FontAwesomeIcon className="text-danger" icon={faTrash} />
																						</Button>
																					</td>
																				</tr>
																			))}
																		</tbody>
																	</Table>

																	Claimant Documents
																	<br />
																	{/* <Table>
															<thead>
																<tr>
																	<th>Document</th>
																	<th>Document Type</th>
																	<th>Document Date</th>
																	<th></th>
																</tr>
															</thead>
															<tbody>
																{claimantDocuments.map((document, index) => (
																	<tr key={index}>
																		<td>{document?.title || ''}</td>
																		<td>{document?.document_type?.type || document?.document_type_id || 'Unknown'}</td>
																		<td>{document?.createdAt ? moment(document.createdAt).format('MM-DD-YYYY hh:mm A') : ''}</td>
																		<td>
																			<Button
																				variant="link"
																				className="p-0 float-end"
																				onClick={() => {
																					setClaimantDocuments(claimantDocuments.filter((c) => c.id !== document.id));
																				}}
																			>
																				<FontAwesomeIcon className="text-danger" icon={faTrash} />
																			</Button>
																		</td>
																	</tr>
																))}
															</tbody>
														</Table> */}
																	<ClaimantDocumentQuickFind
																		buttonLabel={'View Claimant Documents'}
																		buttonVariant={'link'}
																		handleDocumentSelection={handleDocumentSelection}
																		requiredSearchParams={{}}
																		defaultSearchParams={{}}
																		allowMultipleSelect={true}
																		claimantId={claimants[0]?.id}
																	/>
																	<ReactSelect
																		closeMenuOnSelect={false}
																		options={claimantDocuments}
																		placeholder='Select...'
																		isMulti
																		classNamePrefix="react-select"
																		value={selectedDocuments}
																		onChange={value => setSelectedDocuments(value)}
																	/>
																</>
															) : <p className="text-danger">No claimant selected.</p>
														}
													</div>
												)
											}

											{
												formData.recipient_type === 'Another Recipient' && (
													<div className="mb-3">
														<Button variant="link" className="p-0" onClick={() => addOtherRecipient()}>+ Add recipient</Button>
														<Table striped bordered size="md">
															<thead>
																<tr>
																	<th>Recipient</th>
																	<th>Linked Claimant</th>
																	<th></th>
																</tr>
															</thead>
															<tbody>
																{otherRecipients.map((recipient, index) => (
																	<tr key={index}>
																		<td>
																			{
																				formData.mode_of_transmission === 'Email' ?

																					<Form.Control
																						type="email"
																						required
																						value={recipient.name}
																						disabled={submitting}
																						onChange={(e) => {
																							let { value } = e.target;
																							setOtherRecipients(otherRecipients.map((r, i) => {
																								if (i === index) {
																									r.name = value;
																								}
																								return r;
																							}
																							))
																						}}
																					/>
																					:
																					<Form.Control
																						type="text"
																						required
																						value={recipient.name}
																						disabled={submitting}
																						onChange={(e) => {
																							let { value } = e.target;
																							setOtherRecipients(otherRecipients.map((r, i) => {
																								if (i === index) {
																									r.name = value;
																								}
																								return r;
																							}
																							))
																						}}
																					/>
																			}
																		</td>
																		<td>
																			{recipient?.claimant_id ? (
																				<>
																					{recipient.claimant_name}
																					<Button
																						variant="link"
																						className="p-0 float-end"
																						disabled={submitting}
																						onClick={() => {
																							removeOtherRecipientClaimant(index)
																						}}
																					>
																						Remove
																					</Button>
																				</>
																			) : (
																				<>
																					{
																						!submitting && (
																							<ClaimantQuickFind
																								buttonLabel={'Link Claimant'}
																								buttonVariant={'link'}
																								handleClaimantSelection={(selectedClaimants = []) => {
																									console.log(selectedClaimants)
																									if (selectedClaimants.length > 0) {
																										let newOtherRecipients = [...otherRecipients];
																										newOtherRecipients[index].claimant_id = selectedClaimants[0]?.id || null;
																										newOtherRecipients[index].claimant_name = `${selectedClaimants[0]?.first_name || ''} ${selectedClaimants[0]?.last_name || ''}`;
																										setOtherRecipients(newOtherRecipients);
																									} else {
																										alert('Unable to select the claimant');
																									}
																								}}
																								requiredSearchParams={{}}
																								defaultSearchParams={{}}
																								allowMultipleSelect={false}
																							/>
																						)
																					}
																				</>
																			)}
																		</td>
																		<td>
																			<Button
																				disabled={submitting}
																				variant="link"
																				className="p-0"
																				onClick={() => removeOtherRecipient(index)}
																			>
																				<FontAwesomeIcon className="text-danger" icon={faTrash} />
																			</Button>
																		</td>
																	</tr>
																))}
															</tbody>
														</Table>

													</div>
												)
											}

											{/* Select Form Templates */}
											{
												formData.recipient_type !== "Social Security Administration" && (

													<Form.Group controlId="message_type">
														<Form.Label className="fw-bold">Message Type: </Form.Label>
														<br />
														<Form.Check
															type="radio"
															inline
															required
															name="message_type"
															value={"Custom Message"}
															label="Custom Message"
															id="message_type_custom"
															checked={formData.message_type === "Custom Message"}
															onChange={handleInputChange}
															disabled={submitting}
														/>
														<Form.Check
															type="radio"
															inline
															required
															name="message_type"
															value={"Form Templates"}
															label="Form Templates"
															id="message_type_templates"
															checked={formData.message_type === "Form Templates"}
															onChange={handleInputChange}
															disabled={submitting}
														/>
													</Form.Group>

													// <Form.Group controlId="formMessageType">
													// 	<Form.Label>Message Type</Form.Label>
													// 	<Form.Select
													// 		aria-label="Select Message Type"
													// 		onChange={e => {
													// 			let { value } = e.target
													// 			setCustomMessage(value === 'Custom Message' ? true : false)
													// 			handleInputChange(e)
													// 		}}
													// 		name="message_type"
													// 		value={formData.message_type}
													// 		required
													// 	>
													// 		<option value="">Select Message Type</option>
													// 		{messageTypes.map((messageType) => (
													// 			<option key={`message_type_${messageType}`} value={messageType}>
													// 				{messageType}
													// 			</option>
													// 		))}
													// 	</Form.Select>
													// </Form.Group>
												)
											}


											{
												formData.recipient_type !== "Social Security Administration" && formData.message_type && (
													<div>
														{/* Create document packet checkbox */}
														{
															formData.message_type === "Form Templates" && (
																<>
																	<Form.Group controlId="merge_to_document_packet">
																		<Form.Check
																			type="checkbox"
																			label="Combine message template(s) into a packet for each claimant"
																			name="merge_to_document_packet"
																			checked={formData.merge_to_document_packet}
																			onChange={e => {
																				let { checked } = e.target;
																				setFormData({
																					...formData,
																					merge_to_document_packet: checked,
																					document_packet_title: "",
																				});
																			}}
																		/>
																	</Form.Group>

																	{
																		formData.merge_to_document_packet && (
																			<div>
																				{/* Document packet name */}
																				<Form.Group controlId="document_packet_title">
																					<Form.Label>Document Packet Name</Form.Label>
																					<Form.Control
																						type="text"
																						placeholder="Document Packet Name"
																						name="document_packet_title"
																						value={formData.document_packet_title}
																						onChange={handleInputChange}
																						required
																					/>
																				</Form.Group>
																			</div>
																		)
																	}
																</>
															)
														}


														{
															formData.message_type === "Form Templates" && (
																<div>
																	<hr />

																	<Form.Label className="fw-bold">Form Templates</Form.Label>
																	<div>
																		<div className="mb-3">
																			<Button
																				variant="falcon-primary"
																				onClick={() => setMyFormFinderModal(true)}
																			>
																				Select Form Templates
																			</Button>

																			<MyFormFinderModal
																				modalState={myFormFinderModal}
																				setModalState={setMyFormFinderModal}
																				handleFormSelection={handleFormSelection}
																			/>
																		</div>
																		{
																			forms.length > 0 ?
																				<div>
																					<Table bordered size="md" className="bg-white mt-3">
																						<thead className="bg-200">
																							<tr>
																								<th>Type</th>
																								<th style={{ width: "50%" }}>Title</th>
																								<th>Form Type</th>
																								<th>Order</th>
																								<th></th>
																							</tr>
																						</thead>
																						<tbody>
																							{
																								forms.map((form, index) => (
																									<tr key={index}>
																										<td className="fw-bold">{form.type}</td>
																										<td>
																											<Form.Control
																												type="text"
																												name="title"
																												value={form.title}
																												onChange={e => {
																													const newForms = [...forms];
																													newForms[index].title = e.target.value;
																													setForms(newForms);
																												}}
																												required
																											/>
																										</td>
																										<td>
																											<Form.Control
																												as="select"
																												name="form_type_id"
																												value={form.form_type_id}
																												onChange={e => {
																													const newForms = [...forms];
																													newForms[index].form_type_id = e.target.value;
																													setForms(newForms);
																												}}
																												required
																											>
																												<option value={""}>--Select One--</option>
																												{Array.isArray(documentTypes) && documentTypes.map((docType, index) => (
																													<option key={index} value={docType.id}>{docType.type}</option>
																												))}
																											</Form.Control>
																										</td>
																										<td>
																											<span className="fw-bold px-0">{index + 1}</span>
																											{
																												index > 0 &&
																												<Button variant="link" onClick={e => {
																													const newForms = [...forms];
																													const temp = newForms[index];
																													newForms[index] = newForms[index - 1];
																													newForms[index - 1] = temp;
																													setForms(newForms);
																												}} className="float-end text-primary px-0 mx-1">
																													<FontAwesomeIcon icon="arrow-up" />
																												</Button>
																											}
																											{
																												index < forms.length - 1 &&
																												<Button variant="link" onClick={e => {
																													const newForms = [...forms];
																													const temp = newForms[index];
																													newForms[index] = newForms[index + 1];
																													newForms[index + 1] = temp;
																													setForms(newForms);
																												}} className="float-end text-primary px-0  mx-1">
																													<FontAwesomeIcon icon="arrow-down" />
																												</Button>
																											}
																										</td>
																										<td>
																											{
																												form?.type === 'PDF' ?
																													<Button variant="link" className="px-0 mx-1" onClick={e => { }}>
																														<FontAwesomeIcon icon="eye" />
																													</Button>
																													:
																													<Button variant="link" className="px-0 mx-1" onClick={e => { handleEditSelected(form) }}>
																														<FontAwesomeIcon icon="edit" />
																													</Button>
																											}
																											<Button variant="link" className="text-danger px-0 mx-1" onClick={e => {
																												Swal.fire({
																													title: 'Remove template?',
																													text: "Are you sure you would like to remove this template?",
																													icon: 'warning',
																													showCancelButton: true,
																													confirmButtonColor: '#d33',
																													cancelButtonColor: '#3085d6',
																													confirmButtonText: 'Remove it!'
																												}).then((result) => {
																													if (result.isConfirmed) {
																														setForms(forms.filter(
																															(f, i) => i !== index
																														))
																													}
																												})
																											}}>
																												<FontAwesomeIcon icon="trash" />
																											</Button>
																										</td>
																									</tr>
																								))
																							}
																						</tbody>
																					</Table>
																				</div>
																				: <p className="my-3">No form templates selected</p>
																		}
																	</div>
																</div>
															)
														}
													</div>
												)
											}
										</div>
									}
								</>
							)
						}




					</Col>
				</Row>
				<Button ref={submitRef} className="d-none" type="submit" variant="primary">Submit</Button>

				<PDFViewerModal
					modalState={pdfViewerModalState}
					setModalState={setPdfViewerModalState}
					selectedFileId={1}
					setSelectedFileId={() => { }}
				/>

				<EditorModal
					modalState={formEditorModal}
					setModalState={setFormEditorModal}
					content={selectedForm?.content || ""}
					handleEditorSave={handleEditorSave}
					allowNewFormSave={allowNewFormSave}
					allowPreview={true}
					setAllowNewFormSave={setAllowNewFormSave}
					defaultEditorConfig={editorConfig}
					options={editorModalOptions}
					clientDataArray={claimants || []}
				/>
			</Form >
		</>
	);
};

export default CreateCorrespondenceForm;