import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Modal, Row, Col, Alert, ListGroup, Spinner } from 'react-bootstrap';
import { useNavigate, Link } from 'react-router-dom';
import AppContext from 'context/Context';
import { assureAPI, caseAPI, documentAPI } from 'utils/api/fulcrum-api';
import { toast } from 'react-toastify';
import { removeEmptyFromObj } from 'utils/functions';
import moment from 'moment';
import ClaimantQuickFind from 'components/claimants/find-a-claimant/ClaimantQuickFind';

const CreateUploadRequest = ({
	modalState,
	setModalState = () => { },
	caseFileRequestData,
	setCaseFileRequestData,
	claimantId = null,
	claimant = {},
	refreshParent = (() => { })
}) => {
	const {
		config: { isDark }
	} = useContext(AppContext);
	let defaultDocState = {
		document_type: null,
		document_date: null,
		document_id: null,
		treatment_source: null,
		from_date: null,
		to_date: null,
		document_date: null,
		notes: null
	};
	let documentTypeOptions = [
		{ value: '0001', label: 'Medical Evidence of Record (MER)' },
		{ value: '3040', label: 'Recent Medical Treatment (Form 4631)' },
		{ value: '3045', label: 'Medications (Form 4632)' },
		{ value: '3050', label: 'Work Background (Form 4633)' },
		{ value: '3260', label: 'Critical/Dire Need Request (CRTRQST)' },
		{ value: '3265', label: 'On the Record Request (OTRRQST)' },
		{ value: '5031', label: 'Representative Brief (REPBRIEF)' },
		{ value: '3270', label: 'Amended Alleged Onset Date (AMNDAOD)' },
		{ value: '5040', label: 'Appointment of Representative (Form 1696)' },
		{ value: '1090', label: 'Disability Report-Appeals (Form 3441)' },
		{ value: '0050', label: 'Activities of Daily Living (ADL)' },
		{ value: '3005', label: 'Acknowledge Notice of Hearing (Form 504)' },
		{ value: '1103', label: 'Request for Change in Time/Place of Disability Hearing (Form 769)' },
		{ value: '3105', label: 'Response to Show Cause Notice (RSPSCN)' },
		{ value: '5030', label: 'Representative Correspondence (REPLTR)' },
		{ value: '5045', label: 'Representative Fee Agreement (FEEAGRMT)' },
		{ value: '7014', label: 'Misc Disability Development and Documentation (MDF E)' },
		{ value: '5007', label: `Claimant's Change of Address Notification (CHNGADD)` },
		{ value: '4022', label: `Representative's Withdrawal of Acceptance of Appointment (WDREP)` },
		{ value: '3157', label: 'Education Records - Medical (EDREC)' },
		{ value: '3158', label: 'Education Records - Non Medical (EDRECNMD)' },
		{ value: '3020', label: 'Waive Advance Notice of Hearing' },
		{ value: '0510', label: 'Workers Compensation/Public Disability Benefits Evidence' },
		{ value: '0351', label: 'Objections to the issues in the Notice of Hearing' },
		{ value: '0350', label: 'Third Party (Non-medical) Statements' },
		{ value: '3025', label: 'Withdraw Hearing Request' },
		{ value: '3035', label: 'Subpoena' },
		{ value: '3090', label: 'Request Medical Expert Attendance at Hearing' },
		{ value: '0352', label: 'Correspondence regarding efforts to obtain evidence' },
		{ value: '1099', label: 'COVID Hearing Agreement Form' },
		{ value: '1050', label: 'Authorization for Source to Release Information to SSA (Form 827)' }
	];
	const [claimantInfo, setClaimantInfo] = useState({});
	const [claimantDocuments, setClaimantDocuments] = useState([]);
	const [loadingClaimantDocuments, setLoadingClaimantDocuments] = useState(false);
	const [documents, setDocuments] = useState([defaultDocState]);
	const [documentTypes, setDocumentTypes] = useState(documentTypeOptions);
	const [submitting, setSubmitting] = useState(false);
	const [submitErrors, setSubmitErrors] = useState(null);
	const [claimantErrors, setClaimantErrors] = useState([]);
	const [missingClaimantInfo, setMissingClaimantInfo] = useState(false);

	// Verify claimant case information
	const verifyClaimantCaseInformation = async (claimant) => {
		let claimantVerified = false;
		let errors = [];
		try {
			// Check if basic claimant information is present
			if (!claimant?.first_name || !claimant?.last_name || !claimant?.ssn) {
				errors.push('Missing claimant information');
				setMissingClaimantInfo(true);
			}
			// Verify provided claimant has an ID
			if (!claimant?.id) {
				errors.push('No claimant ID provided');
				throw new Error('No claimant ID provided');
			}

			// Verify claimant has an active case ID
			if (!claimant?.active_case_id) {
				errors.push('No active case ID provided');
				throw new Error('No active case ID provided');
			}

			if (claimant?.claimant_active_case?.assure_case_id) {
				// Was passed with active case information
			} else {
				let response = await caseAPI.getCase(claimant.id, claimant.active_case_id);
				if (!response?.data) {
					errors.push('Unable to retrieve case information');
					throw new Error('Unable to retrieve case information');
				}

				// // Check if assure case is present
				// if (!response.data.assure_case_id) {
				// 	errors.push('Assure case not found');
				// 	throw new Error('Assure case not found');
				// }
			}

			// Claimant verified
			claimantVerified = true;
		} catch (error) {
			console.error(error);
			claimantVerified = false;
		}

		return { claimantVerified, errors };
	};

	// Handle Close
	const handleClose = () => {
		setClaimantDocuments([]);
		setDocuments([defaultDocState]);
		setClaimantInfo({});
		setSubmitErrors(null);
		setMissingClaimantInfo(false);
		setModalState(!modalState);
		setSubmitting(false);
		setLoadingClaimantDocuments(false);
		setClaimantErrors([]);
	};

	// Get claimant documents
	const getClaimantDocuments = async (claimantId) => {
		setLoadingClaimantDocuments(true);
		try {
			let res = await documentAPI.getClaimantDocuments(claimantId);
			if (!res?.data) {
				throw new Error('No data returned');
			}
			setClaimantDocuments(res.data);
		} catch (err) {
			toast.error("Unable to pull claimant documents.", { theme: 'colored' });
		}
		setLoadingClaimantDocuments(false);
	};

	// Add document
	const addDocument = (document, claimant_document_id) => {
		setDocuments([...documents, {
			...defaultDocState,
			// document_id: claimant_document_id
		}]);
	};

	// Remove document
	const removeDocument = (index) => {
		let newDocuments = [...documents];
		newDocuments.splice(index, 1);
		setDocuments(newDocuments);
	};

	// Form Validation
	const validateForm = (doc) => {
		let valid = true;
		let errors = [];


		if (!doc?.document_id) {
			valid = false;
			errors.push('Please select a document.');
		} else {
			// Check that document is a valid extension type (.wpd, .doc, .docx, .mdi, .txt, .rtf, .xls, .xlsx, .pdf, .tiff, .tif) and is less than 200mb
			let selectedDocument = claimantDocuments.find(d => d.id === doc.document_id);
			if (!selectedDocument) {
				valid = false;
				errors.push('Invalid document selected.');
			} else {
				// Validate file name
				if (!selectedDocument?.file?.file_name) {
					valid = false;
					errors.push('File name not found.');
				}
				// Validate file size
				if (selectedDocument.file_size > 200000000) {
					valid = false;
					errors.push('Document is too large.');
				}
				// Validate file extension
				let fileType = selectedDocument.file.file_name.split('.').pop();
				console.log(fileType);
				if (!["wpd", "doc", "docx", "mdi", "txt", "rtf", "xls", "xlsx", "pdf", "tiff", "tif"].includes(fileType.toLowerCase())) {
					valid = false;
					errors.push('Invalid file type.');
				}
			}
		}
		// Validate document type
		if (!doc.document_type) {
			valid = false;
			errors.push('Document Type is required');
		}
		// Validate treatment source
		if (!doc.treatment_source && ['0001', '3157'].includes(doc.document_type)) {
			valid = false;
			errors.push('Treatment Source is required');
		}
		// Validate treatment source length
		if (doc?.treatment_source && doc.treatment_source.length > 57) {
			valid = false;
			errors.push('Treatment Source must be 57 characters or less');
		}
		// Validate from date
		if ((!doc.from_date || !doc.from_date instanceof Date) && ['0001', '3157'].includes(doc.document_type)) {
			valid = false;
			errors.push('From Date is required');
		}
		// Validate to date
		if ((!doc.to_date || !doc.to_date instanceof Date) && ['0001', '3157'].includes(doc.document_type)) {
			valid = false;
			errors.push('To Date is required');
		}
		// Validate from date is before to date
		if (['0001', '3157'].includes(doc.document_type) && (doc.from_date && doc.to_date && moment(doc.from_date).isAfter(doc.to_date))) {
			valid = false;
			errors.push('From Date cannot be after To Date');
		}
		// Validate document date
		if ((!doc.document_date || !doc.document_date instanceof Date) && !['0001', '3157'].includes(doc.document_type)) {
			valid = false;
			errors.push('Document Date is required');
		}
		// Validate note
		if (doc?.note && doc.note.length > 40) {
			valid = false;
			errors.push('Note must be less than 40 characters');
		}

		return {
			...doc,
			valid,
			errors
		};
	};



	// Submit
	const handleSubmit = async e => {
		e.preventDefault();
		setSubmitting(true);

		try {
			if (!Array.isArray(documents) || !documents.length) {
				throw new Error('No documents to submit');
			}
			// Loop through each document and perform validation
			let validationPassed = true;
			let adjustedDocuments = [];
			for (let i = 0; i < documents.length; i++) {
				let doc = await validateForm(documents[i]);
				if (!doc.valid) {
					validationPassed = false;
				}

				console.log(doc);

				adjustedDocuments.push(doc);
			}
			setDocuments(adjustedDocuments);

			if (!validationPassed) {
				throw new Error('Validation failed');
			}

			let result = await assureAPI.submitAtlaswareEREUploadRequest(claimantInfo.id, claimantInfo?.active_case_id, documents);
			if (!result?.message) {
				setSubmitErrors(JSON.stringify({ reason: result?.response || result?.error || 'Unknown error' }));
				throw new Error('Unable to submit the upload request.');
			}

			toast.success(result.message, { theme: 'colored' });
			refreshParent();
			handleClose();

			// let document = documents[i];
			// let formData = new FormData();
			// formData.append('claimant_id', claimantInfo.claimant_id);
			// formData.append('document_type', document.document_type);
			// formData.append('document_id', document.document_id.document_id);
			// formData.append('treatment_source', document.treatment_source);
			// formData.append('from_date', document.from_date);
			// formData.append('to_date', document.to_date);
			// formData.append('document', document.document_id.file);
			// let res = await documentAPI.addDocument(formData);
			// if (!res?.data) {
			// 	throw new Error('No data returned');
			// }
		} catch (error) {
			console.log(error)
			toast.error(error, {
				theme: 'colored'
			});
		}
		setSubmitting(false);
	};

	// Handle Claimant Selection
	const handleClaimantSelection = async (claimant) => {
		console.log(claimant);
		let claimantName = claimant?.first_name ? `${claimant?.first_name} ${claimant?.last_name}` : 'Unknown claimant'
		let result = await verifyClaimantCaseInformation(claimant);
		if (result.claimantVerified) {
			getClaimantDocuments(claimant.id);
			setClaimantInfo(claimant);
			setClaimantErrors([]);
		} else {
			console.log(result);
			setClaimantInfo({});
			setClaimantErrors([`Unable to upload for ${claimantName}`, ...result.errors]);
		}
	};

	// handle show
	const handleShow = () => {
		if (claimantId) {
			getClaimantActiveCaseData(claimantId);
		}

		if (claimant?.id) {
			handleClaimantSelection(claimant);
		}
	};

	return (
		<>
			<Modal
				show={modalState}
				onHide={handleClose}
				onShow={handleShow}
				contentClassName="border"
				size='lg'
				backdrop="static"
			>
				<Form onSubmit={handleSubmit}>
					<Modal.Header
						closeButton
						closeVariant={isDark ? 'white' : undefined}
						className="bg-light px-card border-bottom-0"
					>
						<Modal.Title as="h5">Upload files to the ERE</Modal.Title>
					</Modal.Header>
					<Modal.Body className="p-card">
						{
							!claimantInfo?.id ?
								<div>
									<p>Find a claimant to submit the case file request for.</p>
									<ClaimantQuickFind
										handleClaimantSelection={handleClaimantSelection}
									/>


									{
										claimantErrors.length ?
											<Alert variant="danger" className="mt-3">
												<div className="mt-3">
													<h6 className="text-danger">Errors</h6>
													<ul className="list-unstyled">
														{
															claimantErrors.map((error, index) => {
																return (
																	<li key={index} className="text-danger">{error}</li>
																)
															})
														}
													</ul>
												</div>
											</Alert>
											: null
									}
								</div>
								:
								<>

									{
										!missingClaimantInfo
											?
											<>
												<h5 className="mb-3">Selected claimant: {claimantInfo?.first_name || ""} {claimantInfo?.last_name || ""}</h5>
												<Alert variant="info">
													<p>Select one or more documents to submit to Atlasware to upload to the ERE.</p>
													<span>File Restrictions</span>
													<ul>
														<li>File size must be less than 200MB</li>
														<li>Only wpd, doc, docx, jpg, bmp, txt, rtf, xls, xlsx, pdf, tiff, and tif file formats are accepted.</li>
														<li>Please do not upload password-protected files because they cannot be processed.</li>
													</ul>
												</Alert>

												{
													submitErrors ?
														<Alert variant="danger" className="mt-3">
															<div className="mt-3">
																<h6 className="text-danger">Errors</h6>
																{submitErrors}
															</div>
														</Alert>
														: null
												}

												{
													Array.isArray(claimantDocuments) && claimantDocuments.length > 0 ?
														<>
															{
																documents.map((document, index) => (
																	<div className="my-3 border p-3 bg-100">
																		<div className="mb-3">
																			<span className="fw-bold">File {index + 1}</span>
																			<Button variant="falcon-danger" className="float-end" onClick={() => removeDocument(index)}>Remove</Button>
																		</div>

																		{
																			document?.errors && document.errors.length > 0 &&
																			<Alert variant="danger">
																				<ul>
																					{
																						document.errors.map((error, index) => (
																							<li key={index}>{error}</li>
																						))
																					}
																				</ul>
																			</Alert>
																		}
																		<Form.Group controlId="document_id">
																			<Form.Label>Select a Document</Form.Label>
																			<Form.Control
																				as="select"
																				name="document_id"
																				required
																				value={document.document_id}
																				onChange={e => {
																					let newDocuments = [...documents];
																					newDocuments[index].document_id = parseInt(e.target.value);
																					setDocuments(newDocuments);
																				}}
																			>
																				<option value="">--Select a Document--</option>
																				{
																					claimantDocuments.map((document, index) => (
																						<option key={index} value={document.id}>{document.title} - {document?.file?.file_type} ({document?.file?.file_size})</option>
																					))
																				}
																			</Form.Control>
																		</Form.Group>
																		{
																			document.document_id &&
																			<div>
																				<Form.Group controlId="document_type" className="mt-3">
																					<Form.Label>Document Type</Form.Label>
																					<Form.Control
																						as="select"
																						name="document_type"
																						required
																						value={document.document_type}
																						onChange={e => {
																							let newDocuments = [...documents];
																							newDocuments[index].document_type = e.target.value;
																							setDocuments(newDocuments);
																						}}
																					>
																						<option value="">--Select a Document Type--</option>
																						{
																							documentTypes.map((documentType, index) => (
																								<option key={index} value={documentType.value}>{documentType.label}</option>
																							))
																						}
																					</Form.Control>
																				</Form.Group>

																				{
																					['0001', '3157'].includes(document.document_type)
																						?
																						<div>
																							<Form.Group controlId="treatment_source" className="mt-3">
																								<Form.Label>Treatment Source</Form.Label>
																								<Form.Control
																									type="text"
																									name="treatment_source"
																									required
																									value={document.treatment_source}
																									onChange={e => {
																										let newDocuments = [...documents];
																										// remove all non-alphanumeric characters
																										newDocuments[index].treatment_source = e.target.value.replace(/[^a-zA-Z0-9 ]/g, "");
																										if (e.target.value.length <= 57) {
																											setDocuments(newDocuments);
																										}
																									}}
																								/>
																							</Form.Group>
																							<Row>
																								<Col>
																									<Form.Group controlId="from_date" className="mt-3">
																										<Form.Label>From Date</Form.Label>
																										<Form.Control
																											type="date"
																											name="from_date"
																											required
																											value={document.from_date}
																											onChange={e => {
																												let newDocuments = [...documents];
																												newDocuments[index].from_date = e.target.value;
																												setDocuments(newDocuments);
																											}}
																										/>
																									</Form.Group>
																								</Col>
																								<Col>
																									<Form.Group controlId="to_date" className="mt-3">
																										<Form.Label>To Date</Form.Label>
																										<Form.Control
																											type="date"
																											name="to_date"
																											required
																											value={document.to_date}
																											onChange={e => {
																												let newDocuments = [...documents];
																												newDocuments[index].to_date = e.target.value;
																												setDocuments(newDocuments);
																											}}
																										/>
																									</Form.Group>
																								</Col>
																							</Row>
																						</div>
																						:
																						<div>
																							<Form.Group controlId="document_date" className="mt-3">
																								<Form.Label>Document Date</Form.Label>
																								<Form.Control
																									type="date"
																									name="document_date"
																									required
																									value={document.document_date}
																									onChange={e => {
																										let newDocuments = [...documents];
																										newDocuments[index].document_date = e.target.value;
																										setDocuments(newDocuments);
																									}}
																								/>
																							</Form.Group>
																						</div>
																				}

																				{
																					document?.document_type &&
																					<Form.Group controlId="notes" className="mt-3">
																						<Form.Label>
																							Notes about the document. 40 character limit, only accepts a-Z, 0-9 and spaces.
																						</Form.Label>
																						<Form.Control
																							type="text"
																							name="notes"
																							value={document.notes}
																							onChange={e => {
																								let newDocuments = [...documents];
																								// remove all non-alphanumeric characters
																								newDocuments[index].notes = e.target.value.replace(/[^a-zA-Z0-9 ]/g, "");
																								if (e.target.value.length <= 40) {
																									setDocuments(newDocuments);
																								}
																							}}
																						/>
																					</Form.Group>
																				}

																			</div>
																		}



																	</div>
																))
															}


															<Button variant="link" onClick={() => { addDocument() }}>+ Add Document</Button>
														</>
														:
														(
															loadingClaimantDocuments
																?

																<div className="text-center">
																	< Spinner animation="border" role="status">
																		<span className="sr-only">Loading...</span>
																	</Spinner>
																</div>
																:
																<Alert variant="danger">
																	No documents found for this claimant. To submit a upload request to the ERE, you must first upload a document to the claimant record.
																</Alert>
														)
												}
											</>
											:
											<div>
												<Alert variant="danger" className="mt-3">
													<div className="mt-3">
														<ul className="list-unstyled">
															<li className="text-danger">
																<h5 className="text-danger">Selected Claimant: <Link to={claimantInfo?.id ? `/claimant/profile/${claimantInfo.id}` : '#'}>{claimantInfo?.first_name ? `${claimantInfo.first_name} ${claimantInfo?.last_name || ""}` : 'Unknow Claimant'}</Link></h5>
																<p>Missing claimant information needed to submit a document upload request to the ERE.
																	Please provide the missing data and try again.</p>
																<ul>
																	{
																		!claimantInfo?.first_name && <li>Missing First Name</li>
																	}
																	{
																		!claimantInfo?.last_name && <li>Missing Last Name</li>
																	}
																	{
																		!claimantInfo?.ssn && <li>Missing Social Security Number</li>
																	}
																</ul>
															</li>
														</ul>
													</div>
												</Alert>
											</div>
									}
								</>
						}


					</Modal.Body>
					<Modal.Footer className="bg-light px-card border-top-0">
						<Button variant="falcon-secondary" onClick={handleClose}>Close</Button>
						{' '}
						{
							(claimantInfo?.id && !loadingClaimantDocuments && documents.length > 0 && !missingClaimantInfo) &&
							<Button type="submit" variant="primary" disabled={submitting}>
								{submitting ? 'Submitting...' : 'Submit'}
							</Button>
						}
					</Modal.Footer>
				</Form>
			</Modal >


		</>
	);
};

export default CreateUploadRequest;