import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Card, Row, Col, Button } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import moment from 'moment';
import { claimantAPI } from 'utils/api/fulcrum-api';
import { removeEmptyFromObj } from 'utils/functions';
import Flex from 'components/common/Flex';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import CustomAdvanceTable from 'components/common/advance-table/CustomAdvanceTable';
import CustomAdvanceTablePagination from 'components/common/advance-table/CustomAdvanceTablePagination';
import FindAClaimantModal from 'components/claimants/find-a-claimant/FindAClaimantModal';
import InboundLeadMetrics from 'components/metrics/claimants/InboundLeadMetrics';
import TaskMetrics from 'components/metrics/task/TaskMetrics';
import ClaimantFolderMetrics from 'components/metrics/claimants/ClaimantFolderMetrics';
import ClaimantTableHeader from './ClaimantTableHeader';
import CreateClaimantForm from './CreateClaimantForm';
import ClaimantTableFilters from './ClaimantTableFilters';

const defaultColumns = [
	{
		accessor: 'name',
		Header: 'Name',
		headerProps: { className: 'pe-1' },
		cellProps: {
			className: 'py-2'
		},
		Cell: rowData => {
			const { name, id } = rowData.row.original;
			return <Link to={`/claimant/profile/${id}`}>{name}</Link>;
		}
	}
];

const ClaimantTable = ({ account_id, tableHeaderLabel = "Claimants", columns = defaultColumns, defaultSearchParams = {}, requiredSearchParams = {}, siteAdminArea = false, showMetrics = true } = {}) => {
	const navigate = useNavigate();
	const [createClaimantModal, setCreateClaimantModal] = useState(false);
	const [showFindAClaimantModal, setShowFindAClaimantModal] = useState(false);

	const [claimantData, setClaimantData] = useState([]);
	const [Pages, setPages] = useState([1]);
	const [activePage, setActivePage] = useState(1);
	const [perPage, setPerPage] = useState(10);
	const [claimantDataCount, setClaimantDataCount] = useState(0);

	const [searching, setSearching] = useState(true);
	const [lastQuery, setLastQuery] = useState({});
	const [searchParams, setSearchParams] = useState({ ...defaultSearchParams, ...requiredSearchParams });
	let defaultSort = ["created_at", "ASC"];
	const [appliedSortOrders, setAppliedSortOrders] = useState([defaultSort]);

	const createClaimant = async (newValues = {}) => {
		try {
			let newClaimantData = await claimantAPI.createClaimant(newValues);
			if (newClaimantData?.response?.error === 'Claimant already exists for this account.') {
				toast.error('Claimant already exists for this account.', {
					theme: 'colored'
				});
				navigate(`/claimant/profile/${newClaimantData.response.claimant.id}`);
				return;
			}
			if (!newClaimantData?.data?.id) {
				throw new Error('Unable to create the new claimant.');
			}

			setClaimantData([...claimantData, newClaimantData.data]); // Set claimant data in state
			setCreateClaimantModal(false); // Close modal
			refreshLastQuery(); // If a callback function was passed, invoke it
			toast.success('New claimant created!', {
				theme: 'colored'
			});
			navigate(`/claimant/profile/${newClaimantData.data.id}`);
		} catch (error) {
			alert(error);
			toast.error(error, {
				theme: 'colored'
			});
		}
	};

	const handleBulkActions = (actionObj = {}, selectedRows = [], selectedIds = []) => {
		let claimantItem = {};
		if (Array.isArray(selectedRows) && selectedRows.length === 1) {
			claimantItem = selectedRows[0];
		}
		let { bulk_action } = actionObj || {};
		switch (bulk_action) {
			case 'clear filters':
				clearSearchParams();
				break;
			case 'find-a-claimant':
				setShowFindAClaimantModal(true);
				break;
			case 'new claimant':
				setCreateClaimantModal(true);
				break;
			default:
				console.log("@todo handleBulkActions", actionObj, "selectedRows", selectedRows, "selectedIds", selectedIds, "claimantItem", claimantItem);
				refreshLastQuery();
				break;
		}
	};

	const formatData = (data = []) => {
		let adjustedData = data.map(c => {
			return {
				...c,
				business_name: c?.account?.business_name || '',
				last_called: c?.last_called ? moment(c.last_called).format('MM/DD/YYYY hh:mm A') : '',
				name: `${c.first_name} ${c.last_name}`,
				created_at: moment(c.createdAt).format('MM/DD/YYYY'),
				office_with_jurisdiction: c?.claimant_active_case?.office_with_jurisdiction || '',
				primary_representative: c?.claimant_active_case?.primary_representative?.first_name ? `${c.claimant_active_case.primary_representative.first_name} ${c.claimant_active_case.primary_representative.last_name}` : '',
			}
		});
		setClaimantData(adjustedData);
	};

	const headerClickFn = (id, previousSortBy) => {
		if (!id) {
			return;
		}
		let foundIndex = appliedSortOrders.findIndex(s => s[0] === id);
		let sortBy = !previousSortBy ? "ASC" : previousSortBy === "asc" ? "DESC" : '';

		if (!sortBy) { // Not Sorted so remove clicked sort item 
			if (foundIndex > -1) {
				setAppliedSortOrders((prev) => {
					let newSortOrders = [...prev];
					newSortOrders.splice(foundIndex, 1); // remove clicked sort item
					return newSortOrders;
				});
			}
		} else { // Sort by Ascending or Descending 
			let newSort = [id, sortBy];
			if (foundIndex > -1) { // replaces existing 
				setAppliedSortOrders((prev) => {
					let newSortOrders = [...prev];
					newSortOrders[foundIndex] = newSort;
					return newSortOrders;
				});
			} else { // inserts 
				setAppliedSortOrders((prev) => [...prev, newSort]);
			}
		}
	};

	const claimantSearchHandler = async (params = {}, saveLastQuery = false) => {
		setSearching(true);
		try {
			params = removeEmptyFromObj(params);
			params.page = activePage;
			params.limit = perPage;
			params.navSize = 4; // How many buttons to show for navigation
			params.order = appliedSortOrders; // Example [['last_last', 'asc'],['first_name','asc'], ['id','asc']]
			let { error, response, data } = await claimantAPI.searchClaimants(params);
			if (error) {
				if (typeof response?.error === 'string') {
					throw new Error(`${error} - ${response.error}`);
				} else if (typeof response === 'string') {
					throw new Error(`${error} - ${response}`);
				}
				throw new Error(`${error} - There was an error searching claimants.`);
			}

			let { count = 0, rows = [], pages = [] } = data || {}; // data = { count = 0, rows = [], limit, pageCount, page, pages = [1,2,3,10] } 
			if (!rows || (Array.isArray(rows) && rows.length === 0)) {
				formatData([]);
				setActivePage(1); // Make sure we don't get stuck loading nothing
			} else {
				formatData(rows);
				setPages(pages);
			}

			formatData(rows);
			setClaimantDataCount(count);
			setPages(pages);
			if (saveLastQuery) {
				setLastQuery({ ...params, ...requiredSearchParams });
			}
		} catch (error) {
			console.log("Error searching claimant data", error);
			setClaimantData([]);
			setClaimantDataCount(0);
			setActivePage(1);
		}
		setSearching(false);
	};

	// New Search
	const newSearch = async (params = {}) => {
		claimantSearchHandler({ ...params, ...requiredSearchParams }, true);
	};

	const refreshLastQuery = async () => {
		await claimantSearchHandler(lastQuery, false);
	};

	// Render table metrics
	const renderMetrics = (type) => {
		switch (type) {
			case 'Inbound Leads':
			case 'Inbound':
				return <Row><Col md={5} xxl={5}><InboundLeadMetrics siteAdminArea={siteAdminArea} setSearchParams={setSearchParams} radius={['100%', '87%']} /></Col></Row>;
			case 'Cases':
			case 'ClaimantFolders':
				return <Col md={5} xxl={5}><ClaimantFolderMetrics siteAdminArea={siteAdminArea} setSearchParams={setSearchParams} radius={['100%', '87%']} /></Col>;
			default:
				return null;
		}
	};

	// Clear search params and reset to default
	const clearSearchParams = () => {
		if (JSON.stringify(searchParams) !== JSON.stringify({ ...defaultSearchParams, ...requiredSearchParams })) {
			setSearchParams({});
		}
		if (JSON.stringify(appliedSortOrders) !== JSON.stringify([defaultSort])) {
			setAppliedSortOrders([defaultSort]);
		}
		if (activePage !== 1) {
			setActivePage(1);
		}
		if (perPage !== 10) {
			setPerPage(10);
		}
	};

	useEffect(() => {
		newSearch(searchParams)
	}, [searchParams, activePage, perPage, appliedSortOrders]);

	return (
		<div>
			<Card className="bg-100 shadow-none border mb-3">
				<Card.Body>
					<Row className=" justify-content-between pr-0">
						<Col sm="auto" className="pr-0 align-middle">
							<Flex alignItems="center">
								<h4 className="align-middle fw-bold mb-0 h-inline">
									<span className="fw-medium">{tableHeaderLabel}</span>
								</h4>
							</Flex>
						</Col>
						{ (tableHeaderLabel === 'Inbound' || tableHeaderLabel === 'Inbound Leads') &&
						<Col sm="auto" className="pr-0">
							<Flex alignItems="center">
								<div className="page-header-actions float-end">
									<Button className="btn btn-primary float-end" onClick={() => setCreateClaimantModal(true)}>+ Create New Claimant</Button>
								</div>
							</Flex>
						</Col>
						}
					</Row>
				</Card.Body>
			</Card>

			{showMetrics && (
				<Row className="g-3 mb-3">
					<Col>
						{renderMetrics(tableHeaderLabel)}
					</Col>
				</Row>
			)}

			<AdvanceTableWrapper
				columns={columns}
				data={claimantData}
				selection
				sortable
				pagination
				perPage={perPage}
				setPerPage={setPerPage}
			>
				<Card className="mb-3">
					<Card.Header>
						<ClaimantTableHeader
							table
							label={tableHeaderLabel}
							handleBulkActions={handleBulkActions}
							searchParams={searchParams}
							showClearFilters={JSON.stringify(searchParams) !== JSON.stringify({ ...defaultSearchParams, ...requiredSearchParams })}
						/>
						<ClaimantTableFilters
							searchParams={searchParams}
							siteAdminArea={siteAdminArea}
							setSearchParams={setSearchParams}
							requiredSearchParams={requiredSearchParams}
							exludedFilters={
								['Inbound Leads'].includes(tableHeaderLabel)
									? [...(!siteAdminArea ? ['account_id', 'business_name'] : [])]
									: [...(!siteAdminArea ? ['account_id', 'business_name'] : []), 'established_contact', 'reviewed', 'screened']
							}
						/>
					</Card.Header>
					<Card.Body className="p-0">
						{searching ? <p>Searching claimants....</p> :
							<CustomAdvanceTable
								table
								headerClickFn={headerClickFn}
								appliedSortOrders={appliedSortOrders}
								headerClassName="bg-200 text-900 text-nowrap align-middle"
								rowClassName="align-middle white-space-nowrap"
								tableProps={{
									size: 'sm',
									striped: true,
									className: 'fs--1 mb-0'
								}}
							/>
						}
					</Card.Body>
					<Card.Footer>
						<CustomAdvanceTablePagination
							table
							Pages={Pages}
							activePage={activePage}
							setActivePage={setActivePage}
							totalRecords={claimantDataCount}
						/>
					</Card.Footer>
				</Card>
			</AdvanceTableWrapper>
			<CreateClaimantForm
				modalState={createClaimantModal}
				setModalState={setCreateClaimantModal}
				refreshParent={refreshLastQuery}
				account_id={account_id}
				siteAdminArea={siteAdminArea}
			/>
			<FindAClaimantModal
				modalState={showFindAClaimantModal}
				setModalState={setShowFindAClaimantModal}
				searchParams={searchParams}
				setSearchParams={setSearchParams}
				requiredSearchParams={requiredSearchParams}
				siteAdminArea={siteAdminArea}
			/>
		</div>
	);
};

ClaimantTable.propTypes = {
	account_id: PropTypes.string,
	tableHeaderLabel: PropTypes.string,
	columns: PropTypes.array,
	defaultSearchParams: PropTypes.object,
	requiredSearchParams: PropTypes.object,
	siteAdminArea: PropTypes.bool,
	showMetrics: PropTypes.bool,
};

export default ClaimantTable;