import React from 'react';
import PropTypes from 'prop-types';
import WizardInput from '../WizardInput';

// const CreateCorrespondenceForm = ({ register, errors, watch, updateUserError, setUpdateUserError, handleSubmitForm }) => {
const CreateCorrespondenceForm = ({ register, errors }) => {

	// useEffect(() => {
	// }, []);

	return (
		<>

			<h5>Mode of transmission</h5>
			<p>Select a mode that should be used to transmit the correspondence.</p>

			<WizardInput
				type="select"
				label="Mode of Transmission"
				name="mode_of_transmission"
				placeholder="Select mode of transmission..."
				errors={errors}
				options={['EMAIL', 'SMS', 'FAX', 'MAIL']}
				formGroupProps={{
					className: 'mb-3'
				}}
				formControlProps={{
					...register('mode_of_transmission', {
						defaultValue: 'EMAIL',
						required: "Mode of transmission is required"
					})
				}}
			/>
		</>
	);
};

CreateCorrespondenceForm.propTypes = {
	register: PropTypes.func.isRequired,
	errors: PropTypes.object,
	watch: PropTypes.func
};

export default CreateCorrespondenceForm;