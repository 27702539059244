import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import JoditEditor from "jodit-pro-react";
import { toast } from 'react-toastify';
import { accountAPI } from 'utils/api/fulcrum-api';

const SignatureBlock = ({ account = {} }) => {
	const editor = useRef(null);
	const [formData, setFormData] = useState({
		signature_block: account?.signature_block || ''
	});
	const [previousFormData, setPreviousFormData] = useState(formData);

	// const handleChange = e => {
	// 	let { name, value } = e.target;

	// 	setFormData({
	// 		...formData,
	// 		[name]: value
	// 	});
	// };

	const saveSignatureBlcok = async data => {
		try {
			if (data.signature_block === previousFormData.signature_block) {
				return;
			}
			let result = await accountAPI.updateAccount(account.id, data);
			if (!result?.data) {
				throw new Error('No account data returned');
			}

			setFormData({ ...formData, ...data });
			setPreviousFormData({ ...formData, ...data });

			toast.success('Signature block saved!', {
				theme: 'colored'
			});
		} catch (error) {
			console.log(error);
			toast.error(error, {
				theme: 'colored'
			});
		}
	};


	return (
		<div>
			<h5>Signature Block</h5>
			<p>Manage your form signature.</p>
			<div className="mt-3">
				<JoditEditor
					ref={editor}
					value={formData.signature_block}
					rows={10}
					config={{
						readonly: false,
						buttons: [
							"bold",
							"strikethrough",
							"underline",
							"italic",
							'ul',
							'ol',
							'outdent',
							'indent',
							'font',
							'fontsize',
							'brush',
							'paragraph',
							'lineHeight',
							'image',
							'table',
							'link',
							'align',
							'undo',
							'redo',
							'hr',
							'eraser',
							'symbol',
							'copyformat',
							// 'fullsize',
							'print'
						],
					}}
					tabIndex={1} // tabIndex of textarea
					onBlur={newContent => { let newData = { ...formData, signature_block: newContent }; saveSignatureBlcok(newData) }} // preferred to use only this option to update the content for performance reasons
				/>
			</div>
		</div>
	);
};

SignatureBlock.propTypes = {
	account: PropTypes.object
}

export default SignatureBlock;