import React, { useContext, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import DocumentGeneratorForm from './DocumentGeneratorForm';
import AppContext from 'context/Context';


const DocumentGeneratorModal = ({
	modalState,
	setModalState,
	setMyFormData,
}) => {

	const {
		config: { isDark }
	} = useContext(AppContext);

	// Handle Show
	const handleShow = () => {

	};

	const handleClose = () => {
		setModalState(false);
	};

	return (
		<Modal
			show={modalState}
			onHide={handleClose}
			onShow={handleShow}
			contentClassName="border"
			size="xl"
			backdrop="static"
		>
			<Modal.Header
				closeButton
				closeVariant={isDark ? 'white' : undefined}
				className="bg-light px-card border-bottom-0"
			>
				<Modal.Title as="h5">Document Generator</Modal.Title>
			</Modal.Header>
			<Modal.Body className="p-card">
				<DocumentGeneratorForm

				/>
			</Modal.Body>
		</Modal>
	);
};

export default DocumentGeneratorModal;