import React, { useEffect, useState } from 'react';
import { Card, Row, Col, Button, Form, Dropdown } from 'react-bootstrap';
import CardDropdown from 'components/common/CardDropdown';
import Flex from 'components/common/Flex';
import { Link } from 'react-router-dom';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import DocumentTableHeader from './DocumentTableHeader';
import CustomAdvanceTable from 'components/common/advance-table/CustomAdvanceTable';
import CustomAdvanceTablePagination from 'components/common/advance-table/CustomAdvanceTablePagination';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import { documentAPI } from 'utils/api/fulcrum-api';
import { removeEmptyFromObj, sleep } from 'utils/functions';
import moment from 'moment';
import Auth from 'utils/auth';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import UploadDocumentForm from './UploadDocumentForm';
import PDFViewerModal from 'components/app/pdfjs-viewer/PDFViewerModal';

const defaultColumns = [
	{
		accessor: 'document_type',
		Header: 'Document Title',
		headerProps: { className: 'pe-1' },
		cellProps: {
			className: 'py-2'
		}
	},
	{
		accessor: 'title',
		Header: 'Title',
		headerProps: { className: 'pe-1' },
		cellProps: {
			className: 'py-2'
		}
	},
	{
		accessor: 'packet_title',
		Header: 'Packet',
		headerProps: { className: 'pe-1' },
		cellProps: {
			className: 'py-2'
		}
	},
	{
		accessor: 'claimant_name',
		Header: 'Claimant',
		headerProps: { className: 'pe-1' },
		cellProps: {
			className: 'py-2'
		}
	},
	{
		accessor: 'menu',
		Header: '',
		headerProps: { className: 'pe-1' },
		cellProps: {
			className: 'py-2'
		}
	}
];

const DocumentTable = ({ account_id = Auth.getProfile()?.data?.account_id || null, columns = defaultColumns, tableHeaderLabel = "Documents", defaultSearchParams = {}, requiredSearchParams = {}, defaultClaimants = [], refreshParent = () => { }, siteAdminArea = false }) => {
	// const [createClaimantModal, setCreateClaimantModal] = useState(false);
	const [documentData, setDocumentData] = useState([]);
	const [documentCount, setDocumentCount] = useState(0);
	const [Pages, setPages] = useState([1]);
	const [activePage, setActivePage] = useState(1);
	const [perPage, setPerPage] = useState(10);
	const [documentDetailsModalState, setDocumentDetailsModalState] = useState(false);
	const [selectedDocument, setSelectedDocument] = useState({});
	const [documentCreateModal, setDocumentCreateModal] = useState(false);
	const [searching, setSearching] = useState(true);
	const [documentTypes, setDocumentTypes] = useState([]);
	const [documentStatuses, setDocumentStatuses] = useState([]);
	const [lastQuery, setLastQuery] = useState({});
	const [searchParams, setSearchParams] = useState({ ...defaultSearchParams, ...requiredSearchParams });
	let defaultSort = ["created_at", "ASC"];
	const [appliedSortOrders, setAppliedSortOrders] = useState([defaultSort]);
	const [pdfViewerModalState, setPdfViewerModalState] = useState(false);
	const [selectedFileId, setSelectedFileId] = useState(null);



	const headerClickFn = (id, previousSortBy) => {
		if (!id) {
			return;
		}
		let foundIndex = appliedSortOrders.findIndex(s => s[0] === id);
		let sortBy = !previousSortBy ? "ASC" : previousSortBy === "asc" ? "DESC" : '';

		if (!sortBy) { // Not Sorted so remove clicked sort item 
			if (foundIndex > -1) {
				setAppliedSortOrders((prev) => {
					let newSortOrders = [...prev];
					newSortOrders.splice(foundIndex, 1); // remove clicked sort item
					return newSortOrders;
				});
			}
		} else { // Sort by Ascending or Descending 
			let newSort = [id, sortBy];
			if (foundIndex > -1) { // replaces existing 
				setAppliedSortOrders((prev) => {
					let newSortOrders = [...prev];
					newSortOrders[foundIndex] = newSort;
					return newSortOrders;
				});
			} else { // inserts 
				setAppliedSortOrders((prev) => [...prev, newSort]);
			}
		}
	};

	const formatData = (data = []) => {

		let adjustedData = data.map(c => {
			return {
				...c,
				document_type: c.document_type.type === "Other" ? `${c.document_type.type} - ${c.title}` : c.document_type.type,
				created_at: moment(c.createdAt).format('MM/DD/YYYY hh:mm A'),
				packet_title: c?.document_packet?.title ? c.document_packet.title : '',
				claimant_name: c?.claimant ? <Link to={`/claimant/profile/${c.claimant.id}`}>{`${c.claimant.first_name} ${c.claimant.last_name}`}</Link> : '',
				view: <span className={`link-primary cursor-hover`} onClick={() => handleDocumentSelection(c)}>View</span>,
				business_name: c?.account?.business_name ? c.account.business_name : '',
				menu: (<>
					<div className="d-flex justify-content-between float-end">
						<CardDropdown
							children={(
								<div className="py-2">
									<Dropdown.Item onClick={() => { downloadDocument(c?.account_id || null, c) }} className="">Download</Dropdown.Item>
									<Dropdown.Item onClick={async () => { setSelectedFileId(c.file_id); await sleep(1000); setPdfViewerModalState(true); }} className="">View</Dropdown.Item>
									<Dropdown.Divider />
									<Dropdown.Item onClick={() => { deleteDocument(c?.account_id || null, c.id) }} className="text-danger">Delete</Dropdown.Item>
								</div>
							)}
						/>
					</div>
				</>)
			}
		});
		setDocumentData(adjustedData);
	};

	// Download Document
	const downloadDocument = async (accountId, document) => {
		try {
			await documentAPI.downloadDocuments(accountId, [document.file_id], (result) => {
				if (result?.error) {
					throw new Error(result.error);
				}
			});
		} catch (error) {
			toast.error("Unable to download document", { theme: 'colored' });
			console.log(error);
		}
	};

	// Delete Document
	const deleteDocument = async (accountId, id) => {
		Swal.fire({
			title: 'Are you sure?',
			text: "You won't be able to revert this!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Yes, delete it!'
		}).then(async (result) => {
			if (result.isConfirmed) {
				try {
					let result = await documentAPI.deleteDocument(accountId, id);
					if (!result?.message) { throw new Error(result.error); }
					toast.warning('Document deleted', { theme: 'colored' });
				} catch (error) {
					toast.error("Unable to delete document", { theme: 'colored' });
					console.log(error);
				}
			}
		});
	};

	const documentSearchHandler = async (params = {}, saveLastQuery = false) => {
		try {
			params = removeEmptyFromObj(params);
			params.page = activePage;
			params.limit = perPage;
			params.navSize = 4; // How many buttons to show for navigation
			params.order = appliedSortOrders; // Example [['last_last', 'asc'],['first_name','asc'], ['id','asc']]
			let { data } = await documentAPI.searchDocuments(params);
			let { rows = [], pages = [], count = 0, pageCount, page = 1 } = data || {}; // pages = [1,2,3,10]
			if (!rows || (Array.isArray(rows) && rows.length === 0)) {
				setActivePage(1); // Make sure we don't get stuck loading nothing
				throw new Error('No claimant data found');
			}

			if (pageCount < activePage) {
				setActivePage(1);
			} else {
				setActivePage(page);
			}

			setPages(pages);
			formatData(rows || []);
			setDocumentCount(count);

			if (saveLastQuery) {
				setLastQuery(params);
			}
		} catch (error) {
			console.log(error);
		}
		setSearching(false);
		return;
	};

	// Clear search params and reset to default
	const clearSearchParams = () => {
		setSearchParams({ ...defaultSearchParams, ...requiredSearchParams });
	};

	// New Search
	const newSearch = async (params) => {
		setSearching(true);
		let adjustedSearchParams = {
			...params,
			...requiredSearchParams
		};

		documentSearchHandler(adjustedSearchParams, true);
	};

	// Get related data @TODO: Move this to a context
	// const getRelatedData = async () => {
	// 	try {
	// 		let documentTypeResult = await documentAPI.getDocumentTyes();
	// 		if (!documentTypeResult?.data?.rows) {
	// 			throw new Error('Unable to get document types.');
	// 		}

	// 		let documentStatusResult = await documentAPI.getDocumentStatuses();
	// 		if (!documentStatusResult?.data?.rows) {
	// 			throw new Error('Unable to get document statuses.');
	// 		}

	// 		setDocumentTypes(documentTypeResult.data.rows);
	// 		setTaskStatuses(documentStatusResult.data.rows);
	// 	} catch (error) {
	// 		console.log(error);
	// 	}
	// };

	// Update document
	const updateDocument = async (accountId, documentId, data) => {
		try {
			let result = await documentAPI.updateDocument(accountId, documentId, data);
			if (!result?.data) {
				throw new Error('Error updating document');
			}
			refreshLastQuery();
		} catch (error) {
			console.log(error);
			toast.error('Error updating document', {
				theme: 'colored'
			});
		}
	};

	// Refresh last query
	const refreshLastQuery = async () => {
		documentSearchHandler({ ...lastQuery, ...requiredSearchParams }, false);
		refreshParent();
	};

	// Handle document selection
	const handleDocumentSelection = (document) => {
		setSelectedDocument(document);
		setDocumentDetailsModal(true);
	};

	useEffect(async () => {
		// await getRelatedData();
		newSearch({ ...defaultSearchParams });
	}, []);

	// Apply search filters
	useEffect(() => { newSearch(searchParams) }, [searchParams, activePage, perPage, appliedSortOrders]);

	return (
		<div>

			<AdvanceTableWrapper
				columns={columns}
				data={documentData}
				// selection
				sortable
				pagination
				perPage={perPage}
				setPerPage={setPerPage}
			>
				<Card className="mb-3 shadow-none">
					<Card.Header>
						<DocumentTableHeader
							table
							label={tableHeaderLabel}
							setDocumentCreateModal={setDocumentCreateModal}
							defaultSearchParams={defaultSearchParams}
							searchParams={searchParams}
							setSearchParams={setSearchParams}
							refreshLastQuery={refreshLastQuery}
							requiredSearchParams={requiredSearchParams}
							documentData={documentData}
							clearSearchParams={clearSearchParams}
						/>
					</Card.Header>
					<Card.Body className="p-0">
						{
							searching
								?
								<p>Searching documents....</p>
								:
								documentData.length === 0
									?
									<p>No documents found</p>
									:
									<CustomAdvanceTable
										table
										headerClickFn={headerClickFn}
										appliedSortOrders={appliedSortOrders}
										headerClassName="bg-200 text-900 text-nowrap align-middle"
										rowClassName="align-middle white-space-nowrap"
										tableProps={{
											size: 'sm',
											striped: true,
											className: 'fs--1 mb-0 overflow-hidden'
										}}
									/>
						}
					</Card.Body>
					<Card.Footer>
						<CustomAdvanceTablePagination
							table
							Pages={Pages}
							activePage={activePage}
							setActivePage={setActivePage}
						/>
					</Card.Footer>
				</Card>
			</AdvanceTableWrapper>

			<UploadDocumentForm
				setModalState={setDocumentCreateModal}
				modalState={documentCreateModal}
				defaultClaimants={defaultClaimants}
				refreshParent={refreshLastQuery}
				account_id={account_id}
				siteAdminArea={siteAdminArea}
			/>

			<PDFViewerModal
				modalState={pdfViewerModalState}
				setModalState={setPdfViewerModalState}
				selectedFileId={selectedFileId}
				setSelectedFileId={setSelectedFileId}
				siteAdminArea={siteAdminArea}
			/>
		</div>
	);
};


export default DocumentTable;