import React from 'react';
import GenericClaimantEntryTable from 'components/claimants/generic-claimant-entry-table'


const Reports = () => {
	return (
		<>
			<GenericClaimantEntryTable />
		</>
	);
};

export default Reports;