import React, { useState, useEffect, useContext } from 'react';
import AppContext from 'context/Context';
import { Button, Modal } from 'react-bootstrap';
import FindAUserForm from './FindAUserForm';
import { removeEmptyFromObj } from 'utils/functions';
import { userAPI } from 'utils/api/fulcrum-api';
import Table from 'react-bootstrap/Table';
import auth from 'utils/auth';

const UserQuickFind = ({ buttonVariant = "primary", buttonLabel = "Find-A-User", handleUserSelection = () => { }, defaultSearchParams = {}, requiredSearchParams = {}, skipFirstQuery = true }) => {

	const {
		config: { isDark }
	} = useContext(AppContext);
	const [quickFindModalState, setQuickFindModalState] = useState(false);
	const [foundUsers, setFoundUsers] = useState([]);
	const [userCount, setUserCount] = useState(0);
	const [searchParams, setSearchParams] = useState({ ...defaultSearchParams, ...requiredSearchParams, });
	const [, setLastQuery] = useState({});
	const [skipFirstSearch, setSkipFirstSearch] = useState(skipFirstQuery);
	const [searching, setSearching] = useState(false);

	// Search users
	const handleSearch = async (params) => {
		try {

			params = removeEmptyFromObj(params);
			let response = await userAPI.searchUsers({ ...params }, { limit: 25, offset: 0 });
			if (!response?.data?.rows) {
				throw new Error('No user data found');
			}
			setUserCount(response.data.count);
			setFoundUsers(response.data.rows);
			setLastQuery(params);
		} catch (error) {
			console.log(error);
		}
		setSearching(false);
	};

	// Apply search filters
	const newSearch = async (params) => {
		setSearching(true);
		let adjustedSearchParams = {
			...params,
			...requiredSearchParams
		};
		handleSearch(adjustedSearchParams);
	};

	// Refresh last query
	// const refreshLastQuery = async () => {
	// 	handleSearch(lastQuery);
	// };

	// Handle modal close
	const handleClose = () => {
		setUserCount(0);
		setFoundUsers([]);
		setSearchParams({});
		setQuickFindModalState(false);
	};

	// Select a user
	const handleUserSelect = (user) => {
		handleUserSelection(user);
		handleClose();
	};

	useEffect(() => {
		if (!skipFirstSearch && Object.keys(searchParams).length > 0) {
			newSearch(searchParams);
		}
		setSkipFirstSearch(false);
	}, [searchParams]);


	return (
		<>
			<Button variant={buttonVariant} onClick={() => setQuickFindModalState(true)}>
				{buttonLabel}
			</Button>

			<Modal
				show={quickFindModalState}
				onHide={handleClose}
				backdrop="static"
				size="lg"
				contentClassName="border"
			>
				<Modal.Header
					closeButton
					closeVariant={isDark ? 'white' : undefined}
					className="bg-light px-card border-bottom-0"
				>
					<Modal.Title as="h5">User Quick Find</Modal.Title>
				</Modal.Header>
				<Modal.Body className="p-card">
					<FindAUserForm
						searchParams={searchParams}
						setSearchParams={setSearchParams}
						requiredSearchParams={requiredSearchParams}
					/>

					<hr />

					<div className="d-flex justify-content-between align-items-center mt-3">
						<h5 className="mb-0">Found Users</h5>
						<span className="text-muted">{userCount} found</span>
					</div>
					<div className="mt-3">
						{
							searching ?
								<p>Searching....</p>
								:
								<Table hover responsive striped>
									<thead>
										<th>Name</th>
									</thead>
									<tbody>
										{foundUsers.map((user, index) => (
											<tr key={index} className="cursor-hover" onClick={() => handleUserSelect(user)}>
												<td className="cursor-hover">{user.first_name} {user.last_name}</td>
											</tr>
										))}
									</tbody>
								</Table>

						}
					</div>
				</Modal.Body>
			</Modal>
		</>
	);
};


export default UserQuickFind;