import React, { useState } from 'react';
import ClaimantEntryTable from './ClaimantEntryTable';
import { allowedColumns } from './AllowedColumns';

const GenericClaimantEntryTable = ({ }) => {

	const [displayedColumns, setDisplayedColumns] = useState([
		// ...allowedColumns
	]);
	return (
		<>
			<ClaimantEntryTable
				allowedColumns={allowedColumns}
			// columns={displayedColumns}
			/>
		</>
	);
};

export default GenericClaimantEntryTable;