export default [
  {
    media: { icon: ['fab', 'calendar'], color: 'info', className: 'fs-4' },
    title: 'Easy & Efficient Scheduling',
    description:
      "By scheduling multiple hearings each day at one hearing office, law firms can substantially lower their legal costs."
  },
  {
    media: { icon: ['fab', 'chart-line'], color: 'success', className: 'fs-5' },
    title: 'Increase Profits & Productivity',
    description:
      'Assure Disability can offer you open hearings in your area. Keep your employees home and still get paid for their appearance!'
  },
  {
    media: { icon: ['fab', 'folder-open'], color: 'danger', className: 'fs-6' },
    title: 'Generate & Organize Briefs',
    description:
      'Assure Disability lets your firm easily make requests for briefs for each of your disability hearings, which are uploaded and ready for you before the hearing.'
  }
];
