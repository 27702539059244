import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import { toast } from 'react-toastify';
import { claimantDetailsAPI } from 'utils/api/fulcrum-api';
import ClaimantChildren from './children/ClaimantChildren';
import ClaimantSpouse from './ClaimantSpouse';
import OtherHousehold from './other-household/OtherHousehold';
import CurrentIncome from './current-income/CurrentIncome';
import Employers from './employers/Employers';
import Doctors from './doctors/Doctors';
import Hospitals from './hospitals/Hospitals';
import Medications from './medications/Medications';
import moment from 'moment';
// import SendScreeningDocumentsButton from '../SendScreeningDocumentsButton';
// import SendUpdateInformationRequestButton from '../SendUpdateInformationRequestButton';
// import SendRetainerPacket from '../SendRetainerPacket';

const ClaimantProfileBio = ({ uuid, claimant, setClaimant }) => {
  let defaultState = {
    has_spouse: claimant.has_spouse,
    spouse_name: claimant.spouse_name,
    spouse_job: claimant.spouse_job,
    spouse_disability: claimant.spouse_disability,
    has_children: claimant.has_children,
    children: claimant.children,
    others_in_household: claimant.others_in_household,
    in_kind_support_and_maintance: claimant.in_kind_support_and_maintance,
    over_ssi_asset_threshold: claimant.over_ssi_asset_threshold,
    workers_comp_claim: claimant.workers_comp_claim,
    ltd: claimant.ltd,
    education: claimant.education,
    special_education: claimant.special_education,
    literacy: claimant.literacy,
    primary_language: claimant.primary_language,
    translator: claimant.translator,
    veteran: claimant.veteran,
    scdr: claimant.scdr,
    scdr_percentage: claimant.scdr_percentage,
    scdr_impairment: claimant.scdr_impairment,
    scdriu: claimant.scdriu,
    legal: claimant.legal,
    legal_date: claimant.legal_date,
    legal_reason: claimant.legal_reason,
    working: claimant.working,
    working_full_time: claimant.working_full_time,
    last_work_date: claimant.last_work_date,
    ssdi_insured: claimant.ssdi_insured,
    employers: claimant.employers,
    alleged_impairments: claimant.alleged_impairments,
    health_insurance: claimant.health_insurance,
    ever_treating: claimant.ever_treating,
    currently_treating: claimant.currently_treating,
    voc_rehab: claimant.voc_rehab,
    daa: claimant.daa,
    doctors: claimant.doctors,
    hospitals: claimant.hospitals,
    medications: claimant.medications,
    pending_app: claimant.pending_app,
    pending_app_filing: claimant.pending_app_filing,
    pending_app_decision: claimant.pending_app_decision,
    pending_app_decision_date: claimant.pending_app_decision_date,
    pending_app_hearing: claimant.pending_app_hearing,
    pending_app_hearing_date: claimant.pending_app_hearing_date,
    prior_app: claimant.prior_app,
    prior_app_filing: claimant.prior_app_filing,
    prior_app_hearing: claimant.prior_app_hearing,
    prior_benefits: claimant.prior_benefits,
    current_benefits: claimant.current_benefits,
    last_bio_saved: claimant.last_bio_saved,
  };
  const [formData, setFormData] = useState(defaultState);
  const [previousFormData, setPreviousFormData] = useState(defaultState);
  const [, setIsEditing] = useState(false);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  // const handleEdit = () => {
  // 	setIsEditing(true);
  // 	setPreviousFormData(formData);
  // };

  // const handleCancel = () => {
  // 	setIsEditing(false);
  // 	setFormData(previousFormData);
  // };

  const saveClaimant = async (data) => {
    try {
      let result = await claimantDetailsAPI.updateClaimantDetails(uuid, claimant.id, { ...data, screened: true, last_bio_saved: moment().format('YYYY-MM-DD') });
      if (!result?.data) {
        throw new Error('No claimant data returned');
      }

      setClaimant(result.data);

      let updatedFormData = { ...formData, ...data };

      setFormData(updatedFormData);
      setPreviousFormData(updatedFormData);
    } catch (error) {
      console.error(error);
      toast.error(`${error}`, {
        theme: 'colored'
      });
    }
    setIsEditing(false);
    setPreviousFormData(formData);
  };

  // Save on change
  const saveOnChange = (event) => {
    const { name, value } = event.target;

    if (['has_spouse', 'has_children', 'spouse_disability'].includes(name)) {
      let value = value === 'true' ? true : false;
    }

    if (previousFormData[name] !== value) {
      saveClaimant({ [name]: value });
    }
  };



  return (
    <div id="claimant-bio-container">
      {/* <SendUpdateInformationRequestButton claimant={claimant} setClaimant={setClaimant} /> */}
      {/* <SendRetainerPacket claimant={claimant} setClaimant={setClaimant} /> */}
      {/* <SendScreeningDocumentsButton claimant={claimant} setClaimant={setClaimant} /> */}
      <p>Last Bio Saved: {formData?.last_bio_saved ? moment(formData.last_bio_saved).format('MM/DD/YYYY hh:mm A') : 'NA'}</p>
      <h4>Living Situation</h4>
      <hr />
      <ClaimantSpouse
        claimant={claimant}
        setClaimant={setClaimant}
        saveClaimant={saveClaimant}
        saveOnChange={saveOnChange}
      />
      <ClaimantChildren
        claimant={claimant}
        setClaimant={setClaimant}
        saveClaimant={saveClaimant}
        saveOnChange={saveOnChange}
      />
      <OtherHousehold
        claimant={claimant}
        setClaimant={setClaimant}
        saveClaimant={saveClaimant}
        saveOnChange={saveOnChange}
      />
      <CurrentIncome
        claimant={claimant}
        setClaimant={setClaimant}
        saveClaimant={saveClaimant}
        saveOnChange={saveOnChange}
      />

      <Form>
        <Form.Group controlId="over_ssi_asset_threshold">
          <Form.Label>
            Over SSI Asset Threshold
          </Form.Label>
          <Form.Control
            as="select"
            name="over_ssi_asset_threshold"
            value={formData.over_ssi_asset_threshold}
            onChange={e => { handleInputChange(e); saveOnChange(e) }}
          >
            <option value="true">Yes</option>
            <option value="false">No</option>
          </Form.Control>
        </Form.Group>

        <Form.Group controlId="in_kind_support_and_maintance">
          <Form.Label>
            Receiving In-Kind Support and Maintenance
          </Form.Label>
          <Form.Control
            as="select"
            name="in_kind_support_and_maintance"
            value={formData.in_kind_support_and_maintance}
            onChange={e => { handleInputChange(e); saveOnChange(e) }}
          >
            <option value="true">Yes</option>
            <option value="false">No</option>
          </Form.Control>
        </Form.Group>

        <Form.Group controlId="workers_comp_claim">
          <Form.Label>
            Workers' Compensation Claim
          </Form.Label>
          <Form.Control
            as="select"
            name="workers_comp_claim"
            value={formData.workers_comp_claim}
            onChange={e => { handleInputChange(e); saveOnChange(e) }}
          >
            <option value="true">Yes</option>
            <option value="false">No</option>
          </Form.Control>
        </Form.Group>

        <Form.Group controlId="ltd">
          <Form.Label>
            Private Disability Claim
          </Form.Label>
          <Form.Control
            as="select"
            name="ltd"
            value={formData.ltd}
            onChange={e => { handleInputChange(e); saveOnChange(e) }}
          >
            <option value="true">Yes</option>
            <option value="false">No</option>
          </Form.Control>
        </Form.Group>

      </Form>



      <div id="claimant-spouse-container" className="my-3">
        <h5 className="d-inline">Vocational Profile</h5>
        <hr />
        <Form>

          <hr />
          <Form.Group controlId="education">
            <Form.Label>
              Education
            </Form.Label>
            <Form.Control
              type="text"
              name="education"
              value={formData.education}
              onChange={handleInputChange}
              onBlur={saveOnChange}
            />
          </Form.Group>
          <Form.Group controlId="special_education">
            <Form.Label>
              Special Education
            </Form.Label>
            <Form.Control
              as="select"
              name="special_education"
              value={formData.special_education}
              onChange={e => { handleInputChange(e); saveOnChange(e) }}
            >
              <option value="true">Yes</option>
              <option value="false">No</option>
            </Form.Control>
          </Form.Group>
          <Form.Group controlId="literacy">
            <Form.Label>
              Able to read, write, understand English
            </Form.Label>
            <Form.Control
              as="select"
              name="literacy"
              value={formData.literacy}
              onChange={e => { handleInputChange(e); saveOnChange(e) }}
            >
              <option value="true">Yes</option>
              <option value="false">No</option>
            </Form.Control>
          </Form.Group>
          {
            !formData.literacy &&
            <>
              <Form.Group controlId="primary_language">
                <Form.Label>
                  Primary Language
                </Form.Label>
                <Form.Control
                  type="text"
                  name="primary_language"
                  value={formData.primary_language}
                  onChange={handleInputChange}
                  onBlur={saveOnChange}
                />
              </Form.Group>
              <Form.Group controlId="translator">
                <Form.Label>
                  Requires Translator
                </Form.Label>
                <Form.Control
                  as="select"
                  name="translator"
                  value={formData.translator}
                  onChange={e => { handleInputChange(e); saveOnChange(e) }}
                >
                  <option value="true">Yes</option>
                  <option value="false">No</option>
                </Form.Control>
              </Form.Group>
            </>
          }

          <hr />
          <Form.Group controlId="veteran">
            <Form.Label>
              Veteran
            </Form.Label>
            <Form.Control
              as="select"
              name="veteran"
              value={formData.veteran}
              onChange={e => { handleInputChange(e); saveOnChange(e) }}
            >
              <option value="true">Yes</option>
              <option value="false">No</option>
            </Form.Control>
          </Form.Group>

          {
            formData.veteran &&
            <>
              <Form.Group controlId="scdr">
                <Form.Label>
                  VA Disability Benefits
                </Form.Label>
                <Form.Control
                  as="select"
                  name="scdr"
                  value={formData.scdr}
                  onChange={e => { handleInputChange(e); saveOnChange(e) }}
                >
                  <option value="true">Yes</option>
                  <option value="false">No</option>
                </Form.Control>
              </Form.Group>
              <Form.Group controlId="scdr_percentage">
                <Form.Label>
                  Service-Connected Disability Rating
                </Form.Label>
                <Form.Control
                  as="select"
                  name="scdr_percentage"
                  value={formData.scdr_percentage}
                  onChange={e => { handleInputChange(e); saveOnChange(e) }}
                >
                  <option value="true">Yes</option>
                  <option value="false">No</option>
                </Form.Control>
              </Form.Group>
              <Form.Group controlId="scdr_impairment">
                <Form.Label>
                  Service-Connected Impairments
                </Form.Label>
                <Form.Control
                  type="text"
                  name="scdr_impairment"
                  value={formData.scdr_impairment}
                  onChange={handleInputChange}
                  onBlur={saveOnChange}
                />
              </Form.Group>
              <Form.Group controlId="scdriu">
                <Form.Label>
                  Service-Connected Disability Rating
                </Form.Label>
                <Form.Control
                  as="select"
                  name="scdriu"
                  value={formData.scdriu}
                  onChange={e => { handleInputChange(e); saveOnChange(e) }}
                >
                  <option value="true">Yes</option>
                  <option value="false">No</option>
                </Form.Control>
              </Form.Group>
            </>
          }


          <hr />
          <Form.Group controlId="legal">
            <Form.Label>
              History of arrest/incarceration
            </Form.Label>
            <Form.Control
              as="select"
              name="legal"
              value={formData.legal}
              onChange={e => { handleInputChange(e); saveOnChange(e) }}
            >
              <option value="true">Yes</option>
              <option value="false">No</option>
            </Form.Control>
          </Form.Group>

          {
            formData.legal &&
            <>
              <Form.Group controlId="legal_date">
                <Form.Label>
                  Date of arrest/incarceration
                </Form.Label>
                <Form.Control
                  type="date"
                  name="legal_date"
                  value={formData.legal_date}
                  onChange={handleInputChange}
                  onBlur={saveOnChange}
                />
              </Form.Group>
              <Form.Group controlId="legal_reason">
                <Form.Label>
                  Reason for arrest/incarceration
                </Form.Label>
                <Form.Control
                  type="text"
                  name="legal_reason"
                  value={formData.legal_reason}
                  onChange={handleInputChange}
                  onBlur={saveOnChange}
                />
              </Form.Group>
            </>
          }

          <hr />
          <Form.Group controlId="working">
            <Form.Label>
              Currently Working
            </Form.Label>
            <Form.Control
              as="select"
              name="working"
              value={formData.working}
              onChange={e => { handleInputChange(e); saveOnChange(e) }}
            >
              <option value="true">Yes</option>
              <option value="false">No</option>
            </Form.Control>
          </Form.Group>

          {
            formData.working &&
            <>
              <Form.Group controlId="working_full_time">
                <Form.Label>
                  Full-Time
                </Form.Label>
                <Form.Control
                  as="select"
                  name="working_full_time"
                  value={formData.working_full_time}
                  onChange={e => { handleInputChange(e); saveOnChange(e) }}
                >
                  <option value="true">Yes</option>
                  <option value="false">No</option>
                </Form.Control>
              </Form.Group>
              {
                !formData.working_full_time &&
                <Form.Group controlId="legal_date">
                  <Form.Label>
                    Date of arrest/incarceration
                  </Form.Label>
                  <Form.Control
                    type="date"
                    name="legal_date"
                    value={formData.legal_date}
                    onChange={handleInputChange}
                    onBlur={saveOnChange}
                  />
                </Form.Group>
              }
            </>
          }

          <Form.Group controlId="ssdi_insured">
            <Form.Label>
              Worked Five of last Ten Years
            </Form.Label>
            <Form.Control
              as="select"
              name="ssdi_insured"
              value={formData.ssdi_insured}
              onChange={e => { handleInputChange(e); saveOnChange(e) }}
            >
              <option value="true">Yes</option>
              <option value="false">No</option>
            </Form.Control>
          </Form.Group>


          <Employers
            claimant={claimant}
            setClaimant={setClaimant}
            saveClaimant={saveClaimant}
            saveOnChange={saveOnChange}
          />

          <h5 className="d-inline">Medical Profile</h5>
          <hr />
          <Form.Group controlId="alleged_impairments">
            <Form.Label>
              Alleged Impairments
            </Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              name="alleged_impairments"
              value={formData.alleged_impairments}
              onChange={e => { handleInputChange(e); saveOnChange(e) }}
            // @todo - the above control should be adapted to not use saveOnChange because it's a textarea and it impacts the cursor position.
            />
          </Form.Group>
          <Form.Group controlId="health_insurance">
            <Form.Label>
              Health Insurance
            </Form.Label>
            <Form.Control
              as="select"
              name="health_insurance"
              value={formData.health_insurance}
              onChange={e => { handleInputChange(e); saveOnChange(e) }}
            >
              <option value="true">Yes</option>
              <option value="false">No</option>
            </Form.Control>
          </Form.Group>
          <Form.Group controlId="ever_treating">
            <Form.Label>
              History of Treatment
            </Form.Label>
            <Form.Control
              as="select"
              name="ever_treating"
              value={formData.ever_treating}
              onChange={e => { handleInputChange(e); saveOnChange(e) }}
            >
              <option value="true">Yes</option>
              <option value="false">No</option>
            </Form.Control>
          </Form.Group>
          <Form.Group controlId="currently_treating">
            <Form.Label>
              Currently Treating
            </Form.Label>
            <Form.Control
              as="select"
              name="currently_treating"
              value={formData.currently_treating}
              onChange={e => { handleInputChange(e); saveOnChange(e) }}
            >
              <option value="true">Yes</option>
              <option value="false">No</option>
            </Form.Control>
          </Form.Group>
          <Form.Group controlId="voc_rehab">
            <Form.Label>
              History of Vocational Rehabilitation
            </Form.Label>
            <Form.Control
              as="select"
              name="voc_rehab"
              value={formData.voc_rehab}
              onChange={e => { handleInputChange(e); saveOnChange(e) }}
            >
              <option value="true">Yes</option>
              <option value="false">No</option>
            </Form.Control>
          </Form.Group>
          <Form.Group controlId="daa">
            <Form.Label>
              History of Substance Abuse Treatment
            </Form.Label>
            <Form.Control
              as="select"
              name="daa"
              value={formData.daa}
              onChange={e => { handleInputChange(e); saveOnChange(e) }}
            >
              <option value="true">Yes</option>
              <option value="false">No</option>
            </Form.Control>
          </Form.Group>

          <Doctors
            uuid={uuid}
            claimant={claimant}
            setClaimant={setClaimant}
            saveClaimant={saveClaimant}
            saveOnChange={saveOnChange}
          />

          <Hospitals
            claimant={claimant}
            setClaimant={setClaimant}
            saveClaimant={saveClaimant}
            saveOnChange={saveOnChange}
          />

          <Medications
            claimant={claimant}
            setClaimant={setClaimant}
            saveClaimant={saveClaimant}
            saveOnChange={saveOnChange}
          />

          <h5 className="d-inline">Social Security Summary</h5>
          <hr />
          <Form.Group controlId="pending_app">
            <Form.Label>
              Pending Social Security Disability Application
            </Form.Label>
            <Form.Control
              as="select"
              name="pending_app"
              value={formData.pending_app}
              onChange={e => { handleInputChange(e); saveOnChange(e) }}
            >
              <option value="true">Yes</option>
              <option value="false">No</option>
            </Form.Control>
          </Form.Group>

          {
            formData.pending_app &&
            <>
              <Form.Group controlId="pending_app_filing">
                <Form.Label>
                  Filed
                </Form.Label>
                <Form.Control
                  type="date"
                  name="pending_app_filing"
                  value={formData.pending_app_filing}
                  onChange={handleInputChange}
                  onBlur={saveOnChange}
                />
              </Form.Group>
              <Form.Group controlId="pending_app_decision">
                <Form.Label>
                  Received Decision
                </Form.Label>
                <Form.Control
                  as="select"
                  name="pending_app_decision"
                  value={formData.pending_app_decision}
                  onChange={e => { handleInputChange(e); saveOnChange(e) }}
                >
                  <option value="true">Yes</option>
                  <option value="false">No</option>
                </Form.Control>
              </Form.Group>

              {
                formData.pending_app_decision &&
                <Form.Group controlId="pending_app_decision_date">
                  <Form.Label>
                    Decision Date
                  </Form.Label>
                  <Form.Control
                    type="date"
                    name="pending_app_decision_date"
                    value={formData.pending_app_decision_date}
                    onChange={handleInputChange}
                    onBlur={saveOnChange}
                  />
                </Form.Group>
              }


              <Form.Group controlId="pending_app_hearing">
                <Form.Label>
                  Attended Hearing
                </Form.Label>
                <Form.Control
                  as="select"
                  name="pending_app_hearing"
                  value={formData.pending_app_hearing}
                  onChange={e => { handleInputChange(e); saveOnChange(e) }}
                >
                  <option value="true">Yes</option>
                  <option value="false">No</option>
                </Form.Control>
              </Form.Group>

              {
                formData.pending_app_hearing &&
                <Form.Group controlId="pending_app_hearing_date">
                  <Form.Label>
                    Hearing Date
                  </Form.Label>
                  <Form.Control
                    type="date"
                    name="pending_app_hearing_date"
                    value={formData.pending_app_hearing_date}
                    onChange={handleInputChange}
                    onBlur={saveOnChange}
                  />
                </Form.Group>
              }


            </>
          }


          <Form.Group controlId="prior_app">
            <Form.Label>
              Prior Social Security Disability Application
            </Form.Label>
            <Form.Control
              as="select"
              name="prior_app"
              value={formData.prior_app}
              onChange={e => { handleInputChange(e); saveOnChange(e) }}
            >
              <option value="true">Yes</option>
              <option value="false">No</option>
            </Form.Control>
          </Form.Group>

          {
            formData.prior_app &&
            <>
              <Form.Group controlId="prior_app_number">
                <Form.Label>
                  Number of Prior Applications
                </Form.Label>
                <Form.Control
                  type="number"
                  name="prior_app_number"
                  value={formData.prior_app_number}
                  onChange={handleInputChange}
                  onBlur={saveOnChange}
                />
              </Form.Group>
              <Form.Group controlId="prior_app_filing">
                <Form.Label>
                  Last Application
                </Form.Label>
                <Form.Control
                  type="date"
                  name="prior_app_filing"
                  value={formData.prior_app_filing}
                  onChange={handleInputChange}
                  onBlur={saveOnChange}
                />
              </Form.Group>
              <Form.Group controlId="prior_app_hearing">
                <Form.Label>
                  Prior Hearing
                </Form.Label>
                <Form.Control
                  as="select"
                  name="prior_app_hearing"
                  value={formData.prior_app_hearing}
                  onChange={e => { handleInputChange(e); saveOnChange(e) }}
                >
                  <option value="true">Yes</option>
                  <option value="false">No</option>
                </Form.Control>
              </Form.Group>

              {
                formData.prior_app_decision &&
                <Form.Group controlId="prior_app_decision_date">
                  <Form.Label>
                    Decision Date
                  </Form.Label>
                  <Form.Control
                    as="date"
                    name="prior_app_decision_date"
                    value={formData.prior_app_decision_date}
                    onChange={handleInputChange}
                    onBlur={saveOnChange}
                  />
                </Form.Group>
              }
            </>
          }

          <Form.Group controlId="prior_benefits">
            <Form.Label>
              Previously Received Social Security Disability Benefits
            </Form.Label>
            <Form.Control
              as="select"
              name="prior_benefits"
              value={formData.prior_benefits}
              onChange={e => { handleInputChange(e); saveOnChange(e) }}
            >
              <option value="true">Yes</option>
              <option value="false">No</option>
            </Form.Control>
          </Form.Group>

          <Form.Group controlId="current_benefits">
            <Form.Label>
              Currently Receiving Social Security Disability Benefits
            </Form.Label>
            <Form.Control
              as="select"
              name="current_benefits"
              value={formData.current_benefits}
              onChange={e => { handleInputChange(e); saveOnChange(e) }}
            >
              <option value="true">Yes</option>
              <option value="false">No</option>
            </Form.Control>
          </Form.Group>


        </Form>
      </div>
    </div >
  );
};

ClaimantProfileBio.propTypes = {
  claimant: PropTypes.object,
  setClaimant: PropTypes.func
}

export default ClaimantProfileBio;