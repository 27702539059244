import React, { useState, useEffect, useRef } from 'react';
import { accountServiceAPI } from 'utils/api/fulcrum-api';
import { Alert } from 'react-bootstrap';

const HearingCoverageAccess = ({ user, serUser = () => { } }) => {
	const [accountHasHearingCoverageAccess, setAccountHasHearingCoverageAccess] = useState(false);

	// Check if account has hearing coverage access
	const checkAccountHearingCoverageAccess = async () => {
		try {
			let result = await accountServiceAPI.checkAccountServiceStatus(user?.account_id || null, 1, `${user?.account_id || null}_1`);
			if (!result?.data) { throw new Error('Error checking account hearing coverage access'); }



			if ("isActive" in result.data && result.data.isActive) {
				setAccountHasHearingCoverageAccess(true);
			} else {
				setAccountHasHearingCoverageAccess(false);
			}
		} catch (error) {
			setAccountHasHearingCoverageAccess(false);
			return false;
		}
	};

	useEffect(() => {
		checkAccountHearingCoverageAccess();
	}, [])


	return (
		<div>
			{
				accountHasHearingCoverageAccess ?
					<div>
						<Alert variant="success">
							Account has Hearing Coverage Access!
						</Alert>
					</div>
					:
					<div>
						<Alert variant="danger">
							Account does not have Hearing Coverage Access. You must enable this on the Account details page under the Services tab.
						</Alert>
					</div>
			}
		</div>
	);
}

export default HearingCoverageAccess;