import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, Form, Table, Alert, ButtonGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AppContext from 'context/Context';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import MyFormFinderModal from 'components/pages/documents/my-forms/MyFormFinderModal';
import EditorModal from 'components/pages/documents/my-forms/document-generator/EditorModal';
import PDFViewerPreviewModal from 'components/app/pdfjs-viewer/PDFViewerPreviewModal';
import { claimantAPI, userAPI, myFormsAPI, documentAPI } from 'utils/api/fulcrum-api';

const SendRetainerPacket = ({
	modalState,
	setModalState = () => { },
	claimant,
	defaultDocumentPacketTitle = 'Retainer Packet',
	defaultPacketDocumentTypeId = "RETAINER_PACKET",
	defaultAttachedForms = []
} = {}) => {
	const { config: { isDark } } = useContext(AppContext);
	const [loading, setLoading] = useState(true);
	const [sending, setSending] = useState(false);
	const [foundRepresentatives, setFoundRepresentatives] = useState([]);
	const [pdfViewerModalState, setPdfViewerModalState] = useState(false);
	const [selectedForm, setSelectedForm] = useState(null);
	const [forms, setForms] = useState([]);
	const [documentTypes, setDocumentTypes] = useState([]);
	let defaultFormState = {
		claimant_id: claimant?.id || null,
		mode_of_transmission: null,
		primary_rep_id: claimant?.primary_rep?.id || null,
		document_packet_title: 'Retainer Packet',
		document_type_id: defaultPacketDocumentTypeId,
		content_form_id: 19
	};
	const [formData, setFormData] = useState(defaultFormState);
	const [myFormFinderModal, setMyFormFinderModal] = useState(false);
	const [formEditorModal, setFormEditorModal] = useState(false);

	// Search Represenatives
	const searchRepresentatives = async () => {
		try {
			let result = await userAPI.searchUsersByUserType('REPRESENTATIVE', { account_id: claimant?.account_id });
			if (!result?.data) {
				throw 'Error searching for representatives';
			}
			setFoundRepresentatives(result?.data);
		} catch (error) {
			toast.error(error, { theme: 'colored' });
			handleClose();
		}
		setLoading(false);
	};

	// Get default form by id and add to forms
	const getDefaultForm = async (id) => {
		try {
			let result = await myFormsAPI.getMyForm(id);
			if (!result?.data) {
				throw new Error('Unable to get default form');
			}

			return result.data;
		} catch (error) {
			toast.error('Unable to attach the default form.', { theme: 'colored' });
			return null;
		}
	};

	// Handle submit
	const handleSubmit = async (e) => {
		e.preventDefault();
		if (formData.mode_of_transmission == null) {
			toast.error('Please select a mode of transmission', { theme: 'colored' });
			return;
		}

		setSending(true);
		try {
			let result = await claimantAPI.sendClaimantRetainerPacket(claimant.id, {
				...formData,
				forms
			});

			if (!result?.message) {
				throw new Error('There was an error sending the retainer packet');
			}
			toast.success('Update information request sent!', { theme: 'colored' });
			handleClose();
		} catch (error) {
			toast.error("Unable to send the retainer packet", { theme: 'colored' });
		}
		setSending(false);
	};

	// Get related data @TODO: Move this to a context
	const getRelatedData = async () => {
		try {
			let docTypeResults = await documentAPI.searchDocumentTypes();
			if (!docTypeResults?.data?.rows) {
				throw new Error('Unable to get documents types.');
			}

			setDocumentTypes(docTypeResults.data.rows);
		} catch (error) {
			alert(error);
		}
	};

	// Handle modal close
	const handleClose = () => {
		setModalState(false);
		setSending(false);
		setFormData(defaultFormState);
	};

	// Handle edit selected 
	const handleEditSelected = (form) => {
		setSelectedForm(form);
		switch (form.type) {
			case "My Form":
			case 'RTF':
				setSelectedForm(form);
				setFormEditorModal(true);
				break;
			case "PDF":
				setSelectedForm(form);
				setPdfViewerModalState(true);
				break;
			default:
				break;
		}
	};

	// Handle modal show
	const handleShow = async () => {
		setLoading(true);
		getRelatedData();
		if (Array.isArray(defaultAttachedForms) && defaultAttachedForms.length > 0) {
			// Loop through and get each form and add to forms
			let newForms = [];
			for (let i = 0; i < defaultAttachedForms.length; i++) {
				let form = await getDefaultForm(defaultAttachedForms[i].id);
				if (form?.id) {
					newForms.push(form);
				}
			}
			setForms(newForms);
		}
		setFormData({
			claimant_id: claimant?.id || null,
			mode_of_transmission: null,
			primary_rep_id: claimant?.primary_rep?.id || null,
			document_packet_title: defaultDocumentPacketTitle,
			document_type_id: "RETAINER_PACKET"
		});
		await searchRepresentatives();
		setLoading(false)
	};

	// Handle Editor Save
	const handleEditorSave = (content) => {
		let newForms = forms.map((form, index) => {
			if (form.id === selectedForm.id) {
				return {
					...form,
					content
				}
			}
			return form;
		});

		setForms(newForms);
	};

	// Handle form selection
	const handleFormSelection = (form) => {
		// let type = ['My Form', 'RTF', 'my-form-response'].includes(type) ? 'my-form-response' : 'document-response';
		let newForms = [...forms, { id: Date.now(), ...form, order: forms.length + 1 }];
		setForms(newForms);
	};

	return (<Modal
		show={modalState}
		onShow={handleShow}
		onHide={handleClose}
		contentClassName="border"
		size="xl"
		backdrop="static"
	>
		<Form onSubmit={e => handleSubmit(e)}>
			<Modal.Header
				closeButton
				closeVariant={isDark ? 'white' : undefined}
				className="bg-light px-card border-bottom-0"
			>
				<Modal.Title as="h5">Send Retainer Packet</Modal.Title>
			</Modal.Header>
			<Modal.Body className="p-card">
				<Alert variant="info">
					To send a retainer packet to <span className="fw-bold">{claimant?.first_name || ""} {claimant?.last_name || ""}</span>, please complete the form below.
				</Alert>

				{loading ? <div className="d-flex justify-content-center align-items-center">
					<div className="spinner-border text-primary" role="status">
						<span className="sr-only">Loading...</span>
					</div>
				</div> : <div>
					<span className="fw-bold">Select a Primary Representative</span>
					<br />
					<span>Select a primary representative that will be assigned to the case upon a returned retainer packet.</span>
					<Form.Select
						aria-label="Primary Representative"
						value={formData.primary_rep_id}
						name="primary_rep_id"
						required
						disabled={sending}
						onChange={(e) => {
							setFormData({
								...formData,
								primary_rep_id: e.target.value
							});
						}}

					>
						<option value="">Select Primary Representative</option>
						{
							foundRepresentatives.map((rep, index) => {
								return (
									<option key={index} value={rep.id}>{rep.first_name} {rep.last_name}</option>
								);
							})
						}
					</Form.Select>

					<br />
					<br />

					<Form.Group controlId="formBasicCheckbox">
						<span className="fw-bold">Mode of Transmission</span>
						<br />
						<span>Select a mode of transmission for this correspondence.</span>
						<br />
						<Form.Check
							type="radio"
							inline
							label="Email"
							checked={formData.mode_of_transmission === 'email'}
							name="mode_of_transmission"
							id="email"
							required
							disabled={sending}
							onChange={(e) => {
								setFormData({ ...formData, [e.target.name]: e.target.id });
							}}
						/>
						<Form.Check
							type="radio"
							inline
							label="SMS Message"
							checked={formData.mode_of_transmission === 'sms'}
							name="mode_of_transmission"
							id="sms"
							required
							disabled={sending}
							onChange={(e) => {
								setFormData({ ...formData, [e.target.name]: e.target.id });
							}}
						/>
						<Form.Check
							type="radio"
							inline
							label="Regular Mail"
							checked={formData.mode_of_transmission === 'mail'}
							name="mode_of_transmission"
							id="mail"
							required
							disabled={sending}
							onChange={(e) => {
								setFormData({ ...formData, [e.target.name]: e.target.id });
							}}
						/>
					</Form.Group>

					<br />
					<br />

					<span className="fw-bold">Select forms to send to the claimant:</span>
					<br />
					<Button
						variant="link"
						onClick={() => setMyFormFinderModal(true)}
						disabled={sending}
					>
						Select Form Templates
					</Button>
					<MyFormFinderModal
						modalState={myFormFinderModal}
						setModalState={setMyFormFinderModal}
						handleFormSelection={handleFormSelection}
					/>

					{
						forms.length > 0 ?
							<div>
								<Table bordered size="md" className="bg-white mt-3">
									<thead className="bg-200">
										<tr>
											<th>Type</th>
											<th style={{ width: "50%" }}>Title</th>
											<th>Form Type</th>
											<th>Order</th>
											<th></th>
										</tr>
									</thead>
									<tbody>
										{
											forms.map((form, index) => (
												<tr key={index}>
													<td className="fw-bold">{form.type}</td>
													<td>
														<Form.Control
															type="text"
															name="title"
															value={form.title}
															disabled={sending}
															onChange={e => {
																const newForms = [...forms];
																newForms[index].title = e.target.value;
																setForms(newForms);
															}}
															required
														/>
													</td>
													<td>
														<Form.Control
															as="select"
															name="form_type_id"
															value={form.form_type_id}
															disabled={sending}
															onChange={e => {
																const newForms = [...forms];
																newForms[index].form_type_id = e.target.value;
																setForms(newForms);
															}}
															required
														>
															<option value={""}>--Select One--</option>
															{Array.isArray(documentTypes) && documentTypes.map((docType, index) => (
																<option key={index} value={docType.id}>{docType.type}</option>
															))}
														</Form.Control>
													</td>
													<td>
														{/* <span className="fw-bold px-0">{index + 1}</span> */}

														<ButtonGroup size="lg" className="me-2 mb-1">
															{
																index > 0 &&
																<Button variant="link" disabled={sending} onClick={e => {
																	const newForms = [...forms];
																	const temp = newForms[index];
																	newForms[index] = newForms[index - 1];
																	newForms[index - 1] = temp;
																	setForms(newForms);
																}} className="text-primary px-0 mx-1">
																	<FontAwesomeIcon icon="arrow-up" />
																</Button>
															}

															{
																index < forms.length - 1 &&
																<Button variant="link" disabled={sending} onClick={e => {
																	const newForms = [...forms];
																	const temp = newForms[index];
																	newForms[index] = newForms[index + 1];
																	newForms[index + 1] = temp;
																	setForms(newForms);
																}} className="text-primary px-0  mx-1">
																	<FontAwesomeIcon icon="arrow-down" />
																</Button>
															}
														</ButtonGroup>

													</td>
													<td>
														<ButtonGroup size="lg" className="me-2 mb-1">
															{
																form?.type === 'PDF' ?
																	<Button variant="link" className="px-0 mx-1" disabled={sending} onClick={e => { handleEditSelected(form) }}>
																		<FontAwesomeIcon icon="eye" />
																	</Button>
																	:
																	<Button variant="link" className="px-0 mx-1" disabled={sending} onClick={e => { handleEditSelected(form) }}>
																		<FontAwesomeIcon icon="edit" />
																	</Button>
															}
															<Button variant="link" className="text-danger px-0 mx-1" disabled={sending} onClick={e => {
																Swal.fire({
																	title: 'Remove template?',
																	text: "Are you sure you would like to remove this template?",
																	icon: 'warning',
																	showCancelButton: true,
																	confirmButtonColor: '#d33',
																	cancelButtonColor: '#3085d6',
																	confirmButtonText: 'Remove it!'
																}).then((result) => {
																	if (result.isConfirmed) {
																		setForms(forms.filter(
																			(f, i) => i !== index
																		))
																	}
																})
															}}>
																<FontAwesomeIcon icon="trash" />
															</Button>
														</ButtonGroup>
													</td>
												</tr>
											))
										}
									</tbody>
								</Table>
							</div>
							: <p className="my-3">No form templates selected</p>
					}

					<br />

					<Form.Group controlId="document_packet_title">
						<span className="fw-bold">Document Packet Name</span>
						<br />
						<span className="">This will link all these documents to a packet when they are completed.</span>
						<br />
						<Form.Control
							type="text"
							placeholder="Document Packet Name"
							name="document_packet_title"
							disabled={sending}
							value={formData.document_packet_title}
							onChange={e => {
								setFormData({
									...formData,
									[e.target.name]: e.target.value
								});
							}}
							required
						/>
					</Form.Group>

					<EditorModal
						modalState={formEditorModal}
						setModalState={setFormEditorModal}
						content={selectedForm?.content || ""}
						handleEditorSave={handleEditorSave}
						allowNewFormSave={false}
						allowPreview={true}
						setAllowNewFormSave={() => { }}
						defaultEditorConfig={{
							readonly: false,
						}}
						options={{
							allowNewFormSave: false,
							allowFormSave: true,
						}}
						clientDataArray={[claimant] || []}
					/>

					{selectedForm?.content && <PDFViewerPreviewModal
						modalState={pdfViewerModalState}
						setModalState={setPdfViewerModalState}
						headerButtons={['resetButton', 'printButton', 'downloadPdfButton']}
						selectedClaimants={[claimant] || []}
						pdf_template={selectedForm?.content}
					/>}
				</div>
				}

			</Modal.Body>
			<Modal.Footer className="border-top-0">
				<Button variant="secondary" onClick={handleClose}>
					Cancel
				</Button>
				{formData.mode_of_transmission !== null && (<Button variant="primary" type="submit" disabled={sending}>
					{sending && <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>}
					Send
				</Button>)}
			</Modal.Footer>
		</Form>
	</Modal>
	);
};

SendRetainerPacket.propTypes = {
	modalState: PropTypes.bool,
	setModalState: PropTypes.func,
	claimant: PropTypes.object,
	defaultDocumentPacketTitle: PropTypes.string,
	defaultPacketDocumentTypeId: PropTypes.string,
	defaultAttachedForms: PropTypes.array,
};

export default SendRetainerPacket;