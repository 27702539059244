import moment from 'moment';

export const availableFields = {
	"generic": {
		"today": {
			key: 'today',
			label: 'Today',
			input_type: 'text',
			value: moment().format('MM/DD/YYYY'),
			allowedInput: true,
			allowedText: false,
		},
		"text": {
			key: 'text',
			label: 'Text',
			input_type: 'text',
			value: '',
			allowedInput: true,
			allowedText: false,
		},
		'text_area': {
			key: 'text_area',
			label: 'Text Area',
			input_type: 'textarea',
			allowedInput: true,
			allowedText: true,
			value: ''
		},
		"signature": {
			key: 'signature',
			label: 'Signature',
			input_type: 'image',
			value: '/signature-placeholder.png',
			allowedInput: true,
			allowedText: true,
		},
		"page_break": {
			key: 'page_break',
			label: 'Page Break',
			input_type: 'page_break',
			value: '<pageBreak class="page-break" style="page-break-after: always;"></pageBreak>'
		}
	},
	"custom_fields": {
		"custom_field": {
			key: 'custom_field',
			label: 'Custom Field',
			input_type: 'text',
			value: ''
		}
	},
	"claimant": {
		"default": {
			key: 'default',
			label: 'Default',
			input_type: 'text',
			value: ''
		},
		"account_id": {
			key: 'account_id',
			label: 'Account ID',
			input_type: 'text',
			value: '',
			reference: 'relatedData?.claimant?.account_id || ""',
			readonly: true
		},
		"lead_vendor_id": {
			key: 'lead_vendor_id',
			label: 'Lead Vendor ID',
			input_type: 'text',
			value: '',
			reference: 'relatedData?.claimant?.lead_vendor_id || ""',
			readonly: true
		},
		"claimant_status_id": {
			key: 'claimant_status_id',
			label: 'Claimant Status ID',
			input_type: 'text',
			value: '',
			reference: 'relatedData?.claimant?.claimant_status_id || ""',
			readonly: true
		},
		"assigned_to": {
			key: 'assigned_to',
			label: 'Assigned To',
			input_type: 'text',
			value: '',
			reference: 'relatedData?.claimant?.assigned_to || ""',
			readonly: true
		},
		"assigned_user_first_name": {
			key: 'assigned_user_first_name',
			label: 'Assigned User First Name',
			input_type: 'text',
			value: '',
			reference: 'relatedData?.claimant?.assigned_user?.first_name || ""',
			readonly: true
		},
		"assigned_user_last_name": {
			key: 'assigned_user_last_name',
			label: 'Assigned User Last Name',
			input_type: 'text',
			value: '',
			reference: 'relatedData?.claimant?.assigned_user?.last_name || ""',
			readonly: true
		},
		"first_name": {
			key: 'first_name',
			label: 'Claimant First Name',
			input_type: 'text',
			value: '',
			reference: 'relatedData?.claimant?.first_name || ""',
			readonly: false
		},
		"middle_name": {
			key: 'middle_name',
			label: 'Claimant Middle Name',
			input_type: 'text',
			value: '',
			reference: 'relatedData?.claimant?.middle_name || ""',
			readonly: false
		},
		"last_name": {
			key: 'last_name',
			label: 'Claimant Last Name',
			input_type: 'text',
			value: '',
			reference: 'relatedData?.claimant?.last_name || ""',
			readonly: false
		},
		"suffix": {
			key: 'suffix',
			label: 'Claimant Suffix',
			input_type: 'text',
			value: '',
			reference: 'relatedData?.claimant?.suffix || ""',
			readonly: false
		},
		"ssn": {
			key: 'ssn',
			label: 'Claimant SSN',
			input_type: 'text',
			value: '',
			reference: 'relatedData?.claimant?.ssn || ""',
			readonly: false
		},
		"email": {
			key: 'email',
			label: 'Claimant Email',
			input_type: 'email',
			value: '',
			reference: 'relatedData?.claimant?.email || ""',
			readonly: false
		},
		"active_case_id": {
			key: 'active_case_id',
			label: 'Active Case ID',
			input_type: 'number',
			value: '',
			reference: 'relatedData?.claimant?.active_case_id || ""',
			readonly: false
		},
		"primary_address": {
			key: 'primary_address',
			label: 'Primary Address',
			input_type: 'text',
			value: '',
			reference: 'relatedData?.claimant?.entity?.contact_addresses.map(address => address.is_primary)[0]?.address || ""',
			readonly: false
		},
		"primary_city": {
			key: 'primary_city',
			label: 'Primary City',
			input_type: 'text',
			value: '',
			reference: 'relatedData?.claimant?.entity?.contact_addresses.map(address => address.is_primary)[0]?.city || ""',
			readonly: false
		},
		"primary_state": {
			key: 'primary_state',
			label: 'Primary State',
			input_type: 'text',
			value: '',
			reference: 'relatedData?.claimant?.entity?.contact_addresses.map(address => address.is_primary)[0]?.state || ""',
			readonly: false
		},
		"primary_zip": {
			key: 'primary_zip',
			label: 'Primary ZIP',
			input_type: 'text',
			value: '',
			reference: 'relatedData?.claimant?.entity?.contact_addresses.map(address => address.is_primary)[0]?.zip || ""',
			readonly: false
		},
		"primary_county": {
			key: 'primary_county',
			label: 'Primary County',
			input_type: 'text',
			value: '',
			reference: 'relatedData?.claimant?.entity?.contact_addresses.map(address => address.is_primary)[0]?.county || ""',
			readonly: false
		},
		"mailing_address": {
			key: 'mailing_address',
			label: 'Mailing Address',
			input_type: 'text',
			value: '',
			reference: 'relatedData?.claimant?.entity?.contact_addresses.map(address => address.address_type_id === 2)[0]?.address || ""',
			readonly: false
		},
		"mailing_city": {
			key: 'mailing_city',
			label: 'Mailing City',
			input_type: 'text',
			value: '',
			reference: 'relatedData?.claimant?.entity?.contact_addresses.map(address => address.address_type_id === 2)[0]?.city || ""',
			readonly: false
		},
		"mailing_state": {
			key: 'mailing_state',
			label: 'Mailing State',
			input_type: 'text',
			value: '',
			reference: 'relatedData?.claimant?.entity?.contact_addresses.map(address => address.address_type_id === 2)[0]?.state || ""',
			readonly: false
		},
		"mailing_zip": {
			key: 'mailing_zip',
			label: 'Mailing ZIP',
			input_type: 'text',
			value: '',
			reference: 'relatedData?.claimant?.entity?.contact_addresses.map(address => address.address_type_id === 2)[0]?.zip || ""',
			readonly: false
		},
		"mailing_county": {
			key: 'mailing_county',
			label: 'Mailing County',
			input_type: 'text',
			value: '',
			reference: 'relatedData?.claimant?.entity?.contact_addresses.map(address => address.address_type_id === 2)[0]?.county || ""',
			readonly: false
		},
		"physical_address": {
			key: 'physical_address',
			label: 'Physical Address',
			input_type: 'text',
			value: '',
			reference: 'relatedData?.claimant?.entity?.contact_addresses.map(address => address.address_type_id === 1)[0]?.address || ""',
			readonly: false
		},
		"physical_city": {
			key: 'physical_city',
			label: 'Physical City',
			input_type: 'text',
			value: '',
			reference: 'relatedData?.claimant?.entity?.contact_addresses.map(address => address.address_type_id === 1)[0]?.city || ""',
			readonly: false
		},
		"physical_state": {
			key: 'physical_state',
			label: 'Physical State',
			input_type: 'text',
			value: '',
			reference: 'relatedData?.claimant?.entity?.contact_addresses.map(address => address.address_type_id === 1)[0]?.state || ""',
			readonly: false
		},
		"physical_zip": {
			key: 'physical_zip',
			label: 'Physical ZIP',
			input_type: 'text',
			value: '',
			reference: 'relatedData?.claimant?.entity?.contact_addresses.map(address => address.address_type_id === 1)[0]?.zip || ""',
			readonly: false
		},
		"physical_county": {
			key: 'physical_county',
			label: 'Physical County',
			input_type: 'text',
			value: '',
			reference: 'relatedData?.claimant?.entity?.contact_addresses.map(address => address.address_type_id === 1)[0]?.county || ""',
			readonly: false
		},
		"primary_phone": {
			key: 'primary_address',
			label: 'Client Last Name',
			input_type: 'text',
			value: '',
			reference: 'relatedData?.claimant?.entity?.contact_addresses.map(address => address.is_primary)[0]?.address || ""',
			readonly: false
		},
		"dob": {
			key: 'dob',
			label: 'Client Date of Birth',
			input_type: 'date',
			value: '',
			reference: 'relatedData?.claimant?.entity?.dob ? moment(relatedData?.claimant?.entity?.dob).format("MM/DD/YYYY") : ""',
			readonly: false
		},
	},
	"user": {
		"first_name": {
			key: 'first_name',
			label: 'User First Name',
			input_type: 'text',
			value: '',
			reference: 'relatedData?.user?.first_name || ""',
			readonly: false
		},
		"last_name": {
			key: 'last_name',
			label: 'User Last Name',
			input_type: 'text',
			value: '',
			reference: 'relatedData?.user?.last_name || ""',
			readonly: false
		},
	},
	"account": {
		"form_header": {
			key: 'form_header',
			label: 'Form Header',
			input_type: 'text',
			value: '',
			reference: 'relatedData?.account?.form_header || ""'
		},
		"signature_block": {
			key: 'signature_block',
			label: 'Signature Block',
			input_type: 'text',
			value: '',
			reference: 'relatedData?.account?.signature_block || ""'
		}
	}
};