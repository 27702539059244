import React, { useState, useEffect } from 'react';
import { Col, Form, Button, Row } from 'react-bootstrap';

const CaseFileRequestFilters = ({ searchParams, setSearchParams = () => { }, fields = [], clearSearchParams = () => { } } = {}) => {
	const statusOptions = [
		"Received",
		"Sent",
		"Pending",
		"Completed",
	];

	let defaultFilters = {
		claimant_name: "",
		case_file_request_id: "",
		office_with_jurisdiction: "",
		primary_rep: "",
		type: "",
		status: "",
		status_message: "",
	};
	const [formData, setFormData] = useState({
		claimant_name: searchParams?.claimant_name || null,
		case_file_request_id: searchParams?.case_file_request_id || null,
		office_with_jurisdiction: searchParams?.office_with_jurisdiction || null,
		primary_rep: searchParams?.primary_rep || null,
		type: searchParams?.type || null,
		status: searchParams?.status || null,
		status_message: searchParams?.status_message || null,
		requested_start_date: searchParams?.requested_start_date || null,
		requested_end_date: searchParams?.requested_end_date || null,
	});

	// Clear Filters
	const clearFilters = () => {
		setFormData(defaultFilters);
		clearSearchParams();
	};

	const onSubmit = async (e) => {
		e.preventDefault();
		setSearchParams(formData);
	};

	useEffect(() => {
		setFormData({
			claimant_name: searchParams?.claimant_name || null,
			case_file_request_id: searchParams?.case_file_request_id || null,
			office_with_jurisdiction: searchParams?.office_with_jurisdiction || null,
			primary_rep: searchParams?.primary_rep || null,
			type: searchParams?.type || null,
			status: searchParams?.status || null,
			status_message: searchParams?.status_message || null,
			requested_start_date: searchParams?.requested_start_date || null,
			requested_end_date: searchParams?.requested_end_date || null,
		});
	}, [searchParams]);

	return (
		<Form noValidate onSubmit={onSubmit} className="row flex-between-center px-2 py-3 my-3 border bg-100 mx-2">
			<Row>
				<Col>
					<h5>Filters</h5>
				</Col>
			</Row>

			<Row>
				{
					fields.includes('claimant_name') &&
					<Col>
						<Form.Group controlId="claimant_name">
							<Form.Label>Claimant</Form.Label>
							<Form.Control
								className={`${searchParams?.claimant_name ? "bg-soft-info" : ""}`}
								size="sm"
								type="text"
								placeholder="Claimant"
								name="claimant_name"
								value={formData.claimant_name}
								onChange={(e) => setFormData({ ...formData, claimant_name: e.target.value })}
							/>
						</Form.Group>
					</Col>
				}

				{
					fields.includes("status") &&
					<Col>
						<Form.Group controlId="status">
							<Form.Label>Status</Form.Label>
							<Form.Select
								className={`${searchParams?.status ? "bg-soft-info" : ""}`}
								size="sm"
								aria-label="Status"
								name="status"
								value={formData.status}
								onChange={(e) =>
									setFormData({ ...formData, status: e.target.value })
								}
							>
								<option value="">All</option>
								{statusOptions.map((option) => (
									<option key={option} value={option}>
										{option}
									</option>
								))}
							</Form.Select>
						</Form.Group>
					</Col>
				}

				{
					fields.includes('office_with_jurisdiction') &&
					<Col>
						<Form.Group controlId="office_with_jurisdiction">
							<Form.Label>Office with Jurisdiction</Form.Label>
							<Form.Control
								className={`${searchParams?.office_with_jurisdiction ? "bg-soft-info" : ""}`}
								size="sm"
								type="text"
								placeholder="Office with Jurisdiction"
								name="office_with_jurisdiction"
								value={formData.office_with_jurisdiction}
								onChange={(e) => setFormData({ ...formData, office_with_jurisdiction: e.target.value })}
							/>
						</Form.Group>
					</Col>
				}
			</Row>
			<Row>
				{
					fields.includes("type") &&
					<Col>
						<Form.Group controlId="type">
							<Form.Label>Type</Form.Label>
							<Form.Select
								className={`${searchParams?.type ? "bg-soft-info" : ""}`}
								size="sm"
								aria-label="Type"
								name="type"
								value={formData.type}
								onChange={(e) =>
									setFormData({ ...formData, type: e.target.value })
								}
							>
								<option value="">Both</option>
								<option value="1">Exhibited</option>
								<option value="2">All</option>
							</Form.Select>
						</Form.Group>
					</Col>
				}

				{
					fields.includes('requested_start_date') &&
					<Col>
						<Form.Group controlId="requested_start_date">
							<Form.Label>Requested Start Date</Form.Label>
							<Form.Control
								className={`${searchParams?.requested_start_date ? "bg-soft-info" : ""}`}
								size="sm"
								type="date"
								placeholder="Requested Start Date"
								name="requested_start_date"
								value={formData.requested_start_date}
								onChange={(e) =>
									setFormData({ ...formData, requested_start_date: e.target.value })
								}
							/>
						</Form.Group>
					</Col>
				}

				{
					fields.includes('requested_end_date') &&
					<Col>
						<Form.Group controlId="requested_end_date">
							<Form.Label>Requested End Date</Form.Label>
							<Form.Control
								className={`${searchParams?.requested_end_date ? "bg-soft-info" : ""}`}
								size="sm"
								type="date"
								placeholder="Requested End Date"
								name="requested_end_date"
								value={formData.requested_end_date}
								onChange={(e) =>
									setFormData({ ...formData, requested_end_date: e.target.value })
								}
							/>
						</Form.Group>
					</Col>
				}
			</Row>
			<Row>
				{
					fields.length > 0 && (
						<>
							<Col>
								<div className="float-end">
									<Button variant="primary" type="submit" className="mt-4">
										Apply Filters
									</Button>
									{
										JSON.stringify(searchParams) && (
											<>
												{' '}
												<Button
													variant="outline-danger"
													type="button"
													className="mt-4"
													onClick={() => {
														clearFilters();
													}}
												>
													Clear Filters
												</Button>
											</>
										)
									}
								</div>
							</Col>
						</>
					)
				}
			</Row>
		</Form >
	);
};

export default CaseFileRequestFilters;
