import React, { useState } from 'react';
import { Modal, Button, Row, Col } from 'react-bootstrap';
import { assureAPI } from 'utils/api/fulcrum-api';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';

const columns = [
	{
		accessor: 'id',
		Header: 'ID',
		headerProps: { className: 'pe-1' },
		cellProps: {
			className: 'py-2'
		}
	},
	{
		accessor: 'address_street_1',
		Header: 'Address',
		headerProps: { className: 'pe-1' },
		cellProps: {
			className: 'py-2'
		}
	},
	{
		accessor: 'address_street_2',
		Header: 'Address 2',
		headerProps: { className: 'pe-1' },
		cellProps: {
			className: 'py-2'
		}
	},
	{
		accessor: 'address_city',
		Header: 'City',
		headerProps: { className: 'pe-1' },
		cellProps: {
			className: 'py-2'
		}
	},
	{
		accessor: 'address_state',
		Header: 'State',
		headerProps: { className: 'pe-1' },
		cellProps: {
			className: 'py-2'
		}
	},
	{
		accessor: 'address_zip',
		Header: 'Zip',
		headerProps: { className: 'pe-1' },
		cellProps: {
			className: 'py-2'
		}
	},
	{
		accessor: 'timezone',
		Header: 'Timezone',
		headerProps: { className: 'pe-1' },
		cellProps: {
			className: 'py-2'
		}
	},
	{
		accessor: 'special',
		Header: 'Special',
		headerProps: { className: 'pe-1' },
		cellProps: {
			className: 'py-2'
		}
	},
	{
		accessor: 'menu',
		Header: '',
		headerProps: { className: 'pe-1' },
		cellProps: {
			className: 'py-2'
		}
	}
];

const ViewLocations = ({ modalState, setModalState = () => { }, locationSelectCallBack = () => { }, alreadySelectedLocations = [] }) => {
	const [foundLocations, setFoundLocations] = useState([]);
	const [foundLocationCount, setFoundLocationCount] = useState(0);
	const [loading, setLoading] = useState(false);

	const formatData = (data = []) => {
		let adjustedData = data.map(l => {
			return {
				...l,
				timezone: l.timezone_abbr ? l.timezone_abbr : 'N/A',
				address: <span>{l.address_street_1} {l.address_street_2}<br /> {l.address_city} {l.address_state} {l.address_zip}</span>,
				menu:
					alreadySelectedLocations.includes(l)
						?
						<span className="text-success">Selected</span>
						:
						<Button variant="falcon-primary" onClick={() => handleLocationSelect(l)}>Select</Button>
			}
		});
		setFoundLocations(adjustedData);
	};

	// Get assure location list
	const getLocationList = async () => {
		setLoading(true);
		try {
			let result = await assureAPI.getLocationList();
			formatData(result?.data?.data || []);
			setFoundLocationCount(result?.data?.data?.length || 0);
		} catch (error) {
			console.log(error);
		}
		setLoading(false);
	};

	// Handle location select
	const handleLocationSelect = (location) => {
		locationSelectCallBack(location);
		setModalState(false);
	};

	// Handle open
	const handleOpen = () => {
		getLocationList();
	};

	// Handle close
	const handleClose = () => {
		setModalState(false);
	};


	return (
		<div>
			<Modal
				show={modalState}
				onShow={handleOpen}
				onHide={handleClose}
				backdrop="static"
				size="xl"
			>
				<Modal.Header closeButton>
					<Modal.Title>Locations</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{
						loading ?
							<div className="text-center">
								<div className="spinner-border" role="status">
									<span className="sr-only">Loading locations...</span>
								</div>
							</div>
							:
							<AdvanceTableWrapper
								columns={columns}
								data={foundLocations}
								sortable
								searchable
								pagination
								perPage={10}
							>
								<Row className="flex-end-center mb-3">
									<Col xs="auto" sm={6} lg={4}>
										<AdvanceTableSearchBox table />
									</Col>
								</Row>
								<AdvanceTable
									table
									headerClassName="bg-200 text-900 text-nowrap align-middle"
									rowClassName="align-middle white-space-nowrap"
									tableProps={{
										bordered: true,
										striped: true,
										className: 'fs--1 mb-0 overflow-hidden'
									}}
								/>
								<div className="mt-3">
									<AdvanceTableFooter
										rowCount={foundLocationCount}
										table
										navButtons
									/>
								</div>
							</AdvanceTableWrapper>
					}
				</Modal.Body>
			</Modal>
		</div>
	);
};

export default ViewLocations;