import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Card } from 'react-bootstrap';
import Swal from 'sweetalert2';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import ClaimantEntryTableHeader from './ClaimantEntryTableHeader';
import CustomAdvanceTable from 'components/common/advance-table/CustomAdvanceTable';
import CustomAdvanceTablePagination from 'components/common/advance-table/CustomAdvanceTablePagination';
import ClaimantFiltersModalForm from './ClaimantFiltersModalForm';
import { claimantAPI } from 'utils/api/fulcrum-api';
import { removeEmptyFromObj } from 'utils/functions';
import auth from 'utils/auth';

const defaultColumns = [
	{
		accessor: 'first_name',
		Header: 'First Name',
		model: 'Claimant',
		headerProps: { className: 'pe-1' },
		cellProps: {
			className: 'py-2'
		},
		Cell: rowData => {
			const { first_name, id } = rowData.row.original;
			return <Link to={`/claimant/profile/${id}`}>{first_name}</Link>;
		},
		type: 'text'
	},
	{
		accessor: 'last_name',
		Header: 'Last Name',
		model: 'Claimant',
		headerProps: { className: 'pe-1' },
		cellProps: {
			className: 'py-2'
		},
		Cell: rowData => {
			const { last_name, id } = rowData.row.original;
			return <Link to={`/claimant/profile/${id}`}>{last_name}</Link>;
		},
		type: 'text'
	},
];

// Used for Report Builder Page 
const ClaimantEntryTable = ({ allowedColumns = [], tableHeaderLabel = "Claimants Report" }) => {
	let defaultSort = ["created_at", "ASC"];
	const defaultReportParams = {
		selectedIds: [],
		page: 1,
		limit: 10,
		navSize: 4, // How many buttons to show for navigation
		order: [defaultSort], // Example [['last_last', 'asc'],['first_name','asc'], ['id','asc']]
		requestedFields: [],
	};

	const [reportModalIsShown, setReportModalIsShown] = useState(false);
	const [columns, setColumns] = useState(defaultColumns);
	const [reportData, setReportData] = useState([]); // Data coming back 
	const [reportParams, setReportParams] = useState(defaultReportParams); // Payload going in 
	const [Pages, setPages] = useState([defaultReportParams.page]);
	const [activePage, setActivePage] = useState(defaultReportParams.page);
	const [perPage, setPerPage] = useState(defaultReportParams.limit);
	const [searching, setSearching] = useState(true);
	const [lastQuery, setLastQuery] = useState({});
	const [appliedSortOrders, setAppliedSortOrders] = useState([defaultSort]);
	const [claimantReports, setClaimantReports] = useState([]);
	const [selectedReport, setSelectedReport] = useState({});

	const handleBulkActions = (actionObj = {}, selectedRows = [], selectedIds = []) => {
		if (Array.isArray(selectedIds) && selectedIds.length > 0) {
			setReportParams((prev) => ({ ...prev, selectedIds }));
		}
		let tableItem = {};
		if (Array.isArray(selectedRows) && selectedRows.length === 1) {
			tableItem = selectedRows[0];
		}
		let { bulk_action, claimant_report_id } = actionObj || {};
		switch (bulk_action) {
			case 'clear filters':
				clearReportParams();
				break;
			case 'filters':
				setReportModalIsShown(true);
				break;
			case 'inactive claimant':
				queryInactiveClaimant(); // Claimant.active_claimant = false
				break;
			case 'save report':
				saveReport();
				break;
			case 'load_report':
				loadClaimantReport(claimant_report_id);
				break;
			case 'delete report':
				deleteClaimantReport(claimant_report_id);
				break;
			default:
				console.log("@todo handleBulkActions", actionObj, "selectedRows", selectedRows, "selectedIds", selectedIds, "tableItem", tableItem);
				refreshLastQuery();
				break;
		}
	};

	const formatData = (data = []) => {
		let nullishFields = ['active_case_id'];
		const formattedData = data.map((item) => {
			Object.entries(item).forEach(([key, value]) => {
				if (typeof value === 'boolean') {
					item[key] = value ? 'Yes' : 'No';
				} else if (nullishFields.includes(key)) {
					item[key] = value ? "Active" : "Inactive";
				}
			});
			return item;
		});
		setReportData(formattedData);
	};

	const headerClickFn = (id, previousSortBy) => {
		if (!id) {
			return;
		}
		let foundIndex = appliedSortOrders.findIndex(s => s[0] === id);
		let sortBy = !previousSortBy ? "ASC" : previousSortBy === "asc" ? "DESC" : '';

		if (!sortBy) { // Not Sorted so remove clicked sort item 
			if (foundIndex > -1) {
				setAppliedSortOrders((prev) => {
					let newSortOrders = [...prev];
					newSortOrders.splice(foundIndex, 1); // remove clicked sort item
					return newSortOrders;
				});
			}
		} else { // Sort by Ascending or Descending 
			let newSort = [id, sortBy];
			if (foundIndex > -1) { // replaces existing 
				setAppliedSortOrders((prev) => {
					let newSortOrders = [...prev];
					newSortOrders[foundIndex] = newSort;
					return newSortOrders;
				});
			} else { // inserts 
				setAppliedSortOrders((prev) => [...prev, newSort]);
			}
		}
	};

	/**
	 * reportSearchHandler
	 * @param {object} params - { requestedFields, ...filterParams }
	 * @param {boolean} saveLastQuery 
	 */
	const reportSearchHandler = async (params = {}, saveLastQuery = true) => {
		if (!params?.requestedFields || params.requestedFields.length === 0) {
			setSearching(false);
			return
		}
		setSearching(true);
		try {
			params = removeEmptyFromObj(params);
			params.page = activePage;
			params.limit = perPage;
			params.navSize = 4; // How many buttons to show for navigation
			params.order = appliedSortOrders; // Example [[id, 'ASC']]
			let { error, response, data } = await claimantAPI.getReport(params);
			if (error) {
				if (typeof response?.error === 'string') {
					throw new Error(`${error} - ${response.error}`);
				} else if (typeof response === 'string') {
					throw new Error(`${error} - ${response}`);
				}
				throw new Error(`${error} - There was an error searching claimants.`);
			}

			let { rows = [], pages = [] } = data || {}; // data = { count = 0, rows = [], limit, pageCount, page, pages = [1,2,3,10] } 
			if (!rows || (Array.isArray(rows) && rows.length === 0)) {
				setReportData([]);
				setActivePage(1); // Make sure we don't get stuck loading nothing
			} else {
				formatData(rows);
				setPages(pages);
				setColumns(params?.requestedFields || defaultColumns);
			}

			if (saveLastQuery) {
				setLastQuery(params);
			}
		} catch (error) {
			console.log("Error searching claimant data", error);
			setReportData([]);
			setActivePage(1);
		}
		setSearching(false);
	};

	const clearReportParams = () => {
		if (!(JSON.stringify(reportParams) === JSON.stringify(defaultReportParams))) {
			setReportParams(defaultReportParams);
		}
		if (!(JSON.stringify(appliedSortOrders) === JSON.stringify([defaultSort]))) {
			setAppliedSortOrders([defaultSort]);
		}
		if (!(activePage === defaultReportParams.page)) {
			setActivePage(defaultReportParams.page);
		}
		if (!(perPage === defaultReportParams.limit)) {
			setPerPage(defaultReportParams.limit);
		}
	};

	const refreshLastQuery = async () => {
		await reportSearchHandler(lastQuery, false);
	}

	const queryInactiveClaimant = () => {
		const formData = {
			first_name: "",
			middle_name: "",
			last_name: "",
			active_claimant: "false",
		};
		const formFieldsArray = Object.keys(formData);
		const requestedFields = allowedColumns.filter((col) => formFieldsArray.includes(col.accessor))
			.map((col) => ({ ...col, ...(formData[col.accessor] !== '' && { value: formData[col.accessor] }) }));
		setReportParams((prev) => ({ ...prev, requestedFields }));
	};

	const saveReport = async () => {
		if (!reportParams?.requestedFields || reportParams.requestedFields.length === 0) {
			return await Swal.fire({
				icon: "error",
				title: "Oops...",
				text: "You must have at least one column selected to save a report.",
			});
		}

		let { account_id, user_id: creation_user_id } = auth.getProfile()?.data || {};
		if (!account_id || !creation_user_id) {
			return await Swal.fire({
				icon: "error",
				title: "Oops...",
				text: "You must be logged in to save a report.",
			});
		}

		// Get report title from user 
		const { isConfirmed: nameIsConfirmed, value: title } = await Swal.fire({
			title: "Save As",
			input: "text",
			inputLabel: "Your report name",
			inputPlaceholder: "Enter report name",
			inputAttributes: {
				autocapitalize: "off",
				autocorrect: "off",
			},
			showCancelButton: true,
			inputValidator: (value) => {
				if (!value) {
					return "You need to write something!";
				}
			},
		});
		if (!nameIsConfirmed || !title) {
			return;
		}

		// Save report 
		const { isConfirmed, value } = await Swal.fire({
			title: `Saving Report as ${title}`,
			input: "text",
			inputLabel: "Description",
			inputPlaceholder: "Enter report description",
			inputAttributes: {
				autocapitalize: "off",
				autocorrect: "off",
			},
			showCancelButton: true,
			confirmButtonText: 'Save',
			showLoaderOnConfirm: true,
			preConfirm: async (description) => {
				try {
					let formData = reportParams.requestedFields.reduce((acc, field) => ({ ...acc, [field.accessor]: field.value || '' }), {});
					let requested_fields = JSON.stringify(formData);
					let saveReportParams = { account_id, creation_user_id, requested_fields, title, description };
					const { error, response, data } = await claimantAPI.createClaimantReport(account_id, saveReportParams);
					if (error) {
						if (typeof response?.error === 'string') {
							throw new Error(`${error} - ${response.error}`);
						} else if (typeof response === 'string') {
							throw new Error(`${error} - ${response}`);
						}
						throw new Error(`${error} - There was an error searching claimants.`);
					}
					return data;
				} catch (error) {
					console.log("Error saving report", error);
					Swal.showValidationMessage(`Error saving report: ${error}`);
					return {};
				}
			},
			allowOutsideClick: () => !Swal.isLoading()
		});

		if (!isConfirmed) {
			return await Swal.fire({
				icon: "warning",
				title: "Warning",
				text: "There was an error saving your report.",
			});
		}

		const isConfirmedSuccess = await Swal.fire({
			icon: "success",
			title: "Report Saved",
			text: `Your report has been saved as ${title}${value?.description ? " with description: " + value.description : ""}.`,
		}).then((data) => data.isConfirmed);

		if (isConfirmedSuccess) {
			await searchAllClaimantReport();
		}
	};

	const updateReport = async () => {
		if (!selectedReport?.id) {
			return await Swal.fire({
				icon: "error",
				title: "Oops...",
				text: "You must have a report selected to update a report.",
			});
		}

		let { account_id, user_id: creation_user_id } = auth.getProfile()?.data || {};
		if (!account_id || !creation_user_id) {
			return await Swal.fire({
				icon: "error",
				title: "Oops...",
				text: "You must be logged in to save a report.",
			});
		}

		// Update Report Title from user 
		const { isConfirmed: nameIsConfirmed, value: title } = await Swal.fire({
			title: "Save As",
			input: "text",
			inputLabel: "Your report name",
			inputValue: selectedReport.title,
			inputPlaceholder: "Enter report name",
			inputAttributes: {
				autocapitalize: "off",
				autocorrect: "off",
			},
			showCancelButton: true,
			inputValidator: (value) => {
				if (!value) {
					return "You need to write something!";
				}
			},
		});
		if (!nameIsConfirmed || !title) {
			return;
		}

		// Update Report 
		const { isConfirmed, value } = await Swal.fire({
			title: `Updating Report ${selectedReport.title !== title ? `as ${title}` : selectedReport.title}`,
			input: "text",
			inputLabel: "Description",
			inputValue: selectedReport.description,
			inputPlaceholder: "Enter report description",
			inputAttributes: {
				autocapitalize: "off",
				autocorrect: "off",
			},
			showCancelButton: true,
			confirmButtonText: 'Update',
			showLoaderOnConfirm: true,
			preConfirm: async (description) => {
				try {
					let formData = reportParams.requestedFields.reduce((acc, field) => ({ ...acc, [field.accessor]: field.value || '' }), {});
					let requested_fields = JSON.stringify(formData);
					let updateReportParams = { account_id, creation_user_id, requested_fields, title, description };
					const { error, response, data } = await claimantAPI.updateClaimantReport(account_id, selectedReport.id, updateReportParams);
					if (error) {
						if (typeof response?.error === 'string') {
							throw new Error(`${error} - ${response.error}`);
						} else if (typeof response === 'string') {
							throw new Error(`${error} - ${response}`);
						}
						throw new Error(`${error} - There was an error searching claimants.`);
					}
					return data;
				} catch (error) {
					console.log("Error updating report", error);
					Swal.showValidationMessage(`Error updating report: ${error}`);
					return {};
				}
			},
			allowOutsideClick: () => !Swal.isLoading()
		});

		if (!isConfirmed) {
			return await Swal.fire({
				icon: "warning",
				title: "Warning",
				text: "There was an error updating your report.",
			});
		}

		const isConfirmedSuccess = await Swal.fire({
			icon: "success",
			title: "Report Updated",
			text: `Your report has been updated as ${title}${value?.description ? " with description: " + value.description : ""}.`,
		}).then((data) => data.isConfirmed);

		if (isConfirmedSuccess) {
			await searchAllClaimantReport();
		}
	};

	// Saved reports 
	const searchAllClaimantReport = async (params) => {
		let { account_id, user_id: creation_user_id } = auth.getProfile()?.data || {};
		if (!account_id || !creation_user_id) {
			return;
		}

		let queryParams = { ...params, account_id, creation_user_id };
		try {
			const { error, response, data } = await claimantAPI.searchAllClaimantReport(queryParams);
			if (error) {
				if (typeof response?.error === 'string') {
					throw new Error(`${error} - ${response.error}`);
				} else if (typeof response === 'string') {
					throw new Error(`${error} - ${response}`);
				}
				throw new Error(`${error} - There was an error searching claimants.`);
			}
			let { rows = [] } = data || {};
			setClaimantReports(rows);
		} catch (error) {
			console.log("Error search for claimant reports", error);
			setClaimantReports([]);
		}
		return;
	};

	const loadClaimantReport = async (claimantReportId) => {
		if (!claimantReportId) {
			return;
		}

		let { account_id } = auth.getProfile()?.data || {};
		if (!account_id) {
			return await Swal.fire({
				icon: "error",
				title: "Oops...",
				text: "You must be logged in to save a report.",
			});
		}

		let requestedFields = [];
		try {
			const { error, response, data } = await claimantAPI.getClaimantReport(account_id, claimantReportId);
			if (error) {
				if (typeof response?.error === 'string') {
					throw new Error(`${error} - ${response.error}`);
				} else if (typeof response === 'string') {
					throw new Error(`${error} - ${response}`);
				}
				throw new Error(`${error} - There was an error searching claimants.`);
			}
			let { requested_fields } = data || {};
			try {
				let parsedRequestedFields = JSON.parse(requested_fields);
				if (Array.isArray(parsedRequestedFields)) {
					requestedFields = parsedRequestedFields;
				} else if (parsedRequestedFields != null && typeof parsedRequestedFields === 'object') {
					const formFieldsArray = Object.keys(parsedRequestedFields);
					requestedFields = allowedColumns.filter((col) => formFieldsArray.includes(col.accessor))
						.map((col) => ({ ...col, ...(parsedRequestedFields[col.accessor] !== '' && { value: parsedRequestedFields[col.accessor] }) }));
				}
			} catch (error) {
				console.log("Error parsing requested fields", data);
				throw new Error(`Error parsing requested fields: ${error}`);
			}
		} catch (error) {
			console.log("Error loading claimant report", error);
			requestedFields = [];
			return await Swal.fire({
				icon: "error",
				title: "Oops...",
				text: "There was an error loading your report.",
			});
		}

		if (requestedFields.length > 0) {
			setReportParams((prev) => ({ ...prev, requestedFields }));
			let _selectedReport = claimantReports.find(({ id } = {}) => Number(id) === Number(claimantReportId)) || {};
			if (Object.keys(_selectedReport).length > 0) {
				setSelectedReport(_selectedReport);
			}
		}
	};

	const deleteClaimantReport = async (claimantReportId) => {
		if (!claimantReportId) {
			return;
		}

		let { account_id } = auth.getProfile()?.data || {};
		if (!account_id) {
			return await Swal.fire({
				icon: "error",
				title: "Oops...",
				text: "You must be logged in to save a report.",
			});
		}

		let reportDetails = claimantReports.find((report) => Number(report.id) === Number(claimantReportId));
		const confirmDeletion = await Swal.fire({
			title: `Are you sure that you want to delete ${reportDetails?.title ? reportDetails.title : ""} report?`,
			text: `This action cannot be undone. ${reportDetails?.description ? "Description: " + reportDetails.description : ""}`,
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: `Yes, delete it!`
		}).then((result) => result.isConfirmed);
		if (!confirmDeletion) {
			return;
		}

		let swalMessage = "";
		try {
			const { error, response, message } = await claimantAPI.deleteClaimantReport(account_id, claimantReportId);
			swalMessage = message;
			if (error) {
				if (typeof response?.error === 'string') {
					throw new Error(`${error} - ${response.error}`);
				} else if (typeof response === 'string') {
					throw new Error(`${error} - ${response}`);
				}
				throw new Error(`${error} - There was an error searching claimants.`);
			}
		} catch (error) {
			swalMessage = error.message || "Error deleting claimant report";
			console.log(swalMessage, error);
			return await Swal.fire({
				icon: "error",
				title: "Oops...",
				text: "There was an error deleting your report.",
			});
		}

		const isConfirmedFinished = await Swal.fire({
			icon: "info",
			title: "Report Deleted",
			text: swalMessage,
		}).then((data) => data.isConfirmed);
		if (isConfirmedFinished) {
			await searchAllClaimantReport();
		}
	};

	useEffect(() => {
		searchAllClaimantReport();
	}, []);

	useEffect(() => {
		reportSearchHandler(reportParams);
	}, [reportParams, reportParams.requestedFields, activePage, perPage, appliedSortOrders]);

	return (
		<div>
			<AdvanceTableWrapper
				columns={columns}
				data={reportData}
				// selection
				sortable
				pagination
				perPage={perPage}
				setPerPage={setPerPage}
			>
				<Card className="mb-3">
					<Card.Header>
						<ClaimantEntryTableHeader
							table
							label={selectedReport?.title || tableHeaderLabel}
							handleBulkActions={handleBulkActions}
							reportParams={reportParams}
							claimantReports={claimantReports}
							selectedReport={selectedReport}
							setSelectedReport={setSelectedReport}
							showClearFilters={Object.values(reportParams?.requestedFields.reduce((acc, field) => ({ ...acc, [field.accessor]: field.value || "" }), {}) || {}).filter(Boolean).length > 0}
						/>
					</Card.Header>
					<Card.Body className="p-0" style={{ overflow: "auto" }}>
						{searching ? <p>Searching claimants....</p> : reportParams?.requestedFields.length === 0 ? <p className="ps-3">No results found. Load report or change filter.</p> :
							<CustomAdvanceTable
								table
								headerClickFn={headerClickFn}
								appliedSortOrders={appliedSortOrders}
								headerClassName="bg-200 text-900 text-nowrap align-middle"
								rowClassName="align-middle white-space-nowrap"
								tableProps={{
									size: 'sm',
									striped: true,
									className: 'fs--1 mb-0 '
								}}
							/>
						}
					</Card.Body>
					<Card.Footer>
						<CustomAdvanceTablePagination
							table
							Pages={Pages}
							activePage={activePage}
							setActivePage={setActivePage}
							totalRecords={reportData.length}
						/>
					</Card.Footer>
				</Card>
			</AdvanceTableWrapper>
			<ClaimantFiltersModalForm
				initialState={reportParams?.requestedFields.reduce((acc, field) => ({ ...acc, [field.accessor]: field.value || "" }), {}) || {}}
				modalState={reportModalIsShown}
				setModalState={setReportModalIsShown}
				availableColumns={allowedColumns}
				setReportParams={setReportParams}
				selectedReport={selectedReport}
				updateReport={updateReport}
			/>
		</div>
	);
};

ClaimantEntryTable.propTypes = {
	allowedColumns: PropTypes.array,
	tableHeaderLabel: PropTypes.string,
};

export default ClaimantEntryTable;