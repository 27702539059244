import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';

const FindAUserForm = ({ setParentModalState = () => { }, searchParams, setSearchParams, requiredSearchParams = {} }) => {
	let defaultState = {
		first_name: searchParams.first_name || null,
		last_name: searchParams.last_name || null,
		email: searchParams.email || null,

	};
	const [formData, setFormData] = useState(defaultState);

	const handleInputChange = (event) => {
		const { name, value } = event.target;
		setFormData({ ...formData, [name]: value });
	};

	// Apply Search Params
	const handleApply = e => {
		e.stopPropagation();
		e.preventDefault();
		setSearchParams(formData);
		setParentModalState(false);
	};

	// Clear Search Params
	const handleClear = () => {
		setSearchParams({
			...requiredSearchParams
		});
		setParentModalState(false);
	};

	// const handleEdit = () => {
	// 	setIsEditing(true);
	// 	setPreviousFormData(formData);
	// };

	// const handleCancel = () => {
	// 	setIsEditing(false);
	// 	setFormData(previousFormData);
	// };

	// const handleSave = () => {
	// 	setIsEditing(false);
	// 	setPreviousFormData(formData);
	// };



	return (
		<Form onSubmit={e => handleApply(e)} >
			<Row>
				<Form.Group as={Col} controlId="first_name">
					<Form.Label>First Name</Form.Label>
					<Form.Control
						size="sm"
						name="first_name"
						value={formData.first_name}
						onChange={handleInputChange}
						className={""}
					/>
				</Form.Group>

				<Form.Group as={Col} controlId="last_name">
					<Form.Label>Last Name</Form.Label>
					<Form.Control size="sm" name="last_name" value={formData.last_name} onChange={handleInputChange} className={""} />
				</Form.Group>
			</Row>

			<Row>
				<Form.Group as={Col} controlId="email">
					<Form.Label>Email</Form.Label>
					<Form.Control size="sm" name="email" value={formData.email} onChange={handleInputChange} className={""} />
				</Form.Group>
			</Row>


			{/* <Form.Group controlId="exampleForm.ControlInput1">
				<Form.Label>Email</Form.Label>
				<Form.Control size="sm" type="email" name="email" value={user?.email || ""} disabled />
			</Form.Group> */}

			{/* <Form.Group controlId="exampleForm.ControlInput1">
				<Form.Label>SSN</Form.Label>
				<Form.Control size="sm" type="text" name="ssn" value={formData.ssn} onChange={handleInputChange} />
			</Form.Group> */}

			<div className="mt-3">
				<Button variant="primary" type="submit" size="sm" >
					Apply
				</Button>

				<span className="mx-2" />
				<Button variant="outline-danger" size="sm" onClick={handleClear}>
					Clear
				</Button>
			</div>
		</Form>
	);
};

export default FindAUserForm;