import React, { useState } from 'react';
import PropTypes from 'prop-types';
import WizardInput from './WizardInput';
import { Button } from 'react-bootstrap';
import IconAlert from '../common/IconAlert';
import { accessVerificationAPI } from '../../utils/api/fulcrum-api';

const AccessCodeForm = ({ uuid, register, errors, user, showIncorrectAccessCodeToast, setShowIncorrectAccessCodeToast }) => {

	const [newAccessCodeSent, setNewAccessCodeSent] = useState(false);

	const sendNewAccessCodeLink = async () => {
		try {
			let result = await accessVerificationAPI.resendGenericAccessCodeRoute(uuid, {});
			if (!result?.data) {
				throw new Error('Unabled to send new access code.');
			}
			setNewAccessCodeSent(true);
		} catch (error) {
			alert('Unable to send new access code.');
		}
	};

	return (
		<>
			<p>An access code has been sent to <b>{user?.primary_email || 'your email'}</b>. You'll need to provide this access code below to complete the email verification process.</p>

			{newAccessCodeSent &&
				<IconAlert
					variant="success"
					className="mb-3"
					dismissible={true}
					onClose={() => setNewAccessCodeSent(false)}
				>
					<p>New access code sent.</p>
				</IconAlert>
			}

			{showIncorrectAccessCodeToast &&
				<IconAlert
					variant="danger"
					className="mb-3"
					dismissible={true}
					onClose={() => setShowIncorrectAccessCodeToast(false)}
				>
					<p>Incorrect access code.</p>
				</IconAlert>
			}

			<WizardInput
				label="Access Code"
				name="access_key"
				errors={errors}
				formGroupProps={{ className: 'mb-3' }}
				formControlProps={{
					...register('access_key', {
						required: 'Access key is required'
					})
				}}
			/>

			<Button variant='link' className="pl-0" onClick={() => { sendNewAccessCodeLink() }}>Send new access link.</Button>
		</>
	);
};

AccessCodeForm.propTypes = {
	register: PropTypes.func.isRequired,
	errors: PropTypes.object,
	watch: PropTypes.func,
	user: PropTypes.object,
	uuid: PropTypes.string,
	showIncorrectAccessCodeToast: PropTypes.bool,
	setShowIncorrectAccessCodeToast: PropTypes.func
};

export default AccessCodeForm;
