import React, { forwardRef } from 'react';
import { Card } from 'react-bootstrap';

const CaliforniaPrivacyRights = forwardRef((props, ref) => {
	return (
		<Card className="mb-3" id="california-privacy-rights" ref={ref}>
			<Card.Header className="bg-light">
				<h5 className="mb-0">California Privay Rights</h5>
			</Card.Header>
			<Card.Body>
				<p className="mb-0 ps-3">
					If you are a California resident, California law may provide you with additional rights regarding the use of your information.  To learn more about your California privacy rights, visit http://www.oag.ca.gov/privacy/privacy-laws.
				</p>
			</Card.Body>
		</Card>
	);
});

export default CaliforniaPrivacyRights;