import React from 'react';
import FormHeader from './FormHeader';
import SignatureBlock from './SignatureBlock';

const AccountSettings = ({ account }) => {
	return (
		<div id="user-settings-container">
			<FormHeader account={account} />
			<hr />
			<SignatureBlock account={account} />
		</div>
	);
};


export default AccountSettings;