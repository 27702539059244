import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import FormControl from 'react-bootstrap/FormControl';
import InputGroup from 'react-bootstrap/InputGroup';
import ListGroup from 'react-bootstrap/ListGroup';
import { myFormsAPI } from 'utils/api/fulcrum-api';
import { FormLabel, Row, Col } from 'react-bootstrap';

const MyFormFinder = ({ handleClose = () => { }, handleFormSelection = () => { } }) => {

	let defaultFormState = {
		searchTerm: '',
	};
	const [formData, setFormData] = useState(defaultFormState);
	const [foundForms, setFoundForms] = useState([]);


	// Handle Input Change
	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setFormData({ ...formData, [name]: value });
	}

	// Handle select form
	const handleSelectForm = (form) => {
		handleFormSelection({ ...form });
		handleClose();
	}


	// Search for forms
	const searchForms = async (e) => {
		e.stopPropagation();
		e.preventDefault();
		try {
			let result = await myFormsAPI.searchMyForms({ searchTerm: formData.searchTerm });
			if (!result?.data?.rows) {
				throw new Error('Error getting forms');
			}
			setFoundForms(result.data.rows);
		} catch (error) {
			console.log(error);
		}
	};

	useEffect(() => {
	}, []);

	return (
		<>
			<Form onSubmit={e => searchForms(e)}>

				<InputGroup className="mb-3">
					<FormControl
						placeholder="Search forms..."
						aria-label="Search forms"
						aria-describedby="basic-addon2"
						name="searchTerm"
						value={formData.searchTerm}
						onChange={handleInputChange}
					/>
					<Button type="submit" variant="outline-secondary" id="button-addon2">
						Search
					</Button>
				</InputGroup>
			</Form>

			<div>
				{foundForms.length > 0 && (
					<>
						<h5>Found Forms</h5>
						<ListGroup>
							{foundForms.map((form, index) => (
								<ListGroup.Item key={index} action onClick={() => handleSelectForm(form)}>
									<Row>
										<Col>
											<span>{form.title}</span>
											<Button
												onClick={() => handleSelectForm(form, "My Form")}
												className="float-end"
												variant="primary"
											>
												Select
											</Button>

										</Col>
									</Row>
								</ListGroup.Item>
							))}
						</ListGroup>
					</>
				)}
				{/* {foundForms.map((form, index) => {
					return (
						<div key={index} className="mb-3">
							<FormLabel>{form.title}</FormLabel>
						</div>
					)
				})} */}
			</div>
		</>
	);
};

MyFormFinder.propTypes = {
	handleClose: PropTypes.func,
	handleFormSelection: PropTypes.func
}

export default MyFormFinder;