import React, { useRef, useState, useEffect } from 'react';
import WebViewer from '@pdftron/pdfjs-express';
import PropTypes from 'prop-types';
import Swal from 'sweetalert2';
const PDFJS_LICENSE_KEY = process.env.REACT_APP_PDFJS_LICENSE_KEY || 'wpdS767IzJPdSPuEAABI'; // Plus License Key
const defaultHeaderButtons = ['resetButton', 'infoButton', 'printButton', 'downloadZipButton', 'downloadPdfButton'];

const PDFJSViewerPreview = ({ getPdfStream = async () => { }, captureCallback = async () => { },
  pdfFilename = 'myfile.pdf', xfdfInitialState = '', setWarningMessages = () => { }, setWarningMessageType = () => { },
  headerButtons = defaultHeaderButtons, toggleResetFromParent = false, documentLoading = true, setDocumentLoading = () => { }, ...props
} = {}) => {
  if (!pdfFilename || !pdfFilename.endsWith(".pdf")) {
    pdfFilename = pdfFilename ? pdfFilename + ".pdf" : "myfile.pdf";
  }
  if (!headerButtons || Array.isArray(headerButtons) && headerButtons.length === 0) {
    headerButtons = defaultHeaderButtons;
  }
  headerButtons.map((btn) => !`${btn}`.toLowerCase().includes("button") ? `${btn}Button` : btn); // add 'Button' to the end of each button name if it doesn't already have it

  const viewerRef = useRef(null);
  const instanceRef = useRef(null);
  const [toggleReset, setToggleReset] = useState(true);

  /**
  * generateAnnotationImage
  * @param {*} annotation 
  * @param {integer} paddingSize 
  * @param {*} instance 
  * @param {integer} stackMultiplier - How many times to stack the image 
  * @returns 
  */
  const generateAnnotationImage = (annotation, paddingSize = 0, instance = instanceRef.current, stackMultiplier = 4) => {
    if (!annotation) {
      setWarningMessageType("danger");
      setWarningMessages((prev) => [...prev, "generateAnnotationImage: annotation is null"]);
      return null;
    }
    if (isNaN(paddingSize) || paddingSize < 0) {
      paddingSize = 0;
    }
    const canvas = document.createElement('canvas');
    canvas.width = annotation.Width + (2 * paddingSize);
    canvas.height = annotation.Height + (2 * paddingSize);

    // Set the canvas context to the annotation's matrix 
    const ctx = canvas.getContext('2d');
    ctx.translate(-annotation.X + paddingSize, -annotation.Y + paddingSize);
    ctx.imageSmoothingEnabled = true;

    // Draw the annotation signature on the canvas 
    const pageMatrix = instance.docViewer.getDocument().getPageMatrix(annotation.PageNumber);
    annotation.draw(ctx, pageMatrix);

    // Draw the image on the final canvas multiple times for better quality 
    if (stackMultiplier > 1) {
      // stackMultiplier - 1 because the first image is already drawn on the canvas (ctx) above 
      const iterationLoop = [...Array(stackMultiplier - 1).keys()]; // [0, 1]
      iterationLoop.forEach((i) => {
        ctx.drawImage(canvas, 0, 0);
      });
    }

    return canvas.toDataURL("image/png");
  };

  /**
   * captureFields
   * @param {*} viewerInstance 
   * @param {function} cb 
   * @param {object} params 
   * @returns 
   */
  const captureFields = async (viewerInstance = instanceRef.current, cb = captureCallback, params = {}) => {
    let { return_type = "pdf", pdf_filename = pdfFilename, ...cbParams } = params || {};
    if (!viewerInstance) {
      setWarningMessageType("danger");
      setWarningMessages((prev) => [...prev, "captureFields: viewerInstance is null"]);
      return;
    }

    const { Core, Annotations } = viewerInstance || {};
    const { annotationManager } = Core || {};
    if (!annotationManager) {
      setWarningMessageType("danger");
      setWarningMessages((prev) => [...prev, "captureFields: annotationManager is null"]);
      return;
    }

    const xfdfString = await annotationManager.exportAnnotations();
    const fieldManager = await annotationManager.getFieldManager();
    const annots = await annotationManager.getAnnotationsList();

    let extractedFields = {};
    let renameTypeFields = {
      Tx: 'text',
      Btn: 'button',
      Ch: 'checkbox',
      Sig: 'signature',
    };

    fieldManager.forEachField(async field => {
      let key = field.name || field.rd;
      let input_type = field.type in renameTypeFields ? renameTypeFields[field.type] : field.type || field.Ar;

      if (field?.Ve[0] && field.Ve[0] instanceof Annotations.SignatureWidgetAnnotation) {
        let base64 = null;
        annots.forEach(async (annot) => {
          if (annot?.Qa?.rd === key) {
            if (annot?.Bz?.ToolName === "AnnotationCreateFreeHand") {
              base64 = generateAnnotationImage(annot?.Bz || [], 0, viewerInstance, 4);
              extractedFields[key] = base64;
            } else if (annot?.Bz?.ToolName === "AnnotationCreateRubberStamp") {
              base64 = annot?.Bz?.image.src;
            }

            extractedFields[key] = {
              key,
              value: base64,
              input_type,
              page: annot?.Bz?.PageNumber,
              rect: {
                x: annot?.Bz?.X,
                y: annot?.Bz?.Y,
                width: annot?.Bz?.Width,
                height: annot?.Bz?.Height,
              },
            };
          }
        });
      } else { // All other fields
        extractedFields[key] = {
          key,
          value: field.value || field.getValue(),
          input_type,
        };
      }
    });

    if (typeof cb === "function") {
      await cb({ fields: extractedFields, xfdf: xfdfString, return_type, pdf_filename, ...cbParams });
      if (cbParams.myFormResponseId) {
        setToggleReset((prev) => !prev);
      }
    } else {
      let flatFields = Object.entries(extractedFields)
        .map(([key, valueObj]) => ({ [key || valueObj.key]: valueObj.value }))
        .filter((obj) => Object.values(obj)[0]); // Remove empty values 

      console.log("captureFields", flatFields);
      await Swal.fire({
        title: `${flatFields.length} Fields Captured`,
        html: `<div class="list-group list-group-flush>
          <div class="list-group-item list-group-item-info">${pdfFilename}</div>
          ${flatFields.map((obj) =>
          Object.entries(obj).map(([k, v]) => v.includes(';base64,') ? `<span>${k}<span><img src="${v}" alt="${k}" />` : `${k}: ${v}`)
        ).flat().map((listItem, idx) =>
          `<div class="list-group-item list-group-item-info">${listItem}</div>`).join("")}
        </div>`,
        icon: 'info',
        confirmButtonText: 'Ok'
      });
    }

    return { extractedFields, xfdfString };
  };

  const resetFields = async () => {
    setWarningMessages([]);
    setToggleReset((prev) => !prev);
  };

  const initializeWebViewer = async () => {
    const WebViewerParams = {
      path: '/webviewer/lib',
      licenseKey: PDFJS_LICENSE_KEY,
      disabledElements: [
        'miscToolGroupButton'
      ]
    };

    instanceRef.current = await WebViewer(WebViewerParams, viewerRef.current);
    setToggleReset((prev) => !prev);
  }; // END initializeWebViewer

  const loadDocument = async (instance = instanceRef.current) => {
    setDocumentLoading(true);
    const { Core, docViewer } = instance || {};
    const { documentViewer, annotationManager } = Core || {};
    let claimant_id, user_id, myFormResponseId, pdfStream, resp;
    try {
      resp = await getPdfStream();
    } catch (error) {
      console.log("initializeWebViewer > getPdfStream Error", error);
      resp = error?.response || error || { status: 500, statusText: error?.message || 'Unknown Error', message: error?.message || 'Unknown Error' };
    }

    if (resp instanceof Blob) {
      pdfStream = resp;
    } else if (resp.pdfStream instanceof Blob) {
      pdfStream = resp.pdfStream;
      pdfFilename = resp.pdfFilename || pdfFilename;
      myFormResponseId = resp?.myFormResponseId;
      claimant_id = resp?.claimant_id;
      user_id = resp?.user_id;
    }

    if (!pdfStream) {
      await Swal.fire({
        title: 'Error',
        text: resp?.message || resp?.statusText || resp?.status || 'Unable to load document, You do not have permission to view this document, are you logged in?',
        icon: 'error',
        confirmButtonText: 'Ok'
      });
      return;
    }

    instance.UI.loadDocument(pdfStream, { filename: pdfFilename });
    instance.UI.setHeaderItems(header => {
      const items = header.getItems().slice(9, -3);
      items && header.update(items);
    });

    instance.disableTools(['AnnotationEdit']);
    instance.setToolMode('Pan');
    docViewer.on('toolModeUpdated', (annot) => {
      if (annot.name === 'AnnotationEdit') {
        instance.setToolMode('Pan');
      }
    });

    instance.UI.setHeaderItems(function (header) {
      // get the tools overlay
      const toolsOverlay = header.getHeader('toolbarGroup-Annotate').get('toolsOverlay');
      toolsOverlay && header.getHeader('toolbarGroup-Annotate').delete('toolsOverlay');

      // add the line tool to the top header
      header.getHeader('default').push({
        type: 'toolGroupButton',
        toolGroup: 'lineTools',
        dataElement: 'lineToolGroupButton',
        title: 'annotation.line',
      });

      // add the tools overlay to the top header
      toolsOverlay && header.push(toolsOverlay);
    });

    // hide the ribbons and second header
    instance.UI.disableElements(['ribbons']);
    instance.UI.disableElements(['toolsHeader']);

    annotationManager.addEventListener('annotationChanged', (annots) => {
      console.log("annotationChanged", annots[0]);
    });

    annotationManager.addEventListener('fieldChanged', async (field, value) => {
      console.log('Field changed:', { [field]: value });
    });

    instance.UI.iframeWindow.addEventListener('loaderror', (error) => {
      const errorMessage = `Error loading document: ${error?.detail?.message}`;
      console.log('loaderror', error, errorMessage);
      setWarningMessageType("danger");
      setWarningMessages((prev) => [...new Set([...prev, errorMessage])]); // Remove duplicate errors 
      setDocumentLoading(false);
    });

    // adding an event listener for when a document is loaded
    Core.documentViewer.addEventListener('documentLoaded', async () => {
      setDocumentLoading(false);
      console.log('document loaded');
      documentViewer.getAnnotationsLoadedPromise().then(async () => {
        if (xfdfInitialState) {
          await instance.Core.annotationManager.importAnnotations(xfdfInitialState);
        }
      });

      let cbParams = {
        pdf_filename: pdfFilename,
        ...(claimant_id && { claimant_id }),
        ...(user_id && { user_id }),
        ...(myFormResponseId && { myFormResponseId }),
      };

      const resetButton = {
        type: 'actionButton',
        img: 'cancel-24px',
        title: 'Reset Fields',
        onClick: () => (resetFields()),
        dataElement: 'resetButton',
      };

      const printButton = {
        type: 'actionButton',
        img: 'icon-header-print-line',
        title: 'Print Flattened PDF',
        onClick: () => {
          instance.UI.print()
        },
        dataElement: 'printButton',
      };

      const downloadZipButton = {
        type: 'actionButton',
        img: 'ic_fileattachment_24px',
        title: 'Generate Flattened PDF and Field Data, and download as zip',
        onClick: () => (captureFields(instance, captureCallback, { ...cbParams, return_type: 'zip' })),
        dataElement: 'downloadZipButton',
      };

      const downloadPdfButton = {
        type: 'actionButton',
        img: 'icon-header-download',
        title: 'Generate Flattened PDF and Field Data',
        onClick: () => (captureFields(instance, captureCallback, { ...cbParams, return_type: 'pdf' })),
        dataElement: 'downloadPdfButton',
      };

      const infoButton = {
        type: 'actionButton',
        img: 'icon-info',
        title: 'Show Field Data',
        onClick: () => (captureFields(instance, null, null)),
        dataElement: 'infoButton',
      };

      let enabledButtons = [resetButton, infoButton, printButton, downloadZipButton, downloadPdfButton].filter((btn) => (headerButtons.includes(btn.dataElement)));
      instance.UI.setHeaderItems((header) => (header.update(enabledButtons)));
    });

    // adding an event listener for when the page number has changed
    Core.documentViewer.addEventListener('pageNumberUpdated', (pageNumber) => {
      console.log(`Page number is: ${pageNumber}`);
    });
  }; // END loadDocument 

  useEffect(() => {
    const init = async () => (await initializeWebViewer());
    init();
  }, []);

  useEffect(() => {
    if (instanceRef.current) {
      loadDocument();
    }
  }, [toggleReset, toggleResetFromParent]);

  return (<div className="PDFJSWebviewer h-100" {...props}>
    <div className={`webviewer h-100`} ref={viewerRef} ></div>
  </div>);
};

PDFJSViewerPreview.propTypes = {
  getPdfStream: PropTypes.func.isRequired,
  captureCallback: PropTypes.func,
  pdfFilename: PropTypes.string,
  xfdfInitialState: PropTypes.string,
  setWarningMessages: PropTypes.func,
  setWarningMessageType: PropTypes.func,
  headerButtons: PropTypes.array,
  toggleResetFromParent: PropTypes.bool,
  documentLoading: PropTypes.bool,
  setDocumentLoading: PropTypes.func,
};

export default PDFJSViewerPreview;