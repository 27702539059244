import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, Form } from 'react-bootstrap';
import { revisionLogAPI } from 'utils/api/fulcrum-api';
import { toast } from 'react-toastify';
import Select from 'react-select';
import AppContext from 'context/Context';
import { sleep } from 'utils/functions';
import moment from 'moment';

const RevisionLogDetails = ({ modalState, setModalState = () => { }, selectedRevisionLog, setSelectedRevisionLog = () => { } }) => {
	const {
		config: { isDark }
	} = useContext(AppContext);
	const [log, setLog] = useState({});
	const [loading, setLoading] = useState(true);

	// Get revision log
	const getRevisionLog = async (revisionLogId) => {
		try {
			const response = await revisionLogAPI.getRevisionLogById(selectedRevisionLog?.account_id || null, revisionLogId);
			if (!response?.data) {
				throw new Error('Error getting revision log.');
			}
			setLog(response.data);
		} catch (error) {
			toast.error(error.message, { theme: 'colored' });
			handleClose();
		}
		setLoading(false);
	};

	// Handle modal close
	const handleClose = () => {
		setModalState(false);
		setSelectedRevisionLog(null);
	};

	// Handle modal show
	const handleShow = async () => {
		setLoading(true);
		await sleep(500);
		if (selectedRevisionLog?.id) {
			getRevisionLog(selectedRevisionLog.id);
		} else {
			toast.error('Error getting revision log.', { theme: 'colored' });
			handleClose();
		}
	};

	return (
		<Modal
			show={modalState}
			onShow={handleShow}
			onHide={handleClose}
			size="lg"
			contentClassName="border"
		>
			<Modal.Header
				closeButton
				closeVariant={isDark ? 'white' : undefined}
				className="bg-light px-card border-bottom-0"
			>
				<Modal.Title as="h5">Revision Log Details</Modal.Title>
			</Modal.Header>
			<Modal.Body className="p-card">
				{
					loading ? (
						<div className="text-center">
							<div className="spinner-border" role="status">
								<span className="visually-hidden">Loading...</span>
							</div>
						</div>
					) : (
						<div>
							<span className="fw-bold">ID: </span><span>{log?.id}</span>
							<hr />
							<span className="fw-bold">Modified: </span><span>{log?.createdAt ? moment(log.createdAt).format('MM-DD-YYYY hh:mm A') : 'Unknown'}</span>
							<hr />
							<span className="fw-bold">Table: </span><span>{log?.table}</span>
							<hr />
							<span className="fw-bold">Previous Value: </span>
							<br />
							<span>{log?.previous_value}</span>
							<hr />
							<span className="fw-bold">New Value: </span>
							<br />
							<span>{log?.previous_value}</span>
							<br />
						</div>
					)
				}
			</Modal.Body>
			<Modal.Footer className="border-top-0">
				<Button variant="secondary" onClick={handleClose}>
					Close
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default RevisionLogDetails;