import React, { useState, useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, Col, Row } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import { getColor } from 'helpers/utils';
import * as echarts from 'echarts/core';
import { PieChart } from 'echarts/charts';
import Spinner from 'react-bootstrap/Spinner';
import { claimantAPI } from 'utils/api/fulcrum-api';

import {
	GridComponent,
	TooltipComponent,
	TitleComponent
} from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';
import BasicECharts from 'components/common/BasicEChart';

echarts.use([
	TitleComponent,
	TooltipComponent,
	GridComponent,
	PieChart,
	CanvasRenderer
]);



const getOptions = (data, radius, totalRecords = data.reduce((acc, val) => val.value + acc, 0)) => ({

	color: [
		getColor(data[0].color),
		getColor(data[1].color),
		getColor(data[2].color),
		getColor(data[3].color)
	],

	onEvents: {
		click: function () {
			// alert('test')
		}
	},
	tooltip: {
		padding: [7, 10],
		transitionDuration: 0,
		backgroundColor: getColor('100'),
		borderColor: getColor('300'),
		textStyle: { color: getColor('dark') },
		borderWidth: 1,
		formatter: params =>
			`<strong>${params.data.name}:</strong> ${params.data.value}`
	},
	series: [
		{
			name: `Total Active Cases 
			${totalRecords}`,
			type: 'pie',
			radius,
			avoidLabelOverlap: false,
			emphasis: {
				scale: false
			},
			itemStyle: {
				borderWidth: 2,
				borderColor: getColor('card-bg')
			},
			label: {
				show: true,
				position: 'center',
				formatter: '{a}',
				fontSize: 10,
				color: getColor('dark')
			},
			data
		}
	]
});

const ClaimantFolderMetricsItem = ({ item, index, setSearchParams = () => { } }) => {
	const { name, color, value, searchParams } = item;
	// const navigate = useNavigate();

	// const percentage = ((value * 100) / total).toFixed(0);

	const handleItemClick = () => {
		setSearchParams(searchParams);
	};

	return (
		<Flex
			alignItems="center"
			justifyContent="between"
			className={`fw-semi-bold fs--2 ${index == 0 && 'mt-3'} cursor-pointer`}
			onClick={() => handleItemClick()}
		>
			<p className="mb-1">
				<FontAwesomeIcon
					icon="circle"
					className={`me-2 text-${color || 'text-info'}`}
				/>
				{value} {name}
			</p>
			{/* <div className="d-xxl-none">{value}</div> */}
		</Flex>
	);
};



const ClaimantFolderMetrics = ({ radius, setSearchParams = () => { } }) => {
	const [loading, setLoading] = useState(true);
	const [data, setData] = useState([]);
	const [total, setTotal] = useState(0);

	// const [radius, setRadius] = useState(['50%', '50%', '0%', '0%']);

	// Categorize claimant folder leads
	const categorizedClaimantFolderLeads = (claimants, totalRecords) => {
		let pendingApplication = 0;
		let stateAgency = 0;
		let hearingLevel = 0;
		let appealsCouncil = 0;
		let federalCourt = 0;

		setTotal(totalRecords);

		claimants.forEach((lead) => {
			if (lead?.tasks && lead.tasks.filter(task => {
				if (task.task_type_id === 7 && task.task_status_id !== 3) { return task; }
			}).length > 0) {
				pendingApplication++;
			} else if (lead.office_with_jurisdiction === "Social Security Field Office" && lead?.tasks && lead.tasks.filter(task => {
				if (task.task_type_id === 7 && task.task_status_id === 3) { return task; }
			}).length > 0) {
				stateAgency++;
			} else if (lead.office_with_jurisdiction === "Office of Hearing Operations") {
				hearingLevel++;
			} else if (lead.office_with_jurisdiction === "Appeals Council") {
				appealsCouncil++;
			} else if (lead.office_with_jurisdiction === "Federal Court") {
				federalCourt++;
			}
		});
		let adjustedData = [
			{ id: 1, value: pendingApplication, name: 'Pending Application', color: 'primary', searchParams: { active_case: true, active_claimant: true, task_filter: JSON.stringify({ task_type_id: 7, excludedTaskStatusIdList: [3] }) } },
			{ id: 2, value: stateAgency, name: 'State Agency', color: 'warning', searchParams: { active_case: true, active_claimant: true, office_with_jurisdiction: 'Social Security Field Office', task_filter: JSON.stringify({ task_type_id: 7, includedTaskStatusIdList: [3] }) } },
			{ id: 3, value: hearingLevel, name: 'Hearing Level', color: 'secondary', searchParams: { active_case: true, active_claimant: true, office_with_jurisdiction: 'Office of Hearing Operations' } },
			{ id: 4, value: appealsCouncil, name: 'Appeals Council', color: 'dark', searchParams: { active_case: true, active_claimant: true, office_with_jurisdiction: 'Appeals Council' } },
			{ id: 5, value: federalCourt, name: 'Federal Court', color: 'info', searchParams: { active_case: true, active_claimant: true, office_with_jurisdiction: 'Federal Court' } },
		];
		setData(adjustedData);
	};

	// Get claimant folder data
	const getClaimantFolderData = async () => {
		try {
			let result = await claimantAPI.searchClaimants({
				active_claimant: true,
				active_case: true
			});
			if (!result?.data?.rows) {
				throw new Error('Unable to get claimant folder data');
			}
			categorizedClaimantFolderLeads(result.data.rows, result.data.count);
		} catch (error) {
			console.log(error);
		}
	};

	// const navigate = useNavigate();

	const handleItemClick = (sp = {}) => {
		setSearchParams(sp);
	};

	// const total = data.reduce((acc, val) => val.value + acc, 0);

	useEffect(() => {
		const init = async () => {
			await getClaimantFolderData();
			setLoading(false);
		}
		init();
	}, []);

	useEffect(() => {

	}, [data, radius]);

	return (
		<Card className="h-md-100">
			<Card.Body>
				<Row className="justify-content-between g-0">
					<h6 className="mt-1">Claimant Folder Metrics</h6>


					{
						loading ? (

							<Spinner animation="border" role="status">
								<span className="visually-hidden">Loading...</span>
							</Spinner>
						) :
							<>
								<Col xs={5} sm={6} xxl className="pe-2">
									{data.map((item, index) => (
										<ClaimantFolderMetricsItem
											item={item}
											index={index}
											key={index}
											total={total}
											setSearchParams={setSearchParams}
										/>
									))}
								</Col>
								<Col xs="auto">
									<div className="ps-0">
										{(data?.length > 0 &&

											<BasicECharts
												echarts={echarts}
												options={getOptions(data, radius, total)}
												onEvents={{
													click: (event => { handleItemClick(event.data.searchParams) })
												}}
												style={{ width: '7.625rem', height: '7.625rem' }}
											/>
										)}
									</div>
								</Col>
							</>
					}

				</Row>
			</Card.Body>
		</Card>
	);
};

ClaimantFolderMetricsItem.propTypes = {
	item: PropTypes.shape({
		name: PropTypes.string.isRequired,
		color: PropTypes.string.isRequired,
		value: PropTypes.number.isRequired,
		searchParams: PropTypes.object.isRequired
	}),
	index: PropTypes.number.isRequired,
	total: PropTypes.number.isRequired,
	setSearchParams: PropTypes.func
};

ClaimantFolderMetrics.propTypes = {
	setSearchParams: PropTypes.func,
	radius: PropTypes.number
};

export default ClaimantFolderMetrics;
