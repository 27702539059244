import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Dropdown from 'react-bootstrap/Dropdown';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import CardDropdown from 'components/common/CardDropdown';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import { claimantDoctorAPI } from 'utils/api/fulcrum-api';

const DoctorRow = ({ doctor, claimant, getClaimantDoctors }) => {
	let defaultFormData = {
		doctor: doctor.doctor,
		phone: doctor.phone,
		address: doctor.address,
		start_date: doctor.start_date,
		end_date: doctor.end_date,
		frequency_of_visits: doctor.frequency_of_visits
	};
	const [formData, setFormData] = useState(defaultFormData);
	const [previousFormData, setPreviousFormData] = useState(defaultFormData);

	// Remove a doctor
	const removeDoctor = () => {
		Swal.fire({
			title: 'Permanently delete this doctor record?',
			text: "You won't be able to revert this!",
			icon: 'warning',
			showCansetFormDatalButton: true,
			confirmButtonColor: '#3085d6',
			cansetFormDatalButtonColor: '#d33',
			confirmButtonText: 'Yes, delete it!',
			cancelButtonColor: '#d33',
			showCancelButton: true,
		}).then(async (result) => {
			if (result.isConfirmed) {
				try {
					let result = await claimantDoctorAPI.deleteClaimantDoctor(claimant.id, doctor.id);
					if (!result?.message) {
						throw new Error('No data returned from server');
					}
					getClaimantDoctors();
					toast.warning('Doctor removed', { theme: 'colored' });
				} catch (error) {
					console.error(error);
					toast.error('Error deleting doctor', { theme: 'colored' });
				}
			}
		});
	};

	// Update a doctor
	const updateCurrentDoctor = async (doctorId, data) => {
		try {
			let result = await claimantDoctorAPI.updateClaimantDoctor(claimant.id, doctorId, data);
			console.log(result);
			if (!result) {
				throw new Error('Unable to update doctor');
			}
			setPreviousFormData({ ...formData, ...data });

		} catch (error) {
			console.error(error);
			toast.error('Error updating doctor', {
				theme: 'colored'
			});
		}
	};

	// Save on change
	const saveOnChange = async (e, saveChange) => {
		let { name, value } = e.target;

		if (saveChange && previousFormData[name] !== value) {
			await updateCurrentDoctor(doctor.id, { [name]: value });
		}

		setFormData({ ...formData, [name]: value });
	};

	useEffect(() => {
		let drData = {
			doctor: doctor.doctor,
			phone: doctor.phone,
			address: doctor.address,
			start_date: doctor.start_date,
			end_date: doctor.end_date,
			frequency_of_visits: doctor.frequency_of_visits
		};
		setFormData(drData);
		setPreviousFormData(drData);
	}, [doctor])

	return (
		<Form>
			<Row>
				<Col>
					<div className="d-flex justify-content-between float-end">
						<CardDropdown>
							<div className="py-2">
								<Dropdown.Item onClick={() => { removeDoctor(doctor.id) }} className="text-danger">Delete</Dropdown.Item>
							</div>
						</CardDropdown>
					</div>
				</Col>
			</Row>
			<Row>
				<Col sm={12} className={'px-0'}>
					<Form.Group controlId="doctor">
						<FloatingLabel
							controlId="doctor"
							label="Doctor"
							className=""
							size="sm"
						>
							<Form.Control
								size="sm"
								type="text"
								name="doctor"
								value={formData.doctor}
								onChange={e => saveOnChange(e, false)}
								onBlur={e => saveOnChange(e, true)}
							/>
						</FloatingLabel>
					</Form.Group>
				</Col>
			</Row>
			<Row>
				<Col sm={12} className={'px-0'}>
					<Form.Group controlId="address">
						<FloatingLabel
							controlId="address"
							label="Address"
							className=""
							size="sm"
						>
							<Form.Control
								size="sm"
								type="text"
								name="address"
								value={formData.address}
								onChange={e => saveOnChange(e, false)}
								onBlur={e => saveOnChange(e, true)}
							/>
						</FloatingLabel>
					</Form.Group>
				</Col>
			</Row>
			<Row>
				<Col sm={12} className={'px-0'}>
					<Form.Group controlId="phone">
						<FloatingLabel
							controlId="phone"
							label="Phone Number"
							className=""
							size="sm"
						>
							<Form.Control
								size="sm"
								type="text"
								name="phone"
								value={formData.phone}
								onChange={e => saveOnChange(e, false)}
								onBlur={e => saveOnChange(e, true)}
							/>
						</FloatingLabel>
					</Form.Group>
				</Col>
			</Row>
			<Row>
				<Col sm={6} className={'px-0'}>
					<Form.Group controlId="start_date">
						<FloatingLabel
							controlId="start_date"
							label="First Visit"
							className=""
							size="sm"
						>
							<Form.Control
								size="sm"
								type="date"
								name="start_date"
								value={formData.start_date}
								onChange={e => saveOnChange(e, false)}
								onBlur={e => saveOnChange(e, true)}
							/>
						</FloatingLabel>
					</Form.Group>
				</Col>
				<Col sm={6} className={'px-0'}>
					<Form.Group controlId="end_date">
						<FloatingLabel
							controlId="end_date"
							label="Last Visit"
							className=""
							size="sm"
						>
							<Form.Control
								size="sm"
								type="date"
								name="end_date"
								value={formData.end_date}
								onChange={e => saveOnChange(e, false)}
								onBlur={e => saveOnChange(e, true)}
							/>
						</FloatingLabel>
					</Form.Group>
				</Col>
			</Row>
			<Row>
				<Col sm={12} className={'px-0'}>
					<Form.Group controlId="frequency_of_visits">
						<FloatingLabel
							controlId="frequency_of_visits"
							label="Frequency of Visits"
							className=""
							size="sm"
						>
							<Form.Control
								size="sm"
								type="text"
								name="frequency_of_visits"
								value={formData.frequency_of_visits}
								onChange={e => saveOnChange(e, false)}
								onBlur={e => saveOnChange(e, true)}
							/>
						</FloatingLabel>
					</Form.Group>
				</Col>
			</Row>
		</Form>
	);
};

DoctorRow.propTypes = {
	doctor: PropTypes.object,
	claimant: PropTypes.object,
	getClaimantDoctors: PropTypes.func
}

export default DoctorRow;