import React, { useState, useEffect, useContext, useRef } from 'react';
import { documentAPI, inboxOutboxAPI, myFormsAPI } from 'utils/api/fulcrum-api';
import { Button, Form, Modal, Table, Alert, Spinner, Row, Col } from 'react-bootstrap';
import ClaimantQuickFind from 'components/claimants/find-a-claimant/ClaimantQuickFind';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { formatPhone, isValidPhone, sleep } from 'utils/functions';
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { toast } from 'react-toastify';
import MyFormFinderModal from 'components/pages/documents/my-forms/MyFormFinderModal';
import EditorModal from 'components/pages/documents/my-forms/document-generator/EditorModal';
import PDFViewerModal from 'components/app/pdfjs-viewer/PDFViewerModal';
import CustomMessageEditor from './CustomMessageEditor';
import moment from 'moment';
import auth from 'utils/auth';
import ClaimantDocumentQuickFind from 'components/claimants/claimant-document-quick-find';

const ClaimantCorrespondence = ({ allowMultipleClaimants = false, account_id = auth.getProfile()?.data?.account_id || null, submitRef, handleClose = () => { }, setCustomMessage = () => { }, refreshParent = () => { }, defaultClaimants = [] }) => {
	const [documentTypes, setDocumentTypes] = useState([]);
	const [myFormFinderModal, setMyFormFinderModal] = useState(false);
	const [formEditorModal, setFormEditorModal] = useState(false);
	const [selectedForm, setSelectedForm] = useState(null);
	const [selectedDocuments, setSelectedDocuments] = useState(null);
	const [FaxCoversheetModalState, setFaxCoversheetModalState] = useState(false);
	const [claimantDocuments, setClaimantDocuments] = useState([]);
	const [selectedFileId, setSelectedFileId] = useState(null);
	const [editorModalOptions, setEditorModalOptions] = useState({
		allowNewFormSave: false,
		allowFormSave: true,
	});
	const [allowNewFormSave, setAllowNewFormSave] = useState(false);
	const [pdfViewerModalState, setPdfViewerModalState] = useState(false);
	const [submitting, setSubmitting] = useState(false);
	const [claimants, setClaimants] = useState(defaultClaimants);
	const [otherRecipients, setOtherRecipients] = useState([]);
	const [editorConfig, setEditorConfig] = useState({});
	const [attachments, setAttachments] = useState([]);
	const [forms, setForms] = useState([]);
	let defaultFormData = {
		mode_of_transmission: null,
		recipient_type: null,
		message_type: null,
		subject: '', // Email only
		all_claimants: false,
		documents: [],
		fax_coversheet_content: '',
		fax_coversheet_my_form_id: 53,
		recipient: '',
	};

	const [formData, setFormData] = useState(defaultFormData);

	// Get related data @TODO: Move this to a context
	const getRelatedData = async () => {
		try {
			let docTypeResults = await documentAPI.searchDocumentTypes();
			if (!docTypeResults?.data?.rows) {
				throw new Error('Unable to get documents types.');
			}
			setDocumentTypes(docTypeResults.data.rows);
		} catch (error) {
			alert(error);
		}
	};

	// Handle edit selected 
	const handleEditSelected = (form) => {
		setSelectedForm(form);
		switch (form.file_type) {
			case "My Form":
				setSelectedForm(form);
				setAllowNewFormSave(false);
				setEditorModalOptions({
					...editorModalOptions,
					allowNewFormSave: false,
					allowFormSave: true
				})
				setEditorConfig({
					readonly: false,
				});
				setFormEditorModal(true);

				break;
			case "PDF":
				setPdfViewerModalState(true);
				break;
			default:
				break;
		}
	};

	// Handle mode of transmission change
	const handleModeOfTransmissionChange = (event) => {
		const { name, value } = event.target;
		setOtherRecipients([]);
		setFormData({ ...formData, mode_of_transmission: value, recipient: null, documents: [], message_type: null, subject: '' });
		setClaimants([]);
		setCustomMessage(false);
	}

	// Handle input change
	const handleInputChange = (event) => {
		const { name, value } = event.target;
		setFormData({ ...formData, [name]: value });
	};

	// Handle form submission
	const handleSubmit = async (event) => {
		event.preventDefault();
		setSubmitting(true);
		try {
			let formTemplates = [];
			if (formData.message_type === 'Custom Message') {
				let message = document.getElementById('custom-message-content').innerHTML;
				formTemplates.push({
					content: message,
					type: 'RTF',
					file_type: 'RTF',
					form_type_id: "OTHER",
					title: 'Custom Message',
					description: 'Custom Message',
					order: 1,
				});
			} else {
				formTemplates = forms;
			}

			let attachments = [];
			if (Array.isArray(selectedDocuments)) {
				for (let i = 0; i < selectedDocuments.length; i++) {
					attachments.push({
						document_id: selectedDocuments[i].id,
						file_id: selectedDocuments[i].file_id,
					});
				}
			}

			let result = await inboxOutboxAPI.submitCorrespondence(account_id, {
				...formData,
				claimants: claimants,
				forms: formTemplates,
				recipient_type: "OTHER_RECIPIENTS",
				attachments,
				other_recipients: otherRecipients
			});
			if (result?.message !== "Correspondence submitted successfully.") {
				throw new Error('Unable to submit correspondence.');
			}
			toast.success('Correspondence submitted successfully.', { theme: 'colored' });
			refreshParent();
			handleClose();
		} catch (error) {
			console.log(error);
			toast.error('Unable to submit correspondence.', { theme: 'colored' });
		}
		setSubmitting(false);
	};

	// Add other recipient
	const addOtherRecipient = () => {
		let newOtherRecipient = {
			recipient: ''
		};
		setOtherRecipients([...otherRecipients, newOtherRecipient]);
	};

	// Remove other recipient
	const removeOtherRecipient = (index) => {
		let newOtherRecipients = [...otherRecipients];
		newOtherRecipients.splice(index, 1);
		setOtherRecipients(newOtherRecipients);
	};

	// Handle Claimant Selection// Handle Claimant Selection
	const handleClaimantSelection = (selectedClaimants = []) => {
		// Check if the claimant is already in the list
		if (allowMultipleClaimants) {
			let approvedClaimants = [];
			for (let i = 0; i < selectedClaimants.length; i++) {
				let allowClaimant = true;
				for (let j = 0; j < claimants.length; j++) {
					if (selectedClaimants[i].id === claimants[j].id) {
						allowClaimant = false
					}
				}
				if (allowClaimant) {
					approvedClaimants.push(selectedClaimants[i]);
				}
			}

			setClaimants([...claimants, ...approvedClaimants]);
			setFormData({ ...formData, recipient: null });
		} else {
			setClaimants([selectedClaimants[0]]);
			setFormData({ ...formData, fax_coversheet_content: '', documents: [], recipient: selectedClaimants[0]?.entity?.contact_phones.find(p => p.phone_type_id === "Fax")?.phone });
			getFaxCoversheetTemplate(formData.fax_coversheet_my_form_id, selectedClaimants[0]);
		}


	};

	// Handle form selection
	const handleFormSelection = (form, file_type = "My Form") => {
		console.log(form)
		let newForms = [...forms, { id: Date.now(), ...form, order: forms.length + 1, file_type }];
		setForms(newForms);
	};

	// Handle Editor Save
	const handleEditorSave = (content) => {
		let newForms = forms.map((form, index) => {
			if (form.id === selectedForm.id) {
				return {
					...form,
					content
				}
			}
			return form;
		});

		setForms(newForms);
	};

	// Get claimant documents
	const getClaimantDocuments = async (claimant_id) => {
		if (claimant_id) {
			try {
				let result = await documentAPI.getClaimantDocuments(claimant_id);
				if (!result?.data) {
					throw new Error('Unable to get claimant documents.');
				}
				setClaimantDocuments(result.data.map((document) => {
					return {
						...document,
						value: document.id,
						label: `${document?.document_type?.type || ''} - ${document.title} - ${document?.createdAt ? moment(document.createdAt).format('MM-DD-YYYY hh:mm A') : ''}`,
					}
				}));
			} catch (error) {
				setClaimantDocuments([]);
				setSelectedDocuments(null);
				toast.error('Unable to get claimant documents.', { theme: 'colored' });
			}
		}
	};

	// Handle document selection
	const handleDocumentSelection = (selectedClaimantDocuments = []) => {
		let approvedDocuments = [];

		let currentDocuments = formData.documents || [];

		for (let i = 0; i < selectedClaimantDocuments.length; i++) {
			let allowDocument = true;
			for (let j = 0; j < currentDocuments.length; j++) {
				if (selectedClaimantDocuments[i].id === currentDocuments[j].id) {
					allowDocument = false
				}
			}
			if (allowDocument) {
				approvedDocuments.push(selectedClaimantDocuments[i]);
			}
		}
		setFormData({ ...formData, documents: [...currentDocuments, ...approvedDocuments] });
	};


	// Get fax coversheet template
	const getFaxCoversheetTemplate = async (fax_coversheet_my_form_id, claimant) => {

		let faxCoversheet = `
			<div class="p-3">
			  <h1><strong><span>Fax</span></strong></h1>\n
			  <table class="table table-bordered" style=\"width: 719px; height: 204px;\">\n 
			  <tbody>
			  <tr>
			  <td><p>To:</p></td>
			  <td><p>${claimant?.first_name || ''} ${claimant?.last_name || ''}</p></td>
			  <td><p>From:</p></td>
			  <td><p><span>${claimant?.account?.business_name || ''}</span></p></td>
			  </tr>
			  <tr>
			  <td><p>Fax:</p></td>
			  <td><p><span>${claimant?.entity?.contact_phones.find(p => p.phone_type_id === "Fax")?.phone}</span></p></td>
			  <td><p>Fax:</p></td>
			  <td><p><span></span></p></td>
			  </tr>
			  <tr>
			  <td><p>Phone:</p></td>
			  <td><p><span>${''}</span></p></td>
			  <td><p>Phone:</p></td>
			  <td><p><span>{{account_primary_phone}}</span></p></td>
			  </tr>
			  <tr><td><p>Subject:</p></td>
			  <td><p><span>&nbsp;</span><br></p><br></td>
			  <td><p>Date:</p></td><td><p><span>{{today}}</span></p></td>
			  </tr>
			  </tbody>
			  </table>
			  </div>
			`;

		if (fax_coversheet_my_form_id) {
			try {
				let result = await myFormsAPI.getRenderedMyFormContentById(fax_coversheet_my_form_id, { claimant_id: claimant?.id || null, account_id: claimant?.account_id || null });
				if (!result?.data) {
					throw new Error('Unable to get fax coversheet template.');
				}
				faxCoversheet = result.data.adjustedContent;
			} catch (error) {
			}
		}

		setFormData({ ...formData, fax_coversheet_content: faxCoversheet });
	};


	useEffect(() => {
		setClaimantDocuments([]);
		setSelectedDocuments(null);
		if (formData.recipient_type === 'Social Security Administration' && claimants.length === 1) {
			getClaimantDocuments(claimants[0]?.id || null);
		}
	}, [claimants]);

	useEffect(() => {
		setClaimantDocuments([]);
		setSelectedDocuments(null);
		getRelatedData();
	}, []);

	return (
		<>
			<Form onSubmit={handleSubmit}>

				<Alert variant="info">
					<Alert.Heading>Other Recipient Correspondence</Alert.Heading>
					<p>
						After selecting a mode of transimssion, please select a claimant and the recipients you would like to send the correspondence to.
					</p>
				</Alert>
				<Form.Group>
					<Form.Label className="fw-bold">Mode of Transmission</Form.Label>
					<br />
					<Form.Check
						type="radio"
						inline
						label="Email"
						name="mode_of_transmission"
						id="message_type_for"
						value="EMAIL"
						checked={formData.mode_of_transmission === 'EMAIL'}
						onChange={handleModeOfTransmissionChange}
						disabled={submitting}
					/>
					<Form.Check
						type="radio"
						inline
						label="Regular Mail"
						name="mode_of_transmission"
						id="mode_of_transmission_mail"
						value="MAIL"
						checked={formData.mode_of_transmission === 'MAIL'}
						onChange={handleModeOfTransmissionChange}
						disabled={submitting}
					/>
					<Form.Check
						type="radio"
						inline
						label="SMS"
						name="mode_of_transmission"
						id="mode_of_transmission_sms"
						value="SMS"
						checked={formData.mode_of_transmission === 'SMS'}
						onChange={handleModeOfTransmissionChange}
						disabled={submitting}
					/>
					<Form.Check
						type="radio"
						inline
						label="Fax"
						name="mode_of_transmission"
						id="mode_of_transmission_fax"
						value="FAX"
						checked={formData.mode_of_transmission === 'FAX'}
						onChange={handleModeOfTransmissionChange}
						disabled={submitting}
					/>
				</Form.Group>



				{
					formData.mode_of_transmission &&
					<div>
						<hr />



						<div>
							<div className=" my-3">
								<span className="fw-bold">Assign a Claimant: &nbsp;
									{
										(claimants.length) === 1 ? (
											!submitting && (
												<>
													<span className="text-success">{claimants[0]?.first_name || ''} {claimants[0]?.last_name || ''}
														<ClaimantQuickFind
															buttonLabel={'Change Claimant'}
															buttonVariant={'link'}
															handleClaimantSelection={handleClaimantSelection}
															requiredSearchParams={{ account_id }}
															defaultSearchParams={{}}
															allowMultipleSelect={allowMultipleClaimants}
														/>
													</span>
												</>
											)
										) : (
											<>
												<span className="text-danger">No claimant selected

													{!submitting && (
														<ClaimantQuickFind
															buttonLabel={'Search Claimants'}
															buttonVariant={'link'}
															handleClaimantSelection={handleClaimantSelection}
															requiredSearchParams={{ account_id }}
															defaultSearchParams={{}}
															allowMultipleSelect={allowMultipleClaimants}
														/>
													)}
												</span>
											</>
										)
									}

								</span>
							</div>
						</div>

						{
							formData?.all_claimants || claimants?.length > 0 && (
								<>
									<hr />

									<p className="fw-bold">Add Recipient(s)
										<Button variant="link" className="p-0" onClick={() => addOtherRecipient()}>+ Add recipient</Button>
									</p>
									<div className="mb-3">
										{
											otherRecipients.length > 0 ? (
												<Table bordered size="md" className="bg-white mt-3">
													<thead>
														<tr>
															<th>Recipient</th>
															<th></th>
														</tr>
													</thead>
													<tbody>
														{otherRecipients.map((recipient, index) => (
															<tr key={index}>
																<td>
																	{
																		formData.mode_of_transmission === 'EMAIL' ?
																			<Form.Control
																				type="email"
																				required
																				value={recipient.name}
																				disabled={submitting}
																				className={
																					`${"error" in recipient && (recipient?.error ? 'border-none text-danger bg-soft-danger' : 'bg-soft-success text-success')}`
																				}
																				onChange={(e) => {
																					let { value } = e.target;
																					setOtherRecipients(otherRecipients.map((r, i) => {
																						if (i === index) {
																							r.name = value;
																						}
																						return r;
																					}
																					))
																				}}
																				onBlur={e => {
																					// email regex
																					const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

																					let { value } = e.target;
																					let error = false;
																					if (!emailRegex.test(value)) {
																						error = true;
																					}

																					setOtherRecipients(otherRecipients.map((r, i) => {
																						if (i === index) {
																							r.error = error;
																						}
																						return r;
																					}
																					))
																				}}
																			/>
																			:
																			<Form.Control
																				type="text"
																				required
																				value={recipient.name}
																				className={
																					`${"error" in recipient && (recipient?.error ? 'border-none text-danger bg-soft-danger' : 'bg-soft-success text-success')}`
																				}
																				disabled={submitting}
																				onChange={(e) => {
																					let { value } = e.target;
																					setOtherRecipients(otherRecipients.map((r, i) => {
																						if (i === index) {
																							r.name = value;
																						}
																						return r;
																					}
																					))
																				}}
																				onBlur={e => {
																					let { value } = e.target;

																					let error = true;
																					if (formData.mode_of_transmission === "MAIL") {
																						error = false;
																					} else {
																						const phoneCheckRegex = /^\+?(\d{1,3})?[-. ]?(\(\d{3}\)|\d{3})[-. ]?(\d{3})[-. ]?(\d{4})$/;
																						const result = phoneCheckRegex.test(value);
																						if (result) {
																							error = false;
																						}
																					}

																					setOtherRecipients(otherRecipients.map((r, i) => {
																						if (i === index) {
																							r.error = error;
																						}
																						return r;
																					}
																					))
																				}}
																			/>
																	}
																</td>
																<td>
																	<Button
																		disabled={submitting}
																		variant="link"
																		className="p-0"
																		onClick={() => removeOtherRecipient(index)}
																	>
																		<FontAwesomeIcon className="text-danger" icon={faTrash} />
																	</Button>
																</td>
															</tr>
														))}
													</tbody>
												</Table>
											) : (
												<p className="text-danger">No recipients added</p>
											)
										}
									</div>

									{
										(formData.mode_of_transmission === 'MAIL' || otherRecipients.length > 0) && (
											<>
												{
													formData.mode_of_transmission === "EMAIL" &&
													(
														<>
															<hr />
															<Form.Group controlId="subject">
																<Form.Label>Email Subject</Form.Label>
																<Form.Control
																	size="sm"
																	type="text"
																	name="subject"
																	value={formData.subject}
																	onChange={handleInputChange}
																	required
																	disabled={submitting}
																/>
															</Form.Group>
														</>
													)
												}
												< hr />
												{
													!allowMultipleClaimants && (
														formData.mode_of_transmission === 'FAX' && (
															<>
																<span className="fw-bold">Fax Information: </span>
																<br />
																<br />
																<Form.Group controlId="fax_coversheet_content">
																	<Form.Label className="fw-bold">Fax Cover Sheet</Form.Label>
																	{/* <Button
													variant="link"
													onClick={() => getFaxCoversheetTemplate(formData.fax_coversheet_my_form_id)}
												>
													Refresh Template
												</Button> */}
																	<Button
																		variant="link"
																		onClick={() => setFaxCoversheetModalState(true)}
																	>
																		view / Edit
																	</Button>
																</Form.Group>

																<EditorModal
																	modalState={FaxCoversheetModalState}
																	setModalState={setFaxCoversheetModalState}
																	content={formData?.fax_coversheet_content || ""}
																	handleEditorSave={content => {
																		setFormData({
																			...formData,
																			fax_coversheet_content: content,
																		})
																	}}
																	allowNewFormSave={false}
																	allowPreview={false}
																	setAllowNewFormSave={false}
																	defaultEditorConfig={{
																		readonly: false,
																	}}
																	options={{
																		allowNewFormSave: false,
																		allowFormSave: true,
																	}}
																	clientDataArray={[formData.claimant] || []}
																/>

																{/* <FaxCoversheetModal
												defaultFormData={formData.fax_coversheet_content}
												modalState={FaxCoversheetModalState}
												setModalState={setFaxCoversheetModalState}
												refreshParent={() => { }}
											/> */}
															</>
														)
													)
												}

												<hr />

												{
													!allowMultipleClaimants && formData.mode_of_transmission !== "SMS" &&
													<>
														<p className="fw-bold">Attach Claimant Documents:
															<ClaimantDocumentQuickFind
																buttonLabel={'Browse Claimant Documents'}
																buttonVariant={'link'}
																handleDocumentSelection={handleDocumentSelection}
																requiredSearchParams={{}}
																defaultSearchParams={{}}
																allowMultipleSelect={true}
																claimantId={claimants[0]?.id || null}
																claimant={claimants[0]}
															/>
														</p>

														<Table striped bordered size="md" className="bg-white mt-3">
															<thead>
																<tr>
																	<th>Document Type</th>
																	<th>Title</th>
																	<th>Created At</th>
																	<th>Order</th>
																	<th>Actions</th>
																</tr>
															</thead>
															<tbody>
																{
																	Array.isArray(formData.documents) && formData.documents.length > 0
																		?
																		formData.documents.map((document, index) => (
																			<tr key={`selected_doc_${document?.id}`}>
																				<td>{document?.document_type_id || ''}</td>
																				<td>{document?.title || ''}</td>
																				<td>{document?.createdAt ? moment(document.createdAt).format('MM-DD-YYYY hh:mm A') : ''}</td>
																				<td>{index + 1}
																				</td>
																				<td>
																					<Button variant="link" onClick={async () => { setSelectedFileId(document.file_id); await sleep(1000); setPdfViewerModalState(true); }} className="">View</Button>

																					<Button
																						variant="link"
																						className='text-danger'
																						onClick={() => {
																							const newDocuments = formData.documents.filter((_, i) => i !== index);
																							setFormData({ ...formData, documents: newDocuments });
																						}}
																					>
																						<FontAwesomeIcon icon="trash" />
																					</Button>
																				</td>
																			</tr>
																		))
																		:
																		(
																			<tr>
																				<td colSpan="4" className=''>No documents selected</td>
																			</tr>
																		)

																}
															</tbody>
														</Table>
													</>
												}

												<hr />
												<Form.Group>
													<Form.Label className="fw-bold">Message Type</Form.Label>
													<br />
													<Form.Check
														type="radio"
														inline
														label="Custom Message"
														name="message_type"
														id="message_type_custom_message"
														value="Custom Message"
														checked={formData.message_type === 'Custom Message'}
														onChange={e => {
															let { value } = e.target
															setCustomMessage(value === 'Custom Message' ? true : false);
															setFormData({ ...formData, message_type: value });
														}}
														disabled={submitting}
													/>
													<Form.Check
														type="radio"
														inline
														label="Form Templates"
														name="message_type"
														id="message_type_form_templates"
														value="Form Templates"
														checked={formData.message_type === 'Form Templates'}
														onChange={e => { setCustomMessage(false); setFormData({ ...formData, message_type: e.target.value }) }}
														disabled={submitting}
													/>
												</Form.Group>
												<div>
													<span className="fw-bold">Message body:</span>
													{
														formData.message_type === "Form Templates" && (
															<div>

																<div>
																	<div className="mb-3">
																		<Button
																			variant="link"
																			onClick={() => setMyFormFinderModal(true)}
																		>
																			Select Form Templates
																		</Button>

																		<MyFormFinderModal
																			modalState={myFormFinderModal}
																			setModalState={setMyFormFinderModal}
																			handleFormSelection={handleFormSelection}
																			account_id={account_id}
																		/>
																	</div>
																	{
																		forms.length > 0 ?
																			<div>
																				<Table bordered size="md" className="bg-white mt-3">
																					<thead>
																						<tr>
																							<th>Type</th>
																							<th style={{ width: "50%" }}>Title</th>
																							<th>Form Type</th>
																							<th>Order</th>
																							<th></th>
																						</tr>
																					</thead>
																					<tbody>
																						{
																							forms.map((form, index) => (
																								<tr key={index}>
																									<td>{form.file_type}</td>
																									<td>
																										<Form.Control
																											type="text"
																											name="title"
																											value={form.title}
																											onChange={e => {
																												const newForms = [...forms];
																												newForms[index].title = e.target.value;
																												setForms(newForms);
																											}}
																											required
																										/>
																									</td>
																									<td>
																										<Form.Control
																											as="select"
																											name="form_type_id"
																											value={form.form_type_id}
																											onChange={e => {
																												const newForms = [...forms];
																												newForms[index].form_type_id = e.target.value;
																												setForms(newForms);
																											}}
																											required
																										>
																											<option value={""}>--Select One--</option>
																											{Array.isArray(documentTypes) && documentTypes.map((docType, index) => (
																												<option key={index} value={docType.id}>{docType.type}</option>
																											))}
																										</Form.Control>
																									</td>
																									<td>
																										{
																											index > 0 &&
																											<Button variant="link" onClick={e => {
																												const newForms = [...forms];
																												const temp = newForms[index];
																												newForms[index] = newForms[index - 1];
																												newForms[index - 1] = temp;
																												setForms(newForms);
																											}} className="float-end text-primary">
																												<FontAwesomeIcon icon="arrow-up" />
																											</Button>
																										}
																										{
																											index < forms.length - 1 &&
																											<Button variant="link" onClick={e => {
																												const newForms = [...forms];
																												const temp = newForms[index];
																												newForms[index] = newForms[index + 1];
																												newForms[index + 1] = temp;
																												setForms(newForms);
																											}} className="float-end text-primary">
																												<FontAwesomeIcon icon="arrow-down" />
																											</Button>
																										}
																									</td>
																									<td>
																										<Button variant="link" onClick={e => { handleEditSelected(form) }}>Edit</Button>
																									</td>
																								</tr>
																							))
																						}
																					</tbody>
																				</Table>
																			</div>
																			: <p className="my-3">No form templates selected</p>
																	}
																</div>
															</div>
														)
													}
												</div>

											</>
										)
									}



								</>
							)
						}


					</div>
				}


				<Button ref={submitRef} className="d-none" type="submit" variant="primary">Submit</Button>
				<PDFViewerModal
					modalState={pdfViewerModalState}
					setModalState={setPdfViewerModalState}
					selectedFileId={1}
					setSelectedFileId={() => { }}
				/>

				<EditorModal
					modalState={formEditorModal}
					setModalState={setFormEditorModal}
					content={selectedForm?.content || ""}
					handleEditorSave={handleEditorSave}
					allowNewFormSave={allowNewFormSave}
					allowPreview={true}
					setAllowNewFormSave={setAllowNewFormSave}
					defaultEditorConfig={editorConfig}
					options={editorModalOptions}
					clientDataArray={claimants || []}
				/>
			</Form>
		</>
	);
};

export default ClaimantCorrespondence;