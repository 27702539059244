import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import HubspotForm from 'react-hubspot-form';

const HUBSPOT_PORTAL_ID = process.env.REACT_APP_HUBSPOT_PORTAL_ID;
const HUBSPOT_FORM_ID = process.env.REACT_APP_HUBSPOT_FORM_ID;

const ScheduleADemoModal = ({ modalState, setModalState = () => { }, title = "Schedule a Demo", message = "", callBack = () => { } }) => {

	// Handle Submit
	const handleSubmit = (values) => {
		console.log(values);
		toast.success('Thank you for your interest in Fulcrum. We will be in touch shortly.', { theme: 'colored' });
		callBack();
		handleClose();
	};

	// Handle Show
	const handleShow = () => {
		setModalState(true);
	};

	// Handle close
	const handleClose = () => {
		setModalState(false);
	};

	return (
		<Modal
			show={modalState}
			size="lg"
			onHide={handleClose}
			onShow={handleShow}
			className='agreement'
		>
			<Modal.Header closeButton>
				<Modal.Title>{title}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<p>{message}</p>
				<HubspotForm
					portalId={HUBSPOT_PORTAL_ID}
					formId={HUBSPOT_FORM_ID}
					onFormSubmit={handleSubmit}
					onSubmit={handleSubmit}
					onFormSubmitted={handleSubmit}
					loading={<div>Loading...</div>}
				/>
			</Modal.Body>
		</Modal>
	);
}

export default ScheduleADemoModal;