import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import Flex from 'components/common/Flex';
import cloudUpload from 'assets/img/icons/cloud-upload.svg';
import { Button } from 'react-bootstrap';
import { userAPI } from 'utils/api/fulcrum-api';
import Auth from 'utils/auth';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import { faCamera } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ImageUploader from '../../../../pages/account/ImageUploader';

const ProfileImageUploader = ({ user, setUser = () => { }, updateUser = () => { } }) => {

  const activeUserId = user?.id || Auth.getProfile().data.user_id;

  const [files, setFiles] = useState([]);
  const [show, setShow] = useState(false);

  const handleShow = () => setShow(true);
  const handleClose = () => {
    setShow(false);
    setFiles([]);
  };

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const removeImage = async () => {
    try {

      // @todo - low priority - this only updates the database backend, still will need to refresh the user profile image - seek a better solution for doing this throughout the app
      console.log("activeUserId:", activeUserId);
      const result = await userAPI.updateUser(activeUserId, { remove_profile_picture: Date.now() });
      setUser(result.data);
      console.log("remove Image result:", result);
      toast.success('Profile image removed successfully.', { theme: 'colored' });
    } catch (error) {
      console.log("Error removing image:", error);
    }
  }

  const handleSave = async (files) => {

    if (!files || !Array.isArray(files) || files.length != 1) {
      console.log('Error: One and only one file allowed.');
      return;
    }

    try {
      const base64 = await convertToBase64(files[0]);
      console.log("activeUserId:", activeUserId);
      const result = await userAPI.updateUser(activeUserId, {
        logoBase64: base64
      });
      setUser(result.data);
      if (!result) {
        throw new Error('Unable to update account logo.');
      }
      toast.success('Account logo updated successfully.', { theme: 'colored' });

    } catch (error) {

      console.log('Failed to save logo:', error);
      toast.error('Failed to save logo.', { theme: 'colored' });

    }

    handleClose();

  }

  return (
    <>
      {
        Auth.getProfile().data.user_id === user.id ?
          <Button variant='link' className="fs--1" onClick={() => handleShow()} style={{ cursor: 'pointer' }}>
            <FontAwesomeIcon icon={faCamera} className="mr-2 text-primary cursor-pointer" />
            {" "}Change Profile Image
          </Button>
          :
          <Button variant='link' className="text-danger" onClick={removeImage} style={{ cursor: 'pointer' }}>Remove Image</Button>
      }

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Upload Profile Image</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ImageUploader setValue={handleSave} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant='link' className="text-danger" onClick={removeImage} style={{ cursor: 'pointer' }}>Remove Image</Button>
          <Button variant="secondary" onClick={handleClose}>Cancel</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

ProfileImageUploader.propTypes = {
  user: PropTypes.object,
  setUser: PropTypes.func,
  updateUser: PropTypes.func
}

export default ProfileImageUploader;