import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import LoginForm from 'components/authentication/LoginForm';
import TFAForm from 'components/authentication/TFAForm';
import AuthCardLayout from 'layouts/AuthCardLayout';

const Login = () => {
  const [slide, setSlide] = useState("login");
  const [uuid, setUUID] = useState(null);

  return (
    <AuthCardLayout
      leftSideContent={
        <p className="text-white">
          Don't have an account?
          <br />
          <Link
            className="text-white text-decoration-underline"
            to="/register"
          >
            Get started!
          </Link>
        </p>
      }
    >
      <h3>Account Login</h3>
      {
        slide === "login"
          ?
          <LoginForm layout="card" hasLabel setSlide={setSlide} setUUID={setUUID} />
          :
          <TFAForm layout="card" hasLabel setSlide={setSlide} uuid={uuid} />
      }

    </AuthCardLayout>
  );
};

export default Login;