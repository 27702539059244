import React, { useState } from 'react';
import { Card, Dropdown } from 'react-bootstrap';
import CardDropdown from 'components/common/CardDropdown';
import { Link } from 'react-router-dom';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import DocumentTableHeader from './DocumentTableHeaderOld';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import { documentAPI } from 'utils/api/fulcrum-api';
import { useEffect } from 'react';
import { removeEmptyFromObj, sleep } from 'utils/functions';
import moment from 'moment';
import UploadDocumentForm from './UploadDocumentForm';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import PDFViewerModal from 'components/app/pdfjs-viewer/PDFViewerModal';

const columns = [
	{
		accessor: 'document_type',
		Header: 'Document Title',
		headerProps: { className: 'pe-1' },
		cellProps: {
			className: 'py-2'
		}
	},
	{
		accessor: 'title',
		Header: 'Title',
		headerProps: { className: 'pe-1' },
		cellProps: {
			className: 'py-2'
		}
	},
	{
		accessor: 'claimant_name',
		Header: 'Claimant',
		headerProps: { className: 'pe-1' },
		cellProps: {
			className: 'py-2'
		}
	},
	{
		accessor: 'menu',
		Header: '',
		headerProps: { className: 'pe-1' },
		cellProps: {
			className: 'py-2'
		}
	}
];

const DocumentTable = ({ defaultSearchParams = {}, requiredSearchParams = {}, defaultClaimants = [], defaultColumns = columns, hideExport = false, hideCreateButton = false, hideSearch = false, attachSelectedCB = () => { }, immediateSearch = false, bulkActionOverride = [] }) => {
	const [documentData, setDocumentData] = useState([]);
	const [documentCount, setDocumentCount] = useState(0);
	const [searching, setSearching] = useState(false);
	const [lastQuery, setLastQuery] = useState({});
	const [searchParams] = useState({ ...defaultSearchParams, ...requiredSearchParams });
	const [selectedDocument, setSelectedDocument] = useState({});
	const [uploadDocumentModalState, setUploadDocumentModalState] = useState(false);
	const [documentDetailsModalState, setDocumentDetailsModalState] = useState(false);
	const [scheduleStartDate, setScheduleStartDate] = useState();
	const [scheduleEndDate, setScheduleEndDate] = useState();
	const [pdfViewerModalState, setPdfViewerModalState] = useState(false);
	const [selectedFileId, setSelectedFileId] = useState(null);


	const formatData = (data = []) => {

		let adjustedData = data.map(c => {
			return {
				...c,
				document_type: c.document_type.type === "Other" ? `${c.document_type.type} - ${c.title}` : c.document_type.type,
				created_at: moment(c.createdAt).format('MM/DD/YYYY hh:mm A'),
				claimant_name: c?.claimant ? <Link to={`/claimant/profile/${c.claimant.id}`}>{`${c.claimant.first_name} ${c.claimant.last_name}`}</Link> : '',
				view: <span className={`link-primary cursor-hover`} onClick={() => handleDocumentSelection(c)}>View</span>,
				menu: (<>
					<div className="d-flex justify-content-between float-end">
						<CardDropdown
							children={(
								<div className="py-2">
									<Dropdown.Item onClick={() => { downloadDocument(c) }} className="">Download</Dropdown.Item>
									<Dropdown.Item onClick={async () => { setSelectedFileId(c.file_id); await sleep(1000); setPdfViewerModalState(true); }} className="">View</Dropdown.Item>
									<Dropdown.Divider />
									<Dropdown.Item onClick={() => { deleteDocument(c?.account_id || null, c.id) }} className="text-danger">Delete</Dropdown.Item>
								</div>
							)}
						/>
					</div>
				</>)
			}
		});
		setDocumentData(adjustedData);
	};

	// Download Document
	const downloadDocument = async (document) => {
		try {
			await documentAPI.downloadDocuments([document.file_id], (result) => {
				if (result?.error) {
					throw new Error(result.error);
				}
			});
		} catch (error) {
			toast.error("Unable to download document", { theme: 'colored' });
			console.log(error);
		}
	};

	// Delete Document
	const deleteDocument = async (accountId, id) => {
		Swal.fire({
			title: 'Are you sure?',
			text: "You won't be able to revert this!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Yes, delete it!'
		}).then(async (result) => {
			if (result.isConfirmed) {
				try {
					let result = await documentAPI.deleteDocument(accountId, id);
					if (!result?.message) { throw new Error(result.error); }
					toast.warning('Document deleted', { theme: 'colored' });
				} catch (error) {
					toast.error("Unable to delete document", { theme: 'colored' });
					console.log(error);
				}
			}
		});
	};



	const documentSearchHandler = async (params = {}, saveLastQuery = false) => {
		try {
			params = removeEmptyFromObj(params);
			let documentSearchResult = await documentAPI.searchDocuments({ ...params, ...requiredSearchParams });
			if (!documentSearchResult?.data?.rows) {
				throw new Error('No document data found');
			}

			formatData(documentSearchResult.data.rows);
			setDocumentCount(documentSearchResult.data.count);
			setSearching(false);

			if (saveLastQuery) {
				setLastQuery(params);
			}
		} catch (error) {
			console.log(error);
		}
	};


	const newSearch = async (params) => {
		setSearching(true);
		let adjustedSearchParams = {
			...params,
			...requiredSearchParams
		};
		documentSearchHandler(adjustedSearchParams);
	};

	// Refresh last query
	const refreshLastQuery = async () => {
		documentSearchHandler(lastQuery);
	};


	// Handle document selection
	const handleDocumentSelection = (document) => {
		setSelectedDocument(document);
		setDocumentDetailsModalState(true);
	};

	useEffect(() => {
		if (immediateSearch) {
			newSearch({ ...defaultSearchParams });
		}
	}, []);

	// Apply search filters
	useEffect(() => { newSearch(searchParams) }, [searchParams]);


	return (
		<>
			<AdvanceTableWrapper
				columns={defaultColumns}
				data={documentData}
				selection
				sortable
				pagination
				perPage={10}
			>
				<Card className="mb-3 shadow-none border">
					<Card.Header>
						<DocumentTableHeader
							table
							hideExport={hideExport}
							hideCreateButton={hideCreateButton}
							hideSearch={hideSearch}
							setUploadDocumentModalState={setUploadDocumentModalState}
							refreshLastQuery={refreshLastQuery}
							attachSelectedCB={attachSelectedCB}
							bulkActionOverride={bulkActionOverride}
						/>
					</Card.Header>
					<Card.Body className="p-0">
						{
							searching ?
								<p>Searching documents...</p>
								:
								<AdvanceTable
									table
									headerClassName="bg-200 text-900 text-nowrap align-middle"
									rowClassName="align-middle white-space-nowrap"
									tableProps={{
										size: 'sm',
										striped: true,
										className: 'fs--1 mb-0 overflow-hidden'
									}}
								/>
						}

					</Card.Body>
					<Card.Footer>
						<AdvanceTablePagination table />
					</Card.Footer>
				</Card>
			</AdvanceTableWrapper>

			<UploadDocumentForm
				setModalState={setUploadDocumentModalState}
				modalState={uploadDocumentModalState}
				defaultClaimants={defaultClaimants}
				refreshParent={refreshLastQuery}
			/>

			<PDFViewerModal
				modalState={pdfViewerModalState}
				setModalState={setPdfViewerModalState}
				selectedFileId={selectedFileId}
				setSelectedFileId={setSelectedFileId}
			/>
		</>
	);
};


export default DocumentTable;