import React, { useContext, useState, useRef } from 'react';
import { Modal, Form, Button, ListGroup, Alert } from 'react-bootstrap';
import AppContext from 'context/Context';
import OtherRecipientCorrespondenceWrapper from './OtherRecipientCorrespondenceWrapper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import ClaimantCorrespondenceWrapper from './ClaimantCorrespondenceWrapper';
import CustomMessageEditor from './CustomMessageEditor';
import SSACorrespondenceForm from './SSACorrespondenceForm';
import FieldOfficeCorrespondenceForm from './FieldOfficeCorrespondenceForm';
import auth from 'utils/auth';

const CreateCorrespondenceWizard = ({
	modalTitle = 'Create Correspondence',
	account_id = auth.getProfile()?.data?.account_id || null,
	defaultFormData = {},
	modalState,
	setModalState,
	defaultClaimant = {},
	defaultClaimants = [],
	defaultRecipientType = null,
	defaultSelectedDocuments = [],
	defaultModeOfTransmission = null,
	customMessage = null,
	defaultOptions = {},
	defaultSlide,
	refreshParent = () => { },
}) => {
	const { config: { isDark } } = useContext(AppContext);
	const submitRef = useRef();
	const [slide, setSlide] = useState(defaultSlide || 0);

	// Handle Show
	const handleShow = () => {
		setSlide(defaultSlide || 0);
	};

	const handleClose = () => {
		setSlide(0);
		setModalState(false);
	};

	// Handle Recipient Type Click
	const handleRecipientTypeClick = (type) => {
		setSlide(type);
	};

	// Render Slide
	const renderSlide = () => {
		switch (slide) {

			case 1:
				return (
					<div>
						<ClaimantCorrespondenceWrapper
							handleClose={handleClose}
							defaultClaimants={defaultClaimants}
							defaultSelectedDocuments={defaultSelectedDocuments}
							customMessage={customMessage}
							refreshParent={refreshParent}
							defaultModeOfTransmission={defaultModeOfTransmission}
							account_id={account_id}
							setSlide={setSlide}
							allowMultipleClaimants={true}
						/>
					</div>

				);
				break;
			case 2:
				return (
					<div>
						<SSACorrespondenceForm
							handleClose={handleClose}
							defaultClaimant={defaultClaimant}
							defaultSelectedDocuments={defaultSelectedDocuments}
							customMessage={customMessage}
							refreshParent={refreshParent}
							defaultModeOfTransmission={defaultModeOfTransmission}
							account_id={account_id}
							setSlide={setSlide}
						/>
					</div>
				);
				break;
			case 3:
				return (
					<div>
						<OtherRecipientCorrespondenceWrapper
							handleClose={handleClose}
							defaultClaimants={defaultClaimants}
							defaultSelectedDocuments={defaultSelectedDocuments}
							customMessage={customMessage}
							refreshParent={refreshParent}
							defaultModeOfTransmission={defaultModeOfTransmission}
							account_id={account_id}
							setSlide={setSlide}
							allowMultipleClaimants={false}
						/>
					</div>
				);
				break;
			case 4:
				return (
					<div>
						<FieldOfficeCorrespondenceForm
							handleClose={handleClose}
							defaultClaimant={defaultClaimant}
							defaultSelectedDocuments={defaultSelectedDocuments}
							customMessage={customMessage}
							defaultModeOfTransmission={defaultModeOfTransmission}
							refreshParent={refreshParent}
							account_id={account_id}
							setSlide={setSlide}
						/>
					</div>
				);
				break;
			case 0:
			default:
				return (
					<div>
						<Alert variant="info">
							<Alert.Heading>Choose Recipient Type</Alert.Heading>
							<p>
								Please choose the type of recipient you would like to send this
								correspondence to.
							</p>
						</Alert>

						<ListGroup>
							<ListGroup.Item className="my-3 py-3 px-3 shadow-sm" action onClick={() => handleRecipientTypeClick(1)}>
								<h5 className="fw-bold">Claimants</h5>
								<p>
									Select this option if you would like to send a correspondence to one or more claimants. <br />Allowed mode of correspondence: Email, SMS, Fax, or Regular Mail.
								</p>
							</ListGroup.Item>
							<ListGroup.Item className="my-3 py-3 px-3 shadow-sm" action onClick={() => handleRecipientTypeClick(2)}>
								<h5 className="fw-bold">Social Security Administration</h5>
								<p>
									Select this option if you would like to send a correspondence to the Social Security Administration. <br />Allowed mode of correspondence: Fax or Regular Mail.
								</p>
							</ListGroup.Item>
							<ListGroup.Item className="my-3 py-3 px-3 shadow-sm" action onClick={() => handleRecipientTypeClick(3)}>
								<h5 className="fw-bold">Other Recipient(s)</h5>
								<p>
									Select this option if you would like to send a correspondence to someone other than a claimant or the Social Security Administration. <br />Allowed mode of correspondence: Email, SMS, Fax, or Regular Mail.
								</p>
							</ListGroup.Item>
						</ListGroup>
					</div>
				);
				break;
		}
	};

	return (
		<Modal
			show={modalState}
			onHide={handleClose}
			onShow={handleShow}
			contentClassName="border"
			size="xl"
			enforceFocus={false}
			backdrop="static"
		>
			<Modal.Header
				closeButton
				closeVariant={isDark ? 'white' : undefined}
				className="border-bottom bg-300"
			>
				<Modal.Title as="h5">{modalTitle || "Create Correspondence"}</Modal.Title>
			</Modal.Header>
			<Modal.Body className="py-3 px-5 bg-100">
				{
					[1, 2, 3].includes(slide) &&
					<div>
						{/* Back to wizard menu */}
						<Button variant="link" className="text-decoration-none" onClick={() => setSlide(0)}>
							<FontAwesomeIcon icon={faChevronLeft} className="me-2" />
							Back to correspondence wizard menu
						</Button>
					</div>
				}
				{renderSlide()}
			</Modal.Body>
			<Modal.Footer className="border-top bg-200">
			</Modal.Footer>
		</Modal>
	);
};


export default CreateCorrespondenceWizard;