import React from 'react';
import DocumentTable from 'components/documents/DocumentTable';
import Auth from 'utils/auth';

const Documents = () => {
	return (
		<>
			<DocumentTable
				defaultSearchParams={{}}
				requiredSearchParams={{ account_id: Auth.getProfile()?.data?.account_id }}
				account_id={Auth.getProfile()?.data?.account_id}
			/>
		</>
	);
};

export default Documents;