import React from 'react';
import MyFormRepositoryTable from './MyFormRepositoryTable';

const MyFormRepository = () => {
	return (
		<>
			<MyFormRepositoryTable
				defaultSearchParams={{}}
				requiredSearchParams={{}}
			/>
		</>
	);
};

export default MyFormRepository;