import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { phoneAPI } from 'utils/api/fulcrum-api';
import PhoneRow from './PhoneRow';
import IconButton from 'components/common/IconButton';
import Table from 'react-bootstrap/Table';
import { Alert } from 'react-bootstrap';
import { toast } from 'react-toastify';

const PhoneList = ({ entity_id, refreshParent = () => { } }) => {

	const [hasPrimaryPhone, setHasPrimaryPhone] = useState(false);
	const [loading, setLoading] = useState(true);
	const [phones, setPhones] = useState([]);
	// const [phoneTypes, setPhoneTypes] = useState([]);

	// Add phone
	const addPhone = async () => {
		try {
			let result = await phoneAPI.createPhone({
				phone_type_id: "Other",
				entity_id
			});
			if (!result?.data) {
				throw new Error('No phone data returned');
			}
			getPhoneList();
			toast.success(`Phone successfully added.`, {
				theme: 'colored'
			});
		} catch (error) {
			console.error(error);
			toast.error(`${error}`, {
				theme: 'colored'
			});
		}
	};

	// Get phone list by entity_id
	const getPhoneList = async () => {
		try {
			let result = await phoneAPI.getPhoneListByEntityId(entity_id);
			if (!result?.data) {
				throw new Error('No phone data returned');
			}

			// Check if any phones are primary
			let hasPrimary = false;
			result.data.forEach(email => {
				if (email.is_primary) {
					hasPrimary = true;
				}
			});
			setHasPrimaryPhone(hasPrimary);
			setPhones(result.data);
			refreshParent(result.data);
			setLoading(false);
		} catch (error) {
			console.error(error);
			toast.error(`${error}`, {
				theme: 'colored'
			});
		}
	};

	// Get related data
	// const getRelatedData = async () => {
	// 	try {
	// 		let result = await phoneAPI.getPhoneTypes();
	// 		if (!result?.data?.rows) {
	// 			throw new Error('Unable to find any phone types');
	// 		}
	// 		setPhoneTypes(result.data.rows);
	// 		setLoading(false);
	// 	} catch (error) {
	// 		console.error(error);
	// 	}
	// };

	useEffect(() => {
		getPhoneList(entity_id);
		// getRelatedData();
	}, []);

	if (!entity_id) {
		return (<p>No entity provided</p>);
	}

	return (
		<div>
			<div>

				{loading ? <p>Loading phones...</p> : (
					<>

						<span className="mb-2 fw-bold">Phones</span>
						<Table responsive>
							<thead>
								{/* <tr>
								<th colSpan="5">
									<span className="d-inline fw-bold">Phones</span>
									<IconButton
										className="me-2 mb-1 float-end"
										variant="falcon-default"
										size="sm"
										icon="plus"
										transform="shrink-3"
										onClick={addPhone}
									>
										Add Phone
									</IconButton>
								</th>
							</tr> */}
								<tr>
									<th scope="col">Prim.</th>
									<th scope="col">Type</th>
									<th scope="col">Phone</th>
									{/* <th scope="col">Ext.</th> */}
									{/* <th scope="col">Note</th> */}
									<th scope="col"></th>
								</tr>
							</thead>
							<tbody>

								{
									!hasPrimaryPhone && (
										<tr>
											<td colSpan={5}>
												<Alert variant='danger' className="">No primary phone selected.</Alert>
											</td>
										</tr>
									)
								}
								{phones.map((phone) => (
									<PhoneRow
										phone={phone}
										getPhoneList={getPhoneList}
										phones={phones}
										setPhones={setPhones}
										key={phone.id}
										refreshParent={refreshParent}
									// phoneTypes={phoneTypes}
									/>
								))}
								<tr >
									<td colSpan="5">
										<IconButton
											className=""
											variant="falcon-default"
											size="sm"
											icon="plus"
											transform="shrink-3"
											onClick={addPhone}
										>
											Add Phone
										</IconButton>
									</td>
								</tr>
							</tbody>
						</Table>
					</>
				)}
			</div>
		</div>
	);
};

PhoneList.propTypes = {
	entity_id: PropTypes.string
}

export default PhoneList;