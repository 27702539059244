import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';
import AppContext from 'context/Context';
import MyFormFinder from './MyFormFinder';


const MyFormFinderModal = ({
	modalState,
	setModalState,
	handleFormSelection = () => { },
}) => {
	const {
		config: { isDark }
	} = useContext(AppContext);
	const [pdfTemplates, setPdfTemplates] = useState([
		{
			type: 'PDF',
			title: "Disability Report – Adult (Form SSA-3368-BK)",
			url: 'http://www.ssa.gov/forms/ssa-3368-bk.pdf',
			form_type_id: "OTHER"
		},
		{
			type: 'PDF',
			title: "Disability Report – Child (Form SSA-3369-BK)",
			url: 'https://www.ssa.gov/forms/ssa-3820.pdf',
			form_type_id: "OTHER"
		},
		{
			type: 'PDF',
			title: "Claimant’s Recent Medical Treatment (Form HA-4631)",
			url: 'https://www.ssa.gov/forms/ha-4631.pdf)',
			form_type_id: "OTHER"
		},
		{
			type: 'PDF',
			title: "Request for Reconsideration (Form HA-561-U2)",
			url: "http://www.ssa.gov/forms/ha-4632.pdf",
			form_type_id: "OTHER"
		},
		{
			type: 'PDF',
			title: "Request for Reconsideration (Form HA-561-U2)",
			form_type_id: "Other",
			url: "http://www.ssa.gov/forms/ssa-561-u2.pdf",
			form_type_id: "OTHER"
		},
		{
			type: 'PDF',
			title: "Disability Report - Appeal (Form SSA-3441)",
			url: 'http://www.ssa.gov/forms/ssa-3441.pdf',
			form_type_id: "OTHER"
		},
		{
			type: 'PDF',
			title: "•	Request for Hearing (Form HA-501)",
			url: 'http://www.ssa.gov/forms/ha-501.pdf',
			form_type_id: "OTHER"
		},
		{
			type: 'PDF',
			title: "Request for Review of Hearing Decision/Order (Form HA-520)",
			url: 'http://www.ssa.gov/forms/ha-520.pdf',
			form_type_id: "OTHER"
		}
	]);

	// Get related data @TODO: Move this to a context
	// const getRelatedData = async () => {
	// };

	// Add Blank Form Template
	const addBlankFormTemplate = () => {
		handleFormSelection({
			title: 'New Form Blank ' + Date.now(),
			description: 'New Form Blank ',
			form_type_id: "OTHER",
			content: '',
			type: 'RTF'
		});
		handleClose();
	};

	// Handle Show
	const handleShow = () => {
	};

	const handleClose = () => {
		setModalState(false);
	};

	return (
		<Modal
			show={modalState}
			onHide={handleClose}
			onShow={handleShow}
			contentClassName="border"
			size="lg"
			backdrop="static"
		>
			<Modal.Header
				closeButton
				closeVariant={isDark ? 'white' : undefined}
				className="bg-light px-card border-bottom-0"
			>
				<Modal.Title as="h5">Form Finder</Modal.Title>
			</Modal.Header>
			<Modal.Body className="p-card">
				<div className="my-3 text-center">
					<Button variant={'falcon-primary'} onClick={addBlankFormTemplate}>Add Blank Form Template</Button>
				</div>
				<p className="text-center">- or -</p>

				{/* <p className="text-center">Select a PDF Template</p>
				<Row>
					<Form.Group as={Col} md={12}>
						<Form.Control
							as="select"
							custom
							onChange={(e) => {
								const selectedTemplate = pdfTemplates.find((template) => template.title === e.target.value);
								handleFormSelection(selectedTemplate, "PDF");
								handleClose();
							}}
						>
							<option value="">Select a PDF Template</option>
							{pdfTemplates.map((template) => (
								<option key={template.title} value={template.title}>
									{template.title}
								</option>
							))}
						</Form.Control>
					</Form.Group>
				</Row> */}

				{/* <p className="text-center">- or -</p> */}

				<p className="text-center">Search my saved form templates</p>
				<MyFormFinder
					handleFormSelection={handleFormSelection}
					handleClose={handleClose}
				/>
			</Modal.Body>
		</Modal>
	);
};

MyFormFinderModal.propTypes = {
	modalState: PropTypes.bool,
	setModalState: PropTypes.func,
	handleFormSelection: PropTypes.func
}

export default MyFormFinderModal;