import React, { useState, useEffect } from 'react';
import { Card, Tab, Tabs, Alert, Button } from 'react-bootstrap';
import AccountSettings from 'components/client-account/settings/AccountSettings';
import AccountPreferences from 'components/account/AccountPreferences';
import Services from 'components/client-account/services/Services'
import PaymentTable from 'components/entity-components/PaymentTable';
import ScheduleADemoModal from 'components/app/hubspot/ScheduleADemoModal';
import termsOfService from 'data/termsOfService.js';
import UserTable from 'components/pages/users/user-list/UserTable';
import LeadVendors from 'components/pages/lead-vendors';
import TaskTable from 'components/task/TaskTable';
import CustomFieldTable from 'components/custom-fields/CustomFieldTable';
import RevisionLogTable from 'components/app/revision-logs/RevisionLogTable';
import CredentialManager from 'components/pages/assure/atlasware/crednetials/CredentialManager';

const AccountProfileTabs = ({ account, setAccount, siteAdminArea = false }) => {
  const [terms, setTerms] = useState('');
  const [ScheduleADemoModalState, setScheduleADemoModalState] = useState(false);

  const applySubstitutions = (text, substitutions = []) => {
    if (Array.isArray(substitutions)) {
      for (let i = 0; i < substitutions.length; i++) {
        text = text.replaceAll(substitutions[i].replace, substitutions[i].with);
      }
    }
    return text;
  };

  const onButtonClick = () => {
    // using Java Script method to get PDF file
    fetch(termsOfService.downloadUrl).then(response => {
      response.blob().then(blob => {
        // Creating new object of PDF file
        const fileURL = window.URL.createObjectURL(blob);
        // Setting various property values
        let alink = document.createElement('a');
        alink.href = fileURL;
        alink.download = 'terms-of-service.pdf';
        alink.click();
      })
    })
  }

  useEffect(() => {
    setTerms(applySubstitutions(termsOfService.terms, termsOfService.substitutions));
  }, []);

  return (
    <Card>
      <Card.Body>
        <Tabs defaultActiveKey="settings" id="uncontrolled-tab-example" unmountOnExit={true} mountOnEnter={true}>
          <Tab eventKey="settings" title="Settings" className='border-bottom border-x p-3 overflow-auto'>
            <AccountSettings account={account} setAccount={setAccount} siteAdminArea={siteAdminArea} />
          </Tab>
          <Tab eventKey="users" title="Users" className='border-bottom border-x p-3 overflow-auto'>
            <UserTable siteAdminArea={siteAdminArea} account={account} requiredSearchParams={{ account_id: account.id }} />
          </Tab>
          <Tab eventKey="services" title="Services" className='border-bottom border-x p-3 overflow-auto'>
            <Alert variant="info" className="mb-3">
              <p className="fw-bold">
                To enroll in services, please review and execute the service agreements below.
              </p>
              <p className="mb-0">
                If you want more information regarding any of the service, please <span className="text-primary cursor-pointer fw-bold" onClick={() => setScheduleADemoModalState(true)}>schedule a demo here</span>.
              </p>
            </Alert>

            <ScheduleADemoModal
              modalState={ScheduleADemoModalState}
              setModalState={setScheduleADemoModalState}
              title="Request a Demo"
              message="We would love to give you a full tour of all the great things Fulcrum has to offer. All you need to do is fill out this form and we will reach out to you so we can schedule a demo. We can't wait to introduce you to your new way of working."
            />

            <Services account={account} />


            <div className="my-5">
              <h5 className="fw-bold mb-3">Terms of Service</h5>
              <div
                className="border p-3"
                style={{
                  overflow: "auto",
                  height: "400px"
                }}>
                <div
                  dangerouslySetInnerHTML={{ __html: terms }}
                />
              </div>

              <br />
              <Button variant="link" onClick={onButtonClick}>Download Terms of Service</Button>

            </div>
          </Tab>
          <Tab eventKey="atlasware" title="Atlasware">
            <CredentialManager
              account={account}
              setAccount={setAccount}
              refreshParent={() => { }}
            />
          </Tab>
          <Tab eventKey="payment" title="Payment">
            <PaymentTable
              tableHeaderLabel="Payment Methods"
              requiredSearchParams={{ account_id: account.id, entity_id: account.entity_id }}
            />
          </Tab>
          <Tab eventKey="preferences" title="Preferences" className='border-bottom border-x p-3 overflow-auto' unmountOnExit={true} mountOnEnter={true}>
            <AccountPreferences account={account} setAccount={setAccount} siteAdminArea={siteAdminArea} />
          </Tab>
          {
            siteAdminArea &&
            <Tab eventKey="lead-vendors" title="Lead Vendors" className='border-bottom border-x p-3 overflow-auto'>
              <LeadVendors account_id={account.id} withHeader={false} />
            </Tab>
          }
          {
            siteAdminArea &&
            <Tab eventKey="tasks" title="Tasks" className='border-bottom border-x p-3 overflow-auto'>
              <TaskTable
                requiredSearchParams={{ account_id: account.id }}
              />
            </Tab>
          }
          {
            siteAdminArea &&
            <Tab eventKey="custom-fields" title="Custom Fields" className='border-bottom border-x p-3 overflow-auto'>
              <CustomFieldTable
                requiredSearchParams={{ account_id: account.id }}
                siteAdminArea={true}
                account_id={account.id}
              />
            </Tab>
          }

          <Tab eventKey="other" title="Other" className='border-bottom border-x p-3'>
            <Tabs defaultActiveKey="revision-logs" id="other-tabs">
              <Tab eventKey="revision-logs" title="Revision Logs" className='border-bottom border-x p-3 overflow-auto'>
                <RevisionLogTable
                  defaultSearchParams={{}}
                  requiredSearchParams={{ table: 'account', parent_id: account.id, account_id: account.id }}
                />
              </Tab>
            </Tabs>
          </Tab>
        </Tabs>
      </Card.Body>
    </Card>
  );
};


export default AccountProfileTabs;