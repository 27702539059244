import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Card, Form, Nav, ProgressBar } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useForm } from 'react-hook-form';
import TermsOfService from '../TermsOfService';
import UserForm from './UserForm';
import Complete from './Complete';
import AccessCodeForm from '../AccessCodeForm';
import AppContext, { AuthWizardContext } from 'context/Context';
import IconButton from 'components/common/IconButton';
import WizardModal from '../WizardModal';
import Flex from 'components/common/Flex';
import { accessVerificationAPI, userAPI } from 'utils/api/fulcrum-api';
import { toast } from 'react-toastify';

const RegistrationWizardLayout = ({ uuid, accessVerificationData, variant, validation, progressBar }) => {
  const { isRTL } = useContext(AppContext);
  const { user, setUser, step, setStep } = useContext(AuthWizardContext);
  const [updateUserError, setUpdateUserError] = useState(false);
  const [showIncorrectAccessCodeToast, setShowIncorrectAccessCodeToast] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    clearErrors
  } = useForm();

  const navigate = useNavigate();

  const [modal, setModal] = useState(false);

  const navItems = [
    {
      icon: 'lock',
      label: 'Access Code'
    },
    {
      icon: 'file',
      label: 'Terms of Service'
    },
    {
      icon: 'user',
      label: 'User Information'
    },
    {
      icon: 'thumbs-up',
      label: 'Done'
    }
  ];

  const handleSubmitForm = async () => {
    navigate('/login');
  };

  // Verify access code
  const checkAccessCode = async (accessCode) => {
    try {
      let result = await accessVerificationAPI.verifyAccessVerificationCode(uuid, { access_key: accessCode });
      if (!result?.data) {
        throw new Error('Invalid access code');
      }
      return true;
    } catch (error) {
      return false;
    }
  };

  const updateUser = async (userId, data = {}) => {
    try {
      let updatedUserResult = await userAPI.completeUserRegistration(userId, uuid, data);
      if (!updatedUserResult?.message) {
        throw new Error('Unable to update user');
      }
      toast.success('User registration completed!', {
        theme: 'colored',
      });
      return true;
    } catch (error) {
      toast.error('Unable to complete registration.', {
        theme: 'colored',
      });
      return false;
    }
  };

  const onSubmitData = async data => {
    setUser({ ...user, ...data });

    let pass = false;
    if (step === 1) {
      pass = await checkAccessCode(data.access_key);
      if (!pass) {
        setShowIncorrectAccessCodeToast(true);
        return;
      }
    } else if (step === 3) {
      pass = await updateUser(accessVerificationData.entity_id.split("_")[2], {
        first_name: data.first_name,
        last_name: data.last_name,
        phones: [
          {
            phone: data.primary_phone,
            phone_type_id: 3,
            is_primary: true,
            note: "Primary Phone",
            entity_id: accessVerificationData.entity_id
          },
          {
            phone: data.fax,
            phone_type_id: 4,
            is_primary: false,
            note: "Fax",
            entity_id: accessVerificationData.entity_id
          }
        ],
        password: data.password,
      });
      if (!pass) {
        setUpdateUserError(true);
        return;
      }
    } else {
      pass = true;
    }

    if (pass) {
      setStep(step + 1);
    }

    // setUser({ ...user, ...data });
    // setStep(step + 1);
  };

  const onError = () => {
    if (!validation) {
      clearErrors();
      setStep(step + 1);
    }
  };

  const toggle = () => setModal(!modal);

  const handleNavs = targetStep => {
    if (step !== 4) {
      if (targetStep < step) {
        setStep(targetStep);
      } else {
        handleSubmit(onSubmitData, onError)();
      }
    } else {
      toggle();
    }
  };

  return (
    <>
      <WizardModal modal={modal} setModal={setModal} />
      <Card
        as={Form}
        noValidate
        onSubmit={handleSubmit(onSubmitData, onError)}
        className="theme-wizard mb-5"
      >
        <Card.Header
          className={classNames('bg-light', {
            'px-4 py-3': variant === 'pills',
            'pb-2': !variant
          })}
        >
          <Nav className="justify-content-center" variant={variant}>
            {variant === 'pills'
              ? navItems.map((item, index) => (
                <NavItemPill
                  key={item.label}
                  index={index + 1}
                  step={step}
                  handleNavs={handleNavs}
                  icon={item.icon}
                  label={item.label}
                />
              ))
              : navItems.map((item, index) => (
                <NavItem
                  key={item.label}
                  index={index + 1}
                  step={step}
                  handleNavs={handleNavs}
                  icon={item.icon}
                  label={item.label}
                />
              ))}
          </Nav>
        </Card.Header>
        {progressBar && <ProgressBar now={step * 25} style={{ height: 2 }} />}
        <Card.Body className="fw-normal px-md-6 py-4">
          {step === 1 && (
            <AccessCodeForm
              showIncorrectAccessCodeToast={showIncorrectAccessCodeToast}
              setShowIncorrectAccessCodeToast={setShowIncorrectAccessCodeToast}
              register={register}
              errors={errors}
              watch={watch}
              user={user}
              setUser={setUser}
              uuid={uuid}
            />
          )}
          {step === 2 && (
            <TermsOfService register={register} errors={errors} watch={watch} />
          )}
          {step === 3 && (
            <UserForm
              register={register}
              errors={errors}
              setValue={setValue}
              accessVerificationData={accessVerificationData}
              watch={watch}
              updateUserError={updateUserError}
              setUpdateUserError={setUpdateUserError}
            />
          )}
          {step === 4 && (
            <Complete handleSubmitForm={handleSubmitForm} />
          )}
        </Card.Body>
        <Card.Footer
          className={classNames('px-md-6 bg-light', {
            'd-none': step === 4,
            ' d-flex': step < 4
          })}
        >
          <IconButton
            variant="link"
            icon={isRTL ? 'chevron-right' : 'chevron-left'}
            iconAlign="left"
            transform="down-1 shrink-4"
            className={classNames('px-0 fw-semi-bold', {
              'd-none': step === 1
            })}
            onClick={() => {
              setStep(step - 1);
            }}
          >
            Prev
          </IconButton>

          <IconButton
            variant="primary"
            className="ms-auto px-5"
            type="submit"
            icon={isRTL ? 'chevron-left' : 'chevron-right'}
            iconAlign="right"
            transform="down-1 shrink-4"
          >
            Next
          </IconButton>
        </Card.Footer>
      </Card>
    </>
  );
};

const NavItem = ({ index, step, handleNavs, icon, label }) => {
  return (
    <Nav.Item>
      <Nav.Link
        className={classNames('fw-semi-bold', {
          done: index < 4 ? step > index : step > 3,
          active: step === index
        })}
        onClick={() => handleNavs(index)}
      >
        <span className="nav-item-circle-parent">
          <span className="nav-item-circle">
            <FontAwesomeIcon icon={icon} />
          </span>
        </span>
        <span className="d-none d-md-block mt-1 fs--1">{label}</span>
      </Nav.Link>
    </Nav.Item>
  );
};

const NavItemPill = ({ index, step, handleNavs, icon, label }) => {
  return (
    <Nav.Item>
      <Nav.Link
        className={classNames('fw-semi-bold', {
          done: step > index,
          active: step === index
        })}
        onClick={() => handleNavs(index)}
      >
        <Flex alignItems="center" justifyContent="center">
          <FontAwesomeIcon icon={icon} />
          <span className="d-none d-md-block mt-1 fs--1 ms-2">{label}</span>
        </Flex>
      </Nav.Link>
    </Nav.Item>
  );
};

RegistrationWizardLayout.propTypes = {
  variant: PropTypes.oneOf(['pills']),
  validation: PropTypes.bool,
  progressBar: PropTypes.bool,
  uuid: PropTypes.string,
  accessVerificationData: PropTypes.object
};

NavItemPill.propTypes = {
  index: PropTypes.number.isRequired,
  step: PropTypes.number.isRequired,
  handleNavs: PropTypes.func.isRequired,
  icon: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired
};

NavItem.propTypes = NavItemPill.propTypes;

export default RegistrationWizardLayout;
