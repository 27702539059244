import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import Dropdown from 'react-bootstrap/Dropdown';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import CardDropdown from 'components/common/CardDropdown';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import { claimantOtherHouseholdMemberAPI } from 'utils/api/fulcrum-api';

const ClaimantOtherHouseholdRow = ({ member, claimant, getClaimantOtherHouseholdMembers = () => { } }) => {
	let defaultFormData = {
		name: member.name,
		relationship: member.relationship
	};
	const [formData, setFormData] = useState(defaultFormData);
	const [previousFormData, setPreviousFormData] = useState(defaultFormData);

	// Remove a member
	const removeChild = () => {
		Swal.fire({
			title: 'Permanently delete this member record?',
			text: "You won't be able to revert this!",
			icon: 'warning',
			showCansetFormDatalButton: true,
			confirmButtonColor: '#3085d6',
			cansetFormDatalButtonColor: '#d33',
			confirmButtonText: 'Yes, delete it!',
			cancelButtonColor: '#d33',
			showCancelButton: true,
		}).then(async (result) => {
			if (result.isConfirmed) {
				try {
					let result = await claimantOtherHouseholdMemberAPI.deleteClaimantOtherHouseholdMember(claimant.id, member.id);
					if (!result?.message) {
						throw new Error('No data returned from server');
					}
					getClaimantOtherHouseholdMembers();
					toast.warning('Other household member removed', { theme: 'colored' });
				} catch (error) {
					console.error(error);
					toast.error('Error deleting member', { theme: 'colored' });
				}
			}
		});
	};

	// Update a member
	const updateCurrentChild = async (memberId, data) => {
		try {
			let result = await claimantOtherHouseholdMemberAPI.updateClaimantOtherHouseholdMember(claimant.id, memberId, data);
			console.log(result);
			if (!result) {
				throw new Error('Unable to update member');
			}
			setPreviousFormData({ ...formData, ...data });

		} catch (error) {
			console.error(error);
			toast.error('Error updating member', {
				theme: 'colored'
			});
		}
	};

	// Save on change
	const saveOnChange = async (e, saveChange) => {
		let { name, value } = e.target;

		if (saveChange && previousFormData[name] !== value) {
			await updateCurrentChild(member.id, { [name]: value });
		}

		setFormData({ ...formData, [name]: value });
	};

	useEffect(() => {
		let memberData = {
			name: member.name,
			relationship: member.relationship
		};
		setFormData(memberData);
		setPreviousFormData(memberData);
	}, [member])

	return (
		<>
			<td className="px-0">
				<Form.Control
					className="px-2"
					type="text"
					size="sm"
					name="name"
					value={formData.name}
					onChange={e => saveOnChange(e, false)}
					onBlur={e => saveOnChange(e, true)}
				/>
			</td>
			<td className="px-0">
				<Form.Control
					className="px-2"
					type="text"
					size="sm"
					name="relationship"
					value={formData.relationship}
					onChange={e => saveOnChange(e, false)}
					onBlur={e => saveOnChange(e, true)}
				/>
			</td>
			<td>
				<div className="d-flex justify-content-between float-end">
					<CardDropdown>
						<div className="py-2">
							<Dropdown.Item onClick={() => { removeChild(member.id) }} className="text-danger">Delete</Dropdown.Item>
						</div>
					</CardDropdown>
				</div>
			</td>
		</>
	);
};

export default ClaimantOtherHouseholdRow;