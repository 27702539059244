import React, { useState, useEffect, useRef, createContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { claimantAPI, notificationAPI } from 'utils/api/fulcrum-api';
import Auth from 'utils/auth';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import ClaimantProfileCard from './ClaimantProfileCard';
import ClaimantCaseWrapper from './ClaimantCaseWrapper';
import { toast } from 'react-toastify';
import { isIterableArray } from 'helpers/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './toastOverrideWidth.css';
import moment from 'moment';
import handleNotification from 'helpers/handleNotification';
import TagList from 'components/entity-components/TagList';
import ReactInterval from 'react-interval';

const ClaimantProfile = ({ siteAdminArea = false }) => {
  let { id: claimantId } = useParams();
  const prevClaimantId = useRef(claimantId);
  // let claimantContext = createContext();
  const [componentState, setComponentState] = useState({
    closeCaseModalState: false,
  });

  const navigate = useNavigate();
  const [loads, setLoads] = useState(0);
  const [loading, setLoading] = useState(true);
  const [claimant, setClaimant] = useState({});
  const [notifications, setNotifications] = useState([]);
  const toastIds = useRef({});

  // Notification
  const notificationInit = () => {
    checkNotifications(claimantId);
    const unsubscribeTo = setInterval(() => {
      checkNotifications();
    }, 10000);
    return (() => {
      clearInterval(unsubscribeTo);
      Object.values(toastIds).forEach((valObj) => {
        Object.values(valObj).forEach((id) => {
          toast.update(id, {
            autoClose: 1
          });
        })
      });
    });
  };

  // Get claimant details
  const getClaimantDetails = async (saveRecentClaiamnt = false, params = {}) => {
    try {
      let result = await claimantAPI.getClaimant(claimantId, saveRecentClaiamnt, params);
      if (!result?.data) {
        throw new Error('No claimant data returned');
      }
      setClaimant(result.data);
      setLoading(false);
      checkNotifications(claimantId);
      // notificationInit();
    } catch (error) {
      toast.error('Unable to load claimant details.', { theme: 'colored' })
      navigate('/claimant-folders');
    }
  };

  // Get claimant notifications
  const checkNotifications = async (claimantId) => {
    const { account_id } = Auth.getProfile().data;
    try {
      const { data: claimantMatchData } = await notificationAPI.searchNotifications({
        entity_id: `${account_id}_CLAIMANT_${claimantId}`,
        readType: 'unread'
      });
      if (isIterableArray(claimantMatchData.rows)) {
        setNotifications([...claimantMatchData.rows]);
      }
    } catch (error) {
    }
  }

  const dismissNotification = async (notificationId) => {
    const { user_id } = Auth.getProfile().data;
    const toastId = toastIds.current[notificationId];
    delete toastIds.current[notificationId];
    toast.update(toastId, {
      autoClose: 1
    });
    try {
      const { data } = await notificationAPI.updateMyNotification(notificationId, {
        dismissed_timestamp: moment.now(),
        dismissed_by_id: user_id
      });
      if (data) {
        setNotifications(notifications.filter((note) => note.id !== notificationId));
      }
    } catch (error) {
      console.log(error);
    }
  }

  const handleNotificationSubmit = (notification) => {
    let callBack = () => { };

    switch (notification.notification_type_id) {
      case 'RECEIVED_AWARD_LETTER':
      case 'CASE_CLOSED_BY_SOCIAL_SECURITY':
        callBack = () => {
          setComponentState({
            ...componentState,
            closeCaseModalState: true
          });
        };
        break;
      case 'RECEIVED_RETAINER_PACKET':
        break;
    }

    handleNotification(notification, callBack);
    dismissNotification(notification.id);
  };

  // Refresh Claimant Page 
  const refreshClaimant = (params = {}) => {
    getClaimantDetails(false, params);
  };


  useEffect(() => {
    getClaimantDetails(true);
    const unsubscribeTo = setInterval(() => {
      checkNotifications(claimantId);
    }, 10000);
    return (() => {
      clearInterval(unsubscribeTo);
      Object.values(toastIds).forEach((valObj) => {
        Object.values(valObj).forEach((id) => {
          toast.update(id, {
            autoClose: 1
          });
        })
      });
    });
  }, []);

  useEffect(() => {
    notifications.forEach((notification) => {
      if (notification.notification_type_id === 'RECEIVED_RETAINER_PACKET' && claimant?.active_case_id) {
        dismissNotification(notification.id);
        return;
      }

      if (!(notification.id in toastIds.current)) {
        toastIds.current[notification.id] = toast.warning((
          <div style={{ cursor: 'context-menu' }}>
            <Row className=" fw-bold pb-2 pt-2 mb-2 mr-6  rounded-3" style={{ backgroundColor: '#E6EFFC' }}>
              <Col sm={1}>
                <FontAwesomeIcon
                  size={'2x'}
                  className={`text-center align-middle `}
                  icon={notification?.notification_type?.icon || 'exclamation'}
                />
              </Col>
              <Col>
                {notification?.notification_type?.description}: {notification.message}
              </Col>
            </Row>
            <Row>
              <Col>
                <p>
                  {notification?.notification_type?.message}
                </p>
                <Button
                  className="p-1"
                  onClick={() => { handleNotificationSubmit(notification) }}
                  variant='primary'
                >
                  {notification?.notification_type?.submit_button_text}
                </Button>
                <Button
                  className="p-1 ms-2"
                  onClick={() => dismissNotification(notification.id)}
                  variant='falcon-warning'
                >
                  {notification?.notification_type?.cancel_button_text}
                </Button>
              </Col>
            </Row>
          </div>
        ), {
          theme: 'colored',
          position: 'bottom-right',
          autoClose: false,
          closeOnClick: false,
          // className: 'pt-0 shadow p-3 mb-5 bg-white rounded',
          style: { width: '100%', padding: '0px', backgroundColor: '#E6EFFC', color: '#619eff', border: '2px solid #75aaff' },
        });
      }
    });
  }, [notifications, claimant?.active_case_id]);

  useEffect(() => { // For if a user navigates directly from one client to another
    // reload page if claimant id changes
    if (prevClaimantId.current !== claimantId) {
      window.location.reload(false);
      return;
    }

    if (claimantId !== prevClaimantId.current) {
      Object.values(toastIds).forEach((valObj) => {
        Object.values(valObj).forEach((id) => {
          toast.update(id, {
            autoClose: 1
          });
        })
      });
      prevClaimantId.current = claimantId;
    }
  }, [claimantId]);

  useEffect(() => {
  }, [loads]);

  if (loading) {
    return (<p>Loading claimant details...</p>);
  };



  return (
    <div className="">
      <Row>
        <Col md={4} className="p-2">
          <div className="mb-3">
            <ClaimantProfileCard
              siteAdminArea={siteAdminArea}
              claimant={claimant}
              setClaimant={setClaimant}
              refreshClaimant={refreshClaimant}
            />
          </div>
        </Col>
        <Col className="px-1">
          <Row className="mb-2">
            {/* <Col sm={4}>
              <span>Assigned User: {claimant?.assigned_user?.id ? `${claimant?.assigned_user?.first_name || ""} ${claimant?.assigned_user?.last_name || ""}` : <span className="text-danger">No user assigned</span>}</span>
            </Col> */}
            <Col className="float-end">
              <div className="float-end">
                <TagList
                  siteAdminArea={siteAdminArea}
                  entity_type="claimant"
                  entity_id={claimant.entity_id}
                  tags={claimant.tags}
                  entity={claimant}
                  setEntity={setClaimant}
                  refreshClaimant={refreshClaimant}
                />
              </div>
            </Col>
          </Row>

          <ClaimantCaseWrapper
            siteAdminArea={siteAdminArea}
            componentState={componentState}
            setComponentState={setComponentState}
            claimant={claimant}
            setClaimant={setClaimant}
            getClaimantDetails={getClaimantDetails}
            refreshClaimant={refreshClaimant}
          />
        </Col>
      </Row>

      {/* <ReactInterval timeout={15000} enabled={true} callback={() => { refreshClaimant({ ignoreTouch: true }) }} /> */}
    </div>
  );
};

export default ClaimantProfile;
