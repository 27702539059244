import React, { useEffect } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import auth from 'utils/auth';
import CustomFieldTable from './CustomFieldTable';

const CustomFields = ({ account_id = auth.getProfile()?.data?.account_id || null }) => {

	useEffect(() => { }, []);

	return (
		<div>
			<Card className="bg-100 shadow-none border mb-3">
				<Card.Body>
					<Row className=" justify-content-between pr-0">
						<Col sm="auto" className="pr-0 align-middle">
							<Flex alignItems="center">
								<h4 className="align-middle fw-bold mb-0 h-inline">
									<span className="fw-medium">Custom Fields</span>
								</h4>
							</Flex>
						</Col>
					</Row>
				</Card.Body>
			</Card>

			<CustomFieldTable model={"CLAIMANT"} requiredSearchParams={{ account_id }} />
		</div>
	);
};


export default CustomFields;