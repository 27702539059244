import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Modal, Row, Col, Alert, ListGroup, Spinner } from 'react-bootstrap';
import { useNavigate, Link } from 'react-router-dom';
import AppContext from 'context/Context';
import { assureAPI, userAPI, accountAPI } from 'utils/api/fulcrum-api';

const Finished = ({
	setModalState = () => { },
	user = {},
	slide,
	setScheduleADemoModalState = (() => { }),
	setSlide = (() => { }),
	handleClose = (() => { }),
}) => {


	return (
		<div>
			<div className="text-center mx-auto" style={{ maxWidth: '550px' }}>

				<h5 className="text-success">Finished!</h5>

				<p>
					Your Atlasware onboarding has been completed. Please verify that you can still manually access your ERE account and reach out if you have any issues.
				</p>

				<div className="text-center">
					<Button
						variant="success"
						onClick={() => handleClose()}
						size="lg"
					>
						Done!
					</Button>
				</div>
			</div>
		</div>
	);
};

export default Finished;