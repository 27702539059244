import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { taskAPI } from 'utils/api/fulcrum-api';
import Auth from 'utils/auth';
import { Card, Col, Row } from 'react-bootstrap';

const TaskMetrics = ({ className, defaultSearchParams = {}, requiredSearchParams = {}, account_id = Auth.getProfile()?.data?.account_id || null }) => {
	const [metrics, setMetrics] = useState({
		'my_tasks': 0,
		'overdue': 0,
		'due_today': 0,
		'unassigned': 0,
	});

	const getTaskMetrics = async () => {
		try {

			let newMetricsResult = await taskAPI.getTaskMetrics(account_id, {active_claimant: true, active_case: false, active_case_id: null});
			setMetrics(newMetricsResult?.data || {
				'my_tasks': 0,
				'overdue': 0,
				'due_today': 0,
				'unassigned': 0,
			});
		} catch (error) {
			console.log(error);
		}
	}

	useEffect(() => {
		getTaskMetrics();
	}, []);

	return (
		<div>
			<Card className="h-md-100">
			<Card.Body>
				<Row className="justify-content-between g-0">
					<h6 className="mt-1">Inbound Lead Metrics</h6>
				</Row>
				<Row className="mb-3 border-bottom pb-3">
					<Col>
						<div className="text-center cursor-pointer" onClick={() => handleTaskMetricClick('my_tasks')}>
							<span className="fw-bold">{metrics.my_tasks}</span>
							<br />
							<span className="fw-bold">My Tasks</span>
						</div>
					</Col>
					<Col>
						<div className="text-center cursor-pointer" onClick={() => handleTaskMetricClick('overdue')}>
							<span className="fw-bold">{metrics.overdue}</span>
							<br />
							<span className="fw-bold">Overdue</span>
						</div>
					</Col>
					<Col>
						<div className="text-center cursor-pointer" onClick={() => handleTaskMetricClick('due_today')}>
							<span className="fw-bold">{metrics.due_today}</span>
							<br />
							<span className="fw-bold">Due Today</span>
						</div>
					</Col>
					<Col>
						<div className="text-center cursor-pointer" onClick={() => handleTaskMetricClick('unassigned')}>
							<span className="fw-bold">{metrics.unassigned}</span>
							<br />
							<span className="fw-bold">Unassigned</span>
						</div>
					</Col>
				</Row>
			</Card.Body>
		</Card>
	</div>
	);
};

TaskMetrics.propTypes = {
	className: PropTypes.string.isRequired,
	defaultSearchParams: PropTypes.object,
	requiredSearchParams: PropTypes.object
};

export default TaskMetrics;
