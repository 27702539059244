import React, { forwardRef } from 'react';
import { Card } from 'react-bootstrap';

const Uses = forwardRef((props, ref) => {
	return (
		<Card className="mb-3" id="uses" ref={ref}>
			<Card.Header className="bg-light">
				<h5 className="mb-0">Information Uses</h5>
			</Card.Header>
			<Card.Body>
				<h6 className="text-primary">Eligibility</h6>
				<p className="fs-0 mb-0 fw-semi-bold">
					Assure Disability, Inc. uses information for the following business purposes:
				</p>
				<ol type="1">
					<li>auditing related to transactions and compliances with specifications and standards,</li>
					<li>detecting security incidents, protecting against malicious, deceptive, fraudulent, or illegal activity, and prosecuting those responsible for such activity,</li>
					<li>debugging to identify and repair errors that impair existing intended functionality,</li>
					<li>short-term, transient uses in which information is not disclosed to third parties or used to build a profile,</li>
					<li>performing services that have been requested by the source of the information, including maintaining service accounts, providing customer service, processing or fulfilling orders and transactions, verifying customer information, processing payments, providing analytic services, or providing similar services requested by the source of the Personal Information,</li>
					<li>undertaking internal research for technological development and demonstration,</li>
					<li>undertaking activities to verify or maintain the quality or safety of a service that is provided by Assure Disability, Inc., and to improve, upgrade, or enhance the service provided by Assure Disability, Inc.</li>
				</ol>
				<hr className="my-4" />
				<h6 className="text-primary">Accepting these Terms</h6>
				<p className="mb-0 ps-3">
					Assure Disability, Inc. will use such information to evaluate your situation, to allow unaffiliated sponsoring lawyers or law firms, or vendors of Social Security disability representatives to evaluate your practice and offer legal services to you, and to provide ongoing communications to you in furtherance of those legal services.  We may contact you from time to time regarding legal services we believe may be of interest to you.  If you would like to opt out of these communications, send an email to info@higginsandassociates.com.
				</p>
				<p className="mb-0 ps-3">
					All information obtained by Assure Disability, Inc. is stored securely on servers owned and operated by Assure Disability, Inc., to which only authorized personnel can access through a password.  Information is retained by Assure Disability, Inc.  for a minimum of six (6) years.  Clients may at any time review and update their information stored with Assure Disability, Inc in order to ensure its accuracy by written notice at Assure Disability, Inc., 8200 Camp Bowie West Blvd., Fort Worth, TX 76116 or at info@higginsandassociates.com.  Clients agree to hold Assure Disability, Inc. harmless in the event such information is compromised by Assure Disability, Inc. or a third party.  If you wish to edit, remove, or access your information, send an e-mail to info@higginsandassociates.com.
				</p>
			</Card.Body>
		</Card>
	);
});

export default Uses;