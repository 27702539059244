import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { userAPI } from 'utils/api/fulcrum-api';
import { Col, Row, Form, Tabs, Tab } from 'react-bootstrap';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import { toast } from 'react-toastify';
import moment from 'moment';
import IconButton from 'components/common/IconButton';
import PDFViewerPreviewModal from 'components/app/pdfjs-viewer/PDFViewerPreviewModal';
import RepresentativeMOU from './memorandum-of-understanding/RepresentativeMOU';
import AdmittedLog from './AdmittedLog/Admitted';
import DiscLog from './DiscLog/Disc';
import HearingCoverageAccess from './HearingCoverageAccess';
import OfCounselAgreements from './OfCounselAgreements';
import { formatPhone } from 'utils/functions';
import { stateData } from 'data/stateData';

const RepresentativeRegistration = ({ user, setUser = () => { }, refreshParent = () => { } }) => {
	let defaultRegistrationInfo = {
		representative_id: user.representative_id,
		dob: user?.dob ? moment(user.dob).format('YYYY-MM-DD') : '',
		ssn: user?.ssn ? user.ssn : '',
		admitted: "admitted" in user ? user.admitted : '',
		// admitted_log: 'admitted_log' in parsedRepRegInfo ? parsedRepRegInfo.admitted_log : '',
		notices: 'notices' in user ? user.notices : '',
		receive_notice_address: user.receive_notice_address || '',
		receive_notice_city: user.receive_notice_city || '',
		receive_notice_state: user.receive_notice_state || '',
		receive_notice_zip: user.receive_notice_zip || '',
		tax_notices: "tax_notices" in user ? user.tax_notices : '',
		receive_tax_info_address: user.receive_tax_info_address || '',
		receive_tax_info_city: user.receive_tax_info_city || '',
		receive_tax_info_state: user.receive_tax_info_state || '',
		receive_tax_info_zip: user.receive_tax_info_zip || '',
		ssa_contact_information: "ssa_contact_information" in user ? user.ssa_contact_information : '',
		ssa_contact_phone: user.ssa_contact_phone || '',
		ssa_contact_email: user.ssa_contact_email || '',
		ssa_contact_fax: user.ssa_contact_fax || '',
		ssa_payment_method: user.ssa_payment_method || '',
		// ssa_direct_deposit: user.ssa_direct_deposit || '', // Need this?
		ssa_payment_financial: user.ssa_payment_financial || '',
		ssa_payment_routing: user.ssa_payment_routing || '',
		ssa_payment_account: user.ssa_payment_account || '',
		working_for_firm: "working_for_firm" in user ? user.working_for_firm : '',
		firm_ein: user.firm_ein || '',
		firm_name: user.firm_name || '',
		disc_ssa: 'disc_ssa' in user ? user.disc_ssa : '',
		disc_court: 'disc_court' in user ? user.disc_court : '',
		disc_act: 'disc_act' in user ? user.disc_act : '',
		disc_clmt: 'disc_clmt' in user ? user.disc_clmt : '',
		// disc_log: 'disc_log' in user ? user.disc_log : '',
		acknowledgement_1: 'acknowledgement_1' in user ? user.acknowledgement_1 : '',
		acknowledgement_2: 'acknowledgement_2' in user ? user.acknowledgement_2 : '',
	};

	const [formData, setFormData] = useState(defaultRegistrationInfo);
	const [previousFormData, setPreviousFormData] = useState(defaultRegistrationInfo);
	const [pdfViewerModalState, setPdfViewerModalState] = useState(false);

	const saveRepresentativeRegistration = async (data) => {
		try {
			const result = await userAPI.updateUser(user.id, data);
			if (!result?.data) {
				throw new Error('No user data returned');
			}

			setUser({
				...user,
				...data
			});
			setPreviousFormData({
				...formData
			});
		} catch (error) {
			toast.error("Unable to save representative registration.", { theme: "colored" });
		}
	};

	const saveOnChange = async (e, saveChange = false) => {
		let { name, value, checked } = e.target;
		let additionalData = {};
		let hasChanges = false;

		// If target is a checkbox, set value to checked
		if (e.target.type === 'checkbox') {
			value = checked;
		} else if (['admitted', 'notices', 'tax_notices', 'ssa_contact_information', 'working_for_firm', 'disc_clmt', 'disc_ssa', 'disc_court', 'disc_act'].includes(name)) {
			value = value === "true" ? true : false;
		}

		hasChanges = previousFormData[name] !== value;
		if (saveChange && hasChanges) {
			saveRepresentativeRegistration({
				[name]: value
			});
		}

		setFormData({
			...formData,
			[name]: value,
			...additionalData
		});
	};

	return (<div>
		<PDFViewerPreviewModal
			modalState={pdfViewerModalState}
			setModalState={setPdfViewerModalState}
			headerButtons={['resetButton', 'printButton', 'downloadPdfButton']}
			pdf_template="ssa-1699"
			user_id={user.id}
		/>

		<Tabs defaultActiveKey="representative-form" id="uncontrolled-tab-example">
			<Tab eventKey="representative-form" title="Representative Registration" className='border-bottom border-x p-3'>
				<HearingCoverageAccess
					user={user}
					setUser={setUser}
				/>

				<div className="mb-4 border-bottom pb-4">
					<RepresentativeMOU
						user={user}
						setUser={setUser}
						refreshParent={refreshParent}
					/>
				</div>
				<h5 className="d-flex justify-content-between">
					<span>Hearing Representative Form</span>
					<IconButton variant="falcon-default" size="sm" icon="eye" transform="shrink-3" iconAlign="left" onClick={() => setPdfViewerModalState(true)}>
						<span>ssa-1699</span>
					</IconButton>
				</h5>
				<hr />

				<Form>
					<div className="py-3 px-3 border-bottom">
						<Row>
							<Col sm={5} className={'px-0'}>
								<Form.Group controlId="representative_id">
									<FloatingLabel
										controlId="representative_id"
										label="Representative ID"
										className=""
										size="sm"
									>
										<Form.Control
											size="sm"
											type="text"
											name="representative_id"
											value={formData.representative_id}
											onChange={e => { saveOnChange(e, false) }}
											onBlur={e => saveOnChange(e, true)}
										/>
									</FloatingLabel>
								</Form.Group>
							</Col>
							<Col sm={3} className={'px-0'}>
								<Form.Group controlId="dob">
									<FloatingLabel
										controlId="dob"
										label="Date of Birth"
										className=""
										size="sm"
									>
										<Form.Control
											size="sm"
											type="date"
											name="dob"
											value={formData.dob}
											onChange={e => { saveOnChange(e, false) }}
											onBlur={e => saveOnChange(e, true)}
										/>
									</FloatingLabel>
								</Form.Group>
							</Col>
							<Col sm={4} className={'px-0'}>
								<Form.Group controlId="ssn">
									<FloatingLabel
										controlId="ssn"
										label="SSN"
										className=""
										size="sm"
									>
										<Form.Control
											size="sm"
											type="text"
											name="ssn"
											value={formData.ssn}
											onChange={e => { saveOnChange(e, false) }}
											onBlur={e => saveOnChange(e, true)}
										/>
									</FloatingLabel>
								</Form.Group>
							</Col>
						</Row>
					</div>
					<div className="py-3 border-bottom">
						<Form.Group controlId="admitted">
							<Form.Label>
								Are you a currently in good standing and admitted to practice law before the U.S. Supreme Court; a U.S. Federal, state, territorial, insular possession, or District of Columbia court; or a member of a state bar if that membership carries with it the authority to practice law in that state?
							</Form.Label>
							<Col>
								<Form.Check
									type="radio"
									inline
									label="Yes"
									required
									name="admitted"
									id='admitted-yes'
									value={true}
									checked={formData.admitted === true}
									onChange={(e) => saveOnChange(e, true)}
								/>
								<Form.Check
									type="radio"
									inline
									label="No"
									required
									name="admitted"
									id='admitted-no'
									value={false}
									checked={formData.admitted === false}
									onChange={(e) => saveOnChange(e, true)}
								/>
								{/* 
								<Form.Select
									aria-label="Default select example"
									onChange={e => saveOnChange(e, true)}
									name="admitted"
									value={formData.admitted}
								>
									<option>-- Select One --</option>
									<option value="true">Yes</option>
									<option value="false">No</option>
								</Form.Select> 
							  */}
							</Col>
						</Form.Group>

						{formData.admitted && <AdmittedLog user={user} saveUser={setUser} />}
					</div>
					<div className="py-3 border-bottom">
						<Form.Group controlId="formnotices">
							<Form.Label>
								Do you want to receive notices at {
									user?.account?.entity?.contact_addresses
										? user.account.entity.contact_addresses.map((address, index) => {
											if (address.is_primary) {
												return <span key={index}>{address.address} {address.address_2} {address.city}, {address.state} {address.zip}</span>
											}
										})
										: <span className=""> your business address. <span className="text-danger">Your account is missing a primary address.</span></span>
								}?
							</Form.Label>
							<Col>
								<Form.Check
									type="radio"
									inline
									label="Yes"
									required
									name="notices"
									id='notices-yes'
									value={true}
									checked={formData.notices === true}
									onChange={(e) => saveOnChange(e, true)}
								/>
								<Form.Check
									type="radio"
									inline
									label="No"
									required
									name="notices"
									id='notices-no'
									value={false}
									checked={formData.notices === false}
									onChange={(e) => saveOnChange(e, true)}
								/>
								{/* 
								<Form.Select
									aria-label="notices"
									onChange={e => saveOnChange(e, true)}
									name="notices"
									value={formData.notices}
								>
									<option>-- Select One --</option>
									<option value="true">Yes</option>
									<option value="false">No</option>
								</Form.Select> 
								*/}
							</Col>
						</Form.Group>

						{formData.notices !== null && !formData.notices && <div className="p-3">
							<span>Please provide the address you would like to use for notices.</span>
							<Row>
								<Col className={'px-0'}>
									<Form.Group controlId="receive_notice_address">
										<FloatingLabel
											controlId="receive_notice_address"
											label="Address"
											className=""
											size="sm"
										>
											<Form.Control
												size="sm"
												type="text"
												name="receive_notice_address"
												value={formData.receive_notice_address}
												onChange={e => { saveOnChange(e, false) }}
												onBlur={e => saveOnChange(e, true)}
											/>
										</FloatingLabel>
									</Form.Group>
								</Col>
							</Row>
							<Row>
								<Col sm={6} className={'px-0'}>
									<Form.Group controlId="receive_notice_city">
										<FloatingLabel
											controlId="receive_notice_city"
											label="City"
											className=""
											size="sm"
										>
											<Form.Control
												size="sm"
												type="text"
												name="receive_notice_city"
												value={formData.receive_notice_city}
												onChange={e => { saveOnChange(e, false) }}
												onBlur={e => saveOnChange(e, true)}
											/>
										</FloatingLabel>
									</Form.Group>
								</Col>
								<Col sm={3} className={'px-0'}>
									<Form.Group controlId="receive_notice_state">
										<FloatingLabel
											controlId="receive_notice_state"
											label="State"
											className=""
											size="sm"
										>
											<Form.Select
												size="sm"
												as="select"
												name="receive_notice_state"
												value={formData.receive_notice_state}
												onChange={e => { saveOnChange(e, false) }}
												onBlur={e => saveOnChange(e, true)}
											>
												<option value=""></option>
												{Array.isArray(stateData) && stateData.map((state, index) => (
													<option key={index} value={state.abbreviation}>{state.abbreviation}</option>
												))}
											</Form.Select>
										</FloatingLabel>
									</Form.Group>
								</Col>
								<Col sm={3} className={'px-0'}>
									<Form.Group>
										<FloatingLabel
											controlId="receive_notice_zip"
											label="Zip"
											className=""
											size="sm"
										>
											<Form.Control
												size="sm"
												type="text"
												name="receive_notice_zip"
												value={formData.receive_notice_zip}
												onChange={e => { saveOnChange(e, false) }}
												onBlur={e => saveOnChange(e, true)}
											/>
										</FloatingLabel>
									</Form.Group>
								</Col>
							</Row>
						</div>}
					</div>

					<div className="py-3 border-bottom">
						<Form.Group controlId="formtaxnotices">
							<Form.Label>
								Do you want to receive tax information at {
									user?.account?.entity?.contact_addresses ?
										user.account.entity.contact_addresses.map((address, index) => {
											if (address.is_primary) {
												return <span key={index}>{address.address} {address.address_2} {address.city}, {address.state} {address.zip}</span>
											}
										})
										:
										<span className=""> your business address. <span className="text-danger">Your account is missing a primary address.</span></span>
								}?
							</Form.Label>
							<Col>

								<Form.Check
									type="radio"
									inline
									label="Yes"
									required
									name="tax_notices"
									id='tax_notices-yes'
									value={true}
									checked={formData.tax_notices === true}
									onChange={(e) => saveOnChange(e, true)}
								/>
								<Form.Check
									type="radio"
									inline
									label="No"
									required
									name="tax_notices"
									id='tax_notices-no'
									value={false}
									checked={formData.tax_notices === false}
									onChange={(e) => saveOnChange(e, true)}
								/>
								{/* 
								<Form.Select
									aria-label="notices"
									onChange={e => saveOnChange(e, true)}
									name="tax_notices"
									value={formData.tax_notices}
								>
									<option>-- Select One --</option>
									<option value="true">Yes</option>
									<option value="false">No</option>
								</Form.Select> 
								*/}
							</Col>
						</Form.Group>

						{formData.tax_notices !== null && !formData.tax_notices && <div className="p-3">
							<span>Please provide the address you would like to use for notices.</span>
							<Row>
								<Col className={'px-0'}>
									<Form.Group controlId="receive_tax_info_address">
										<FloatingLabel
											controlId="receive_tax_info_address"
											label="Address"
											className=""
											size="sm"
										>
											<Form.Control
												size="sm"
												type="text"
												name="receive_tax_info_address"
												value={formData.receive_tax_info_address}
												onChange={e => { saveOnChange(e, false) }}
												onBlur={e => saveOnChange(e, true)}
											/>
										</FloatingLabel>
									</Form.Group>
								</Col>
							</Row>
							<Row>
								<Col sm={6} className={'px-0'}>
									<Form.Group controlId="receive_tax_info_city">
										<FloatingLabel
											controlId="receive_tax_info_city"
											label="City"
											className=""
											size="sm"
										>
											<Form.Control
												size="sm"
												type="text"
												name="receive_tax_info_city"
												value={formData.receive_tax_info_city}
												onChange={e => { saveOnChange(e, false) }}
												onBlur={e => saveOnChange(e, true)}
											/>
										</FloatingLabel>
									</Form.Group>
								</Col>
								<Col sm={3} className={'px-0'}>
									<Form.Group controlId="receive_tax_info_state">
										<FloatingLabel
											controlId="receive_tax_info_state"
											label="State"
											className=""
											size="sm"
										>
											<Form.Select
												size="sm"
												as="select"
												name="receive_tax_info_state"
												value={formData.receive_tax_info_state}
												onChange={e => { saveOnChange(e, false) }}
												onBlur={e => saveOnChange(e, true)}
											>
												<option value=""></option>
												{
													Array.isArray(stateData) && stateData.map((state, index) => {
														return (
															<option key={index} value={state.abbreviation}>{state.abbreviation}</option>
														);
													})
												}
											</Form.Select>
										</FloatingLabel>
									</Form.Group>
								</Col>
								<Col sm={3} className={'px-0'}>
									<Form.Group>
										<FloatingLabel
											controlId="receive_tax_info_zip"
											label="Zip"
											className=""
											size="sm"
										>
											<Form.Control
												size="sm"
												type="text"
												name="receive_tax_info_zip"
												value={formData.receive_tax_info_zip}
												onChange={e => { saveOnChange(e, false) }}
												onBlur={e => saveOnChange(e, true)}
											/>
										</FloatingLabel>
									</Form.Group>
								</Col>
							</Row>
						</div>}
					</div>

					<div className="py-3 border-bottom">
						<h5 className="bold">Contact Information</h5>

						<Form.Group controlId="formssacontactinformation">
							<Form.Label>
								Do you wish to register different contact information with the Social Security Administration than <br />Phone: {
									user?.account?.entity?.contact_phones
										? user.account.entity.contact_phones.map((phone, index) => {
											if (phone.is_primary) {
												return <span key={index}>{formatPhone(phone.phone)}</span>
											}
										})
										: <span className="text-danger">Your account is missing a primary phone.</span>
								}, <br />Fax: {
									user?.account?.entity?.contact_phones ?
										user.account.entity.contact_phones.map((phone, index) => {
											if (phone.phone_type_id === "Fax") {
												return <span key={index}>{formatPhone(phone.phone)}</span>
											}
										})
										:
										<span className="text-danger">Your account is missing a primary phone.</span>
								}, <br />Email: {
									user?.account?.entity?.contact_emails
										? user.account.entity.contact_emails.map((email, index) => {
											if (email.is_primary) {
												return <span key={index}>{email.email}</span>
											}
										})
										: <span className="text-danger">Your account is missing a primary email.</span>
								}?
							</Form.Label>
							<Col>
								<Form.Check
									type="radio"
									inline
									label="Yes"
									required
									name="ssa_contact_information"
									id='ssa_contact_information-yes'
									value={true}
									checked={formData.ssa_contact_information === true}
									onChange={(e) => saveOnChange(e, true)}
								/>
								<Form.Check
									type="radio"
									inline
									label="No"
									required
									name="ssa_contact_information"
									id='ssa_contact_information-no'
									value={false}
									checked={formData.ssa_contact_information === false}
									onChange={(e) => saveOnChange(e, true)}
								/>
								{/* 
								<Form.Select
									aria-label="ssa_contact_information"
									onChange={e => saveOnChange(e, true)}
									name="ssa_contact_information"
									value={formData.ssa_contact_information}
								>
									<option>-- Select One --</option>
									<option value="true">Yes</option>
									<option value="false">No</option>
								</Form.Select> 
								*/}
							</Col>
						</Form.Group>

						{formData.ssa_contact_information && <>
							<Row>
								<Col sm={6} className={'px-0'}>
									<Form.Group controlId="ssa_contact_phone">
										<FloatingLabel
											controlId="ssa_contact_phone"
											label="Phone"
											className=""
											size="sm"
										>
											<Form.Control
												size="sm"
												type="text"
												name="ssa_contact_phone"
												value={formData.ssa_contact_phone}
												onChange={e => { saveOnChange(e, false) }}
												onBlur={e => saveOnChange(e, true)}
											/>
										</FloatingLabel>
									</Form.Group>
								</Col>
								<Col sm={6} className={'px-0'}>
									<Form.Group controlId="ssa_contact_fax">
										<FloatingLabel
											controlId="ssa_contact_fax"
											label="Fax"
											className=""
											size="sm"
										>

											<Form.Control
												size="sm"
												type="text"
												name="ssa_contact_fax"
												value={formData.ssa_contact_fax}
												onChange={e => { saveOnChange(e, false) }}
												onBlur={e => saveOnChange(e, true)}
											/>
										</FloatingLabel>
									</Form.Group>
								</Col>
							</Row>
							<Row>
								<Col sm={12} className={'px-0'}>
									<Form.Group>
										<FloatingLabel
											controlId="ssa_contact_email"
											label="Email"
											className=""
											size="sm"
										>
											<Form.Control
												size="sm"
												type="text"
												name="ssa_contact_email"
												value={formData.ssa_contact_email}
												onChange={e => { saveOnChange(e, false) }}
												onBlur={e => saveOnChange(e, true)}
											/>
										</FloatingLabel>
									</Form.Group>
								</Col>
							</Row>
						</>}
					</div>

					<div className="py-3 border-bottom">
						<Form.Group controlId="formsdssapaymentmethod">
							<Form.Label>
								What is your preferred payment method?
							</Form.Label>
							<Col>
								<Form.Select
									aria-label="ssa_payment_method"
									onChange={e => saveOnChange(e, true)}
									name="ssa_payment_method"
									value={formData.ssa_payment_method}
								>
									<option>-- Select One --</option>
									<option value="Direct Deposit">Direct Deposit</option>
									<option value="Check">Check</option>
									<option value="I am not eligible for direct payment of fees">I am not eligible for direct payment of fees</option>
								</Form.Select>
							</Col>
						</Form.Group>

						{formData.ssa_payment_method === "Direct Deposit" && <>
							<Form.Group controlId="formpaymentfinancial">
								<Form.Label>
									Financial Institution Name
								</Form.Label>
								<Col>
									<Form.Control
										name="ssa_payment_financial"
										value={formData.ssa_payment_financial}
										onChange={e => saveOnChange(e, false)}
										onBlur={e => saveOnChange(e, true)}
									/>
								</Col>
							</Form.Group>
							<Form.Group controlId="formrouting">
								<Form.Label>
									Routing Number
								</Form.Label>
								<Col>
									<Form.Control
										name="ssa_payment_routing"
										value={formData.ssa_payment_routing}
										onChange={e => saveOnChange(e, false)}
										onBlur={e => saveOnChange(e, true)}
									/>
								</Col>
							</Form.Group>
							<Form.Group controlId="formaccountnum">
								<Form.Label>
									Account Number
								</Form.Label>
								<Col>
									<Form.Control
										name="ssa_payment_account"
										value={formData.ssa_payment_account}
										onChange={e => saveOnChange(e, false)}
										onBlur={e => saveOnChange(e, true)}
									/>
								</Col>
							</Form.Group>
						</>}
					</div>

					<div className="py-3 border-bottom">
						<Form.Group controlId="formworkingforfirm">
							<Form.Label>
								Are you working for a firm?
							</Form.Label>
							<Col>
								<Form.Check
									type="radio"
									inline
									label="Yes"
									required
									name="working_for_firm"
									id='working_for_firm-yes'
									value={true}
									checked={formData.working_for_firm === true}
									onChange={(e) => saveOnChange(e, true)}
								/>
								<Form.Check
									type="radio"
									inline
									label="No"
									required
									name="working_for_firm"
									id='working_for_firm-no'
									value={false}
									checked={formData.working_for_firm === false}
									onChange={(e) => saveOnChange(e, true)}
								/>
								{/* 
								<Form.Select
									aria-label="working_for_firm"
									onChange={e => saveOnChange(e, true)}
									name="working_for_firm"
									value={formData.working_for_firm}
								>
									<option>-- Select One --</option>
									<option value="true">Yes</option>
									<option value="false">No</option>
								</Form.Select> 
								*/}
							</Col>
						</Form.Group>

						{formData.working_for_firm && <>
							<Form.Group controlId="formfirmein">
								<Form.Label>
									Firm EIN
								</Form.Label>
								<Col>
									<Form.Control
										name="firm_ein"
										value={formData.firm_ein}
										onChange={e => saveOnChange(e, false)}
										onBlur={e => saveOnChange(e, true)}
									/>
								</Col>
							</Form.Group>
							<Form.Group controlId="formfirmname">
								<Form.Label>
									Firm Name
								</Form.Label>
								<Col>
									<Form.Control
										name="firm_name"
										value={formData.firm_name}
										onChange={e => saveOnChange(e, false)}
										onBlur={e => saveOnChange(e, true)}
									/>
								</Col>
							</Form.Group>
						</>}
					</div>

					<div className="py-3 border-bottom">
						<Form.Group controlId="formdiscssa">
							<Form.Label>
								Suspended or prohibited from practice before SSA or any other Federal program or agency?
							</Form.Label>
							<Col>
								<Form.Check
									type="radio"
									inline
									label="Yes"
									required
									name="disc_ssa"
									id='disc_ssa-yes'
									value={true}
									checked={formData.disc_ssa === true}
									onChange={(e) => saveOnChange(e, true)}
								/>
								<Form.Check
									type="radio"
									inline
									label="No"
									required
									name="disc_ssa"
									id='disc_ssa-no'
									value={false}
									checked={formData.disc_ssa === false}
									onChange={(e) => saveOnChange(e, true)}
								/>
								{/* 
								<Form.Select
									aria-label="disc_ssa"
									onChange={e => saveOnChange(e, true)}
									name="disc_ssa"
									value={formData.disc_ssa}
								>
									<option>-- Select One --</option>
									<option value="true">Yes</option>
									<option value="false">No</option>
								</Form.Select> 
								*/}
							</Col>
						</Form.Group>

						<Form.Group controlId="formdisccourt">
							<Form.Label>
								Disbarred or suspended from a court or bar to which you were previously admitted to practice as an attorney?
							</Form.Label>
							<Col>
								<Form.Check
									type="radio"
									inline
									label="Yes"
									required
									name="disc_court"
									id='disc_court-yes'
									value={true}
									checked={formData.disc_court === true}
									onChange={(e) => saveOnChange(e, true)}
								/>
								<Form.Check
									type="radio"
									inline
									label="No"
									required
									name="disc_court"
									id='disc_court-no'
									value={false}
									checked={formData.disc_court === false}
									onChange={(e) => saveOnChange(e, true)}
								/>
								{/* 
								<Form.Select
									aria-label="disc_court"
									onChange={e => saveOnChange(e, true)}
									name="disc_court"
									value={formData.disc_court}
								>
									<option>-- Select One --</option>
									<option value="true">Yes</option>
									<option value="false">No</option>
								</Form.Select> 
								*/}
							</Col>
						</Form.Group>

						<Form.Group controlId="formdisccourt">
							<Form.Label>
								Convicted of a violation under Section 206 or 1631(d) of the Social Security Act?
							</Form.Label>
							<Col>
								<Form.Check
									type="radio"
									inline
									label="Yes"
									required
									name="disc_act"
									id='disc_act-yes'
									value={true}
									checked={formData.disc_act === true}
									onChange={(e) => saveOnChange(e, true)}
								/>
								<Form.Check
									type="radio"
									inline
									label="No"
									required
									name="disc_act"
									id='disc_act-no'
									value={false}
									checked={formData.disc_act === false}
									onChange={(e) => saveOnChange(e, true)}
								/>
								{/* 
								<Form.Select
									aria-label="disc_act"
									onChange={e => saveOnChange(e, true)}
									name="disc_act"
									value={formData.disc_act}
								>
									<option>-- Select One --</option>
									<option value="true">Yes</option>
									<option value="false">No</option>
								</Form.Select> 
								*/}
							</Col>
						</Form.Group>

						<Form.Group controlId="formdiscclmt">
							<Form.Label>
								Disqualified from representing a claimant as a current or former officer or employee of the United States?
							</Form.Label>
							<Col>
								<Form.Check
									type="radio"
									inline
									label="Yes"
									required
									name="disc_clmt"
									id='disc_clmt-yes'
									value={true}
									checked={formData.disc_clmt === true}
									onChange={(e) => saveOnChange(e, true)}
								/>
								<Form.Check
									type="radio"
									inline
									label="No"
									required
									name="disc_clmt"
									id='disc_clmt-no'
									value={false}
									checked={formData.disc_clmt === false}
									onChange={(e) => saveOnChange(e, true)}
								/>
								{/* 
								<Form.Select
									aria-label="disc_clmt"
									onChange={e => saveOnChange(e, true)}
									name="disc_clmt"
									value={formData.disc_clmt}
								>
									<option>-- Select One --</option>
									<option value="true">Yes</option>
									<option value="false">No</option>
								</Form.Select> 
								*/}
							</Col>
						</Form.Group>

						{(formData.disc_clmt || formData.disc_act || formData.disc_court || formData.disc_ssa) && <DiscLog user={user} />}
					</div>

					<div className="py-3 border-bottom">
						<ul>
							<li>
								I understand and will comply with SSA laws and rules relating to the representation of parties, including the Rules of Conduct and Standards of Responsibility for Representatives.
							</li>
							<li>
								I will not charge, collect, or retain a fee for representational services that SSA has not approved or that is more than SSA approved, unless a regulatory exclusion applies.
							</li>
							<li>
								I will not threaten, coerce, intimidate, deceive, or knowingly mislead a claimant or prospective claimant, or beneficiary, regarding benefits or other rights under the Social Security Act.
							</li>
							<li>
								I will not knowingly make or present, or participate in making or presenting, false or misleading oral or written statements, assertions, or representations about a material fact or law concerning a matter within SSA's jurisdiction.
							</li>
							<li>
								I am aware that if I fail to comply with any SSA laws and rules relating to representation, I may be suspended or disqualified from practicing as a representative before SSA.
							</li>
							<li>
								I will not divulge any information that SSA has furnished or disclosed about a claim or prospective claim, unless I have the claimant's consent or there is a Federal law or regulation authorizing me to divulge this information.
							</li>
							<li>
								I have in place reasonable administrative, technical, and physical security safeguards to protect the confidentiality of all personal information I receive from SSA, to avoid its loss, theft, or inadvertent disclosure.
							</li>
							<li>
								I will not omit or otherwise withhold disclosure of information to SSA that is material to the benefit entitlement or eligibility of claimants or beneficiaries, nor will I cause someone else to do so, if I know or should know, that this would be false or misleading.
							</li>
							<li>
								I will not use Social Security program words, letters, symbols, branding, or emblems in my advertising or other communications, in a way that conveys the false impression that SSA has approved, endorsed, or authorized me, my communications, or my organization, or that I have some connection with or authorization from SSA.
							</li>
							<li>
								I will update this registration if my personal, professional or business affiliation information changes, including information related to disbarments, suspensions or sanctions.
							</li>
							<li>
								I am aware that if I fail to comply with SSA laws and rules, I could be criminally punished by a fine or imprisonment or both, and I could be subject to civil monetary penalties.
							</li>
							<li>
								I understand that SSA will validate the information I provide.
							</li>
						</ul>
						<Form.Check
							type='checkbox'
							id='defaultCheckbox'
							name="acknowledgement_1"
							checked={formData.acknowledgement_1}
							onChange={e => saveOnChange(e, true)}
							label='I acknowledge that I have read and understand the above statements.'
						/>
					</div>
					<div className="py-3 border-bottom">
						<ul>
							<li>
								I understand that the information I’ve provided through this website may be used to generate a Form SSA-1699 and, at my direction, the generated form may be submitted to the Social Security Administration.  (This form will not be submitted automatically.)
							</li>
							<li>
								I agree that a copy of this signed Form SSA-1699 will have the same force and effect as the original.
							</li>
							<li>
								I declare under penalty of perjury that I have examined all of the information on this application and it is true and correct to the best of my knowledge.
							</li>
						</ul>
						<Form.Check
							type='checkbox'
							id='defaultCheckbox'
							name="acknowledgement_2"
							checked={formData.acknowledgement_2}
							onChange={e => saveOnChange(e, true)}
							label='I acknowledge that I have read and understand the above statements.'
						/>
					</div>
				</Form>
			</Tab>
			<Tab eventKey="of-counsel-agreements" title="Of Counsel Agreements" className='border-bottom border-x p-3'>
				<OfCounselAgreements
					user={user}
					setUser={setUser}
				/>
			</Tab>
		</Tabs>
	</div>);
};

RepresentativeRegistration.propTypes = {
	user: PropTypes.object,
	setUser: PropTypes.func,
	refreshParent: PropTypes.func,
};

export default RepresentativeRegistration;
