import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import IconButton from 'components/common/IconButton';

const HearingTableHeader = ({
	label = "Hearings",
	selectedRowIds,
	page = [],
	requiredSearchParams = {},
	searchParams = {},
	defaultSearchParams = {},
	setSearchParams = () => { },
	setHearingCreationModal = () => { },
	clearSearchParams = () => { },
	hearingData = [] }) => {

	const hearingIdsSelected = Array.isArray(page)
		? (page.reduce((acc, { isSelected = false, original } = {}) => (
			[...acc, ...(isSelected ? [original] : [])]
		), [])).map(({ id } = {}) => id)
		: [];

	return (<Row className="flex-between-center">
		<Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
			<h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">{label}</h5>
		</Col>
		<Col xs={8} sm="auto" className="ms-auto text-end ps-0">
			{Object.keys(selectedRowIds).length > 0 ? (
				<div className="d-flex">

				</div>
			) : (
				<div id="orders-actions">
					{
						JSON.stringify(searchParams) !== JSON.stringify({ ...defaultSearchParams, ...requiredSearchParams }) && (
							<IconButton
								size="sm"
								variant="outline-danger"
								className="me-2"
								icon="x"
								onClick={() => {
									clearSearchParams();
								}}
							>Clear Filters</IconButton>
						)
					}
					<IconButton
						variant="falcon-default"
						size="sm"
						icon="plus"
						transform="shrink-3"
						onClick={() => { setHearingCreationModal(true) }}
					>
						<span className="d-none d-sm-inline-block ms-1">Create Hearing</span>
					</IconButton>
				</div>
			)}
		</Col>
	</Row>);
};

HearingTableHeader.propTypes = {
	label: PropTypes.string,
	selectedRowIds: PropTypes.object,
	page: PropTypes.array,
	requiredSearchParams: PropTypes.object,
	searchParams: PropTypes.object,
	defaultSearchParams: PropTypes.object,
	setSearchParams: PropTypes.func,
	setHearingCreationModal: PropTypes.func,
	clearSearchParams: PropTypes.func,
	hearingData: PropTypes.array,
};

export default HearingTableHeader;