import React from 'react';
import UserTable from './UserTable';
import auth from 'utils/auth';
const UserList = () => {
	return (
		<>
			<UserTable requiredSearchParams={{
				account_id: auth.getProfile().data.account_id
			}} />
		</>
	);
};

export default UserList;