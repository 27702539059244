import React, { useContext, useEffect, useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import AuthSimpleLayout from './AuthSimpleLayout';
import is from 'is_js';
import MainLayout from './MainLayout';
import SettingsToggle from 'components/settings-panel/SettingsToggle';
import SettingsPanel from 'components/settings-panel/SettingsPanel';

import ErrorLayout from './ErrorLayout';

import RegistrationWizard from 'components/wizard/registration-wizard/RegistrationWizard';
import NewUserRegistrationWizard from 'components/wizard/new-user-registration-wizard/NewUserRegistrationWizard';
import PasswordResetWizard from 'components/wizard/password-reset-wizard/PasswordResetWizard';
import Dashboard from 'components/pages/dashboard';
import Landing from 'components/pages/landing/Landing';
import InboundLeads from 'components/pages/inbound-leads/InboundLeads';

import ClaimantDetails from 'components/pages/claimant-details';
import ExternalTaskPortal from 'components/pages/external-task-portal';

import AccountManagement from 'components/pages/super-admin/account-management';
import AdminUserManagement from 'components/pages/super-admin/account-management/user-management';
import AdminUserDetails from 'components/pages/super-admin/account-management/user-management/user-details';
import AccountDetails from 'components/pages/super-admin/account-management/account-details';
import ClaimantManagement from 'components/pages/super-admin/account-management/claimant-management';
import AdminClaimantDetails from 'components/pages/super-admin/account-management/claimant-management/claimant-details';
import TaskManagement from 'components/pages/super-admin/account-management/task-management';
import DocumentManagement from 'components/pages/super-admin/account-management/document-management';

import ClaimantFolders from 'components/pages/claimant-folders';
import ClaimantProfile from 'components/pages/claimant-folders/claimant-profile';

import PDFViewerLayout from 'components/app/pdfjs-viewer/PDFViewerLayout';
import PDFViewerPreview from 'components/app/pdfjs-viewer/PDFViewerPreview';

import AccessVerificationLayout from './AccessVerificationLayout';

import TaskManager from 'components/pages/task-manager';
import Documents from 'components/pages/documents';
import DocumentGenerator from 'components/pages/documents/my-forms/document-generator';
import FormGenerator from 'components/pages/documents/my-forms/form-generator';
import MyFormRepository from 'components/pages/documents/my-forms/MyFormRepository';
import MyFormBuilder from 'components/pages/documents/my-forms/MyFormBuilder';

import InboxOutbox from 'components/pages/inbox-outbox';

import Reservation from 'components/pages/reservation';
import Briefs from 'components/pages/assure/briefs';
import MedicalRecords from 'components/pages/assure/medical-records';
import Atlasware from 'components/pages/assure/atlasware';
import AtlaswareCaseFiles from 'components/pages/assure/atlasware/case-files';
import AtlaswareUploader from 'components/pages/assure/atlasware/uploader';
import AtlaswareStatusReports from 'components/pages/assure/atlasware/status-reports';
import Hearings from 'components/pages/hearings';
import Reports from 'components/pages/reports';
import Error404 from 'components/errors/Error404';
import Error500 from 'components/errors/Error500';
import ServicesLayout from './ServicesLayout';
import SiteAdminLayout from './SiteAdminLayout';
import AdminLayout from './AdminLayout.js';
import Account from 'components/pages/account';
import UserProfile from 'components/pages/users/user/profile';
import UserManagement from 'components/pages/users/user-list';
import CustomFields from 'components/custom-fields/CustomFields';
import LeadVendors from 'components/pages/lead-vendors';
import PrivacyPolicy from 'components/pages/miscellaneous/privacy-policy/PrivacyPolicy';
import PrivacyPolicyPage from 'components/pages/miscellaneous/privacy-policy';

////////////////////////////////////////////////////////////////////
import WizardAuth from 'components/authentication/wizard/WizardAuth';
import { toast, ToastContainer } from 'react-toastify';
import { CloseButton } from 'components/common/Toast';
import Inbox from 'components/app/email/inbox/Inbox';
import CardLogin from 'components/authentication/card/Login';
import Logout from 'components/authentication/card/Logout';
import CardForgetPassword from 'components/authentication/card/ForgetPassword';
import AppContext from 'context/Context';

const APP_DEFAULT_SHOW_SETTINGS_TOGGLE = process?.env?.REACT_APP_DEFAULT_SHOW_SETTINGS_TOGGLE === 'true'; // default to false if not set as true in client .env

const Layout = () => {
  const HTMLClassList = document.getElementsByTagName('html')[0].classList;
  useContext(AppContext);

  useEffect(() => {
    if (is.windows()) {
      HTMLClassList.add('windows');
    }
    if (is.chrome()) {
      HTMLClassList.add('chrome');
    }
    if (is.firefox()) {
      HTMLClassList.add('firefox');
    }
  }, [HTMLClassList]);

  const [showSettingsToggle, setShowSettingsToggle] = useState(APP_DEFAULT_SHOW_SETTINGS_TOGGLE);

  return (
    <>
      <Routes>

        <Route element={<ErrorLayout />}>
          <Route path="errors/404" element={<Error404 />} />
          <Route path="errors/500" element={<Error500 />} />
        </Route>

        {/*- ------------- Landing Page ---------------------------  */}
        <Route path="/" element={<Landing />} />

        {/*- ------------- Authentication ---------------------------  */}
        <Route path="/login" element={<CardLogin />} />

        <Route
          path="/forgot-password"
          element={<CardForgetPassword />}
        />

        <Route path="/register" element={<WizardAuth Component={RegistrationWizard} />} />

        <Route path="/logout" element={<Logout />} />

        <Route path="/claimant/details/:id" element={<ClaimantDetails />} />
        <Route path="/external/portal/:uuid" element={<ExternalTaskPortal />} />

        {/*- ------------- Privacy Policy ---------------------------  */}
        <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />

        {/*- ------------- Invite User ---------------------------  */}
        <Route path="/user/invite/:uuid" element={<WizardAuth Component={NewUserRegistrationWizard} />} />
        {/*- ------------- Password Reset Link ---------------------------  */}
        <Route path="/user/password-reset/:uuid" element={<WizardAuth Component={PasswordResetWizard} />} />

        {/*- ------------- Access Verification Layout ---------------------------  */}
        {/* <Route element={<AccessVerificationLayout />}> */}
        {/*- ------------- Form Response  ---------------------------  */}
        {/* <Route path="/form-response/:uuid" element={<MyFormViewer />} /> */}
        {/* </Route> */}
        <Route path="/my-form-response/:uuid" element={<AccessVerificationLayout />} />

        <Route path="/document-response/:uuid" element={<PDFViewerLayout />} />
        <Route path="/preview-pdf/:myFormResponseId" element={<PDFViewerPreview />} />
        <Route path="/preview-pdf/:claimant_id/:pdf_template" element={<PDFViewerPreview />} />
        <Route path="/preview-pdf/user/:user_id/:pdf_template" element={<PDFViewerPreview />} />


        {/*- ------------- Authenticated Routes ---------------------------  */}
        <Route element={<MainLayout />}>
          {/*- ------------- Dashboard ---------------------------  */}
          <Route path="/dashboard" element={<Dashboard />} />

          {/*- ------------- Inbound Lead Management ---------------------------  */}
          <Route path="/inbound-leads" element={<InboundLeads />} />

          {/*- ------------- Claimant Folders ---------------------------  */}
          <Route path="/claimant-folders" element={<ClaimantFolders />} />
          <Route path="/claimant/profile/:id" element={<ClaimantProfile />} />

          {/*- ------------- Task Manager ---------------------------  */}
          <Route path="/task-manager" element={<TaskManager />} />

          {/*- ------------- Documents ---------------------------  */}
          <Route path="/documents" element={<Documents />} />
          <Route path="/document-generator" element={<DocumentGenerator />} />
          <Route path="/form-generator" element={<FormGenerator />} />
          <Route path="/my-forms" element={<MyFormRepository />} />
          <Route path="/my-forms/:id" element={<MyFormBuilder />} />

          {/*- ------------- Inbox/Outbox ---------------------------  */}
          <Route path="/inbox-outbox" element={<InboxOutbox />} />
          <Route path="/inbox" element={<Inbox />} />
          <Route path="/outbox" element={<Inbox />} />
          <Route path="/create-correspondence" element={<Inbox />} />

          <Route path="/documentation/privacy-policy" element={<PrivacyPolicy />} />

          {/*- ------------- Reservation! ---------------------------  */}
          <Route path="/reservation" element={<Reservation />} />

          {/*- ------------- Map! ---------------------------  */}
          {/* <Route path="/map-view" element={<MapViewer />} /> */}

          {/*- ------------- Atlasware ---------------------------  */}
          <Route path="/atlasware" element={<Atlasware />} />
          <Route path="/atlasware/case-files" element={<AtlaswareCaseFiles />} />
          <Route path="/atlasware/uploader" element={<AtlaswareUploader />} />
          <Route path="/atlasware/status-reports" element={<AtlaswareStatusReports />} />

          {/*- ------------- Assure - Suite of Services ---------------------------  */}
          <Route element={<ServicesLayout />}>

            {/*- ------------- Hearings ---------------------------  */}
            <Route path="/hearings" element={<Hearings />} />



            {/*- ------------- Briefs ---------------------------  */}
            <Route path="/briefs" element={<Briefs />} />

            {/*- ------------- Medical Records ---------------------------  */}
            <Route path="/medical-records" element={<MedicalRecords />} />

          </Route>

          {/*- ------------- User Profile ---------------------------  */}
          <Route path="/user/profile" element={<UserProfile />} />
          <Route path="/user/profile/:id" element={<UserProfile />} />

          {/*- ------------- Admin ---------------------------  */}
          <Route element={<AdminLayout />}>
            {/*- ------------- User Management ---------------------------  */}
            <Route path="/user-management" element={<UserManagement />} />
            {/*- ------------- Account Admin Area ---------------------------  */}
            <Route path="/account-management" element={<Account />} />
            {/*- ------------- Custom Fields ---------------------------  */}
            <Route path="/custom-fields" element={<CustomFields />} />
            {/*- ------------- Lead Vendors ---------------------------  */}
            <Route path="/lead-vendors" element={<LeadVendors />} />
          </Route>

          {/*- ------------- Reports ---------------------------  */}
          <Route path="/reports" element={<Reports />} />


          {/*- ------------- Site Admin Area ---------------------------  */}
          <Route element={<SiteAdminLayout />}>
            <Route path="/admin/account-management" element={<AccountManagement />} />
            <Route path="/admin/account-details/:accountId" element={<AccountDetails />} />
            <Route path="/admin/user-management" element={<AdminUserManagement />} />
            <Route path="/admin/user-details/:id" element={<AdminUserDetails />} />
            <Route path="/admin/claimant-management" element={<ClaimantManagement />} />
            <Route path="/admin/claimant-details/:id" element={<AdminClaimantDetails />} />
            <Route path="/admin/task-management" element={<TaskManagement />} />
            <Route path="/admin/document-management" element={<DocumentManagement />} />
          </Route>

        </Route>

        {/*- ------------- simple ---------------------------  */}
        {/* <Route element={<AuthSimpleLayout />}>
          <Route path="temp/authentication/simple/login" element={<SimpleLogin />} />
          <Route
            path="temp/authentication/simple/register"
            element={<SimpleRegistration />}
          />
          <Route
            path="temp/authentication/simple/logout"
            element={<SimpleLogout />}
          />
          <Route
            path="temp/authentication/simple/forgot-password"
            element={<SimpleForgetPassword />}
          />
          <Route
            path="temp/authentication/simple/reset-password"
            element={<SimplePasswordReset />}
          />
          <Route
            path="temp/authentication/simple/confirm-mail"
            element={<SimpleConfirmMail />}
          />
          <Route
            path="temp/authentication/simple/lock-screen"
            element={<SimpleLockScreen />}
          />
        </Route> */}

        {/*- ------------- Card ---------------------------  */}
        {/* <Route path="temp/authentication/card/login" element={<CardLogin />} />
        <Route
          path="temp/authentication/card/register"
          element={<CardRegistration />}
        />
        <Route path="temp/authentication/card/logout" element={<Logout />} />
        <Route
          path="temp/authentication/card/forgot-password"
          element={<CardForgetPassword />}
        />
        <Route
          path="temp/authentication/card/reset-password"
          element={<CardPasswordReset />}
        />
        <Route
          path="temp/authentication/card/confirm-mail"
          element={<CardConfirmMail />}
        />
        <Route
          path="temp/authentication/card/lock-screen"
          element={<CardLockScreen />}
        /> */}

        {/*- ------------- Split ---------------------------  */}

        {/* <Route path="temp/authentication/split/login" element={<SplitLogin />} />
        <Route path="temp/authentication/split/logout" element={<SplitLogout />} />
        <Route
          path="temp/authentication/split/register"
          element={<SplitRegistration />}
        />
        <Route
          path="temp/authentication/split/forgot-password"
          element={<SplitForgetPassword />}
        />
        <Route
          path="temp/authentication/split/reset-password"
          element={<SplitPasswordReset />}
        />
        <Route
          path="temp/authentication/split/confirm-mail"
          element={<SplitConfirmMail />}
        />
        <Route
          path="temp/authentication/split/lock-screen"
          element={<SplitLockScreen />}
        />

        <Route element={<WizardAuth />}>
          <Route
            path="temp/authentication/wizard"
            element={<Wizard validation={true} />}
          /> 
        </Route>*/}

        {/* //--- MainLayout Starts  */}

        {/* <Route element={<MainLayout />}> */}
        {/*Dashboard*/}
        {/* <Route path="temp/" element={<Dashboard />} />
          <Route path="temp/dashboard/analytics" element={<Analytics />} />
          <Route path="temp/dashboard/crm" element={<Crm />} />
          <Route path="temp/dashboard/saas" element={<Saas />} />
          <Route path="temp/dashboard/e-commerce" element={<Ecommerce />} />
          <Route
            path="temp/dashboard/project-management"
            element={<ProjectManagement />}
          /> */}
        {/* E Commerce */}
        {/* <Route
            path="temp/e-commerce/orders/order-details"
            element={<OrderDetails />}
          />
          <Route path="temp/e-commerce/orders/order-list" element={<Orders />} />
          <Route path="temp/e-commerce/invoice" element={<Invoice />} />
          <Route path="temp/e-commerce/billing" element={<Billing />} />
          <Route path="temp/e-commerce/checkout" element={<Checkout />} />
          <Route path="temp/e-commerce/shopping-cart" element={<ShoppingCart />} />
          <Route path="temp/e-commerce/customers" element={<Customers />} />
          <Route
            path="temp/e-commerce/customer-details"
            element={<CustomersDetails />}
          />

          <Route
            path="temp/e-commerce/product/product-details"
            element={<ProductDetails />}
          />
          <Route
            path="temp/e-commerce/product/product-details/:productId"
            element={<ProductDetails />}
          /> */}

        {/* <Route
            path="temp/e-commerce/product/:productLayout"
            element={<Products />}
          /> */}

        {/* <Route path="temp/e-commerce/invoice" element={<Invoice />} /> */}

        {/*icons*/}
        {/* <Route path="temp/icons/font-awesome" element={<FontAwesome />} />
          <Route path="temp/icons/react-icons" element={<ReactIcons />} /> */}

        {/* maps */}
        {/* <Route path="temp/maps/google-map" element={<GoogleMapExample />} />
          <Route path="temp/maps/leaflet-map" element={<LeafletMapExample />} /> */}

        {/*App*/}
        {/* <Route path="temp/app/calendar" element={<Calendar />} />
          <Route path="temp/app/chat" element={<Chat />} />
          <Route path="temp/app/kanban" element={<Kanban />} />
          <Route path="temp/social/feed" element={<Feed />} />
          <Route path="temp/social/activity-log" element={<ActivityLog />} />
          <Route path="temp/social/notifications" element={<Notifications />} />
          <Route path="temp/social/followers" element={<Followers />} />
          <Route path="temp/events/event-detail" element={<EventDetail />} />
          <Route path="temp/events/create-an-event" element={<CreateEvent />} />
          <Route path="temp/events/event-list" element={<EventList />} /> */}

        {/* Email */}
        {/* <Route path="temp/email/email-detail" element={<EmailDetail />} />
          <Route path="temp/email/inbox" element={<Inbox />} />
          <Route path="temp/email/compose" element={<Compose />} /> */}

        {/*Pages*/}
        {/* <Route path="temp/pages/starter" element={<Starter />} /> */}
        {/* <Route path="temp/user/profile" element={<Profile />} />
          <Route path="temp/user/settings" element={<Settings />} />
          <Route path="temp/miscellaneous/associations" element={<Associations />} />
          <Route path="temp/faq/faq-alt" element={<FaqAlt />} />
          <Route path="temp/faq/faq-basic" element={<FaqBasic />} />
          <Route path="temp/faq/faq-accordion" element={<FaqAccordion />} />
          <Route path="temp/pricing/pricing-default" element={<PricingDefault />} />
          <Route path="temp/pricing/pricing-alt" element={<PricingAlt />} />
          <Route
            path="temp/miscellaneous/privacy-policy"
            element={<PrivacyPolicy />}
          />
          <Route
            path="temp/miscellaneous/invite-people"
            element={<InvitePeople />}
          /> */}
        {/* charts-example */}

        {/* <Route path="temp/charts/chartjs" element={<Chartjs />} />
          <Route path="temp/charts/echarts/line-charts" element={<LineCharts />} />
          <Route path="temp/charts/echarts/bar-charts" element={<BarCharts />} />
          <Route
            path="temp/charts/echarts/candlestick-charts"
            element={<CandlestickCharts />}
          />
          <Route path="temp/charts/echarts/geo-map" element={<GeoMaps />} />
          <Route
            path="temp/charts/echarts/scatter-charts"
            element={<ScatterCharts />}
          />
          <Route path="temp/charts/echarts/pie-charts" element={<PieCharts />} />
          <Route path="temp/charts/echarts/radar-charts" element={<RadarCharts />} />
          <Route
            path="temp/charts/echarts/heatmap-charts"
            element={<HeatmapCharts />}
          />
          <Route path="temp/charts/echarts/how-to-use" element={<HowToUse />} /> */}

        {/*Components*/}
        {/* <Route path="temp/components/alerts" element={<Alerts />} />
          <Route path="temp/components/accordion" element={<Accordion />} />
          <Route path="temp/components/animated-icons" element={<AnimatedIcons />} />
          <Route path="temp/components/badges" element={<Badges />} />
          <Route path="temp/components/breadcrumb" element={<Breadcrumbs />} />
          <Route path="temp/components/buttons" element={<Buttons />} />
          <Route path="temp/components/calendar" element={<CalendarExample />} />
          <Route path="temp/components/cards" element={<Cards />} />
          <Route path="temp/components/dropdowns" element={<Dropdowns />} />
          <Route path="temp/components/list-group" element={<ListGroups />} />
          <Route path="temp/components/modals" element={<Modals />} />
          <Route path="temp/components/offcanvas" element={<Offcanvas />} />
          <Route path="temp/components/pagination" element={<Pagination />} />
          <Route
            path="temp/components/progress-bar"
            element={<BasicProgressBar />}
          />
          <Route path="temp/components/placeholder" element={<Placeholder />} />
          <Route path="temp/components/spinners" element={<Spinners />} />
          <Route path="temp/components/toasts" element={<Toasts />} />
          <Route path="temp/components/pictures/avatar" element={<Avatar />} />
          <Route path="temp/components/pictures/images" element={<Image />} />
          <Route path="temp/components/pictures/figures" element={<Figures />} />
          <Route path="temp/components/pictures/hoverbox" element={<Hoverbox />} />
          <Route path="temp/components/pictures/lightbox" element={<Lightbox />} />
          <Route path="temp/components/tooltips" element={<Tooltips />} />
          <Route path="temp/components/popovers" element={<Popovers />} />
          <Route path="temp/components/draggable" element={<DraggableExample />} />
          <Route path="temp/components/scrollspy" element={<Scrollspy />} />
          <Route path="temp/components/timeline" element={<Timeline />} />
          <Route path="temp/components/treeview" element={<TreeviewExample />} />
          <Route
            path="temp/components/carousel/bootstrap"
            element={<BootstrapCarousel />}
          />
          <Route path="temp/components/carousel/slick" element={<SlickCarousel />} />
          <Route path="temp/components/navs-and-tabs/navs" element={<Navs />} />
          <Route path="temp/tables/basic-tables" element={<Tables />} />
          <Route
            path="temp/tables/advance-tables"
            element={<AdvanceTableExamples />}
          />
          <Route path="temp/forms/basic/form-control" element={<FormControl />} />
          <Route path="temp/forms/basic/input-group" element={<InputGroup />} />
          <Route path="temp/forms/basic/select" element={<Select />} />
          <Route path="temp/forms/basic/checks" element={<Checks />} />
          <Route path="temp/forms/basic/range" element={<Range />} />
          <Route path="temp/forms/basic/layout" element={<FormLayout />} />
          <Route path="temp/forms/advance/date-picker" element={<DatePicker />} />
          <Route path="temp/forms/advance/editor" element={<Editor />} />
          <Route
            path="temp/forms/advance/emoji-button"
            element={<EmojiMartExample />}
          />
          <Route
            path="temp/forms/advance/advance-select"
            element={<AdvanceSelect />}
          />
          <Route
            path="temp/forms/advance/file-uploader"
            element={<FileUploader />}
          />
          <Route path="temp/forms/advance/rating" element={<Rating />} />
          <Route path="temp/forms/floating-labels" element={<FloatingLabels />} />
          <Route path="temp/forms/validation" element={<FormValidation />} />
          <Route path="temp/forms/wizard" element={<WizardForms />} />
          <Route path="temp/components/navs-and-tabs/navbar" element={<Navbars />} />
          <Route path="temp/components/navs-and-tabs/tabs" element={<Tabs />} />
          <Route path="temp/components/collapse" element={<Collapse />} />
          <Route
            path="temp/components/cookie-notice"
            element={<CookieNoticeExample />}
          />
          <Route path="temp/components/countup" element={<CountUp />} />
          <Route path="temp/components/videos/embed" element={<Embed />} />
          <Route
            path="temp/components/videos/react-player"
            element={<ReactPlayerExample />}
          />
          <Route path="temp/components/background" element={<Background />} />
          <Route path="temp/components/search" element={<Search />} />
          <Route path="temp/components/typed-text" element={<TypedText />} />
          <Route
            path="temp/components/navs-and-tabs/vertical-navbar"
            element={<VerticalNavbar />}
          />
          <Route
            path="temp/components/navs-and-tabs/top-navbar"
            element={<NavBarTop />}
          />
          <Route
            path="temp/components/navs-and-tabs/combo-navbar"
            element={<ComboNavbar />}
          /> */}

        {/*Utilities*/}
        {/* <Route path="temp/utilities/borders" element={<Borders />} />
          <Route path="temp/utilities/colors" element={<Colors />} />
          <Route path="temp/utilities/colored-links" element={<ColoredLinks />} />
          <Route path="temp/utilities/display" element={<Display />} />
          <Route path="temp/utilities/visibility" element={<Visibility />} />
          <Route path="temp/utilities/stretched-link" element={<StretchedLink />} />
          <Route path="temp/utilities/stretched-link" element={<StretchedLink />} />
          <Route path="temp/utilities/float" element={<Float />} />
          <Route path="temp/utilities/position" element={<Position />} />
          <Route path="temp/utilities/spacing" element={<Spacing />} />
          <Route path="temp/utilities/sizing" element={<Sizing />} />
          <Route
            path="temp/utilities/text-truncation"
            element={<TextTruncation />}
          /> */}
        {/* <Route path="temp/utilities/typography" element={<Typography />} />
          <Route path="temp/utilities/vertical-align" element={<VerticalAlign />} />
          <Route path="temp/utilities/flex" element={<Flex />} />
          <Route path="temp/utilities/grid" element={<Grid />} />
          <Route path="temp/utilities/scroll-bar" element={<Scrollbar />} />

          <Route path="temp/widgets" element={<Widgets />} /> */}

        {/*Documentation*/}
        {/* <Route
            path="temp/documentation/getting-started"
            element={<GettingStarted />}
          />
          <Route
            path="temp/documentation/configuration"
            element={<Configuration />}
          />
          <Route path="temp/documentation/styling" element={<Styling />} />
          <Route path="temp/documentation/dark-mode" element={<DarkMode />} />
          <Route path="temp/documentation/plugin" element={<Plugins />} />
          <Route path="temp/documentation/faq" element={<Faq />} />
          <Route path="temp/documentation/design-file" element={<DesignFile />} />
          <Route path="temp/changelog" element={<Changelog />} />
          <Route path="temp/authentication-modal" element={<ModalAuth />} />
        </Route> */}

        {/* //--- MainLayout end  */}

        {/* <Navigate to="/errors/404" /> */}
        <Route path="*" element={<Navigate to="/errors/404" replace />} />
      </Routes>
      <SettingsToggle {...{ showSettingsToggle, setShowSettingsToggle }} />
      <SettingsPanel />
      <ToastContainer
        closeButton={CloseButton}
        icon={false}
        position={toast.POSITION.BOTTOM_LEFT}
      />
    </>
  );
};

export default Layout;
