import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import ClaimantTable from './ClaimantTable';

const ClaimantFolderTable = ({ requiredSearchParams = {}, defaultSearchParams = {}, defaultColumns = null, siteAdminArea = false } = {}) => {
	let columns = defaultColumns || [
		// {
		// 	accessor: 'name',
		// 	Header: 'Name',
		// 	headerProps: { className: 'pe-1' },
		// 	cellProps: {
		// 		className: 'py-2'
		// 	},
		// 	Cell: rowData => {
		// 		const { name, id } = rowData.row.original;
		// 		return <Link to={`/claimant/profile/${id}`}>{name}</Link>;
		// 	}
		// },
		{
			accessor: 'first_name',
			Header: 'First Name',
			headerProps: { className: 'pe-1' },
			cellProps: {
				className: 'py-2'
			},
			Cell: rowData => {
				const { first_name, id } = rowData.row.original;
				return <Link to={`/claimant/profile/${id}`}>{first_name}</Link>;
			}
		},
		{
			accessor: 'last_name',
			Header: 'Last Name',
			headerProps: { className: 'pe-1' },
			cellProps: {
				className: 'py-2'
			},
			Cell: rowData => {
				const { last_name, id } = rowData.row.original;
				return <Link to={`/claimant/profile/${id}`}>{last_name}</Link>;
			}
		},
		{
			accessor: 'ssn',
			Header: 'SSN',
			headerProps: { className: 'pe-1' },
			cellProps: {
				className: 'py-2'
			}
		},
		{
			accessor: 'created_at',
			Header: 'Created',
			headerProps: { className: 'pe-1' },
			cellProps: {
				className: 'py-2'
			}
		},
		{
			accessor: 'primary_representative',
			Header: 'Primary Rep',
			headerProps: { className: 'pe-1' },
			cellProps: {
				className: 'py-2'
			},
		},
		{
			accessor: 'office_with_jurisdiction',
			Header: 'Office with Jurisdiction',
			headerProps: { className: 'pe-1' },
			cellProps: {
				className: 'py-2'
			},
		},
	];

	useEffect(() => { }, []);

	return (
		<div>
			<ClaimantTable
				tableHeaderLabel="Cases"
				siteAdminArea={siteAdminArea}
				columns={columns}
				defaultSearchParams={{ ...defaultSearchParams }}
				requiredSearchParams={{
					active_claimant: true,
					active_case: true,
					...requiredSearchParams
				}}
			/>
		</div>
	);
};

ClaimantFolderTable.propTypes = {
	requiredSearchParams: PropTypes.object,
	defaultSearchParams: PropTypes.object,
	defaultColumns: PropTypes.array,
	siteAdminArea: PropTypes.bool
};

export default ClaimantFolderTable;