import axios from "axios";

function get(url) {
  return axios.get(url).then(res => res.data).catch(err => { console.log(err); return null; });
}

const ipify = {
  ipv4() {
    return get('https://api.ipify.org');
  },
  ipv64() {
    return get('https://api64.ipify.org');
  }
};

/**
 * Uses ipify functions to get external ip 
 * @returns string IP 
 */
const getExternalIP = async () => {
  let external_ip = await ipify.ipv64();
  if (!external_ip) {
    external_ip = await ipify.ipv4();
  }
  return external_ip;
};

const getClientDigitalInformation = async () => {
  let { userAgent: user_agent } = navigator || window?.navigator || {};
  return {
    timestamp: new Date(),
    ip: await getExternalIP(),
    user_agent,
  };
}

export {
  ipify,
  getExternalIP,
  getClientDigitalInformation,
};