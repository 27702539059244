import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Auth from 'utils/auth';
import { Col, Row } from 'react-bootstrap';
import UserProfileCard from './UserProfileCard';
import UserProfileTabs from './UserProfileTabs';
import { userAPI } from "utils/api/fulcrum-api"
import { toast } from 'react-toastify';

const UserProfile = ({ siteAdminArea = false }) => {
  const navigate = useNavigate();
  let { id: userId } = useParams();
  userId = userId || Auth.getProfile().data.user_id;

  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState({});

  // Get user details
  const getUserDetails = async () => {
    try {
      let result = await userAPI.getUserDetails(userId);
      if (!result?.data) {
        throw new Error('No user data returned');
      }
      setUser(result.data);
      setLoading(false);
    } catch (error) {
      console.error(error);
      navigate('/dashboard');
      toast.error('Error loading user details', { theme: 'colored' });
    }
  };

  // Refresh user
  const refreshUser = () => {
    getUserDetails(userId);
  };

  useEffect(() => {
    getUserDetails(userId);
  }, [userId]);

  if (loading) {
    return (<p>Loading user details...</p>);
  };

  return (
    <div>
      <Row>
        <Col md={5} xxl={4}>
          <div className="mb-3">
            <UserProfileCard user={user} setUser={setUser} refreshParent={refreshUser} siteAdminArea={siteAdminArea} />
          </div>
        </Col>
        <Col md={7} xxl={8}>
          <UserProfileTabs user={user} setUser={setUser} refreshParent={refreshUser} siteAdminArea={siteAdminArea} />
        </Col>
      </Row>
    </div>
  );
};

export default UserProfile;