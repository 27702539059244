import React, { useState } from 'react';
import { Row, Col, Button, Alert, Form, FloatingLabel, DropdownButton, Dropdown, ButtonGroup } from 'react-bootstrap';
import { caseAPI, assureAPI } from 'utils/api/fulcrum-api';
import { toast } from 'react-toastify';
// import ConfirmSSAApplicationButton from './ConfirmSSAApplicationButton';
// import ConfirmAppointmentAsRepresentative from './ConfirmAppointmentAsRepresentative';
import SubmitRepresentativeDocuments from './SubmitRepresentativeDocuments';
import RequestCaseStatusUpdate from './RequestCaseStatusUpdate';
import FileAnAppeal from './FileAnAppeal';
import RecordAdministrationDecision from './RecordAdministrationDecision';
import { useEffect } from 'react';
import Swal from 'sweetalert2';
import HearingOfficeMapModal from '../../../../map/HearingOfficeMapModal';
import SendSSAForms from '../SendSSAForms';
import AssureCase from './AssureCase';
import PrimaryRep from './PrimaryRep';
import CustomFieldList from 'components/custom-fields/custom-field-list/CustomFieldList';

const CaseInformation = ({ attorneyList, caseStatuses = [], claimant, setClaimant, selectedCase, setSelectedCase = () => { }, componentState, setComponentState = () => { }, refreshParent = () => { } }) => {
	let defaultData = {
		jurisdiction_office_id: selectedCase.jurisdiction_office_id,
		office_with_jurisdiction: selectedCase.office_with_jurisdiction,
		office_with_jurisdiction_mailing_address: selectedCase.office_with_jurisdiction_mailing_address,
		office_with_jurisdiction_mailing_city: selectedCase.office_with_jurisdiction_mailing_city,
		office_with_jurisdiction_mailing_state: selectedCase.office_with_jurisdiction_mailing_state,
		office_with_jurisdiction_mailing_zip: selectedCase.office_with_jurisdiction_mailing_zip,
		office_with_jurisdiction_phone: selectedCase.office_with_jurisdiction_phone,
		office_with_jurisdiction_fax: selectedCase.office_with_jurisdiction_fax,
		field_office_id: selectedCase.field_office_id,
		field_office_mailing_address: selectedCase.field_office_mailing_address,
		field_office_mailing_city: selectedCase.field_office_mailing_city,
		field_office_mailing_state: selectedCase.field_office_mailing_state,
		field_office_mailing_zip: selectedCase.field_office_mailing_zip,
		field_office_phone: selectedCase.field_office_phone,
		field_office_fax: selectedCase.field_office_fax,
		ere_claim_type: selectedCase.ere_claim_type,
		ere_status_of_case: selectedCase.ere_status_of_case,
		ere_status_date: selectedCase.ere_status_date,
		ere_electronic_folder: selectedCase.ere_electronic_folder,
		ere_fee_agreement: selectedCase.ere_fee_agreement,
		ere_fee_petition: selectedCase.ere_fee_petition,
		ere_t2_decision: selectedCase.ere_t2_decision,
		ere_t16_decision: selectedCase.ere_t16_decision,
		case_status_id: selectedCase.case_status_id,
		assure_credential_id: selectedCase.assure_credential_id,
	};
	const [formData, setFormData] = useState(defaultData);
	const [previousFormData, setPreviousFormData] = useState(defaultData);
	const [hearingOfficeModalState, setHearingOfficeModalState] = useState(false);
	const [submitRepresentativeDocumentsModal, setSubmitRepresentativeDocumentsModal] = useState(false);
	const [confirmAppointmentAsRepresentativeModal, setConfirmAppointmentAsRepresentativeModal] = useState(false);
	const [requestCaseStatusUpdateModal, setRequestCaseStatusUpdateModal] = useState(false);
	const [recordAdministrationDecisionModal, setRecordAdministrationDecisionModal] = useState(false);
	const [fileAnAppealModalState, setFileAnAppealModalState] = useState(false);

	// Handle form change
	const handleInputChange = e => {
		// let { name, value, type, checked } = e.target;
		let { name, value } = e.target;
		let additionalChanges = {};

		setFormData({
			...formData,
			...additionalChanges,
			[name]: value
		})
	};




	// Update case information
	const updateCaseInformation = async (data) => {
		try {
			let response = await caseAPI.updateCase(selectedCase.claimant_id, selectedCase.id, data);
			if (!response?.data) {
				throw new Error('Unable to update case information');
			}

			setSelectedCase(response.data);
			setPreviousFormData({ ...formData, ...data });
			toast.success('Case information updated successfully', { theme: 'colored' });
		} catch (error) {
			console.log(error);
			toast.error('Unable to update case information', { theme: 'colored' });
		}
	};

	// Refresh Field Office
	const refreshFieldOffice = async (e) => {
		if (e) { e.preventDefault(); }
		let response = await lookupOfficeWithJurisdiction(e);
		if (response) {
			let additionalChanges = {
				...additionalChanges,
				field_office_mailing_address: response?.data?.data?.address,
				field_office_mailing_city: response?.data?.data?.city,
				field_office_mailing_state: response?.data?.data?.state,
				field_office_mailing_zip: response?.data?.data?.zip,
				field_office_phone: response?.data?.data?.phone,
				field_office_fax: response?.data?.data?.fax,
			}
			updateCaseInformation({
				...additionalChanges
			});
		}
	}

	// Refresh Office with Jurisdiction
	const refreshOfficeWithJurisdiction = async (e) => {
		if (e) { e.preventDefault(); }
		let response = await lookupOfficeWithJurisdiction(e);
		if (response) {
			let additionalChanges = {
				...additionalChanges,
				office_with_jurisdiction_mailing_address: response?.data?.data?.address,
				office_with_jurisdiction_mailing_city: response?.data?.data?.city,
				office_with_jurisdiction_mailing_state: response?.data?.data?.state,
				office_with_jurisdiction_mailing_zip: response?.data?.data?.zip,
				office_with_jurisdiction_phone: response?.data?.data?.phone,
				office_with_jurisdiction_fax: response?.data?.data?.fax,
			}
			updateCaseInformation({
				...additionalChanges
			});
		}
	};

	// Handle Confirm Appointment As Representative
	const handleConfirmAppointmentAsRepresentativeClick = async () => {
		Swal.fire({
			title: 'Confirmed Appointment as Representative?',
			text: "Are you sure you want to confirm that you have been appointed as the representative for this claimant?",
			icon: 'question',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Confirmed SSA Application'
		}).then(async (result) => {
			if (result.isConfirmed) {
				try {
					let result = await caseAPI.confirmApoointmentAsRepresentative(selectedCase.claimant_id, selectedCase.id, {});
					if (!result?.data) {
						throw new Error('Something went wrong.');
					}
					setSelectedCase(result.data);
					toast.success("SSA applicaiton confirmation completed!", {
						theme: 'colored'
					});
				} catch (error) {
					toast.error("Unable to confirm the appointment as representative", {
						theme: 'colored'
					});
				}
			}
		});
	};

	// Save on change
	const saveOnChange = async (e, forceUpdate = false, updatingFieldOffice = false) => {
		// let { name, value, type, checked } = e.target;
		let { name, value } = e.target;
		let additionalChanges = {};

		if (name === 'office_with_jurisdiction') {
			let response;
			switch (value) {
				case 'Appeals Council':
					additionalChanges = {
						...additionalChanges,
						office_with_jurisdiction_mailing_address: "5107 Leesburg Pike",
						office_with_jurisdiction_mailing_city: "Falls Church",
						office_with_jurisdiction_mailing_state: "VA",
						office_with_jurisdiction_mailing_zip: "22041",
						office_with_jurisdiction_phone: "703-605-8000",
						office_with_jurisdiction_fax: "833-509-0817",
					};
					updatingFieldOffice = true;
					break;
				case 'Social Security Field Office':
					response = await lookupOfficeWithJurisdiction(e);
					if (response) {
						additionalChanges = {
							...additionalChanges,
							office_with_jurisdiction_mailing_address: response?.data?.data?.address,
							office_with_jurisdiction_mailing_city: response?.data?.data?.city,
							office_with_jurisdiction_mailing_state: response?.data?.data?.state,
							office_with_jurisdiction_mailing_zip: response?.data?.data?.zip,
							office_with_jurisdiction_phone: response?.data?.data?.phone,
							office_with_jurisdiction_fax: response?.data?.data?.fax,
							field_office_mailing_address: null,
							field_office_mailing_city: null,
							field_office_mailing_state: null,
							field_office_mailing_zip: null,
							field_office_phone: null,
							field_office_fax: null,
						}
					}
					break;
				case 'Office of Hearing Operations':
				case 'Federal Court':
				default:
					additionalChanges = {
						...additionalChanges,
						office_with_jurisdiction_mailing_address: "",
						office_with_jurisdiction_mailing_city: "",
						office_with_jurisdiction_mailing_state: "",
						office_with_jurisdiction_mailing_zip: "",
						office_with_jurisdiction_phone: "",
						office_with_jurisdiction_fax: "",
					}

					updatingFieldOffice = true;
			}
		}

		if (updatingFieldOffice) {
			let response = await lookupOfficeWithJurisdiction(e);
			if (response) {
				additionalChanges = {
					...additionalChanges,
					field_office_mailing_address: response?.data?.data?.address,
					field_office_mailing_city: response?.data?.data?.city,
					field_office_mailing_state: response?.data?.data?.state,
					field_office_mailing_zip: response?.data?.data?.zip,
					field_office_phone: response?.data?.data?.phone,
					field_office_fax: response?.data?.data?.fax,
				}
				console.log({ additionalChanges }, name, value);
			}
		}

		if (previousFormData[name] !== value || forceUpdate) {
			await updateCaseInformation({ ...additionalChanges, [name]: value });
		}

		setFormData({
			...formData,
			...additionalChanges,
			[name]: value
		})
	};

	// Revert Confirmed Appointment as Representative
	const revertConfirmedAppointmentAsRepresentative = async () => {
		Swal.fire({
			title: 'Revert Confirmed Appointment as Representative?',
			text: "Are you sure you would like to remove the confirmed appointment as representative?",
			icon: 'question',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Revert'
		}).then(async (result) => {
			if (result.isConfirmed) {
				// Update case information
				await updateCaseInformation({ confirmed_appointment_as_representative: false })
			}
		});
	};

	// Handle Confirm SSA Application Button Click
	const handleConfirmSSAApplicationButtonClick = async () => {
		Swal.fire({
			title: 'Confirm SSA Application?',
			text: "By confirming the SSA application, you are indicating that the SSA has received the application and is processing it. This will update the case status and will mark all tasks with the type of 'Confirm SSA Application' as completed.",
			icon: 'question',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Confirmed SSA Application'
		}).then(async (result) => {
			if (result.isConfirmed) {
				try {
					let result = await caseAPI.confirmSSAApplication(selectedCase.claimant_id, selectedCase.id, {});
					if (!result?.data) {
						throw new Error('Unable to confirm the SSA application.');
					}
					setSelectedCase(result.data);
					toast.success("SSA applicaiton confirmation completed!", {
						theme: 'colored'
					});
				} catch (error) {
					toast.error("Unable to confirm the appliation", {
						theme: 'colored'
					});
				}
			}
		});
	};

	// Revert Confirmed Appointment as Representative
	const revertConfirmation = async () => {
		Swal.fire({
			title: 'Revert Confirmed SSA Application?',
			text: "Are you sure you would like to change confirmation of SSA application to unconfirmed?",
			icon: 'question',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Revert'
		}).then(async (result) => {
			if (result.isConfirmed) {
				try {
					let result = await caseAPI.updateCase(selectedCase.id, {
						confirmed_ssa_application: false
					});
					if (!result?.data) { throw new Error('Unable to revert confirmation of SSA application.') }
					setSelectedCase(result.data)
					toast.success('Confirmation of SSA application reverted.', { theme: 'colored' })
				} catch (error) {
					toast.error('Unable to revert confirmation.', { theme: 'colored' })
				}
			}
		});
	};

	// lookup office with jurisdiction
	const lookupOfficeWithJurisdiction = async () => {
		try {
			let response = await caseAPI.lookupOfficeWithJurisdiction(selectedCase.claimant_id, selectedCase.id);
			if (!response?.data || response?.data?.error) {
				throw new Error('Unable to lookup office with jurisdiction. You must provide a primary address with a valid zip code.');
			}

			return response;

		} catch (error) {
			console.log(error);
			toast.error('Unable to lookup office with jurisdiction. You must provide a primary address with a valid zip code.', {
				theme: 'colored'
			})
		}
	};

	useEffect(() => {
		let newFormData = {
			jurisdiction_office_id: selectedCase.jurisdiction_office_id,
			office_with_jurisdiction: selectedCase.office_with_jurisdiction,
			office_with_jurisdiction_mailing_address: selectedCase.office_with_jurisdiction_mailing_address,
			office_with_jurisdiction_mailing_city: selectedCase.office_with_jurisdiction_mailing_city,
			office_with_jurisdiction_mailing_state: selectedCase.office_with_jurisdiction_mailing_state,
			office_with_jurisdiction_mailing_zip: selectedCase.office_with_jurisdiction_mailing_zip,
			office_with_jurisdiction_phone: selectedCase.office_with_jurisdiction_phone,
			office_with_jurisdiction_fax: selectedCase.office_with_jurisdiction_fax,
			field_office_id: selectedCase.field_office_id,
			field_office_mailing_address: selectedCase.field_office_mailing_address,
			field_office_mailing_city: selectedCase.field_office_mailing_city,
			field_office_mailing_state: selectedCase.field_office_mailing_state,
			field_office_mailing_zip: selectedCase.field_office_mailing_zip,
			field_office_phone: selectedCase.field_office_phone,
			field_office_fax: selectedCase.field_office_fax,
			ere_claim_type: selectedCase.ere_claim_type,
			ere_status_of_case: selectedCase.ere_status_of_case,
			ere_status_date: selectedCase.ere_status_date,
			ere_electronic_folder: selectedCase.ere_electronic_folder,
			ere_fee_agreement: selectedCase.ere_fee_agreement,
			ere_fee_petition: selectedCase.ere_fee_petition,
			ere_t2_decision: selectedCase.ere_t2_decision,
			ere_t16_decision: selectedCase.ere_t16_decision,
			case_status_id: selectedCase.case_status_id,
		};
		setFormData(newFormData);
		setPreviousFormData(newFormData);
	}, [selectedCase]);

	useEffect(() => {

	}, []);

	return (
		<div>
			<Row>
				<Col className="py-3 px-2 bg-200">
					<div>
						<h3 className="d-inline">Claim Information</h3>
						<DropdownButton
							className="float-end"
							as={ButtonGroup}
							id={`claim-dropdown`}
							variant={"primary"}
							title={"Menu"}
						>
							{
								selectedCase.confirmed_ssa_application
									?
									<Dropdown.Item eventKey="4" className="text-danger" onClick={() => revertConfirmation()}>Revert Confirmed SSA Application</Dropdown.Item>
									:
									<Dropdown.Item eventKey="5" onClick={() => handleConfirmSSAApplicationButtonClick()}>Confirm SSA Application</Dropdown.Item>
							}
							<Dropdown.Divider />
							{
								!selectedCase.confirmed_appointment_as_representative ?
									<>
										<Dropdown.Item eventKey="1" onClick={() => setSubmitRepresentativeDocumentsModal(true)}>Submit Representative Documents</Dropdown.Item>
										<Dropdown.Item eventKey="2" onClick={() => handleConfirmAppointmentAsRepresentativeClick()}>Confirm Appointment as Representative</Dropdown.Item>
									</>
									:
									<>
										<Dropdown.Item eventKey="3" onClick={() => setRequestCaseStatusUpdateModal(true)}>Request Case Status Update</Dropdown.Item>
									</>
							}
							<Dropdown.Divider />
							{
								(selectedCase.confirmed_ssa_application && selectedCase.confirmed_appointment_as_representative) &&
								<>
									<Dropdown.Item eventKey="6" onClick={() => setRecordAdministrationDecisionModal(true)}>Record Administration Decision</Dropdown.Item>
									<Dropdown.Item eventKey="7" onClick={() => setFileAnAppealModalState(true)}>File an Appeal</Dropdown.Item>

									{
										selectedCase.case_status_id !== 5 &&
										<>
											<Dropdown.Divider />
											<Dropdown.Item eventKey="8" className="text-danger" onClick={() => setComponentState({ ...componentState, closeCaseModalState: true })}>Close the Case</Dropdown.Item>
										</>
									}

								</>
							}
						</DropdownButton>
					</div>

					{
						(selectedCase?.id && claimant?.claimant_cases && claimant?.claimant_cases.length > 0) &&
						<>
							{
								(selectedCase.id !== claimant.active_case_id && claimant?.active_case_id) &&
								<>
									<Alert variant={"warning"}>
										This is not the active case for this claimant.
										<Button variant="link" onClick={() => refreshParent(claimant.active_case_id)}>Click here to view the active case.</Button>
									</Alert>
								</>
							}
							<Form.Group className="mb-3" controlId="formPlaintextEmail">
								<Form.Label className="fw-bold">
									Selected Case:
								</Form.Label>
								<Form.Select
									size="sm"
									as="select"
									aria-label="Case selection"
									value={selectedCase.id}
									name='selectedCase.id'
									onChange={e => { refreshParent(e.target.value) }}
									className={` form-select`}
								>
									{
										claimant.claimant_cases.map((c, index) => (
											<option key={index} value={c.id}>
												({c.id === claimant.active_case_id ? "Active Case" : "Old Case"}) {c.id} - {c?.case_status?.status} - {c?.jurisdiction_office?.name}
											</option>
										))
									}
								</Form.Select>
							</Form.Group>
						</>
					}

				</Col>
			</Row>


			<div>

				<Form>
					<PrimaryRep
						claimant={claimant}
						setClaimant={setClaimant}
						selectedCase={selectedCase}
						setSelectedCase={setSelectedCase}
						refreshParent={refreshParent}
						updateCaseInformation={updateCaseInformation}
					/>

					<AssureCase
						claimant={claimant}
						setClaimant={setClaimant}
						selectedCase={selectedCase}
						setSelectedCase={setSelectedCase}
						refreshParent={refreshParent}
					/>

					{/* <Form.Group className="my-3">
						<Form.Label>ERE Credentials:</Form.Label>
						<Form.Control
							as="select"
							name="assure_credential_id"
							value={formData.assure_credential_id}
							disabled={false}
							onChange={saveOnChange}
						>
							<option value="">Select an attorney</option>
							{
								Array.isArray(attorneyList) && attorneyList?.map(attorney => (
									<option key={`attorney-cred-${attorney.id}`} value={attorney.id}>{attorney.attorney_name}</option>
								))
							}
						</Form.Control>
					</Form.Group> */}
					<br />
					{/* 
					<Form.Group className="mb-3" controlId="case_status_id">
						<Form.Label className="fw-bold">Case Status</Form.Label>
						<Form.Select
							size="sm"
							as="select"
							aria-label="Case Status"
							value={formData.case_status_id}
							name='case_status_id'
							onChange={e => { saveOnChange(e, true, false) }}
							className={` form-select`}
						>
							{
								Array.isArray(caseStatuses) && caseStatuses.map((c, index) => (
									<option key={index} value={c.id}>
										{c.status}
									</option>
								))
							}
						</Form.Select>
					</Form.Group> */}
					<hr />




					<div className="my-3 py-3">
						<div>
							<h5 className="d-inline">Office with Jurisdiction</h5>
						</div>
						{
							formData.office_with_jurisdiction === "Office of Hearing Operations"
								?
								<>
									<Button variant="link" onClick={() => { setHearingOfficeModalState(true) }}>Search Hearing Offices</Button>
									&nbsp;&nbsp;
									<Button
										variant="link"
										className=""
										onClick={() => { saveOnChange({ target: { name: 'office_with_jurisdiction', value: formData.office_with_jurisdiction } }, true, null) }}>
										Refresh Lookup
									</Button>
								</>
								:
								<div className="my-2">
									<a href={'https://www.ssa.gov/locator/'} target="_blank" rel="noreferrer">Find your local office</a>
									&nbsp;&nbsp;
									<Button
										variant="link"
										className=""
										onClick={() => { saveOnChange({ target: { name: 'office_with_jurisdiction', value: formData.office_with_jurisdiction } }, true, null) }}>
										Refresh Lookup
									</Button>
								</div>
						}
						<Row>
							<Col sm={12} className={'px-0'}>
								<Form.Group controlId="office_with_jurisdiction">
									<FloatingLabel
										controlId="office_with_jurisdiction"
										label="Office with Jurisdiction"
										className=""
										size="sm"
									>
										<Form.Select
											size="sm"
											as="select"
											name="office_with_jurisdiction"
											value={formData.office_with_jurisdiction}
											onChange={saveOnChange}
										>
											<option value="Social Security Field Office">Social Security Field Office</option>
											<option value="Office of Hearing Operations">Office of Hearing Operations</option>
											<option value="Appeals Council">Appeals Council</option>
											<option value="Federal Court">Federal Court</option>
										</Form.Select>
									</FloatingLabel>
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Col sm={12} className={'px-0'}>
								<Form.Group controlId="office_with_jurisdiction_mailing_address">
									<FloatingLabel
										controlId="office_with_jurisdiction_mailing_address"
										label="Mailing Address"
										className=""
										size="sm"
									>
										<Form.Control
											size="sm"
											name="office_with_jurisdiction_mailing_address"
											value={formData.office_with_jurisdiction_mailing_address}
											onChange={handleInputChange}
											className={""}
											onBlur={saveOnChange}
										/>
									</FloatingLabel>
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Col sm={6} className={'px-0'}>
								<Form.Group controlId="office_with_jurisdiction_mailing_city">
									<FloatingLabel
										controlId="office_with_jurisdiction_mailing_city"
										label="City"
										className=""
										size="sm"
									>
										<Form.Control
											size="sm"
											name="office_with_jurisdiction_mailing_city"
											value={formData.office_with_jurisdiction_mailing_city}
											onChange={handleInputChange}
											className={""}
											onBlur={saveOnChange}
										/>
									</FloatingLabel>
								</Form.Group>
							</Col>
							<Col sm={3} className={'px-0'}>
								<Form.Group controlId="office_with_jurisdiction_mailing_state">
									<FloatingLabel
										controlId="office_with_jurisdiction_mailing_state"
										label="State"
										className=""
										size="sm"
									>
										<Form.Control
											size="sm"
											name="office_with_jurisdiction_mailing_state"
											value={formData.office_with_jurisdiction_mailing_state}
											onChange={handleInputChange}
											className={""}
											onBlur={saveOnChange}
										/>
									</FloatingLabel>
								</Form.Group>
							</Col>
							<Col sm={3} className={'px-0'}>
								<Form.Group controlId="office_with_jurisdiction_mailing_zip">
									<FloatingLabel
										controlId="office_with_jurisdiction_mailing_zip"
										label="Zip"
										className=""
										size="sm"
									>
										<Form.Control
											size="sm"
											name="office_with_jurisdiction_mailing_zip"
											value={formData.office_with_jurisdiction_mailing_zip}
											onChange={handleInputChange}
											className={""}
											onBlur={saveOnChange}
										/>
									</FloatingLabel>
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Col sm={6} className={'px-0'}>
								<Form.Group controlId="office_with_jurisdiction_phone">
									<FloatingLabel
										controlId="office_with_jurisdiction_phone"
										label="Phone"
										className=""
										size="sm"
									>
										<Form.Control
											size="sm"
											name="office_with_jurisdiction_phone"
											value={formData.office_with_jurisdiction_phone}
											onChange={handleInputChange}
											className={""}
											onBlur={saveOnChange}
										/>
									</FloatingLabel>
								</Form.Group>
							</Col>
							<Col sm={6} className={'px-0'}>
								<Form.Group controlId="office_with_jurisdiction_fax">
									<FloatingLabel
										controlId="office_with_jurisdiction_fax"
										label="Fax"
										className=""
										size="sm"
									>
										<Form.Control
											size="sm"
											name="office_with_jurisdiction_fax"
											value={formData.office_with_jurisdiction_fax}
											onChange={handleInputChange}
											className={""}
											onBlur={saveOnChange}
										/>
									</FloatingLabel>
								</Form.Group>
							</Col>
						</Row>
					</div>

					<div>
						{
							(formData?.office_with_jurisdiction && formData.office_with_jurisdiction !== 'Social Security Field Office') &&
							<div className="my-3 py-3">
								<div className="pb-2">
									<h5 className="d-inline">Local Field Office</h5>
								</div>

								<a href={'https://www.ssa.gov/locator/'} target="_blank" rel="noreferrer">Find your local office</a>
								&nbsp;&nbsp;
								<Button
									variant="link"
									className=""
									onClick={() => { refreshFieldOffice() }}>
									Refresh lookup
								</Button>
								<Row>
									<Col sm={12} className={'px-0'}>
										<Form.Group controlId="field_office_mailing_address">
											<FloatingLabel
												controlId="field_office_mailing_address"
												label="Mailing Address"
												className=""
												size="sm"
											>
												<Form.Control
													size="sm"
													name="field_office_mailing_address"
													value={formData.field_office_mailing_address}
													onChange={handleInputChange}
													className={""}
													onBlur={saveOnChange}
												/>
											</FloatingLabel>
										</Form.Group>
									</Col>
								</Row>
								<Row>
									<Col sm={6} className={'px-0'}>
										<Form.Group controlId="field_office_mailing_city">
											<FloatingLabel
												controlId="field_office_mailing_city"
												label="City"
												className=""
												size="sm"
											>
												<Form.Control
													size="sm"
													name="field_office_mailing_city"
													value={formData.field_office_mailing_city}
													onChange={handleInputChange}
													className={""}
													onBlur={saveOnChange}
												/>
											</FloatingLabel>
										</Form.Group>
									</Col>
									<Col sm={3} className={'px-0'}>
										<Form.Group controlId="field_office_mailing_state">
											<FloatingLabel
												controlId="field_office_mailing_state"
												label="State"
												className=""
												size="sm"
											>
												<Form.Control
													size="sm"
													name="field_office_mailing_state"
													value={formData.field_office_mailing_state}
													onChange={handleInputChange}
													className={""}
													onBlur={saveOnChange}
												/>
											</FloatingLabel>
										</Form.Group>
									</Col>
									<Col sm={3} className={'px-0'}>
										<Form.Group controlId="field_office_mailing_zip">
											<FloatingLabel
												controlId="field_office_mailing_zip"
												label="Zip"
												className=""
												size="sm"
											>
												<Form.Control
													size="sm"
													name="field_office_mailing_zip"
													value={formData.field_office_mailing_zip}
													onChange={handleInputChange}
													className={""}
													onBlur={saveOnChange}
												/>
											</FloatingLabel>
										</Form.Group>
									</Col>
								</Row>
								<Row>
									<Col sm={6} className={'px-0'}>
										<Form.Group controlId="field_office_phone">
											<FloatingLabel
												controlId="field_office_phone"
												label="Phone"
												className=""
												size="sm"
											>
												<Form.Control
													size="sm"
													name="field_office_phone"
													value={formData.field_office_phone}
													onChange={handleInputChange}
													className={""}
													onBlur={saveOnChange}
												/>
											</FloatingLabel>
										</Form.Group>
									</Col>
									<Col sm={6} className={'px-0'}>
										<Form.Group controlId="field_office_fax">
											<FloatingLabel
												controlId="field_office_fax"
												label="Fax"
												className=""
												size="sm"
											>
												<Form.Control
													size="sm"
													name="field_office_fax"
													value={formData.field_office_fax}
													onChange={handleInputChange}
													className={""}
													onBlur={saveOnChange}
												/>
											</FloatingLabel>
										</Form.Group>
									</Col>
								</Row>
							</div>
						}
					</div>

					<div className="my-3 py-3">
						<h5>ERE Claim Information</h5>
						<Row>
							<Col sm={12} className={'px-0'}>
								<Form.Group controlId="ere_claim_type">
									<FloatingLabel
										controlId="ere_claim_type"
										label="ERE Claim Type"
										className=""
										size="sm"
									>
										<Form.Select
											size="sm"
											as="select"
											name="ere_claim_type"
											value={formData.ere_claim_type}
											onChange={saveOnChange}
										>
											<option value="">-- Select One --</option>
											<option value="Title 2 – Disability">Title 2 – Disability</option>
											<option value="Title 16 – Disability">Title 16 – Disability</option>
											<option value="Title 2 and Title 16 – Disability">Title 2 and Title 16 – Disability</option>
										</Form.Select>
									</FloatingLabel>
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Col sm={8} className={'px-0'}>
								<Form.Group controlId="ere_status_of_case">
									<FloatingLabel
										controlId="ere_status_of_case"
										label="ERE Status of Case"
										className=""
										size="sm"
									>
										<Form.Control
											size="sm"
											name="ere_status_of_case"
											value={formData.ere_status_of_case}
											onChange={handleInputChange}
											className={""}
											onBlur={saveOnChange}
										/>
									</FloatingLabel>
								</Form.Group>
							</Col>
							<Col sm={4} className={'px-0'}>
								<Form.Group controlId="ere_status_date">
									<FloatingLabel
										controlId="ere_status_date"
										label="ERE Status Date"
										className=""
										size="sm"
									>
										<Form.Control
											size="sm"
											name="ere_status_date"
											value={formData.ere_status_date}
											onChange={handleInputChange}
											className={""}
											onBlur={saveOnChange}
										/>
									</FloatingLabel>
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Col sm={4} className={'px-0'}>
								<Form.Group controlId="ere_electronic_folder">
									<FloatingLabel
										controlId="ere_electronic_folder"
										label="ERE Electronic Folder"
										className=""
										size="sm"
									>
										<Form.Control
											size="sm"
											name="ere_electronic_folder"
											value={formData.ere_electronic_folder}
											onChange={handleInputChange}
											className={""}
											onBlur={saveOnChange}
										/>
									</FloatingLabel>
								</Form.Group>
							</Col>
							<Col sm={4} className={'px-0'}>
								<Form.Group controlId="ere_fee_agreement">
									<FloatingLabel
										controlId="ere_fee_agreement"
										label="ERE Fee Agreement"
										className=""
										size="sm"
									>
										<Form.Control
											size="sm"
											name="ere_fee_agreement"
											value={formData.ere_fee_agreement}
											onChange={handleInputChange}
											className={""}
											onBlur={saveOnChange}
										/>
									</FloatingLabel>
								</Form.Group>
							</Col>
							<Col sm={4} className={'px-0'}>
								<Form.Group controlId="ere_fee_petition">
									<FloatingLabel
										controlId="ere_fee_petition"
										label="ERE Fee Petition"
										className=""
										size="sm"
									>
										<Form.Control
											size="sm"
											name="ere_fee_petition"
											value={formData.ere_fee_petition}
											onChange={handleInputChange}
											className={""}
											onBlur={saveOnChange}
										/>
									</FloatingLabel>
								</Form.Group>
							</Col>
						</Row>
						<Row>
							{
								['Title 2 – Disability', 'Title 2 and Title 16 – Disability'].includes(formData.ere_claim_type) &&
								<Col className={'px-0'}>
									<Form.Group controlId="ere_t2_decision">
										<FloatingLabel
											controlId="ere_t2_decision"
											label="ERE T2 Decision"
											className=""
											size="sm"
										>
											<Form.Control
												size="sm"
												name="ere_t2_decision"
												value={formData.ere_t2_decision}
												onChange={handleInputChange}
												className={""}
												onBlur={saveOnChange}
											/>
										</FloatingLabel>
									</Form.Group>
								</Col>
							}

							{
								['Title 16 – Disability', 'Title 2 and Title 16 – Disability'].includes(formData.ere_claim_type) &&
								<Col className={'px-0'}>
									<Form.Group controlId="ere_t16_decision">
										<FloatingLabel
											controlId="ere_t16_decision"
											label="ERE T16 Decision"
											className=""
											size="sm"
										>
											<Form.Control
												size="sm"
												name="ere_t16_decision"
												value={formData.ere_t16_decision}
												onChange={handleInputChange}
												className={""}
												onBlur={saveOnChange}
											/>
										</FloatingLabel>
									</Form.Group>
								</Col>
							}
						</Row>
					</div>

					{
						!selectedCase.confirmed_ssa_application &&
						<div className="my-3 py-3 border-top border-bottom">
							<h5 className="fw-bold">Social Security Disability Application</h5>
							<div>
								<p>To assist in the filing of an Title an SSDI application, <br />visit <a href="http://secure.ssa.gov/iClaim/dib" target="_blank">http://secure.ssa.gov/iClaim/dib</a>.</p>
								<p>To make an appointment to file an SSI application,  <br /> visit <a href="http://www.ssa.gov/benefits/ssa/start.htm" target="_blank">http://www.ssa.gov/benefits/ssa/start.htm</a>.</p>
							</div>
							{
								selectedCase.confirmed_ssa_application
									?
									<p><span className="text-success fw-bold">SSA Application Confirmed!!</span> Not confirmed? <span className="text-primary cursor-pointer" onClick={revertConfirmation}>Revert back here.</span></p>
									:
									<Button variant="primary" onClick={() => { handleConfirmSSAApplicationButtonClick() }}>Confirm SSA Application</Button>
							}
						</div>
					}

					{
						!selectedCase.confirmed_appointment_as_representative
							?
							<div className="my-3 py-3 border-bottom">
								<h5 className="fw-bold">Submit Representative Documents</h5>
								<p>Submit and confirm representative documents.</p>
								<Button variant={'primary'} onClick={() => setSubmitRepresentativeDocumentsModal(true)}>Submit Representative Documents</Button>
								<br />
								<br />
								<Button variant={'info'} onClick={() => handleConfirmAppointmentAsRepresentativeClick()}>Confirm Appointment as Representative</Button>
							</div>
							:
							<div className="my-3 py-3 border-bottom">
								<h5 className="fw-bold">Submit Representative Documents</h5>
								<p>Submit and confirm representative documents.</p>
								<p><span className="text-success fw-bold">Appointment as Representative Confirmed!</span>
									<br />
									Not confirmed? <span className="text-danger cursor-pointer" onClick={revertConfirmedAppointmentAsRepresentative}>Revert back here.</span></p>

							</div>

					}


					{
						(selectedCase.confirmed_ssa_application && selectedCase.confirmed_appointment_as_representative) &&
						<>
							<div className="my-3 py-3 pb-4 border-bottom">
								<h5 className="fw-bold">Record Administration Decision</h5>
								<p>Record a hearing decision by uploading documents and selecting an appropriate disposition of the decision.</p>
								<Button variant="primary" onClick={() => setRecordAdministrationDecisionModal(true)}>Record Administration Decision</Button>
							</div>

							<div className="my-3 py-3 pb-4 border-bottom">
								<h5 className="fw-bold">File an Appeal</h5>
								<p>File an paper appeal or set a task reminder to check the status of an online appeal.</p>
								<Button variant="primary" onClick={() => setFileAnAppealModalState(true)}>File an Appeal</Button>
							</div>


							{
								selectedCase.case_status_id !== 5 &&
								<div className="my-3 py-3 pb-4">
									<h5 className="fw-bold">Close the Case</h5>
									<p>This action will close the active case.</p>
									<Button variant="falcon-danger" onClick={() => { setComponentState({ ...componentState, closeCaseModalState: true }) }}>Close the Case</Button>
								</div>
							}

						</>
					}
				</Form>

			</div >
			<HearingOfficeMapModal
				modalState={hearingOfficeModalState}
				setModalState={setHearingOfficeModalState}
				claimant={claimant}
				updateCaseInformation={updateCaseInformation}
			/>

			<SubmitRepresentativeDocuments
				modalState={submitRepresentativeDocumentsModal}
				setModalState={setSubmitRepresentativeDocumentsModal}
				claimant={claimant}
				setClaimant={setClaimant}
				selectedCase={selectedCase}
				setSelectedCase={setSelectedCase}
				refreshParent={refreshParent}
			/>

			<RequestCaseStatusUpdate
				modalState={requestCaseStatusUpdateModal}
				setModalState={setRequestCaseStatusUpdateModal}
				claimant={claimant}
				setClaimant={setClaimant}
				selectedCase={selectedCase}
				setSelectedCase={setSelectedCase}
			/>

			<RecordAdministrationDecision
				modalState={recordAdministrationDecisionModal}
				setModalState={setRecordAdministrationDecisionModal}
				claimant={claimant}
				setClaimant={setClaimant}
				selectedCase={selectedCase}
				setSelectedCase={setSelectedCase}
			/>

			<FileAnAppeal
				modalState={fileAnAppealModalState}
				setModalState={setFileAnAppealModalState}
				claimant={claimant}
				setClaimant={setClaimant}
				selectedCase={selectedCase}
				setSelectedCase={setSelectedCase}
			/>

			<CustomFieldList
				account_id={claimant.account_id}
				model={"CASE"}
				entity_id={selectedCase.entity_id}
				view={'CASE_DETAILS'}
				options={{}}
			/>

		</div >
	);
};

export default CaseInformation;