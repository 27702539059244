import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import genericAvatar from 'assets/img/team/avatar.png';
import Avatar from 'components/common/Avatar';
import auth from '../../../utils/auth';
import { AuthContext } from 'context/Context';
import Auth from 'utils/auth';

const ProfileDropdown = () => {

  let userDetails = Auth?.getProfile()?.data || {};
  console.log("// @todo - Debug this->userDetails: ", userDetails);

  const {
    permissionsDispatch
  } = useContext(AuthContext);

  let userProfileImage = auth.getProfile()?.data?.profile_picture ? auth.getProfile().data.profile_picture : genericAvatar;
  const userLogout = async () => {
    try {
      await auth.logout(permissionsDispatch);
    } catch (err) {
      alert('Something went wrong.');
    }
  };

  return (
    <Dropdown navbar={true} as="li">
      <Dropdown.Toggle
        bsPrefix="toggle"
        as={Link}
        to="#!"
        className="pe-0 ps-2 nav-link"
      >
        {/* // @todo - need to capture failure to load images and set to default or display empty or no display */}
        <Avatar src={userProfileImage} />
      </Dropdown.Toggle>

      <Dropdown.Menu className="dropdown-caret dropdown-menu-card  dropdown-menu-end">
        <div className="bg-white rounded-2 py-2 dark__bg-1000">
          <Dropdown.Item title={`${userDetails.first_name} ${userDetails.last_name}`} as={Link} to="/user/profile">
            My Profile
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item onClick={() => { userLogout() }}>
            Logout
          </Dropdown.Item>
        </div>
      </Dropdown.Menu>
    </Dropdown >
  );
};

export default ProfileDropdown;
