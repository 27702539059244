import React from 'react';
import { Row, Col, Card } from 'react-bootstrap';

import Calendar from 'components/pages/reservation/calendar/Calendar';

const Reservation = () => {

  return (
    <>
      <Row>
        <Col>
          <Calendar />
        </Col>
      </Row>
    </>
  );
};

export default Reservation;