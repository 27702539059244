import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Modal, Row, Col, Alert, Table } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import AppContext from 'context/Context';
import { assureAPI, claimantAPI, documentAPI } from 'utils/api/fulcrum-api';
import { toast } from 'react-toastify';
import { removeEmptyFromObj } from 'utils/functions';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import ClaimantQuickFind from 'components/claimants/find-a-claimant/ClaimantQuickFind';
import DocumentSearchTool from 'components/documents/document-search-tool/DocumentSearchTool';
import ViewLocations from '../locations/ViewLocations';
import ReactSelect from 'react-select';

const BriefRequest = ({
	modalState,
	setModalState = () => { },
	briefRequestData,
	setBriefRequestData,
	claimantId = null,
	claimant = {},
	refreshParent = (() => { })
}) => {
	const {
		config: { isDark }
	} = useContext(AppContext);
	const [claimantInfo, setClaimantInfo] = useState(claimant);
	const navigate = useNavigate();
	const defaultDueDate = moment().add(7, 'days').toDate();
	const [scheduledDueAtDate, setScheduledDueAtDate] = useState(defaultDueDate);
	const [scheduledHearingDate, setScheduledHearingDate] = useState(null);
	const [documentSearchModalState, setDocumentSearchModalState] = useState(false);
	const [locationModalState, setLocationModalState] = useState(false);
	const defaultForm = {
		brief_type: '',
		brief_create_type: 1,
		due_at: defaultDueDate,
		special_instructions: '',
		hearing_timestamp: null,
		location_id: '',
		selected_location: {},
		documents: []
	};
	const [documents, setDocuments] = useState([]);
	const [value, setValue] = useState(null);
	const [formData, setFormData] = useState(defaultForm);

	// Get claimant documents
	const getClaimantDocuments = async (id) => {
		try {
			let result = await documentAPI.getClaimantDocuments(id);
			if (!result.data) {
				return;
			}
			if (result.data.length > 0) {
				setDocuments(result.data);
				// setDocuments(result.data.map(doc => {
				// 	return {
				// 		value: doc.id,
				// 		label: doc.document_type.type + ' - ' + moment(doc.created_at).format('MM/DD/YYYY'),
				// 		document_type_id: doc.document_type_id,
				// 	}
				// }));
			} else {
				setDocuments([]);
			}
		} catch (error) {
			toast.error('Unable to find any client documents.', { theme: 'colored' });
			console.error(error);
		}
	};


	// Get claimant info
	// const getClaimantInformation = async (id) => {
	// 	try {
	// 		let result = await claimantAPI.getClaimant(id);
	// 		if (!result.data) {
	// 			throw new Error('Unable to find claimant.');
	// 		}
	// 		setClaimantInfo(result.data);
	// getClaimantDocuments(result.data.id);
	// 	} catch (error) {
	// 		toast.error('Unable to find any client documents.', { theme: 'colored' });
	// 		handleClose();
	// 		console.error(error);
	// 	}
	// };


	// Handle Close
	const handleClose = () => {
		setFormData(defaultForm);
		setClaimantInfo({});
		setValue(null);
		setDocuments([]);
		setModalState(!modalState);
		setScheduledDueAtDate(defaultDueDate);
	};

	// Handle Change
	const handleChange = ({ target }) => {
		let { name, value } = target;
		let additionalValues = {};

		if (name === 'brief_type') {
			value = value ? parseInt(value) : '';
		}

		setFormData({ ...formData, ...additionalValues, [name]: value });
	};


	// Submit
	const handleSubmit = async e => {
		e.preventDefault();

		// Validate form
		if (!formData.brief_type) {
			toast.error('Please select a brief type', { theme: 'colored' });
			return;
		}

		if (!formData.due_at) {
			toast.error('Please select a due date', { theme: 'colored' });
			return;
		}

		if (!claimantInfo?.id) {
			toast.error('Please select a claimant', { theme: 'colored' });
			return;
		}

		if (formData.brief_type === 1) {
			if (!formData.hearing_timestamp) {
				toast.error('Please select a hearing date', { theme: 'colored' });
				return;
			}
			if (!formData.location_id) {
				toast.error('Please select a hearing location', { theme: 'colored' });
				return;
			}
		}

		// Check that at least one document of case-file type is selected
		if (formData.documents.length === 0) {
			toast.error('Please select at least one document', { theme: 'colored' });
			return;
		}

		let containsCaseFile = false;
		for (let i = 0; i < formData.documents.length; i++) {
			// console.log(formData.documents[i]);
			// if (formData.documents[i].document_type_id.includes("CASE_FILE")) {
			// 	containsCaseFile = true;
			// }
			formData.documents[i].file_type = parseInt(formData.documents[i].file_type);

			if (formData.documents[i].file_type === 15 || claimantInfo?.claimant_active_case?.assure_case_id) {
				containsCaseFile = true;
			}
		}
		if (!containsCaseFile) {
			toast.error('Please select at least one document of case-file type', { theme: 'colored' });
			return;
		}


		try {
			let payload = {
				...formData
			}
			let result = await assureAPI.submitNewBriefRequest(claimantInfo.id, payload);
			if (!result?.data) {
				toast.error('Error submitting brief request', { theme: 'colored' });
				return;
			}

			handleClose();
			toast.success('Brief request submitted', { theme: 'colored' });
		} catch (error) {
			alert(error);
			toast.error(error, {
				theme: 'colored'
			});
		}

		refreshParent();
	};

	// Handle Claimant Selection
	const handleClaimantSelection = (claimant) => {
		setClaimantInfo(claimant);
		if (claimant?.id) {
			// getClaimantInformation(claimant.id);
			getClaimantDocuments(claimant.id);
		}
	};

	// Handle Location Selection
	const handleLocationSelection = (location) => {
		setFormData({ ...formData, location_id: location.id, selected_location: location });
	};

	// handle show
	const handleShow = () => {
		// if (claimantId) {
		// 	getClaimantDocuments(claimantId);
		// }
	};

	return (
		<>
			<Modal
				show={modalState}
				onHide={handleClose}
				onShow={handleShow}
				contentClassName="border"
				backdrop="static"
				size='lg'
			>
				<Form onSubmit={handleSubmit}>
					<Modal.Header
						closeButton
						closeVariant={isDark ? 'white' : undefined}
						className="bg-light px-card border-bottom-0"
					>
						<Modal.Title as="h5">Brief Request</Modal.Title>
					</Modal.Header>
					<Modal.Body className="p-card">
						<Alert variant={'primary'}>
							To create a new brief request, please fill out the form below.
						</Alert>


						{
							!claimantInfo?.id
								?
								<div>
									<p>Find a claimant to submit the case file request for.</p>
									<ClaimantQuickFind
										handleClaimantSelection={handleClaimantSelection}
									/>
								</div>
								:
								<div>
									<p>
										Submit a brief request for <span className="fw-bold">{claimantInfo?.first_name} {claimantInfo?.last_name} ({claimantInfo?.id})</span>
									</p>

									<div>
										<Form.Group as={Col} className="mb-3" controlId="brief_type">
											<Form.Label className="fs-0">Type of Brief:</Form.Label>
											<Form.Select name="brief_type" defaultValue={''} value={formData.brief_type || null} onChange={handleChange} required>
												<option value={""}>--Select One--</option>
												<option value={1}>Pre-Hearing</option>
												<option value={2}>On-The-Record</option>
												<option value={3}>Post-Hearing</option>
												<option value={4}>Appellate</option>
												<option value={5}>Other</option>
											</Form.Select>
										</Form.Group>

										<Form.Group className="mb-3" controlId="due_at">
											<Form.Label className="fs-0">Due Date:</Form.Label>
											{' '}<span>Due dates can not fall on Saturday, Sunday or any goverment holidays.</span>
											<DatePicker
												selected={scheduledDueAtDate}
												onChange={date => {
													setScheduledDueAtDate(date);
													setFormData({ ...formData, due_at: date });
												}}
												className="form-control"
												placeholderText="MM-DD-YYYY"
												dateFormat="MM-dd-yyyy"
												minDate={moment().toDate()}
												required
											/>
										</Form.Group>

										{
											formData.brief_type === 1 &&
											<div>
												<Row>
													<Col>
														<Form.Group className="mb-3" controlId="hearing_timestamp">
															<Form.Label className="fs-0">Hearing Date and Time:</Form.Label>
															<DatePicker
																selected={scheduledHearingDate}
																onChange={date => {
																	setScheduledHearingDate(date);
																	setFormData({ ...formData, hearing_timestamp: date });
																}}
																className="form-control"
																placeholderText="MM-DD-YYYY hh:mm"
																dateFormat="MM/dd/yyyy hh:mm aa"
																showTimeSelect
																minDate={moment().toDate()}
																required
															/>
														</Form.Group>
													</Col>
												</Row>
												<Row>
													<Col>
														<p>Selected Location: {formData?.location_id ?
															<>
																<Button variant="link" onClick={() => setLocationModalState(true)}>Change Location</Button>
																<br />
																<span className="fw-bold">{formData?.selected_location?.address_street_1} {formData?.selected_location?.address_street_2}</span>
																<br />
																<span className="fw-bold">{formData?.selected_location?.address_city}, {formData?.selected_location?.address_state} {formData?.selected_location?.address_zip}</span>
															</>
															:
															<>
																<Button variant="link" onClick={() => setLocationModalState(true)}>Select Location</Button>
															</>
														}</p>
													</Col>
													<ViewLocations
														modalState={locationModalState}
														setModalState={setLocationModalState}
														locationSelectCallBack={handleLocationSelection}
													/>
												</Row>
											</div>
										}

										{
											claimantInfo?.claimant_active_case?.assure_case_id &&
											<span>Assure Case ID: {claimantInfo.claimant_active_case.assure_case_id}</span>
										}
										<br />
										<span>Attach documents to brief request. You must attach at least one "Case File" document.</span>
										<br />
										<span>Only .pdf, .doc, .docx, .png, .tiff, .jpg, .txt, .zip, .xls, and .xlsx files are accepted.</span>
										<br />
										<Button variant="link" onClick={() => {
											setFormData({
												...formData, documents: [...formData.documents, {
													id: moment().unix(),
													document_id: '',
													file_type: '',
												}]
											});
										}}>+ Add Documents</Button>

										{
											formData.documents.length > 0
												?
												<div>
													<p>Attached Documents:</p>
													{
														<Table striped bordered hover>
															<thead>
																<tr>
																	<th>Document</th>
																	<th>File Type</th>
																	<th></th>
																</tr>
															</thead>
															<tbody>
																{formData.documents.map((document, index) => (
																	<tr key={document.id}>
																		<td>
																			<Form.Select name="document_id" defaultValue={''} value={document.document_id || null} onChange={
																				(e) => {
																					let newDocuments = formData.documents.map(doc => {
																						if (doc.id === document.id) {
																							doc.document_id = e.target.value;
																						}

																						return doc;
																					})
																					setFormData({
																						...formData,
																						documents: newDocuments
																					});
																				}
																			} required>
																				<option value={""}>--Select One--</option>
																				{
																					documents.map((document, docIndex) => (
																						<option key={docIndex} value={document.id}>{documents[docIndex].title} {moment(document.created_at).format('MM-DD-YYYY hh:mm a')}</option>
																					))
																				}
																			</Form.Select>
																		</td>
																		<td>
																			<Form.Select name="file_type" defaultValue={''} value={document.file_type || null} onChange={
																				(e) => {
																					let newDocuments = formData.documents.map(doc => {
																						if (doc.id === document.id) {
																							doc.file_type = e.target.value;
																						}

																						return doc;
																					});
																					setFormData({
																						...formData,
																						documents: newDocuments
																					});
																				}
																			} required>
																				<option value={""}>--Select One--</option>
																				<option value={14}>Hearing Brief</option>
																				<option value={15}>Case File</option>
																				<option value={16}>Law Firm Notes</option>
																				<option value={27}>Case Notes (Additional Information)</option>
																				<option value={28}>AA Client Contact Information</option>
																				<option value={33}>Notice of Hearing</option>
																			</Form.Select>
																		</td>
																		<td>
																			<Button variant="link" className="text-danger" onClick={() => {
																				setFormData({
																					...formData,
																					documents: formData.documents.filter((doc, docIndex) => doc.id !== document.id)
																				});
																			}}>Remove</Button>
																		</td>
																	</tr>
																))}
															</tbody>
														</Table>
													}
												</div>
												:
												<div>
													<p>No documents attached.</p>
												</div>
										}

										<Form.Group className="mb-3" controlId="special_instructions">
											<Form.Label className="fs-0">Special Instructions: </Form.Label>
											<Form.Control
												as="textarea"
												rows={3}
												value={formData.special_instructions}
												name="special_instructions"
												onChange={handleChange}
											/>
										</Form.Group>
									</div>

									{/* {
										!claimantInfo?.active_case_id
											?
											<Alert variant={'danger'}>
												Claimant does not have an active case. The claimant must have an active case in order to submit a brief request.
											</Alert>
											:
											
									} */}
								</div>
						}


					</Modal.Body>
					<Modal.Footer className="bg-light px-card border-top-0">
						<Button variant="secondary" onClick={handleClose}>
							Close
						</Button>
						{
							(claimantInfo?.id &&
								(
									formData.brief_type &&
									formData.due_at
								)) &&
							<Button variant="primary" onClick={handleSubmit}>
								Submit Brief Request
							</Button>
						}
					</Modal.Footer>
				</Form>
			</Modal>


		</>
	);
};

export default BriefRequest;