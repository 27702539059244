import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import IconButton from 'components/common/IconButton';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';

const ClaimantChildren = ({ claimant, saveClaimant }) => {
	const [children, setCildren] = useState((claimant?.children) ? JSON.parse(claimant.children) : []);

	// Prepare the children array to save
	const prepareChildrenArray = (childArray) => {
		let adjustedChildrenArray = JSON.stringify(childArray);

		if (claimant.children !== adjustedChildrenArray) {
			saveClaimant({ children: adjustedChildrenArray });
		}
	};

	// Update a child
	const updateChild = (index, field, value) => {
		let newChildren = [...children];
		newChildren[index][field] = value;
		setCildren(newChildren);
	};

	// Add a new child
	const addChild = () => {
		let newChild = {
			name: '',
			age: ''
		};
		let newChildren = [...children, newChild];
		setCildren(newChildren);
		prepareChildrenArray(newChildren);
	};

	// Remove a child
	const removeChild = (index) => {
		Swal.fire({
			title: 'Permanently delete child?',
			text: "You won't be able to revert this!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Yes, delete it!'
		}).then(async (result) => {
			if (result.isConfirmed) {
				let newChildren = [...children];
				newChildren.splice(index, 1);
				setCildren(newChildren);
				prepareChildrenArray(newChildren);
				toast.warning(result.message, {
					theme: 'colored'
				});
			}
		});
	};

	return (
		<div id="claimant-children-container" className="my-3">
			<h5 className="d-inline">Children</h5>

			<IconButton
				className="me-2 mb-1 float-end"
				variant="falcon-default"
				size="sm"
				icon="plus"
				transform="shrink-3"
				onClick={addChild}
			>
				Add
			</IconButton>
			<hr />
			<Table responsive bordered striped >
				<thead>
					<th scope="col">Name</th>
					<th scope="col">Age</th>
					<th scope="col"></th>
				</thead>
				<tbody>
					{children.map((child, index) => (
						<tr key={index}>
							<td>
								<Form.Control
									type="text"
									size="sm"
									value={child.name}
									onChange={e => { updateChild(index, 'name', e.target.value) }}
									onBlur={() => prepareChildrenArray(children)}
								/>
							</td>
							<td>
								<Form.Control
									type="text"
									size="sm"
									value={child.age}
									onChange={e => { updateChild(index, 'age', e.target.value) }}
									onBlur={() => prepareChildrenArray(children)}
								/>
							</td>
							<td>
								<IconButton
									className="me-2 mb-1 float-end"
									variant="falcon-danger"
									size="sm"
									icon="trash"
									transform="shrink-3"
									onClick={() => removeChild(index)}
								>
									Delete
								</IconButton>
							</td>
						</tr>
					))}
				</tbody>
			</Table>
		</div>
	);
};

ClaimantChildren.propTypes = {
	claimant: PropTypes.object,
	saveClaimant: PropTypes.func
}

export default ClaimantChildren;