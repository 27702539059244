import React, { useContext, useState, useRef } from 'react';
import { Button, Form, Modal, Table, Alert, Spinner } from 'react-bootstrap';
// import { v4 as uuid } from 'uuid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import AppContext from 'context/Context';
import { inboxOutboxAPI } from 'utils/api/fulcrum-api';
import ClaimantQuickFind from 'components/claimants/find-a-claimant/ClaimantQuickFind';
import { useDropzone } from 'react-dropzone';
import Flex from 'components/common/Flex';
import cloudUpload from 'assets/img/icons/cloud-upload.svg';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import moment from 'moment';

const UnknownMessageTool = ({
	selectedCorrespondence,
	setSelectedCorrespondence = () => { },
	modalState,
	setModalState,
	refreshParent = () => { },
	clearSelectedCorrespondence = true
}) => {
	const {
		config: { isDark }
	} = useContext(AppContext);
	const defaultFormData = {
		associate_sender_to_claimant: false,
		associate_as_email: false,
		associate_as_phone: false,
		associate_as_fax: false,
	};


	const [formData, setFormData] = useState(defaultFormData);
	const [claimant, setClaimant] = useState(null);
	const [submitting, setSubmitting] = useState(false);

	const [errors, setErrors] = useState([]);


	const handleChange = ({ target }) => {
		let { name, value } = target;
		let additionalValues = {};

		setFormData({ ...formData, ...additionalValues, [name]: value });
	};


	const handleSubmit = async e => {
		e.preventDefault();
		setErrors([]);
		let newErrors = [];
		if (!claimant?.id) {
			toast.error('Please select a claimant.');
			newErrors.push('Please select a claimant.');
		}

		if (!selectedCorrespondence?.id) {
			toast.error('Please select a correspondence.');
			handleClose();
			newErrors.push('Please select a correspondence.');
		}

		setSubmitting(true);
		setErrors(newErrors);
		if (!newErrors.length) {
			try {
				let result = await inboxOutboxAPI.associateClaimantToCorrespondence(selectedCorrespondence?.account_id || null, selectedCorrespondence.id, claimant.id, {
					associate_as_email: formData.associate_as_email,
					associate_as_phone: formData.associate_as_phone,
				});
				if (!result?.data) {
					throw new Error("Unable to associate the claimant to the correspondence correspondence.");
				}
				toast.success("Successfully associated the claimant to the correspondence.", { theme: 'colored' });
				refreshParent();
				handleClose();
			} catch (error) {
				toast.error('Unable to associate the claimant to the correspondence.', { theme: 'colored' });
				return false;
			}
		}

		setSubmitting(false);
	};

	// Handle Claimant Selection// Handle Claimant Selection
	const handleClaimantSelection = (selectedclaimant = []) => {
		setClaimant(selectedclaimant[0]?.id ? {
			id: selectedclaimant[0].id,
			acitve_case_id: selectedclaimant[0].active_case_id,
			first_name: selectedclaimant[0].first_name,
			last_name: selectedclaimant[0].last_name,
		} : {});
	};

	// Handle Show
	const handleShow = () => {
		setSubmitting(false);
		setErrors([]);
		setClaimant([]);
		if (!selectedCorrespondence?.id) {
			toast.error('Please select a correspondence.', { theme: 'colored' });
			handleClose();
		}
		setFormData(defaultFormData);
	};

	// Handle Close
	const handleClose = () => {
		setSubmitting(false);
		setErrors([]);
		if (clearSelectedCorrespondence) {
			setSelectedCorrespondence(null);
		}
		setFormData(defaultFormData);
		setClaimant([]);
		setModalState(false);
	};



	return (
		<Modal
			show={modalState}
			onHide={handleClose}
			onShow={handleShow}
			backdrop="static"
			size="lg"
			contentClassName="border"
		>
			<Modal.Header
				closeButton
				closeVariant={isDark ? 'white' : undefined}
				className="bg-light px-card border-bottom-0"
			>
				<Modal.Title as="h5">Link a Claimant</Modal.Title>
			</Modal.Header>
			<Modal.Body className="p-card">
				<Alert variant="info">
					This tool allows you to associate an inbound correspondence with a claimant record.
				</Alert>

				<Alert variant="danger" show={errors.length}>
					{errors.map((error, index) => (
						<div key={index}>{error}</div>
					))}
				</Alert>

				{
					selectedCorrespondence?.id && (
						<>
							<h5>Correspondence Information</h5>
							<span className="">Received:</span><span> {selectedCorrespondence?.createdAt ? moment(selectedCorrespondence.createdAt).format('MM-DD-YYYY hh:mm A') : ''}</span>
							<br />
							<span className="">Sender:</span><span> {selectedCorrespondence?.sender || ''}</span>
							<br />
							<span className="">Mode of Transmission:</span><span> {selectedCorrespondence?.mode_of_transmission || ""}</span>
						</>
					)
				}

				<div className="py-3  border-top mt-3">

					<h5>Associate a Claimant</h5>
					<p>Select a claimant to associate to this correspondence record.</p>

					{
						claimant?.id ?
							(
								<>
									<span className="">Associate this correspondence with </span><span className="fw-bold">{claimant?.first_name} {claimant?.last_name}?</span>
									{
										!submitting && (
											<ClaimantQuickFind
												buttonLabel={'Change Claimant'}
												buttonVariant={'link'}
												handleClaimantSelection={handleClaimantSelection}
												requiredSearchParams={{}}
												defaultSearchParams={{}}
												allowMultipleSelect={false}
											/>
										)
									}
								</>
							) : (
								<>
									<span className="text-danger">No claimant selected </span>
									{
										!submitting && (
											<ClaimantQuickFind
												buttonLabel={'Search Claimants'}
												buttonVariant={'link'}
												handleClaimantSelection={handleClaimantSelection}
												requiredSearchParams={{}}
												defaultSearchParams={{}}
												allowMultipleSelect={false}
											/>
										)
									}
								</>
							)
					}


					{
						selectedCorrespondence?.sender && (
							<>
								{
									selectedCorrespondence?.mode_of_transmission === 'EMAIL' && selectedCorrespondence?.sender && (
										<>
											{/* Associate as email */}
											<Form.Group className="mt-3">
												<Form.Check
													type="checkbox"
													id="associateAsEmail"
													name="associate_as_email"
													label="Assign email to claimant record?"
													checked={formData.associate_as_email}
													disabled={submitting}
													onChange={e => {
														setFormData({
															...formData,
															associate_as_email: e.target.checked
														})
													}}
												/>
											</Form.Group>
										</>
									)
								}

								{
									selectedCorrespondence?.mode_of_transmission === 'SMS' && selectedCorrespondence?.sender && (
										<>
											{/* Associate as phone */}
											<Form.Group className="mt-3">
												<Form.Check
													type="checkbox"
													id="associateAsEmail"
													name="associate_as_phone"
													label="Assign phone to claimant record?"
													checked={formData.associate_as_phone}
													disabled={submitting}
													onChange={e => {
														setFormData({
															...formData,
															associate_as_phone: e.target.checked
														})
													}}
												/>
											</Form.Group>
										</>
									)
								}

								{
									selectedCorrespondence?.mode_of_transmission === 'FAX' && selectedCorrespondence?.sender && (
										<>
											{/* Associate as phone */}
											<Form.Group className="mt-3">
												<Form.Check
													type="checkbox"
													id="associateAsEmail"
													name="associate_as_phone"
													label="Assign fax to claimant record?"
													checked={formData.associate_as_phone}
													disabled={submitting}
													onChange={e => {
														setFormData({
															...formData,
															associate_as_phone: e.target.checked
														})
													}}
												/>
											</Form.Group>
										</>
									)
								}
								<br />
								{
									selectedCorrespondence?.mode_of_transmission === 'UPLOAD' && selectedCorrespondence?.sender && (
										<div className="mt-3">
											<Form.Label className="fw-bold">Associate sender as phone or email. (Optional)</Form.Label>
											<Form.Group className="">
												<Form.Check
													inline
													type="checkbox"
													id="associateAsEmail"
													name="associate_as_email"
													label="Email"
													checked={formData.associate_as_email}
													disabled={submitting}
													onChange={e => {
														setFormData({
															...formData,
															associate_as_email: e.target.checked
														})
													}}
												/>
												<Form.Check
													inline
													type="checkbox"
													id="associateAsPhone"
													name="associate_as_phone"
													label="Phone"
													checked={formData.associate_as_phone}
													disabled={submitting}
													onChange={e => {
														setFormData({
															...formData,
															associate_as_phone: e.target.checked
														})
													}}
												/>
											</Form.Group>
										</div>
									)
								}
							</>
						)
					}


				</div>



			</Modal.Body>
			<Modal.Footer className="bg-light px-card border-top-0">
				{
					selectedCorrespondence?.id && claimant?.id &&
					<div>
						<Button
							variant="success"
							onClick={e => handleSubmit(e)}
							disabled={submitting}
						>
							{submitting ? 'Saving...' : 'Associate Claimant'}
						</Button>
					</div>
				}

				<Button
					variant="outline-secondary"
					onClick={e => {
						handleClose(e);
					}}
				>
					Cancel
				</Button>
			</Modal.Footer>
		</Modal>
	);
};


export default UnknownMessageTool;