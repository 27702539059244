import React, { useState, useEffect } from 'react';
import ListGroup from 'react-bootstrap/ListGroup';
import IconButton from 'components/common/IconButton';
import { toast } from 'react-toastify';
import { claimantMedicationAPI } from 'utils/api/fulcrum-api';
import MedicationRow from './MedicationRow';
import { Table } from 'react-bootstrap';

const MedicationList = ({ claimant, setClaimant }) => {
	const [loading, setLoading] = useState(true);
	const [medications, setMedications] = useState([]);

	// Get the medications
	const getMedications = async () => {
		try {
			let result = await claimantMedicationAPI.getAllClaimantMedications(claimant.id);
			if (!result?.data) {
				throw new Error('No claimant medications data returned');
			}
			setLoading(false);
			setMedications(result.data);
		} catch (error) {
			console.error(error);
		}
	};

	// Add a new medication
	const addMedication = async () => {
		try {
			let result = await claimantMedicationAPI.createClaimantMedication(claimant.id, {});
			if (!result?.data) {
				throw new Error('No data returned from server');
			}
			// setMedications([...medications, result.data]);
			getMedications();
			toast.success('Medication added', { theme: 'colored' });
		} catch (error) {
			console.log(error);
			toast.error('Error adding child', { theme: 'colored' });
		};
	};

	useEffect(() => {
		getMedications();
	}, []);

	if (loading) {
		return <div>Loading...</div>;
	}

	return (
		<div id="claimant-current-employer-container" className="my-3">
			<h5 className="d-inline">Medications</h5>
			<IconButton
				className="me-2 mb-1 float-end"
				variant="falcon-default"
				size="sm"
				icon="plus"
				transform="shrink-3"
				onClick={addMedication}
			>
				Add Medication
			</IconButton>
			<p>Please list your medications.</p>
			<Table>
				<thead>
					<tr>
						<th style={{ width: "25%" }}>Name</th>
						<th>Dose</th>
						<th style={{ width: "25%" }}>Prescribing Doctor</th>
						<th style={{ width: "25%" }}>Medical Condition</th>
						<th>

						</th>
					</tr>
				</thead>
				<tbody>
					{
						(medications.length > 0)
							?
							medications.map((medication, index) => (
								<tr key={index}>
									<MedicationRow
										medication={medication}
										claimant={claimant}
										setClaimant={setClaimant}
										getMedications={getMedications}
									/>
								</tr>
							))
							:
							<tr>
								<td colSpan="3">No medications.</td>
							</tr>
					}

				</tbody>
			</Table>
		</div>
	);
};
export default MedicationList;