import React, { useState } from 'react';
import PropTypes from 'prop-types';
import InboxTable from 'components/app/inbox-outbox/inbox/InboxTable';
import OutboxTable from 'components/app/inbox-outbox/outbox/OutboxTable';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Button from 'react-bootstrap/Button';
import CreateCorrespondenceWizard from 'components/app/inbox-outbox/correspondence-wizard/CreateCorrespondenceWizard';
import CreateCorrespondenceModal from 'components/app/inbox-outbox/correspondence-wizard/CreateCorrespondenceModal';
import PageHeader from 'components/pages/PageHeader';
import auth from 'utils/auth';

const InboxOutbox = ({ account_id = auth.getProfile()?.data?.account_id } = {}) => {
	const [createCorrespondenceModalState, setCreateCorrespondenceModalState] = useState(false);

	return (
		<div>
			<PageHeader title={"Inbox/Outbox"}>
				<div className="page-header-actions float-end">
					<Button className="btn btn-primary float-end" onClick={() => setCreateCorrespondenceModalState(true)}>Create Correspondence</Button>
				</div>
			</PageHeader>
			<Tabs variant='pills' defaultActiveKey="inbox" id="uncontrolled-tab-example" className='mb-3'>
				<Tab eventKey="inbox" title="Inbox" className='border p-3'>
					<InboxTable
						requiredSearchParams={{ account_id, direction: "Inbound" }}
						account_id={account_id}
					/>
				</Tab>
				<Tab eventKey="outbox" title="Outbox" className='border p-3'>
					<OutboxTable
						requiredSearchParams={{ account_id, direction: "Outbound" }}
						account_id={account_id}
					/>
				</Tab>
			</Tabs>
			<CreateCorrespondenceWizard
				modalState={createCorrespondenceModalState}
				setModalState={setCreateCorrespondenceModalState}
			/>
		</div>
	);
};

InboxOutbox.propTypes = {
	account_id: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number
	])
};

export default InboxOutbox;
