import React, { useContext, useState, useEffect } from 'react';
import { Button, Form, Table, Row, Col, Alert, ListGroup, Spinner } from 'react-bootstrap';
import { assureAPI, userAPI } from 'utils/api/fulcrum-api';
import AtlaswareOnboardingModal from './atlasware-onboarding-wizard/AtlaswareOnboardingModal';

const CredentialManager = ({
	account = {},
	refreshParent = () => { },
}) => {
	const [retrievingCredentials, setRetrievingCredentials] = useState(true);
	const [credentialList, setCredentialList] = useState([]);
	const [atlaswareOnboardingWizardModalState, setAtlaswareOnboardingModalState] = useState(false);


	// Get list of account ERE credentials so the user has the oportunity to link their account before proceeding
	const getEreCredentialsList = async () => {
		setRetrievingCredentials(true);
		try {
			let result = await assureAPI.listEreCredentials();
			if (!result?.data?.data) {
				throw new Error('Unable to get a list of ERE credentials.');
			}

			setCredentialList(result.data.data);
		} catch (error) {
			setSlide(slide + 1);
		}
		setRetrievingCredentials(false);
	};


	useEffect(() => {
		getEreCredentialsList();
	}, []);

	return (
		<div>
			<h5 className="d-inline">ERE Credential Manager</h5>
			<Button
				variant="falcon-primary"
				onClick={() => {
					setAtlaswareOnboardingModalState(true);
				}}
			>
				Add Account
			</Button>
			<hr />

			{/* {
				Array.isArray(credentialList) && credentialList.length > 0 ?
					<Table striped bordered hover>
						<thead>
							<tr>
							</tr>
						</thead>
						<tbody>

			} */}

			<AtlaswareOnboardingModal
				account_id={account.id}
				modalState={atlaswareOnboardingWizardModalState}
				setModalState={setAtlaswareOnboardingModalState}
				user={null}
				refreshParent={refreshParent}
			/>
		</div>
	);
};

export default CredentialManager;