import React, { useReducer, useState } from 'react';
import PropTypes from 'prop-types';

import { AuthContext } from 'context/Context';
import { permissionsReducer } from 'reducers/permissionsReducer';

const AuthContextProvider = ({ children }) => {
  const [permissionsState, permissionsDispatch] = useReducer(
    permissionsReducer,
    {
      permissions: []
    }
  );

  const [accountSettings, setAccountSettings] = useState({}); // alternative state test for context settings

  const getAccountSetting = (key) => { // alternative state test for context settings
    return accountSettings[key];
  }

  const setAccountSetting = (key, value) => { // alternative state test for context settings
    setAccountSettings((previousValues) => {
      return {
        ...previousValues,
        [key]: value
      }
    });
  }

  return (
    <AuthContext.Provider value={{
      permissionsState,
      permissionsDispatch,
      getAccountSetting,
      setAccountSetting,
      accountSettings,
      setAccountSettings
    }}>
      {children}
    </AuthContext.Provider>
  );
}

AuthContextProvider.propTypes = {
  children: PropTypes.element
}

export default AuthContextProvider;