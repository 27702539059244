import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import IconButton from 'components/common/IconButton';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';

const Medications = ({ claimant, saveClaimant }) => {
	const [medications, setMedications] = useState((claimant?.medications) ? JSON.parse(claimant.medications) : []);

	// Prepare the medications array to save
	const prepareMedicationsData = (medicationsArray) => {
		let adjustedCurrentIncom = JSON.stringify(medicationsArray);

		if (claimant.medications !== adjustedCurrentIncom) {
			saveClaimant({ medications: adjustedCurrentIncom });
		}
	};

	// Update a medication
	const updateMedication = (index, field, value) => {
		let newMedication = [...medications];
		newMedication[index][field] = value;
		setMedications(newMedication);
	};

	// Add a new medication
	const addMedication = () => {
		let newMedicationMember = {
			medication: '',
			dosage: '',
			prescribing_doctor: '',
			medical_condition: ''
		};
		let newMedication = [...medications, newMedicationMember];
		setMedications(newMedication);
		prepareMedicationsData(newMedication);
	};

	// Remove a medication
	const removeMedication = (index) => {
		Swal.fire({
			title: 'Permanently delete this medication record?',
			text: "You won't be able to revert this!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Yes, delete it!'
		}).then(async (result) => {
			if (result.isConfirmed) {
				let newMedication = [...medications];
				newMedication.splice(index, 1);
				setMedications(newMedication);
				prepareMedicationsData(newMedication);
				toast.warning(result.message, {
					theme: 'colored'
				});
			}
		});
	};

	return (
		<div id="claimant-medications-container" className="my-3">
			<h5 className="d-inline">Medications</h5>

			<IconButton
				className="me-2 mb-1 float-end"
				variant="falcon-default"
				size="sm"
				icon="plus"
				transform="shrink-3"
				onClick={addMedication}
			>
				Add
			</IconButton>
			<hr />
			<Table responsive bordered striped >
				<thead>
					<th scope="col">Name</th>
					<th scope="col">Dose</th>
					<th scope="col">Prescribing Doctor</th>
					<th scope="col">Medical Condition</th>
				</thead>
				<tbody>
					{medications.map((cis, index) => (
						<tr key={index}>
							<td>
								<Form.Control
									type="test"
									size="sm"
									value={cis.medication}
									onChange={e => { updateMedication(index, 'medication', e.target.value) }}
									onBlur={() => prepareMedicationsData(medications)}
								/>
							</td>
							<td>
								<Form.Control
									type="text"
									size="sm"
									value={cis.dosage}
									onChange={e => { updateMedication(index, 'dosage', e.target.value) }}
									onBlur={() => prepareMedicationsData(medications)}
								/>
							</td>
							<td>
								<Form.Control
									type="text"
									size="sm"
									value={cis.prescribing_doctor}
									onChange={e => { updateMedication(index, 'prescribing_doctor', e.target.value) }}
									onBlur={() => prepareMedicationsData(medications)}
								/>
							</td>
							<td>
								<Form.Control
									type="text"
									size="sm"
									value={cis.medical_condition}
									onChange={e => { updateMedication(index, 'medical_condition', e.target.value) }}
									onBlur={() => prepareMedicationsData(medications)}
								/>
							</td>
							<td>
								<IconButton
									className="me-2 mb-1 float-end"
									variant="falcon-danger"
									size="sm"
									icon="trash"
									transform="shrink-3"
									onClick={() => removeMedication(index)}
								>
									Delete
								</IconButton>
							</td>
						</tr>
					))}
				</tbody>
			</Table>
		</div>
	);
};

Medications.propTypes = {
	claimant: PropTypes.object,
	saveClaimant: PropTypes.func
}

export default Medications;