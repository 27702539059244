import React, { useRef } from 'react';
import PageHeader from 'components/common/PageHeader';
import { Card, Col, Nav, Row, Button } from 'react-bootstrap';
import useScrollSpy from 'react-use-scrollspy';
import Children from './Fulcrum/Children';
import Collected from './Fulcrum/Collected';
import ThirdParties from './Fulcrum/ThirdParties';
import Updates from './Fulcrum/Updates';
import Uses from './Fulcrum/Uses';
import CaliforniaPrivacyRights from './Fulcrum/CaliforniaPrivacyRights';

const PrivacyPolicy = () => {
  const sectionRefs = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null)
  ];

  const activeSection = useScrollSpy({
    sectionElementRefs: sectionRefs,
    offsetPx: -150
  });

  const onButtonClick = () => {
    // using Java Script method to get PDF file
    fetch('/files/privacy-policy.pdf').then(response => {
      response.blob().then(blob => {
        // Creating new object of PDF file
        const fileURL = window.URL.createObjectURL(blob);
        // Setting various property values
        let alink = document.createElement('a');
        alink.href = fileURL;
        alink.download = 'privacy-policy.pdf';
        alink.click();
      })
    })
  }

  return (
    <>
      <PageHeader
        title="PRIVACY POLICY – ASSURE DISABILITY, INC."
        description="Assure Disability, Inc. is committed to safeguarding the privacy of our  clients.  This privacy policy will explain what information we collect, the steps we take to keep this information private and secure, what we do with this information, and your rights and choices as a client.  By providing information to Assure Disability, Inc. (including its employees and agents, in any format), you acknowledge your understanding of this Privacy Policy and agree to the terms of this Privacy Policy."
        className="mb-3"
      />
      <Row className="g-0">
        <Col lg={8} className="pe-lg-2">
          {/* <Account ref={sectionRefs[0]} /> */}
          <Children ref={sectionRefs[1]} />
          <Collected ref={sectionRefs[2]} />
          <Uses ref={sectionRefs[3]} />
          <ThirdParties ref={sectionRefs[4]} />
          <CaliforniaPrivacyRights ref={sectionRefs[5]} />
          <Updates ref={sectionRefs[6]} />
        </Col>
        <Col lg={4} className="ps-lg-2">
          <div className="sticky-sidebar">
            <Card className="sticky-top">
              <Card.Header className="border-bottom">
                <h6 className="mb-0 fs-0">On this page</h6>
                <br />
                <Button variant="link" onClick={onButtonClick}>Download Privacy Policy</Button>
              </Card.Header>
              <Card.Body>
                <div
                  id="terms-sidebar"
                  className="terms-sidebar nav flex-column fs--1"
                >
                  {/* <Nav.Item>
                    <Nav.Link
                      href="#account"
                      className="nav-link px-0 py-1"
                      active={activeSection === 0}
                    >
                      Account
                    </Nav.Link>
                  </Nav.Item> */}
                  <Nav.Item>
                    <Nav.Link
                      href="#children"
                      className="nav-link px-0 py-1"
                      active={activeSection === 1}
                    >
                      Children Under 16
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      href="#information-collected"
                      className="nav-link px-0 py-1"
                      active={activeSection === 2}
                    >
                      Information Collected
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      href="#uses"
                      className="nav-link px-0 py-1"
                      active={activeSection === 3}
                    >
                      Information uses
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      href="#third-parties"
                      className="nav-link px-0 py-1"
                      active={activeSection === 4}
                    >
                      Disclosing Information to Third Parties
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      href="#california-privacy-rights"
                      className="nav-link px-0 py-1"
                      active={activeSection === 5}
                    >
                      California Privacy Rights
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      href="#updates"
                      className="nav-link px-0 py-1"
                      active={activeSection === 6}
                    >
                      Updates to Privcy Rights
                    </Nav.Link>
                  </Nav.Item>
                </div>
              </Card.Body>
            </Card>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default PrivacyPolicy;
