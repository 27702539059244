import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Col, Form } from 'react-bootstrap';
import IconButton from 'components/common/IconButton';

const AccountTableHeader = ({ label = "Accounts", selectedRowIds, page = [], handleBulkActions, searchParams = {}, showClearFilters = false, setSearchParams = () => { }, setCreateAccountModal = () => { } } = {}) => {
	const formRef = useRef();
	const onClick = async (e) => {
		if (e) {
			e.preventDefault();
			e.stopPropagation();
		}
		let bulk_action = e.target?.innerText ? `${e.target.innerText}`.toLowerCase() : '';
		const formData = new FormData(formRef.current);
		let formDataObj = { bulk_action };
		for (let [key, value] of formData) {
			formDataObj[key] = value;
		}
		if (typeof handleBulkActions === 'function' && bulk_action) {
			let selectedRows = Array.isArray(page) ? page.reduce((acc, { isSelected = false, original } = {}) => (
				[...acc, ...(isSelected ? [original] : [])]
			), []) : [];
			let selectedIds = selectedRows.length > 0 ? selectedRows.map(({ id } = {}) => id) : [];
			await handleBulkActions({ bulk_action }, selectedRows, selectedIds);
		}
	};

	const onSubmit = async (e) => {
		if (e) {
			e.preventDefault();
			e.stopPropagation();
		}
		const formData = new FormData(formRef.current);
		let formDataObj = {};
		for (let [key, value] of formData) {
			formDataObj[key] = value;
		}
		if (typeof handleBulkActions === 'function' && formDataObj.bulk_action) {
			let selectedRows = Array.isArray(page) ? page.reduce((acc, { isSelected = false, original } = {}) => (
				[...acc, ...(isSelected ? [original] : [])]
			), []) : [];
			let selectedIds = selectedRows.length > 0 ? selectedRows.map(({ id } = {}) => id) : [];
			await handleBulkActions(formDataObj, selectedRows, selectedIds);
		}
	};

	const selectedIds = Array.isArray(page)
		? (page.reduce((acc, { isSelected = false, original } = {}) => (
			[...acc, ...(isSelected ? [original] : [])]
		), [])).map(({ id } = {}) => id)
		: [];

	return (
		<Form noValidate ref={formRef} onSubmit={onSubmit} className="row flex-between-center">
			<Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
				<h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">{label}</h5>
			</Col>
			<Col xs={8} sm="auto" className="ms-auto text-end ps-0">
				{Object.keys(selectedRowIds).length > 0 ? (
					<div className="d-flex">

					</div>
				) : (
					<div id="orders-actions">
						<IconButton
							variant="falcon-success"
							size="sm"
							icon="plus"
							transform="shrink-3"
							type="button"
							onClick={() => { setCreateAccountModal(true) }}
						>
							<span className="d-none d-sm-inline-block ms-1 fw-bold">Create New Account</span>
						</IconButton>
					</div>
				)}
			</Col>
		</Form>
	);
};

AccountTableHeader.propTypes = {
	label: PropTypes.string,
	selectedRowIds: PropTypes.object,
	page: PropTypes.array,
	handleBulkActions: PropTypes.func,
	searchParams: PropTypes.object,
	showClearFilters: PropTypes.bool,
	setSearchParams: PropTypes.func,
	setCreateAccountModal: PropTypes.func
};

export default AccountTableHeader;