import React from 'react';
import Card from 'react-bootstrap/Card';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import UserSettings from '../settings/UserSettings';
import RepresentativeRegistration from './representative/RepresentativeRegistration';
import UserAtlasware from './atlasware';
import PaymentSettings from '../settings/PaymentSettings';
import RevisionLogTable from 'components/app/revision-logs/RevisionLogTable';

const UserProfileTabs = ({ user, setUser = () => { }, refreshParent = () => { }, siteAdminArea = false }) => {
	return (
		<Card>
			<Card.Body>
				<Tabs defaultActiveKey="settings" id="uncontrolled-tab-example" unmountOnExit={true} mountOnEnter={true}>
					<Tab eventKey="settings" title="Settings" className='border-bottom border-x p-3'>
						<UserSettings user={user} setUser={setUser} refreshParent={refreshParent} />
					</Tab>
					{
						user?.assigned_user_types && user.assigned_user_types.filter(t => t.user_type_id === "REPRESENTATIVE").length > 0 && (
							<Tab eventKey="representative" title="Representative" className='border-bottom border-x p-3'>
								<RepresentativeRegistration
									user={user}
									setUser={setUser}
									refreshParent={refreshParent}
								/>
							</Tab>
						)
					}
					{
						user?.assigned_user_types && user.assigned_user_types.filter(t => t.user_type_id === "REPRESENTATIVE").length > 0 && (
							<Tab eventKey="atlasware" title="Atlasware" className='border-bottom border-x p-3'>
								<UserAtlasware
									user={user}
									setUser={setUser}
									refreshParent={refreshParent} />
							</Tab>
						)
					}
					{/* {
						user?.assigned_user_types && user.assigned_user_types.filter(t => t.user_type_id === "ADMIN").length > 0 && (
							<Tab eventKey="payment" title="Payment" className='border-bottom border-x p-3'>
								<PaymentSettings user={user} setUser={setUser} refreshParent={refreshParent} />
							</Tab>
						)
					} */}

					<Tab eventKey="other" title="Other" className='border-bottom border-x p-3'>
						<Tabs defaultActiveKey="revision-logs" id="other-tabs">
							<Tab eventKey="revision-logs" title="Revision Logs" className='border-bottom border-x p-3 overflow-auto'>
								<RevisionLogTable
									defaultSearchParams={{}}
									requiredSearchParams={{ table: 'user', parent_id: user.id, account_id: user.account_id }}
								/>
							</Tab>
						</Tabs>
					</Tab>
				</Tabs>
			</Card.Body>
		</Card>
	);
};

export default UserProfileTabs;