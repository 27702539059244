import React from 'react';
// import TaskMetrics from 'components/task/TaskMetrics';
import TaskTable from 'components/task/TaskTable';
import Flex from 'components/common/Flex';
import { Card, Row, Col, Button } from 'react-bootstrap';
import Auth from 'utils/auth';

const TaskManager = () => {
	return (
		<div>
			<Card className="bg-100 shadow-none border mb-3">

				<Card.Body>
					<Row className=" justify-content-between pr-0">
						<Col sm="auto" className="pr-0 align-middle">
							<Flex alignItems="center">
								<h4 className="align-middle fw-bold mb-0 h-inline">
									<span className="fw-medium">Task Manager</span>
								</h4>
							</Flex>
						</Col>
					</Row>
				</Card.Body>
			</Card>

			<Card>
				<Card.Body className="shadow-none">
					<TaskTable
						requiredSearchParams={{ account_id: Auth.getProfile()?.data?.account_id || null }}
						defaultSearchParams={{}}
					/>
				</Card.Body>
			</Card>
		</div>
	);
};

export default TaskManager;