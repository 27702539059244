import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'react-bootstrap';
import { caseAPI } from 'utils/api/fulcrum-api';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import AppContext from 'context/Context';
import DocumentTable from 'components/documents/DocumentTableOld';
import moment from 'moment/moment';
import CreateCorrespondenceWizard from 'components/app/inbox-outbox/correspondence-wizard/CreateCorrespondenceWizard';

const SubmitRepresentativeDocuments = ({ modalState, setModalState, claimant, selectedCase }) => {
	const defaultFormState = {
		claimant_id: claimant.id,
		recipient_fax: '',
		recipient_email: '',
	};
	const [formData, setFormData] = useState(defaultFormState);
	const [loading, setLoading] = useState(false);

	const {
		config: { isDark }
	} = useContext(AppContext);

	// Handle Confirm SSA Application Button Click
	const handleSubmitRepresentativeDocumentsClick = async () => {
		setLoading(true);
		try {
			let result = await caseAPI.submitRepresentiveDocuments(claimant.id, selectedCase.id, {
				...formData,
				claimant_id: claimant.id,
				type: 'FAX'
			});
			console.log(result)
			toast.success('Documents sent to SSA', { theme: 'colored' });
		} catch (error) {
			console.log(error);
			toast.error('Error submitting representative documents', { theme: 'colored' });
		}
		setLoading(false);
	};


	return (
		<div>
			{/* <Button variant="primary" onClick={() => { setModalState(true) }} disabled={loading}>Submit Representative Documents</Button> */}
			<CreateCorrespondenceWizard
				modalState={modalState}
				setModalState={setModalState}
				defaultClaimant={claimant}
				customMessage={`<p>Previously Submitted: ${selectedCase?.submitted_representative_documents_timestamp ? moment(selectedCase?.submitted_representative_documents_timestamp).format('MM/DD/YYYY') : 'Not Submitted'}</p>Submit representative documents to the Social Security Administration using the instructions below.`}
				modalTitle={`Submit Representative Documents`}
				defaultSlide={4}
				defaultModeOfTransmission={'FAX'}
				refreshParent={handleSubmitRepresentativeDocumentsClick}
			/>
		</div >
	);
};

SubmitRepresentativeDocuments.propTypes = {
	claimant: PropTypes.object,
	selectedCase: PropTypes.object
}

export default SubmitRepresentativeDocuments;