import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { Card, Modal, Spinner, ListGroup } from 'react-bootstrap';
import PDFJSViewerPreview from 'components/app/pdfjs-viewer/PDFJSViewerPreview';
import { myFormsAPI } from 'utils/api/fulcrum-api';
const defaultHeaderButtons = ['resetButton', 'infoButton', 'printButton', 'downloadZipButton', 'downloadPdfButton'];

const PDFViewerPreview = ({ headerButtons = defaultHeaderButtons, pdf_template, claimant_id, user_id, myFormResponseId, children, ...props } = {}) => {
  let params = useParams();
  if (!claimant_id && params.claimant_id) {
    claimant_id = params.claimant_id;
  }
  if (!user_id && params.user_id) {
    user_id = params.user_id;
  }
  if (!pdf_template && params.pdf_template) {
    pdf_template = params.pdf_template;
  }
  if (!myFormResponseId && params.myFormResponseId) {
    myFormResponseId = params.myFormResponseId;
    pdf_template = ``
  }
  if (!headerButtons || Array.isArray(headerButtons) && headerButtons.length === 0) {
    headerButtons = defaultHeaderButtons;
  }
  headerButtons.map((btn) => !`${btn}`.toLowerCase().includes("button") ? `${btn}Button` : btn); // add 'Button' to the end of each button name if it doesn't already have it
  const [warningMessageType, setWarningMessageType] = useState('warning'); // 'primary', 'secondary', 'success', 'danger', 'warning', 'info', 'light', 'dark'
  const [warningMessages, setWarningMessages] = useState([]);
  const [documentLoading, setDocumentLoading] = useState(false);
  const [toggleResetFromParent, setToggleResetFromParent] = useState(false); // Used to reload pdf when props change. Calls loadDocument which ultimately calls getPdfStream. i.e. setToggleReset((prev) => !prev);

  /**
   * getPdfStream
   * @param {object} params
   * @returns {blob} pdfStream
   */
  const getPdfStream = async ({ claimantID = claimant_id, pdfTemplate = pdf_template, mfrID = myFormResponseId, userID = user_id } = {}) => {
    let pdfStream = null;
    let resp;
    try {
      let params = {
        ...(mfrID ? { myFormResponseId: mfrID } : { pdf_template: pdfTemplate }),
        ...(claimantID && { claimant_id: claimantID }),
        ...(userID && { user_id: userID }),
      };
      resp = await myFormsAPI.streamPDFWithFormDataPreview(params);
    } catch (error) {
      console.error(error);
      let errorMessage = `getPdfStream ${Object.entries({ claimant_id: claimantID, user_id: userID, myFormResponseId: mfrID, pdf_template: pdfTemplate }).filter(([k, v]) => v).map(([key, value]) => `${key}: ${value}`).join(" ")} Error ${error.message}`;
      console.error("errorMessage", errorMessage);

      resp = error?.response || error || {};
      let { headers = {}, data: response, status, statusText } = resp;
      let headerType = headers['content-type'] || "application/json";
      let parsedErrorMessages = statusText || status || "Unknown Error";

      if (["text/html", "text/plain", "application/json"].some((t) => (headerType.includes(t)))) {
        let tmpData = await response.text();
        if (headerType.includes("application/json")) {
          resp = JSON.parse(tmpData);
          parsedErrorMessages = resp?.error || resp?.message || resp?.statusMessage || resp?.status || resp?.statusText || statusText || status || "Unknown Error";
        } else {
          parsedErrorMessages = tmpData;
        }
      };
      console.error("parsedErrorMessages", parsedErrorMessages);

      setWarningMessageType("danger");
      setWarningMessages((prev) => [...new Set([...prev, errorMessage, parsedErrorMessages])]); // Remove duplicate errors 
      setDocumentLoading(false);
      return { ...resp, myFormResponseId: mfrID, claimant_id: claimantID, user_id: userID };
    }
    if (resp instanceof Blob) {
      pdfStream = resp;
    } else if (resp.pdfStream instanceof Blob) {
      return { ...resp, myFormResponseId: mfrID, claimant_id: claimantID, user_id: userID }; // { pdfStream: blob, pdfFilename: string, myFormResponseId: string, claimant_id: string, user_id: string }
    }
    return pdfStream;
  };

  /**
   * saveFilledPdf
   * @param {object} params 
   */
  const saveFilledPdf = async (params = {}) => {
    let { fields = {}, xfdf = '', return_type = "pdf", pdf_filename, claimant_id: claimantID, userID = user_id, ...extraParams } = params || {};
    let pdfTemplate = pdf_filename ? pdf_filename.replace('.pdf', '') : pdf_template;
    claimantID = claimantID || claimant_id;
    let msg = '';
    let msgType = 'info';
    try {
      let params = {
        ...(claimantID && { claimant_id: claimantID }),
        ...(userID && { user_id: userID }),
        pdf_template: pdfTemplate,
        fields, xfdf,
        return_type,
        ...extraParams
      };
      let { error, response, message = '' } = await myFormsAPI.saveFilledPdf(params);
      let { statusMessage, message: _message, error: _error } = response || {};
      if (_error) {
        throw new Error(_error);
      }
      if (error) {
        if (typeof response?.error === 'string') {
          throw new Error(`${error} - ${response.error}`);
        } else if (typeof response === 'string') {
          throw new Error(`${error} - ${response}`);
        } else if (typeof error === 'string' && typeof message === 'string') {
          error = `${error} - ${message}`;
        }
        throw new Error(error);
      }

      msgType = 'success';
      msg = _message || statusMessage || message || `Save filled PDF Successfully!`;
    } catch (error) {
      console.log(error, error.message);
      msgType = 'danger';
      msg = `saveFilledPdf Error ${error.message}`;
    }
    setWarningMessageType(msgType);
    setWarningMessages([msg]);
  };

  useEffect(() => {
    if (pdf_template || myFormResponseId) {
      setToggleResetFromParent((prev) => !prev); // reload pdf when props change
    } else {
      let msgType = 'danger';
      let msg = `pdf_template or myFormResponseId is required`;
      setWarningMessageType(msgType);
      setWarningMessages([msg]);
    }
  }, [claimant_id, user_id, pdf_template, myFormResponseId, headerButtons]);

  return (<div className="vh-100">
    <Card className="PDFJSWebviewer vh-100">
      {children}
      <div>{warningMessages.length > 0 && <ListGroup variant="flush">{warningMessages.length > 0 && warningMessages.map((message, index) => (
        <ListGroup.Item key={index} variant={warningMessageType} >{message}</ListGroup.Item>))}</ListGroup>}
      </div>

      {(pdf_template || myFormResponseId) && <PDFJSViewerPreview
        getPdfStream={getPdfStream}
        captureCallback={saveFilledPdf}
        pdfFilename={`${pdf_template || "myFormResponseId_" + myFormResponseId}.pdf`}
        setWarningMessages={setWarningMessages}
        setWarningMessageType={setWarningMessageType}
        headerButtons={headerButtons}
        toggleResetFromParent={toggleResetFromParent}
        documentLoading={documentLoading}
        setDocumentLoading={setDocumentLoading}
        {...props} />}

      <Modal show={documentLoading} fullscreen={true} className="opacity-75">
        <Modal.Body className={'text-center opacity-75 d-flex'}>
          <div className="justify-content-center m-auto" style={{ width: "50%" }}>
            <h5>Loading <strong>{pdf_template}</strong> for <strong>{Object.entries({ claimant_id, user_id, myFormResponseId }).filter(([k, v]) => v).map(([key, value]) =>
              `${key}: ${value}`).join(" ")
            }</strong></h5>
            <Spinner animation="border" role="status"> <span className="visually-hidden">Loading...</span> </Spinner>
          </div>
        </Modal.Body>
      </Modal>
    </Card>
  </div>);
};

PDFViewerPreview.propTypes = {
  headerButtons: PropTypes.array,
  pdf_template: PropTypes.string,
  claimant_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  user_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  myFormResponseId: PropTypes.integer,
  children: PropTypes.node,
};

export default PDFViewerPreview;
