import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Card, Dropdown } from 'react-bootstrap';
import moment from 'moment';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import { inboxOutboxAPI } from 'utils/api/fulcrum-api';
import { removeEmptyFromObj } from 'utils/functions';
import CardDropdown from 'components/common/CardDropdown';
import Flex from 'components/common/Flex';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import CustomAdvanceTable from 'components/common/advance-table/CustomAdvanceTable';
import CustomAdvanceTablePagination from 'components/common/advance-table/CustomAdvanceTablePagination';
import OutboxTableHeader from './OutboxTableHeader';
import ReactInterval from 'react-interval';
import InboxOutboxFilters from '../InboxOutboxFilters';
import InboxOutboxDetails from '../InboxOutboxDetails';

const defaultOutboxColumns = [
	{
		accessor: 'sender',
		Header: 'Sender'
	},
	{
		accessor: 'mode_of_transmission',
		Header: 'Mode of Transmission'
	},
	{
		accessor: 'status',
		Header: 'Status'
	},
	{
		accessor: 'claimant',
		Header: 'Claimant'
	},
	{
		accessor: 'createdAt',
		Header: 'Received'
	},
];

let regularMailColumns = [
	{
		accessor: 'last_download_timestamp',
		Header: 'Last Download',
		show: false,
		hidden: true
	},
	{
		accessor: 'last_download_by',
		Header: 'Last Download By',
		show: false
	},
]

const OutboxTable = ({ outboxColumns = defaultOutboxColumns, defaultSearchParams = {}, requiredSearchParams = { direction: 'Outbound' }, refreshParent = () => { } } = {}) => {
	const [outboxData, setOutboxData] = useState([]);
	const [Pages, setPages] = useState([1]);
	const [activePage, setActivePage] = useState(1);
	const [perPage, setPerPage] = useState(10);
	const [outboxDataCount, setOutboxDataCount] = useState(0);
	const [inboxOutboxDetailsModalState, setInboxOutboxDetailsModalState] = useState(false);

	const [selectedCorrespondence, setSelectedCorrespondence] = useState({});
	const [searchParams, setSearchParams] = useState({ ...defaultSearchParams, ...requiredSearchParams });
	let defaultSort = ["created_at", "DESC"];
	const [searching, setSearching] = useState(false);
	const [lastQuery, setLastQuery] = useState({});
	const [appliedSortOrders, setAppliedSortOrders] = useState([defaultSort]);

	const [modes, setModes] = useState(['EMAIL', 'FAX', 'SMS', 'MAIL']);
	const [columns, setColumns] = useState([
		...outboxColumns,
		{
			accessor: 'actions',
			Header: 'Actions'
		}
	]);

	const deleteOutboxItem = async (accountId, id) => {
		if (!id) return;

		let confirmed = await Swal.fire({
			title: 'Permanently delete outbox item?',
			text: "You won't be able to revert this!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Yes, delete it!'
		}).then((result) => (result.isConfirmed));
		if (!confirmed) {
			return;
		}

		try {
			let { error, response, message = '' } = await inboxOutboxAPI.deleteInboxOutbox(accountId, id);
			if (error) {
				if (typeof response?.error === 'string') {
					throw new Error(`${error} - ${response.error}`);
				} else if (typeof response === 'string') {
					throw new Error(`${error} - ${response}`);
				}

				let { statusCode } = response?.error || response || {};
				if (!message) {
					message = `${error} - There was an error deleting.`;
				}
				if (statusCode) {
					message = `${statusCode} - ${message}`;
				} else {
					message = `${error} - ${message}`;
				}
				throw new Error(message);
			}

			refreshLastQuery();
			toast.success('Successfully deleted outbox item', {
				theme: 'colored'
			});
		} catch (err) {
			let error_message = err?.message || err?.data?.error?.message || err || "There was an error deleting the outbox item. See console for error";
			toast.error(error_message, {
				theme: 'colored'
			});
		}
	};

	const handleBulkActions = (actionObj = {}, selectedRows = [], selectedIds = []) => {
		let outboxItem = {};
		if (Array.isArray(selectedRows) && selectedRows.length === 1) {
			outboxItem = selectedRows[0];
		}
		let { bulk_action } = actionObj || {};
		switch (bulk_action) {
			case 'delete':
				// (outboxItem.id) && deleteOutboxItem(outboxItem.id);
				break;
			case 'view outbox':
				setModes(['EMAIL', 'FAX', 'SMS']);
				break;
			case 'view regular mail':
				setModes(['MAIL', 'REGULAR_MAIL', 'Regular Mail']);
				break;
			default:
				console.log("@todo handleBulkActions", actionObj, "selectedRows", selectedRows, "selectedIds", selectedIds, "outboxItem", outboxItem);
				break;
		}
	};

	const formatData = (data = []) => {
		let adjustedData = data.map(item => {
			return {
				...item,
				status: item?.status || '',
				createdAt: moment(item.createdAt).format('MM/DD/YYYY hh:mm A'),
				updatedAt: moment(item.updatedAt).format('MM/DD/YYYY hh:mm A'),
				claimant: item?.claimant ? <Link to={`/claimant/profile/${item.claimant.id}`}>{item.claimant.first_name} {item.claimant.last_name}</Link> : '',
				actions: (
					<Flex className="justify-content-end">
						<CardDropdown>
							<Dropdown.Item onClick={(e) => { setSelectedCorrespondence(item); setInboxOutboxDetailsModalState(true) }} >
								<span>View</span>
							</Dropdown.Item>
							{/* <Dropdown.Divider />
							<Dropdown.Item onClick={() => deleteOutboxItem(item?.account_id || null, item.id)} >
								<span>Delete</span>
							</Dropdown.Item> */}
						</CardDropdown>
					</Flex>
				)
			}
		});
		setOutboxData(adjustedData);
	};

	const headerClickFn = (id, previousSortBy) => {
		if (!id) {
			return;
		}
		let foundIndex = appliedSortOrders.findIndex(s => s[0] === id);
		let sortBy = !previousSortBy ? "ASC" : previousSortBy === "asc" ? "DESC" : '';

		if (!sortBy) { // Not Sorted so remove clicked sort item 
			if (foundIndex > -1) {
				setAppliedSortOrders((prev) => {
					let newSortOrders = [...prev];
					newSortOrders.splice(foundIndex, 1); // remove clicked sort item
					return newSortOrders;
				});
			}
		} else { // Sort by Ascending or Descending 
			let newSort = [id, sortBy]

			if (foundIndex > -1) { // replaces existing 
				setAppliedSortOrders((prev) => {
					let newSortOrders = [...prev];
					newSortOrders[foundIndex] = newSort;
					return newSortOrders;
				});
			} else { // inserts 
				setAppliedSortOrders((prev) => [...prev, newSort]);
			}
		}
	};

	const getOutboxData = async (params = {}, saveLastQuery = true, options = {}) => {
		try {
			params = removeEmptyFromObj(params);
			params.mode_of_transmission = modes;
			params.page = activePage;
			params.limit = perPage;
			params.navSize = 5; // How many buttons to show for navigation 
			params.order = appliedSortOrders; // Example [[id, 'ASC']]
			params.direction = "Outbound"
			let { error, response, data } = await inboxOutboxAPI.searchInboxOutboxes({ ...params, ...requiredSearchParams }, options);
			if (error) {
				if (typeof response?.error === 'string') {
					throw new Error(`${error} - ${response.error}`);
				} else if (typeof response === 'string') {
					throw new Error(`${error} - ${response}`);
				}
				throw new Error(`${error} - There was an error searching the outbox.`);
			}

			let { count = 0, rows = [], pages, page, pageCount } = data || {}; // data = { count = 0, rows = [], limit, pageCount, page, pages = [1,2,3,10] } 
			if (!rows || (Array.isArray(rows) && rows.length === 0)) {
				throw new Error('No outbox data found');
			}

			if (pageCount < activePage) {
				setActivePage(1);
			} else {
				setActivePage(page);
			}
			setPages(pages);
			formatData(rows);
			setOutboxDataCount(count);
			if (saveLastQuery) {
				setLastQuery(params);
			}
		} catch (error) {
			console.log("Error getting outbox data", error);
			setOutboxData([]);
			setOutboxDataCount(0);
			setActivePage(1);
		}
		setSearching(false);
	};

	const newSearch = async (params = {}) => {
		setSearching(true);
		getOutboxData(params, true);
	};

	const refreshLastQuery = async (options = {}) => {
		await getOutboxData({ ...lastQuery, ...requiredSearchParams }, false, options);
		refreshParent();
	};;

	// Clear search params and reset to default
	const clearSearchParams = () => {
		if (JSON.stringify(searchParams) !== JSON.stringify({ ...defaultSearchParams, ...requiredSearchParams })) {
			setSearchParams({ ...defaultSearchParams, ...requiredSearchParams });
		}
		if (JSON.stringify(appliedSortOrders) !== JSON.stringify([defaultSort])) {
			setAppliedSortOrders([defaultSort]);
		}
		if (activePage !== 1) {
			setActivePage(1);
		}
		if (perPage !== 10) {
			setPerPage(10);
		}
	};

	useEffect(() => {
		if (modes.includes('MAIL')) {
			setColumns([
				...outboxColumns,
				...regularMailColumns,
				{
					accessor: 'actions',
					Header: 'Actions'
				}
			]);
		} else {
			setColumns([
				...outboxColumns,
				{
					accessor: 'actions',
					Header: 'Actions'
				}
			]);
		}

		newSearch({ ...searchParams });
	}, [searchParams, activePage, perPage, appliedSortOrders, modes]);

	return (
		<AdvanceTableWrapper
			columns={columns}
			data={outboxData}
			selection
			sortable
			pagination
			perPage={perPage}
			setPerPage={setPerPage}
			hiddenColumns={['last_download_by']}
		>
			<Card className="mb-3 shadow-none">
				<Card.Header>
					<OutboxTableHeader
						table
						modes={modes}
						searchParams={searchParams}
						handleBulkActions={handleBulkActions}
						refreshLastQuery={refreshLastQuery}
					/>

					<InboxOutboxFilters
						searchParams={searchParams}
						setSearchParams={setSearchParams}
						clearSearchParams={clearSearchParams}
						fields={[
							"sender",
							"mode_of_transmission",
							// "recipient",
							"claimant",
							"recieved_start_date",
							"recieved_end_date",
							// "status",
						]}
					/>
				</Card.Header>
				<Card.Body className="p-0">
					{
						searching ?
							<p>Loading Outbox...</p>
							:
							<CustomAdvanceTable
								table
								headerClickFn={headerClickFn}
								appliedSortOrders={appliedSortOrders}
								headerClassName="bg-200 text-900 text-nowrap align-middle"
								rowClassName="align-middle white-space-nowrap"
								tableProps={{
									bordered: true,
									striped: true,
									className: 'fs--1 mb-0'
								}}
							/>
					}
				</Card.Body>
				<Card.Footer>
					<CustomAdvanceTablePagination
						table
						Pages={Pages}
						activePage={activePage}
						setActivePage={setActivePage}
						totalRecords={outboxDataCount}
					/>
				</Card.Footer>
			</Card>

			<InboxOutboxDetails
				modalState={inboxOutboxDetailsModalState}
				setModalState={setInboxOutboxDetailsModalState}
				refreshParent={refreshLastQuery}
				selectedCorrespondence={selectedCorrespondence}
				setSelectedCorrespondence={setSelectedCorrespondence}
			/>

			<ReactInterval timeout={15000} enabled={true} callback={() => { refreshLastQuery({ ignoreTouch: true }) }} />

		</AdvanceTableWrapper>
	)
};


export default OutboxTable;