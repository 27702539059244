import React, { useEffect, useState, useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import AppContext from 'context/Context';
import WizardInput from '../WizardInput';
import { Col, Row, Button, Modal } from 'react-bootstrap';
import JoditEditor from "jodit-pro-react";
import { AuthWizardContext } from 'context/Context';
import MyFormFinderModal from 'components/pages/documents/my-forms/MyFormFinderModal';

// const CreateCorrespondenceEmailForm = ({ register, errors, watch, updateUserError, setUpdateUserError, handleSubmitForm }) => {
const CreateCorrespondenceEmailForm = ({ register, errors, watch }) => {
	const { user } = useContext(AuthWizardContext);
	const [forms, setForms] = useState([]);
	const [myFormFinderModalShow, setMyFormFinderModalShow] = useState(false);
	const [addCorrespondenceFormModal, setAddCorrespondenceFormModal] = useState(false);

	const editor = useRef(null);
	// const [formState, setFormState] = useState({
	// 	body: ''
	// });

	const {
		config: { isDark }
	} = useContext(AppContext);

	const editorConfig = {
		// licnese: 
		readonly: false // all options from https://xdsoft.net/jodit/doc/
	}

	// Select from the list of forms
	const handleFormSelect = (selectedForm) => {
		let selectedFormData = {
			id: forms.length + 1,
			name: selectedForm?.title || 'Untitled Form',
			content: selectedForm?.content || '',
			type: 'text',
			required: false
		};

		addForm(selectedFormData);
	}

	// Add form
	const addForm = (newFormData = { id: forms.length, name: `Custom Form ${forms.length}`, content: '', type: 'text', required: false }) => {
		setForms([...forms, newFormData]);
	};

	// Remove form
	const removeForm = (index) => {
		const newForms = [...forms];
		newForms.splice(index, 1);
		setForms(newForms);
	};

	// Shift form
	const shiftForm = (index, direction) => {
		const newForms = [...forms];
		const form = newForms[index];
		newForms.splice(index, 1);
		newForms.splice(index + direction, 0, form);
		console.log({
			forms,
			newForms
		});


		setForms(newForms);
	};

	useEffect(() => {
	}, [forms]);

	return (
		<>

			<h5>Correspondence</h5>

			{
				user.mode_of_transmission === 'EMAIL' &&
				<WizardInput
					label="Subject*"
					name="subject"
					errors={errors}
					formGroupProps={{ className: 'mb-3' }}
					formControlProps={{
						...register('subject', {
							required: 'Email subject is required'
						})
					}}
				/>
			}

			<div className="my-3">


				<div className="mb-3">
					<h5 className="d-inline">Message Content</h5>
					<Button variant="success" className="float-end" onClick={() => setAddCorrespondenceFormModal(true)}>+ Add</Button>
				</div>

				{
					forms.map((form, index) => {
						return (
							<div key={index} className="my-3 pt-3 border-top">
								<div>
									<span className=''>{form.name}</span>
									<Button variant="danger" className="ml-3 float-end" onClick={() => removeForm(index)}>Remove</Button>
									<div className="float-end mr-3">
										<Button onClick={() => shiftForm(index, -1)} variant="link mx-2" className="p-0">Up<i className="fas fa-arrow-up"></i></Button>
										<Button onClick={() => shiftForm(index, 1)} variant="link" className="p-0">Down<i className="fas fa-arrow-down"></i></Button>
									</div>
								</div>
								<br />
								<Row>
									<Col>
										<JoditEditor
											tabIndex={1} // tabIndex of textarea
											ref={editor}
											value={form.content}
											config={editorConfig}
											onBlur={newContent => { form.content = newContent }}
										/>
									</Col>
								</Row>
							</div>
						)
					})
				}

			</div>


			<h5>Recipient(s)</h5>
			<WizardInput
				type="select"
				label="Recipient(s)*"
				name="recipient"
				placeholder="Select recipient type..."
				errors={errors}
				options={['One or more claimants', 'Social Security Administration', 'Another Recipient']}
				formGroupProps={{
					className: 'mb-3'
				}}
				formControlProps={{
					...register('recipient', {
						required: "Recipient(s) is required"
					})
				}}
			/>

			{
				watch('recipient') === 'One or more claimants' &&
				<>
				</>
			}

			{
				watch('recipient') === 'Social Security Administration' &&
				<>
					Social Security Selected
					{/* <WizardInput
        type="text"
        errors={errors}
        label="Fax Number"
        name="fax_number"
        formGroupProps={{ className: 'mb-3' }}
        formControlProps={{
          ...register('fax_number')
        }}
      /> */}
				</>
			}

			{
				watch('recipient') === 'Another Recipient' &&
				<>
					{
						user.mode_of_transmission == "Email" &&
						<WizardInput
							label="Email*"
							name="email"
							errors={errors}
							formGroupProps={{ as: Col, sm: 6, className: 'mb-3' }}
							formControlProps={{
								...register('email', {
									required: 'Email is required'
								})
							}}
						/>
					}

					{
						user.mode_of_transmission == "Fax" &&
						<WizardInput
							label="Fax Number*"
							name="fax_number"
							errors={errors}
							formGroupProps={{ as: Col, sm: 6, className: 'mb-3' }}
							formControlProps={{
								...register('fax_number', {
									required: 'Fax number is required'
								})
							}}
						/>
					}

					{
						user.mode_of_transmission == "SMS" &&
						<WizardInput
							label="Phone Number*"
							name="phone_number"
							errors={errors}
							formGroupProps={{ as: Col, sm: 6, className: 'mb-3' }}
							formControlProps={{
								...register('phone_number', {
									required: 'Phone number is required'
								})
							}}
						/>
					}

				</>
			}

			<h5>Associated Claimants</h5>

			<Modal
				show={addCorrespondenceFormModal}
				onHide={() => {
					setAddCorrespondenceFormModal(false)
				}}
				onShow={() => {
					setAddCorrespondenceFormModal(true)
				}}
				contentClassName="border"
				size="lg"
				backdrop="static"
			>
				<Modal.Header
					closeButton
					closeVariant={isDark ? 'white' : undefined}
					className="bg-light px-card border-bottom-0"
				>
					<Modal.Title as="h5">Add Message Content</Modal.Title>
				</Modal.Header>
				<Modal.Body className="p-card">
					<p>Which</p>
					<Button variant="primary" className="" onClick={() => {
						setAddCorrespondenceFormModal(false);
						setMyFormFinderModalShow(true);
					}}>Search My Forms</Button>
					<Button variant="secondary" onClick={() => {
						setAddCorrespondenceFormModal(false);
						addForm();
					}}>New Custom Form</Button>
				</Modal.Body>
			</Modal>

			<MyFormFinderModal
				handleFormSelection={handleFormSelect}
				modalState={myFormFinderModalShow}
				setModalState={setMyFormFinderModalShow}
			/>

		</>
	);
};

CreateCorrespondenceEmailForm.propTypes = {
	register: PropTypes.func.isRequired,
	errors: PropTypes.object,
	watch: PropTypes.func
};

export default CreateCorrespondenceEmailForm;