import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import IconButton from 'components/common/IconButton';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';

const OtherHousehold = ({ claimant, saveClaimant }) => {
	const [otherHousehold, setOtherHousehold] = useState((claimant?.others_in_household) ? JSON.parse(claimant.others_in_household) : []);

	// Prepare the otherHousehold array to save
	const prepareOtherHouseholdData = (otherHouseholdArray) => {
		let adjustedOtherHouseholdArray = JSON.stringify(otherHouseholdArray);

		if (claimant.otherHousehold !== adjustedOtherHouseholdArray) {
			saveClaimant({ others_in_household: adjustedOtherHouseholdArray });
		}
	};

	// Update a other household member
	const updateOtherHouseholdMember = (index, field, value) => {
		let newOtherHousehold = [...otherHousehold];
		newOtherHousehold[index][field] = value;
		setOtherHousehold(newOtherHousehold);
	};

	// Add a new other household member
	const addOtherHousehold = () => {
		let newOtherHouseholdMember = {
			name: '',
			relationship: ''
		};
		let newOtherHousehold = [...otherHousehold, newOtherHouseholdMember];
		setOtherHousehold(newOtherHousehold);
		prepareOtherHouseholdData(newOtherHousehold);
	};

	// Remove a other household member
	const removeOtherHousehold = (index) => {
		Swal.fire({
			title: 'Permanently delete this other household record?',
			text: "You won't be able to revert this!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Yes, delete it!'
		}).then(async (result) => {
			if (result.isConfirmed) {
				let newOtherHousehold = [...otherHousehold];
				newOtherHousehold.splice(index, 1);
				setOtherHousehold(newOtherHousehold);
				prepareOtherHouseholdData(newOtherHousehold);
				toast.warning(result.message, {
					theme: 'colored'
				});
			}
		});
	};

	return (
		<div id="claimant-otherHousehold-container" className="my-3">
			<h5 className="d-inline">Other Household</h5>

			<IconButton
				className="me-2 mb-1 float-end"
				variant="falcon-default"
				size="sm"
				icon="plus"
				transform="shrink-3"
				onClick={addOtherHousehold}
			>
				Add
			</IconButton>
			<hr />
			<Table responsive bordered striped >
				<thead>
					<th scope="col">Name</th>
					<th scope="col">Relationship</th>
					<th scope="col"></th>
				</thead>
				<tbody>
					{otherHousehold.map((oh, index) => (
						<tr key={index}>
							<td>
								<Form.Control
									type="text"
									size="sm"
									value={oh.name}
									onChange={e => { updateOtherHouseholdMember(index, 'name', e.target.value) }}
									onBlur={() => prepareOtherHouseholdData(otherHousehold)}
								/>
							</td>
							<td>
								<Form.Control
									type="text"
									size="sm"
									value={oh.relationship}
									onChange={e => { updateOtherHouseholdMember(index, 'relationship', e.target.value) }}
									onBlur={() => prepareOtherHouseholdData(otherHousehold)}
								/>
							</td>
							<td>
								<IconButton
									className="me-2 mb-1 float-end"
									variant="falcon-danger"
									size="sm"
									icon="trash"
									transform="shrink-3"
									onClick={() => removeOtherHousehold(index)}
								>
									Delete
								</IconButton>
							</td>
						</tr>
					))}
				</tbody>
			</Table>
		</div>
	);
};

OtherHousehold.propTypes = {
	claimant: PropTypes.object,
	saveClaimant: PropTypes.func
}

export default OtherHousehold;