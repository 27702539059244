import React, { useState } from 'react';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import { toast } from 'react-toastify';
import { claimantAPI } from 'utils/api/fulcrum-api';
import { getAgeFromBirthdate, formatPhone, isValidPhone, formatSSN, isValidSSN } from 'utils/functions';
import AddressList from 'components/app/address/AddressList';
import EmailList from 'components/app/email/emails/EmailList';
import PhoneList from 'components/app/phones/PhoneList';
import moment from 'moment';
import ClaimantConfirmContactInfo from './ClaimantConfirmContactInfo';
import LostContact from './lost-contact-with-claimant/LostContact';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import { stateData } from 'data/stateData';
import { Link } from 'react-router-dom';
import UserQuickFind from 'components/users/find-a-user/UserQuickFind';
import PrimaryRepSearch from 'components/users/find-a-user/PrimaryRepSearch';
import CustomFieldList from 'components/custom-fields/custom-field-list/CustomFieldList';

const ClaimantProfileCard = ({ claimant, setClaimant, refreshClaimant = () => { }, siteAdminArea = false }) => {
	let defaultState = {
		first_name: claimant.first_name,
		middle_name: claimant.middle_name,
		last_name: claimant.last_name,
		suffix: claimant.suffix,
		email: claimant.email,
		ssn: formatSSN(claimant.ssn),
		dob: claimant.dob,
		alt_contact_name: claimant.alt_contact_name,
		alt_contact_phone: formatPhone(claimant.alt_contact_phone),
		alt_contact_relationship: claimant.alt_contact_relationship,
		mailing_address: claimant.mailing_address,
		mailing_address_2: claimant.mailing_address_2,
		mailing_city: claimant.mailing_city,
		mailing_state: claimant.mailing_state,
		mailing_zip: claimant.mailing_zip,
		mailing_country: claimant.mailing_country,
		physical_address: claimant.physical_address,
		physical_address_2: claimant.physical_address_2,
		physical_city: claimant.physical_city,
		physical_state: claimant.physical_state,
		physical_zip: claimant.physical_zip,
		physical_country: claimant.physical_country,
		mailing_same_as_physical: claimant.mailing_same_as_physical,
	};
	const [formData, setFormData] = useState(defaultState);
	const [previousFormData, setPreviousFormData] = useState(defaultState);
	const [isEditing] = useState(true);
	const [saveOnChangeForm] = useState(true);
	const [showLostContact, setShowLostContact] = useState(false);
	const [primaryRepModal, setPrimaryRepModal] = useState(false);
	const [userSearchModal, setUserSearchModal] = useState(false);

	const handleInputChange = (event) => {
		let { name, value, checked } = event.target;

		if (name === 'mailing_same_as_physical') {
			value = checked === 'on' ? true : false;
		}
		if (name === 'alt_contact_phone') {
			value = formatPhone(value);
		}
		if (name === 'ssn') {
			value = formatSSN(value);
		}
		setFormData({ ...formData, [name]: value });
	};


	// const handleEdit = () => {
	// 	setIsEditing(true);
	// 	setPreviousFormData(formData);
	// };

	// const handleCancel = () => {
	// 	setIsEditing(false);
	// 	setFormData(previousFormData);
	// };

	const saveClaimant = async (data) => {
		try {
			let result = await claimantAPI.updateClaimant(claimant.id, data);
			if (!result?.data) {
				throw new Error('No claimant data returned');
			}

			setClaimant(result.data);

			let updatedFormData = { ...formData, ...data };

			setFormData(updatedFormData);
			setPreviousFormData(updatedFormData);
		} catch (error) {
			console.error(error);
			toast.error(`${error}`, {
				theme: 'colored'
			});
		}
		// setIsEditing(false);
		setPreviousFormData(formData);
	};


	// Save on change
	const saveOnChange = (event) => {
		let { name, value, checked } = event.target;
		if (name == 'alt_contact_phone' && !isValidPhone(value)) {
			toast.warning('Please enter a valid phone number.', {
				theme: 'colored'
			});
		}

		if (name == 'ssn' && !isValidSSN(value)) {
			toast.warning('Please enter a valid social security number.', {
				theme: 'colored'
			});
		}

		if (name === 'mailing_same_as_physical') {
			value = checked;
		}

		setFormData({ ...formData, [name]: value });

		if (previousFormData[name] !== value) {
			saveClaimant({ [name]: value });
		}
	};

	// Handle user selction callback
	const handleUserSelect = (user) => {
		if (user?.id) {
			saveClaimant({ assigned_to: user.id });
			toast.success('User updated.', { theme: 'colored' });
		}
	};

	// Handle primary rep selction callback
	const handlePrimaryRepSelect = (user) => {
		if (user?.id) {
			saveClaimant({ primary_rep_id: user.id });
			toast.success('Primary rep updated.', { theme: 'colored' });
		}
	};


	return (
		<Card className="" >
			<Card.Header className="border-bottom">

				<Card.Title>
					ID: {claimant.id} - {claimant.first_name} {claimant.last_name}
				</Card.Title>
				<span className="fw-bold">Account: {siteAdminArea ? <Link to={`/admin/account-details/${claimant?.account_id}`}>{claimant?.account?.business_name}</Link> : claimant?.account?.business_name}</span>
				<br />
				<span>Created: {moment(claimant.createdAt).format('MM/DD/YYYY hh:mm A')}</span>
				<br />
				<span>Assigned User: {claimant?.assigned_user?.id
					?
					<>
						{`${claimant?.assigned_user?.first_name || ""} ${claimant?.assigned_user?.last_name || ""}`} {' '}
						<UserQuickFind
							requiredSearchParams={{ account_id: claimant?.account_id }}
							buttonVariant={'link'}
							buttonLabel={'Change User'}
							handleUserSelection={handleUserSelect}
						/>
					</>
					:
					<>
						<span className="text-danger">No user assigned</span> {' '}
						<UserQuickFind
							requiredSearchParams={{ account_id: claimant?.account_id }}
							buttonVariant={'link'}
							buttonLabel={'Select a User'}
							handleUserSelection={handleUserSelect}
						/>
					</>
				}
				</span>
				<br />
				<span>Primary Rep: {claimant?.primary_rep?.id
					?
					<>
						<span>{`${claimant?.primary_rep?.first_name || ""} ${claimant?.primary_rep?.last_name || ""}`} {' '}
							<Button variant={'link'} className="" onClick={() => { setPrimaryRepModal(true) }}>Change</Button>
						</span>
					</>
					:
					<>
						<span className="text-danger">No Rep Selected</span> {' '}
						<Button variant={'link'} className="" onClick={() => { setPrimaryRepModal(true) }}>Select a primary rep</Button>
					</>
				}
				</span>

				<br />


				<PrimaryRepSearch
					account_id={claimant?.account_id}
					modalState={primaryRepModal}
					setModalState={setPrimaryRepModal}
					primaryRepSelectionCallBack={handlePrimaryRepSelect}
				/>

				{
					claimant.established_contact == true
						?
						<>
							<Button variant="falcon-danger" onClick={() => setShowLostContact(true)}>Lost contact!</Button>
							{
								claimant?.claimant_locator_requests &&
								Array.isArray(claimant.claimant_locator_requests) &&
								claimant.claimant_locator_requests.filter(clr => clr.status === "SUBMITTED").map((r, index) => (
									<Alert variant="info" key={index}>
										<p>
											There is a pending lost contact request for this claimant with Assure Disability submitted {moment(r.createdAt).format('MM-DD-YYYY hh:mm A')}.
										</p>

									</Alert>
								))
							}
							<LostContact modalState={showLostContact} setModalState={setShowLostContact} claimant={claimant} setClaimant={setClaimant} />
						</>
						:
						<ClaimantConfirmContactInfo claimant={claimant} setClaimant={setClaimant} />
				}
			</Card.Header>
			<Card.Body>
				{/* <br />
				{claimant?.account?.business_name || ""}
			<br />*/}



				<Form className="px-3">
					<Row>
						<Col sm={4} className={'px-0'}>
							<Form.Group controlId="first_name">
								<FloatingLabel
									controlId="first_name"
									label="First Name"
									className=""
									size="sm"
								>

									<Form.Control
										size="sm"
										type="text"
										name="first_name"
										value={formData.first_name}
										onChange={handleInputChange}
										onBlur={saveOnChangeForm ? saveOnChange : () => { }}
										disabled={!isEditing}
									/>
								</FloatingLabel>
							</Form.Group>
						</Col>
						<Col sm={2} className={'px-0'}>
							<Form.Group controlId="middle_name">
								<FloatingLabel
									controlId="middle_name"
									label="M.I."
									className=""
									size="sm"
								>
									<Form.Control
										size="sm"
										type="text"
										name="middle_name"
										value={formData.middle_name}
										onChange={handleInputChange}
										onBlur={saveOnChangeForm ? saveOnChange : () => { }}
										disabled={!isEditing}
									/>
								</FloatingLabel>
							</Form.Group>
						</Col>
						<Col sm={4} className={'px-0'}>
							<Form.Group controlId="last_name">
								<FloatingLabel
									controlId="last_name"
									label="Last Name"
									className=""
									size="sm"
								>
									<Form.Control
										size="sm"
										type="text"
										name="last_name"
										value={formData.last_name}
										onChange={handleInputChange}
										onBlur={saveOnChangeForm ? saveOnChange : () => { }}
										disabled={!isEditing}
									/>
								</FloatingLabel>
							</Form.Group>
						</Col>
						<Col sm={2} className={'px-0'}>
							<Form.Group controlId="suffix">
								<FloatingLabel
									controlId="suffix"
									label="suffix"
									className=""
									size="sm"
								>
									<Form.Control
										size="sm"
										type="text"
										name="suffix"
										value={formData.suffix}
										onChange={handleInputChange}
										onBlur={saveOnChangeForm ? saveOnChange : () => { }}
										disabled={!isEditing}
									/>
								</FloatingLabel>
							</Form.Group>
						</Col>
					</Row>

					<Row>
						<Col sm={5} className={'px-0'}>
							<Form.Group controlId="ssn">
								<FloatingLabel
									controlId="ssn"
									label="SSN"
									className=""
									size="sm"
								>
									<Form.Control
										size="sm"
										type="text"
										name="ssn"
										value={formData.ssn}
										onChange={handleInputChange}
										onBlur={saveOnChangeForm ? saveOnChange : () => { }}
										disabled={!isEditing}
									/>
								</FloatingLabel>
							</Form.Group>
						</Col>
						<Col sm={5} className={'px-0'}>
							<Form.Group controlId="dob">
								<FloatingLabel
									controlId="dob"
									label="DOB"
									className=""
									size="sm"
								>
									<Form.Control
										size="sm"
										type="date"
										name="dob"
										value={formData.dob}
										onChange={handleInputChange}
										onBlur={saveOnChangeForm ? saveOnChange : () => { }}
										disabled={!isEditing}
									/>
								</FloatingLabel>
							</Form.Group>
						</Col>
						<Col className={'px-0'}>
							<Form.Group controlId="age">
								<FloatingLabel
									controlId="age"
									label="Age"
									className=""
									size="sm"
								>

									<Form.Control
										size="sm"
										name="age"
										value={getAgeFromBirthdate(formData.dob)}
										onChange={handleInputChange}
										className={""}
										onBlur={saveOnChangeForm ? saveOnChange : () => { }}
										disabled={true}
									/>
								</FloatingLabel>
							</Form.Group>
						</Col>
					</Row>

					<br />
					<Row>
						<Col sm={12} className={'px-0'}>
							<span className="mb-2 fw-bold">Mailing Address</span>
							<Form.Group controlId="mailing_address">
								<FloatingLabel
									controlId="mailing_address"
									label="Mailing Address"
									className=""
									size="sm"
								>
									<Form.Control
										size="sm"
										type="text"
										name="mailing_address"
										value={formData.mailing_address}
										onChange={handleInputChange}
										onBlur={saveOnChange}
									/>
								</FloatingLabel>
							</Form.Group>
						</Col>
						{/* <Col sm={3} className={'px-0'}>
						<Form.Group controlId="address_2">
							<FloatingLabel
								controlId="address_2"
								label="Address 2"
								className=""
								size="sm"
							>
								<Form.Control
									size="sm"
									type="text"
									name="address_2"
									value={formData.address_2}
									onChange={handleInputChange}
									onBlur={saveOnChange}
								/>
							</FloatingLabel>
						</Form.Group>
					</Col> */}
					</Row>
					<Row>
						<Col sm={6} className={'px-0'}>
							<Form.Group controlId="mailing_city">
								<FloatingLabel
									controlId="mailing_city"
									label="City"
									className=""
									size="sm"
								>
									<Form.Control
										size="sm"
										type="text"
										name="mailing_city"
										value={formData.mailing_city}
										onChange={handleInputChange}
										onBlur={saveOnChange}
									/>
								</FloatingLabel>
							</Form.Group>
						</Col>
						<Col sm={3} className={'px-0'}>
							<Form.Group controlId="mailing_state">
								<FloatingLabel
									controlId="mailing_state"
									label="State"
									className=""
									size="sm"
								>
									<Form.Select
										size="sm"
										as="select"
										name="mailing_state"
										value={formData.mailing_state}
										onChange={handleInputChange}
										onBlur={saveOnChange}
									>
										<option value=""></option>
										{
											Array.isArray(stateData) && stateData.map((state, index) => {
												return (
													<option key={index} value={state.abbreviation}>{state.abbreviation}</option>
												);
											})
										}
									</Form.Select>
								</FloatingLabel>
							</Form.Group>
						</Col>
						<Col sm={3} className={'px-0'}>
							<Form.Group>
								<FloatingLabel
									controlId="mailing_zip"
									label="Zip"
									className=""
									size="sm"
								>
									<Form.Control
										size="sm"
										type="text"
										name="mailing_zip"
										value={formData.mailing_zip}
										onChange={handleInputChange}
										onBlur={saveOnChange}
									/>
								</FloatingLabel>
							</Form.Group>
						</Col>
					</Row>

					<Row>
						<Col sm={12} className={'px-0 pt-2'}>
							{/* Ask if mailing is same as physical mailing_same_as_physical*/}
							<Form.Group controlId="mailing_same_as_physical">
								<Form.Check
									type="checkbox"
									label="Mailing Address is the same as Physical Address"
									name="mailing_same_as_physical"
									checked={formData.mailing_same_as_physical}
									onChange={saveOnChange}
								/>
							</Form.Group>
						</Col>
					</Row>

					{
						formData.mailing_same_as_physical &&
						<div>
							<Row>
								<Col sm={12} className={'px-0'}>
									<span className="mb-2 fw-bold">Physical Address</span>
									<Form.Group controlId="physical_address">
										<FloatingLabel
											controlId="physical_address"
											label="Physical Address"
											className=""
											size="sm"
										>
											<Form.Control
												size="sm"
												type="text"
												name="physical_address"
												value={formData.physical_address}
												onChange={handleInputChange}
												onBlur={saveOnChange}
											/>
										</FloatingLabel>
									</Form.Group>
								</Col>
								{/* <Col sm={3} className={'px-0'}>
						<Form.Group controlId="address_2">
							<FloatingLabel
								controlId="address_2"
								label="Address 2"
								className=""
								size="sm"
							>
								<Form.Control
									size="sm"
									type="text"
									name="address_2"
									value={formData.address_2}
									onChange={handleInputChange}
									onBlur={saveOnChange}
								/>
							</FloatingLabel>
						</Form.Group>
					</Col> */}
							</Row>
							<Row>
								<Col sm={6} className={'px-0'}>
									<Form.Group controlId="physical_city">
										<FloatingLabel
											controlId="physical_city"
											label="City"
											className=""
											size="sm"
										>
											<Form.Control
												size="sm"
												type="text"
												name="physical_city"
												value={formData.physical_city}
												onChange={handleInputChange}
												onBlur={saveOnChange}
											/>
										</FloatingLabel>
									</Form.Group>
								</Col>
								<Col sm={3} className={'px-0'}>
									<Form.Group controlId="physical_state">
										<FloatingLabel
											controlId="physical_state"
											label="State"
											className=""
											size="sm"
										>
											<Form.Select
												size="sm"
												as="select"
												name="physical_state"
												value={formData.physical_state}
												onChange={handleInputChange}
												onBlur={saveOnChange}
											>
												<option value=""></option>
												{
													Array.isArray(stateData) && stateData.map((state, index) => {
														return (
															<option key={index} value={state.abbreviation}>{state.abbreviation}</option>
														);
													})
												}
											</Form.Select>
										</FloatingLabel>
									</Form.Group>
								</Col>
								<Col sm={3} className={'px-0'}>
									<Form.Group>
										<FloatingLabel
											controlId="physical_zip"
											label="Zip"
											className=""
											size="sm"
										>
											<Form.Control
												size="sm"
												type="text"
												name="physical_zip"
												value={formData.physical_zip}
												onChange={handleInputChange}
												onBlur={saveOnChange}
											/>
										</FloatingLabel>
									</Form.Group>
								</Col>
							</Row>
						</div>
					}





					{/* <Row>
						<Col sm={12} className={'px-0'}>
							<Form.Group controlId="email">
								<FloatingLabel
									controlId="email"
									label="Email"
									className=""
									size="sm"
								>

									<Form.Control
										size="sm"
										type="email"
										name="email"
										value={formData.email}
										onChange={handleInputChange}
										className={""}
										onBlur={saveOnChangeForm ? saveOnChange : () => { }}
										disabled={!isEditing}
									/>
								</FloatingLabel>
							</Form.Group>
						</Col>
					</Row> */}
					<br />

					{/* <span className="fw-bold">Alternate Contact Information</span> */}
					<Row>
						<Col sm={12} className={'px-0'}>

							<span className="mb-2 fw-bold">Alternate Contact</span>
							<Form.Group controlId="alt_contact_name">
								<FloatingLabel
									controlId="alt_contact_name"
									label="Alternate Contact Name"
									className=""
									size="sm"
								>
									<Form.Control
										size="sm"
										name="alt_contact_name"
										value={formData.alt_contact_name}
										onChange={handleInputChange}
										className={""}
										onBlur={saveOnChangeForm ? saveOnChange : () => { }}
										disabled={!isEditing}
									/>
								</FloatingLabel>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Col sm={6} className={'px-0'}>
							<Form.Group controlId="alt_contact_phone">
								<FloatingLabel
									controlId="alt_contact_phone"
									label=" Phone"
									className=""
									size="sm"
								>
									<Form.Control
										size="sm"
										name="alt_contact_phone"
										value={formData.alt_contact_phone}
										onChange={handleInputChange}
										className={""}
										onBlur={saveOnChangeForm ? saveOnChange : () => { }}
										disabled={!isEditing}
									/>
								</FloatingLabel>
							</Form.Group>
						</Col>
						<Col sm={6} className={'px-0'}>
							<Form.Group controlId="alt_contact_relationship">
								<FloatingLabel
									controlId="alt_contact_relationship"
									label="Relationship"
									className=""
									size="sm"
								>
									<Form.Control
										size="sm"
										name="alt_contact_relationship"
										value={formData.alt_contact_relationship}
										onChange={handleInputChange}
										className={""}
										onBlur={saveOnChangeForm ? saveOnChange : () => { }}
										disabled={!isEditing}
									/>
								</FloatingLabel>
							</Form.Group>
						</Col>
					</Row>

					<Row>
						<Col sm={12} className={'px-0'}>
							<div className="my-3">
								<PhoneList
									entity_id={claimant.entity_id}
									refreshParent={(data) => {
										console.log(data)
										if (Array.isArray(data) && data.length > 0) {
											// Loop through and set claimant.primary_phone to the filtered data that has is_primary === true
											setClaimant({
												...claimant,
												primary_phone: data.filter((phone) => phone.is_primary === true)[0],
												entity: { ...claimant?.entity, contact_phones: data }
											});
										}
									}}
								/>
							</div>

							<div className="my-3">
								<EmailList
									entity_id={claimant.entity_id}
									refreshParent={(data) => {
										console.log(data)
										if (Array.isArray(data) && data.length > 0) {
											// Loop through and set claimant.primary_email to the filtered data that has is_primary === true
											setClaimant({
												...claimant,
												primary_email: data.filter((email) => email.is_primary === true)[0],
												entity: { ...claimant?.entity, contact_emails: data }
											});
										}
									}}
								/>
							</div>
						</Col>
					</Row>

					{/* {
						isEditing
							?
							<>
								<Button variant="primary" onClick={() => { saveClaimant(formData) }}>Save</Button>
								<Button variant="secondary" onClick={handleCancel}>cancel</Button>
							</>
							:
							<Button variant="primary" onClick={handleEdit}>Edit</Button>
					} */}
				</Form>
				{/* <div className="my-3">
					<AddressList entity_id={claimant.entity_id} />
				</div> */}



				<CustomFieldList
					account_id={claimant.account_id}
					model={"CLAIMANT"}
					entity_id={claimant.entity_id}
					view={'CLAIMANT_DETAILS'}
					options={{}}
					refreshParent={(data) => {
						refreshClaimant();
					}}
				/>

			</Card.Body>
		</Card>
	);
};



export default ClaimantProfileCard;