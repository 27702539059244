import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import AppContext from 'context/Context';
import MyFormEditor from './MyFormEditor';
import CreateMyForm from './CreateMyForm';


const MyFormEditorModal = ({
	modalState,
	setModalState,
	selectedForm,
	setSelectedForm = () => { },
	myFormData,
	setMyFormData,
	defaultNewFormValues = {},
	setDefaultNewFormValues = () => { },
	refreshParent = () => { },
	withNavigateToFormPage = true
}) => {
	const {
		config: { isDark }
	} = useContext(AppContext);

	// Get related data @TODO: Move this to a context
	// const getRelatedData = async () => {
	// };

	// Handle Show
	const handleShow = () => {
		document.querySelectorAll('div[role="dialog"]').forEach((el) => {
			el.setAttribute("tabindex", "unset");
		});
		document.querySelectorAll('.jodit-wysiwyg').forEach((el) => {
			el.focus();
		});
	};

	const handleClose = () => {
		setSelectedForm({});
		setModalState(false);
		setDefaultNewFormValues({});
	};

	// useEffect(() => {
	// 	if (modalState) {
	// 	}

	// }, [modalState]);

	return (
		<Modal
			show={modalState}
			onHide={handleClose}
			onShow={handleShow}
			contentClassName="border"
			size="lg"
			backdrop="static"
		>
			<Modal.Header
				closeButton
				closeVariant={isDark ? 'white' : undefined}
				className="bg-light px-card border-bottom-0"
			>
				<Modal.Title as="h5">Create Form Template</Modal.Title>
			</Modal.Header>
			<Modal.Body className="p-card">
				<CreateMyForm
					refreshParent={refreshParent}
					defaultNewFormValues={defaultNewFormValues}
					setDefaultNewFormValues={setDefaultNewFormValues}
					handleClose={handleClose}
					withNavigateToFormPage={withNavigateToFormPage}
				/>
			</Modal.Body>
		</Modal>
	);
};

MyFormEditorModal.propTypes = {
	modalState: PropTypes.bool,
	setModalState: PropTypes.func,
	selectedForm: PropTypes.object,
	setSelectedForm: PropTypes.func,
	myFormData: PropTypes.array,
	setMyFormData: PropTypes.func,
	refreshParent: PropTypes.func
}

export default MyFormEditorModal;