import React from 'react';
import DocumentTable from 'components/documents/DocumentTableOld';
import PDFJSWebviewer from 'components/app/pdfjs-viewer/PDFViewer';
import { Modal, Button } from 'react-bootstrap';
// import DocViewer, { DocViewerRenderers } from "react-doc-viewer";

const PDFViewer = ({ modalState, setModalState = () => { }, setSelectedFileId = () => { }, selectedFileId }) => {

	// Handle Close Modal
	const handleClose = () => {
		setModalState(false);
		setSelectedFileId(null);
	};

	// const file = '/files/fulcrum-design-doc.docx'
	// const type = 'docx'
	// let url = URL.createObjectURL(new Blob(['files/fulcrum-terms-of-service.pdf'], { type: 'application/pdf' }));

	// const docs = [
	// 	// { uri: "https://www.ssa.gov/forms/ssa-1696.pdf" },
	// 	// { uri: require('../../../IncludedCampID.PNG').default },
	// ];

	return (
		<Modal show={modalState} fullscreen={true} onHide={handleClose}>
			<Modal.Header closeButton>
				<Modal.Title>File Viewer</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{/* 
				<iframe src="https://docs.google.com/gview?url=https://www.ssa.gov/forms/ssa-1696.pdf&embedded=true" style={{ width: '100%', height: '100%' }}>
				</iframe> */}
				{/* <DocViewer
					pluginRenderers={DocViewerRenderers}
					documents={docs}
					config={{
						header: {
							disableHeader: false,
							disableFileName: false,
							retainURLParams: false
						}
					}}
					style={{ height: 500 }}
				/> */}
				{/* <iframe className="" src="https://docs.google.com/gview?url=http://ieee802.org/secmail/docIZSEwEqHFr.doc&embedded=true" frameborder="0">
				</iframe> */}
				{/* <PDFJSWebviewer fileId={selectedFileId} /> */}
				<PDFJSWebviewer fileId={selectedFileId} />

				{/* <FileViewer
					fileType={type}
					filePath={file}
					// errorComponent={CustomErrorComponent}
					onError={() => { }} /> */}
			</Modal.Body>
		</Modal>
	);
};

export default PDFViewer;