import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { phoneAPI } from 'utils/api/fulcrum-api';
import { formatPhone, isValidPhone } from 'utils/functions';
import Dropdown from 'react-bootstrap/Dropdown';
import { Form } from 'react-bootstrap';
import CardDropdown from 'components/common/CardDropdown';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import AppContext from 'context/Context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone } from '@fortawesome/free-solid-svg-icons'

const PhoneRow = ({ refreshParent = () => { }, phone, phones, setPhones, getPhoneList = () => { } }) => {
  const [loading, setLoading] = useState(false);
  const {
    lookups
  } = useContext(AppContext);
  const defaultFormData = {
    phone_type_id: phone.phone_type_id || '',
    phone: formatPhone(phone?.phone || ''),
    extension: phone?.extension || '',
    note: phone.note || '',
    is_primary: phone?.is_primary || false
  }

  const [formData, setFormData] = useState(defaultFormData);
  const [previousFormData, setPreviousFormData] = useState(defaultFormData);

  const handleInputChange = (event) => {
    let { name, value } = event.target;
    if (name === 'is_primary') {
      value = !formData[name];
    }
    if (name == "phone") {
      value = formatPhone(value);
    }

    setFormData({ ...formData, [name]: value });
  };

  // Toggle primary phone
  const togglePrimaryPhone = async (newPrimaryId, withListRefresh = false) => {
    try {
      let result = await phoneAPI.togglePrimaryPhone(newPrimaryId, phone.entity_id);
      if (!result?.message) {
        throw new Error('Unable to update primary phone.');
      } else if (result?.message === 'You must have at least one primary contact phone number.') {
        throw new Error(result.message);
      }
    } catch (error) {
      console.error(error);
      toast.error(`${error}`, {
        theme: 'colored'
      });
    }

    getPhoneList();
  };

  // Update phone
  const updatePhone = async (data) => {
    try {
      let result = await phoneAPI.updatePhone(phone.id, data);
      if (!result?.data) {
        throw new Error('Unable to update the phone record.');
      }
      setPreviousFormData({
        ...formData,
        ...data
      });

      getPhoneList();
    } catch (error) {
      setFormData(previousFormData);
      toast.error(error.message, {
        theme: 'colored'
      });
    }
  };

  // Handle softphone dial click
  const handleSoftphoneDialClick = () => {
    alert('Dialing...');
  };

  // Save on change
  const saveOnChange = async (event) => {
    let { name, value } = event.target;
    if (name == "phone" && !isValidPhone(value)) {
      toast.warning('Please enter a valid phone number.', {
        theme: 'colored'
      });
    }

    if (['is_primary'].includes(name)) {
      value = !formData[name];
      // let withRefresh = value ? true : false;
      let phoneId = value ? phone.id : null;
      return await togglePrimaryPhone(phoneId, true);
    }

    setFormData({ ...formData, [name]: value });

    if (previousFormData[name] !== value) {
      await updatePhone({ [name]: value });
    }
  };

  // Delete phone
  const deletePhone = async (phoneId) => {
    Swal.fire({
      title: 'Permanently delete phone?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          let result = await phoneAPI.deletePhone(phoneId);
          if (!result?.message) {
            if (result?.response?.error) {
              throw new Error(result.response.error);
            }
            throw new Error('Unable to delete the phone record.');
          }
          let newPhones = phones.filter(p => p.id !== phoneId);
          setPhones(newPhones);
          refreshParent(newPhones);
          toast.warning(`Phone deleted successfully.`, {
            theme: 'colored'
          });
        } catch (error) {
          console.error(error);
          toast.error(`${error}`, {
            theme: 'colored'
          });
        }
      }
    });
  };


  useEffect(() => {
    setLoading(true);
    const updatedFormData = {
      phone_type_id: phone.phone_type_id || '',
      phone: formatPhone(phone?.phone || ''),
      extension: phone?.extension || '',
      note: phone.note || '',
      is_primary: phone?.is_primary || false
    }
    setFormData(updatedFormData);
    setPreviousFormData(updatedFormData);
    setLoading(false);
  }, [phone, phone?.is_primary]);

  if (loading) {
    return (<p>Loading...</p>)
  }


  return (

    <tr key={phone.id} className="mb-1">
      <td className="px-1 py-1 text-center">
        {/* <FontAwesomeIcon icon={faPhone} className="text-twitter cursor-pointer" onClick={() => { handleSoftphoneDialClick(phone) }} /> */}
        {" "}&nbsp;
        <div className="d-inline">
          <Form.Check
            size="sm"
            type="checkbox"
            name='is_primary'
            checked={formData.is_primary}
            onChange={e => { handleInputChange(e); saveOnChange(e); }}
            className={`text-center ${formData.is_primary !== previousFormData.is_primary && ''} d-inline`}
          />
        </div>

      </td>

      <td className="px-0 py-1">
        <Form.Select
          size="sm"
          as="select"
          aria-label="Default select example"
          value={formData.phone_type_id}
          name='phone_type_id'
          onChange={e => saveOnChange(e)}
          // onBlur={e => saveOnChange(e)}
          className={` form-select ${formData.phone_type_id !== previousFormData.phone_type_id && ''}`}
        >
          {
            lookups.phoneTypes.map((phoneType, index) => (
              <option key={index} value={phoneType.id}>{phoneType.type}</option>
            ))
          }
        </Form.Select>
      </td>

      <td className="px-0 py-1">
        <Form.Control
          size="sm"
          type="text"
          // placeholder="Enter phone number" 
          value={formData.phone}
          name='phone'
          onChange={handleInputChange}
          onBlur={e => saveOnChange(e)}
          className={` ${formData.phone !== previousFormData.phone && ''}`}
        />
      </td>

      {/* <td className="px-0 py-1">
				<Form.Control
					size="sm"
					type="text"
					// placeholder="Enter phone extension" 
					value={formData.extension}
					name='extension'
					onChange={handleInputChange}
					onBlur={e => saveOnChange(e)}
					className={`pl-0 pr-0 ${formData.extension !== previousFormData.extension && ''}`}
				/>
			</td> */}

      {/* <td className="px-0 py-1">
        <Form.Control
          size="sm"
          type="text"
          // placeholder="Enter phone notes" 
          value={formData.note}
          name='note'
          onChange={handleInputChange}
          onBlur={e => saveOnChange(e)}
          className={` ${formData.note !== previousFormData.note && ''}`}
        />
      </td> */}

      <td className="px-0 py-1">
        <CardDropdown>
          <div className="py-2">
            <Dropdown.Item onClick={() => { deletePhone(phone.id) }} className="text-danger">Delete</Dropdown.Item>
          </div>
        </CardDropdown>
      </td>
    </tr>
  );
};

PhoneRow.propTypes = {
  phone: PropTypes.object,
  phones: PropTypes.array,
  setPhones: PropTypes.func,
  getPhoneList: PropTypes.func,
}

export default PhoneRow;