import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Card, Alert, Button, Form, Spinner } from 'react-bootstrap';
import moment from 'moment';
import { accessVerificationAPI, myFormsAPI } from 'utils/api/fulcrum-api';
import { getClientDigitalInformation } from '../../../utils/ipify';
import PDFJSWebviewer from 'components/app/pdfjs-viewer/PDFViewer';

const PDFViewerLayout = ({ defaultUUID, myFormResponseId } = {}) => {
	let { uuid } = useParams();
	uuid = defaultUUID || uuid;
	const [accessVerification] = useState({});
	const [viewerState, setViewerState] = useState(null);
	const [expiredLink, setExpiredLink] = useState(false);
	const [loading, setLoading] = useState(true);
	const [myFormData, setMyFormData] = useState({});
	const [xfdf, setXfdf] = useState(``);
	const [isValid, setIsValid] = useState(true);
	const [warningMessages, setWarningMessages] = useState([]);
	const [signatureFieldCount, setSignatureFieldCount] = useState(0);
	const [documentLoading, setDocumentLoading] = useState(true);
	const [loadingMessage, setLoadingMessage] = useState('Loading Document...');
	const [submitting, setSubmitting] = useState(false);
	const [formCompleted, setFormCompleted] = useState(false);
	const [digitalConsent, setDigitalConsent] = useState(false);

	/**
	 * generateAnnotationImage
	 * @param {*} annotation 
	 * @param {integer} paddingSize 
	 * @param {*} instance 
	 * @param {integer} stackMultiplier - How many times to stack the image 
	 * @returns 
	 */
	function generateAnnotationImage(annotation, paddingSize, instance, stackMultiplier = 4) {
		paddingSize = paddingSize || 0;
		const canvas = document.createElement('canvas');
		canvas.width = annotation.Width + (2 * paddingSize);
		canvas.height = annotation.Height + (2 * paddingSize);

		// Set the canvas context to the annotation's matrix 
		const ctx = canvas.getContext('2d');
		ctx.translate(-annotation.X + paddingSize, -annotation.Y + paddingSize);
		ctx.imageSmoothingEnabled = true;

		// Draw the annotation signature on the canvas 
		const pageMatrix = instance.docViewer.getDocument().getPageMatrix(annotation.PageNumber);
		annotation.draw(ctx, pageMatrix);

		// Draw the image on the final canvas multiple times for better quality 
		if (stackMultiplier > 1) {
			// stackMultiplier - 1 because the first image is already drawn on the canvas (ctx) above 
			const iterationLoop = [...Array(stackMultiplier - 1).keys()]; // [0, 1]
			iterationLoop.forEach((i) => {
				ctx.drawImage(canvas, 0, 0);
			});
		}

		return canvas.toDataURL("image/png");
	};

	// Set Form Fields
	const setFormFields = (field, value) => {

	};

	// Form Validation
	const formValidation = async () => {
		let newWarningMessages = [];
		const { Core, Annotations } = viewerState;
		const { annotationManager } = Core;
		let hasScrolled = false;

		let pass = true;

		if (!digitalConsent) {
			pass = false;
			newWarningMessages.push([...newWarningMessages, 'Please check the electronic signature consent box.']);
		}

		// Loop through signature fields
		const signatureWidgetAnnots = annotationManager.getAnnotationsList().filter(
			annot => annot instanceof Annotations.SignatureWidgetAnnotation
		);

		signatureWidgetAnnots.forEach((annot) => {
			let isSigned = annot?.Bz ? true : false;
			if (!isSigned) {
				pass = false;
				if (!newWarningMessages.includes('Please sign all signature fields.')) {
					newWarningMessages.push([...newWarningMessages, 'Please sign all signature fields.']);
				}

				if (!hasScrolled) {
					scrollToSignatureField(annot.PageNumber);
					hasScrolled = true;
				}
			}
		});

		// Loop through required fields
		// fieldManager.forEachField(async field => {
		// 	if (field?.Ve[0] && field.Ve[0] instanceof Annotations.SignatureWidgetAnnotation) {
		// 		annots.forEach(async (annot) => {
		// 			alert()
		// 			if (annot?.Qa?.rd === field.rd) {
		// 				console.log(`${annot?.Qa?.rd} === ${field.rd}`);
		// 				console.log(annot)
		// 				let base64 = generateAnnotationImage(annot?.Bz || [], 0, viewerState);
		// 				adjustedFieldData[field.rd] = base64;
		// 				adjustedFieldData[`${[field.rd]}_af_image`] = base64;
		// 			}
		// 		});
		// 	} else {
		// 		adjustedFieldData[field.rd] = field.getValue();
		// 	}
		// });

		setIsValid(pass);
		setWarningMessages(newWarningMessages);
		return pass;
	};

	// Get my forms data
	const getMyFormsData = async () => {
		try {
			setLoadingMessage('Loading Document...');
			let response = await myFormsAPI.getMyFormResponseByUUID(uuid, myFormResponseId);
			if (!response?.data) {
				throw new Error('Unable to find the My Form using the provided uuid.');
			}

			// Check status of the my form response
			const status = response?.data?.status;
			let responseData = {};
			if (status === 'COMPLETED') {
				setFormCompleted(true);
				setDocumentLoading(false);
			} else {
				responseData = response.data?.response_data ? JSON.parse(response.data.response_data) : {};
				setSignatureFieldCount(responseData?.fieldData?.filter(field => field?.field?.includes("claimant_signature")).length);
				setXfdf(responseData?.xfdf || '');
				setDigitalConsent("electronic_signature_consent" in response.data ? response.data.electronic_signature_consent : false);
			}
			setMyFormData({ ...response.data, response_data: { ...responseData } });

			setLoading(false);
		} catch (error) {
			console.log(error);
		}
	};

	// Scroll to Signature Field
	const scrollToSignatureField = async (pageNumber) => {
		const { Core } = viewerState;
		const { documentViewer } = Core;

		documentViewer.setCurrentPage(pageNumber);
	};

	// Get document data
	const handleDocumentSave = async (data) => {
		try {
			setSubmitting(true);
			setLoadingMessage('Submitting Document...');
			setDocumentLoading(true);

			// Form Validation
			let pass = await formValidation();
			if (!pass) { throw new Error('Form Validation Failed'); }
			const { Core, Annotations } = viewerState;
			const { documentViewer, annotationManager } = Core;
			const fieldManager = annotationManager.getFieldManager();
			let fieldData = myFormData?.response_data?.fieldData || [];
			const xfdf = await annotationManager.exportAnnotations();
			const annots = annotationManager.getAnnotationsList();
			const adjustedFields = {};
			let renameTypeFields = {
				Tx: 'text',
				Btn: 'button',
				Ch: 'checkbox',
				Sig: 'signature',
			};

			// Update original field data with new values
			fieldManager.forEachField(async field => {
				let key = field.name || field.rd;
				let input_type = field.type in renameTypeFields ? renameTypeFields[field.type] : field.type || field.Ar;

				if (field?.Ve[0] && field.Ve[0] instanceof Annotations.SignatureWidgetAnnotation) {
					let base64 = null;
					annots.forEach(async (annot) => {
						if (annot?.Qa?.rd === key) {
							if (annot?.Bz?.ToolName === "AnnotationCreateFreeHand") {
								base64 = generateAnnotationImage(annot?.Bz || [], 0, viewerState, 4);
								adjustedFields[key] = base64;
							} else if (annot?.Bz?.ToolName === "AnnotationCreateRubberStamp") {
								base64 = annot?.Bz?.image.src;
							}

							console.log("annot", annot);
							adjustedFields[key] = {
								key,
								value: base64,
								input_type,
								page: annot?.Bz?.PageNumber,
								rect: {
									x: annot?.Bz?.X,
									y: annot?.Bz?.Y,
									width: annot?.Bz?.Width,
									height: annot?.Bz?.Height,
								},
							};
						}
					});
				} else {
					// All other fields
					adjustedFields[key] = {
						key,
						value: field.value || field.getValue(),
						input_type,
					};
				}
			});

			// Loop through and adjust field data
			let newFieldData = [];
			for (let i = 0; i < fieldData.length; i++) {
				let field = fieldData[i];
				if (adjustedFields[field.field]) {
					let adjustedField = adjustedFields[field.field];
					field = { ...field, ...adjustedField };
				}

				newFieldData.push(field);
			}

			let clientDeviceInformation = await getClientDigitalInformation();
			let savedPDFFormResponse = await myFormsAPI.savePDFFormData(myFormData.id, { fieldData: newFieldData, adjustedFields, xfdf, digitalConsent, clientDeviceInformation });
			if (!savedPDFFormResponse?.data) { throw new Error('Unable to save form data'); }
			setMyFormData(savedPDFFormResponse.data);
			setFormCompleted(true);
		} catch (error) {
			console.log(error);
		}
		setDocumentLoading(false);
		setSubmitting(false);
	};

	// Handle Loading Modal Close
	const handleModalClose = () => {
		setDocumentLoading(false);
	};

	// Handle Modal Open	
	const handleModalOpen = () => {
		setDocumentLoading(true);
	};

	// Lookup Aceess Verification by UUID
	const getAccessVerification = async () => {
		try {
			let response = await accessVerificationAPI.getAccessVerification(uuid);
			if (!response?.data) {
				throw new Error('Unable to find the Access Verification using the provided uuid.');
			}

		} catch (error) {
			setExpiredLink(true);
			console.log(error);
		}
		setLoading(false);
	};

	useEffect(() => {
		getMyFormsData();
	}, []);

	return (
		<div className="vh-100">
			{
				loading
					?
					<div>Loading...</div>
					:
					(
						expiredLink
							?
							<div>
								<h1>Expired Link</h1>
							</div>
							:
							formCompleted
								?
								<div className='d-flex'>
									<Card className="mx-auto text-center justify-content-center py-5 px-3 my-5" style={{ width: "50%" }}>
										<Card.Header className="border-bottom">
											<h2 className="text-success">Document Submitted!</h2>
										</Card.Header>
										<Card.Body>
											<p>Submitted: {myFormData?.submitted_timestamp && moment(myFormData.submitted_timestamp).format('MM/DD/YYYY hh:mm A')}</p>
											<p>Thank you for your submission! Your firm will review your document and reach out if any further action is needed.</p>
										</Card.Body>
									</Card>
								</div>
								:
								<Card className=" PDFJSWebviewer vh-100">
									<div className="px-3 pt-3 pb-2" style={{ backgroundColor: "#025cb9", color: "#FFFFFF" }}>
										<h4 className="d-inline" style={{ color: '#FFFFFF' }}>Review and Submit</h4>
										<Button
											variant={'success'}
											onClick={handleDocumentSave}
											className="float-end"
											size="lg"
											disabled={documentLoading || loading || submitting}
										>Submit Document</Button>
									</div>
									<div className="px-3 pt-3 pb-2" style={{ backgroundColor: '#FFFFFF' }}>
										<Form.Check
											onChange={e => { setDigitalConsent(e.target.checked); }}
											type='checkbox'
											disabled={documentLoading || loading || submitting}
											id='electronic_signature_consent'
											label='I consent to signing this document and conducting business electronically.'
											className={`fs-2 fw-bold align-middle ${myFormData?.electronic_signature_consent ? 'text-success' : 'text-danger'}`}
										/>
										{/* <button onClick={() => { scrollToSignatureField() }}>Scroll</button> */}
										{
											warningMessages.length > 0 &&
											<Alert variant={"warning"}>
												<ul>

													{
														warningMessages.length > 0 && warningMessages.map((message, index) => (
															<li key={index}>{message}</li>
														))
													}
												</ul>
											</Alert>
										}
									</div>

									{
										documentLoading &&
										<div style={{
											"position": "absolute",
											"width": "100%",
											"height": "100%",
											backgroundColor: "#FFFFFF",
											"opacity": "0.7",
										}}>
											<div className={'text-center opacity-75 d-flex'}>
												<div className="justify-content-center m-auto" style={{ width: "50%" }}>
													<h5>{loadingMessage}</h5>
													<Spinner animation="border" role="status"> <span className="visually-hidden">Loading...</span> </Spinner>
												</div>
											</div>
										</div>
									}

									<PDFJSWebviewer
										fileId={myFormData?.id || null}
										type={'my-form'}
										xfdf={xfdf}
										setXfdf={setXfdf}
										handleDocumentSave={handleDocumentSave}
										showSubmitButton={true}
										disabledSubmitButton={!isValid}
										setViewerState={setViewerState}
										viewerState={viewerState}
										onDocumentLoadCallBack={handleModalClose}
										onAnnotationChangeCallBack={setFormFields}
										onFieldChangeCallBack={setFormFields}
									/>

									{/* <Modal show={documentLoading} fullscreen={true} onClose={handleModalClose} onShow={handleModalOpen} className="opacity-75">
										<Modal.Body className={'text-center opacity-75 d-flex'}>
											<div className="justify-content-center m-auto" style={{ width: "50%" }}>
												<h5>{loadingMessage}</h5>
												<Spinner animation="border" role="status"> <span className="visually-hidden">Loading...</span> </Spinner>
											</div>
										</Modal.Body>
									</Modal> */}

								</Card>
					)
			}
		</div>
	);
};

PDFViewerLayout.propTypes = {
	defaultUUID: PropTypes.string,
};

export default PDFViewerLayout;