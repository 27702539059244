import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Row, Col, Form, Card, Button } from 'react-bootstrap';
import { claimantDetailsAPI, accessVerificationAPI } from 'utils/api/fulcrum-api.js';
import ClaimantProfileBio from './bio/ClaimantProfileBio';
import ClaimantProfileDetails from './ClaimantProfileDetails';
import Swal from 'sweetalert2';

const ClaimantDetails = () => {
  const [slide, setSlide] = useState(0); // 0 = loading, 1 = verification, 2 = details, 3 = Expired/Denied, 4 = completed 
  let { id: uuid } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [accessKey, setAccessKey] = useState('');
  const [error, setError] = useState('');
  const [claimant, setClaimant] = useState({});
  const [allowAccess, setAllowAccess] = useState(false);
  const [claimantId, setClaimantId] = useState(null);
  const [verifying, setVerifying] = useState(false);
  const [verified, setVerified] = useState(false);

  const [formData, setFormData] = useState({
    dob: '',
  });

  const verifyAccessVerificationForClaimantDetails = async (e) => {

    if (e) e.preventDefault();
    getClaimant(claimantId);
    setVerified(true);
    return;
    setVerifying(true);

    try {
      let result = await claimantDetailsAPI.verifyAccessVerificationForClaimantDetails(uuid, claimantId, {
        ...formData,
      });
      if (!result?.data) {
        if (result?.error === "Access denied. Link already used.") {
          setVerifying(false);
          return setSlide(4);
        } else {
          throw new Error('Could not verify access verification for claimant details.');
        }
      }

      // Get Claimant Details
      setVerified(true);
      getClaimant(claimantId);
    } catch (error) {
      setSlide(6);
      setAllowAccess(false);
      console.log(error);
    }
    setVerifying(false);
  };

  const getClaimant = async (id) => {
    try {
      let result = await claimantDetailsAPI.getClaimantDetails(uuid, id);
      if (!result?.data) {
        throw new Error('Could not get claimant details.');
      }
      setClaimant(result.data);
      setAllowAccess(true);
      setSlide(2);

      Swal.fire({
        title: 'Access Granted!',
        text: 'You have been granted access to this claimant profile.',
        icon: 'success',
        confirmButtonText: 'Continue',
      });

    } catch (error) {
      setAllowAccess(false);
      setSlide(3);
      console.log(error);
    }
    setLoading(false);
  };

  const init = async () => {
    try {
      // access verification
      let getAccessVerificationResult = await accessVerificationAPI.getAccessVerification(uuid);
      if (getAccessVerificationResult?.error) {
        setError(getAccessVerificationResult.error);
      }

      let { entity_id } = getAccessVerificationResult?.data || {};

      if (!entity_id) {
        // Set allow access to false
        setAllowAccess(false);
        setSlide(3);
        setLoading(false);
      } else {
        // derive claimant id from entity_id
        let claimant_id = entity_id.split('_').pop();
        // get claimant details
        setClaimantId(claimant_id);
        setSlide(1); // Verification Needed
      }
    } catch (error) {
      setSlide(3); // Access Denied
      setAllowAccess(false);
      setLoading(false);
    }
  }





  // Render content by slide
  const renderContent = () => {
    switch (slide) {
      case 0:
        return (
          <div className="m-5">
            <div>Loading...</div>
          </div>
        );
      case 1:
        return (
          <div className="m-5">
            <Card style={{ maxWidth: '500px' }} className="mx-auto">
              <Card.Header>
                <Card.Title>Access Verification Required</Card.Title>
              </Card.Header>
              <Card.Body>
                <p>Enter the information below to grant access to this claimant profile.</p>

                <Form onSubmit={verifyAccessVerificationForClaimantDetails}>
                  <Form.Group controlId="dob">
                    <Form.Label>Date of Birth</Form.Label>
                    <Form.Control type="date" name="dob" value={formData.dob} onChange={e => { setFormData({ ...formData, dob: e.target.value }) }} disabled={verifying} />
                  </Form.Group>
                  <div className="my-3">
                    {
                      verifying ?
                        <Button variant="primary" type="submit" disabled>
                          Verifying...
                        </Button>
                        :
                        <Button variant="primary" type="submit">
                          Verify
                        </Button>
                    }
                  </div>
                </Form>
              </Card.Body>
            </Card>
          </div>
        );
      case 2:
        return (
          <div className="m-5">
            {
              loading || !claimant?.id
                ?
                <div>Loading...</div>
                :
                (
                  !allowAccess
                    ?
                    <div className="m-5">
                      <div>Access denied.</div>
                    </div>
                    :
                    <div>
                      <Row>
                        <Col>
                          <Card className="mb-4">
                            <Card.Body>
                              <Card.Title>Claimant Details</Card.Title>
                              <p>Your law firm has </p>
                            </Card.Body>
                          </Card>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={4}>
                          <ClaimantProfileDetails uuid={uuid} claimant={claimant} setClaimant={setClaimant} />
                        </Col>

                        <Col>
                          <Card>
                            <Card.Body>
                              <Card.Title>Claimant Bio</Card.Title>
                              <ClaimantProfileBio uuid={uuid} claimant={claimant} setClaimant={setClaimant} />
                            </Card.Body>
                          </Card>
                        </Col>
                      </Row>
                    </div>
                )
            }
          </div>
        );
      case 3:
        return (
          <div className="m-5">
            <Card style={{ maxWidth: '500px' }} className="mx-auto">
              <Card.Header>
                <Card.Title>Invalid or Expired Link</Card.Title>
              </Card.Header>
              <Card.Body>
                <p>You do not have access to this claimant's profile. Either your link has expired or you do not have access to this claimant's profile.</p>
              </Card.Body>
            </Card>
          </div>
        );
      case 4:
        return (
          <div className="m-5">
            <Card style={{ maxWidth: '500px' }} className="mx-auto">
              <Card.Header>
                <Card.Title>Completed</Card.Title>
              </Card.Header>
              <Card.Body>
                <p>You have successfully completed reviewing profile. If you need more access, please reach out to your firm for assistance.</p>
              </Card.Body>
            </Card>
          </div>
        );
      default:
        return (
          <div className="m-5">
            <Card style={{ maxWidth: '500px' }} className="mx-auto">
              <Card.Header>
                <Card.Title>Access Denied</Card.Title>
              </Card.Header>
              <Card.Body>
                <p>You do not have access to this claimant's profile. Either your link has expired or you do not have access to this claimant's profile. Please reach out to your firm for assisance.</p>
              </Card.Body>
            </Card>
          </div>
        );
    }
  }

  useEffect(() => { }, [slide]);

  useEffect(() => {
    init();
  }, [uuid]);

  // if (!allowAccess) {
  //   return (
  //     <div className="m-5">
  //       <div>Access denied.</div>
  //     </div>
  //   );
  // }

  return (
    <div className="">
      {renderContent()}
    </div>
  );
};

export default ClaimantDetails;