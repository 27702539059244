import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import PDFViewerPreview from 'components/app/pdfjs-viewer/PDFViewerPreview';
const defaultHeaderButtons = ['resetButton', 'infoButton', 'printButton', 'downloadZipButton', 'downloadPdfButton'];

const PDFViewerPreviewModal = ({ modalState, setModalState = () => { }, selectedClaimants = [], headerButtons = defaultHeaderButtons,
	pdf_template = '', claimant_id, user_id, myFormResponseId, ...props
} = {}) => {
	const [selectedClaimantIndex, setSelectedClaimantIndex] = useState(0); // Toggle between selectedClaimants index records

	const viewerProps = {
		headerButtons,
		pdf_template,
		claimant_id: selectedClaimants[selectedClaimantIndex]?.id || claimant_id,
		user_id,
		myFormResponseId,
	};

	const handleClose = () => {
		setSelectedClaimantIndex(0);
		setModalState(false);
	};

	const handleShow = async () => {
		if (!pdf_template && !myFormResponseId) {
			toast.error('Error getting My Form content.', { theme: 'colored' });
			handleClose();
			return;
		}
		setSelectedClaimantIndex(0);
		setModalState(true);
	};

	return (<Modal
		size="xl"
		backdrop="static"
		fullscreen={true}
		onHide={handleClose}
		onShow={handleShow}
		show={modalState}
		{...props}
	>
		<Modal.Header style={{ position: "absolute", height: "40px", top: 0, right: 0, zIndex: 1 }} closeButton></Modal.Header>
		<Modal.Body className="p-0">
			{modalState && viewerProps.pdf_template && (<PDFViewerPreview {...viewerProps} >
				{selectedClaimants.length > 1 ? (
					<div className="w-100 d-flex justify-content-center align-items-center bg-300 py-2">
						<Button variant="outline-primary" size="sm"
							disabled={selectedClaimantIndex === 0}
							onClick={() => setSelectedClaimantIndex(selectedClaimantIndex - 1)}
						>Previous</Button>

						<span className="fw-bold px-3">{pdf_template} for {selectedClaimants[selectedClaimantIndex]?.first_name} {selectedClaimants[selectedClaimantIndex]?.last_name}</span>

						<Button variant="outline-primary" size="sm" className="ml-2"
							disabled={selectedClaimantIndex === selectedClaimants.length - 1}
							onClick={() => setSelectedClaimantIndex(selectedClaimantIndex + 1)}
						>Next</Button>
					</div>) : selectedClaimants.length === 1 ? (<div className="w-100 d-flex justify-content-center align-items-center bg-300 py-2">
						<span className="fw-bold px-3">Preview {pdf_template} for {selectedClaimants[selectedClaimantIndex]?.first_name} {selectedClaimants[selectedClaimantIndex]?.last_name}</span>
					</div>) : <div className="w-100 d-flex justify-content-center align-items-center bg-300 py-2">
					<span className="fw-bold px-3">Preview {pdf_template}</span>
				</div>}
			</PDFViewerPreview>)}
		</Modal.Body>
	</Modal>);
};

PDFViewerPreviewModal.propTypes = {
	modalState: PropTypes.bool,
	setModalState: PropTypes.func,
	selectedClaimants: PropTypes.array,
	headerButtons: PropTypes.array,
	pdf_template: PropTypes.string,
	claimant_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	user_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	myFormResponseId: PropTypes.integer,
};

export default PDFViewerPreviewModal;