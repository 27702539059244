import React, { useState, useContext, useRef, useCallback } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import { documentAPI, taskAPI, caseAPI } from 'utils/api/fulcrum-api';
import { toast } from 'react-toastify';
import LeadProgressTool from '../LeadProgressTool';
import Swal from 'sweetalert2';
import AppContext from 'context/Context';
import cloudUpload from 'assets/img/icons/cloud-upload.svg';
import moment from 'moment';
import { useDropzone } from 'react-dropzone';
import Flex from 'components/common/Flex';


const RecordAdministrationDecision = ({ modalState, setModalState = () => { }, claimant, setClaimant, selectedCase, setSelectedCase = () => { }, refreshParent = () => { }, getClaimantCase = () => { } }) => {
	// const [modalState, setModalState] = useState(false);

	const [selectedFiles, setSelectedFiles] = useState([]);
	const onDrop = useCallback(acceptedFiles => {
		setSelectedFiles([...selectedFiles, ...acceptedFiles])
	}, [selectedFiles])
	const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
		onDrop,
	})
	const [isUploading, setIsUploading] = useState(false);
	const files = selectedFiles.map(file => (
		<li key={file.path}>
			{file.path} - {file.size} bytes
		</li>
	));
	const {
		config: { isDark }
	} = useContext(AppContext);
	const formRef = useRef(null);

	const removeAll = () => {
		setSelectedFiles([])
	}


	const defaultFormData = {
		document_type_id: null,
		document_status_id: null,
		title: '',
		description: '',
		due_date: null,
		file: null,
		disposition: null
	};
	const [formData, setFormData] = useState(defaultFormData);

	// Handle form change 
	const handleChange = async e => {
		let { name, value } = e.target;

		setFormData({
			...formData,
			[name]: value
		});
	};

	// Handle Confirm SSA Application Button Click
	const handleSubmit = async e => {
		e.preventDefault();

		if (!formData?.document_type_id) {
			toast.error('Please select a document type.');
			return;
		}

		setIsUploading(true);

		let formDataObject = new FormData(formRef.current);

		try {
			for (let i = 0; i < acceptedFiles.length; i++) {
				let file = acceptedFiles[i];
				file.originalFilename = file.path;
				formDataObject.append("files", file);
			}

			// Append claimants
			formDataObject.append('claimants', JSON.stringify([{
				id: claimant.id,
				active_case_id: claimant.active_case_id,

			}]));

			const response = await documentAPI.uploadDocument(claimant?.account_id || null, formDataObject);
			if (!response?.formParseResult) { throw new Error('Unable to record administration decision. ') }

			// Create task if favorable 
			if (formData.title === "Favorable") {
				// Create task
				let taskResult = await taskAPI.createTask(claimant?.account_id || null, {
					account_id: claimant?.account_id || null,
					claimant_id: claimant?.id || null,
					case_id: selectedCase?.id || null,
					task_type_id: 10,
					task_status_id: 1,
					title: 'Upload Award Letter',
					description: 'Upload Award Letter',
					due_date: moment().add(7, 'days')
				});
			}

			Swal.fire({
				icon: 'success',
				text: 'Recorded administration decision!',
				showConfirmButton: true,
				background: false,
				backdrop: false
			});
		} catch (error) {
			Swal.fire({
				icon: 'error',
				text: error,
				showConfirmButton: true,
				background: false,
				backdrop: false
			});
			handleClose();
			return false;
		}
		setIsUploading(false);
		refreshParent();
		handleClose();
	};

	// Handle close
	const handleClose = () => {
		removeAll();
		setIsUploading(false);
		setFormData(defaultFormData);
		setModalState(false);
	};


	return (
		<div>
			{/* <Button variant="primary" onClick={() => { setModalState(true) }}>Record Administration Decision</Button> */}


			<Modal show={modalState} size="lg" onHide={handleClose}>
				<Form ref={formRef} onSubmmit={handleSubmit}>
					<Modal.Header closeButton>
						<Modal.Title>Record Administration Decision</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<Form.Group className="mb-3" controlId="document_type_id">
							<Form.Label className="fs-0">Decision Type</Form.Label>
							<Form.Select disabled={isUploading} name="document_type_id" value={formData.document_type_id} onChange={handleChange} required>
								<option value={""}>--Select One--</option>
								<option value={"INITIAL_DECISION"}>Initial Decision</option>
								<option value={"RECONSIDERATION_DECISION"}>Reconsideration Decision</option>
								<option value={"HEARING_DECISION"}>Hearing Decision</option>
								<option value={"APPEALS_COUNCIL_ORDER"}>Appeals Council Order</option>
							</Form.Select>
						</Form.Group>

						<Form.Group className="mb-3" controlId="title">
							<Form.Label disabled={isUploading} className="fs-0">Disposition of the decision</Form.Label>
							<Form.Select name="title" value={formData.title} onChange={handleChange} required>
								<option value={""}>--Select One--</option>
								<option value={"Favorable"}>Favorable</option>
								<option value={"Partially Favorable"}>Partially Favorable</option>
								<option value={"Unfavorable"}>unfavorable</option>
							</Form.Select>
						</Form.Group>

						<span className="fs-0 fw-bold">Upload File:</span>
						{
							!isUploading && (
								<div {...getRootProps({ className: 'dropzone-area py-6' })}>
									<input {...getInputProps({ multiple: false })} />
									<Flex justifyContent="center">
										<img src={cloudUpload} alt="" width={25} className="me-2" />
										<p className="fs-0 mb-0 text-700">Drop your file here</p>
									</Flex>
								</div>
							)
						}

						<div className="mt-3">
							{selectedFiles.length > 0 && (
								<>
									<h6>File</h6>
									<ul>{files}</ul>
								</>
							)}
						</div>
					</Modal.Body>
					<Modal.Footer>
						<Button variant="falcon-secondary" onClick={handleClose}>Cancel</Button>
						<Button variant="falcon-primary" onClick={e => handleSubmit(e)} disabled={isUploading}>
							{isUploading ? "Uploading..." : "Submit"}
						</Button>
					</Modal.Footer>
				</Form>
			</Modal>
		</div>
	);
};

export default RecordAdministrationDecision;