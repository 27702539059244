import React, { useEffect, useState } from 'react';
import { Card, Row, Col, Button, Form } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import { Link } from 'react-router-dom';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import HearingTableHeader from './HearingTableHeader';
import CustomAdvanceTable from 'components/common/advance-table/CustomAdvanceTable';
import CustomAdvanceTablePagination from 'components/common/advance-table/CustomAdvanceTablePagination';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import { hearingAPI } from 'utils/api/fulcrum-api';
import { removeEmptyFromObj } from 'utils/functions';
import moment from 'moment';
import Auth from 'utils/auth';
import DatePicker from 'react-datepicker';
import { toast } from 'react-toastify';
import HearingCreationTool from './HearingCreationTool';
import PreHearingChecklist from './PreHearingChecklist';
import PostHearingSummary from './PostHearingSummary';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';



const columns = [
	{
		accessor: 'claimant_first_name',
		Header: 'First Name',
		style: { 'whiteSpace': 'unset' },
		headerProps: { className: 'pe-1 text-wrap' },
		cellProps: {
			className: 'py-2'
		}
	},
	{
		accessor: 'claimant_last_name',
		Header: 'Last Name',
		style: { 'whiteSpace': 'unset' },
		headerProps: { className: 'pe-1 text-wrap' },
		cellProps: {
			className: 'py-2'
		}
	},
	{
		accessor: 'hearing_date',
		Header: 'Date',
		style: { 'whiteSpace': 'unset' },
		headerProps: { className: 'pe-1 text-wrap' },
		cellProps: {
			className: 'py-2'
		}
	},
	{
		accessor: 'hearing_time',
		Header: 'Time',
		style: { 'whiteSpace': 'unset' },
		headerProps: { className: 'pe-1 text-wrap' },
		cellProps: {
			className: 'py-2'
		}
	},
	{
		accessor: 'city',
		Header: 'City',
		style: { 'whiteSpace': 'unset' },
		headerProps: { className: 'pe-1 text-wrap' },
		cellProps: {
			className: 'py-2'
		}
	},
	{
		accessor: 'state',
		Header: 'State',
		style: { 'whiteSpace': 'unset' },
		headerProps: { className: 'pe-1 text-wrap' },
		cellProps: {
			className: 'py-2'
		}
	},
	{
		accessor: 'location_special_instructions',
		Header: 'Special',
		style: { 'whiteSpace': 'unset' },
		headerProps: { className: 'pe-1 text-wrap' },
		cellProps: {
			className: 'py-2'
		}
	},
	{
		accessor: 'primary_rep',
		Header: 'Primary Rep',
		style: { 'whiteSpace': 'unset' },
		headerProps: { className: 'pe-1 text-wrap' },
		cellProps: {
			className: 'py-2'
		}
	},
	{
		accessor: 'hearing_rep',
		Header: 'Hearing Rep',
		style: { 'whiteSpace': 'unset' },
		headerProps: { className: 'pe-1 text-wrap' },
		cellProps: {
			className: 'py-2'
		}
	},
	{
		accessor: 'attendance_type',
		Header: 'Type',
		style: { 'whiteSpace': 'unset' },
		headerProps: { className: 'pe-1 text-wrap' },
		cellProps: {
			className: 'py-2'
		}
	},
	{
		accessor: 'rep_docs',
		Header: 'Rep Docs',
		style: { 'whiteSpace': 'unset' },
		headerProps: { className: 'pe-1 text-wrap' },
		cellProps: {
			className: 'py-2'
		}
	},
	{
		accessor: 'pre_hearing_checklist_id',
		Header: 'Pre-Hearing Checklist',
		style: { 'whiteSpace': 'unset' },
		headerProps: { className: 'pe-1 text-wrap' },
		cellProps: {
			className: 'py-2'
		}
	},
	{
		accessor: 'post_hearing_summary_id',
		Header: 'Post-Hearing Summary',
		style: { 'whiteSpace': 'unset' },
		headerProps: { className: 'pe-1 text-wrap' },
		cellProps: {
			className: 'py-2'
		}
	},
	{
		accessor: 'view',
		Header: '',
		headerProps: { className: 'pe-1 text-wrap' },
		cellProps: {
			className: 'py-2'
		}
	},
];

const HearingTable = ({ tableHeaderLabel = "Hearings", defaultSearchParams = {}, requiredSearchParams = {}, defaultClaimant = {} }) => {
	// const [createClaimantModal, setCreateClaimantModal] = useState(false);
	const [skipFirstQuery, setSkipFirstQuery] = useState(true);
	const [hearingData, setHearingData] = useState([]);
	const [hearingCount, setHearingCount] = useState(0);
	const [Pages, setPages] = useState([1]);
	const [activePage, setActivePage] = useState(1);
	const [perPage, setPerPage] = useState(10);
	const [selectedHearing, setSelectedHearing] = useState({});
	const [hearingCreationModal, setHearingCreationModal] = useState(false);
	const [preHearingChecklistModal, setPreHearingChecklistModal] = useState(false);
	const [selectedPreHearingChecklistId, setSelectedPreHearingChecklistId] = useState(null);
	const [postHearingSummaryModal, setPostHearingSummaryModal] = useState(false);
	const [selectedPostHearingSummaryId, setSelectedPostHearingSummaryId] = useState(null);
	const [searching, setSearching] = useState(true);
	const [hearingJudges, setHearingJudges] = useState([]);
	const [hearingLocations, setHearingLocations] = useState([]);
	const [lastQuery, setLastQuery] = useState({});
	const [searchParams, setSearchParams] = useState({ ...defaultSearchParams, ...requiredSearchParams });
	let defaultSort = ["created_at", "ASC"];
	const [appliedSortOrders, setAppliedSortOrders] = useState([defaultSort]);


	const headerClickFn = (id, previousSortBy) => {
		if (!id) {
			return;
		}
		let foundIndex = appliedSortOrders.findIndex(s => s[0] === id);
		let sortBy = !previousSortBy ? "ASC" : previousSortBy === "asc" ? "DESC" : '';

		if (!sortBy) { // Not Sorted so remove clicked sort item 
			if (foundIndex > -1) {
				setAppliedSortOrders((prev) => {
					let newSortOrders = [...prev];
					newSortOrders.splice(foundIndex, 1); // remove clicked sort item
					return newSortOrders;
				});
			}
		} else { // Sort by Ascending or Descending 
			let newSort = [id, sortBy];
			if (foundIndex > -1) { // replaces existing 
				setAppliedSortOrders((prev) => {
					let newSortOrders = [...prev];
					newSortOrders[foundIndex] = newSort;
					return newSortOrders;
				});
			} else { // inserts 
				setAppliedSortOrders((prev) => [...prev, newSort]);
			}
		}
	};

	const formatData = (data = []) => {
		let adjustedData = data.map(h => {
			return {
				...h,
				claimant_first_name: h?.claimant?.first_name || '',
				claimant_last_name: h?.claimant?.last_name || '',
				date: h?.date ? moment(h.date).format('MM/DD/YYYY') : '',
				time: h?.time ? moment(h.time, 'HH:mm:ss').format('hh:mm A') : '',
				primary_rep: h?.claimant?.claimant_active_case?.primary_representative ? (h?.claimant?.claimant_active_case?.primary_representative?.first_name || '') + ' ' + (h?.claimant?.claimant_active_case?.primary_representative?.last_name || '') : '',
				hearing_rep: h?.hearing_rep?.first_name + ' ' + h?.hearing_rep?.last_name || '',
				hearing_type: h?.hearing_type || '',
				pre_hearing_checklist_id: h?.pre_hearing_checklist_id ? (
					<span className="text-success">Yes <span className="text-link cursor-pointer" onClick={() => {
						setSelectedHearing(h);
						setPreHearingChecklistModal(true);
					}}>
						<FontAwesomeIcon icon="eye" className="me-2" />
					</span></span>
				) : (
					<span className="text-danger">No <span className="text-link cursor-pointer" onClick={() => {
						setSelectedHearing(h);
						setPreHearingChecklistModal(true);
					}}>
						<FontAwesomeIcon icon="edit" className="me-2" />
					</span></span>
				),
				post_hearing_summary_id: h?.post_hearing_summary_id ? (
					<span className="text-success">Yes <span className="text-link cursor-pointer" onClick={() => {
						setSelectedHearing(h);
						setPostHearingSummaryModal(true);
					}}>
						<FontAwesomeIcon icon="eye" className="me-2" />
					</span></span>
				) : (
					<span className="text-danger">No <span className="text-link cursor-pointer" onClick={() => {
						setSelectedHearing(h);
						setPostHearingSummaryModal(true);
					}}>
						<FontAwesomeIcon icon="edit" className="me-2" />
					</span></span>
				),
				view: <Link to={`/claimant/profile/${h.claimant_id}`}>View</Link>
			}
		});

		setHearingData(adjustedData);
	};

	const hearingSearchHandler = async (params = {}, saveLastQuery = false) => {
		try {
			params = removeEmptyFromObj(params);
			params.page = activePage;
			params.limit = perPage;
			params.navSize = 4; // How many buttons to show for navigation
			params.order = appliedSortOrders; // Example [['last_last', 'asc'],['first_name','asc'], ['id','asc']]
			let { data } = await hearingAPI.searchHearings(params);
			if (!data) {
				throw new Error('No hearing data found');
			}
			let { rows = [], pages = [], count = 0, page = 1, pageCount = 0 } = data || {}; // pages = [1,2,3,10]
			if (!rows) {
				throw new Error('No hearing data found');
			}
			if ((Array.isArray(rows) && rows.length === 0)) {
				setActivePage(1); // Make sure we don't get stuck loading nothing
			}

			if (pageCount < activePage) {
				setActivePage(1);
			} else {
				setActivePage(page);
			}
			setPages(pages);
			formatData(rows);
			console.log(count)
			setHearingCount(count);

			if (saveLastQuery) {
				setLastQuery(params);
			}
		} catch (error) {
			console.log(error);
			toast.error('Error searching hearings', { theme: 'colored' });
		}
		setSearching(false);
		return;
	};


	// Clear search params and reset to default
	const clearSearchParams = () => {
		setSearchParams({ ...defaultSearchParams, ...requiredSearchParams });
	};

	// New Search
	const newSearch = async (params) => {
		setSearching(true);
		let adjustedSearchParams = {
			...params,
			...requiredSearchParams
		};

		hearingSearchHandler(adjustedSearchParams, true);
	};

	// Refresh last query
	const refreshLastQuery = async () => {
		hearingSearchHandler({ ...lastQuery, ...requiredSearchParams }, false);
	};

	// Handle hearing selection
	const handleTaskSelection = (hearing) => {
		setSelectedHearing(hearing);
		setTaskDetailsModalState(true);
	};

	useEffect(async () => {
		if (!skipFirstQuery) {
			newSearch({ ...defaultSearchParams });
		}
		setSkipFirstQuery(false);
	}, []);

	// Apply search filters
	useEffect(() => { newSearch(searchParams) }, [searchParams, activePage, perPage, appliedSortOrders]);

	return (
		<div>
			<AdvanceTableWrapper
				columns={columns}
				data={hearingData}
				// selection
				sortable
				pagination
				perPage={perPage}
				setPerPage={setPerPage}
			>
				<Card className="mb-3 shadow-none">
					<Card.Header>
						<HearingTableHeader
							table
							label={tableHeaderLabel}
							setHearingCreationModal={setHearingCreationModal}
							defaultSearchParams={defaultSearchParams}
							searchParams={searchParams}
							setSearchParams={setSearchParams}
							refreshLastQuery={refreshLastQuery}
							requiredSearchParams={requiredSearchParams}
							hearingData={hearingData}
							clearSearchParams={clearSearchParams}
						/>
					</Card.Header>
					<Card.Body className="p-0">
						{
							searching
								?
								<p>Searching hearings....</p>
								:
								hearingData.length === 0
									?
									<p>No hearings found</p>
									:
									<CustomAdvanceTable
										table
										headerClickFn={headerClickFn}
										appliedSortOrders={appliedSortOrders}
										headerClassName="bg-200 text-900 text-nowrap align-middle"
										rowClassName="align-middle white-space-nowrap"
										tableProps={{
											size: 'sm',
											striped: true,
											className: 'fs--1 mb-0 overflow-hidden'
										}}
									/>
						}
					</Card.Body>
					<Card.Footer>
						<CustomAdvanceTablePagination
							table
							Pages={Pages}
							activePage={activePage}
							setActivePage={setActivePage}
						/>
					</Card.Footer>
				</Card>
			</AdvanceTableWrapper>

			<PreHearingChecklist
				modalState={preHearingChecklistModal}
				setModalState={setPreHearingChecklistModal}
				selectedHearing={selectedHearing}
				setSelectedHearing={setSelectedHearing}
				refreshParent={refreshLastQuery}
			/>

			<PostHearingSummary
				modalState={postHearingSummaryModal}
				setModalState={setPostHearingSummaryModal}
				selectedHearing={selectedHearing}
				setSelectedHearing={setSelectedHearing}
				refreshParent={refreshLastQuery}
			/>

			<HearingCreationTool
				refreshParent={refreshLastQuery}
				modalState={hearingCreationModal}
				setModalState={setHearingCreationModal}
				defaultClaimant={defaultClaimant}
			/>
		</div>
	);
};


export default HearingTable;