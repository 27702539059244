import React, { useState, useEffect } from 'react';
import { Col, Form, Button, Row } from 'react-bootstrap';

const RevisionLogFilters = ({ searchParams, setSearchParams = () => { }, fields = [], clearSearchParams = () => { } } = {}) => {
	let defaultFilters = {
		table: searchParams?.table || "",
		field: searchParams?.field || "",
		previous_value: searchParams?.previous_value || null,
		new_value: searchParams?.new_value || null,
		created_by_id: searchParams?.created_by_id || null,
		// created_at: searchParams?.created_at || null,
	};
	const [formData, setFormData] = useState(defaultFilters);

	// Clear Filters
	const clearFilters = () => {
		setFormData({
			table: searchParams?.table || "",
			field: searchParams?.field || "",
			previous_value: searchParams?.previous_value || null,
			new_value: searchParams?.new_value || null,
			created_by_id: searchParams?.created_by_id || null,
			// created_at: searchParams?.created_at || null,
		});
		clearSearchParams();
	};

	const onSubmit = async (e) => {
		e.preventDefault();
		setSearchParams(formData);
	};

	useEffect(() => {
		setFormData({
			table: searchParams?.table || "",
			field: searchParams?.field || "",
			previous_value: searchParams?.previous_value || null,
			new_value: searchParams?.new_value || null,
			created_by_id: searchParams?.created_by_id || null,
			// created_at: searchParams?.created_at || null,
		});
	}, [searchParams]);

	return (
		<Form noValidate onSubmit={onSubmit} className="row flex-between-center px-2 py-3 my-3 border bg-100 mx-2">
			<Row>
				<Col>
					<h5>Filters</h5>
				</Col>
			</Row>
			<Row>
				{
					fields.includes("field") &&
					<Col>
						<Form.Group controlId="field">
							<Form.Label>Field</Form.Label>
							<Form.Control
								className={`${searchParams?.field ? "bg-soft-info" : ""}`}
								size="sm"
								type="text"
								placeholder="Field"
								name="field"
								value={formData.field}
								onChange={(e) => setFormData({ ...formData, field: e.target.value })}
							/>
						</Form.Group>
					</Col>
				}

				{
					fields.includes("description") &&
					<Col>
						<Form.Group controlId="description">
							<Form.Label>Description</Form.Label>
							<Form.Control
								className={`${searchParams?.description ? "bg-soft-info" : ""}`}
								size="sm"
								type="text"
								placeholder="Description"
								name="description"
								value={formData.description}
								onChange={(e) => setFormData({ ...formData, description: e.target.value })}
							/>
						</Form.Group>
					</Col>
				}

				{
					fields.includes("previous_value") &&
					<Col>
						<Form.Group controlId="previous_value">
							<Form.Label>Previous Value</Form.Label>
							<Form.Control
								className={`${searchParams?.previous_value ? "bg-soft-info" : ""}`}
								size="sm"
								type="text"
								placeholder="Previous Value"
								name="previous_value"
								value={formData.previous_value}
								onChange={(e) => setFormData({ ...formData, previous_value: e.target.value })}
							/>
						</Form.Group>
					</Col>
				}

				{
					fields.includes("new_value") &&
					<Col>
						<Form.Group controlId="new_value">
							<Form.Label>New Value</Form.Label>
							<Form.Control
								className={`${searchParams?.new_value ? "bg-soft-info" : ""}`}
								size="sm"
								type="text"
								placeholder="New Value"
								name="new_value"
								value={formData.new_value}
								onChange={(e) => setFormData({ ...formData, new_value: e.target.value })}
							/>
						</Form.Group>
					</Col>
				}
			</Row>



			<Row className="mt-3">
				<Col>
					<Button
						variant="outline-primary"
						size="sm"
						type="submit"
						className="me-2"
					>
						Search
					</Button>
					<Button
						variant="outline-danger"
						size="sm"
						onClick={() => { clearFilters() }}
					>
						Clear
					</Button>
				</Col>
			</Row>
		</Form >
	);
};

export default RevisionLogFilters;
