import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFont } from "@fortawesome/free-solid-svg-icons";
import AppContext from "context/Context";
import PasswordChangeModal from './PasswordChangeModal';
import UserSignature from './UserSignature';
import UserInitials from './UserInitials';
import UserServicePermissions from './UserServicePermissions';

const UserSettings = ({ user, setUser } = {}) => {
  const { config, setConfig, fontSizes } = useContext(AppContext);
  const { fontSize = 'medium' } = config || {};
  const onChangeFontSize = (e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    let { name = "fontSize", value } = e.target || {};
    if (name === "fontSize" && value && value !== fontSize) {
      setConfig(name, value); // Updates global font size
    }
  };

  return (
    <div id="user-settings-container">
      <PasswordChangeModal userId={user.id} />
      <Row className="border-bottom border-top my-2 py-3">
        <Col sm={3} className="text-center">
          <UserInitials user={user} setUser={setUser} />
        </Col>
        <Col>
          <UserSignature user={user} setUser={setUser} />
        </Col>
        <Form noValidate className="col">
          <Form.Group className="d-flex">
            <Form.Label className="mt-2 pe-2"><FontAwesomeIcon icon={faFont} className='me-1' /> Font Size</Form.Label>
            <Form.Select size="sm" name="fontSize" className="w-auto" value={fontSize} onChange={onChangeFontSize}>
              {fontSizes.map((size, i) => (<option key={i} value={size}>{size}</option>))}
            </Form.Select>
          </Form.Group>
        </Form>
      </Row>
      <Row>
        <UserServicePermissions user={user} setUser={setUser} />
      </Row>
    </div>
  );
};

UserSettings.propTypes = {
  user: PropTypes.object,
  setUser: PropTypes.func
};

export default UserSettings;