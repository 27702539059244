import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Row, Col, Form, Card, Button, Nav, NavDropdown, Spinner, ListGroup } from 'react-bootstrap';
import { claimantDetailsAPI, accessVerificationAPI, myFormsAPI } from 'utils/api/fulcrum-api.js';
import Navbar from 'react-bootstrap/Navbar';
import Swal from 'sweetalert2';
import MyFormViewer from 'components/pages/documents/my-forms/MyFormViewer';
import PDFViewerLayout from 'components/app/pdfjs-viewer/PDFViewerLayout';
import PDFViewerPreview from 'components/app/pdfjs-viewer/PDFViewerPreview';
import { toast } from 'react-toastify';

const ExternalTaskPortalLayout = ({ slide, setSlide, uuid, correspondenceRecord }) => {
	const [loading, setLoading] = useState(true);
	const [loadingContent, setLoadingContent] = useState(true);
	const [selectedItem, setSelectedItem] = useState(null);
	let defaultContent = (
		<div className="text-center fw-bold my-5">
			<h5>Select an item to view the content.</h5>
		</div>
	);
	const [contentSection, setContentSection] = useState(defaultContent);

	// Get assigned tasks
	const [assignedTasks, setAssignedTasks] = useState([]);
	const getAssignedTasks = async () => {
		try {
			setAssignedTasks(correspondenceRecord?.my_form_responses ? correspondenceRecord.my_form_responses.map(record => {
				console.log(record);
				return {
					...record,
					task_status: ['LOOKUP_COMPLETE', 'SENT'].includes(record.status) ? 'Incomplete' : 'Completed',
				}
			}) : [])
		} catch (error) {
			console.log(error);
		}
		setLoadingContent(false);
	};


	// Handle task selection
	const handleTaskSelection = async (task) => {
		setSelectedItem(null);
		setSelectedItem(task);
		setContentSection(defaultContent);
		// try {
		// 	let result = await myFormsAPI.getCorrespondenceMyFormResponseByUUID(uuid, correspondenceRecord.id, task.id);
		// 	console.log(result);
		// 	if (!result?.data?.myFormResponse) {
		// 		throw new Error('No data found');
		// 	}
		// 	setSelectedItem(task);
		// } catch (error) {
		// 	console.log(error);
		// 	renderContent('Error');
		// 	toast.error("Unable to load the content. Please try again later.", { theme: "colored" })
		// }
	}


	const renderContent = (type) => {
		let content = null;
		switch (type) {
			case 'PDF':
			case 'document-response':
				content = (
					<PDFViewerLayout
						defaultUUID={uuid}
						myFormResponseId={selectedItem.id}
					/>
					// <PDFViewerPreview
					// 	myFormResponseId={selectedItem.id}
					// // claimant_id={selectedItem.claimant_id}
					// // pdf_template={selectedItem.form_data}
					// />
				);
				break;
			case 'RTF':
			case 'my-form-response':
				content = (
					<MyFormViewer
						uuid={uuid}
						inboxOutboxId={correspondenceRecord.id}
						myFormResponseId={selectedItem.id}
						selectedItem={selectedItem}
						setSelectedItem={setSelectedItem}
						setAssignedTasks={setAssignedTasks}
						assignedTasks={assignedTasks}
					/>
				);
				break;
			default:
				content = defaultContent;
		}

		setContentSection(content);
	}

	useEffect(() => {
		getAssignedTasks();
	}, []);

	useEffect(() => {
		setContentSection(defaultContent)
		if (selectedItem?.type) {
			renderContent(selectedItem.type);
		}
	}, [selectedItem]);

	return (
		< div className="m-2" >
			{/* <div className="px-3 pt-3 pb-2 mb-2 shadow" style={{ backgroundColor: "#FFF", color: "#FFFFFF" }}>
				<div className="pb-3">
					<h4 className="d-inline" style={{ color: '#FFFFFF' }}>Review and Submit</h4>
				</div>
			</div> */}
			{/* <Row>
				<Col>
					<Navbar bg="light" expand="lg">
						<Navbar.Brand href="#home">Fulcrum</Navbar.Brand>
						<Navbar.Toggle aria-controls="basic-navbar-nav" />
						<Navbar.Collapse id="basic-navbar-nav">
							<Nav className="mr-auto">
								<Nav.Link href="#home">Home</Nav.Link>
								<Nav.Link href="#link">Link</Nav.Link>
								<NavDropdown title="Dropdown" id="basic-nav-dropdown">
									<NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
									<NavDropdown.Item href="#action/3.2">Another action</NavDropdown.Item>
									<NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
									<NavDropdown.Divider />
									<NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item>
								</NavDropdown>
							</Nav>
						</Navbar.Collapse>
					</Navbar>
				</Col>
			</Row> */}
			<Row className="justify-content-center">
				<Col sm={3} className="">
					<Card className="my-3">
						<Card.Header>
							<h5 className="text-center mb-3">Documents</h5>
							{
								Array.isArray(assignedTasks) && assignedTasks.length > 0 &&
								<p className='text-muted'>Your firm has shared the following items with you to review and submit. Please take a moment and complete the following items. If you have any questions, please reach out to your firm for assistance.</p>
							}
						</Card.Header>
						<Card.Body>

							{
								Array.isArray(assignedTasks) && assignedTasks.length > 0 &&
								<div className="mb-2">
									Please complete the tasks below:
									<br />
									{assignedTasks.map(task => task.task_status === "Completed").length} of {assignedTasks.length} tasks completed
								</div>
							}
							<ListGroup>

								{
									Array.isArray(assignedTasks) &&
									(
										assignedTasks.length > 0
											?
											assignedTasks.map((task, index) => (
												<ListGroup.Item variant={task.task_status === "Completed" ? 'success' : 'info'} className="my-1" action onClick={() => handleTaskSelection(task)} key={index}>
													<span className={task.task_status === "Completed" ? `text-decoration-line-through text-success` : ''} >
														{task.id} {task.title} ({task.task_status})
													</span>
												</ListGroup.Item>
											))
											:
											<ListGroup.Item>
												<span className="fw-bold">No assigned items.</span>
												<p>It looks like you're all caught up!</p>
											</ListGroup.Item>
									)
								}
							</ListGroup>
						</Card.Body>
						<Card.Footer>
							{/* <Button variant="primary" size="sm" block>
								Close
							</Button> */}
						</Card.Footer>
					</Card>
				</Col>
				<Col sm={9} className="border bg-300">
					{
						selectedItem ? (
							loadingContent ? (
								<div className="text-center">
									<Spinner animation="border" role="status">
										<span className="sr-only">Loading content...</span>
									</Spinner>
								</div>
							) : (
								<div>
									{contentSection}
								</div>
							)) :
							(
								<div className="text-center fw-bold my-5">
									<h5>Select an item to view the content.</h5>
								</div>
							)
					}
				</Col>
			</Row>
		</div >
	);
};

export default ExternalTaskPortalLayout;