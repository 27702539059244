import React from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import { claimantAPI } from 'utils/api/fulcrum-api';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';

const ClaimantConfirmContactInfo = ({ claimant, setClaimant }) => {

	// Handle click
	const handleClick = async () => {
		Swal.fire({
			title: 'Claimant confirmed contact information?',
			text: `${claimant?.first_name || ""} ${claimant?.last_name || ""} had confirmed their contact information?`,
			icon: 'question',
			showCancelButton: true,
			confirmButtonText: 'Yes',
			cancelButtonText: 'No'
		}).then(async (result) => {
			if (result.isConfirmed) {
				try {
					let result = await claimantAPI.updateClaimant(claimant.id, {
						established_contact: true,
					});
					if (!result?.data) {
						throw new Error('No claimant data returned');
					}
					toast.success(`Established contact and confirmed claimant information.`, {
						theme: 'colored'
					});

					setClaimant(result.data);
				} catch (error) {
					console.error(error);
					toast.error(`${error}`, {
						theme: 'colored'
					});
				}
			}
		});
	};

	return (
		<>
			{
				claimant.established_contact == false &&
				<Button variant="success" onClick={() => handleClick()}>Confirm Contact Info</Button>
			}
		</>
	);
};

ClaimantConfirmContactInfo.propTypes = {
	claimant: PropTypes.object,
	setClaimant: PropTypes.func
}

export default ClaimantConfirmContactInfo;