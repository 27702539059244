import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import JoditEditor from "jodit-pro-react";
import { toast } from 'react-toastify';
import { accountAPI } from 'utils/api/fulcrum-api';

const FormHeader = ({ account = {} }) => {
	const editor = useRef(null);
	const [formData, setFormData] = useState({
		form_header: account?.form_header || ''
	});
	const [previousFormData, setPreviousFormData] = useState(formData);

	const editorConfig = {
		// license: 
		readonly: false, // all options from https://xdsoft.net/jodit/doc/
		// toolbarAdaptive: false,
		buttons: [
			"bold",
			"strikethrough",
			"underline",
			"italic",
			'ul',
			'ol',
			'outdent',
			'indent',
			'font',
			'fontsize',
			'brush',
			'paragraph',
			'lineHeight',
			'image',
			'table',
			'link',
			'align',
			'undo',
			'redo',
			'hr',
			'eraser',
			'symbol',
			'copyformat',
			// 'fullsize',
			'print'
		],
	}

	// const handleChange = e => {
	// 	let { name, value } = e.target;

	// 	setFormData({
	// 		...formData,
	// 		[name]: value
	// 	});
	// };

	const saveFormHeader = async data => {
		try {
			if (data.form_header === previousFormData.form_header) {
				return;
			}
			let result = await accountAPI.updateAccount(account.id, data);
			if (!result?.data) {
				throw new Error('No account data returned');
			}

			setFormData({ ...formData, ...data });
			setPreviousFormData({ ...formData, ...data });

			toast.success('Form header saved!', {
				theme: 'colored'
			});
		} catch (error) {
			console.log(error);
			toast.error(error, {
				theme: 'colored'
			});
		}
	};


	return (
		<div>
			<h5>Form Header</h5>
			<p>Manage your form form header.</p>
			<div className="mt-3">
				<JoditEditor
					ref={editor}
					value={formData.form_header}
					rows={10}
					config={editorConfig}
					tabIndex={1} // tabIndex of textarea
					onBlur={newContent => { let newData = { ...formData, form_header: newContent }; saveFormHeader(newData) }} // preferred to use only this option to update the content for performance reasons
				/>
			</div>
		</div>
	);
};

FormHeader.propTypes = {
	account: PropTypes.object
}

export default FormHeader;