import React, { useState } from 'react';
import { Card, Col, Row, Button, Modal, Form, Alert, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { postHearingSummaryAPI } from 'utils/api/fulcrum-api';
import DatePicker from 'react-datepicker';
import moment from 'moment';

const PostHearingSummary = ({ modalState, setModalState, selectedHearing, setSelectedHearing = () => { }, refreshParent = () => { } }) => {

	const defaultFormData = {
		attendants: [], // Array Checkbox required Options: Attorney, Claimant, Vocational Expert, Medical Expert - Physical, Medical Expert - Psych, Other Witness (explain in notes)
		events: [], // Array Checkbox Options: Hearing Conducted, Hearing Continued (explain in notes), Case Dismissed (explain in notes), Bench Decision, Case Taken Under Advisement, Client Consented to Amended Onset Date (explain in notes)
		rep_notes: '', // String Textarea required
		follow_up: null, // Boolean Radio Button required
		follow_up_notes: '', // String Textarea (required if follow_up is true)
		follow_up_due: null, // Date (required if follow_up is true)

		// Case Feedback Section
		outcome: '', // String select options: Favorable, Partially Favorable, Unfavorable, Hearing Not Conducted
		appeal: null, // Boolean Radio Button (required if outcome === "Partially Favorable" || outcome === "Unfavorable")
		appeal_type: '', // String Select Options: Short Form, Long Form
		brief: null, // Boolean Radio Button (required if outcome === "Favorable" || outcome === "Partially Favorable" || outcome === "Unfavorable")
		questioning: null, // Boolean Radio Button (required if outcome === "Favorable" || outcome === "Partially Favorable" || outcome === "Unfavorable")
		length: '', // String Select (required if outcome === "Favorable" || outcome === "Partially Favorable" || outcome === "Unfavorable") Options: // 15 minutes, 30 minutes, 45 minutes, 1 hour, 1 hour 15 minutes, 1 hour 30 minutes, 1 hour 45 minutes, 2 hours, Over 2 hours
		opening_statement: null, // Boolean Radio Button (required if outcome === "Favorable" || outcome === "Partially Favorable" || outcome === "Unfavorable")
		closing_statement: null, // Boolean Radio Button (required if outcome === "Favorable" || outcome === "Partially Favorable" || outcome === "Unfavorable")
		stickler: null, // Boolean Radio Button (required if outcome === "Favorable" || outcome === "Partially Favorable" || outcome === "Unfavorable")
		judge_feedback: '', // String Textarea (required if outcome === "Favorable" || outcome === "Partially Favorable" || outcome === "Unfavorable")

		// Firm Feedback
		claimant_satisfaction: null, // Int Radio Button (1 through 5) required
		claimant_concerns: null, // Boolean Radio Button required
		judge_satisfaction: null, // Int Radio Button (1 through 5) required
		judge_concerns: null, // Boolean Radio Button required
		viability: null, // Int Radio Button (1 through 5) required
		organization: null, // Int Radio Button (1 through 5) required
		missing_records: null, // Boolean Radio Button required
		responsiveness: null, // Int Radio Button (1 through 5) required
		improvement: '', // String Textarea 
		affirmation_1: null, // Boolean Checkbox (single acknowledgment)
		affirmation_2: null, // Boolean Checkbox (single acknowledgment)

	};
	const [formData, setFormData] = useState(defaultFormData);
	const [previousFormData, setPreviousFormData] = useState(defaultFormData);
	const [loading, setLoading] = useState(true);
	const [submitting, setSubmitting] = useState(false);

	// Get Post-Hearing Summary
	const getPostHearingSummary = async (id) => {
		if (!id) return toast.error('Error getting Post-Hearing Summary');
		try {
			let result = await postHearingSummaryAPI.getPostHearingSummary(selectedHearing.id, id);
			if (!result?.data) {
				toast.error('Error getting Post-Hearing Summary');
			}

			let newFormData = {
				attendants: result?.data?.attendants ? result.data.attendants.split(',') : [],
				events: result?.data?.events ? result.data.events.split(',') : [],
				rep_notes: result.data.rep_notes,
				follow_up: result.data.follow_up,
				follow_up_notes: result.data.follow_up_notes,
				follow_up_due: result.data.follow_up_due,

				// Case Feedback Section
				outcome: result.data.outcome,
				appeal: result.data.appeal,
				appeal_type: result.data.appeal_type,
				brief: result.data.brief,
				questioning: result.data.questioning,
				length: result.data.length,
				opening_statement: result.data.opening_statement,
				closing_statement: result.data.closing_statement,
				stickler: result.data.stickler,
				judge_feedback: result.data.judge_feedback,

				// Firm Feedback
				claimant_satisfaction: result.data.claimant_satisfaction,
				claimant_concerns: result.data.claimant_concerns,
				judge_satisfaction: result.data.judge_satisfaction,
				judge_concerns: result.data.judge_concerns,
				viability: result.data.viability,
				organization: result.data.organization,
				missing_records: result.data.missing_records,
				responsiveness: result.data.responsiveness,
				improvement: result.data.improvement,
				affirmation_1: result.data.affirmation_1,
				affirmation_2: result.data.affirmation_2,
			}
			setFormData(newFormData);
			setPreviousFormData(newFormData);
		} catch (error) {
			toast.error('Error getting Post-Hearing Summary', { theme: 'colored' });
		}
		setLoading(false);
	};

	// Handle Submit
	const handleSubmit = async (e) => {
		setSubmitting(true);
		e.preventDefault();
		try {
			let result;
			if (selectedHearing.post_hearing_summary_id) {
				result = await postHearingSummaryAPI.updatePostHearingSummary(selectedHearing.id, selectedHearing.post_hearing_summary_id, {
					...formData,
					attendants: formData.attendants.join(','),
					events: formData.events.join(','),
				});
			} else {
				result = await postHearingSummaryAPI.createPostHearingSummary(selectedHearing.id, {
					...formData,
					attendants: formData.attendants.join(','),
					events: formData.events.join(','),
				});
			}
			if (!result?.data) {
				throw new Error('Error saving Post-Hearing Summary');
			}
			setFormData(formData);
			setPreviousFormData(formData);
			toast.success('Post-Hearing Summary saved', { theme: 'colored' });
			handleClose(true);
			refreshParent();
		} catch (error) {
			console.log(error)
			toast.error('Error saving Post-Hearing Summary', { theme: 'colored' });
		}
		setSubmitting(false);
	};


	// Handle Close
	const handleClose = (bypassSaveCheck = false) => {
		if (!bypassSaveCheck && selectedHearing?.post_hearing_summary_id && JSON.stringify(formData) !== JSON.stringify(previousFormData)) {
			if (!window.confirm('You have unsaved changes. Are you sure you want to close?')) {
				return;
			}
		}
		setFormData(defaultFormData);
		setSelectedHearing(null);
		setModalState(false);
		setLoading(true);
	};

	// Handle Show
	const handleShow = () => {
		setFormData(defaultFormData);
		setModalState(true);
		setLoading(true);
		if (selectedHearing?.post_hearing_summary_id) {
			getPostHearingSummary(selectedHearing.post_hearing_summary_id);
		} else {
			setLoading(false);
		}
	};

	return (
		<Modal
			show={modalState}
			onShow={handleShow}
			onHide={handleClose}
			size="xl"
			backdrop="static"
			aria-labelledby="contained-modal-title-vcenter"
			centered
		>
			<Form onSubmit={handleSubmit}>
				<Modal.Header closeButton>
					<Modal.Title id="contained-modal-title-vcenter">
						Post-Hearing Summary
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{
						loading ?
							<Row>
								<Col>
									<Spinner animation="border" role="status">
										<span className="visually-hidden">Loading...</span>
									</Spinner>
								</Col>
							</Row>
							:
							<div>
								<Row>
									<Col>
										<Alert variant="info">
											Please take a moment to complete the post-hearing summary for {selectedHearing?.claimant?.first_name || ""} {selectedHearing?.claimant?.last_name || ""}.
										</Alert>

										{
											selectedHearing?.post_hearing_summary_id && (JSON.stringify(formData) !== JSON.stringify(previousFormData)) && (
												<Alert variant={'warning'}>
													You have unsaved changes. Please save or cancel before closing this window.
												</Alert>
											)
										}
									</Col>
								</Row>
								<Row>
									<Col>
										<Form.Group controlId="attendants">
											<Form.Label className="fw-bold border-bottom pb-2">Who attended the hearing?</Form.Label>
											<Form.Check
												type="checkbox"
												label="Attorney"
												name="attendants"
												value="Attorney"
												id="attorney"
												checked={formData.attendants.includes('Attorney')}
												onChange={e => {
													if (e.target.checked) {
														setFormData({
															...formData,
															attendants: [...formData.attendants, e.target.value]
														})
													} else {
														setFormData({
															...formData,
															attendants: formData.attendants.filter(attendant => attendant !== e.target.value)
														})
													}
												}}
											/>
											<Form.Check
												type="checkbox"
												label="Claimant"
												name="attendants"
												value="Claimant"
												id='claimant'
												checked={formData.attendants.includes('Claimant')}
												onChange={e => {
													if (e.target.checked) {
														setFormData({
															...formData,
															attendants: [...formData.attendants, e.target.value]
														})
													} else {
														setFormData({
															...formData,
															attendants: formData.attendants.filter(attendant => attendant !== e.target.value)
														})
													}
												}}
											/>
											<Form.Check
												type="checkbox"
												label="Vocational Expert"
												name="attendants"
												value="Vocational Expert"
												id="vocational-expert"
												checked={formData.attendants.includes('Vocational Expert')}
												onChange={e => {
													if (e.target.checked) {
														setFormData({
															...formData,
															attendants: [...formData.attendants, e.target.value]
														})
													} else {
														setFormData({
															...formData,
															attendants: formData.attendants.filter(attendant => attendant !== e.target.value)
														})
													}
												}}
											/>
											<Form.Check
												type="checkbox"
												label="Medical Expert - Physical"
												name="attendants"
												value="Medical Expert - Physical"
												id="medical-expert-physical"
												checked={formData.attendants.includes('Medical Expert - Physical')}
												onChange={e => {
													if (e.target.checked) {
														setFormData({
															...formData,
															attendants: [...formData.attendants, e.target.value]
														})
													} else {
														setFormData({
															...formData,
															attendants: formData.attendants.filter(attendant => attendant !== e.target.value)
														})
													}
												}}
											/>
											<Form.Check
												type="checkbox"
												label="Medical Expert - Mental"
												name="attendants"
												value="Medical Expert - Mental"
												id="medical-expert-mental"
												checked={formData.attendants.includes('Medical Expert - Mental')}
												onChange={e => {
													if (e.target.checked) {
														setFormData({
															...formData,
															attendants: [...formData.attendants, e.target.value]
														})
													} else {
														setFormData({
															...formData,
															attendants: formData.attendants.filter(attendant => attendant !== e.target.value)
														})
													}
												}}
											/>
											<Form.Check
												type="checkbox"
												label="Other"
												name="attendants"
												value="Other"
												id="other"
												checked={formData.attendants.includes('Other')}
												onChange={e => {
													if (e.target.checked) {
														setFormData({
															...formData,
															attendants: [...formData.attendants, e.target.value]
														})
													} else {
														setFormData({
															...formData,
															attendants: formData.attendants.filter(attendant => attendant !== e.target.value)
														})
													}
												}}
											/>
										</Form.Group>
									</Col>
									<Col>
										<Form.Group controlId="formBasicEvents">
											<Form.Label className="fw-bold border-bottom pb-2">Events</Form.Label>
											<Form.Check
												type="checkbox"
												label="Hearing Conducted"
												name="events"
												value="Hearing Conducted"
												id="hearing-conducted"
												checked={formData.events.includes('Hearing Conducted')}
												onChange={e => {
													if (e.target.checked) {
														setFormData({
															...formData,
															events: [...formData.events, e.target.value]
														})
													} else {
														setFormData({
															...formData,
															events: formData.events.filter(event => event !== e.target.value)
														})
													}
												}}
											/>
											<Form.Check
												type="checkbox"
												label="Hearing Continued (explain in notes)"
												name="events"
												value="Hearing Continued (explain in notes)"
												id="hearing-continued"
												checked={formData.events.includes('Hearing Continued (explain in notes)')}
												onChange={e => {
													if (e.target.checked) {
														setFormData({
															...formData,
															events: [...formData.events, e.target.value]
														})
													} else {
														setFormData({
															...formData,
															events: formData.events.filter(event => event !== e.target.value)
														})
													}
												}}
											/>
											<Form.Check
												type="checkbox"
												label="Case Dismissed (explain in notes)"
												name="events"
												value="Case Dismissed (explain in notes)"
												id="case-dismissed"
												checked={formData.events.includes('Case Dismissed (explain in notes)')}
												onChange={e => {
													if (e.target.checked) {
														setFormData({
															...formData,
															events: [...formData.events, e.target.value]
														})
													} else {
														setFormData({
															...formData,
															events: formData.events.filter(event => event !== e.target.value)
														})
													}
												}}
											/>
											<Form.Check
												type="checkbox"
												label="Bench Decision"
												name="events"
												value="Bench Decision"
												id="bench-decision"
												checked={formData.events.includes('Bench Decision')}
												onChange={e => {
													if (e.target.checked) {
														setFormData({
															...formData,
															events: [...formData.events, e.target.value]
														})
													} else {
														setFormData({
															...formData,
															events: formData.events.filter(event => event !== e.target.value)
														})
													}
												}}
											/>
											<Form.Check
												type="checkbox"
												label="Case Taken Under Advisement"
												name="events"
												value="Case Taken Under Advisement"
												id="case-taken-under-advisement"
												checked={formData.events.includes('Case Taken Under Advisement')}
												onChange={e => {
													if (e.target.checked) {
														setFormData({
															...formData,
															events: [...formData.events, e.target.value]
														})
													} else {
														setFormData({
															...formData,
															events: formData.events.filter(event => event !== e.target.value)
														})
													}
												}}
											/>
											<Form.Check
												type="checkbox"
												label="Client Consented to Amended Onset Date (explain in notes)"
												name="events"
												value="Client Consented to Amended Onset Date (explain in notes)"
												id="client-consented-to-amended-onset-date"
												checked={formData.events.includes('Client Consented to Amended Onset Date (explain in notes)')}
												onChange={e => {
													if (e.target.checked) {
														setFormData({
															...formData,
															events: [...formData.events, e.target.value]
														})
													} else {
														setFormData({
															...formData,
															events: formData.events.filter(event => event !== e.target.value)
														})
													}
												}}
											/>
										</Form.Group>
									</Col>
								</Row>
								<Row>
									<Col>
										<span className="fw-bold">Representative Notes:</span>
										<p>
											Please provide a brief summary of the hearing conducted.  Please be sure to include information regarding strength and credibility of claimant testimony, other witness testimony, any client complaints, and any issues that may relate to an appeal.
										</p>
										<Form.Group controlId="rep_notes">
											<Form.Control
												as="textarea"
												rows={5}
												name="rep_notes"
												value={formData.rep_notes}
												onChange={e => setFormData({ ...formData, rep_notes: e.target.value })}
											/>
										</Form.Group>
									</Col>
								</Row>
								<Row>
									<Col>
										<span className="fw-bold">Follow Up</span>
										<Form.Group controlId="follow_up">
											<Form.Label>Is Post-Hearing follow up required?</Form.Label>
											<br />
											<Form.Check
												type="radio"
												inline
												label="Yes"
												required
												name="follow_up"
												id='follow-up-yes'
												value={true}
												checked={formData.follow_up === true}
												onChange={(e) => setFormData({ ...formData, follow_up: true })}
											/>
											<Form.Check
												type="radio"
												inline
												label="No"
												required
												name="follow_up"
												id='follow-up-no'
												value={false}
												checked={formData.follow_up === false}
												onChange={(e) => setFormData({ ...formData, follow_up: false })}
											/>
										</Form.Group>
									</Col>
								</Row>

								{
									formData.follow_up && (
										<div>
											<Row>
												<Col>
													<Form.Group controlId="follow_up_notes">
														<Form.Label>Please describe what post-hearing follow up is required:</Form.Label>
														<Form.Control
															as="textarea"
															rows={5}
															name="follow_up_notes"
															value={formData.follow_up_notes}
															onChange={e => setFormData({ ...formData, follow_up_notes: e.target.value })}
														/>
													</Form.Group>
												</Col>
											</Row>
											<Row>
												<Col>
													<Form.Group controlId="follow_up_due">
														<Form.Label>Follow Up Due:</Form.Label>
														<DatePicker
															selected={formData?.follow_up_due ? moment(formData.follow_up_due).toDate() : null}
															onChange={(date) => {
																setFormData({ ...formData, follow_up_due: date });
															}}
															formatWeekDay={day => day.slice(0, 3)}
															className='form-control'
															placeholderText="Select Date"
															required
														/>
													</Form.Group>
												</Col>
											</Row>
										</div>
									)
								}


								<Row className="my-3">
									<Col className="my-3" lg={6}>
										<span className="fw-bold">Case Feedback</span>
										<Form.Group controlId="outcome">
											<Form.Label className="fw-bold">Anticipated Outcome:</Form.Label>
											<Form.Control
												as="select"
												name="outcome"
												value={formData.outcome}
												onChange={e => setFormData({ ...formData, outcome: e.target.value })}
											>
												<option value="">Select Outcome</option>
												<option value="Favorable">Favorable</option>
												<option value="Partially Favorable">Partially Favorable</option>
												<option value="Unfavorable">Unfavorable</option>
												<option value="Hearing Not Conducted">Hearing Not Conducted</option>
											</Form.Control>
										</Form.Group>

										{
											['Partially Favorable', 'Unfavorable'].includes(formData.outcome) && (
												<div>
													<Form.Group controlId="appeal">
														<Form.Label>Should the case be appealed?</Form.Label>
														<Form.Check
															type="radio"
															label="Yes"
															required
															name="appeal"
															id="appeal-yes"
															value={true}
															checked={formData.appeal === true}
															onChange={(e) => setFormData({ ...formData, appeal: true })}
														/>
														<Form.Check
															type="radio"
															label="No"
															required
															name="appeal"
															id="appeal-no"
															value={false}
															checked={formData.appeal === false}
															onChange={(e) => setFormData({ ...formData, appeal: false })}
														/>
													</Form.Group>

													{
														formData.appeal && (
															<Form.Group controlId="appeal_type">
																<Form.Label>Appeal Type:</Form.Label>
																<Form.Control
																	as="select"
																	name="appeal_type"
																	value={formData.appeal_type}
																	onChange={e => setFormData({ ...formData, appeal_type: e.target.value })}
																>
																	<option value="">Select Appeal Type</option>
																	<option value="Short Form">Short Form</option>
																	<option value="Long Form">Long Form</option>
																</Form.Control>
															</Form.Group>
														)
													}
												</div>
											)
										}

										{
											['Favorable', 'Partially Favorable', 'Unfavorable'].includes(formData.outcome) && (
												<div>
													<Form.Group controlId="brief">
														<Form.Label>Did the judge desire/require a brief?</Form.Label>
														<br />
														<Form.Check
															type="radio"
															inline
															label="Yes"
															required
															id="brief_yes"
															name="brief"
															value={true}
															checked={formData.brief === true}
															onChange={(e) => setFormData({ ...formData, brief: true })}
														/>
														<Form.Check
															type="radio"
															inline
															label="No"
															required
															name="brief"
															id="brief_no"
															value={false}
															checked={formData.brief === false}
															onChange={(e) => setFormData({ ...formData, brief: false })}
														/>
													</Form.Group>
													<Form.Group controlId="questioning">
														<Form.Label>Did the judge to the questioning or were you allowed to question the claimant?</Form.Label>
														<br />
														<Form.Check
															type="radio"
															inline
															label="Yes"
															required
															name="questioning"
															id="questioning_yes"
															value={true}
															checked={formData.questioning === true}
															onChange={(e) => setFormData({ ...formData, questioning: true })}
														/>
														<Form.Check
															type="radio"
															inline
															label="No"
															required
															name="questioning"
															id="questioning_no"
															value={false}
															checked={formData.questioning === false}
															onChange={(e) => setFormData({ ...formData, questioning: false })}
														/>
													</Form.Group>

													<Form.Group controlId="length">
														<Form.Label>About how long was the hearing?</Form.Label>
														<Form.Control
															as="select"
															name="length"
															value={formData.length}
															onChange={e => setFormData({ ...formData, length: e.target.value })}
														>
															<option value="">Select Length</option>
															<option value="15 Minutes">15 Minutes</option>
															<option value="30 Minutes">30 Minutes</option>
															<option value="45 Minutes">45 Minutes</option>
															<option value="1 Hour">1 Hour</option>
															<option value="1 Hour 15 Minutes">1 Hour 15 Minutes</option>
															<option value="1 Hour 30 Minutes">1 Hour 30 Minutes</option>
															<option value="1 Hour 45 Minutes">1 Hour 45 Minutes</option>
															<option value="2 Hours">2 Hours</option>
															<option value="Over 2 Hours">Over 2 Hours</option>
														</Form.Control>
													</Form.Group>
													<Form.Group controlId="opening_statement">
														<Form.Label>Were you allowed or given the opportunity for an opening statement?</Form.Label>
														<br />
														<Form.Check
															type="radio"
															inline
															label="Yes"
															required
															name="opening_statement"
															id="opening_statement_yes"
															value={true}
															checked={formData.opening_statement === true}
															onChange={(e) => setFormData({ ...formData, opening_statement: true })}
														/>
														<Form.Check
															type="radio"
															inline
															label="No"
															required
															name="opening_statement"
															id="opening_statement_no"
															value={false}
															checked={formData.opening_statement === false}
															onChange={(e) => setFormData({ ...formData, opening_statement: false })}
														/>
													</Form.Group>
													<Form.Group controlId="closing_statement">
														<Form.Label>Were you allowed or given the opportunity for a closing statement?</Form.Label>
														<br />
														<Form.Check
															type="radio"
															inline
															label="Yes"
															required
															name="closing_statement"
															id="closing_statement_yes"
															value={true}
															checked={formData.closing_statement === true}
															onChange={(e) => setFormData({ ...formData, closing_statement: true })}
														/>
														<Form.Check
															type="radio"
															inline
															label="No"
															required
															name="closing_statement"
															id="closing_statement_no"
															value={false}
															checked={formData.closing_statement === false}
															onChange={(e) => setFormData({ ...formData, closing_statement: false })}
														/>
													</Form.Group>
													<Form.Group controlId="stickler">
														<Form.Label>Did the judge seem like a stickler for details (i.e. Juris Docs, Employment History, Substance Abuse)?</Form.Label>
														<br />
														<Form.Check
															type="radio"
															inline
															label="Yes"
															required
															name="stickler"
															id="stickler_yes"
															value={true}
															checked={formData.stickler === true}
															onChange={(e) => setFormData({ ...formData, stickler: true })}
														/>
														<Form.Check
															type="radio"
															inline
															label="No"
															required
															name="stickler"
															id="stickler_no"
															value={false}
															checked={formData.stickler === false}
															onChange={(e) => setFormData({ ...formData, stickler: false })}
														/>
													</Form.Group>
													<Form.Group controlId="judge_feedback">
														<Form.Label>Is there something you would like to add about this judge that could be helpful to know?</Form.Label>
														<Form.Control
															as="textarea"
															rows={3}
															name="judge_feedback"
															value={formData.judge_feedback}
															onChange={(e) => setFormData({ ...formData, judge_feedback: e.target.value })}
														/>
													</Form.Group>
												</div>
											)
										}
									</Col>
									<Col className="my-3" lg={6}>
										<span className="fw-bold">Firm Feedback</span>
										<p>
											For the following questions, please use the following scale: 1 – Poor; 2 – Below Average; 3 – Average; 4 – Good; 5 – Exceptional
										</p>
										<Form.Group controlId="claimant_satisfaction">
											<Form.Label>How would you rate the claimant’s satisfaction with this firm?</Form.Label>
											<br />
											<Form.Check
												type="radio"
												inline
												required
												name="claimant_satisfaction"
												value={1}
												label="1"
												checked={formData.claimant_satisfaction === 1}
												onChange={(e) => setFormData({ ...formData, claimant_satisfaction: 1 })}
											/>
											<Form.Check
												type="radio"
												inline
												required
												name="claimant_satisfaction"
												value={2}
												label="2"
												checked={formData.claimant_satisfaction === 2}
												onChange={(e) => setFormData({ ...formData, claimant_satisfaction: 2 })}
											/>
											<Form.Check
												type="radio"
												inline
												required
												name="claimant_satisfaction"
												value={3}
												label="3"
												checked={formData.claimant_satisfaction === 3}
												onChange={(e) => setFormData({ ...formData, claimant_satisfaction: 3 })}
											/>
											<Form.Check
												type="radio"
												inline
												required
												name="claimant_satisfaction"
												value={4}
												label="4"
												checked={formData.claimant_satisfaction === 4}
												onChange={(e) => setFormData({ ...formData, claimant_satisfaction: 4 })}
											/>
											<Form.Check
												type="radio"
												inline
												required
												name="claimant_satisfaction"
												value={5}
												label="5"
												checked={formData.claimant_satisfaction === 5}
												onChange={(e) => setFormData({ ...formData, claimant_satisfaction: 5 })}
											/>
										</Form.Group>
										<Form.Group controlId="claimant_concerns">
											<Form.Label>Did the claimant voice any specific concerns about the firm?</Form.Label>
											<br />
											<Form.Check
												type="radio"
												inline
												required
												label="Yes"
												id="claimant_concerns_yes"
												name="claimant_concerns"
												value={true}
												checked={formData.claimant_concerns === true}
												onChange={(e) => setFormData({ ...formData, claimant_concerns: true })}
											/>
											<Form.Check
												type="radio"
												inline
												required
												label="No"
												id="claimant_concerns_no"
												name="claimant_concerns"
												value={false}
												checked={formData.claimant_concerns === false}
												onChange={(e) => setFormData({ ...formData, claimant_concerns: false })}
											/>
										</Form.Group>
										<Form.Group controlId="judge_satisfaction">
											<Form.Label>How would you rate the judge’s satisfaction with this firm?</Form.Label>
											<br />
											<Form.Check
												type="radio"
												inline
												required
												id="judge_satisfaction_1"
												name="judge_satisfaction"
												value={1}
												label="1"
												checked={formData.judge_satisfaction === 1}
												onChange={(e) => setFormData({ ...formData, judge_satisfaction: 1 })}
											/>
											<Form.Check
												type="radio"
												inline
												required
												id="judge_satisfaction_2"
												name="judge_satisfaction"
												value={2}
												label="2"
												checked={formData.judge_satisfaction === 2}
												onChange={(e) => setFormData({ ...formData, judge_satisfaction: 2 })}
											/>
											<Form.Check
												type="radio"
												inline
												required
												id="judge_satisfaction_3"
												name="judge_satisfaction"
												value={3}
												label="3"
												checked={formData.judge_satisfaction === 3}
												onChange={(e) => setFormData({ ...formData, judge_satisfaction: 3 })}
											/>
											<Form.Check
												type="radio"
												inline
												required
												id="judge_satisfaction_4"
												name="judge_satisfaction"
												value={4}
												label="4"
												checked={formData.judge_satisfaction === 4}
												onChange={(e) => setFormData({ ...formData, judge_satisfaction: 4 })}
											/>
											<Form.Check
												type="radio"
												inline
												required
												id="judge_satisfaction_5"
												name="judge_satisfaction"
												value={5}
												label="5"
												checked={formData.judge_satisfaction === 5}
												onChange={(e) => setFormData({ ...formData, judge_satisfaction: 5 })}
											/>
										</Form.Group>
										<Form.Group controlId="judge_concerns">
											<Form.Label>Did the judge voice any specific concerns about the firm?</Form.Label>
											<br />
											<Form.Check
												type="radio"
												inline
												id='judge_concerns_yes'
												required
												label="Yes"
												name="judge_concerns"
												value={true}
												checked={formData.judge_concerns === true}
												onChange={(e) => setFormData({ ...formData, judge_concerns: true })}
											/>
											<Form.Check
												type="radio"
												inline
												id='judge_concerns_no'
												required
												label="No"
												name="judge_concerns"
												value={false}
												checked={formData.judge_concerns === false}
												onChange={(e) => setFormData({ ...formData, judge_concerns: false })}
											/>
										</Form.Group>

										<Form.Group controlId="viability">
											<Form.Label>How would you have rated this claim’s viability prior to hearing?</Form.Label>
											<br />
											<Form.Check
												type="radio"
												inline
												required
												id="viability_1"
												name="viability"
												value={1}
												label="1"
												checked={formData.viability === 1}
												onChange={(e) => setFormData({ ...formData, viability: 1 })}
											/>
											<Form.Check
												type="radio"
												inline
												required
												id="viability_2"
												name="viability"
												value={2}
												label="2"
												checked={formData.viability === 2}
												onChange={(e) => setFormData({ ...formData, viability: 2 })}
											/>
											<Form.Check
												type="radio"
												inline
												required
												id="viability_3"
												name="viability"
												value={3}
												label="3"
												checked={formData.viability === 3}
												onChange={(e) => setFormData({ ...formData, viability: 3 })}
											/>
											<Form.Check
												type="radio"
												inline
												required
												id="viability_4"
												name="viability"
												value={4}
												label="4"
												checked={formData.viability === 4}
												onChange={(e) => setFormData({ ...formData, viability: 4 })}
											/>
											<Form.Check
												type="radio"
												inline
												required
												id="viability_5"
												name="viability"
												value={5}
												label="5"
												checked={formData.viability === 5}
												onChange={(e) => setFormData({ ...formData, viability: 5 })}
											/>
										</Form.Group>
										<Form.Group controlId="organization">
											<Form.Label>How would you rate the case file’s preparedness with regard to medical records, brief, file organization, etc.?</Form.Label>
											<br />
											<Form.Check
												type="radio"
												inline
												required
												id="organization_1"
												name="organization"
												value={1}
												label="1"
												checked={formData.organization === 1}
												onChange={(e) => setFormData({ ...formData, organization: 1 })}
											/>
											<Form.Check
												type="radio"
												inline
												required
												id="organization_2"
												name="organization"
												value={2}
												label="2"
												checked={formData.organization === 2}
												onChange={(e) => setFormData({ ...formData, organization: 2 })}
											/>
											<Form.Check
												type="radio"
												inline
												required
												id="organization_3"
												name="organization"
												value={3}
												label="3"
												checked={formData.organization === 3}
												onChange={(e) => setFormData({ ...formData, organization: 3 })}
											/>
											<Form.Check
												type="radio"
												inline
												required
												id="organization_4"
												name="organization"
												value={4}
												label="4"
												checked={formData.organization === 4}
												onChange={(e) => setFormData({ ...formData, organization: 4 })}
											/>
											<Form.Check
												type="radio"
												inline
												required
												id="organization_5"
												name="organization"
												value={5}
												label="5"
												checked={formData.organization === 5}
												onChange={(e) => setFormData({ ...formData, organization: 5 })}
											/>
										</Form.Group>
										<Form.Group controlId="missing_records">
											<Form.Label>Were there any records relevant to the case that were not attended to the file?</Form.Label>
											<br />
											<Form.Check
												type="radio"
												inline
												required
												id="missing_records_yes"
												name="missing_records"
												value="yes"
												label="Yes"
												checked={formData.missing_records === true}
												onChange={(e) => setFormData({ ...formData, missing_records: true })}
											/>
											<Form.Check
												type="radio"
												inline
												required
												id="missing_records_no"
												name="missing_records"
												value="no"
												label="No"
												checked={formData.missing_records === false}
												onChange={(e) => setFormData({ ...formData, missing_records: false })}
											/>
										</Form.Group>
										<Form.Group controlId="responsiveness">
											<Form.Label>How would you rate the firm’s responsiveness?</Form.Label>
											<br />
											<Form.Check
												type="radio"
												inline
												required
												id="responsiveness_1"
												name="responsiveness"
												value={1}
												label="1"
												checked={formData.responsiveness === 1}
												onChange={(e) => setFormData({ ...formData, responsiveness: 1 })}
											/>
											<Form.Check
												type="radio"
												inline
												required
												id="responsiveness_2"
												name="responsiveness"
												value={2}
												label="2"
												checked={formData.responsiveness === 2}
												onChange={(e) => setFormData({ ...formData, responsiveness: 2 })}
											/>
											<Form.Check
												type="radio"
												inline
												required
												id="responsiveness_3"
												name="responsiveness"
												value={3}
												label="3"
												checked={formData.responsiveness === 3}
												onChange={(e) => setFormData({ ...formData, responsiveness: 3 })}
											/>
											<Form.Check
												type="radio"
												inline
												required
												id="responsiveness_4"
												name="responsiveness"
												value={4}
												label="4"
												checked={formData.responsiveness === 4}
												onChange={(e) => setFormData({ ...formData, responsiveness: 4 })}
											/>
											<Form.Check
												type="radio"
												inline
												required
												id="responsiveness_5"
												name="responsiveness"
												value={5}
												label="5"
												checked={formData.responsiveness === 5}
												onChange={(e) => setFormData({ ...formData, responsiveness: 5 })}
											/>
										</Form.Group>
										<Form.Group controlId="improvement">
											<Form.Label>Is there something else the firm could have done to improve this case?</Form.Label>
											<Form.Control
												as="textarea"
												rows={3}
												required={
													(
														formData.claimant_satisfaction <= 2 ||
														formData.judge_satisfaction <= 2 ||
														formData.organization <= 2 ||
														formData.responsiveness <= 2 ||
														formData.judge_concerns === true ||
														formData.claimant_concerns === true
													) ? true : false
												}
												value={formData.improvement}
												onChange={(e) => setFormData({ ...formData, improvement: e.target.value })}
											/>
										</Form.Group>

										{
											(
												['Favorable', 'Partially Favorable', 'Unfavorable'].includes(formData.outcome) &&
												(
													<div>
														<Form.Group controlId="affirmation_1">
															<Form.Label>I have signed and uploaded an executed Form SSA-1696 and Fee Agreement.  I have personally attended this hearing for the above-mentioned claimant.  I have conducted myself in an ethical and businesslike manner and complied with the rules of my state bar while handling this case.</Form.Label>
															<br />
															<Form.Check
																type="checkbox"
																required
																id="affirmation_1"
																name="affirmation_1"
																value={true}
																label="I affirm this statement."
																checked={formData.affirmation_1 === true}
																onChange={(e) => setFormData({ ...formData, affirmation_1: true })}
															/>
														</Form.Group>
													</div>
												)
											)
										}

										{
											(
												['Hearing Not Conducted'].includes(formData.outcome) &&
												(
													<div>
														<Form.Group controlId="affirmation_2">
															<Form.Label>I have explained in detail why the hearing was continued or not conducted.  I have conducted myself in an ethical and businesslike manner and complied with the rules of my state bar while handling this case.</Form.Label>
															<br />
															<Form.Check
																type="checkbox"
																required
																id="affirmation_2"
																name="affirmation_2"
																value={true}
																label="I affirm this statement."
																checked={formData.affirmation_2 === true}
																onChange={(e) => setFormData({ ...formData, affirmation_2: true })}
															/>
														</Form.Group>
													</div>
												)
											)
										}
									</Col>
								</Row>
							</div>
					}


				</Modal.Body>
				<Modal.Footer>
					<Button variant="outline-secondary" onClick={handleClose}>Close</Button>

					{
						!loading &&
						(selectedHearing?.post_hearing_summary_id && (JSON.stringify(formData) !== JSON.stringify(previousFormData)) ? (
							<>
								{
									submitting ?
										<Button variant="primary" type="submit" disabled>
											<Spinner
												as="span"
												animation="border"
												size="sm"
												role="status"
												aria-hidden="true"
											/>
											Updating...
										</Button>
										:
										<Button variant="primary" type="submit">
											Save and Exit
										</Button>
								}
							</>
						) : (
							<>
								{
									submitting ?
										<Button variant="primary" type="submit" disabled>
											<Spinner
												as="span"
												animation="border"
												size="sm"
												role="status"
												aria-hidden="true"
											/>
											Saving...
										</Button>
										:
										<Button variant="primary" type="submit">
											Save
										</Button>
								}
							</>
						))
					}
				</Modal.Footer>
			</Form>
		</Modal>
	);
};

export default PostHearingSummary;