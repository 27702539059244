import React, { useState, useEffect } from 'react';
import IconButton from 'components/common/IconButton';
import { toast } from 'react-toastify';
import { claimantEmploymentHistoryAPI } from 'utils/api/fulcrum-api';
import EmployerRow from './EmployerRow';
import { ListGroup } from 'react-bootstrap';

const EmployerList = ({ claimant, setClaimant }) => {
	const [loading, setLoading] = useState(true);
	const [employers, setEmployers] = useState([]);

	// Get the employers
	const getClaimantEmployers = async () => {
		try {
			let result = await claimantEmploymentHistoryAPI.getAllClaimantEmployers(claimant.id);
			if (!result?.data) {
				throw new Error('No claimant employer data returned');
			}
			setLoading(false);
			setEmployers(result.data);
		} catch (error) {
			console.error(error);
		}
	};

	// Add a new employer
	const addClaimantEmployer = async () => {
		try {
			let result = await claimantEmploymentHistoryAPI.createClaimantEmployer(claimant.id, {});
			if (!result?.data) {
				throw new Error('No data returned from server');
			}
			// setEmployers([...employers, result.data]);
			getClaimantEmployers();
		} catch (error) {
			console.log(error);
			toast.error('Error adding employer.', { theme: 'colored' });
		};
	};

	useEffect(() => {
		getClaimantEmployers();
	}, []);

	if (loading) {
		return <div>Loading...</div>;
	}

	return (
		<div id="claimant-current-income-sourcesr" className="my-3">
			<h5 className="d-inline">Employment History</h5>
			<IconButton
				className="me-2 mb-1 float-end"
				variant="falcon-default"
				size="sm"
				icon="plus"
				transform="shrink-3"
				onClick={addClaimantEmployer}
			>
				Add Employer
			</IconButton>
			<p>Please list your employment history.</p>

			<ListGroup>
				{employers.map((employer, index) => (
					<ListGroup.Item key={index}>
						<EmployerRow
							employer={employer}
							index={index}
							claimant={claimant}
							setClaimant={setClaimant}
							getClaimantEmployers={getClaimantEmployers}
						/>
					</ListGroup.Item>
				))}
			</ListGroup>
		</div>
	);
};


export default EmployerList;