import React, { useEffect, useState } from 'react';
import { myFormsAPI, documentAPI } from 'utils/api/fulcrum-api';
import { useParams, useNavigate, Link } from 'react-router-dom';
import MyFormEditor from './MyFormEditor';
import MyFormContentEditor from './MyFormContentEditor';
import { toast } from 'react-toastify';
import { Card, Dropdown, Button } from 'react-bootstrap';
import CardDropdown from 'components/common/CardDropdown';
import Swal from 'sweetalert2';

const MyFormBuilder = () => {
	const navigate = useNavigate();
	let { id: myFormId } = useParams();
	const [myForm, setMyForm] = useState({});
	const [loading, setLoading] = useState(true);
	const [documentTypes, setDocumentTypes] = useState([]);

	// Delete My Form
	const deleteMyForm = async (id) => {
		Swal.fire({
			title: 'Permanently delete this form?',
			text: "You won't be able to revert this!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Yes, delete it!'
		}).then(async (result) => {
			if (result.isConfirmed) {
				try {
					let result = await myFormsAPI.deleteMyForm(id);
					if (!result?.message) {
						throw new Error('Error deleting form');
					}
					toast.warning(result.message, {
						theme: 'colored'
					});
					navigate('/my-forms');
				} catch (error) {
					toast.error(error.message, {
						theme: 'colored'
					});
				}
			}
		});
	};


	// Get related data @TODO: Move this to a context
	const getRelatedData = async () => {
		try {
			let docTypeResults = await documentAPI.searchDocumentTypes();
			if (!docTypeResults?.data?.rows) {
				throw new Error('Unable to get documents types.');
			}

			setDocumentTypes(docTypeResults.data.rows);
		} catch (error) {
			alert(error);
		}
	};

	// Get My Form
	const getMyForm = async (id) => {
		try {
			let result = await myFormsAPI.getMyForm(id);
			if (!result?.data) { throw new Error(`Error getting My Form by ${id}.`); }
			// if (!result?.data?.account_id) {
			// 	setMyFormContent(result.data.content);
			// 	toast.error('Unable to find the form.', { theme: 'colored' });
			// 	navigate('/my-forms');
			// }
			setMyForm(result.data);
			setLoading(false);
		} catch (error) {
			console.error(error);
			toast.error('Error getting My Form.', { theme: 'colored' });
			navigate('/my-forms');
		}
	};

	useEffect(() => {
		getMyForm(myFormId);
	}, []);

	useEffect(() => {
		setLoading(true);
		getMyForm(myFormId);
		getRelatedData();
	}, [myFormId]);

	if (loading) {
		return (
			<div className="d-flex justify-content-center align-items-center">
				<div className="spinner-border text-primary" role="status">
					<span className="sr-only">Loading...</span>
				</div>
			</div>
		);
	}

	return (
		<div>
			<Card>

				<Card.Header className="border-bottom">
					<div className="mb-3">
						<Link to="/my-forms" className="">Back to My Forms</Link>
					</div>
					<div className="d-flex justify-content-between">
						<div>
							<h5 className="mb-0">My Form Editor</h5>
						</div>
						<div>
							<CardDropdown>
								<div className="py-2">
									<Dropdown.Item
										onClick={() => deleteMyForm(myForm.id)}
									>
										<span>Delete</span>
									</Dropdown.Item>
								</div>
							</CardDropdown>
						</div>
					</div>
				</Card.Header>
				<Card.Body>
					<MyFormEditor
						selectedForm={myForm}
						setSelectedForm={setMyForm}
						documentTypes={documentTypes}
					/>
					<hr />
					<MyFormContentEditor
						selectedForm={myForm}
						setSelectedForm={setMyForm}
						documentTypes={documentTypes}
					/>
				</Card.Body>
			</Card>
		</div>
	);
};

export default MyFormBuilder;