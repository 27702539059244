import React, { useState, useEffect } from 'react';
import IconButton from 'components/common/IconButton';
import { toast } from 'react-toastify';
import { claimantOtherHouseholdMemberAPI } from 'utils/api/fulcrum-api';
import ClaimantOtherHouseholdRow from './ClaimantOtherHouseholdRow';
import { Table } from 'react-bootstrap';

const ClaimantOtherHouseholdList = ({ claimant, setClaimant }) => {
	const [loading, setLoading] = useState(true);
	const [otherHouseholdMembers, setOtherHouseholdMembers] = useState([]);

	// Get the otherHouseholdMembers
	const getClaimantOtherHouseholdMembers = async () => {
		try {
			let result = await claimantOtherHouseholdMemberAPI.getAllClaimantOtherHouseholdMembers(claimant.id);
			if (!result?.data) {
				throw new Error('No claimant other household member data returned');
			}
			setLoading(false);
			setOtherHouseholdMembers(result.data);
		} catch (error) {
			console.error(error);
		}
	};

	// Add a new household member
	const addHouseholdMember = async () => {
		try {
			let result = await claimantOtherHouseholdMemberAPI.createClaimantOtherHouseholdMember(claimant.id, {
				name: '',
				relationship: '',
			});
			if (!result?.data) {
				throw new Error('No data returned from server');
			}
			// setOtherHouseholdMembers([...otherHouseholdMembers, result.data]);
			getClaimantOtherHouseholdMembers();
		} catch (error) {
			console.log(error);
			toast.error('Error adding household member.', { theme: 'colored' });
		};
	};

	useEffect(() => {
		getClaimantOtherHouseholdMembers();
	}, []);

	if (loading) {
		return <div>Loading...</div>;
	}

	return (
		<div id="claimant-current-other-household-members" className="my-3">
			<h5 className="d-inline">Other Household Members</h5>
			<IconButton
				className="me-2 mb-1 float-end"
				variant="falcon-default"
				size="sm"
				icon="plus"
				transform="shrink-3"
				onClick={addHouseholdMember}
			>
				Add Member
			</IconButton>
			<p>Please list other members in your household.</p>
			<Table>
				<thead>
					<tr>
						<th>Name</th>
						<th>Relationship</th>
						<th>

						</th>
					</tr>
				</thead>
				<tbody>
					{
						(otherHouseholdMembers.length > 0)
							?
							otherHouseholdMembers.map((member, index) => (
								<tr key={index}>
									<ClaimantOtherHouseholdRow
										member={member}
										claimant={claimant}
										setClaimant={setClaimant}
										getClaimantOtherHouseholdMembers={getClaimantOtherHouseholdMembers}
									/>
								</tr>
							))
							:
							<tr>
								<td colSpan="3">No household members.</td>
							</tr>
					}

				</tbody>
			</Table>
		</div>
	);
};


export default ClaimantOtherHouseholdList;