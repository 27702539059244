import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import IconButton from 'components/common/IconButton';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import { representativeAdmittedAPI } from 'utils/api/fulcrum-api';

const AdmittedRow = ({ user, log, admittedLog, setAdmittedLog = () => { }, getRepresentativeAdmittedLog = () => { } }) => {

	let defaultFormData = {
		bar: log.bar,
		year: log.year,
		bar_number: log.bar_number
	};
	const [formData, setFormData] = useState(defaultFormData);
	const [previousFormData, setPreviousFormData] = useState(defaultFormData);

	// Handle input change
	const handleInputChange = (e) => {
		let { name, value } = e.target;
		setFormData({ ...formData, [name]: value });
	};

	// Update a admitted log item
	const updateAdmitted = async (data) => {
		try {
			let result = await representativeAdmittedAPI.updateRepresentativeAdmitted(user.id, log.id, data);
			if (!result?.data) {
				throw new Error('Error updating admitted log item');
			}
			// getRepresentativeAdmittedLog();
			setAdmittedLog(admittedLog.map((item) => {
				if (item.id === log.id) {
					return result.data;
				}
				return item;
			}));
			setPreviousFormData(formData);
		} catch (error) {
			console.error(error);
			toast.error('Error updating admitted log item', { theme: 'colored' });
		}
	};

	// Add a new admitted log item
	const deleteAdmittedLog = async () => {
		Swal.fire({
			title: 'Permanently delete admitted log item?',
			text: "You won't be able to revert this!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Yes, delete it!'
		}).then(async (result) => {
			if (result.isConfirmed) {
				try {

					let result = await representativeAdmittedAPI.deleteRepresentativeAdmitted(user.id, log.id);
					if (!result?.message) {
						throw new Error('Error deleting admitted log item');
					}
					// getRepresentativeAdmittedLog();
					setAdmittedLog(admittedLog.filter((item) => item.id !== log.id));
					toast.warning('Admitted log item deleted', { theme: 'colored' });
				} catch (error) {
					console.error(error);
					toast.error('Error deleting admitted log item', { theme: 'colored' });
				}
			}
		});
	};

	// Save on blur
	const saveOnBlur = (e) => {
		let { name, value } = e.target;

		if (previousFormData[name] !== value) {
			updateAdmitted({ [name]: value });
			setPreviousFormData({ ...previousFormData, [name]: value });
		}
		setFormData({ ...formData, [name]: value });
	}


	return (
		<>
			<td>
				<Form.Control
					type="text"
					size="sm"
					name="bar"
					value={formData.bar}
					onChange={handleInputChange}
					onBlur={saveOnBlur}
				/>
			</td>
			<td>
				<Form.Control
					type="text"
					size="sm"
					name="year"
					value={formData.year}
					onChange={handleInputChange}
					onBlur={saveOnBlur}
				/>
			</td>
			<td>
				<Form.Control
					type="text"
					size="sm"
					name="bar_number"
					value={formData.bar_number}
					onChange={handleInputChange}
					onBlur={saveOnBlur}
				/>
			</td>
			<td>
				<IconButton
					className="me-2 mb-1 float-end"
					variant="falcon-danger"
					size="sm"
					icon="trash"
					transform="shrink-3"
					onClick={() => deleteAdmittedLog()}
				>
					Delete
				</IconButton>
			</td>
		</>
	);
};


export default AdmittedRow;