import React from 'react';
import ClaimantFolderTable from 'components/claimants/ClaimantFolderTable';
import auth from 'utils/auth';

const ClaimantFolders = () => {
	return (
		<>
			<ClaimantFolderTable
				requiredSearchParams={{
					account_id: auth.getProfile().data.account_id
				}}
			/>
		</>
	);
};

export default ClaimantFolders;