export default [
  {
    description:
      "The files are always well prepared and complete. Having everything on one website makes remote access easy even in the hearing itself. I do not need to lug around a suitcase full of files, and I can usually get in and out quickly. Payment is always timely, and in general working with Assure is hassle-free.",
    author: 'Matt, ESQ.',
    designation: '',
    companyImage: null,
    size: 45
  },
  {
    description:
      "Assure Disability completely revolutionized our Social Security practice. Filing documents with the Agency and downloading ERE files is quicker and simpler than using the SSA system. ERE files have a linked table of contents and are automatically OCR’d, making hearing preparation so much easier. The briefs and briefing tool cuts hearing preparation time in half and utilizing the system for medical records requests is a breeze. There is before Assure, and there is after Assure for our firm. The change has been amazing!",
    author: 'Adam S',
    designation: 'Cooper Law LLC',
    companyImage: null,
    size: 30
  },
  {
    description:
      'Doing Social Security Disability hearings for Assure has been an amazingly fulfilling professional experience. It is very rewarding to be the "feet on the ground" at hearings for Claimants. Claimants appreciate someone local to lean on for support; and I completely appreciated the fact that I can always rely on Assure for well developed, accessible information regarding each of the hearings I attend.',
    author: 'Joanna',
    designation: '',
    companyImage: null,
    size: 45
  },
  {
    description:
      'I have worked with Assure Disability for a number of years. Through the network, I have covered a large number of hearings. The Assure network makes it possible to supplement my existing practice and earn extra money. The website is very efficient and extremely user friendly. I am also able to be paid almost instantaneously for my work. It has been an excellent experience and I look forward to working with the network for years to come.',
    author: 'Jared, ESQ.',
    designation: '',
    companyImage: null,
    size: 45
  }
];
