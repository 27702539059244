import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Card, Form, Nav, ProgressBar } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useForm } from 'react-hook-form';
import TermsOfService from '../TermsOfService';
import AccountForm from './AccountForm';
import StripePaymentForm from '../../stripe/StripePaymentForm';
import SetPassword from './SetPassword';
import AccessCodeForm from '../AccessCodeForm';
import Success from './Success';
import AppContext, { AuthWizardContext } from 'context/Context';
import IconButton from 'components/common/IconButton';
import WizardModal from '../WizardModal';
import Flex from 'components/common/Flex';
import { accessVerificationAPI, accountAPI, userAPI } from 'utils/api/fulcrum-api';
import { toast } from 'react-toastify';
import { validateEmail } from 'utils/functions';

const RegistrationWizardLayout = ({ variant, validation, progressBar }) => {
  const navigate = useNavigate();
  const { isRTL } = useContext(AppContext);
  const [accessVerificationData, setAccessVerificationData] = useState({});
  const [showIncorrectAccessCodeToast, setShowIncorrectAccessCodeToast] = useState(false);
  const [cardInformationValid, setCardInformationValid] = useState(false);
  const { user, setUser, step, setStep } = useContext(AuthWizardContext);
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    useWatch,
    setValue,
    getValues,
    setError,
    clearErrors
  } = useForm();

  const [modal, setModal] = useState(false);

  const navItems = [
    {
      icon: 'file',
      label: 'Terms of Service'
    },
    {
      icon: 'user',
      label: 'Account'
    },
    {
      icon: 'lock',
      label: 'Verification'
    },
    {
      icon: 'lock',
      label: 'Set Password'
    },
    {
      icon: 'dollar-sign',
      label: 'Billing'
    },
    {
      icon: 'thumbs-up',
      label: 'Done'
    }
  ];

  // Runs after all steps are completed at the last step on success component 
  const handleSubmitForm = async () => {
    try {
      let result = await accountAPI.registerAccount({ ...user, user_type_list: ['REPRESENTATIVE', 'ADMIN'] });

      if (!result?.message || result?.error) {
        throw new Error(`Error registering account: ${result.error}`);
      }
      if (result.message == 'Success') {
        toast.success('Account created successfully', {
          theme: 'colored',
        });
        navigate('/login');
        return;
      }
      toast.success('Account creation pending...', {
        theme: 'colored',
      });
      return;
    } catch (error) {
      toast.error('Account creation error, Please contact support.', {
        theme: 'colored',
      });
    }
  };

  // Send email verification
  const verifyEmail = async (data) => {
    try {
      // 1) Verify email is unique
      let isUniqueResult = await userAPI.verifyEmailAvailability(data.primary_email);

      if (!isUniqueResult?.data || isUniqueResult.data == false) {
        throw new Error('Email is not unique');
      }

      // 2) Send email verification
      let result = await accessVerificationAPI.sendRegistrationEmailVerification({
        email: data.primary_email
      });
      if (!result?.data) {
        throw new Error('Error sending email verification');
      }
      setAccessVerificationData(result.data);
      return true;
    } catch (error) {
      setError('system_email', { type: 'manual', message: 'Primary email is not valid and/or unique' });
      return false;
    }
  };


  // Verify system email is unique
  const verifySystemEmail = async (system_email) => {
    console.log('VERIFYING', system_email)
    try {
      const isValidEmail = await validateEmail(`${system_email}@fulcrum.com`);
      if (!isValidEmail) {
        throw new Error('Invalid email');
      }
      const isUniqueResult = await accountAPI.verifyAccountSystemEmailAvail(`${system_email}@fulcrum.com`);
      console.log(isUniqueResult)
      if (!isUniqueResult?.data || isUniqueResult.data == false) {
        console.log('NOT UNIQUE')
        throw new Error('Email is not unique');
      }

      console.log('HOW?', isUniqueResult)
      clearErrors('system_email');
      return true;
    } catch (error) {
      console.log('FAILED')
      setError('system_email', { type: 'manual', message: 'System email is not unique' });
      return false;
    }
  };



  // Verify access code
  const checkAccessCode = async (accessCode) => {
    try {
      let result = await accessVerificationAPI.verifyAccessVerificationCode(accessVerificationData.uuid, { access_key: accessCode });
      if (!result?.data) {
        throw new Error('Invalid access code');
      }
      return true;
    } catch (error) {
      console.log("checkAccessCode", error);
      return false;
    }
  };

  const onSubmitData = async data => {
    setUser({ ...user, ...data });
    let pass = false;

    // NOTE: Uncomment for testing only! Keep commented because passwords will be showing in the console
    // const watchAllFields = watch(); // All form fields are available in this object 
    // console.log("onSubmitData watchAllFields", watchAllFields); 
    // console.log(`onSubmitData Step ${step}:`, data);

    if (step === 1) { // Step 1: Terms of Service 
      pass = true;
    }
    else if (step === 2) {
      // Check email against user emails and send verification email
      let systemEmailValidationResult = await verifySystemEmail(data.system_email)
      if (systemEmailValidationResult) {
        pass = true;
      }

      if (pass) {
        pass = await verifyEmail(data);
      }

      // @todo - also reverify system email availability
    }
    else if (step === 3) {
      pass = await checkAccessCode(data.access_key);
      if (!pass) {
        setShowIncorrectAccessCodeToast(true);
      }
    }
    else if (step === 4) {
      pass = true;
    }
    else if (step === 5) {
      pass = cardInformationValid;
    }
    else { // Step 6: Success
      pass = true;
    }

    if (pass) { // Only move on to the next step if current step passes validation
      setStep(step + 1);
    }
    return;
  };

  const onError = () => {
    if (!validation) {
      clearErrors();
      setStep(step + 1);
    }
  };

  const toggle = () => setModal(!modal);

  const handleNavs = targetStep => {
    if (step !== 4) {
      if (targetStep < step) {
        setStep(targetStep);
      } else {
        handleSubmit(onSubmitData, onError)();
      }
    } else {
      toggle();
    }
  };

  // StripePaymentForm init={initializePaymentForm}
  const initializePaymentForm = () => {
    setValue('billing_address_address', user.business_mailing_address);
    setValue('billing_address_city', user.business_mailing_city);
    setValue('billing_address_state', user.business_mailing_state);
    setValue('billing_address_zip', user.business_mailing_zip);
  };

  return (
    <>
      {/* // @todo - adjust this modal size */}
      <WizardModal modal={modal} setModal={setModal} />
      <Card
        as={Form}
        noValidate
        onSubmit={handleSubmit(onSubmitData, onError)}
        className="theme-wizard mb-5"
      >
        <Card.Header
          className={classNames('bg-light', {
            'px-4 py-3': variant === 'pills',
            'pb-2': !variant
          })}
        >
          <Nav className="justify-content-center" variant={variant}>
            {variant === 'pills'
              ? navItems.map((item, index) => (
                <NavItemPill
                  key={item.label}
                  index={index + 1}
                  step={step}
                  handleNavs={handleNavs}
                  icon={item.icon}
                  label={item.label}
                />
              ))
              : navItems.map((item, index) => (
                <NavItem
                  key={item.label}
                  index={index + 1}
                  step={step}
                  handleNavs={handleNavs}
                  icon={item.icon}
                  label={item.label}
                />
              ))}
          </Nav>
        </Card.Header>
        {progressBar && <ProgressBar now={step * 25} style={{ height: 2 }} />}
        <Card.Body className="fw-normal px-md-6 py-4">
          {step === 1 && (
            <TermsOfService register={register} errors={errors} watch={watch} />
          )}
          {step === 2 && (
            <AccountForm setValue={setValue} getValues={getValues} setUser={setUser} user={user} register={register} errors={errors} setError={setError} watch={watch} clearErrors={clearErrors} />
          )}
          {step === 3 && (
            <AccessCodeForm
              uuid={accessVerificationData?.uuid || null}
              register={register}
              errors={errors}
              user={user}
              watch={watch}
              setShowIncorrectAccessCodeToast={setShowIncorrectAccessCodeToast}
              showIncorrectAccessCodeToast={showIncorrectAccessCodeToast}
            />
          )}
          {step === 4 && (
            <SetPassword
              accessVerificationData={accessVerificationData}
              register={register}
              errors={errors}
              watch={watch}
            />
          )}
          {step === 5 && (
            <StripePaymentForm
              cardInformationValid={cardInformationValid}
              setCardInformationValid={setCardInformationValid}
              useSetupIntent={false}
              useCardElement={true}
              hidePostalCode={true}
              register={register}
              errors={errors}
              watch={watch}
              useWatch={useWatch}
              setValue={setValue}
              getValues={getValues}
              setError={setError}
              clearErrors={clearErrors}
              init={initializePaymentForm}
            />
          )}
          {step === 6 && <Success handleSubmitForm={handleSubmitForm} />}
        </Card.Body>
        <Card.Footer
          className={classNames('px-md-6 bg-light', {
            'd-none': step === 6,
            ' d-flex': step < 6
          })}
        >
          <IconButton
            variant="link"
            icon={isRTL ? 'chevron-right' : 'chevron-left'}
            iconAlign="left"
            transform="down-1 shrink-4"
            className={classNames('px-0 fw-semi-bold', {
              'd-none': step === 1
            })}
            onClick={() => {
              setStep(step - 1);
            }}
          >
            Prev
          </IconButton>

          <IconButton
            variant="primary"
            className="ms-auto px-5"
            type="submit"
            icon={isRTL ? 'chevron-left' : 'chevron-right'}
            iconAlign="right"
            transform="down-1 shrink-4"
          >
            Next
          </IconButton>
        </Card.Footer>
      </Card>
    </>
  );
};

const NavItem = ({ index, step, handleNavs, icon, label }) => {
  return (
    <Nav.Item>
      <Nav.Link
        className={classNames('fw-semi-bold', {
          done: index < 6 ? step > index : step > 5,
          active: step === index
        })}
        onClick={() => handleNavs(index)}
      >
        <span className="nav-item-circle-parent">
          <span className="nav-item-circle">
            <FontAwesomeIcon icon={icon} />
          </span>
        </span>
        <span className="d-none d-md-block mt-1 fs--1">{label}</span>
      </Nav.Link>
    </Nav.Item>
  );
};

const NavItemPill = ({ index, step, handleNavs, icon, label }) => {
  return (
    <Nav.Item>
      <Nav.Link
        className={classNames('fw-semi-bold', {
          done: step > index,
          active: step === index
        })}
        onClick={() => handleNavs(index)}
      >
        <Flex alignItems="center" justifyContent="center">
          <FontAwesomeIcon icon={icon} />
          <span className="d-none d-md-block mt-1 fs--1 ms-2">{label}</span>
        </Flex>
      </Nav.Link>
    </Nav.Item>
  );
};

RegistrationWizardLayout.propTypes = {
  variant: PropTypes.oneOf(['pills']),
  validation: PropTypes.bool,
  progressBar: PropTypes.bool
};

NavItemPill.propTypes = {
  index: PropTypes.number.isRequired,
  step: PropTypes.number.isRequired,
  handleNavs: PropTypes.func.isRequired,
  icon: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired
};

NavItem.propTypes = NavItemPill.propTypes;

export default RegistrationWizardLayout;
