import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Modal, Row, Col, Alert, Spinner, Tabs, Tab, Table } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import AppContext from 'context/Context';
import { assureAPI, claimantAPI } from 'utils/api/fulcrum-api';
import { toast } from 'react-toastify';
import { removeEmptyFromObj } from 'utils/functions';
import StatusReportItemTable from '../status-report-items/StatusReportItemTable';
import moment from 'moment';
import auth from 'utils/auth';

const StatusReportRequestDetails = ({
	account_id = auth.getProfile()?.data?.account_id || null,
	modalState,
	selectedStatusReportRequest,
	setSelectedStatusReportRequest = () => { },
	setModalState = () => { },
	claimant = {},
	attorneyList = [],
	refreshParent = (() => { })
}) => {
	const {
		config: { isDark }
	} = useContext(AppContext);
	const navigate = useNavigate();
	const [loading, setLoading] = useState(true);
	const [submitting, setSubmitting] = useState(false);
	const [downloading, setDownloading] = useState(false);
	const [updateCheckInProgress, setUpdateCheckInProgress] = useState(false);
	const [errors, setErrors] = useState([]);
	const [statusReportRequest, setStatusReportRequest] = useState({});
	const defaultForm = {
	};
	const [formData, setFormData] = useState(defaultForm);
	const [prevousFormData, setPreviousFormData] = useState(defaultForm);

	// Handle Change
	const handleChange = ({ target }) => {
		let { name, value } = target;
		let additionalValues = {};
		setFormData({ ...formData, ...additionalValues, [name]: value });
	};

	// Get Status Report Request
	const getStatusReportRequest = async () => {
		try {
			let result = await assureAPI.getStatusReportRequest(account_id || null, selectedStatusReportRequest?.id || null);
			if (!result?.data) { throw new Error('Unable to get the status report request details.'); }
			setStatusReportRequest(result?.data);

		} catch (error) {
			toast.error('Unable to get the status report details.', { theme: 'colored' });
			handleClose();
		}
		setLoading(false);
	};

	// Handle update check
	const handleUpdateCheck = async () => {
		setUpdateCheckInProgress(true);
		try {
			let result = await assureAPI.checkStatusReportRequest(account_id || null, selectedStatusReportRequest?.id || null);
			if (!result?.data) { throw new Error('Unable to get the status report request details.'); }
			setStatusReportRequest(result?.data);
			toast.success('Status report request updated.', { theme: 'colored' });
		} catch (error) {
			toast.error('Unable to get an update on the status report request.', { theme: 'colored' });
		}
		setUpdateCheckInProgress(false);
	};

	// Handle download
	const handleDownload = async () => {
		setDownloading(true);
		try {
			let result = await assureAPI.downloadStatusReport(account_id || null, selectedStatusReportRequest?.id || null);
			handleUpdateCheck();
		} catch (error) {
			toast.error('Unable to download the status report.', { theme: 'colored' });
		}
		setDownloading(false);
	};

	// handle show
	const handleShow = () => {
		setSubmitting(false);
		setLoading(true);
		if (selectedStatusReportRequest?.id) {
			getStatusReportRequest();
		} else {
			handleClose();
		}
	};

	// Handle Close
	const handleClose = () => {
		setSubmitting(false);
		setFormData(defaultForm);
		setSelectedStatusReportRequest({});
		setModalState(false);
	};

	return (
		<>
			<Modal
				show={modalState}
				onHide={handleClose}
				onShow={handleShow}
				contentClassName="border"
				size='xl'
			>
				<Modal.Header
					closeButton
					closeVariant={isDark ? 'white' : undefined}
					className="bg-light px-card border-bottom-0"
				>
					<Modal.Title as="h5">Status Report Request</Modal.Title>
				</Modal.Header>
				<Modal.Body className="p-card">
					{
						loading ?
							<>
								<div>
									<Spinner animation="border" role="status">
										<span className="sr-only">Loading...</span>
									</Spinner>
								</div>
							</>
							:
							<>
								<div className="mb-4">
									<div>
										<h5 className="d-inline">Request Details</h5>

										<Button
											variant="falcon-primary"
											className="float-end"
											onClick={handleUpdateCheck}
											disabled={submitting || updateCheckInProgress}
										>
											{
												updateCheckInProgress ?
													<>
														<Spinner
															as="span"
															animation="border"
															size="sm"
															role="status"
															aria-hidden="true"
															className="mr-2"
														/>
														Checking for updates...
													</>
													:
													<>
														Check for Update
													</>
											}
										</Button>
									</div>
									<hr />
									<Table bordered responsive striped size='sm'>
										<thead className="border-none">
											<tr>
												<th className="" style={{ width: '30%' }}></th>
												<th></th>
											</tr>
										</thead>
										<tbody>
											<tr>
												<td className="border-right">Assure ID</td>
												<td className="pl-3">{statusReportRequest?.assure_id || ''}</td>
											</tr>
											<tr>
												<td>ERE Credentials ID</td>
												<td>{statusReportRequest?.ere_credentials_id || ''}</td>
											</tr>
											<tr>
												<td>Status</td>
												<td>{statusReportRequest?.status || ''}</td>
											</tr>
											<tr>
												<td>Status Reason</td>
												<td>{statusReportRequest?.status_reason || ''}</td>
											</tr>
											<tr>
												<td>Report Type</td>
												<td>{statusReportRequest?.ac_report ? "Appeals Council Report" : 'Hearing Status Report'}</td>
											</tr>
											<tr>
												<td>Started Importing</td>
												<td>{statusReportRequest?.started_at ? moment(statusReportRequest.started_at).format('MM-DD-YYYY hh:mm A') : ''}</td>
											</tr>
											{
												[5].includes(statusReportRequest?.status) &&
												<tr>
													<td>Completed</td>
													<td>{statusReportRequest?.completed_at ? moment(statusReportRequest.completed_at).format('MM-DD-YYYY hh:mm A') : ''}</td>
												</tr>
											}
											{
												statusReportRequest?.assure_id &&
												<>
													<tr>
														<td>Assure Created At</td>
														<td>{statusReportRequest?.assure_created_at ? moment(statusReportRequest.assure_created_at).format('MM-DD-YYYY hh:mm A') : ''}</td>
													</tr>
													<tr>
														<td>Assure Updated At</td>
														<td>{statusReportRequest?.assure_updated_at ? moment(statusReportRequest.assure_updated_at).format('MM-DD-YYYY hh:mm A') : ''}</td>
													</tr>
												</>
											}
											{
												statusReportRequest?.test_mode &&
												<tr>
													<td>Test Mode</td>
													<td>{statusReportRequest?.test_mode ? 'Yes' : 'No'}</td>
												</tr>
											}
											{
												statusReportRequest?.file_id &&
												<tr>
													<td>File</td>
													<td>
														{
															statusReportRequest?.file_id ?
																<>
																	<Button
																		variant="link"
																		onClick={handleDownload}
																		size="sm"
																		disabled={downloading || submitting}
																	>
																		{
																			downloading ?
																				<>
																					{statusReportRequest?.file_id}
																					<Spinner
																						as="span"
																						animation="border"
																						size="sm"
																						role="status"
																						aria-hidden="true"
																					/>
																					<span className="">Downloading...</span>
																				</>
																				:
																				<>
																					{statusReportRequest?.file_id ? "Download Status Report" : "Download and Parse Report"}
																				</>
																		}
																	</Button>
																</>
																: null

														}

													</td>
												</tr>
											}
										</tbody>
									</Table>
								</div>

								<Tabs defaultActiveKey="report-summary" id="report-tabs" className="mb-3">
									<Tab eventKey="report-summary" title="Report Summary">
										<div className="mb-4">
											<Table bordered responsive striped size='sm'>
												<thead style={{ width: '30%' }}>
													<tr>
														<th></th>
														<th></th>
													</tr>
												</thead>
												<tbody>
													<tr>
														<td>Total Rows</td>
														<td className="text-">{statusReportRequest?.total_rows || 0}</td>
													</tr>
													<tr>
														<td>Total Unique Cases</td>
														<td className="text-">{statusReportRequest?.total || 0}</td>
													</tr>
													<tr>
														<td>Total Failed</td>
														<td className="text-">{statusReportRequest?.failed || 0}</td>
													</tr>
													<tr>
														<td>Total Skipped</td>
														<td className="text-">{statusReportRequest?.skipped || 0}</td>
													</tr>
													<tr>
														<td>CRQ</td>
														<td className="text-">{statusReportRequest?.in_crq || 0}</td>
													</tr>
													<tr>
														<td>Moved</td>
														<td className="text-">{statusReportRequest?.moved || 0}</td>
													</tr>

												</tbody>
											</Table>
										</div>
									</Tab>
									<Tab eventKey="report-rows" title="Report Rows">
										<div className="mb-4">
											{
												statusReportRequest?.file_id ?
													<div>
														<StatusReportItemTable
															account_id={account_id}
															requiredSearchParams={{
																status_report_request_id: statusReportRequest.id
															}}
														/>
													</div>
													:
													<div className="p-5 m-auto bg-200 text-center align-center">
														<p className="fw-bold">Status report has not previously been downloaded and parsed.</p>

														<Button
															variant="primary"
															onClick={handleDownload}
															disabled={downloading || submitting}
														>
															{
																downloading ?
																	<>
																		<Spinner
																			as="span"
																			animation="border"
																			size="sm"
																			role="status"
																			aria-hidden="true"
																		/>
																		<span className="">Downloading...</span>
																	</>
																	:
																	<>
																		{statusReportRequest?.file_id ? "Download Report" : "Download and Parse Report"}
																	</>
															}
														</Button>
													</div>
											}
										</div>
									</Tab>

								</Tabs>
							</>
					}
				</Modal.Body>
				<Modal.Footer className="bg-light px-card border-top-0">
					<Button variant="secondary" onClick={handleClose} disabled={submitting}>
						Close
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
};

export default StatusReportRequestDetails;