import React, { forwardRef } from 'react';
import { Card } from 'react-bootstrap';

const Collected = forwardRef((props, ref) => {
	return (
		<Card className="mb-3" id="information-collected" ref={ref}>
			<Card.Header className="bg-light">
				<h5 className="mb-0">Information Collected</h5>
			</Card.Header>
			<Card.Body>
				<p className="fs-0 mb-0 fw-semi-bold">
					Assure Disability, Inc. collects information from and about clients, including:
				</p>
				<ol type="1">
					<li>Identifiers and Personal Information categories listed in the California Customer Records statute (Cal. Civ. Code § 1798.80(e)) such as a real name, alias, postal address, unique personal identifier, online identifier, Internet Protocol address, email address, account name, social security number, driver’s license or state identification card number, passport number, signature, physical characteristics or description, telephone number, insurance policy number, bank account number, credit card number, debit card number, financial information, medical information, health insurance information, or other similar identifiers,</li>
					<li>characteristics of protected classifications under California or federal law, such as age, race, ancestry, national origin, citizenship, religion, creed, marital status, medical condition, physical or mental disability, sex, sexual orientation, veteran or military status, and genetic information,</li>
					<li>commercial information, including records of personal property, products or services purchased, obtained, or considered, or other purchasing or consuming histories or tendencies,</li>
					<li>biometric information,</li>
					<li>internet or other electronic network activity information, including, but not limited to, browsing history, search history, and information regarding interactions with an Internet Web site, application, or advertisement,</li>
					<li>geolocation data,</li>
					<li>audio, electronic, and visual information,</li>
					<li>professional or employment-related information,</li>
					<li>education information, and</li>
					<li>inferences drawn from any of the aforementioned information to create a profile reflecting preferences, characteristics, and behavior.</li>
				</ol>
				<p className="mb-0 ps-3">
					Assure Disability, Inc. may collect this information directly from you when you provide it to us, or from third parties who possess the authority to disclose such information about you.
				</p>
				<p className="mb-0 ps-3">
					Assure Disability, Inc. maintains websites that use Cookies (electronic identifiers that are transferred automatically to your computer through your browser that allow our servers to save certain information you provide us and store information about you so we can recognize you when you visit our website in the future) and collect your Internet Protocol (IP) address (which allows us to understand how you use our website and to enable us to make changes to our website in order to improve your user experience).  The Assure Disability, Inc. websites collect user history to, through, and away from the websites, including your session information, page loading times, page errors, broken links, the length of your visit(s) to specific pages within the websites, search terms from web searches, search result information from web searches, and other information related to your experience on the website.  The Assure Disability, Inc. websites contains links to other sites.  Please be aware that we are not responsible for the privacy practices of other websites.  We encourage our clients to be aware when they leave our websites and to read the privacy statements of each and every website that collects information.  This Privacy Policy applies solely to the information collected by the website maintained by Assure Disability, Inc.
				</p>
			</Card.Body>
		</Card>
	);
});

export default Collected;
