import React, { useState } from 'react';
import IconButton from 'components/common/IconButton';
import PropTypes from 'prop-types';
import { Col, Form, Row, Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import { documentAPI } from 'utils/api/fulcrum-api';

const DocumentTableHeader = ({ selectedRowIds, setUploadDocumentModalState, refreshLastQuery, hideExport = false, hideSearch = false, attachSelectedCB, bulkActionOverride = [] }) => {
	const [slectedBulkAction, setSlectedBulkAction] = useState(null);
	const [bulkActions, setBulkActions] = useState(
		bulkActionOverride.length > 0 ? bulkActionOverride :
			[
				// {
				// 	label: 'Delete',
				// 	action: "DELETE",
				// 	visible: true,
				// 	actionHanlder: () => {
				// 		Swal.fire({
				// 			title: 'Are you sure?',
				// 			text: 'You will not be able to recover this document(s)!',
				// 			icon: 'warning',
				// 			showCancelButton: true,
				// 			confirmButtonColor: '#3085d6',
				// 			cancelButtonColor: '#d33',
				// 			confirmButtonText: 'Yes, delete it!'
				// 		}).then(async (result) => {
				// 			if (result.isConfirmed) {
				// 				try {
				// 					let result = await documentAPI.deleteDocuments(selectedRowIds);
				// 					if (!result?.message) { throw new Error(result.error); }
				// 					toast.warning('Document(s) deleted', { theme: 'colored' });
				// 					refreshLastQuery();
				// 				} catch (error) {
				// 					toast.error("Unable to delete document(s)", { theme: 'colored' });
				// 					console.log(error);
				// 				}
				// 			}
				// 		});
				// 	}
				// },
				{
					label: 'Attach Selected',
					action: "ATTACH_SELECTED",
					visible: true,
					actionHanlder: () => {
						alert('here')
						attachSelectedCB(selectedRowIds);
					}
				}
			]
	);

	// Bulk Action Handler
	const bulkActionHandler = () => {
		if (slectedBulkAction) {
			let selectedAction = bulkActions.find(action => action.action === slectedBulkAction);
			selectedAction.actionHanlder();
		} else {
			alert("Unanble to process the bulk action.")
		}
	};

	return (
		<Row className="flex-between-center">
			<Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
				<h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">Documents</h5>
			</Col>
			<Col xs={8} sm="auto" className="ms-auto text-end ps-0">
				{Object.keys(selectedRowIds).length > 0 ? (
					<div className="d-flex">
						<Form.Select size="sm" aria-label="Bulk actions" onChange={e => { setSlectedBulkAction(e.target.value) }}>
							<option>Bulk Actions</option>
							{
								bulkActions.map((action, index) => {
									return action.visible ? <option key={index} value={action.action} onClick={action.actionHanlder}>{action.label}</option> : null;
								})
							}
						</Form.Select>
						<Button
							type="button"
							variant="falcon-default"
							size="sm"
							className="ms-2"
							onClick={(e) => bulkActionHandler(e)}
						>
							Apply
						</Button>
					</div>
				) : (
					<div id="orders-actions">
						{
							!hideSearch &&
							<IconButton
								variant="falcon-default"
								size="sm"
								icon="plus"
								transform="shrink-3"
								onClick={() => { setUploadDocumentModalState(true) }}
							>
								<span className="d-none d-sm-inline-block ms-1">Upload</span>
							</IconButton>
						}
						{
							!hideSearch &&
							<IconButton
								variant="falcon-default"
								size="sm"
								icon="filter"
								transform="shrink-3"
								className="mx-2"
							>
								<span className="d-none d-sm-inline-block ms-1">Filter</span>
							</IconButton>
						}

						{
							!hideExport &&
							<IconButton
								variant="falcon-default"
								size="sm"
								icon="external-link-alt"
								transform="shrink-3"
							>
								<span className="d-none d-sm-inline-block ms-1">Export</span>
							</IconButton>
						}
					</div>
				)}
			</Col>
		</Row>
	);
};

DocumentTableHeader.propTypes = {
	selectedRowIds: PropTypes.object,
	setUploadDocumentModalState: PropTypes.func,
	refreshLastQuery: PropTypes.func,
	hideExport: PropTypes.bool,
	hideSearch: PropTypes.bool,
	attachSelectedCB: PropTypes.func
};

export default DocumentTableHeader;
