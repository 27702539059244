import hearing from 'assets/img/products/application/hearing.webp';
import atlasware from 'assets/img/products/application/atlasware.webp';
import brief from 'assets/img/products/application/brief.webp';

export default [
  {
    icon: ['far', 'lightbulb'],
    iconText: 'Hearing Coverage',
    color: 'danger',
    title: 'Hearing Coverage',
    description: `<p>Never miss a hearing across the country. Attorneys are local to your client, leading to better results at the hearing. Never drop a good client again.</p>
    <ul>
    <li>
      Industry leader in remote hearing coverage
    </li>
    <li>
      300K+ nationwide hearings covered since 2013
    </li>
    <li>
      Our expert software provides for gathering, combining and analyzing data so you can reveal crucial insights in making decisions.
    </li>
    <li>
      Atlasware technology automatically scans and organizes your cases on your status report
    </li>
    </ul>`,
    image: hearing
  },
  {
    icon: ['far', 'object-ungroup'],
    iconText: 'Atlasware Cloud',
    color: 'info',
    title: 'Atlasware Cloud',
    description: `
    <p>Direct, easy and secure access to the ERE</p>
    <ul>
    <li>
      Full access to client’s disability files at initial, reconsideration, hearing and Appeals Council
    </li>
    <li>
      Client’s disability files are indexed, paginated and key word searchable
    </li>
    <li>
      Access to file downloads of the exhibited files to Include newest evidence
    </li>
    <li>
      Streamlined upload of documents to the electronic records exchange (ERE) without a barcode or a coversheet
    </li>
    </ul>`,
    image: atlasware,
    inverse: true
  },
  {
    icon: ['far', 'paper-plane'],
    iconText: 'Brief Writing',
    color: 'success',
    title: 'Brief Writing',
    description: `
      <p>Assure provides powerful ways to help with your prehearing, on the record, post hearing and appeals council briefs.</p>

      <ul>
      <li>
        Assure will analyze your case file and write a brief for your prehearing, on the record, post hearing and appeals council briefs available
      </li>
      <li>
        Access Brief Tool Pro and our smart brief template technology to easily and quickly write your own briefs
      </li>
      <li>
        Assure delivers thousands of custom briefs each month for claimants nationwide
      </li>
    </ul>`,
    image: brief
  }
];
