import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';

const TaskSearchForm = ({ clearSearchParams = () => { }, setParentModalState = () => { }, searchParams, setSearchParams, taskStatuses = [], taskTypes = [] }) => {
	let defaultState = {
		task_status_id: searchParams.task_status_id || '',
		task_type_id: searchParams.last_name || '',
		title: searchParams.title || '',
		startDueDate: searchParams.startDueDate || '',
		endDueDate: searchParams.endDueDate || '',
	};
	const [formData, setFormData] = useState(defaultState);

	const handleInputChange = (event) => {
		if (event) {
			event.stopPropagation();
		}
		let { name, value, type, checked = false } = event?.target || {}
		if (type === "checkbox") {
			value = checked;
		} else if (event) {
			event.preventDefault();
		}
		setFormData((prevState) => ({ ...prevState, [name]: value }));
	};

	// Apply Search Params
	const handleApply = (e) => {
		if (e) {
			e.stopPropagation();
			e.preventDefault();
		}
		setSearchParams(formData);
		setParentModalState(false);
	};

	// Clear Search Params
	const handleClear = () => {
		setSearchParams(requiredSearchParams);
		setParentModalState(false);
	};

	// const handleEdit = () => {
	// 	setIsEditing(true);
	// 	setPreviousFormData(formData);
	// };

	// const handleCancel = () => {
	// 	setIsEditing(false);
	// 	setFormData(previousFormData);
	// };

	// const handleSave = () => {
	// 	setIsEditing(false);
	// 	setPreviousFormData(formData);
	// };

	return (
		<Form onSubmit={handleApply} className="row flex-between-center px-2 py-3 my-3 border bg-100 mx-2">
			<Row>
				<Col>
					<h5>Filters</h5>
				</Col>
			</Row>

			<Row>
				<Col>
					<Form.Group controlId="task_status_id">
						<Form.Label>Status</Form.Label>
						<Form.Control
							as="select"
							name="task_status_id"
							value={formData.task_status_id || ''}
							onChange={handleInputChange}
						>
							<option value="">All</option>
							{
								taskStatuses.map((taskStatus) => {
									return (
										<option key={taskStatus.id} value={taskStatus.id}>{taskStatus.status}</option>
									)
								})
							}
						</Form.Control>
					</Form.Group>
				</Col>
				<Col>

					<Form.Group controlId="task_type_id">
						<Form.Label>Type</Form.Label>
						<Form.Control
							as="select"
							name="task_type_id"
							value={formData.task_type_id || ''}
							onChange={handleInputChange}
						>
							<option value="">All</option>
							{
								taskTypes.map((taskType) => {
									return (
										<option key={taskType.id} value={taskType.id}>{taskType.type}</option>
									)
								})
							}
						</Form.Control>
					</Form.Group>
				</Col>

				<Col>
					<Form.Group controlId="title">
						<Form.Label>Title</Form.Label>
						<Form.Control
							type="text"
							name="title"
							value={formData.title || ''}
							onChange={handleInputChange}
							placeholder="Title..."
						/>
					</Form.Group>

				</Col>
			</Row>
			<Row>
				<Col>
					<Form.Group controlId="startDueDate">
						<Form.Label>Start Date</Form.Label>
						<Form.Control
							type="date"
							name="startDueDate"
							value={formData.startDueDate || ''}
							onChange={handleInputChange}
							placeholder="Due Date..."
						/>
					</Form.Group>
				</Col>
				<Col>
					<Form.Group controlId="endDueDate">
						<Form.Label>End Date</Form.Label>
						<Form.Control
							type="date"
							name="endDueDate"
							value={formData.endDueDate || ''}
							onChange={handleInputChange}
							placeholder="Due Date..."
						/>
					</Form.Group>
				</Col>
			</Row>

			{/* <Row>
				<Form.Group as={Col} controlId="formGridCity">
					<Form.Label>First Name</Form.Label>
					<Form.Control
						size="sm"
						name="first_name"
						value={formData.first_name}
						onChange={handleInputChange}
						className={""}
					/>
				</Form.Group>

				<Form.Group as={Col} controlId="formGridState">
					<Form.Label>Last Name</Form.Label>
					<Form.Control size="sm" name="last_name" value={formData.last_name} onChange={handleInputChange} className={""} />
				</Form.Group>
			</Row>

			<Row>
				<Form.Group controlId="exampleForm.ControlInput1">
					<Form.Label>SSN</Form.Label>
					<Form.Control size="sm" type="text" name="ssn" value={formData.ssn} onChange={handleInputChange} />
				</Form.Group>
				<Form.Group controlId="exampleForm.ControlInput2">
					<Form.Label>Tag</Form.Label>
					<Form.Control size="sm" type="text" name="tags" value={formData.tags} onChange={handleInputChange} />
				</Form.Group>
			</Row> */}


			<Row className="mt-3">
				<Col>
					<div className="float-end">
						<Button variant="primary" type="submit" size="sm" onClick={handleApply} >
							Apply Search
						</Button>

						<span className="mx-2" />
						<Button variant="outline-danger" size="sm" onClick={handleClear}>
							Clear
						</Button>
					</div>
				</Col>
			</Row>
		</Form>
	);
};


export default TaskSearchForm;