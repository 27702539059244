
export default {
	downloadUrl: '/files/fulcrum-terms-of-service.pdf',
	substitutions: [
		{
			replace: '[CRM product]',
			with: 'Fulcrum'
		},
		{
			replace: '[Privacy Policy URL]',
			with: '<a href="http://localhost:3000/privacy-policy" target="_blank">Privacy Policy</a>'
		},
		{
			replace: '[Fee Amount]',
			with: '$50'
		},
		{
			replace: '[Fee Type]',
			with: 'user'
		},
		{
			replace: '[Last Update Date]',
			with: '6/1/2022'
		},
		{
			replace: '[CRM URL]',
			with: '<a href="/" target="_blank">https://fulcrum.com</a>'
		}
	],
	terms: `
	<h4>Terms of Service</h4>
	<hr />
	<p>By accessing and creating an account for [CRM product], you confirm that you are in agreement with and bound by the Terms & Conditions outlined below.  These terms apply to the entire website and any email or other type of communication between you and Assure Disability, Inc.</p>
	<h5>Definitions</h5>
	<p>Wherever appearing in these Terms and Conditions, the following words or terms appearing in bold typeface will have the following meanings:</p>
	<ul>
		<li>[CRM product] means the collection of related web pages, including all products and services found therein, published by Assure Disability, Inc. at [CRM URL].</li>
		<li>Affiliate mean any employee, independent contractor, subcontractor, agent, officer, director, successor, assign, or authorized representative as well as any corporation or other controlling, controlled, or commonly controlled business entity.</li>
		<li>Data means any data generated by a user through [CRM product] or any data uploaded to [CRM product] by a user.</li>
		<li>Privacy Policy means the privacy policy of Assure Disability, Inc., found at [Privacy Policy URL]. </li>
	</ul>

	<h5>License</h5>
	<p>Assure Disability grants you a revocable, non-exclusive, non-transferable, limited license to download, install, and use [CRM product] strictly in accordance with the Terms & Conditions of this agreement.  These Terms & Conditions are a contract between you and Assure Disability, Inc.</p>

	<h5>Use and Access</h5>
	<p>You will use [CRM product] only for your own business purposes and not for the benefit of any third party, other than Social Security disability claimants for whom you are, or reasonably anticipate to be, an appointed representative.</p>
	<p>Use of [CRM product] is contingent upon registering for access with Assure Disability, Inc.  Upon registration, Assure Disability, Inc. will issue credentials to enable access to [CRM product].  You are responsible for keeping confidential your credentials and will not disclose the same to any third party, save to your Affiliates to the extent necessary to enable use of [CRM product].  You are responsibility for all access to [CRM product] where such access is facilitated by your credentials, whether such access is with or without your consent.  If you become aware of any unauthorized access to [CRM product], you will immediately notify Assure Disability, Inc.</p>
	<p>Upon registration, Assure Disability, Inc. will make [CRM product] available to you to the extent not previously made available.  All subsequent versions of [CRM product] and all updates to [CRM product] made available to you will be considered a part of [CRM product] and subject to these Terms and Conditions.</p>

	<h5>Restrictions</h5>
	<p>You will not, or permit others to:</p>
	<ul>
		<li>allow, assist, or attempt to assist any individual or entity to access [CRM product], except to your Affiliates, all of whom must individually acknowledge and agree to these Terms and Conditions as well as Assure Disability, Inc.’s [Privacy Policy URL];</li>
		<li>modify, translate, reverse-engineer, reverse-compile, or disassemble [CRM product], or otherwise create, use, and/or exploit derivative work based upon [CRM product];</li>
		<li>use, copy, adapt, reproduce, sublicense, distribute, transfer, or dispose of [CRM product], in whole or in part, other than as permitted under these Terms and Conditions;</li>
		<li>make copies of [CRM product] or install [CRM product] to a platform without notice to and written consent from Assure Disability, Inc.;</li>
		<li>market, sell, lease, lend, or otherwise exploit [CRM product];</li>
		<li>alter, change, or remove any proprietary notices, labels, or identifications, including but not limited to any copyright or trademark notices, from [CRM product];</li>
		<li>disrupt, interfere with, or restrict the use of [CRM product] by other uses including, without limitation, denial of service attacks, mail-bombing, flooding, or other deliberate attempts to overload [CRM product];</li>
		<li>upload, display, or transmit any materials through [CRM product] which are offensive, defamatory, threatening, obscene, unlawful, or which infringe the rights, including but not limited to intellectual property rights, of any other individual or entity;</li>
		<li>probe, scan, or test the vulnerability of [CRM product], or circumvent or hack any user authentication or security controls in respect to [CRM product];</li>
		<li>attempt to transmit to or via [CRM product] any material that contains a virus, worm, Trojan horse, or other harmful or disruptive component;</li>
		<li>access, change, modify, delete, interfere with, or misuse any files, data, or software relating to [CRM product] (including other versions [CRM product]) which are not intended for you;</li>
		<li>access [CRM product] using credentials other than those given to you by Assure Disability, Inc.</li>
	</ul>

	<h5>Software and Data</h5>
	<p>[CRM product] is set of custom developed and proprietary systems encompassing software and database applications that utilize “Cloud Based” network services offered by Amazon Web Services and Rackspace datacenter products.  Assure Disability, Inc. manages [CRM product] and data associated with [CRM product] as follows:</p>
	<ul>
		<li>Monitoring, performance, and security of [CRM product] is handled via a management services contract consistent with best practice industry standards with recognized professional companies.</li>
		<li>Annually, Assure Disability, Inc. engages a licensed CPA firm to perform a System and Organization Controls 2 (SOC 2) Type 2 examination performed under AT-C 105 and AT-C 205.</li>
		<li>Assure Disability, Inc. maintains secure physical and logistic business systems.</li>
		<li>Assure Disability Inc. makes periodic changes and updates to its systems and network.  Changes that affect your usage and access to [CRM product] may be communicated directly via email or via messaging system located with [CRM product].  Other changes that do not directly affect your usage and/or data will be periodically made and may or may not be communicated.</li>
	</ul>
	<p>Assure Disability Inc. will use industry best practices and commercially reasonable due diligence in operation and management of its systems and in the provision of [CRM product].  To that end, Assure Disability, Inc. will make [CRM product] available with a monthly uptime percentage of at least 99.9%, in each case during any monthly billing cycle.  Monthly uptime percentage is calculated by subtracting from 100% the percentage of minutes during the month in which [CRM product] was unavailable.  [CRM product] is deemed unavailable when [CRM product] is in whole or in part unreachable through one or more commercially available web browsers.  In the event [CRM product] is not available as described above, you will be eligible to receive a credit as follows:</p>
	<ul>
		<li>
			Credits are calculated as a percentage of the total charges paid by you for the license to use [CRM product] for the monthly billing cycle in which [CRM product] was unavailable.  A credit will be provided using the following schedule:
			<table class="border my-2">
				<thead>
					<tr class="border">
						<th  class="border p-2">Monthly Uptime Percentage</th>
						<th  class="border p-2">Credit</th>
					</tr>
				</thead>
				<tbody>
					<tr class="border">
						<td  class="border p-2">Less than 99.9% but equal to or greater than 99.0%</td>
						<td  class="border p-2">10%</td>
					</tr>
					<tr class="border">
						<td  class="border p-2">Less than 99.0% but equal to or greater than 95.0%</td>
						<td  class="border p-2">30%</td>
					</tr>
					<tr class="border">
						<td  class="border p-2">Less than 95%</td>
						<td  class="border p-2">100%</td>
					</tr>
				</tbody>
			</table>
		</li>
		<li>Credits are applied against future payments for [CRM product].  At the discretion of Assure Disability, Inc., a credit may be issued to the banking information or credit card information you have supplied to Assure Disability, Inc.  Credits do not entitle you to any refund or other payment from Assure Disability, Inc.  Credits may not be transferred or applied to any other account.</li>
		<li>To receive a credit, you must submit a claim by providing written notice to Assure Disability, Inc. no later than the end of the second billing cycle after which [CRM product] was unavailable.  Such notice must include the dates and times [CRM product] was unavailable.</li>
		<li>
		Credits will not be provided for any unavailability, suspension, termination of service, or performance issue:
			<ul>
				<li>caused by factors outside the reasonable control of Assure Disability, Inc., including any force majeure event, Internet access, or related problem beyond the demarcation point of the applicable service;</li>
				<li>resulting from any action or inaction of you or another third party;</li>
				<li>resulting from equipment, software, or other technology not under the direct and immediate control of Assure Disability, Inc.;</li>
				<li>resulting from downtime for maintenance, with notice provided to you or published through [CRM product];</li>
				<li>resulting from the suspension or termination of rights or privileges under these Terms and Conditions.</li>
			</ul>
		</li>
	</ul>

	<p>Except as provided for in these Terms and Conditions, [CRM product] is provided “as is” and “as available” and Assure Disability, Inc. does not warrant the accuracy, adequacy, or completeness of [CRM product] and disclaims all liability for errors or omissions therein.  Except as provided for in these Terms and Conditions, no warranty of any kind is made by Assure Disability, Inc., including any warranty of merchantability, fitness for a particular purpose, non-infringement of third-party rights, or freedom from viruses or other code defects.  You agree that Assure Disability, Inc. has no responsibility for maintaining or providing any equipment, software, services, or any testing required in connection with [CRM product] and that Assure shall not be liable for any special, consequential, incidental, punitive, or indirect damages arising from or relating to use of [CRM product].</p>
	<p>[CRM product] is independently owned and operated by Assure Disability, Inc.  All right, title, and interest in the materials connected with [CRM product], including but not limited to information, documents, logos, graphics, sounds, images, and source code, are owned by Assure Disability, Inc.  Assure Disability, Inc. exclusively owns all rights, title and interest in and to any software programs, tools, utilities, processes, inventions, devices, methodologies, specifications, documentation, information, techniques, and material of any kind generated or developed by Assure Disability, Inc. in connection with [CRM product], including all worldwide patent rights, copyright rights, trade secret rights, know-how and any other intellectual property rights therein.  Except as otherwise expressly provided in these Terms and Conditions, none of the materials owned by Assure Disability, Inc. may be copied, reproduced, republished, downloaded, uploaded, posted, displayed, transmitted, or distributed in any way.  Except as expressly provided in these Terms and Conditions, nothing within [CRM product] shall be construed to confer any license under any of the intellectual property rights held by Assure Disability, Inc., whether by estoppels, implication, or otherwise.</p>
	<p>You are the sole owner of your Data and shall have the right to use your Data for the permitted uses described in these Terms and Conditions.  You acknowledge and agree that Assure Disability, Inc. has the right to use your Data on your behalf and for its own business purposes.  Assure Disability, Inc. will not disclose any of your Data to a third party without prior notice and authorization and will otherwise hold your Data as confidential as outlined in Assure Disability’s [Privacy Policy URL].</p>

	<h5>Services</h5>
	<p>You may use [CRM product] to obtain or receive other services from Assure Disability, Inc.  Any services obtained or received through [CRM product] will be provided under these Terms and Conditions and as specified in a supplemental service agreement.</p>

	<h5>Compensation and Payment</h5>
	<p>In consideration for access to and use of [CRM product], you will pay Assure Disability, Inc. a fee of [Fee Amount] for each [Fee Type].</p>
	<p>In consideration for services rendered by Assure Disability, Inc. through [CRM product], you will pay Assure Disability, Inc. fees as set forth in the agreement for services associated with the services rendered.</p>
	<p>The fees payable to Assure Disability, Inc. will be calculated by Assure Disability, Inc., and an invoice to you will be prepared by Assure Disability, Inc. at the end of each calendar month.  You will pay all fees reflected in the invoice within thirty (30) calendar days of receipt of the invoice from Assure Disability, Inc.  If you fail to pay any amount payable under these Terms and Conditions by the relevant due date, Assure Disability, Inc. may (in addition to any other rights or remedies of Assure) suspend your access to [CRM product] without notice and may suspend the provision of services through [CRM product] without notice until the outstanding balance is satisfied.</p>

	<h5>Term and Termination</h5>
	<p>These Terms and Conditions will remain in effect until this agreement is terminated by you or Assure Disability, Inc.  Assure Disability, Inc. may, in its sole discretion, at any time and for any or no reason, suspend or terminate this agreement with or without prior notice.  This agreement will terminate immediately, without prior notice, in the event that you fail to comply with any provision of these Terms and Conditions.  You may also terminate this agreement by deleting your account using the credentials provided to you by Assure Disability, Inc. upon registration.  Upon termination of this agreement, you shall cease all use of [CRM product].  Termination of this agreement will not limit any rights or remedies at law or in equity held by Assure Disability, Inc. in case of your breach of any of obligations under these Terms and Conditions.</p>

	<h5>Entire Agreement</h5>
	<p>These Terms and Conditions, and any agreement for service incorporating these Terms and Conditions, constitute the entire agreement between the parties with respect to the subject matter of this agreement, and supersede all previous communications, either oral or written, and all previous representations or warranties of any kind whatsoever, except as expressly set forth herein.</p>

	<h5>Assignment</h5>
	<p>You may not assign any of your rights or obligations under these Terms and Conditions without the prior written consent of Assure Disability, Inc.</p>

	<h5>Third Party Beneficiaries</h5>
	<p>This agreement is for the sole benefit of the parties to this agreement. Nothing expressed or implied herein shall give or be construed to give any person, other than the parties hereto and such assigns, any legal or equitable rights hereunder.</p>
	
	<h5>Indemnification</h5>
	<p>You agree to indemnify and hold Assure Disability, Inc. and its Affiliates harmless from any claim or demand, including reasonable attorneys’ fees, due to or arising out of your use of [CRM product], your violation of these Terms and Conditions, your violation of any law or regulation, or your violation of any right of a third party.</p>

	<h5>Limited of Liability</h5>
	<p>Notwithstanding any damages that you might incur, the entire liability of Assure Disability, Inc. and its Affiliates under any provision of these Terms and Conditions and your exclusive remedy for all of the foregoing shall be limited to the amount you actually pain in fees to Assure Disability, Inc. for license to [CRM product] and services rendered by Assure Disability, Inc. through [CRM product].</p>

	<h5>Non-Soliciation</h5>
	<p>During the term of this agreement, and for twelve months following termination of this agreement, you will not, directly or indirectly, solicit or endeavor to entice away, employ, hire, engage, work together with or for the same business enterprise as, or otherwise interfere with the business relationship of Assure Disability, Inc. and, any person who is, or was withing the twelve (12) months of the date of solicitation or contact, an employee, contractor, or service provider of Assure Disability, Inc.</p>

	<h5>Waiver</h5>
	<p>A parties’ failure to exercise or delay in exercising any right, power, or privilege under this agreement shall not operate as a waiver, nor shall any single or partial exercise of any right, power, or privilege preclude any other further exercise thereof.</p>

	<h5>Severability</h5>
	<p>If any provision in these Terms and Conditions is held to be unenforceable or invalid, such provision will be changed and interpreted to accomplish the objectives of such provision to the greatest extent possible under applicable law and the remaining provisions will continue in full force and effect.</p>

	<h5>Governing Law</h5>
	<p>All issues concerning these Terms and Conditions shall be governed by and construed in accordance with the laws of the State of Texas, without giving effect to any choice of law or conflict of law provision or rule (whether of the State of Texas or any other jurisdiction) that would cause the application of the law of any other jurisdiction other than the State of Texas.  All suits arising out of or relating to these Terms and Conditions shall be filed in the courts of Tarrant County, Texas.</p>

	<h5>Notices</h5>
	<p>Please send any notices under these Terms and Conditions to Assure Disability at Assure Disability, Inc., 8200 Camp Bowie West Blvd., Fort Worth, TX 76116 or at info@higginsandassociates.com.</p>

	<h5>Updates to Terms and Conditions</h5>
	<p>Assure Disability, Inc. may change our Terms and Conditions and our policies.  Unless otherwise required by law, Assure Disability, Inc. will notify you before we make changes to the Terms and Conditions or our policies and give you an opportunity to review them before they go into effect.  If you continue to use [CRM product] and/or the services offered through [CRM product], you will be bound by the updated Terms and Conditions or policies, as applicable.</p>

	<p>Last updated: [Last Update Date]</p>
	`
};
