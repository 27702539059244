import React, { useContext, useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import AppContext from 'context/Context';
import { Modal, Form, Row } from 'react-bootstrap';
import Select from 'react-select';
import IconButton from 'components/common/IconButton';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import { stateData } from 'data/stateData';

const ClaimantFiltersModalForm = ({ initialState = {}, modalState = false, setModalState = () => { }, availableColumns = [], setReportParams = {}, refreshParent = () => { }, selectedReport = {}, updateReport = () => { } }) => {
	const { config: { isDark } } = useContext(AppContext);
	const formRef = useRef();
	const defaultNewColumn = { value: '', label: '' }; // { value: availableColumns[0]?.accessor, label: availableColumns[0]?.Header };
	const [newColumn, setNewColumn] = useState(defaultNewColumn);
	const [formData, setFormData] = useState({}); // Initial state is set in UseEffect below whenever modalState changes 

	const onSubmitData = async (e) => {
		if (e) {
			e.preventDefault();
			e.stopPropagation();
		}
		if (!formData || formData && Object.keys(formData).length === 0) {
			return
		}

		let formFieldsArray = Object.keys(formData);
		let requestedFields = availableColumns
			.filter((col) => formFieldsArray.includes(col.accessor)).map((col) => ({ ...col, ...(formData[col.accessor] !== '' && { value: formData[col.accessor] }) }));
		setReportParams((prev) => ({ ...prev, requestedFields }));
		closeModal();
	};

	const onUpdateReport = async (e) => {
		if (e) {
			e.preventDefault();
			e.stopPropagation();
		}
		if (!formData || formData && Object.keys(formData).length === 0) {
			return
		}

		let formFieldsArray = Object.keys(formData);
		let requestedFields = availableColumns
			.filter((col) => formFieldsArray.includes(col.accessor)).map((col) => ({ ...col, ...(formData[col.accessor] !== '' && { value: formData[col.accessor] }) }));
		setReportParams((prev) => ({ ...prev, requestedFields }));
		updateReport();
		closeModal();
	};

	const onClearFilter = (e) => {
		if (e) {
			e.preventDefault();
			e.stopPropagation();
		}

		setFormData((prev) => {
			let newFormData = Object.entries(prev).reduce((acc, [key, value]) => ({ ...acc, [key]: '' }), {});
			return newFormData;
		});
	};

	const addNewColumn = (e) => {
		if (e) {
			e.preventDefault();
			e.stopPropagation();
		}

		let formDataObj = {};
		const formData = new FormData(formRef.current);
		for (let [key, value] of formData) {
			formDataObj[key] = value;
		}

		let newFormData = Object.entries(formDataObj).reduce((acc, [key, value]) => ({ ...acc, [value]: '' }), {});
		if (!newColumn?.value || Object.keys(newFormData).filter(Boolean).length == 0) {
			return;
		}
		setFormData((prev) => ({ ...prev, ...newFormData }));
		setNewColumn(defaultNewColumn); // Reset and allow user to pick a different column 
	};

	const removeColumn = (key, e) => {
		if (e) {
			e.preventDefault();
			e.stopPropagation();
		}
		if (formData && Object.hasOwn(formData, key)) {
			delete formData[key];
		}
		setFormData({ ...formData });
	};

	const openModal = () => (setModalState(true));
	const closeModal = () => {
		refreshParent();
		setModalState(false);
	};

	const handleInputChange = (event) => {
		let { name, value, type, checked = false } = event.target;
		if (type === 'checkbox') {
			value = checked;
		} else {
			event.preventDefault();
		}
		if (name.includes('--')) {
			let dateSplit = name.split("--");
			setFormData({ ...formData, [dateSplit[0]]: { ...formData[dateSplit[0]], [dateSplit[1]]: value } });
		} else {
			setFormData({ ...formData, [name]: value });
		}
	};

	const formInputRenderSwitch = (index, key, value) => {
		const type = availableColumns.find((col) => col.accessor === key)?.type;
		switch (type) {
			case 'select-state':
				return <Form.Group key={index} controlId={key} >
					<FloatingLabel
						style={{ display: 'inline-block', width: "25%" }}
						className="fw-bold"
					>
						{availableColumns?.find((col) => col.accessor === key)?.Header}
					</FloatingLabel>
					<Form.Select
						style={{ display: 'inline-block', width: "65%" }}
						size="sm"
						as="select"
						name={key}
						value={value}
						onChange={handleInputChange}
					>
						<option value=""></option>
						{
							Array.isArray(stateData) && stateData.map((state, index) => {
								return (
									<option key={index} value={state.abbreviation}>{state.abbreviation}</option>
								);
							})
						}
					</Form.Select>
					<IconButton style={{ display: 'inline-block', width: "5%", marginLeft: "10px", marginBottom: "5px" }} className="me-2" variant="falcon-default" size="sm" icon="trash" transform="shrink-3" type="button" onClick={(e) => removeColumn(key, e)} />
				</Form.Group>
			case 'nullish':
			case 'boolean':
				return <Form.Group key={index} controlId={key} >
					<FloatingLabel
						style={{ display: 'inline-block', width: "25%" }}
						className="fw-bold"
					>
						{availableColumns?.find((col) => col.accessor === key)?.Header}
					</FloatingLabel>
					<Form.Select
						style={{ display: 'inline-block', width: "65%" }}
						size="sm"
						as="select"
						name={key}
						value={value}
						onChange={handleInputChange}
					>
						{
							["", "true", "false"].map((opt, index) => {
								return (
									<option key={index} value={opt}>{type === "boolean" ? (`${opt}`.toLowerCase() === "true" ? "Yes" : (`${opt}`.toLowerCase() === "" ? "" : "No")) : opt}</option>
								);
							})
						}
					</Form.Select>
					<IconButton style={{ display: 'inline-block', width: "5%", marginLeft: "10px", marginBottom: "5px" }} className="me-2" variant="falcon-default" size="sm" icon="trash" transform="shrink-3" type="button" onClick={(e) => removeColumn(key, e)} />
				</Form.Group>

			case 'date':
				return <Form.Group key={index} controlId={key}>
					<FloatingLabel
						style={{ display: 'inline-block', width: "25%" }}
						className="fw-bold"
					>
						{availableColumns?.find((col) => col.accessor === key)?.Header}
					</FloatingLabel>
					<Form.Control
						style={{ display: 'inline-block', width: "32.5%" }}
						size="sm"
						type="date"
						name={`${key}--start`}
						value={formData[key].start || ''}
						onChange={handleInputChange}
					/>
					{formData[key].isRange ? (<Form.Control
						style={{ display: 'inline-block', width: "32.5%" }}
						size="sm"
						type="date"
						name={`${key}--end`}
						value={formData[key].end || ''}
						onChange={handleInputChange}
					/>) : (<Form.Check
						style={{ display: 'inline-block', width: "32.5%", margin: "auto", paddingLeft: "35px" }}
						type="checkbox"
						label="Search between date range?"
						name={`${key}--isRange`}
						value={formData[key].isRange || false}
						onChange={handleInputChange}
					/>)}
					<IconButton style={{ display: 'inline-block', width: "5%", marginLeft: "10px", marginBottom: "5px" }} className="me-2" variant="falcon-default" size="sm" icon="trash" transform="shrink-3" type="button" onClick={(e) => removeColumn(key, e)} />
				</Form.Group>
			default:
				return <Form.Group key={index} controlId={key} >
					<Form.Label style={{ display: 'inline-block', width: "25%" }} className="fw-bold" size="sm">
						{availableColumns?.find((col) => col.accessor === key)?.Header}
					</Form.Label>
					<Form.Control
						size="sm"
						type="text"
						style={{ display: 'inline-block', width: "65%" }}
						name={key}
						value={value}
						onChange={handleInputChange}
					/>
					<IconButton
						id={key}
						name={key}
						style={{ display: 'inline-block', width: "5%", marginLeft: "10px", marginBottom: "5px" }}
						className="me-2"
						variant="falcon-default"
						size="sm"
						icon="trash"
						transform="shrink-3"
						type="button"
						onClick={(e) => removeColumn(key, e)} />
				</Form.Group>;
		}
	}

	useEffect(() => {
		if (modalState) {
			setFormData(initialState);
		}
	}, [modalState])

	return (<Modal show={modalState} onHide={closeModal} onShow={openModal} contentClassName="border" size="lg" backdrop="static" className="ps-2" >
		<Modal.Header closeButton closeVariant={isDark ? 'white' : undefined} className="bg-light px-card border-bottom-0" >
			<Modal.Title as="h5">Filters</Modal.Title>
			<Form noValidate ref={formRef} onSubmit={addNewColumn} className="flex-between-center ms-1 w-100">
				<Form.Group className="d-flex">
					<Select
						closeMenuOnSelect={false}
						options={availableColumns.filter((col) => !Object.keys(formData).includes(col.accessor)).map((column) => ({ value: column.accessor, label: column.Header }))}
						placeholder='Add Column'
						classNamePrefix="react-select"
						className="flex-grow-1 w-auto"
						aria-label="Add Column"
						name="add_column"
						value={newColumn}
						onChange={value => setNewColumn(value)}
					/>
					<IconButton variant="primary" size="sm" icon="check" className="ms-2" type="submit" iconAlign="left" disabled={!newColumn?.value}>
						<span className="d-sm-inline-block ms-1">Add Field</span>
					</IconButton>
				</Form.Group>
			</Form>
		</Modal.Header>
		<Modal.Body>
			<Form noValidate onSubmit={onSubmitData} className="flex-between-center">
				<Row className="mb-3" style={{ overflow: "auto", maxHeight: "300px" }}>
					{Object.entries(formData).map(([key, value], index) => {
						return formInputRenderSwitch(index, key, value);
					})}
				</Row>
				<Row className="mb-3 px-2">
					{Object.keys(formData).length > 0 && (<IconButton variant="primary" size="sm" icon="check" className="col" type="submit" iconAlign="left">
						<span className="d-sm-inline-block ms-1">Submit</span>
					</IconButton>)}
					{selectedReport.title && <IconButton variant="secondary" size="sm" icon="edit" className="col mx-2" type="button" iconAlign="left" onClick={onUpdateReport} >
						<span className="d-sm-inline-block ms-1">Update {selectedReport.title}</span>
					</IconButton>}
					{Object.values(formData).filter(Boolean).length > 0 && (<IconButton variant="danger" size="sm" icon="clear" className="col me-2" type="button" iconAlign="left" onClick={onClearFilter}>
						<span className="d-sm-inline-block ms-1">Clear</span>
					</IconButton>)}
				</Row>
			</Form>
		</Modal.Body>
	</Modal>);
};

ClaimantFiltersModalForm.propTypes = {
	initialState: PropTypes.object,
	modalState: PropTypes.object,
	setModalState: PropTypes.func,
	availableColumns: PropTypes.array,
	setReportParams: PropTypes.object,
	refreshParent: PropTypes.func,
	selectedReport: PropTypes.object,
	updateReport: PropTypes.func,
};

export default ClaimantFiltersModalForm;