import React, { useState, useEffect, useRef } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { toast } from 'react-toastify';
import { myFormsAPI, serviceAPI } from 'utils/api/fulcrum-api';
import GenericFormView from 'components/pages/documents/my-forms/MyFormViewer/GenericFormView';
import { getClientDigitalInformation } from 'utils/ipify';

const ExecuteServiceAgreementDocument = ({ account, service, selectedServiceAgreement, setSelectedServiceAgreement = () => { }, modalState, setModalState = () => { }, refreshAccountServices = () => { } }) => {
  const [modalWidthPx, setModalWidthPx] = useState(500);
  const [renderedAgreementHtml, setRenderedAgreementHtml] = useState('');
  const [submitting, setSubmitting] = useState(false);


  // Get rendered agreement html
  const getAgreementHtml = async (myFormId) => {
    try {
      if (!myFormId) { throw new Error('No myFormId'); };
      let myFormResponse = await myFormsAPI.getMyForm(myFormId);
      if (!myFormResponse?.data?.content) {
        throw new Error('No agreement html');
      }

      let mergedContentResult = await myFormsAPI.getRenderedMyFormContent(myFormResponse?.data?.content, { account_id: account?.id || null });
      if (!mergedContentResult?.data?.adjustedContent) {
        throw new Error('No merged agreement html');
      }


      setRenderedAgreementHtml(mergedContentResult?.data?.adjustedContent || '');
    } catch (error) {
      toast.error('Error getting agreement html', { theme: 'colored' });
      console.log(error);
      handleClose();
    }
  };

  const handleUpload = async (event) => {
    if (event) { event.preventDefault(); }
    console.log("handleUpload");
    return;
  }

  // Handle Submit
  const handleSubmit = async () => {
    setSubmitting(true);
    try {
      // Get rendered agreement html
      let adjustedContent = document.querySelector('#my-form-response-container').innerHTML;

      // Make sure all signature fields are filled out
      let signatureFields = document.querySelectorAll('.signature');
      let signatureFieldsFilled = true;

      signatureFields.forEach((field) => {
        if (!field?.src || field.src.includes('/signature-placeholder.png')) {
          signatureFieldsFilled = false;
        }
      });

      if (!signatureFieldsFilled) {
        toast.error("Please sign all signature fields", { theme: 'colored' });
        setSubmitting(false);
        return;
      }

      let clientDeviceInformation = await getClientDigitalInformation();

      if (!adjustedContent) {
        throw new Error('No adjusted content');
      }

      let newAccountServiceAgreement = {
        ...selectedServiceAgreement,
        account_id: account?.id || null,
        content: adjustedContent,
        status: 'SIGNED',
        file_id: null,
        signed_timestamp: new Date().toISOString(),
        clientDeviceInformation
      };

      // Create new my form response record
      let myFormResponseResult = await serviceAPI.executeServiceAgreement(
        account.id || null,
        service.id || null,
        newAccountServiceAgreement
      )
      if (!myFormResponseResult?.data?.message) {
        throw new Error('No myFormResponseResult');
      }
      toast.success('Agreement executed', { theme: 'colored' });
      console.log("My Form Response", myFormResponseResult);

      refreshAccountServices();
      handleClose();
    } catch (error) {
      console.log(error);
      toast.error('Error executing service agreement', { theme: 'colored' });
    }

    setSubmitting(false);
  };

  // Handle Close
  const handleClose = () => {
    setSubmitting(false);
    setModalState(false);
    setRenderedAgreementHtml('');
    setSelectedServiceAgreement({});
  };

  // Handle Show
  const handleShow = () => {
    if (selectedServiceAgreement?.my_form_id) {
      getAgreementHtml(selectedServiceAgreement?.my_form_id || null);
    }
    // getAgreementHtml();
  };

  // useEffect(() => {
  //   if (selectedServiceAgreement?.my_form_id) {
  //     getAgreementHtml(selectedServiceAgreement?.my_form_id || null);
  //   }
  // }, [selectedServiceAgreement])

  return (
    <Modal
      show={modalState}
      size="lg"
      onHide={handleClose}
      onShow={handleShow}
      className='agreement'
    >
      <Modal.Header closeButton>
        <Modal.Title>Execute Service Agreement Document</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h5>{selectedServiceAgreement?.title || ''}</h5>
        <p>Please review and sign the agreement below to execute the service agreement.</p>

        <div className="border p-2" style={{ overflowY: "auto", backgroundColor: '#FFFFFF', color: '#000000', height: "500px" }}>
          {
            renderedAgreementHtml &&
            <>
              {
                submitting ?
                  <div className="d-flex justify-content-center align-items-center" style={{ height: "100%" }}>
                    <div className="spinner-border text-primary" role="status">
                      <span className="sr-only">Submitting...</span>
                    </div>
                  </div>
                  :
                  <GenericFormView
                    content={renderedAgreementHtml}
                  />
              }

              {
                !submitting &&
                <Button variant="secondary" onClick={handleClose}>
                  Close
                </Button>
              }
              <Button variant="primary" onClick={handleSubmit} disabled={submitting}>
                {submitting ? 'Submitting...' : 'Submit'}
              </Button>
            </>
          }
        </div>

      </Modal.Body>
      <Modal.Footer>

      </Modal.Footer>
    </Modal>
  );
}

export default ExecuteServiceAgreementDocument;