import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan, faToggleOff } from "@fortawesome/free-solid-svg-icons";

// NOTE: Icons that are added to the library in helpers/initFA.js can be used throughout our app by their icon string names, "check-square" and "coffee", respectively.
// <FontAwesomeIcon icon="coffee" /> or <FontAwesomeIcon icon="check-square" />
const iconAlias = {
  'clear': faBan,
  'toggle': faToggleOff,
};

const IconButton = ({
  icon,
  iconAlign = 'left',
  iconClassName,
  transform,
  children,
  ...rest
}) => (
  <Button {...rest}>
    {iconAlign === 'right' && children}
    <FontAwesomeIcon
      icon={icon in iconAlias ? iconAlias[icon] : icon}
      className={classNames(iconClassName, {
        'me-1': children && iconAlign === 'left',
        'ms-1': children && iconAlign === 'right'
      })}
      transform={transform}
    />
    {iconAlign === 'left' && children}
  </Button>
);

IconButton.propTypes = {
  ...Button.propTypes,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  children: PropTypes.any,
  iconAlign: PropTypes.oneOf(['left', 'right']),
  iconClassName: PropTypes.string,
  transform: PropTypes.string
};

export default IconButton;
