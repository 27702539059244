import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'react-bootstrap';
import AppContext from 'context/Context';
import { myFormsAPI } from 'utils/api/fulcrum-api';
import { toast } from 'react-toastify';


const RenderMyFormContent = ({
	modalState,
	setModalState = () => { },
	relatedData = {
		claimant_id: 1,
		user_id: 1
	},
	defaultContent = null
}) => {
	const [content, setContent] = useState(defaultContent);
	const {
		config: { isDark }
	} = useContext(AppContext);

	// Render the form
	const renderForm = async () => {
		try {
			let result = await myFormsAPI.renderMyFormContent(content, relatedData);
			if (!result?.data) {
				throw new Error('Error rendering form');
			}
			setContent(result.data?.adjustedContent || "");
		} catch (error) {
			console.log(error);
			toast.error('Error rendering form', { theme: 'colored' });
		}
	};

	// Submit Form
	// const submitForm = async () => {
	// 	alert('submitForm');
	// };

	// Handle Show
	const handleShow = () => {

	};

	const handleClose = () => {
		setModalState(false);
	};

	return (
		<Modal
			show={modalState}
			onHide={handleClose}
			onShow={handleShow}
			contentClassName="border"
			size="lg"
			backdrop="static"
		>
			<Modal.Header
				closeButton
				closeVariant={isDark ? 'white' : undefined}
				className="bg-light px-card border-bottom-0"
			>
				<Modal.Title as="h5">My Form Viewer</Modal.Title>
			</Modal.Header>
			<Modal.Body className="p-card">
				<Button variant="primary" onClick={renderForm}>Render Form</Button>
				<hr />
				<div
					dangerouslySetInnerHTML={{ __html: content }}
				/>
			</Modal.Body>
		</Modal>
	);
};

RenderMyFormContent.propTypes = {
	modalState: PropTypes.bool,
	setModalState: PropTypes.func,
	relatedData: PropTypes.object,
	defaultContent: PropTypes.string
}

export default RenderMyFormContent;