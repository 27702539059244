export const lookupsReducer = (state, action) => {
    const { type, payload } = action;
    switch (type) {
        case 'SET_LOOKUP':
            return {
                ...state,
                [payload.key]: payload.value
            };
        default:
            return state;
    }
};
