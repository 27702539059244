import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';

const Notification = ({ selectNotification, showModal, notification }) => {
  const prepOpenModal = () => {
    selectNotification(notification);
    showModal();
  }

  return (
    <Link
      className={classNames(
        'notification',
        { 'notification-unread': "dismissed_timestamp" in notification ? notification?.dismissed_timestamp : false, 'notification-flush': true }
      )}
      to="#!"
      onClick={prepOpenModal}
    >
      <Row>
        <Col sm={2}>

          <div className="notification-avatar text-center align-center">
            <FontAwesomeIcon className={`text-${notification?.notification_type?.importance || "warning"}`} icon={notification?.notification_type?.icon || 'exclamation'} />
          </div>

        </Col>
        <Col>

          <div className="notification-body">
            <p className="mb-1">{notification?.notification_type?.message || ''} {notification?.message || ''}</p>
            <span className="notification-created_at">
              {notification?.createdAt ? moment(notification?.createdAt).format('MM/DD/YYYY hh:mm A') : ''}
            </span>
          </div>
        </Col>
      </Row>
    </Link>
  );
};

Notification.propTypes = {
  selectNotification: PropTypes.func,
  showModal: PropTypes.func,
  notification: PropTypes.object
}

export default Notification;
