import React, { useRef } from 'react';
import useScrollSpy from 'react-use-scrollspy';
import NavbarStandardNoTransparent from 'components/pages/landing/NavbarStandardNoTransparent';
import Hero from 'components/pages/landing/Hero';
import PrivacyPolicy from './PrivacyPolicy';

const PrivacyPolicyPage = () => {
	const sectionRefs = [
		useRef(null),
		useRef(null),
		useRef(null),
		useRef(null),
		useRef(null),
		useRef(null),
		useRef(null)
	];

	const activeSection = useScrollSpy({
		sectionElementRefs: sectionRefs,
		offsetPx: -150
	});

	return (
		<>
			<NavbarStandardNoTransparent />
			<div className="pt-5">
				{' '}
			</div>
			<div className="my-5 mx-5">
				<PrivacyPolicy />
			</div>
		</>
	);
};

export default PrivacyPolicyPage;
