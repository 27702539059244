import React, { useState } from 'react';
import InboxTable from 'components/app/inbox-outbox/inbox/InboxTable';
import OutboxTable from 'components/app/inbox-outbox/outbox/OutboxTable';
import Tabs from 'react-bootstrap/Tabs';
import Card from 'react-bootstrap/Card';
import Tab from 'react-bootstrap/Tab';
import Button from 'react-bootstrap/Button';
import CreateCorrespondenceWizard from 'components/app/inbox-outbox/correspondence-wizard/CreateCorrespondenceWizard';
import CreateCorrespondenceModal from 'components/app/inbox-outbox/correspondence-wizard/CreateCorrespondenceModal';
import PageHeader from 'components/pages/PageHeader';

const ClaimantInboxOutboxView = ({ requiredSearchParams = {}, defaultSearchParams = {}, refreshParent = () => { } }) => {
	const [createCorrespondenceModalState, setCreateCorrespondenceModalState] = useState(false);

	return (
		<div>		
			<Card className="shadow-none">
				<Card.Body>
					<h5 className="d-inline">Correspondence</h5>
					<div className="page-header-actions float-end">
						<Button className="btn btn-primary float-end" onClick={() => setCreateCorrespondenceModalState(true)}>Create Correspondence</Button>
					</div>
				</Card.Body>
			</Card>
			<Tabs variant='pills' defaultActiveKey="inbox" id="uncontrolled-tab-example" className='mb-3'>		
				<Tab eventKey="inbox" title="Inbox">
					<InboxTable
						requiredSearchParams={{ claimant_id: requiredSearchParams?.claimant?.id }}
						defaultSearchParams={defaultSearchParams}
						refreshParent={refreshParent}
					/>
				</Tab>
				<Tab eventKey="outbox" title="Outbox">
					<OutboxTable
						requiredSearchParams={{ claimant_id: requiredSearchParams?.claimant?.id }}
						defaultSearchParams={defaultSearchParams}
						refreshParent={refreshParent}
					/>
				</Tab>
			</Tabs>
			<CreateCorrespondenceWizard
				modalState={createCorrespondenceModalState}
				modalTitle={'Email Claimant'}
				customMessage={'Email Claimant'}
				defaultClaimants={[requiredSearchParams?.claimant]}
				defaultSlide={1}
				defaultModeOfTransmission={'EMAIL'}
				setModalState={setCreateCorrespondenceModalState}
				refreshParent={refreshParent}
			/>
		</div>
	);
};

export default ClaimantInboxOutboxView;
