import React from 'react';
import PropTypes from 'prop-types';
import WizardInput from '../WizardInput';
import IconAlert from '../../common/IconAlert';

// const PasswordResetForm = ({ register, errors, watch, accessVerificationData, updateUserError, setUpdateUserError, handleSubmitForm }) => {
const PasswordResetForm = ({ register, errors, watch, updateUserError, setUpdateUserError }) => {

  return (
    <>
      {updateUserError &&
        <IconAlert
          variant="danger"
          className="mb-3"
          dismissible={true}
          onClose={() => setUpdateUserError(false)}
        >
          <p>Unable to update the password. Please reach out to your system administrator for help.</p>
        </IconAlert>
      }

      <h5>Set Password</h5>
      <p>You must use your email and new password to log into your account.</p>

      <WizardInput
        type="password"
        errors={errors}
        label="Password*"
        name="password"
        formGroupProps={{}}
        formControlProps={{
          ...register('password', {
            required: 'You must specify a password',
            pattern: {
              value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
              message: 'Password must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, one number, and one special character'
            }
          })
        }}
      />
      <WizardInput
        type="password"
        errors={errors}
        label="Confirm Password*"
        name="confirmPassword"
        formGroupProps={{}}
        formControlProps={{
          ...register('confirmPassword', {
            required: 'Confirm Password field is required',
            validate: value =>
              value === watch('password') || 'The password does not match'
          })
        }}
      />
    </>
  );
};

PasswordResetForm.propTypes = {
  register: PropTypes.func.isRequired,
  errors: PropTypes.object,
  watch: PropTypes.func,
  updateUserError: PropTypes.bool,
  setUpdateUserError: PropTypes.func
};

export default PasswordResetForm;