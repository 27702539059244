import React, { useState, useRef } from 'react';
import JoditEditor from "jodit-pro-react";
import { Button } from 'react-bootstrap';
import PreviewFormModal from './MyFormViewer/PreviewFormModal';


const JoditContentEditor = ({ content, setContent = () => { }, editorConfig = { readonly: false }, saveContentCallback = () => { }, withSaveButton = true, onEditorChangeHandler = () => { } }) => {
	const editor = useRef(null);
	const [editorContent, setEditorContent] = useState(content);
	const [viewFormModalState, setViewFormModalState] = useState(false);

	const saveJoditContentEditor = async () => {
		saveContentCallback(editorContent);
		setEditorContent(editorContent);
	};

	// Update content
	const updateContent = (newContent) => {
		setEditorContent(newContent);
		onEditorChangeHandler(newContent);
	};

	// window.addEventListener("beforeunload", function (e) {
	// 	if (content !== editorContent) {
	// 		var confirmationMessage = 'It looks like you have not saved your recent changes. If you leave this page, your changes will be lost.';

	// 		(e || window.event).returnValue = confirmationMessage; //Gecko + IE
	// 		return confirmationMessage; //Gecko + Webkit, Safari, Chrome etc.
	// 	}
	// });

	return (
		<div>
			<h5>Content Editor</h5>
			<div className="mt-3">
				<div className="mb-3">
					{
						(content !== editorContent && withSaveButton) &&
						<>
							<Button className="mr-2" onClick={saveJoditContentEditor}>Save</Button> {' '}{' '}
						</>
					}
					<Button variant="falcon-primary" onClick={() => setViewFormModalState(true)}>Preview Form</Button>
				</div>

				<JoditEditor
					ref={editor}
					value={editorContent}
					rows={10}
					config={editorConfig}
					onChange={newContent => setEditorContent(newContent)}
					tabIndex={1} // tabIndex of textarea
				/>

				<PreviewFormModal
					modalState={viewFormModalState}
					setModalState={setViewFormModalState}
					content={content}
				/>
			</div>
		</div>
	);
};


export default JoditContentEditor;